const PromoCodeActionType = {
  GET_PROMO_CODES_PACKS: 'GET_PROMO_CODES_PACKS',
  SAVE_PROMO_CODES_PACKS: 'SAVE_PROMO_CODES_PACKS',
  RESET_PROMO_CODES_PACKS: 'RESET_PROMO_CODES_PACKS',

  GET_PROMO_CODE: 'GET_PROMO_CODE',
  SAVE_PROMO_CODE: 'SAVE_PROMO_CODE',
  RESET_PROMO_CODE: 'RESET_PROMO_CODE',

  GET_USED_PROMO_CODES: 'GET_USED_PROMO_CODES',
  SAVE_USED_PROMO_CODES: 'SAVE_USED_PROMO_CODES',
  RESET_USED_PROMO_CODES: 'RESET_USED_PROMO_CODES',

  EXPORT_AVAILABLE_PROMO_CODES: 'EXPORT_AVAILABLE_PROMO_CODES',
  EXPORT_USED_PROMO_CODES: 'EXPORT_USED_PROMO_CODES',

  GET_IS_PROMO_CODE_EXISTS: 'GET_IS_PROMO_CODE_EXISTS',
  SAVE_IS_PROMO_CODE_EXISTS: 'SAVE_IS_PROMO_CODE_EXISTS',
  RESET_IS_PROMO_CODE_EXISTS: 'RESET_IS_PROMO_CODE_EXISTS',

  GET_PROMO_CODES: 'GET_PROMO_CODES',
  SAVE_PROMO_CODES: 'SAVE_PROMO_CODES',
  RESET_PROMO_CODES: 'RESET_PROMO_CODES',

  GET_CSV_PROMO_CODES: 'GET_CSV_PROMO_CODES',
  SAVE_CSV_PROMO_CODES: 'SAVE_CSV_PROMO_CODES',
  RESET_CSV_PROMO_CODES: 'RESET_CSV_PROMO_CODES',

  CHANGE_PROMO_CODE_ENDED_STATUS: 'CHANGE_PROMO_CODE_ENDED_STATUS',
};

export default PromoCodeActionType;
