import { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Route, Routes, unstable_HistoryRouter as HistoryRouter } from 'react-router-dom';
import { Reset as CSSReset } from 'styled-reset';
import { ConfigProvider } from 'antd';
import { ConfigProvider as ConfigProviderOld } from 'antd-old';
import {
  Auth, NoMatch, CreateBrand, NoAccessAccount, HealthCheck,
} from 'pages';
import NoAccess from 'containers/common/NoAccess';
import BaseLayout from 'layouts/BaseLayout';
import BrandSettingLayout from 'layouts/BrandSettingLayout';
import { history } from 'services';
import StyledGlobal from 'Global.styled';
import {
  lightModeColors, nightModeColors, theme, token,
} from 'theme/theme';
import { resource, types } from 'constants/accessControl';
import { locales } from 'constants/locale/antd';
import ThemeProvider from 'components/common/ThemeProvider';
import { LayoutProvider } from 'providers/LayoutProvider';
import { brandRouters } from './brandRouters';
import { allRouters } from './allRouters';
import { PrivateRoute } from './privateRoute';

function Routers(props) {
  const {
    isNightMode, changeLoadingState, user, localeLanguage, partnerAccessIds,
  } = props;

  history.listen(() => {
    changeLoadingState(false);
  });

  const renderMultiRoutes = ({ element: Element, paths, ...rest }) => paths.map((path) => <Route key={path} path={path} {...rest} element={Element} />);

  const allRoutersList = useMemo(() => allRouters({ partnerAccessIds }), [partnerAccessIds]);

  const currentTheme = useMemo(() => ({ token: { ...theme, ...token, colors: isNightMode ? nightModeColors : lightModeColors } }), [isNightMode]);

  return (
    <HistoryRouter history={history}>
      <ConfigProvider locale={locales[localeLanguage]} theme={currentTheme}>
        <ConfigProviderOld locale={locales[localeLanguage]} theme={currentTheme}>
          <ThemeProvider>
            <LayoutProvider>
              <StyledGlobal />
              <CSSReset />
              <Routes>
                <Route exact path="/noAccessAccount" element={<NoAccessAccount />} />
                {renderMultiRoutes({
                  paths: ['/forgotPassword', '/resendTFA', '/resetPassword', '/setPassword', '/login', '/emailSent'],
                  element: <Auth theme={{ ...theme, colors: lightModeColors }} />,
                })}
                <Route path="/health/check" element={<HealthCheck />} />
                <Route
                  path="/brands/create"
                  element={(
                    <BaseLayout routers={allRoutersList}>
                      <Routes>
                        <Route
                          path="/"
                          element={(
                            <PrivateRoute
                              menuParentId={16}
                              component={CreateBrand}
                              permission={{
                                type: types.add,
                                rule: `${resource.brands}.${resource.brands}`,
                                fallback: false,
                              }}
                              componentProps={{ showPartner: { viewMode: true } }}
                              breadcrumb={[
                                {
                                  name: 'home',
                                  path: '/',
                                },
                                {
                                  name: 'brands',
                                  path: '/brands',
                                },
                                {
                                  name: 'create',
                                },
                              ]}
                              user={user}
                            />
                          )}
                        />
                      </Routes>
                    </BaseLayout>
                  )}
                />

                {/* Brand routes */}
                <Route
                  exact
                  path="/brands/:id/*"
                  element={(
                    <BrandSettingLayout routers={brandRouters} page="brand-settings">
                      <Routes>
                        {brandRouters.map((route) => (
                          <Route
                            path={route.path}
                            key={route.path}
                            element={(
                              <PrivateRoute
                                {...route}
                                componentProps={{
                                  showPartner: route.showPartner,
                                  showTimeZone: route.showTimeZone,
                                }}
                                user={user}
                              />
                            )}
                          />
                        ))}
                        <Route exact path="noAccess" element={<NoAccess />} />
                        <Route path="*" element={<NoMatch />} />
                      </Routes>
                    </BrandSettingLayout>
                  )}
                />
                {/* General routes */}
                <Route
                  path="*"
                  element={(
                    <BaseLayout routers={allRoutersList}>
                      <Routes>
                        {allRoutersList.map((route) => (
                          <Route
                            path={route.path}
                            key={route.path}
                            element={(
                              <PrivateRoute
                                {...route}
                                componentProps={{
                                  showPartner: route.showPartner,
                                  showBrand: route.showBrand,
                                  showConversion: route.showConversion,
                                  showTimeZone: route.showTimeZone,
                                }}
                                user={user}
                              />
                            )}
                          />
                        ))}
                        <Route path="/noAccess" element={<NoAccess />} />
                        <Route path="*" element={<NoMatch />} />
                      </Routes>
                    </BaseLayout>
                  )}
                />
              </Routes>
            </LayoutProvider>
          </ThemeProvider>
        </ConfigProviderOld>
      </ConfigProvider>
    </HistoryRouter>
  );
}

Routers.propTypes = {
  isNightMode: PropTypes.bool,
  changeLoadingState: PropTypes.func.isRequired,
  user: PropTypes.object,
  localeLanguage: PropTypes.string.isRequired,
  partnerAccessIds: PropTypes.array,
};
Routers.defaultProps = {
  isNightMode: false,
  user: null,
  partnerAccessIds: [],
};

export default Routers;
