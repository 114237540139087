import CampaignActionType from './campaignActionType';

export const createCampaign = (data) => ({
  type: CampaignActionType.CREATE_CAMPAIGN,
  payload: { data },
});

export const getCampaigns = (params) => ({
  type: CampaignActionType.GET_CAMPAIGNS,
  payload: { params },
});

export const saveCampaigns = (data) => ({
  type: CampaignActionType.SAVE_CAMPAIGNS,
  payload: { data },
});

export const resetCampaigns = () => ({
  type: CampaignActionType.RESET_CAMPAIGNS,
});

export const getCampaign = (id, isLoading) => ({
  type: CampaignActionType.GET_CAMPAIGN,
  payload: { id, isLoading },
});

export const saveCampaign = (data) => ({
  type: CampaignActionType.SAVE_CAMPAIGN,
  payload: { data },
});

export const resetCampaign = () => ({
  type: CampaignActionType.RESET_CAMPAIGN,
});

export const deleteCampaign = (id, data) => ({
  type: CampaignActionType.DELETE_CAMPAIGN,
  payload: { id, data },
});

export const editCampaign = (id, data) => ({
  type: CampaignActionType.EDIT_CAMPAIGN,
  payload: {
    id,
    data,
  },
});

export const getCampaignTriggerConditions = (type) => ({
  type: CampaignActionType.GET_CAMPAIGN_TRIGGER_CONDITIONS,
  payload: { type },
});

export const saveCampaignTriggerConditions = (data) => ({
  type: CampaignActionType.SAVE_CAMPAIGN_TRIGGER_CONDITIONS,
  payload: { data },
});

export const resetCampaignTriggerConditions = () => ({
  type: CampaignActionType.RESET_CAMPAIGN_TRIGGER_CONDITIONS,
});

export const getCampaignPlayers = (id, params) => ({
  type: CampaignActionType.GET_CAMPAIGN_PLAYERS,
  payload: { id, params },
});

export const saveCampaignPlayers = (data) => ({
  type: CampaignActionType.SAVE_CAMPAIGN_PLAYERS,
  payload: { data },
});

export const resetCampaignPlayers = () => ({
  type: CampaignActionType.RESET_CAMPAIGN_PLAYERS,
});

export const editCampaignPlayers = (id, data) => ({
  type: CampaignActionType.EDIT_CAMPAIGN_PLAYERS,
  payload: { id, data },
});

export const finishCampaign = (id, data) => ({
  type: CampaignActionType.FINISH_CAMPAIGN,
  payload: { id, data },
});

export const cancelCampaign = (id, data) => ({
  type: CampaignActionType.CANCEL_CAMPAIGN,
  payload: { id, data },
});

export const cancelCampaignPromotion = (id, data) => ({
  type: CampaignActionType.CANCEL_CAMPAIGN_PROMOTION,
  payload: { id, data },
});

export const getCampaignReceivedDepositStatistics = (id, params) => ({
  type: CampaignActionType.GET_CAMPAIGN_RECEIVED_DEPOSIT_STATISTICS,
  payload: { id, params },
});

export const saveCampaignReceivedDepositStatistics = (data) => ({
  type: CampaignActionType.SAVE_CAMPAIGN_RECEIVED_DEPOSIT_STATISTICS,
  payload: { data },
});

export const resetCampaignReceivedDepositStatistics = () => ({
  type: CampaignActionType.RESET_CAMPAIGN_RECEIVED_DEPOSIT_STATISTICS,
});

export const exportCampaignReceivedDepositStatistics = (id, params) => ({
  type: CampaignActionType.EXPORT_CAMPAIGN_RECEIVED_DEPOSIT_STATISTICS,
  payload: { id, params },
});

export const getCampaignFailedDepositStatistics = (id, params) => ({
  type: CampaignActionType.GET_CAMPAIGN_FAILED_DEPOSIT_STATISTICS,
  payload: { id, params },
});

export const saveCampaignFailedDepositStatistics = (data) => ({
  type: CampaignActionType.SAVE_CAMPAIGN_FAILED_DEPOSIT_STATISTICS,
  payload: { data },
});

export const resetCampaignFailedDepositStatistics = () => ({
  type: CampaignActionType.RESET_CAMPAIGN_FAILED_DEPOSIT_STATISTICS,
});

export const getCampaignReceivedVerificationStatistics = (id, params) => ({
  type: CampaignActionType.GET_CAMPAIGN_RECEIVED_VERIFICATION_STATISTICS,
  payload: { id, params },
});

export const saveCampaignReceivedVerificationStatistics = (data) => ({
  type: CampaignActionType.SAVE_CAMPAIGN_RECEIVED_VERIFICATION_STATISTICS,
  payload: { data },
});

export const resetCampaignReceivedVerificationStatistics = () => ({
  type: CampaignActionType.RESET_CAMPAIGN_RECEIVED_VERIFICATION_STATISTICS,
});

export const exportCampaignReceivedVerificationStatistics = (id, params) => ({
  type: CampaignActionType.EXPORT_CAMPAIGN_RECEIVED_VERIFICATION_STATISTICS,
  payload: { id, params },
});

export const getCampaignReceivedSignUpStatistics = (id, params) => ({
  type: CampaignActionType.GET_CAMPAIGN_RECEIVED_SIGN_UP_STATISTICS,
  payload: { id, params },
});

export const saveCampaignReceivedSignUpStatistics = (data) => ({
  type: CampaignActionType.SAVE_CAMPAIGN_RECEIVED_SIGN_UP_STATISTICS,
  payload: { data },
});

export const resetCampaignReceivedSignUpStatistics = () => ({
  type: CampaignActionType.RESET_CAMPAIGN_RECEIVED_SIGN_UP_STATISTICS,
});

export const exportCampaignReceivedSignUpStatistics = (id, params) => ({
  type: CampaignActionType.EXPORT_CAMPAIGN_RECEIVED_SIGN_UP_STATISTICS,
  payload: { id, params },
});

export const getCampaignReceivedNoTriggerStatistics = (id, params) => ({
  type: CampaignActionType.GET_CAMPAIGN_RECEIVED_NO_TRIGGER_STATISTICS,
  payload: { id, params },
});

export const saveCampaignReceivedNoTriggerStatistics = (data) => ({
  type: CampaignActionType.SAVE_CAMPAIGN_RECEIVED_NO_TRIGGER_STATISTICS,
  payload: { data },
});

export const resetCampaignReceivedNoTriggerStatistics = () => ({
  type: CampaignActionType.RESET_CAMPAIGN_RECEIVED_NO_TRIGGER_STATISTICS,
});

export const exportCampaignReceivedNoTriggerStatistics = (id, params) => ({
  type: CampaignActionType.EXPORT_CAMPAIGN_RECEIVED_NO_TRIGGER_STATISTICS,
  payload: { id, params },
});

export const createCrossPlatformCampaign = (data) => ({
  type: CampaignActionType.CREATE_CROSS_PLATFORM_CAMPAIGN,
  payload: { data },
});

export const getCrossPlatformCampaigns = (params) => ({
  type: CampaignActionType.GET_CROSS_PLATFORM_CAMPAIGNS,
  payload: { params },
});

export const saveCrossPlatformCampaigns = (data) => ({
  type: CampaignActionType.SAVE_CROSS_PLATFORM_CAMPAIGNS,
  payload: { data },
});

export const resetCrossPlatformCampaigns = () => ({
  type: CampaignActionType.RESET_CROSS_PLATFORM_CAMPAIGNS,
});

export const getCrossPlatformCampaign = (id) => ({
  type: CampaignActionType.GET_CROSS_PLATFORM_CAMPAIGN,
  payload: { id },
});

export const saveCrossPlatformCampaign = (data) => ({
  type: CampaignActionType.SAVE_CROSS_PLATFORM_CAMPAIGN,
  payload: { data },
});

export const resetCrossPlatformCampaign = () => ({
  type: CampaignActionType.RESET_CROSS_PLATFORM_CAMPAIGN,
});

export const finishCrossPlatformCampaign = (id, data) => ({
  type: CampaignActionType.FINISH_CROSS_PLATFORM_CAMPAIGN,
  payload: { id, data },
});

export const cancelCrossPlatformCampaign = (id, data) => ({
  type: CampaignActionType.CANCEL_CROSS_PLATFORM_CAMPAIGN,
  payload: { id, data },
});

export const cancelCrossPlatformCampaignPromotion = (id, data) => ({
  type: CampaignActionType.CANCEL_CROSS_PLATFORM_CAMPAIGN_PROMOTION,
  payload: { id, data },
});

export const getCrossPlatformCampaignNoTriggerStatistics = (id, params) => ({
  type: CampaignActionType.GET_CROSS_PLATFORM_CAMPAIGN_NO_TRIGGER_STATISTICS,
  payload: { id, params },
});

export const saveCrossPlatformCampaignNoTriggerStatistics = (data) => ({
  type: CampaignActionType.SAVE_CROSS_PLATFORM_CAMPAIGN_NO_TRIGGER_STATISTICS,
  payload: { data },
});

export const resetCrossPlatformCampaignNoTriggerStatistics = () => ({
  type: CampaignActionType.RESET_CROSS_PLATFORM_CAMPAIGN_NO_TRIGGER_STATISTICS,
});

export const exportCrossPlatformCampaignNoTriggerStatistics = (id, params) => ({
  type: CampaignActionType.EXPORT_CROSS_PLATFORM_CAMPAIGN_NO_TRIGGER_STATISTICS,
  payload: { id, params },
});

export const getCrossPlatformCampaignUsages = (id, params) => ({
  type: CampaignActionType.GET_CROSS_PLATFORM_CAMPAIGN_USAGES,
  payload: { id, params },
});

export const saveCrossPlatformCampaignUsages = (data) => ({
  type: CampaignActionType.SAVE_CROSS_PLATFORM_CAMPAIGN_USAGES,
  payload: { data },
});

export const resetCrossPlatformCampaignUsages = () => ({
  type: CampaignActionType.RESET_CROSS_PLATFORM_CAMPAIGN_USAGES,
});

export const getCampaignContentConfig = (id) => ({
  type: CampaignActionType.GET_CAMPAIGN_CONTENT_CONFIG,
  payload: { id },
});

export const saveCampaignContentConfig = (data) => ({
  type: CampaignActionType.SAVE_CAMPAIGN_CONTENT_CONFIG,
  payload: { data },
});

export const resetCampaignContentConfig = () => ({
  type: CampaignActionType.RESET_CAMPAIGN_CONTENT_CONFIG,
});

export const changeContentConfigLoadingState = (state) => ({
  type: CampaignActionType.CHANGE_CONTENT_CONFIG_LOADING_STATE,
  payload: { state },
});

export const editCampaignContentConfig = (id, name, data) => ({
  type: CampaignActionType.EDIT_CAMPAIGN_CONTENT_CONFIG,
  payload: {
    id,
    name,
    data,
  },
});

export const changeCampaignLoadingState = (state) => ({
  type: CampaignActionType.CHANGE_CAMPAIGN_LOADING_STATE,
  payload: { state },
});

export const getCampaignParticipantPlayers = (id) => ({
  type: CampaignActionType.GET_CAMPAIGN_PARTICIPANT_PLAYERS,
  payload: { id },
});

export const saveCampaignParticipantPlayers = (data) => ({
  type: CampaignActionType.SAVE_CAMPAIGN_PARTICIPANT_PLAYERS,
  payload: { data },
});

export const resetCampaignParticipantPlayers = () => ({
  type: CampaignActionType.RESET_CAMPAIGN_PARTICIPANT_PLAYERS,
});

export const changeCampaignPlayersLoadingState = (state) => ({
  type: CampaignActionType.CHANGE_CAMPAIGN_PLAYERS_LOADING_STATE,
  payload: { state },
});

export const getCrossPlatformCampaignContentConfig = (id) => ({
  type: CampaignActionType.GET_CROSS_PLATFORM_CONTENT_CONFIG,
  payload: { id },
});

export const saveCrossPlatformCampaignContentConfig = (data) => ({
  type: CampaignActionType.SAVE_CROSS_PLATFORM_CONTENT_CONFIG,
  payload: { data },
});

export const resetCrossPlatformCampaignContentConfig = () => ({
  type: CampaignActionType.RESET_CROSS_PLATFORM_CONTENT_CONFIG,
});

export const editCrossPlatformCampaignContentConfig = (id, name, data) => ({
  type: CampaignActionType.EDIT_CROSS_PLATFORM_CONTENT_CONFIG,
  payload: {
    id,
    name,
    data,
  },
});

export const getCampaignFailedVerificationStatistics = (id, params) => ({
  type: CampaignActionType.GET_CAMPAIGN_FAILED_VERIFICATION_STATISTICS,
  payload: { id, params },
});

export const saveCampaignFailedVerificationStatistics = (data) => ({
  type: CampaignActionType.SAVE_CAMPAIGN_FAILED_VERIFICATION_STATISTICS,
  payload: { data },
});

export const resetCampaignFailedVerificationStatistics = () => ({
  type: CampaignActionType.RESET_CAMPAIGN_FAILED_VERIFICATION_STATISTICS,
});

export const getCampaignFailedSignUpStatistics = (id, params) => ({
  type: CampaignActionType.GET_CAMPAIGN_FAILED_SIGN_UP_STATISTICS,
  payload: { id, params },
});

export const saveCampaignFailedSignUpStatistics = (data) => ({
  type: CampaignActionType.SAVE_CAMPAIGN_FAILED_SIGN_UP_STATISTICS,
  payload: { data },
});

export const resetCampaignFailedSignUpStatistics = () => ({
  type: CampaignActionType.RESET_CAMPAIGN_FAILED_SIGN_UP_STATISTICS,
});

export const getCampaignFailedNoTriggerStatistics = (id, params) => ({
  type: CampaignActionType.GET_CAMPAIGN_FAILED_NO_TRIGGER_STATISTICS,
  payload: { id, params },
});

export const saveCampaignFailedNoTriggerStatistics = (data) => ({
  type: CampaignActionType.SAVE_CAMPAIGN_FAILED_NO_TRIGGER_STATISTICS,
  payload: { data },
});

export const resetCampaignFailedNoTriggerStatistics = () => ({
  type: CampaignActionType.RESET_CAMPAIGN_FAILED_NO_TRIGGER_STATISTICS,
});

export const getCampaignReceivedSignInStatistics = (id, params) => ({
  type: CampaignActionType.GET_CAMPAIGN_RECEIVED_SIGN_IN_STATISTICS,
  payload: { id, params },
});

export const saveCampaignReceivedSignInStatistics = (data) => ({
  type: CampaignActionType.SAVE_CAMPAIGN_RECEIVED_SIGN_IN_STATISTICS,
  payload: { data },
});

export const resetCampaignReceivedSignInStatistics = () => ({
  type: CampaignActionType.RESET_CAMPAIGN_RECEIVED_SIGN_IN_STATISTICS,
});

export const exportCampaignReceivedSignInStatistics = (id, params) => ({
  type: CampaignActionType.EXPORT_CAMPAIGN_RECEIVED_SIGN_IN_STATISTICS,
  payload: { id, params },
});

export const getCampaignFailedSignInStatistics = (id, params) => ({
  type: CampaignActionType.GET_CAMPAIGN_FAILED_SIGN_IN_STATISTICS,
  payload: { id, params },
});

export const saveCampaignFailedSignInStatistics = (data) => ({
  type: CampaignActionType.SAVE_CAMPAIGN_FAILED_SIGN_IN_STATISTICS,
  payload: { data },
});

export const resetCampaignFailedSignInStatistics = () => ({
  type: CampaignActionType.RESET_CAMPAIGN_FAILED_SIGN_IN_STATISTICS,
});
