import PropTypes from 'prop-types';
import { withTheme } from 'styled-components';
import { Tooltip } from 'antd';
import IconSvg from 'components/common/icon/IconSvg';
import { StyledLabelInfo } from 'components/brand/Brand.styled';

const LabelInfo = (props) => {
  const {
    label, title, trigger, width, height,
  } = props;
  return (
    <StyledLabelInfo>
      <span>{label}</span>
      <Tooltip title={title} trigger={trigger}>
        {' '}
        <IconSvg icon="InfoIcon" width={width} height={height} />
      </Tooltip>
    </StyledLabelInfo>
  );
};

LabelInfo.propTypes = {
  label: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  trigger: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
};

LabelInfo.defaultProps = {
  trigger: 'hover',
  width: 14,
  height: 14,
};

export default withTheme(LabelInfo);
