import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { SearchableTable } from 'components/common/table';
import { changeLoadingState } from 'actions/setting/settingActionCreator';

const mapDispatchToProps = (dispatch) => bindActionCreators(
  {
    changeLoadingState,
  },
  dispatch,
);

export default connect(null, mapDispatchToProps)(SearchableTable);
