export default {
  en: {
    detectionArea: 'Detection Area',
    sessionCount: 'Session Count',
    adjustmentId: 'Adjustment ID',
    totalPersonas: 'Total Personas',
    personasWithIncorrectData: 'Personas with incorrect data',
    uploadCsvForBlacklist: 'You can upload list of personas to be blacklisted. Only CSV format is acceptable',
    groupId: 'Group ID',
    chooseCriteria: 'Choose Criteria',
    balanceAdjustmentInfo: 'Please use these IDs in case of CSV upload, so that system can identify the balance:',
    expirationPeriodHour: 'Expiration Period (hour)',
    roundFinish: 'Round Finish',
    roundCreationDate: 'Round Creation Date',
    correctionDate: 'Correction Date',
    betCorrection: 'Bet Correction',
    correctionStatus: 'Correction Status',
    correctionAmount: 'Correction Amount',
    roundStatus: 'Round Status',
    statusCorrection: 'Status Correction',
    failedCorrections: 'Failed Corrections',
    externalTransactionID: 'External Transaction ID',
    failReason: 'Fail Reason',
    blockedPaymentMethodsList: 'Blocked Payment Methods list',
    blockedPaymentMethodPlayers: 'Blocked Payment Method Players',
  },
  es: {
    detectionArea: 'Zona de detección',
    sessionCount: 'Recuento de sesiones',
    adjustmentId: 'ID del ajuste',
    totalPersonas: 'Personajes totales',
    personasWithIncorrectData: 'Personajes con datos incorrectos',
    uploadCsvForBlacklist: 'Puede cargar la lista de personajes que deben incluirse en la lista de no admitidos. Solo se acepta el formato CSV',
    groupId: 'ID de grupo',
    chooseCriteria: 'Elegir criterios',
    balanceAdjustmentInfo: 'Utilice estos ID en caso de carga de CSV, para que el sistema pueda identificar el saldo:',
    expirationPeriodHour: 'Periodo de Caducidad (hora)',
    roundFinish: 'Final de la Ronda ',
    roundCreationDate: 'Fecha de la Creacion de la Ronda',
    correctionDate: 'Fecha de Rectificación',
    betCorrection: 'Corrección de Apuestas',
    correctionStatus: 'Estado de Corrección',
    correctionAmount: 'Importe de la Correción ',
    roundStatus: 'Estado de la Ronda',
    statusCorrection: 'Corrección de Estado',
    failedCorrections: 'Correcciones Fallidas',
    externalTransactionID: 'ID de Transacción Externa',
    failReason: 'Motivo del Error',
  },
  tr: {
    detectionArea: 'Tespit Etme Alanı',
    sessionCount: 'Oturum Sayısı',
    adjustmentId: 'Ayar ID',
    totalPersonas: 'Toplam Personalar',
    personasWithIncorrectData: 'Yanlış verili personalar',
    uploadCsvForBlacklist: 'Kara listeye alınacak personaların listesini yükleyebilirsin. Yalnızca CSV formatı kabul edilir',
    groupId: 'Grup ID',
    chooseCriteria: 'Kriterleri seçin',
    balanceAdjustmentInfo: "Sistemin bakiyeyi tanımlayabilmesi için lütfen CSV yüklemesi durumunda bu ID'leri kullanın:",
    expirationPeriodHour: 'Sona Erme Süresi (saat)',
    roundFinish: 'Raund Sonu ',
    roundCreationDate: 'Raund Oluşturma Tarihi',
    correctionDate: 'Düzeltme Tarihi',
    betCorrection: 'Bahis Düzeltme',
    correctionStatus: 'Düzeltme Durumu',
    correctionAmount: 'Düzeltme Tutarı',
    roundStatus: 'Raund Durumu',
    statusCorrection: 'Durum Düzeltme',
    failedCorrections: 'Başarısız Düzeltmeler',
    externalTransactionID: 'Harici İşlem ID',
    failReason: 'Başarısızlık Nedeni',
  },
  ru: {
    detectionArea: 'Зона обнаружения',
    sessionCount: 'Количество сессий',
    adjustmentId: 'ID корректировки',
    totalPersonas: 'Всего пользователей',
    personasWithIncorrectData: 'Пользователи с неверными данными',
    uploadCsvForBlacklist: 'Вы можете загрузить список людей, которые должны быть внесены в черный список. Допускается только формат CSV',
    groupId: 'ID группы',
    chooseCriteria: 'Выбрать критерии',
    balanceAdjustmentInfo: 'Пожалуйста, используйте эти идентификаторы при загрузке CSV, чтобы система могла определить баланс:',
    expirationPeriodHour: 'Срок действия (час)',
    roundFinish: 'Финиш раунда',
    roundCreationDate: 'Дата создания раунда',
    correctionDate: 'Дата корректировки',
    betCorrection: 'Корректировка ставки',
    correctionStatus: 'Статус корректировки',
    correctionAmount: 'Сумма корректировки',
    roundStatus: 'Статус раунда',
    statusCorrection: 'Корретировка статуса',
    failedCorrections: 'Неудачная корректировка',
    externalTransactionID: 'ID внешней транзакции',
    failReason: 'Причина неудачи',
  },
  pt: {
    detectionArea: 'Área de Detecção',
    sessionCount: 'Contagem de Sessão',
    adjustmentId: 'ID de Ajuste',
    totalPersonas: 'Total de Personas',
    personasWithIncorrectData: 'Personas com dados incorretos',
    uploadCsvForBlacklist: 'Pode carregar a lista de personas a serem incluídas na lista de proibidos. É aceite apenas o formato CSV',
    groupId: 'ID do Grupo',
    chooseCriteria: 'Escolha o Critério',
    balanceAdjustmentInfo: 'Use estes IDs no caso de upload de CSV, para que o sistema possa identificar o saldo:',
    expirationPeriodHour: 'Período de expiração (hora)',
    roundFinish: 'Termino da Ronda',
    roundCreationDate: 'Data de criação da ronda',
    correctionDate: 'Data de correção',
    betCorrection: 'Correção da aposta',
    correctionStatus: 'Estado de correção',
    correctionAmount: 'Montante da correção',
    roundStatus: 'Estado da ronda',
    statusCorrection: 'Correção do estado',
    failedCorrections: 'Correcções falhadas',
    externalTransactionID: 'ID da transação externa',
    failReason: 'Motivo da falha',
  },
  zh: {
    detectionArea: '探测区域',
    sessionCount: '专场数',
    adjustmentId: '调整ID',
    totalPersonas: '用户总数',
    personasWithIncorrectData: '数据有误的用户',
    uploadCsvForBlacklist: '您可以上传要列入黑名单的用户。我们仅接受CSV格式',
    groupId: '小组ID',
    chooseCriteria: '选择标准',
    balanceAdjustmentInfo: '上传CSV时请使用这些ID，以便系统识别余额：',
    expirationPeriodHour: '过期时间（小时）',
    roundFinish: '回合结束',
    roundCreationDate: '回合创建日期',
    correctionDate: '更正日期',
    betCorrection: '投注更正',
    correctionStatus: '更正状态',
    correctionAmount: '更正数额',
    roundStatus: '回合状态',
    statusCorrection: '状态更正',
    failedCorrections: '失败的更正',
    externalTransactionID: '外部交易ID',
  },
  ro: {
    detectionArea: 'Secțiune de detectare',
    sessionCount: 'Numărul de sesiuni',
    adjustmentId: 'ID de ajustare',
    totalPersonas: 'Nr. total de utilizatori',
    personasWithIncorrectData: 'Utilizatori cu date incorecte',
    uploadCsvForBlacklist: 'Poți încărca lista de utilizatori, care urmează să fie incluse pe lista neagră. Doar formatul CSV este acceptabil',
    groupId: 'ID de grup',
    chooseCriteria: 'Alege criterii',
    balanceAdjustmentInfo: 'Te rugăm să utilizezi aceste ID-uri în cazul încărcării CSV, ca sistemul să poată identifica balanța:',
  },
  bg: {},
  el: {
    detectionArea: 'Περιοχή εντοπισμού',
    sessionCount: 'Αριθμός συνεδριών',
    adjustmentId: 'Αναγνωριστικό προσαρμογής',
    totalPersonas: 'Συνολικά άτομα',
    personasWithIncorrectData: 'Άτομα με λάθος δεδομένα',
    uploadCsvForBlacklist: 'Μπορείτε να μεταφορτώσετε λίστα των ατόμων που θα μπουν στη λίστα αποκλεισμένων. Μόνο η μορφή CSV είναι αποδεκτή',
    groupId: 'Αναγνωριστικό ομάδας',
    chooseCriteria: 'Επιλογή κριτηρίων',
    balanceAdjustmentInfo: 'Χρησιμοποιήστε αυτά τα Αναγνωριστικά σε περίπτωση μεταφόρτωσης αρχείου CVS, ώστε το σύστημα να μπορεί να εντοπίσει το υπόλοιπο:',
    expirationPeriodHour: 'Περίοδος λήξης (ώρα)',
  },
};
