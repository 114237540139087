import GameSegmentActionType from 'actions/gameSegment/gameSegmentActionType';

const initialState = {
  segmentGames: {
    data: [],
    total: 0,
  },
  gameSegment: {},
  gamesSegments: {},
};

const gameSegmentReducer = (state = initialState, { type, payload }) => {
  switch (type) {
  case GameSegmentActionType.SAVE_GAMES_SEGMENTS:
    return {
      ...state,
      gamesSegments: payload.data,
    };
  case GameSegmentActionType.SAVE_SEGMENT_GAMES:
    return {
      ...state,
      segmentGames: payload.data,
    };
  case GameSegmentActionType.RESET_SEGMENT_GAMES:
    return {
      ...state,
      segmentGames: {},
    };
  case GameSegmentActionType.RESET_GAMES_SEGMENTS:
    return {
      ...state,
      gamesSegments: {},
    };
  case GameSegmentActionType.SAVE_GAME_SEGMENT:
    return {
      ...state,
      gameSegment: payload.data,
    };
  case GameSegmentActionType.RESET_GAME_SEGMENT:
    return {
      ...state,
      gameSegment: {},
    };
  default:
    return state;
  }
};

export default gameSegmentReducer;
