import { Popover } from 'antd';
import PropTypes from 'prop-types';
import { IconSvg } from 'components/common';
import {
  StyledMoreWrapper, StyledMoreItem, StyledPopoverContentItem, StyledPopoverItem,
} from 'components/styledComponents/button/Button.styled';
import { StyledMoreIcon, StyledIconHover } from 'components/styledComponents/icon/Icon.styled';

const More = (props) => {
  const {
    data, noPadding, allowFullWidth, allowTruncate,
  } = props;

  if (!data) return null;
  const { length } = data;

  switch (length) {
  case 0:
    return null;
  case 1:
    return (
      <StyledMoreWrapper>
        <Popover content={<StyledPopoverContentItem>{data[0].name}</StyledPopoverContentItem>} overlayClassName="description-popover">
          <StyledPopoverItem noPadding allowFullWidth={allowFullWidth ? 1 : 0} allowTruncate={allowTruncate ? 1 : 0}>
            {data[0].displayName || data[0].name}
          </StyledPopoverItem>
        </Popover>
      </StyledMoreWrapper>
    );
  default:
    return (
      <StyledMoreWrapper>
        <StyledPopoverItem noPadding={noPadding ? 1 : 0} allowTruncate={allowTruncate ? 1 : 0}>
          {data[0].displayName || data[0].name}
        </StyledPopoverItem>
        <Popover
          placement="bottomRight"
          content={data.map((item) => (
            <StyledMoreItem key={item.id}>{item.name}</StyledMoreItem>
          ))}
          overlayClassName="more-popover"
        >
          {' '}
          <StyledIconHover>
            <StyledMoreIcon>
              <IconSvg icon="MoreIcon" width="0.71rem" height="0.71rem" />
            </StyledMoreIcon>
          </StyledIconHover>
        </Popover>
      </StyledMoreWrapper>
    );
  }
};

More.propTypes = {
  data: PropTypes.array,
  noPadding: PropTypes.bool,
  allowFullWidth: PropTypes.bool,
  allowTruncate: PropTypes.bool,
};
More.defaultProps = {
  data: null,
  noPadding: false,
  allowFullWidth: false,
  allowTruncate: true,
};

export default More;
