const PreviousAccountActionType = {
  GET_PREVIOUS_ACCOUNTS_GROUPS: 'GET_PREVIOUS_ACCOUNTS_GROUPS',
  SAVE_PREVIOUS_ACCOUNTS_GROUPS: 'SAVE_PREVIOUS_ACCOUNTS_GROUPS',
  RESET_PREVIOUS_ACCOUNTS_GROUPS: 'RESET_PREVIOUS_ACCOUNTS_GROUPS',

  GET_PLAYER_PREVIOUS_ACCOUNTS: 'GET_PLAYER_PREVIOUS_ACCOUNTS',
  SAVE_PLAYER_PREVIOUS_ACCOUNTS: 'SAVE_PLAYER_PREVIOUS_ACCOUNTS',
  RESET_PLAYER_PREVIOUS_ACCOUNTS: 'RESET_PLAYER_PREVIOUS_ACCOUNTS',

  GET_PLAYER_PREVIOUS_ACCOUNTS_INFO: 'GET_PLAYER_PREVIOUS_ACCOUNTS_INFO',
  SAVE_PLAYER_PREVIOUS_ACCOUNTS_INFO: 'SAVE_PLAYER_PREVIOUS_ACCOUNTS_INFO',
  RESET_PLAYER_PREVIOUS_ACCOUNTS_INFO: 'RESET_PLAYER_PREVIOUS_ACCOUNTS_INFO',
};

export default PreviousAccountActionType;
