import IpWhitelistActionType from 'actions/ipWhitelist/ipWhitelistActionType';

const initialState = {
  ipWhitelist: {
    data: [],
    total: 0,
  },
};

const ipWhitelistReducer = (state = initialState, { type, payload }) => {
  switch (type) {
  case IpWhitelistActionType.SAVE_IP_WHITELIST:
    return {
      ...state,
      ipWhitelist: payload.data,
    };
  case IpWhitelistActionType.RESET_IP_WHITELIST:
    return {
      ...state,
      ipWhitelist: {
        data: [],
        total: 0,
      },
    };
  default:
    return state;
  }
};

export default ipWhitelistReducer;
