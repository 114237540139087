const KycActionType = {
  GET_KYC_REPORT: 'GET_KYC_REPORT',
  SAVE_KYC_REPORT: 'SAVE_KYC_REPORT',
  RESET_KYC_REPORT: 'RESET_KYC_REPORT',

  GET_PLAYER_ATTACHMENTS: 'GET_PLAYER_ATTACHMENTS',
  SAVE_PLAYER_ATTACHMENTS: 'SAVE_PLAYER_ATTACHMENTS',
  REJECT_PLAYER_ATTACHMENT: 'REJECT_PLAYER_ATTACHMENT',
  APPROVE_PLAYER_ATTACHMENT: 'APPROVE_PLAYER_ATTACHMENT',
  ADD_PLAYER_ATTACHMENT: 'ADD_PLAYER_ATTACHMENT',
  ARCHIVE_PLAYER_ATTACHMENT: 'ARCHIVE_PLAYER_ATTACHMENT',
  VERIFY_PLAYER_ATTACHMENT: 'VERIFY_PLAYER_ATTACHMENT',
  GET_PLAYER_ATTACHMENTS_HISTORY: 'GET_PLAYER_ATTACHMENTS_HISTORY',
  SAVE_PLAYER_ATTACHMENTS_HISTORY: 'SAVE_PLAYER_ATTACHMENTS_HISTORY',
  RESET_PLAYER_ATTACHMENTS_HISTORY: 'RESET_PLAYER_ATTACHMENTS_HISTORY',
  UPDATE_PLAYER_ATTACHMENT: 'UPDATE_PLAYER_ATTACHMENT',
  RESET_PLAYER_ATTACHMENTS: 'RESET_PLAYER_ATTACHMENTS',
  START_PROCESS: 'START_PROCESS',
  MOVE_PLAYER_ATTACHMENT: 'MOVE_PLAYER_ATTACHMENT',
  EDIT_AND_APPROVE_PLAYER_ATTACHMENT: 'EDIT_AND_APPROVE_PLAYER_ATTACHMENT',
};

export default KycActionType;
