import PromoCodeActionType from 'actions/promoCode/promoCodeActionType';

const initialState = {
  promoCodesPacks: {
    data: [],
    total: 0,
  },
  promoCode: {},
  usedPromoCodes: {
    data: [],
    total: 0,
  },
  isPromoCodeExists: null,
  promoCodes: [],
  csvPromoCodes: [],
  endedStatus: false,
};

const promoCodeReducer = (state = initialState, { type, payload }) => {
  switch (type) {
  case PromoCodeActionType.SAVE_PROMO_CODES_PACKS:
    return {
      ...state,
      promoCodesPacks: payload.data,
    };
  case PromoCodeActionType.RESET_PROMO_CODES_PACKS:
    return {
      ...state,
      promoCodesPacks: {
        data: [],
        total: 0,
      },
    };
  case PromoCodeActionType.SAVE_PROMO_CODE:
    return {
      ...state,
      promoCode: payload.data,
    };
  case PromoCodeActionType.RESET_PROMO_CODE:
    return {
      ...state,
      promoCode: {},
    };
  case PromoCodeActionType.SAVE_USED_PROMO_CODES:
    return {
      ...state,
      usedPromoCodes: payload.data,
    };
  case PromoCodeActionType.RESET_USED_PROMO_CODES: {
    return {
      ...state,
      usedPromoCodes: {
        data: [],
        total: 0,
      },
    };
  }
  case PromoCodeActionType.SAVE_IS_PROMO_CODE_EXISTS:
    return {
      ...state,
      isPromoCodeExists: payload.data,
    };
  case PromoCodeActionType.RESET_IS_PROMO_CODE_EXISTS: {
    return {
      ...state,
      isPromoCodeExists: null,
    };
  }
  case PromoCodeActionType.SAVE_PROMO_CODES:
    return {
      ...state,
      promoCodes: payload.data,
    };
  case PromoCodeActionType.RESET_PROMO_CODES: {
    return {
      ...state,
      promoCodes: [],
    };
  }
  case PromoCodeActionType.SAVE_CSV_PROMO_CODES:
    return {
      ...state,
      csvPromoCodes: payload.data,
    };
  case PromoCodeActionType.RESET_CSV_PROMO_CODES:
    return {
      ...state,
      csvPromoCodes: [],
    };
  case PromoCodeActionType.CHANGE_PROMO_CODE_ENDED_STATUS:
    return {
      ...state,
      endedStatus: payload.status,
    };
  default:
    return state;
  }
};

export default promoCodeReducer;
