import OverviewActionType from './overviewActionType';

export const getPlayerOverviewFinancial = (id) => ({
  type: OverviewActionType.GET_PLAYER_OVERVIEW_FINANCIAL,
  payload: { id },
});

export const savePlayerOverviewFinancial = (data) => ({
  type: OverviewActionType.SAVE_PLAYER_OVERVIEW_FINANCIAL,
  payload: { data },
});

export const resetPlayerOverviewFinancial = () => ({
  type: OverviewActionType.RESET_PLAYER_OVERVIEW_FINANCIAL,
});

export const changePlayerOverviewFinancialLoadingState = (state) => ({
  type: OverviewActionType.CHANGE_PLAYER_OVERVIEW_FINANCIAL_LOADING_STATE,
  payload: { state },
});

export const getPlayerOverviewActivities = (id) => ({
  type: OverviewActionType.GET_PLAYER_OVERVIEW_ACTIVITIES,
  payload: { id },
});

export const savePlayerOverviewActivities = (data) => ({
  type: OverviewActionType.SAVE_PLAYER_OVERVIEW_ACTIVITIES,
  payload: { data },
});

export const resetPlayerOverviewActivities = () => ({
  type: OverviewActionType.RESET_PLAYER_OVERVIEW_ACTIVITIES,
});

export const changePlayerOverviewActivitiesLoadingState = (state) => ({
  type: OverviewActionType.CHANGE_PLAYER_OVERVIEW_ACTIVITIES_LOADING_STATE,
  payload: { state },
});

export const getPlayerOverviewRevenues = (id, params) => ({
  type: OverviewActionType.GET_PLAYER_OVERVIEW_REVENUES,
  payload: { id, params },
});

export const savePlayerOverviewRevenues = (data) => ({
  type: OverviewActionType.SAVE_PLAYER_OVERVIEW_REVENUES,
  payload: { data },
});

export const resetPlayerOverviewRevenues = () => ({
  type: OverviewActionType.RESET_PLAYER_OVERVIEW_REVENUES,
});

export const changePlayerOverviewRevenuesLoadingState = (state) => ({
  type: OverviewActionType.CHANGE_PLAYER_OVERVIEW_REVENUES_LOADING_STATE,
  payload: { state },
});

export const getPlayerOverviewLimitsCheck = (id, isLoading) => ({
  type: OverviewActionType.GET_PLAYER_OVERVIEW_LIMITS_CHECK,
  payload: { id, isLoading },
});

export const savePlayerOverviewLimitsCheck = (data) => ({
  type: OverviewActionType.SAVE_PLAYER_OVERVIEW_LIMITS_CHECK,
  payload: { data },
});

export const resetPlayerOverviewLimitsCheck = () => ({
  type: OverviewActionType.RESET_PLAYER_OVERVIEW_LIMITS_CHECK,
});

export const changePlayerOverviewVerticalTagsLoadingState = (state) => ({
  type: OverviewActionType.CHANGE_PLAYER_OVERVIEW_VERTICAL_TAGS_LOADING_STATE,
  payload: { state },
});

export const getPlayerOverviewVerticalTags = (id, params) => ({
  type: OverviewActionType.GET_PLAYER_OVERVIEW_VERTICAL_TAGS,
  payload: { id, params },
});

export const savePlayerOverviewVerticalTags = (data) => ({
  type: OverviewActionType.SAVE_PLAYER_OVERVIEW_VERTICAL_TAGS,
  payload: { data },
});

export const resetPlayerOverviewVerticalTags = () => ({
  type: OverviewActionType.RESET_PLAYER_OVERVIEW_VERTICAL_TAGS,
});

export const getPlayerOverviewCasinoBonuses = (params) => ({
  type: OverviewActionType.GET_PLAYER_OVERVIEW_CASINO_BONUSES,
  payload: { params },
});

export const savePlayerOverviewCasinoBonuses = (data) => ({
  type: OverviewActionType.SAVE_PLAYER_OVERVIEW_CASINO_BONUSES,
  payload: { data },
});

export const resetPlayerOverviewCasinoBonuses = () => ({
  type: OverviewActionType.RESET_PLAYER_OVERVIEW_CASINO_BONUSES,
});

export const getPlayerOverviewSportBonuses = (params) => ({
  type: OverviewActionType.GET_PLAYER_OVERVIEW_SPORT_BONUSES,
  payload: { params },
});

export const savePlayerOverviewSportBonus = (data) => ({
  type: OverviewActionType.SAVE_PLAYER_OVERVIEW_SPORT_BONUSES,
  payload: { data },
});

export const resetPlayerOverviewSportBonuses = () => ({
  type: OverviewActionType.RESET_PLAYER_OVERVIEW_SPORT_BONUSES,
});

export const changePlayerOverviewBonusesLoadingState = (state) => ({
  type: OverviewActionType.CHANGE_PLAYER_OVERVIEW_BONUSES_LOADING_STATE,
  payload: { state },
});

export const getPlayerOverviewPayments = (params) => ({
  type: OverviewActionType.GET_PLAYER_OVERVIEW_PAYMENTS,
  payload: { params },
});

export const savePlayerOverviewPayments = (data) => ({
  type: OverviewActionType.SAVE_PLAYER_OVERVIEW_PAYMENTS,
  payload: { data },
});

export const resetPlayerOverviewPayments = () => ({
  type: OverviewActionType.RESET_PLAYER_OVERVIEW_PAYMENTS,
});

export const changePlayerOverviewPaymentsLoadingState = (state) => ({
  type: OverviewActionType.CHANGE_PLAYER_OVERVIEW_PAYMENTS_LOADING_STATE,
  payload: { state },
});

export const getPlayerOverviewCasinoIndicators = (playerId) => ({
  type: OverviewActionType.GET_PLAYER_OVERVIEW_CASINO_INDICATORS,
  payload: { playerId },
});

export const savePlayerOverviewCasinoIndicators = (data) => ({
  type: OverviewActionType.SAVE_PLAYER_OVERVIEW_CASINO_INDICATORS,
  payload: { data },
});

export const resetPlayerOverviewCasinoIndicators = () => ({
  type: OverviewActionType.RESET_PLAYER_OVERVIEW_CASINO_INDICATORS,
});

export const getPlayerOverviewSportIndicators = (playerId) => ({
  type: OverviewActionType.GET_PLAYER_OVERVIEW_SPORT_INDICATORS,
  payload: { playerId },
});

export const savePlayerOverviewSportIndicators = (data) => ({
  type: OverviewActionType.SAVE_PLAYER_OVERVIEW_SPORT_INDICATORS,
  payload: { data },
});

export const resetPlayerOverviewSportIndicators = () => ({
  type: OverviewActionType.RESET_PLAYER_OVERVIEW_SPORT_INDICATORS,
});

export const changePlayerOverviewIndicatorsLoadingState = (state) => ({
  type: OverviewActionType.CHANGE_PLAYER_OVERVIEW_INDICATORS_LOADING_STATE,
  payload: { state },
});
