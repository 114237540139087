import {
  put, all, call, takeLatest,
} from 'redux-saga/effects';
import qs from 'qs';
import { NetworkService, i18n } from 'services';
import {
  changeEndedStatus, changeLoadingState, setErrorMessage, setSuccessMessage,
} from 'actions/setting/settingActionCreator';
import { AppConstants } from 'constants/index';
import { saveKycReport, savePlayerAttachments, savePlayerAttachmentsHistory } from 'actions/kyc/kycActionCreator';
import { changePlayerDataUpdatedStatus, changePlayerPersonalInfoUpdatedStatus } from 'actions/player/playerActionCreator';
import KycActionType from 'actions/kyc/kycActionType';
import { getMessage, getError, getResourceValue } from 'utils/helpers';
import enumTypes from 'constants/enumTypes';

function* getKycReport({ payload }) {
  try {
    const { params } = payload;
    const options = {
      params,
      paramsSerializer: (param) => qs.stringify(param),
    };
    const { ReportingAdmin, KYC, Report } = AppConstants.api;
    yield put(changeLoadingState(true));
    const { data } = yield call(NetworkService.makeAPIGetRequest, [ReportingAdmin, KYC, Report], options);
    yield put(changeLoadingState(false));
    yield put(saveKycReport(data));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getPlayerAttachments({ payload }) {
  try {
    const { playerId, params } = payload;
    const options = {
      params,
    };
    const { PlayersAdmin, KYC } = AppConstants.api;
    yield put(changeLoadingState(true));
    const { data } = yield call(NetworkService.makeAPIGetRequest, [PlayersAdmin, KYC, playerId], options);
    yield put(savePlayerAttachments(data));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* rejectPlayerAttachment({ payload }) {
  try {
    const { data } = payload;
    const options = {
      data,
    };
    const { PlayersAdmin, KYC, Reject } = AppConstants.api;
    yield put(changeLoadingState(true));
    yield call(NetworkService.makeAPIPostRequest, [PlayersAdmin, KYC, Reject], options);
    yield put(changeLoadingState(false));
    yield put(changeEndedStatus(true));
    yield put(changePlayerDataUpdatedStatus(true));
    yield put(changePlayerPersonalInfoUpdatedStatus(true));
    yield put(setSuccessMessage(getMessage(i18n.t('player:documents'), i18n.t('notification:plRejected'))));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* verifyPlayerAttachment({ payload }) {
  try {
    const { id, type, option } = payload;
    const { PlayersAdmin, KYC, Verify } = AppConstants.api;
    yield put(changeLoadingState(true));
    yield call(NetworkService.makeAPIPostRequest, [PlayersAdmin, KYC, Verify, id]);
    yield put(changeLoadingState(false));
    yield put(changeEndedStatus(true));
    yield put(changePlayerDataUpdatedStatus(true));
    yield put(changePlayerPersonalInfoUpdatedStatus(true));
    if (option) {
      yield put(setSuccessMessage(i18n.t('notification:playerAccountVerifiedWith', { docType: getResourceValue('kycDocumentOptions', option, enumTypes.kycDocumentOptions) })));
    } else if (type) {
      yield put(setSuccessMessage(getMessage(getResourceValue('kycDocumentTypes', type, enumTypes.kycDocumentTypesy), i18n.t('notification:plVerified'))));
    }
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* approvePlayerAttachment({ payload }) {
  try {
    const { data } = payload;
    const options = {
      data,
    };
    const { PlayersAdmin, KYC, Approve } = AppConstants.api;
    yield put(changeLoadingState(true));
    yield call(NetworkService.makeAPIPostRequest, [PlayersAdmin, KYC, Approve], options);
    yield put(changeLoadingState(false));
    yield put(setSuccessMessage(getMessage(i18n.t('player:documents'), i18n.t('notification:plApproved'))));
    yield put(changeEndedStatus(true));
    yield put(changePlayerDataUpdatedStatus(true));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* addPlayerAttachment({ payload }) {
  try {
    const { data } = payload;
    const options = {
      data,
      headers: { 'content-type': 'multipart/form-data' },
    };
    const { PlayersAdmin, KYC } = AppConstants.api;
    yield put(changeLoadingState(true));
    yield call(NetworkService.makeAPIPostRequest, [PlayersAdmin, KYC], options);
    yield put(changeLoadingState(false));
    yield put(setSuccessMessage(getMessage(i18n.t('player:documents'), i18n.t('notification:plUploaded'))));
    yield put(changeEndedStatus(true));
    yield put(changePlayerDataUpdatedStatus(true));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* archivePlayerAttachment({ payload }) {
  try {
    const { data } = payload;
    const options = {
      data,
    };
    const { PlayersAdmin, KYC, Archiv } = AppConstants.api;
    yield put(changeLoadingState(true));
    yield call(NetworkService.makeAPIPostRequest, [PlayersAdmin, KYC, Archiv], options);
    yield put(changeLoadingState(false));
    yield put(changeEndedStatus(true));
    yield put(changePlayerDataUpdatedStatus(true));
    yield put(changePlayerPersonalInfoUpdatedStatus(true));
    yield put(setSuccessMessage(getMessage(i18n.t('player:documents'), i18n.t('notification:plArchived'))));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getPlayerAttachmentsHistory({ payload }) {
  try {
    const { playerId, params } = payload;
    const options = {
      params,
    };
    const { ReportingAdmin, KYC, History } = AppConstants.api;
    yield put(changeLoadingState(true));
    const { data } = yield call(NetworkService.makeAPIGetRequest, [ReportingAdmin, KYC, History, playerId], options);
    yield put(savePlayerAttachmentsHistory(data));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* updatePlayerAttachment({ payload }) {
  try {
    const { data } = payload;
    const options = {
      data,
    };
    const { PlayersAdmin, KYC } = AppConstants.api;
    yield put(changeLoadingState(true));
    yield call(NetworkService.makeAPIPutRequest, [PlayersAdmin, KYC], options);
    yield put(changeLoadingState(false));
    yield put(changeEndedStatus(true));
    yield put(changePlayerDataUpdatedStatus(true));
    yield put(changePlayerPersonalInfoUpdatedStatus(true));
    yield put(setSuccessMessage(getMessage(i18n.t('player:documents'), i18n.t('notification:plUpdated'))));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* startProcess({ payload }) {
  try {
    const { id } = payload;
    const { PlayersAdmin, KYC, StartProgress } = AppConstants.api;
    yield put(changeLoadingState(true));
    yield call(NetworkService.makeAPIPostRequest, [PlayersAdmin, KYC, StartProgress, id]);
    yield put(changeLoadingState(false));
    yield put(changeEndedStatus(true));
    yield put(changePlayerDataUpdatedStatus(true));
    yield put(setSuccessMessage(getMessage(i18n.t('player:process'), i18n.t('notification:slStarted'))));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* movePlayerAttachment({ payload }) {
  try {
    const { id, data } = payload;
    const options = {
      data,
    };
    const { PlayersAdmin, KYC, Move } = AppConstants.api;
    yield put(changeLoadingState(true));
    yield call(NetworkService.makeAPIPostRequest, [PlayersAdmin, KYC, Move, id], options);
    yield put(changeLoadingState(false));
    yield put(changeEndedStatus(true));
    yield put(setSuccessMessage(getMessage(i18n.t('player:documents'), i18n.t('notification:plMoved'))));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* editAndApprovePlayerAttachment({ payload }) {
  try {
    const { id, data } = payload;
    const options = {
      data,
    };
    const { PlayersAdmin, KYC, Approve } = AppConstants.api;
    yield put(changeLoadingState(true));
    yield call(NetworkService.makeAPIPostRequest, [PlayersAdmin, KYC, Approve, id], options);
    yield put(changeLoadingState(false));
    yield put(setSuccessMessage(getMessage(i18n.t('player:documents'), i18n.t('notification:plApproved'))));
    yield put(changeEndedStatus(true));
    yield put(changePlayerDataUpdatedStatus(true));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

export default function* kycSaga() {
  yield all([
    takeLatest(KycActionType.GET_KYC_REPORT, getKycReport),
    takeLatest(KycActionType.GET_PLAYER_ATTACHMENTS, getPlayerAttachments),
    takeLatest(KycActionType.REJECT_PLAYER_ATTACHMENT, rejectPlayerAttachment),
    takeLatest(KycActionType.APPROVE_PLAYER_ATTACHMENT, approvePlayerAttachment),
    takeLatest(KycActionType.ADD_PLAYER_ATTACHMENT, addPlayerAttachment),
    takeLatest(KycActionType.ARCHIVE_PLAYER_ATTACHMENT, archivePlayerAttachment),
    takeLatest(KycActionType.GET_PLAYER_ATTACHMENTS_HISTORY, getPlayerAttachmentsHistory),
    takeLatest(KycActionType.UPDATE_PLAYER_ATTACHMENT, updatePlayerAttachment),
    takeLatest(KycActionType.VERIFY_PLAYER_ATTACHMENT, verifyPlayerAttachment),
    takeLatest(KycActionType.START_PROCESS, startProcess),
    takeLatest(KycActionType.MOVE_PLAYER_ATTACHMENT, movePlayerAttachment),
    takeLatest(KycActionType.EDIT_AND_APPROVE_PLAYER_ATTACHMENT, editAndApprovePlayerAttachment),
  ]);
}
