import { Layout } from 'antd';
import { NoMatchContent } from 'components/common';

const NoMatch = () => (
  <Layout>
    <NoMatchContent />
  </Layout>
);

export default NoMatch;
