const CashierActionType = {
  GET_CASHIER_INVENTORY: 'GET_CASHIER_INVENTORY',
  SAVE_CASHIER_INVENTORY: 'SAVE_CASHIER_INVENTORY',
  RESET_CASHIER_INVENTORY: 'RESET_CASHIER_INVENTORY',

  EDIT_CASHIER_INVENTORY: 'EDIT_CASHIER_INVENTORY',

  GET_CASHIER_DEPOSIT: 'GET_CASHIER_DEPOSIT',
  SAVE_CASHIER_DEPOSIT: 'SAVE_CASHIER_DEPOSIT',
  RESET_CASHIER_DEPOSIT: 'RESET_CASHIER_DEPOSIT',

  DELETE_CASHIER_DEPOSIT: 'DELETE_CASHIER_DEPOSIT',
  CREATE_CASHIER_DEPOSIT: 'CREATE_CASHIER_DEPOSIT',
  EDIT_CASHIER_DEPOSIT: 'EDIT_CASHIER_DEPOSIT',

  REORDER_CASHIER_DEPOSIT: 'REORDER_CASHIER_DEPOSIT',

  GET_DEPOSIT_PAYMENT_METHODS_RESOURCE: 'GET_DEPOSIT_PAYMENT_METHODS_RESOURCE',
  SAVE_DEPOSIT_PAYMENT_METHODS_RESOURCE: 'SAVE_DEPOSIT_PAYMENT_METHODS_RESOURCE',
  RESET_DEPOSIT_PAYMENT_METHODS_RESOURCE: 'RESET_DEPOSIT_PAYMENT_METHODS_RESOURCE',

  GET_CASHIER_DEPOSIT_CATEGORY: 'GET_CASHIER_DEPOSIT_CATEGORY',
  SAVE_CASHIER_DEPOSIT_CATEGORY: 'SAVE_CASHIER_DEPOSIT_CATEGORY',
  RESET_CASHIER_DEPOSIT_CATEGORY: 'RESET_CASHIER_DEPOSIT_CATEGORY',

  GET_CASHIER_WITHDRAWAL: 'GET_CASHIER_WITHDRAWAL',
  SAVE_CASHIER_WITHDRAWAL: 'SAVE_CASHIER_WITHDRAWAL',
  RESET_CASHIER_WITHDRAWAL: 'RESET_CASHIER_WITHDRAWAL',

  GET_WITHDRAWAL_PAYMENT_METHODS_RESOURCE: 'GET_WITHDRAWAL_PAYMENT_METHODS_RESOURCE',
  SAVE_WITHDRAWAL_PAYMENT_METHODS_RESOURCE: 'SAVE_WITHDRAWAL_PAYMENT_METHODS_RESOURCE',
  RESET_WITHDRAWAL_PAYMENT_METHODS_RESOURCE: 'RESET_WITHDRAWAL_PAYMENT_METHODS_RESOURCE',

  GET_CASHIER_WITHDRAWAL_CATEGORY: 'GET_CASHIER_WITHDRAWAL_CATEGORY',
  SAVE_CASHIER_WITHDRAWAL_CATEGORY: 'SAVE_CASHIER_WITHDRAWAL_CATEGORY',
  RESET_CASHIER_WITHDRAWAL_CATEGORY: 'RESET_CASHIER_WITHDRAWAL_CATEGORY',

  DELETE_CASHIER_WITHDRAWAL: 'DELETE_CASHIER_WITHDRAWAL',
  CREATE_CASHIER_WITHDRAWAL: 'CREATE_CASHIER_WITHDRAWAL',
  EDIT_CASHIER_WITHDRAWAL: 'EDIT_CASHIER_WITHDRAWAL',

  REORDER_CASHIER_WITHDRAWAL: 'REORDER_CASHIER_WITHDRAWAL',

  GET_CASHIER_DEPOSIT_PAYMENT_METHODS: 'GET_CASHIER_DEPOSIT_PAYMENT_METHODS',
  SAVE_CASHIER_DEPOSIT_PAYMENT_METHODS: 'SAVE_CASHIER_DEPOSIT_PAYMENT_METHODS',
  RESET_CASHIER_DEPOSIT_PAYMENT_METHODS: 'RESET_CASHIER_DEPOSIT_PAYMENT_METHODS',

  DEPOSIT_PAYMENT_METHOD_STATE_CHANGE: 'DEPOSIT_PAYMENT_METHOD_STATE_CHANGE',
  DEPOSIT_PAYMENT_METHOD_MOVE: 'DEPOSIT_PAYMENT_METHOD_MOVE',
  DEPOSIT_PAYMENT_METHOD_REORDER: 'DEPOSIT_PAYMENT_METHOD_REORDER',

  GET_DEPOSIT_CATEGORY_RESOURCE: 'GET_DEPOSIT_CATEGORY_RESOURCE',
  SAVE_DEPOSIT_CATEGORY_RESOURCE: 'SAVE_DEPOSIT_CATEGORY_RESOURCE',
  RESET_DEPOSIT_CATEGORY_RESOURCE: 'RESET_DEPOSIT_CATEGORY_RESOURCE',

  GET_CASHIER_WITHDRAWAL_PAYMENT_METHODS: 'GET_CASHIER_WITHDRAWAL_PAYMENT_METHODS',
  SAVE_CASHIER_WITHDRAWAL_PAYMENT_METHODS: 'SAVE_CASHIER_WITHDRAWAL_PAYMENT_METHODS',
  RESET_CASHIER_WITHDRAWAL_PAYMENT_METHODS: 'RESET_CASHIER_WITHDRAWAL_PAYMENT_METHODS',

  WITHDRAWAL_PAYMENT_METHOD_STATE_CHANGE: 'WITHDRAWAL_PAYMENT_METHOD_STATE_CHANGE',
  WITHDRAWAL_PAYMENT_METHOD_MOVE: 'WITHDRAWAL_PAYMENT_METHOD_MOVE',
  WITHDRAWAL_PAYMENT_METHOD_REORDER: 'WITHDRAWAL_PAYMENT_METHOD_REORDER',

  GET_WITHDRAWAL_CATEGORY_RESOURCE: 'GET_WITHDRAWAL_CATEGORY_RESOURCE',
  SAVE_WITHDRAWAL_CATEGORY_RESOURCE: 'SAVE_WITHDRAWAL_CATEGORY_RESOURCE',
  RESET_WITHDRAWAL_CATEGORY_RESOURCE: 'RESET_WITHDRAWAL_CATEGORY_RESOURCE',

  GET_CASHIER_SETTINGS: 'GET_CASHIER_SETTINGS',
  SAVE_CASHIER_GENERAL_SETTINGS: 'SAVE_CASHIER_GENERAL_SETTINGS',
  RESET_CASHIER_GENERAL_SETTINGS: 'RESET_CASHIER_GENERAL_SETTINGS',
  SAVE_CASHIER_BRAND_SETTINGS: 'SAVE_CASHIER_BRAND_SETTINGS',
  RESET_CASHIER_BRAND_SETTINGS: 'RESET_CASHIER_BRAND_SETTINGS',

  EDIT_CASHIER_GENERAL_SETTINGS: 'EDIT_CASHIER_GENERAL_SETTINGS',
};

export default CashierActionType;
