import {
  put, call, takeLatest, all,
} from 'redux-saga/effects';
import qs from 'qs';
import mime from 'mimetypes';
import NetworkService from 'services/networkService';
import { changeLoadingState, setErrorMessage } from 'actions/setting/settingActionCreator';
import SimilarAccountActionType from 'actions/similarAccount/similarAccountActionType';
import { downloadFile } from 'utils/utils';
import { AppConstants } from 'constants/index';
import { saveSimilarAccountsGroups, saveSimilarAccounts, savePlayerSimilarAccountsInfo } from 'actions/similarAccount/similarAccountActionCreator';
import { i18n } from 'services';
import { getError } from 'utils/helpers';

function* getSimilarAccountsGroups({ payload }) {
  try {
    const { params } = payload;
    const options = {
      params,
      paramsSerializer: (param) => qs.stringify(param, { allowDots: true }),
    };
    yield put(changeLoadingState(true));
    const { ReportingAdmin, Player, SimilarAccountGroups } = AppConstants.api;
    const { data } = yield call(NetworkService.makeAPIGetRequest, [ReportingAdmin, Player, SimilarAccountGroups], options);
    yield put(changeLoadingState(false));
    yield put(saveSimilarAccountsGroups(data));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* exportSimilarAccountsGroups({ payload }) {
  try {
    const options = {
      params: payload.params,
      paramsSerializer: (params) => qs.stringify(params, { allowDots: true }),
      responseType: 'blob',
    };
    yield put(changeLoadingState(true));
    const {
      ReportingAdmin, Player, SimilarAccountGroups, Export,
    } = AppConstants.api;
    const { data, headers } = yield call(NetworkService.makeAPIGetRequest, [ReportingAdmin, Player, SimilarAccountGroups, Export], options);
    downloadFile(data, i18n.t('similarAccounts'), mime.detectExtension(headers['content-type']));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getSimilarAccounts({ payload }) {
  try {
    const { params } = payload;
    const options = {
      params,
      paramsSerializer: (param) => qs.stringify(param, { allowDots: true }),
    };
    yield put(changeLoadingState(true));
    const { ReportingAdmin, Player, SimilarAccounts } = AppConstants.api;
    const { data } = yield call(NetworkService.makeAPIGetRequest, [ReportingAdmin, Player, SimilarAccounts], options);
    yield put(changeLoadingState(false));
    yield put(saveSimilarAccounts(data));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* exportSimilarAccounts({ payload }) {
  try {
    const options = {
      params: payload.params,
      paramsSerializer: (params) => qs.stringify(params, { allowDots: true }),
      responseType: 'blob',
    };
    yield put(changeLoadingState(true));
    const {
      ReportingAdmin, Player, SimilarAccounts, Export,
    } = AppConstants.api;
    const { data, headers } = yield call(NetworkService.makeAPIGetRequest, [ReportingAdmin, Player, SimilarAccounts, Export], options);
    downloadFile(data, i18n.t('similarAccounts'), mime.detectExtension(headers['content-type']));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getPlayerSimilarAccountsInfo({ payload }) {
  const { isLoading, playerId } = payload;
  try {
    if (isLoading) {
      yield put(changeLoadingState(false));
    }
    const { ReportingAdmin, Player, SimilarAccountsInfo } = AppConstants.api;
    const { data } = yield call(NetworkService.makeAPIGetRequest, [ReportingAdmin, Player, playerId, SimilarAccountsInfo]);

    if (isLoading) {
      yield put(changeLoadingState(false));
    }

    yield put(savePlayerSimilarAccountsInfo(data));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    if (isLoading) {
      yield put(changeLoadingState(false));
    }
  }
}

export default function* similarAccountSaga() {
  yield all([
    takeLatest(SimilarAccountActionType.GET_SIMILAR_ACCOUNTS_GROUPS, getSimilarAccountsGroups),
    takeLatest(SimilarAccountActionType.EXPORT_SIMILAR_ACCOUNTS_GROUPS, exportSimilarAccountsGroups),
    takeLatest(SimilarAccountActionType.GET_SIMILAR_ACCOUNTS, getSimilarAccounts),
    takeLatest(SimilarAccountActionType.EXPORT_SIMILAR_ACCOUNTS, exportSimilarAccounts),
    takeLatest(SimilarAccountActionType.GET_PLAYER_SIMILAR_ACCOUNTS_INFO, getPlayerSimilarAccountsInfo),
  ]);
}
