import BrandMarketActionType from './brandMarketActionType';

export const getBrandMarketGeneralSettings = (brandId, isLoading) => ({
  type: BrandMarketActionType.GET_BRAND_MARKET_GENERAL_SETTINGS,
  payload: { brandId, isLoading },
});

export const saveBrandMarketGeneralSettings = (data) => ({
  type: BrandMarketActionType.SAVE_BRAND_MARKET_GENERAL_SETTINGS,
  payload: { data },
});

export const resetBrandMarketGeneralSettings = () => ({
  type: BrandMarketActionType.RESET_BRAND_MARKET_GENERAL_SETTINGS,
});

export const editBrandMarketGeneralSettings = (brandId, data) => ({
  type: BrandMarketActionType.EDIT_BRAND_MARKET_GENERAL_SETTINGS,
  payload: { data, brandId },
});

export const getBrandVerificationConfigurations = (brandId) => ({
  type: BrandMarketActionType.GET_BRAND_VERIFICATION_CONFIGURATIONS,
  payload: { brandId },
});

export const saveBrandVerificationConfigurations = (data) => ({
  type: BrandMarketActionType.SAVE_BRAND_VERIFICATION_CONFIGURATIONS,
  payload: { data },
});

export const resetBrandVerificationConfigurations = () => ({
  type: BrandMarketActionType.RESET_BRAND_VERIFICATION_CONFIGURATIONS,
});

export const editBrandVerificationConfigurations = (brandId, data) => ({
  type: BrandMarketActionType.EDIT_BRAND_VERIFICATION_CONFIGURATIONS,
  payload: { brandId, data },
});

export const getBrandVerificationDocumentTypes = (brandId, isLoading) => ({
  type: BrandMarketActionType.GET_BRAND_VERIFICATION_DOCUMENT_TYPES,
  payload: { brandId, isLoading },
});

export const saveBrandVerificationDocumentTypes = (data) => ({
  type: BrandMarketActionType.SAVE_BRAND_VERIFICATION_DOCUMENT_TYPES,
  payload: { data },
});

export const resetBrandVerificationDocumentTypes = () => ({
  type: BrandMarketActionType.RESET_BRAND_VERIFICATION_DOCUMENT_TYPES,
});

export const editBrandVerificationDocumentTypes = (brandId, data) => ({
  type: BrandMarketActionType.EDIT_BRAND_VERIFICATION_DOCUMENT_TYPES,
  payload: { brandId, data },
});

export const getBrandVerificationPeriod = (brandId) => ({
  type: BrandMarketActionType.GET_BRAND_VERIFICATION_PERIOD,
  payload: { brandId },
});

export const saveBrandVerificationPeriod = (data) => ({
  type: BrandMarketActionType.SAVE_BRAND_VERIFICATION_PERIOD,
  payload: { data },
});

export const resetBrandVerificationPeriod = () => ({
  type: BrandMarketActionType.RESET_BRAND_VERIFICATION_PERIOD,
});

export const addBrandVerificationPeriod = (brandId, data) => ({
  type: BrandMarketActionType.ADD_BRAND_VERIFICATION_PERIOD,
  payload: { brandId, data },
});

export const getBrandUnverifiedPlayersDepositLimits = (params) => ({
  type: BrandMarketActionType.GET_BRAND_UNVERIFIED_PLAYERS_DEPOSIT_LIMITS,
  payload: { params },
});

export const saveBrandUnverifiedPlayersDepositLimits = (data) => ({
  type: BrandMarketActionType.SAVE_BRAND_UNVERIFIED_PLAYERS_DEPOSIT_LIMITS,
  payload: { data },
});

export const resetBrandUnverifiedPlayersDepositLimits = () => ({
  type: BrandMarketActionType.RESET_BRAND_UNVERIFIED_PLAYERS_DEPOSIT_LIMITS,
});

export const editBrandUnverifiedPlayersDepositLimits = (data, params) => ({
  type: BrandMarketActionType.EDIT_BRAND_UNVERIFIED_PLAYERS_DEPOSIT_LIMITS,
  payload: { data, params },
});
