import { createSelector } from 'reselect';

export const selectUsers = (state) => state.users;
export const selectUserLoading = (state) => state.loading;
export const selectUserTotal = (state) => state.total;
export const selectUserConfigs = (state) => state.userConfigs;
export const selectUserConfig = createSelector([selectUserConfigs, (state, key) => key], (configs, key) => configs?.[key]);
export const selectUserPartnerAccesses = (state) => state.partnerAccesses;
export const selectUserBrandAccesses = (state) => state.brandAccesses;
export const selectUserPermissionGroup = (state) => state.permissionGroup;
export const selectUserPermissionsGroupPermissionsData = (state) => state.permissionGroupPermissions;
export const selectUserBrandsByPartner = (state) => state.partnerBrands;
export const selectUserPositions = (state) => state.positions;
export const selectSupervisorUsers = (state) => state.supervisors;
export const selectUser = (state) => state.user;
