import PropTypes from 'prop-types';
import { Form } from 'antd';
import { useTranslation } from 'react-i18next';
import { MessageService } from 'services';
import { IconSvg } from 'components/common';
import { StyledIconDefault } from 'components/styledComponents/icon/Icon.styled';
import Buttons from 'components/common/button/Buttons';
import { StyledModal } from 'components/styledComponents/modal/Modal.styled';
import ChangeStatusForm from 'components/brandSetting/ChangeStatusForm';
import enumTypes from 'constants/enumTypes';
import { useTimeZone } from 'hooks';
import { useState } from 'react';
import _ from 'lodash';

const ChangeStatus = (props) => {
  const {
    visible, brand, activateBrand, onClose, locale,
  } = props;

  const { brandStatuses, deviceTypes } = locale;

  const { t } = useTranslation();
  const { getIsoDate } = useTimeZone();

  const [form] = Form.useForm();
  const { validateFields } = form;

  const [now, setNow] = useState(true);

  const onApply = () => {
    validateFields().then((values) => {
      const {
        id, name, maintenancePeriodFrom, maintenancePeriodTo,
      } = brand;
      const { disabledVersionNames = [], status, maintenancePeriod } = values;

      const newMaintenancePeriod = status === enumTypes.brandStatuses.Maintenance
        ? {
          maintenancePeriodFrom: maintenancePeriod?.[0] && !now ? getIsoDate(maintenancePeriod[0].startOf('minute')) : null,
          maintenancePeriodTo: maintenancePeriod?.[1] ? getIsoDate(maintenancePeriod[1].startOf('minute')) : null,
        }
        : {};

      const oldMaintenancePeriod = {
        maintenancePeriodFrom,
        maintenancePeriodTo,
      };

      if (status !== brand.status || !_.isEqual(_.pickBy(newMaintenancePeriod, _.identity), _.pickBy(oldMaintenancePeriod, _.identity))) {
        let reqData = values;

        if (status === enumTypes.brandStatuses.VersionNamesDisabled && !disabledVersionNames.length) {
          return MessageService.error(t('notification:atLeastOneVersion'));
        }
        if (status === enumTypes.brandStatuses.Maintenance) {
          const newMaintenancePeriodFrom = brand.status !== enumTypes.brandStatuses.Maintenance ? newMaintenancePeriod.maintenancePeriodFrom : undefined;
          reqData = {
            status,
            disabledVersionNames: undefined,
            ...newMaintenancePeriod,
            maintenancePeriodFrom: newMaintenancePeriodFrom,
          };
        }

        activateBrand({
          id,
          name,
          ...reqData,
        });
      } else {
        onClose();
      }
    });
  };

  return (
    <StyledModal
      maskClosable={false}
      destroyOnClose
      open={visible}
      title={t('edit')}
      closable
      onCancel={onClose}
      width="34.3rem"
      centered
      closeIcon={(
        <StyledIconDefault>
          <IconSvg icon="CloseModalIcon" width="1.14rem" height="1.14rem" />
        </StyledIconDefault>
      )}
      footer={<Buttons onClose={onClose} onApply={onApply} size="large" />}
    >
      <ChangeStatusForm brand={brand} form={form} locale={{ brandStatuses, deviceTypes }} setNow={setNow} now={now} />
    </StyledModal>
  );
};

ChangeStatus.propTypes = {
  visible: PropTypes.bool,
  brand: PropTypes.object,
  activateBrand: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  locale: PropTypes.object.isRequired,
};

ChangeStatus.defaultProps = {
  visible: false,
  brand: {},
};

export default ChangeStatus;
