import CampaignV2ActionType from './campaignV2ActionType';

export const getCampaignsV2 = (params) => ({
  type: CampaignV2ActionType.GET_CAMPAIGNS_V2,
  payload: { params },
});

export const saveCampaignsV2 = (data) => ({
  type: CampaignV2ActionType.SAVE_CAMPAIGNS_V2,
  payload: { data },
});

export const resetCampaignsV2 = () => ({
  type: CampaignV2ActionType.RESET_CAMPAIGNS_V2,
});

export const getCampaignV2 = (id) => ({
  type: CampaignV2ActionType.GET_CAMPAIGN_V2,
  payload: { id },
});

export const saveCampaignV2 = (data) => ({
  type: CampaignV2ActionType.SAVE_CAMPAIGN_V2,
  payload: { data },
});

export const resetCampaignV2 = () => ({
  type: CampaignV2ActionType.RESET_CAMPAIGN_V2,
});

export const createCampaignV2 = (data) => ({
  type: CampaignV2ActionType.CREATE_CAMPAIGN_V2,
  payload: { data },
});

export const saveCreatedCampaignV2Id = (id) => ({
  type: CampaignV2ActionType.SAVE_CREATED_CAMPAIGN_V2_ID,
  payload: { id },
});

export const changeCampaignV2GeneralConfigEndedStatus = (status) => ({
  type: CampaignV2ActionType.CHANGE_CAMPAIGN_V2_GENERAL_CONFIG_ENDED_STATUS,
  payload: { status },
});

export const editCampaignV2 = (id, data) => ({
  type: CampaignV2ActionType.EDIT_CAMPAIGN_V2,
  payload: { id, data },
});

export const createCampaignV2ParticipationGroup = (campaignId, data) => ({
  type: CampaignV2ActionType.CREATE_CAMPAIGN_V2_PARTICIPATION_GROUP,
  payload: { campaignId, data },
});

export const editCampaignV2ParticipationGroup = (campaignId, id, data) => ({
  type: CampaignV2ActionType.EDIT_CAMPAIGN_V2_PARTICIPATION_GROUP,
  payload: { campaignId, id, data },
});

export const deleteCampaignV2ParticipationGroup = (campaignId, id, name) => ({
  type: CampaignV2ActionType.DELETE_CAMPAIGN_V2_PARTICIPATION_GROUP,
  payload: { campaignId, id, name },
});

export const getCampaignV2ParticipationGroups = (id) => ({
  type: CampaignV2ActionType.GET_CAMPAIGN_V2_PARTICIPATION_GROUPS,
  payload: { id },
});

export const saveCampaignV2ParticipationGroups = (data) => ({
  type: CampaignV2ActionType.SAVE_CAMPAIGN_V2_PARTICIPATION_GROUPS,
  payload: { data },
});

export const resetCampaignV2ParticipationGroups = () => ({
  type: CampaignV2ActionType.RESET_CAMPAIGN_V2_PARTICIPATION_GROUPS,
});

export const getCampaignV2ParticipationGroup = (campaignId, id) => ({
  type: CampaignV2ActionType.GET_CAMPAIGN_V2_PARTICIPATION_GROUP,
  payload: { campaignId, id },
});

export const saveCampaignV2ParticipationGroup = (data) => ({
  type: CampaignV2ActionType.SAVE_CAMPAIGN_V2_PARTICIPATION_GROUP,
  payload: { data },
});

export const resetCampaignV2ParticipationGroup = () => ({
  type: CampaignV2ActionType.RESET_CAMPAIGN_V2_PARTICIPATION_GROUP,
});

export const changeCampaignV2ParticipationGroupEndedStatus = (status) => ({
  type: CampaignV2ActionType.CHANGE_CAMPAIGN_V2_PARTICIPATION_GROUP_ENDED_STATUS,
  payload: { status },
});

export const createCampaignV2FreeSpinBonus = (campaignId, data) => ({
  type: CampaignV2ActionType.CREATE_CAMPAIGN_V2_FREE_SPIN_BONUS,
  payload: { campaignId, data },
});

export const editCampaignV2FreeSpinBonus = (campaignId, id, data) => ({
  type: CampaignV2ActionType.EDIT_CAMPAIGN_V2_FREE_SPIN_BONUS,
  payload: { campaignId, id, data },
});

export const getCampaignV2FreeSpinBonus = (campaignId, id) => ({
  type: CampaignV2ActionType.GET_CAMPAIGN_V2_FREE_SPIN_BONUS,
  payload: { campaignId, id },
});

export const saveCampaignV2FreeSpinBonus = (data) => ({
  type: CampaignV2ActionType.SAVE_CAMPAIGN_V2_FREE_SPIN_BONUS,
  payload: { data },
});

export const resetCampaignV2FreeSpinBonus = () => ({
  type: CampaignV2ActionType.RESET_CAMPAIGN_V2_FREE_SPIN_BONUS,
});

export const deleteCampaignV2FreeSpinBonus = (campaignId, id, name) => ({
  type: CampaignV2ActionType.DELETE_CAMPAIGN_V2_FREE_SPIN_BONUS,
  payload: { campaignId, id, name },
});

export const createCampaignV2FreeBetBonus = (campaignId, data) => ({
  type: CampaignV2ActionType.CREATE_CAMPAIGN_V2_FREE_BET_BONUS,
  payload: { campaignId, data },
});

export const editCampaignV2FreeBetBonus = (campaignId, id, data) => ({
  type: CampaignV2ActionType.EDIT_CAMPAIGN_V2_FREE_BET_BONUS,
  payload: { campaignId, id, data },
});

export const getCampaignV2FreeBetBonus = (campaignId, id) => ({
  type: CampaignV2ActionType.GET_CAMPAIGN_V2_FREE_BET_BONUS,
  payload: { campaignId, id },
});

export const saveCampaignV2FreeBetBonus = (data) => ({
  type: CampaignV2ActionType.SAVE_CAMPAIGN_V2_FREE_BET_BONUS,
  payload: { data },
});

export const deleteCampaignV2FreeBetBonus = (campaignId, id, name) => ({
  type: CampaignV2ActionType.DELETE_CAMPAIGN_V2_FREE_BET_BONUS,
  payload: { campaignId, id, name },
});

export const resetCampaignV2FreeBetBonus = () => ({
  type: CampaignV2ActionType.RESET_CAMPAIGN_V2_FREE_BET_BONUS,
});

export const changeCampaignV2BonusEndedStatus = (status) => ({
  type: CampaignV2ActionType.CHANGE_CAMPAIGN_V2_BONUS_ENDED_STATUS,
  payload: { status },
});

export const getCampaignV2Bonuses = (id) => ({
  type: CampaignV2ActionType.GET_CAMPAIGN_V2_BONUSES,
  payload: { id },
});

export const saveCampaignV2Bonuses = (data) => ({
  type: CampaignV2ActionType.SAVE_CAMPAIGN_V2_BONUSES,
  payload: { data },
});

export const resetCampaignV2Bonuses = () => ({
  type: CampaignV2ActionType.RESET_CAMPAIGN_V2_BONUSES,
});

export const getCampaignV2TriggerConditions = (id) => ({
  type: CampaignV2ActionType.GET_CAMPAIGN_V2_TRIGGER_CONDITIONS,
  payload: { id },
});

export const saveCampaignV2TriggerConditions = (data) => ({
  type: CampaignV2ActionType.SAVE_CAMPAIGN_V2_TRIGGER_CONDITIONS,
  payload: { data },
});

export const resetCampaignV2TriggerConditions = () => ({
  type: CampaignV2ActionType.RESET_CAMPAIGN_V2_TRIGGER_CONDITIONS,
});

export const getCampaignV2TriggerCondition = (campaignId, id) => ({
  type: CampaignV2ActionType.GET_CAMPAIGN_V2_TRIGGER_CONDITION,
  payload: { campaignId, id },
});

export const saveCampaignV2TriggerCondition = (data) => ({
  type: CampaignV2ActionType.SAVE_CAMPAIGN_V2_TRIGGER_CONDITION,
  payload: { data },
});

export const resetCampaignV2TriggerCondition = () => ({
  type: CampaignV2ActionType.RESET_CAMPAIGN_V2_TRIGGER_CONDITION,
});

export const createCampaignV2TriggerCondition = (id, data) => ({
  type: CampaignV2ActionType.CREATE_CAMPAIGN_V2_TRIGGER_CONDITION,
  payload: { id, data },
});

export const editCampaignV2TriggerCondition = (campaignId, data) => ({
  type: CampaignV2ActionType.EDIT_CAMPAIGN_V2_TRIGGER_CONDITION,
  payload: { campaignId, data },
});

export const deleteCampaignV2TriggerCondition = (campaignId, data) => ({
  type: CampaignV2ActionType.DELETE_CAMPAIGN_V2_TRIGGER_CONDITION,
  payload: { campaignId, data },
});

export const changeCampaignV2TriggerConditionEndedStatus = (status) => ({
  type: CampaignV2ActionType.CHANGE_CAMPAIGN_V2_TRIGGER_CONDITION_ENDED_STATUS,
  payload: { status },
});
