import PlayerWalletActionType from 'actions/playerWallet/playerWalletActionType';

const initialState = {
  walletHistory: {},
  walletsInfo: {},
  isPlayerWalletsInfoChanged: false,
  netBalance: null,
  netBalanceLoading: false,
  balanceAdjustments: {},
  balanceAdjustmentTransactions: {},
  isPlayerNetBalanceChanged: false,
  csvPlayers: [],
};

const playerWalletReducer = (state = initialState, { type, payload }) => {
  switch (type) {
  case PlayerWalletActionType.SAVE_PLAYER_WALLET_HISTORY:
    return {
      ...state,
      walletHistory: payload.data,
    };
  case PlayerWalletActionType.RESET_PLAYER_WALLET_HISTORY:
    return {
      ...state,
      walletHistory: {},
    };
  case PlayerWalletActionType.SAVE_PLAYER_WALLETS_INFO:
    return {
      ...state,
      walletsInfo: payload.data,
    };
  case PlayerWalletActionType.RESET_PLAYER_WALLETS_INFO:
    return {
      ...state,
      walletsInfo: {},
    };
  case PlayerWalletActionType.CHANGE_PLAYER_WALLETS_INFO_UPDATED_STATUS:
    return {
      ...state,
      isPlayerWalletsInfoChanged: payload.status,
    };
  case PlayerWalletActionType.SAVE_PLAYER_NET_BALANCE:
    return {
      ...state,
      netBalance: payload.data,
    };
  case PlayerWalletActionType.RESET_PLAYER_NET_BALANCE:
    return {
      ...state,
      netBalance: null,
    };
  case PlayerWalletActionType.CHANGE_PLAYER_NET_BALANCE_UPDATED_STATUS:
    return {
      ...state,
      isPlayerNetBalanceChanged: payload.status,
    };
  case PlayerWalletActionType.PLAYER_NET_BALANCE_LOADING:
    return {
      ...state,
      netBalanceLoading: payload.state,
    };
  case PlayerWalletActionType.SAVE_BALANCE_ADJUSTMENTS:
    return {
      ...state,
      balanceAdjustments: payload.data,
    };
  case PlayerWalletActionType.RESET_BALANCE_ADJUSTMENTS:
    return {
      ...state,
      balanceAdjustments: {},
    };
  case PlayerWalletActionType.SAVE_BALANCE_ADJUSTMENT_TRANSACTIONS:
    return {
      ...state,
      balanceAdjustmentTransactions: payload.data,
    };
  case PlayerWalletActionType.RESET_BALANCE_ADJUSTMENT_TRANSACTIONS:
    return {
      ...state,
      balanceAdjustmentTransactions: {},
    };
  case PlayerWalletActionType.SAVE_ADJUSTMENT_CSV_PLAYERS:
    return {
      ...state,
      csvPlayers: payload.data,
    };
  case PlayerWalletActionType.RESET_ADJUSTMENT_CSV_PLAYERS:
    return {
      ...state,
      csvPlayers: [],
    };
  default:
    return state;
  }
};

export default playerWalletReducer;
