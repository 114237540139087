const OverviewActionType = {
  GET_PLAYER_OVERVIEW_FINANCIAL: 'GET_PLAYER_OVERVIEW_FINANCIAL',
  SAVE_PLAYER_OVERVIEW_FINANCIAL: 'SAVE_PLAYER_OVERVIEW_FINANCIAL',
  RESET_PLAYER_OVERVIEW_FINANCIAL: 'RESET_PLAYER_OVERVIEW_FINANCIAL',

  CHANGE_PLAYER_OVERVIEW_FINANCIAL_LOADING_STATE: 'CHANGE_PLAYER_OVERVIEW_FINANCIAL_LOADING_STATE',

  GET_PLAYER_OVERVIEW_ACTIVITIES: 'GET_PLAYER_OVERVIEW_ACTIVITIES',
  SAVE_PLAYER_OVERVIEW_ACTIVITIES: 'SAVE_PLAYER_OVERVIEW_ACTIVITIES',
  RESET_PLAYER_OVERVIEW_ACTIVITIES: 'RESET_PLAYER_OVERVIEW_ACTIVITIES',

  CHANGE_PLAYER_OVERVIEW_ACTIVITIES_LOADING_STATE: 'CHANGE_PLAYER_OVERVIEW_ACTIVITIES_LOADING_STATE',

  GET_PLAYER_OVERVIEW_REVENUES: 'GET_PLAYER_OVERVIEW_REVENUES',
  SAVE_PLAYER_OVERVIEW_REVENUES: 'SAVE_PLAYER_OVERVIEW_REVENUES',
  RESET_PLAYER_OVERVIEW_REVENUES: 'RESET_PLAYER_OVERVIEW_REVENUES',

  CHANGE_PLAYER_OVERVIEW_REVENUES_LOADING_STATE: 'CHANGE_PLAYER_OVERVIEW_REVENUES_LOADING_STATE',

  GET_PLAYER_OVERVIEW_LIMITS_CHECK: 'GET_PLAYER_OVERVIEW_LIMITS_CHECK',
  SAVE_PLAYER_OVERVIEW_LIMITS_CHECK: 'SAVE_PLAYER_OVERVIEW_LIMITS_CHECK',
  RESET_PLAYER_OVERVIEW_LIMITS_CHECK: 'RESET_PLAYER_OVERVIEW_LIMITS_CHECK',

  CHANGE_PLAYER_OVERVIEW_VERTICAL_TAGS_LOADING_STATE: 'CHANGE_PLAYER_OVERVIEW_VERTICAL_TAGS_LOADING_STATE',

  GET_PLAYER_OVERVIEW_VERTICAL_TAGS: 'GET_PLAYER_OVERVIEW_VERTICAL_TAGS',
  SAVE_PLAYER_OVERVIEW_VERTICAL_TAGS: 'SAVE_PLAYER_OVERVIEW_VERTICAL_TAGS',
  RESET_PLAYER_OVERVIEW_VERTICAL_TAGS: 'RESET_PLAYER_OVERVIEW_VERTICAL_TAGS',

  GET_PLAYER_OVERVIEW_CASINO_BONUSES: 'GET_PLAYER_OVERVIEW_CASINO_BONUSES',
  SAVE_PLAYER_OVERVIEW_CASINO_BONUSES: 'SAVE_PLAYER_OVERVIEW_CASINO_BONUSES',
  RESET_PLAYER_OVERVIEW_CASINO_BONUSES: 'RESET_PLAYER_OVERVIEW_CASINO_BONUSES',

  GET_PLAYER_OVERVIEW_SPORT_BONUSES: 'GET_PLAYER_OVERVIEW_SPORT_BONUSES',
  SAVE_PLAYER_OVERVIEW_SPORT_BONUSES: 'SAVE_PLAYER_OVERVIEW_SPORT_BONUSES',
  RESET_PLAYER_OVERVIEW_SPORT_BONUSES: 'RESET_PLAYER_OVERVIEW_SPORT_BONUSES',

  CHANGE_PLAYER_OVERVIEW_BONUSES_LOADING_STATE: 'CHANGE_PLAYER_OVERVIEW_BONUSES_LOADING_STATE',

  GET_PLAYER_OVERVIEW_PAYMENTS: 'GET_PLAYER_OVERVIEW_PAYMENTS',
  SAVE_PLAYER_OVERVIEW_PAYMENTS: 'SAVE_PLAYER_OVERVIEW_PAYMENTS',
  RESET_PLAYER_OVERVIEW_PAYMENTS: 'RESET_PLAYER_OVERVIEW_PAYMENTS',

  CHANGE_PLAYER_OVERVIEW_PAYMENTS_LOADING_STATE: 'CHANGE_PLAYER_OVERVIEW_PAYMENTS_LOADING_STATE',

  GET_PLAYER_OVERVIEW_CASINO_INDICATORS: 'GET_PLAYER_OVERVIEW_CASINO_INDICATORS',
  SAVE_PLAYER_OVERVIEW_CASINO_INDICATORS: 'SAVE_PLAYER_OVERVIEW_CASINO_INDICATORS',
  RESET_PLAYER_OVERVIEW_CASINO_INDICATORS: 'RESET_PLAYER_OVERVIEW_CASINO_INDICATORS',

  GET_PLAYER_OVERVIEW_SPORT_INDICATORS: 'GET_PLAYER_OVERVIEW_SPORT_INDICATORS',
  SAVE_PLAYER_OVERVIEW_SPORT_INDICATORS: 'SAVE_PLAYER_OVERVIEW_SPORT_INDICATORS',
  RESET_PLAYER_OVERVIEW_SPORT_INDICATORS: 'RESET_PLAYER_OVERVIEW_SPORT_INDICATORS',

  CHANGE_PLAYER_OVERVIEW_INDICATORS_LOADING_STATE: 'CHANGE_PLAYER_OVERVIEW_INDICATORS_LOADING_STATE',
};

export default OverviewActionType;
