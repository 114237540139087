import { useState, createContext } from 'react';
import PropTypes from 'prop-types';

export const LayoutContext = createContext({
  showPartner: false,
  setShowPartner: null,
  showBrand: false,
  setShowBrand: null,
  showConversion: false,
  setShowConversion: null,
  showTimeZone: false,
  setShowTimeZone: null,
});

export const LayoutProvider = ({ children }) => {
  const [showPartner, setShowPartner] = useState(false);
  const [showBrand, setShowBrand] = useState(false);
  const [showConversion, setShowConversion] = useState(false);
  const [showTimeZone, setShowTimeZone] = useState(false);
  const [hideLayout, setHideLayout] = useState(false);

  const value = {
    showPartner,
    setShowPartner,
    showBrand,
    setShowBrand,
    showConversion,
    setShowConversion,
    showTimeZone,
    setShowTimeZone,
    hideLayout,
    setHideLayout,
  };

  return <LayoutContext.Provider value={value}>{children}</LayoutContext.Provider>;
};

LayoutProvider.propTypes = {
  children: PropTypes.any,
};

LayoutProvider.defaultProps = {
  children: '',
};
