import CampaignActionType from 'actions/campaign/campaignActionType';

const initialState = {
  campaigns: {
    data: [],
    total: 0,
  },
  campaign: {},
  campaignLoading: null,
  conditionTypes: [],
  campaignPlayers: {
    data: [],
    total: 0,
  },
  campaignDepositStatistics: {
    received: {},
    failed: {},
  },
  campaignVerificationStatistics: {
    received: {},
    failed: {},
  },
  campaignSignUpStatistics: {
    received: {},
    failed: {},
  },
  campaignNoTriggerStatistics: {
    received: {},
    failed: {},
  },
  crossPlatformCampaigns: {
    data: [],
    total: 0,
  },
  crossPlatformCampaign: {},
  crossPlatformNoTriggerStatistics: {
    data: [],
    total: 0,
  },
  crossPlatformUsages: {
    data: [],
    total: 0,
  },
  contentConfig: {},
  crossPlatformContentConfig: [],
  contentLoading: null,
  campaignParticipants: [],
  playersLoading: false,
  campaignSignInStatistics: {
    received: {},
    failed: {},
  },
};

const campaignReducer = (state = initialState, { type, payload }) => {
  switch (type) {
  case CampaignActionType.SAVE_CAMPAIGNS:
    return {
      ...state,
      campaigns: payload.data,
    };
  case CampaignActionType.RESET_CAMPAIGNS:
    return {
      ...state,
      campaigns: {
        data: [],
        total: 0,
      },
    };
  case CampaignActionType.SAVE_CAMPAIGN:
    return {
      ...state,
      campaign: payload.data,
    };
  case CampaignActionType.RESET_CAMPAIGN:
    return {
      ...state,
      campaign: {},
    };
  case CampaignActionType.SAVE_CAMPAIGN_TRIGGER_CONDITIONS:
    return {
      ...state,
      conditionTypes: payload.data,
    };
  case CampaignActionType.RESET_CAMPAIGN_TRIGGER_CONDITIONS:
    return {
      ...state,
      conditionTypes: [],
    };
  case CampaignActionType.SAVE_CAMPAIGN_PLAYERS:
    return {
      ...state,
      campaignPlayers: payload.data,
    };
  case CampaignActionType.RESET_CAMPAIGN_PLAYERS:
    return {
      ...state,
      campaignPlayers: {
        data: [],
        total: 0,
      },
    };
  case CampaignActionType.SAVE_CAMPAIGN_RECEIVED_DEPOSIT_STATISTICS:
    return {
      ...state,
      campaignDepositStatistics: {
        ...state.campaignDepositStatistics,
        received: payload.data,
      },
    };
  case CampaignActionType.RESET_CAMPAIGN_RECEIVED_DEPOSIT_STATISTICS:
    return {
      ...state,
      campaignDepositStatistics: {
        ...state.campaignDepositStatistics,
        received: {},
      },
    };

  case CampaignActionType.SAVE_CAMPAIGN_FAILED_DEPOSIT_STATISTICS:
    return {
      ...state,
      campaignDepositStatistics: {
        ...state.campaignDepositStatistics,
        failed: payload.data,
      },
    };
  case CampaignActionType.RESET_CAMPAIGN_FAILED_DEPOSIT_STATISTICS:
    return {
      ...state,
      campaignDepositStatistics: {
        ...state.campaignDepositStatistics,
        failed: {},
      },
    };
  case CampaignActionType.SAVE_CAMPAIGN_RECEIVED_VERIFICATION_STATISTICS:
    return {
      ...state,
      campaignVerificationStatistics: {
        ...state.campaignVerificationStatistics,
        received: payload.data,
      },
    };
  case CampaignActionType.RESET_CAMPAIGN_RECEIVED_VERIFICATION_STATISTICS:
    return {
      ...state,
      campaignVerificationStatistics: {
        ...state.campaignVerificationStatistics,
        received: {},
      },
    };
  case CampaignActionType.SAVE_CAMPAIGN_FAILED_VERIFICATION_STATISTICS:
    return {
      ...state,
      campaignVerificationStatistics: {
        ...state.campaignVerificationStatistics,
        failed: payload.data,
      },
    };
  case CampaignActionType.RESET_CAMPAIGN_FAILED_VERIFICATION_STATISTICS:
    return {
      ...state,
      campaignVerificationStatistics: {
        ...state.campaignVerificationStatistics,
        failed: {},
      },
    };
  case CampaignActionType.SAVE_CAMPAIGN_RECEIVED_SIGN_UP_STATISTICS:
    return {
      ...state,
      campaignSignUpStatistics: {
        ...state.campaignSignUpStatistics,
        received: payload.data,
      },
    };
  case CampaignActionType.RESET_CAMPAIGN_RECEIVED_SIGN_UP_STATISTICS:
    return {
      ...state,
      campaignSignUpStatistics: {
        ...state.campaignSignUpStatistics,
        received: {},
      },
    };
  case CampaignActionType.SAVE_CAMPAIGN_FAILED_SIGN_UP_STATISTICS:
    return {
      ...state,
      campaignSignUpStatistics: {
        ...state.campaignSignUpStatistics,
        failed: payload.data,
      },
    };
  case CampaignActionType.RESET_CAMPAIGN_FAILED_SIGN_UP_STATISTICS:
    return {
      ...state,
      campaignSignUpStatistics: {
        ...state.campaignSignUpStatistics,
        failed: {},
      },
    };
  case CampaignActionType.SAVE_CAMPAIGN_RECEIVED_NO_TRIGGER_STATISTICS:
    return {
      ...state,
      campaignNoTriggerStatistics: {
        ...state.campaignNoTriggerStatistics,
        received: payload.data,
      },
    };
  case CampaignActionType.RESET_CAMPAIGN_RECEIVED_NO_TRIGGER_STATISTICS:
    return {
      ...state,
      campaignNoTriggerStatistics: {
        ...state.campaignNoTriggerStatistics,
        received: {},
      },
    };
  case CampaignActionType.SAVE_CAMPAIGN_FAILED_NO_TRIGGER_STATISTICS:
    return {
      ...state,
      campaignNoTriggerStatistics: {
        ...state.campaignNoTriggerStatistics,
        failed: payload.data,
      },
    };
  case CampaignActionType.RESET_CAMPAIGN_FAILED_NO_TRIGGER_STATISTICS:
    return {
      ...state,
      campaignNoTriggerStatistics: {
        ...state.campaignNoTriggerStatistics,
        failed: {},
      },
    };
  case CampaignActionType.SAVE_CROSS_PLATFORM_CAMPAIGNS:
    return {
      ...state,
      crossPlatformCampaigns: payload.data,
    };
  case CampaignActionType.RESET_CROSS_PLATFORM_CAMPAIGNS:
    return {
      ...state,
      crossPlatformCampaigns: {
        data: [],
        total: 0,
      },
    };
  case CampaignActionType.SAVE_CROSS_PLATFORM_CAMPAIGN:
    return {
      ...state,
      crossPlatformCampaign: payload.data,
    };
  case CampaignActionType.RESET_CROSS_PLATFORM_CAMPAIGN:
    return {
      ...state,
      crossPlatformCampaign: {},
    };
  case CampaignActionType.SAVE_CROSS_PLATFORM_CAMPAIGN_NO_TRIGGER_STATISTICS:
    return {
      ...state,
      crossPlatformNoTriggerStatistics: payload.data,
    };
  case CampaignActionType.RESET_CROSS_PLATFORM_CAMPAIGN_NO_TRIGGER_STATISTICS:
    return {
      ...state,
      crossPlatformNoTriggerStatistics: {
        data: [],
        total: 0,
      },
    };
  case CampaignActionType.SAVE_CROSS_PLATFORM_CAMPAIGN_USAGES:
    return {
      ...state,
      crossPlatformUsages: payload.data,
    };
  case CampaignActionType.RESET_CROSS_PLATFORM_CAMPAIGN_USAGES:
    return {
      ...state,
      crossPlatformUsages: {
        data: [],
        total: 0,
      },
    };
  case CampaignActionType.SAVE_CAMPAIGN_CONTENT_CONFIG:
    return {
      ...state,
      contentConfig: payload.data,
    };
  case CampaignActionType.RESET_CAMPAIGN_CONTENT_CONFIG:
    return {
      ...state,
      contentConfig: {},
    };
  case CampaignActionType.CHANGE_CONTENT_CONFIG_LOADING_STATE:
    return {
      ...state,
      contentLoading: payload.state,
    };
  case CampaignActionType.CHANGE_CAMPAIGN_LOADING_STATE:
    return {
      ...state,
      campaignLoading: payload.state,
    };
  case CampaignActionType.SAVE_CAMPAIGN_PARTICIPANT_PLAYERS:
    return {
      ...state,
      campaignParticipants: payload.data,
    };
  case CampaignActionType.RESET_CAMPAIGN_PARTICIPANT_PLAYERS:
    return {
      ...state,
      campaignParticipants: [],
    };
  case CampaignActionType.CHANGE_CAMPAIGN_PLAYERS_LOADING_STATE:
    return {
      ...state,
      playersLoading: payload.state,
    };
  case CampaignActionType.SAVE_CROSS_PLATFORM_CONTENT_CONFIG:
    return {
      ...state,
      crossPlatformContentConfig: payload.data,
    };
  case CampaignActionType.RESET_CROSS_PLATFORM_CONTENT_CONFIG:
    return {
      ...state,
      crossPlatformContentConfig: [],
    };
  case CampaignActionType.SAVE_CAMPAIGN_RECEIVED_SIGN_IN_STATISTICS:
    return {
      ...state,
      campaignSignInStatistics: {
        ...state.campaignSignInStatistics,
        received: payload.data,
      },
    };
  case CampaignActionType.RESET_CAMPAIGN_RECEIVED_SIGN_IN_STATISTICS:
    return {
      ...state,
      campaignSignInStatistics: {
        ...state.campaignSignInStatistics,
        received: {},
      },
    };
  case CampaignActionType.SAVE_CAMPAIGN_FAILED_SIGN_IN_STATISTICS:
    return {
      ...state,
      campaignSignInStatistics: {
        ...state.campaignSignInStatistics,
        failed: payload.data,
      },
    };
  case CampaignActionType.RESET_CAMPAIGN_FAILED_SIGN_IN_STATISTICS:
    return {
      ...state,
      campaignSignInStatistics: {
        ...state.campaignSignInStatistics,
        failed: {},
      },
    };
  default:
    return state;
  }
};

export default campaignReducer;
