import _ from 'lodash';
import { ExclamationCircleFilled } from '@ant-design/icons';
import { StyledMessageCloseIcon } from 'Global.styled';
import IconSvg from 'components/common/icon/IconSvg';
import { getRandomNumber } from 'utils/utils';

const MessageService = {
  message: null,

  setMessage: (message) => {
    MessageService.message = message;
  },

  destroy: (key) => MessageService.message?.destroy(key),

  getMessageDetails: (msgContent) => {
    const msg = _.isString(msgContent) ? msgContent : msgContent?.content;
    const key = getRandomNumber();

    return {
      content: (
        <>
          {msg}
          <StyledMessageCloseIcon onClick={() => MessageService.destroy(key)}>
            <IconSvg icon="CloseModalIcon" width="12px" height="12px" color="" />
          </StyledMessageCloseIcon>
        </>
      ),
      key,
    };
  },

  error: (msgData) => {
    const { content, key } = MessageService.getMessageDetails(msgData);

    return MessageService.message?.error({
      content,
      onClose: () => msgData?.onClose?.(),
      icon: <ExclamationCircleFilled />,
      key,
    });
  },

  success: (msgData) => {
    const { content, key } = MessageService.getMessageDetails(msgData);
    return MessageService.message?.success({
      content,
      onClose: () => msgData?.onClose?.(),
      key,
    });
  },
};

export default MessageService;
