import {
  all, call, put, select, takeLatest,
} from 'redux-saga/effects';
import qs from 'qs';
import NotificationActionType from 'actions/notification/notificationActionType';
import {
  changeLoadingState, setErrorMessage, setSuccessMessage, changeEndedStatus,
} from 'actions/setting/settingActionCreator';
import {
  changeNotificationSentStatus,
  saveTemplateTagsResource,
  saveMassMessagesState,
  saveMessagesTotal,
  getMassMessageSuccess,
  saveInboxMessages,
  saveMassMessageRecipients,
  saveTitleExists,
  saveBonusNotification,
  saveBonusNotifications,
} from 'actions/notification/notificationActionCreator';
import NetworkService from 'services/networkService';
import { getError, getMessage } from 'utils/helpers';
import { AppConstants } from 'constants/index';
import {
  selectAuthUser, selectInboxMessages, selectMassMessages, selectMessagesTotal, selectPlayerMessages,
} from 'selectors';
import { savePlayerMessages } from 'actions/player/playerActionCreator';
import { i18n, DateService } from 'services';

const { getFormattedDate } = DateService;

function* getMassMessage({ payload }) {
  try {
    const { NotificationAdmin, MassMessages } = AppConstants.api;
    yield put(changeLoadingState(true));
    const response = yield call(NetworkService.makeAPIGetRequest, [NotificationAdmin, MassMessages, payload.id]);
    yield put(getMassMessageSuccess(response.data));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err, true);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* saveMassMessages({ payload }) {
  try {
    const { isDraft, scheduleTime } = payload.data;
    const { NotificationAdmin, MassMessages } = AppConstants.api;
    yield put(changeLoadingState(true));
    yield call(NetworkService.makeAPIPostRequest, [NotificationAdmin, MassMessages], payload);
    let message = i18n.t('notification:slSent');
    if (isDraft) {
      message = i18n.t('notification:slSavedAsDraft');
    } else if (scheduleTime) {
      message = i18n.t('notification:slScheduledForDate', { date: getFormattedDate(scheduleTime, AppConstants.format.dateTime) });
    }
    yield put(setSuccessMessage(getMessage(i18n.t('communication:message'), message)));
    yield put(changeLoadingState(false));
    yield put(changeNotificationSentStatus(true));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* updateMassMessage({ payload }) {
  try {
    const { isDraft, scheduleTime } = payload.data;
    const { NotificationAdmin, MassMessages } = AppConstants.api;
    yield put(changeLoadingState(true));
    yield call(NetworkService.makeAPIPutRequest, [NotificationAdmin, MassMessages], payload);
    yield put(changeEndedStatus(true));
    let message = i18n.t('notification:slSent');
    if (isDraft) {
      message = i18n.t('notification:slSavedAsDraft');
    } else if (scheduleTime) {
      message = i18n.t('notification:slScheduledForDate', { date: getFormattedDate(scheduleTime, AppConstants.format.dateTime) });
    }
    yield put(setSuccessMessage(getMessage(i18n.t('communication:message'), message)));
    yield put(changeLoadingState(false));
    yield put(changeNotificationSentStatus(true));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* moveToDraft({ payload }) {
  try {
    const { NotificationAdmin, MassMessages, MoveToDraft } = AppConstants.api;
    yield put(changeLoadingState(true));
    yield call(NetworkService.makeAPIPutRequest, [NotificationAdmin, MassMessages, MoveToDraft, payload.id]);
    yield put(changeEndedStatus(true));
    yield put(setSuccessMessage(getMessage(i18n.t('communication:message'), i18n.t('notification:movedToDraft'))));
    yield put(changeLoadingState(false));
    yield put(changeNotificationSentStatus(true));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getSystemTemplateTagsResource({ payload }) {
  const { brandId, typeId, params } = payload;
  const options = {
    params,
    paramsSerializer: (param) => qs.stringify(param),
  };
  try {
    const {
      NotificationAdmin, SystemTemplate, Tags, Resource,
    } = AppConstants.api;
    const response = yield call(NetworkService.makeAPIGetRequest, [NotificationAdmin, SystemTemplate, Tags, Resource, brandId, typeId], options);
    const data = response.data.length ? response.data.map((el) => ({ key: el.systemTag.tag })) : response.data;
    yield put(saveTemplateTagsResource(data));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
  }
}

function* getCustomTemplateTagsResource({ payload }) {
  try {
    const {
      NotificationAdmin, MassMessages, Tags, Resource,
    } = AppConstants.api;
    const { params } = payload;
    const options = {
      params,
    };
    const response = yield call(NetworkService.makeAPIGetRequest, [NotificationAdmin, MassMessages, Tags, Resource], options);
    yield put(saveTemplateTagsResource(response.data));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
  }
}

function* getSentMassMessages({ payload }) {
  try {
    const options = {
      params: payload.data,
      paramsSerializer: (params) => qs.stringify(params),
    };
    yield put(changeLoadingState(true));
    const { NotificationAdmin, MassMessages, Sent } = AppConstants.api;
    const response = yield call(NetworkService.makeAPIGetRequest, [NotificationAdmin, MassMessages, Sent], options);
    const { massMessages, total } = response.data;
    const state = yield select();
    const massMessagesState = selectMassMessages(state.notification);
    const totalState = selectMessagesTotal(state.notification);
    yield put(
      saveMassMessagesState({
        ...massMessagesState,
        sent: massMessages,
      }),
    );
    yield put(
      saveMessagesTotal({
        ...totalState,
        sent: total,
      }),
    );
    yield put(changeLoadingState(false));
  } catch (err) {
    yield put(changeLoadingState(false));
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
  }
}

function* getScheduledMassMessages({ payload }) {
  try {
    const options = {
      params: payload.data,
      paramsSerializer: (params) => qs.stringify(params),
    };
    yield put(changeLoadingState(true));
    const { NotificationAdmin, MassMessages, Scheduled } = AppConstants.api;
    const response = yield call(NetworkService.makeAPIGetRequest, [NotificationAdmin, MassMessages, Scheduled], options);
    const { massMessages, total } = response.data;
    const state = yield select();
    const massMessagesState = selectMassMessages(state.notification);
    const totalState = selectMessagesTotal(state.notification);
    yield put(
      saveMassMessagesState({
        ...massMessagesState,
        scheduled: massMessages,
      }),
    );
    yield put(
      saveMessagesTotal({
        ...totalState,
        scheduled: total,
      }),
    );
    yield put(changeLoadingState(false));
  } catch (err) {
    yield put(changeLoadingState(false));
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
  }
}

function* getDraftMassMessage({ payload }) {
  try {
    const options = {
      params: payload.data,
      paramsSerializer: (params) => qs.stringify(params),
    };
    yield put(changeLoadingState(true));
    const { NotificationAdmin, MassMessages, Draft } = AppConstants.api;
    const response = yield call(NetworkService.makeAPIGetRequest, [NotificationAdmin, MassMessages, Draft], options);
    const { massMessages, total } = response.data;
    const state = yield select();
    const massMessagesState = selectMassMessages(state.notification);
    const totalState = selectMessagesTotal(state.notification);
    yield put(
      saveMassMessagesState({
        ...massMessagesState,
        draft: massMessages,
      }),
    );
    yield put(
      saveMessagesTotal({
        ...totalState,
        draft: total,
      }),
    );
    yield put(changeLoadingState(false));
  } catch (err) {
    yield put(changeLoadingState(false));
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
  }
}

function* deleteMassMessage({ payload }) {
  try {
    const { id, name } = payload;
    yield put(changeLoadingState(true));
    const { NotificationAdmin, MassMessages } = AppConstants.api;
    yield call(NetworkService.makeAPIDeleteRequest, [NotificationAdmin, MassMessages, id]);
    yield put(changeEndedStatus(true));
    yield put(setSuccessMessage(getMessage(i18n.t('communication:lMessage'), i18n.t('notification:slDeleted'), name)));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getInboxMessages({ payload }) {
  try {
    const options = {
      params: payload.data,
      paramsSerializer: (param) => qs.stringify(param),
    };
    yield put(changeLoadingState(true));
    const { NotificationAdmin, Inbox, GetByBrand } = AppConstants.api;
    const response = yield call(NetworkService.makeAPIGetRequest, [NotificationAdmin, Inbox, GetByBrand, payload.id], options);
    const { data, total } = response.data;
    const state = yield select();
    const totalState = selectMessagesTotal(state.notification);
    yield put(saveInboxMessages(data));
    yield put(
      saveMessagesTotal({
        ...totalState,
        inbox: total,
      }),
    );
    yield put(changeLoadingState(false));
  } catch (err) {
    yield put(changeLoadingState(false));
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
  }
}

function* getInboxMessage({ payload }) {
  try {
    yield put(changeLoadingState(true));
    const { NotificationAdmin, Inbox } = AppConstants.api;
    const { data } = yield call(NetworkService.makeAPIGetRequest, [NotificationAdmin, Inbox, payload.id]);
    const state = yield select();
    const massMessages = payload.isPlayer ? selectPlayerMessages(state.player) : selectInboxMessages(state.notification);
    const messages = payload.isPlayer ? massMessages.inbox : massMessages;
    const newData = messages.map((ms) => {
      if (ms.id === payload.id) return data;
      return ms;
    });
    yield put(
      payload.isPlayer
        ? savePlayerMessages({
          ...massMessages,
          inbox: newData,
        })
        : saveInboxMessages(newData),
    );
    yield put(changeLoadingState(false));
  } catch (err) {
    yield put(changeLoadingState(false));
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
  }
}

function* getMassMessageRecipients({ payload }) {
  try {
    const options = {
      params: payload.data,
      paramsSerializer: (params) => qs.stringify(params),
    };
    yield put(changeLoadingState(true));
    const { NotificationAdmin, MassMessages, RecipientsDetail } = AppConstants.api;
    const { data } = yield call(NetworkService.makeAPIGetRequest, [NotificationAdmin, MassMessages, RecipientsDetail], options);
    yield put(saveMassMessageRecipients(data));
    yield put(changeLoadingState(false));
  } catch (err) {
    yield put(changeLoadingState(false));
    if (err) {
      const error = getError(err, true);
      yield put(setErrorMessage(error));
    }
  }
}

function* changeInboxMessageReplyState({ payload }) {
  try {
    const { id, canReply } = payload;
    const options = { params: { canReply } };
    const { NotificationAdmin, Inbox, CanReply } = AppConstants.api;
    yield put(changeLoadingState(true));
    yield call(NetworkService.makeAPIPostRequest, [NotificationAdmin, Inbox, CanReply, id], options);

    const state = yield select();
    const massMessages = payload.isPlayer ? selectPlayerMessages(state.player) : selectInboxMessages(state.notification);
    const messages = payload.isPlayer ? massMessages.inbox : massMessages;
    const newData = messages.map((ms) => {
      if (ms.id === payload.id) return { ...ms, canReply };
      return ms;
    });
    yield put(
      payload.isPlayer
        ? savePlayerMessages({
          ...massMessages,
          inbox: newData,
        })
        : saveInboxMessages(newData),
    );

    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* replyInboxMessage({ payload }) {
  try {
    const options = { data: payload.data };
    const { NotificationAdmin, Inbox } = AppConstants.api;
    yield put(changeLoadingState(true));
    const { data } = yield call(NetworkService.makeAPIPutRequest, [NotificationAdmin, Inbox, payload.id], options);

    const state = yield select();
    const massMessages = payload.isPlayer ? selectPlayerMessages(state.player) : selectInboxMessages(state.notification);
    const messages = payload.isPlayer ? massMessages.inbox : massMessages;
    const newData = messages.map((ms) => {
      if (ms.id === payload.id) return data;
      return ms;
    });
    yield put(
      payload.isPlayer
        ? savePlayerMessages({
          ...massMessages,
          inbox: newData,
        })
        : saveInboxMessages(newData),
    );

    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* recallInboxMessage({ payload }) {
  try {
    const { id, index } = payload;
    const options = {
      params: { index },
    };
    const { NotificationAdmin, Inbox, Recall } = AppConstants.api;
    yield put(changeLoadingState(true));
    yield call(NetworkService.makeAPIDeleteRequest, [NotificationAdmin, Inbox, Recall, id], options);

    const state = yield select();
    const { firstName, lastName } = selectAuthUser(state.auth);

    const massMessages = payload.isPlayer ? selectPlayerMessages(state.player) : selectInboxMessages(state.notification);
    const messages = payload.isPlayer ? massMessages.inbox : massMessages;
    const newData = messages.map((ms) => {
      if (ms.id === id) {
        const arr = [...ms.contents];
        arr[index] = {
          ...arr[index],
          isRecalled: true,
        };
        return { ...ms, contents: arr };
      }
      return ms;
    });
    yield put(
      payload.isPlayer
        ? savePlayerMessages({
          ...massMessages,
          inbox: newData,
        })
        : saveInboxMessages(newData),
    );

    yield put(setSuccessMessage(i18n.t('notification:recalledMessageSuccess', { fullName: `${firstName} ${lastName}` })));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* markInboxMessageAsRead({ payload }) {
  try {
    const { id } = payload;
    const { NotificationAdmin, Inbox, MarkAsRead } = AppConstants.api;
    yield put(changeLoadingState(true));
    const { data } = yield call(NetworkService.makeAPIPostRequest, [NotificationAdmin, Inbox, MarkAsRead, id]);

    const state = yield select();
    const massMessages = payload.isPlayer ? selectPlayerMessages(state.player) : selectInboxMessages(state.notification);
    const messages = payload.isPlayer ? massMessages.inbox : massMessages;
    const newData = messages.map((ms) => {
      if (ms.id === id) {
        return data;
      }
      return ms;
    });
    yield put(
      payload.isPlayer
        ? savePlayerMessages({
          ...massMessages,
          inbox: newData,
        })
        : saveInboxMessages(newData),
    );

    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* checkTitleExists({ payload }) {
  try {
    const { brandId, params, id } = payload;
    const { NotificationAdmin, MassMessages, TitleExists } = AppConstants.api;
    const { data } = yield call(NetworkService.makeAPIGetRequest, [NotificationAdmin, MassMessages, TitleExists, brandId, id], { params });
    yield put(saveTitleExists(data));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
  }
}

function* getBonusNotifications({ payload }) {
  try {
    const { params, id } = payload;
    const options = {
      params,
    };
    yield put(changeLoadingState(true));
    const { NotificationAdmin, BonusNotifications, ByPlayer } = AppConstants.api;
    const { data } = yield call(NetworkService.makeAPIGetRequest, [NotificationAdmin, BonusNotifications, ByPlayer, id], options);
    yield put(saveBonusNotifications(data));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getBonusNotification({ payload }) {
  try {
    const { messageId, playerId } = payload;
    const options = {
      params: { messageId },
    };
    yield put(changeLoadingState(true));
    const { NotificationAdmin, BonusNotifications, MessageByPlayer } = AppConstants.api;
    const { data } = yield call(NetworkService.makeAPIGetRequest, [NotificationAdmin, BonusNotifications, MessageByPlayer, playerId], options);
    yield put(saveBonusNotification(data));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

export default function* notificationSaga() {
  yield all([
    takeLatest(NotificationActionType.GET_MASS_MESSAGE, getMassMessage),
    takeLatest(NotificationActionType.UPDATE_MASS_MESSAGE, updateMassMessage),
    takeLatest(NotificationActionType.MOVE_TO_DRAFT, moveToDraft),
    takeLatest(NotificationActionType.SAVE_MASS_MESSAGES, saveMassMessages),
    takeLatest(NotificationActionType.GET_CUSTOM_TEMPLATE_TAGS_RESOURCE, getCustomTemplateTagsResource),
    takeLatest(NotificationActionType.GET_SYSTEM_TEMPLATE_TAGS_RESOURCE, getSystemTemplateTagsResource),
    takeLatest(NotificationActionType.GET_SENT_MASS_MESSAGES, getSentMassMessages),
    takeLatest(NotificationActionType.GET_SCHEDULED_MASS_MESSAGES, getScheduledMassMessages),
    takeLatest(NotificationActionType.GET_DRAFT_MASS_MESSAGES, getDraftMassMessage),
    takeLatest(NotificationActionType.DELETE_MASS_MESSAGE, deleteMassMessage),
    takeLatest(NotificationActionType.GET_INBOX_MESSAGES, getInboxMessages),
    takeLatest(NotificationActionType.GET_INBOX_MESSAGE, getInboxMessage),
    takeLatest(NotificationActionType.CHANGE_INBOX_MESSAGE_REPLY_STATE, changeInboxMessageReplyState),
    takeLatest(NotificationActionType.REPLY_INBOX_MESSAGE, replyInboxMessage),
    takeLatest(NotificationActionType.RECALL_INBOX_MESSAGE, recallInboxMessage),
    takeLatest(NotificationActionType.MARK_INBOX_MESSAGE_AS_READ, markInboxMessageAsRead),
    takeLatest(NotificationActionType.GET_MASS_MESSAGE_RECIPIENTS, getMassMessageRecipients),
    takeLatest(NotificationActionType.CHECK_TITLE_EXISTS, checkTitleExists),
    takeLatest(NotificationActionType.GET_BONUS_NOTIFICATIONS, getBonusNotifications),
    takeLatest(NotificationActionType.GET_BONUS_NOTIFICATION, getBonusNotification),
  ]);
}
