import {
  all, call, put, takeLatest,
} from 'redux-saga/effects';
import qs from 'qs';
import mime from 'mimetypes';
import { changeLoadingState, setErrorMessage } from 'actions/setting/settingActionCreator';
import NetworkService from 'services/networkService';
import { AppConstants } from 'constants/index';
import AccountUpdatesActionType from 'actions/accountUpdates/accountUpdatesActionType';
import { savePlayerAccountUpdates, savePlayerProfileControlNames } from 'actions/accountUpdates/accountUpdatesActionCreator';
import { downloadFile } from 'utils/utils';
import { getError } from 'utils/helpers';
import { i18n } from 'services';

function* getPlayerAccountUpdates({ payload }) {
  try {
    const { params, playerId } = payload;
    const options = {
      params,
      paramsSerializer: (param) => qs.stringify(param),
    };
    const { ReportingAdmin, Player, AccountUpdate } = AppConstants.api;
    yield put(changeLoadingState(true));
    const { data } = yield call(NetworkService.makeAPIGetRequest, [ReportingAdmin, Player, AccountUpdate, playerId], options);
    yield put(savePlayerAccountUpdates(data));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getPlayerProfileControlNames({ payload }) {
  const { isLoading, params } = payload;
  try {
    const options = {
      params,
    };
    const { BuilderAdmin, UiCustomization, GetPlayerProfileControlNames } = AppConstants.api;

    if (isLoading) {
      yield put(changeLoadingState(true));
    }

    const { data } = yield call(NetworkService.makeAPIGetRequest, [BuilderAdmin, UiCustomization, GetPlayerProfileControlNames], options);
    yield put(savePlayerProfileControlNames(data));

    if (isLoading) {
      yield put(changeLoadingState(false));
    }
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    if (isLoading) {
      yield put(changeLoadingState(false));
    }
  }
}

function* exportPlayerAccountUpdates({ payload }) {
  try {
    const { playerId, params } = payload;
    const options = {
      params,
      paramsSerializer: (param) => qs.stringify(param, { allowDots: true }),
      responseType: 'blob',
    };
    yield put(changeLoadingState(true));
    const {
      ReportingAdmin, Player, AccountUpdate, Export,
    } = AppConstants.api;
    const { data, headers } = yield call(NetworkService.makeAPIGetRequest, [ReportingAdmin, Player, AccountUpdate, Export, playerId], options);
    downloadFile(data, i18n.t('title:accountUpdates'), mime.detectExtension(headers['content-type']));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

export default function* accountUpdatesSaga() {
  yield all([
    takeLatest(AccountUpdatesActionType.GET_PLAYER_ACCOUNT_UPDATES, getPlayerAccountUpdates),
    takeLatest(AccountUpdatesActionType.GET_PLAYER_PROFILE_CONTROL_NAMES, getPlayerProfileControlNames),
    takeLatest(AccountUpdatesActionType.EXPORT_PLAYER_ACCOUNT_UPDATES, exportPlayerAccountUpdates),
  ]);
}
