import {
  put, call, takeLatest, all,
} from 'redux-saga/effects';
import NetworkService from 'services/networkService';
import { changeLoadingState, setErrorMessage } from 'actions/setting/settingActionCreator';
import { saveSearchResult } from 'actions/quickSearch/quickSearchActionCreator';
import QuickSearchActionType from 'actions/quickSearch/quickSearchActionType';
import { getError } from 'utils/helpers';
import { AppConstants } from 'constants/index';

function* getPlayerSearchResult({ payload }) {
  try {
    const { searchKey, params } = payload;
    const options = { params };
    const { ReportingAdmin, QuickSearch, Players } = AppConstants.api;
    yield put(changeLoadingState(true));
    const { data } = yield call(NetworkService.makeAPIGetRequest, [ReportingAdmin, QuickSearch, Players], options);
    yield put(
      saveSearchResult({
        searchKey,
        searchResult: data,
      }),
    );
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getPaymentSearchResult({ payload }) {
  try {
    const { searchKey, params } = payload;
    const { ReportingAdmin, QuickSearch, PaymentRequest } = AppConstants.api;
    yield put(changeLoadingState(true));
    const { data } = yield call(NetworkService.makeAPIGetRequest, [ReportingAdmin, QuickSearch, PaymentRequest, params.paymentRequestId]);
    yield put(
      saveSearchResult({
        searchKey,
        searchResult: data,
      }),
    );
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getBetSearchResult({ payload }) {
  try {
    const { searchKey, params } = payload;
    const options = { params };
    const { ReportingAdmin, QuickSearch, Bet } = AppConstants.api;
    yield put(changeLoadingState(true));
    const { data } = yield call(NetworkService.makeAPIGetRequest, [ReportingAdmin, QuickSearch, Bet], options);
    yield put(
      saveSearchResult({
        searchKey,
        searchResult: data,
      }),
    );
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

export default function* quickSearchSaga() {
  yield all([
    takeLatest(QuickSearchActionType.GET_PLAYER_SEARCH_RESULT, getPlayerSearchResult),
    takeLatest(QuickSearchActionType.GET_PAYMENT_SEARCH_RESULT, getPaymentSearchResult),
    takeLatest(QuickSearchActionType.GET_BET_SEARCH_RESULT, getBetSearchResult),
  ]);
}
