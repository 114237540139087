import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { StyledModal, StyledModalQuestion, SaveDiscardButtonsGroup } from 'components/styledComponents/modal/Modal.styled';
import { StyledButtonBlue, StyledButtonBlueUnbordered } from 'components/styledComponents/button/Button.styled';
import { StyledIconDefault } from 'components/styledComponents/icon/Icon.styled';
import { IconSvg } from 'components/common';

const ConfirmModal = ({
  visible, changeModalState, text, handleConfirm, handleCancel, okText, closeText, autoHeight, propsWidth, hideClose, title,
}) => {
  const { t } = useTranslation();

  // at first close modal, than call handle Confirm , it's important in translation
  const onOk = () => {
    changeModalState(false);
    handleConfirm();
  };

  const onCancel = () => {
    changeModalState(false);
    handleCancel();
  };

  return (
    <StyledModal
      getContainer=".ant-layout-content"
      open={visible}
      onOk={onOk}
      onCancel={onCancel}
      width={propsWidth}
      closable
      centered
      title={title}
      mdPaddings
      closeIcon={(
        <StyledIconDefault>
          <IconSvg icon="CloseModalIcon" width="1.14rem" height="1.14rem" />
        </StyledIconDefault>
      )}
      height={autoHeight ? 'auto' : '16.14rem'}
      zIndex={10000}
      className={autoHeight ? 'autoModal' : 'confirm-modal'}
      footer={(
        <SaveDiscardButtonsGroup isCenter={!hideClose}>
          {!hideClose && (
            <StyledButtonBlueUnbordered type="submit" onClick={onCancel} size="large">
              {t(closeText)}
            </StyledButtonBlueUnbordered>
          )}
          <StyledButtonBlue htmlType="submit" onClick={onOk} size="large">
            {t(okText)}
          </StyledButtonBlue>
        </SaveDiscardButtonsGroup>
      )}
    >
      <StyledModalQuestion>{text}</StyledModalQuestion>
    </StyledModal>
  );
};

ConfirmModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  propsWidth: PropTypes.string,
  text: PropTypes.any.isRequired,
  changeModalState: PropTypes.func.isRequired,
  handleConfirm: PropTypes.func,
  handleCancel: PropTypes.func,
  okText: PropTypes.string,
  closeText: PropTypes.string,
  autoHeight: PropTypes.bool,
  hideClose: PropTypes.bool,
  title: PropTypes.string,
};

ConfirmModal.defaultProps = {
  handleConfirm: () => {},
  handleCancel: () => {},
  okText: 'yes',
  closeText: 'cancel',
  autoHeight: false,
  propsWidth: '26.3rem',
  hideClose: false,
  title: undefined,
};

export default ConfirmModal;
