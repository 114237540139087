import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Header from 'components/layout/header/Header';
import { selectAuthTokenState } from 'selectors';
import { changeTokenState } from 'actions/auth/authActionCreator';

const mapDispatchToProps = (dispatch) => bindActionCreators(
  {
    changeTokenState,
  },
  dispatch,
);

const mapStateToProps = ({ auth }) => ({
  isTokenChanged: selectAuthTokenState(auth),
});

export default connect(mapStateToProps, mapDispatchToProps)(Header);
