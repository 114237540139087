import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  getPlayerSearchResult, resetSearchResult, getBetSearchResult, getPaymentSearchResult,
} from 'actions/quickSearch/quickSearchActionCreator';
import { selectLocaleResource, selectSearchKey, selectSearchResult } from 'selectors';
import enumTypes from 'constants/enumTypes';
import { setCurrentBrand } from 'actions/brand/brandActionCreator';
import SearchFields from 'components/layout/header/SearchFields';

const mapDispatchToProps = (dispatch) => bindActionCreators(
  {
    getPlayerSearchResult,
    getBetSearchResult,
    getPaymentSearchResult,
    resetSearchResult,
    setCurrentBrand,
  },
  dispatch,
);

const mapStateToProps = ({ quickSearch, locale }) => ({
  searchResult: selectSearchResult(quickSearch),
  searchKey: selectSearchKey(quickSearch),
  locale: selectLocaleResource(locale, { quickSearchResponseItemType: enumTypes.quickSearchResponseItemType }),
});

export default connect(mapStateToProps, mapDispatchToProps)(SearchFields);
