import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { AdminTabs } from 'components/common/tab';
import { createOrEditUserConfig } from 'actions/user/userActionCreator';
import { selectUserConfig } from 'selectors';
import { changeLoadingState } from 'actions/setting/settingActionCreator';

const mapStateToProps = ({ users }, ownProps) => ({
  userTabConfigs: selectUserConfig(users, ownProps?.tabKey),
});

const mapDispatchToProps = (dispatch) => bindActionCreators(
  {
    createOrEditUserConfig,
    changeLoadingState,
  },
  dispatch,
);

export default connect(mapStateToProps, mapDispatchToProps)(AdminTabs);
