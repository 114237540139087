import { Resizable } from 'react-resizable';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { StyledResizableTitle, StyledResizableHandle } from 'components/styledComponents/table/Table.styled';

const ResizeableTitle = (props) => {
  const {
    onResize, width, onResizeStop, onResizeStart, minWidth, ...restProps
  } = props;

  const [isResizing, setIsResizing] = useState(false);

  if (!width) {
    return <th {...restProps} />;
  }

  const handleResizeStart = (e) => {
    e.stopPropagation();
    onResizeStart(e);
    setIsResizing(true);
  };

  const handleResizeStop = (e) => {
    onResizeStop(e);
    setTimeout(() => {
      setIsResizing(false);
    }, 0);
  };

  return (
    <Resizable
      width={width}
      height={0}
      onResizeStop={handleResizeStop}
      onResizeStart={handleResizeStart}
      onResize={onResize}
      minConstraints={[minWidth, 0]}
      maxConstraints={[520, 0]}
      handle={<StyledResizableHandle className="react-resizable-handle" />}
      draggableOpts={{ enableUserSelectHack: false }}
      axis="x"
      className={isResizing ? 'ant-table-cell-resizing' : ''}
    >
      <StyledResizableTitle {...restProps} {...(isResizing && { onClick: (e) => e.stopPropagation() })} isResizing={isResizing ? 1 : 0} width={width} />
    </Resizable>
  );
};

ResizeableTitle.propTypes = {
  onResize: PropTypes.func,
  onResizeStop: PropTypes.func,
  onResizeStart: PropTypes.func,
  width: PropTypes.number,
  minWidth: PropTypes.number,
};

ResizeableTitle.defaultProps = {
  width: null,
  minWidth: 64,
  onResizeStop: null,
  onResizeStart: null,
  onResize: null,
};

export default ResizeableTitle;
