import PlayerPerformanceActionType from 'actions/playerPerformance/playerPerformanceActionType';

const initialState = {
  walletAggregation: {},
  walletAggregationLoading: null,
  transactionAggregation: {},
  transactionAggregationLoading: null,
  aggregations: {},
  mixedTransaction: {},
  mixedTransactionLoading: null,
};

const playerPerformanceReducer = (state = initialState, { type, payload }) => {
  switch (type) {
  case PlayerPerformanceActionType.SAVE_PLAYER_WALLET_AGGREGATION:
    return {
      ...state,
      walletAggregation: payload.data,
    };
  case PlayerPerformanceActionType.RESET_PLAYER_WALLET_AGGREGATION:
    return {
      ...state,
      walletAggregation: {},
    };
  case PlayerPerformanceActionType.CHANGE_WALLET_LOADING_STATE:
    return {
      ...state,
      walletAggregationLoading: payload,
    };
  case PlayerPerformanceActionType.SAVE_PLAYER_TRANSACTION_AGGREGATION:
    return {
      ...state,
      transactionAggregation: payload.data,
    };
  case PlayerPerformanceActionType.RESET_PLAYER_TRANSACTION_AGGREGATION:
    return {
      ...state,
      transactionAggregation: {},
    };
  case PlayerPerformanceActionType.CHANGE_TRANSACTION_LOADING_STATE:
    return {
      ...state,
      transactionAggregationLoading: payload,
    };
  case PlayerPerformanceActionType.SAVE_PLAYERS_AGGREGATIONS:
    return {
      ...state,
      aggregations: payload.data,
    };
  case PlayerPerformanceActionType.RESET_PLAYERS_AGGREGATIONS:
    return {
      ...state,
      aggregations: {},
    };
  case PlayerPerformanceActionType.SAVE_PLAYER_MIXED_TRANSACTION:
    return {
      ...state,
      mixedTransaction: payload.data,
    };
  case PlayerPerformanceActionType.RESET_PLAYER_MIXED_TRANSACTION:
    return {
      ...state,
      mixedTransaction: {},
    };
  case PlayerPerformanceActionType.CHANGE_MIXED_TRANSACTION_LOADING_STATE:
    return {
      ...state,
      mixedTransactionLoading: payload,
    };
  default:
    return state;
  }
};

export default playerPerformanceReducer;
