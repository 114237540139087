import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Grid, Menu } from 'antd';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import { IconSvg } from 'components/common';
import { handleNavigate } from 'utils/helpers';
import { StyledMenuItem } from './Layout.styled';

const { useBreakpoint } = Grid;

// todo dear front , please review collapsed part,when collapsed= true  sometimes besides selected menuItem previous opened menuItem icon stayed selected.
const prefix = '_';
const iconSizes = [
  { width: '1.29rem', height: '1.29rem', color: '#999999' },
  { width: '1.71rem', height: '1.71rem', color: '#999999' },
];
const SiderMenu = (props) => {
  const {
    menus, mode, page, activeMenu, collapsed, initialSubMenu, setInitialSubMenu, setCollapsed,
  } = props;

  const screens = useBreakpoint();
  const isMobile = !screens.md;
  const { t } = useTranslation();

  const [openKeys, setOpenKeys] = useState([]);
  const [active, setActive] = useState('');
  // local collapse state to catch collapse changing
  const [prevCollapsed, setPrevCollapsed] = useState(collapsed);

  const generateKey = (id) => prefix + id;

  useEffect(() => {
    // when collapse state has updated or initial load - open keys
    if (prevCollapsed !== collapsed || initialSubMenu) {
      const openedMenu = _.find(menus, _.flow(_.property('children'), _.partialRight(_.some, { id: activeMenu })));
      setOpenKeys((prev) => (collapsed ? [] : _.union(prev, openedMenu ? [generateKey(openedMenu.id)] : [])));
      setActive(activeMenu);
      setPrevCollapsed(collapsed);
    }
  }, [active, activeMenu, menus, collapsed, prevCollapsed, initialSubMenu]);

  const handleOpenChange = (keys) => {
    setInitialSubMenu(false);
    setOpenKeys(collapsed && keys.length ? [keys[keys.length - 1]] : keys);
  };

  const handleOpenPage = (e, route) => {
    const { key, path } = route;
    const url = (page ? key : path) || '#';
    // wheel click
    handleNavigate(e, url);
    if (isMobile) {
      setCollapsed(true);
    }
  };

  const generateMenus = (data) => data
    ?.filter(({ hide }) => !hide?.())
    ?.map((item) => {
      if (item.children) {
        return {
          key: generateKey(item.id),
          label: (
            <>
              {item.icon && <IconSvg icon={item.icon} {...iconSizes[+!!page]} />}
              <span>{t(item.name)}</span>
            </>
          ),
          children: generateMenus(item.children),
        };
      }

      return {
        key: generateKey(item.id),
        onMouseDown: (e) => handleOpenPage(e, item),
        label: (
          <StyledMenuItem>
            {item.icon && <IconSvg icon={item.icon} {...iconSizes[+!!page]} />}
            <span>{t(item.name)}</span>
          </StyledMenuItem>
        ),
      };
    });

  const menuProps = _.pickBy({
    mode,
    selectedKeys: activeMenu ? generateKey(activeMenu) : '',
    openKeys,
    onOpenChange: handleOpenChange,
    motion: {},
  });

  return <Menu {...menuProps} items={generateMenus(menus)} />;
};

SiderMenu.propTypes = {
  menus: PropTypes.array,
  mode: PropTypes.string,
  page: PropTypes.string,
  collapsed: PropTypes.bool,
  activeMenu: PropTypes.number.isRequired,
  initialSubMenu: PropTypes.bool.isRequired,
  setInitialSubMenu: PropTypes.func.isRequired,
  setCollapsed: PropTypes.func.isRequired,
};

SiderMenu.defaultProps = {
  menus: [],
  mode: 'inline',
  page: '',
  collapsed: false,
};

export default SiderMenu;
