import ReportingActionType from './reportingActionType';

export const getClosedAccounts = (params) => ({
  type: ReportingActionType.GET_CLOSED_ACCOUNTS,
  payload: { params },
});

export const saveClosedAccounts = (data) => ({
  type: ReportingActionType.SAVE_CLOSED_ACCOUNTS,
  payload: { data },
});

export const resetClosedAccounts = () => ({
  type: ReportingActionType.RESET_CLOSED_ACCOUNTS,
});

export const exportClosedAccounts = (params) => ({
  type: ReportingActionType.EXPORT_CLOSED_ACCOUNTS,
  payload: { params },
});

export const getLargeTransactions = (params) => ({
  type: ReportingActionType.GET_LARGE_TRANSACTIONS,
  payload: { params },
});

export const saveLargeTransactions = (data) => ({
  type: ReportingActionType.SAVE_LARGE_TRANSACTIONS,
  payload: { data },
});

export const resetLargeTransactions = () => ({
  type: ReportingActionType.RESET_LARGE_TRANSACTIONS,
});

export const generateLargeTransactions = (params) => ({
  type: ReportingActionType.GENERATE_LARGE_TRANSACTIONS,
  payload: { params },
});

export const getLargeTransactionsByPlayer = (params) => ({
  type: ReportingActionType.GET_LARGE_TRANSACTIONS_BY_PLAYER,
  payload: { params },
});

export const saveLargeTransactionsByPlayer = (data) => ({
  type: ReportingActionType.SAVE_LARGE_TRANSACTIONS_BY_PLAYER,
  payload: { data },
});

export const resetLargeTransactionsByPlayer = () => ({
  type: ReportingActionType.RESET_LARGE_TRANSACTIONS_BY_PLAYER,
});

export const exportLargeTransactionsByPlayer = (params) => ({
  type: ReportingActionType.EXPORT_LARGE_TRANSACTIONS_BY_PLAYER,
  payload: { params },
});

export const getLargeTransactionsByBrand = (params) => ({
  type: ReportingActionType.GET_LARGE_TRANSACTIONS_BY_BRAND,
  payload: { params },
});

export const saveLargeTransactionsByBrand = (data) => ({
  type: ReportingActionType.SAVE_LARGE_TRANSACTIONS_BY_BRAND,
  payload: { data },
});

export const resetLargeTransactionsByBrand = () => ({
  type: ReportingActionType.RESET_LARGE_TRANSACTIONS_BY_BRAND,
});

export const exportLargeTransactionsByBrand = (params) => ({
  type: ReportingActionType.EXPORT_LARGE_TRANSACTIONS_BY_BRAND,
  payload: { params },
});

export const getNegativeBalance = (brandId, params) => ({
  type: ReportingActionType.GET_NEGATIVE_BALANCE,
  payload: { brandId, params },
});

export const saveNegativeBalance = (data) => ({
  type: ReportingActionType.SAVE_NEGATIVE_BALANCE,
  payload: { data },
});

export const resetNegativeBalance = () => ({
  type: ReportingActionType.RESET_NEGATIVE_BALANCE,
});

export const exportNegativeBalance = (brandId, params) => ({
  type: ReportingActionType.EXPORT_NEGATIVE_BALANCE,
  payload: { brandId, params },
});

export const getBalanceAdjustmentsReport = (brandId, params) => ({
  type: ReportingActionType.GET_BALANCE_ADJUSTMENTS_REPORT,
  payload: { brandId, params },
});

export const saveBalanceAdjustmentsReport = (data) => ({
  type: ReportingActionType.SAVE_BALANCE_ADJUSTMENTS_REPORT,
  payload: { data },
});

export const resetBalanceAdjustmentsReport = () => ({
  type: ReportingActionType.RESET_BALANCE_ADJUSTMENTS_REPORT,
});

export const exportBalanceAdjustmentsReport = (brandId, params) => ({
  type: ReportingActionType.EXPORT_BALANCE_ADJUSTMENTS_REPORT,
  payload: { brandId, params },
});

export const getBiBetByPlayer = (params) => ({
  type: ReportingActionType.GET_BI_BET_BY_PLAYER,
  payload: { params },
});

export const saveBiBetByPlayer = (data) => ({
  type: ReportingActionType.SAVE_BI_BET_BY_PLAYER,
  payload: { data },
});

export const resetBiBetByPlayer = () => ({
  type: ReportingActionType.RESET_BI_BET_BY_PLAYER,
});

export const generateBiBetByPlayer = (params) => ({
  type: ReportingActionType.GENERATE_BI_BET_BY_PLAYER,
  payload: { params },
});

export const getBiBetByCurrency = (params) => ({
  type: ReportingActionType.GET_BI_BET_BY_CURRENCY,
  payload: { params },
});

export const saveBiBetByCurrency = (data) => ({
  type: ReportingActionType.SAVE_BI_BET_BY_CURRENCY,
  payload: { data },
});

export const resetBiBetByCurrency = () => ({
  type: ReportingActionType.RESET_BI_BET_BY_CURRENCY,
});

export const exportBiBetByCurrency = (params) => ({
  type: ReportingActionType.EXPORT_BI_BET_BY_CURRENCY,
  payload: { params },
});

export const getBiFinancialByPlayer = (params) => ({
  type: ReportingActionType.GET_BI_FINANCIAL_BY_PLAYER,
  payload: { params },
});

export const saveBiFinancialByPlayer = (data) => ({
  type: ReportingActionType.SAVE_BI_FINANCIAL_BY_PLAYER,
  payload: { data },
});

export const resetBiFinancialByPlayer = () => ({
  type: ReportingActionType.RESET_BI_FINANCIAL_BY_PLAYER,
});

export const exportBiFinancialByPlayer = (params) => ({
  type: ReportingActionType.EXPORT_BI_FINANCIAL_BY_PLAYER,
  payload: { params },
});

export const getBiFinancialByCurrency = (params) => ({
  type: ReportingActionType.GET_BI_FINANCIAL_BY_CURRENCY,
  payload: { params },
});

export const saveBiFinancialByCurrency = (data) => ({
  type: ReportingActionType.SAVE_BI_FINANCIAL_BY_CURRENCY,
  payload: { data },
});

export const resetBiFinancialByCurrency = () => ({
  type: ReportingActionType.RESET_BI_FINANCIAL_BY_CURRENCY,
});

export const exportBiFinancialByCurrency = (params) => ({
  type: ReportingActionType.EXPORT_BI_FINANCIAL_BY_CURRENCY,
  payload: { params },
});

export const getPlayersWalletsInfo = (params) => ({
  type: ReportingActionType.GET_PLAYERS_WALLETS_INFO,
  payload: { params },
});

export const savePlayersWalletsInfo = (data) => ({
  type: ReportingActionType.SAVE_PLAYERS_WALLETS_INFO,
  payload: { data },
});

export const resetPlayersWalletsInfo = () => ({
  type: ReportingActionType.RESET_PLAYERS_WALLETS_INFO,
});

export const generatePlayersWalletsInfo = (params) => ({
  type: ReportingActionType.GENERATE_PLAYERS_WALLETS_INFO,
  payload: { params },
});

export const getFinancialByProvider = (params) => ({
  type: ReportingActionType.GET_FINANCIAL_BY_PROVIDER,
  payload: { params },
});

export const saveFinancialByProvider = (data) => ({
  type: ReportingActionType.SAVE_FINANCIAL_BY_PROVIDER,
  payload: { data },
});

export const resetFinancialByProvider = () => ({
  type: ReportingActionType.RESET_FINANCIAL_BY_PROVIDER,
});

export const exportFinancialByProvider = (params) => ({
  type: ReportingActionType.EXPORT_FINANCIAL_BY_PROVIDER,
  payload: { params },
});

export const getProviderTopGgrs = (providerId, params) => ({
  type: ReportingActionType.GET_PROVIDER_TOP_GGRS,
  payload: { providerId, params },
});

export const saveProviderTopPositiveGgrs = (data) => ({
  type: ReportingActionType.SAVE_PROVIDER_TOP_POSITIVE_GGRS,
  payload: { data },
});

export const resetProviderTopPositiveGgrs = () => ({
  type: ReportingActionType.RESET_PROVIDER_TOP_POSITIVE_GGRS,
});

export const saveProviderTopNegativeGgrs = (data) => ({
  type: ReportingActionType.SAVE_PROVIDER_TOP_NEGATIVE_GGRS,
  payload: { data },
});

export const resetProviderTopNegativeGgrs = () => ({
  type: ReportingActionType.RESET_PROVIDER_TOP_NEGATIVE_GGRS,
});

export const getPlayersFirstDeposits = (params) => ({
  type: ReportingActionType.GET_PLAYERS_FIRST_DEPOSITS,
  payload: { params },
});

export const savePlayersFirstDeposits = (data) => ({
  type: ReportingActionType.SAVE_PLAYERS_FIRST_DEPOSITS,
  payload: { data },
});

export const resetPlayersFirstDeposits = () => ({
  type: ReportingActionType.RESET_PLAYERS_FIRST_DEPOSITS,
});

export const exportPlayersFirstDeposits = (params) => ({
  type: ReportingActionType.EXPORT_PLAYERS_FIRST_DEPOSITS,
  payload: { params },
});

export const getBonusTotalsByPlayer = (params) => ({
  type: ReportingActionType.GET_BONUS_TOTALS_BY_PLAYER,
  payload: { params },
});

export const saveBonusTotalsByPlayer = (data) => ({
  type: ReportingActionType.SAVE_BONUS_TOTALS_BY_PLAYER,
  payload: { data },
});

export const resetBonusTotalsByPlayer = () => ({
  type: ReportingActionType.RESET_BONUS_TOTALS_BY_PLAYER,
});

export const generateBonusTotalsByPlayerReport = (params) => ({
  type: ReportingActionType.GENERATE_BONUS_TOTALS_BY_PLAYER_REPORT,
  payload: { params },
});

export const getBonusTotalsByType = (params) => ({
  type: ReportingActionType.GET_BONUS_TOTALS_BY_TYPE,
  payload: { params },
});

export const saveBonusTotalsByType = (data) => ({
  type: ReportingActionType.SAVE_BONUS_TOTALS_BY_TYPE,
  payload: { data },
});

export const resetBonusTotalsByType = () => ({
  type: ReportingActionType.RESET_BONUS_TOTALS_BY_TYPE,
});

export const exportBonusTotalsByType = (params) => ({
  type: ReportingActionType.EXPORT_BONUS_TOTALS_BY_TYPE,
  payload: { params },
});

export const getBonusTotalsByStatus = (params) => ({
  type: ReportingActionType.GET_BONUS_TOTALS_BY_STATUS,
  payload: { params },
});

export const saveBonusTotalsByStatus = (data) => ({
  type: ReportingActionType.SAVE_BONUS_TOTALS_BY_STATUS,
  payload: { data },
});

export const resetBonusTotalsByStatus = () => ({
  type: ReportingActionType.RESET_BONUS_TOTALS_BY_STATUS,
});

export const exportBonusTotalsByStatus = (params) => ({
  type: ReportingActionType.EXPORT_BONUS_TOTALS_BY_STATUS,
  payload: { params },
});

export const getBonusTotalsByProvider = (params) => ({
  type: ReportingActionType.GET_BONUS_TOTALS_BY_PROVIDER,
  payload: { params },
});

export const saveBonusTotalsByProvider = (data) => ({
  type: ReportingActionType.SAVE_BONUS_TOTALS_BY_PROVIDER,
  payload: { data },
});

export const resetBonusTotalsByProvider = () => ({
  type: ReportingActionType.RESET_BONUS_TOTALS_BY_PROVIDER,
});

export const exportBonusTotalsByProvider = (params) => ({
  type: ReportingActionType.EXPORT_BONUS_TOTALS_BY_PROVIDER,
  payload: { params },
});

export const getInactivePlayers = (params) => ({
  type: ReportingActionType.GET_INACTIVE_PLAYERS,
  payload: { params },
});

export const saveInactivePlayers = (data) => ({
  type: ReportingActionType.SAVE_INACTIVE_PLAYERS,
  payload: { data },
});

export const resetInactivePlayers = () => ({
  type: ReportingActionType.RESET_INACTIVE_PLAYERS,
});

export const generateInactivePlayers = (params) => ({
  type: ReportingActionType.GENERATE_INACTIVE_PLAYERS,
  payload: { params },
});

export const transferInactivePlayersMoney = (data) => ({
  type: ReportingActionType.TRANSFER_INACTIVE_PLAYERS_MONEY,
  payload: { data },
});

export const getTopPlayersByGgr = (params) => ({
  type: ReportingActionType.GET_TOP_PLAYERS_BY_GGR,
  payload: { params },
});

export const saveTopPlayersByGgr = (data) => ({
  type: ReportingActionType.SAVE_TOP_PLAYERS_BY_GGR,
  payload: { data },
});

export const resetTopPlayersByGgr = () => ({
  type: ReportingActionType.RESET_TOP_PLAYERS_BY_GGR,
});

export const exportTopPlayersByGgr = (params) => ({
  type: ReportingActionType.EXPORT_TOP_PLAYERS_BY_GGR,
  payload: { params },
});

export const getTopPlayerByGgrDetails = (playerId, params) => ({
  type: ReportingActionType.GET_TOP_PLAYER_BY_GGR_DETAILS,
  payload: { playerId, params },
});

export const saveTopPlayerByGgrDetails = (playerId, data) => ({
  type: ReportingActionType.SAVE_TOP_PLAYER_BY_GGR_DETAILS,
  payload: { playerId, data },
});

export const resetTopPlayerByGgrDetails = () => ({
  type: ReportingActionType.RESET_TOP_PLAYER_BY_GGR_DETAILS,
});

export const getTopPlayersByTransactionType = (params) => ({
  type: ReportingActionType.GET_TOP_PLAYERS_BY_TRANSACTION_TYPE,
  payload: { params },
});

export const saveTopPlayersByTransactionType = (data) => ({
  type: ReportingActionType.SAVE_TOP_PLAYERS_BY_TRANSACTION_TYPE,
  payload: { data },
});

export const resetTopPlayersByTransactionType = () => ({
  type: ReportingActionType.RESET_TOP_PLAYERS_BY_TRANSACTION_TYPE,
});

export const exportTopPlayersByTransactionType = (params) => ({
  type: ReportingActionType.EXPORT_TOP_PLAYERS_BY_TRANSACTION_TYPE,
  payload: { params },
});

export const getTopPlayerByTransactionTypeDetails = (playerId, params) => ({
  type: ReportingActionType.GET_TOP_PLAYER_BY_TRANSACTION_TYPE_DETAILS,
  payload: { playerId, params },
});

export const saveTopPlayerByTransactionTypeDetails = (playerId, data) => ({
  type: ReportingActionType.SAVE_TOP_PLAYER_BY_TRANSACTION_TYPE_DETAILS,
  payload: { playerId, data },
});

export const resetTopPlayerByTransactionTypeDetails = () => ({
  type: ReportingActionType.RESET_TOP_PLAYER_BY_TRANSACTION_TYPE_DETAILS,
});
