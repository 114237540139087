const AuditLogActionType = {
  GET_USER_LOGS: 'GET_USER_LOGS',
  SAVE_USER_LOGS: 'SAVE_USER_LOGS',
  RESET_USER_LOGS: 'RESET_USER_LOGS',

  GET_PLAYER_LOGS: 'GET_PLAYER_LOGS',
  SAVE_PLAYER_LOGS: 'SAVE_PLAYER_LOGS',
  RESET_PLAYER_LOGS: 'RESET_PLAYER_LOGS',

  GET_USER_MANAGEMENT_LOGS: 'GET_USER_MANAGEMENT_LOGS',
  SAVE_USER_MANAGEMENT_LOGS: 'SAVE_USER_MANAGEMENT_LOGS',
  RESET_USER_MANAGEMENT_LOGS: 'RESET_USER_MANAGEMENT_LOGS',

  GENERATE_USER_LOGS: 'GENERATE_USER_LOGS',
  GENERATE_PLAYER_LOGS: 'GENERATE_PLAYER_LOGS',
  GENERATE_USER_MANAGEMENT_LOGS: 'GENERATE_USER_MANAGEMENT_LOGS',
};

export default AuditLogActionType;
