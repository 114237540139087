import styled from 'styled-components';
import SlotBg from 'assets/img/page404/errorBlock.png';
import SlotPic1 from 'assets/img/page404/errorBlockImg1.png';
import SlotPic2 from 'assets/img/page404/errorBlockImg2.png';
import SlotPic3 from 'assets/img/page404/errorBlockImg3.png';

const StyledErrorPageContent = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  background: white;
`;
const ErrorPageCircleWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 495px;
  height: 495px;
  border-radius: ${(props) => props.theme.customBorderRadius.borderRadiusCircle};
  background: #f9f9f9;
`;
const StyledErrorPageBody = styled.div`
  position: absolute;
  width: 383px;
  height: 166px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: url(${SlotBg}) no-repeat center center;
  background-size: 100% auto;
  > div {
    width: 93px;
    height: 160px;
    position: relative;
    display: inline-block;
    left: 38px;
    &:not(:first-of-type) {
      margin-left: 13px;
    }
  }
`;
const StyledErrorBlock = styled.div`
  position: absolute;
  width: 95px;
  height: 160px;
  top: 3px;
  left: 0;
  background: transparent linear-gradient(180deg, #707070 0%, #e2e2e200 36%, #ffffff00 64%, #707070 100%) 0% 0% no-repeat padding-box;
  border-radius: 14px;
  opacity: 0.41;
  z-index: 10;
`;

const StyledErrorBlockImg = styled.div`
  position: absolute;
  width: 93px;
  height: 160px;
  top: 3px;
  left: 0;
`;
const StyledErrorBlockImg1 = styled(StyledErrorBlockImg)`
  background: url(${SlotPic1}) no-repeat center -32px;
  animation: changeBackground1 2s;
  animation-fill-mode: forwards;
  @keyframes changeBackground1 {
    to {
      background-position: center bottom -62px;
    }
  }
`;

const StyledErrorBlockImg2 = styled(StyledErrorBlockImg)`
  background: url(${SlotPic2}) no-repeat center -60px;
  animation: changeBackground2 2s;
  animation-fill-mode: forwards;
  @keyframes changeBackground2 {
    to {
      background-position: center bottom -36px;
    }
  }
`;

const StyledErrorBlockImg3 = styled(StyledErrorBlockImg)`
  background: url(${SlotPic3}) no-repeat center -63px;
  animation: changeBackground3 2s;
  animation-fill-mode: forwards;
  @keyframes changeBackground3 {
    to {
      background-position: center bottom -66px;
    }
  }
`;
const ErrorPageText = styled.p`
  font-weight: ${(props) => props.theme.typography.fontWeightRegular};
  font-size: 19px;
  color: #707070;
  opacity: 50%;
  text-align: center;
  margin-top: 101px;
`;
const GoBackButtonWrapper = styled.div`
  margin-top: 238px;
  display: flex;
  justify-content: center;
  > button {
    margin: 0 auto;
  }
  > div {
    width: 116px;
  }
`;
export {
  StyledErrorPageBody,
  StyledErrorPageContent,
  StyledErrorBlock,
  StyledErrorBlockImg,
  StyledErrorBlockImg1,
  StyledErrorBlockImg2,
  StyledErrorBlockImg3,
  ErrorPageCircleWrapper,
  ErrorPageText,
  GoBackButtonWrapper,
};
