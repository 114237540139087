import PropTypes from 'prop-types';
import { StyledViewAmount } from 'components/bonusManagement/cashBack/Cashback.styled';
import { StyledDepositAmount } from 'components/bonusManagement/campaign/crud/templates/BonusTemplates.styled';

const ViewConfigList = (props) => {
  const { list } = props;
  return (
    <StyledViewAmount>
      {list.map(({ value, label }) => (
        <p key={label}>
          {`${label}:`}
          <StyledDepositAmount>{value}</StyledDepositAmount>
        </p>
      ))}
    </StyledViewAmount>
  );
};

ViewConfigList.propTypes = {
  list: PropTypes.array.isRequired,
};

export default ViewConfigList;
