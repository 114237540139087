const LegalAgreementActionType = {
  GET_BRAND_LEGAL_AGREEMENTS_VERSIONS: 'GET_BRAND_LEGAL_AGREEMENTS_VERSIONS',
  SAVE_BRAND_LEGAL_AGREEMENTS_VERSIONS: 'SAVE_BRAND_LEGAL_AGREEMENTS_VERSIONS',
  RESET_BRAND_LEGAL_AGREEMENTS_VERSIONS: 'RESET_BRAND_LEGAL_AGREEMENTS_VERSIONS',

  GET_PLAYER_LEGAL_AGREEMENTS_VERSIONS: 'GET_PLAYER_LEGAL_AGREEMENTS_VERSIONS',
  SAVE_PLAYER_LEGAL_AGREEMENTS_VERSIONS: 'SAVE_PLAYER_LEGAL_AGREEMENTS_VERSIONS',
  RESET_PLAYER_LEGAL_AGREEMENTS_VERSIONS: 'RESET_PLAYER_LEGAL_AGREEMENTS_VERSIONS',

  DELETE_BRAND_LEGAL_AGREEMENTS_VERSION: 'DELETE_BRAND_LEGAL_AGREEMENTS_VERSION',
  ADD_BRAND_LEGAL_AGREEMENTS_VERSION: 'ADD_BRAND_LEGAL_AGREEMENTS_VERSION',
  EDIT_BRAND_LEGAL_AGREEMENTS_VERSION: 'EDIT_BRAND_LEGAL_AGREEMENTS_VERSION',

  GET_BRAND_LEGAL_AGREEMENTS_POLICIES: 'GET_BRAND_LEGAL_AGREEMENTS_POLICIES',
  SAVE_BRAND_LEGAL_AGREEMENTS_POLICIES: 'SAVE_BRAND_LEGAL_AGREEMENTS_POLICIES',
  RESET_BRAND_LEGAL_AGREEMENTS_POLICIES: 'RESET_BRAND_LEGAL_AGREEMENTS_POLICIES',
  SAVE_BRAND_LEGAL_AGREEMENTS_NOT_PENDING_POLICIES: 'SAVE_BRAND_LEGAL_AGREEMENTS_NOT_PENDING_POLICIES',
  RESET_BRAND_LEGAL_AGREEMENTS_NOT_PENDING_POLICIES: 'RESET_BRAND_LEGAL_AGREEMENTS_NOT_PENDING_POLICIES',

};

export default LegalAgreementActionType;
