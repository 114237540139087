import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { changeExpiredPassword, changePasswordExpiredState } from 'actions/auth/authActionCreator';
import ExpirePassword from 'components/auth/ExpirePassword';
import { selectCallEndedStatus } from 'selectors';
import { changeEndedStatus } from 'actions/setting/settingActionCreator';

const mapStateToProps = ({ settings }) => ({
  endedStatus: selectCallEndedStatus(settings),
});

const mapDispatchToProps = (dispatch) => bindActionCreators(
  {
    changeExpiredPassword,
    changeEndedStatus,
    changePasswordExpiredState,
  },
  dispatch,
);

export default connect(mapStateToProps, mapDispatchToProps)(ExpirePassword);
