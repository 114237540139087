import {
  all, call, put, takeLatest,
} from '@redux-saga/core/effects';
import qs from 'qs';
import PlayerPerformanceActionType from 'actions/playerPerformance/playerPerformanceActionType';
import NetworkService from 'services/networkService';
import {
  savePlayerWalletAggregation,
  changeWalletLoadingState,
  changeTransactionLoadingState,
  savePlayerTransactionAggregation,
  savePlayersAggregations,
  savePlayerMixedTransaction,
  changePlayerMixedTransactionLoadingState,
} from 'actions/playerPerformance/playerPerformanceActionCreator';
import { AppConstants } from 'constants/index';
import { changeLoadingState, setErrorMessage, changeGenerateReportEndedStatus } from 'actions/setting/settingActionCreator';
import { getError } from 'utils/helpers';

function* getPlayerWalletAggregation({ payload }) {
  try {
    const { id, params } = payload;
    const options = { params };
    yield put(changeWalletLoadingState(true));
    const { ReportingAdmin, Aggregation, Wallet } = AppConstants.api;
    const response = yield call(NetworkService.makeAPIGetRequest, [ReportingAdmin, Aggregation, Wallet, id], options);
    yield put(savePlayerWalletAggregation(response.data));
    yield put(changeWalletLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeWalletLoadingState(false));
  }
}

function* getPlayerTransactionAggregation({ payload }) {
  try {
    const { id, params } = payload;
    const options = { params };
    yield put(changeTransactionLoadingState(true));
    const { ReportingAdmin, Aggregation, Transaction } = AppConstants.api;
    const response = yield call(NetworkService.makeAPIGetRequest, [ReportingAdmin, Aggregation, Transaction, id], options);
    yield put(savePlayerTransactionAggregation(response.data));
    yield put(changeTransactionLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeTransactionLoadingState(false));
  }
}

function* getPlayersAggregations({ payload }) {
  try {
    const { brandId, params } = payload;
    const options = {
      params,
      paramsSerializer: (param) => qs.stringify(param),
    };
    yield put(changeTransactionLoadingState(true));
    const { ReportingAdmin, Aggregation } = AppConstants.api;
    const { data } = yield call(NetworkService.makeAPIGetRequest, [ReportingAdmin, Aggregation, brandId], options);
    yield put(savePlayersAggregations(data));
    yield put(changeTransactionLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeTransactionLoadingState(false));
  }
}

function* generatePlayersAggregationsReport({ payload }) {
  try {
    const { params } = payload;
    const options = {
      params,
      paramsSerializer: (param) => qs.stringify(param, { allowDots: true }),
    };
    yield put(changeLoadingState(true));
    const { ReportingAdmin, Aggregation, Generate } = AppConstants.api;
    yield call(NetworkService.makeAPIGetRequest, [ReportingAdmin, Aggregation, Generate], options);
    yield put(changeGenerateReportEndedStatus(true));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getPlayerMixedTransaction({ payload }) {
  try {
    const { playerId, params } = payload;
    const options = {
      params,
      paramsSerializer: (param) => qs.stringify(param),
    };
    yield put(changePlayerMixedTransactionLoadingState(true));
    const { ReportingAdmin, Aggregation, MixedTransaction } = AppConstants.api;
    const { data } = yield call(NetworkService.makeAPIGetRequest, [ReportingAdmin, Aggregation, MixedTransaction, playerId], options);
    yield put(savePlayerMixedTransaction(data));
    yield put(changePlayerMixedTransactionLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changePlayerMixedTransactionLoadingState(false));
  }
}

export default function* playerPerformanceSaga() {
  yield all([
    takeLatest(PlayerPerformanceActionType.GET_PLAYER_WALLET_AGGREGATION, getPlayerWalletAggregation),
    takeLatest(PlayerPerformanceActionType.GET_PLAYER_TRANSACTION_AGGREGATION, getPlayerTransactionAggregation),
    takeLatest(PlayerPerformanceActionType.GET_PLAYERS_AGGREGATIONS, getPlayersAggregations),
    takeLatest(PlayerPerformanceActionType.GENERATE_PLAYERS_AGGREGATIONS_REPORT, generatePlayersAggregationsReport),
    takeLatest(PlayerPerformanceActionType.GET_PLAYER_MIXED_TRANSACTION, getPlayerMixedTransaction),
  ]);
}
