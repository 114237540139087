import { ThemeProvider as StyledThemeProvider } from 'styled-components';
import { theme } from 'antd';
import PropTypes from 'prop-types';

const ThemeProvider = ({ children }) => {
  const { token } = theme.useToken();
  return <StyledThemeProvider theme={token}>{children}</StyledThemeProvider>;
};

ThemeProvider.propTypes = {
  children: PropTypes.any.isRequired,
};
export default ThemeProvider;
