import qs from 'qs';
import {
  put, all, call, takeLatest,
} from 'redux-saga/effects';
import { NetworkService, i18n } from 'services';
import {
  changeEndedStatus, changeLoadingState, setErrorMessage, setSuccessMessage,
} from 'actions/setting/settingActionCreator';
import OperatorLimitActionType from 'actions/operatorLimit/operatorLimitActionType';
import {
  saveBrandPlayerLimits, saveLargeTransactionLimitsLogs, saveLargeTransactionsLimitsByBrand, savePeriodsLimits,
} from 'actions/operatorLimit/operatorLimitActionCreator';
import { getError, getMessage } from 'utils/helpers';
import { AppConstants } from 'constants/index';

function* getLargeTransactionsLimitsByBrand({ payload }) {
  try {
    const { params, brandId } = payload;
    const options = {
      params,
    };
    const { BrandAdmin, LargeTransactions } = AppConstants.api;
    yield put(changeLoadingState(true));
    const { data } = yield call(NetworkService.makeAPIGetRequest, [BrandAdmin, LargeTransactions, brandId], options);
    yield put(changeLoadingState(false));
    yield put(saveLargeTransactionsLimitsByBrand(data));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* editLargeTransactionLimitsByBrand({ payload }) {
  try {
    const { brandId } = payload;
    const options = {
      data: payload.data,
    };
    const { BrandAdmin, LargeTransactions } = AppConstants.api;
    yield put(changeLoadingState(true));
    yield call(NetworkService.makeAPIPostRequest, [BrandAdmin, LargeTransactions, brandId], options);
    yield put(changeLoadingState(false));
    yield put(changeEndedStatus(true));
    yield put(setSuccessMessage(getMessage(i18n.t('brand:largeTransactions'), i18n.t('notification:plUpdated'))));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getLargeTransactionLimitsLogs({ payload }) {
  try {
    const { params, brandId } = payload;
    const options = {
      params,
      paramsSerializer: (param) => qs.stringify(param),
    };
    const { BrandAdmin, LargeTransactions, History } = AppConstants.api;
    yield put(changeLoadingState(true));
    const { data } = yield call(NetworkService.makeAPIGetRequest, [BrandAdmin, LargeTransactions, History, brandId], options);
    yield put(changeLoadingState(false));
    yield put(saveLargeTransactionLimitsLogs(data));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getBrandPlayerLimits({ payload }) {
  try {
    const { brandId } = payload;
    const { BrandAdmin, BrandMarketSetting, ResponsibleGaming } = AppConstants.api;
    yield put(changeLoadingState(true));
    const { data } = yield call(NetworkService.makeAPIGetRequest, [BrandAdmin, BrandMarketSetting, ResponsibleGaming, brandId]);
    yield put(changeLoadingState(false));
    yield put(saveBrandPlayerLimits(data));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* changeBrandPlayerLimits({ payload }) {
  try {
    const { brandId, data } = payload;
    const options = {
      data,
    };
    const { BrandAdmin, BrandMarketSetting, ResponsibleGaming } = AppConstants.api;
    yield put(changeLoadingState(true));
    yield call(NetworkService.makeAPIPostRequest, [BrandAdmin, BrandMarketSetting, ResponsibleGaming, brandId], options);
    yield put(changeLoadingState(false));
    yield put(saveBrandPlayerLimits(data));
    yield put(setSuccessMessage(getMessage(i18n.t('brand:playerLimitsConfiguration'), i18n.t('notification:slUpdated'))));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getPeriodsLimits({ payload }) {
  try {
    const { params } = payload;
    const options = {
      params,
    };
    const {
      BrandAdmin, BrandMarketSetting, ResponsibleGaming, LimitationPeriods,
    } = AppConstants.api;
    yield put(changeLoadingState(true));
    const { data } = yield call(NetworkService.makeAPIGetRequest, [BrandAdmin, BrandMarketSetting, ResponsibleGaming, LimitationPeriods], options);
    yield put(changeLoadingState(false));
    yield put(savePeriodsLimits(data));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* editPeriodsLimits({ payload }) {
  try {
    const { params, data } = payload;
    const options = {
      params,
      data,
    };
    const {
      BrandAdmin, BrandMarketSetting, ResponsibleGaming, LimitationPeriods,
    } = AppConstants.api;
    yield put(changeLoadingState(true));
    yield call(NetworkService.makeAPIPostRequest, [BrandAdmin, BrandMarketSetting, ResponsibleGaming, LimitationPeriods], options);
    yield put(changeLoadingState(false));
    yield put(changeEndedStatus(true));
    yield put(setSuccessMessage(getMessage(i18n.t('brand:periodLimit'), i18n.t('notification:plUpdated'))));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

export default function* operatorLimitSaga() {
  yield all([
    takeLatest(OperatorLimitActionType.GET_LARGE_TRANSACTIONS_LIMITS_BY_BRAND, getLargeTransactionsLimitsByBrand),
    takeLatest(OperatorLimitActionType.EDIT_LARGE_TRANSACTIONS_LIMITS_BY_BRAND, editLargeTransactionLimitsByBrand),
    takeLatest(OperatorLimitActionType.GET_LARGE_TRANSACTION_LIMITS_LOGS, getLargeTransactionLimitsLogs),
    takeLatest(OperatorLimitActionType.GET_BRAND_PLAYER_LIMITS, getBrandPlayerLimits),
    takeLatest(OperatorLimitActionType.CHANGE_BRAND_PLAYER_LIMITS, changeBrandPlayerLimits),
    takeLatest(OperatorLimitActionType.GET_PERIODS_LIMITS, getPeriodsLimits),
    takeLatest(OperatorLimitActionType.EDIT_PERIODS_LIMITS, editPeriodsLimits),
  ]);
}
