export const selectFileManagerLoading = (state) => state.loading;
export const selectFileManagerAllDirectoriesLoading = (state) => state.allDirectoriesLoading;
export const selectFileManagerDirectoriesAndFilesLoading = (state) => state.directoriesAndFilesLoading;
export const selectFileManagerDirectoriesAndFiles = (state) => state.directoriesAndFiles;
export const selectFileManagerBaseDirectories = (state) => state.baseDirectories;
export const selectFileTypeGroups = (state) => state.fileTypeGroups;
export const selectFileManagerModalVisible = (state) => state.modalVisible;
export const selectFileManagerBrandId = (state) => state.brandId;
export const selectFileManagerCallEndedStatus = (state) => state.endedStatus;
export const selectFileManagerDirectoryHierarchy = (state) => state.directoriesHierarchy;
export const selectFileManagerPopoverVisible = (state) => state.popoverVisible;
export const selectFilesByPaths = (state) => state.filesByPaths;
export const selectUploadedFile = (state) => state.uploadedFile;
