import styled from 'styled-components';
import { truncate, media } from 'utils/styledHelpers';

const StyledAccountMainWrapper = styled.div`
  padding: 0 1.14rem;
  h3 {
    margin-bottom: 2.57rem;
  }
`;
const StyledAccountWrapper = styled.div`
  display: flex;
  justify-content: center;
  color: ${(props) => props.theme.colors.primaryText};
  & > div {
    display: flex;
    flex-direction: column;
    min-width: 0;
    & > span,
    & > strong {
      white-space: nowrap;
      margin-bottom: 1.14rem;
      ${truncate()}
    }
  }
  & > div:first-child {
    max-width: 45%;
    margin-right: 1.57rem;
    text-align: right;
    flex-shrink: 0;

    ${media.xs`
      text-align: left;
    `}
  }

  ${media.xs`
    justify-content: flex-start;
  `}
`;

export { StyledAccountWrapper, StyledAccountMainWrapper };
