import GameSegmentActionType from './gameSegmentActionType';

export const createGameSegment = (data) => ({
  type: GameSegmentActionType.CREATE_GAME_SEGMENT,
  payload: { data },
});

export const saveGamesSegments = (data) => ({
  type: GameSegmentActionType.SAVE_GAMES_SEGMENTS,
  payload: { data },
});

export const getGamesSegments = (data) => ({
  type: GameSegmentActionType.GET_GAMES_SEGMENTS,
  payload: { data },
});

export const deleteGameSegment = (id, name) => ({
  type: GameSegmentActionType.DELETE_GAME_SEGMENT,
  payload: { id, name },
});

export const editGameSegment = (id, data) => ({
  type: GameSegmentActionType.EDIT_GAME_SEGMENT,
  payload: { id, data },
});

export const getSegmentGames = (segmentId, params, isLoading) => ({
  type: GameSegmentActionType.GET_SEGMENT_GAMES,
  payload: { segmentId, params, isLoading },
});

export const saveSegmentGames = (data) => ({
  type: GameSegmentActionType.SAVE_SEGMENT_GAMES,
  payload: { data },
});

export const deleteGameSegmentGame = (id, name, segmentId) => ({
  type: GameSegmentActionType.DELETE_GAME_SEGMENT_GAME,
  payload: { id, name, segmentId },
});

export const updateGameSegment = (id, data) => ({
  type: GameSegmentActionType.UPDATE_GAME_SEGMENT,
  payload: {
    id,
    data,
  },
});

export const resetSegmentGames = () => ({
  type: GameSegmentActionType.RESET_SEGMENT_GAMES,
});

export const resetGamesSegments = () => ({
  type: GameSegmentActionType.RESET_GAMES_SEGMENTS,
});

export const getGameSegment = (segmentId) => ({
  type: GameSegmentActionType.GET_GAME_SEGMENT,
  payload: { segmentId },
});

export const saveGameSegment = (data) => ({
  type: GameSegmentActionType.SAVE_GAME_SEGMENT,
  payload: { data },
});

export const resetGameSegment = () => ({
  type: GameSegmentActionType.RESET_GAME_SEGMENT,
});
