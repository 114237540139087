import PaymentActionType from 'actions/payment/paymentActionType';

const initialState = {
  deposits: {},
  withdrawals: {},
  playerDeposits: {},
  playerWithdrawals: {},
  depositTags: [],
  withdrawalTags: [],
  assignedTagCategories: [],
  assignedTags: [],
  logs: {},
  details: {},
  payeeData: {},
  notes: {},
};

const paymentReducer = (state = initialState, { type, payload }) => {
  switch (type) {
  case PaymentActionType.SAVE_DEPOSITS:
    return {
      ...state,
      deposits: payload.data,
    };
  case PaymentActionType.RESET_DEPOSITS:
    return {
      ...state,
      deposits: {},
    };
  case PaymentActionType.SAVE_WITHDRAWALS:
    return {
      ...state,
      withdrawals: payload.data,
    };
  case PaymentActionType.RESET_WITHDRAWALS:
    return {
      ...state,
      withdrawals: {},
    };
  case PaymentActionType.SAVE_PLAYER_DEPOSITS:
    return {
      ...state,
      playerDeposits: payload.data,
    };
  case PaymentActionType.RESET_PLAYER_DEPOSITS:
    return {
      ...state,
      playerDeposits: {},
    };
  case PaymentActionType.SAVE_PLAYER_WITHDRAWALS:
    return {
      ...state,
      playerWithdrawals: payload.data,
    };
  case PaymentActionType.RESET_PLAYER_WITHDRAWALS:
    return {
      ...state,
      playerWithdrawals: {},
    };
  case PaymentActionType.SAVE_DEPOSIT_TAGS:
    return {
      ...state,
      depositTags: payload.data,
    };
  case PaymentActionType.RESET_DEPOSIT_TAGS:
    return {
      ...state,
      depositTags: [],
    };
  case PaymentActionType.SAVE_WITHDRAWAL_TAGS:
    return {
      ...state,
      withdrawalTags: payload.data,
    };
  case PaymentActionType.RESET_WITHDRAWAL_TAGS:
    return {
      ...state,
      withdrawalTags: [],
    };
  case PaymentActionType.SAVE_ASSIGNED_TAG_CATEGORIES:
    return {
      ...state,
      assignedTagCategories: payload.data,
    };
  case PaymentActionType.RESET_ASSIGNED_TAG_CATEGORIES:
    return {
      ...state,
      assignedTagCategories: [],
    };
  case PaymentActionType.SAVE_ASSIGNED_TAGS:
    return {
      ...state,
      assignedTags: payload.data,
    };
  case PaymentActionType.RESET_ASSIGNED_TAGS:
    return {
      ...state,
      assignedTags: [],
    };
  case PaymentActionType.SAVE_PAYMENT_NOTES:
    return {
      ...state,
      notes: {
        ...state.notes,
        [payload.id]: payload.data,
      },
    };
  case PaymentActionType.RESET_PAYMENT_NOTES:
    return {
      ...state,
      notes: {},
    };
  case PaymentActionType.SAVE_PAYMENT_LOGS:
    return {
      ...state,
      logs: {
        ...state.logs,
        [payload.id]: payload.data,
      },
    };
  case PaymentActionType.RESET_PAYMENT_LOGS:
    return {
      ...state,
      logs: {},
    };
  case PaymentActionType.SAVE_PAYMENT_DETAILS:
    return {
      ...state,
      details: {
        ...state.details,
        [payload.id]: payload.data,
      },
    };
  case PaymentActionType.RESET_PAYMENT_DETAILS:
    return {
      ...state,
      details: {},
    };
  case PaymentActionType.SAVE_TRANSACTION_PLAYER_INFO:
    return {
      ...state,
      payeeData: {
        ...state.payeeData,
        [payload.id]: payload.data,
      },
    };
  case PaymentActionType.RESET_TRANSACTION_PLAYER_INFO:
    return {
      ...state,
      payeeData: {},
    };
  default:
    return state;
  }
};

export default paymentReducer;
