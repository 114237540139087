import PropTypes from 'prop-types';
import { StyledBannerRadius, StyledBannersTitle } from 'components/styledComponents/banner/Banner.styled';

const Banner = (props) => {
  const {
    img, text, hintText, type, isSmallText,
  } = props;
  return (
    <StyledBannerRadius src={img} type={type}>
      <StyledBannersTitle isHint={hintText ? 1 : 0} isSmallText={isSmallText ? 1 : 0}>
        {text}
        {hintText && <p>{hintText}</p>}
      </StyledBannersTitle>
    </StyledBannerRadius>
  );
};

Banner.propTypes = {
  img: PropTypes.string,
  text: PropTypes.any.isRequired,
  type: PropTypes.number,
  hintText: PropTypes.string,
  isSmallText: PropTypes.bool,
};

Banner.defaultProps = {
  type: 1,
  img: '',
  hintText: '',
  isSmallText: false,
};

export default Banner;
