import { Breadcrumb } from 'antd';
import styled from 'styled-components';

const StyledBreadcrump = styled(Breadcrumb)`
  &.ant-breadcrumb {
    color: ${(props) => props.theme.colors.secondaryColor};
    padding: 1.2rem 0;
    display: flex;

    & .ant-breadcrumb-separator {
      margin: 0;
      cursor: default;
      > div {
        margin: 0 0.5rem;
      }
    }
    & > ol > li {
      display: flex;
      align-items: center;
      & > span {
        cursor: pointer;
        color: ${(props) => props.theme.colors.primaryText};
        font-weight: ${(props) => props.theme.typography.fontWeightRegular};
        font-size: ${(props) => props.theme.typography.fontSizeSmall};
        @media (max-width: 1366px) {
          font-size: 11px;
        }
        &:hover {
          color: ${(props) => props.theme.colors.blue};
          text-decoration: underline;
        }
      }
      &:last-child {
        & > span {
          cursor: default;
          color: ${(props) => props.theme.colors.secondaryColor};
          text-decoration: none;
          color: ${(props) => props.theme.colors.filterLabelColor};
          pointer-events: none;
        }
      }
    }
    & .ant-breadcrumb-link:hover {
      color: ${(props) => props.theme.colors.blue};
    }
    .inactive-item {
      cursor: default;
      color: ${(props) => props.theme.colors.secondaryColor};
      &:hover {
        color: ${(props) => props.theme.colors.secondaryColor};
        text-decoration: none;
      }
    }
  }
`;

export { StyledBreadcrump };
