import {
  put, call, takeLatest, all,
} from 'redux-saga/effects';
import qs from 'qs';
import NetworkService from 'services/networkService';
import {
  getPermissionsSuccess,
  getResourcesSuccess,
  changePermissionLoadingState,
  savePermissionsResource,
  saveUserManagementResource,
  saveAllActionResource,
} from 'actions/permission/permissionActionCreator';
import PermissionActionType from 'actions/permission/permissionActionType';
import { changeEndedStatus, changeLoadingState, setErrorMessage } from 'actions/setting/settingActionCreator';
import { getError } from 'utils/helpers';
import { AppConstants } from 'constants/index';

function* getResources() {
  try {
    const { Admin, Resource } = AppConstants.api;
    const response = yield call(NetworkService.makeAPIGetRequest, [Admin, Resource]);
    yield put(getResourcesSuccess(response.data));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
  }
}

function* getPermissions({ payload }) {
  try {
    yield put(changePermissionLoadingState(true));
    const { Admin, Permission } = AppConstants.api;
    const response = yield call(NetworkService.makeAPIGetRequest, [Admin, Permission], {
      params: payload,
      paramsSerializer: (params) => qs.stringify(params),
    });
    yield put(getPermissionsSuccess(response.data));
    yield put(changePermissionLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changePermissionLoadingState(false));
  }
}

function* getPermissionsResource({ payload }) {
  const { isLoading } = payload;
  try {
    const { params } = payload;
    if (isLoading) {
      yield put(changePermissionLoadingState(true));
    }
    const { Admin, Permission, Resource } = AppConstants.api;
    const { data } = yield call(NetworkService.makeAPIGetRequest, [Admin, Permission, Resource], {
      params,
      paramsSerializer: (param) => qs.stringify(param),
    });
    yield put(savePermissionsResource(data));
    if (isLoading) {
      yield put(changePermissionLoadingState(false));
    }
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    if (isLoading) {
      yield put(changePermissionLoadingState(false));
    }
  }
}

function* getUserManagementResource({ payload }) {
  const { isLoading } = payload;
  try {
    if (isLoading) {
      yield put(changePermissionLoadingState(true));
    }
    const { Admin, Resource, UserManagement } = AppConstants.api;
    const { data } = yield call(NetworkService.makeAPIGetRequest, [Admin, Resource, UserManagement]);
    yield put(saveUserManagementResource(data));
    if (isLoading) {
      yield put(changePermissionLoadingState(false));
    }
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    if (isLoading) {
      yield put(changePermissionLoadingState(false));
    }
  }
}

function* getAllActionResource({ payload }) {
  const { isLoading } = payload;
  try {
    if (isLoading) {
      yield put(changePermissionLoadingState(true));
    }
    const { Admin, Resource } = AppConstants.api;
    const { data } = yield call(NetworkService.makeAPIGetRequest, [Admin, Resource]);
    yield put(saveAllActionResource(data));
    if (isLoading) {
      yield put(changePermissionLoadingState(false));
    }
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    if (isLoading) {
      yield put(changePermissionLoadingState(false));
    }
  }
}

function* updatePermissionLevel({ payload }) {
  try {
    const { id, securityLevelId } = payload.data;
    yield put(changeLoadingState(true));
    const { Admin, Permission } = AppConstants.api;
    yield call(NetworkService.makeAPIPutRequest, [Admin, Permission, id], {
      data: { securityLevelId },
    });
    yield put(changeLoadingState(false));
    yield put(changeEndedStatus(true));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

export default function* permissionSaga() {
  yield all([
    takeLatest(PermissionActionType.GET_RESOURCES, getResources),
    takeLatest(PermissionActionType.GET_PERMISSIONS, getPermissions),
    takeLatest(PermissionActionType.GET_PERMISSIONS_RESOURCE, getPermissionsResource),
    takeLatest(PermissionActionType.GET_USER_MANAGEMENT_RESOURCE, getUserManagementResource),
    takeLatest(PermissionActionType.GET_ALL_ACTION_RESOURCE, getAllActionResource),
    takeLatest(PermissionActionType.UPDATE_PERMISSION_LEVEL, updatePermissionLevel),
  ]);
}
