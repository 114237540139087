import CashbackActionType from 'actions/cashBack/cashBackActionType';

const initialState = {
  cashBacks: [],
  total: 0,
  cashBack: {},
  accumulated: [],
  accumulatedTotal: 0,
  transferred: [],
  transferredTotal: 0,
  failed: [],
  failedTotal: 0,
  players: [],
  playersTotal: 0,
  includedPlayers: {},
  playerCashBacks: [],
  playerCashBacksTotal: 0,
  statisticsDetails: [],
  cashBackPeriodsResource: [],
  playerAccountCashBackPeriods: {},
  playerAccumulatedPeriods: {},
  playerTransferredPeriods: {},
  playerFailedPeriods: {},
};

const cashBackReducer = (state = initialState, { type, payload }) => {
  switch (type) {
  case CashbackActionType.SAVE_CASH_BACKS:
    return {
      ...state,
      cashBacks: payload.data.data,
      total: payload.data.total,
    };
  case CashbackActionType.RESET_CASH_BACKS:
    return {
      ...state,
      cashBacks: [],
      total: 0,
    };
  case CashbackActionType.SAVE_CASH_BACK:
    return {
      ...state,
      cashBack: payload.data,
    };
  case CashbackActionType.RESET_CASH_BACK:
    return {
      ...state,
      cashBack: {},
    };
  case CashbackActionType.SAVE_CASH_BACK_ACCUMULATED:
    return {
      ...state,
      accumulated: payload.data.data,
      accumulatedTotal: payload.data.total,
    };
  case CashbackActionType.SAVE_CASH_BACK_TRANSFERRED:
    return {
      ...state,
      transferred: payload.data.data,
      transferredTotal: payload.data.total,
    };
  case CashbackActionType.SAVE_CASH_BACK_FAILED:
    return {
      ...state,
      failed: payload.data.data,
      failedTotal: payload.data.total,
    };
  case CashbackActionType.RESET_CASH_BACK_ACCUMULATED:
    return {
      ...state,
      accumulated: [],
      accumulatedTotal: 0,
    };
  case CashbackActionType.RESET_CASH_BACK_TRANSFERRED:
    return {
      ...state,
      transferred: [],
      transferredTotal: 0,
    };
  case CashbackActionType.RESET_CASH_BACK_FAILED:
    return {
      ...state,
      failed: [],
      failedTotal: 0,
    };
  case CashbackActionType.SAVE_CASH_BACK_PLAYERS:
    return {
      ...state,
      players: payload.data.data,
      playersTotal: payload.data.total,
    };
  case CashbackActionType.RESET_CASH_BACK_PLAYERS:
    return {
      ...state,
      players: [],
      playersTotal: 0,
    };
  case CashbackActionType.SAVE_CASH_BACK_INCLUDED_PLAYERS:
    return {
      ...state,
      includedPlayers: payload.data,
    };
  case CashbackActionType.RESET_CASH_BACK_INCLUDED_PLAYERS:
    return {
      ...state,
      includedPlayers: {},
    };
  case CashbackActionType.SAVE_PLAYER_CASH_BACKS:
    return {
      ...state,
      playerCashBacks: payload.data.data,
      playerCashBacksTotal: payload.data.total,
    };
  case CashbackActionType.RESET_PLAYER_CASH_BACKS:
    return {
      ...state,
      playerCashBacks: [],
      playerCashBacksTotal: 0,
    };
  case CashbackActionType.SAVE_CASH_BACK_STATISTICS_DETAILS:
    return {
      ...state,
      statisticsDetails: payload.data,
    };
  case CashbackActionType.RESET_CASH_BACK_STATISTICS_DETAILS:
    return {
      ...state,
      statisticsDetails: [],
    };
  case CashbackActionType.SAVE_PLAYER_ACCOUNT_CASH_BACK_PERIODS:
    return {
      ...state,
      playerAccountCashBackPeriods: payload.data,
    };
  case CashbackActionType.RESET_PLAYER_ACCOUNT_CASH_BACK_PERIODS:
    return {
      ...state,
      playerAccountCashBackPeriods: {},
    };
  case CashbackActionType.SAVE_CASH_BACK_PERIODS_RESOURCE:
    return {
      ...state,
      cashBackPeriodsResource: payload.data,
    };
  case CashbackActionType.RESET_CASH_BACK_PERIODS_RESOURCE:
    return {
      ...state,
      cashBackPeriodsResource: [],
    };
  case CashbackActionType.SAVE_PLAYER_ACCUMULATED_PERIODS:
    return {
      ...state,
      playerAccumulatedPeriods: payload.data,
    };
  case CashbackActionType.RESET_PLAYER_ACCUMULATED_PERIODS:
    return {
      ...state,
      playerAccumulatedPeriods: {},
    };
  case CashbackActionType.SAVE_PLAYER_TRANSFERRED_PERIODS:
    return {
      ...state,
      playerTransferredPeriods: payload.data,
    };
  case CashbackActionType.RESET_PLAYER_TRANSFERRED_PERIODS:
    return {
      ...state,
      playerTransferredPeriods: {},
    };
  case CashbackActionType.SAVE_PLAYER_FAILED_PERIODS:
    return {
      ...state,
      playerFailedPeriods: payload.data,
    };
  case CashbackActionType.RESET_PLAYER_FAILED_PERIODS:
    return {
      ...state,
      playerFailedPeriods: {},
    };
  default:
    return state;
  }
};

export default cashBackReducer;
