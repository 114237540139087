const selectCampaigns = (state) => state.campaigns;
export const selectCampaignsData = (state) => selectCampaigns(state).data;
export const selectCampaignsTotal = (state) => selectCampaigns(state).total;

export const selectCampaign = (state) => state.campaign;
export const selectCampaignName = (state) => selectCampaign(state).name;
export const selectCampaignConditionTypes = (state) => state.conditionTypes;
const selectCampaignPlayers = (state) => state.campaignPlayers;
export const selectCampaignPlayersData = (state) => selectCampaignPlayers(state).data;
export const selectCampaignPlayersTotal = (state) => selectCampaignPlayers(state).total;

const selectCampaignDepositStatistics = (state) => state.campaignDepositStatistics;
const selectCampaignReceivedDepositStatistics = (state) => selectCampaignDepositStatistics(state).received;
export const selectCampaignReceivedDepositStatisticsData = (state) => selectCampaignReceivedDepositStatistics(state).data;
export const selectCampaignReceivedDepositStatisticsTotal = (state) => selectCampaignReceivedDepositStatistics(state).total;
const selectCampaignFailedDepositStatistics = (state) => selectCampaignDepositStatistics(state).failed;
export const selectCampaignFailedDepositStatisticsData = (state) => selectCampaignFailedDepositStatistics(state).data;
export const selectCampaignFailedDepositStatisticsTotal = (state) => selectCampaignFailedDepositStatistics(state).total;

const selectCampaignVerificationStatistics = (state) => state.campaignVerificationStatistics;
const selectCampaignReceivedVerificationStatistics = (state) => selectCampaignVerificationStatistics(state).received;
export const selectCampaignReceivedVerificationStatisticsData = (state) => selectCampaignReceivedVerificationStatistics(state).data;
export const selectCampaignReceivedVerificationStatisticsTotal = (state) => selectCampaignReceivedVerificationStatistics(state).total;
const selectCampaignFailedVerificationStatistics = (state) => selectCampaignVerificationStatistics(state).failed;
export const selectCampaignFailedVerificationStatisticsData = (state) => selectCampaignFailedVerificationStatistics(state).data;
export const selectCampaignFailedVerificationStatisticsTotal = (state) => selectCampaignFailedVerificationStatistics(state).total;

const selectCampaignSignUpStatistics = (state) => state.campaignSignUpStatistics;
const selectCampaignReceivedSignUpStatistics = (state) => selectCampaignSignUpStatistics(state).received;
export const selectCampaignReceivedSignUpStatisticsData = (state) => selectCampaignReceivedSignUpStatistics(state).data;
export const selectCampaignReceivedSignUpStatisticsTotal = (state) => selectCampaignReceivedSignUpStatistics(state).total;
const selectCampaignFailedSignUpStatistics = (state) => selectCampaignSignUpStatistics(state).failed;
export const selectCampaignFailedSignUpStatisticsData = (state) => selectCampaignFailedSignUpStatistics(state).data;
export const selectCampaignFailedSignUpStatisticsTotal = (state) => selectCampaignFailedSignUpStatistics(state).total;

const selectCampaignNoTriggerStatistics = (state) => state.campaignNoTriggerStatistics;
const selectCampaignReceivedNoTriggerStatistics = (state) => selectCampaignNoTriggerStatistics(state).received;
export const selectCampaignReceivedNoTriggerStatisticsData = (state) => selectCampaignReceivedNoTriggerStatistics(state).data;
export const selectCampaignReceivedNoTriggerStatisticsTotal = (state) => selectCampaignReceivedNoTriggerStatistics(state).total;
const selectCampaignFailedNoTriggerStatistics = (state) => selectCampaignNoTriggerStatistics(state).failed;
export const selectCampaignFailedNoTriggerStatisticsData = (state) => selectCampaignFailedNoTriggerStatistics(state).data;
export const selectCampaignFailedNoTriggerStatisticsTotal = (state) => selectCampaignFailedNoTriggerStatistics(state).total;

const selectCrossPlatformCampaigns = (state) => state.crossPlatformCampaigns;
export const selectCrossPlatformCampaignsData = (state) => selectCrossPlatformCampaigns(state).data;
export const selectCrossPlatformCampaignsTotal = (state) => selectCrossPlatformCampaigns(state).total;

export const selectCrossPlatformCampaign = (state) => state.crossPlatformCampaign;
export const selectCrossPlatformCampaignName = (state) => selectCrossPlatformCampaign(state).name;

const selectCrossPlatformCampaignNoTriggerStatistics = (state) => state.crossPlatformNoTriggerStatistics;
export const selectCrossPlatformCampaignNoTriggerStatisticsData = (state) => selectCrossPlatformCampaignNoTriggerStatistics(state).data;
export const selectCrossPlatformCampaignNoTriggerStatisticsTotal = (state) => selectCrossPlatformCampaignNoTriggerStatistics(state).total;

const selectCrossPlatformCampaignUsages = (state) => state.crossPlatformUsages;
export const selectCrossPlatformCampaignUsagesData = (state) => selectCrossPlatformCampaignUsages(state).data;
export const selectCrossPlatformCampaignUsagesTotal = (state) => selectCrossPlatformCampaignUsages(state).total;

const selectCampaignContentConfig = (state) => state.contentConfig;
export const selectCampaignBonusContentConfig = (state) => selectCampaignContentConfig(state)?.bonusContentConfigs;
export const selectCampaignCampaignContentConfig = (state) => selectCampaignContentConfig(state)?.campaignContents;
export const selectCampaignContentLoading = (state) => state.contentLoading;
export const selectCampaignLoading = (state) => state.campaignLoading;

export const selectCampaignParticipants = (state) => state.campaignParticipants;

export const selectCampaignParticipantsLoading = (state) => state.playersLoading;

export const selectCrossPlatformCampaignContentConfig = (state) => state.crossPlatformContentConfig;

const selectCampaignSignInStatistics = (state) => state.campaignSignInStatistics;
const selectCampaignReceivedSignInStatistics = (state) => selectCampaignSignInStatistics(state).received;
export const selectCampaignReceivedSignInStatisticsData = (state) => selectCampaignReceivedSignInStatistics(state).data;
export const selectCampaignReceivedSignInStatisticsTotal = (state) => selectCampaignReceivedSignInStatistics(state).total;
const selectCampaignFailedSignInStatistics = (state) => selectCampaignSignInStatistics(state).failed;
export const selectCampaignFailedSignInStatisticsData = (state) => selectCampaignFailedSignInStatistics(state).data;
export const selectCampaignFailedSignInStatisticsTotal = (state) => selectCampaignFailedSignInStatistics(state).total;
