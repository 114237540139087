import RegulatoryActionType from 'actions/regulatory/regulatoryActionType';

export const getSoftwareChanges = (params) => ({
  type: RegulatoryActionType.GET_SOFTWARE_CHANGES,
  payload: { params },
});

export const saveSoftwareChanges = (data) => ({
  type: RegulatoryActionType.SAVE_SOFTWARE_CHANGES,
  payload: { data },
});

export const resetSoftwareChanges = () => ({
  type: RegulatoryActionType.RESET_SOFTWARE_CHANGES,
});

export const exportSoftwareChanges = (params) => ({
  type: RegulatoryActionType.EXPORT_SOFTWARE_CHANGES,
  payload: { params },
});

export const getGameRatio = (params) => ({
  type: RegulatoryActionType.GET_GAME_RATIO,
  payload: { params },
});

export const saveGameRatio = (data) => ({
  type: RegulatoryActionType.SAVE_GAME_RATIO,
  payload: { data },
});

export const resetGameRatio = () => ({
  type: RegulatoryActionType.RESET_GAME_RATIO,
});

export const exportGameRatio = (params) => ({
  type: RegulatoryActionType.EXPORT_GAME_RATIO,
  payload: { params },
});

export const getPlayerExclusion = (params) => ({
  type: RegulatoryActionType.GET_PLAYER_EXCLUSION,
  payload: { params },
});

export const savePlayerExclusion = (data) => ({
  type: RegulatoryActionType.SAVE_PLAYER_EXCLUSION,
  payload: { data },
});

export const resetPlayerExclusion = () => ({
  type: RegulatoryActionType.RESET_PLAYER_EXCLUSION,
});

export const exportPlayerExclusion = (params) => ({
  type: RegulatoryActionType.EXPORT_PLAYER_EXCLUSION,
  payload: { params },
});

export const getGameTypes = (params) => ({
  type: RegulatoryActionType.GET_GAME_TYPES_REPORT,
  payload: { params },
});

export const saveGameTypes = (data) => ({
  type: RegulatoryActionType.SAVE_GAME_TYPES_REPORT,
  payload: { data },
});

export const resetGameTypes = () => ({
  type: RegulatoryActionType.RESET_GAME_TYPES_REPORT,
});

export const exportGameTypes = (params) => ({
  type: RegulatoryActionType.EXPORT_GAME_TYPES_REPORT,
  payload: { params },
});

export const getBalanceAdjustment = (params) => ({
  type: RegulatoryActionType.GET_BALANCE_ADJUSTMENT,
  payload: { params },
});

export const saveBalanceAdjustment = (data) => ({
  type: RegulatoryActionType.SAVE_BALANCE_ADJUSTMENT,
  payload: { data },
});

export const resetBalanceAdjustment = () => ({
  type: RegulatoryActionType.RESET_BALANCE_ADJUSTMENT,
});

export const exportBalanceAdjustment = (params) => ({
  type: RegulatoryActionType.EXPORT_BALANCE_ADJUSTMENT,
  payload: { params },
});

export const getSessions = (params) => ({
  type: RegulatoryActionType.GET_SESSIONS,
  payload: { params },
});

export const saveSessions = (data) => ({
  type: RegulatoryActionType.SAVE_SESSIONS,
  payload: { data },
});

export const resetSessions = () => ({
  type: RegulatoryActionType.RESET_SESSIONS,
});

export const exportSessions = (params) => ({
  type: RegulatoryActionType.EXPORT_SESSIONS,
  payload: { params },
});

export const getMirroringServer = (params) => ({
  type: RegulatoryActionType.GET_MIRRORING_SERVER,
  payload: { params },
});

export const saveMirroringServer = (data) => ({
  type: RegulatoryActionType.SAVE_MIRRORING_SERVER,
  payload: { data },
});

export const resetMirroringServer = () => ({
  type: RegulatoryActionType.RESET_MIRRORING_SERVER,
});

export const exportMirroringServer = (params) => ({
  type: RegulatoryActionType.EXPORT_MIRRORING_SERVER,
  payload: { params },
});

export const getSafeServer = (params) => ({
  type: RegulatoryActionType.GET_SAFE_SERVER,
  payload: { params },
});

export const saveSafeServer = (data) => ({
  type: RegulatoryActionType.SAVE_SAFE_SERVER,
  payload: { data },
});

export const resetSafeServer = () => ({
  type: RegulatoryActionType.RESET_SAFE_SERVER,
});

export const exportSafeServer = (params) => ({
  type: RegulatoryActionType.EXPORT_SAFE_SERVER,
  payload: { params },
});

export const getChangeNotification = (params) => ({
  type: RegulatoryActionType.GET_CHANGE_NOTIFICATION,
  payload: { params },
});

export const saveChangeNotification = (data) => ({
  type: RegulatoryActionType.SAVE_CHANGE_NOTIFICATION,
  payload: { data },
});

export const resetChangeNotification = () => ({
  type: RegulatoryActionType.RESET_CHANGE_NOTIFICATION,
});

export const exportChangeNotification = (params) => ({
  type: RegulatoryActionType.EXPORT_CHANGE_NOTIFICATION,
  payload: { params },
});

export const addChangeNotification = (data) => ({
  type: RegulatoryActionType.ADD_CHANGE_NOTIFICATION,
  payload: { data },
});

export const getInaccessibilityServer = (params) => ({
  type: RegulatoryActionType.GET_INACCESSIBILITY_SERVER,
  payload: { params },
});

export const saveInaccessibilityServer = (data) => ({
  type: RegulatoryActionType.SAVE_INACCESSIBILITY_SERVER,
  payload: { data },
});

export const resetInaccessibilityServer = () => ({
  type: RegulatoryActionType.RESET_INACCESSIBILITY_SERVER,
});

export const exportInaccessibilityServer = (params) => ({
  type: RegulatoryActionType.EXPORT_INACCESSIBILITY_SERVER,
  payload: { params },
});

export const addInaccessibilityServer = (data) => ({
  type: RegulatoryActionType.ADD_INACCESSIBILITY_SERVER,
  payload: { data },
});

export const deleteInaccessibilityServer = (id) => ({
  type: RegulatoryActionType.DELETE_INACCESSIBILITY_SERVER,
  payload: { id },
});

export const getSignificantEventsLargeTransactions = (params) => ({
  type: RegulatoryActionType.GET_SIGNIFICANT_EVENTS_LARGE_TRANSACTIONS,
  payload: { params },
});

export const saveSignificantEventsLargeTransactions = (data) => ({
  type: RegulatoryActionType.SAVE_SIGNIFICANT_EVENTS_LARGE_TRANSACTIONS,
  payload: { data },
});

export const resetSignificantEventsLargeTransactions = () => ({
  type: RegulatoryActionType.RESET_SIGNIFICANT_EVENTS_LARGE_TRANSACTIONS,
});

export const exportSignificantEventsLargeTransactions = (params) => ({
  type: RegulatoryActionType.EXPORT_SIGNIFICANT_EVENTS_LARGE_TRANSACTIONS,
  payload: { params },
});

export const getSignificantEventsRestrictions = (params) => ({
  type: RegulatoryActionType.GET_SIGNIFICANT_EVENTS_RESTRICTIONS,
  payload: { params },
});

export const saveSignificantEventsRestrictions = (data) => ({
  type: RegulatoryActionType.SAVE_SIGNIFICANT_EVENTS_RESTRICTIONS,
  payload: { data },
});

export const resetSignificantEventsRestrictions = () => ({
  type: RegulatoryActionType.RESET_SIGNIFICANT_EVENTS_RESTRICTIONS,
});

export const exportSignificantEventsRestrictions = (params) => ({
  type: RegulatoryActionType.EXPORT_SIGNIFICANT_EVENTS_RESTRICTIONS,
  payload: { params },
});

export const getJackpotWinRatio = (params) => ({
  type: RegulatoryActionType.GET_JACKPOT_WIN_RATIO,
  payload: { params },
});

export const saveJackpotWinRatio = (data) => ({
  type: RegulatoryActionType.SAVE_JACKPOT_WIN_RATIO,
  payload: { data },
});

export const resetJackpotWinRatio = () => ({
  type: RegulatoryActionType.RESET_JACKPOT_WIN_RATIO,
});

export const exportJackpotWinRatio = (params) => ({
  type: RegulatoryActionType.EXPORT_JACKPOT_WIN_RATIO,
  payload: { params },
});

export const getJackpotConfiguration = (params) => ({
  type: RegulatoryActionType.GET_JACKPOT_CONFIGURATION,
  payload: { params },
});

export const saveJackpotConfiguration = (data) => ({
  type: RegulatoryActionType.SAVE_JACKPOT_CONFIGURATION,
  payload: { data },
});

export const resetJackpotConfiguration = () => ({
  type: RegulatoryActionType.RESET_JACKPOT_CONFIGURATION,
});

export const exportJackpotConfiguration = (params) => ({
  type: RegulatoryActionType.EXPORT_JACKPOT_CONFIGURATION,
  payload: { params },
});

export const getPromotionalItems = (params) => ({
  type: RegulatoryActionType.GET_PROMOTIONAL_ITEMS,
  payload: { params },
});

export const savePromotionalItems = (data) => ({
  type: RegulatoryActionType.SAVE_PROMOTIONAL_ITEMS,
  payload: { data },
});

export const resetPromotionalItems = () => ({
  type: RegulatoryActionType.RESET_PROMOTIONAL_ITEMS,
});

export const exportPromotionalItems = (params) => ({
  type: RegulatoryActionType.EXPORT_PROMOTIONAL_ITEMS,
  payload: { params },
});

export const getTaxationDaily = (params) => ({
  type: RegulatoryActionType.GET_TAXATION_DAILY,
  payload: { params },
});

export const saveTaxationDaily = (data) => ({
  type: RegulatoryActionType.SAVE_TAXATION_DAILY,
  payload: { data },
});

export const resetTaxationDaily = () => ({
  type: RegulatoryActionType.RESET_TAXATION_DAILY,
});

export const exportTaxationDaily = (params) => ({
  type: RegulatoryActionType.EXPORT_TAXATION_DAILY,
  payload: { params },
});

export const getTaxationMonthly = (params) => ({
  type: RegulatoryActionType.GET_TAXATION_MONTHLY,
  payload: { params },
});

export const saveTaxationMonthly = (data) => ({
  type: RegulatoryActionType.SAVE_TAXATION_MONTHLY,
  payload: { data },
});

export const resetTaxationMonthly = () => ({
  type: RegulatoryActionType.RESET_TAXATION_MONTHLY,
});

export const exportTaxationMonthly = (params) => ({
  type: RegulatoryActionType.EXPORT_TAXATION_MONTHLY,
  payload: { params },
});

export const exportBettingSelfProtectionReport = (params, name) => ({
  type: RegulatoryActionType.EXPORT_BETTING_SELF_PROTECTION_REPORT,
  payload: { params, name },
});
