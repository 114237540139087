import { Tooltip } from 'antd';
import { useCallback, useEffect, useState } from 'react';
import { withTheme } from 'styled-components';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import SearchFields from 'containers/layout/header/SearchFields';
import { StyledListItemHeader } from 'components/layout/Layout.styled';
import { IconSvg } from 'components/common';

const Search = (props) => {
  const {
    theme: { colors },
    fieldAccesses,
  } = props;
  const [visible, setVisible] = useState(false);
  const { t } = useTranslation();

  const handleSearch = () => setVisible(true);

  const onClose = () => setVisible(false);

  const handleHotKeys = useCallback((e) => {
    // Ctrl + Q
    if (e.ctrlKey && e.keyCode === 81) {
      handleSearch();
    }
  }, []);

  useEffect(() => {
    document.addEventListener('keydown', handleHotKeys);
    return () => {
      document.removeEventListener('keydown', handleHotKeys);
    };
  }, [handleHotKeys]);

  return (
    <>
      {visible && <SearchFields visible={visible} changeModalState={onClose} fieldAccesses={fieldAccesses} />}
      <StyledListItemHeader onClick={handleSearch}>
        <Tooltip placement="bottom" title={t('quickSearch')}>
          <div>
            <IconSvg icon="SearchIcon" width="1.25rem" height="1.29rem" color={colors.white} />
          </div>
        </Tooltip>
      </StyledListItemHeader>
    </>
  );
};

Search.propTypes = {
  theme: PropTypes.object.isRequired,
  fieldAccesses: PropTypes.object.isRequired,
};

export default withTheme(Search);
