import AgentSystemActionType from 'actions/agentSystem/agentSystemActionType';

const initialState = {
  history: {
    data: [],
    total: 0,
  },
};

const agentSystemReducer = (state = initialState, { type, payload }) => {
  switch (type) {
  case AgentSystemActionType.SAVE_AGENT_SYSTEM_HISTORY:
    return {
      ...state,
      history: payload.data,
    };
  case AgentSystemActionType.RESET_AGENT_SYSTEM_HISTORY:
    return {
      ...state,
      history: {
        data: [],
        total: 0,
      },
    };
  default:
    return state;
  }
};

export default agentSystemReducer;
