import { Empty, List } from 'antd';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { AppConstants } from 'constants/index';
import { StyledPaginationWrapper } from 'components/styledComponents/pagination/Pagination.styled';

const AdminList = (props) => {
  const {
    createOrEditUserConfig, pageSize, pagination, rowKey, itemLayout, dataSource, renderItem, grid,
  } = props;

  const { t } = useTranslation();

  const onShowSizeChange = (current, pgSize) => {
    createOrEditUserConfig({ key: 'pageSize', configValue: pgSize });
  };

  const total = pagination ? pagination.total : 0;

  return (
    <StyledPaginationWrapper>
      <List
        rowKey={rowKey}
        itemLayout={itemLayout}
        renderItem={renderItem}
        dataSource={dataSource}
        grid={grid}
        locale={{
          emptyText: <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={t('noData')} />,
        }}
        pagination={
          pagination
          && total && {
            showSizeChanger: true,
            onShowSizeChange,
            pageSize,
            showLessItems: true,
            defaultPageSize: AppConstants.pageSize,
            pageSizeOptions: AppConstants.pageSizeOptions,
            showTotal: (totalValue, range) => t('showTotal', { min: range[0], max: range[1], total: totalValue }),
            ...pagination,
          }
        }
      />
    </StyledPaginationWrapper>
  );
};

AdminList.propTypes = {
  createOrEditUserConfig: PropTypes.func,
  pageSize: PropTypes.number,
  pagination: PropTypes.object,
  rowKey: PropTypes.string,
  itemLayout: PropTypes.string,
  dataSource: PropTypes.array,
  renderItem: PropTypes.func.isRequired,
  grid: PropTypes.object,
};

AdminList.defaultProps = {
  createOrEditUserConfig: () => {},
  pageSize: AppConstants.pageSize,
  pagination: undefined,
  rowKey: 'id',
  itemLayout: undefined,
  dataSource: [],
  grid: undefined,
};

export default AdminList;
