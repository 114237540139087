import { useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import IconSvg from 'components/common/icon/IconSvg';
import { StyledModalBig } from 'components/styledComponents/modal/Modal.styled';
import { StyledAddSettingsModalFooterButtons, StyledButtonBlue, StyledButtonBlueUnbordered } from 'components/styledComponents/button/Button.styled';
import {
  StyledBrandsModalLeft, StyledBrandsModalRight, StyledFlexModalTitle, StyledModalDescription,
} from 'components/brand/Brand.styled';
import { StyledIconDefault } from 'components/styledComponents/icon/Icon.styled';
import SearchableTable from 'containers/common/SearchableTable';

const AddSettingsModal = (props) => {
  const {
    visible, title, changeModalState, handleAddRows, columns, dataSource, searchBy, mainText, mainValue, icon, endedStatus, okText, cancelText,
  } = props;

  const [inputValue, setInputValue] = useState('');
  const [filtered, setFiltered] = useState([]);
  const { t } = useTranslation();
  const [selected, setSelected] = useState([]);

  const handleSearch = useCallback(
    (keyword) => {
      setInputValue(keyword);
      const newFiltered = [];
      dataSource.forEach((row) => {
        if (row[searchBy].toLowerCase().startsWith(keyword.toLowerCase())) {
          newFiltered.push(row.id);
        }
      });
      setFiltered(newFiltered);
    },
    [dataSource, searchBy],
  );

  useEffect(() => {
    handleSearch(inputValue);
  }, [handleSearch, inputValue]);

  const onClose = useCallback(() => {
    handleSearch('');
    changeModalState(!visible);
    setSelected([]);
  }, [changeModalState, handleSearch, visible]);

  const handleAdd = () => {
    handleAddRows(dataSource.filter((el) => selected.includes(el.id)));
  };

  useEffect(() => {
    if (endedStatus) {
      onClose();
    }
  }, [endedStatus, onClose]);

  return (
    <StyledModalBig
      getContainer=".ant-layout-content"
      open={visible}
      title={title}
      closable
      centered
      closeIcon={(
        <StyledIconDefault>
          <IconSvg icon="CloseModalIcon" width="1.14rem" height="1.14rem" />
        </StyledIconDefault>
      )}
      onCancel={onClose}
      width="60rem"
      footer={null}
      className="add-settings-modal"
      destroyOnClose
    >
      <StyledBrandsModalLeft>
        <StyledFlexModalTitle>
          {icon && <IconSvg icon={icon} width={12} height={12} color="#ffffff" />}
          <p>{mainText}</p>
        </StyledFlexModalTitle>
        <StyledModalDescription>{mainValue}</StyledModalDescription>
      </StyledBrandsModalLeft>
      <StyledBrandsModalRight>
        <SearchableTable data={dataSource} isSearchable columns={columns} isNumeric={false} placeholder={t('search')} searchBy={searchBy} selected={selected} setSelected={setSelected} />
        <StyledAddSettingsModalFooterButtons>
          <StyledButtonBlueUnbordered key="handleCancel" onClick={onClose}>
            {t(cancelText)}
          </StyledButtonBlueUnbordered>
          <StyledButtonBlue size="large" key="handleAdd" htmlType="submit" onClick={handleAdd} disabled={!(selected.length && filtered.length)}>
            {t(okText)}
          </StyledButtonBlue>
        </StyledAddSettingsModalFooterButtons>
      </StyledBrandsModalRight>
    </StyledModalBig>
  );
};

AddSettingsModal.propTypes = {
  visible: PropTypes.bool,
  title: PropTypes.string,
  changeModalState: PropTypes.func,
  handleAddRows: PropTypes.func.isRequired,
  columns: PropTypes.array.isRequired,
  dataSource: PropTypes.array,
  searchBy: PropTypes.string.isRequired,
  mainText: PropTypes.string.isRequired,
  mainValue: PropTypes.string,
  icon: PropTypes.string,
  endedStatus: PropTypes.bool,
  okText: PropTypes.string,
  cancelText: PropTypes.string,
};

AddSettingsModal.defaultProps = {
  visible: false,
  title: '',
  changeModalState: null,
  dataSource: [],
  mainValue: '',
  icon: 'StarIcon',
  endedStatus: false,
  okText: 'add',
  cancelText: 'cancel',
};

export default AddSettingsModal;
