const StaticCollectionActionType = {
  CHANGE_STATIC_COLLECTION_LOADING_STATE: 'CHANGE_STATIC_COLLECTION_LOADING_STATE',

  GET_LANGUAGES: 'GET_LANGUAGES',
  GET_LANGUAGES_SUCCESS: 'GET_LANGUAGES_SUCCESS',

  GET_CURRENCIES: 'GET_CURRENCIES',
  GET_CURRENCIES_SUCCESS: 'GET_CURRENCIES_SUCCESS',

  GET_COUNTRIES: 'GET_COUNTRIES',
  GET_COUNTRIES_SUCCESS: 'GET_COUNTRIES_SUCCESS',

  GET_CITIES: 'GET_CITIES',
  GET_CITIES_SUCCESS: 'GET_CITIES_SUCCESS',
  RESET_CITIES: 'RESET_CITIES',

  GET_TIME_ZONES: 'GET_TIME_ZONES',
  GET_TIME_ZONES_SUCCESS: 'GET_TIME_ZONES_SUCCESS',

  GET_OPERATION_TYPES: 'GET_OPERATION_TYPES',
  SAVE_OPERATION_TYPES: 'SAVE_OPERATION_TYPES',
  RESET_OPERATION_TYPES: 'RESET_OPERATION_TYPES',

  GET_REGIONS: 'GET_REGIONS',
  SAVE_REGIONS: 'SAVE_REGIONS',
  RESET_REGIONS: 'RESET_REGIONS',
};

export default StaticCollectionActionType;
