import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import pluralGetSet from 'dayjs/plugin/pluralGetSet';
import isoWeek from 'dayjs/plugin/isoWeek';
import duration from 'dayjs/plugin/duration';
import quarterOfYear from 'dayjs/plugin/quarterOfYear';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import localeData from 'dayjs/plugin/localeData';
import _ from 'lodash';
import { AppConstants } from 'constants/index';
import LocalStorageService from 'services/localStorageService';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(pluralGetSet);
dayjs.extend(isoWeek);
dayjs.extend(duration);
dayjs.extend(customParseFormat);
dayjs.extend(localeData);
dayjs.extend(quarterOfYear);

const DateService = {
  tz: LocalStorageService.get('userConfigs')?.timeZone ?? dayjs.tz.guess(),
  date: dayjs,
  dateTz: dayjs.tz,
  setDateTz: (tz) => {
    dayjs.tz.setDefault(tz);
    DateService.tz = tz;
  },

  /**
   * Is Valid Date.
   * @param   {Date|String|null|undefined} date.
   * @return  {Boolean}
   */
  isValidDate: (date) => (!_.isString(date) ? date?.isValid() : DateService.date(date).isValid()),

  /**
   * Convert UTC to Local date.
   * @param   {Date|String}  dateTz object Date or String.
   * @return  {Object|null}    Return a dateTz object or null.
   */
  getLocalDate: (date) => {
    if (DateService.isValidDate(date)) {
      const data = !_.isString(date) ? date : DateService.dateTz(date);
      return data.isUTC() ? data.utc(true) : DateService.date.utc(date).tz(DateService.tz);
    }
    return null;
  },

  /**
   * Get formatted date.
   * @param   {String}    ISO string    Date.
   * @param   {String}  format
   * @return  {String|null}    Return a formatted date or null.
   */
  getFormattedDate: (date, format = AppConstants.format.date) => DateService.getLocalDate(date)?.format(format),

  /**
   * @param {String} date ISO string Date.
   * @param   {String}  format
   * @param timeZone
   * @returns {string|null}
   */
  getFormattedDateByTimeZone: (date, timeZone, format = AppConstants.format.date) => {
    if (DateService.isValidDate(date)) {
      return DateService.dateTz(date).tz(timeZone).isUTC() ? DateService.dateTz(date, timeZone).format(format) : dayjs.utc(date).tz(timeZone).format(format);
    }
    return null;
  },

  /**
   * Convert UTC to Local date.
   * @param {dayjs.Dayjs|String|undefined} date
   * @returns {dayjs.Dayjs}
   */
  getDateTz: (date) => {
    if (DateService.dateTz(date)?.isUTC()) {
      return _.isString(date) || !date ? DateService.dateTz(date).utc(true) : date.utc(true);
    }
    if (date && !_.isString(date)) {
      return DateService.date.utc(DateService.convertDateObjectToDateString(date)).tz(DateService.tz, true);
    }
    return DateService.dateTz(date);
  },

  /**
   * Set formatted date.
   * @param   {String}    ISO string    Date.
   * @return  {Object|null}    Return a dateTz object or null.
   */
  setFormattedDate: (date) => (DateService.isValidDate(date) ? DateService.getDateTz(date) : null),

  /**
   * Get start date.
   * @param   {Date}    dateTz object    Date.
   * @return  {Date}    Return a start date.
   */
  getStartDate: (date) => (date || DateService.getDateTz()).startOf('day'),

  /**
   * Get end date.
   * @param   {Date}    dateTz object    Date.
   * @return  {String}    Return an end date.
   */
  getEndDate: (date) => (date || DateService.getDateTz()).endOf('day'),

  /**
   * Get ISO date.
   * @return  {String|null}    Return a date with ISO format or null.
   * @param {Date|String} date
   * @param {Boolean} keepLocalTime
   */
  getIsoDate: (date, keepLocalTime) => {
    if (!DateService.isValidDate(date)) {
      return null;
    }

    const data = !_.isString(date) ? DateService.convertDateObjectToDateString(date) : date;
    return DateService.dateTz(data).utc(keepLocalTime).toISOString();
  },

  /**
   *
   * @param date dateTz object Date.
   * @returns {*} Return a formatted date.
   */
  convertDateObjectToDateString: (date) => date?.format(AppConstants.format.fullDateTime),

  /**
   * @param {Boolean} includeEndOfDate
   * @returns Date object
   */
  getDateRanges: (includeEndOfDate) => {
    if (includeEndOfDate) {
      return {
        lastMonth: [DateService.getDateTz().subtract(1, 'months').startOf('month').startOf('day'), DateService.getDateTz().subtract(1, 'months').endOf('month').add(1, 'days')
          .startOf('day')],
        thisMonth: [DateService.getDateTz().startOf('month').startOf('day'), null],
        lastWeek: [DateService.getDateTz().subtract(1, 'weeks').startOf('isoWeek').startOf('day'), DateService.getDateTz().subtract(1, 'weeks').endOf('isoWeek').add(1, 'days')
          .startOf('day')],
        thisWeek: [DateService.getDateTz().startOf('isoWeek').startOf('day'), null],
        yesterday: [DateService.getDateTz().subtract(1, 'days').startOf('day'), DateService.getDateTz().startOf('day')],
        today: [DateService.getDateTz().startOf('day'), null],
      };
    }

    return {
      lastMonth: [DateService.getDateTz().subtract(1, 'months').startOf('month').startOf('day'), DateService.getDateTz().subtract(1, 'months').endOf('month').endOf('day')],
      thisMonth: [DateService.getDateTz().startOf('month').startOf('day'), null],
      lastWeek: [DateService.getDateTz().subtract(1, 'weeks').startOf('isoWeek').startOf('day'), DateService.getDateTz().subtract(1, 'weeks').endOf('isoWeek').endOf('day')],
      thisWeek: [DateService.getDateTz().startOf('isoWeek').startOf('day'), null],
      yesterday: [DateService.getDateTz().subtract(1, 'days').startOf('day'), DateService.getDateTz().subtract(1, 'days').endOf('day')],
      today: [DateService.getDateTz().startOf('day'), null],
    };
  },
};

export default DateService;
