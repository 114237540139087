import {
  all, call, put, takeLatest,
} from 'redux-saga/effects';
import {
  changeEndedStatus, changeLoadingState, setErrorMessage, setSuccessMessage,
} from 'actions/setting/settingActionCreator';
import NetworkService from 'services/networkService';
import { AppConstants } from 'constants/index';
import { getError, getMessage } from 'utils/helpers';
import { saveExportHistory } from 'actions/exportCenter/exportCenterActionCreator';
import { i18n } from 'services';
import ExportCenterActionType from 'actions/exportCenter/exportCenterActionType';

function* getExportHistory({ payload }) {
  try {
    const { params } = payload;
    const options = {
      params,
    };
    const { ReportingAdmin, ExportCenter } = AppConstants.api;
    yield put(changeLoadingState(true));
    const { data } = yield call(NetworkService.makeAPIGetRequest, [ReportingAdmin, ExportCenter], options);
    yield put(saveExportHistory(data));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* deleteExportHistoryFile({ payload }) {
  try {
    const { id, name } = payload;
    const { ReportingAdmin, ExportCenter } = AppConstants.api;
    yield put(changeLoadingState(true));
    yield call(NetworkService.makeAPIDeleteRequest, [ReportingAdmin, ExportCenter, id]);
    yield put(setSuccessMessage(getMessage(i18n.t(name), i18n.t('notification:slDeleted'))));
    yield put(changeEndedStatus(true));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}
function* cancelExportHistoryFileGeneration({ payload }) {
  try {
    const { id, name } = payload;
    const { ReportingAdmin, ExportCenter, Cancel } = AppConstants.api;
    yield put(changeLoadingState(true));
    yield call(NetworkService.makeAPIPutRequest, [ReportingAdmin, ExportCenter, id, Cancel]);
    yield put(setSuccessMessage(getMessage(i18n.t(name), i18n.t('notification:slCancelled'))));
    yield put(changeEndedStatus(true));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

export default function* exportCenterSaga() {
  yield all([
    takeLatest(ExportCenterActionType.GET_EXPORT_HISTORY, getExportHistory),
    takeLatest(ExportCenterActionType.DELETE_EXPORT_HISTORY_FILE, deleteExportHistoryFile),
    takeLatest(ExportCenterActionType.CANCEL_EXPORT_HISTORY_FILE_GENERATION, cancelExportHistoryFileGeneration),
  ]);
}
