import { createSelector } from 'reselect';

export const selectBrandFailedPaymentsSettings = (state) => state.brandFailedPaymentsSettings;
const selectBrandPaymentMethods = (state) => state.brandPaymentMethods;
export const selectBrandPaymentMethodsData = (state) => selectBrandPaymentMethods(state).data;
export const selectBrandPaymentMethodsTotal = (state) => selectBrandPaymentMethods(state).total;
export const selectBrandPaymentSettings = (state) => state.brandPaymentSettings;
export const selectBrandPaymentMethodSettings = (state) => state.brandPaymentMethodSettings;
export const selectBrandPaymentMethodName = (state) => selectBrandPaymentMethodSettings(state)?.paymentMethodName;

const selectBrandPaymentMethodsFees = (state) => state.brandFees;
export const selectBrandPaymentMethodsFeesData = (state) => selectBrandPaymentMethodsFees(state).data;
export const selectBrandPaymentMethodsFeesTotal = (state) => selectBrandPaymentMethodsFees(state).total;
const selectBrandPaymentMethodsLimits = (state) => state.brandLimits;
export const selectBrandPaymentMethodsLimitsData = (state) => selectBrandPaymentMethodsLimits(state).data;
export const selectBrandPaymentMethodsLimitsTotal = (state) => selectBrandPaymentMethodsLimits(state).total;
export const selectPaymentCurrencies = (state) => state.paymentCurrencies;
export const selectBrandPaymentAllowedCurrencies = (state) => state.brandPaymentAllowedCurrencies;
export const selectBrandPaymentAllowedCountries = (state) => state.brandPaymentAllowedCountries;
export const selectBrandPaymentsActiveCurrencies = (state) => state.brandPaymentsActiveCurrencies;
export const selectBrandPaymentActiveCurrencies = (state, id) => createSelector(
  () => selectBrandPaymentsActiveCurrencies(state),
  (data) => data[id] || [],
)();

export const selectBrandPaymentMethodTags = (state) => state.brandPaymentMethodTags;
