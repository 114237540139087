const TaxesActionType = {
  GET_DEPOSIT_TAXES: 'GET_DEPOSIT_TAXES',
  SAVE_DEPOSIT_TAXES: 'SAVE_DEPOSIT_TAXES',
  RESET_DEPOSIT_TAXES: 'RESET_DEPOSIT_TAXES',

  EDIT_DEPOSIT_TAX: 'EDIT_DEPOSIT_TAX',

  GET_DEPOSIT_TAX: 'GET_DEPOSIT_TAX',
  SAVE_DEPOSIT_TAX: 'SAVE_DEPOSIT_TAX',
  RESET_DEPOSIT_TAX: 'RESET_DEPOSIT_TAX',

  GET_WITHDRAWAL_TAXES: 'GET_WITHDRAWAL_TAXES',
  SAVE_WITHDRAWAL_TAXES: 'SAVE_WITHDRAWAL_TAXES',
  RESET_WITHDRAWAL_TAXES: 'RESET_WITHDRAWAL_TAXES',

  EDIT_WITHDRAWAL_TAX: 'EDIT_WITHDRAWAL_TAX',

  GET_WITHDRAWAL_TAX: 'GET_WITHDRAWAL_TAX',
  SAVE_WITHDRAWAL_TAX: 'SAVE_WITHDRAWAL_TAX',
  RESET_WITHDRAWAL_TAX: 'RESET_WITHDRAWAL_TAX',

  GET_TAX_SETTING: 'GET_TAX_SETTING',
  SAVE_TAX_SETTING: 'SAVE_TAX_SETTING',
  RESET_TAX_SETTING: 'RESET_TAX_SETTING',

  ADD_TAX_SETTING: 'ADD_TAX_SETTING',
};

export default TaxesActionType;
