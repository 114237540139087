const selectGamesSegments = (state) => state.gamesSegments;
export const selectGamesSegmentsData = (state) => selectGamesSegments(state).segments;
export const selectGamesSegmentsTotal = (state) => selectGamesSegments(state).total;
const selectSegmentGames = (state) => state.segmentGames;
export const selectSegmentGamesData = (state) => selectSegmentGames(state).data;
export const selectSegmentGamesTotal = (state) => selectSegmentGames(state).total;
const selectGameSegment = (state) => state.gameSegment;
export const selectGameSegmentCategories = (state) => selectGameSegment(state).categories;
export const selectGameSegmentProviders = (state) => selectGameSegment(state).providers;
export const selectGameSegmentName = (state) => selectGameSegment(state).name;
