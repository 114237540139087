import PropTypes from 'prop-types';
import { StyledSwitchSmall } from 'components/styledComponents/switch/Switch.styled';
import { StyledProductsSwitcherWrapper, StyledProductsSwitcher, StyledProductDefault } from 'components/brand/Brand.styled';

const Games = (props) => {
  const {
    name, checked, changeState, text,
  } = props;

  return (
    <StyledProductsSwitcherWrapper>
      <StyledProductsSwitcher>
        <h2>{name}</h2>
      </StyledProductsSwitcher>
      <StyledProductDefault>
        <span>{text}</span>
      </StyledProductDefault>
      <StyledSwitchSmall size="small" onChange={changeState} checked={checked} />
    </StyledProductsSwitcherWrapper>
  );
};

Games.propTypes = {
  name: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  checked: PropTypes.bool,
  changeState: PropTypes.func.isRequired,
};
Games.defaultProps = {
  checked: false,
};
export default Games;
