export const AppConstants = {
  methods: {
    GET: 'get',
    POST: 'post',
    PUT: 'put',
    DELETE: 'delete',
    PATCH: 'patch',
  },
  api: {
    Admin: 'admin',
    Account: 'account',
    Token: 'token',
    AdminUser: 'adminUser',
    PlayersAdmin: 'playersAdmin',
    AdminPlayer: 'adminPlayer',
    GetPlayers: 'getPlayers',
    GetPlayer: 'getPlayer',
    GetPlayerData: 'getPlayerData',
    EditProfile: 'editProfile',
    BrandAdmin: 'brandAdmin',
    Segment: 'segment',
    Brand: 'brand',
    Player: 'player',
    Players: 'players',
    Country: 'country',
    Language: 'language',
    NotUsed: 'notUsed',
    Partners: 'partners',
    Partner: 'partner',
    Currency: 'currency',
    BrandCurrency: 'brandCurrency',
    BrandCountry: 'brandCountry',
    GetCities: 'getCities',
    BrandLanguage: 'brandLanguage',
    EditPartner: 'editPartner',
    GetWithBrands: 'getWithBrands',
    GetPartnerBrands: 'getPartnerBrands',
    GetCSVPlayers: 'getCSVPlayers',
    GetByBrand: 'getByBrand',
    ByBrand: 'byBrand',
    GetById: 'getById',
    Get: 'get',
    PUT: 'put',
    ForgotPassword: 'forgotPassword',
    ResetPassword: 'resetPassword',
    ResendTFA: 'resendTFA',
    Status: 'status',
    SetMain: 'setMain',
    ChangeOrder: 'changeOrder',
    User: 'user',
    GetByPartnerId: 'getByPartnerId',
    Permission: 'permission',
    Permissions: 'permissions',
    Resource: 'resource',
    PermissionGroup: 'permissionGroup',
    All: 'all',
    AssignPermissions: 'assignPermissions',
    UserConfig: 'userConfig',
    ChangePassword: 'changePassword',
    ChangeExpiredPassword: 'changeExpiredPassword',
    PartnerAccesses: 'partnerAccesses',
    BrandAccesses: 'brandAccesses',
    Brands: 'brands',
    NotificationAdmin: 'notificationAdmin',
    CustomTemplate: 'customTemplate',
    SystemTemplate: 'systemTemplate',
    SystemTemplateTypes: 'systemTemplateTypes',
    MassMessages: 'massMessages',
    RecipientsDetail: 'recipientsDetail',
    GetTags: 'getTags',
    Sent: 'sent',
    Scheduled: 'scheduled',
    Draft: 'draft',
    Translation: 'translation',
    Sections: 'sections',
    Reset: 'reset',
    Set: 'set',
    InAllSections: 'inAllSections',
    TranslationsForForm: 'translationsForForm',
    Export: 'export',
    Import: 'import',
    ResetForLanguages: 'resetForLanguages',
    Languages: 'languages',
    GetReport: 'getReport',
    PlayerNote: 'playerNote',
    GetNoteTypes: 'getNoteTypes',
    BrandMarketSetting: 'brandMarketSetting',
    MarketGeneralSetting: 'marketGeneralSetting',
    Inbox: 'inbox',
    CanReply: 'canReply',
    Recall: 'recall',
    MarkAsRead: 'markAsRead',
    ChangeStatus: 'changeStatus',
    GetByPlayer: 'getByPlayer',
    MessageByPlayer: 'messageByPlayer',
    ByPlayer: 'byPlayer',
    Positions: 'positions',
    Supervisors: 'supervisors',
    FileManager: 'fileManager',
    Directories: 'directories',
    FileTypeGroups: 'fileTypeGroups',
    UploadFile: 'uploadFile',
    UploadFiles: 'uploadFiles',
    Files: 'files',
    RenamePublicFile: 'renamePublicFile',
    RenamePublicDirectory: 'renamePublicDirectory',
    DeleteFiles: 'deleteFiles',
    DeletePublicDirectory: 'deletePublicDirectory',
    BrandDomain: 'brandDomain',
    BrandSportDomain: 'brandSportDomain',
    Connect: 'connect',
    DisConnect: 'disconnect',
    Game: 'game',
    Providers: 'providers',
    Provider: 'provider',
    Categories: 'categories',
    Category: 'category',
    CategoryById: 'categoryById',
    Games: 'games',
    GameSegment: 'gameSegment',
    Edit: 'edit',
    BySegment: 'bySegment',
    Update: 'Update',
    BonusAdmin: 'bonusAdmin',
    BonusTemplate: 'bonusTemplate',
    BuilderAdmin: 'builderAdmin',
    UiCustomization: 'uiCustomization',
    GetPlayerProfileControls: 'getPlayerProfileControls',
    Reporting: 'reporting',
    PlayerReport: 'playerReport',
    Segments: 'segments',
    SegmentPlayers: 'segmentPlayers',
    KYC: 'KYC',
    Reject: 'reject',
    Approve: 'approve',
    Archiv: 'archiv',
    Verify: 'verify',
    History: 'history',
    PromoCode: 'promoCode',
    PromoCodes: 'promoCodes',
    PromoCodePack: 'promoCodePack',
    Used: 'used',
    Available: 'available',
    ResponsibleGaming: 'responsibleGaming',
    Bonus: 'bonus',
    Actions: 'actions',
    Reasons: 'reasons',
    Attention: 'attention',
    Unlock: 'unlock',
    BlockInbox: 'blockInbox',
    SMSSettings: 'smsSettings',
    BrandSetting: 'brandSetting',
    GetByBrandId: 'getByBrandId',
    Rule: 'rule',
    Disable: 'disable',
    Finish: 'finish',
    Cancel: 'cancel',
    Trigger: 'trigger',
    CancelByPlayerId: 'cancelByPlayerId',
    CancelByBonusPlayerId: 'cancelByBonusPlayerId',
    GetStatistics: 'getStatistics',
    GetBonusInAccount: 'getBonusInAccount',
    PlayerBulk: 'playerBulk',
    Transaction: 'transaction',
    Wallet: 'wallet',
    BalanceAdjustmentReport: 'balanceAdjustmentReport',
    BalanceAdjustmentByPlayerId: 'balanceAdjustmentByPlayerId',
    CustomCodes: 'customCodes',
    ExternalService: 'externalService',
    Activate: 'activate',
    Add: 'add',
    MissingProviders: 'missingProviders',
    ReportingAdmin: 'reportingAdmin',
    ActivateGame: 'activateGame',
    GetMarkets: 'getMarkets',
    MoveToDraft: 'moveToDraft',
    DirectoryHierarchy: 'directoryHierarchy',
    MovePublicFiles: 'movePublicFiles',
    BrandNameExist: 'brandNameExist',
    Templates: 'templates',
    TitleExists: 'titleExists',
    SmtpSettings: 'smtpSettings',
    Custom: 'custom',
    Cashback: 'cashback',
    Captcha: 'captcha',
    PlayerSegments: 'playerSegments',
    Except: 'except',
    Enable: 'enable',
    BulkActions: 'bulkActions',
    BulkActionDetails: 'bulkActionDetails',
    PreviousAccounts: 'previousAccounts',
    Statistics: 'statistics',
    Periods: 'periods',
    TransferFailed: 'transferFailed',
    Failed: 'failed',
    ByPlayerPeriods: 'byPlayerPeriods',
    Transfer: 'transfer',
    ProviderGames: 'providerGames',
    LoginPlayerInfo: 'loginPlayerInfo',
    Dormant: 'dormant',
    AddTestPlayer: 'addTestPlayer',
    GetTestPlayers: 'getTestPlayers',
    AddTestPlayerUser: 'addTestPlayerUser',
    FinishUseTestPlayer: 'finishUseTestPlayer',
    AdjustBalance: 'adjustBalance',
    QuickSearch: 'quickSearch',
    GetTestUsers: 'getTestUsers',
    EditTestPlayerUser: 'editTestPlayerUser',
    SimilarAccounts: 'similarAccounts',
    SimilarAccountGroups: 'similarAccountGroups',
    BulkActionSegments: 'bulkActionSegments',
    ParseBalanceAdjustmentCsv: 'parseBalanceAdjustmentCsv',
    BulkAdjustBalance: 'bulkAdjustBalance',
    BalanceAdjustmentsForBrand: 'balanceAdjustmentsForBrand',
    BalanceAdjustmentTransactions: 'balanceAdjustmentTransactions',
    EmailContact: 'emailContact',
    Conversion: 'conversion',
    Deposit: 'deposit',
    Withdrawal: 'withdrawal',
    Others: 'others',
    Log: 'log',
    Referral: 'referral',
    ExistingPlayers: 'existingPlayers',
    New: 'new',
    Monitoring: 'monitoring',
    Commissions: 'commissions',
    CommissionDetails: 'commissionDetails',
    Standards: 'standards',
    NotificationPreference: 'notificationPreference',
    Verification: 'verification',
    Details: 'details',
    Referees: 'referees',
    ByPlayerReferees: 'byPlayerReferees',
    TimeZones: 'timeZones',
    TimeZone: 'timeZone',
    AllPlayers: 'allPlayers',
    Manage: 'manage',
    SelfExclusion: 'selfExclusion',
    Timeout: 'timeout',
    Bet: 'bet',
    ResponsibleGamingHistory: 'responsibleGamingHistory',
    Bulk: 'bulk',
    StatusBulk: 'statusBulk',
    EditInfoBulk: 'editInfoBulk',
    AssignPermissionsBulk: 'assignPermissionsBulk',
    Simple: 'simple',
    PlayerSegment: 'playerSegment',
    Blacklist: 'blacklist',
    Validate: 'validate',
    Tags: 'tags',
    Multiple: 'multiple',
    Restriction: 'restriction',
    CountryCodes: 'countryCodes',
    IpRanges: 'ipRanges',
    Move: 'move',
    Merge: 'merge',
    TagPlayers: 'tagPlayers',
    UnAssign: 'unAssign',
    ByCategory: 'byCategory',
    AuditLog: 'auditLog',
    UserLogs: 'userLogs',
    PlayerLogs: 'playerLogs',
    PaymentRequest: 'paymentRequest',
    Deposits: 'deposits',
    Withdrawals: 'withdrawals',
    PaymentAdmin: 'paymentAdmin',
    Note: 'note',
    BrandPaymentMethod: 'brandPaymentMethod',
    PaymentMethods: 'paymentMethods',
    PaymentMethod: 'paymentMethod',
    WithdrawalInProcess: 'withdrawalInProcess',
    WithdrawalToApproved: 'withdrawalToApproved',
    WithdrawalToFrozen: 'withdrawalToFrozen',
    WithdrawalToCancelled: 'withdrawalToCancelled',
    WithdrawalToDeclined: 'withdrawalToDeclined',
    OfflineWithdrawalToProcess: 'offlineWithdrawalToProcess',
    OfflineWithdrawalToApproved: 'offlineWithdrawalToApproved',
    OfflineWithdrawalToDeclined: 'offlineWithdrawalToDeclined',
    ClosedAccounts: 'closedAccounts',
    BetReport: 'betReport',
    Casino: 'casino',
    Sport: 'sport',
    Hubs: 'hubs',
    NotificationHub: 'notificationHub',
    LargeTransactions: 'largeTransactions',
    Summary: 'summary',
    Remainder: 'remainder',
    FailedPayments: 'failedPayments',
    WhiteList: 'whiteList',
    LegalAgreements: 'legalAgreements',
    Versions: 'versions',
    Policies: 'policies',
    LargeTransaction: 'largeTransaction',
    Knowledge: 'knowledge',
    OperationTypes: 'operationTypes',
    NegativeBalance: 'negativeBalance',
    Automation: 'automation',
    Exists: 'exists',
    Conditions: 'conditions',
    Items: 'items',
    GetPlayerProfileControlNames: 'getPlayerProfileControlNames',
    AccountUpdate: 'accountUpdate',
    BalanceAdjustment: 'balanceAdjustment',
    Periodical: 'periodical',
    Documents: 'documents',
    BiBetReport: 'biBetReport',
    ByCurrency: 'byCurrency',
    Report: 'report',
    Reports: 'reports',
    BiPaymentReport: 'biPaymentReport',
    StartProgress: 'startProgress',
    Names: 'names',
    BrandPaymentMethods: 'brandPaymentMethods',
    BrandPaymentGlobal: 'brandPaymentGlobal',
    BrandPaymentGlobalSetting: 'brandPaymentGlobalSetting',
    Fees: 'fees',
    Limits: 'limits',
    ForwardUrl: 'forwardUrl',
    PaymentCurrencies: 'paymentCurrencies',
    AllowedCurrencies: 'allowedCurrencies',
    UserManagement: 'userManagement',
    UserManagementLogs: 'userManagementLogs',
    PaymentReport: 'paymentReport',
    AllowedCountries: 'allowedCountries',
    CashierInventory: 'cashierInventory',
    CashierDeposit: 'cashierDeposit',
    Reorder: 'reorder',
    CashierWithdrawal: 'cashierWithdrawal',
    CashierDepositPayment: 'cashierDepositPayment',
    CashierWithdrawalPayment: 'cashierWithdrawalPayment',
    GeneralSetting: 'generalSetting',
    CashierSetting: 'cashierSetting',
    Currencies: 'currencies',
    Countries: 'countries',
    AddPlayer: 'addPlayer',
    PaymentRequestReason: 'paymentRequestReason',
    PlayerInfo: 'playerInfo',
    SportSettings: 'sportSettings',
    SportsBook: 'sportsBook',
    StatusChange: 'statusChange',
    State: 'state',
    Aggregation: 'aggregation',
    Sessions: 'sessions',
    WalletFlowHistory: 'walletFlowHistory',
    StatusUpdates: 'statusUpdates',
    OfflineDepositStatusChange: 'offlineDepositStatusChange',
    PlayerTotals: 'playerTotals',
    Dashboards: 'dashboards',
    Widgets: 'widgets',
    RemoveWidget: 'removeWidget',
    Maintenance: 'maintenance',
    ByNotification: 'byNotification',
    Seo: 'seo',
    Settings: 'settings',
    SocialMedia: 'socialMedia',
    Schema: 'schema',
    Missing: 'missing',
    Themes: 'themes',
    SystemConversion: 'systemConversion',
    Badges: 'badges',
    GameGroup: 'gameGroup',
    ContactInfo: 'contactInfo',
    DataCollector: 'dataCollector',
    Rgs: 'rgs',
    FreeSpin: 'freeSpin',
    FreeSpinBonuses: 'freeSpinBonuses',
    Amount: 'amount',
    Restrictions: 'restrictions',
    BlockProvidersHistory: 'blockProvidersHistory',
    BulkBlockProvidersHistory: 'bulkBlockProvidersHistory',
    BlockProviders: 'blockProviders',
    BulkBlockProviders: 'bulkBlockProviders',
    BlockPaymentsHistory: 'blockPaymentsHistory',
    BlockPayments: 'blockPayments',
    UnblockPayments: 'unblockPayments',
    BulkBlockPaymentsHistory: 'bulkBlockPaymentsHistory',
    BulkBlockPayments: 'bulkBlockPayments',
    BulkUnblockPayments: 'bulkUnblockPayments',
    BlockedPaymentMethod: 'blockedPaymentMethod',
    Block: 'block',
    Unblock: 'unblock',
    Overview: 'overview',
    Activities: 'activities',
    Financial: 'financial',
    Revenues: 'revenues',
    Check: 'check',
    Configs: 'configs',
    GameCategories: 'gameCategories',
    Payment: 'payment',
    Users: 'users',
    SignUp: 'signUp',
    Campaigns: 'campaigns',
    TriggerConditions: 'triggerConditions',
    CancelPromotion: 'cancelPromotion',
    NoTrigger: 'noTrigger',
    CasinoBonuses: 'casinoBonuses',
    SportBonuses: 'sportBonuses',
    SpecialBonuses: 'specialBonuses',
    SimilarAccountsInfo: 'similarAccountsInfo',
    PreviousAccountsInfo: 'previousAccountsInfo',
    Resources: 'resources',
    External: 'external',
    Changes: 'changes',
    Exclusions: 'exclusions',
    Types: 'types',
    TagCategories: 'tagCategories',
    Vertical: 'vertical',
    Priority: 'priority',
    Horizontal: 'horizontal',
    Top: 'top',
    Assign: 'assign',
    Reason: 'reason',
    Taxes: 'taxes',
    Wallets: 'wallets',
    Personal: 'personal',
    SportConfiguration: 'sportConfiguration',
    PrematchTopMatches: 'prematchTopMatches',
    PrematchTotalTopMatches: 'prematchTotalTopMatches',
    LivePrematchTopMatches: 'livePrematchTopMatches',
    LiveTopMatches: 'liveTopMatches',
    Jackpot: 'jackpot',
    SectionScroling: 'sectionScroling',
    TournamentBalance: 'tournamentBalance',
    IntegrationType: 'integrationType',
    Mirroring: 'mirroring',
    Safe: 'safe',
    Server: 'server',
    Inaccessibility: 'inaccessibility',
    RealityCheck: 'realityCheck',
    SiteStyle: 'siteStyle',
    Affiliates: 'affiliates',
    Deactivate: 'deactivate',
    Analytics: 'analytics',
    GoogleAnalytics: 'googleAnalytics',
    HotJar: 'hotJar',
    GoogleSearchConsole: 'googleSearchConsole',
    Code: 'code',
    Chats: 'chats',
    LiveChat: 'liveChat',
    LiveAgent: 'liveAgent',
    Zendesk: 'zendesk',
    Intercom: 'intercom',
    Agents: 'agents',
    EveryMatrix: 'everyMatrix',
    Smartico: 'smartico',
    MyAffiliates: 'myAffiliates',
    SignIn: 'signIn',
    Bonuses: 'bonuses',
    Special: 'special',
    Digitain: 'digitain',
    Pages: 'pages',
    PreviousAccountGroups: 'previousAccountGroups',
    PageGroups: 'pageGroups',
    AgentSystems: 'agentSystems',
    Resend: 'resend',
    Crm: 'crm',
    Gamifications: 'gamifications',
    IgniteCache: 'igniteCache',
    SystemCaches: 'systemCaches',
    Data: 'data',
    Betshops: 'betshops',
    DbSync: 'dbSync',
    Services: 'services',
    Tables: 'tables',
    Sync: 'sync',
    Jobs: 'jobs',
    Retails: 'retails',
    ResendTFABulk: 'resendTFABulk',
    TFAStatus: 'tFAStatus',
    Lobbies: 'lobbies',
    HomeWidgets: 'homeWidgets',
    Order: 'order',
    Upload: 'upload',
    Lobby: 'lobby',
    CampaignsCrossPlatform: 'campaignsCrossPlatform',
    PromoAdmin: 'promoAdmin',
    Promotions: 'promotions',
    SpinDistributingConfigs: 'spinDistributingConfigs',
    Prizes: 'prizes',
    Machines: 'machines',
    MachineCondition: 'machineCondition',
    Configure: 'configure',
    GroupLimits: 'groupLimits',
    Publish: 'publish',
    Contents: 'contents',
    Clone: 'clone',
    Transactions: 'transactions',
    PrizeReports: 'prizeReports',
    ManualSpin: 'manualSpin',
    ManualSpinReports: 'manualSpinReports',
    Logs: 'logs',
    ChangeNotification: 'changeNotification',
    VerificationPeriod: 'verificationPeriod',
    IncludedPlayer: 'includedPlayer',
    ManageIncludedPlayer: 'ManageIncludedPlayer',
    JackpotWinRatio: 'jackpotWinRatio',
    JackpotConfiguration: 'jackpotConfiguration',
    PromotionalItems: 'promotionalItems',
    LimitationPeriods: 'limitationPeriods',
    notificationPreferences: 'notificationPreferences',
    depositPreferences: 'depositPreferences',
    withdrawalsPreferences: 'withdrawalsPreferences',
    promotionPreferences: 'promotionPreferences',
    BonusesPreferences: 'bonusesPreferences',
    Tag: 'tag',
    SportWalletConfiguration: 'sportWalletConfiguration',
    ResetTestPlayerUserPassword: 'resetTestPlayerUserPassword',
    Generate: 'generate',
    ExportCenter: 'exportCenter',
    Precisions: 'precisions',
    Groups: 'groups',
    DdaWithdrawal: 'ddaWithdrawal',
    FixedWithdrawalAmount: 'fixedWithdrawalAmount',
    PlayerReports: 'playerReports',
    Spins: 'spins',
    AdjustManualSpin: 'adjustManualSpin',
    WhatsApp: 'whatsApp',
    Aggregators: 'aggregators',
    Promotion: 'promotion',
    Machine: 'machine',
    MachineContents: 'machineContents',
    PromotionContents: 'promotionContents',
    CasinoGameSetting: 'casinoGameSetting',
    Reset2FA: 'reset2FA',
    DistributionTypes: 'distributionTypes',
    PushNotifications: 'pushNotifications',
    Recipients: 'recipients',
    NameExists: 'nameExists',
    Notifications: 'notifications',
    ProviderViews: 'providerViews',
    Sportsbook: 'sportsbook',
    PrivateParticipants: 'privateParticipants',
    GridMachineDimensionTypes: 'gridMachineDimensionTypes',
    ContentConfig: 'contentConfig',
    MaterialPrize: 'materialPrize',
    MaterialPrizeStatistics: 'materialPrizeStatistics',
    MachineSpinPrizeStatistics: 'machineSpinPrizeStatistics',
    BalanceMultiplierPrizeStatistics: 'balanceMultiplierPrizeStatistics',
    Format: 'format',
    BonusNotifications: 'bonusNotifications',
    PlayersFirstDeposit: 'playersFirstDeposit',
    ExportByPartnerId: 'exportByPartnerId',
    Indicators: 'indicators',
    Page: 'page',
    TotalNetBalance: 'totalNetBalance',
    Participants: 'participants',
    AccessLimit: 'accessLimit',
    AccessLimitOverview: 'accessLimitOverview',
    AccessLimitPending: 'accessLimitPending',
    AccessLimitLog: 'accessLimitLog',
    AssignedTagCategories: 'assignedTagCategories',
    AssignedTags: 'assignedTags',
    SpinDistributionContents: 'spinDistributionContents',
    SpinDistribution: 'spinDistribution',
    Taxation: 'taxation',
    Totals: 'totals',
    Payments: 'payments',
    ByType: 'byType',
    ByStatus: 'byStatus',
    ByProvider: 'byProvider',
    BetCorrection: 'betCorrection',
    Single: 'single',
    Loss: 'loss',
    Inactivity: 'inactivity',
    InactiveAccounts: 'inactiveAccounts',
    SingleBet: 'singleBet',
    Cashier: 'cashier',
    SpinsReport: 'spinsReport',
    SpinsDetailsReport: 'spinsDetailsReport',
    Received: 'received',
    SportStatusSettings: 'sportStatusSettings',
    Alanbase: 'alanbase',
    TransferInactivePlayersMoney: 'transferInactivePlayersMoney',
    ReleaseNotes: 'releaseNotes',
    HasTransaction: 'hasTransaction',
    Regulatory: 'regulatory',
    BetSelfProtection: 'betSelfProtection',
    ExchangeItems: 'exchangeItems',
    TopPlayersGgr: 'topPlayersGgr',
    TopPlayers: 'topPlayers',
    Seen: 'seen',
    Latest: 'latest',
    GameTypes: 'gameTypes',
    DeepLinkPromotions: 'deepLinkPromotions',
    Prize: 'prize',
    PrizeContents: 'prizeContents',
    ConnectedPrizes: 'connectedPrizes',
    PaymentLimits: 'paymentLimits',
    DeviceSession: 'deviceSession',
    ApplicationSessions: 'applicationSessions',
    WebSessions: 'webSessions',
    Exchanges: 'exchanges',
    Regions: 'regions',
    MetaMap: 'metaMap',
    ThunderBite: 'thunderBite',
    Usages: 'usages',
    GeneralWidgets: 'generalWidgets',
    JackpotWidgets: 'jackpotWidgets',
    ActiveSessions: 'activeSessions',
    End: 'end',
    Robots: 'robots',
    UnVerification: 'unVerification',
    MixedTransaction: 'mixTransaction',
    CasinoWalletConfiguration: 'casinoWalletConfiguration',
    JackpotFeeds: 'jackpotFeeds',
    V2: 'v2',
    PlayerComplaints: 'playerComplaints',
    ParticipationConditions: 'participationConditions',
    FreeBets: 'freeBets',
  },
  notFoundStatusCode: 404,
  redirectStatusCodes: [404, 403],
  pageSize: 50,
  pageSizeOptions: [10, 20, 50, 100, 200],
  maxFileSize: 10,
  maxFilesSize: 200,
  defaultConversionCurrency: 'EUR',
  actionsWidth: {
    defaultIcon: 44,
    smallIcon: 37,
    minWidth: 90,
  },
  maxResizePageSize: 100,
  regex: {
    domain: '^[a-zA-Z0-9][a-zA-Z0-9-]{1,61}.([a-zA-Z0-9]+([-][a-zA-Z0-9]+)?)+?(.[a-zA-Z]{2,})?$',
    mobile: '^(\\+{0,1})[\\d]*$',
    emailOrMobile: '((^[^\\s@]+@[^\\s@]+\\.[^\\s@]+$)|(^(\\+{0,1})[\\d]*$))',
    digit: '^[\\d]*$',
    password: '^(?=.*\\d)(?=.*[A-Z]).{8,40}$',
    u1to100: '^(?=\\S)(?=.*\\p{L})(.){1,100}$',
    uto500: '^(?=[\\s\\S]*\\p{L})[\\s\\S]{0,500}$',
    uto3000: '^(?=[\\s\\S]*\\p{L})[\\s\\S]{0,3000}$',
    u1to50: '^(?=[\\p{L}\\p{N}])[\\p{L}\\p{N}_\\s]{1,50}$',
    uFL1to50: '^(?=[\\p{Lu}])[\\p{L}]{1,50}$',
    lN3to50: '^(?=.*?[a-zA-Z])[a-zA-Z0-9_]{3,50}$',
    uL1to50: '^(?=.*[\\p{L}])[\\p{L}\\p{N}_\\s]{1,50}$',
    uLS1to50: '^(?=.*[\\p{L}])[\\p{L}\\s]{1,50}$',
    letters: '^([a-zA-Z])*$',
    lettersFirstUppercase: '^[A-Z]([a-z])*$',
    notOnlySpaces: '.*[\\S].*',
    limited1to50FirstLetter: '^([A-Za-z])[0-9a-zA-Z_ ]{0,49}$',
    limited3to50FirstLetter: '^([A-Za-z])[0-9a-zA-Z_ ]{2,49}$',
    limited2to50: '^(?=.*?[a-zA-Z])[a-zA-Z0-9_]{2,50}$',
    limited5to20: '^[A-Za-z0-9]{5,20}$',
    limited6to16: '^[A-Za-z0-9]{6,16}$',
    fileName: '^[^"<>?|:*\\\\\\/]{0,200}$',
    host: '^(?:(?:(?:[a-zA-z-]+)\\:\\/{1,3})?(?:[a-zA-Z0-9])(?:[a-zA-Z0-9-\\.]){1,61}[a-zA-Z0-9](?:\\.[a-zA-Z]{2,})+|\\[(?:(?:(?:[a-fA-F0-9]){1,4})(?::(?:[a-fA-F0-9]){1,4}){7}|::1|::)\\]|(?:(?:[0-9]{1,3})(?:.[0-9]{1,3}){3}))(?::[0-9]{1,5})?$',
    rate: '^(?:\\d{1,50}|(?=.{1,50}$)\\d+\\.\\d{1,8})$',
    email: '^([a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,6})?$',
    tag: '^(?=[\\p{L}\\p{N}])[\\p{L}\\p{N}_-\\s]{1,50}$',
    address: '^[\\p{L}\\p{N} \\- \\,\\.\\|\\/]{2,100}$',
    limited1to50: '^.{1,50}$',
    decimalNumber1to6: '^(?:\\d{0,6}\\.\\d{1,2})$|^\\d{0,6}$',
    imageUrl1: '^(http(s?):)([/|.|\\w|\\s(|)-])*\\.(?:jpg|gif|png|svg)$',
    imageUrl2: '^(http(s?):)([\\/|.|\\w|\\s(|)\\-\\%20])*\\.(?:jpg|jpeg|gif|png|svg|webp)$',
    onlyNumbersAndRange: '^(([1-9]|[1-8][0-9]|9[0-9]|100)(-([1-9]|[1-9][0-9]|100))?($|,|, |-))+$',
    doubleNumber: '^-?(?:0|[1-9]\\d*)(\\.\\d+)?$',
    u1to50FNotS: '^(?=[\\p{L}\\p{N}_])[\\p{L}\\p{N}_\\s]{1,50}$',
    notOnlySpaces1To100: '^(?=[\\s\\S]*[\\S])[\\s\\S]{1,100}$',
    notOnlySpaces1To200: '^(?=[\\s\\S]*[\\S])[\\s\\S]{1,200}$',
    notOnlySpaces1To500: '^(?=[\\s\\S]*[\\S])[\\s\\S]{1,500}$',
    notOnlySpaces1To4000: '^(?=[\\s\\S]*[\\S])[\\s\\S]{1,4000}$',
    maxLimit: '^[\\s\\S]{1,126}$',
    uL3to100Space: '^(?=[\\p{L}])[\\p{L} ]{3,100}$',
    uL2to100Space: '^(?=[\\p{L}])[\\p{L} ]{2,100}$',
    lNLimited2to100: '^[A-Za-z0-9 _\\/]{2,100}$',
    scriptName: '^[A-Za-z0-9 )(_-]{2,55}$',
    emptyHtml: /(?:&nbsp;)|(<\/?(?:img|hr|i)[^>]*>)|<[^>]+>/gi,
    htmlTag: /<[^>]*>/g,
    notNDotUnderscore: /[^\d.-]/g,
    negativeNumber: /^-?[1-9]\d*\.?\d*|^-?(0(\.\d*)?)?/g,
    doublePositiveNumber: /^0(\.\d*)?|[1-9]\d*\.?\d*/g,
    intNumber: /^(0|[1-9]\d*)/g,
    negIntNumber: /^-?(0|[1-9]\d*)?/g,
    splitNumber: /(\d)(?=(\d{3})+$)/g,
    onlyEnter: '^[\\r\\n|\\r|\\n]*$',
    u3to100FNotS: '^(?=[\\p{L}\\p{N}_])[\\p{L}\\p{N}_\\s]{3,100}$',
    generalMobileNumber: '^(?:00|\\+)[0-9]{7,16}$',
    digit1To10DecimalTo2: /^[\d+]{1,10}(?:\.\d{1,2})?$/g,
    uLS3To50FNotS: '^(?=.*[\\p{L}])(?=[\\p{L}\\p{N}_])[\\p{L}\\p{N}_\\s]{3,50}$',
    uLS1to50FNotS: '^(?=.*[\\p{L}])(?=[\\p{L}\\p{N}_])[\\p{L}\\p{N}_\\s]{1,50}$',
    uLS3To300FNotS: '^(?=.*[\\p{L}])(?=[\\p{L}\\p{N}_])[\\p{L}\\p{N}_\\s]{3,300}$',
    maxLimit1To50: '^[\\s\\S]{1,50}$',
    maxLimit1To500: '^[\\s\\S]{1,500}$',
    brandName: '^(?!.*\\s\\s)[A-Za-z0-9][A-Za-z0-9\\s]{1,48}[A-Za-z0-9]$',
    phoneNumber: '^\\+?[1-9][0-9]{7,14}$',
    lLNLimit1To50: '^[a-z0-9_-]{1,50}$',
    decimalNumber1to13: '^(?:\\d{0,13}\\.\\d{1,2})$|^\\d{0,13}$',
    notOnlySpaces1To50: '^(?=[\\s\\S]*[\\S])[\\s\\S]{1,50}$',
    notSpaces32: '^[\\S]{32}$',
    u1to32FNotS: '^(?=[\\p{L}\\p{N}_])[\\p{L}\\p{N}_\\s]{1,32}$',
    u1to200FNotS: '^(?=[\\p{L}\\p{N}_])[\\p{L}\\p{N}_\\s]{1,200}$',
    maxLimit1To9: '^[\\s\\S]{1,9}$',
    onlyNumbersAndRangeMax31: '^(([1-9]|[1,2][0-9]|3[0-1])(-([1-9]|[1,2][0-9]|3[0-1]))?($|,))+$',
    sameNumberInRangeMax31: '(\\d+,)?([1-9]|[1,2][0-9]|3[0-1])-\\2($|,)',
    uLS3To50FNotSMin3L: '^(?=(.*\\p{L}){3,})(?=[\\p{L}\\p{N}_])[\\p{L}\\p{N}_\\s]{3,50}$',
  },
  format: {
    time: 'HH:mm',
    timeSeconds: 'HH:mm:ss',
    date: 'DD.MM.YYYY',
    dateConvert: 'MM/DD/YYYY',
    dateTime: 'DD.MM.YYYY HH:mm',
    dateTimeStartOf: 'DD.MM.YYYY HH:mm:00',
    dateTimeHour: 'DD.MM.YYYY HH',
    dateTimeTimezone: 'DD.MM.YYYY HH:mm Z',
    timeHour: 'HH',
    fullDateTime: 'YYYY-MM-DDTHH:mm:ss.SSS',
    dateMonth: 'YYYY-MM',
  },
  languages: {
    en: 'English',
    ru: 'Russian',
    // tr: 'Turkish',
    es: 'Spanish',
    pt: 'Portuguese',
    zh: 'Chinese',
    ro: 'Romanian',
    // bg: 'Bulgarian',
    el: 'Greek',
  },
  defaultLanguage: 'en',
  defaultEmoji: '\u{1f600}',
};
