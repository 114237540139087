const UserActionType = {
  GET_USERS: 'GET_USERS',
  GET_USERS_SUCCESS: 'GET_USERS_SUCCESS',
  RESET_USERS: 'RESET_USERS',

  GET_USER: 'GET_USER',
  SAVE_USER: 'SAVE_USER',
  RESET_USER: 'RESET_USER',

  ADD_USER: 'ADD_USER',

  EDIT_USER: 'EDIT_USER',

  DELETE_USER: 'DELETE_ADMIN_USER',

  CHANGE_USER_LOADING_STATE: 'CHANGE_USER_LOADING_STATE',

  USER_FAILURE: 'USER_FAILURE',

  GET_USER_PARTNER_ACCESSES: 'GET_USER_PARTNER_ACCESSES',
  GET_USER_PARTNER_ACCESSES_REQUEST: 'GET_USER_PARTNER_ACCESSES_REQUEST',
  GET_USER_PARTNER_ACCESSES_SUCCESS: 'GET_USER_PARTNER_ACCESSES_SUCCESS',
  GET_USER_PARTNER_ACCESSES_FAILURE: 'GET_USER_PARTNER_ACCESSES_FAILURE',
  RESET_USER_PARTNER_ACCESSES: 'RESET_USER_PARTNER_ACCESSES',

  GET_USER_BRAND_ACCESSES: 'GET_USER_BRAND_ACCESSES',
  GET_USER_BRAND_ACCESSES_REQUEST: 'GET_USER_BRAND_ACCESSES_REQUEST',
  GET_USER_BRAND_ACCESSES_SUCCESS: 'GET_USER_BRAND_ACCESSES_SUCCESS',
  GET_USER_BRAND_ACCESSES_FAILURE: 'GET_USER_BRAND_ACCESSES_FAILURE',
  RESET_USER_BRAND_ACCESSES: 'RESET_USER_BRAND_ACCESSES',

  GET_USER_PERMISSIONS: 'GET_USER_PERMISSIONS',
  GET_USER_PERMISSIONS_SUCCESS: 'GET_USER_PERMISSIONS_SUCCESS',
  RESET_USER_PERMISSIONS: 'RESET_USER_PERMISSIONS',

  CHANGE_ACTIVITY_STATUS: 'CHANGE_ACTIVITY_STATUS',

  GET_USER_PERMISSION_GROUP: 'GET_USER_PERMISSION_GROUP',
  GET_USER_PERMISSION_GROUP_SUCCESS: 'GET_USER_PERMISSION_GROUP_SUCCESS',
  RESET_USER_PERMISSION_GROUP: 'RESET_USER_PERMISSION_GROUP',

  USER_ASSIGN_PERMISSIONS: 'USER_ASSIGN_PERMISSIONS',

  GET_USER_BRANDS_BY_PARTNER: 'GET_USER_BRANDS_BY_PARTNER',
  GET_USER_BRANDS_BY_PARTNER_SUCCESS: 'GET_USER_BRANDS_BY_PARTNER_SUCCESS',
  RESET_USER_BRANDS_BY_PARTNER: 'RESET_USER_BRANDS_BY_PARTNER',

  GET_USER_POSITIONS: 'GET_USER_POSITIONS',
  GET_USER_POSITIONS_SUCCESS: 'GET_USER_POSITIONS_SUCCESS',
  RESET_USER_POSITIONS: 'RESET_USER_POSITIONS',

  GET_SUPERVISOR_USERS: 'GET_SUPERVISOR_USERS',
  GET_SUPERVISOR_USERS_SUCCESS: 'GET_SUPERVISOR_USERS_SUCCESS',
  RESET_SUPERVISOR_USERS: 'RESET_SUPERVISOR_USERS',

  GET_USER_CONFIGS: 'GET_USER_CONFIGS',
  CREATE_OR_EDIT_USER_CONFIG: 'CREATE_OR_EDIT_USER_CONFIG',
  SAVE_USER_CONFIGS: 'SAVE_USER_CONFIGS',
  DELETE_USER_CONFIG: 'DELETE_USER_CONFIG',

  EDIT_USERS_ACTIVITY_STATUS: 'EDIT_USERS_ACTIVITY_STATUS',
  DELETE_USERS: 'DELETE_USERS',
  EDIT_USERS: 'EDIT_USERS',
  BULK_ASSIGN_PERMISSIONS: 'BULK_ASSIGN_PERMISSIONS',

  RESEND_QR_CODES_BY_BULK: 'RESEND_QR_CODES_BY_BULK',

  UNLOCK_USER: 'UNLOCK_USER',

  EXPORT_USERS: 'EXPORT_USERS',
};

export default UserActionType;
