import ReleaseNoteActionType from 'actions/releaseNote/releaseNoteActionType';

const initialState = {
  releaseNotes: {
    data: [],
    total: 0,
  },
  releaseNote: {},
  latestReleaseNote: {},
};

const releaseNoteReducer = (state = initialState, { type, payload }) => {
  switch (type) {
  case ReleaseNoteActionType.SAVE_RELEASE_NOTES:
    return {
      ...state,
      releaseNotes: payload.data,
    };
  case ReleaseNoteActionType.RESET_RELEASE_NOTES:
    return {
      ...state,
      releaseNotes: {
        data: [],
        total: 0,
      },
    };
  case ReleaseNoteActionType.SAVE_RELEASE_NOTE:
    return {
      ...state,
      releaseNote: payload.data,
    };
  case ReleaseNoteActionType.RESET_RELEASE_NOTE:
    return {
      ...state,
      releaseNote: {},
    };
  case ReleaseNoteActionType.SAVE_LATEST_RELEASE_NOTE:
    return {
      ...state,
      latestReleaseNote: payload.data,
    };
  case ReleaseNoteActionType.RESET_LATEST_RELEASE_NOTE:
    return {
      ...state,
      latestReleaseNote: {},
    };
  default:
    return state;
  }
};

export default releaseNoteReducer;
