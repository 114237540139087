import BrandPaymentActionType from './brandPaymentActionType';

export const getBrandFailedPaymentsSettings = (brandId) => ({
  type: BrandPaymentActionType.GET_BRAND_FAILED_PAYMENTS_SETTINGS,
  payload: { brandId },
});

export const saveBrandFailedPaymentsSettings = (data) => ({
  type: BrandPaymentActionType.SAVE_BRAND_FAILED_PAYMENTS_SETTINGS,
  payload: { data },
});

export const resetBrandFailedPaymentsSettings = (brandId) => ({
  type: BrandPaymentActionType.RESET_BRAND_FAILED_PAYMENTS_SETTINGS,
  payload: { brandId },
});

export const editBrandFailedPaymentsSettings = (brandId, data) => ({
  type: BrandPaymentActionType.EDIT_BRAND_FAILED_PAYMENTS_SETTINGS,
  payload: { brandId, data },
});

export const getBrandPaymentMethods = (params, id) => ({
  type: BrandPaymentActionType.GET_BRAND_PAYMENT_METHODS,
  payload: { params, id },
});

export const saveBrandPaymentMethods = (data) => ({
  type: BrandPaymentActionType.SAVE_BRAND_PAYMENT_METHODS,
  payload: { data },
});

export const resetBrandPaymentMethods = () => ({
  type: BrandPaymentActionType.RESET_BRAND_PAYMENT_METHODS,
});

export const getBrandPaymentSettings = (params) => ({
  type: BrandPaymentActionType.GET_BRAND_PAYMENT_SETTINGS,
  payload: { params },
});

export const saveBrandPaymentSettings = (data) => ({
  type: BrandPaymentActionType.SAVE_BRAND_PAYMENT_SETTINGS,
  payload: { data },
});

export const resetBrandPaymentSettings = () => ({
  type: BrandPaymentActionType.RESET_BRAND_PAYMENT_SETTINGS,
});

export const editBrandPaymentSettings = (brandId, data) => ({
  type: BrandPaymentActionType.EDIT_BRAND_PAYMENT_SETTINGS,
  payload: { brandId, data },
});

export const getBrandPaymentMethodsFees = (params, id) => ({
  type: BrandPaymentActionType.GET_BRAND_PAYMENT_METHODS_FEES,
  payload: { params, id },
});

export const saveBrandPaymentMethodsFees = (data) => ({
  type: BrandPaymentActionType.SAVE_BRAND_PAYMENT_METHODS_FEES,
  payload: { data },
});

export const resetBrandPaymentMethodsFees = () => ({
  type: BrandPaymentActionType.RESET_BRAND_PAYMENT_METHODS_FEES,
});

export const getBrandPaymentMethodsLimits = (params, id) => ({
  type: BrandPaymentActionType.GET_BRAND_PAYMENT_METHODS_LIMITS,
  payload: { params, id },
});

export const saveBrandPaymentMethodsLimits = (data) => ({
  type: BrandPaymentActionType.SAVE_BRAND_PAYMENT_METHODS_LIMITS,
  payload: { data },
});

export const editBrandPaymentMethodSettings = (data, name) => ({
  type: BrandPaymentActionType.EDIT_BRAND_PAYMENT_METHOD_SETTINGS,
  payload: { data, name },
});

export const resetBrandPaymentMethodsLimits = () => ({
  type: BrandPaymentActionType.RESET_BRAND_PAYMENT_METHODS_LIMITS,
});

export const getBrandPaymentMethodSettings = (id, brandId) => ({
  type: BrandPaymentActionType.GET_BRAND_PAYMENT_METHOD_SETTINGS,
  payload: { id, brandId },
});

export const saveBrandPaymentMethodSettings = (data) => ({
  type: BrandPaymentActionType.SAVE_BRAND_PAYMENT_METHOD_SETTINGS,
  payload: { data },
});

export const resetBrandPaymentMethodSettings = () => ({
  type: BrandPaymentActionType.RESET_BRAND_PAYMENT_METHOD_SETTINGS,
});

export const getBrandPaymentAllowedCurrencies = (id, params) => ({
  type: BrandPaymentActionType.GET_BRAND_PAYMENT_ALLOWED_CURRENCIES,
  payload: {
    id,
    params,
  },
});

export const saveBrandPaymentAllowedCurrencies = (data) => ({
  type: BrandPaymentActionType.SAVE_BRAND_PAYMENT_ALLOWED_CURRENCIES,
  payload: { data },
});

export const resetBrandPaymentAllowedCurrencies = () => ({
  type: BrandPaymentActionType.RESET_BRAND_PAYMENT_ALLOWED_CURRENCIES,
});

export const getPaymentCurrencies = (id) => ({
  type: BrandPaymentActionType.GET_PAYMENT_CURRENCIES,
  payload: { id },
});

export const savePaymentCurrencies = (data) => ({
  type: BrandPaymentActionType.SAVE_PAYMENT_CURRENCIES,
  payload: { data },
});

export const resetPaymentCurrencies = () => ({
  type: BrandPaymentActionType.RESET_PAYMENT_CURRENCIES,
});

export const getBrandPaymentAllowedCountries = (params, isLoading) => ({
  type: BrandPaymentActionType.GET_BRAND_PAYMENT_ALLOWED_COUNTRIES,
  payload: { params, isLoading },
});

export const saveBrandPaymentAllowedCountries = (data) => ({
  type: BrandPaymentActionType.SAVE_BRAND_PAYMENT_ALLOWED_COUNTRIES,
  payload: { data },
});

export const resetBrandPaymentAllowedCountries = () => ({
  type: BrandPaymentActionType.RESET_BRAND_PAYMENT_ALLOWED_COUNTRIES,
});

export const getBrandPaymentsActiveCurrencies = (brandId, paymentId) => ({
  type: BrandPaymentActionType.GET_BRAND_PAYMENTS_ACTIVE_CURRENCIES,
  payload: { brandId, paymentId },
});

export const saveBrandPaymentsActiveCurrencies = (data) => ({
  type: BrandPaymentActionType.SAVE_BRAND_PAYMENTS_ACTIVE_CURRENCIES,
  payload: { data },
});

export const resetBrandPaymentsActiveCurrencies = () => ({
  type: BrandPaymentActionType.RESET_BRAND_PAYMENTS_ACTIVE_CURRENCIES,
});

export const getBrandPaymentMethodTags = (params) => ({
  type: BrandPaymentActionType.GET_BRAND_PAYMENT_METHOD_TAGS,
  payload: { params },
});

export const saveBrandPaymentMethodTags = (data) => ({
  type: BrandPaymentActionType.SAVE_BRAND_PAYMENT_METHOD_TAGS,
  payload: { data },
});

export const resetBrandPaymentMethodTags = () => ({
  type: BrandPaymentActionType.RESET_BRAND_PAYMENT_METHOD_TAGS,
});

export const editBrandPaymentMethodTags = (data, params, name) => ({
  type: BrandPaymentActionType.EDIT_BRAND_PAYMENT_METHOD_TAGS,
  payload: { data, params, name },
});
