import { LocalStorageService } from 'services';
import SettingsActionType from 'actions/setting/settingActionType';

const initialState = {
  isNightMode: LocalStorageService.get('isNightMode') || false,
  pageLoading: false,
  notificationMessage: '',
  successMessage: '',
  errorMessage: { status: null, errors: [] },
  breadcrumbParams: {},
  activeMenu: 0,
  endedStatus: false,
  generateReportEndedStatus: false,
};

const settingReducer = (state = initialState, { type, payload }) => {
  switch (type) {
  case SettingsActionType.CHANGE_MODE:
    return {
      ...state,
      isNightMode: payload,
    };
  case SettingsActionType.CHANGE_LOADING_STATE:
    return {
      ...state,
      pageLoading: payload,
    };
  case SettingsActionType.SET_SUCCESS_MESSAGE:
    return {
      ...state,
      successMessage: payload.message,
    };
  case SettingsActionType.SET_ERROR_MESSAGE:
    return {
      ...state,
      errorMessage: payload.message,
    };
  case SettingsActionType.RESET_MESSAGE:
    return {
      ...state,
      successMessage: '',
      errorMessage: { status: null, errors: [] },
    };
  case SettingsActionType.SET_BREADCRUMB_PARAMS:
    return {
      ...state,
      breadcrumbParams: {
        ...state.breadcrumbParams,
        [payload.key]: payload.value,
      },
    };
  case SettingsActionType.RESET_BREADCRUMB_PARAMS:
    return {
      ...state,
      breadcrumbParams: {},
    };
  case SettingsActionType.CHANGE_ENDED_STATUS:
    return {
      ...state,
      endedStatus: payload.status,
    };
  case SettingsActionType.CHANGE_GENERATE_REPORT_ENDED_STATUS:
    return {
      ...state,
      generateReportEndedStatus: payload.status,
    };
  case SettingsActionType.SET_ACTIVE_MENU:
    return {
      ...state,
      activeMenu: payload.data,
    };
  default:
    return state;
  }
};

export default settingReducer;
