import ThirdPartyServicesActionType from './thirdPartyServicesActionType';

export const getCustomScripts = (params) => ({
  type: ThirdPartyServicesActionType.GET_CUSTOM_SCRIPTS,
  payload: { params },
});

export const saveCustomScripts = (data) => ({
  type: ThirdPartyServicesActionType.SAVE_CUSTOM_SCRIPTS,
  payload: { data },
});

export const resetCustomScripts = () => ({
  type: ThirdPartyServicesActionType.RESET_CUSTOM_SCRIPTS,
});

export const addCustomScript = (data) => ({
  type: ThirdPartyServicesActionType.ADD_CUSTOM_SCRIPT,
  payload: { data },
});

export const editCustomScript = (id, data) => ({
  type: ThirdPartyServicesActionType.EDIT_CUSTOM_SCRIPT,
  payload: { id, data },
});

export const deleteCustomScript = (data) => ({
  type: ThirdPartyServicesActionType.DELETE_CUSTOM_SCRIPT,
  payload: { data },
});

export const changeCustomScriptStatus = (data) => ({
  type: ThirdPartyServicesActionType.CHANGE_CUSTOM_SCRIPT_STATUS,
  payload: { data },
});

export const getBrandAffiliateServices = (params) => ({
  type: ThirdPartyServicesActionType.GET_BRAND_AFFILIATE_SERVICES,
  payload: { params },
});

export const saveBrandAffiliateServices = (data) => ({
  type: ThirdPartyServicesActionType.SAVE_BRAND_AFFILIATE_SERVICES,
  payload: { data },
});

export const resetBrandAffiliateServices = () => ({
  type: ThirdPartyServicesActionType.RESET_BRAND_AFFILIATE_SERVICES,
});

export const addBrandEveryMatrixAffiliateService = (brandId, data) => ({
  type: ThirdPartyServicesActionType.ADD_BRAND_EVERY_MATRIX_AFFILIATE_SERVICE,
  payload: { brandId, data },
});

export const deleteBrandEveryMatrixAffiliateService = (brandId, data) => ({
  type: ThirdPartyServicesActionType.DELETE_BRAND_EVERY_MATRIX_AFFILIATE_SERVICE,
  payload: { brandId, data },
});

export const addBrandSmarticoAffiliateService = (brandId, data) => ({
  type: ThirdPartyServicesActionType.ADD_BRAND_SMARTICO_AFFILIATE_SERVICE,
  payload: { brandId, data },
});

export const deleteBrandSmarticoAffiliateService = (brandId, data) => ({
  type: ThirdPartyServicesActionType.DELETE_BRAND_SMARTICO_AFFILIATE_SERVICE,
  payload: { brandId, data },
});

export const addBrandMyAffiliatesService = (brandId, data) => ({
  type: ThirdPartyServicesActionType.ADD_BRAND_MY_AFFILIATES_AFFILIATE_SERVICE,
  payload: { brandId, data },
});

export const deleteBrandMyAffiliatesService = (brandId, data) => ({
  type: ThirdPartyServicesActionType.DELETE_BRAND_MY_AFFILIATES_AFFILIATE_SERVICE,
  payload: { brandId, data },
});

export const getBrandAnalyticServices = (params) => ({
  type: ThirdPartyServicesActionType.GET_BRAND_ANALYTIC_SERVICES,
  payload: { params },
});

export const saveBrandAnalyticServices = (data) => ({
  type: ThirdPartyServicesActionType.SAVE_BRAND_ANALYTIC_SERVICES,
  payload: { data },
});

export const resetBrandAnalyticServices = () => ({
  type: ThirdPartyServicesActionType.RESET_BRAND_ANALYTIC_SERVICES,
});

export const addBrandHotJarService = (data, name) => ({
  type: ThirdPartyServicesActionType.ADD_BRAND_HOTJAR_SERVICE,
  payload: { data, name },
});

export const getBrandHotJarService = (params) => ({
  type: ThirdPartyServicesActionType.GET_BRAND_HOTJAR_SERVICE,
  payload: { params },
});

export const editBrandHotJarService = (data, name) => ({
  type: ThirdPartyServicesActionType.EDIT_BRAND_HOTJAR_SERVICE,
  payload: { data, name },
});

export const deleteBrandHotJarService = (brandId, params) => ({
  type: ThirdPartyServicesActionType.DELETE_BRAND_HOTJAR_SERVICE,
  payload: { params, brandId },
});
export const changeBrandHotJarServiceStatus = (brandId, data) => ({
  type: ThirdPartyServicesActionType.CHANGE_BRAND_HOTJAR_SERVICE_STATUS,
  payload: { data, brandId },
});

export const addBrandGoogleAnalyticsService = (data, name) => ({
  type: ThirdPartyServicesActionType.ADD_BRAND_GOOGLE_ANALYTICS_SERVICE,
  payload: { data, name },
});

export const getBrandGoogleAnalyticsService = (params) => ({
  type: ThirdPartyServicesActionType.GET_BRAND_GOOGLE_ANALYTICS_SERVICE,
  payload: { params },
});

export const editBrandGoogleAnalyticsService = (data, name) => ({
  type: ThirdPartyServicesActionType.EDIT_BRAND_GOOGLE_ANALYTICS_SERVICE,
  payload: { data, name },
});

export const deleteBrandGoogleAnalyticsService = (brandId, params) => ({
  type: ThirdPartyServicesActionType.DELETE_BRAND_GOOGLE_ANALYTICS_SERVICE,
  payload: { params, brandId },
});

export const changeBrandGoogleAnalyticsServiceStatus = (brandId, data) => ({
  type: ThirdPartyServicesActionType.CHANGE_BRAND_GOOGLE_ANALYTICS_SERVICE_STATUS,
  payload: { brandId, data },
});

export const addBrandGoogleSearchConsoleService = (data, name) => ({
  type: ThirdPartyServicesActionType.ADD_BRAND_GOOGLE_SEARCH_CONSOLE_SERVICE,
  payload: { data, name },
});

export const getBrandGoogleSearchConsoleService = (params) => ({
  type: ThirdPartyServicesActionType.GET_BRAND_GOOGLE_SEARCH_CONSOLE_SERVICE,
  payload: { params },
});

export const editBrandGoogleSearchConsoleService = (data, name) => ({
  type: ThirdPartyServicesActionType.EDIT_BRAND_GOOGLE_SEARCH_CONSOLE_SERVICE,
  payload: { data, name },
});

export const deleteBrandGoogleSearchConsoleService = (brandId, params) => ({
  type: ThirdPartyServicesActionType.DELETE_BRAND_GOOGLE_SEARCH_CONSOLE_SERVICE,
  payload: { brandId, params },
});

export const changeBrandGoogleSearchConsoleServiceStatus = (brandId, data) => ({
  type: ThirdPartyServicesActionType.CHANGE_BRAND_GOOGLE_SEARCH_CONSOLE_SERVICE_STATUS,
  payload: { brandId, data },
});

export const saveBrandAnalyticService = (data) => ({
  type: ThirdPartyServicesActionType.SAVE_BRAND_ANALYTIC_SERVICE,
  payload: { data },
});

export const resetBrandAnalyticService = () => ({
  type: ThirdPartyServicesActionType.RESET_BRAND_ANALYTIC_SERVICE,
});

export const getBrandLiveChatSolutionServices = (params) => ({
  type: ThirdPartyServicesActionType.GET_BRAND_LIVE_CHAT_SOLUTION_SERVICES,
  payload: { params },
});

export const saveBrandLiveChatSolutionServices = (data) => ({
  type: ThirdPartyServicesActionType.SAVE_BRAND_LIVE_CHAT_SOLUTION_SERVICES,
  payload: { data },
});

export const resetBrandLiveChatSolutionServices = () => ({
  type: ThirdPartyServicesActionType.RESET_BRAND_LIVE_CHAT_SOLUTION_SERVICES,
});

export const addBrandLiveChatService = (data, name) => ({
  type: ThirdPartyServicesActionType.ADD_BRAND_LIVE_CHAT_SERVICE,
  payload: { data, name },
});

export const getBrandLiveChatService = (params) => ({
  type: ThirdPartyServicesActionType.GET_BRAND_LIVE_CHAT_SERVICE,
  payload: { params },
});

export const editBrandLiveChatService = (data, name) => ({
  type: ThirdPartyServicesActionType.EDIT_BRAND_LIVE_CHAT_SERVICE,
  payload: { data, name },
});

export const deleteBrandLiveChatService = (brandId, params) => ({
  type: ThirdPartyServicesActionType.DELETE_BRAND_LIVE_CHAT_SERVICE,
  payload: { params, brandId },
});
export const changeBrandLiveChatServiceStatus = (brandId, data) => ({
  type: ThirdPartyServicesActionType.CHANGE_BRAND_LIVE_CHAT_SERVICE_STATUS,
  payload: { data, brandId },
});

export const addBrandLiveAgentService = (data, name) => ({
  type: ThirdPartyServicesActionType.ADD_BRAND_LIVE_AGENT_SERVICE,
  payload: { data, name },
});

export const getBrandLiveAgentService = (params) => ({
  type: ThirdPartyServicesActionType.GET_BRAND_LIVE_AGENT_SERVICE,
  payload: { params },
});

export const editBrandLiveAgentService = (data, name) => ({
  type: ThirdPartyServicesActionType.EDIT_BRAND_LIVE_AGENT_SERVICE,
  payload: { data, name },
});

export const deleteBrandLiveAgentService = (brandId, params) => ({
  type: ThirdPartyServicesActionType.DELETE_BRAND_LIVE_AGENT_SERVICE,
  payload: { params, brandId },
});
export const changeBrandLiveAgentServiceStatus = (brandId, data) => ({
  type: ThirdPartyServicesActionType.CHANGE_BRAND_LIVE_AGENT_SERVICE_STATUS,
  payload: { data, brandId },
});

export const addBrandZendeskService = (data, name) => ({
  type: ThirdPartyServicesActionType.ADD_BRAND_ZENDESK_SERVICE,
  payload: { data, name },
});

export const getBrandZendeskService = (params) => ({
  type: ThirdPartyServicesActionType.GET_BRAND_ZENDESK_SERVICE,
  payload: { params },
});

export const editBrandZendeskService = (data, name) => ({
  type: ThirdPartyServicesActionType.EDIT_BRAND_ZENDESK_SERVICE,
  payload: { data, name },
});

export const deleteBrandZendeskService = (brandId, params) => ({
  type: ThirdPartyServicesActionType.DELETE_BRAND_ZENDESK_SERVICE,
  payload: { params, brandId },
});
export const changeBrandZendeskServiceStatus = (brandId, data) => ({
  type: ThirdPartyServicesActionType.CHANGE_BRAND_ZENDESK_SERVICE_STATUS,
  payload: { data, brandId },
});

export const addBrandIntercomService = (data, name) => ({
  type: ThirdPartyServicesActionType.ADD_BRAND_INTERCOM_SERVICE,
  payload: { data, name },
});

export const getBrandIntercomService = (params) => ({
  type: ThirdPartyServicesActionType.GET_BRAND_INTERCOM_SERVICE,
  payload: { params },
});

export const editBrandIntercomService = (data, name) => ({
  type: ThirdPartyServicesActionType.EDIT_BRAND_INTERCOM_SERVICE,
  payload: { data, name },
});

export const deleteBrandIntercomService = (brandId, params) => ({
  type: ThirdPartyServicesActionType.DELETE_BRAND_INTERCOM_SERVICE,
  payload: { params, brandId },
});
export const changeBrandIntercomServiceStatus = (brandId, data) => ({
  type: ThirdPartyServicesActionType.CHANGE_BRAND_INTERCOM_SERVICE_STATUS,
  payload: { data, brandId },
});

export const saveBrandLiveChatSolutionService = (data) => ({
  type: ThirdPartyServicesActionType.SAVE_BRAND_LIVE_CHAT_SOLUTION_SERVICE,
  payload: { data },
});

export const resetBrandLiveChatSolutionService = () => ({
  type: ThirdPartyServicesActionType.RESET_BRAND_LIVE_CHAT_SOLUTION_SERVICE,
});

export const getBrandAgSolutionServices = (params) => ({
  type: ThirdPartyServicesActionType.GET_BRAND_AG_SOLUTION_SERVICES,
  payload: { params },
});

export const saveBrandAgSolutionServices = (data) => ({
  type: ThirdPartyServicesActionType.SAVE_BRAND_AG_SOLUTION_SERVICES,
  payload: { data },
});

export const resetBrandAgSolutionServices = () => ({
  type: ThirdPartyServicesActionType.RESET_BRAND_AG_SOLUTION_SERVICES,
});
export const addBrandEveryMatrixAgService = (brandId, data) => ({
  type: ThirdPartyServicesActionType.ADD_BRAND_EVERY_MATRIX_AG_SERVICE,
  payload: { brandId, data },
});

export const deleteBrandEveryMatrixAgService = (brandId, data) => ({
  type: ThirdPartyServicesActionType.DELETE_BRAND_EVERY_MATRIX_AG_SERVICE,
  payload: { brandId, data },
});

export const saveBrandAgSolutionService = (data) => ({
  type: ThirdPartyServicesActionType.SAVE_BRAND_AG_SOLUTION_SERVICE,
  payload: { data },
});

export const resetBrandAgSolutionService = () => ({
  type: ThirdPartyServicesActionType.RESET_BRAND_AG_SOLUTION_SERVICE,
});

export const addBrandDigitainAgService = (data, name) => ({
  type: ThirdPartyServicesActionType.ADD_BRAND_DIGITAIN_AG_SERVICE,
  payload: { data, name },
});

export const getBrandDigitainAgService = (params) => ({
  type: ThirdPartyServicesActionType.GET_BRAND_DIGITAIN_AG_SERVICE,
  payload: { params },
});

export const editBrandDigitainAgService = (data, name) => ({
  type: ThirdPartyServicesActionType.EDIT_BRAND_DIGITAIN_AG_SERVICE,
  payload: { data, name },
});

export const deleteBrandDigitainAgService = (brandId, params) => ({
  type: ThirdPartyServicesActionType.DELETE_BRAND_DIGITAIN_AG_SERVICE,
  payload: { params, brandId },
});

export const configureBrandDigitainAgServicePaymentLimits = (data, params, name) => ({
  type: ThirdPartyServicesActionType.CONFIGURE_BRAND_DIGITAIN_AG_SERVICE_PAYMENT_LIMITS,
  payload: { data, params, name },
});

export const getBrandDigitainAgServicePaymentLimits = (params) => ({
  type: ThirdPartyServicesActionType.GET_BRAND_DIGITAIN_AG_SERVICE_PAYMENT_LIMITS,
  payload: { params },
});

export const saveBrandDigitainAgServicePaymentLimits = (data) => ({
  type: ThirdPartyServicesActionType.SAVE_BRAND_DIGITAIN_AG_SERVICE_PAYMENT_LIMITS,
  payload: { data },
});

export const resetBrandDigitainAgServicePaymentLimits = () => ({
  type: ThirdPartyServicesActionType.RESET_BRAND_DIGITAIN_AG_SERVICE_PAYMENT_LIMITS,
});

export const getBrandCrmServices = (params) => ({
  type: ThirdPartyServicesActionType.GET_BRAND_CRM_SERVICES,
  payload: { params },
});

export const saveBrandCrmServices = (data) => ({
  type: ThirdPartyServicesActionType.SAVE_BRAND_CRM_SERVICES,
  payload: { data },
});

export const resetBrandCrmServices = () => ({
  type: ThirdPartyServicesActionType.RESET_BRAND_CRM_SERVICES,
});
export const addBrandSmarticoCrmService = (data, name) => ({
  type: ThirdPartyServicesActionType.ADD_BRAND_SMARTICO_CRM_SERVICE,
  payload: { data, name },
});

export const deleteBrandSmarticoCrmService = (brandId, params) => ({
  type: ThirdPartyServicesActionType.DELETE_BRAND_SMARTICO_CRM_SERVICE,
  payload: { brandId, params },
});

export const editBrandSmarticoCrmService = (data, name) => ({
  type: ThirdPartyServicesActionType.EDIT_BRAND_SMARTICO_CRM_SERVICE,
  payload: { data, name },
});

export const getBrandSmarticoCrmService = (params) => ({
  type: ThirdPartyServicesActionType.GET_BRAND_SMARTICO_CRM_SERVICE,
  payload: { params },
});

export const saveBrandCrmService = (data) => ({
  type: ThirdPartyServicesActionType.SAVE_BRAND_CRM_SERVICE,
  payload: { data },
});

export const resetBrandCrmService = () => ({
  type: ThirdPartyServicesActionType.RESET_BRAND_CRM_SERVICE,
});

export const changeBrandSmarticoCrmServiceStatus = (brandId, data) => ({
  type: ThirdPartyServicesActionType.CHANGE_BRAND_SMARTICO_CRM_SERVICE_STATUS,
  payload: { data, brandId },
});

export const getBrandGamificationServices = (params) => ({
  type: ThirdPartyServicesActionType.GET_BRAND_GAMIFICATION_SERVICES,
  payload: { params },
});

export const saveBrandGamificationServices = (data) => ({
  type: ThirdPartyServicesActionType.SAVE_BRAND_GAMIFICATION_SERVICES,
  payload: { data },
});

export const resetBrandGamificationServices = () => ({
  type: ThirdPartyServicesActionType.RESET_BRAND_GAMIFICATION_SERVICES,
});
export const addBrandSmarticoGamificationService = (data, name) => ({
  type: ThirdPartyServicesActionType.ADD_BRAND_SMARTICO_GAMIFICATION_SERVICE,
  payload: { data, name },
});

export const deleteBrandSmarticoGamificationService = (brandId, params) => ({
  type: ThirdPartyServicesActionType.DELETE_BRAND_SMARTICO_GAMIFICATION_SERVICE,
  payload: { brandId, params },
});

export const editBrandSmarticoGamificationService = (data, name) => ({
  type: ThirdPartyServicesActionType.EDIT_BRAND_SMARTICO_GAMIFICATION_SERVICE,
  payload: { data, name },
});

export const getBrandSmarticoGamificationService = (params) => ({
  type: ThirdPartyServicesActionType.GET_BRAND_SMARTICO_GAMIFICATION_SERVICE,
  payload: { params },
});

export const saveBrandGamificationService = (data) => ({
  type: ThirdPartyServicesActionType.SAVE_BRAND_GAMIFICATION_SERVICE,
  payload: { data },
});

export const resetBrandGamificationService = () => ({
  type: ThirdPartyServicesActionType.RESET_BRAND_GAMIFICATION_SERVICE,
});

export const changeBrandSmarticoGamificationServiceStatus = (brandId, data) => ({
  type: ThirdPartyServicesActionType.CHANGE_BRAND_SMARTICO_GAMIFICATION_SERVICE_STATUS,
  payload: { data, brandId },
});

export const getBrandRetailSolutionServices = (params) => ({
  type: ThirdPartyServicesActionType.GET_BRAND_RETAIL_SOLUTION_SERVICES,
  payload: { params },
});

export const saveBrandRetailSolutionServices = (data) => ({
  type: ThirdPartyServicesActionType.SAVE_BRAND_RETAIL_SOLUTION_SERVICES,
  payload: { data },
});

export const resetBrandRetailSolutionServices = () => ({
  type: ThirdPartyServicesActionType.RESET_BRAND_RETAIL_SOLUTION_SERVICES,
});

export const saveBrandRetailSolutionService = (data) => ({
  type: ThirdPartyServicesActionType.SAVE_BRAND_RETAIL_SOLUTION_SERVICE,
  payload: { data },
});

export const resetBrandRetailSolutionService = () => ({
  type: ThirdPartyServicesActionType.RESET_BRAND_RETAIL_SOLUTION_SERVICE,
});

export const addBrandDigitainRetailService = (data, name) => ({
  type: ThirdPartyServicesActionType.ADD_BRAND_DIGITAIN_RETAIL_SERVICE,
  payload: { data, name },
});

export const getBrandDigitainRetailService = (params) => ({
  type: ThirdPartyServicesActionType.GET_BRAND_DIGITAIN_RETAIL_SERVICE,
  payload: { params },
});

export const editBrandDigitainRetailService = (data, name) => ({
  type: ThirdPartyServicesActionType.EDIT_BRAND_DIGITAIN_RETAIL_SERVICE,
  payload: { data, name },
});

export const deleteBrandDigitainRetailService = (brandId, params) => ({
  type: ThirdPartyServicesActionType.DELETE_BRAND_DIGITAIN_RETAIL_SERVICE,
  payload: { params, brandId },
});

export const addBrandWhatsAppService = (data, name) => ({
  type: ThirdPartyServicesActionType.ADD_BRAND_WHATSAPP_SERVICE,
  payload: { data, name },
});

export const getBrandWhatsAppService = (params) => ({
  type: ThirdPartyServicesActionType.GET_BRAND_WHATSAPP_SERVICE,
  payload: { params },
});

export const editBrandWhatsAppService = (data, name) => ({
  type: ThirdPartyServicesActionType.EDIT_BRAND_WHATSAPP_SERVICE,
  payload: { data, name },
});

export const deleteBrandWhatsAppService = (brandId, params) => ({
  type: ThirdPartyServicesActionType.DELETE_BRAND_WHATSAPP_SERVICE,
  payload: { params, brandId },
});
export const changeBrandWhatsAppServiceStatus = (brandId, data) => ({
  type: ThirdPartyServicesActionType.CHANGE_BRAND_WHATSAPP_SERVICE_STATUS,
  payload: { data, brandId },
});

export const addBrandDigitainCrmService = (brandId, data) => ({
  type: ThirdPartyServicesActionType.ADD_BRAND_DIGITAIN_CRM_SERVICE,
  payload: { brandId, data },
});

export const deleteBrandDigitainCrmService = (brandId, data) => ({
  type: ThirdPartyServicesActionType.DELETE_BRAND_DIGITAIN_CRM_SERVICE,
  payload: { brandId, data },
});

export const addBrandDigitainAffiliateService = (data, name) => ({
  type: ThirdPartyServicesActionType.ADD_BRAND_DIGITAIN_AFFILIATE_SERVICE,
  payload: { name, data },
});

export const getBrandDigitainAffiliateService = (data) => ({
  type: ThirdPartyServicesActionType.GET_BRAND_DIGITAIN_AFFILIATE_SERVICE,
  payload: { data },
});

export const editBrandDigitainAffiliateService = (data, name) => ({
  type: ThirdPartyServicesActionType.EDIT_BRAND_DIGITAIN_AFFILIATE_SERVICE,
  payload: { name, data },
});

export const deleteBrandDigitainAffiliateService = (brandId, data) => ({
  type: ThirdPartyServicesActionType.DELETE_BRAND_DIGITAIN_AFFILIATE_SERVICE,
  payload: { brandId, data },
});

export const addBrandAlanbaseService = (data, name) => ({
  type: ThirdPartyServicesActionType.ADD_BRAND_ALANBASE_SERVICE,
  payload: { name, data },
});

export const getBrandAlanbaseService = (data) => ({
  type: ThirdPartyServicesActionType.GET_BRAND_ALANBASE_SERVICE,
  payload: { data },
});

export const editBrandAlanbaseService = (data, name) => ({
  type: ThirdPartyServicesActionType.EDIT_BRAND_ALANBASE_SERVICE,
  payload: { name, data },
});

export const deleteBrandAlanbaseService = (brandId, data) => ({
  type: ThirdPartyServicesActionType.DELETE_BRAND_ALANBASE_SERVICE,
  payload: { brandId, data },
});

export const saveBrandAffiliateService = (data) => ({
  type: ThirdPartyServicesActionType.SAVE_BRAND_AFFILIATE_SERVICE,
  payload: { data },
});

export const resetBrandAffiliateService = () => ({
  type: ThirdPartyServicesActionType.RESET_BRAND_AFFILIATE_SERVICE,
});

export const getBrandKycServices = (params) => ({
  type: ThirdPartyServicesActionType.GET_BRAND_KYC_SERVICES,
  payload: { params },
});

export const saveBrandKycServices = (data) => ({
  type: ThirdPartyServicesActionType.SAVE_BRAND_KYC_SERVICES,
  payload: { data },
});

export const resetBrandKycServices = () => ({
  type: ThirdPartyServicesActionType.RESET_BRAND_KYC_SERVICES,
});

export const saveBrandKycService = (data) => ({
  type: ThirdPartyServicesActionType.SAVE_BRAND_KYC_SERVICE,
  payload: { data },
});

export const resetBrandKycService = () => ({
  type: ThirdPartyServicesActionType.RESET_BRAND_KYC_SERVICE,
});

export const addBrandMetaMapService = (data, name) => ({
  type: ThirdPartyServicesActionType.ADD_BRAND_METAMAP_SERVICE,
  payload: { name, data },
});

export const getBrandMetaMapService = (params) => ({
  type: ThirdPartyServicesActionType.GET_BRAND_METAMAP_SERVICE,
  payload: { params },
});

export const editBrandMetaMapService = (data, name) => ({
  type: ThirdPartyServicesActionType.EDIT_BRAND_METAMAP_SERVICE,
  payload: { name, data },
});

export const deleteBrandMetaMapService = (brandId, data) => ({
  type: ThirdPartyServicesActionType.DELETE_BRAND_METAMAP_SERVICE,
  payload: { brandId, data },
});

export const changeMetaMapServiceStatus = (brandId, data) => ({
  type: ThirdPartyServicesActionType.CHANGE_BRAND_METAMAP_SERVICE_STATUS,
  payload: { brandId, data },
});

export const addBrandThunderBiteGamificationService = (data, name) => ({
  type: ThirdPartyServicesActionType.ADD_BRAND_THUNDERBITE_GAMIFICATION_SERVICE,
  payload: { data, name },
});

export const deleteBrandThunderBiteGamificationService = (brandId, params) => ({
  type: ThirdPartyServicesActionType.DELETE_BRAND_THUNDERBITE_GAMIFICATION_SERVICE,
  payload: { brandId, params },
});

export const editBrandThunderBiteGamificationService = (data, name) => ({
  type: ThirdPartyServicesActionType.EDIT_BRAND_THUNDERBITE_GAMIFICATION_SERVICE,
  payload: { data, name },
});

export const getBrandThunderBiteGamificationService = (params) => ({
  type: ThirdPartyServicesActionType.GET_BRAND_THUNDERBITE_GAMIFICATION_SERVICE,
  payload: { params },
});

export const changeBrandThunderBiteGamificationServiceStatus = (brandId, data) => ({
  type: ThirdPartyServicesActionType.CHANGE_BRAND_THUNDERBITE_GAMIFICATION_SERVICE_STATUS,
  payload: { data, brandId },
});
