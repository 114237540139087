import { Upload } from 'antd';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { MessageService } from 'services';
import { StyledUploadIcon, StyledUploadWrapper } from 'components/styledComponents/icon/Icon.styled';
import { AppConstants } from 'constants/index';
import { getFileNameAndExtension } from 'utils/utils';
import { getError, getFileSizeByMb } from 'utils/helpers';
import NetworkService from 'services/networkService';
import _ from 'lodash';

const { Dragger } = Upload;

const UploadLogo = (props) => {
  const {
    accept, maxFileSize, directoryPath, brandId, changeLoadingState, setErrorMessage, setFieldsValue, fieldName, children, directoryRules,
  } = props;

  const { t } = useTranslation();

  const handleUpload = ({ file }) => {
    changeLoadingState(true);
    const formData = new FormData();
    formData.append('file', file);
    formData.append('brandId', brandId);
    formData.append('directoryPath', directoryPath);
    formData.append('generateDirectoryIfNotExists', true);
    formData.append('generateNewFileName', true);
    const options = {
      data: formData,
      headers: { 'content-type': 'multipart/form-data' },
    };
    const { BrandAdmin, FileManager, UploadFile } = AppConstants.api;
    return NetworkService.makeAPIPostRequest([BrandAdmin, FileManager, UploadFile], options)
      .then(({ data }) => {
        changeLoadingState(false);
        const {
          fileInfo: { cdnUrl },
        } = data;

        setFieldsValue({ [fieldName]: cdnUrl });
      })
      .catch((err) => {
        changeLoadingState(false);
        if (err) {
          const error = getError(err);
          setErrorMessage(error);
        }
      });
  };

  const beforeUpload = (file) => {
    const [, extension] = getFileNameAndExtension(file.name);
    const acceptedExtensions = accept.split(',');
    const ext = `.${extension}`;

    if (!acceptedExtensions.includes(ext) || !directoryRules.find((el) => el.permittedFileTypeExtension === ext)) {
      MessageService.error(t('notification:wrongFileFormat'));
      return false;
    }

    const allowedMaxFileSize = getFileSizeByMb(
      directoryRules.find((el) => (_.isNull(el.permittedFileTypeExtension) || el.permittedFileTypeExtension === ext) && !_.isNull(el.maxFileSize)).maxFileSize,
    );
    const allowedFileSize = !_.isNull(maxFileSize) ? Math.min(maxFileSize, allowedMaxFileSize) : allowedMaxFileSize;
    const fileSize = getFileSizeByMb(file.size);

    const isSmallFile = allowedFileSize < 1;
    const maxFS = isSmallFile ? allowedFileSize * 1024 : allowedFileSize;

    if (fileSize > allowedFileSize) {
      MessageService.error(`${t('notification:maxUploadSize')} ${maxFS}${t(isSmallFile ? 'memorySize.kb' : 'memorySize.mb')}`);
      return false;
    }

    return true;
  };

  const uploadProps = {
    customRequest: handleUpload,
    showUploadList: false,
    multiple: false,
    beforeUpload,
  };

  return (
    <Dragger {...uploadProps} accept={accept}>
      <StyledUploadWrapper>
        <StyledUploadIcon>{children}</StyledUploadIcon>
      </StyledUploadWrapper>
    </Dragger>
  );
};

UploadLogo.propTypes = {
  accept: PropTypes.string,
  maxFileSize: PropTypes.number,
  directoryPath: PropTypes.string.isRequired,
  changeLoadingState: PropTypes.func.isRequired,
  setErrorMessage: PropTypes.func.isRequired,
  setFieldsValue: PropTypes.func.isRequired,
  fieldName: PropTypes.string.isRequired,
  brandId: PropTypes.number,
  children: PropTypes.node.isRequired,
  directoryRules: PropTypes.array,
};

UploadLogo.defaultProps = {
  accept: undefined,
  brandId: null,
  maxFileSize: AppConstants.maxFileSize,
  directoryRules: [],
};

export default UploadLogo;
