import { LocalStorageService } from 'services';
import AuthActionType from 'actions/auth/authActionType';

const getInitialState = () => ({
  user: LocalStorageService.get('user'),
  isTFAEnabled: false,
  isAuthenticated: false,
  partnerAccesses: [],
  isPasswordExpired: false,
  isTokenChanged: false,
});

const authReducer = (state = getInitialState(), { type, payload }) => {
  switch (type) {
  case AuthActionType.SET_AUTHENTICATED:
    return {
      ...state,
      isAuthenticated: payload.status,
    };
  case AuthActionType.SAVE_AUTH_USER:
    return {
      ...state,
      user: payload.data,
    };
  case AuthActionType.GET_USER_SUCCESS:
    return {
      ...state,
      user: {
        ...state.user,
        ...payload.data,
      },
    };
  case AuthActionType.CHANGE_TFA_STATE:
    return {
      ...state,
      isTFAEnabled: payload.state,
    };
  case AuthActionType.GET_AUTH_USER_PARTNER_ACCESSES_SUCCESS:
    return {
      ...state,
      partnerAccesses: payload.data,
    };
  case AuthActionType.CHANGE_PASSWORD_EXPIRED_STATE:
    return {
      ...state,
      isPasswordExpired: payload.state,
    };
  case AuthActionType.CHANGE_TOKEN_STATE:
    return {
      ...state,
      isTokenChanged: payload.state,
    };
  default:
    return state;
  }
};

export default authReducer;
