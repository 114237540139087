import PropTypes from 'prop-types';
import _ from 'lodash';
import { useMemo } from 'react';
import { Content } from 'components/layout';
import { Sider, Notification } from 'containers/layout';
import Header from 'containers/layout/header/Header';
import { arrayToTree } from 'utils/utils';
import { getSidebarSizes } from 'utils/styledHelpers';
import { useLayout } from 'hooks';
import { StyledLayout } from './Layouts.styled';

const sidebarSizes = getSidebarSizes();

const BaseLayout = (props) => {
  const {
    children, routers, page, width, collapsedWidth,
  } = props;

  const { hideLayout } = useLayout();

  const newData = useMemo(() => _.cloneDeep(routers).filter((el) => el.menuItem), [routers]);
  const menus = useMemo(() => arrayToTree(newData, 'id', 'menuParentId'), [newData]);

  return (
    <>
      <Notification />
      {hideLayout ? (
        <StyledLayout>
          <Content noPadding>{children}</Content>
        </StyledLayout>
      ) : (
        <>
          <Header collapsedWidth={collapsedWidth} width={width} />
          <StyledLayout>
            <Sider menus={menus} collapsedWidth={collapsedWidth} width={width} page={page} />
            <Content>{children}</Content>
          </StyledLayout>
        </>
      )}
    </>
  );
};

BaseLayout.propTypes = {
  children: PropTypes.node,
  routers: PropTypes.array,
  page: PropTypes.string,
  width: PropTypes.string,
  collapsedWidth: PropTypes.string,
};

BaseLayout.defaultProps = {
  children: null,
  routers: [],
  page: '',
  collapsedWidth: sidebarSizes.collapsedWidth,
  width: sidebarSizes.width,
};

export default BaseLayout;
