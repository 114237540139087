import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Empty, Input } from 'antd';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import { StyledSearchableTable } from 'components/styledComponents/table/Table.styled';
import { StyledModalRight } from 'components/styledComponents/modal/Modal.styled';
import { NotFormItemInputWrapper } from 'components/styledComponents/input/Input.styled';
import { onlyNumbersFormatter } from 'utils/utils';
import AdminTable from './AdminTable';

const pageSize = 50;

const SearchableTable = (props) => {
  const {
    searchBy, data, columns, loading, placeholder, rowClassName, isHeaderTable, changeLoadingState, isSelectable, rowKey, id, isNumeric, isSearchable, selected, setSelected,
  } = props;

  const { t } = useTranslation();

  const [filteredData, setFilteredData] = useState([]);
  const [search, setSearch] = useState('');
  const [pageData, setPageData] = useState([]);
  const [pageNumber, setPageNumber] = useState(1);
  const [value, setValue] = useState('');

  useEffect(() => {
    changeLoadingState(loading);
  }, [changeLoadingState, loading]);

  useEffect(() => {
    let newData = [];
    if (isSearchable) {
      newData = data.filter((row) => row[searchBy]?.toString().trim().toLowerCase().startsWith(search.trim().toLowerCase()));
    } else {
      newData = data;
    }
    setFilteredData(newData);
  }, [data, isSearchable, search, searchBy]);

  useEffect(() => {
    if (filteredData.length) {
      const newPageData = filteredData.slice((pageNumber - 1) * pageSize, pageNumber * pageSize);
      setPageData(newPageData);
    }
  }, [filteredData, pageNumber]);

  const handleSearch = (term) => {
    setSearch(term);
    setPageNumber(1);
  };

  const handleChange = (e) => {
    const val = isNumeric ? onlyNumbersFormatter(e) : e.target.value;
    setValue(val);
  };

  const handleTableChange = ({ current }) => setPageNumber(current);

  const rowSelection = {
    selectedRowKeys: selected,
    onChange: (values) => {
      const visibleIds = pageData.map((el) => el[id]);
      setSelected([..._.difference(selected, visibleIds), ...values]);
    },
    getCheckboxProps: (record) => ({ disabled: !_.isEmpty(record.segments) }),
  };

  const tableInnerContent = filteredData.length ? (
    <AdminTable
      rowKey={rowKey}
      rowClassName={rowClassName}
      rowSelection={isSelectable ? rowSelection : null}
      size={isHeaderTable ? 'small' : 'middle'}
      data={pageData}
      columns={columns}
      pagination={{
        showSizeChanger: false,
        pageSize,
        pageSizeOptions: false,
        size: 'small',
        total: filteredData.length,
        showTotal: null,
        current: pageNumber,
        hideOnSinglePage: true,
      }}
      onChange={handleTableChange}
    />
  ) : (
    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={t('noData')} />
  );
  return (
    <StyledModalRight fullWidth>
      {isSearchable && (
        <NotFormItemInputWrapper hasMarginBottom>
          <Input.Search allowClear placeholder={placeholder} onSearch={handleSearch} onChange={handleChange} value={value} />
        </NotFormItemInputWrapper>
      )}
      <StyledSearchableTable>{tableInnerContent}</StyledSearchableTable>
    </StyledModalRight>
  );
};

SearchableTable.propTypes = {
  data: PropTypes.array,
  loading: PropTypes.bool,
  columns: PropTypes.array.isRequired,
  searchBy: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  rowClassName: PropTypes.func,
  changeLoadingState: PropTypes.func,
  isSelectable: PropTypes.bool,
  rowKey: PropTypes.string,
  id: PropTypes.string,
  isNumeric: PropTypes.bool,
  isSearchable: PropTypes.bool,
  isHeaderTable: PropTypes.bool,
  selected: PropTypes.array,
  setSelected: PropTypes.func,
};

SearchableTable.defaultProps = {
  data: [],
  loading: false,
  placeholder: '',
  rowClassName: (record, index) => (index % 2 ? 'odd' : 'even'),
  isSelectable: true,
  rowKey: 'id',
  id: 'id',
  isNumeric: true,
  isSearchable: true,
  isHeaderTable: false,
  changeLoadingState: () => {},
  selected: [],
  setSelected: () => {},
};

export default SearchableTable;
