import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { ChangeStatus } from 'components/brandSetting';
import { activateBrand } from 'actions/brand/brandActionCreator';
import { selectBrand, selectLocaleResource } from 'selectors';
import { deviceTypes } from 'constants/mappingOrder';
import enumTypes from 'constants/enumTypes';

const mapStateToProps = ({ brands, locale }) => ({
  brand: selectBrand(brands),
  locale: selectLocaleResource(locale, { brandStatuses: enumTypes.brandStatuses, deviceTypes: enumTypes.deviceTypes }, { deviceTypes }),
});

const mapDispatchToProps = (dispatch) => bindActionCreators(
  {
    activateBrand,
  },
  dispatch,
);

export default connect(mapStateToProps, mapDispatchToProps)(ChangeStatus);
