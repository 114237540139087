import { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Input, Form } from 'antd';
import _ from 'lodash';
import { StyledEditableCell, CellAlign } from 'components/styledComponents/table/Table.styled';

const AdminTableCell = (props) => {
  const {
    // column props
    additionalProps: {
      key, editable, className: columnClass, rules, component, props: columnProps,
    },
    // additional
    dataIndex,
    render,
    record,
    form,
    index,
    actions,
    expandIcon,
    isExpanded,
    className,
    prefixCls,
    align,
    // fixed column props
    firstFixLeft,
    firstFixRight,
    fixLeft,
    fixRight,
    lastFixLeft,
    lastFixRight,
  } = props;

  const [editing, setEditing] = useState(false);
  const inputEl = useRef(null);

  const {
    validateFields, resetFields, setFieldsValue, getFieldValue,
  } = form;

  const fixColumnClass = {
    firstFixRight: firstFixRight ? 'ant-table-cell-fix-right-first' : '',
    firstFixLeft: firstFixLeft ? 'ant-table-cell-fix-left-first' : '',
    lastFixRight: lastFixRight ? 'ant-table-cell-fix-right-last' : '',
    lastFixLeft: lastFixLeft ? 'ant-table-cell-fix-left-last' : '',
    fixLeft: !_.isUndefined(fixLeft) ? 'ant-table-cell-fix-left' : '',
    fixRight: !_.isUndefined(fixRight) ? 'ant-table-cell-fix-right' : '',
  };
  const style = align ? { textAlign: align } : null;

  const cellClassName = `${prefixCls}-cell ${className} ${columnClass || ''} ${Object.values(fixColumnClass).join(' ')}`;

  const Component = component || Input;

  useEffect(() => {
    if (editing) {
      setFieldsValue({ [dataIndex]: record[dataIndex] });
      inputEl.current.focus();
    }
  }, [editing, dataIndex, form, record, setFieldsValue]);

  const handleClick = () => {
    if (!editing) {
      setFieldsValue({ [dataIndex]: undefined });
    }
    setEditing(true);
  };

  const save = () => {
    validateFields()
      .then((values) => {
        if (_.isEqual(record[dataIndex], getFieldValue([dataIndex]))) {
          setEditing(false);
        } else {
          actions.handleSave({ ...record, ...values }, index);
        }
      })
      .catch(() => {
        setEditing(false);
        resetFields();
      });
  };

  const renderEditableCell = () => (editing ? (
    <Form.Item className="editable-cell-active " name={dataIndex} rules={rules}>
      <Component ref={inputEl} onPressEnter={save} onBlur={save} {...columnProps} />
    </Form.Item>
  ) : (
    <StyledEditableCell className="editable-cell-value-wrap  " onClick={handleClick}>
      <div className="editable-cell-value-wrapper">{record[dataIndex]}</div>
    </StyledEditableCell>
  ));

  const renderExpanded = () => (isExpanded ? (
    <div role="button" tabIndex="0" className="ant-table-row-expand-icon ant-table-row-expanded" aria-label="Collapse row" />
  ) : (
    <div role="button" className="ant-table-row-expand-icon ant-table-row-collapsed" aria-label="Expand row" />
  ));

  return (
    <td className={cellClassName} key={key} style={style}>
      {!!expandIcon && renderExpanded()}
      {(editable && renderEditableCell()) || (render ? render(actions, record, index) : (!_.isNull(record[dataIndex]) && record[dataIndex]) || <CellAlign type="left">-</CellAlign>)}
    </td>
  );
};

AdminTableCell.propTypes = {
  form: PropTypes.shape({
    validateFields: PropTypes.func,
    setFieldsValue: PropTypes.func,
    resetFields: PropTypes.func,
    getFieldValue: PropTypes.func,
  }),
  additionalProps: PropTypes.object,
  dataIndex: PropTypes.string,
  render: PropTypes.func,
  record: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  isExpanded: PropTypes.bool.isRequired,
  actions: PropTypes.object,
  expandIcon: PropTypes.any,
  className: PropTypes.string,
  prefixCls: PropTypes.string,
  align: PropTypes.string,
  // fixed column props
  firstFixLeft: PropTypes.bool,
  firstFixRight: PropTypes.bool,
  fixLeft: PropTypes.number,
  fixRight: PropTypes.number,
  lastFixLeft: PropTypes.bool,
  lastFixRight: PropTypes.bool,
};

AdminTableCell.defaultProps = {
  actions: {},
  form: {},
  expandIcon: null,
  additionalProps: {},
  render: null,
  dataIndex: '',
  className: '',
  prefixCls: '',
  // fixed column props
  firstFixLeft: false,
  firstFixRight: false,
  fixLeft: undefined,
  fixRight: undefined,
  lastFixLeft: false,
  lastFixRight: false,
  align: '',
};

export default AdminTableCell;
