const ReportingActionType = {
  GET_CLOSED_ACCOUNTS: 'GET_CLOSED_ACCOUNTS',
  SAVE_CLOSED_ACCOUNTS: 'SAVE_CLOSED_ACCOUNTS',
  RESET_CLOSED_ACCOUNTS: 'RESET_CLOSED_ACCOUNTS',
  EXPORT_CLOSED_ACCOUNTS: 'EXPORT_CLOSED_ACCOUNTS',

  GET_LARGE_TRANSACTIONS: 'GET_LARGE_TRANSACTIONS',
  SAVE_LARGE_TRANSACTIONS: 'SAVE_LARGE_TRANSACTIONS',
  RESET_LARGE_TRANSACTIONS: 'RESET_LARGE_TRANSACTIONS',
  GENERATE_LARGE_TRANSACTIONS: 'GENERATE_LARGE_TRANSACTIONS',

  GET_LARGE_TRANSACTIONS_BY_PLAYER: 'GET_LARGE_TRANSACTIONS_BY_PLAYER',
  SAVE_LARGE_TRANSACTIONS_BY_PLAYER: 'SAVE_LARGE_TRANSACTIONS_BY_PLAYER',
  RESET_LARGE_TRANSACTIONS_BY_PLAYER: 'RESET_LARGE_TRANSACTIONS_BY_PLAYER',
  EXPORT_LARGE_TRANSACTIONS_BY_PLAYER: 'EXPORT_LARGE_TRANSACTIONS_BY_PLAYER',

  GET_LARGE_TRANSACTIONS_BY_BRAND: 'GET_LARGE_TRANSACTIONS_BY_BRAND',
  SAVE_LARGE_TRANSACTIONS_BY_BRAND: 'SAVE_LARGE_TRANSACTIONS_BY_BRAND',
  RESET_LARGE_TRANSACTIONS_BY_BRAND: 'RESET_LARGE_TRANSACTIONS_BY_BRAND',
  EXPORT_LARGE_TRANSACTIONS_BY_BRAND: 'EXPORT_LARGE_TRANSACTIONS_BY_BRAND',

  GET_NEGATIVE_BALANCE: 'GET_NEGATIVE_BALANCE',
  SAVE_NEGATIVE_BALANCE: 'SAVE_NEGATIVE_BALANCE',
  RESET_NEGATIVE_BALANCE: 'RESET_NEGATIVE_BALANCE',
  EXPORT_NEGATIVE_BALANCE: 'EXPORT_NEGATIVE_BALANCE',

  GET_BALANCE_ADJUSTMENTS_REPORT: 'GET_BALANCE_ADJUSTMENTS_REPORT',
  SAVE_BALANCE_ADJUSTMENTS_REPORT: 'SAVE_BALANCE_ADJUSTMENTS_REPORT',
  RESET_BALANCE_ADJUSTMENTS_REPORT: 'RESET_BALANCE_ADJUSTMENTS_REPORT',
  EXPORT_BALANCE_ADJUSTMENTS_REPORT: 'EXPORT_BALANCE_ADJUSTMENTS_REPORT',

  GET_BI_BET_BY_PLAYER: 'GET_BI_BET_BY_PLAYER',
  SAVE_BI_BET_BY_PLAYER: 'SAVE_BI_BET_BY_PLAYER',
  RESET_BI_BET_BY_PLAYER: 'RESET_BI_BET_BY_PLAYER',
  GENERATE_BI_BET_BY_PLAYER: 'GENERATE_BI_BET_BY_PLAYER',

  GET_BI_BET_BY_CURRENCY: 'GET_BI_BET_BY_CURRENCY',
  SAVE_BI_BET_BY_CURRENCY: 'SAVE_BI_BET_BY_CURRENCY',
  RESET_BI_BET_BY_CURRENCY: 'RESET_BI_BET_BY_CURRENCY',
  EXPORT_BI_BET_BY_CURRENCY: 'EXPORT_BI_BET_BY_CURRENCY',

  GET_BI_FINANCIAL_BY_PLAYER: 'GET_BI_FINANCIAL_BY_PLAYER',
  SAVE_BI_FINANCIAL_BY_PLAYER: 'SAVE_BI_FINANCIAL_BY_PLAYER',
  RESET_BI_FINANCIAL_BY_PLAYER: 'RESET_BI_FINANCIAL_BY_PLAYER',
  EXPORT_BI_FINANCIAL_BY_PLAYER: 'EXPORT_BI_FINANCIAL_BY_PLAYER',

  GET_BI_FINANCIAL_BY_CURRENCY: 'GET_BI_FINANCIAL_BY_CURRENCY',
  SAVE_BI_FINANCIAL_BY_CURRENCY: 'SAVE_BI_FINANCIAL_BY_CURRENCY',
  RESET_BI_FINANCIAL_BY_CURRENCY: 'RESET_BI_FINANCIAL_BY_CURRENCY',
  EXPORT_BI_FINANCIAL_BY_CURRENCY: 'EXPORT_BI_FINANCIAL_BY_CURRENCY',

  GET_PLAYERS_WALLETS_INFO: 'GET_PLAYERS_WALLETS_INFO',
  SAVE_PLAYERS_WALLETS_INFO: 'SAVE_PLAYERS_WALLETS_INFO',
  RESET_PLAYERS_WALLETS_INFO: 'RESET_PLAYERS_WALLETS_INFO',
  GENERATE_PLAYERS_WALLETS_INFO: 'GENERATE_PLAYERS_WALLETS_INFO',

  GET_FINANCIAL_BY_PROVIDER: 'GET_FINANCIAL_BY_PROVIDER',
  SAVE_FINANCIAL_BY_PROVIDER: 'SAVE_FINANCIAL_BY_PROVIDER',
  RESET_FINANCIAL_BY_PROVIDER: 'RESET_FINANCIAL_BY_PROVIDER',
  EXPORT_FINANCIAL_BY_PROVIDER: 'EXPORT_FINANCIAL_BY_PROVIDER',

  GET_PROVIDER_TOP_GGRS: 'GET_PROVIDER_TOP_GGRS',
  SAVE_PROVIDER_TOP_POSITIVE_GGRS: 'SAVE_PROVIDER_TOP_POSITIVE_GGRS',
  RESET_PROVIDER_TOP_POSITIVE_GGRS: 'RESET_PROVIDER_TOP_POSITIVE_GGRS',

  SAVE_PROVIDER_TOP_NEGATIVE_GGRS: 'SAVE_PROVIDER_TOP_NEGATIVE_GGRS',
  RESET_PROVIDER_TOP_NEGATIVE_GGRS: 'RESET_PROVIDER_TOP_NEGATIVE_GGRS',

  GET_PLAYERS_FIRST_DEPOSITS: 'GET_PLAYERS_FIRST_DEPOSITS',
  SAVE_PLAYERS_FIRST_DEPOSITS: 'SAVE_PLAYERS_FIRST_DEPOSITS',
  RESET_PLAYERS_FIRST_DEPOSITS: 'RESET_PLAYERS_FIRST_DEPOSITS',

  EXPORT_PLAYERS_FIRST_DEPOSITS: 'EXPORT_PLAYERS_FIRST_DEPOSITS',

  GET_BONUS_TOTALS_BY_PLAYER: 'GET_BONUS_TOTALS_BY_PLAYER',
  SAVE_BONUS_TOTALS_BY_PLAYER: 'SAVE_BONUS_TOTALS_BY_PLAYER',
  RESET_BONUS_TOTALS_BY_PLAYER: 'RESET_BONUS_TOTALS_BY_PLAYER',
  GENERATE_BONUS_TOTALS_BY_PLAYER_REPORT: 'GENERATE_BONUS_TOTALS_BY_PLAYER_REPORT',

  GET_BONUS_TOTALS_BY_TYPE: 'GET_BONUS_TOTALS_BY_TYPE',
  SAVE_BONUS_TOTALS_BY_TYPE: 'SAVE_BONUS_TOTALS_BY_TYPE',
  RESET_BONUS_TOTALS_BY_TYPE: 'RESET_BONUS_TOTALS_BY_TYPE',
  EXPORT_BONUS_TOTALS_BY_TYPE: 'EXPORT_BONUS_TOTALS_BY_TYPE',

  GET_BONUS_TOTALS_BY_STATUS: 'GET_BONUS_TOTALS_BY_STATUS',
  SAVE_BONUS_TOTALS_BY_STATUS: 'SAVE_BONUS_TOTALS_BY_STATUS',
  RESET_BONUS_TOTALS_BY_STATUS: 'RESET_BONUS_TOTALS_BY_STATUS',
  EXPORT_BONUS_TOTALS_BY_STATUS: 'EXPORT_BONUS_TOTALS_BY_STATUS',

  GET_BONUS_TOTALS_BY_PROVIDER: 'GET_BONUS_TOTALS_BY_PROVIDER',
  SAVE_BONUS_TOTALS_BY_PROVIDER: 'SAVE_BONUS_TOTALS_BY_PROVIDER',
  RESET_BONUS_TOTALS_BY_PROVIDER: 'RESET_BONUS_TOTALS_BY_PROVIDER',
  EXPORT_BONUS_TOTALS_BY_PROVIDER: 'EXPORT_BONUS_TOTALS_BY_PROVIDER',

  GET_INACTIVE_PLAYERS: 'GET_INACTIVE_PLAYERS',
  SAVE_INACTIVE_PLAYERS: 'SAVE_INACTIVE_PLAYERS',
  RESET_INACTIVE_PLAYERS: 'RESET_INACTIVE_PLAYERS',
  GENERATE_INACTIVE_PLAYERS: 'GENERATE_INACTIVE_PLAYERS',

  TRANSFER_INACTIVE_PLAYERS_MONEY: 'TRANSFER_INACTIVE_PLAYERS_MONEY',

  GET_TOP_PLAYERS_BY_GGR: 'GET_TOP_PLAYERS_BY_GGR',
  SAVE_TOP_PLAYERS_BY_GGR: 'SAVE_TOP_PLAYERS_BY_GGR',
  RESET_TOP_PLAYERS_BY_GGR: 'RESET_TOP_PLAYERS_BY_GGR',
  EXPORT_TOP_PLAYERS_BY_GGR: 'EXPORT_TOP_PLAYERS_BY_GGR',

  GET_TOP_PLAYER_BY_GGR_DETAILS: 'GET_TOP_PLAYER_BY_GGR_DETAILS',
  SAVE_TOP_PLAYER_BY_GGR_DETAILS: 'SAVE_TOP_PLAYER_BY_GGR_DETAILS',
  RESET_TOP_PLAYER_BY_GGR_DETAILS: 'RESET_TOP_PLAYER_BY_GGR_DETAILS',

  GET_TOP_PLAYERS_BY_TRANSACTION_TYPE: 'GET_TOP_PLAYERS_BY_TRANSACTION_TYPE',
  SAVE_TOP_PLAYERS_BY_TRANSACTION_TYPE: 'SAVE_TOP_PLAYERS_BY_TRANSACTION_TYPE',
  RESET_TOP_PLAYERS_BY_TRANSACTION_TYPE: 'RESET_TOP_PLAYERS_BY_TRANSACTION_TYPE',
  EXPORT_TOP_PLAYERS_BY_TRANSACTION_TYPE: 'EXPORT_TOP_PLAYERS_BY_TRANSACTION_TYPE',

  GET_TOP_PLAYER_BY_TRANSACTION_TYPE_DETAILS: 'GET_TOP_PLAYER_BY_TRANSACTION_TYPE_DETAILS',
  SAVE_TOP_PLAYER_BY_TRANSACTION_TYPE_DETAILS: 'SAVE_TOP_PLAYER_BY_TRANSACTION_TYPE_DETAILS',
  RESET_TOP_PLAYER_BY_TRANSACTION_TYPE_DETAILS: 'RESET_TOP_PLAYER_BY_TRANSACTION_TYPE_DETAILS',
};

export default ReportingActionType;
