const RegulatoryActionType = {
  GET_SOFTWARE_CHANGES: 'GET_SOFTWARE_CHANGES',
  SAVE_SOFTWARE_CHANGES: 'SAVE_SOFTWARE_CHANGES',
  RESET_SOFTWARE_CHANGES: 'RESET_SOFTWARE_CHANGES',
  EXPORT_SOFTWARE_CHANGES: 'EXPORT_SOFTWARE_CHANGES',

  GET_GAME_RATIO: 'GET_GAME_RATIO',
  SAVE_GAME_RATIO: 'SAVE_GAME_RATIO',
  RESET_GAME_RATIO: 'RESET_GAME_RATIO',
  EXPORT_GAME_RATIO: 'EXPORT_GAME_RATIO',

  GET_PLAYER_EXCLUSION: 'GET_PLAYER_EXCLUSION',
  SAVE_PLAYER_EXCLUSION: 'SAVE_PLAYER_EXCLUSION',
  RESET_PLAYER_EXCLUSION: 'RESET_PLAYER_EXCLUSION',
  EXPORT_PLAYER_EXCLUSION: 'EXPORT_PLAYER_EXCLUSION',

  GET_GAME_TYPES_REPORT: 'GET_GAME_TYPES_REPORT',
  SAVE_GAME_TYPES_REPORT: 'SAVE_GAME_TYPES_REPORT',
  RESET_GAME_TYPES_REPORT: 'RESET_GAME_TYPES_REPORT',
  EXPORT_GAME_TYPES_REPORT: 'EXPORT_GAME_TYPES_REPORT',

  GET_BALANCE_ADJUSTMENT: 'GET_BALANCE_ADJUSTMENT',
  SAVE_BALANCE_ADJUSTMENT: 'SAVE_BALANCE_ADJUSTMENT',
  RESET_BALANCE_ADJUSTMENT: 'RESET_BALANCE_ADJUSTMENT',
  EXPORT_BALANCE_ADJUSTMENT: 'EXPORT_BALANCE_ADJUSTMENT',

  GET_SESSIONS: 'GET_SESSIONS',
  SAVE_SESSIONS: 'SAVE_SESSIONS',
  RESET_SESSIONS: 'RESET_SESSIONS',
  EXPORT_SESSIONS: 'EXPORT_SESSIONS',

  GET_MIRRORING_SERVER: 'GET_MIRRORING_SERVER',
  SAVE_MIRRORING_SERVER: 'SAVE_MIRRORING_SERVER',
  RESET_MIRRORING_SERVER: 'RESET_MIRRORING_SERVER',
  EXPORT_MIRRORING_SERVER: 'EXPORT_MIRRORING_SERVER',

  GET_SAFE_SERVER: 'GET_SAFE_SERVER',
  SAVE_SAFE_SERVER: 'SAVE_SAFE_SERVER',
  RESET_SAFE_SERVER: 'RESET_SAFE_SERVER',
  EXPORT_SAFE_SERVER: 'EXPORT_SAFE_SERVER',

  GET_CHANGE_NOTIFICATION: 'GET_CHANGE_NOTIFICATION',
  SAVE_CHANGE_NOTIFICATION: 'SAVE_CHANGE_NOTIFICATION',
  RESET_CHANGE_NOTIFICATION: 'RESET_CHANGE_NOTIFICATION',
  EXPORT_CHANGE_NOTIFICATION: 'EXPORT_CHANGE_NOTIFICATION',
  ADD_CHANGE_NOTIFICATION: 'ADD_CHANGE_NOTIFICATION',

  ADD_INACCESSIBILITY_SERVER: 'ADD_INACCESSIBILITY_SERVER',
  DELETE_INACCESSIBILITY_SERVER: 'DELETE_INACCESSIBILITY_SERVER',

  GET_INACCESSIBILITY_SERVER: 'GET_INACCESSIBILITY_SERVER',
  SAVE_INACCESSIBILITY_SERVER: 'SAVE_INACCESSIBILITY_SERVER',
  RESET_INACCESSIBILITY_SERVER: 'RESET_INACCESSIBILITY_SERVER',
  EXPORT_INACCESSIBILITY_SERVER: 'EXPORT_INACCESSIBILITY_SERVER',

  GET_SIGNIFICANT_EVENTS_LARGE_TRANSACTIONS: 'GET_SIGNIFICANT_EVENTS_LARGE_TRANSACTIONS',
  SAVE_SIGNIFICANT_EVENTS_LARGE_TRANSACTIONS: 'SAVE_SIGNIFICANT_EVENTS_LARGE_TRANSACTIONS',
  RESET_SIGNIFICANT_EVENTS_LARGE_TRANSACTIONS: 'RESET_SIGNIFICANT_EVENTS_LARGE_TRANSACTIONS',
  EXPORT_SIGNIFICANT_EVENTS_LARGE_TRANSACTIONS: 'EXPORT_SIGNIFICANT_EVENTS_LARGE_TRANSACTIONS',

  GET_SIGNIFICANT_EVENTS_RESTRICTIONS: 'GET_SIGNIFICANT_EVENTS_RESTRICTIONS',
  SAVE_SIGNIFICANT_EVENTS_RESTRICTIONS: 'SAVE_SIGNIFICANT_EVENTS_RESTRICTIONS',
  RESET_SIGNIFICANT_EVENTS_RESTRICTIONS: 'RESET_SIGNIFICANT_EVENTS_RESTRICTIONS',
  EXPORT_SIGNIFICANT_EVENTS_RESTRICTIONS: 'EXPORT_SIGNIFICANT_EVENTS_RESTRICTIONS',

  GET_JACKPOT_WIN_RATIO: 'GET_JACKPOT_WIN_RATIO',
  SAVE_JACKPOT_WIN_RATIO: 'SAVE_JACKPOT_WIN_RATIO',
  RESET_JACKPOT_WIN_RATIO: 'RESET_JACKPOT_WIN_RATIO',
  EXPORT_JACKPOT_WIN_RATIO: 'EXPORT_JACKPOT_WIN_RATIO',

  GET_JACKPOT_CONFIGURATION: 'GET_JACKPOT_CONFIGURATION',
  SAVE_JACKPOT_CONFIGURATION: 'SAVE_JACKPOT_CONFIGURATION',
  RESET_JACKPOT_CONFIGURATION: 'RESET_JACKPOT_CONFIGURATION',
  EXPORT_JACKPOT_CONFIGURATION: 'EXPORT_JACKPOT_CONFIGURATION',

  GET_PROMOTIONAL_ITEMS: 'GET_PROMOTIONAL_ITEMS',
  SAVE_PROMOTIONAL_ITEMS: 'SAVE_PROMOTIONAL_ITEMS',
  RESET_PROMOTIONAL_ITEMS: 'RESET_PROMOTIONAL_ITEMS',
  EXPORT_PROMOTIONAL_ITEMS: 'EXPORT_PROMOTIONAL_ITEMS',

  GET_TAXATION_DAILY: 'GET_TAXATION_DAILY',
  SAVE_TAXATION_DAILY: 'SAVE_TAXATION_DAILY',
  RESET_TAXATION_DAILY: 'RESET_TAXATION_DAILY',
  EXPORT_TAXATION_DAILY: 'EXPORT_TAXATION_DAILY',

  GET_TAXATION_MONTHLY: 'GET_TAXATION_MONTHLY',
  SAVE_TAXATION_MONTHLY: 'SAVE_TAXATION_MONTHLY',
  RESET_TAXATION_MONTHLY: 'RESET_TAXATION_MONTHLY',
  EXPORT_TAXATION_MONTHLY: 'EXPORT_TAXATION_MONTHLY',

  EXPORT_BETTING_SELF_PROTECTION_REPORT: 'EXPORT_BETTING_SELF_PROTECTION_REPORT',
};

export default RegulatoryActionType;
