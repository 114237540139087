import PropTypes from 'prop-types';
import { Tag, Popover } from 'antd';
import StyledTag, { StyledTagsNumber } from './tag.styled';

const PopoverTag = (props) => {
  const {
    data: { text, count },
  } = props;
  return (
    <Popover
      placement="bottomLeft"
      title="Complainer"
      content="Description of the hashtag Description of the hashtag"
    >
      <StyledTag>
        <Tag>
          {text}
          {count && <StyledTagsNumber>12</StyledTagsNumber>}
        </Tag>
      </StyledTag>
    </Popover>
  );
};

PopoverTag.propTypes = {
  data: PropTypes.object.isRequired,
};

export default PopoverTag;
