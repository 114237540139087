const BetStatusCorrectionActionType = {
  GET_SINGLE_BET_STATUS_CORRECTIONS: 'GET_SINGLE_BET_STATUS_CORRECTIONS',
  SAVE_SINGLE_BET_STATUS_CORRECTIONS: 'SAVE_SINGLE_BET_STATUS_CORRECTIONS',
  RESET_SINGLE_BET_STATUS_CORRECTIONS: 'RESET_SINGLE_BET_STATUS_CORRECTIONS',

  GET_BULK_BET_STATUS_CORRECTIONS: 'GET_BULK_BET_STATUS_CORRECTIONS',
  SAVE_BULK_BET_STATUS_CORRECTIONS: 'SAVE_BULK_BET_STATUS_CORRECTIONS',
  RESET_BULK_BET_STATUS_CORRECTIONS: 'RESET_BULK_BET_STATUS_CORRECTIONS',

  CREATE_SINGLE_BET_STATUS_CORRECTION: 'CREATE_SINGLE_BET_STATUS_CORRECTION',
  CREATE_BULK_BET_STATUS_CORRECTION: 'CREATE_BULK_BET_STATUS_CORRECTION',

  EXPORT_BET_STATUS_CORRECTIONS: 'EXPORT_BET_STATUS_CORRECTIONS',

  GET_BET_STATUS_CORRECTIONS_STATISTICS: 'GET_BET_STATUS_CORRECTIONS_STATISTICS',
  SAVE_BET_STATUS_CORRECTIONS_STATISTICS: 'SAVE_BET_STATUS_CORRECTIONS_STATISTICS',
  RESET_BET_STATUS_CORRECTIONS_STATISTICS: 'RESET_BET_STATUS_CORRECTIONS_STATISTICS',
};

export default BetStatusCorrectionActionType;
