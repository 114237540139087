import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Pages from 'components/layout/Pages';
import { selectBreadCrumbParams } from 'selectors';
import { setActiveMenu, resetBreadCrumbParams } from 'actions/setting/settingActionCreator';

const mapStateToProps = ({ settings }) => ({
  breadcrumbParams: selectBreadCrumbParams(settings),
});

const mapDispatchToProps = (dispatch) => bindActionCreators(
  {
    setActiveMenu,
    resetBreadCrumbParams,
  },
  dispatch,
);

export default connect(mapStateToProps, mapDispatchToProps)(Pages);
