import qs from 'qs';
import {
  takeLatest, all, put, call,
} from 'redux-saga/effects';
import PreviousAccountActionType from 'actions/previousAccount/previousAccountActionType';
import NetworkService from 'services/networkService';
import { changeLoadingState, setErrorMessage } from 'actions/setting/settingActionCreator';
import { savePlayerPreviousAccounts, savePlayerPreviousAccountsInfo, savePreviousAccountsGroups } from 'actions/previousAccount/previousAccountActionCreator';

import { AppConstants } from 'constants/index';
import { getError } from 'utils/helpers';

function* getPlayerPreviousAccounts({ payload }) {
  try {
    const options = {
      params: payload.params,
      paramsSerializer: (params) => qs.stringify(params),
    };
    yield put(changeLoadingState(true));
    const { PlayersAdmin, Player, PreviousAccounts } = AppConstants.api;
    const { data } = yield call(NetworkService.makeAPIGetRequest, [PlayersAdmin, Player, PreviousAccounts, payload.playerId], options);
    yield put(changeLoadingState(false));
    yield put(savePlayerPreviousAccounts(data));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getPreviousAccountsGroups({ payload }) {
  try {
    const options = {
      params: payload.params,
      paramsSerializer: (params) => qs.stringify(params),
    };
    yield put(changeLoadingState(true));
    const { PlayersAdmin, Player, PreviousAccountGroups } = AppConstants.api;
    const { data } = yield call(NetworkService.makeAPIGetRequest, [PlayersAdmin, Player, PreviousAccountGroups], options);
    yield put(changeLoadingState(false));
    yield put(savePreviousAccountsGroups(data));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getPlayerPreviousAccountsInfo({ payload }) {
  const { isLoading, playerId } = payload;
  try {
    if (isLoading) {
      yield put(changeLoadingState(true));
    }

    const { PlayersAdmin, Player, PreviousAccountsInfo } = AppConstants.api;
    const { data } = yield call(NetworkService.makeAPIGetRequest, [PlayersAdmin, Player, playerId, PreviousAccountsInfo]);

    if (isLoading) {
      yield put(changeLoadingState(false));
    }

    yield put(savePlayerPreviousAccountsInfo(data));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    if (isLoading) {
      yield put(changeLoadingState(false));
    }
  }
}

export default function* previousAccountSaga() {
  yield all([
    takeLatest(PreviousAccountActionType.GET_PREVIOUS_ACCOUNTS_GROUPS, getPreviousAccountsGroups),
    takeLatest(PreviousAccountActionType.GET_PLAYER_PREVIOUS_ACCOUNTS, getPlayerPreviousAccounts),
    takeLatest(PreviousAccountActionType.GET_PLAYER_PREVIOUS_ACCOUNTS_INFO, getPlayerPreviousAccountsInfo),
  ]);
}
