import {
  put, call, takeLatest, all, select,
} from 'redux-saga/effects';
import qs from 'qs';
import {
  getUsersSuccess,
  changeUserLoadingState,
  getUserPermissionsSuccess,
  saveUserConfigs,
  getUserPartnerAccessesSuccess,
  getUserBrandAccessesSuccess,
  getUserBrandsByPartnerSuccess,
  getUserPositionsSuccess,
  getSupervisorUsersSuccess,
  saveUser,
} from 'actions/user/userActionCreator';
import {
  setSuccessMessage, setErrorMessage, changeLoadingState, changeEndedStatus,
} from 'actions/setting/settingActionCreator';
import { LocalStorageService, NetworkService } from 'services';
import UserActionType from 'actions/user/userActionType';
import { selectUserConfigs } from 'selectors';
import { getUserSuccess, setAuthenticated } from 'actions/auth/authActionCreator';
import i18n from 'services/i18n';
import { getError, getMessage } from 'utils/helpers';
import { AppConstants } from 'constants/index';
import Config from 'config';
import mime from 'mimetypes';
import { downloadFile } from 'utils/utils';

const { isBrRegulationAudit } = Config;

function* getUsers({ payload }) {
  try {
    yield put(changeLoadingState(true));
    const { Admin, User, GetByPartnerId } = AppConstants.api;
    const response = yield call(NetworkService.makeAPIGetRequest, [Admin, User, GetByPartnerId], {
      ...payload,
      data: {},
      paramsSerializer: (params) => qs.stringify(params),
    });
    yield put(getUsersSuccess(response.data));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getUser({ payload }) {
  try {
    yield put(changeLoadingState(true));
    const { Admin, User } = AppConstants.api;
    const { data } = yield call(NetworkService.makeAPIGetRequest, [Admin, User, payload.id]);
    yield put(changeLoadingState(false));
    if (payload.isAuth) {
      yield put(getUserSuccess(data));
    } else {
      yield put(saveUser(data));
    }
  } catch (err) {
    if (err) {
      const error = getError(err, true);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* addUser({ payload }) {
  try {
    yield put(changeLoadingState(true));
    const { Admin, User, AdminUser } = AppConstants.api;
    if (payload.data.partnerId) {
      yield call(NetworkService.makeAPIPostRequest, [Admin, User], payload);
    } else {
      yield call(NetworkService.makeAPIPostRequest, [Admin, User, AdminUser], payload);
    }
    yield put(changeLoadingState(false));
    yield put(changeEndedStatus(true));
    yield put(setSuccessMessage(i18n.t('notification:userAddSuccess')));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* editUser({ payload }) {
  try {
    yield put(changeLoadingState(true));
    const { Admin, User } = AppConstants.api;
    const { userId, data } = payload;
    yield call(NetworkService.makeAPIPutRequest, [Admin, User, userId], {
      data,
    });
    yield put(changeLoadingState(false));
    const name = `${data.firstName} ${data.lastName}`;
    yield put(setSuccessMessage(getMessage(i18n.t('lAccount'), i18n.t('notification:slUpdated'), name)));
    yield put(changeEndedStatus(true));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* deleteUser({ payload }) {
  try {
    const { Admin, User } = AppConstants.api;
    const { id, name } = payload;
    yield put(changeLoadingState(true));
    yield call(NetworkService.makeAPIDeleteRequest, [Admin, User, id], {
      data: {},
    });
    yield put(changeEndedStatus(true));
    yield put(changeLoadingState(false));
    yield put(setSuccessMessage(getMessage(i18n.t('lAccount'), i18n.t('notification:slDeleted'), name)));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* changeActivityStatus({ payload }) {
  try {
    yield put(changeLoadingState(true));
    const { Admin, User, Status } = AppConstants.api;
    const {
      id, isActive, firstName, lastName,
    } = payload.data;
    const data = { isActive: !isActive };
    yield call(NetworkService.makeAPIPutRequest, [Admin, User, Status, id], {
      data,
    });
    yield put(changeEndedStatus(true));
    const name = `${firstName} ${lastName}`;
    yield put(setSuccessMessage(getMessage(i18n.t('userManagement:lActivityStatus'), i18n.t('notification:slUpdated'), name)));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getUserPermissions({ payload }) {
  try {
    const { userId } = payload.data;
    const { Admin, User, Permissions } = AppConstants.api;
    yield put(changeUserLoadingState(true));
    const { data } = yield call(NetworkService.makeAPIGetRequest, [Admin, User, Permissions], {
      params: { userId },
    });
    yield put(getUserPermissionsSuccess(data));
    yield put(changeUserLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err, true);
      yield put(setErrorMessage(error));
    }
    yield put(changeUserLoadingState(false));
  }
}

function* assignPermissions({ payload }) {
  try {
    const { userId, data, name } = payload;
    const { Admin, User, AssignPermissions } = AppConstants.api;
    yield put(changeLoadingState(true));
    yield call(NetworkService.makeAPIPutRequest, [Admin, User, AssignPermissions, userId], { data });
    yield put(changeEndedStatus(true));
    yield put(changeLoadingState(false));
    yield put(setSuccessMessage(getMessage(i18n.t('userManagement:lPermissions'), i18n.t('notification:plUpdated'), name)));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getUserConfigs() {
  try {
    const { Admin, UserConfig } = AppConstants.api;
    yield put(changeUserLoadingState(true));
    const { data } = yield call(NetworkService.makeAPIGetRequest, [Admin, UserConfig]);
    yield put(changeUserLoadingState(false));
    yield put(saveUserConfigs(data));
    LocalStorageService.set('userConfigs', data);
  } catch (err) {
    // combined call with auth
    LocalStorageService.remove('auth');
    yield put(setAuthenticated(false));
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeUserLoadingState(false));
  }
}

function* createOrEditUserConfig({ payload }) {
  const { isLoading } = payload;
  try {
    const { key, configValue } = payload.data;
    const { Admin, UserConfig } = AppConstants.api;
    if (isLoading) {
      yield put(changeLoadingState(true));
    }
    if (!isBrRegulationAudit) {
      yield call(NetworkService.makeAPIPostRequest, [Admin, UserConfig], payload);
    }
    if (isLoading) {
      yield put(changeLoadingState(false));
    }
    const state = yield select();
    const config = selectUserConfigs(state.users);
    const userConfig = { ...config, [key]: configValue };
    yield put(saveUserConfigs(userConfig));
    LocalStorageService.set('userConfigs', userConfig);
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    if (isLoading) {
      yield put(changeLoadingState(false));
    }
  }
}

function* deleteUserConfig({ payload }) {
  try {
    const { key } = payload;
    const { Admin, UserConfig } = AppConstants.api;
    yield put(changeLoadingState(true));
    yield call(NetworkService.makeAPIDeleteRequest, [Admin, UserConfig, key]);
    yield put(changeLoadingState(false));
    yield put(setSuccessMessage(getMessage(i18n.t('title:userConfig'), i18n.t('notification:slDeleted'))));
    yield put(changeEndedStatus(true));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getUserPartnerAccesses({ payload }) {
  try {
    const { Admin, User, PartnerAccesses } = AppConstants.api;
    const { userId } = payload;
    const options = {
      params: { userId },
    };
    yield put(changeUserLoadingState(true));
    const { data } = yield call(NetworkService.makeAPIGetRequest, [Admin, User, PartnerAccesses], options);
    yield put(getUserPartnerAccessesSuccess(data));
    yield put(changeUserLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeUserLoadingState(false));
  }
}

function* getUserBrandAccesses({ payload }) {
  try {
    const { Admin, User, BrandAccesses } = AppConstants.api;
    const { userId } = payload;
    const options = {
      params: { userId },
    };
    yield put(changeUserLoadingState(true));
    const { data } = yield call(NetworkService.makeAPIGetRequest, [Admin, User, BrandAccesses], options);
    yield put(getUserBrandAccessesSuccess(data));
    yield put(changeUserLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeUserLoadingState(false));
  }
}

function* getUserBrandsByPartner({ payload }) {
  const { isLoading, partnerId } = payload;
  try {
    const { Admin, Account, Brands } = AppConstants.api;
    const options = {
      params: { partnerId },
    };
    if (isLoading) {
      yield put(changeUserLoadingState(true));
    }
    const { data } = yield call(NetworkService.makeAPIGetRequest, [Admin, Account, Brands], options);
    yield put(getUserBrandsByPartnerSuccess(data));
    if (isLoading) {
      yield put(changeUserLoadingState(false));
    }
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    if (isLoading) {
      yield put(changeUserLoadingState(false));
    }
  }
}

function* getUserPositions({ payload }) {
  const { isLoading } = payload;
  try {
    const { Admin, User, Positions } = AppConstants.api;

    if (isLoading) {
      yield put(changeUserLoadingState(true));
    }
    const { data } = yield call(NetworkService.makeAPIGetRequest, [Admin, User, Positions]);
    yield put(getUserPositionsSuccess(data));

    if (isLoading) {
      yield put(changeUserLoadingState(false));
    }
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    if (isLoading) {
      yield put(changeUserLoadingState(false));
    }
  }
}

function* getSupervisorUsers({ payload }) {
  try {
    const options = {
      params: payload.params,
      paramsSerializer: (params) => qs.stringify(params),
    };
    const { Admin, User, Supervisors } = AppConstants.api;
    yield put(changeUserLoadingState(true));
    const { data } = yield call(NetworkService.makeAPIGetRequest, [Admin, User, Supervisors, payload.partnerId], options);
    yield put(getSupervisorUsersSuccess(data));
    yield put(changeUserLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeUserLoadingState(false));
  }
}

function* deleteUsers({ payload }) {
  try {
    const { data } = payload;
    yield put(changeLoadingState(true));
    const { Admin, User, Bulk } = AppConstants.api;
    yield call(NetworkService.makeAPIDeleteRequest, [Admin, User, Bulk], data);
    yield put(changeEndedStatus(true));
    yield put(setSuccessMessage(getMessage(i18n.t('users'), i18n.t('notification:plDeleted'))));
    yield put(changeLoadingState(false));
  } catch (err) {
    yield put(changeLoadingState(false));
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
  }
}

function* editUsersActivityStatus({ payload }) {
  try {
    yield put(changeLoadingState(true));
    const { Admin, User, StatusBulk } = AppConstants.api;
    const { data } = payload;
    yield call(NetworkService.makeAPIPutRequest, [Admin, User, StatusBulk], {
      data,
    });
    yield put(changeEndedStatus(true));
    yield put(setSuccessMessage(getMessage(i18n.t('users'), i18n.t(data.isActive ? 'notification:plActivated' : 'notification:plDeactivated'))));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* editUsers({ payload }) {
  try {
    yield put(changeLoadingState(true));
    const { Admin, User, EditInfoBulk } = AppConstants.api;
    const { data } = payload;
    yield call(NetworkService.makeAPIPutRequest, [Admin, User, EditInfoBulk], {
      data,
    });
    yield put(changeEndedStatus(true));
    yield put(setSuccessMessage(getMessage(i18n.t('users'), i18n.t('notification:plUpdated'))));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* bulkAssignPermissions({ payload }) {
  try {
    const { data } = payload;
    const { Admin, User, AssignPermissionsBulk } = AppConstants.api;
    yield put(changeLoadingState(true));
    yield call(NetworkService.makeAPIPutRequest, [Admin, User, AssignPermissionsBulk], { data });
    yield put(setSuccessMessage(getMessage(i18n.t('userManagement:permissionsOfUsers'), i18n.t('notification:plUpdated'))));
    yield put(changeEndedStatus(true));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* resendQRCodesByBulk({ payload }) {
  try {
    const { data } = payload;
    const options = {
      data,
    };
    const isSingular = data?.data?.length === 1;
    const { Admin, Account, ResendTFABulk } = AppConstants.api;
    yield put(changeLoadingState(true));
    yield call(NetworkService.makeAPIPostRequest, [Admin, Account, ResendTFABulk], options);
    yield put(setSuccessMessage(getMessage(i18n.t(isSingular ? 'userManagement:qrCode' : 'userManagement:qrCodes'), i18n.t(isSingular ? 'notification:slSent' : 'notification:plSent'))));
    yield put(changeEndedStatus(true));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* unlockUser({ payload }) {
  try {
    const { userId, name } = payload;
    const { Admin, User, Unlock } = AppConstants.api;
    yield put(changeLoadingState(true));
    yield call(NetworkService.makeAPIPutRequest, [Admin, User, Unlock, userId]);
    yield put(setSuccessMessage(getMessage(i18n.t('lAccount'), i18n.t('notification:slUnlocked'), name)));
    yield put(changeEndedStatus(true));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* exportUsers({ payload }) {
  try {
    const options = {
      params: payload.params,
      paramsSerializer: (params) => qs.stringify(params, { allowDots: true }),
      responseType: 'blob',
    };
    yield put(changeLoadingState(true));
    const { Admin, User, ExportByPartnerId } = AppConstants.api;
    const { headers, data } = yield call(NetworkService.makeAPIGetRequest, [Admin, User, ExportByPartnerId], options);
    downloadFile(data, i18n.t('title:users'), mime.detectExtension(headers['content-type']));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

export default function* userSaga() {
  yield all([
    takeLatest(UserActionType.ADD_USER, addUser),
    takeLatest(UserActionType.EDIT_USER, editUser),
    takeLatest(UserActionType.DELETE_USER, deleteUser),
    takeLatest(UserActionType.GET_USERS, getUsers),
    takeLatest(UserActionType.CHANGE_ACTIVITY_STATUS, changeActivityStatus),
    takeLatest(UserActionType.GET_USER_PERMISSIONS, getUserPermissions),
    takeLatest(UserActionType.USER_ASSIGN_PERMISSIONS, assignPermissions),
    takeLatest(UserActionType.GET_USER_CONFIGS, getUserConfigs),
    takeLatest(UserActionType.CREATE_OR_EDIT_USER_CONFIG, createOrEditUserConfig),
    takeLatest(UserActionType.DELETE_USER_CONFIG, deleteUserConfig),
    takeLatest(UserActionType.GET_USER_PARTNER_ACCESSES, getUserPartnerAccesses),
    takeLatest(UserActionType.GET_USER_BRAND_ACCESSES, getUserBrandAccesses),
    takeLatest(UserActionType.GET_USER_BRANDS_BY_PARTNER, getUserBrandsByPartner),
    takeLatest(UserActionType.GET_USER_POSITIONS, getUserPositions),
    takeLatest(UserActionType.GET_SUPERVISOR_USERS, getSupervisorUsers),
    takeLatest(UserActionType.GET_USER, getUser),
    takeLatest(UserActionType.DELETE_USERS, deleteUsers),
    takeLatest(UserActionType.EDIT_USERS_ACTIVITY_STATUS, editUsersActivityStatus),
    takeLatest(UserActionType.EDIT_USERS, editUsers),
    takeLatest(UserActionType.BULK_ASSIGN_PERMISSIONS, bulkAssignPermissions),
    takeLatest(UserActionType.RESEND_QR_CODES_BY_BULK, resendQRCodesByBulk),
    takeLatest(UserActionType.UNLOCK_USER, unlockUser),
    takeLatest(UserActionType.EXPORT_USERS, exportUsers),
  ]);
}
