const DashboardActionType = {
  GET_DASHBOARDS: 'GET_DASHBOARDS',
  SAVE_DASHBOARDS: 'SAVE_DASHBOARDS',
  RESET_DASHBOARDS: 'RESET_DASHBOARDS',

  CREATE_DASHBOARD: 'CREATE_DASHBOARD',
  EDIT_DASHBOARD: 'EDIT_DASHBOARD',
  DELETE_DASHBOARD: 'DELETE_DASHBOARD',

  GET_WIDGETS: 'GET_WIDGETS',
  SAVE_WIDGETS: 'SAVE_WIDGETS',
  RESET_WIDGETS: 'RESET_WIDGETS',

  CREATE_WIDGET: 'CREATE_WIDGET',
  EDIT_WIDGET: 'EDIT_WIDGET',
  DELETE_WIDGET: 'DELETE_WIDGET',

  SAVE_LAST_REQUESTED_WIDGET: 'SAVE_LAST_REQUESTED_WIDGET',
  RESET_LAST_REQUESTED_WIDGET: 'RESET_LAST_REQUESTED_WIDGET',

  GET_WIDGET_DATA: 'GET_WIDGET_DATA',

  SAVE_WIDGET_DATA: 'SAVE_WIDGET_DATA',

  RESET_WIDGETS_DATA: 'RESET_WIDGETS_DATA',

  SAVE_WIDGET_LOADING: 'SAVE_WIDGET_LOADING',

  SAVE_APPLY_TO_WIDGET: 'SAVE_APPLY_TO_WIDGET',
  RESET_APPLY_TO_WIDGET: 'RESET_APPLY_TO_WIDGET',

  CHANGE_DASHBOARD_ENDED_STATUS: 'CHANGE_DASHBOARD_ENDED_STATUS',
};

export default DashboardActionType;
