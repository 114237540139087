const GameManagementActionType = {
  GET_GAMES: 'GET_GAMES',
  SAVE_GAMES: 'SAVE_GAMES',
  RESET_GAMES: 'RESET_GAMES',
  EXPORT_GAMES: 'EXPORT_GAMES',

  GET_GAME: 'GET_GAME',
  SAVE_GAME: 'SAVE_GAME',
  RESET_GAME: 'RESET_GAME',

  SET_GAME_MAINTENANCE: 'SET_GAME_MAINTENANCE',
  RESET_GAME_MAINTENANCE: 'RESET_GAME_MAINTENANCE',

  EDIT_GAME: 'EDIT_GAME',

  GET_GAME_THEMES_RESOURCE: 'GET_GAME_THEMES_RESOURCE',
  SAVE_GAME_THEMES_RESOURCE: 'SAVE_GAME_THEMES_RESOURCE',
  RESET_GAME_THEMES_RESOURCE: 'RESET_GAME_THEMES_RESOURCE',

  SET_GAMES_MAINTENANCE: 'SET_GAMES_MAINTENANCE',
  RESET_GAMES_MAINTENANCE: 'RESET_GAMES_MAINTENANCE',

  EDIT_GAMES: 'EDIT_GAMES',

  GET_GAME_GROUPS_RESOURCE: 'GET_GAME_GROUPS_RESOURCE',
  SAVE_GAME_GROUPS_RESOURCE: 'SAVE_GAME_GROUPS_RESOURCE',
  RESET_GAME_GROUPS_RESOURCE: 'RESET_GAME_GROUPS_RESOURCE',

  GET_GAME_BADGES_RESOURCE: 'GET_GAME_BADGES_RESOURCE',
  SAVE_GAME_BADGES_RESOURCE: 'SAVE_GAME_BADGES_RESOURCE',
  RESET_GAME_BADGES_RESOURCE: 'RESET_GAME_BADGES_RESOURCE',

  GET_GAME_GROUPS: 'GET_GAME_GROUPS',
  SAVE_GAME_GROUPS: 'SAVE_GAME_GROUPS',
  RESET_GAME_GROUPS: 'RESET_GAME_GROUPS',

  GET_CSV_GAMES: 'GET_CSV_GAMES',
  SAVE_CSV_GAMES: 'SAVE_CSV_GAMES',
  RESET_CSV_GAMES: 'RESET_CSV_GAMES',
  GET_CSV_ORDERED_GAMES: 'GET_CSV_ORDERED_GAMES',

  CHANGE_GAME_MANAGEMENT_ENDED_STATUS: 'CHANGE_GAME_MANAGEMENT_ENDED_STATUS',

  GET_GAME_GROUP: 'GET_GAME_GROUP',
  SAVE_GAME_GROUP: 'SAVE_GAME_GROUP',
  RESET_GAME_GROUP: 'RESET_GAME_GROUP',

  CREATE_GAME_GROUP: 'CREATE_GAME_GROUP',
  DELETE_GAME_GROUP: 'DELETE_GAME_GROUP',
  EDIT_GAME_GROUP: 'EDIT_GAME_GROUP',

  CHANGE_GAME_GROUP_STATUS: 'CHANGE_GAME_GROUP_STATUS',

  CHANGE_GAME_STATUS: 'CHANGE_GAME_STATUS',

  GET_GAME_TYPES: 'GET_GAME_TYPES',
  SAVE_GAME_TYPES: 'SAVE_GAME_TYPES',
  RESET_GAME_TYPES: 'RESET_GAME_TYPES',

  GET_GAME_TYPE: 'GET_GAME_TYPE',
  SAVE_GAME_TYPE: 'SAVE_GAME_TYPE',
  RESET_GAME_TYPE: 'RESET_GAME_TYPE',

  CREATE_GAME_TYPE: 'CREATE_GAME_TYPE',
  EDIT_GAME_TYPE: 'EDIT_GAME_TYPE',
  DELETE_GAME_TYPE: 'DELETE_GAME_TYPE',
  CHANGE_GAME_TYPE_STATE: 'CHANGE_GAME_TYPE_STATE',

  DELETE_GAME_TYPE_GAMES: 'DELETE_GAME_TYPE_GAMES',

  GET_CSV_GAME_TYPE_GAMES: 'GET_CSV_GAME_TYPE_GAMES',
  SAVE_CSV_GAME_TYPE_GAMES: 'SAVE_CSV_GAME_TYPE_GAMES',
  RESET_CSV_GAME_TYPE_GAMES: 'RESET_CSV_GAME_TYPE_GAMES',
};

export default GameManagementActionType;
