const BrandMarketActionType = {
  GET_BRAND_MARKET_GENERAL_SETTINGS: 'GET_BRAND_MARKET_GENERAL_SETTINGS',
  SAVE_BRAND_MARKET_GENERAL_SETTINGS: 'SAVE_BRAND_MARKET_GENERAL_SETTINGS',
  RESET_BRAND_MARKET_GENERAL_SETTINGS: 'RESET_BRAND_MARKET_GENERAL_SETTINGS',
  EDIT_BRAND_MARKET_GENERAL_SETTINGS: 'EDIT_BRAND_MARKET_GENERAL_SETTINGS',

  GET_BRAND_VERIFICATION_CONFIGURATIONS: 'GET_BRAND_VERIFICATION_CONFIGURATIONS',
  SAVE_BRAND_VERIFICATION_CONFIGURATIONS: 'SAVE_BRAND_VERIFICATION_CONFIGURATIONS',
  RESET_BRAND_VERIFICATION_CONFIGURATIONS: 'RESET_BRAND_VERIFICATION_CONFIGURATIONS',
  EDIT_BRAND_VERIFICATION_CONFIGURATIONS: 'EDIT_BRAND_VERIFICATION_CONFIGURATIONS',

  GET_BRAND_VERIFICATION_DOCUMENT_TYPES: 'GET_BRAND_VERIFICATION_DOCUMENT_TYPES',
  SAVE_BRAND_VERIFICATION_DOCUMENT_TYPES: 'SAVE_BRAND_VERIFICATION_DOCUMENT_TYPES',
  RESET_BRAND_VERIFICATION_DOCUMENT_TYPES: 'RESET_BRAND_VERIFICATION_DOCUMENT_TYPES',
  EDIT_BRAND_VERIFICATION_DOCUMENT_TYPES: 'EDIT_BRAND_VERIFICATION_DOCUMENT_TYPES',

  GET_BRAND_VERIFICATION_PERIOD: 'GET_BRAND_VERIFICATION_PERIOD',
  SAVE_BRAND_VERIFICATION_PERIOD: 'SAVE_BRAND_VERIFICATION_PERIOD',
  RESET_BRAND_VERIFICATION_PERIOD: 'RESET_BRAND_VERIFICATION_PERIOD',
  ADD_BRAND_VERIFICATION_PERIOD: 'ADD_BRAND_VERIFICATION_PERIOD',

  GET_BRAND_UNVERIFIED_PLAYERS_DEPOSIT_LIMITS: 'GET_BRAND_UNVERIFIED_PLAYERS_DEPOSIT_LIMITS',
  SAVE_BRAND_UNVERIFIED_PLAYERS_DEPOSIT_LIMITS: 'SAVE_BRAND_UNVERIFIED_PLAYERS_DEPOSIT_LIMITS',
  RESET_BRAND_UNVERIFIED_PLAYERS_DEPOSIT_LIMITS: 'RESET_BRAND_UNVERIFIED_PLAYERS_DEPOSIT_LIMITS',
  EDIT_BRAND_UNVERIFIED_PLAYERS_DEPOSIT_LIMITS: 'EDIT_BRAND_UNVERIFIED_PLAYERS_DEPOSIT_LIMITS',
};

export default BrandMarketActionType;
