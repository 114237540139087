import OperatorLimitActionType from 'actions/operatorLimit/operatorLimitActionType';

const initialState = {
  largeTransactionsLimitsByBrand: [],
  largeTransactionLogs: {},
  playerLimits: {},
  periodsLimits: {},
};

const operatorLimitReducer = (state = initialState, { type, payload }) => {
  switch (type) {
  case OperatorLimitActionType.SAVE_LARGE_TRANSACTIONS_LIMITS_BY_BRAND:
    return {
      ...state,
      largeTransactionsLimitsByBrand: payload.data,
    };
  case OperatorLimitActionType.RESET_LARGE_TRANSACTIONS_LIMITS_BY_BRAND:
    return {
      ...state,
      largeTransactionsLimitsByBrand: [],
    };
  case OperatorLimitActionType.SAVE_LARGE_TRANSACTION_LIMITS_LOGS:
    return {
      ...state,
      largeTransactionLogs: payload.data,
    };
  case OperatorLimitActionType.RESET_LARGE_TRANSACTION_LIMITS_LOGS:
    return {
      ...state,
      largeTransactionLogs: {},
    };
  case OperatorLimitActionType.SAVE_BRAND_PLAYER_LIMITS:
    return {
      ...state,
      playerLimits: payload.data,
    };
  case OperatorLimitActionType.RESET_BRAND_PLAYER_LIMITS:
    return {
      ...state,
      playerLimits: {},
    };
  case OperatorLimitActionType.SAVE_PERIODS_LIMITS:
    return {
      ...state,
      periodsLimits: payload.data,
    };
  case OperatorLimitActionType.RESET_PERIODS_LIMITS:
    return {
      ...state,
      periodsLimits: {},
    };
  default:
    return state;
  }
};

export default operatorLimitReducer;
