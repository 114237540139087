import { Spin } from 'antd';
import styled from 'styled-components';
import { LoadingOutlined } from '@ant-design/icons';
import { media, getSidebarSizes } from 'utils/styledHelpers';

const { collapsedWidth, width } = getSidebarSizes();

const StyledSpinnerWrapper = styled.div`
  // Hide all loader component if is not in loading state
  &:not(.spin-loading) {
    display: none;
  }

  // Prevent mouse actions in modal when loading
  &.spin-loading ~ .ant-modal-root .ant-modal-content {
    pointer-events: none;
  }
`;
const StyledSpin = styled(Spin)`
  background-color: #cccccc10;
  height: 100%;
  top: 0;
  overflow: hidden;
  z-index: 1001;
  &.ant-spin-spinning {
    position: fixed;
    width: calc(100% - ${(props) => (props.collapsed ? collapsedWidth : width)});
    height: calc(100% - 3.43rem);
    left: ${(props) => (props.collapsed ? collapsedWidth : width)};
    top: 3.43rem;
    display: flex;
    align-items: center;
    justify-content: center;
    pointer-events: none;
    z-index: 11111;

    ${media.sm`
      width: 100%;
      left: 0;
    `}
  }
`;
const StyledSpinCards = styled(Spin)`
  background: ${(props) => props.theme.colors.cardBg}08;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  position: absolute;
  z-index: 1;
  display: ${(props) => (props.spinning ? 'flex' : 'none')};
  left: 0;
  top: 0;
`;

const StyledAuthSpin = styled(StyledSpin)`
  &.ant-spin-spinning {
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    pointer-events: auto;
  }
`;

const StyledPreventClickOnLoading = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2000;
`;

const StyledLoadingOutlined = styled(LoadingOutlined)`
  font-size: ${(props) => props.size || '1.71rem'};
`;

export {
  StyledSpinnerWrapper, StyledSpin, StyledAuthSpin, StyledSpinCards, StyledPreventClickOnLoading, StyledLoadingOutlined,
};
