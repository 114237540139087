import BonusActionType from 'actions/bonus/bonusActionType';

const initialState = {
  casinoBonus: {},
  spinBonus: {},
  sportBonus: {},
  specialBonus: {},
  casinoBonuses: {
    data: [],
    total: 0,
  },
  spinBonuses: {
    data: [],
    total: 0,
  },
  sportBonuses: {
    data: [],
    total: 0,
  },
  specialBonuses: {
    data: [],
    total: 0,
  },

  playerCasinoBonuses: {
    data: [],
    total: 0,
  },
  playerSpinBonuses: {
    data: [],
    total: 0,
  },
  playerSportBonuses: {
    data: [],
    total: 0,
  },
  playerSpecialBonuses: {
    data: [],
    total: 0,
  },
  casinoBonusStatistics: {
    data: [],
    total: 0,
  },
  spinBonusStatistics: {
    data: [],
    total: 0,
  },
  sportBonusStatistics: {
    data: [],
    total: 0,
  },
  specialBonusStatistics: {
    data: [],
    total: 0,
  },
  content: {},
};

const bonusReducer = (state = initialState, { type, payload }) => {
  switch (type) {
  case BonusActionType.SAVE_CASINO_BONUSES:
    return {
      ...state,
      casinoBonuses: payload.data,
    };
  case BonusActionType.RESET_CASINO_BONUSES:
    return {
      ...state,
      casinoBonuses: {
        data: [],
        total: 0,
      },
    };
  case BonusActionType.SAVE_SPIN_BONUSES:
    return {
      ...state,
      spinBonuses: payload.data,
    };
  case BonusActionType.RESET_SPIN_BONUSES:
    return {
      ...state,
      spinBonuses: {
        data: [],
        total: 0,
      },
    };
  case BonusActionType.SAVE_SPORT_BONUSES:
    return {
      ...state,
      sportBonuses: payload.data,
    };
  case BonusActionType.RESET_SPORT_BONUSES:
    return {
      ...state,
      sportBonuses: {
        data: [],
        total: 0,
      },
    };
  case BonusActionType.SAVE_SPECIAL_BONUSES:
    return {
      ...state,
      specialBonuses: payload.data,
    };
  case BonusActionType.RESET_SPECIAL_BONUSES:
    return {
      ...state,
      specialBonuses: {
        data: [],
        total: 0,
      },
    };
  case BonusActionType.SAVE_PLAYER_CASINO_BONUSES:
    return {
      ...state,
      playerCasinoBonuses: payload.data,
    };
  case BonusActionType.RESET_PLAYER_CASINO_BONUSES:
    return {
      ...state,
      playerCasinoBonuses: {
        data: [],
        total: 0,
      },
    };
  case BonusActionType.SAVE_PLAYER_SPIN_BONUSES:
    return {
      ...state,
      playerSpinBonuses: payload.data,
    };
  case BonusActionType.RESET_PLAYER_SPIN_BONUSES:
    return {
      ...state,
      playerSpinBonuses: {
        data: [],
        total: 0,
      },
    };
  case BonusActionType.SAVE_PLAYER_SPORT_BONUSES:
    return {
      ...state,
      playerSportBonuses: payload.data,
    };
  case BonusActionType.RESET_PLAYER_SPORT_BONUSES:
    return {
      ...state,
      playerSportBonuses: {
        data: [],
        total: 0,
      },
    };
  case BonusActionType.SAVE_PLAYER_SPECIAL_BONUSES:
    return {
      ...state,
      playerSpecialBonuses: payload.data,
    };
  case BonusActionType.RESET_PLAYER_SPECIAL_BONUSES:
    return {
      ...state,
      playerSpecialBonuses: {
        data: [],
        total: 0,
      },
    };
  case BonusActionType.SAVE_CASINO_BONUS_STATISTICS:
    return {
      ...state,
      casinoBonusStatistics: payload.data,
    };
  case BonusActionType.RESET_CASINO_BONUS_STATISTICS:
    return {
      ...state,
      casinoBonusStatistics: {
        data: [],
        total: 0,
      },
    };
  case BonusActionType.SAVE_SPIN_BONUS_STATISTICS:
    return {
      ...state,
      spinBonusStatistics: payload.data,
    };
  case BonusActionType.RESET_SPIN_BONUS_STATISTICS:
    return {
      ...state,
      spinBonusStatistics: {
        data: [],
        total: 0,
      },
    };
  case BonusActionType.SAVE_SPORT_BONUS_STATISTICS:
    return {
      ...state,
      sportBonusStatistics: payload.data,
    };
  case BonusActionType.RESET_SPORT_BONUS_STATISTICS:
    return {
      ...state,
      sportBonusStatistics: {
        data: [],
        total: 0,
      },
    };
  case BonusActionType.SAVE_SPECIAL_BONUS_STATISTICS:
    return {
      ...state,
      specialBonusStatistics: payload.data,
    };
  case BonusActionType.RESET_SPECIAL_BONUS_STATISTICS:
    return {
      ...state,
      specialBonusStatistics: {
        data: [],
        total: 0,
      },
    };
  case BonusActionType.SAVE_CASINO_BONUS:
    return {
      ...state,
      casinoBonus: payload.data,
    };
  case BonusActionType.RESET_CASINO_BONUS:
    return {
      ...state,
      casinoBonus: {},
    };
  case BonusActionType.SAVE_SPIN_BONUS:
    return {
      ...state,
      spinBonus: payload.data,
    };
  case BonusActionType.RESET_SPIN_BONUS:
    return {
      ...state,
      spinBonus: {},
    };
  case BonusActionType.SAVE_SPORT_BONUS:
    return {
      ...state,
      sportBonus: payload.data,
    };
  case BonusActionType.RESET_SPORT_BONUS:
    return {
      ...state,
      sportBonus: {},
    };
  case BonusActionType.SAVE_SPECIAL_BONUS:
    return {
      ...state,
      specialBonus: payload.data,
    };
  case BonusActionType.RESET_SPECIAL_BONUS:
    return {
      ...state,
      specialBonus: {},
    };
  case BonusActionType.SAVE_BONUS_CONTENT:
    return {
      ...state,
      content: payload.data,
    };
  case BonusActionType.RESET_BONUS_CONTENT:
    return {
      ...state,
      content: {},
    };
  default:
    return state;
  }
};

export default bonusReducer;
