import {
  takeLatest, put, all, call,
} from 'redux-saga/effects';
import { saveBetStatusCorrectionsStatistics, saveBulkBetStatusCorrections, saveSingleBetStatusCorrections } from 'actions/betStatusCorrection/betStatusCorrectionActionCreator';
import { AppConstants } from 'constants/index';
import {
  changeLoadingState, setErrorMessage, setSuccessMessage, changeEndedStatus,
} from 'actions/setting/settingActionCreator';
import { i18n, NetworkService } from 'services';
import { getError, getMessage } from 'utils/helpers';
import BetStatusCorrectionActionType from 'actions/betStatusCorrection/betStatusCorrectionActionType';
import qs from 'qs';
import mime from 'mimetypes';
import { downloadFile } from 'utils/utils';

function* getSingleBetStatusCorrections({ payload }) {
  try {
    const { params } = payload;
    const options = {
      params,
      paramsSerializer: (param) => qs.stringify(param),
    };
    const { ReportingAdmin, BetCorrection, Single } = AppConstants.api;
    yield put(changeLoadingState(true));
    const { data } = yield call(NetworkService.makeAPIGetRequest, [ReportingAdmin, BetCorrection, Single], options);
    yield put(changeLoadingState(false));
    yield put(saveSingleBetStatusCorrections(data));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getBulkBetStatusCorrections({ payload }) {
  try {
    const { params } = payload;
    const options = {
      params,
      paramsSerializer: (param) => qs.stringify(param),
    };
    const { ReportingAdmin, BetCorrection, Bulk } = AppConstants.api;
    yield put(changeLoadingState(true));
    const { data } = yield call(NetworkService.makeAPIGetRequest, [ReportingAdmin, BetCorrection, Bulk], options);
    yield put(changeLoadingState(false));
    yield put(saveBulkBetStatusCorrections(data));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* createSingleBetStatusCorrection({ payload }) {
  try {
    const { data } = payload;
    const options = {
      data,
    };
    const { Transaction, BetCorrection, Single } = AppConstants.api;
    yield put(changeLoadingState(true));
    yield call(NetworkService.makeAPIPostRequest, [Transaction, BetCorrection, Single], options);
    yield put(changeLoadingState(false));
    yield put(setSuccessMessage(getMessage(i18n.t('riskManagement:statusCorrection'), i18n.t('notification:slDone'))));
    yield put(changeEndedStatus(true));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* createBulkStatusCorrection({ payload }) {
  try {
    const { data } = payload;
    const options = {
      data,
      headers: { 'content-type': 'multipart/form-data' },
    };
    const { Transaction, BetCorrection, Bulk } = AppConstants.api;
    yield put(changeLoadingState(true));
    yield call(NetworkService.makeAPIPostRequest, [Transaction, BetCorrection, Bulk], options);
    yield put(changeLoadingState(false));
    yield put(setSuccessMessage(i18n.t('notification:statusCorrectionIsInProgress')));
    yield put(changeEndedStatus(true));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getBetStatusCorrectionsStatistics({ payload }) {
  try {
    const { params, id } = payload;
    const options = {
      params,
      paramsSerializer: (param) => qs.stringify(param),
    };
    const { ReportingAdmin, BetCorrection, Bulk } = AppConstants.api;
    yield put(changeLoadingState(true));
    const { data } = yield call(NetworkService.makeAPIGetRequest, [ReportingAdmin, BetCorrection, Bulk, id], options);
    yield put(changeLoadingState(false));
    yield put(saveBetStatusCorrectionsStatistics(data));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* exportBetStatusCorrections({ payload }) {
  try {
    const { id } = payload;
    const options = {
      params: payload.params,
      paramsSerializer: (params) => qs.stringify(params, { allowDots: true }),
      responseType: 'blob',
    };
    const {
      ReportingAdmin, BetCorrection, Bulk, Export,
    } = AppConstants.api;

    yield put(changeLoadingState(true));
    const { headers, data } = yield call(NetworkService.makeAPIGetRequest, [ReportingAdmin, BetCorrection, Bulk, id, Export], options);
    downloadFile(data, i18n.t('riskManagement:failedCorrections'), mime.detectExtension(headers['content-type']));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err, true);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

export default function* betStatusCorrectionSaga() {
  yield all([
    takeLatest(BetStatusCorrectionActionType.GET_SINGLE_BET_STATUS_CORRECTIONS, getSingleBetStatusCorrections),
    takeLatest(BetStatusCorrectionActionType.GET_BULK_BET_STATUS_CORRECTIONS, getBulkBetStatusCorrections),
    takeLatest(BetStatusCorrectionActionType.CREATE_SINGLE_BET_STATUS_CORRECTION, createSingleBetStatusCorrection),
    takeLatest(BetStatusCorrectionActionType.CREATE_BULK_BET_STATUS_CORRECTION, createBulkStatusCorrection),
    takeLatest(BetStatusCorrectionActionType.GET_BET_STATUS_CORRECTIONS_STATISTICS, getBetStatusCorrectionsStatistics),
    takeLatest(BetStatusCorrectionActionType.EXPORT_BET_STATUS_CORRECTIONS, exportBetStatusCorrections),
  ]);
}
