export default {
  en: {
    forgotTFA: 'Please fill your email for recovery protocol.QR code for 2 factor authentication will be sent to your email',
    forgotPassword: "Please, fill your email for recovery protocol. Recovery link will be sent to your account's email",
    setPassword: 'Your password has been set. Now you can log in to admin panel',
    resetPassword: 'Only passwords with minimum of 8 characters including at least 1 number and 1 letter are accepted',
    resendTFASuccess: 'QR code for 2 factor authentication has been sent to your email',
    userAddSuccess: 'User will get email to follow and set password for the account',
    TFARequired: 'The two-factor authentication required',
    unknownError: 'Something went wrong, please try again',
    noSubjectOrBody: 'Subject line and body must be provided for all selected channels and languages',
    noComposeSubject: 'You cannot sent message with empty subject line',
    noComposeContent: 'You cannot send empty messages',
    noComposeContentSubject: 'You cannot send empty messages or with empty subject line',
    recalledMessageSuccess: 'Message recalled by user {{fullName}}',
    sendEmptyMessageError: 'You cannot send empty messages',
    sessionExpired: 'Session Expired',
    atLeastOneCurrency: 'At least 1 currency must be configured',
    atLeastOneGame: 'At least 1 game must be selected',
    atLeastOnePlayer: 'At least 1 player must be selected',
    atLeastOneRanges: 'At least 1 range must be filled',
    atLeastOnePersona: 'At least 1 persona must be selected',
    maxUploadSize: 'File size cannot exceed ',
    maxSumUploadSize: 'Max allowed upload size cannot exceed ',
    maxUploadedFilesSize: 'Maximum size of the selected files cannot exceed ',
    selectedAllRestrictedCountries: 'You cannot select all restricted countries',
    wrongFileFormat: 'Wrong file format',
    playerInList: 'Player is already in the list',
    folderContainFile: 'File is already in this folder',
    folderContainFiles: 'Files are already in this folder',
    brandExist: 'Brand with this name already exists',
    automationRuleExist: 'Rule with this name already exists',
    messageTitleExist: 'Message with this name already exists',
    noAvailablePlayers: 'No available players for selected date range',
    passwordExpired: 'Password expired',
    colorNotEmpty: 'Color cannot be empty',
    tagNotEmpty: 'Tag cannot be empty',
    accountDeleted: 'Your account has been deleted. Now you will be logged out',
    accountDisabled: 'Your account has been disabled. Now you will be logged out',
    atLeastOneVersion: 'At least one version must be selected',
    fileUploaded: 'File has been uploaded',
    notMatchRegexRequirements: '{{name}} file does not match regex requirements',
    notMatchMaxSizeRequirement: '{{name}} file does not match max size requirement',
    copied: 'Copied',
    atLeastOneLimitMustBeUpdated: 'At least 1 limit must be updated',
    noAttachments: 'No attachments',
    tagDeletedFromPlayerAccount: '{{name}} tag has been deleted from the player account',
    bonusCanceledForPlayer: 'Bonus has been canceled for {{name}} player',
    generalMessage: '{{name}} {{model}} {{action}}',
    slUpdated: 'has been updated',
    plUpdated: 'have been updated',
    slDeleted: 'has been deleted',
    slExcluded: 'has been excluded',
    plDeleted: 'have been deleted',
    slActivated: 'has been activated',
    plActivated: 'have been activated',
    slDeactivated: 'has been deactivated',
    plDeactivated: 'have been deactivated',
    slImported: 'has been imported',
    slReset: 'has been reset',
    plReset: 'have been reset',
    slAdded: 'has been added',
    plAdded: 'have been added',
    slDisabled: 'has been disabled',
    plDisabled: 'have been disabled',
    slEnabled: 'has been enabled',
    slCreated: 'has been created',
    plCreated: 'have been created',
    plMoved: 'have been moved',
    plMerged: 'have been merged',
    plTagged: 'have been tagged',
    slUntagged: 'has been untagged',
    plUntagged: 'have been untagged',
    removedFromSegment: '{{name}} has been removed from segment',
    playerAddedToSegment: 'The player has been added to {{name}} segment',
    playerRemovedFromSegment: 'The player has been removed from {{name}} segment',
    slFinished: 'has been finished',
    slConfigured: 'has been configured',
    plSent: 'have been sent',
    plRemoved: 'has been removed',
    slSent: 'has been sent',
    setMarkAttention: 'Mark Attention has been set {{name}}',
    slRemoved: 'has been removed',
    slUnlocked: 'has been unlocked',
    accountBlockedForMessages: 'Account {{action}} for messages',
    slBlocked: 'has been blocked',
    plBlocked: 'have been blocked',
    plUnblocked: 'have been unblocked',
    slUnblocked: 'has been unblocked',
    slChanged: 'has been changed',
    slReactivated: 'has been reactivated',
    changeAccountStatus: 'Account has been {{action}}',
    bulkStatusUpdate: '{{action}} has been performed on selected players',
    slAdjusted: 'has been adjusted',
    plAdjusted: 'have been adjusted',
    slClosed: 'has been closed',
    slAssigned: 'has been assigned',
    plAssigned: 'have been assigned',
    finishedTestPlayersAccountUse: "Test player's account use has been finished",
    adjustedTestPlayersAccountBalance: "Test player's account balance has been adjusted",
    slVerified: 'has been verified',
    plVerified: 'has been verified',
    slUnverified: 'has been unverified',
    slSet: 'has been set',
    slIntegrated: 'has been integrated',
    slSavedAsDraft: 'has been saved as draft',
    slScheduledForDate: 'has been scheduled for {{date}}',
    movedToDraft: 'has been moved to draft',
    plRejected: 'have been rejected',
    playerAccountVerifiedWith: 'Player account has been verified with {{docType}}',
    plApproved: 'have been approved',
    plUploaded: 'have been uploaded',
    plArchived: 'have been archived',
    slStarted: 'has been started',
    slMoved: 'has been moved',
    slResent: 'has been resent',
    playerRemovedFromRestrictionList: '{{name}} player has been removed from restriction list',
    plBlacklisted: 'have been blacklisted',
    plWhiteListed: 'have been whitelisted',
    slBlacklisted: 'has been blacklisted',
    slWhiteListed: 'has been whitelisted',
    slCancelled: 'has been cancelled',
    playerDisconnectedFromBonus: '{{name}} player has been disconnected from bonus',
    playerRemovedFromBonus: '{{name}} player has been removed from bonus',
    playersAddedToBonus: 'The players have been added to the bonus',
    slIssued: 'has been issued',
    slScheduled: 'has been scheduled',
    slRenamed: 'has been renamed',
    slUploaded: 'has been uploaded',
    slConnected: 'has been connected',
    slDisconnected: 'has been disconnected',
    plConfigured: 'have been configured',
    mailSetAsDefault: '{{name}} email has been set as default',
    slRestricted: 'has been restricted',
    plRestricted: 'have been restricted',
    countryDeletedFromRestrictionList: '{{name}} country deleted from restriction list',
    ipAddressDeletedFromRestrictionList: '{{name}} IP address deleted from restriction list',
    playerChangedNotificationPreferences: 'Player changed notification preferences',
    playerNotEligible: 'The player is not eligible to receive a message',
    bodyIsRequired: 'Email body cannot be empty',
    playerCanceledFromBonus: 'Bonus has been canceled for {{name}} player',
    noRecipientMessage: 'No recipient to get message',
    atLeastOneDepositConfig: 'At least 1 deposit config must be configured',
    atLeastOneProvider: 'At least one provider must be selected',
    countOrAtLeastOneAmountConfig: 'Count or at least one currency amount must be configured',
    atLeastOneCurrencyAmountConfig: 'At least one currency amount config must be configured',
    allCurrencyAmountsConfigs: 'All currency amount configs must be configured',
    bonusNameExists: 'Bonus name already exists',
    slPromotionCancelled: 'promotion has been cancelled',
    atLeastOneSuccessPlayer: 'At least one player able to get bonuses must be provided',
    setAsDefault: '{{name}} has been set as default',
    promoCodeExist: 'The promo code already exists',
    dataSyncError: 'Data Synchronization in process',
    atLeastOneTable: 'At least 1 table must be selected',
    plSynchronized: 'have been synchronized',
    slSynchronized: 'has been synchronized',
    lobbyProviderOrderError: 'All providers should have order',
    lobbyProviderGameOrderError: 'All games should have order',
    atLeastOneCurrencyEnabled: 'At least one currency must be enabled',
    machineSectionCannotBeEmpty: "Machine's sections cannot be empty",
    atLeastOneCondition: 'At least one condition must be filled',
    allSectionsMustBeSelectedAndHasProbability: 'All sections must be selected and have probability',
    sumOfProbabilities: 'Sum of probabilities must be 100',
    prizeAndProbabilityConfigure: 'Prize and Probability section must be configure',
    slPublished: 'has been published',
    sectionImageRequired: 'A section image is required',
    titleAndDescriptionRequired: 'The title and the description are required for the Terms and Conditions',
    configureSelectedPrizeItems: 'Please config the selected prize items',
    atLeastOneGroupMustHasLimit: 'At least one group must has limit',
    atLeastOneGroupMustBeConfigured: 'At least one group must be configured',
    atLeastTwoPrize: 'At least two prize must be selected',
    allSectionsMustBeConfigured: 'All sections must be configured',
    mustSelectDefaultPrizeAndProbability: 'Default prize must be selected and has probability greater than 0',
    mustSelectDefaultPrize: 'Default prize must be selected',
    slTimeout: 'is currently on a timeout.',
    testPlayerResetPassword: "The new password has been sent to the user's email address",
    atLeastOneTag: 'At least one tag must be selected',
    groupNameAlreadyExist: 'The group name is already used for the active group. You can not have 2 groups with the same name in active status.',
    atLeastOnePromoCodeMustBeUploaded: 'At least 1 valid promo code must be uploaded',
    emptyFile: 'The file is empty. Please check it again.',
    websitePageBackgroundRequired: "The website's page background is required",
    mobilePageBackgroundRequired: "The mobile's page background is required",
    visibilityConfigurationUpdated: 'Visibility configurations for {{name}} payment method has been updated',
    atLeastOneTemplate: 'At least one template must be selected',
    plEnabled: 'have been enabled',
    atLeastOneContentSection: 'At least 1 of the opened sections should be configured',
    deepLink: 'Deep link',
    deepLinkSection: 'Deep Link Section',
    deepLinkIdOrName: 'Deep Link ID/Name',
    slCompleted: 'has been completed',
    passwordResetLinkSent:
      "A password reset link has successfully been sent to <0>{{email}}</0> email address, please check your inbox. <1/> If you haven't received an email in 5 minutes, check your spam or try to resend the recovery request again",
    atLeastOneFileMustBeUploaded: 'At least one file must be uploaded',
    slDone: 'has been done',
    statusCorrectionIsInProgress: 'Status Correction is in progress',
    mustBeUpdated: '{{fieldName}} must be updated',
    campaignMainLanguageMustBeConfigured: "Campaign content must be configured for brand's main language",
    atLeastOneReleaseNote: 'At least one release note must be configured',
    manageMaintenance: 'Maintenance setup has been completed for {{fieldName}} partner brands.',
    removeMaintenance: 'Maintenance has been removed for {{fieldName}} partner brands.',
    bulkManageMaintenance: 'Maintenance setup for the selected brands has been completed.',
    bulkRemoveMaintenance: 'Maintenance has been removed for the selected brands',
    plEnded: 'have been ended',
    atLeastOnePermissionGroup: 'At least 1 permission group must be selected',
    mustSelectImageGridType: 'Must be selected image grid type',
  },
  es: {
    forgotTFA: 'Rellene su correo electrónico para el protocolo de recuperación. El código QR para la autenticación de 2 factores se enviará a su correo electrónico',
    forgotPassword: 'Rellene su correo electrónico para el protocolo de recuperación. El enlace de recuperación se enviará al correo electrónico de su cuenta',
    setPassword: 'Su contraseña ha sido establecida. Ahora puede entrar en el panel de administración',
    resetPassword: 'Sólo se aceptan contraseñas con un mínimo de 8 caracteres que incluyan al menos 1 número y 1 letra',
    resendTFASuccess: 'El código QR para la autenticación de 2 factores se ha enviado a su correo electrónico',
    userAddSuccess: 'El usuario recibirá un correo electrónico para seguir y establecer la contraseña de la cuenta',
    TFARequired: 'La autenticación de dos factores "requerida.',
    unknownError: 'Algo" ha ido mal inténtelo de "nuevo.""',
    noSubjectOrBody: 'La" línea de asunto y el cuerpo deben proporcionarse para todos los canales e idiomas seleccionados',
    noComposeSubject: 'No se puede enviar un mensaje con la línea de asunto vacía',
    noComposeContent: 'No se pueden enviar mensajes vacíos',
    noComposeContentSubject: 'No se pueden enviar mensajes vacíos o con la línea de asunto vacío',
    recalledMessageSuccess: 'Mensaje retirado por el usuario {{fullName}}',
    sendEmptyMessageError: 'No se pueden enviar mensajes vacíos',
    sessionExpired: 'La sesión ha expirado',
    atLeastOneCurrency: 'Debe configurarse al menos 1 moneda',
    atLeastOneGame: 'Debe configurarse al menos 1 juego',
    atLeastOnePlayer: 'Debe configurarse al menos 1 jugador',
    atLeastOneRanges: 'Debe rellenarse al menos 1 rango',
    atLeastOnePersona: 'Debe configurarse al menos 1 personaje',
    maxUploadSize: 'El tamaño del archivo no puede exceder ',
    maxSumUploadSize: 'El tamaño máximo de subida permitido no puede exceder ',
    maxUploadedFilesSize: 'El tamaño máximo de los archivos seleccionados no puede superar',
    selectedAllRestrictedCountries: 'No puede seleccionar todos los países restringidos',
    wrongFileFormat: 'Formato de archivo incorrecto',
    globalGamesEnabled: 'Se ha/han activado el/los juego(s) para todas las marcas',
    globalGamesDisabled: 'Se ha/han desactivado el/los juego(s) para todas las marcas',
    playerInList: 'El jugador ya aparece en la lista',
    folderContainFile: 'El jugador ya está en la lista',
    folderContainFiles: 'El archivo ya está en esta carpeta',
    brandExist: 'Ya existe una marca con este nombre',
    automationRuleExist: 'Ya existe una regla con este nombre',
    messageTitleExist: 'Ya existe un mensaje con este nombre',
    noAvailablePlayers: 'No hay jugadores disponibles para el rango de fechas seleccionado',
    passwordExpired: 'Contraseña caducada',
    colorNotEmpty: 'El color no puede estar vacío',
    tagNotEmpty: 'La etiqueta no puede estar vacía',
    accountDeleted: 'Su cuenta ha sido eliminada. Ahora se cerrará su sesión.',
    accountDisabled: 'Su cuenta ha sido desactivada. Ahora se cerrará su sesión.',
    atLeastOneVersion: 'Debe seleccionarse al menos una versión',
    fileUploaded: 'Se ha subido el archivo',
    notMatchRegexRequirements: 'El archivo {{name}} no coincide con los requisitos regex',
    notMatchMaxSizeRequirement: 'El archivo {{name}} no coincide con el requisito de tamaño máximo',
    copied: 'Copiado',
    atLeastOneLimitMustBeUpdated: 'Debe actulizarse al menos 1 límite',
    noAttachments: 'No hay archivos adjuntos',
    tagDeletedFromPlayerAccount: 'La etiqueta {{name}} ha sido eliminada de la cuenta del jugador',
    bonusCanceledForPlayer: 'Se ha cancelado el bono para el jugador "{{name}}',
    generalMessage: '{{name}} {{model}} {{action}}',
    slUpdated: 'se" ha actualizado',
    plUpdated: 'se han actualizado',
    slDeleted: 'se ha eliminado',
    slExcluded: 'se ha excluido',
    plDeleted: 'se han eliminado',
    slActivated: 'se ha activado',
    plActivated: 'se han activado',
    slDeactivated: 'se ha desactivado',
    plDeactivated: 'se han desactivado',
    slImported: 'se ha importado',
    slReset: 'se ha restablecido',
    plReset: 'se han restablecido',
    slAdded: 'se ha añadido',
    plAdded: 'se han añadido',
    slDisabled: 'se ha desactivado',
    plDisabled: 'se han desactivado',
    slEnabled: 'se ha activado',
    slCreated: 'se ha creado',
    plCreated: 'se han creado',
    plMoved: 'se han movido',
    plMerged: 'se han fusionado',
    plTagged: 'se han etiquetado',
    slUntagged: 'se ha desetiquetado',
    plUntagged: 'se han desetiquetado',
    removedFromSegment: '{{name}} se ha eliminado del segmento',
    playerAddedToSegment: 'El jugador ha sido añadido al segmento {{name}}',
    playerRemovedFromSegment: 'El jugador ha sido eliminado del segmento {{name}}',
    slFinished: 'se ha terminado',
    slConfigured: 'se ha configurado',
    plSent: 'se han enviado',
    plRemoved: 'se ha eliminado',
    slSent: 'se ha enviado',
    setMarkAttention: 'La atención de la marca se ha establecido {{name}}',
    slRemoved: 'se ha eliminado',
    slUnlocked: 'se ha desbloqueado',
    accountBlockedForMessages: 'Cuenta {{action}} para mensajes',
    slBlocked: 'se ha bloqueado',
    slUnblocked: 'se han desbloqueado',
    slChanged: 'se ha cambiado',
    slReactivated: 'se ha reactivado',
    changeAccountStatus: 'La cuenta se ha {{action}}',
    bulkStatusUpdate: 'Se ha realizado la {{action}} en jugadores seleccionados',
    slAdjusted: 'se ha ajustado',
    plAdjusted: 'se han ajustado',
    slClosed: 'se ha cerrado',
    slAssigned: 'se ha asignado',
    plAssigned: 'se han asignado',
    finishedTestPlayersAccountUse: 'El uso de la cuenta del jugador de prueba ha finalizado',
    adjustedTestPlayersAccountBalance: 'Se ha ajustado el saldo de la cuenta del jugador de prueba',
    slVerified: 'se ha verificado',
    plVerified: 'se ha verificado',
    slUnverified: 'no se ha verificado',
    slSet: 'se ha establecido',
    slIntegrated: 'se ha integrado',
    slSavedAsDraft: 'se ha guardado como borrador',
    slScheduledForDate: 'se ha programado para {{date}}',
    movedToDraft: 'se han movido a borradores',
    plRejected: 'se han rechazado',
    playerAccountVerifiedWith: 'La cuenta del jugador se ha verificado con {{docType}}',
    plApproved: 'se han aprobado',
    plUploaded: 'se han cargado',
    plArchived: 'se han archivado',
    slStarted: 'se ha iniciado',
    slMoved: 'se ha movido',
    slResent: 'se ha reenviado',
    playerRemovedFromRestrictionList: 'El jugador {{name}} ha sido eliminado de la lista de restricciones',
    plBlacklisted: 'han sido incluidos en la lista negra',
    plWhiteListed: 'han sido incluidos en la lista blanca',
    slBlacklisted: 'se han incluido en la lista negra',
    slWhiteListed: 'se han incluido en la lista blanca',
    slCancelled: 'ha sido cancelado',
    playerDisconnectedFromBonus: 'El jugador {{name}} ha sido desconectado del bono',
    playerRemovedFromBonus: '{{name}} jugador ha sido eliminado de la bonificación',
    playersAddedToBonus: 'Los jugadores se han añadido a la bonificación',
    slIssued: 'se ha expedido',
    slScheduled: 'se ha programado',
    slRenamed: 'ha cambiado de nombre a',
    slUploaded: 'se ha cargado',
    slConnected: 'se ha conectado',
    slDisconnected: 'se ha desconectado',
    plConfigured: 'Ha sido configurado',
    mailSetAsDefault: '{{name}} correo electrónico se ha establecido por defecto',
    slRestricted: 'se ha restringido',
    plRestricted: 'se han restringido',
    countryDeletedFromRestrictionList: '{{name}} país eliminado de la lista de restricciones',
    ipAddressDeletedFromRestrictionList: '{{nombre}} Dirección IP eliminada de la lista de restricciones',
    playerChangedNotificationPreferences: 'El jugador ha cambiado las preferencias de notificación',
    playerNotEligible: 'El jugador no puede recibir mensajes',
    bodyIsRequired: 'El cuerpo del correo electrónico no puede estar vacío',
    playerCanceledFromBonus: 'Se ha cancelado la bonificación para el jugador {{name}}',
    noRecipientMessage: 'El mensaje no ha llegado a ningún destinatario',
    atLeastOneDepositConfig: 'Debe configurarse al menos 1 depósito',
    atLeastOneProvider: 'Debe seleccionarse al menos un proveedor',
    countOrAtLeastOneAmountConfig: 'Debe configurarse el recuento o al menos un importe de divisa',
    atLeastOneCurrencyAmountConfig: 'Debe configurarse al menos un importe de divisa',
    bonusNameExists: 'El nombre de la bonificación ya existe',
    slPromotionCancelled: 'promoción cancelada',
    atLeastOneSuccessPlayer: 'Debe haber al menos un jugador capaz de obtener bonificaciones',
    setAsDefault: '{{nombre}} se ha establecido por defecto',
    promoCodeExist: 'El código promocional ya existe',
    dataSyncError: 'Sincronización de Datos en curso',
    atLeastOneTable: 'Debe seleccionarse al menos 1 mesa',
    plSynchronized: 'se han sincronizado',
    slSynchronized: 'se ha sincronizado',
    lobbyProviderOrderError: 'Todos los proveedores deben tener orden.',
    lobbyProviderGameOrderError: 'Todos los juegos deben tener orden.',
    atLeastOneCurrencyEnabled: 'Al menos una moneda debe estar habilitada.',
    machineSectionCannotBeEmpty: 'Las secciones de la máquina no pueden estar vacías.',
    atLeastOneCondition: 'Al menos una condición debe ser completada.',
    allSectionsMustBeSelectedAndHasProbability: 'Todas las secciones deben ser seleccionadas y tener probabilidad.',
    sumOfProbabilities: 'La suma de las probabilidades debe ser 100.',
    prizeAndProbabilityConfigure: 'La sección de Premio y Probabilidad debe ser configurada.',
    slPublished: 'ha sido publicado.',
    sectionImageRequired: 'Se requiere una imagen de sección.',
    titleAndDescriptionRequired: 'El título y la descripción son requeridos para los Términos y Condiciones.',
    configureSelectedPrizeItems: 'Por favor, configura los premios seleccionados.',
    atLeastOneGroupMustHasLimit: 'Al menos un grupo debe tener límite.',
    atLeastOneGroupMustBeConfigured: 'Al menos un grupo debe ser configurado.',
    atLeastTwoPrize: 'Al menos dos premios deben ser seleccionados.',
    allSectionsMustBeConfigured: 'Todas las secciones deben ser configuradas.',
    mustSelectDefaultPrizeAndProbability: 'El premio predeterminado debe ser seleccionado y tener una probabilidad mayor que 0',
    mustSelectDefaultPrize: 'El premio predeterminado debe ser seleccionado',
    slTimeout: 'actualmente está en un tiempo de espera',
    testPlayerResetPassword: 'La nueva contraseña ha sido enviada a la dirección de correo electrónico del usuario',
    atLeastOneTag: 'Al menos una etiqueta debe ser seleccionada',
    groupNameAlreadyExist: 'El nombre del grupo ya está en uso para el grupo activo. No puedes tener 2 grupos con el mismo nombre en estado activo',
    atLeastOnePromoCodeMustBeUploaded: 'Al menos 1 código promocional válido debe ser subido',
    emptyFile: 'El archivo está vacío. Por favor, revísalo de nuevo',
    mobilePageBackgroundRequired: 'Se requiere el fondo de la página móvil.',
    visibilityConfigurationUpdated: 'Las configuraciones de visibilidad para el método de pago {{name}} han sido actualizadas.',
    atLeastOneTemplate: 'Al menos una plantilla debe ser seleccionada.',
    plEnabled: 'han sido habilitados.',
    atLeastOneContentSection: 'Al menos 1 de las secciones abiertas debe ser configurada',
    deepLink: 'Enlace Directo',
    deepLinkSection: 'Sección de Enlace Directo',
    deepLinkIdOrName: 'ID/Nombre de Enlace Directo',
    slCompleted: 'ha sido completado.',
    passwordResetLinkSent:
      'El enlace para restablecer la contraseña ha sido enviado exitosamente a la dirección de correo electrónico <0>{{email}}</0>, por favor revisa tu bandeja de entrada. <1/> Si no ha recibido un correo en 5 minutos, revisa tu carpeta de spam o intenta enviar la solicitud de recuperación nuevamente.',
    atLeastOneFileMustBeUploaded: 'Al menos un archivo debe ser subido',
    slDone: 'ha sido hecho',
    statusCorrectionIsInProgress: 'La corrección de estado está en progreso',
    mustBeUpdated: '{{fieldName}} debe ser actualizado',
  },
  tr: {
    forgotTFA: 'Lütfen kurtarma protokolü için e-posta adresinizi girin. 2 faktörlü kimlik doğrulama için QR kodu e-postanıza gönderilecektir',
    forgotPassword: 'Kurtarma protokolü için lütfen e-postanızı doldurun. Kurtarma bağlantısı hesabınızın e-postasına gönderilecektir',
    setPassword: 'Şifreniz oluşturuldu. Artık yönetici paneline giriş yapabilirsiniz',
    resetPassword: 'Sadece en az 1 rakam ve 1 harf içeren minimum 8 karakterli şifreler kabul edilir',
    resendTFASuccess: '2 faktörlü kimlik doğrulama için QR kodu e-posta adresinize gönderildi',
    userAddSuccess: 'Kullanıcı e-posta alır ve hesap için şifre belirler',
    TFARequired: 'İki  faktörlü kimlik doğrulama gereklidir',
    unknownError: 'Bir hata oluştu, lütfen tekrar deneyin',
    noSubjectOrBody: 'Konu satırı ve gövdesi seçilen tüm kanallar ve diller için belirtilmeli',
    noComposeSubject: 'Konu satırı boş olarak mesaj gönderemezsiniz',
    noComposeContent: 'Boş mesaj gönderemezsiniz',
    noComposeContentSubject: 'Konu satırı boş olarak mesaj gönderemezsiniz',
    recalledMessageSuccess: 'Mesaj {{fullName}} tarafından geri çağrıldı',
    sendEmptyMessageError: 'Boş mesaj gönderemezsiniz',
    sessionExpired: 'Oturum Süresi Doldu',
    atLeastOneCurrency: 'En az 1 para birimi yapılandırılmalıdır',
    atLeastOneGame: 'En az 1 oyun seçilmelidir',
    atLeastOnePlayer: 'En az 1 oyuncu seçilmelidir',
    atLeastOneRanges: 'En az 1 aralık doldurulmalıdır',
    atLeastOnePersona: 'En az 1 persona seçilmelidir',
    maxUploadSize: 'Dosya boyutu geçilemez',
    maxSumUploadSize: 'İzin verilen maksimum yükleme boyutu aşılamaz',
    maxUploadedFilesSize: 'Seçilen dosyaların maksimum boyutu aşılamaz',
    selectedAllRestrictedCountries: 'Tüm kısıtlı ülkeleri seçemezsiniz',
    wrongFileFormat: 'Yanlış dosya formatı',
    globalGamesEnabled: 'Oyun(lar) tüm markalar için etkinleştirildi',
    globalGamesDisabled: 'Oyun(lar) tüm markalar için devre dışı bırakıldı',
    playerInList: 'Oyuncu zaten listede mevcut',
    folderContainFile: 'Dosya zaten bu klasörde mevcut',
    folderContainFiles: 'Dosyalar zaten bu klasörde mevcut',
    brandExist: 'Bu isimde bir marka zaten mevcut',
    automationRuleExist: 'Bu isimde bir kural zaten mevcut',
    messageTitleExist: 'Bu isimde bir mesaj zaten mevcut',
    noAvailablePlayers: 'Seçili tarih aralığı için uygun kullanıcı yok',
    passwordExpired: 'Şifrenin süresi doldu',
    colorNotEmpty: 'Renk boş olamaz',
    tagNotEmpty: 'Etiket boş olamaz',
    accountDeleted: 'Hesabınız silindi. Şimdi oturumunuz kapatılacak',
    accountDisabled: 'Hesabınız devre dışı bırakıldı. Şimdi oturumunuz kapatılacak',
    atLeastOneVersion: 'En az bir versiyon seçilmelidir',
    fileUploaded: 'Dosya yüklendi',
    notMatchRegexRequirements: '{{name}} adlı dosya regex gerekliliklerine uymuyor',
    notMatchMaxSizeRequirement: '{{name}} adlı dosya maksimum boyut gerekliliklerine uymuyor',
    copied: 'Kopyalandı',
    atLeastOneLimitMustBeUpdated: 'En az 1 limit güncellenmeli',
    noAttachments: 'Ek yok',
    tagDeletedFromPlayerAccount: '{{name}} etiketi oyuncunun hesabından silindi',
    bonusCanceledForPlayer: 'Bonus {{name}} adlı oyuncu için iptal edildi',
    generalMessage: '{{name}} {{model}} {{action}}',
    slUpdated: 'güncellendi',
    plUpdated: 'güncellendi',
    slDeleted: 'silindi',
    slExcluded: 'hariç tutuldu',
    plDeleted: 'silindi',
    slActivated: 'etkinleştirildi',
    plActivated: 'etkinleştirildi',
    slDeactivated: 'devre dışı bırakıldı',
    plDeactivated: 'devre dışı bırakıldı',
    slImported: 'içe aktarıldı',
    slReset: 'sıfırlandı',
    plReset: 'sıfırlandı',
    slAdded: 'eklendi',
    plAdded: 'eklendi',
    slDisabled: 'devre dışı bırakıldı',
    plDisabled: 'devre dışı bırakıldı',
    slEnabled: 'etkinleştirildi',
    slCreated: 'oluşturuldu',
    plCreated: 'oluşturuldu',
    plMoved: 'taşındı',
    plMerged: 'birleştirildi',
    plTagged: 'etiketlendi',
    slUntagged: 'etiketi kaldırıldı',
    plUntagged: 'etiketi kaldırıldı',
    removedFromSegment: '{{name}} bölümden kaldırıldı',
    playerAddedToSegment: 'Oyuncu {{name}} bölümüne eklendi',
    playerRemovedFromSegment: 'Oyuncu {{name}} bölümünden kaldırıldı',
    slFinished: 'bitirildi',
    slConfigured: 'yapılandırıldı',
    plSent: 'gönderildi',
    plRemoved: 'kaldırıldı',
    slSent: 'gönderildi',
    setMarkAttention: 'Dikkat İşareti ayarlandı {{name}}',
    slRemoved: 'kaldırıldı',
    slUnlocked: 'kilidi açıldı',
    accountBlockedForMessages: 'Mesajlar için hesap {{action}}',
    slBlocked: 'engellendi',
    slUnblocked: 'kilidi açıldı',
    slChanged: 'değiştirildi',
    slReactivated: 'tekrar aktive edildi',
    changeAccountStatus: 'Hesap {{action}}',
    bulkStatusUpdate: 'Seçili oyuncular üzerinde {{action}} gerçekleştirildi',
    slAdjusted: 'düzenlendi',
    plAdjusted: 'düzenlendi',
    slClosed: 'kapatıldı',
    slAssigned: 'atandı',
    plAssigned: 'atandı',
    finishedTestPlayersAccountUse: 'Test oyuncusu hesabı kullanımı bitirildi',
    adjustedTestPlayersAccountBalance: 'Test oyuncusunun hesap bakiyesi ayarlandı',
    slVerified: 'doğrulandı',
    plVerified: 'doğrulandı',
    slUnverified: 'doğrulanmadı',
    slSet: 'ayarlandı',
    slIntegrated: 'entegre edildi',
    slSavedAsDraft: 'taslak olarak kaydedildi',
    slScheduledForDate: '{{date}} tarihi için planlandı',
    movedToDraft: 'taslağa taşındı',
    plRejected: 'reddedildi',
    playerAccountVerifiedWith: 'Oyuncu hesabı {{docType}} ile doğrulandı',
    plApproved: 'onaylandı',
    plUploaded: 'yüklendi',
    plArchived: 'arşivlendi',
    slStarted: 'başlatıldı',
    slMoved: 'taşındı',
    slResent: 'tekrar gönderildi',
    playerRemovedFromRestrictionList: '{{name}} oyuncu kısıtlama listesinden kaldırıldı',
    plBlacklisted: 'kara listeye eklendi',
    plWhiteListed: 'beyaz listeye eklendi',
    slBlacklisted: 'kara listeye eklendi',
    slWhiteListed: 'beyaz listeye eklendi',
    slCancelled: 'iptal edildi',
    playerDisconnectedFromBonus: '{{name}} oyuncusunun bonus bağlantısı kesildi',
    playerRemovedFromBonus: '{{name}} oyuncu bonustan kaldırıldı',
    playersAddedToBonus: 'Oyuncular bonusa eklendi',
    slIssued: 'işlendi',
    slScheduled: 'planlandı',
    slRenamed: 'yeniden adlandırıldı',
    slUploaded: 'yüklendi',
    slConnected: 'bağlandı',
    slDisconnected: 'bağlantısı kesildi',
    plConfigured: 'yapılandırıldı',
    mailSetAsDefault: '{{name}} e-posta varsayılan olarak belirlendi',
    slRestricted: 'kısıtlandı',
    plRestricted: 'kısıtlandı',
    countryDeletedFromRestrictionList: '{{name}} ülke kısıtlama listesinden silindi',
    ipAddressDeletedFromRestrictionList: '{{name}} IP adresi kısıtlama listesinden silindi',
    playerChangedNotificationPreferences: 'Oyuncu bildirim tercihlerini değiştirdi',
    playerNotEligible: 'Oyuncu mesaj alamaz',
    bodyIsRequired: 'E-posta gövdesi boş olamaz',
    playerCanceledFromBonus: 'Bonus {{name}} oyuncusu için iptal edildi',
    noRecipientMessage: 'Mesaj alıcısı yok',
    atLeastOneDepositConfig: 'En az 1 para yatırma yapılandırması ayarlanmalıdır',
    atLeastOneProvider: 'En az bir sağlayıcı seçilmelidir',
    countOrAtLeastOneAmountConfig: 'Sayı veya en az bir para birimi tutarı yapılandırılmalıdır',
    atLeastOneCurrencyAmountConfig: 'En az bir para birimi tutarı yapılandırması ayarlanmalıdır',
    bonusNameExists: 'Bonus adı zaten mevcut',
    slPromotionCancelled: 'promosyon i̇ptal edi̇lmi̇şti̇r',
    atLeastOneSuccessPlayer: 'Bonus alabilecek en az bir oyuncu sağlanmalıdır',
    setAsDefault: '{{isim}} varsayılan olarak ayarlandı',
    promoCodeExist: 'Promosyon kodu zaten mevcut',
    dataSyncError: 'Veri Senkronizasyonu yapılıyor',
    atLeastOneTable: 'En az 1 tablo seçilmelidir',
    plSynchronized: 'senkronize edilmiştir',
    slSynchronized: 'senkronize edilmiştir',
    allCurrencyAmountsConfigs: 'Tüm para birimi tutarı yapılandırmaları ayarlanmalıdır',
    lobbyProviderOrderError: 'Tüm sağlayıcıların sırası olmalıdır',
    lobbyProviderGameOrderError: 'Tüm oyunların sırası olmalıdır',
    atLeastOneCurrencyEnabled: 'En az bir para birimi etkinleştirilmelidir',
    machineSectionCannotBeEmpty: 'Makine bölümleri boş olamaz',
    atLeastOneCondition: 'En az bir koşulun yerine getirilmesi gerekir',
    allSectionsMustBeSelectedAndHasProbability: 'Tüm bölümler seçilmeli ve olasılığa sahip olmalıdır',
    sumOfProbabilities: 'Olasılıklar toplamı 100 olmalıdır',
    prizeAndProbabilityConfigure: 'Ödül ve Olasılık bölümü yapılandırılmalıdır',
    slPublished: 'yayınlandı',
    sectionImageRequired: 'Bölüm resmi gereklidir',
    titleAndDescriptionRequired: 'Şartlar ve Koşullar için başlık ve açıklama gereklidir',
    configureSelectedPrizeItems: 'Lütfen seçilen ödül öğelerini yapılandırın',
    atLeastOneGroupMustHasLimit: 'En az bir grubun limiti olmalıdır',
    atLeastOneGroupMustBeConfigured: 'En az bir grup yapılandırılmalıdır',
    atLeastTwoPrize: 'En az iki ödül seçilmelidir',
    allSectionsMustBeConfigured: 'Tüm bölümler yapılandırılmalıdır',
    mustSelectDefaultPrizeAndProbability: "Varsayılan ödül seçilmeli ve 0'dan büyük olasılığa sahip olmalıdır",
    mustSelectDefaultPrize: 'Varsayılan ödül seçilmelidir',
    slTimeout: 'şu anda bir zaman aşımında.',
    testPlayerResetPassword: 'Yeni şifre kullanıcının e-posta adresine gönderildi',
    atLeastOneTag: 'En az bir etiket seçilmelidir',
    groupNameAlreadyExist: 'Grup ismi aktif grup için zaten kullanılmaktadır. Aynı isimli 2 aktif gruba sahip olamazsınız.',
    atLeastOnePromoCodeMustBeUploaded: 'En az 1 geçerli promosyon kodu yüklenmelidir',
    emptyFile: 'Dosya boş. Lütfen tekrar kontrol edin.',
    websitePageBackgroundRequired: 'Web sitesi sayfasının arka planı gereklidir',
    mobilePageBackgroundRequired: 'Cep telefonu sayfasının arka planı gereklidir',
    visibilityConfigurationUpdated: '{{name}} ödeme yöntemi için görünürlük konfigürasyonları güncellendi',
    atLeastOneTemplate: 'En az bir şablon seçilmelidir',
    plEnabled: 'etkinleştirildi',
    atLeastOneContentSection: 'Açılan bölümlerden en az 1 tanesi yapılandırılmış olmalıdır',
    deepLink: 'Derin Link',
    deepLinkSection: 'Derin Link Bölümü',
    deepLinkIdOrName: 'Derin Link ID/Adı',
    slCompleted: 'tamamlandı',
    passwordResetLinkSent:
      '<0>{{email}}</0> e-posta adresine şifre sıfırlama bağlantısı başarıyla gönderildi, lütfen gelen kutunuzu kontrol edin. <1/> 5 dakika içinde bir e-posta almadıysanız, spam kutunuzu kontrol edin veya kurtarma isteğini yeniden göndermeyi deneyin',
    atLeastOneFileMustBeUploaded: 'En az bir dosya yüklenmelidir',
    slDone: 'yapıldı',
    statusCorrectionIsInProgress: 'Durum Düzeltme devam ediyor',
    mustBeUpdated: '{{fieldName}} güncellenmelidir',
  },
  ru: {
    forgotTFA: 'Введите свой адрес электронной почты для протокола восстановления. QR-код для двухфакторной аутентификации будет отправлен на ваш адрес электронной почты',
    forgotPassword:
      'Введите свой адрес электронной почты для протокола восстановления. Ссылка восстановления пароля будет отправлена на адрес электронной почты, использованный при регистрации учетной записи',
    setPassword: 'Пароль задан. Вы можете войти в панель администратора',
    resetPassword: 'Допускаются только пароли, состоящие как минимум из 8 символов, включая минимум 1 цифру и 1 букву',
    resendTFASuccess: 'QR-код для двухфакторной аутентификации был отправлен на ваш адрес электронной почты',
    userAddSuccess: 'Пользователь получит электронное письмо с инструкциями по установке пароля к учетной записи',
    TFARequired: 'Требуется двухфакторная аутентификация',
    unknownError: 'Произошла ошибка, просим повторить попытку',
    noSubjectOrBody: 'Тема и основной текст должны быть предоставлены для всех выбранных каналов и языков',
    noComposeSubject: 'Сообщение не может быть отправлено с пустой строкой темы',
    noComposeContent: 'Пустые сообщения не могут быть отправлены',
    noComposeContentSubject: 'Пустые сообщения и сообщения с пустыми строками темы не могут быть отправлены',
    recalledMessageSuccess: 'Сообщение отозвано пользователем {{fullName}}',
    sendEmptyMessageError: 'Пустые сообщения не могут быть отправлены',
    sessionExpired: 'Время сессии истекло',
    atLeastOneCurrency: 'Минимум 1 валюта должна быть задана',
    atLeastOneGame: 'Минимум 1 игра должна быть выбрана ',
    atLeastOnePlayer: 'Минимум 1 игрок должен быть выбран',
    atLeastOneRanges: 'Минимум 1 диапазон должен быть заполнен',
    atLeastOnePersona: 'Минимум 1 пользователь должен быть выбран ',
    maxUploadSize: 'Размер файла не может превышать',
    maxSumUploadSize: 'Максимально разрешенный размер файла для загрузки не может превышать',
    maxUploadedFilesSize: 'Максимальный размер выбранных файлов не может превышать',
    selectedAllRestrictedCountries: 'Вы не можете выбрать все запрещенные страны',
    wrongFileFormat: 'Недопустимый формат файла',
    globalGamesEnabled: 'Игра(-ы) была(-и) активированы для всех брендов',
    globalGamesDisabled: 'Игра(-ы) была(-и) деактивированы для всех брендов',
    playerInList: 'Игрок уже в списке',
    folderContainFile: 'Файл уже в этой папке',
    folderContainFiles: 'Файлы уже в этой папке',
    brandExist: 'Бренд с этим именем уже существует',
    automationRuleExist: 'Правило с этим именем уже существует',
    messageTitleExist: 'Сообщение с этим именем уже существует',
    noAvailablePlayers: 'Нет доступных игроков для выбранного диапазона дат',
    passwordExpired: 'Срок действия пароля истек',
    colorNotEmpty: 'Цвет не может быть пустым',
    tagNotEmpty: 'Тег не может быть пустым',
    accountDeleted: 'Ваша учетная запись была удалена. Сейчас будет выполнен выход',
    accountDisabled: 'Ваша учетная запись была заблокирована. Сейчас будет выполнен выход',
    atLeastOneVersion: 'Минимум 1 версия должна быть выбрана ',
    fileUploaded: 'Файл был загружен',
    notMatchRegexRequirements: 'Файл {{name}} не соответствует требованиям регулярного выражения',
    notMatchMaxSizeRequirement: 'Файл {{name}} не соответствует требованиям по максимально допустимому размеру',
    copied: 'Скопировано',
    atLeastOneLimitMustBeUpdated: 'Минимум 1 лимит должен быть обновлен',
    noAttachments: 'Вложений нет',
    tagDeletedFromPlayerAccount: 'Тег {{name}} был удален из учетной записи игрока',
    bonusCanceledForPlayer: 'Бонус отменен для игрока {{name}}',
    generalMessage: '‎{{name}} {{model}} {{action}}',
    slUpdated: 'обновлено',
    plUpdated: 'обновлены',
    slDeleted: 'удалено',
    slExcluded: 'исключено',
    plDeleted: 'удалено',
    slActivated: 'активировано',
    plActivated: 'активированы',
    slDeactivated: 'деактивирован',
    plDeactivated: 'деактивированы',
    slImported: 'импортировано',
    slReset: 'сброшено',
    plReset: 'сброшены',
    slAdded: 'добавлено',
    plAdded: 'добавлены',
    slDisabled: 'блокировано',
    plDisabled: 'блокированы',
    slEnabled: 'сделано доступным',
    slCreated: 'создано',
    plCreated: 'созданы',
    plMoved: 'перемещены',
    plMerged: 'объединены',
    plTagged: 'помечены',
    slUntagged: 'не помечено',
    plUntagged: 'не помечены',
    removedFromSegment: '‎{{name}} был удален из сегмента',
    playerAddedToSegment: 'Игрок был добавлен в сегмент {{name}}',
    playerRemovedFromSegment: 'Игрок был удален из сегмента {{name}}',
    slFinished: 'завершено',
    slConfigured: 'задано',
    plSent: 'отправлены',
    plRemoved: 'удалено',
    slSent: 'отправлено',
    setMarkAttention: 'Была добавлена пометка Внимание {{name}}',
    slRemoved: 'удалено',
    slUnlocked: 'разблокировано',
    accountBlockedForMessages: 'Учетная запись {{action}} для сообщений',
    slBlocked: 'заблокирована',
    slUnblocked: 'разблокирована',
    slChanged: 'изменена',
    slReactivated: 'повторно активирована',
    changeAccountStatus: 'Учетная запись была {{action}}',
    bulkStatusUpdate: '‎{{action}} было применено к выбранным игрокам',
    slAdjusted: 'скорректировано',
    plAdjusted: 'скорректированы',
    slClosed: 'закрыто',
    slAssigned: 'назначено',
    plAssigned: 'назначены',
    finishedTestPlayersAccountUse: 'Пользование тестовой учетной записью игрока завершено',
    adjustedTestPlayersAccountBalance: 'Баланс тестовой учетной записи игрока пополнен',
    slVerified: 'верифицировано',
    plVerified: 'верифицировано',
    slUnverified: 'не верифицировано',
    slSet: 'задано',
    slIntegrated: 'интегрировано',
    slSavedAsDraft: 'сохранено как черновик',
    slScheduledForDate: 'запланировано на {{date}}',
    movedToDraft: 'перемещено в черновики',
    plRejected: 'отклонены',
    playerAccountVerifiedWith: 'Учетная запись игрока была проверена с {{docType}}',
    plApproved: 'одобрены',
    plUploaded: 'загружены',
    plArchived: 'архивированы',
    slStarted: 'начато',
    slMoved: 'перемещено',
    slResent: 'повторно отправлено',
    playerRemovedFromRestrictionList: 'Игрок {{name}} удален из списка ограничений',
    plBlacklisted: 'добавлены в список запрещенных',
    plWhiteListed: 'добавлены в список разрешенных',
    slBlacklisted: 'добавлено в список запрещенных',
    slWhiteListed: 'добавлено в список разрешенных',
    slCancelled: 'отменено',
    playerDisconnectedFromBonus: 'Игрок {{name}} был отключен от бонуса',
    playerRemovedFromBonus: 'Игрок {{name}} был исключен из бонуса',
    playersAddedToBonus: 'Игроки были добавлены к бонусу',
    slIssued: 'начислено',
    slScheduled: 'запланировано',
    slRenamed: 'переименовано',
    slUploaded: 'загружено',
    slConnected: 'подключено',
    slDisconnected: 'отключено',
    plConfigured: 'настроены',
    mailSetAsDefault: 'Адрес электронной почты {{name}} был выбран в качестве адреса электронной почты по умолчанию',
    slRestricted: 'ограничено',
    plRestricted: 'ограничены',
    countryDeletedFromRestrictionList: 'Страна {{name}} удалена из списка запрещенных',
    ipAddressDeletedFromRestrictionList: 'IP-адрес {{name}} удален из списка запрещенных',
    playerChangedNotificationPreferences: 'Игрок изменил параметры уведомлений',
    playerNotEligible: 'Игрок не может получать сообщения',
    bodyIsRequired: 'Основной текст электронного письма не может быть пустым',
    playerCanceledFromBonus: 'Бонус для {{name}} игрока был отменен',
    noRecipientMessage: 'Нет адресата для получения сообщения',
    atLeastOneDepositConfig: 'Должна быть настроена как минимум 1 конфигурация депозита',
    atLeastOneProvider: 'Должен быть выбран как минимум один провайдер',
    countOrAtLeastOneAmountConfig: 'Должен быть определен счет или как минимум одна сумма в валюте',
    atLeastOneCurrencyAmountConfig: 'Должна быть настроена как минимум одна конфигурация суммы валюты',
    bonusNameExists: 'Название бонуса уже существует',
    slPromotionCancelled: 'акция отменена',
    atLeastOneSuccessPlayer: 'Должен быть указан как минимум один игрок, способный получать бонусы',
    setAsDefault: '{{name}} задано по умолчанию',
    dataSyncError: 'Синхронизация данных в процессе',
    promoCodeExist: 'Промокод уже существует',
    atLeastOneTable: 'Необходимо выбрать как минимум 1 таблицу',
    plSynchronized: 'синхронизированы',
    slSynchronized: 'синхронизировано',
    allCurrencyAmountsConfigs: 'Все конфигурации суммы валют должны быть настроены',
    lobbyProviderOrderError: 'Все провайдеры должны иметь порядок',
    lobbyProviderGameOrderError: 'Все игры должны иметь порядок',
    atLeastOneCurrencyEnabled: 'Как минимум одна валюта должна быть включена',
    machineSectionCannotBeEmpty: 'Разделы игрового автомата не могут быть пустыми',
    atLeastOneCondition: 'Как минимум одно условие должно быть выполнено',
    allSectionsMustBeSelectedAndHasProbability: 'Все разделы должны быть выбраны и иметь вероятность',
    sumOfProbabilities: 'Сумма вероятностей должна быть равна 100',
    prizeAndProbabilityConfigure: 'Раздел Премия и вероятность должен быть настроен',
    slPublished: 'было опубликовано',
    sectionImageRequired: 'Требуется изображение раздела',
    titleAndDescriptionRequired: 'Заголовок и описание обязательны для Условий и Положений',
    configureSelectedPrizeItems: 'Пожалуйста, настройте выбранные призовые элементы',
    atLeastOneGroupMustHasLimit: 'Как минимум одна группа должна иметь ограничение',
    atLeastOneGroupMustBeConfigured: 'Как минимум одна группа должна быть настроена',
    atLeastTwoPrize: 'Как минимум два приза должны быть выбраны',
    allSectionsMustBeConfigured: 'Все разделы должны быть настроены',
    mustSelectDefaultPrizeAndProbability: 'Приз по умолчанию должен быть выбран и иметь вероятность больше 0',
    mustSelectDefaultPrize: 'Приз по умолчанию должен быть выбран',
    slTimeout: 'в данный момент находится в режиме тайм-аута.',
    testPlayerResetPassword: 'Новый пароль был отправлен на адрес электронной почты пользователя',
    atLeastOneTag: 'Как минимум один тег должен быть выбран',
    groupNameAlreadyExist: 'Имя группы уже используется для активной группы. Нельзя иметь две группы с одинаковым именем в активном состоянии.',
    atLeastOnePromoCodeMustBeUploaded: 'Как минимум 1 действующий промокод должен быть загружен',
    emptyFile: 'Файл пуст. Пожалуйста, проверьте его еще раз.',
    websitePageBackgroundRequired: 'Необходим бэкграунд страницы веб-сайта',
    mobilePageBackgroundRequired: 'Необходим бэкграунд мобильной версии',
    visibilityConfigurationUpdated: 'Обновлены настройки видимости для метода оплаты {{name}}',
    atLeastOneTemplate: 'Должен быть выбран хотя бы один шаблон',
    plEnabled: 'были включены',
    atLeastOneContentSection: 'Как минимум 1 из открытых разделов должен быть настроен',
    deepLink: 'Глубокая ссылка',
    deepLinkSection: 'Раздел Глубокие ссылки',
    deepLinkIdOrName: 'ID/название глубокой ссылки',
    slCompleted: 'было завершено',
    passwordResetLinkSent:
      'Ссылка на восстановление пароля успешно отправлена на адрес электронной почты <0>{{email}}</0>, пожалуйста, проверьте свой почтовый ящик. <1/> Если вы не получили письмо в течение 5 минут, проверьте спам или попробуйте отправить запрос на восстановление еще раз.',
    atLeastOneFileMustBeUploaded: 'Как минимум один файл должен быть загружен',
    slDone: 'было выполнено',
    statusCorrectionIsInProgress: 'Коррекция статуса в процессе',
    mustBeUpdated: '{{fieldName}} должно быть обновлено',
  },
  pt: {
    forgotTFA: 'Por favor, preencha o seu e-mail para o protocolo de recuperação. O código QR para autenticação de 2 fatores será enviado para o seu e-mail',
    forgotPassword: 'Por favor, preencha o seu e-mail para o protocolo de recuperação. O link de recuperação será enviado para o e-mail da sua conta',
    setPassword: 'A sua palavra-passe foi definida. Pode agora entrar no painel de administração',
    resetPassword: 'São aceites apenas palavras-passe com um mínimo de 8 caracteres, incluindo pelo menos 1 número e 1 letra',
    resendTFASuccess: 'O código QR para autenticação de 2 fatores foi enviado para o seu e-mail',
    userAddSuccess: 'O utilizador receberá um e-mail para seguir e definir a palavra-passe para a conta',
    TFARequired: 'Exigida a autenticação de dois fatores',
    unknownError: 'Algo correu mal, por favor, tente novamente',
    noSubjectOrBody: 'A linha de assunto e o corpo precisam de ser fornecidos para todos os canais e idiomas selecionados',
    noComposeSubject: 'Não pode enviar uma mensagem com a linha de assunto vazia',
    noComposeContent: 'Não pode enviar mensagens vazias',
    noComposeContentSubject: 'Não pode enviar mensagens vazias ou com a linha de assunto vazia',
    recalledMessageSuccess: 'Mensagem recuperada pelo utilizador {{fullName}}',
    sendEmptyMessageError: 'Não pode enviar mensagens vazias',
    sessionExpired: 'Sessão Expirada',
    atLeastOneCurrency: 'Precisa de configurar pelo menos 1 moeda',
    atLeastOneGame: 'Precisa de selecionar pelo menos 1 jogo',
    atLeastOnePlayer: 'Precisa de selecionar pelo menos 1 jogador',
    atLeastOneRanges: 'Precisa de preencher pelo menos 1 intervalo',
    atLeastOnePersona: 'Precisa de selecionar pelo menos 1 pessoa',
    maxUploadSize: 'O tamanho do arquivo não pode exceder',
    maxSumUploadSize: 'O tamanho máximo de upload permitido não pode exceder',
    maxUploadedFilesSize: 'O tamanho máximo dos arquivos selecionados não pode exceder',
    selectedAllRestrictedCountries: 'Não pode selecionar todos os países restritos',
    wrongFileFormat: 'Formato de arquivo incorreto',
    playerInList: 'O jogador já está na lista',
    folderContainFile: 'O arquivo já está nesta pasta',
    folderContainFiles: 'Os arquivos já estão nesta pasta',
    brandExist: 'Já existe uma marca com este nome',
    automationRuleExist: 'Já existe uma regra com este nome',
    messageTitleExist: 'Já existe uma mensagem com este nome',
    noAvailablePlayers: 'Não há jogadores disponíveis para o intervalo de datas selecionado',
    passwordExpired: 'Palavra-passe expirada',
    colorNotEmpty: 'A cor não pode estar vazia',
    tagNotEmpty: 'A tag não pode estar vazia',
    accountDeleted: 'A sua conta foi excluída. Será desconectado agora',
    accountDisabled: 'A sua conta foi desativada. Será desconectado agora',
    atLeastOneVersion: 'Tem de ser selecionada pelo menos uma versão',
    fileUploaded: 'O arquivo foi carregado',
    notMatchRegexRequirements: '{{name}} não corresponde aos requisitos de regex',
    notMatchMaxSizeRequirement: '{{name}} arquivo não corresponde ao tamanho máximo exigido',
    copied: 'Copiado',
    atLeastOneLimitMustBeUpdated: 'Pelo menos 1 limite precisa ser atualizado',
    noAttachments: 'Sem anexos',
    tagDeletedFromPlayerAccount: 'A tag {{name}} foi excluída da conta do jogador',
    bonusCanceledForPlayer: 'O bónus foi cancelado para o jogador {{name}}',
    generalMessage: '{{name}} {{model}} {{action}}',
    slUpdated: 'foi atualizado',
    plUpdated: 'foram atualizados',
    slDeleted: 'foi apagado',
    slExcluded: 'foi excluído',
    plDeleted: 'foram apagados',
    slActivated: 'foi ativado',
    plActivated: 'foram ativados',
    slDeactivated: 'foi desativado',
    plDeactivated: 'foram desativados',
    slImported: 'foi importado',
    slReset: 'foi redefinido',
    plReset: 'foram redefinidos',
    slAdded: 'foi adicionado',
    plAdded: 'foram adicionados',
    slDisabled: 'foi desativado',
    plDisabled: 'foram desativados',
    slEnabled: 'foi habilitado',
    slCreated: 'foi criado',
    plCreated: 'foram criados',
    plMoved: 'foram movidos',
    plMerged: 'foram combinados',
    plTagged: 'foram marcados (tagged)',
    slUntagged: 'foi desmarcado (untagged)',
    plUntagged: 'foram desmarcados (untagged)',
    removedFromSegment: '{{name}} foi removido do segmento',
    playerAddedToSegment: 'O jogador foi adicionado ao segmento {{name}}',
    playerRemovedFromSegment: 'O jogador foi removido do segmento {{name}}',
    slFinished: 'foi concluído',
    slConfigured: 'foi configurado',
    plSent: 'foram enviados',
    plRemoved: 'foi removido',
    slSent: 'foi enviado',
    setMarkAttention: 'Foi definida uma Marca de Atenção {{name}}',
    slRemoved: 'foi removido',
    slUnlocked: 'foi desbloqueado',
    accountBlockedForMessages: 'Conta {{action}} para mensagens',
    slBlocked: 'foi bloqueado',
    slUnblocked: 'foi desbloqueado',
    slChanged: 'foi alterado',
    slReactivated: 'foi reativado',
    changeAccountStatus: 'A conta foi {{action}}',
    bulkStatusUpdate: '{{action}} foi realizado nos jogadores selecionados',
    slAdjusted: 'foi ajustado',
    plAdjusted: 'foram ajustados',
    slClosed: 'foi fechado',
    slAssigned: 'foi designado',
    plAssigned: 'foram designados',
    finishedTestPlayersAccountUse: 'O uso da conta do jogador de teste foi concluído',
    adjustedTestPlayersAccountBalance: 'O saldo da conta do jogador de teste foi ajustado',
    slVerified: 'foi verificado',
    plVerified: 'foi verificado',
    slUnverified: 'não foi verificado',
    slSet: 'foi definido',
    slIntegrated: 'foi integrado',
    slSavedAsDraft: 'foi salvo como rascunho',
    slScheduledForDate: 'foi agendado para {{date}}',
    movedToDraft: 'foi movido para o rascunho',
    plRejected: 'foram rejeitados',
    playerAccountVerifiedWith: 'A conta do jogador foi verificada com {{docType}}',
    plApproved: 'foram aprovados',
    plUploaded: 'foram carregados',
    plArchived: 'foram arquivados',
    slStarted: 'foi iniciado',
    slMoved: 'foi movido',
    slResent: 'foi reenviado',
    playerRemovedFromRestrictionList: 'O jogador {{name}} foi removido da lista de restrições',
    plBlacklisted: 'foram colocados na lista de proibidos',
    plWhiteListed: 'foram colocados na lista de permitidos',
    slBlacklisted: 'foi colocado na lista de proibidos',
    slWhiteListed: 'foi colocado na lista de permitidos',
    slCancelled: 'foi cancelado',
    playerDisconnectedFromBonus: 'jogador {{name}} foi desconectado do bónus',
    playerRemovedFromBonus: 'O jogador {{name}} foi removido do bónus',
    playersAddedToBonus: 'Os jogadores foram adicionados ao bónus',
    slIssued: 'foi emitido',
    slScheduled: 'foi agendado',
    slRenamed: 'foi renomeado',
    slUploaded: 'foi carregado',
    slConnected: 'foi conectado',
    slDisconnected: 'foi desconectado',
    plConfigured: 'foram configurados',
    mailSetAsDefault: '{{name}} o e-mail foi definido como padrão',
    slRestricted: 'foi restringido',
    plRestricted: 'foram restringidos',
    countryDeletedFromRestrictionList: 'O país {{name}} eliminado da lista de restrições',
    ipAddressDeletedFromRestrictionList: 'O Endereço IP {{name}} excluído da lista de restrições',
    playerChangedNotificationPreferences: 'O jogador alterou as preferências de notificação',
    playerNotEligible: 'O jogador não é elegível para receber uma mensagem',
    bodyIsRequired: 'O corpo do e-mail não pode estar vazio',
    playerCanceledFromBonus: 'O bónus foi cancelado para o jogador {{name}}',
    noRecipientMessage: 'Nenhum destinatário para receber a mensagem',
    atLeastOneDepositConfig: 'Tem de ser configurada, pelo menos, 1 definição relativa ao depósito',
    atLeastOneProvider: 'Tem de ser selecionado, pelo menos, um fornecedor',
    countOrAtLeastOneAmountConfig: 'Tem de ser configurada a contagem ou, pelo menos, um valor da unidade monetária',
    atLeastOneCurrencyAmountConfig: 'Tem de ser configurada, pelo menos, uma definição relativa ao valor da unidade monetária',
    bonusNameExists: 'O nome do bónus já existe',
    slPromotionCancelled: 'a promoção foi cancelada',
    atLeastOneSuccessPlayer: 'Tem de ser fornecido, pelo menos, um jogador habilitado a receber bónus',
    setAsDefault: '{{name}} foi definido como padrão',
    promoCodeExist: 'O código promocional já existe',
    dataSyncError: 'Sincronização de dados em andamento',
    atLeastOneTable: 'Pelo menos 1 tabela deve ser selecionada',
    plSynchronized: 'foram sincronizados',
    slSynchronized: 'foi sincronizado',
    allCurrencyAmountsConfigs: 'Todas as configurações do montante da moeda devem ser configuradas',
    lobbyProviderOrderError: 'Todos os fornecedores devem estar ordenados',
    lobbyProviderGameOrderError: 'Todos os jogos devem estar ordenados',
    atLeastOneCurrencyEnabled: 'Pelo menos uma moeda deve estar activada',
    machineSectionCannotBeEmpty: 'As secções da máquina não podem estar vazias',
    atLeastOneCondition: 'Pelo menos uma condição deve ser preenchida',
    allSectionsMustBeSelectedAndHasProbability: 'Todas as secções devem ser seleccionadas e ter probabilidade',
    sumOfProbabilities: 'A soma das probabilidades deve ser 100',
    prizeAndProbabilityConfigure: 'As secções Prémio e Probabilidade têm de ser configuradas',
    slPublished: 'foi publicado',
    sectionImageRequired: 'É necessária uma imagem da secção',
    titleAndDescriptionRequired: 'O título e a descrição são obrigatórios para os Termos e Condições',
    configureSelectedPrizeItems: 'Por favor, configure os itens do prémio selecionado',
    atLeastOneGroupMustHasLimit: 'Pelo menos um grupo deve ter limite',
    atLeastOneGroupMustBeConfigured: 'Pelo menos um grupo tem de ser configurado',
    atLeastTwoPrize: 'Devem ser seleccionados pelo menos dois prémios',
    allSectionsMustBeConfigured: 'Todas as secções devem ser configuradas',
    mustSelectDefaultPrizeAndProbability: 'O prémio por defeito deve ser selecionado e ter uma probabilidade superior a 0',
    mustSelectDefaultPrize: 'O prémio predefinido deve ser selecionado',
    slTimeout: 'está atualmente em timeout.',
    testPlayerResetPassword: 'A nova palavra-passe foi enviada para o endereço de correio eletrónico do utilizador',
    atLeastOneTag: 'Tem de ser selecionada pelo menos uma etiqueta',
    groupNameAlreadyExist: 'O nome do grupo já é utilizado para o grupo ativo. Não é possível ter 2 grupos com o mesmo nome no estado ativo.',
    atLeastOnePromoCodeMustBeUploaded: 'Pelo menos 1 código promocional válido deve ser carregado',
    emptyFile: 'O ficheiro está vazio. Por favor, verifique-o novamente.',
    websitePageBackgroundRequired: 'O fundo da página do sítio Web é obrigatório',
    mobilePageBackgroundRequired: 'O fundo da página do telemóvel é obrigatório',
    visibilityConfigurationUpdated: 'As configurações de visibilidade para o método de pagamento {{name}} foram actualizadas',
    atLeastOneTemplate: 'Pelo menos um modelo deve ser selecionado',
    plEnabled: 'foi ativado',
    atLeastOneContentSection: 'Pelo menos uma das secções abertas deve ser configurada',
    deepLink: 'Deep link',
    deepLinkSection: 'Secção do Deep Link',
    deepLinkIdOrName: 'ID/Nome do Deep Link',
    slCompleted: 'foi concluído',
    passwordResetLinkSent:
      'Foi enviada com êxito uma hiperligação de redefinição da palavra-passe para o endereço de e-mail <0>{{email}}</0>, verifique a sua caixa de entrada. <1/> Se não receberes um e-mail em 5 minutos, verifica o teu spam ou bandeja para reenviar o pedido de recuperação novamente',
    atLeastOneFileMustBeUploaded: 'Deve ser carregado pelo menos um ficheiro',
    slDone: 'foi efectuada',
    statusCorrectionIsInProgress: 'A correção do estado está em curso',
    mustBeUpdated: '{{fieldName}} tem de ser atualizado',
  },
  zh: {
    forgotTFA: '请填写您的电子邮件以恢复密码。用于双重认证的二维码将会被发送到您的电子邮箱',
    forgotPassword: '请填写您的电子邮件以恢复密码。恢复链接将会被发送到您的电子邮箱。',
    setPassword: '您的密码已设置。现在您可以登录到管理面板',
    resetPassword: '密码需至少包含8个字符，且必须包括至少1个数字和1个字母',
    resendTFASuccess: '用于双重认证的二维码将会被发送到您的电子邮箱',
    userAddSuccess: '用户将收到电子邮件，以按照步骤设置密码',
    TFARequired: '需要双重认证',
    unknownError: '出错了，请重试',
    noSubjectOrBody: '必须为所有选定的频道和语言提供主题和正文',
    noComposeSubject: '您无法发送主题为空的邮件',
    noComposeContent: '您不能发送空消息',
    noComposeContentSubject: '您不能发送空消息或主题为空的消息',
    recalledMessageSuccess: '消息被用户{{fullName}}撤回',
    sendEmptyMessageError: '您不能发送空消息',
    sessionExpired: '会期已失效',
    atLeastOneCurrency: '必须设置至少1种货币',
    atLeastOneGame: '必须选择至少1场比赛',
    atLeastOnePlayer: '必须选择至少1个玩家',
    atLeastOneRanges: '必须填写至少1个范围',
    atLeastOnePersona: '必须选择至少1个角色',
    maxUploadSize: '文件大小不能超过',
    maxSumUploadSize: '上传大小最大不能超过',
    maxUploadedFilesSize: '所选文件的最大尺寸不能超过',
    selectedAllRestrictedCountries: '您不能选择所有受限制的国家',
    wrongFileFormat: '文件格式错误',
    playerInList: '玩家已在列表中',
    folderContainFile: '文件已在此文件夹中',
    folderContainFiles: '文件已在此文件夹中',
    brandExist: '同名品牌已存在',
    automationRuleExist: '同名规则已存在',
    messageTitleExist: '同名消息已存在',
    noAvailablePlayers: '所选日期范围内没有可用的玩家',
    passwordExpired: '密码已过期',
    colorNotEmpty: '颜色不能为空',
    tagNotEmpty: '标签不能为空',
    accountDeleted: '您的账号已被删除，现在您将登出',
    accountDisabled: '您的账号已被禁用，现在您将登出',
    atLeastOneVersion: '必须选择至少1个版本',
    fileUploaded: '文件已上传',
    notMatchRegexRequirements: '{{name}}文件不符合正则表达式要求',
    notMatchMaxSizeRequirement: '{{name}}文件不符合最大大小要求',
    copied: '已复制',
    atLeastOneLimitMustBeUpdated: '必须更新至少1个限制',
    noAttachments: '无附件',
    tagDeletedFromPlayerAccount: '{{name}}标签已从玩家账户中删除',
    bonusCanceledForPlayer: '奖金已被取消给{{name}}玩家',
    generalMessage: '{{name}} {{model}} {{action}}',
    slUpdated: '已更新',
    plUpdated: '已更新',
    slDeleted: '已删除',
    slExcluded: '已排除',
    plDeleted: '已删除',
    slActivated: '已激活',
    plActivated: '已激活',
    slDeactivated: '已停用',
    plDeactivated: '已停用',
    slImported: '已导入',
    slReset: '已重置',
    plReset: '已重置',
    slAdded: '已添加',
    plAdded: '已添加',
    slDisabled: '已禁用',
    plDisabled: '已禁用',
    slEnabled: '已启用',
    slCreated: '已创建',
    plCreated: '已创建',
    plMoved: '已移动',
    plMerged: '已合并',
    plTagged: '已标记',
    slUntagged: '已取消标记',
    plUntagged: '已取消标记',
    removedFromSegment: '{{name}}已被从分区中删除',
    playerAddedToSegment: '玩家已被添加到{{name}}分区',
    playerRemovedFromSegment: '玩家已被从{{name}}分区移除',
    slFinished: '已完成',
    slConfigured: '已设置',
    plSent: '已发送',
    plRemoved: '已移除',
    slSent: '已发送',
    setMarkAttention: '标记注意已设置{{name}}',
    slRemoved: '已移除',
    slUnlocked: '已解锁',
    accountBlockedForMessages: '账户状态为{{action}}，无法接收消息',
    slBlocked: '已屏蔽',
    slUnblocked: '已解除屏蔽',
    slChanged: '已更改',
    slReactivated: '已被重新激活',
    changeAccountStatus: '账户已被{{action}}',
    bulkStatusUpdate: '已对选定的玩家进行了{{action}}',
    slAdjusted: '已被调整',
    plAdjusted: '已被调整',
    slClosed: '已关闭',
    slAssigned: '已分配',
    plAssigned: '已分配',
    finishedTestPlayersAccountUse: '测试玩家的账号已使用完毕',
    adjustedTestPlayersAccountBalance: '测试玩家账号的余额已调整',
    slVerified: '已验证',
    plVerified: '已验证',
    slUnverified: '未验证',
    slSet: '已设置',
    slIntegrated: '已整合',
    slSavedAsDraft: '已保存为草稿',
    slScheduledForDate: '已安排在{{date}}',
    movedToDraft: '已移至草稿',
    plRejected: '已拒绝',
    playerAccountVerifiedWith: '玩家的账号已通过{{docType}}验证',
    plApproved: '已批准',
    plUploaded: '已上传',
    plArchived: '已归档',
    slStarted: '已开始',
    slMoved: '已移动',
    slResent: '已重置',
    playerRemovedFromRestrictionList: '{{name}}玩家已被从限制名单移除',
    plBlacklisted: '已加入黑名单',
    plWhiteListed: '已加入白名单',
    slBlacklisted: '已加入黑名单',
    slWhiteListed: '已加入白名单',
    slCancelled: '已取消',
    playerDisconnectedFromBonus: '{{name}}玩家已与奖金断开连接',
    playerRemovedFromBonus: '{{name}}玩家已被从奖金移除',
    playersAddedToBonus: '玩家已被添加到奖金',
    slIssued: '已发出',
    slScheduled: '已安排',
    slRenamed: '已重命名',
    slUploaded: '已上传',
    slConnected: '已连接',
    slDisconnected: '已断开连接',
    plConfigured: '已配置',
    mailSetAsDefault: '{{name}}电子邮箱已设置为默认邮箱',
    slRestricted: '已受限',
    plRestricted: '已受限',
    countryDeletedFromRestrictionList: '{{name}}国家已从限制名单删除',
    ipAddressDeletedFromRestrictionList: '{{name}}IP地址已从限制名单删除',
    playerChangedNotificationPreferences: '玩家更改了通知偏好',
    playerNotEligible: '玩家没有资格接收消息',
    bodyIsRequired: '电子邮件正文不能为空',
    playerCanceledFromBonus: '{{name}}玩家的奖金已被取消',
    noRecipientMessage: '没有消息接受人',
    atLeastOneCurrencyAmountConfig: '必须配置至少一种货币金额配置',
    bonusNameExists: '奖金名称已经存在',
    slPromotionCancelled: '促销活动已被取消',
    atLeastOneSuccessPlayer: '必须提供至少1名能够获得奖金的玩家',
    setAsDefault: '{{name}}已被设置为默认值',
    atLeastOneDepositConfig: '必须至少配置1个存款配置',
    atLeastOneProvider: '必须至少选择一个提供程序',
    countOrAtLeastOneAmountConfig: '必须配置计数或至少一个货币金额',
    promoCodeExist: '该促销代码已经存在',
    dataSyncError: '正在进行数据同步',
    atLeastOneTable: '至少必须选择1个表格',
    plSynchronized: '已同步',
    slSynchronized: '已同步',
    allCurrencyAmountsConfigs: '必须配置所有货币金额配置',
    lobbyProviderOrderError: '所有提供商都应有订单',
    lobbyProviderGameOrderError: '所有游戏都应有订单',
    atLeastOneCurrencyEnabled: '必须启用至少一种货币',
    machineSectionCannotBeEmpty: '机器区域不能为空',
    atLeastOneCondition: '必须至少满足一个条件',
    allSectionsMustBeSelectedAndHasProbability: '所有部分都必须选定并具有概率',
    sumOfProbabilities: '概率之和必须是100',
    prizeAndProbabilityConfigure: '奖品和概率部分必须配置',
    slPublished: '已发布',
    sectionImageRequired: '需要一个区域图',
    titleAndDescriptionRequired: '条款和条件需要标题和说明',
    configureSelectedPrizeItems: '请配置所选的奖品项目',
    atLeastOneGroupMustHasLimit: '至少有一组必须有限制',
    atLeastOneGroupMustBeConfigured: '必须至少配置一个组',
    atLeastTwoPrize: '必须至少选出两个奖品',
    allSectionsMustBeConfigured: '必须配置所有部分',
    mustSelectDefaultPrizeAndProbability: '必须选择默认奖品，且概率大于0',
    mustSelectDefaultPrize: '必须选择默认奖品',
    slTimeout: '目前处于超时状态。',
    testPlayerResetPassword: '新密码已发送至用户的电子邮件地址',
    atLeastOneTag: '必须至少选择一个标记',
    groupNameAlreadyExist: '组名已用于活跃组。不能有两个同名组处于活跃状态。',
    atLeastOnePromoCodeMustBeUploaded: '必须上传至少1个有效的促销代码',
    emptyFile: '文件为空。请重新检查。',
    websitePageBackgroundRequired: '需要网站页面背景',
    mobilePageBackgroundRequired: '需要手机页面背景',
    visibilityConfigurationUpdated: '更新了{{name}}支付方式的可见性配置',
    atLeastOneTemplate: '必须至少选择一个模板',
    plEnabled: '已启用',
    atLeastOneContentSection: '至少应配置1个已打开的部分',
    deepLink: '深层链接',
    deepLinkSection: '深层链接部分',
    deepLinkIdOrName: '深层链接ID/名称',
    slCompleted: '已完成',
    passwordResetLinkSent: '密码重置链接已成功发送至 <0>{{email}}</0> 电子邮件地址，请检查您的收件箱。<1/> 如果您在5分钟内没有收到电子邮件，请检查您的垃圾箱或托盘，重新发送恢复请求。',
    atLeastOneFileMustBeUploaded: '必须上传至少一个文件',
    slDone: '已做',
    statusCorrectionIsInProgress: '状态更正正在进行中',
    mustBeUpdated: '{{fieldName}} 必须更新',
  },
  ro: {
    forgotTFA: 'Te rugăm să completezi e-mailul pentru protocolul de recuperare. Codul QR pentru autentificare cu 2 factori va fi trimis pe adresa ta de e-mail',
    forgotPassword: 'Te rugăm să completezi e-mailul pentru protocolul de recuperare. Link-ul de recuperare va fi trimis la adresa de e-mail a contului tău',
    setPassword: 'Parola ta a fost setată. Acum poți conecta la panoul de administrare',
    resetPassword: 'Sunt acceptate numai parole cu minim 8 caractere, inclusiv cel puțin 1 număr și 1 literă',
    resendTFASuccess: 'Codul QR pentru autentificare cu doi factori a fost trimis pe e-mailul tău',
    userAddSuccess: 'Utilizatorul va primi un e-mail pentru urmărit și setat parola pentru cont',
    TFARequired: 'Este necesară o autentificare cu doi factori',
    unknownError: 'Ceva nu a mers bine. Te rugăm să încerci din nou',
    noSubjectOrBody: 'Subiectul și corpul trebuie furnizate pentru toate canalele și limbile selectate',
    noComposeSubject: 'Nu poți trimite mesaje cu un subiect necompletat',
    noComposeContent: 'Nu poți trimite mesaje necompletate',
    noComposeContentSubject: 'Nu poți trimite mesaje necompletate sau cu subiect necompletat',
    recalledMessageSuccess: 'Mesaj rechemat de utilizatorul {{fullName}}',
    sendEmptyMessageError: 'Nu poți trimite mesaje fără conținut',
    sessionExpired: 'Sesiune expirată',
    atLeastOneCurrency: 'Trebuie configurată cel puțin o valută',
    atLeastOneGame: 'Trebuie selectat cel puțin un joc',
    atLeastOnePlayer: 'Trebuie selectat cel puțin un jucător',
    atLeastOneRanges: 'Trebuie completat cel puțin un interval',
    atLeastOnePersona: 'Trebuie să fie selectată cel puțin o persoană',
    maxUploadSize: 'Dimensiunea de fișier nu poate depăși',
    maxSumUploadSize: 'Dimensiunea maximă de încărcare permisă nu poate depăși',
    maxUploadedFilesSize: 'Dimensiunea maximă permisă a fișierelor selectate nu poate depăși',
    selectedAllRestrictedCountries: 'Nu puteți selecta toate țările restricționate',
    wrongFileFormat: 'Format de fișier greșit',
    playerInList: 'Jucătorul este deja pe listă',
    folderContainFile: 'Fișierul este deja în acest dosar (folder)',
    folderContainFiles: 'Fișierele sunt deja în acest dosar',
    brandExist: 'Marca cu acest nume există deja',
    automationRuleExist: 'Regula cu acest nume există deja',
    messageTitleExist: 'Mesaj cu acest nume există deja',
    noAvailablePlayers: 'Nu există jucători disponibili pentru intervalul de date selectat',
    passwordExpired: 'Parola expirată',
    colorNotEmpty: 'Câmpul culorii nu poate fi gol ',
    tagNotEmpty: 'Câmpul etichetei nu poate fi gol',
    accountDeleted: 'Contul tău a fost șters. Acum vei fi deconectat',
    accountDisabled: 'Contul tău a fost dezactivat. Acum vei fi deconectat',
    atLeastOneVersion: 'Trebuie selectată cel puțin o variantă',
    fileUploaded: 'Fișierul a fost încărcat',
    notMatchRegexRequirements: 'Fișierul {{name}} nu corespunde cerințelor expresiei regulate (regex)',
    notMatchMaxSizeRequirement: 'Fișierul {{name}} nu corespunde cerinței privind dimensiunea maximă',
    copied: 'Copiat',
    atLeastOneLimitMustBeUpdated: 'Trebuie actualizată cel puțin o limită',
    noAttachments: 'Fara atașamente',
    tagDeletedFromPlayerAccount: 'Eticheta {{name}} a fost ștearsă din contul jucătorului',
    bonusCanceledForPlayer: 'Bonusul a fost anulat pentru jucător {{name}}',
    generalMessage: '{{name}} {{model}} {{action}}',
    slUpdated: 'a fost actualizat',
    plUpdated: 'au fost actualizați',
    slDeleted: 'a fost șters',
    slExcluded: 'a fost exclus',
    plDeleted: 'au fost șterși',
    slActivated: 'a fost activat',
    plActivated: 'au fost activați',
    slDeactivated: 'a fost dezactivat',
    plDeactivated: 'au fost dezactivate',
    slImported: 'a fost importat',
    slReset: 'a fost resetat',
    plReset: 'au fost resetați',
    slAdded: 'a fost adăugat',
    plAdded: 'au fost adăugați',
    slDisabled: 'a fost deconectat',
    plDisabled: 'au fost deconectați',
    slEnabled: 'a fost activat',
    slCreated: 'a fost creat',
    plCreated: 'au fost creați',
    plMoved: 'au fost mutate',
    plMerged: 'au fost comasate',
    plTagged: 'au fost etichetate',
    slUntagged: 'a fost etichetat',
    plUntagged: 'au fost neetichetate',
    removedFromSegment: '{{name}} a fost eliminat din segment',
    playerAddedToSegment: 'Jucătorul a fost adăugat la segmentul {{name}}',
    playerRemovedFromSegment: 'Jucătorul a fost eliminat din segmentul {{name}}',
    slFinished: 's-a terminat',
    slConfigured: 'a fost configurat',
    plSent: 'au fost trimiși',
    plRemoved: 'a fost eliminat',
    slSent: 'a fost trimis',
    setMarkAttention: 'Indicatorul Atenție a fost setat {{name}}',
    slRemoved: 'au fost eliminați',
    slUnlocked: 'a fost deblocat',
    accountBlockedForMessages: 'Contul {{action}} pentru mesaje',
    slBlocked: 'a fost bocat',
    slUnblocked: 'a fost deblocat',
    slChanged: 'a fost schimbat',
    slReactivated: 'a fost reactivat',
    changeAccountStatus: 'Contul a fost {{action}}',
    bulkStatusUpdate: '{{action}} a fost efectuată asupra jucătorilor selectați ',
    slAdjusted: 'a fost ajustat',
    plAdjusted: 'au fost ajustați',
    slClosed: 'a fost închis',
    slAssigned: 'a fost atribuit',
    plAssigned: 'au fost atribuiți',
    finishedTestPlayersAccountUse: 'Utilizarea contului de testare a jocuui s-a încheiat',
    adjustedTestPlayersAccountBalance: 'Soldul contului de testare al jocătorului a fost depus (ajustat)',
    slVerified: 'a fost verificat',
    plVerified: 'a fost verificat',
    slUnverified: 'a fost neverificat',
    slSet: 'a fost setat',
    slIntegrated: 'a fost întegrat',
    slSavedAsDraft: 'a fost salvat ca schiță',
    slScheduledForDate: 'a fost programat pentru {{date}}',
    movedToDraft: 'a fost mutat la schiță',
    plRejected: 'au fost respinși',
    playerAccountVerifiedWith: 'Contul jucătorului a fost verificat cu {{docType}}',
    plApproved: 'au fost aprobați',
    plUploaded: 'au fost încărcate',
    plArchived: 'au fost arhivați',
    slStarted: 'au fost începuți',
    slMoved: 'a fost mutat',
    slResent: 'a fost retrimis',
    playerRemovedFromRestrictionList: 'Jucătorul {{name}} a fost înlăturat de pe lista de restricții',
    plBlacklisted: 'au fost incluși pe lista neagră',
    plWhiteListed: 'au fost trecuți pe lista albă',
    slBlacklisted: 'a fost trecut pe lista neagră',
    slWhiteListed: 'a fost trecut pe lista albă',
    slCancelled: 'a fost anulat',
    playerDisconnectedFromBonus: 'Jucătorul {{name}} a fost deconectat de la bonus',
    playerRemovedFromBonus: 'Jucătorul {{name}} a fost scos de la bonus',
    playersAddedToBonus: 'Jucătorii au fost adăugați la bonus',
    slIssued: 'a fost emis',
    slScheduled: 'a fost programat',
    slRenamed: 'a fost redenumit',
    slUploaded: 'a fost încărcat',
    slConnected: 'a fost conectat',
    slDisconnected: 'a fost deconectat',
    plConfigured: 'au fost configurați',
    mailSetAsDefault: 'E-mailul {{name}} a fost setat ca fiind prestabilit',
    slRestricted: 'a fost setat ca implicit',
    plRestricted: 'au fost restricționați',
    countryDeletedFromRestrictionList: 'Țara {{name}} a fost eliminată din lista de restricții',
    ipAddressDeletedFromRestrictionList: 'Adresa IP {{name}} a fost ștearsă din lista de restricții',
    playerChangedNotificationPreferences: 'Jucătorul a schimbat preferințele de notificare',
    playerNotEligible: 'Jucătorul nu este eligibil pentru primirea unui mesaj',
    bodyIsRequired: 'Corpul e-mailului nu poate fi gol',
    playerCanceledFromBonus: 'Bonusul a fost anulat pentru jucătorul {{name}}',
    noRecipientMessage: 'Niciun destinatar nu primește mesaj',
    atLeastOneDepositConfig: 'Trebuie configurată cel puțin 1 configurație de depozit',
    atLeastOneProvider: 'Trebuie selectat cel puțin un furnizor',
    countOrAtLeastOneAmountConfig: 'Numărul __  sau cel puțin o sumă monetară trebuie să fie configurată   ',
    atLeastOneCurrencyAmountConfig: 'Cel puțin o configurație a sumei monetare trebuie să fie configurată   ',
    bonusNameExists: 'Numele de Bonus există deja',
    slPromotionCancelled: 'promoția a fost anulată',
    atLeastOneSuccessPlayer: 'Trebuie să fie prevăzut cel puțin un jucător, fiind capabil să obțină bonusuri',
    setAsDefault: '{{name}} a fost setat ca implicit',
    promoCodeExist: 'Codul promoțional există deja',
    dataSyncError: 'Data sincronizării este în proces',
    atLeastOneTable: 'Este necesar să selectezi cel puțin 1 tabel',
    plSynchronized: 'au fost sincronizate',
    slSynchronized: 'a fost sincronizat',
  },
  bg: {},
  el: {
    forgotTFA: 'Συμπληρώστε το email σας για πρωτόκολλο ανάκτησης. Ο κωδικός QR για έλεγχο ταυτότητας 2 παραγόντων θα αποσταλεί στο email σας',
    forgotPassword: 'Συμπληρώστε το email σας για πρωτόκολλο ανάκτησης. Ο σύνδεσμος ανάκτησης θα αποσταλεί στο email του λογαριασμού σας',
    setPassword: 'Ο κωδικός πρόσβασης ορίστηκε. Τώρα μπορείτε να συνδεθείτε στον πίνακα διαχειριστή',
    resetPassword: "Γίνονται δεκτοί μόνο κωδικοί πρόσβασης με 8 χαρακτήρες κατ' ελάχιστο συμπεριλαμβάνοντας τουλάχιστον 1 αριθμό και 1 γράμμα",
    resendTFASuccess: 'Ο κωδικός QR για έλεγχο ταυτότητας 2 παραγόντων στάλθηκε στο email σας',
    userAddSuccess: 'Ο χρήστης θα λάβει email με οδηγίες για να ορίσει κωδικό πρόσβασης για τον λογαριασμό',
    TFARequired: 'Απαιτείται έλεγχος ταυτότητας δύο παραγόντων',
    unknownError: 'Παρουσιάστηκε σφάλμα, δοκιμάστε ξανά',
    noSubjectOrBody: 'Η γραμμή θέματος και το σώμα κειμένου πρέπει να παρέχονται για όλα τα επιλεγμένα κανάλια και τις γλώσσες',
    noComposeSubject: 'Δεν μπορείτε να στείλετε μήνυμα με κενή γραμμή θέματος',
    noComposeContent: 'Δεν μπορείτε να στείλετε κενά μηνύματα',
    noComposeContentSubject: 'Δεν μπορείτε να στείλετε κενά μηνύματα ή μηνύματα με κενή γραμμή θέματος',
    recalledMessageSuccess: 'Το μήνυμα ανακλήθηκε από τον χρήστη {{fullName}}',
    sendEmptyMessageError: 'Δεν μπορείτε να στείλετε κενά μηνύματα',
    sessionExpired: 'Η συνεδρία έληξε',
    atLeastOneCurrency: 'Πρέπει να διαμορφωθεί τουλάχιστον 1 νόμισμα',
    atLeastOneGame: 'Πρέπει να επιλεγεί τουλάχιστον 1 παιχνίδι',
    atLeastOnePlayer: 'Πρέπει να επιλεγεί τουλάχιστον 1 παίκτης',
    atLeastOneRanges: 'Πρέπει να συμπληρωθεί τουλάχιστον 1 εύρος',
    atLeastOnePersona: 'Πρέπει να επιλεγεί τουλάχιστον 1 άτομο',
    maxUploadSize: 'Το μέγεθος του αρχείου δεν μπορεί να είναι πάνω από ',
    maxSumUploadSize: 'Το μέγιστο επιτρεπόμενο μέγεθος μεταφόρτωσης δεν μπορεί να είναι πάνω από ',
    maxUploadedFilesSize: 'Το μέγιστο μέγεθος των επιλεγμένων αρχείων δεν μπορεί να είναι πάνω από ',
    selectedAllRestrictedCountries: 'Δεν μπορείτε να επιλέξετε όλες τις χώρες υπό περιορισμό',
    wrongFileFormat: 'Λανθασμένη μορφή αρχείου',
    playerInList: 'Ο παίκτης βρίσκεται ήδη στη λίστα',
    folderContainFile: 'Το αρχείο βρίσκεται ήδη σε αυτόν τον φάκελο',
    folderContainFiles: 'Τα αρχεία βρίσκονται ήδη σε αυτόν τον φάκελο',
    brandExist: 'Υπάρχει ήδη επωνυμία με αυτό το όνομα',
    automationRuleExist: 'Υπάρχει ήδη κανόνας με αυτό το όνομα',
    messageTitleExist: 'Υπάρχει ήδη μήνυμα με αυτό το όνομα',
    noAvailablePlayers: 'Δεν υπάρχουν διαθέσιμοι παίκτες για το επιλεγμένο εύρος ημερομηνιών',
    passwordExpired: 'Ο κωδικός πρόσβασης έληξε',
    colorNotEmpty: 'Το χρώμα δεν μπορεί να είναι κενό',
    tagNotEmpty: 'Η ετικέτα δεν μπορεί να είναι κενή',
    accountDeleted: 'Ο λογαριασμός σας διαγράφηκε. Τώρα θα αποσυνδεθείτε',
    accountDisabled: 'Ο λογαριασμός σας απενεργοποιήθηκε. Τώρα θα αποσυνδεθείτε',
    atLeastOneVersion: 'Πρέπει να επιλεγεί τουλάχιστον μία έκδοση',
    fileUploaded: 'Το αρχείο μεταφορτώθηκε',
    notMatchRegexRequirements: 'Το αρχείο {{name}} δεν πληροί τις απαιτήσεις του regex',
    notMatchMaxSizeRequirement: 'Το αρχείο {{name}} δεν πληροί την απαίτηση μέγιστου μεγέθους',
    copied: 'Αντιγράφηκε',
    atLeastOneLimitMustBeUpdated: 'Πρέπει να ενημερωθεί τουλάχιστον 1 όριο',
    noAttachments: 'Δεν υπάρχουν συνημμένα',
    tagDeletedFromPlayerAccount: 'Η ετικέτα {{name}} διαγράφηκε από τον λογαριασμό παίκτη',
    bonusCanceledForPlayer: 'Το μπόνους ακυρώθηκε για τον παίκτη {{name}}',
    generalMessage: '{{name}} {{model}} {{action}}',
    slUpdated: 'ενημερώθηκε',
    plUpdated: 'ενημερώθηκαν',
    slDeleted: 'διαγράφηκε',
    slExcluded: 'αποκλείστηκε',
    plDeleted: 'διαγράφηκαν',
    slActivated: 'ενεργοποιήθηκε',
    plActivated: 'ενεργοποιήθηκαν',
    slDeactivated: 'απενεργοποιήθηκε',
    plDeactivated: 'απενεργοποιήθηκαν',
    slImported: 'εισήχθη',
    slReset: 'επαναφέρθηκε',
    plReset: 'επαναφέρθηκαν',
    slAdded: 'προστέθηκε',
    plAdded: 'προστέθηκαν',
    slDisabled: 'απενεργοποιήθηκε',
    plDisabled: 'απενεργοποιήθηκαν',
    slEnabled: 'ενεργοποιήθηκε',
    slCreated: 'δημιουργήθηκε',
    plCreated: 'δημιουργήθηκαν',
    plMoved: 'μετακινήθηκαν',
    plMerged: 'συγχωνεύτηκαν',
    plTagged: 'επισημάνθηκαν με ετικέτα',
    slUntagged: 'δεν έχει πια ετικέτα',
    plUntagged: 'δεν έχουν πια ετικέτα',
    removedFromSegment: 'Το {{name}} αφαιρέθηκε από το τμήμα',
    playerAddedToSegment: 'Ο παίκτης προστέθηκε στο τμήμα {{name}}',
    playerRemovedFromSegment: 'Ο παίκτης αφαιρέθηκε από το τμήμα {{name}}',
    slFinished: 'τελείωσε',
    slConfigured: 'διαμορφώθηκε',
    plSent: 'στάλθηκαν',
    plRemoved: 'αφαιρέθηκε',
    slSent: 'στάλθηκε',
    setMarkAttention: 'Η επισήμανση προσοχής ορίστηκε ως {{name}}',
    slRemoved: 'αφαιρέθηκε',
    slUnlocked: 'ξεκλειδώθηκε',
    accountBlockedForMessages: 'Ο λογαριασμός {{action}} για μηνύματα',
    slBlocked: 'αποκλείστηκε',
    slUnblocked: 'δεν είναι πια υπό αποκλεισμό',
    slChanged: 'άλλαξε',
    slReactivated: 'επανενεργοποιήθηκε',
    changeAccountStatus: 'Ο λογαριασμός {{action}}',
    bulkStatusUpdate: 'Πραγματοποιήθηκε {{action}} σε επιλεγμένους παίκτες',
    slAdjusted: 'προσαρμόστηκε',
    plAdjusted: 'προσαρμόστηκαν',
    slClosed: 'έκλεισε',
    slAssigned: 'ανατέθηκε',
    plAssigned: 'ανατέθηκαν',
    finishedTestPlayersAccountUse: 'Η χρήση του δοκιμαστικού λογαριασμού παίκτη τερματίστηκε',
    adjustedTestPlayersAccountBalance: 'Το υπόλοιπο του δοκιμαστικού λογαριασμού παίκτη προσαρμόστηκε',
    slVerified: 'επαληθεύτηκε',
    plVerified: 'επαληθεύτηκε',
    slUnverified: 'δεν είναι πια επαληθευμένο',
    slSet: 'ορίστηκε',
    slIntegrated: 'ενσωματώθηκε',
    slSavedAsDraft: 'αποθηκεύτηκε ως πρόχειρο',
    slScheduledForDate: 'προγραμματίστηκε για {{date}}',
    movedToDraft: 'μεταφέρθηκε στο πρόχειρο',
    plRejected: 'απορρίφθηκαν',
    playerAccountVerifiedWith: 'Ο λογαριασμός παίκτη επαληθεύτηκε με {{docType}}',
    plApproved: 'εγκρίθηκαν',
    plUploaded: 'μεταφορτώθηκαν',
    plArchived: 'αρχειοθετήθηκαν',
    slStarted: 'ξεκίνησε',
    slMoved: 'μετακινήθηκε',
    slResent: 'στάλθηκε εκ νέου',
    playerRemovedFromRestrictionList: 'Ο παίκτης {{name}} αφαιρέθηκε από τη λίστα περιορισμών',
    plBlacklisted: 'προστέθηκαν στη λίστα αποκλεισμένων',
    plWhiteListed: 'προστέθηκαν στη λίστα επιτρεπόμενων',
    slBlacklisted: 'προστέθηκε στη λίστα αποκλεισμένων',
    slWhiteListed: 'προστέθηκε στη λίστα επιτρεπόμενων',
    slCancelled: 'ακυρώθηκε',
    playerDisconnectedFromBonus: 'Ο παίκτης {{name}} αποσυνδέθηκε από το μπόνους',
    playerRemovedFromBonus: 'Ο παίκτης {{name}} αφαιρέθηκε από το μπόνους',
    playersAddedToBonus: 'Οι παίκτες προστέθηκαν στο μπόνους',
    slIssued: 'εκδόθηκε',
    slScheduled: 'προγραμματίστηκε',
    slRenamed: 'μετονομάστηκε',
    slUploaded: 'μεταφορτώθηκε',
    slConnected: 'συνδέθηκε',
    slDisconnected: 'αποσυνδέθηκε',
    plConfigured: 'διαμορφώθηκαν',
    mailSetAsDefault: 'Το email {{name}} ορίστηκε ως προεπιλογή',
    slRestricted: 'περιορίστηκε',
    plRestricted: 'περιορίστηκαν',
    countryDeletedFromRestrictionList: 'Η χώρα {{name}} διαγράφηκε από τη λίστα περιορισμών',
    ipAddressDeletedFromRestrictionList: 'Η διεύθυνση IP {{name}} διαγράφηκε από τη λίστα περιορισμών',
    playerChangedNotificationPreferences: 'Ο παίκτης άλλαξε τις προτιμήσεις ειδοποιήσεων',
    playerNotEligible: 'Ο παίκτης δεν πληροί τις προϋποθέσεις για λήψη μηνύματος',
    bodyIsRequired: 'Το σώμα κειμένου του email δεν μπορεί να είναι κενό',
    playerCanceledFromBonus: 'Το μπόνους ακυρώθηκε για τον παίκτη {{name}}',
    noRecipientMessage: 'Δεν υπάρχει παραλήπτης για λήψη μηνύματος',
    atLeastOneDepositConfig: 'Πρέπει να διαμορφωθεί τουλάχιστον 1 κατάθεση',
    atLeastOneProvider: 'Πρέπει να επιλεγεί τουλάχιστον ένας πάροχος',
    countOrAtLeastOneAmountConfig: 'Πρέπει να διαμορφωθεί ο αριθμός ή τουλάχιστον ένα ποσό νομίσματος',
    atLeastOneCurrencyAmountConfig: 'Πρέπει να διαμορφωθεί τουλάχιστον μία διαμόρφωση ποσού νομίσματος',
    allCurrencyAmountsConfigs: 'Πρέπει να διαμορφωθούν όλες οι διαμορφώσεις ποσού νομίσματος',
    bonusNameExists: 'Το όνομα μπόνους υπάρχει ήδη',
    slPromotionCancelled: 'η προωθητική ενέργεια ακυρώθηκε',
    atLeastOneSuccessPlayer: 'Πρέπει να παρέχεται τουλάχιστον ένας παίκτης με δυνατότητα λήψης μπόνους',
    setAsDefault: 'Το {{name}} ορίστηκε ως προεπιλογή',
    promoCodeExist: 'Ο κωδικός προωθητικής ενέργειας υπάρχει ήδη',
    dataSyncError: 'Συγχρονισμός δεδομένων σε εξέλιξη',
    atLeastOneTable: 'Πρέπει να επιλεγεί τουλάχιστον 1 πίνακας',
    plSynchronized: 'συγχρονίστηκαν',
    slSynchronized: 'συγχρονίστηκε',
    lobbyProviderOrderError: 'Όλοι οι πάροχοι θα πρέπει να είναι σε σειρά',
    lobbyProviderGameOrderError: 'Όλα τα παιχνίδια θα πρέπει να είναι σε σειρά',
    atLeastOneCurrencyEnabled: 'Πρέπει να είναι ενεργοποιημένο τουλάχιστον ένα νόμισμα',
    machineSectionCannotBeEmpty: 'Οι ενότητες του μηχανισμού δεν μπορούν να είναι κενές',
    atLeastOneCondition: 'Πρέπει να συμπληρωθεί τουλάχιστον μία προϋπόθεση',
    allSectionsMustBeSelectedAndHasProbability: 'Όλες οι ενότητες πρέπει να είναι επιλεγμένες και να έχουν πιθανότητα',
    sumOfProbabilities: 'Το άθροισμα των πιθανοτήτων πρέπει να είναι 100',
    prizeAndProbabilityConfigure: 'Η ενότητα Επάθλου και Πιθανότητας πρέπει να είναι διαμορφωμένη',
    slPublished: 'δημοσιεύτηκε',
    sectionImageRequired: 'Απαιτείται μια εικόνα ενότητας',
    titleAndDescriptionRequired: 'Ο τίτλος και η περιγραφή απαιτούνται για τους Όρους και τις Προϋποθέσεις',
    configureSelectedPrizeItems: 'Παρακαλούμε διαμορφώστε τα επιλεγμένα στοιχεία επάθλου',
    atLeastOneGroupMustHasLimit: 'Τουλάχιστον μία ομάδα πρέπει να έχει όριο',
    atLeastOneGroupMustBeConfigured: 'Τουλάχιστον μία ομάδα πρέπει να διαμορφωθεί',
    atLeastTwoPrize: 'Πρέπει να επιλεγούν τουλάχιστον δύο έπαθλα',
    allSectionsMustBeConfigured: 'Όλες οι ενότητες πρέπει να διαμορφωθούν',
    mustSelectDefaultPrizeAndProbability: 'Το προεπιλεγμένο έπαθλο πρέπει είναι επιλεγμένο και να έχει πιθανότητα μεγαλύτερη από 0',
    mustSelectDefaultPrize: 'Πρέπει να επιλεγεί το προεπιλεγμένο έπαθλο',
    slTimeout: 'επί του παρόντος βρίσκεται σε προσωρινή διακοπή.',
    testPlayerResetPassword: 'Ο νέος κωδικός πρόσβασης έχει αποσταλεί στη διεύθυνση email του χρήστη',
    atLeastOneTag: 'Πρέπει να επιλεγεί τουλάχιστον μία ετικέτα',
    groupNameAlreadyExist: 'Το όνομα της ομάδας χρησιμοποιείται ήδη για την ενεργή ομάδα. Δεν μπορείτε να έχετε 2 ομάδες με το ίδιο όνομα σε ενεργή κατάσταση.',
    atLeastOnePromoCodeMustBeUploaded: 'Πρέπει να μεταφορτωθεί τουλάχιστον 1 έγκυρος κωδικός προώθησης',
    emptyFile: 'Το αρχείο είναι άδειο. Ελέγξτε το ξανά.',
    websitePageBackgroundRequired: 'Απαιτείται το φόντο της σελίδας του ιστότοπου',
    mobilePageBackgroundRequired: 'Απαιτείται το φόντο της σελίδας του κινητού',
    visibilityConfigurationUpdated: 'Οι διαμορφώσεις ορατότητας για τη μέθοδο πληρωμής {{name}} ενημερώθηκαν',
    atLeastOneTemplate: 'Πρέπει να επιλεγεί τουλάχιστον ένα πρότυπο',
    passwordResetLinkSent: 'Ένας σύνδεσμος επαναφοράς κωδικού πρόσβασης έχει αποσταλεί στη διεύθυνση email σας, ελέγξτε τον φάκελο εισερχομένων σας.',
  },
};
