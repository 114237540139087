import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { ChangeTimeZone } from 'components/brandSetting';
import { editBrandTimeZone } from 'actions/brand/brandActionCreator';
import { selectBrand, selectStaticCollectionTimeZones } from 'selectors';

const mapStateToProps = ({ brands, staticCollection }) => ({
  brand: selectBrand(brands),
  timeZones: selectStaticCollectionTimeZones(staticCollection),
});

const mapDispatchToProps = (dispatch) => bindActionCreators(
  {
    editBrandTimeZone,
  },
  dispatch,
);

export default connect(mapStateToProps, mapDispatchToProps)(ChangeTimeZone);
