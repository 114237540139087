import { createSelector } from 'reselect';

export const selectAuthUser = (state) => state.user;
export const selectAuthUserPartnerAccesses = (state) => state.partnerAccesses;
export const selectAuthenticatedStatus = (state) => state.isAuthenticated;
export const selectAuthUserPartnerId = (state) => selectAuthUser(state).company.id;
export const selectAuthUserPartnerName = (state) => selectAuthUser(state).company.name;
export const selectAuthUserId = (state) => selectAuthUser(state).id;
export const selectAuthIsTFAEnabled = (state) => state.isTFAEnabled;
export const selectUserPermissions = (state) => selectAuthUser(state).permissions;
export const selectAuthIsPasswordExpired = (state) => state.isPasswordExpired;
export const selectAuthTokenState = (state) => state.isTokenChanged;

export const selectUserClaims = (state) => selectAuthUser(state)?.userClaims;
export const selectHasClaimType = createSelector([selectUserClaims, (state, type) => type], (userClaims, type) => userClaims?.includes(type));

export const selectUserPartnerAccessIds = (state) => selectAuthUser(state)?.partnerAccessIds;
