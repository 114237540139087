import {
  all, call, put, takeLatest,
} from 'redux-saga/effects';
import qs from 'qs';
import mime from 'mimetypes';
import { AppConstants } from 'constants/index';
import { getError, getMessage } from 'utils/helpers';
import {
  savePromotions,
  savePromotion,
  saveCreatedPromotionId,
  changeGeneralConfigEndedStatus,
  changeSpinDistributionEndedStatus,
  saveSpinDistributions,
  saveSpinDistribution,
  changeMachineEndedStatus,
  saveMachines,
  saveMachine,
  changePlayingParticipantEndedStatus,
  savePlayingParticipants,
  savePlayingParticipant,
  savePrizes,
  savePrize,
  changePrizeEndedStatus,
  saveMachineConfig,
  changeMachineConfigEndedStatus,
  saveGroupLimit,
  changeGroupLimitEndedStatus,
  saveMachineContent,
  changeMachineContentEndedStatus,
  saveMachineContents,
  saveMachineDetails,
  savePromotionTransactions,
  savePromotionMachines,
  savePromotionPrizes,
  savePromotionPlayers,
  changePublishEndedStatus,
  savePromotionCsvPlayers,
  savePromotionManualSpinReport,
  changePlayersEndedStatus,
  savePromoSpinAdjustmentPlayers,
  savePlayerPromotionSpins,
  savePlayerPromotionTransactions,
  saveIsPromotionValidForPreview,
  savePromotionContent,
  savePromotionContents,
  changePromotionContentEndedStatus,
  savePrivatePromotionParticipants,
  savePromotionPrizeMaterialStatistics,
  saveSpinDistributionContents,
  saveSpinDistributionContent,
  changeSpinDistributionContentEndedStatus,
  savePromotionSpins,
  savePromotionSpinStatistics,
  savePromotionExchangeItem,
  changePromotionExchangeEndedStatus,
  savePromotionExchangeItems,
  savePromotionPrizeMachineSpinStatistics,
  changePrizeContentEndedStatus,
  savePrizeContents,
  savePrizeContent,
  savePrizeContentConnected,
  savePromotionPrizeBalanceMultiplierStatistics,
  saveMachineConfigs,
  savePromotionExchanges,
  savePromotionChangeLogs,
  savePromotionChangeLog,
} from 'actions/promotion/promotionActionCreator';
import {
  setErrorMessage, changeLoadingState, changeEndedStatus, setSuccessMessage,
} from 'actions/setting/settingActionCreator';
import NetworkService from 'services/networkService';
import PromotionActionType from 'actions/promotion/promotionActionType';
import { i18n } from 'services';
import { downloadFile } from 'utils/utils';

function* getPromotions({ payload }) {
  try {
    const { params } = payload;
    const options = {
      params,
      paramsSerializer: (param) => qs.stringify(param),
    };
    const { PromoAdmin, Promotions } = AppConstants.api;
    yield put(changeLoadingState(true));
    const { data } = yield call(NetworkService.makeAPIGetRequest, [PromoAdmin, Promotions], options);
    yield put(savePromotions(data));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getPromotion({ payload }) {
  const { id, isLoading } = payload;
  try {
    const { PromoAdmin, Promotions } = AppConstants.api;
    if (isLoading) {
      yield put(changeLoadingState(true));
    }
    const { data } = yield call(NetworkService.makeAPIGetRequest, [PromoAdmin, Promotions, id]);
    yield put(savePromotion(data));
    if (isLoading) {
      yield put(changeLoadingState(false));
    }
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    if (isLoading) {
      yield put(changeLoadingState(false));
    }
  }
}

function* createPromotion({ payload }) {
  try {
    const { data } = payload;
    const { name } = data;
    const { PromoAdmin, Promotions } = AppConstants.api;
    yield put(changeLoadingState(true));
    const { data: id } = yield call(NetworkService.makeAPIPostRequest, [PromoAdmin, Promotions], payload);
    yield put(saveCreatedPromotionId(id));
    yield put(changeLoadingState(false));
    yield put(changeEndedStatus(true));
    yield put(setSuccessMessage(getMessage(i18n.t('promotion:slPromotion'), i18n.t('notification:slCreated'), name)));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* editPromotion({ payload }) {
  try {
    const { id, data } = payload;
    const options = {
      data,
    };
    const { name } = data;
    const { PromoAdmin, Promotions } = AppConstants.api;
    yield put(changeLoadingState(true));
    yield call(NetworkService.makeAPIPutRequest, [PromoAdmin, Promotions, id], options);
    yield put(changeGeneralConfigEndedStatus(true));
    yield put(changeLoadingState(false));
    yield put(setSuccessMessage(getMessage(i18n.t('promotion:slPromotion'), i18n.t('notification:slUpdated'), name)));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getSpinDistributions({ payload }) {
  try {
    const { promotionId } = payload;
    const { PromoAdmin, Promotions, SpinDistributingConfigs } = AppConstants.api;
    yield put(changeLoadingState(true));
    const { data } = yield call(NetworkService.makeAPIGetRequest, [PromoAdmin, Promotions, promotionId, SpinDistributingConfigs]);
    yield put(saveSpinDistributions(data));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* createSpinDistribution({ payload }) {
  try {
    const { promotionId, data, name } = payload;
    const options = {
      data,
    };
    const { PromoAdmin, Promotions, SpinDistributingConfigs } = AppConstants.api;
    yield put(changeLoadingState(true));
    yield call(NetworkService.makeAPIPostRequest, [PromoAdmin, Promotions, promotionId, SpinDistributingConfigs], options);
    yield put(setSuccessMessage(getMessage(i18n.t('promotion:lSpinDistribution'), i18n.t('notification:slCreated'), name)));
    yield put(changeSpinDistributionEndedStatus(true));
    yield put(changeGeneralConfigEndedStatus(true));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* editSpinDistribution({ payload }) {
  try {
    const {
      promotionId, id, data, name,
    } = payload;
    const options = {
      data,
    };
    const { PromoAdmin, Promotions, SpinDistributingConfigs } = AppConstants.api;
    yield put(changeLoadingState(true));
    yield call(NetworkService.makeAPIPutRequest, [PromoAdmin, Promotions, promotionId, SpinDistributingConfigs, id], options);
    yield put(setSuccessMessage(getMessage(i18n.t('promotion:lSpinDistribution'), i18n.t('notification:slUpdated'), name)));
    yield put(changeSpinDistributionEndedStatus(true));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getSpinDistribution({ payload }) {
  try {
    const { promotionId, id } = payload;
    const { PromoAdmin, Promotions, SpinDistributingConfigs } = AppConstants.api;
    yield put(changeLoadingState(true));
    const { data } = yield call(NetworkService.makeAPIGetRequest, [PromoAdmin, Promotions, promotionId, SpinDistributingConfigs, id]);
    yield put(saveSpinDistribution(data));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* deleteSpinDistribution({ payload }) {
  try {
    const { promotionId, id, name } = payload;
    const { PromoAdmin, Promotions, SpinDistributingConfigs } = AppConstants.api;
    yield put(changeLoadingState(true));
    yield call(NetworkService.makeAPIDeleteRequest, [PromoAdmin, Promotions, promotionId, SpinDistributingConfigs, id]);
    yield put(setSuccessMessage(getMessage(i18n.t('promotion:lSpinDistribution'), i18n.t('notification:slDeleted'), name)));
    yield put(changeSpinDistributionEndedStatus(true));
    yield put(changeGeneralConfigEndedStatus(true));
    yield put(changeSpinDistributionContentEndedStatus(true));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getPrizes({ payload }) {
  try {
    const { promotionId } = payload;
    const { PromoAdmin, Promotions, Prizes } = AppConstants.api;
    yield put(changeLoadingState(true));
    const { data } = yield call(NetworkService.makeAPIGetRequest, [PromoAdmin, Promotions, promotionId, Prizes]);
    yield put(savePrizes(data));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getPrize({ payload }) {
  try {
    const { id } = payload;
    const { PromoAdmin, Prizes } = AppConstants.api;
    yield put(changeLoadingState(true));
    const { data } = yield call(NetworkService.makeAPIGetRequest, [PromoAdmin, Prizes, id]);
    yield put(savePrize(data));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* createPrize({ payload }) {
  try {
    const { data, name } = payload;
    const options = {
      data,
    };
    const { PromoAdmin, Prizes } = AppConstants.api;
    yield put(changeLoadingState(true));
    yield call(NetworkService.makeAPIPostRequest, [PromoAdmin, Prizes], options);
    yield put(setSuccessMessage(getMessage(i18n.t('promotion:lPrize'), i18n.t('notification:slCreated'), name)));
    yield put(changeGeneralConfigEndedStatus(true));
    yield put(changePrizeEndedStatus(true));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* editPrize({ payload }) {
  try {
    const { id, data } = payload;
    const options = {
      data,
    };
    const { PromoAdmin, Prizes } = AppConstants.api;
    yield put(changeLoadingState(true));
    yield call(NetworkService.makeAPIPutRequest, [PromoAdmin, Prizes, id], options);
    yield put(setSuccessMessage(getMessage(i18n.t('promotion:prize'), i18n.t('notification:slUpdated'))));
    yield put(changePrizeEndedStatus(true));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* deletePrize({ payload }) {
  try {
    const { id, name } = payload;
    const { PromoAdmin, Prizes } = AppConstants.api;
    yield put(changeLoadingState(true));
    yield call(NetworkService.makeAPIDeleteRequest, [PromoAdmin, Prizes, id]);
    yield put(setSuccessMessage(getMessage(i18n.t('promotion:lPrize'), i18n.t('notification:slDeleted'), name)));
    yield put(changeGeneralConfigEndedStatus(true));
    yield put(changePrizeEndedStatus(true));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getMachines({ payload }) {
  try {
    const { PromoAdmin, Machines } = AppConstants.api;
    const { params } = payload;
    const options = {
      params,
    };
    yield put(changeLoadingState(true));
    const { data } = yield call(NetworkService.makeAPIGetRequest, [PromoAdmin, Machines], options);
    yield put(saveMachines(data));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* createMachine({ payload }) {
  try {
    const { data } = payload;
    const { name } = data;
    const options = {
      data,
    };
    const { PromoAdmin, Machines } = AppConstants.api;
    yield put(changeLoadingState(true));
    yield call(NetworkService.makeAPIPostRequest, [PromoAdmin, Machines], options);
    yield put(setSuccessMessage(getMessage(i18n.t('promotion:lMachine'), i18n.t('notification:slCreated'), name)));
    yield put(changeMachineEndedStatus(true));
    yield put(changeGeneralConfigEndedStatus(true));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* editMachine({ payload }) {
  try {
    const { id, data } = payload;

    const { name } = data;

    const options = {
      data,
    };
    const { PromoAdmin, Machines } = AppConstants.api;
    yield put(changeLoadingState(true));
    yield call(NetworkService.makeAPIPutRequest, [PromoAdmin, Machines, id], options);
    yield put(setSuccessMessage(getMessage(i18n.t('promotion:lMachine'), i18n.t('notification:slUpdated'), name)));
    yield put(changeMachineEndedStatus(true));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getMachine({ payload }) {
  try {
    const { id } = payload;
    const { PromoAdmin, Machines } = AppConstants.api;
    yield put(changeLoadingState(true));
    const { data } = yield call(NetworkService.makeAPIGetRequest, [PromoAdmin, Machines, id]);
    yield put(saveMachine(data));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getMachineDetails({ payload }) {
  try {
    const { id } = payload;
    const { PromoAdmin, Machines, Details } = AppConstants.api;
    yield put(changeLoadingState(true));
    const { data } = yield call(NetworkService.makeAPIGetRequest, [PromoAdmin, Machines, id, Details]);
    yield put(saveMachineDetails(data));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* deleteMachine({ payload }) {
  try {
    const { id, name } = payload;
    const { PromoAdmin, Machines } = AppConstants.api;
    yield put(changeLoadingState(true));
    yield call(NetworkService.makeAPIDeleteRequest, [PromoAdmin, Machines, id]);
    yield put(setSuccessMessage(getMessage(i18n.t('promotion:lMachine'), i18n.t('notification:slDeleted'), name)));
    yield put(changeMachineEndedStatus(true));
    yield put(changeMachineContentEndedStatus(true));
    yield put(changeGeneralConfigEndedStatus(true));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getPlayingParticipants({ payload }) {
  try {
    const { PromoAdmin, Promotions, MachineCondition } = AppConstants.api;
    yield put(changeLoadingState(true));
    const { data } = yield call(NetworkService.makeAPIGetRequest, [PromoAdmin, Promotions, payload.id, MachineCondition]);
    yield put(savePlayingParticipants(data));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* createPlayingParticipant({ payload }) {
  try {
    const { id, data } = payload;
    const options = {
      data,
    };
    const { PromoAdmin, Promotions, MachineCondition } = AppConstants.api;
    yield put(changeLoadingState(true));
    yield call(NetworkService.makeAPIPostRequest, [PromoAdmin, Promotions, id, MachineCondition], options);
    yield put(setSuccessMessage(getMessage(i18n.t('promotion:lPlayingParticipant'), i18n.t('notification:slCreated'))));
    yield put(changePlayingParticipantEndedStatus(true));
    yield put(changeGeneralConfigEndedStatus(true));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* editPlayingParticipant({ payload }) {
  try {
    const { id, participantId, data } = payload;

    const { name } = data;

    const options = {
      data,
    };
    const { PromoAdmin, Promotions, MachineCondition } = AppConstants.api;
    yield put(changeLoadingState(true));
    yield call(NetworkService.makeAPIPutRequest, [PromoAdmin, Promotions, id, MachineCondition, participantId], options);
    yield put(setSuccessMessage(getMessage(i18n.t('promotion:lPlayingParticipant'), i18n.t('notification:slUpdated'), name)));
    yield put(changePlayingParticipantEndedStatus(true));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getPlayingParticipant({ payload }) {
  try {
    const { id, participantId } = payload;
    const { PromoAdmin, Promotions, MachineCondition } = AppConstants.api;
    yield put(changeLoadingState(true));
    const { data } = yield call(NetworkService.makeAPIGetRequest, [PromoAdmin, Promotions, id, MachineCondition, participantId]);
    yield put(savePlayingParticipant(data));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* deletePlayingParticipant({ payload }) {
  try {
    const { id, data } = payload;
    const { id: participantId, name } = data;
    const { PromoAdmin, Promotions, MachineCondition } = AppConstants.api;
    yield put(changeLoadingState(true));
    yield call(NetworkService.makeAPIDeleteRequest, [PromoAdmin, Promotions, id, MachineCondition, participantId]);
    yield put(setSuccessMessage(getMessage(i18n.t('promotion:lPlayingParticipant'), i18n.t('notification:slDeleted'), name)));
    yield put(changePlayingParticipantEndedStatus(true));
    yield put(changeGeneralConfigEndedStatus(true));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getContents({ payload }) {
  try {
    const { promotionId } = payload;
    const { PromoAdmin, Promotions, MachineContents } = AppConstants.api;
    yield put(changeLoadingState(true));
    const { data } = yield call(NetworkService.makeAPIGetRequest, [PromoAdmin, Promotions, promotionId, MachineContents]);
    yield put(saveMachineContents(data));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getMachineContent({ payload }) {
  try {
    const { params } = payload;
    const options = {
      params,
    };
    const { PromoAdmin, Contents, Machine } = AppConstants.api;
    yield put(changeLoadingState(true));
    const { data } = yield call(NetworkService.makeAPIGetRequest, [PromoAdmin, Contents, Machine], options);
    yield put(saveMachineContent(data));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* createMachineContent({ payload }) {
  try {
    const { data } = payload;
    const options = {
      data,
    };
    const { PromoAdmin, Contents, Machine } = AppConstants.api;
    yield put(changeLoadingState(true));
    yield call(NetworkService.makeAPIPostRequest, [PromoAdmin, Contents, Machine], options);
    yield put(setSuccessMessage(getMessage(i18n.t('promotion:content'), i18n.t('notification:slCreated'))));
    yield put(changeGeneralConfigEndedStatus(true));
    yield put(changeMachineContentEndedStatus(true));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* editMachineContent({ payload }) {
  try {
    const { params, data } = payload;
    const options = {
      data,
      params,
    };
    const { PromoAdmin, Contents, Machine } = AppConstants.api;
    yield put(changeLoadingState(true));
    yield call(NetworkService.makeAPIPutRequest, [PromoAdmin, Contents, Machine], options);
    yield put(setSuccessMessage(getMessage(i18n.t('promotion:content'), i18n.t('notification:slUpdated'))));
    yield put(changeMachineContentEndedStatus(true));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* deleteMachineContent({ payload }) {
  try {
    const { params } = payload;
    const options = {
      params,
    };
    const { PromoAdmin, Contents, Machine } = AppConstants.api;
    yield put(changeLoadingState(true));
    yield call(NetworkService.makeAPIDeleteRequest, [PromoAdmin, Contents, Machine], options);
    yield put(setSuccessMessage(getMessage(i18n.t('promotion:content'), i18n.t('notification:slDeleted'))));
    yield put(changeGeneralConfigEndedStatus(true));
    yield put(changeMachineContentEndedStatus(true));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getMachineConfigs({ payload }) {
  try {
    const { PromoAdmin, Machines, Configs } = AppConstants.api;
    const { params } = payload;
    const options = {
      params,
    };
    yield put(changeLoadingState(true));
    const { data } = yield call(NetworkService.makeAPIGetRequest, [PromoAdmin, Machines, Configs], options);
    yield put(saveMachineConfigs(data));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getMachineConfig({ payload }) {
  try {
    const { id } = payload;
    const { PromoAdmin, Machines, Configure } = AppConstants.api;
    yield put(changeLoadingState(true));
    const { data } = yield call(NetworkService.makeAPIGetRequest, [PromoAdmin, Machines, id, Configure]);
    yield put(saveMachineConfig(data));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* createMachineConfig({ payload }) {
  try {
    const { id, data } = payload;
    const { name } = data;
    const options = {
      data,
    };
    const { PromoAdmin, Machines, Configure } = AppConstants.api;
    yield put(changeLoadingState(true));
    yield call(NetworkService.makeAPIPostRequest, [PromoAdmin, Machines, id, Configure], options);
    yield put(setSuccessMessage(getMessage(i18n.t('promotion:lMachineConfig'), i18n.t('notification:slCreated'), name)));
    yield put(changeGeneralConfigEndedStatus(true));
    yield put(changeMachineConfigEndedStatus(true));
    yield put(changeMachineEndedStatus(true));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* editMachineConfig({ payload }) {
  try {
    const { id, data } = payload;
    const options = {
      data,
    };
    const { PromoAdmin, Machines, Configure } = AppConstants.api;
    yield put(changeLoadingState(true));
    yield call(NetworkService.makeAPIPostRequest, [PromoAdmin, Machines, id, Configure], options);
    yield put(setSuccessMessage(getMessage(i18n.t('promotion:lMachineConfig'), i18n.t('notification:slUpdated'))));
    yield put(changeMachineConfigEndedStatus(true));
    yield put(changeMachineEndedStatus(true));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* deleteMachineConfig({ payload }) {
  try {
    const { id, name } = payload;
    const { PromoAdmin, Machines, Configure } = AppConstants.api;
    yield put(changeLoadingState(true));
    yield call(NetworkService.makeAPIDeleteRequest, [PromoAdmin, Machines, id, Configure]);
    yield put(setSuccessMessage(getMessage(i18n.t('promotion:lMachineConfig'), i18n.t('notification:slDeleted'), name)));
    yield put(changeGeneralConfigEndedStatus(true));
    yield put(changeMachineConfigEndedStatus(true));
    yield put(changeMachineEndedStatus(true));
    yield put(changeSpinDistributionEndedStatus(true));
    yield put(changePrizeEndedStatus(true));
    yield put(changePlayingParticipantEndedStatus(true));
    yield put(changeMachineContentEndedStatus(true));
    yield put(changePrizeContentEndedStatus(true));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getGroupLimit({ payload }) {
  try {
    const { id } = payload;
    const { PromoAdmin, Promotions, GroupLimits } = AppConstants.api;
    yield put(changeLoadingState(true));
    const { data } = yield call(NetworkService.makeAPIGetRequest, [PromoAdmin, Promotions, id, GroupLimits]);
    yield put(saveGroupLimit(data));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* createGroupLimit({ payload }) {
  try {
    const { id, data } = payload;
    const { name } = data;
    const options = {
      data,
    };
    const { PromoAdmin, Promotions, GroupLimits } = AppConstants.api;
    yield put(changeLoadingState(true));
    yield call(NetworkService.makeAPIPostRequest, [PromoAdmin, Promotions, id, GroupLimits], options);
    yield put(setSuccessMessage(getMessage(i18n.t('promotion:lGroupLimit'), i18n.t('notification:slCreated'), name)));
    yield put(changeGeneralConfigEndedStatus(true));
    yield put(changeGroupLimitEndedStatus(true));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* editGroupLimit({ payload }) {
  try {
    const { id, data } = payload;
    const options = {
      data,
    };
    const { PromoAdmin, Promotions, GroupLimits } = AppConstants.api;
    yield put(changeLoadingState(true));
    yield call(NetworkService.makeAPIPutRequest, [PromoAdmin, Promotions, id, GroupLimits], options);
    yield put(setSuccessMessage(getMessage(i18n.t('promotion:lGroupLimit'), i18n.t('notification:slUpdated'))));
    yield put(changeGroupLimitEndedStatus(true));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* deleteGroupLimit({ payload }) {
  try {
    const { id } = payload;
    const { PromoAdmin, Promotions, GroupLimits } = AppConstants.api;
    yield put(changeLoadingState(true));
    yield call(NetworkService.makeAPIDeleteRequest, [PromoAdmin, Promotions, id, GroupLimits]);
    yield put(setSuccessMessage(getMessage(i18n.t('promotion:lGroupLimit'), i18n.t('notification:slDeleted'))));
    yield put(changeGeneralConfigEndedStatus(true));
    yield put(changeGroupLimitEndedStatus(true));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* publishPromotion({ payload }) {
  try {
    const { id, data } = payload;
    const options = {
      data,
    };
    const { name } = data;
    const { PromoAdmin, Promotions, Publish } = AppConstants.api;
    yield put(changeLoadingState(true));
    yield call(NetworkService.makeAPIPostRequest, [PromoAdmin, Promotions, id, Publish], options);
    yield put(setSuccessMessage(getMessage(i18n.t('promotion:lPromotion'), i18n.t('notification:slPublished'), name)));
    yield put(changePublishEndedStatus(true));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* deletePromotion({ payload }) {
  try {
    const { id, name } = payload;
    const { PromoAdmin, Promotions } = AppConstants.api;
    yield put(changeLoadingState(true));
    yield call(NetworkService.makeAPIDeleteRequest, [PromoAdmin, Promotions, id]);
    yield put(setSuccessMessage(getMessage(i18n.t('promotion:lPromotion'), i18n.t('notification:slDeleted'), name)));
    yield put(changeEndedStatus(true));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* finishPromotion({ payload }) {
  try {
    const { id, name } = payload;
    const { PromoAdmin, Promotions, Finish } = AppConstants.api;
    yield put(changeLoadingState(true));
    yield call(NetworkService.makeAPIPutRequest, [PromoAdmin, Promotions, id, Finish]);
    yield put(setSuccessMessage(getMessage(i18n.t('promotion:lPromotion'), i18n.t('notification:slFinished'), name)));
    yield put(changeEndedStatus(true));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* changePromotionStatus({ payload }) {
  try {
    const { id, params, data } = payload;
    const options = {
      params,
      data,
    };
    const { name } = data;
    const { isEnabled } = params;
    const { PromoAdmin, Promotions, Status } = AppConstants.api;
    yield put(changeLoadingState(true));
    yield call(NetworkService.makeAPIPutRequest, [PromoAdmin, Promotions, id, Status], options);
    yield put(changeEndedStatus(true));
    yield put(changeLoadingState(false));
    yield put(setSuccessMessage(getMessage(i18n.t('promotion:slPromotion'), i18n.t(isEnabled ? 'notification:slEnabled' : 'notification:slDisabled'), name)));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* clonePromotion({ payload }) {
  try {
    const { data } = payload;
    const { name } = data;
    const { PromoAdmin, Promotions, Clone } = AppConstants.api;
    yield put(changeLoadingState(true));
    const { data: id } = yield call(NetworkService.makeAPIPostRequest, [PromoAdmin, Promotions, Clone], payload);
    yield put(saveCreatedPromotionId(id));
    yield put(changeLoadingState(false));
    yield put(changeEndedStatus(true));
    yield put(setSuccessMessage(getMessage(i18n.t('promotion:slPromotion'), i18n.t('notification:slCreated'), name)));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getPromotionTransactions({ payload }) {
  try {
    const { id, params } = payload;
    const options = {
      params,
      paramsSerializer: (param) => qs.stringify(param),
    };
    const { PromoAdmin, Promotions, Transactions } = AppConstants.api;
    yield put(changeLoadingState(true));
    const { data } = yield call(NetworkService.makeAPIGetRequest, [PromoAdmin, Promotions, id, Transactions], options);
    yield put(savePromotionTransactions(data));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* exportPromotionTransactions({ payload }) {
  try {
    const { id, params } = payload;
    const options = {
      params,
      paramsSerializer: (param) => qs.stringify(param, { allowDots: true }),
      responseType: 'blob',
    };
    const {
      PromoAdmin, Promotions, Transactions, Export,
    } = AppConstants.api;
    yield put(changeLoadingState(true));
    const { data, headers } = yield call(NetworkService.makeAPIGetRequest, [PromoAdmin, Promotions, id, Transactions, Export], options);
    downloadFile(data, i18n.t('promotion:promotionTransactions'), mime.detectExtension(headers['content-type']));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getPromotionMachines({ payload }) {
  try {
    const { id, params } = payload;
    const options = {
      params,
      paramsSerializer: (param) => qs.stringify(param),
    };
    const { PromoAdmin, Promotions, Machines } = AppConstants.api;
    yield put(changeLoadingState(true));
    const { data } = yield call(NetworkService.makeAPIGetRequest, [PromoAdmin, Promotions, id, Machines], options);
    yield put(savePromotionMachines(data));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* exportPromotionMachines({ payload }) {
  try {
    const { id, params } = payload;
    const options = {
      params,
      paramsSerializer: (param) => qs.stringify(param, { allowDots: true }),
      responseType: 'blob',
    };
    const {
      PromoAdmin, Promotions, Machines, Export,
    } = AppConstants.api;
    yield put(changeLoadingState(true));
    const { data, headers } = yield call(NetworkService.makeAPIGetRequest, [PromoAdmin, Promotions, id, Machines, Export], options);
    downloadFile(data, i18n.t('promotion:promotionMachines'), mime.detectExtension(headers['content-type']));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getPromotionPrizes({ payload }) {
  try {
    const { id, params } = payload;
    const options = {
      params,
      paramsSerializer: (param) => qs.stringify(param),
    };
    const { PromoAdmin, Promotions, PrizeReports } = AppConstants.api;
    yield put(changeLoadingState(true));
    const { data } = yield call(NetworkService.makeAPIGetRequest, [PromoAdmin, Promotions, id, PrizeReports], options);
    yield put(savePromotionPrizes(data));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* exportPromotionPrizes({ payload }) {
  try {
    const { id, params } = payload;
    const options = {
      params,
      paramsSerializer: (param) => qs.stringify(param, { allowDots: true }),
      responseType: 'blob',
    };
    const {
      PromoAdmin, Promotions, PrizeReports, Export,
    } = AppConstants.api;
    yield put(changeLoadingState(true));
    const { data, headers } = yield call(NetworkService.makeAPIGetRequest, [PromoAdmin, Promotions, id, PrizeReports, Export], options);
    downloadFile(data, i18n.t('promotion:promotionPrizes'), mime.detectExtension(headers['content-type']));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getPromotionPlayers({ payload }) {
  try {
    const { id, params } = payload;
    const options = {
      params,
      paramsSerializer: (param) => qs.stringify(param),
    };
    const { PromoAdmin, Promotions, Players } = AppConstants.api;
    yield put(changeLoadingState(true));
    const { data } = yield call(NetworkService.makeAPIGetRequest, [PromoAdmin, Promotions, id, Players], options);
    yield put(savePromotionPlayers(data));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* exportPromotionPlayers({ payload }) {
  try {
    const { id, params } = payload;
    const options = {
      params,
      paramsSerializer: (param) => qs.stringify(param, { allowDots: true }),
      responseType: 'blob',
    };
    const {
      PromoAdmin, Promotions, Players, Export,
    } = AppConstants.api;
    yield put(changeLoadingState(true));
    const { data, headers } = yield call(NetworkService.makeAPIGetRequest, [PromoAdmin, Promotions, id, Players, Export], options);
    downloadFile(data, i18n.t('promotion:promotionPlayers'), mime.detectExtension(headers['content-type']));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getPromotionCsvPlayers({ payload }) {
  try {
    const { data } = payload;
    const options = {
      data,
    };
    const { PromoAdmin, Promotions, GetCSVPlayers } = AppConstants.api;
    yield put(changeLoadingState(true));
    const { data: result } = yield call(NetworkService.makeAPIPostRequest, [PromoAdmin, Promotions, GetCSVPlayers], options);
    yield put(savePromotionCsvPlayers(result));
    yield put(changeLoadingState(false));
    yield put(changePlayersEndedStatus(true));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* adjustManualSpin({ payload }) {
  try {
    const { PromoAdmin, Promotions, ManualSpin } = AppConstants.api;
    yield put(changeLoadingState(true));
    yield call(NetworkService.makeAPIPostRequest, [PromoAdmin, Promotions, ManualSpin], payload);
    yield put(changeLoadingState(false));
    yield put(changeEndedStatus(true));
    yield put(setSuccessMessage(getMessage(i18n.t('promotion:spins'), i18n.t('notification:slAdjusted'))));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getPromotionManualSpinReport({ payload }) {
  try {
    const { params } = payload;
    const options = {
      params,
      paramsSerializer: (param) => qs.stringify(param),
    };
    const { PromoAdmin, Promotions, ManualSpinReports } = AppConstants.api;
    yield put(changeLoadingState(true));
    const { data } = yield call(NetworkService.makeAPIGetRequest, [PromoAdmin, Promotions, ManualSpinReports], options);
    yield put(savePromotionManualSpinReport(data));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getPromoSpinAdjustmentPlayers({ payload }) {
  try {
    const { id, params } = payload;
    const options = {
      params,
      paramsSerializer: (param) => qs.stringify(param),
    };
    const { PromoAdmin, Promotions, ManualSpin } = AppConstants.api;
    yield put(changeLoadingState(true));
    const { data } = yield call(NetworkService.makeAPIGetRequest, [PromoAdmin, Promotions, id, ManualSpin], options);
    yield put(savePromoSpinAdjustmentPlayers(data));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getPlayerPromotionSpins({ payload }) {
  try {
    const { playerId, params } = payload;
    const options = {
      params,
      paramsSerializer: (param) => qs.stringify(param),
    };
    const { PromoAdmin, PlayerReports, Spins } = AppConstants.api;
    yield put(changeLoadingState(true));
    const { data } = yield call(NetworkService.makeAPIGetRequest, [PromoAdmin, PlayerReports, playerId, Spins], options);
    yield put(savePlayerPromotionSpins(data));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getPlayerPromotionTransactions({ payload }) {
  try {
    const { playerId, params } = payload;
    const options = {
      params,
      paramsSerializer: (param) => qs.stringify(param),
    };
    const { PromoAdmin, PlayerReports, Transactions } = AppConstants.api;
    yield put(changeLoadingState(true));
    const { data } = yield call(NetworkService.makeAPIGetRequest, [PromoAdmin, PlayerReports, playerId, Transactions], options);
    yield put(savePlayerPromotionTransactions(data));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* adjustPlayerManualSpin({ payload }) {
  try {
    const { promotionId, data } = payload;
    const options = {
      data,
    };
    const { PromoAdmin, Promotions, AdjustManualSpin } = AppConstants.api;
    yield put(changeLoadingState(true));
    yield call(NetworkService.makeAPIPostRequest, [PromoAdmin, Promotions, promotionId, AdjustManualSpin], options);
    yield put(changeLoadingState(false));
    yield put(changeEndedStatus(true));
    yield put(setSuccessMessage(getMessage(i18n.t('promotion:spins'), i18n.t('notification:plAdjusted'))));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getPromotionPreviewValidationState({ payload }) {
  try {
    const { id } = payload;
    const { PromoAdmin, Promotions, Validate } = AppConstants.api;
    yield put(changeLoadingState(true));
    yield call(NetworkService.makeAPIGetRequest, [PromoAdmin, Promotions, id, Validate]);
    yield put(saveIsPromotionValidForPreview(true));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getPromotionContents({ payload }) {
  try {
    const { promotionId } = payload;
    const { PromoAdmin, Promotions, PromotionContents } = AppConstants.api;
    yield put(changeLoadingState(true));
    const { data } = yield call(NetworkService.makeAPIGetRequest, [PromoAdmin, Promotions, promotionId, PromotionContents]);
    yield put(savePromotionContents(data));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getPromotionContent({ payload }) {
  try {
    const { params } = payload;
    const options = {
      params,
    };
    const { PromoAdmin, Contents, Promotion } = AppConstants.api;
    yield put(changeLoadingState(true));
    const { data } = yield call(NetworkService.makeAPIGetRequest, [PromoAdmin, Contents, Promotion], options);
    yield put(savePromotionContent(data));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* createPromotionContent({ payload }) {
  try {
    const { data } = payload;
    const options = {
      data,
    };
    const { PromoAdmin, Contents, Promotion } = AppConstants.api;
    yield put(changeLoadingState(true));
    yield call(NetworkService.makeAPIPostRequest, [PromoAdmin, Contents, Promotion], options);
    yield put(setSuccessMessage(getMessage(i18n.t('promotion:content'), i18n.t('notification:slCreated'))));
    yield put(changeGeneralConfigEndedStatus(true));
    yield put(changePromotionContentEndedStatus(true));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* editPromotionContent({ payload }) {
  try {
    const { params, data } = payload;
    const options = {
      data,
      params,
    };
    const { PromoAdmin, Contents, Promotion } = AppConstants.api;
    yield put(changeLoadingState(true));
    yield call(NetworkService.makeAPIPutRequest, [PromoAdmin, Contents, Promotion], options);
    yield put(setSuccessMessage(getMessage(i18n.t('promotion:content'), i18n.t('notification:slUpdated'))));
    yield put(changePromotionContentEndedStatus(true));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* deletePromotionContent({ payload }) {
  try {
    const { params } = payload;
    const options = {
      params,
    };
    const { PromoAdmin, Contents, Promotion } = AppConstants.api;
    yield put(changeLoadingState(true));
    yield call(NetworkService.makeAPIDeleteRequest, [PromoAdmin, Contents, Promotion], options);
    yield put(setSuccessMessage(getMessage(i18n.t('promotion:content'), i18n.t('notification:slDeleted'))));
    yield put(changeGeneralConfigEndedStatus(true));
    yield put(changePromotionContentEndedStatus(true));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}
function* getPrivatePromotionParticipants({ payload }) {
  try {
    const { promotionId, params } = payload;
    const options = {
      params,
      paramsSerializer: (param) => qs.stringify(param),
    };
    const { PromoAdmin, Promotions, PrivateParticipants } = AppConstants.api;
    yield put(changeLoadingState(true));
    const { data } = yield call(NetworkService.makeAPIGetRequest, [PromoAdmin, Promotions, promotionId, PrivateParticipants], options);
    yield put(savePrivatePromotionParticipants(data));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* exportPrivatePromotionParticipants({ payload }) {
  try {
    const { promotionId, params } = payload;
    const options = {
      params,
      paramsSerializer: (param) => qs.stringify(param, { allowDots: true }),
    };
    const {
      PromoAdmin, Promotions, PrivateParticipants, Export,
    } = AppConstants.api;
    yield put(changeLoadingState(true));
    const { data, headers } = yield call(NetworkService.makeAPIGetRequest, [PromoAdmin, Promotions, promotionId, PrivateParticipants, Export], options);
    downloadFile(data, i18n.t('promotion:promotionPlayers'), mime.detectExtension(headers['content-type']));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getPromotionPrizeMaterialStatistics({ payload }) {
  try {
    const { promotionId, params } = payload;
    const options = {
      params,
      paramsSerializer: (param) => qs.stringify(param),
    };
    const { PromoAdmin, Promotions, MaterialPrizeStatistics } = AppConstants.api;
    yield put(changeLoadingState(true));
    const { data } = yield call(NetworkService.makeAPIGetRequest, [PromoAdmin, Promotions, promotionId, MaterialPrizeStatistics], options);
    yield put(savePromotionPrizeMaterialStatistics(data));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* exportPromotionPrizeMaterialStatistics({ payload }) {
  try {
    const { promotionId, params } = payload;
    const options = {
      params,
      paramsSerializer: (param) => qs.stringify(param),
    };
    const {
      PromoAdmin, Promotions, MaterialPrizeStatistics, Export,
    } = AppConstants.api;
    yield put(changeLoadingState(true));
    const { data, headers } = yield call(NetworkService.makeAPIGetRequest, [PromoAdmin, Promotions, promotionId, MaterialPrizeStatistics, Export], options);
    downloadFile(data, i18n.t('promotion:materialStatistics'), mime.detectExtension(headers['content-type']));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getPromotionPrizeBalanceMultiplierStatistics({ payload }) {
  try {
    const { promotionId, params } = payload;
    const options = {
      params,
      paramsSerializer: (param) => qs.stringify(param),
    };
    const { PromoAdmin, Promotions, BalanceMultiplierPrizeStatistics } = AppConstants.api;
    yield put(changeLoadingState(true));
    const { data } = yield call(NetworkService.makeAPIGetRequest, [PromoAdmin, Promotions, promotionId, BalanceMultiplierPrizeStatistics], options);
    yield put(savePromotionPrizeBalanceMultiplierStatistics(data));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* exportPromotionPrizeBalanceMultiplierStatistics({ payload }) {
  try {
    const { promotionId, params } = payload;
    const options = {
      params,
      paramsSerializer: (param) => qs.stringify(param),
    };
    const {
      PromoAdmin, Promotions, BalanceMultiplierPrizeStatistics, Export,
    } = AppConstants.api;
    yield put(changeLoadingState(true));
    const { data, headers } = yield call(NetworkService.makeAPIGetRequest, [PromoAdmin, Promotions, promotionId, BalanceMultiplierPrizeStatistics, Export], options);
    downloadFile(data, i18n.t('promotion:materialStatistics'), mime.detectExtension(headers['content-type']));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* changeMaterialPrizeStatus({ payload }) {
  try {
    const { promotionId, data } = payload;
    const options = {
      data,
    };
    const {
      PromoAdmin, Promotions, MaterialPrize, Status,
    } = AppConstants.api;
    yield put(changeLoadingState(true));
    yield call(NetworkService.makeAPIPutRequest, [PromoAdmin, Promotions, promotionId, MaterialPrize, Status], options);
    yield put(changeEndedStatus(true));
    yield put(setSuccessMessage(getMessage(i18n.t('promotion:prize'), i18n.t('notification:slUpdated'))));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getPromotionPrizeMachineSpinStatistics({ payload }) {
  try {
    const { promotionId, params } = payload;
    const options = {
      params,
    };
    const { PromoAdmin, Promotions, MachineSpinPrizeStatistics } = AppConstants.api;
    yield put(changeLoadingState(true));
    const { data } = yield call(NetworkService.makeAPIGetRequest, [PromoAdmin, Promotions, promotionId, MachineSpinPrizeStatistics], options);
    yield put(savePromotionPrizeMachineSpinStatistics(data));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* exportPromotionPrizeMachineSpinStatistics({ payload }) {
  try {
    const { promotionId, params } = payload;
    const options = {
      params,
      paramsSerializer: (param) => qs.stringify(param, { allowDots: true }),
      responseType: 'blob',
    };
    const {
      PromoAdmin, Promotions, MachineSpinPrizeStatistics, Export,
    } = AppConstants.api;
    yield put(changeLoadingState(true));
    const { data, headers } = yield call(NetworkService.makeAPIGetRequest, [PromoAdmin, Promotions, promotionId, MachineSpinPrizeStatistics, Export], options);
    downloadFile(data, i18n.t('promotion:machineSpinStatistics'), mime.detectExtension(headers['content-type']));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getSpinDistributionContents({ payload }) {
  try {
    const { promotionId } = payload;
    const { PromoAdmin, Promotions, SpinDistributionContents } = AppConstants.api;
    yield put(changeLoadingState(true));
    const { data } = yield call(NetworkService.makeAPIGetRequest, [PromoAdmin, Promotions, promotionId, SpinDistributionContents]);
    yield put(saveSpinDistributionContents(data));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getSpinDistributionContent({ payload }) {
  try {
    const { params } = payload;
    const options = {
      params,
    };
    const { PromoAdmin, Contents, SpinDistribution } = AppConstants.api;
    yield put(changeLoadingState(true));
    const { data } = yield call(NetworkService.makeAPIGetRequest, [PromoAdmin, Contents, SpinDistribution], options);
    yield put(saveSpinDistributionContent(data));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* createSpinDistributionContent({ payload }) {
  try {
    const { data } = payload;
    const options = {
      data,
    };
    const { PromoAdmin, Contents, SpinDistribution } = AppConstants.api;
    yield put(changeLoadingState(true));
    yield call(NetworkService.makeAPIPostRequest, [PromoAdmin, Contents, SpinDistribution], options);
    yield put(setSuccessMessage(getMessage(i18n.t('promotion:content'), i18n.t('notification:slCreated'))));
    yield put(changeGeneralConfigEndedStatus(true));
    yield put(changeSpinDistributionContentEndedStatus(true));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* editSpinDistributionContent({ payload }) {
  try {
    const { params, data } = payload;
    const options = {
      data,
      params,
    };
    const { PromoAdmin, Contents, SpinDistribution } = AppConstants.api;
    yield put(changeLoadingState(true));
    yield call(NetworkService.makeAPIPutRequest, [PromoAdmin, Contents, SpinDistribution], options);
    yield put(setSuccessMessage(getMessage(i18n.t('promotion:content'), i18n.t('notification:slUpdated'))));
    yield put(changeSpinDistributionContentEndedStatus(true));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* deleteSpinDistributionContent({ payload }) {
  try {
    const { params } = payload;
    const options = {
      params,
    };
    const { PromoAdmin, Contents, SpinDistribution } = AppConstants.api;
    yield put(changeLoadingState(true));
    yield call(NetworkService.makeAPIDeleteRequest, [PromoAdmin, Contents, SpinDistribution], options);
    yield put(setSuccessMessage(getMessage(i18n.t('promotion:content'), i18n.t('notification:slDeleted'))));
    yield put(changeGeneralConfigEndedStatus(true));
    yield put(changeSpinDistributionContentEndedStatus(true));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getPromotionSpins({ payload }) {
  try {
    const { promotionId, params } = payload;
    const options = {
      params,
      paramsSerializer: (param) => qs.stringify(param),
    };
    const { PromoAdmin, Promotions, SpinsReport } = AppConstants.api;
    yield put(changeLoadingState(true));
    const { data } = yield call(NetworkService.makeAPIGetRequest, [PromoAdmin, Promotions, promotionId, SpinsReport], options);
    yield put(savePromotionSpins(data));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* exportPromotionSpins({ payload }) {
  try {
    const { promotionId, params } = payload;
    const options = {
      params,
      paramsSerializer: (param) => qs.stringify(param, { allowDots: true }),
      responseType: 'blob',
    };
    const {
      PromoAdmin, Promotions, SpinsReport, Export,
    } = AppConstants.api;
    yield put(changeLoadingState(true));
    const { data, headers } = yield call(NetworkService.makeAPIGetRequest, [PromoAdmin, Promotions, promotionId, SpinsReport, Export], options);
    downloadFile(data, i18n.t('promotion:gamificationSpinsReport'), mime.detectExtension(headers['content-type']));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getPromotionSpinStatistics({ payload }) {
  try {
    const { promotionId, params } = payload;
    const options = {
      params,
      paramsSerializer: (param) => qs.stringify(param),
    };
    const { PromoAdmin, Promotions, SpinsDetailsReport } = AppConstants.api;
    yield put(changeLoadingState(true));
    const { data } = yield call(NetworkService.makeAPIGetRequest, [PromoAdmin, Promotions, promotionId, SpinsDetailsReport], options);
    yield put(savePromotionSpinStatistics(data));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* exportPromotionSpinStatistics({ payload }) {
  try {
    const { promotionId, params } = payload;
    const options = {
      params,
      paramsSerializer: (param) => qs.stringify(param, { allowDots: true }),
      responseType: 'blob',
    };
    const {
      PromoAdmin, Promotions, SpinsDetailsReport, Export,
    } = AppConstants.api;
    yield put(changeLoadingState(true));
    const { data, headers } = yield call(NetworkService.makeAPIGetRequest, [PromoAdmin, Promotions, promotionId, SpinsDetailsReport, Export], options);
    downloadFile(data, i18n.t('promotion:gamificationSpinStatistics'), mime.detectExtension(headers['content-type']));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* changeMachineOrder({ payload }) {
  try {
    const { machineId, order } = payload.data;
    const { PromoAdmin, Machines, ChangeOrder } = AppConstants.api;
    yield call(NetworkService.makeAPIPutRequest, [PromoAdmin, Machines, ChangeOrder, machineId, order]);
    yield put(changeMachineEndedStatus(true));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
  }
}

function* getPromotionExchangeItems({ payload }) {
  try {
    const { promotionId } = payload;
    const { PromoAdmin, Promotions, ExchangeItems } = AppConstants.api;
    yield put(changeLoadingState(true));
    const { data } = yield call(NetworkService.makeAPIGetRequest, [PromoAdmin, Promotions, promotionId, ExchangeItems]);
    yield put(savePromotionExchangeItems(data));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getPromotionExchangeItem({ payload }) {
  try {
    const { promotionId, id } = payload;
    const { PromoAdmin, Promotions, ExchangeItems } = AppConstants.api;
    yield put(changeLoadingState(true));
    const { data } = yield call(NetworkService.makeAPIGetRequest, [PromoAdmin, Promotions, promotionId, ExchangeItems, id]);
    yield put(savePromotionExchangeItem(data));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* createPromotionExchangeItem({ payload }) {
  try {
    const { data, promotionId } = payload;
    const options = {
      data,
    };
    const { PromoAdmin, Promotions, ExchangeItems } = AppConstants.api;
    yield put(changeLoadingState(true));
    yield call(NetworkService.makeAPIPostRequest, [PromoAdmin, Promotions, promotionId, ExchangeItems], options);
    yield put(setSuccessMessage(getMessage(i18n.t('promotion:exchange'), i18n.t('notification:slCreated'))));
    yield put(changeGeneralConfigEndedStatus(true));
    yield put(changeMachineConfigEndedStatus(true));
    yield put(changePromotionExchangeEndedStatus(true));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* editPromotionExchangeItem({ payload }) {
  try {
    const { promotionId, id, data } = payload;
    const options = {
      data,
    };
    const { PromoAdmin, Promotions, ExchangeItems } = AppConstants.api;
    yield put(changeLoadingState(true));
    yield call(NetworkService.makeAPIPutRequest, [PromoAdmin, Promotions, promotionId, ExchangeItems, id], options);
    yield put(setSuccessMessage(getMessage(i18n.t('promotion:exchange'), i18n.t('notification:slUpdated'))));
    yield put(changeMachineConfigEndedStatus(true));
    yield put(changePromotionExchangeEndedStatus(true));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* deletePromotionExchangeItem({ payload }) {
  try {
    const { promotionId, id } = payload;
    const { PromoAdmin, Promotions, ExchangeItems } = AppConstants.api;
    yield put(changeLoadingState(true));
    yield call(NetworkService.makeAPIDeleteRequest, [PromoAdmin, Promotions, promotionId, ExchangeItems, id]);
    yield put(setSuccessMessage(getMessage(i18n.t('promotion:exchange'), i18n.t('notification:slDeleted'))));
    yield put(changeGeneralConfigEndedStatus(true));
    yield put(changeMachineConfigEndedStatus(true));
    yield put(changePromotionExchangeEndedStatus(true));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* createPrizeContent({ payload }) {
  try {
    const { data } = payload;
    const options = {
      data,
    };
    const { PromoAdmin, Contents, Prize } = AppConstants.api;
    yield put(changeLoadingState(true));
    yield call(NetworkService.makeAPIPostRequest, [PromoAdmin, Contents, Prize], options);
    yield put(setSuccessMessage(getMessage(i18n.t('promotion:content'), i18n.t('notification:slCreated'))));
    yield put(changePrizeContentEndedStatus(true));
    yield put(changeGeneralConfigEndedStatus(true));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* editPrizeContent({ payload }) {
  try {
    const { data } = payload;
    const options = {
      data,
    };
    const { PromoAdmin, Contents, Prize } = AppConstants.api;
    yield put(changeLoadingState(true));
    yield call(NetworkService.makeAPIPutRequest, [PromoAdmin, Contents, Prize], options);
    yield put(setSuccessMessage(getMessage(i18n.t('promotion:content'), i18n.t('notification:slUpdated'))));
    yield put(changePrizeContentEndedStatus(true));
    yield put(changeGeneralConfigEndedStatus(true));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* deletePrizeContent({ payload }) {
  try {
    const { params } = payload;
    const options = {
      params,
    };
    const { PromoAdmin, Contents, Prize } = AppConstants.api;
    yield put(changeLoadingState(true));
    yield call(NetworkService.makeAPIDeleteRequest, [PromoAdmin, Contents, Prize], options);
    yield put(setSuccessMessage(getMessage(i18n.t('promotion:content'), i18n.t('notification:slDeleted'))));
    yield put(changePrizeContentEndedStatus(true));
    yield put(changeGeneralConfigEndedStatus(true));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getPrizeContent({ payload }) {
  try {
    const { params } = payload;
    const options = {
      params,
    };
    const { PromoAdmin, Contents, Prize } = AppConstants.api;
    yield put(changeLoadingState(true));
    const { data } = yield call(NetworkService.makeAPIGetRequest, [PromoAdmin, Contents, Prize], options);
    yield put(savePrizeContent(data));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getPrizeContents({ payload }) {
  try {
    const { promotionId } = payload;
    const { PromoAdmin, Promotions, PrizeContents } = AppConstants.api;
    yield put(changeLoadingState(true));
    const { data } = yield call(NetworkService.makeAPIGetRequest, [PromoAdmin, Promotions, promotionId, PrizeContents]);
    yield put(savePrizeContents(data));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getPrizeContentConnected({ payload }) {
  try {
    const { promotionId } = payload;
    const { PromoAdmin, Promotions, ConnectedPrizes } = AppConstants.api;
    yield put(changeLoadingState(true));
    const { data } = yield call(NetworkService.makeAPIGetRequest, [PromoAdmin, Promotions, promotionId, ConnectedPrizes]);
    yield put(savePrizeContentConnected(data));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getChangeLogs({ payload }) {
  try {
    const { params, promotionId } = payload;
    const options = {
      params,
      paramsSerializer: (param) => qs.stringify(param),
    };
    const { PromoAdmin, Promotions, Logs } = AppConstants.api;
    yield put(changeLoadingState(true));
    const { data } = yield call(NetworkService.makeAPIGetRequest, [PromoAdmin, Promotions, promotionId, Logs], options);
    yield put(savePromotionChangeLogs(data));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getChangeLog({ payload }) {
  try {
    const { promotionId, logId } = payload;
    const { PromoAdmin, Promotions, Logs } = AppConstants.api;
    yield put(changeLoadingState(true));
    const { data } = yield call(NetworkService.makeAPIGetRequest, [PromoAdmin, Promotions, promotionId, Logs, logId]);
    yield put(savePromotionChangeLog(data));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getPromotionExchanges({ payload }) {
  try {
    const { id, params } = payload;
    const options = {
      params,
      paramsSerializer: (param) => qs.stringify(param),
    };
    const { PromoAdmin, Promotions, Exchanges } = AppConstants.api;
    yield put(changeLoadingState(true));
    const { data } = yield call(NetworkService.makeAPIGetRequest, [PromoAdmin, Promotions, id, Exchanges], options);
    yield put(savePromotionExchanges(data));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* exportPromotionExchanges({ payload }) {
  try {
    const { id, params } = payload;
    const options = {
      params,
      paramsSerializer: (param) => qs.stringify(param, { allowDots: true }),
      responseType: 'blob',
    };
    const {
      PromoAdmin, Promotions, Exchanges, Export,
    } = AppConstants.api;
    yield put(changeLoadingState(true));
    const { data, headers } = yield call(NetworkService.makeAPIGetRequest, [PromoAdmin, Promotions, id, Exchanges, Export], options);
    downloadFile(data, i18n.t('promotion:gamificationExchangesReport'), mime.detectExtension(headers['content-type']));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* addPrivateParticipants({ payload }) {
  try {
    const { data, id } = payload;
    const options = {
      data,
    };
    const { PromoAdmin, Promotions, PrivateParticipants } = AppConstants.api;
    yield put(changeLoadingState(true));
    yield call(NetworkService.makeAPIPostRequest, [PromoAdmin, Promotions, id, PrivateParticipants], options);
    yield put(setSuccessMessage(getMessage(i18n.t('participants'), i18n.t('notification:plUpdated'))));
    yield put(changeEndedStatus(true));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

export default function* promotionSaga() {
  yield all([
    takeLatest(PromotionActionType.GET_PROMOTIONS, getPromotions),
    takeLatest(PromotionActionType.GET_PROMOTION, getPromotion),
    takeLatest(PromotionActionType.CREATE_PROMOTION, createPromotion),
    takeLatest(PromotionActionType.EDIT_PROMOTION, editPromotion),
    takeLatest(PromotionActionType.GET_SPIN_DISTRIBUTIONS, getSpinDistributions),
    takeLatest(PromotionActionType.CREATE_SPIN_DISTRIBUTION, createSpinDistribution),
    takeLatest(PromotionActionType.EDIT_SPIN_DISTRIBUTION, editSpinDistribution),
    takeLatest(PromotionActionType.GET_SPIN_DISTRIBUTION, getSpinDistribution),
    takeLatest(PromotionActionType.DELETE_SPIN_DISTRIBUTION, deleteSpinDistribution),
    takeLatest(PromotionActionType.GET_MACHINES, getMachines),
    takeLatest(PromotionActionType.CREATE_MACHINE, createMachine),
    takeLatest(PromotionActionType.EDIT_MACHINE, editMachine),
    takeLatest(PromotionActionType.GET_MACHINE, getMachine),
    takeLatest(PromotionActionType.GET_MACHINE_DETAILS, getMachineDetails),
    takeLatest(PromotionActionType.DELETE_MACHINE, deleteMachine),
    takeLatest(PromotionActionType.GET_PLAYING_PARTICIPANTS, getPlayingParticipants),
    takeLatest(PromotionActionType.CREATE_PLAYING_PARTICIPANT, createPlayingParticipant),
    takeLatest(PromotionActionType.EDIT_PLAYING_PARTICIPANT, editPlayingParticipant),
    takeLatest(PromotionActionType.GET_PLAYING_PARTICIPANT, getPlayingParticipant),
    takeLatest(PromotionActionType.DELETE_PLAYING_PARTICIPANT, deletePlayingParticipant),
    takeLatest(PromotionActionType.GET_PRIZES, getPrizes),
    takeLatest(PromotionActionType.GET_PRIZE, getPrize),
    takeLatest(PromotionActionType.CREATE_PRIZE, createPrize),
    takeLatest(PromotionActionType.EDIT_PRIZE, editPrize),
    takeLatest(PromotionActionType.DELETE_PRIZE, deletePrize),
    takeLatest(PromotionActionType.GET_MACHINE_CONFIG, getMachineConfig),
    takeLatest(PromotionActionType.CREATE_MACHINE_CONFIG, createMachineConfig),
    takeLatest(PromotionActionType.EDIT_MACHINE_CONFIG, editMachineConfig),
    takeLatest(PromotionActionType.DELETE_MACHINE_CONFIG, deleteMachineConfig),
    takeLatest(PromotionActionType.GET_GROUP_LIMIT, getGroupLimit),
    takeLatest(PromotionActionType.CREATE_GROUP_LIMIT, createGroupLimit),
    takeLatest(PromotionActionType.EDIT_GROUP_LIMIT, editGroupLimit),
    takeLatest(PromotionActionType.DELETE_GROUP_LIMIT, deleteGroupLimit),
    takeLatest(PromotionActionType.PUBLISH_PROMOTION, publishPromotion),
    takeLatest(PromotionActionType.DELETE_PROMOTION, deletePromotion),
    takeLatest(PromotionActionType.FINISH_PROMOTION, finishPromotion),
    takeLatest(PromotionActionType.GET_MACHINE_CONTENTS, getContents),
    takeLatest(PromotionActionType.GET_MACHINE_CONTENT, getMachineContent),
    takeLatest(PromotionActionType.CREATE_MACHINE_CONTENT, createMachineContent),
    takeLatest(PromotionActionType.EDIT_MACHINE_CONTENT, editMachineContent),
    takeLatest(PromotionActionType.DELETE_MACHINE_CONTENT, deleteMachineContent),
    takeLatest(PromotionActionType.CHANGE_PROMOTION_STATUS, changePromotionStatus),
    takeLatest(PromotionActionType.CLONE_PROMOTION, clonePromotion),
    takeLatest(PromotionActionType.GET_PROMOTION_TRANSACTIONS, getPromotionTransactions),
    takeLatest(PromotionActionType.GET_PROMOTION_MACHINES, getPromotionMachines),
    takeLatest(PromotionActionType.GET_PROMOTION_PRIZES, getPromotionPrizes),
    takeLatest(PromotionActionType.GET_PROMOTION_PLAYERS, getPromotionPlayers),
    takeLatest(PromotionActionType.GET_PROMOTION_CSV_PLAYERS, getPromotionCsvPlayers),
    takeLatest(PromotionActionType.ADJUST_MANUAL_SPIN, adjustManualSpin),
    takeLatest(PromotionActionType.GET_PROMOTION_MANUAL_SPIN_REPORT, getPromotionManualSpinReport),
    takeLatest(PromotionActionType.GET_PROMOTION_SPIN_ADJUSTMENT_PLAYERS, getPromoSpinAdjustmentPlayers),
    takeLatest(PromotionActionType.GET_PLAYER_PROMOTION_TRANSACTIONS, getPlayerPromotionTransactions),
    takeLatest(PromotionActionType.GET_PLAYER_PROMOTION_SPINS, getPlayerPromotionSpins),
    takeLatest(PromotionActionType.ADJUST_PLAYER_MANUAL_SPIN, adjustPlayerManualSpin),
    takeLatest(PromotionActionType.GET_PROMOTION_PREVIEW_VALIDATION_STATE, getPromotionPreviewValidationState),
    takeLatest(PromotionActionType.GET_PROMOTION_CONTENT, getPromotionContent),
    takeLatest(PromotionActionType.GET_PROMOTION_CONTENTS, getPromotionContents),
    takeLatest(PromotionActionType.CREATE_PROMOTION_CONTENT, createPromotionContent),
    takeLatest(PromotionActionType.EDIT_PROMOTION_CONTENT, editPromotionContent),
    takeLatest(PromotionActionType.DELETE_PROMOTION_CONTENT, deletePromotionContent),
    takeLatest(PromotionActionType.EXPORT_PROMOTION_TRANSACTIONS, exportPromotionTransactions),
    takeLatest(PromotionActionType.EXPORT_PROMOTION_MACHINES, exportPromotionMachines),
    takeLatest(PromotionActionType.EXPORT_PROMOTION_PRIZES, exportPromotionPrizes),
    takeLatest(PromotionActionType.EXPORT_PROMOTION_PLAYERS, exportPromotionPlayers),
    takeLatest(PromotionActionType.GET_PRIVATE_PROMOTION_PARTICIPANTS, getPrivatePromotionParticipants),
    takeLatest(PromotionActionType.EXPORT_PRIVATE_PROMOTION_PARTICIPANTS, exportPrivatePromotionParticipants),
    takeLatest(PromotionActionType.GET_PROMOTION_PRIZE_MATERIAL_STATISTICS, getPromotionPrizeMaterialStatistics),
    takeLatest(PromotionActionType.EXPORT_PROMOTION_PRIZE_MATERIAL_STATISTICS, exportPromotionPrizeMaterialStatistics),
    takeLatest(PromotionActionType.GET_PROMOTION_PRIZE_MACHINE_SPIN_STATISTICS, getPromotionPrizeMachineSpinStatistics),
    takeLatest(PromotionActionType.EXPORT_PROMOTION_PRIZE_MACHINE_SPIN_STATISTICS, exportPromotionPrizeMachineSpinStatistics),
    takeLatest(PromotionActionType.CHANGE_MATERIAL_PRIZE_STATUS, changeMaterialPrizeStatus),
    takeLatest(PromotionActionType.GET_SPIN_DISTRIBUTION_CONTENTS, getSpinDistributionContents),
    takeLatest(PromotionActionType.GET_SPIN_DISTRIBUTION_CONTENT, getSpinDistributionContent),
    takeLatest(PromotionActionType.CREATE_SPIN_DISTRIBUTION_CONTENT, createSpinDistributionContent),
    takeLatest(PromotionActionType.EDIT_SPIN_DISTRIBUTION_CONTENT, editSpinDistributionContent),
    takeLatest(PromotionActionType.DELETE_SPIN_DISTRIBUTION_CONTENT, deleteSpinDistributionContent),
    takeLatest(PromotionActionType.GET_PROMOTION_SPINS, getPromotionSpins),
    takeLatest(PromotionActionType.EXPORT_PROMOTION_SPINS, exportPromotionSpins),
    takeLatest(PromotionActionType.GET_PROMOTION_SPIN_STATISTICS, getPromotionSpinStatistics),
    takeLatest(PromotionActionType.EXPORT_PROMOTION_SPIN_STATISTICS, exportPromotionSpinStatistics),
    takeLatest(PromotionActionType.GET_PROMOTION_PRIZE_BALANCE_MULTIPLIER_STATISTICS, getPromotionPrizeBalanceMultiplierStatistics),
    takeLatest(PromotionActionType.EXPORT_PROMOTION_PRIZE_BALANCE_MULTIPLIER_STATISTICS, exportPromotionPrizeBalanceMultiplierStatistics),
    takeLatest(PromotionActionType.CHANGE_ORDER_MACHINE, changeMachineOrder),
    takeLatest(PromotionActionType.GET_PROMOTION_EXCHANGE_ITEMS, getPromotionExchangeItems),
    takeLatest(PromotionActionType.GET_PROMOTION_EXCHANGE_ITEM, getPromotionExchangeItem),
    takeLatest(PromotionActionType.CREATE_PROMOTION_EXCHANGE_ITEM, createPromotionExchangeItem),
    takeLatest(PromotionActionType.EDIT_PROMOTION_EXCHANGE_ITEM, editPromotionExchangeItem),
    takeLatest(PromotionActionType.DELETE_PROMOTION_EXCHANGE_ITEM, deletePromotionExchangeItem),
    takeLatest(PromotionActionType.GET_PROMOTION_PRIZE_CONTENT, getPrizeContent),
    takeLatest(PromotionActionType.CREATE_PROMOTION_PRIZE_CONTENT, createPrizeContent),
    takeLatest(PromotionActionType.EDIT_PROMOTION_PRIZE_CONTENT, editPrizeContent),
    takeLatest(PromotionActionType.DELETE_PROMOTION_PRIZE_CONTENT, deletePrizeContent),
    takeLatest(PromotionActionType.GET_PROMOTION_PRIZE_CONTENTS, getPrizeContents),
    takeLatest(PromotionActionType.GET_PROMOTION_PRIZE_CONTENT_CONNECTED, getPrizeContentConnected),
    takeLatest(PromotionActionType.GET_MACHINE_CONFIGS, getMachineConfigs),
    takeLatest(PromotionActionType.GET_PROMOTION_EXCHANGES, getPromotionExchanges),
    takeLatest(PromotionActionType.EXPORT_PROMOTION_EXCHANGES, exportPromotionExchanges),
    takeLatest(PromotionActionType.GET_PROMOTION_CHANGE_LOGS, getChangeLogs),
    takeLatest(PromotionActionType.GET_PROMOTION_CHANGE_LOG, getChangeLog),
    takeLatest(PromotionActionType.ADD_PRIVATE_PARTICIPANTS, addPrivateParticipants),
  ]);
}
