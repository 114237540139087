import { Steps } from 'antd';
import PropTypes from 'prop-types';
import { useState, useEffect, useCallback } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { resetTemplates } from 'actions/brand/brandActionCreator';
import { ActivateProducts, CreateBrandForm } from 'components/brand/create';
import { BasicSettings, ChooseTemplate } from 'containers/brand';
import { StyledBrandsForm } from 'components/brand/Brand.styled';
import { StyledSteps } from 'components/styledComponents/step/Step.styled';
import Banner from 'components/common/Banner';
import { IconSvg } from 'components/common';
import { StyledIconDefault } from 'components/styledComponents/icon/Icon.styled';
import { StyledBannerWrapper, StyledBannerWrapperBg } from 'Global.styled';

const CreateBrand = (props) => {
  const { t } = useTranslation();

  const steps = useCallback(
    (currKey) => [
      {
        title: t('mainSettings'),
        content: BasicSettings,
        key: 0,
        disabled: false,
        icon: currKey?.key > 0 && (
          <StyledIconDefault>
            <IconSvg icon="CheckIcon" color="#ffffff" />
          </StyledIconDefault>
        ),
      },
      {
        title: t('brand:products'),
        content: ActivateProducts,
        key: 1,
        disabled: false,
        icon: currKey?.key > 1 && (
          <StyledIconDefault>
            <IconSvg icon="CheckIcon" color="#ffffff" />
          </StyledIconDefault>
        ),
      },
      {
        title: t('brand:websiteTemplate'),
        content: ChooseTemplate,
        key: 2,
        disabled: false,
        icon: currKey?.key > 2 && (
          <StyledIconDefault>
            <IconSvg icon="CheckIcon" color="#ffffff" />
          </StyledIconDefault>
        ),
      },
    ],
    [t],
  );

  const [current, setCurrent] = useState(steps()?.[0]);
  const [data, setData] = useState({});
  const {
    name, languageId, currencyId, countryId, market, providerViewId,
  } = data;

  const { resetTemplatesProp, ...rest } = props;

  useEffect(
    () => () => {
      resetTemplatesProp();
    },
    [resetTemplatesProp],
  );

  const next = () => {
    setCurrent(steps(current)[current.key + 1]);
  };

  const back = () => {
    setCurrent(steps(current)[current.key - 1]);
  };

  return (
    <StyledBannerWrapper>
      <Banner text={t('brand:createNewBrand')} isSmallText type={1} />
      <StyledBannerWrapperBg>
        <StyledSteps>
          <Steps current={current.key} items={steps(current.key)} />
        </StyledSteps>
        <StyledBrandsForm>
          <CreateBrandForm
            initialValues={{
              name,
              languageId,
              currencyId,
              countryId,
              market,
              providerViewId,
            }}
          >
            <current.content back={back} next={next} data={data} setData={setData} {...rest} />
          </CreateBrandForm>
        </StyledBrandsForm>
      </StyledBannerWrapperBg>
    </StyledBannerWrapper>
  );
};

const mapDispatchToProps = (dispatch) => bindActionCreators(
  {
    resetTemplatesProp: resetTemplates,
  },
  dispatch,
);

CreateBrand.propTypes = {
  resetTemplatesProp: PropTypes.func.isRequired,
};

export default connect(null, mapDispatchToProps)(CreateBrand);
