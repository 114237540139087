import BrandActionType from './brandActionType';

export const getBrand = (id, statusCode) => ({
  type: BrandActionType.GET_BRAND,
  payload: { id, statusCode },
});

export const getBrandSuccess = (data) => ({
  type: BrandActionType.GET_BRAND_SUCCESS,
  payload: { data },
});

export const resetBrand = () => ({
  type: BrandActionType.RESET_BRAND,
});

export const getBrands = (partnerId) => ({
  type: BrandActionType.GET_BRANDS,
  payload: { partnerId },
});

export const getBrandsSuccess = (data) => ({
  type: BrandActionType.GET_BRANDS_SUCCESS,
  payload: { data },
});

export const addBrand = (data) => ({
  type: BrandActionType.ADD_BRAND,
  payload: { data },
});

export const activateBrand = (data) => ({
  type: BrandActionType.ACTIVATE_BRAND,
  payload: { data },
});

export const setCurrentBrand = (id) => ({
  type: BrandActionType.SET_CURRENT_BRAND_ID,
  payload: { id },
});

export const getMarkets = () => ({
  type: BrandActionType.GET_BRAND_MARKETS,
});

export const getMarketsSuccess = (data) => ({
  type: BrandActionType.GET_BRAND_MARKETS_SUCCESS,
  payload: { data },
});

export const changeBrandLoadingState = (state) => ({
  type: BrandActionType.CHANGE_BRAND_LOADING_STATE,
  payload: { state },
});

export const getIsBrandExist = (partnerId, name) => ({
  type: BrandActionType.GET_IS_BRAND_EXIST,
  payload: { partnerId, name },
});

export const getIsBrandExistSuccess = (data) => ({
  type: BrandActionType.GET_IS_BRAND_EXIST_SUCCESS,
  payload: { data },
});

export const resetIsBrandExist = () => ({
  type: BrandActionType.RESET_IS_BRAND_EXIST,
});

export const getTemplates = () => ({
  type: BrandActionType.GET_BRAND_TEMPLATES,
});

export const getTemplatesSuccess = (data) => ({
  type: BrandActionType.GET_BRAND_TEMPLATES_SUCCESS,
  payload: { data },
});

export const resetTemplates = () => ({
  type: BrandActionType.RESET_BRAND_TEMPLATES,
});

export const changeBrandCallEndedStatus = (status) => ({
  type: BrandActionType.CHANGE_BRAND_CALL_ENDED_STATUS,
  payload: { status },
});

export const editBrandTimeZone = (brandId, params) => ({
  type: BrandActionType.EDIT_BRAND_TIME_ZONE,
  payload: { brandId, params },
});

export const editBrand = (brandId, data) => ({
  type: BrandActionType.EDIT_BRAND,
  payload: { brandId, data },
});
