import styled from 'styled-components';
import { media } from 'utils/styledHelpers';

const StyledLayout = styled.div`
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  flex: 1 1 auto;
  overflow-x: hidden;
  overflow-y: auto;

  .ant-menu-item .ant-menu-item-only-child {
    margin: 0;
  }

  .ant-layout-content {
    flex: auto;
    height: 100%;
    padding: 0 1.71rem;
    background-color: ${(props) => props.theme.colors.primaryBg};
    color: ${(props) => props.theme.colors.primaryText};
    display: flex;
    flex-direction: column;
    position: relative;
    overflow: auto;
    @media only screen and (min-width: 320px) and (max-width: 1023px) {
      width: auto;
    }

    ${media.sm`
      padding: 0 1.44rem;
    `}
  }

  .ant-breadcrumb {
    position: ${(props) => (props.isBrand ? 'absolute' : 'initial')};
  }
`;

const StyledMainSettingsWrapper = styled.div`
  display: flex;
  margin: 2.5rem 0 0;
  position: relative;
  border-top: ${(props) => (props.isBorderTop ? `1px solid  ${props.theme.colors.secondaryBorder}` : '')};
  top: 0.85rem;
`;

export { StyledLayout, StyledMainSettingsWrapper };
