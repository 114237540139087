export default {
  en: {
    changes: 'Changes',
    changeDate: 'Change Date',
    changedBy: 'Changed By',
    gameRatio: 'Game Ratio',
    gameTypes: 'Game Types',
    totalCurrentAmount: 'Total Current Amount',
    operatorIncome: 'Operator Income',
    playerExclusion: 'Player Exclusion',
    exclusionType: 'Exclusion Type',
    appliedBy: 'Applied By',
    totalFunds: 'Total Funds',
    operatorsIncome: 'Operators Income',
    totalAmountReturned: 'Total Amount Returned',
    totalAddedAmount: 'Total Added Amount',
    totalEarnedAmount: 'Total Earned Amount',
    totalBetAmount: 'Total Bet Amount',
    balanceAdjustment: 'Balance Adjustment',
    playerFullName: 'Player First Name & Last Name',
    balanceAdjustmentDate: 'Balance Adjustment Date',
    walletType: 'Wallet Type',
    fixedOddsTotalBetAmount: 'Fixed Odds Total Bet Amount',
    fixedOddsTotalWinAmount: 'Fixed Odds Total Win Amount',
    fixedOddsTotalLostAmount: 'Fixed Odds Total Lost Amount',
    fixedOddsBonusBetAmount: 'Fixed Odds Total Bonus Bet',
    casinoTotalBetAmount: 'Casino Games Total Bet Amount',
    casinoTotalWinAmount: 'Casino Games Total Win Amount',
    casinoTotalLostAmount: 'Casino Games Total Lost Amount',
    casinoBonusBetAmount: 'Casino Games Total Bonus Bet',
    sessionEndReason: 'Session End Reason',
    unsettledFundsAmount: 'Unsettled Funds Amount',
    safeServer: 'Safe Server',
    casinoGamesTotalBonusAmount: 'Casino Games Total Bonus Amount',
    fixedOddsTotalBonusAmount: 'Fixed Odds Total Bonus Amount',
    mirroringServer: 'Mirroring Server',
    logDate: 'Log Date',
    changeNotification: 'Change Notification',
    changeCategory: 'Change Category',
    changeDescription: 'Change Description',
    changeReason: 'Change Reason',
    previousVersion: 'Previous Version',
    currentVersion: 'Current Version',
    change: 'Change',
    significantEvents: 'Significant Events',
    inaccessibilityAreas: 'Inaccessibility Areas',
    inaccessibilityFrom: 'Inaccessibility From',
    inaccessibilityTo: 'Inaccessibility To',
    inaccessibilityReason: 'Inaccessibility Reason',
    reconnectionFailedAttempts: 'Reconnection Failed Attempts',
    serverName: 'Server Name',
    inaccessibilityDate: 'Inaccessibility Date',
    inaccessibilityServer: 'Inaccessibility Server',
    largeTransactionsInfo: 'The system is using the default conversion for this report, if you want to change the conversion you can do it from Brand Settings→Conversion Settings→Others',
    transactionType: 'Transaction Type',
    balanceAdjustmentCount: 'Balance Adjustment Count',
    selfExclusionCount: 'Self Exclusion Count',
    accountClosedCount: 'Account Closed Count',
    operatorInterventionCount: 'Operator Intervention Count',
    unusualEventsCount: 'Other Significant/Unusual Events Count',
    jackpotWinRatio: 'Jackpot Win Ratio',
    jackpotConfiguration: 'JackPot Configuration',
    jackpotName: 'Jackpot Name',
    gamePaylistId: 'Game Paylist ID',
    gameSessionId: 'Game Session ID',
    jackpotPrizeDate: 'Jackpot Prize Date',
    maximumJackpotLevel: 'Maximum Jackpot Level',
    jackpotAmount: 'Jackpot Amount',
    processingUserID: 'Processing User ID',
    processingUserName: 'Processing User Name',
    confirmingUserID: 'Confirming User ID',
    confirmingUserName: 'Confirming User Name',
    progressiveJackpotName: 'Progressive Jackpot Name',
    setupParameters: 'Setup Parameters',
    existingGamesPaylist: 'Existing Games Paylist',
    totalBetsAmount: 'Total Bets Amount',
    totalContributionsWinAmount: 'Total Contributions Win Amount',
    nonContributionFinance: 'Non Contribution Finance',
    jackpotPrizeCurrentAmount: 'Jackpot Prize Current Amount',
    jackpotContributionCurrentAmount: 'Jackpot Contribution Current Amount',
    terminationDate: 'Termination Date',
    maximumAwardedAmount: 'Maximum Awarded Amount',
    exceedingLimitAmount: 'Exceeding Limit Amount',
    promotionalItems: 'Promotional Items',
    promotionId: 'Promotion ID',
    promotionType: 'Promotion Type',
    totalBalanceAllocated: 'Total Balance Allocated',
    totalAmountPrizes: 'Total Amount - Prizes',
    totalAmountUsed: 'Total Amount Used',
    totalAdjustmentAmount: 'Total Adjustment Amount',
    finalBalance: 'Final Balance',
    softwareChanges: 'Software Changes',
    taxationDaily: 'Taxation Daily',
    taxationMonthly: 'Taxation Monthly',
    betWin: 'Bet Win',
    taxation: 'Taxation',
    quarterDetails: 'Quarter Details',
    statisticalReportQuarter: 'Statistical Report Q{{quarter}} {{year}}',
    bettingSelfProtectionReportInfo: "The provided report is based on the Brand's timezone only, any time zone changes in this page will not affect the report",
    bettingSelfProtectionReportNonAvailabilityInfo: 'The Regulatory Report Section is not available for the selected Brand',
    totalAmountExpired: 'Total Amount Expired',
  },
  es: {
    changes: 'Cambios',
    changeDate: 'Fecha de modificación',
    changedBy: 'Modificado por',
    gameRatio: 'Ratio de juego',
    gameTypes: 'Tipos de juego',
    totalCurrentAmount: 'Importe total actual',
    operatorIncome: 'Ingresos de los operadores',
    playerExclusion: 'Exclusión de los jugadores',
    exclusionType: 'Tipo de exclusión',
    appliedBy: 'Aplicado por',
    totalFunds: 'Fondos totales',
    operatorsIncome: 'Ingresos de los operadores',
    totalAmountReturned: 'Importe total devuelto',
    totalAddedAmount: 'Importe total añadido',
    totalEarnedAmount: 'Importe total ganado',
    totalBetAmount: 'Importe total de la apuesta',
    balanceAdjustment: 'Ajuste del saldo',
    playerFullName: 'Nombre y Apellidos del Jugador',
    balanceAdjustmentDate: 'Balance Fecha de ajuste',
    walletType: 'Tipo de cartera',
    fixedOddsTotalBetAmount: 'Cuotas fijas Importe total de la apuesta',
    fixedOddsTotalWinAmount: 'Cuotas fijas Importe total de la ganancia',
    fixedOddsTotalLostAmount: 'Cuotas fijas Importe total perdido',
    fixedOddsBonusBetAmount: 'Cuotas fijas Total Bonificación Apuesta',
    casinoTotalBetAmount: 'Juegos de casino Importe total de la apuesta',
    casinoTotalWinAmount: 'Juegos de casino Importe total de ganancias',
    casinoTotalLostAmount: 'Juegos de casino Importe total perdido',
    casinoBonusBetAmount: 'Juegos de Casino Total Bono Apuesta',
    sessionEndReason: 'Motivo de la finalización de la sesión',
    unsettledFundsAmount: 'Importe de los fondos no liquidados',
    safeServer: 'Servidor Seguro',
    casinoGamesTotalBonusAmount: 'Juegos de Casino Importe Total de la Bonificación',
    fixedOddsTotalBonusAmount: 'Cuotas Fijas Importe Total de la Bonificación',
    mirroringServer: 'Servidor Espejo',
    logDate: 'Fecha de Registro',
    changeNotification: 'Notificación de Cambio',
    changeCategory: 'Categoría de Cambio',
    changeDescription: 'Descripción del Cambio',
    changeReason: 'Razón del Cambio',
    previousVersion: 'Versión Anterior',
    currentVersion: 'Versión Actual',
    change: 'Cambio',
    significantEvents: 'Eventos Significativos',
    inaccessibilityAreas: 'Áreas de Inaccesibilidad',
    inaccessibilityFrom: 'Inaccesibilidad Desde',
    inaccessibilityTo: 'Inaccesibilidad Hasta',
    inaccessibilityReason: 'Razón de Inaccesibilidad',
    reconnectionFailedAttempts: 'Intentos Fallidos de Reconexión',
    serverName: 'Nombre del Servidor',
    inaccessibilityDate: 'Fecha de Inaccesibilidad',
    inaccessibilityServer: 'Servidor de Inaccesibilidad',
    largeTransactionsInfo:
      'El sistema está utilizando la conversión predeterminada para este informe; si deseas cambiar la conversión, puedes hacerlo desde Configuración de Marca → Configuración de Conversión → Otros',
    transactionType: 'Tipo de Transacción',
    balanceAdjustmentCount: 'Conteo de Ajuste de Balance',
    selfExclusionCount: 'Conteo de Autoexclusión',
    accountClosedCount: 'Conteo de Cuenta Cerrada',
    operatorInterventionCount: 'Conteo de Intervención del Operador',
    unusualEventsCount: 'Conteo de Otros Eventos Significativos/Inusuales',
    jackpotWinRatio: 'Cociente de Ganancias de Jackpot',
    jackpotConfiguration: 'Configuración de Jackpot',
    jackpotName: 'Nombre del Jackpot',
    gamePaylistId: 'ID de la Lista de Juegos',
    gameSessionId: 'ID de Sesión de Juego',
    jackpotPrizeDate: 'Fecha del Premio del Jackpot',
    maximumJackpotLevel: 'Nivel Máximo del Jackpot',
    jackpotAmount: 'Importe del Jackpot',
    processingUserID: 'ID de Usuario de Procesamiento',
    processingUserName: 'Nombre de Usuario de Procesamiento',
    confirmingUserID: 'ID de Usuario de Confirmación',
    confirmingUserName: 'Nombre de Usuario de Confirmación',
    progressiveJackpotName: 'Nombre del Jackpot Progresivo',
    setupParameters: 'Parámetros de Configuración',
    existingGamesPaylist: 'Lista de Juegos Existente',
    totalBetsAmount: 'Importe Total de Apuestas',
    totalContributionsWinAmount: 'Importe Total de Contribuciones Ganadoras',
    nonContributionFinance: 'Finanzas de No Contribución',
    jackpotPrizeCurrentAmount: 'Importe Actual del Premio del Jackpot',
    jackpotContributionCurrentAmount: 'Importe Actual de Contribución del Jackpot',
    terminationDate: 'Fecha de Terminación',
    maximumAwardedAmount: 'Importe Máximo Otorgado',
    exceedingLimitAmount: 'Importe que Excede el Límite',
    promotionalItems: 'Artículos Promocionales',
    promotionId: 'ID de Promoción',
    promotionType: 'Tipo de Promoción',
    totalBalanceAllocated: 'Balance Total Asignado',
    totalAmountPrizes: 'Importe Total - Premios',
    totalAmountUsed: 'Importe Total Utilizado',
    totalAdjustmentAmount: 'Importe Total de Ajuste',
    finalBalance: 'Balance Final',
    softwareChanges: 'Cambios de Software',
    taxationDaily: 'Impuestos Diarios',
    taxationMonthly: 'Impuestos Mensuales',
    betWin: 'Ganancia de Apuesta',
    taxation: 'Impuestos',
    quarterDetails: 'Detalles del Trimestre',
    statisticalReportQuarter: 'Informe Estadístico Q{{trimestre}} {{año}}',
    bettingSelfProtectionReportInfo: 'El informe proporcionado se basa únicamente en la zona horaria de la Marca; cualquier cambio en la zona horaria en esta página no afectará el informe',
    bettingSelfProtectionReportNonAvailabilityInfo: 'La Sección de Informe Regulatorio no está disponible para la Marca seleccionada',
  },
  tr: {
    changes: 'Değişiklikler',
    changeDate: 'Değişim Tarihi',
    changedBy: 'Değiştiren',
    gameRatio: 'Oyun Oranı',
    gameTypes: 'Oyun Türleri',
    totalCurrentAmount: 'Toplam Mevcut Tutar',
    operatorIncome: 'Operatör Geliri',
    playerExclusion: 'Oyuncu Hariç Tutma',
    exclusionType: 'Hariç Tutma Türü',
    appliedBy: 'Uygulayan',
    totalFunds: 'Toplam Fon Miktarı',
    operatorsIncome: 'Operatörlerin Geliri',
    totalAmountReturned: 'İade Edilen Toplam Tutar',
    totalAddedAmount: 'Toplam Eklenen Tutar',
    totalEarnedAmount: 'Toplam Kazanılan Tutar',
    totalBetAmount: 'Toplam Bahis Tutarı',
    balanceAdjustment: 'Bakiye Düzenleme',
    playerFullName: 'Oyuncu Adı & Soyadı',
    balanceAdjustmentDate: 'Bakiye Düzenleme Tarihi',
    walletType: 'Cüzdan Türü',
    fixedOddsTotalBetAmount: 'Sabit Oranlı Toplam Bahis Tutarı',
    fixedOddsTotalWinAmount: 'Sabit Oranlı Toplam Kazanç Miktarı',
    fixedOddsTotalLostAmount: 'Sabit Oranlı Toplam Kayıp Tutarı',
    fixedOddsBonusBetAmount: 'Sabit Oranlı Toplam Bonus Bahsi',
    casinoTotalBetAmount: 'Casino Oyunları Toplam Bahis Tutarı',
    casinoTotalWinAmount: 'Casino Oyunları Toplam Kazanç Miktarı',
    casinoTotalLostAmount: 'Casino Oyunları Toplam Kayıp Tutarı',
    casinoBonusBetAmount: 'Casino Oyunları Toplam Bonus Bahsi',
    sessionEndReason: 'Oturum Bitiş Nedeni',
    unsettledFundsAmount: 'Ödenmemiş Fon Tutarı',
    safeServer: 'Güvenli Sunucu',
    casinoGamesTotalBonusAmount: 'Casino Oyunları Toplam Bonus Tutarı',
    fixedOddsTotalBonusAmount: 'Sabit Oran Toplam Bonus Tutarı',
    mirroringServer: 'Yansıtma Sunucusu',
    logDate: 'Kayıt Tarihi',
    changeNotification: 'Bildirimi Değiştir',
    changeCategory: 'Kategoriyi Değiştir',
    changeDescription: 'Açıklamayı Değiştir',
    changeReason: 'Nedeni Değiştir',
    previousVersion: 'Önceki Sürüm',
    currentVersion: 'Güncel Sürüm',
    change: 'Değiştir',
    significantEvents: 'Önemli Etkinlikler',
    inaccessibilityAreas: 'Erişilmezlik Alanları',
    inaccessibilityFrom: 'Erişilemezlik Tarihinden',
    inaccessibilityTo: 'Erişilemezlik Tarihine',
    inaccessibilityReason: 'Erişilmezlik Nedeni',
    reconnectionFailedAttempts: 'Yeniden Bağlantı Başarısız Girişimler',
    serverName: 'Sunucu Adı',
    inaccessibilityDate: 'Erişilmezlik Tarihi',
    inaccessibilityServer: 'Erişilmezlik Sunucusu',
    largeTransactionsInfo: 'Sistem bu rapor için varsayılan çevrimi kullanmaktadır, eğer çevrimi değiştirmek isterseniz bunu Marka Ayarları→Çevrim Ayarları→Diğer bölümünden yapabilirsiniz.',
    transactionType: 'İşlem Türü',
    balanceAdjustmentCount: 'Bakiye Ayarlama Sayısı',
    selfExclusionCount: 'Kendini Hariç Tutma Sayısı',
    accountClosedCount: 'Hesap Kapatma Sayısı',
    operatorInterventionCount: 'Operatör Müdahale Sayısı',
    unusualEventsCount: 'Diğer Önemli/ Sıradışı Etkinlikler Sayısı',
    jackpotWinRatio: 'Jackpot Kazanma Oranı',
    jackpotConfiguration: 'JackPot Yapılandırması',
    jackpotName: 'Jackpot Adı',
    gamePaylistId: 'Oyun Ödeme Listesi ID',
    gameSessionId: 'Oyun Oturum ID',
    jackpotPrizeDate: 'Jackpot Ödül Tarihi',
    maximumJackpotLevel: 'Maksimum Jackpot Seviyesi',
    jackpotAmount: 'Jackpot Tutarı',
    processingUserID: 'Kullanıcı ID işleme',
    processingUserName: 'Kullanıcı Adı İşleme',
    confirmingUserID: 'Kullanıcı ID Onaylama',
    confirmingUserName: 'Kullanıcı Adı Onaylama',
    progressiveJackpotName: 'Progressive Jackpot Adı',
    setupParameters: 'Parametreleri Ayarla',
    existingGamesPaylist: 'Mevcut Oyunlar Ödeme Listesi',
    totalBetsAmount: 'Toplam Bahis Tutarı',
    totalContributionsWinAmount: 'Toplam Katkı Kazanç Tutarı',
    nonContributionFinance: 'Katılım Sağlamayan Finans',
    jackpotPrizeCurrentAmount: 'Jackpot Ödülü Güncel Tutar',
    jackpotContributionCurrentAmount: 'Jackpot Katkısı Güncel Tutar',
    terminationDate: 'Sonlandırma Tarihi',
    maximumAwardedAmount: 'Maksimum Ödül Tutarı',
    exceedingLimitAmount: 'Limit Aşımı Tutarı',
    promotionalItems: 'Promosyon Öğeleri',
    promotionId: 'Promosyon ID',
    promotionType: 'Promosyon Türü',
    totalBalanceAllocated: 'Dağıtılan Toplam Bakiye',
    totalAmountPrizes: 'Toplam Tutar - Ödüller',
    totalAmountUsed: 'Toplam Kullanılan Tutar',
    totalAdjustmentAmount: 'Toplam Düzeltme Tutarı',
    finalBalance: 'Son Bakiye',
    softwareChanges: 'Yazılım Değişiklikleri',
    taxationDaily: 'Günlük Vergilendirme',
    taxationMonthly: 'Aylık Vergilendirme',
    betWin: 'Bahis Kazanç',
    taxation: 'Vergilendirme',
    quarterDetails: 'Çeyrek Detaylar',
    statisticalReportQuarter: 'İstatistiksel Rapor Q{{quarter}} {{year}}',
    bettingSelfProtectionReportInfo: 'Sağlanan rapor yalnızca Markanın saat dilimine göre verilmiştir, bu sayfadaki herhangi bir saat dilimi değişikliği raporu etkilemez',
    bettingSelfProtectionReportNonAvailabilityInfo: 'Düzenleyici Rapor Bölümü seçilen Marka için mevcut değil',
  },
  ru: {
    changes: 'Изменения',
    changeDate: 'Дата изменения',
    changedBy: 'Изменено (кем)',
    gameRatio: 'Коэффициент игры',
    gameTypes: 'Типы игр',
    totalCurrentAmount: 'Общая текущая сумма',
    operatorIncome: 'Доход оператора',
    playerExclusion: 'Исключение игрока',
    exclusionType: 'Тип исключения',
    appliedBy: 'Применено (кем)',
    total: 'Всего',
    totalFunds: 'Всего средств',
    operatorsIncome: 'Доход операторов',
    totalAmountReturned: 'Общая сумма возврата средств',
    totalAddedAmount: 'Всего добавленная сумма',
    totalEarnedAmount: 'Всего заработанная сумма',
    totalBetAmount: 'Всего сумма ставок',
    balanceAdjustment: 'Пополнение баланса',
    playerFullName: 'Имя и фамилия игрока',
    balanceAdjustmentDate: 'Дата пополнения баланса',
    walletType: 'Тип кошелька',
    fixedOddsTotalBetAmount: 'Общая сумма ставок по фиксированным коэффициентам',
    fixedOddsTotalWinAmount: 'Общая сумма выигрышей по фиксированным коэффициентам',
    fixedOddsTotalLostAmount: 'Общая сумма проигрышей по фиксированным коэффициентам',
    fixedOddsBonusBetAmount: 'Всего бонусных ставок по фиксированным коэффициентам',
    casinoTotalBetAmount: 'Общая сумма ставок в казино играх',
    casinoTotalWinAmount: 'Общая сумма выигрышей в казино играх',
    casinoTotalLostAmount: 'Общая сумма проигрышей в казино играх',
    casinoBonusBetAmount: 'Всего сумма бонусных ставок в казино играх',
    sessionEndReason: 'Причина завершения сессии',
    unsettledFundsAmount: 'Сумма нераспределенных средств',
    safeServer: 'Безопасный сервер',
    casinoGamesTotalBonusAmount: 'Общая сумма бонуса в играх казино',
    fixedOddsTotalBonusAmount: 'Общая сумма бонуса с фиксированным коэффициентом',
    mirroringServer: 'Зеркальный сервер',
    logDate: ' Дата лога',
    changeNotification: 'Уведомление об изменениях',
    changeCategory: 'Категория изменений',
    changeDescription: 'Описание изменений',
    changeReason: 'Причина изменений',
    previousVersion: 'Предыдущая версия',
    currentVersion: 'Текущая версия',
    change: 'Изменить',
    significantEvents: 'Важные события',
    inaccessibilityAreas: 'Недоступные зоны',
    inaccessibilityFrom: 'Недоступность от',
    inaccessibilityTo: 'Недоступность',
    inaccessibilityReason: 'Причина недоступности',
    reconnectionFailedAttempts: 'Неудачные попытки повторного подключения',
    serverName: 'Название сервера',
    inaccessibilityDate: 'Дата недоступности',
    inaccessibilityServer: 'Сервер недоступности',
    largeTransactionsInfo:
      'Система использует конвертацию по умолчанию для этого отчета, если вы хотите изменить конвертацию, вы можете сделать это в разделе Настройки бренда→Настройки конвертации→Другие',
    transactionType: 'Тип транзакции',
    balanceAdjustmentCount: 'Подсчет корректировки баланса',
    selfExclusionCount: 'Подсчет самоисключений',
    accountClosedCount: 'Подсчет закрытых счетов',
    operatorInterventionCount: 'Подсчет вмешательств оператора',
    unusualEventsCount: 'Подсчет других важных/необычных событий ',
    jackpotWinRatio: 'Коэффициент выигрыша джекпота',
    jackpotConfiguration: 'Конфигурация джекпота',
    jackpotName: 'Название джекпота',
    gamePaylistId: 'ID игрового пейлиста',
    gameSessionId: 'ID игровой сессии',
    jackpotPrizeDate: 'Дата розыгрыша джекпота',
    maximumJackpotLevel: 'Максимальный уровень джекпота',
    jackpotAmount: 'Сумма джекпота',
    processingUserID: 'Обработка ID пользователя',
    processingUserName: 'Обработка имени пользователя',
    confirmingUserID: 'Подтверждение ID пользователя',
    confirmingUserName: 'Подтверждение имени пользователя',
    progressiveJackpotName: 'Название прогрессивного джекпота',
    setupParameters: 'Параметры настройки',
    existingGamesPaylist: 'Пейлист существующих игр',
    totalBetsAmount: 'Общая сумма ставок',
    totalContributionsWinAmount: 'Сумма выигрыша общих взносов ',
    nonContributionFinance: 'Финансирование без взносов ',
    jackpotPrizeCurrentAmount: 'Текущая сумма розыгрыша джекпота ',
    jackpotContributionCurrentAmount: 'Текущая сумма взноса в джекпот',
    terminationDate: 'Дата окончания',
    maximumAwardedAmount: 'Максимальная сумма вознаграждения',
    exceedingLimitAmount: 'Превышение суммы лимита',
    promotionalItems: 'Промо-изделия',
    promotionId: 'ID промоакции',
    promotionType: 'Тип промоакции',
    totalBalanceAllocated: 'Итого распределенный остаток',
    totalAmountPrizes: 'Итоговая сумма — призы',
    totalAmountUsed: 'Всего использованная сумма',
    totalAdjustmentAmount: 'Всего сумма корректировки',
    finalBalance: 'Итоговый баланс',
    softwareChanges: 'Изменения в программном обеспечении',
    taxationDaily: ' Ежедневное налогообложение',
    taxationMonthly: ' Ежемесяччное налогообложение',
    betWin: 'Выигрыш по ставке',
    taxation: 'Налогообложение',
    quarterDetails: 'Подробности квартала',
    statisticalReportQuarter: 'Статистический отчет Q{{квартал}} {{год}}',
    bettingSelfProtectionReportInfo: 'Предоставленный отчет основан только на часовом поясе бренда, любые изменения часового пояса на этой странице не повлияют на отчет',
    bettingSelfProtectionReportNonAvailabilityInfo: 'Раздел Нормативный отчет недоступен для выбранного бренда',
  },
  pt: {
    changes: 'Alterações',
    changeDate: 'Data da Alteração',
    changedBy: 'Alterado por',
    gameRatio: 'Proporção do Jogo',
    gameTypes: 'Tipos de Jogos',
    totalCurrentAmount: 'Valor Total Atual',
    operatorIncome: 'Rendimento do Operador',
    playerExclusion: 'Exclusão do Jogador',
    exclusionType: 'Tipo de Exclusão',
    appliedBy: 'Aplicado por',
    totalFunds: 'Total de Fundos',
    operatorsIncome: 'Rendimento dos Operadores',
    totalAmountReturned: 'Valor Total Devolvido',
    totalAddedAmount: 'Valor Total Adicionado',
    totalEarnedAmount: 'Valor Total Ganho',
    totalBetAmount: 'Valor Total da Aposta',
    balanceAdjustment: 'Ajuste do Saldo',
    playerFullName: 'Primeiro e Último Nome do Jogador',
    balanceAdjustmentDate: 'Data do Ajuste do Saldo',
    walletType: 'Tipo de Carteira',
    fixedOddsTotalBetAmount: 'Odds Fixas Valor Total da Aposta',
    fixedOddsTotalWinAmount: 'Odds Fixas Valor Total Ganho',
    fixedOddsTotalLostAmount: 'Odds Fixas Valor Total Perdido',
    fixedOddsBonusBetAmount: 'Odds Fixas Aposta Total de Bónus',
    casinoTotalBetAmount: 'Jogos de Casino Valor Total da Aposta',
    casinoTotalWinAmount: 'Jogos de Casino Valor Total Ganho',
    casinoTotalLostAmount: 'Jogos de Casino Valor Total Perdido',
    casinoBonusBetAmount: 'Jogos de Casino Aposta Total de Bónus',
    sessionEndReason: 'Motivo do Fim da Sessão',
    unsettledFundsAmount: 'Valor Não Resolvido de Fundos',
    safeServer: 'Servidor Seguro',
    casinoGamesTotalBonusAmount: 'Valor Total do Bônus de Jogos de Casino',
    fixedOddsTotalBonusAmount: 'Valor Total do Bônus de Odds Fixas',
    mirroringServer: 'Servidor Espelho',
    logDate: 'Data de registro',
    changeNotification: 'Notificação de alteração',
    changeCategory: 'Categoria de alteração',
    changeDescription: 'Alterar Descrição',
    changeReason: 'Alterar Motivo',
    previousVersion: 'Versão anterior',
    currentVersion: 'Versão atual',
    change: 'Alteração',
    significantEvents: 'Eventos significativos',
    inaccessibilityAreas: 'Zonas de inacessibilidade',
    inaccessibilityFrom: 'Inacessibilidade de',
    inaccessibilityTo: 'Inacessibilidade para',
    inaccessibilityReason: 'Motivo da inacessibilidade',
    reconnectionFailedAttempts: 'Tentativas de reconexão falhadas',
    serverName: 'Nome do servidor',
    inaccessibilityDate: 'Data de inacessibilidade',
    inaccessibilityServer: 'Servidor de Inacessibilidade',
    largeTransactionsInfo:
      'O sistema está a utilizar a conversão predefinida para este relatório. Se pretender alterar a conversão, pode fazê-lo a partir de Definições da marca→Definições de conversão→Outros',
    transactionType: 'Tipo de transação',
    balanceAdjustmentCount: 'Contagem de ajustamento do saldo',
    selfExclusionCount: 'Contagem de auto-exclusão',
    accountClosedCount: 'Contagem de contas encerradas',
    operatorInterventionCount: 'Contagem de intervenções do operador',
    unusualEventsCount: 'Contagem de outros acontecimentos significativos/incomuns ',
    jackpotWinRatio: 'Rácio de ganhos do jackpot',
    jackpotConfiguration: 'Configuração do JackPot',
    jackpotName: 'Nome do Jackpot',
    gamePaylistId: 'ID da lista de pagamento do jogo',
    gameSessionId: 'ID da sessão de jogo',
    jackpotPrizeDate: 'Data do prémio do jackpot',
    maximumJackpotLevel: 'Nível máximo do jackpot',
    jackpotAmount: 'Montante do jackpot',
    processingUserID: 'Processamento do ID do utilizador',
    processingUserName: 'Processamento do nome do utilizador',
    confirmingUserID: 'Confirmação do ID do utilizador',
    confirmingUserName: 'Confirmação do nome do utilizador',
    progressiveJackpotName: 'Nome do jackpot progressivo',
    setupParameters: 'Parâmetros de configuração',
    existingGamesPaylist: 'Jogos Existentes Lista de pagamentos',
    totalBetsAmount: 'Montante total de apostas',
    totalContributionsWinAmount: '\nTotal das Contribuições Montante de Ganho',
    nonContributionFinance: 'Financiamento sem contribuição',
    jackpotPrizeCurrentAmount: 'Montante atual do prémio do jackpot',
    jackpotContributionCurrentAmount: 'Contribuição para o Jackpot Montante Atual',
    terminationDate: 'Data de término',
    maximumAwardedAmount: 'Montante máximo atribuído',
    exceedingLimitAmount: 'Montante que excede o limite',
    promotionalItems: 'Itens promocionais',
    promotionId: 'ID da promoção',
    promotionType: 'Tipo de promoção',
    totalBalanceAllocated: 'Saldo Total Atribuído',
    totalAmountPrizes: 'Montante total - Prémios',
    totalAmountUsed: 'Montante total utilizado',
    totalAdjustmentAmount: 'Montante total de ajuste',
    finalBalance: 'Saldo final',
    softwareChanges: 'Alterações de software',
    taxationDaily: 'Tributação diária',
    taxationMonthly: 'Tributação mensal',
    betWin: 'Ganho de aposta',
    taxation: 'Tributação',
    quarterDetails: 'Detalhes do trimestre',
    statisticalReportQuarter: 'Relatório estatístico Q{{quarter}} {{year}}',
    bettingSelfProtectionReportInfo: 'O relatório fornecido baseia-se apenas no fuso horário da Marca, quaisquer alterações de fuso horário nesta página não afectarão o relatório',
    bettingSelfProtectionReportNonAvailabilityInfo: 'A Secção do Relatório Regulamentar não está disponível para a Marca selecionada',
  },
  zh: {
    changes: '变化',
    changeDate: '改变日期',
    changedBy: '更改者',
    gameRatio: '游戏比例',
    gameTypes: '游戏类型',
    totalCurrentAmount: '目前总金额',
    operatorIncome: '经营者收入',
    playerExclusion: '玩家排除',
    exclusionType: '排除类型',
    appliedBy: '应用方式',
    total: '总的',
    totalFunds: '资金总额',
    operatorsIncome: '经营者收入',
    totalAmountReturned: '返回的总金额',
    totalAddedAmount: '总增加金额',
    totalEarnedAmount: '总收入金额',
    totalBetAmount: '总赌注金额',
    balanceAdjustment: '余额调整',
    playerFullName: '玩家的名字和姓氏',
    balanceAdjustmentDate: '余额调整日期',
    walletType: '钱包种类',
    fixedOddsTotalBetAmount: '固定赔率总投注额',
    fixedOddsTotalLostAmount: '固定赔率总赢利金额',
    fixedOddsBonusBetAmount: '固定赔率总奖金投注',
    casinoTotalBetAmount: '娱乐场游戏总投注额',
    casinoTotalWinAmount: '娱乐场游戏总获胜金额',
    casinoTotalLostAmount: '娱乐场游戏总损失金额',
    casinoBonusBetAmount: '娱乐场游戏总奖金投注',
    sessionEndReason: '专场结束的原因',
    unsettledFundsAmount: '未结算资金金额',
    fixedOddsTotalWinAmount: '赢钱总额的固定赔率',
    safeServer: '安全的服务器',
    casinoGamesTotalBonusAmount: '娱乐场游戏的总奖金数额',
    fixedOddsTotalBonusAmount: '固定赔率总赢利金额',
    mirroringServer: '镜像服务器',
    logDate: '日志日期',
    changeNotification: '更改通知',
    changeCategory: '更改类别',
    changeDescription: '更改说明',
    changeReason: '更改原因',
    previousVersion: '先前版本',
    currentVersion: '当前版本',
    change: '更改',
    significantEvents: '重要活动',
    inaccessibilityAreas: '无法访问区域',
    inaccessibilityFrom: '无法访问从',
    inaccessibilityTo: '无法访问至',
    inaccessibilityReason: '无法访问原因',
    reconnectionFailedAttempts: '重新连接失败尝试',
    serverName: '服务器名称',
    inaccessibilityDate: '无法访问日期',
    inaccessibilityServer: '无法访问服务器',
    largeTransactionsInfo: '系统对该报告使用默认转换，如果您想更改转换，可以从品牌设置→转换设置→其他中进行更改。',
    transactionType: '交易类型',
    balanceAdjustmentCount: '余额调整计数',
    selfExclusionCount: '自我排除计数',
    accountClosedCount: '账户关闭计数',
    operatorInterventionCount: '操作员介入次数',
    unusualEventsCount: '其他重大/异常事件计数',
    jackpotWinRatio: '大奖中奖率',
    jackpotConfiguration: '大奖配置',
    jackpotName: '大奖名称',
    gamePaylistId: '游戏付费表ID',
    gameSessionId: '游戏会期ID',
    jackpotPrizeDate: '大奖日期',
    maximumJackpotLevel: '最高大奖级别',
    jackpotAmount: '大奖金额',
    processingUserID: '处理用户ID',
    processingUserName: '处理用户名',
    confirmingUserID: '确认用户ID',
    confirmingUserName: '确认用户名',
    progressiveJackpotName: '累积大奖名称',
    setupParameters: '设置参数',
    existingGamesPaylist: '现有游戏付费表',
    totalBetsAmount: '投注总额',
    totalContributionsWinAmount: '总贡献获胜金额',
    nonContributionFinance: '非贡献财务',
    jackpotPrizeCurrentAmount: '大奖奖品当前金额',
    jackpotContributionCurrentAmount: '大奖贡献当前金额',
    terminationDate: '终止日期',
    maximumAwardedAmount: '最高奖励金额',
    exceedingLimitAmount: '超出限制金额',
    promotionalItems: '促销品',
    promotionId: '促销ID',
    promotionType: '促销类型',
    totalBalanceAllocated: '分配余额共计',
    totalAmountPrizes: '总金额 - 奖品',
    totalAmountUsed: '已用总额',
    totalAdjustmentAmount: '调整总额',
    finalBalance: '最终余额',
    softwareChanges: '软件变更',
    taxationDaily: '税务日报',
    taxationMonthly: '税务月报',
    betWin: '投注赢',
    taxation: '税务',
    quarterDetails: '季度详情',
    statisticalReportQuarter: '统计报告Q{{quarter}} {{year}}',
    bettingSelfProtectionReportInfo: '所提供的报告仅基于品牌的时区，此页面中的任何时区变化都不会影响报告',
    bettingSelfProtectionReportNonAvailabilityInfo: '监管报告部分不适用于所选品牌',
  },
  ro: {
    changeDate: 'Data modificării',
    changedBy: 'Modificat de ',
    changes: 'Modificări',
    gameRatio: 'Raport de joc',
    gameTypes: 'Tipul jocului',
    totalCurrentAmount: 'Valoarea totală curentă',
    operatorIncome: 'Veniturile operatorului',
    playerExclusion: 'Excluderea jucătorilor',
    exclusionType: 'Tipul de excludere',
    appliedBy: 'Aplicat de către',
    totalFunds: 'Fonduri totale',
    operatorsIncome: 'Veniturile operatorilor',
    totalAmountReturned: 'Suma totală returnată',
    totalAddedAmount: 'Suma totală adăugată',
    totalEarnedAmount: 'Suma totală câștigată',
    totalBetAmount: 'Suma totală de pariu',
    balanceAdjustment: 'Ajustarea soldului',
    playerFullName: 'Numele și prenumele jucătorului',
    balanceAdjustmentDate: 'Data ajustării soldului',
    walletType: 'Tipul de portofel',
    fixedOddsTotalBetAmount: 'Suma totală de pariu în cote fixe',
    fixedOddsTotalWinAmount: 'Suma totală de câștig în cote fixe',
    fixedOddsTotalLostAmount: 'Suma totală de pierdere în cote fixe',
    fixedOddsBonusBetAmount: 'Suma totală de bonus în cote fixe',
    casinoTotalBetAmount: 'Suma totală a pariului la jocuri de Casino',
    casinoTotalWinAmount: 'Suma totală de câștig la jocurile de Casino',
    casinoTotalLostAmount: 'Suma totală pierdută la jocuri de casino',
    casinoBonusBetAmount: 'Pariul total de Bonus la jocurile de casino',
    sessionEndReason: 'Motivul încheierii sesiunii',
    unsettledFundsAmount: 'Suma fondurilor nedecontate ',
    safeServer: 'Server de siguranță',
    casinoGamesTotalBonusAmount: 'Suma totală a Bonusului la jocuri de casino',
    fixedOddsTotalBonusAmount: 'Suma totală a Bonusului în cotă fixă',
    mirroringServer: 'Serverul de oglindire',
    logDate: 'Data înregistrării',
  },
  bg: {},
  el: {
    changes: 'Αλλαγές',
    changeDate: 'Ημερομηνία αλλαγής',
    changedBy: 'Η αλλαγή έγινε από',
    gameRatio: 'Αναλογία διαστάσεων παιχνιδιού',
    gameTypes: 'Τύποι παιχνιδιού',
    totalCurrentAmount: 'Συνολικό τρέχον ποσό',
    operatorIncome: 'Έσοδα χειριστή',
    playerExclusion: 'Αποκλεισμός παίκτη',
    exclusionType: 'Τύπος αποκλεισμού',
    appliedBy: 'Εφαρμόστηκε από',
    totalFunds: 'Συνολικά χρήματα',
    operatorsIncome: 'Έσοδα χειριστών',
    totalAmountReturned: 'Συνολικό ποσό που επιστράφηκε',
    totalAddedAmount: 'Συνολικό ποσό που προστέθηκε',
    totalEarnedAmount: 'Συνολικό ποσό που κερδήθηκε',
    totalBetAmount: 'Συνολικό ποσό στοιχήματος',
    balanceAdjustment: 'Προσαρμογή υπολοίπου',
    playerFullName: "'Ονομα και Επώνυμο παίκτη",
    balanceAdjustmentDate: 'Ημερομηνία προσαρμογής υπολοίπου',
    walletType: 'Τύπος πορτοφολιού',
    fixedOddsTotalBetAmount: 'Συνολικό ποσό στοιχήματος με σταθερές αποδόσεις',
    fixedOddsTotalWinAmount: 'Συνολικό ποσό κέρδους με σταθερές αποδόσεις',
    fixedOddsTotalLostAmount: 'Συνολικό ποσό απώλειας με σταθερές αποδόσεις',
    fixedOddsBonusBetAmount: 'Συνολικό μπόνους στοίχημα με σταθερές αποδόσεις',
    casinoTotalBetAmount: 'Συνολικό ποσό στοιχήματος σε παιχνίδια καζίνο',
    casinoTotalWinAmount: 'Συνολικό ποσό κέρδους σε παιχνίδια καζίνο',
    casinoTotalLostAmount: 'Συνολικό ποσό απώλειας σε παιχνίδια καζίνο',
    casinoBonusBetAmount: 'Συνολικό στοίχημα μπόνους σε παιχνίδια καζίνο',
    sessionEndReason: 'Αιτία λήξης συνεδρίας',
    unsettledFundsAmount: 'Ποσό μη διευθετημένων χρημάτων',
    safeServer: 'Ασφαλής διακομιστής',
    casinoGamesTotalBonusAmount: 'Συνολικό ποσό μπόνους σε παιχνίδια καζίνο',
    fixedOddsTotalBonusAmount: 'Συνολικό ποσό μπόνους με σταθερές αποδόσεις',
    mirroringServer: 'Διακομιστής-καθρέπτης',
    logDate: 'Ημερομηνία αρχείου καταγραφής',
    changeNotification: 'Ειδοποίηση αλλαγής',
    changeCategory: 'Κατηγορία αλλαγής',
    changeDescription: 'Περιγραφή αλλαγής',
    changeReason: 'Αιτία αλλαγής',
    previousVersion: 'Προηγούμενη έκδοση',
    currentVersion: 'Τρέχουσα έκδοση',
    change: 'Αλλαγή',
    significantEvents: 'Σημαντικά γεγονότα',
    inaccessibilityAreas: 'Περιοχές έλλειψης προσβασιμότητας',
    inaccessibilityFrom: 'Έλλειψη προσβασιμότητας από',
    inaccessibilityTo: 'Έλλειψη προσβασιμότητας έως',
    inaccessibilityReason: 'Αιτία έλλειψης προσβασιμότητας',
    reconnectionFailedAttempts: 'Αποτυχημένες προσπάθειες επανασύνδεσης',
    serverName: 'Όνομα διακομιστή',
    inaccessibilityDate: 'Ημερομηνία έλλειψης προσβασιμότητας',
    inaccessibilityServer: 'Διακομιστής έλλειψης προσβασιμότητας',
    largeTransactionsInfo:
      'Το σύστημα χρησιμοποιεί την προεπιλεγμένη μετατροπή για αυτή την αναφορά. Αν θέλετε να αλλάξετε τη μετατροπή, μπορείτε να το κάνετε από Ρυθμίσεις επωνυμίας -> Ρυθμίσεις μετατροπών -> Άλλα',
    transactionType: 'Τύπος συναλλαγής',
    balanceAdjustmentCount: 'Αριθμός προσαρμογών υπολοίπου',
    selfExclusionCount: 'Αριθμός αυτοαποκλεισμών',
    accountClosedCount: 'Αριθμός κλειστών λογαριασμών',
    operatorInterventionCount: 'Αριθμός παρεμβάσεων χειριστή',
    unusualEventsCount: 'Αριθμός άλλων σημαντικών/ασυνήθιστων γεγονότων',
    jackpotWinRatio: 'Αναλογία κέρδους τζάκποτ',
    jackpotConfiguration: 'Διαμόρφωση τζάκποτ',
    jackpotName: 'Όνομα τζάκποτ',
    gamePaylistId: 'Αναγνωριστικό λίστας πληρωμών παιχνιδιού',
    gameSessionId: 'Αναγνωριστικό συνεδρίας παιχνιδιού',
    jackpotPrizeDate: 'Ημερομηνία επάθλου τζάκποτ',
    maximumJackpotLevel: 'Ανώτατο επίπεδο τζάκποτ',
    jackpotAmount: 'Ποσό τζάκποτ',
    processingUserID: 'Γίνεται επεξεργασία του αναγνωριστικού χρήστη',
    processingUserName: 'Γίνεται επεξεργασία του ονόματος χρήστη',
    confirmingUserID: 'Γίνεται επιβεβαίωση του αναγνωριστικού χρήστη',
    confirmingUserName: 'Γίνεται επιβεβαίωση του ονόματος χρήστη',
    progressiveJackpotName: 'Όνομα Προοδευτικού τζάκποτ',
    setupParameters: 'Παράμετροι ρύθμισης',
    existingGamesPaylist: 'Υφιστάμενη λίστα πληρωμών παιχνιδιών',
    totalBetsAmount: 'Συνολικό ποσό στοιχημάτων',
    totalContributionsWinAmount: 'Συνολικό ποσό κέρδους από συνεισφορές',
    nonContributionFinance: 'Οικονομικά χωρίς συνεισφορές',
    jackpotPrizeCurrentAmount: 'Τρέχον ποσό επάθλου τζάκποτ',
    jackpotContributionCurrentAmount: 'Τρέχον ποσό συνεισφοράς τζάκποτ',
    terminationDate: 'Ημερομηνία τερματισμού',
    maximumAwardedAmount: 'Μέγιστο ποσό που δόθηκε',
    exceedingLimitAmount: 'Υπερβαίνον ποσό ορίου',
    promotionalItems: 'Στοιχεία προωθητικών ενεργειών',
    promotionId: 'Αναγνωριστικό προωθητικής ενέργειας',
    promotionType: 'Τύπος προωθητικής ενέργειας',
    totalBalanceAllocated: 'Συνολικό υπόλοιπο που δόθηκε',
    totalAmountPrizes: 'Συνολικό ποσό - Έπαθλα',
    totalAmountUsed: 'Συνολικό ποσό που χρησιμοποιήθηκε',
    totalAdjustmentAmount: 'Συνολικό ποσό προσαρμογής',
    finalBalance: 'Τελικό υπόλοιπο',
    softwareChanges: 'Αλλαγές λογισμικού',
  },
};
