import AuditLogActionType from 'actions/auditLog/auditLogActionType';

const initialState = {
  userLogs: [],
  userLogsTotal: 0,
  playerLogs: [],
  playerLogsTotal: 0,
  userManagementLogs: [],
  userManagementLogsTotal: 0,
};

const auditLogReducer = (state = initialState, { type, payload }) => {
  switch (type) {
  case AuditLogActionType.SAVE_USER_LOGS:
    return {
      ...state,
      userLogs: payload.data.data,
      userLogsTotal: payload.data.total,
    };
  case AuditLogActionType.RESET_USER_LOGS:
    return {
      ...state,
      userLogs: [],
      userLogsTotal: 0,
    };
  case AuditLogActionType.SAVE_PLAYER_LOGS:
    return {
      ...state,
      playerLogs: payload.data.data,
      playerLogsTotal: payload.data.total,
    };
  case AuditLogActionType.RESET_PLAYER_LOGS:
    return {
      ...state,
      playerLogs: [],
      playerLogsTotal: 0,
    };
  case AuditLogActionType.SAVE_USER_MANAGEMENT_LOGS:
    return {
      ...state,
      userManagementLogs: payload.data.data,
      userManagementLogsTotal: payload.data.total,
    };
  case AuditLogActionType.RESET_USER_MANAGEMENT_LOGS:
    return {
      ...state,
      userManagementLogs: [],
      userManagementLogsTotal: 0,
    };
  default:
    return state;
  }
};

export default auditLogReducer;
