import { cloneElement } from 'react';
import { Form } from 'antd';
import PropTypes from 'prop-types';
import { StyledFormMainWrapper } from 'components/styledComponents/form/Form.styled';

const CreateBrandForm = (props) => {
  const { children, ...prop } = props;

  const [form] = Form.useForm();

  return (
    <StyledFormMainWrapper layout="vertical" form={form} {...prop}>
      {cloneElement(children, { form })}
    </StyledFormMainWrapper>
  );
};
CreateBrandForm.propTypes = {
  children: PropTypes.object.isRequired,
};
export default CreateBrandForm;
