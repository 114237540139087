import GameManagementActionType from './gameManagementActionType';

export const getGames = (params) => ({
  type: GameManagementActionType.GET_GAMES,
  payload: { params },
});

export const saveGames = (data) => ({
  type: GameManagementActionType.SAVE_GAMES,
  payload: { data },
});

export const resetGames = () => ({
  type: GameManagementActionType.RESET_GAMES,
});

export const exportGames = (params) => ({
  type: GameManagementActionType.EXPORT_GAMES,
  payload: { params },
});

export const getGame = (id, brandId) => ({
  type: GameManagementActionType.GET_GAME,
  payload: { id, brandId },
});

export const saveGame = (data) => ({
  type: GameManagementActionType.SAVE_GAME,
  payload: { data },
});

export const resetGame = () => ({
  type: GameManagementActionType.RESET_GAME,
});

export const editGame = (id, brandId, data) => ({
  type: GameManagementActionType.EDIT_GAME,
  payload: { id, brandId, data },
});

export const getGameThemesResource = (params, isLoading) => ({
  type: GameManagementActionType.GET_GAME_THEMES_RESOURCE,
  payload: { params, isLoading },
});

export const saveGameThemesResource = (data) => ({
  type: GameManagementActionType.SAVE_GAME_THEMES_RESOURCE,
  payload: { data },
});

export const resetGameThemesResource = () => ({
  type: GameManagementActionType.RESET_GAME_THEMES_RESOURCE,
});

export const setGameMaintenance = (id, brandId, data) => ({
  type: GameManagementActionType.SET_GAME_MAINTENANCE,
  payload: { id, brandId, data },
});

export const resetGameMaintenance = (id, brandId, name) => ({
  type: GameManagementActionType.RESET_GAME_MAINTENANCE,
  payload: { id, brandId, name },
});

export const setGamesMaintenance = (brandId, data) => ({
  type: GameManagementActionType.SET_GAMES_MAINTENANCE,
  payload: { brandId, data },
});

export const resetGamesMaintenance = (brandId, data) => ({
  type: GameManagementActionType.RESET_GAMES_MAINTENANCE,
  payload: { brandId, data },
});

export const editGames = (brandId, data) => ({
  type: GameManagementActionType.EDIT_GAMES,
  payload: { brandId, data },
});

export const getGameBadgesResource = (params, isLoading) => ({
  type: GameManagementActionType.GET_GAME_BADGES_RESOURCE,
  payload: { params, isLoading },
});

export const saveGameBadgesResource = (data) => ({
  type: GameManagementActionType.SAVE_GAME_BADGES_RESOURCE,
  payload: { data },
});

export const resetGameBadgesResource = () => ({
  type: GameManagementActionType.RESET_GAME_BADGES_RESOURCE,
});

export const createGameGroup = (data) => ({
  type: GameManagementActionType.CREATE_GAME_GROUP,
  payload: { data },
});

export const getGameGroups = (params, isLoading) => ({
  type: GameManagementActionType.GET_GAME_GROUPS,
  payload: { params, isLoading },
});

export const saveGameGroups = (data) => ({
  type: GameManagementActionType.SAVE_GAME_GROUPS,
  payload: { data },
});

export const resetGameGroups = (data) => ({
  type: GameManagementActionType.RESET_GAME_GROUPS,
  payload: { data },
});

export const deleteGameGroup = (id, params, name) => ({
  type: GameManagementActionType.DELETE_GAME_GROUP,
  payload: { id, params, name },
});

export const getCsvGames = (data) => ({
  type: GameManagementActionType.GET_CSV_GAMES,
  payload: { data },
});

export const saveCsvGames = (data) => ({
  type: GameManagementActionType.SAVE_CSV_GAMES,
  payload: { data },
});

export const resetCsvGames = () => ({
  type: GameManagementActionType.RESET_CSV_GAMES,
});

export const getCsvOrderedGames = (data) => ({
  type: GameManagementActionType.GET_CSV_ORDERED_GAMES,
  payload: { data },
});

export const changeEndedStatus = (status) => ({
  type: GameManagementActionType.CHANGE_GAME_MANAGEMENT_ENDED_STATUS,
  payload: { status },
});

export const getGameGroup = (id, params) => ({
  type: GameManagementActionType.GET_GAME_GROUP,
  payload: { id, params },
});

export const saveGameGroup = (data) => ({
  type: GameManagementActionType.SAVE_GAME_GROUP,
  payload: { data },
});

export const resetGameGroup = () => ({
  type: GameManagementActionType.RESET_GAME_GROUP,
});

export const editGameGroup = (id, data) => ({
  type: GameManagementActionType.EDIT_GAME_GROUP,
  payload: { id, data },
});

export const getGameGroupsResource = (params, isLoading) => ({
  type: GameManagementActionType.GET_GAME_GROUPS_RESOURCE,
  payload: { params, isLoading },
});

export const saveGameGroupsResource = (data) => ({
  type: GameManagementActionType.SAVE_GAME_GROUPS_RESOURCE,
  payload: { data },
});

export const resetGameGroupsResource = () => ({
  type: GameManagementActionType.RESET_GAME_GROUPS_RESOURCE,
});

export const changeGameGroupStatus = (id, data, name) => ({
  type: GameManagementActionType.CHANGE_GAME_GROUP_STATUS,
  payload: {
    id,
    data,
    name,
  },
});

export const changeGameStatus = (data) => ({
  type: GameManagementActionType.CHANGE_GAME_STATUS,
  payload: {
    data,
  },
});

export const getGameTypes = (params) => ({
  type: GameManagementActionType.GET_GAME_TYPES,
  payload: { params },
});

export const saveGameTypes = (data) => ({
  type: GameManagementActionType.SAVE_GAME_TYPES,
  payload: { data },
});

export const resetGameTypes = () => ({
  type: GameManagementActionType.RESET_GAME_TYPES,
});

export const createGameType = (data) => ({
  type: GameManagementActionType.CREATE_GAME_TYPE,
  payload: { data },
});

export const editGameType = (id, data) => ({
  type: GameManagementActionType.EDIT_GAME_TYPE,
  payload: { id, data },
});

export const deleteGameType = (id, name) => ({
  type: GameManagementActionType.DELETE_GAME_TYPE,
  payload: { id, name },
});

export const getGameType = (id) => ({
  type: GameManagementActionType.GET_GAME_TYPE,
  payload: { id },
});

export const saveGameType = (data) => ({
  type: GameManagementActionType.SAVE_GAME_TYPE,
  payload: { data },
});

export const resetGameType = () => ({
  type: GameManagementActionType.RESET_GAME_TYPE,
});

export const changeGameTypeState = (id, data, name) => ({
  type: GameManagementActionType.CHANGE_GAME_TYPE_STATE,
  payload: { id, data, name },
});

export const resetGameTypeGames = () => ({
  type: GameManagementActionType.RESET_GAME_TYPE_GAMES,
});

export const deleteGameTypeGames = (id, params) => ({
  type: GameManagementActionType.DELETE_GAME_TYPE_GAMES,
  payload: { id, params },
});

export const getCsvGameTypeGames = (data) => ({
  type: GameManagementActionType.GET_CSV_GAME_TYPE_GAMES,
  payload: { data },
});

export const saveCsvGameTypeGames = (data) => ({
  type: GameManagementActionType.SAVE_CSV_GAME_TYPE_GAMES,
  payload: { data },
});

export const resetCsvGameTypeGames = () => ({
  type: GameManagementActionType.RESET_CSV_GAME_TYPE_GAMES,
});
