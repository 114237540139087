import {
  all, call, put, takeLatest,
} from '@redux-saga/core/effects';
import NetworkService from 'services/networkService';
import { getError, getMessage } from 'utils/helpers';
import {
  changeEndedStatus, changeGenerateReportEndedStatus, changeLoadingState, setErrorMessage, setSuccessMessage,
} from 'actions/setting/settingActionCreator';
import { AppConstants } from 'constants/index';
import qs from 'qs';
import PlayerComplaintActionType from 'actions/playerComplaint/playerComplaintActionType';
import { savePlayerComplaints } from 'actions/playerComplaint/playerComplaintActionCreator';
import { i18n } from 'services';

function* getPlayerComplaints({ payload }) {
  try {
    const options = {
      params: payload.params,
      paramsSerializer: (params) => qs.stringify(params),
    };
    const { ReportingAdmin, PlayerComplaints } = AppConstants.api;
    yield put(changeLoadingState(true));
    const { data } = yield call(NetworkService.makeAPIGetRequest, [ReportingAdmin, PlayerComplaints], options);
    yield put(savePlayerComplaints(data));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err, true);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* generatePlayerComplaintsReport({ payload }) {
  try {
    const options = {
      params: payload.params,
      paramsSerializer: (params) => qs.stringify(params, { allowDots: true }),
    };
    yield put(changeLoadingState(true));
    const { ReportingAdmin, PlayerComplaints, Generate } = AppConstants.api;

    yield call(NetworkService.makeAPIGetRequest, [ReportingAdmin, PlayerComplaints, Generate], options);
    yield put(changeLoadingState(false));
    yield put(changeGenerateReportEndedStatus(true));
  } catch (err) {
    if (err) {
      const error = getError(err, true);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* changePlayerComplaintsStatus({ payload }) {
  try {
    const { data } = payload;
    const options = { data };
    yield put(changeLoadingState(true));
    const { ReportingAdmin, PlayerComplaints, StatusChange } = AppConstants.api;
    yield call(NetworkService.makeAPIPutRequest, [ReportingAdmin, PlayerComplaints, StatusChange], options);
    yield put(changeEndedStatus(true));
    yield put(changeLoadingState(false));
    yield put(setSuccessMessage(getMessage(i18n.t('communication:playerComplaint'), i18n.t('notification:slUpdated'))));
  } catch (err) {
    if (err) {
      const error = getError(err, true);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

export default function* playerComplaintSaga() {
  yield all([
    takeLatest(PlayerComplaintActionType.GET_PLAYER_COMPLAINTS, getPlayerComplaints),
    takeLatest(PlayerComplaintActionType.GENERATE_PLAYER_COMPLAINTS_REPORT, generatePlayerComplaintsReport),
    takeLatest(PlayerComplaintActionType.CHANGE_PLAYER_COMPLAINTS_STATUS, changePlayerComplaintsStatus),
  ]);
}
