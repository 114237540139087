import qs from 'qs';
import _ from 'lodash';
import NavigationService from 'services/navigationService';
import { sizes } from 'utils/styledHelpers';

const FilterService = {
  filterState: false,
  mobileExpand: null,

  setMobileExpand: (isExpand) => {
    FilterService.mobileExpand = isExpand;
  },

  getExpand: (expand) => expand === 'true',

  getQuery: (query) => qs.parse(query, {
    ignoreQueryPrefix: true,
  }),

  setQuery: (params, changeState = true, replaceHistory) => {
    if (changeState) {
      FilterService.filterState = !FilterService.filterState;
    }
    NavigationService(`?${qs.stringify(_.pickBy(params, (val) => _.identity(val) || _.isBoolean(val)))}`, replaceHistory);
  },

  getTableCols: (field, order, cols) => cols.map((column) => (column.dataIndex
    ? {
      ...column,
      sortOrder: column.dataIndex === field && order,
    }
    : column)),

  handleToggle: (query, expand, propName = 'expand') => {
    FilterService.setQuery(
      {
        ...query,
        [propName]: _.isNull(FilterService.mobileExpand) && window.innerWidth < sizes.sm ? true : !FilterService.getExpand(expand),
      },
      false,
    );
    if (_.isNull(FilterService.mobileExpand)) {
      FilterService.setMobileExpand(true);
    }
  },

  getFilterState: (query) => _.isEmpty(
    _.pickBy(
      {
        ...query,
        expand: undefined,
        pageNumber: undefined,
        tab: undefined,
        type: undefined,
        orderType: undefined,
        orderField: undefined,
      },
      (val) => _.identity(val) || _.isBoolean(val),
    ),
  ),
};

export default FilterService;
