import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { selectAuthUser, selectLatestReleaseNote } from 'selectors';
import { resetLatestReleaseNote } from 'actions/releaseNote/releaseNoteActionCreator';
import { Menu } from 'components/layout/header';

const mapDispatchToProps = (dispatch) => bindActionCreators(
  {
    resetLatestReleaseNote,
  },
  dispatch,
);

const mapStateToProps = ({ auth, releaseNote }) => ({
  user: selectAuthUser(auth),
  latestReleaseNote: selectLatestReleaseNote(releaseNote),
});

export default connect(mapStateToProps, mapDispatchToProps)(Menu);
