import { Navigate, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import Pages from 'containers/layout/Pages';
import { LocalStorageService } from 'services';

export const PrivateRoute = ({
  component, breadcrumb, componentProps, user, hide, ...rest
}) => {
  const isAuth = user && LocalStorageService.get('auth');
  const isSubMenu = rest.menuItem && rest.menuParentId;
  const location = useLocation();
  // todo check to prop in navigate
  return isAuth ? (
    <Pages breadcrumb={breadcrumb} hide={hide} component={component} activeMenu={isSubMenu ? rest.id : rest.menuParentId || rest.id} rest={{ ...componentProps }} />
  ) : (
    <Navigate replace to="/login" state={{ from: location.pathname + location.search }} />
  );
};

PrivateRoute.propTypes = {
  component: PropTypes.elementType.isRequired,
  breadcrumb: PropTypes.array,
  componentProps: PropTypes.object.isRequired,
  user: PropTypes.object,
  hide: PropTypes.func,
  claims: PropTypes.array,
};

PrivateRoute.defaultProps = {
  breadcrumb: undefined,
  user: null,
  hide: null,
  claims: null,
};
