import { Suspense, useEffect } from 'react';
import PropTypes from 'prop-types';
import NoAccess from 'containers/common/NoAccess';
import { Loading } from 'containers/layout';
import { ErrorBoundary, BreadcrumbNavigation } from 'components/layout';
import { useLayout } from 'hooks';

const Pages = (props) => {
  const {
    component: Component, breadcrumb, rest, breadcrumbParams, setActiveMenu, activeMenu, resetBreadCrumbParams, hide,
  } = props;

  const {
    showPartner, showBrand, showConversion, showTimeZone,
  } = rest;

  const {
    setShowPartner, setShowBrand, setShowConversion, setShowTimeZone,
  } = useLayout();

  useEffect(() => {
    setShowPartner?.(showPartner);
  }, [setShowPartner, showPartner]);

  useEffect(() => {
    setShowBrand?.(showBrand);
  }, [setShowBrand, showBrand]);

  useEffect(() => {
    setShowConversion?.(showConversion);
  }, [showConversion, setShowConversion]);

  useEffect(() => {
    setShowTimeZone?.(showTimeZone);
  }, [showTimeZone, setShowTimeZone]);

  useEffect(() => {
    if (activeMenu) {
      setActiveMenu(activeMenu);
    }
  }, [activeMenu, setActiveMenu]);

  const getBreadcrumb = () => breadcrumb.map((bc) => {
    if (bc.key) {
      return {
        ...bc,
        name: breadcrumbParams[bc.key],
      };
    }
    return bc;
  });

  const page = (
    <>
      {!!breadcrumb.length && <BreadcrumbNavigation breadcrumb={getBreadcrumb()} resetBreadCrumbParams={resetBreadCrumbParams} />}
      <ErrorBoundary>
        <Suspense fallback={<span />}>
          <Loading />
          <Component {...rest} />
        </Suspense>
      </ErrorBoundary>
    </>
  );
  return hide?.() ? <NoAccess /> : page;
};

Pages.propTypes = {
  component: PropTypes.elementType.isRequired,
  brandId: PropTypes.number,
  partnerId: PropTypes.number,
  setActiveMenu: PropTypes.func.isRequired,
  rest: PropTypes.object.isRequired,
  breadcrumb: PropTypes.array,
  permission: PropTypes.object,
  breadcrumbParams: PropTypes.object,
  activeMenu: PropTypes.number.isRequired,
  resetBreadCrumbParams: PropTypes.func.isRequired,
  claims: PropTypes.array,
  hide: PropTypes.func,
};

Pages.defaultProps = {
  breadcrumb: [],
  breadcrumbParams: {},
  permission: undefined,
  brandId: null,
  partnerId: null,
  claims: [],
  hide: null,
};

export default Pages;
