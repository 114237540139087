import AccountUpdatesActionType from './accountUpdatesActionType';

export const getPlayerAccountUpdates = (playerId, params) => ({
  type: AccountUpdatesActionType.GET_PLAYER_ACCOUNT_UPDATES,
  payload: { playerId, params },
});

export const savePlayerAccountUpdates = (data) => ({
  type: AccountUpdatesActionType.SAVE_PLAYER_ACCOUNT_UPDATES,
  payload: { data },
});

export const resetPlayerAccountUpdates = () => ({
  type: AccountUpdatesActionType.RESET_PLAYER_ACCOUNT_UPDATES,
});

export const getPlayerProfileControlNames = (params, isLoading) => ({
  type: AccountUpdatesActionType.GET_PLAYER_PROFILE_CONTROL_NAMES,
  payload: { params, isLoading },
});

export const savePlayerProfileControlNames = (data) => ({
  type: AccountUpdatesActionType.SAVE_PLAYER_PROFILE_CONTROL_NAMES,
  payload: { data },
});

export const resetPlayerProfileControlNames = () => ({
  type: AccountUpdatesActionType.RESET_PLAYER_PROFILE_CONTROL_NAMES,
});

export const exportPlayerAccountUpdates = (playerId, params) => ({
  type: AccountUpdatesActionType.EXPORT_PLAYER_ACCOUNT_UPDATES,
  payload: { playerId, params },
});
