import PropTypes from 'prop-types';
import { useState } from 'react';
import { Col, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import { StyledButtonBlue, StyledButtonBlueUnbordered, StyledButtonWrapperCentered } from 'components/styledComponents/button/Button.styled';
import { StyledCreateBrand } from 'components/brand/Brand.styled';
import Layouts from 'containers/brand/Layouts';
import { StyledAnimateHeight } from 'Global.styled';
import Games from './Games';

const ActivateProducts = (props) => {
  const {
    setData, data, next, back,
  } = props;

  const { t } = useTranslation();

  const [isCasino, changeCasinoState] = useState(data.isCasino);
  const [isSport, changeSportState] = useState(data.isSport);
  const [viewId, setViewId] = useState(data.providerViewId);

  const nextStep = (e) => {
    e.preventDefault();
    if (isCasino || isSport) {
      setData({
        ...data,
        isCasino,
        isSport,
        providerViewId: isSport ? viewId : null,
      });
      next();
    }
  };

  const backStep = (e) => {
    e.preventDefault();
    setData({
      ...data,
      isCasino,
      isSport,
      providerViewId: isSport ? viewId : null,
    });
    back();
  };

  return (
    <StyledCreateBrand>
      <Row type="flex" justify="center">
        <Col span={24} sm={20}>
          <Games key={0} name="RGS" checked={isCasino} changeState={changeCasinoState} text={t('brand:rgsActivation')} />
          <Games key={1} name="Sportsbook" checked={isSport} changeState={changeSportState} text={t('brand:sportsBookActivation')} />
          {isSport && (
            <StyledAnimateHeight>
              <Layouts setViewId={setViewId} viewId={viewId} />
            </StyledAnimateHeight>
          )}
        </Col>
      </Row>
      <Row type="flex" justify="center">
        <Col>
          <StyledButtonWrapperCentered>
            <StyledButtonBlueUnbordered type="default" onClick={backStep} size="large">
              {t('back')}
            </StyledButtonBlueUnbordered>
            <StyledButtonBlue type="default" onClick={nextStep} disabled={!isSport && !isCasino} size="large">
              {t('next')}
            </StyledButtonBlue>
          </StyledButtonWrapperCentered>
        </Col>
      </Row>
    </StyledCreateBrand>
  );
};

ActivateProducts.propTypes = {
  setData: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired,
  back: PropTypes.func.isRequired,
  next: PropTypes.func.isRequired,
};

export default ActivateProducts;
