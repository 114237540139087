export default {
  en: {
    location: 'Location',
    os: 'OS',
    browserVersion: 'Browser Version',
    requestMethod: 'Request Method',
    requestPath: 'Request Path',
    requestBody: 'Request Body',
    responseBody: 'Response Body',
  },
  es: {
    location: 'Ubicación',
    os: 'SO',
    browserVersion: 'Versión del navegador',
    requestMethod: 'Método de la solicitud',
    requestPath: 'Ruta de la solicitud',
    requestBody: 'Cuerpo de la solicitud',
    responseBody: 'Cuerpo de la respuesta',
  },
  tr: {
    location: 'Konum',
    os: 'OS',
    browserVersion: 'Tarayıcı Sürümü',
    requestMethod: 'İstek Yöntemi',
    requestPath: 'İstek Yolu',
    requestBody: 'İstek Gövdesi',
    responseBody: 'Yanıt Gövdesi',
  },
  ru: {
    location: 'Местонахождение',
    os: ' ОС',
    browserVersion: 'Версия браузера',
    requestMethod: 'Метод запроса',
    requestPath: 'Путь запроса',
    requestBody: 'Содержание запроса',
    responseBody: 'Содержание ответа',
  },
  pt: {
    location: 'Localização',
    os: 'SO',
    browserVersion: 'Versão do Navegador',
    requestMethod: 'Método da Solicitação',
    requestPath: 'Caminho da Solicitação',
    requestBody: 'Body do Pedido',
    responseBody: 'Body da Resposta',
  },
  zh: {
    location: '地址',
    os: '操作系统 （OS）',
    browserVersion: '浏览器版本',
    requestMethod: '请求方法',
    requestPath: '请求途径',
    requestBody: '请求主体',
    responseBody: '响应主体',
  },
  ro: {
    location: 'Locație',
    os: 'SO',
    browserVersion: 'Versiunea browserului',
    requestMethod: 'Versiunea browserului',
    requestPath: 'Cale de solicitare',
    requestBody: 'Corp de solicitare',
    responseBody: 'Corp de răspuns',
  },
  bg: {},
  el: {
    location: 'Τοποθεσία',
    os: 'Λειτουργικό σύστημα',
    browserVersion: 'Έκδοση προγράμματος περιήγησης',
    requestMethod: 'Μέθοδος αιτήματος',
    requestPath: 'Διαδρομή αιτήματος',
    requestBody: 'Σώμα κειμένου αιτήματος',
    responseBody: 'Σώμα κειμένου απάντησης',
  },
};
