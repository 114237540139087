import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { textAlignCenter } from 'utils/styledHelpers';

const StyledCopyright = styled.span`
  ${textAlignCenter()};
  font-size: 1rem;
  color: #9aa1a7;
  position: absolute;
  bottom: 3.4rem;
  display: ${(props) => (props.md ? 'flex' : 'none')};
`;

const StyledLoginAside = styled.div`
  background-size: 100% 100%;
  box-sizing: border-box;
  border-radius: ${(props) => `${props.theme.customBorderRadius.borderRadiusDefault} 0 0 ${props.theme.customBorderRadius.borderRadiusDefault}`};
`;
const StyledSeparator = styled.div`
  position: relative;
  height: 100%;
  &:before {
    position: absolute;
    top: 0;
    left: 50%;
    content: '';
    height: 100%;
    width: 1px;
    background-color: #c2c6cb;
  }
  display: ${(props) => (props.md ? 'block' : 'none')};
`;
const StyledAuthMainWrapper = styled.div`
  box-sizing: border-box;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  background: ${(props) => (props.md ? '#ffffff' : 'transparent')};
  box-shadow: ${(props) => (props.md ? '0 3px 6px #0000000d' : 'none')};
  padding: ${(props) => (props.md ? '7.7rem 5.7rem' : 0)};
  height: ${(props) => (props.md ? '35rem' : '100%')};
  position: relative;
  .ant-input-password .ant-input-suffix {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 0.8rem;
    font-size: 1.16rem;
    .anticon:hover {
      color: #999;
    }
  }

  form,
  & > div {
    justify-content: center;
    height: auto;
    width: 100%;
    .ant-row {
      margin-bottom: 0;
    }
    .ant-form-item-label > label {
      color: ${(props) => props.theme.colors.primaryText};
    }
    .ant-form-item-control-input-content,
    .ant-form-item-control-input-content .ant-input-affix-wrapper,
    .ant-btn {
      height: 36px;
    }
    .ant-input-affix-wrapper {
      padding: 0;
    }
    .ant-input {
      padding: 0.28rem 2.8rem 0.28rem 1.14rem;
    }
    .ant-form-item-control-input-content .ant-input-affix-wrapper input.ant-input,
    .ant-input-suffix {
      margin: 0;
    }
  }
`;
const StyledLoginWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: transparent linear-gradient(136deg, #bad8e1 0%, #d7ebe6 48%, #e3d7ed 100%) 0 0 no-repeat padding-box;
  box-shadow: 0 3px 6px #00000029;
  height: 100%;
  min-height: ${(props) => (!props.md ? '100vh' : 'auto')};
  > div {
    width: 100%;
  }
`;
const StyledFormItems = styled.div`
  .ant-form-item:last-child {
    margin-bottom: 0;
  }
`;

const StyledTitleForm = styled.h2`
  font-weight: ${(props) => props.theme.typography.fontWeightMedium};
  font-size: 1.57em;
  color: #4b4b4b;
  margin-bottom: 1.71rem;
`;

const StyledForgetLink = styled(Link)`
  font-weight: ${(props) => props.theme.typography.fontWeightRegular};
  font-size: ${(props) => props.theme.typography.fontSizeSmall};
  color: ${(props) => props.theme.colors.blue};
  border: initial;
  background: initial;
  cursor: pointer;
  margin: 0.28rem 0 0.57rem;
  @media (max-width: 768px) {
    text-decoration: underline;
    font-size: 1.14rem;
  }
`;
const StyledFlexEnd = styled.p`
  margin-top: 0.875rem;
  margin-left: auto;
  display: flex;
  flex-direction: column;
  margin-bottom: 5.7rem;
  text-align: right;
`;

const StyledFlexEndResend = styled(StyledFlexEnd)`
  margin-bottom: 8rem;
`;

const StyledFlexCorrect = styled.div`
  margin-top: auto;
`;

const InfoIconWrapper = styled.div`
  width: 1rem;
  height: 1rem;
  position: ${(props) => (props.right ? 'absolute' : 'unset')};
  right: ${(props) => (props.right ? props.right : '-30%')};
  cursor: pointer;
`;

const StyledForgetP = styled.p`
  color: ${(props) => props.theme.colors.primaryText};
  line-height: 20px;
  margin-top: 1.7rem;
  font-size: 1rem;
  margin-bottom: 8rem;
`;

const StyledFlexLabel = styled.div`
  display: flex;
  align-items: center;
  position: relative;
`;

const StyledAsideWrapper = styled.div`
  display: flex;
  flex-direction: ${(props) => (props.xl ? '' : 'column')};
  justify-content: center;
  align-items: center;
  height: 100%;
`;
const StyledLogo = styled.div`
  position: relative;
  display: ${(props) => (props.md ? 'flex' : 'none')};
  align-items: center;
  justify-content: center;
  flex-direction: column;
  .cls-1 {
    transform-origin: center center;
    animation: spin 3s forwards;
    @keyframes spin {
      0% {
        transform: scale(0.4) rotate(0);
      }
      100% {
        transform: scale(1) rotate(60deg);
      }
    }
  }
`;
const StyledLogoMobile = styled.div`
  margin-bottom: 3.4rem;
  display: ${(props) => (props.md ? 'none' : 'block')};
`;
const StyledAsideText = styled.div`
  margin-left: ${(props) => (props.xl ? '-4rem' : 0)};
  position: relative;
  z-index: 55;
  top: ${(props) => (props.xl ? '2rem' : '-5rem')};
  text-align: center;
  display: ${(props) => (props.md ? 'flex' : 'none')};
  flex-direction: column;
  > p {
    color: ${(props) => props.theme.colors.primaryText};
    font-size: 1.14rem;
    max-width: 23rem;
  }
  > div {
    text-align: ${(props) => (props.xl ? 'flex-start' : 'center')};
    margin-bottom: 1.28rem;
  }
`;

const StyledPasswordExpired = styled.div`
  > div {
    border: none;
    padding: 0;
    max-width: 22rem;
    width: 100%;
  }
  svg g circle {
    fill: ${(props) => props.theme.colors.danger};
  }
  div {
    color: ${(props) => props.theme.colors.danger};
    line-height: 1.57rem;
  }
`;
const StyledPasswordWrapper = styled.div`
  margin-top: auto;
`;
const StyledExpiredForm = styled.div`
  margin-bottom: 5.8rem;
  padding-top: 1.71rem;
`;

export {
  StyledLoginWrapper,
  StyledLogo,
  StyledLogoMobile,
  StyledAsideText,
  StyledAsideWrapper,
  StyledAuthMainWrapper,
  StyledFlexEnd,
  StyledFlexEndResend,
  StyledFlexCorrect,
  StyledLoginAside,
  StyledFormItems,
  StyledForgetP,
  StyledTitleForm,
  StyledForgetLink,
  StyledCopyright,
  StyledFlexLabel,
  InfoIconWrapper,
  StyledPasswordExpired,
  StyledPasswordWrapper,
  StyledExpiredForm,
  StyledSeparator,
};
