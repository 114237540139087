const CampaignActionType = {
  CREATE_CAMPAIGN: 'CREATE_CAMPAIGN',
  EDIT_CAMPAIGN: 'EDIT_CAMPAIGN',
  DELETE_CAMPAIGN: 'DELETE_CAMPAIGN',

  GET_CAMPAIGNS: 'GET_CAMPAIGNS',
  SAVE_CAMPAIGNS: 'SAVE_CAMPAIGNS',
  RESET_CAMPAIGNS: 'RESET_CAMPAIGNS',

  GET_CAMPAIGN: 'GET_CAMPAIGN',
  SAVE_CAMPAIGN: 'SAVE_CAMPAIGN',
  RESET_CAMPAIGN: 'RESET_CAMPAIGN',

  GET_CAMPAIGN_TRIGGER_CONDITIONS: 'GET_CAMPAIGN_TRIGGER_CONDITIONS',
  SAVE_CAMPAIGN_TRIGGER_CONDITIONS: 'SAVE_CAMPAIGN_TRIGGER_CONDITIONS',
  RESET_CAMPAIGN_TRIGGER_CONDITIONS: 'RESET_CAMPAIGN_TRIGGER_CONDITIONS',

  GET_CAMPAIGN_PLAYERS: 'GET_CAMPAIGN_PLAYERS',
  SAVE_CAMPAIGN_PLAYERS: 'SAVE_CAMPAIGN_PLAYERS',
  RESET_CAMPAIGN_PLAYERS: 'RESET_CAMPAIGN_PLAYERS',

  EDIT_CAMPAIGN_PLAYERS: 'EDIT_CAMPAIGN_PLAYERS',

  FINISH_CAMPAIGN: 'FINISH_CAMPAIGN',
  CANCEL_CAMPAIGN: 'CANCEL_CAMPAIGN',
  CANCEL_CAMPAIGN_PROMOTION: 'CANCEL_CAMPAIGN_PROMOTION',

  GET_CAMPAIGN_RECEIVED_DEPOSIT_STATISTICS: 'GET_CAMPAIGN_RECEIVED_DEPOSIT_STATISTICS',
  SAVE_CAMPAIGN_RECEIVED_DEPOSIT_STATISTICS: 'SAVE_CAMPAIGN_RECEIVED_DEPOSIT_STATISTICS',
  RESET_CAMPAIGN_RECEIVED_DEPOSIT_STATISTICS: 'RESET_CAMPAIGN_RECEIVED_DEPOSIT_STATISTICS',
  EXPORT_CAMPAIGN_RECEIVED_DEPOSIT_STATISTICS: 'EXPORT_CAMPAIGN_RECEIVED_DEPOSIT_STATISTICS',

  GET_CAMPAIGN_FAILED_DEPOSIT_STATISTICS: 'GET_CAMPAIGN_FAILED_DEPOSIT_STATISTICS',
  SAVE_CAMPAIGN_FAILED_DEPOSIT_STATISTICS: 'SAVE_CAMPAIGN_FAILED_DEPOSIT_STATISTICS',
  RESET_CAMPAIGN_FAILED_DEPOSIT_STATISTICS: 'RESET_CAMPAIGN_FAILED_DEPOSIT_STATISTICS',

  GET_CAMPAIGN_RECEIVED_VERIFICATION_STATISTICS: 'GET_CAMPAIGN_RECEIVED_VERIFICATION_STATISTICS',
  SAVE_CAMPAIGN_RECEIVED_VERIFICATION_STATISTICS: 'SAVE_CAMPAIGN_RECEIVED_VERIFICATION_STATISTICS',
  RESET_CAMPAIGN_RECEIVED_VERIFICATION_STATISTICS: 'RESET_CAMPAIGN_RECEIVED_VERIFICATION_STATISTICS',
  EXPORT_CAMPAIGN_RECEIVED_VERIFICATION_STATISTICS: 'EXPORT_CAMPAIGN_RECEIVED_VERIFICATION_STATISTICS',

  GET_CAMPAIGN_FAILED_VERIFICATION_STATISTICS: 'GET_CAMPAIGN_FAILED_VERIFICATION_STATISTICS',
  SAVE_CAMPAIGN_FAILED_VERIFICATION_STATISTICS: 'SAVE_CAMPAIGN_FAILED_VERIFICATION_STATISTICS',
  RESET_CAMPAIGN_FAILED_VERIFICATION_STATISTICS: 'RESET_CAMPAIGN_FAILED_VERIFICATION_STATISTICS',

  GET_CAMPAIGN_RECEIVED_SIGN_UP_STATISTICS: 'GET_CAMPAIGN_RECEIVED_SIGN_UP_STATISTICS',
  SAVE_CAMPAIGN_RECEIVED_SIGN_UP_STATISTICS: 'SAVE_CAMPAIGN_RECEIVED_SIGN_UP_STATISTICS',
  RESET_CAMPAIGN_RECEIVED_SIGN_UP_STATISTICS: 'RESET_CAMPAIGN_RECEIVED_SIGN_UP_STATISTICS',
  EXPORT_CAMPAIGN_RECEIVED_SIGN_UP_STATISTICS: 'EXPORT_CAMPAIGN_RECEIVED_SIGN_UP_STATISTICS',

  GET_CAMPAIGN_FAILED_SIGN_UP_STATISTICS: 'GET_CAMPAIGN_FAILED_SIGN_UP_STATISTICS',
  SAVE_CAMPAIGN_FAILED_SIGN_UP_STATISTICS: 'SAVE_CAMPAIGN_FAILED_SIGN_UP_STATISTICS',
  RESET_CAMPAIGN_FAILED_SIGN_UP_STATISTICS: 'RESET_CAMPAIGN_FAILED_SIGN_UP_STATISTICS',

  GET_CAMPAIGN_RECEIVED_NO_TRIGGER_STATISTICS: 'GET_CAMPAIGN_RECEIVED_NO_TRIGGER_STATISTICS',
  SAVE_CAMPAIGN_RECEIVED_NO_TRIGGER_STATISTICS: 'SAVE_CAMPAIGN_RECEIVED_NO_TRIGGER_STATISTICS',
  RESET_CAMPAIGN_RECEIVED_NO_TRIGGER_STATISTICS: 'RESET_CAMPAIGN_RECEIVED_NO_TRIGGER_STATISTICS',
  EXPORT_CAMPAIGN_RECEIVED_NO_TRIGGER_STATISTICS: 'EXPORT_CAMPAIGN_RECEIVED_NO_TRIGGER_STATISTICS',

  GET_CAMPAIGN_FAILED_NO_TRIGGER_STATISTICS: 'GET_CAMPAIGN_FAILED_NO_TRIGGER_STATISTICS',
  SAVE_CAMPAIGN_FAILED_NO_TRIGGER_STATISTICS: 'SAVE_CAMPAIGN_FAILED_NO_TRIGGER_STATISTICS',
  RESET_CAMPAIGN_FAILED_NO_TRIGGER_STATISTICS: 'RESET_CAMPAIGN_FAILED_NO_TRIGGER_STATISTICS',

  CREATE_CROSS_PLATFORM_CAMPAIGN: 'CREATE_CROSS_PLATFORM_CAMPAIGN',

  GET_CROSS_PLATFORM_CAMPAIGNS: 'GET_CROSS_PLATFORM_CAMPAIGNS',
  SAVE_CROSS_PLATFORM_CAMPAIGNS: 'SAVE_CROSS_PLATFORM_CAMPAIGNS',
  RESET_CROSS_PLATFORM_CAMPAIGNS: 'RESET_CROSS_PLATFORM_CAMPAIGNS',

  GET_CROSS_PLATFORM_CAMPAIGN: 'GET_CROSS_PLATFORM_CAMPAIGN',
  SAVE_CROSS_PLATFORM_CAMPAIGN: 'SAVE_CROSS_PLATFORM_CAMPAIGN',
  RESET_CROSS_PLATFORM_CAMPAIGN: 'RESET_CROSS_PLATFORM_CAMPAIGN',

  FINISH_CROSS_PLATFORM_CAMPAIGN: 'FINISH_CROSS_PLATFORM_CAMPAIGN',
  CANCEL_CROSS_PLATFORM_CAMPAIGN: 'CANCEL_CROSS_PLATFORM_CAMPAIGN',
  CANCEL_CROSS_PLATFORM_CAMPAIGN_PROMOTION: 'CANCEL_CROSS_PLATFORM_CAMPAIGN_PROMOTION',

  GET_CROSS_PLATFORM_CAMPAIGN_NO_TRIGGER_STATISTICS: 'GET_CROSS_PLATFORM_CAMPAIGN_NO_TRIGGER_STATISTICS',
  SAVE_CROSS_PLATFORM_CAMPAIGN_NO_TRIGGER_STATISTICS: 'SAVE_CROSS_PLATFORM_CAMPAIGN_NO_TRIGGER_STATISTICS',
  RESET_CROSS_PLATFORM_CAMPAIGN_NO_TRIGGER_STATISTICS: 'RESET_CROSS_PLATFORM_CAMPAIGN_NO_TRIGGER_STATISTICS',
  EXPORT_CROSS_PLATFORM_CAMPAIGN_NO_TRIGGER_STATISTICS: 'EXPORT_CROSS_PLATFORM_CAMPAIGN_NO_TRIGGER_STATISTICS',

  GET_CROSS_PLATFORM_CAMPAIGN_USAGES: 'GET_CROSS_PLATFORM_CAMPAIGN_USAGES',
  SAVE_CROSS_PLATFORM_CAMPAIGN_USAGES: 'SAVE_CROSS_PLATFORM_CAMPAIGN_USAGES',
  RESET_CROSS_PLATFORM_CAMPAIGN_USAGES: 'RESET_CROSS_PLATFORM_CAMPAIGN_USAGES',

  GET_CAMPAIGN_CONTENT_CONFIG: 'GET_CAMPAIGN_CONTENT_CONFIG',
  SAVE_CAMPAIGN_CONTENT_CONFIG: 'SAVE_CAMPAIGN_CONTENT_CONFIG',
  RESET_CAMPAIGN_CONTENT_CONFIG: 'RESET_CAMPAIGN_CONTENT_CONFIG',
  EDIT_CAMPAIGN_CONTENT_CONFIG: 'EDIT_CAMPAIGN_CONTENT_CONFIG',
  CHANGE_CONTENT_CONFIG_LOADING_STATE: 'CHANGE_CONTENT_CONFIG_LOADING_STATE',
  CHANGE_CAMPAIGN_LOADING_STATE: 'CHANGE_CAMPAIGN_LOADING_STATE',
  CHANGE_CAMPAIGN_PLAYERS_LOADING_STATE: 'CHANGE_CAMPAIGN_PLAYERS_LOADING_STATE',

  GET_CAMPAIGN_PARTICIPANT_PLAYERS: 'GET_CAMPAIGN_PARTICIPANT_PLAYERS',
  SAVE_CAMPAIGN_PARTICIPANT_PLAYERS: 'SAVE_CAMPAIGN_PARTICIPANT_PLAYERS',
  RESET_CAMPAIGN_PARTICIPANT_PLAYERS: 'RESET_CAMPAIGN_PARTICIPANT_PLAYERS',

  GET_CROSS_PLATFORM_CONTENT_CONFIG: 'GET_CROSS_PLATFORM_CONTENT_CONFIG',
  SAVE_CROSS_PLATFORM_CONTENT_CONFIG: 'SAVE_CROSS_PLATFORM_CONTENT_CONFIG',
  RESET_CROSS_PLATFORM_CONTENT_CONFIG: 'RESET_CROSS_PLATFORM_CONTENT_CONFIG',
  EDIT_CROSS_PLATFORM_CONTENT_CONFIG: 'EDIT_CROSS_PLATFORM_CONTENT_CONFIG',

  GET_CAMPAIGN_RECEIVED_SIGN_IN_STATISTICS: 'GET_CAMPAIGN_RECEIVED_SIGN_IN_STATISTICS',
  SAVE_CAMPAIGN_RECEIVED_SIGN_IN_STATISTICS: 'SAVE_CAMPAIGN_RECEIVED_SIGN_IN_STATISTICS',
  RESET_CAMPAIGN_RECEIVED_SIGN_IN_STATISTICS: 'RESET_CAMPAIGN_RECEIVED_SIGN_IN_STATISTICS',
  EXPORT_CAMPAIGN_RECEIVED_SIGN_IN_STATISTICS: 'EXPORT_CAMPAIGN_RECEIVED_SIGN_IN_STATISTICS',

  GET_CAMPAIGN_FAILED_SIGN_IN_STATISTICS: 'GET_CAMPAIGN_FAILED_SIGN_IN_STATISTICS',
  SAVE_CAMPAIGN_FAILED_SIGN_IN_STATISTICS: 'SAVE_CAMPAIGN_FAILED_SIGN_IN_STATISTICS',
  RESET_CAMPAIGN_FAILED_SIGN_IN_STATISTICS: 'RESET_CAMPAIGN_FAILED_SIGN_IN_STATISTICS',
};

export default CampaignActionType;
