import styled from 'styled-components';

const StyledSportsbookNotActive = styled.div`
  padding: 11.8rem 2rem 6.42rem;
  text-align: center;
  img {
    margin-bottom: 1.71rem;
  }
  p {
    max-width: 32rem;
    margin: 0 auto 1.14rem;
  }
  button {
    margin: 0 auto;
  }
`;

const StyledNameWithIcon = styled.div`
  display: flex;
  align-items: center;
  padding: 0.68rem 0;
  > div {
    border-radius: ${(props) => props.theme.customBorderRadius.borderRadiusSmall};
    flex: 0 0 2.57rem;
    height: 2.57rem;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 1.28rem;
    background: ${(props) => props.theme.colors.blue}!important;
    svg {
      g path,
      path {
        fill: ${(props) => props.theme.colors.white}!important;
      }
    }
  }
  > p {
    font-weight: ${(props) => props.theme.typography.fontWeightMedium};
  }
`;

export { StyledSportsbookNotActive, StyledNameWithIcon };
