const QuickSearchActionType = {
  GET_PLAYER_SEARCH_RESULT: 'GET_PLAYER_SEARCH_RESULT',
  GET_PAYMENT_SEARCH_RESULT: 'GET_PAYMENT_SEARCH_RESULT',
  GET_BET_SEARCH_RESULT: 'GET_BET_SEARCH_RESULT',

  SAVE_SEARCH_RESULT: 'SAVE_SEARCH_RESULT',
  RESET_SEARCH_RESULT: 'RESET_SEARCH_RESULT',
};

export default QuickSearchActionType;
