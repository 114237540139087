import KycActionType from './kycActionType';

export const getKycReport = (params) => ({
  type: KycActionType.GET_KYC_REPORT,
  payload: { params },
});

export const saveKycReport = (data) => ({
  type: KycActionType.SAVE_KYC_REPORT,
  payload: { data },
});

export const resetKycReport = () => ({
  type: KycActionType.RESET_KYC_REPORT,
});

export const getPlayerAttachments = (playerId, params) => ({
  type: KycActionType.GET_PLAYER_ATTACHMENTS,
  payload: { playerId, params },
});

export const savePlayerAttachments = (data) => ({
  type: KycActionType.SAVE_PLAYER_ATTACHMENTS,
  payload: { data },
});

export const rejectPlayerAttachment = (data) => ({
  type: KycActionType.REJECT_PLAYER_ATTACHMENT,
  payload: {
    data,
  },
});

export const approvePlayerAttachment = (data) => ({
  type: KycActionType.APPROVE_PLAYER_ATTACHMENT,
  payload: { data },
});

export const addPlayerAttachment = (data) => ({
  type: KycActionType.ADD_PLAYER_ATTACHMENT,
  payload: { data },
});

export const archivePlayerAttachment = (data) => ({
  type: KycActionType.ARCHIVE_PLAYER_ATTACHMENT,
  payload: {
    data,
  },
});

export const verifyPlayerAttachment = (id, option, type) => ({
  type: KycActionType.VERIFY_PLAYER_ATTACHMENT,
  payload: { id, option, type },
});

export const getPlayerAttachmentsHistory = (playerId, params) => ({
  type: KycActionType.GET_PLAYER_ATTACHMENTS_HISTORY,
  payload: { playerId, params },
});

export const savePlayerAttachmentsHistory = (data) => ({
  type: KycActionType.SAVE_PLAYER_ATTACHMENTS_HISTORY,
  payload: { data },
});

export const resetPlayerAttachmentsHistory = () => ({
  type: KycActionType.RESET_PLAYER_ATTACHMENTS_HISTORY,
});

export const updatePlayerAttachment = (data, type) => ({
  type: KycActionType.UPDATE_PLAYER_ATTACHMENT,
  payload: { data, type },
});

export const resetPlayerAttachments = () => ({
  type: KycActionType.RESET_PLAYER_ATTACHMENTS,
});

export const startProcess = (id) => ({
  type: KycActionType.START_PROCESS,
  payload: { id },
});

export const movePlayerAttachment = (id, data) => ({
  type: KycActionType.MOVE_PLAYER_ATTACHMENT,
  payload: { id, data },
});

export const editAndApprovePlayerAttachment = (id, data) => ({
  type: KycActionType.EDIT_AND_APPROVE_PLAYER_ATTACHMENT,
  payload: { id, data },
});
