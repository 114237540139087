export const colors = {
  '#e20a16': {
    name: 'Dust Red',
    background: '#e20a1630',
  },
  '#fa791c': {
    name: 'Volcano',
    background: '#fa791c30',
  },
  '#fa8c16': {
    name: 'Sunset Orange',
    background: '#fa8c1630',
  },
  '#faad14': {
    name: 'Calendula Gold',
    background: '#faad1430',
  },
  '#fadb14': {
    name: 'Sunrise Yellow',
    background: '#fadb1430',
  },
  '#a0d911': {
    name: 'Lime',
    background: '#a0d91130',
  },
  '#52c41a': {
    name: 'Polar Green',
    background: '#52c41a30',
  },
  '#13c2c2': {
    name: 'Cyan',
    background: '#13c2c230',
  },
  '#1890ff': {
    name: 'Daybreak Blue',
    background: '#1890ff30',
  },
  '#2f54eb': {
    name: 'Geek Blue',
    background: '#2f54eb30',
  },
  '#722ed1': {
    name: 'Golden Purple',
    background: '#722ed130',
  },
  '#eb2f96': {
    name: 'Magenta',
    background: '#eb2f9630',
  },
  '#8d939c': {
    name: 'Natural Gray',
    background: '#8d939c30',
  },
};
