import CashierActionType from './cashierActionType';

export const getCashierInventory = (params) => ({
  type: CashierActionType.GET_CASHIER_INVENTORY,
  payload: { params },
});

export const saveCashierInventory = (data) => ({
  type: CashierActionType.SAVE_CASHIER_INVENTORY,
  payload: { data },
});

export const resetCashierInventory = () => ({
  type: CashierActionType.RESET_CASHIER_INVENTORY,
});

export const editCashierInventory = (id, brandId, data) => ({
  type: CashierActionType.EDIT_CASHIER_INVENTORY,
  payload: { id, brandId, data },
});

export const getCashierDeposit = (params) => ({
  type: CashierActionType.GET_CASHIER_DEPOSIT,
  payload: { params },
});

export const saveCashierDeposit = (data) => ({
  type: CashierActionType.SAVE_CASHIER_DEPOSIT,
  payload: { data },
});

export const resetCashierDeposit = () => ({
  type: CashierActionType.RESET_CASHIER_DEPOSIT,
});

export const deleteCashierDeposit = (id) => ({
  type: CashierActionType.DELETE_CASHIER_DEPOSIT,
  payload: { id },
});

export const createCashierDeposit = (data) => ({
  type: CashierActionType.CREATE_CASHIER_DEPOSIT,
  payload: { data },
});

export const editCashierDeposit = (id, data) => ({
  type: CashierActionType.EDIT_CASHIER_DEPOSIT,
  payload: { id, data },
});

export const reorderCashierDeposit = (categoryId, data) => ({
  type: CashierActionType.REORDER_CASHIER_DEPOSIT,
  payload: { categoryId, data },
});

export const getDepositPaymentMethodsResource = (params, isLoading) => ({
  type: CashierActionType.GET_DEPOSIT_PAYMENT_METHODS_RESOURCE,
  payload: { params, isLoading },
});

export const saveDepositPaymentMethodsResource = (data) => ({
  type: CashierActionType.SAVE_DEPOSIT_PAYMENT_METHODS_RESOURCE,
  payload: { data },
});

export const resetDepositPaymentMethodsResource = () => ({
  type: CashierActionType.RESET_DEPOSIT_PAYMENT_METHODS_RESOURCE,
});

export const getCashierDepositCategory = (id, params) => ({
  type: CashierActionType.GET_CASHIER_DEPOSIT_CATEGORY,
  payload: { id, params },
});

export const saveCashierDepositCategory = (data) => ({
  type: CashierActionType.SAVE_CASHIER_DEPOSIT_CATEGORY,
  payload: { data },
});

export const resetCashierDepositCategory = () => ({
  type: CashierActionType.RESET_CASHIER_DEPOSIT_CATEGORY,
});

export const getCashierWithdrawal = (params) => ({
  type: CashierActionType.GET_CASHIER_WITHDRAWAL,
  payload: { params },
});

export const saveCashierWithdrawal = (data) => ({
  type: CashierActionType.SAVE_CASHIER_WITHDRAWAL,
  payload: { data },
});

export const resetCashierWithdrawal = () => ({
  type: CashierActionType.RESET_CASHIER_WITHDRAWAL,
});

export const getWithdrawalPaymentMethodsResource = (params, isLoading) => ({
  type: CashierActionType.GET_WITHDRAWAL_PAYMENT_METHODS_RESOURCE,
  payload: { params, isLoading },
});

export const saveWithdrawalPaymentMethodsResource = (data) => ({
  type: CashierActionType.SAVE_WITHDRAWAL_PAYMENT_METHODS_RESOURCE,
  payload: { data },
});

export const resetWithdrawalPaymentMethodsResource = () => ({
  type: CashierActionType.RESET_WITHDRAWAL_PAYMENT_METHODS_RESOURCE,
});

export const getCashierWithdrawalCategory = (id, params) => ({
  type: CashierActionType.GET_CASHIER_WITHDRAWAL_CATEGORY,
  payload: { id, params },
});

export const saveCashierWithdrawalCategory = (data) => ({
  type: CashierActionType.SAVE_CASHIER_WITHDRAWAL_CATEGORY,
  payload: { data },
});

export const resetCashierWithdrawalCategory = () => ({
  type: CashierActionType.RESET_CASHIER_WITHDRAWAL_CATEGORY,
});

export const deleteCashierWithdrawal = (id) => ({
  type: CashierActionType.DELETE_CASHIER_WITHDRAWAL,
  payload: { id },
});

export const createCashierWithdrawal = (data) => ({
  type: CashierActionType.CREATE_CASHIER_WITHDRAWAL,
  payload: { data },
});

export const editCashierWithdrawal = (id, data) => ({
  type: CashierActionType.EDIT_CASHIER_WITHDRAWAL,
  payload: { id, data },
});

export const reorderCashierWithdrawal = (categoryId, data) => ({
  type: CashierActionType.REORDER_CASHIER_WITHDRAWAL,
  payload: { categoryId, data },
});

export const getCashierDepositPaymentMethods = (id, params) => ({
  type: CashierActionType.GET_CASHIER_DEPOSIT_PAYMENT_METHODS,
  payload: { id, params },
});

export const saveCashierDepositPaymentMethods = (data) => ({
  type: CashierActionType.SAVE_CASHIER_DEPOSIT_PAYMENT_METHODS,
  payload: { data },
});

export const resetCashierDepositPaymentMethods = () => ({
  type: CashierActionType.RESET_CASHIER_DEPOSIT_PAYMENT_METHODS,
});

export const depositPaymentMethodStateChange = (id, data) => ({
  type: CashierActionType.DEPOSIT_PAYMENT_METHOD_STATE_CHANGE,
  payload: { id, data },
});

export const depositPaymentMethodMove = (id, data) => ({
  type: CashierActionType.DEPOSIT_PAYMENT_METHOD_MOVE,
  payload: { id, data },
});

export const depositPaymentMethodReorder = (id, data) => ({
  type: CashierActionType.DEPOSIT_PAYMENT_METHOD_REORDER,
  payload: { id, data },
});

export const getDepositCategoryResource = (params, isLoading) => ({
  type: CashierActionType.GET_DEPOSIT_CATEGORY_RESOURCE,
  payload: { params, isLoading },
});

export const saveDepositCategoryResource = (data) => ({
  type: CashierActionType.SAVE_DEPOSIT_CATEGORY_RESOURCE,
  payload: { data },
});

export const resetDepositCategoryResource = () => ({
  type: CashierActionType.RESET_DEPOSIT_CATEGORY_RESOURCE,
});

export const getCashierWithdrawalPaymentMethods = (id, params) => ({
  type: CashierActionType.GET_CASHIER_WITHDRAWAL_PAYMENT_METHODS,
  payload: { id, params },
});

export const saveCashierWithdrawalPaymentMethods = (data) => ({
  type: CashierActionType.SAVE_CASHIER_WITHDRAWAL_PAYMENT_METHODS,
  payload: { data },
});

export const resetCashierWithdrawalPaymentMethods = () => ({
  type: CashierActionType.RESET_CASHIER_WITHDRAWAL_PAYMENT_METHODS,
});

export const withdrawalPaymentMethodStateChange = (id, data) => ({
  type: CashierActionType.WITHDRAWAL_PAYMENT_METHOD_STATE_CHANGE,
  payload: { id, data },
});

export const withdrawalPaymentMethodMove = (id, data) => ({
  type: CashierActionType.WITHDRAWAL_PAYMENT_METHOD_MOVE,
  payload: { id, data },
});

export const withdrawalPaymentMethodReorder = (id, data) => ({
  type: CashierActionType.WITHDRAWAL_PAYMENT_METHOD_REORDER,
  payload: { id, data },
});

export const getWithdrawalCategoryResource = (params, isLoading) => ({
  type: CashierActionType.GET_WITHDRAWAL_CATEGORY_RESOURCE,
  payload: { params, isLoading },
});

export const saveWithdrawalCategoryResource = (data) => ({
  type: CashierActionType.SAVE_WITHDRAWAL_CATEGORY_RESOURCE,
  payload: { data },
});

export const resetWithdrawalCategoryResource = () => ({
  type: CashierActionType.RESET_WITHDRAWAL_CATEGORY_RESOURCE,
});

export const getCashierSettings = (brandId) => ({
  type: CashierActionType.GET_CASHIER_SETTINGS,
  payload: { brandId },
});

export const saveCashierGeneralSettings = (data) => ({
  type: CashierActionType.SAVE_CASHIER_GENERAL_SETTINGS,
  payload: { data },
});

export const resetCashierGeneralSettings = () => ({
  type: CashierActionType.RESET_CASHIER_GENERAL_SETTINGS,
});

export const saveCashierBrandSettings = (data) => ({
  type: CashierActionType.SAVE_CASHIER_BRAND_SETTINGS,
  payload: { data },
});

export const resetCashierBrandSettings = () => ({
  type: CashierActionType.RESET_CASHIER_BRAND_SETTINGS,
});

export const editCashierGeneralSettings = (brandId, data) => ({
  type: CashierActionType.EDIT_CASHIER_GENERAL_SETTINGS,
  payload: { brandId, data },
});
