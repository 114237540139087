import { css } from 'styled-components';

/**
 * Creates text truncation CSS props.
 *
 * If text is not fitting in block, three dots will be added in the end.
 */
export function truncate() {
  return css`
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  `;
}

export const getBoxShadow = (color) => `0 3px 6px ${color}`;

/**
 * Creates media queries from sizes object.
 */
export const sizes = {
  xs: 575,
  sm: 767,
  md: 991,
  lg: 1024,
};

export const media = Object.keys(sizes).reduce((acc, label) => {
  acc[label] = (...args) => css`
    @media only screen and (max-width: ${sizes[label]}px) {
      ${css(...args)};
    }
  `;
  return acc;
}, {});

export function stickyContainer() {
  return css`
    display: flex;
    align-items: center;
    background: ${(props) => props.theme.colors.secondaryBg};
    justify-content: space-between;
    position: sticky;
    top: 0;
    bottom: -1px;
    width: 100%;
    left: 0;
    height: 4.57rem;
    padding: 0 4rem;
    box-shadow: 0 -4px 6px 0px #00000010;
    z-index: 100;
    font-size: 1rem;
    &:only-child {
      justify-content: flex-end;
    }
    .ant-btn {
      margin-bottom: 0 !important;
    }

    ${media.sm`
      padding: 0 1.14rem;
      justify-content: flex-end;
    `}
  `;
}

export function flexContainer(
  flex = {
    align: 'center',
    justify: 'space-between',
    direction: 'initial',
  },
) {
  const { align, justify, direction } = flex;
  return css`
    display: flex;
    align-items: ${align};
    justify-content: ${justify};
    flex-direction: ${direction};
  `;
}

export function textAlignCenter() {
  return css`
    text-align: center;
  `;
}

export function circularProgress(maxPercent, percent, colorBar, colorBack) {
  let filledPercent;
  if (percent > 100) {
    filledPercent = 100;
  } else if (percent < 0) {
    filledPercent = 0;
  } else {
    filledPercent = percent;
  }
  const increment = 360 / maxPercent;
  const half = Math.round(maxPercent / 2);
  let backgroundImage = '';
  const deg = 90;
  const minusDeg = -90;
  if (filledPercent < half) {
    const nextDeg = deg + increment * filledPercent;
    backgroundImage = `linear-gradient(${deg}deg, ${colorBack} 48%, transparent 50%, transparent),linear-gradient(${nextDeg}deg, ${colorBar} 50%, ${colorBack} 50%, ${colorBack})`;
  } else {
    const nextDeg = minusDeg + increment * (filledPercent - half);
    backgroundImage = `linear-gradient(${nextDeg}deg, ${colorBar} 48%, transparent 50%,  transparent), linear-gradient(270deg,  ${colorBar} 50%,  ${colorBack} 50%,  ${colorBack})`;
  }
  return backgroundImage;
}

export const handleColorType = (status) => {
  switch (status) {
  case 'win':
  case 'approved':
  case 'seen':
  case 1:
    return (props) => props.theme.colors.success;
  case 'live':
  case 'archived':
    return (props) => props.theme.colors.blue;
  case 'pending':
  case 'unseen':
  case 0:
    return (props) => props.theme.colors.yellow;
  case 'lost':
  case 'canceled':
  case 'rejected':
    return (props) => props.theme.colors.danger;
  case 'expired':
    return (props) => props.theme.colors.secondaryColor;
  default:
    return (props) => props.theme.colors.secondaryColor;
  }
};

export const getButtonHeight = (size) => {
  const details = {
    large: '1.28rem',
    medium: '1.14rem',
    small: '1rem',
  };
  return details[size];
};

export const handleCardsTypeColor = (type) => {
  const cardsColor = ['blue', 'success', 'yellow', 'pinkyPurple', 'hover', 'danger'];
  return cardsColor[type - 1];
};

export const getStatusColor = (status) => {
  const statusColors = {
    verify: 'success',
    unVerify: 'danger',
    pending: 'yellow',
  };

  return statusColors[status];
};

export const getSidebarSizes = () => ({
  collapsedWidth: '4.6rem',
  width: '20.7rem',
});
