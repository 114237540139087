import styled from 'styled-components';
import { StyledModal } from 'components/styledComponents/modal/Modal.styled';
import { StyledButtonBlueUnbordered } from 'components/styledComponents/button/Button.styled';

const StyledNotesModal = styled(StyledModal)`
  .ant-modal-body {
    padding: 1.14rem 1.14rem 0;
  }
  .ant-modal-content {
    min-height: 60vh;
  }
  ${StyledButtonBlueUnbordered} {
    span {
      margin-top: 0;
    }
  }
  .ant-select {
    width: 14rem;
    height: 2.71rem;
    background-color: ${(props) => props.theme.colors.blue};
    border-radius: 0 8px 8px 0;
    z-index: 2;
    position: absolute;
    font-size: 1.28rem;
    margin-left: -1.14rem;
    .ant-select-selector {
      border: none;
      padding: 0 4rem 0 2rem;
    }
    .ant-select-selection-item,
    .ant-select-selection-placeholder {
      color: ${(props) => props.theme.colors.white};
      font-size: 1.14rem;
    }
    .ant-select-selection-search {
      left: 2rem;
      color: ${(props) => props.theme.colors.white};
      font-size: 1.28rem;
    }
    svg > path {
      fill: ${(props) => props.theme.colors.white};
    }
    .ant-select-arrow svg > path {
      fill: ${(props) => props.theme.colors.white};
    }
  }
  .ant-select-dropdown {
    margin-top: -2.8rem;
    margin-left: -1rem;
    border-top-right-radius: 0;
  }
`;
const StyledNoteNoDataContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 25.14rem;
  margin: auto;
  height: 60vh;
  max-height: 52.35rem;
  & > img {
    width: 7.64rem;
    margin-bottom: 40px;
  }

  & > span {
    font-size: ${(props) => props.theme.typography.fontSizeDefault};
    margin-bottom: 0.5rem;
    display: flex;
    justify-content: center;
    font-weight: ${(props) => props.theme.typography.fontWeightMedium};
  }

  & > strong {
    font-size: ${(props) => props.theme.typography.fontSizeMiddle};
    display: flex;
    justify-content: center;
    font-weight: ${(props) => props.theme.typography.fontWeightMedium};
  }
`;

const StyledNoteDataContent = styled.div`
  margin-top: 4.57rem;
  padding-top: 0;
  border: 2px solid #eee;
  border-radius: 8px;
  height: 504px;
  .ant-spin {
    left: 0;
    top: 50%;
    transform: translateY(-50%);
  }
  .tox.tox-tinymce {
    border: none;
    .tox-sidebar-wrap {
      .tox-sidebar {
        background-color: initial;
        width: initial;
      }
    }
  }
`;

const StyledBadgeWrapper = styled.div`
  width: calc(100% + 8px);
  position: absolute;
  left: 0;
  top: 1.6rem;
  display: flex;
  align-items: center;
  .ant-ribbon-wrapper {
    margin-left: auto;
  }
  .ant-ribbon {
    position: static;
    padding: 3px 20px;
  }
`;

const StyledNotePagination = styled.div`
  display: flex;
  align-items: center;
  margin-right: auto;
  line-height: 2.28rem;
`;

const StyledPointer = styled.span`
  width: 6px;
  height: 6px;
  background-color: ${(props) => props.theme.colors.blue};
  border-radius: 50%;
  margin-right: 5px;
  margin-left: -5px;
  transform: translateX(5px);

  & + span {
    font-weight: 500;
  }
`;

export {
  StyledNoteNoDataContent, StyledNoteDataContent, StyledBadgeWrapper, StyledNotePagination, StyledNotesModal, StyledPointer,
};
