const PlayerActionType = {
  GET_PLAYERS: 'GET_PLAYERS',
  GET_PLAYERS_SUCCESS: 'GET_PLAYERS_SUCCESS',
  RESET_PLAYERS: 'RESET_PLAYERS',
  GENERATE_PLAYERS_REPORT: 'GENERATE_PLAYERS_REPORT',

  GET_PLAYER: 'GET_PLAYER',
  SAVE_PLAYER: 'SAVE_PLAYER',
  RESET_PLAYER: 'RESET_PLAYER',

  GET_PLAYER_DATA: 'GET_PLAYER_DATA',
  SAVE_PLAYER_DATA: 'SAVE_PLAYER_DATA',
  RESET_PLAYER_DATA: 'RESET_PLAYER_DATA',

  EDIT_PLAYER: 'EDIT_PLAYER',
  EDIT_PLAYER_SUCCESS: 'EDIT_PLAYER_SUCCESS',
  EDIT_PLAYER_FAILURE: 'EDIT_PLAYER_FAILURE',

  GET_CSV_PLAYERS: 'GET_CSV_PLAYERS',
  GET_CSV_PLAYERS_SUCCESS: 'GET_CSV_PLAYERS_SUCCESS',
  RESET_CSV_PLAYERS: 'RESET_CSV_PLAYERS',

  GET_BRAND_PLAYERS_RESOURCE: 'GET_BRAND_PLAYERS_RESOURCE',
  SAVE_BRAND_PLAYERS_RESOURCE: 'SAVE_BRAND_PLAYERS_RESOURCE',
  RESET_BRAND_PLAYERS_RESOURCE: 'RESET_BRAND_PLAYERS_RESOURCE',

  CHANGE_PLAYER_LOADING_STATE: 'CHANGE_PLAYER_LOADING_STATE',
  CHANGE_PLAYER_MODAL_LOADING_STATE: 'CHANGE_PLAYER_MODAL_LOADING_STATE',
  CHANGE_PLAYER_REPORT_LOADING_STATE: 'CHANGE_PLAYER_REPORT_LOADING_STATE',

  GET_PLAYER_NOTES: 'GET_PLAYER_NOTES',
  GET_PLAYER_NOTES_SUCCESS: 'GET_PLAYER_NOTES_SUCCESS',
  RESET_PLAYER_NOTES: 'RESET_PLAYER_NOTES',

  GET_PLAYER_NOTE_TYPES: 'GET_PLAYER_NOTE_TYPES',
  GET_PLAYER_NOTE_TYPES_SUCCESS: 'GET_PLAYER_NOTE_TYPES_SUCCESS',
  RESET_PLAYER_NOTE_TYPES: 'RESET_PLAYER_NOTE_TYPES',

  EXPORT_PLAYER_NOTES: 'EXPORT_PLAYER_NOTES',

  ADD_PLAYER_NOTE: 'ADD_PLAYER_NOTE',

  CHANGE_PLAYER_MODAL_VISIBILITY: 'CHANGE_PLAYER_MODAL_VISIBILITY',
  CHANGE_PLAYER_ENDED_STATUS: 'CHANGE_PLAYER_ENDED_STATUS',

  GET_PLAYER_INBOX_MESSAGES: 'GET_PLAYER_INBOX_MESSAGES',
  RESET_PLAYER_INBOX_MESSAGES: 'RESET_PLAYER_INBOX_MESSAGES',
  GET_PLAYER_MASS_MESSAGES: 'GET_PLAYER_MASS_MESSAGES',
  RESET_PLAYER_MASS_MESSAGES: 'RESET_PLAYER_MASS_MESSAGES',
  GET_PLAYER_MESSAGE: 'GET_PLAYER_MESSAGE',
  SAVE_PLAYER_MESSAGES: 'SAVE_PLAYER_MESSAGES',
  SAVE_PLAYER_MESSAGE: 'SAVE_PLAYER_MESSAGE',
  RESET_PLAYER_MESSAGE: 'RESET_PLAYER_MESSAGE',
  SAVE_PLAYER_MESSAGES_TOTAL: 'SAVE_PLAYER_MESSAGES_TOTAL',
  PLAYER_SEND_MESSAGE: 'PLAYER_SEND_MESSAGE',

  GET_PLAYER_CONTROLS: 'GET_PLAYER_CONTROLS',
  SAVE_PLAYER_CONTROLS: 'SAVE_PLAYER_CONTROLS',

  EDIT_PLAYER_MARK_ATTENTION: 'EDIT_PLAYER_MARK_ATTENTION',
  UNLOCK_PLAYER: 'UNLOCK_PLAYER',
  BLOCK_PLAYER_FOR_MESSAGES: 'BLOCK_PLAYER_FOR_MESSAGES',
  CHANGE_PLAYER_STATUS: 'CHANGE_PLAYER_STATUS',
  CLOSE_PLAYER_ACCOUNT: 'CLOSE_PLAYER_ACCOUNT',
  ACTIVATE_DORMANT_PLAYER: 'ACTIVATE_DORMANT_PLAYER',
  DISABLE_PLAYER_LOGIN_TFA: 'DISABLE_PLAYER_LOGIN_TFA',

  GET_PLAYERS_BULK_STATUS_UPDATE_HISTORY: 'GET_PLAYERS_BULK_STATUS_UPDATE_HISTORY',
  GET_PLAYERS_BULK_STATUS_UPDATE_HISTORY_SUCCESS: 'GET_PLAYERS_BULK_STATUS_UPDATE_HISTORY_SUCCESS',
  RESET_PLAYERS_BULK_STATUS_UPDATE_HISTORY: 'RESET_PLAYERS_BULK_STATUS_UPDATE_HISTORY',
  GET_BULK_STATUS_UPDATED_PLAYERS: 'GET_BULK_STATUS_UPDATED_PLAYERS',
  GET_BULK_STATUS_UPDATED_PLAYERS_SUCCESS: 'GET_BULK_STATUS_UPDATED_PLAYERS_SUCCESS',
  RESET_BULK_STATUS_UPDATED_PLAYERS: 'RESET_BULK_STATUS_UPDATED_PLAYERS',
  CHANGE_PLAYERS_STATUS_IN_BULK: 'CHANGE_PLAYERS_STATUS_IN_BULK',

  GET_PLAYER_ACTIONS: 'GET_PLAYER_ACTIONS',
  SAVE_PLAYER_ACTIONS: 'SAVE_PLAYER_ACTIONS',
  RESET_PLAYER_ACTIONS: 'RESET_PLAYER_ACTIONS',
  EXPORT_PLAYER_ACTIONS: 'EXPORT_PLAYER_ACTIONS',

  ADD_TEST_PLAYER: 'ADD_TEST_PLAYER',
  GET_TEST_PLAYERS: 'GET_TEST_PLAYERS',
  SAVE_TEST_PLAYERS: 'SAVE_TEST_PLAYERS',
  RESET_TEST_PLAYERS: 'RESET_TEST_PLAYERS',
  ASSIGN_TEST_PLAYER_TO_USER: 'ASSIGN_TEST_PLAYER_TO_USER',
  FINISH_USE_TEST_PLAYER: 'FINISH_USE_TEST_PLAYER',
  ADJUST_TEST_PLAYER_BALANCE: 'ADJUST_TEST_PLAYER_BALANCE',
  GET_TEST_PLAYER_USERS: 'GET_TEST_PLAYER_USERS',
  SAVE_TEST_PLAYER_USERS: 'SAVE_TEST_PLAYER_USERS',
  RESET_TEST_PLAYER_USERS: 'RESET_TEST_PLAYER_USERS',
  EDIT_TEST_USER: 'EDIT_TEST_USER',

  CHANGE_PLAYER_DATA_UPDATED_STATUS: 'CHANGE_PLAYER_DATA_UPDATED_STATUS',

  UPDATE_PLAYER_VERIFICATION: 'UPDATE_PLAYER_VERIFICATION',
  UPDATE_PLAYER_CONTACT_INFO_VERIFICATION: 'UPDATE_PLAYER_CONTACT_INFO_VERIFICATION',
  EDIT_PLAYER_NOTIFICATION_PREFERENCE: 'EDIT_PLAYER_NOTIFICATION_PREFERENCE',

  GET_PLAYER_SELF_EXCLUSION_SETTINGS: 'GET_PLAYER_SELF_EXCLUSION_SETTINGS',
  SAVE_PLAYER_SELF_EXCLUSION_SETTINGS: 'SAVE_PLAYER_SELF_EXCLUSION_SETTINGS',
  EDIT_PLAYER_SELF_EXCLUSION_SETTINGS: 'EDIT_PLAYER_SELF_EXCLUSION_SETTINGS',
  GET_PLAYER_TIMOUT_SETTINGS: 'GET_PLAYER_TIMOUT_SETTINGS',
  SAVE_PLAYER_TIMOUT_SETTINGS: 'SAVE_PLAYER_TIMOUT_SETTINGS',
  EDIT_PLAYER_TIMOUT_SETTINGS: 'EDIT_PLAYER_TIMOUT_SETTINGS',
  GET_PLAYER_DEPOSIT_SETTINGS: 'GET_PLAYER_DEPOSIT_SETTINGS',
  SAVE_PLAYER_DEPOSIT_SETTINGS: 'SAVE_PLAYER_DEPOSIT_SETTINGS',
  EDIT_PLAYER_DEPOSIT_SETTINGS: 'EDIT_PLAYER_DEPOSIT_SETTINGS',
  GET_PLAYER_WITHDRAWAL_SETTINGS: 'GET_PLAYER_WITHDRAWAL_SETTINGS',
  SAVE_PLAYER_WITHDRAWAL_SETTINGS: 'SAVE_PLAYER_WITHDRAWAL_SETTINGS',
  EDIT_PLAYER_WITHDRAWAL_SETTINGS: 'EDIT_PLAYER_WITHDRAWAL_SETTINGS',
  GET_PLAYER_BET_SETTINGS: 'GET_PLAYER_BET_SETTINGS',
  SAVE_PLAYER_BET_SETTINGS: 'SAVE_PLAYER_BET_SETTINGS',
  EDIT_PLAYER_BET_SETTINGS: 'EDIT_PLAYER_BET_SETTINGS',
  GET_PLAYER_REALITY_CHECK_SETTINGS: 'GET_PLAYER_REALITY_CHECK_SETTINGS',
  SAVE_PLAYER_REALITY_CHECK_SETTINGS: 'SAVE_PLAYER_REALITY_CHECK_SETTINGS',
  EDIT_PLAYER_REALITY_CHECK_SETTINGS: 'EDIT_PLAYER_REALITY_CHECK_SETTINGS',
  RESET_PLAYER_RESPONSIBLE_GAMING_SETTINGS: 'RESET_PLAYER_RESPONSIBLE_GAMING_SETTINGS',
  GET_PLAYER_LOSS_LIMIT_SETTINGS: 'GET_PLAYER_LOSS_LIMIT_SETTINGS',
  SAVE_PLAYER_LOSS_LIMIT_SETTINGS: 'SAVE_PLAYER_LOSS_LIMIT_SETTINGS',
  EDIT_PLAYER_LOSS_LIMIT_SETTINGS: 'EDIT_PLAYER_LOSS_LIMIT_SETTINGS',
  GET_PLAYER_SINGLE_BET_SETTINGS: 'GET_PLAYER_SINGLE_BET_SETTINGS',
  SAVE_PLAYER_SINGLE_BET_SETTINGS: 'SAVE_PLAYER_SINGLE_BET_SETTINGS',
  EDIT_PLAYER_SINGLE_BET_SETTINGS: 'EDIT_PLAYER_SINGLE_BET_SETTINGS',
  GET_RESPONSIBLE_GAMING_LOGS: 'GET_RESPONSIBLE_GAMING_LOGS',
  SAVE_RESPONSIBLE_GAMING_LOGS: 'SAVE_RESPONSIBLE_GAMING_LOGS',
  RESET_RESPONSIBLE_GAMING_LOGS: 'RESET_RESPONSIBLE_GAMING_LOGS',
  GET_RESPONSIBLE_GAMING_REMINDER_LIMITS: 'GET_RESPONSIBLE_GAMING_REMINDER_LIMITS',
  SAVE_RESPONSIBLE_GAMING_REMINDER_LIMITS: 'SAVE_RESPONSIBLE_GAMING_REMINDER_LIMITS',
  RESET_RESPONSIBLE_GAMING_REMINDER_LIMITS: 'RESET_RESPONSIBLE_GAMING_REMINDER_LIMITS',
  GET_PLAYER_ACCESS_LIMIT_SETTINGS: 'GET_PLAYER_ACCESS_LIMIT_SETTINGS',
  SAVE_PLAYER_ACCESS_LIMIT_SETTINGS: 'SAVE_PLAYER_ACCESS_LIMIT_SETTINGS',
  EDIT_PLAYER_ACCESS_LIMIT_SETTINGS: 'EDIT_PLAYER_ACCESS_LIMIT_SETTINGS',
  GET_PLAYER_ACCESS_LIMIT_OVERVIEW_SETTINGS: 'GET_PLAYER_ACCESS_LIMIT_OVERVIEW_SETTINGS',
  SAVE_PLAYER_ACCESS_LIMIT_OVERVIEW_SETTINGS: 'SAVE_PLAYER_ACCESS_LIMIT_OVERVIEW_SETTINGS',
  GET_PLAYER_ACCESS_LIMIT_PENDING_SETTINGS: 'GET_PLAYER_ACCESS_LIMIT_PENDING_SETTINGS',
  SAVE_PLAYER_ACCESS_LIMIT_PENDING_SETTINGS: 'SAVE_PLAYER_ACCESS_LIMIT_PENDING_SETTINGS',
  GET_PLAYER_ACCESS_LIMIT_SETTINGS_LOG: 'GET_PLAYER_ACCESS_LIMIT_SETTINGS_LOG',
  SAVE_PLAYER_ACCESS_LIMIT_SETTINGS_LOG: 'SAVE_PLAYER_ACCESS_LIMIT_SETTINGS_LOG',

  GET_PLAYER_SYSTEM_NOTIFICATIONS: 'GET_PLAYER_SYSTEM_NOTIFICATIONS',
  SAVE_PLAYER_SYSTEM_NOTIFICATIONS: 'SAVE_PLAYER_SYSTEM_NOTIFICATIONS',
  RESET_PLAYER_SYSTEM_NOTIFICATIONS: 'RESET_PLAYER_SYSTEM_NOTIFICATIONS',

  GET_PLAYER_SYSTEM_NOTIFICATION: 'GET_PLAYER_SYSTEM_NOTIFICATION',
  SAVE_PLAYER_SYSTEM_NOTIFICATION: 'SAVE_PLAYER_SYSTEM_NOTIFICATION',
  RESET_PLAYER_SYSTEM_NOTIFICATION: 'RESET_PLAYER_SYSTEM_NOTIFICATION',

  GET_PLAYER_PERSONAL_INFO: 'GET_PLAYER_PERSONAL_INFO',
  SAVE_PLAYER_PERSONAL_INFO: 'SAVE_PLAYER_PERSONAL_INFO',
  RESET_PLAYER_PERSONAL_INFO: 'RESET_PLAYER_PERSONAL_INFO',

  GET_PLAYER_ACCOUNT_INFO: 'GET_PLAYER_ACCOUNT_INFO',
  SAVE_PLAYER_ACCOUNT_INFO: 'SAVE_PLAYER_ACCOUNT_INFO',
  RESET_PLAYER_ACCOUNT_INFO: 'RESET_PLAYER_ACCOUNT_INFO',

  CHANGE_PLAYER_PERSONAL_INFO_UPDATED_STATUS: 'CHANGE_PLAYER_PERSONAL_INFO_UPDATED_STATUS',
  CHANGE_PLAYER_ACCOUNT_INFO_UPDATED_STATUS: 'CHANGE_PLAYER_ACCOUNT_INFO_UPDATED_STATUS',

  CHANGE_PLAYER_ACTIONS_ENDED_STATUS: 'CHANGE_PLAYER_ACTIONS_ENDED_STATUS',

  CHANGE_PLAYER_LIMITS_LOADING: 'CHANGE_PLAYER_LIMITS_LOADING',

  RESET_TEST_PLAYER_PASSWORD: 'RESET_TEST_PLAYER_PASSWORD',

  GET_HAS_PLAYER_TRANSACTION: 'GET_HAS_PLAYER_TRANSACTION',
  SAVE_HAS_PLAYER_TRANSACTION: 'SAVE_HAS_PLAYER_TRANSACTION',
  RESET_HAS_PLAYER_TRANSACTION: 'RESET_HAS_PLAYER_TRANSACTION',

  CHANGE_HAS_PLAYER_TRANSACTION_LOADING_STATE: 'CHANGE_HAS_PLAYER_TRANSACTION_LOADING_STATE',
};

export default PlayerActionType;
