import SimilarAccountActionType from './similarAccountActionType';

export const getSimilarAccounts = (params) => ({
  type: SimilarAccountActionType.GET_SIMILAR_ACCOUNTS,
  payload: { params },
});

export const saveSimilarAccounts = (data) => ({
  type: SimilarAccountActionType.SAVE_SIMILAR_ACCOUNTS,
  payload: { data },
});

export const resetSimilarAccounts = () => ({
  type: SimilarAccountActionType.RESET_SIMILAR_ACCOUNTS,
});

export const exportSimilarAccounts = (params) => ({
  type: SimilarAccountActionType.EXPORT_SIMILAR_ACCOUNTS,
  payload: { params },
});

export const getSimilarAccountsGroups = (params) => ({
  type: SimilarAccountActionType.GET_SIMILAR_ACCOUNTS_GROUPS,
  payload: { params },
});

export const saveSimilarAccountsGroups = (data) => ({
  type: SimilarAccountActionType.SAVE_SIMILAR_ACCOUNTS_GROUPS,
  payload: { data },
});

export const resetSimilarAccountsGroups = () => ({
  type: SimilarAccountActionType.RESET_SIMILAR_ACCOUNTS_GROUPS,
});

export const exportSimilarAccountsGroups = (params) => ({
  type: SimilarAccountActionType.EXPORT_SIMILAR_ACCOUNTS_GROUPS,
  payload: { params },
});

export const getPlayerSimilarAccountsInfo = (playerId, isLoading) => ({
  type: SimilarAccountActionType.GET_PLAYER_SIMILAR_ACCOUNTS_INFO,
  payload: { playerId, isLoading },
});

export const savePlayerSimilarAccountsInfo = (data) => ({
  type: SimilarAccountActionType.SAVE_PLAYER_SIMILAR_ACCOUNTS_INFO,
  payload: { data },
});

export const resetPlayerSimilarAccountsInfo = () => ({
  type: SimilarAccountActionType.RESET_PLAYER_SIMILAR_ACCOUNTS_INFO,
});
