import PermissionActionType from './permissionActionType';

export const getResources = (partnerId) => ({
  type: PermissionActionType.GET_RESOURCES,
  payload: { partnerId },
});

export const getResourcesSuccess = (data) => ({
  type: PermissionActionType.GET_RESOURCES_SUCCESS,
  payload: { data },
});

export const getPermissions = (resourceIds) => ({
  type: PermissionActionType.GET_PERMISSIONS,
  payload: { resourceIds },
});

export const getPermissionsSuccess = (data) => ({
  type: PermissionActionType.GET_PERMISSIONS_SUCCESS,
  payload: { data },
});

export const resetPermissions = () => ({
  type: PermissionActionType.RESET_PERMISSIONS,
});

export const changePermissionLoadingState = (state) => ({
  type: PermissionActionType.CHANGE_PERMISSION_LOADING_STATE,
  payload: { state },
});

export const getPermissionsResource = (params, isLoading) => ({
  type: PermissionActionType.GET_PERMISSIONS_RESOURCE,
  payload: { params, isLoading },
});

export const savePermissionsResource = (data) => ({
  type: PermissionActionType.SAVE_PERMISSIONS_RESOURCE,
  payload: { data },
});

export const resetPermissionsResource = () => ({
  type: PermissionActionType.RESET_PERMISSIONS_RESOURCE,
});

export const getUserManagementResource = (params, isLoading) => ({
  type: PermissionActionType.GET_USER_MANAGEMENT_RESOURCE,
  payload: { params, isLoading },
});

export const saveUserManagementResource = (data) => ({
  type: PermissionActionType.SAVE_USER_MANAGEMENT_RESOURCE,
  payload: { data },
});

export const resetUserManagementResource = () => ({
  type: PermissionActionType.RESET_USER_MANAGEMENT_RESOURCE,
});

export const getAllActionResource = (isLoading) => ({
  type: PermissionActionType.GET_ALL_ACTION_RESOURCE,
  payload: { isLoading },
});

export const saveAllActionResource = (data) => ({
  type: PermissionActionType.SAVE_ALL_ACTION_RESOURCE,
  payload: { data },
});

export const resetAllActionResource = () => ({
  type: PermissionActionType.RESET_ALL_ACTION_RESOURCE,
});

export const updatePermissionLevel = (data) => ({
  type: PermissionActionType.UPDATE_PERMISSION_LEVEL,
  payload: { data },
});
