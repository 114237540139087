import styled from 'styled-components';
import { Tag } from 'antd';
import { truncate } from 'utils/styledHelpers';

const StyledTag = styled(Tag)`
  &.ant-tag {
    padding: 0.2rem 0.71rem;
    display: flex;
    align-items: center;
    background-color: ${(props) => props.theme.colors.secondaryBorder};
    border: 1px solid ${(props) => props.theme.colors.primaryBorder};
    border-radius: ${(props) => props.theme.customBorderRadius.borderRadiusSmall};
    color: ${(props) => props.theme.colors.primaryText};
    transition: initial;
    font-size: 1rem;
    & span {
      margin-left: 0.28rem;
      line-height: 20px;
    }
    &:hover {
      opacity: initial;
    }
  }
`;

const StyledTagActive = styled(StyledTag)`
  background-color: ${(props) => props.theme.colors.blue};
  color: ${(props) => props.theme.colors.white};
`;

const StyledSmallTag = styled(StyledTag)`
  &.ant-tag {
    padding: 0px 10px;
    height: 24px;
    line-height: 24px;
    margin-bottom: 0px;
    display: inline-block;
    cursor: pointer;
    color: ${(props) => (props.active ? props.theme.colors.blue : props.theme.colors.secondaryColor)};
    border: 1px solid ${(props) => (props.active ? props.theme.colors.blue : props.theme.colors.primaryBorder)};
    background-color: ${(props) => (props.active ? `${props.theme.colors.blue}20` : 'initial')};
    border-radius: 2px;
  }
`;
const StyledAddTag = styled(Tag)`
  padding: 0.07rem 0.87rem;
  border: 1px dashed ${(props) => props.theme.colors.primaryBorder};
  background: ${(props) => props.theme.colors.grayButton};
  color: ${(props) => props.theme.colors.primaryText};
  font-size: 1rem;
  line-height: 1.57rem;
  border-radius: ${(props) => props.theme.customBorderRadius.borderRadiusSmall};
  text-transform: lowercase;
`;
const StyledAddTagsWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  .ant-select.ant-select-auto-complete .ant-select-selector {
    width: 6.85rem;
    height: 1.71rem;
  }
  .ant-select-single .ant-select-selector .ant-select-selection-placeholder {
    line-height: 1.71rem;
  }
  > div,
  > span {
    margin-bottom: 1.14rem !important;
  }
`;

const StyledTagsPointer = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 0 0 5px;
  width: 5px;
  height: 5px;
  border-radius: ${(props) => props.theme.customBorderRadius.borderRadiusCircle};
  background: ${(props) => (props.color ? props.color : props.theme.colors.white)};
  margin-right: 0.57rem;

  ${(props) => props.disabled
    && `
    position: relative;
    width: 14px;
    height: 14px;
    flex: 0 0 14px;
    background: transparent;
    border: 1px solid ${props.darkBg ? props.theme.colors.white : props.theme.colors.primaryText};
    margin-right: .4rem;
    
    &:before {
      content: "";
      width: 1px;
      height: 100%;
      background-color: ${props.darkBg ? props.theme.colors.white : props.theme.colors.primaryText};
      position: absolute;
      left: 50%;
      top: 0;
      transform: translateX(-50%) rotate(-45deg);
    }
  `}
`;

const StyledTagsWrapper = styled(Tag)`
  &.ant-tag {
    display: inline-flex;
    align-items: center;
    background: ${(props) => props.color};
    padding: 0.07rem 0.87rem;
    width: ${(props) => (props.width ? 'auto' : '9.8rem')};
    max-width: 100%;
    cursor: ${(props) => (props.pointer ? 'pointer' : '')};
    margin-bottom: ${(props) => (props.margin ? '0.57rem' : '0')};
    border: 1px solid transparent;
    font-size: 1rem;
    border-radius: ${(props) => props.theme.customBorderRadius.borderRadiusSmall};
    line-height: 1.57rem;
    color: ${(props) => props.theme.colors.primaryText};
    ${truncate()}
  }
  &:after {
    display: none;
  }
  > div {
    max-width: ${(props) => (props.maxwidth ? '7rem' : 'auto')};
  }
  &:hover {
    opacity: unset;
  }
  ${({ disabled }) => disabled
    && `
    opacity: .6;
    padding-left: .4rem;
  `}
`;
const StyledCategoryAndTagWrapper = styled.div`
  display: flex;
  align-items: center;
  max-width: 24rem;
`;
export {
  StyledTag, StyledTagActive, StyledSmallTag, StyledAddTag, StyledAddTagsWrapper, StyledTagsWrapper, StyledTagsPointer, StyledCategoryAndTagWrapper,
};
