import {
  createRef, useCallback, useEffect, useMemo, useState,
} from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { SaveDiscardButtonsGroup } from 'components/styledComponents/modal/Modal.styled';
import _ from 'lodash';
import { AdminSelect } from 'components/common/form';
import { StyledCarouselWrapper } from 'components/styledComponents/slider/Slider.styled';
import {
  StyledNoteNoDataContent, StyledBadgeWrapper, StyledNotePagination, StyledNoteDataContent, StyledNotesModal, StyledPointer,
} from 'components/systemSetting/releaseNote/ReleaseNote.styled';
import AdminEditor from 'containers/common/AdminEditor';
import { StyledButtonBlue, StyledButtonBlueUnbordered, StyledSmallButtonBlue } from 'components/styledComponents/button/Button.styled';
import { Carousel, Badge } from 'antd';
import { releaseNoteLabelsColor } from 'constants/mapping';
import noReleaseNoteChangesImg from 'assets/img/no_release_note_changes.png';
import { StyledIconDefault } from 'components/styledComponents/icon/Icon.styled';
import IconSvg from 'components/common/icon/IconSvg';
import { StyledSelectOption } from 'components/styledComponents/select/Select.styled';

const firstChange = 1;

const ReleaseNoteModal = (props) => {
  const {
    visible, closeModal, getReleaseNote, resetReleaseNote, getResourceReleaseNotes, resetResourceReleaseNotes, releaseNote, releaseNotes, selected, locale, markAsSeenReleaseNote, isView,
  } = props;

  const { t } = useTranslation();
  const { releaseNoteLabels } = locale;

  const [value, setValue] = useState();
  const [current, setCurrent] = useState(firstChange);

  const ref = createRef();

  const { changes = [] } = releaseNote;

  useEffect(() => {
    const val = (selected || releaseNotes[0]?.id)?.toString();
    if (val) {
      setValue(val);
    }
  }, [releaseNotes, selected]);

  useEffect(() => {
    if (value) {
      getReleaseNote(value);
    }
  }, [value, getReleaseNote]);

  useEffect(() => {
    if (visible && !isView) {
      getResourceReleaseNotes();
    }
  }, [getResourceReleaseNotes, visible, isView]);

  useEffect(
    () => () => {
      resetResourceReleaseNotes();
      resetReleaseNote();
    },
    [resetResourceReleaseNotes, resetReleaseNote],
  );

  const renderNotes = () => changes.map(({ content, id }) => (
    <div key={id}>
      <AdminEditor value={content} noToolbar readOnly />
    </div>
  ));

  const handleClose = () => {
    if (!isView) {
      markAsSeenReleaseNote(value);
    }

    setCurrent(firstChange);
    setValue();
    closeModal();
  };

  const getTotalDetails = () => <StyledNotePagination key={current}>{`${current}/${changes.length}`}</StyledNotePagination>;

  const handlePrev = useCallback(() => ref.current?.prev(), [ref]);

  const handleNext = useCallback(() => ref.current?.next(), [ref]);

  const keyup = useCallback(
    (e) => {
      if (ref && ref.current) {
        if (e.keyCode === 37) {
          handlePrev();
        } else if (e.keyCode === 39) {
          handleNext();
        }
      }
    },
    [ref, handleNext, handlePrev],
  );

  useEffect(() => {
    document.addEventListener('keyup', keyup);
    return () => {
      document.removeEventListener('keyup', keyup);
    };
  }, [keyup]);

  const handleAfterChange = (val) => setCurrent(val + 1);

  const getButtons = () => {
    const gotItButton = (
      <StyledButtonBlue key={0} htmlType="submit" size="medium" onClick={handleClose}>
        {t('systemSetting:gotIt')}
      </StyledButtonBlue>
    );

    if (!_.isEmpty(releaseNote) && _.isEmpty(changes)) {
      return gotItButton;
    }

    const content = [];
    const count = changes.length;

    if (current < count) {
      content.push(
        <StyledButtonBlueUnbordered key={1} type="default" size="medium" onClick={handleClose}>
          {t('systemSetting:skip')}
        </StyledButtonBlueUnbordered>,
      );
    }
    if (current > firstChange) {
      content.push(
        <StyledSmallButtonBlue key={2} htmlType="submit" size="medium" onClick={handlePrev}>
          {t('systemSetting:previous')}
        </StyledSmallButtonBlue>,
      );
    }
    if (current < count) {
      content.push(
        <StyledButtonBlue key={3} htmlType="submit" size="medium" onClick={handleNext}>
          {t('next')}
        </StyledButtonBlue>,
      );
    }
    if (current === count) {
      content.push(gotItButton);
    }

    return content;
  };

  const handleValueChange = (val) => {
    setValue(val);
    setCurrent(firstChange);
  };

  const isClosable = useMemo(() => (!isView && _.isEmpty(releaseNotes)) || (!_.isEmpty(releaseNote) && _.isEmpty(releaseNote?.changes)), [isView, releaseNotes, releaseNote]);

  const closeableProps = isView
    ? {
      onCancel: closeModal,
      closable: false,
    }
    : {
      closable: isClosable,
      onCancel: isClosable ? closeModal : undefined,
    };

  const releaseNotesOptions = releaseNotes?.map(({ isSeen, ...el }) => ({
    ...el,
    label: el.name,
    displayName: !isSeen ? (
      <StyledSelectOption>
        <StyledPointer />
        <span>{el.name}</span>
      </StyledSelectOption>
    ) : (
      el.name
    ),
  }));

  return (
    <StyledNotesModal
      getContainer=".ant-layout-content"
      width="49rem"
      height="50rem"
      open={visible}
      centered
      closeIcon={(
        <StyledIconDefault>
          <IconSvg icon="CloseModalIcon" height="0.86rem" width="0.86rem" />
        </StyledIconDefault>
      )}
      maskClosable={false}
      destroyOnClose
      footer={(
        <SaveDiscardButtonsGroup>
          {!_.isEmpty(changes) && getTotalDetails()}
          {getButtons()}
        </SaveDiscardButtonsGroup>
      )}
      {...closeableProps}
    >
      {!_.isEmpty(releaseNotes) && (
        <AdminSelect
          data={releaseNotesOptions}
          value={value}
          placeholder={t('select')}
          onChange={handleValueChange}
          allowClear={false}
          getPopupContainer={(trigger) => trigger.parentNode}
          optionLabelProp="label"
        />
      )}
      {(!_.isEmpty(releaseNote) && _.isEmpty(changes)) || (!isView && _.isEmpty(releaseNotes)) ? (
        <StyledNoteNoDataContent>
          <img alt={t('systemSetting:noReleaseNote')} src={noReleaseNoteChangesImg} />
          <span>{t('systemSetting:whoops')}</span>
          <strong>{t('systemSetting:noContentMessage')}</strong>
        </StyledNoteNoDataContent>
      ) : (
        <StyledNoteDataContent>
          <StyledBadgeWrapper>
            <Badge.Ribbon text={releaseNoteLabels[changes[current - 1]?.label]} color={releaseNoteLabelsColor[changes[current - 1]?.label]} />
          </StyledBadgeWrapper>
          <StyledCarouselWrapper>
            <Carousel effect="fade" dots={false} afterChange={handleAfterChange} ref={ref}>
              {renderNotes()}
            </Carousel>
          </StyledCarouselWrapper>
        </StyledNoteDataContent>
      )}
    </StyledNotesModal>
  );
};

ReleaseNoteModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  getReleaseNote: PropTypes.func.isRequired,
  resetReleaseNote: PropTypes.func.isRequired,
  getResourceReleaseNotes: PropTypes.func.isRequired,
  resetResourceReleaseNotes: PropTypes.func.isRequired,
  markAsSeenReleaseNote: PropTypes.func.isRequired,
  releaseNote: PropTypes.object,
  locale: PropTypes.object.isRequired,

  releaseNotes: PropTypes.array,
  selected: PropTypes.number,
  isView: PropTypes.bool,
};

ReleaseNoteModal.defaultProps = {
  releaseNote: {},
  releaseNotes: [],
  selected: null,
  isView: false,
};

export default ReleaseNoteModal;
