import SettingActionType from './settingActionType';

export const resetState = () => ({
  type: SettingActionType.RESET_STATE,
});

export const changeMode = (state) => ({
  type: SettingActionType.CHANGE_MODE,
  payload: state,
});

export const changeLoadingState = (state) => ({
  type: SettingActionType.CHANGE_LOADING_STATE,
  payload: state,
});

export const setSuccessMessage = (message) => ({
  type: SettingActionType.SET_SUCCESS_MESSAGE,
  payload: { message },
});

export const setErrorMessage = (message) => ({
  type: SettingActionType.SET_ERROR_MESSAGE,
  payload: { message },
});

export const resetMessage = (message) => ({
  type: SettingActionType.RESET_MESSAGE,
  payload: { message },
});

export const setBreadCrumbParams = (key, value) => ({
  type: SettingActionType.SET_BREADCRUMB_PARAMS,
  payload: { key, value },
});

export const resetBreadCrumbParams = () => ({
  type: SettingActionType.RESET_BREADCRUMB_PARAMS,
});

export const changeEndedStatus = (status) => ({
  type: SettingActionType.CHANGE_ENDED_STATUS,
  payload: { status },
});

export const changeGenerateReportEndedStatus = (status) => ({
  type: SettingActionType.CHANGE_GENERATE_REPORT_ENDED_STATUS,
  payload: { status },
});

export const setActiveMenu = (data) => ({
  type: SettingActionType.SET_ACTIVE_MENU,
  payload: { data },
});
