import {
  put, takeLatest, all, call,
} from 'redux-saga/effects';
import qs from 'qs';
import { LocalStorageService, NetworkService, NavigationService } from 'services';
import {
  setAuthenticated, saveAuthUser, changeTFAState, getAuthUserPartnerAccessesSuccess, changePasswordExpiredState,
} from 'actions/auth/authActionCreator';
import AuthActionType from 'actions/auth/authActionType';
import {
  changeLoadingState, setSuccessMessage, setErrorMessage, changeEndedStatus,
} from 'actions/setting/settingActionCreator';
import { getError } from 'utils/helpers';
import i18n from 'services/i18n';
import Config from 'config';

import { AppConstants } from 'constants/index';

const { isBrRegulationAudit } = Config;

// We don't translate Auth part, that is why we set english translations;
const t = i18n.getFixedT('en');

function* login({ payload }) {
  try {
    const formData = qs.stringify({
      ...payload.data,
      grant_type: 'password',
      client_id: 'admin_client',
    });

    const options = {
      data: formData,
      headers: {
        'content-type': 'application/x-www-form-urlencoded',
        Authorization: '',
      },
    };
    const {
      Admin, Account, Token, SignIn,
    } = AppConstants.api;
    yield put(changeLoadingState(true));
    const { data } = yield call(NetworkService.makeAPIPostRequest, [Admin, Account, isBrRegulationAudit ? SignIn : Token], options);
    yield put(setAuthenticated(true));
    LocalStorageService.set('auth', data);
  } catch (err) {
    if (err) {
      const error = getError(err, false, !isBrRegulationAudit);
      yield put(changeLoadingState(false));
      // Received errors are only by english
      if (error.errors[0].errorMessage === t('notification:TFARequired')) {
        yield put(changeTFAState(true));
      } else if (error.errors[0].errorMessage === t('notification:passwordExpired')) {
        yield put(changePasswordExpiredState(true));
      } else yield put(setErrorMessage(error));
    }
  }
}

function* getAuthUser() {
  try {
    const { Admin, Account, Details } = AppConstants.api;
    yield put(changeLoadingState(true));
    const { data } = yield call(NetworkService.makeAPIGetRequest, [Admin, Account, Details]);
    yield put(changeLoadingState(false));
    yield put(saveAuthUser(data));
    LocalStorageService.set('user', data);
  } catch (err) {
    // combined call with auth
    LocalStorageService.remove('auth');
    yield put(setAuthenticated(false));
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* forgotPassword({ payload }) {
  try {
    const { Admin, Account, ForgotPassword } = AppConstants.api;
    const options = {
      params: payload.data,
      headers: {
        Authorization: '',
      },
    };
    yield put(changeLoadingState(true));
    yield call(NetworkService.makeAPIPostRequest, [Admin, Account, ForgotPassword], options);
    yield put(changeLoadingState(false));
    yield put(changeEndedStatus(true));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* resetPassword({ payload }) {
  try {
    const { Admin, Account, ResetPassword } = AppConstants.api;
    const { data, text } = payload;
    const options = {
      data,
    };
    yield put(changeLoadingState(true));
    yield call(NetworkService.makeAPIPostRequest, [Admin, Account, ResetPassword], options);
    yield put(changeLoadingState(false));
    yield put(setSuccessMessage(t('notification:generalMessage', { model: t('password'), action: text, name: '' })));
    LocalStorageService.clear();
    NavigationService('/login');
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* resendTFA({ payload }) {
  try {
    const { Admin, Account, ResendTFA } = AppConstants.api;
    const options = {
      params: payload.data,
    };
    yield put(changeLoadingState(true));
    yield call(NetworkService.makeAPIPostRequest, [Admin, Account, ResendTFA], options);
    yield put(changeLoadingState(false));
    yield put(setSuccessMessage(t('notification:resendTFASuccess')));
    LocalStorageService.clear();
    NavigationService('/login');
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* changePassword({ payload }) {
  try {
    const { Admin, Account, ChangePassword } = AppConstants.api;
    const options = {
      data: payload.data,
    };
    yield put(changeLoadingState(true));
    yield call(NetworkService.makeAPIPostRequest, [Admin, Account, ChangePassword], options);
    yield put(changeLoadingState(false));
    yield put(setSuccessMessage(t('notification:generalMessage', { model: t('password'), action: t('notification:slUpdated'), name: '' })));
    yield put(changeEndedStatus(true));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* changeExpiredPassword({ payload }) {
  try {
    const { Admin, Account, ChangeExpiredPassword } = AppConstants.api;
    const options = {
      data: payload.data,
    };
    yield put(changeLoadingState(true));
    yield call(NetworkService.makeAPIPostRequest, [Admin, Account, ChangeExpiredPassword], options);
    yield put(changeLoadingState(false));
    yield put(changeEndedStatus(true));
    yield put(
      setSuccessMessage(
        t('notification:generalMessage', {
          model: t('password'),
          action: t('notification:slUpdated'),
          name: '',
        }),
      ),
    );
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getAuthUserPartnerAccesses({ payload }) {
  try {
    const { Admin, Account, Partners } = AppConstants.api;
    const { data } = yield call(NetworkService.makeAPIGetRequest, [Admin, Account, Partners, payload.includeMainPartner]);
    yield put(getAuthUserPartnerAccessesSuccess(data));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
  }
}

export default function* authSaga() {
  yield all([
    takeLatest(AuthActionType.LOGIN, login),
    takeLatest(AuthActionType.GET_AUTH_USER, getAuthUser),
    takeLatest(AuthActionType.FORGOT_PASSWORD, forgotPassword),
    takeLatest(AuthActionType.RESET_PASSWORD, resetPassword),
    takeLatest(AuthActionType.RESEND_TFA, resendTFA),
    takeLatest(AuthActionType.CHANGE_PASSWORD, changePassword),
    takeLatest(AuthActionType.CHANGE_EXPIRED_PASSWORD, changeExpiredPassword),
    takeLatest(AuthActionType.GET_AUTH_USER_PARTNER_ACCESSES, getAuthUserPartnerAccesses),
  ]);
}
