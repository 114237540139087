import TemplateActionType from './templateActionType';

export const createCustomTemplate = (data) => ({
  type: TemplateActionType.CREATE_CUSTOM_TEMPLATE,
  payload: { data },
});

export const changeEndedStatus = (status) => ({
  type: TemplateActionType.CHANGE_ENDED_STATUS,
  payload: { status },
});

export const getCustomTemplates = (data) => ({
  type: TemplateActionType.GET_CUSTOM_TEMPLATES,
  payload: { data },
});

export const getCustomTemplate = (id, isMain = true) => ({
  type: TemplateActionType.GET_CUSTOM_TEMPLATE,
  payload: { id, isMain },
});

export const getSystemTemplate = (brandId, typeId) => ({
  type: TemplateActionType.GET_SYSTEM_TEMPLATE,
  payload: { brandId, typeId },
});

export const resetTemplate = () => ({
  type: TemplateActionType.RESET_TEMPLATE,
});

export const resetTemplates = () => ({
  type: TemplateActionType.RESET_TEMPLATES,
});

export const saveTemplates = (data) => ({
  type: TemplateActionType.SAVE_TEMPLATES,
  payload: { data },
});

export const editCustomTemplate = (data) => ({
  type: TemplateActionType.EDIT_CUSTOM_TEMPLATE,
  payload: { data },
});

export const editSystemTemplate = (data) => ({
  type: TemplateActionType.EDIT_SYSTEM_TEMPLATE,
  payload: { data },
});

export const saveTemplate = (data) => ({
  type: TemplateActionType.SAVE_TEMPLATE,
  payload: { data },
});

export const saveTemplatesTotal = (data) => ({
  type: TemplateActionType.SAVE_TEMPLATES_TOTAL,
  payload: { data },
});

export const deleteCustomTemplate = (data) => ({
  type: TemplateActionType.DELETE_CUSTOM_TEMPLATE,
  payload: { data },
});

export const getSystemTemplates = (brandId, params) => ({
  type: TemplateActionType.GET_SYSTEM_TEMPLATES,
  payload: { brandId, params },
});

export const getSystemTemplateTypes = () => ({
  type: TemplateActionType.GET_SYSTEM_TEMPLATE_TYPES,
});

export const saveSystemTemplateTypes = (data) => ({
  type: TemplateActionType.SAVE_SYSTEM_TEMPLATE_TYPES,
  payload: { data },
});

export const activateSystemTemplate = (data) => ({
  type: TemplateActionType.ACTIVATE_SYSTEM_TEMPLATE,
  payload: { data },
});

export const getCustomTemplatesResource = (brandId, params) => ({
  type: TemplateActionType.GET_CUSTOM_TEMPLATES_RESOURCE,
  payload: { brandId, params },
});

export const saveCustomTemplatesResource = (data) => ({
  type: TemplateActionType.SAVE_CUSTOM_TEMPLATES_RESOURCE,
  payload: { data },
});

export const resetCustomTemplatesResource = () => ({
  type: TemplateActionType.RESET_CUSTOM_TEMPLATES_RESOURCE,
});
