const NotificationActionType = {
  GET_MASS_MESSAGE: 'GET_MASS_MESSAGE',
  GET_MASS_MESSAGE_SUCCESS: 'GET_MASS_MESSAGE_SUCCESS',
  UPDATE_MASS_MESSAGE: 'UPDATE_MASS_MESSAGE',

  MOVE_TO_DRAFT: 'MOVE_TO_DRAFT',

  RESET_MASS_MESSAGE: 'RESET_MASS_MESSAGE',
  RESET_MASS_MESSAGE_RECIPIENTS: 'RESET_MASS_MESSAGE_RECIPIENTS',

  SAVE_MASS_MESSAGES: 'SAVE_MASS_MESSAGES',
  CHANGE_NOTIFICATION_SENT_STATUS: 'CHANGE_NOTIFICATION_SENT_STATUS',

  GET_CUSTOM_TEMPLATE_TAGS_RESOURCE: 'GET_CUSTOM_TEMPLATE_TAGS_RESOURCE',
  GET_SYSTEM_TEMPLATE_TAGS_RESOURCE: 'GET_SYSTEM_TEMPLATE_TAGS_RESOURCE',

  SAVE_TEMPLATE_TAGS_RESOURCE: 'SAVE_TEMPLATE_TAGS_RESOURCE',
  RESET_TEMPLATE_TAGS_RESOURCE: 'RESET_TEMPLATE_TAGS_RESOURCE',

  GET_SENT_MASS_MESSAGES: 'GET_SENT_MASS_MESSAGES',
  GET_SCHEDULED_MASS_MESSAGES: 'GET_SCHEDULED_MASS_MESSAGES',
  GET_DRAFT_MASS_MESSAGES: 'GET_DRAFT_MASS_MESSAGES',
  GET_MASS_MESSAGE_RECIPIENTS: 'GET_MASS_MESSAGE_RECIPIENTS',

  DELETE_MASS_MESSAGE: 'DELETE_MASS_MESSAGE',

  SAVE_MASS_MESSAGES_STATE: 'SAVE_MASS_MESSAGES_STATE',
  SAVE_MESSAGES_TOTAL: 'SAVE_MASS_MESSAGES_TOTAL',
  SAVE_MASS_MESSAGE_RECIPIENTS: 'SAVE_MASS_MESSAGE_RECIPIENTS',

  GET_INBOX_MESSAGES: 'GET_INBOX_MESSAGES',
  SAVE_INBOX_MESSAGES: 'SAVE_INBOX_MESSAGES',
  RESET_INBOX_MESSAGES: 'RESET_INBOX_MESSAGES',

  GET_INBOX_MESSAGE: 'GET_INBOX_MESSAGE',

  CHANGE_INBOX_MESSAGE_REPLY_STATE: 'CHANGE_INBOX_MESSAGE_REPLY_STATE',

  REPLY_INBOX_MESSAGE: 'REPLY_INBOX_MESSAGE',

  RECALL_INBOX_MESSAGE: 'RECALL_INBOX_MESSAGE',

  MARK_INBOX_MESSAGE_AS_READ: 'MARK_INBOX_MESSAGE_AS_READ',

  CHECK_TITLE_EXISTS: 'CHECK_TITLE_EXISTS',
  SAVE_TITLE_EXISTS: 'SAVE_TITLE_EXISTS',

  GET_BONUS_NOTIFICATIONS: 'GET_BONUS_NOTIFICATIONS',
  SAVE_BONUS_NOTIFICATIONS: 'SAVE_BONUS_NOTIFICATIONS',
  RESET_BONUS_NOTIFICATIONS: 'RESET_BONUS_NOTIFICATIONS',

  GET_BONUS_NOTIFICATION: 'GET_BONUS_NOTIFICATION',
  SAVE_BONUS_NOTIFICATION: 'SAVE_BONUS_NOTIFICATION',
  RESET_BONUS_NOTIFICATION: 'RESET_BONUS_NOTIFICATION',
};

export default NotificationActionType;
