export const selectCashierInventory = (state) => state.inventory;
export const selectCashierDeposit = (state) => state.deposit;
export const selectDepositPMResource = (state) => state.depositPaymentMethodsResource;
export const selectCashierDepositCategory = (state) => state.cashierDepositCategory;
export const selectCashierWithdrawal = (state) => state.withdrawal;
export const selectWithdrawalPMResource = (state) => state.withdrawalPaymentMethodsResource;
export const selectCashierWithdrawalCategory = (state) => state.cashierWithdrawalCategory;
export const selectCashierDepositPaymentMethods = (state) => state.cashierDepositPaymentMethods;
export const selectDepositCategoryResource = (state) => state.depositCategoryResource;
export const selectCashierWithdrawalPaymentMethods = (state) => state.cashierWithdrawalPaymentMethods;
export const selectWithdrawalCategoryResource = (state) => state.withdrawalCategoryResource;
export const selectCashierGeneralSettings = (state) => state.cashierGeneralSettings;
export const selectCashierBrandSettings = (state) => state.cashierBrandSettings;
