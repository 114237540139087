import {
  all, call, put, takeLatest,
} from 'redux-saga/effects';
import qs from 'qs';
import { changeGenerateReportEndedStatus, changeLoadingState, setErrorMessage } from 'actions/setting/settingActionCreator';
import { saveUserLogs, savePlayerLogs, saveUserManagementLogs } from 'actions/auditLog/auditLogActionCreator';
import NetworkService from 'services/networkService';
import { AppConstants } from 'constants/index';
import AuditLogActionType from 'actions/auditLog/auditLogActionType';
import { getError } from 'utils/helpers';

function* getUserLogs({ payload }) {
  try {
    const { params } = payload;
    const options = {
      params,
      paramsSerializer: (param) => qs.stringify(param),
    };
    const { ReportingAdmin, AuditLog, UserLogs } = AppConstants.api;
    yield put(changeLoadingState(true));
    const { data } = yield call(NetworkService.makeAPIGetRequest, [ReportingAdmin, AuditLog, UserLogs], options);
    yield put(saveUserLogs(data));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getPlayerLogs({ payload }) {
  try {
    const { params } = payload;
    const options = {
      params,
      paramsSerializer: (param) => qs.stringify(param),
    };
    const { ReportingAdmin, AuditLog, PlayerLogs } = AppConstants.api;
    yield put(changeLoadingState(true));
    const { data } = yield call(NetworkService.makeAPIGetRequest, [ReportingAdmin, AuditLog, PlayerLogs], options);
    yield put(savePlayerLogs(data));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getUserManagementLogs({ payload }) {
  try {
    const { params } = payload;
    const options = {
      params,
      paramsSerializer: (param) => qs.stringify(param),
    };
    const { ReportingAdmin, AuditLog, UserManagement } = AppConstants.api;
    yield put(changeLoadingState(true));
    const { data } = yield call(NetworkService.makeAPIGetRequest, [ReportingAdmin, AuditLog, UserManagement], options);
    yield put(saveUserManagementLogs(data));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* generateUserLogs({ payload }) {
  try {
    const { params } = payload;
    const options = {
      params,
      paramsSerializer: (param) => qs.stringify(param, { allowDots: true }),
    };
    yield put(changeLoadingState(true));
    const {
      ReportingAdmin, AuditLog, UserLogs, Generate,
    } = AppConstants.api;
    yield call(NetworkService.makeAPIGetRequest, [ReportingAdmin, AuditLog, UserLogs, Generate], options);
    yield put(changeGenerateReportEndedStatus(true));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* generatePlayerLogs({ payload }) {
  try {
    const { params } = payload;
    const options = {
      params,
      paramsSerializer: (param) => qs.stringify(param, { allowDots: true }),
    };
    yield put(changeLoadingState(true));
    const {
      ReportingAdmin, AuditLog, PlayerLogs, Generate,
    } = AppConstants.api;
    yield call(NetworkService.makeAPIGetRequest, [ReportingAdmin, AuditLog, PlayerLogs, Generate], options);
    yield put(changeGenerateReportEndedStatus(true));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* generateUserManagementLogs({ payload }) {
  try {
    const { params } = payload;
    const options = {
      params,
      paramsSerializer: (param) => qs.stringify(param, { allowDots: true }),
    };
    yield put(changeLoadingState(true));
    const {
      ReportingAdmin, AuditLog, UserManagementLogs, Generate,
    } = AppConstants.api;
    yield call(NetworkService.makeAPIGetRequest, [ReportingAdmin, AuditLog, UserManagementLogs, Generate], options);
    yield put(changeGenerateReportEndedStatus(true));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

export default function* auditLogSaga() {
  yield all([
    takeLatest(AuditLogActionType.GET_USER_LOGS, getUserLogs),
    takeLatest(AuditLogActionType.GET_PLAYER_LOGS, getPlayerLogs),
    takeLatest(AuditLogActionType.GET_USER_MANAGEMENT_LOGS, getUserManagementLogs),
    takeLatest(AuditLogActionType.GENERATE_USER_LOGS, generateUserLogs),
    takeLatest(AuditLogActionType.GENERATE_PLAYER_LOGS, generatePlayerLogs),
    takeLatest(AuditLogActionType.GENERATE_USER_MANAGEMENT_LOGS, generateUserManagementLogs),
  ]);
}
