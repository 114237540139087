import CashBackActionType from './cashBackActionType';

export const createCashBack = (data) => ({
  type: CashBackActionType.CREATE_CASH_BACK,
  payload: { data },
});

export const getCashBacks = (id, params) => ({
  type: CashBackActionType.GET_CASH_BACKS,
  payload: { id, params },
});

export const saveCashBacks = (data) => ({
  type: CashBackActionType.SAVE_CASH_BACKS,
  payload: { data },
});

export const resetCashBacks = () => ({
  type: CashBackActionType.RESET_CASH_BACKS,
});

export const getCashBack = (id, params) => ({
  type: CashBackActionType.GET_CASH_BACK,
  payload: { id, params },
});

export const saveCashBack = (data) => ({
  type: CashBackActionType.SAVE_CASH_BACK,
  payload: { data },
});

export const resetCashBack = () => ({
  type: CashBackActionType.RESET_CASH_BACK,
});

export const editCashBack = (data) => ({
  type: CashBackActionType.EDIT_CASH_BACK,
  payload: { data },
});

export const deleteCashBack = (data) => ({
  type: CashBackActionType.DELETE_CASH_BACK,
  payload: { data },
});

export const enableCashBack = (id, data) => ({
  type: CashBackActionType.ENABLE_CASH_BACK,
  payload: { id, data },
});

export const disableCashBack = (id, data) => ({
  type: CashBackActionType.DISABLE_CASH_BACK,
  payload: { id, data },
});

export const finishCashBack = (id, data) => ({
  type: CashBackActionType.FINISH_CASH_BACK,
  payload: { id, data },
});

export const getCashBackStatistics = (cashbackId, params) => ({
  type: CashBackActionType.GET_CASH_BACK_STATISTICS,
  payload: {
    cashbackId,
    params,
  },
});

export const saveCashBackAccumulated = (data) => ({
  type: CashBackActionType.SAVE_CASH_BACK_ACCUMULATED,
  payload: { data },
});

export const saveCashBackTransferred = (data) => ({
  type: CashBackActionType.SAVE_CASH_BACK_TRANSFERRED,
  payload: { data },
});

export const saveCashBackFailed = (data) => ({
  type: CashBackActionType.SAVE_CASH_BACK_FAILED,
  payload: { data },
});

export const getPlayerCashBackPeriods = (cashbackId, playerId, periodStatus) => ({
  type: CashBackActionType.GET_PLAYER_CASH_BACK_PERIODS,
  payload: {
    cashbackId,
    playerId,
    periodStatus,
  },
});

export const resetCashBackTransferred = () => ({
  type: CashBackActionType.RESET_CASH_BACK_TRANSFERRED,
});

export const resetCashBackAccumulated = () => ({
  type: CashBackActionType.RESET_CASH_BACK_ACCUMULATED,
});

export const resetCashBackFailed = () => ({
  type: CashBackActionType.RESET_CASH_BACK_FAILED,
});

export const getCashBackPlayers = (cashbackId, params) => ({
  type: CashBackActionType.GET_CASH_BACK_PLAYERS,
  payload: {
    cashbackId,
    params,
  },
});

export const saveCashBackPlayers = (data) => ({
  type: CashBackActionType.SAVE_CASH_BACK_PLAYERS,
  payload: { data },
});

export const resetCashBackPlayers = () => ({
  type: CashBackActionType.RESET_CASH_BACK_PLAYERS,
});

export const deleteIncludedPlayer = (id, params) => ({
  type: CashBackActionType.DELETE_INCLUDED_PLAYER,
  payload: { id, params },
});

export const transferCashBack = (cashbackId, data) => ({
  type: CashBackActionType.TRANSFER_CASH_BACK,
  payload: { cashbackId, data },
});

export const deleteCashBackNewPeriods = (cashbackId, params) => ({
  type: CashBackActionType.DELETE_CASH_BACK_NEW_PERIODS,
  payload: { cashbackId, params },
});

export const getCashBackIncludedPlayers = (cashbackId) => ({
  type: CashBackActionType.GET_CASH_BACK_INCLUDED_PLAYERS,
  payload: { cashbackId },
});

export const saveCashBackIncludedPlayers = (data) => ({
  type: CashBackActionType.SAVE_CASH_BACK_INCLUDED_PLAYERS,
  payload: { data },
});

export const resetCashBackIncludedPlayers = () => ({
  type: CashBackActionType.RESET_CASH_BACK_INCLUDED_PLAYERS,
});

export const updateCashBackIncludedPlayers = (cashbackId, params) => ({
  type: CashBackActionType.UPDATE_CASH_BACK_INCLUDED_PLAYERS,
  payload: { cashbackId, params },
});

export const getPlayerCashBacks = (playerId, params) => ({
  type: CashBackActionType.GET_PLAYER_CASH_BACKS,
  payload: { playerId, params },
});

export const savePlayerCashBacks = (data) => ({
  type: CashBackActionType.SAVE_PLAYER_CASH_BACKS,
  payload: { data },
});

export const resetPlayerCashBacks = () => ({
  type: CashBackActionType.RESET_PLAYER_CASH_BACKS,
});

export const exportPlayerCashBacks = (playerId, params) => ({
  type: CashBackActionType.EXPORT_PLAYER_CASH_BACKS,
  payload: { playerId, params },
});

export const getPlayerAccountCashBackPeriods = (cashbackId, playerId) => ({
  type: CashBackActionType.GET_PLAYER_ACCOUNT_CASH_BACK_PERIODS,
  payload: {
    cashbackId,
    playerId,
  },
});

export const savePlayerAccountCashBackPeriods = (data) => ({
  type: CashBackActionType.SAVE_PLAYER_ACCOUNT_CASH_BACK_PERIODS,
  payload: { data },
});

export const resetPlayerAccountCashBackPeriods = () => ({
  type: CashBackActionType.RESET_PLAYER_ACCOUNT_CASH_BACK_PERIODS,
});

export const resendCashBackPeriods = (cashbackId, data) => ({
  type: CashBackActionType.RESEND_CASH_BACK_PERIODS,
  payload: { cashbackId, data },
});

export const deleteCashBackPeriods = (cashbackId, params) => ({
  type: CashBackActionType.DELETE_CASH_BACK_PERIODS,
  payload: { cashbackId, params },
});

export const getCashBackStatisticsDetails = (id, params) => ({
  type: CashBackActionType.GET_CASH_BACK_STATISTICS_DETAILS,
  payload: { id, params },
});

export const saveCashBackStatisticsDetails = (data) => ({
  type: CashBackActionType.SAVE_CASH_BACK_STATISTICS_DETAILS,
  payload: { data },
});

export const resetCashBackStatisticsDetails = () => ({
  type: CashBackActionType.RESET_CASH_BACK_STATISTICS_DETAILS,
});

export const getCashBackPeriodsResource = (id, params, isLoading) => ({
  type: CashBackActionType.GET_CASH_BACK_PERIODS_RESOURCE,
  payload: { id, params, isLoading },
});

export const saveCashBackPeriodsResource = (data) => ({
  type: CashBackActionType.SAVE_CASH_BACK_PERIODS_RESOURCE,
  payload: { data },
});

export const resetCashBackPeriodsResource = () => ({
  type: CashBackActionType.RESET_CASH_BACK_PERIODS_RESOURCE,
});

export const savePlayerAccumulatedPeriods = (data) => ({
  type: CashBackActionType.SAVE_PLAYER_ACCUMULATED_PERIODS,
  payload: { data },
});

export const resetPlayerAccumulatedPeriods = () => ({
  type: CashBackActionType.RESET_PLAYER_ACCUMULATED_PERIODS,
});

export const savePlayerTransferredPeriods = (data) => ({
  type: CashBackActionType.SAVE_PLAYER_TRANSFERRED_PERIODS,
  payload: { data },
});

export const resetPlayerTransferredPeriods = () => ({
  type: CashBackActionType.RESET_PLAYER_TRANSFERRED_PERIODS,
});

export const savePlayerFailedPeriods = (data) => ({
  type: CashBackActionType.SAVE_PLAYER_FAILED_PERIODS,
  payload: { data },
});

export const resetPlayerFailedPeriods = () => ({
  type: CashBackActionType.RESET_PLAYER_FAILED_PERIODS,
});

export const exportCashBackStatistics = (id, params) => ({
  type: CashBackActionType.EXPORT_CASH_BACK_STATISTICS,
  payload: { id, params },
});
