import PropTypes from 'prop-types';
import { Form, Input } from 'antd';
import { useTranslation } from 'react-i18next';
import { FormItemErrorMessage } from 'components/common';
import { FormInputWrapper } from 'components/styledComponents/input/Input.styled';
import { StyledStatusChange } from 'components/brandSetting/BrandSettings.styled';
import { StyledFormMainWrapper } from 'components/styledComponents/form/Form.styled';
import { AppConstants } from 'constants/index';

const ChangeNameForm = (props) => {
  const { name, form } = props;

  const { t } = useTranslation();

  return (
    <StyledStatusChange>
      <StyledFormMainWrapper form={form} initialValues={{ name }} layout="vertical">
        <FormInputWrapper midSize>
          <Form.Item
            name="name"
            label={t('name')}
            rules={[
              {
                required: true,
                message: <FormItemErrorMessage errorMassage={(translate) => translate('validate:requiredField', { fieldName: translate('name') })} />,
              },
              {
                pattern: AppConstants.regex.brandName,
                message: <FormItemErrorMessage errorMassage={(translate) => translate('validate:brandName', { fieldName: translate('name') })} />,
              },
            ]}
          >
            <Input placeholder={t('typeHere')} autoComplete="off" allowClear />
          </Form.Item>
        </FormInputWrapper>
      </StyledFormMainWrapper>
    </StyledStatusChange>
  );
};

ChangeNameForm.propTypes = {
  name: PropTypes.string.isRequired,
  form: PropTypes.object.isRequired,
};

export default ChangeNameForm;
