import { useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Row, Col, Grid } from 'antd';
import { Navigate, useLocation } from 'react-router-dom';
import {
  Forgot, ModifyPassword, Login, EmailSent,
} from 'components/auth';
import {
  forgotPassword, resetPassword, resendTFA, login, changeTFAState, getAuthUser, setAuthenticated,
} from 'actions/auth/authActionCreator';
import { getUserConfigs } from 'actions/user/userActionCreator';
import { changeEndedStatus, changeMode, resetState } from 'actions/setting/settingActionCreator';
import {
  selectAuthenticatedStatus, selectPageLoading, selectAuthIsTFAEnabled, selectAuthUser, selectUserConfigs, selectAuthIsPasswordExpired, selectCallEndedStatus,
} from 'selectors';
import {
  StyledLoginWrapper, StyledSeparator, StyledLoginAside, StyledAsideWrapper, StyledLogo, StyledAsideText, StyledCopyright, StyledLogoMobile,
} from 'components/auth/Login.styled';
import { StyledAuthSpin } from 'components/styledComponents/spin/Spin.styled';
import { IconSvg } from 'components/common';
import { LocalStorageService, i18n } from 'services';
import { useTimeZone } from 'hooks';
import Notification from 'containers/layout/Notification';
import { getResourceCurrenciesPrecisions } from 'actions/resource/resourceActionCreator';
import { getLatestReleaseNote } from 'actions/releaseNote/releaseNoteActionCreator';

const t = i18n.getFixedT('en');

function Auth(props) {
  const {
    resetPasswordProp, forgotPasswordProp, resendTFAProp, pageLoading, user, setAuthenticatedProp, isAuthenticated, userConfigs, changeModeProp, resetStateProp, getLatestReleaseNoteProp,
  } = props;

  const { getDateTz } = useTimeZone();

  const { state, pathname } = useLocation();

  const { useBreakpoint } = Grid;

  const screens = useBreakpoint();

  const breakPointMd = screens.md;
  const breakPointXl = screens.xl;

  const logoutBuilder = useCallback(() => {
    const iframe = document.getElementById('ui-customization');
    if (iframe) {
      const win = iframe.contentWindow;
      win.postMessage(JSON.stringify({ key: 'authorizationData', method: 'remove' }), '*');
    }
  }, []);

  // clear redux store, when I go to Login page and localStorage is clear
  useEffect(() => {
    if (!LocalStorageService.get('auth')) {
      logoutBuilder();
      resetStateProp();
      // reset state - local storage case
      changeModeProp(false);
    }
  }, [logoutBuilder, changeModeProp, resetStateProp]);

  const { from } = state || { from: '/account' };

  const pages = {
    resetPassword: <ModifyPassword title="Reset" text={t('notification:resetPassword')} send={resetPasswordProp} {...props} />,
    forgotPassword: <Forgot title="Recover Password" text={t('notification:forgotPassword')} send={forgotPasswordProp} isPassword {...props} />,
    emailSent: <EmailSent />,
    login: <Login {...props} />,
    setPassword: <ModifyPassword title="Set" text={t('notification:setPassword')} send={resetPasswordProp} {...props} />,
    resendTFA: <Forgot title="Recover 2FA" text={t('notification:forgotTFA')} send={resendTFAProp} {...props} />,
  };

  const handlePermissionCheck = (data) => {
    const { permissions = {}, userClaims } = data;
    // Don't have permission page

    if (!userClaims?.length && !Object.keys(permissions).length) {
      return <Navigate to="/noAccess" replace />;
    }

    getLatestReleaseNoteProp();

    return <Navigate to={from} replace />;
  };

  // if both of them are in localStorage or in redux
  if (user && userConfigs && LocalStorageService.get('auth')) {
    if (!isAuthenticated) {
      setAuthenticatedProp(true);
    }
    // re-write user to localStorage
    LocalStorageService.set('user', user);

    return handlePermissionCheck(user);
  }

  const page = pathname.substring(1);
  const pageKey = Object.keys(pages).find((el) => el.toLowerCase() === page.toLowerCase());
  const Component = pages[pageKey];

  return (
    <>
      <Notification />
      <StyledAuthSpin spinning={pageLoading} />
      <StyledLoginWrapper md={breakPointMd ? 1 : 0}>
        <Row gutter={24} justify="center">
          <Col className="gutter-row" xxl={6} xl={8} lg={10} md={10} sm={22} xs={24}>
            <StyledLoginAside>
              <StyledAsideWrapper md={breakPointMd ? 1 : 0} xl={breakPointXl ? 1 : 0}>
                <StyledLogo md={breakPointMd ? 1 : 0}>
                  <IconSvg icon="LoginPageIcon" width="28.5rem" height="28.5rem" color="" />
                </StyledLogo>
                <StyledLogoMobile md={breakPointMd ? 1 : 0}>
                  <IconSvg icon="CentrivoIcon" width="20rem" height="3.28rem" color="" />
                </StyledLogoMobile>
                <StyledAsideText md={breakPointMd ? 1 : 0} xl={breakPointXl ? 1 : 0}>
                  <IconSvg icon="CentrivoIcon" width="16rem" height="4.14rem" color="" />
                  <p>The world’s most powerful and flexible gaming platform.</p>
                </StyledAsideText>
              </StyledAsideWrapper>
            </StyledLoginAside>
          </Col>
          <Col className="gutter-row" xxl={6} xl={4} lg={2} md={2}>
            <StyledSeparator md={breakPointMd ? 1 : 0} />
          </Col>
          <Col className="gutter-row" xxl={6} xl={8} lg={10} md={10} sm={22} xs={24}>
            {Component}
          </Col>
        </Row>
        <StyledCopyright md={breakPointMd ? 1 : 0}>{`Copyright ${getDateTz().year()} Digitain. All Rights Reserved`}</StyledCopyright>
      </StyledLoginWrapper>
    </>
  );
}

Auth.propTypes = {
  resetPasswordProp: PropTypes.func,
  forgotPasswordProp: PropTypes.func,
  resendTFAProp: PropTypes.func,
  pageLoading: PropTypes.bool,
  resetMessageProp: PropTypes.func,
  successMessage: PropTypes.string,
  errorMessage: PropTypes.array,
  user: PropTypes.object,
  setAuthenticatedProp: PropTypes.func.isRequired,
  changeModeProp: PropTypes.func.isRequired,
  resetStateProp: PropTypes.func.isRequired,
  getLatestReleaseNoteProp: PropTypes.func.isRequired,
  userConfigs: PropTypes.object,
  isAuthenticated: PropTypes.bool,
  isPasswordExpired: PropTypes.bool,
  endedStatus: PropTypes.bool,
};

Auth.defaultProps = {
  forgotPasswordProp: null,
  resendTFAProp: null,
  pageLoading: false,
  resetMessageProp: () => {},
  resetPasswordProp: () => {},
  successMessage: '',
  errorMessage: [],
  userConfigs: null,
  user: null,
  isAuthenticated: false,
  isPasswordExpired: false,
  endedStatus: false,
};

const mapStateToProps = ({ auth, settings, users }) => ({
  isAuthenticated: selectAuthenticatedStatus(auth),
  user: selectAuthUser(auth),
  pageLoading: selectPageLoading(settings),
  isTFAEnabled: selectAuthIsTFAEnabled(auth),
  userConfigs: selectUserConfigs(users),
  isPasswordExpired: selectAuthIsPasswordExpired(auth),
  endedStatus: selectCallEndedStatus(settings),
});

const mapDispatchToProps = (dispatch) => bindActionCreators(
  {
    forgotPasswordProp: forgotPassword,
    resetPasswordProp: resetPassword,
    resendTFAProp: resendTFA,
    login,
    getAuthUser,
    getUserConfigs,
    changeTFAState,
    setAuthenticatedProp: setAuthenticated,
    changeModeProp: changeMode,
    resetStateProp: resetState,
    getCurrenciesPrecisions: getResourceCurrenciesPrecisions,
    changeEndedStatus,
    getLatestReleaseNoteProp: getLatestReleaseNote,
  },
  dispatch,
);

export default connect(mapStateToProps, mapDispatchToProps)(Auth);
