import styled from 'styled-components';
import { flexContainer } from 'utils/styledHelpers';

const StyledRadioList = styled.ul`
  display: flex;
  flex-wrap: wrap;
  gap: 1.17rem;
  margin: 0;
  li {
    width: 100%;
    height: 100%;
    &:first-child label.ant-radio-button-wrapper {
      border-radius: ${(props) => `${props.theme.customBorderRadius.borderRadiusSmall} 0 0 ${props.theme.customBorderRadius.borderRadiusSmall}`};
    }

    &:last-child label.ant-radio-button-wrapper {
      border-radius: ${(props) => `0 ${props.theme.customBorderRadius.borderRadiusSmall} ${props.theme.customBorderRadius.borderRadiusSmall} 0`};
    }

    .ant-radio-input:focus + .ant-radio-inner {
      box-shadow: none;
    }

    p {
      text-align: center;
      margin: 6px 0 0 0;
      color: ${(props) => props.theme.colors.primaryText};
      text-overflow: ellipsis;
      font-size: ${(props) => props.theme.typography.fontSizeDefault};
    }

    label.ant-radio-button-wrapper {
      width: 3.43rem;
      height: 3.43rem;
      ${flexContainer()};
      justify-content: center;
      background-color: ${(props) => props.theme.colors.grayButton};
      color: ${(props) => (props.colorProp ? props.theme.colors.blue : props.theme.colors.purple)};
      border-radius: ${(props) => props.theme.customBorderRadius.borderRadiusCircle};
      cursor: pointer;
      margin: 0 auto;
      padding: 0;
      transition: initial;

      svg {
        path,
        ellipse {
          fill: ${(props) => props.theme.colors[props.color]};
        }

        g {
          rect,
          path,
          circle,
          ellipse {
            fill: ${(props) => props.theme.colors[props.color]};
          }
        }
      }

      i {
        color: ${(props) => props.theme.colors[props.color]};
        display: flex;
      }

      &-checked:not(.ant-radio-button-wrapper-disabled) {
        svg {
          path {
            fill: ${(props) => props.theme.colors.white};
          }

          g {
            rect,
            path,
            circle,
            ellipse {
              fill: ${(props) => props.theme.colors.white};
            }
          }
        }

        &:first-child {
          background-color: ${(props) => props.theme.colors[props.color]};
          border: 1px solid ${(props) => props.theme.colors[props.color]};

          i {
            color: ${(props) => props.theme.colors.white};
          }
        }

        &:focus-within {
          box-shadow: unset;
          outline: unset;
        }
      }

      &-checked + p {
        color: ${(props) => props.theme.colors[props.colorText]};
      }
    }

    .ant-radio-checked .ant-radio-inner,
    .ant-radio:hover .ant-radio-inner,
    .ant-radio-input:focus + .ant-radio-inner,
    .ant-radio-input:active + .ant-radio-inner {
      border-color: ${(props) => props.theme.colors.primaryBorder};
    }

    .ant-radio-wrapper {
      color: ${(props) => props.theme.colors.primaryText};
    }

    .ant-radio-disabled + span {
      color: ${(props) => props.theme.colors.filterLabelColor};
    }

    .ant-radio-inner,
    .ant-radio-disabled .ant-radio-inner {
      border-color: ${(props) => props.theme.colors.primaryBorder} !important;
      background: transparent;
    }

    .ant-radio-checked .ant-radio-inner {
      border-color: ${(props) => props.theme.colors.primaryBorder} !important;
    }

    .ant-radio-wrapper {
      color: ${(props) => props.theme.colors.primaryText};
      margin-right: 2.28rem;
      white-space: nowrap;

      span.ant-radio + * {
        padding-inline-start: 8px;
        padding-inline-end: 8px;
      }

      > span {
        font-size: 1rem;
      }
    }
  }
`;

const StyledFileRadioList = styled.ul`
  display: flex;
  ${(props) => props.isFileManager
    && `ul {
      flex-wrap: nowrap;
      column-gap: 0;
    }
  `};
  span {
    font-size: 1rem;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  label.ant-radio-button-wrapper {
    height: 2.4rem !important;
    width: 100% !important;
    min-width: 42px;
    border-radius: 0;
    display: flex;
    align-items: center;
    padding: 0.7rem 1.14rem;
    color: ${(props) => props.theme.colors.primaryText};
    background: ${(props) => props.theme.colors.secondaryBg};
    border: 1px solid ${(props) => props.theme.colors.primaryBorder};
    transition: none;
    &:hover {
      span {
        color: ${(props) => props.theme.colors.blue};
      }
   
    }
    + p {
      display: none;
    }
  }
  > li {
    &:first-child label.ant-radio-button-wrapper {
      border-radius: ${(props) => `${props.theme.customBorderRadius.borderRadiusSmall} 0 0 ${props.theme.customBorderRadius.borderRadiusSmall}!important`};
      border-left: 1px solid ${(props) => props.theme.colors.primaryBorder}!important;
    }
    &:last-child label.ant-radio-button-wrapper {
       border-radius: ${(props) => `0 ${props.theme.customBorderRadius.borderRadiusSmall} ${props.theme.customBorderRadius.borderRadiusSmall} 0 !important`};
    }
   }
    .ant-radio-button-wrapper:first-child:last-child {
      border-radius: 0;
      border-left: 1px solid transparent;
    }
  }
   .ant-radio-button-wrapper-checked:not([class*=' ant-radio-button-wrapper-disabled']).ant-radio-button-wrapper:first-child {
    background: ${(props) => props.theme.colors.blue}20;
    color: ${(props) => props.theme.colors.blue};
    border: 1px solid ${(props) => props.theme.colors.blue}!important;
    svg g rect,
    svg g path {
      fill: ${(props) => props.theme.colors.blue};
    }
    box-shadow: none;
  }
`;
const StyledRadioTitle = styled.div`
  font-weight: ${(props) => props.theme.typography.fontWeightMedium};
  margin-bottom: 1.14rem;
  font-size: ${(props) => props.theme.typography.fontSizeDefault};
  color: ${(props) => props.theme.colors.primaryText};
`;
const StyledRadioWrapper = styled.div`
  margin-top: 2.28rem;
`;
const StyledRadioHover = styled.li`
  &:hover {
    .ant-radio-button-wrapper {
      transform: scale(1.1);
      transition: all 0.1s ease-in-out;
      + span {
        color: ${(props) => props.theme.colors[props.color]};
      }
    }
    svg g rect {
      fill: ${(props) => props.theme.colors.blue};
    }
    label.ant-radio-button-wrapper {
      background: ${(props) => props.theme.colors[props.color]}20;
    }
    > span {
      color: ${(props) => props.theme.colors[props.color]};
    }
  }
`;
const StyledRadioButtons = styled.div`
  display: inline-block;
  &:not(:last-child) {
    margin-right: 8px;
  }
  .ant-radio-button-wrapper {
    padding: 0.42rem 0.85rem;
    height: 2rem;
    line-height: 1.1rem;
    text-align: center;
    background: ${(props) => props.theme.colors.grayButton};
    color: ${(props) => props.theme.colors.primaryText};
    border: none;
    outline: none;
    font-size: 1rem;
  }
  .ant-radio-button-wrapper-checked {
    background: ${(props) => props.theme.colors.blue};
    color: ${(props) => props.theme.colors.white};
  }
  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover {
    color: ${(props) => props.theme.colors.white};
  }
`;

export {
  StyledRadioList, StyledFileRadioList, StyledRadioTitle, StyledRadioWrapper, StyledRadioButtons, StyledRadioHover,
};
