import { useEffect } from 'react';
import { Form } from 'antd';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { IconSvg } from 'components/common';
import Buttons from 'components/common/button/Buttons';
import { StyledIconDefault } from 'components/styledComponents/icon/Icon.styled';
import { StyledModal } from 'components/styledComponents/modal/Modal.styled';
import ChangePasswordForm from './ChangePasswordForm';

const ChangePassword = (props) => {
  const {
    visible, changeModalState, changePassword, changeEndedStatus, endedStatus,
  } = props;

  const { t } = useTranslation();

  const [form] = Form.useForm();

  const onSave = (event) => {
    event.preventDefault();
    form.validateFields().then((values) => {
      changePassword(values);
    });
  };

  useEffect(() => {
    if (endedStatus) {
      changeModalState();
      changeEndedStatus(false);
    }
  }, [changeEndedStatus, changeModalState, endedStatus]);

  return (
    <StyledModal
      getContainer=".ant-layout-content"
      maskClosable={false}
      destroyOnClose
      title={t('changePassword')}
      closable
      centered
      wrapClassName="account-modal-change-password"
      onCancel={changeModalState}
      closeIcon={(
        <StyledIconDefault>
          <IconSvg icon="CloseModalIcon" height="0.86rem" width="0.86rem" />
        </StyledIconDefault>
      )}
      open={visible}
      changeModalState={changeModalState}
      footer={<Buttons okText="update" onClose={changeModalState} onApply={onSave} size="large" />}
    >
      <ChangePasswordForm form={form} />
    </StyledModal>
  );
};

ChangePassword.propTypes = {
  visible: PropTypes.bool,
  changeModalState: PropTypes.func.isRequired,
  changePassword: PropTypes.func.isRequired,
  changeEndedStatus: PropTypes.func.isRequired,
  endedStatus: PropTypes.bool,
};

ChangePassword.defaultProps = {
  visible: false,
  endedStatus: false,
};
export default ChangePassword;
