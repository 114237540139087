import PropTypes from 'prop-types';
import _ from 'lodash';
import { useMemo } from 'react';
import { Content } from 'components/layout';
import { Notification, Sider } from 'containers/layout';
import Header from 'containers/layout/header/Header';
import { arrayToTree } from 'utils/utils';
import MainInfo from 'containers/brandSetting/MainInfo';
import { getSidebarSizes } from 'utils/styledHelpers';
import { useLayout } from 'hooks';
import { StyledLayout, StyledMainSettingsWrapper } from './Layouts.styled';

const sidebarSizes = getSidebarSizes();

const BrandSettingLayout = (props) => {
  const {
    children, routers, page, showPartner, showBrand, width, collapsedWidth, showTimeZone,
  } = props;

  const { hideLayout } = useLayout();

  const newData = useMemo(() => _.cloneDeep(routers).filter((el) => el.menuItem), [routers]);
  const menus = useMemo(() => arrayToTree(newData, 'id', 'menuParentId'), [newData]);

  return (
    <>
      <Notification />
      {hideLayout ? (
        <StyledLayout>
          <Content noPadding>{children}</Content>
        </StyledLayout>
      ) : (
        <>
          <Header collapsedWidth={collapsedWidth} width={width} showPartner={showPartner} showBrand={showBrand} showTimeZone={showTimeZone} />
          <StyledLayout isBrand>
            <Sider menus={menus} collapsedWidth={collapsedWidth} width={width} page={page} />
            <Content>
              <StyledMainSettingsWrapper>
                <MainInfo />
              </StyledMainSettingsWrapper>
              {children}
            </Content>
          </StyledLayout>
        </>
      )}
    </>
  );
};

BrandSettingLayout.propTypes = {
  children: PropTypes.node,
  routers: PropTypes.array,
  page: PropTypes.string,
  showPartner: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
  showBrand: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
  showTimeZone: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
  width: PropTypes.string,
  collapsedWidth: PropTypes.string,
};

BrandSettingLayout.defaultProps = {
  children: null,
  routers: [],
  page: '',
  showPartner: false,
  showBrand: false,
  collapsedWidth: sidebarSizes.collapsedWidth,
  width: sidebarSizes.width,
  showTimeZone: false,
};

export default BrandSettingLayout;
