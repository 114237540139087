import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { StyledButtonPurple } from 'components/styledComponents/button/Button.styled';
import { StyledBrandsName } from 'components/brand/Brand.styled';
import { StyledBanner } from 'components/styledComponents/banner/Banner.styled';
import Can from 'components/common/Can';
import { resource, types } from 'constants/accessControl';
import Config from 'config';

const { isBrRegulationAudit } = Config;
const Banner = (props) => {
  const { partner } = props;

  const { t } = useTranslation();

  return (
    <StyledBanner>
      <StyledBrandsName isSmallText>{partner && partner.name}</StyledBrandsName>
      {!isBrRegulationAudit && (
        <Can type={types.add} rule={`${resource.brands}.${resource.brands}`}>
          <Link to="/brands/create">
            <StyledButtonPurple size="large">{t('brand:createBrand')}</StyledButtonPurple>
          </Link>
        </Can>
      )}
    </StyledBanner>
  );
};

Banner.propTypes = {
  partner: PropTypes.object,
};

Banner.defaultProps = {
  partner: null,
};

export default Banner;
