import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { cloneElement } from 'react';

const DraggableTabNode = ({ className, ...props }) => {
  const {
    attributes, listeners, setNodeRef, transform, transition,
  } = useSortable({
    id: props['data-node-key'],
  });

  const style = {
    ...props.style,
    transform: CSS.Transform.toString(
      transform && {
        ...transform,
        scaleX: 1,
      },
    ),
    transition,
    cursor: 'move',
  };

  return cloneElement(props.children, {
    ref: setNodeRef,
    style,
    ...attributes,
    ...listeners,
  });
};

export default DraggableTabNode;
