import NotificationActionType from 'actions/notification/notificationActionType';

const initialState = {
  sentStatus: false,
  tagsResource: [],
  inboxMessages: [],
  massMessages: {
    sent: [],
    scheduled: [],
    draft: [],
  },
  total: {
    sent: 0,
    scheduled: 0,
    draft: 0,
    inbox: 0,
  },
  massMessage: {},
  recipients: {
    data: [],
    total: 0,
  },
  titleExists: null,
  bonusNotifications: {},
  bonusNotification: [],
};

const notificationReducer = (state = initialState, { type, payload }) => {
  switch (type) {
  case NotificationActionType.CHANGE_NOTIFICATION_SENT_STATUS:
    return {
      ...state,
      sentStatus: payload.status,
    };
  case NotificationActionType.SAVE_TEMPLATE_TAGS_RESOURCE:
    return {
      ...state,
      tagsResource: payload.data,
    };
  case NotificationActionType.RESET_TEMPLATE_TAGS_RESOURCE:
    return {
      ...state,
      tagsResource: [],
    };
  case NotificationActionType.SAVE_MASS_MESSAGES_STATE:
    return {
      ...state,
      massMessages: payload.data,
    };
  case NotificationActionType.SAVE_MESSAGES_TOTAL:
    return {
      ...state,
      total: payload.data,
    };
  case NotificationActionType.GET_MASS_MESSAGE_SUCCESS:
    return {
      ...state,
      massMessage: payload.data,
    };
  case NotificationActionType.SAVE_INBOX_MESSAGES:
    return {
      ...state,
      inboxMessages: payload.data,
    };
  case NotificationActionType.RESET_INBOX_MESSAGES:
    return {
      ...state,
      inboxMessages: [],
      total: {
        ...state.total,
        inbox: 0,
      },
    };
  case NotificationActionType.RESET_MASS_MESSAGE:
    return {
      ...state,
      massMessage: {},
    };
  case NotificationActionType.SAVE_MASS_MESSAGE_RECIPIENTS:
    return {
      ...state,
      recipients: payload.data,
    };
  case NotificationActionType.RESET_MASS_MESSAGE_RECIPIENTS:
    return {
      ...state,
      recipients: {
        data: [],
        total: 0,
      },
    };
  case NotificationActionType.SAVE_TITLE_EXISTS:
    return {
      ...state,
      titleExists: payload.data,
    };
  case NotificationActionType.SAVE_BONUS_NOTIFICATIONS:
    return {
      ...state,
      bonusNotifications: payload.data,
    };
  case NotificationActionType.RESET_BONUS_NOTIFICATIONS:
    return {
      ...state,
      bonusNotifications: {},
    };
  case NotificationActionType.SAVE_BONUS_NOTIFICATION:
    return {
      ...state,
      bonusNotification: payload.data,
    };
  case NotificationActionType.RESET_BONUS_NOTIFICATION:
    return {
      ...state,
      bonusNotification: [],
    };
  default:
    return state;
  }
};

export default notificationReducer;
