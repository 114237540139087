import styled from 'styled-components';
import { StyledButtonWrapperCentered } from 'components/styledComponents/button/Button.styled';

const ActionsTextItems = styled.div`
  display: flex;
  justify-content: flex-end;
  .ant-btn {
    font-size: 1.14rem;
    font-weight: ${(props) => props.theme.typography.fontWeightMedium};
    color: ${(props) => props.theme.colors.blue};
    background: none;
    border: none;
    cursor: pointer;
    outline: none;
    padding: 0;
    height: 2rem;
    &:disabled {
      svg {
        path {
          fill: ${(props) => props.theme.colors.primaryBorder}!important;
        }
      }
      &:hover {
        background: transparent;
        cursor: not-allowed !important ;
      }
    }
    &:last-child svg {
      margin-right: 0.5rem;
    }

    &:last-of-type {
      margin-left: 1.45rem;
    }
  }
`;

const StyledExpandedTable = styled.div`
  .ant-table-cell.verification span {
    border-left: 0;
    padding-left: 0;
  }
`;
const StyledReferralText = styled.div`
  span {
    justify-content: center;
    margin-bottom: 0 !important;
  }
`;

const StyledPaddingLeftWrapper = styled.div`
  padding-left: 1.71rem;
`;
const StyledAmountConfigWrapper = styled.div`
  display: flex;
  p {
    font-size: 1rem;
    margin-right: 6rem;
  }
`;

const StyledGamesWrapper = styled.div`
  .ant-spin-container .ant-table-container {
    padding: 0;
  }
  .ant-table.ant-table-middle .ant-table-tbody > tr {
    height: 2.43em;
  }
  .ant-table {
    height: 100%;
  }
`;
const StyledMarginTop = styled.div`
  margin-top: 2.28rem;
`;
const StyledDepositMargin = styled.div`
  margin-bottom: 2.28rem;
  span {
    font-size: 1rem;
  }
`;
const StyledRadioInfoGroup = styled.div`
  display: flex;
  &:last-child div {
    margin-left: -0.3rem;
    display: flex;
    align-items: center;
  }
  .ant-radio-wrapper span {
    font-size: 1rem;
  }
`;
const StyledRangeWrapper = styled.span`
  display: ${(props) => (!props.isFlex ? 'flex' : '')};
  align-items: center;
  margin-left: 0.3rem;
  > p,
  > span {
    margin-right: 0.3rem;
  }
  svg {
    margin-left: 0.2rem;
  }
  span {
    font-weight: ${(props) => props.theme.typography.fontWeightMedium};
  }
`;
const StyledButtonWrapperBottom = styled(StyledButtonWrapperCentered)`
  margin-top: auto;
`;
export {
  StyledDepositMargin,
  StyledGamesWrapper,
  StyledAmountConfigWrapper,
  StyledPaddingLeftWrapper,
  StyledReferralText,
  ActionsTextItems,
  StyledExpandedTable,
  StyledMarginTop,
  StyledRangeWrapper,
  StyledRadioInfoGroup,
  StyledButtonWrapperBottom,
};
