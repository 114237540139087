import {
  all, call, put, takeLatest,
} from 'redux-saga/effects';
import { changeLoadingState, setErrorMessage } from 'actions/setting/settingActionCreator';
import NetworkService from 'services/networkService';
import LocaleActionType from 'actions/locale/localeActionType';
import { AppConstants } from 'constants/index';
import { getError } from 'utils/helpers';
import { saveLocaleResources } from 'actions/locale/localeActionCreator';

function* getLocaleResources() {
  try {
    const { ReportingAdmin, Translation } = AppConstants.api;
    yield put(changeLoadingState(true));
    const { data } = yield call(NetworkService.makeAPIGetRequest, [ReportingAdmin, Translation]);
    yield put(saveLocaleResources(data));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

export default function* localeSaga() {
  yield all([takeLatest(LocaleActionType.GET_LOCALE_RESOURCES, getLocaleResources)]);
}
