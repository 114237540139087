import PreviousAccountActionType from 'actions/previousAccount/previousAccountActionType';

const initialState = {
  previousAccountsGroups: {},
  previousAccounts: [],
  previousAccountsInfo: {},
};

const previousAccountReducer = (state = initialState, { type, payload }) => {
  switch (type) {
  case PreviousAccountActionType.SAVE_PLAYER_PREVIOUS_ACCOUNTS:
    return {
      ...state,
      previousAccounts: payload.data,
    };
  case PreviousAccountActionType.RESET_PLAYER_PREVIOUS_ACCOUNTS:
    return {
      ...state,
      previousAccounts: [],
    };
  case PreviousAccountActionType.SAVE_PREVIOUS_ACCOUNTS_GROUPS:
    return {
      ...state,
      previousAccountsGroups: payload.data,
    };
  case PreviousAccountActionType.RESET_PREVIOUS_ACCOUNTS_GROUPS:
    return {
      ...state,
      previousAccountsGroups: {},
    };
  case PreviousAccountActionType.SAVE_PLAYER_PREVIOUS_ACCOUNTS_INFO:
    return {
      ...state,
      previousAccountsInfo: payload.data,
    };
  case PreviousAccountActionType.RESET_PLAYER_PREVIOUS_ACCOUNTS_INFO:
    return {
      ...state,
      previousAccountsInfo: {},
    };
  default:
    return state;
  }
};

export default previousAccountReducer;
