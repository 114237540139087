import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { StyledStandardDescription } from './standard/Standard.styled';

const Description = (props) => {
  const { description, changes } = props;

  const { t } = useTranslation();

  const changesText = `${t('changes')}: `;

  return (
    <StyledStandardDescription>
      <p>{description}</p>
      <p>
        {changes && <strong>{changesText}</strong>}
        {changes}
      </p>
    </StyledStandardDescription>
  );
};

Description.propTypes = {
  description: PropTypes.string,
  changes: PropTypes.string,
};

Description.defaultProps = {
  description: '',
  changes: '',
};

export default Description;
