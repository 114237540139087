import styled from 'styled-components';

const StyledPermissionWrapper = styled.div`
  background: ${(props) => props.theme.colors.secondaryBg};
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-radius: ${(props) => props.theme.customBorderRadius.borderRadiusSmall};
  margin: ${(props) => (props.isMargin ? '2.85rem 1.71rem 1.71rem' : '2.85rem 0 0')};
`;
const StyledPermissionTitle = styled.div`
  font-size: 1.71rem;
  margin: 1.71rem 0 1.14rem;
  color: ${(props) => props.theme.colors.filterLabelColor};
`;

const StyledPermissionText = styled.div`
  color: ${(props) => props.theme.colors.filterLabelColor};
  font-size: 1.28rem;
  margin: 1.71rem 0 2.28rem;
`;

const StyledPermissionIcon = styled.div`
  position: relative;
  z-index: 2;
  margin-right: 3rem;
  svg path,
  svg circle {
    fill: #e3e3e3 !important;
  }
  svg g rect,
  svg g path {
    fill: #acacac;
  }
`;

const StyledPermissionSettings1 = styled.div`
  position: absolute;
  top: 2.3rem;
  left: -3.5rem;
  animation: rotation 25s infinite alternate;
  @keyframes rotation {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
`;
const StyledPermissionSettings2 = styled.div`
  position: absolute;
  z-index: -1;
  bottom: 0.7rem;
  left: -2.5rem;
  animation: rotation 20s infinite alternate;
  @keyframes rotation {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
`;

const StyledEmptyContentText = styled.p`
  margin-top: 1.14rem;
  max-width: 25rem;
  text-align: center;
`;

export {
  StyledPermissionWrapper, StyledPermissionTitle, StyledPermissionText, StyledPermissionSettings1, StyledPermissionSettings2, StyledPermissionIcon, StyledEmptyContentText,
};
