import BrandActionType from 'actions/brand/brandActionType';
import { LocalStorageService } from 'services';

const getInitialState = () => ({
  loading: false,
  brand: {},
  brands: [],
  currentBrandId: LocalStorageService.get('current')?.brandId,
  markets: [],
  isBrandExist: null,
  templates: [],
  callEndedStatus: false,
});

const brandReducer = (state = getInitialState(), { type, payload }) => {
  switch (type) {
  case BrandActionType.GET_BRAND_REQUEST:
    return {
      ...state,
      loading: true,
    };
  case BrandActionType.GET_BRAND_SUCCESS:
    return {
      ...state,
      brand: payload.data,
    };
  case BrandActionType.RESET_BRAND:
    return {
      ...state,
      brand: {},
    };
  case BrandActionType.GET_BRANDS_SUCCESS:
    return {
      ...state,
      brands: payload.data,
    };
  case BrandActionType.SET_CURRENT_BRAND_ID:
    return {
      ...state,
      currentBrandId: payload.id,
    };
  case BrandActionType.CHANGE_BRAND_LOADING_STATE:
    return {
      ...state,
      loading: payload.state,
    };
  case BrandActionType.GET_BRAND_MARKETS_SUCCESS:
    return {
      ...state,
      markets: payload.data,
    };
  case BrandActionType.GET_IS_BRAND_EXIST_SUCCESS:
    return {
      ...state,
      isBrandExist: payload.data,
    };
  case BrandActionType.RESET_IS_BRAND_EXIST:
    return {
      ...state,
      isBrandExist: null,
    };
  case BrandActionType.GET_BRAND_TEMPLATES_SUCCESS:
    return {
      ...state,
      templates: payload.data,
    };
  case BrandActionType.RESET_BRAND_TEMPLATES:
    return {
      ...state,
      templates: [],
    };
  case BrandActionType.CHANGE_BRAND_CALL_ENDED_STATUS:
    return {
      ...state,
      callEndedStatus: payload.status,
    };
  default:
    return state;
  }
};

export default brandReducer;
