export const selectBonusEndedStatus = (state) => state.endedStatus;

const selectCasinoBonuses = (state) => state.casinoBonuses;
export const selectCasinoBonusesData = (state) => selectCasinoBonuses(state).data;
export const selectCasinoBonusesTotal = (state) => selectCasinoBonuses(state).total;

const selectSpinBonuses = (state) => state.spinBonuses;
export const selectSpinBonusesData = (state) => selectSpinBonuses(state).data;
export const selectSpinBonusesTotal = (state) => selectSpinBonuses(state).total;

const selectSportBonuses = (state) => state.sportBonuses;
export const selectSportBonusesData = (state) => selectSportBonuses(state).data;
export const selectSportBonusesTotal = (state) => selectSportBonuses(state).total;

const selectSpecialBonuses = (state) => state.specialBonuses;
export const selectSpecialBonusesData = (state) => selectSpecialBonuses(state).data;
export const selectSpecialBonusesTotal = (state) => selectSpecialBonuses(state).total;

const selectPlayerCasinoBonuses = (state) => state.playerCasinoBonuses;
export const selectPlayerCasinoBonusesData = (state) => selectPlayerCasinoBonuses(state).data;
export const selectPlayerCasinoBonusesTotal = (state) => selectPlayerCasinoBonuses(state).total;

const selectPlayerSpinBonuses = (state) => state.playerSpinBonuses;
export const selectPlayerSpinBonusesData = (state) => selectPlayerSpinBonuses(state).data;
export const selectPlayerSpinBonusesTotal = (state) => selectPlayerSpinBonuses(state).total;

const selectPlayerSportBonuses = (state) => state.playerSportBonuses;
export const selectPlayerSportBonusesData = (state) => selectPlayerSportBonuses(state).data;
export const selectPlayerSportBonusesTotal = (state) => selectPlayerSportBonuses(state).total;

const selectPlayerSpecialBonuses = (state) => state.playerSpecialBonuses;
export const selectPlayerSpecialBonusesData = (state) => selectPlayerSpecialBonuses(state).data;
export const selectPlayerSpecialBonusesTotal = (state) => selectPlayerSpecialBonuses(state).total;

const selectCasinoBonusStatistics = (state) => state.casinoBonusStatistics;
export const selectCasinoBonusStatisticsData = (state) => selectCasinoBonusStatistics(state).data;
export const selectCasinoBonusStatisticsTotal = (state) => selectCasinoBonusStatistics(state).total;

const selectSpinBonusStatistics = (state) => state.spinBonusStatistics;
export const selectSpinBonusStatisticsData = (state) => selectSpinBonusStatistics(state).data;
export const selectSpinBonusStatisticsTotal = (state) => selectSpinBonusStatistics(state).total;

const selectSportBonusStatistics = (state) => state.sportBonusStatistics;
export const selectSportBonusStatisticsData = (state) => selectSportBonusStatistics(state).data;
export const selectSportBonusStatisticsTotal = (state) => selectSportBonusStatistics(state).total;

const selectSpecialBonusStatistics = (state) => state.specialBonusStatistics;
export const selectSpecialBonusStatisticsData = (state) => selectSpecialBonusStatistics(state).data;
export const selectSpecialBonusStatisticsTotal = (state) => selectSpecialBonusStatistics(state).total;

export const selectCasinoBonus = (state) => state.casinoBonus;
export const selectSpinBonus = (state) => state.spinBonus;
export const selectSportBonus = (state) => state.sportBonus;
export const selectSpecialBonus = (state) => state.specialBonus;

export const selectBonusContent = (state) => state.content;
