import PaymentMethodActionType from 'actions/paymentMethod/paymentMethodActionType';

const initialState = {
  playerBlockedPaymentMethodsHistory: {},
  playerBlockedPaymentMethods: {},
  playersBlockedPaymentMethodsHistory: {},
  paymentMethodBlockedPlayers: {
    data: [],
    total: 0,
  },
};

const blockedPaymentReducer = (state = initialState, { type, payload }) => {
  switch (type) {
  case PaymentMethodActionType.SAVE_PLAYER_BLOCKED_PAYMENT_METHODS_HISTORY: {
    return {
      ...state,
      playerBlockedPaymentMethodsHistory: payload.data,
    };
  }
  case PaymentMethodActionType.RESET_PLAYER_BLOCKED_PAYMENT_METHODS_HISTORY:
    return {
      ...state,
      playerBlockedPaymentMethodsHistory: {},
    };
  case PaymentMethodActionType.SAVE_PLAYER_BLOCKED_PAYMENT_METHODS:
    return {
      ...state,
      playerBlockedPaymentMethods: payload.data,
    };
  case PaymentMethodActionType.RESET_PLAYER_BLOCKED_PAYMENT_METHODS:
    return {
      ...state,
      playerBlockedPaymentMethods: {},
    };
  case PaymentMethodActionType.SAVE_BULK_BLOCKED_PAYMENT_METHODS_HISTORY:
    return {
      ...state,
      playersBlockedPaymentMethodsHistory: payload.data,
    };
  case PaymentMethodActionType.RESET_BULK_BLOCKED_PAYMENT_METHODS_HISTORY:
    return {
      ...state,
      playersBlockedPaymentMethodsHistory: {},
    };
  case PaymentMethodActionType.SAVE_BLOCKED_PAYMENT_METHOD_PLAYERS:
    return {
      ...state,
      paymentMethodBlockedPlayers: payload.data,
    };
  case PaymentMethodActionType.RESET_BLOCKED_PAYMENT_METHOD_PLAYERS:
    return {
      ...state,
      paymentMethodBlockedPlayers: {
        data: [],
        total: 0,
      },
    };
  default:
    return state;
  }
};

export default blockedPaymentReducer;
