import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { ManageFilters } from 'components/common';
import { createOrEditUserConfig } from 'actions/user/userActionCreator';
import { selectUserConfig, selectUserLoading } from 'selectors';
import { changeLoadingState } from 'actions/setting/settingActionCreator';

const mapStateToProps = ({ users }, ownProps) => ({
  userFilterConfigs: selectUserConfig(users, ownProps?.filterKey),
  loading: selectUserLoading(users),
});

const mapDispatchToProps = (dispatch) => bindActionCreators(
  {
    createOrEditUserConfig,
    changeLoadingState,
  },
  dispatch,
);

export default connect(mapStateToProps, mapDispatchToProps)(ManageFilters);
