const PaymentMethodActionType = {
  GET_PLAYER_BLOCKED_PAYMENT_METHODS_HISTORY: 'GET_PLAYER_BLOCKED_PAYMENT_METHODS_HISTORY',
  SAVE_PLAYER_BLOCKED_PAYMENT_METHODS_HISTORY: 'SAVE_PLAYER_BLOCKED_PAYMENT_METHODS_HISTORY',
  RESET_PLAYER_BLOCKED_PAYMENT_METHODS_HISTORY: 'RESET_PLAYER_BLOCKED_PAYMENT_METHODS_HISTORY',

  BLOCK_PLAYER_PAYMENT_METHODS: 'BLOCK_PLAYER_PAYMENT_METHODS',
  UNBLOCK_PLAYER_PAYMENT_METHODS: 'UNBLOCK_PLAYER_PAYMENT_METHODS',

  GET_BULK_BLOCKED_PAYMENT_METHODS_HISTORY: 'GET_BULK_BLOCKED_PAYMENT_METHODS_HISTORY',
  SAVE_BULK_BLOCKED_PAYMENT_METHODS_HISTORY: 'SAVE_BULK_BLOCKED_PAYMENT_METHODS_HISTORY',
  RESET_BULK_BLOCKED_PAYMENT_METHODS_HISTORY: 'RESET_BULK_BLOCKED_PAYMENT_METHODS_HISTORY',

  BLOCK_PLAYERS_PAYMENT_METHODS_BY_BULK: 'BLOCK_PLAYERS_PAYMENT_METHODS_BY_BULK',
  UNBLOCK_PLAYERS_PAYMENT_METHODS_BY_BULK: 'UNBLOCK_PLAYERS_PAYMENT_METHODS_BY_BULK',

  GET_BLOCKED_PAYMENT_METHOD_PLAYERS: 'GET_BLOCKED_PAYMENT_METHOD_PLAYERS',
  SAVE_BLOCKED_PAYMENT_METHOD_PLAYERS: 'SAVE_BLOCKED_PAYMENT_METHOD_PLAYERS',
  RESET_BLOCKED_PAYMENT_METHOD_PLAYERS: 'RESET_BLOCKED_PAYMENT_METHOD_PLAYERS',

  EXPORT_BLOCKED_PAYMENT_METHOD_PLAYERS: 'EXPORT_BLOCKED_PAYMENT_METHOD_PLAYERS',
};

export default PaymentMethodActionType;
