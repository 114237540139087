import DashboardActionType from './dashboardActionType';

export const getDashboards = () => ({
  type: DashboardActionType.GET_DASHBOARDS,
});

export const saveDashboards = (data) => ({
  type: DashboardActionType.SAVE_DASHBOARDS,
  payload: { data },
});

export const resetDashboards = () => ({
  type: DashboardActionType.RESET_DASHBOARDS,
});

export const createDashboard = (data) => ({
  type: DashboardActionType.CREATE_DASHBOARD,
  payload: { data },
});

export const editDashboard = (id, data) => ({
  type: DashboardActionType.EDIT_DASHBOARD,
  payload: { id, data },
});

export const deleteDashboard = (data) => ({
  type: DashboardActionType.DELETE_DASHBOARD,
  payload: { data },
});

export const getWidgets = () => ({
  type: DashboardActionType.GET_WIDGETS,
});

export const saveWidgets = (data) => ({
  type: DashboardActionType.SAVE_WIDGETS,
  payload: { data },
});

export const resetWidgets = () => ({
  type: DashboardActionType.RESET_WIDGETS,
});

export const createWidget = (data, applyTo = false) => ({
  type: DashboardActionType.CREATE_WIDGET,
  payload: { data, applyTo },
});

export const editWidget = (id, data, forceUpdate = false) => ({
  type: DashboardActionType.EDIT_WIDGET,
  payload: { id, data, forceUpdate },
});

export const deleteWidget = (widgetId, data) => ({
  type: DashboardActionType.DELETE_WIDGET,
  payload: { widgetId, data },
});

export const saveLastRequestedWidget = (data) => ({
  type: DashboardActionType.SAVE_LAST_REQUESTED_WIDGET,
  payload: { data },
});

export const resetLastRequestedWidget = () => ({
  type: DashboardActionType.RESET_LAST_REQUESTED_WIDGET,
});

export const getWidgetData = (id, params) => ({
  type: DashboardActionType.GET_WIDGET_DATA,
  payload: { id, params },
});

export const saveWidgetData = (id, data) => ({
  type: DashboardActionType.SAVE_WIDGET_DATA,
  payload: { id, data },
});

export const resetWidgetsData = () => ({
  type: DashboardActionType.RESET_WIDGETS_DATA,
});

export const saveWidgetLoading = (id, loading) => ({
  type: DashboardActionType.SAVE_WIDGET_LOADING,
  payload: { id, loading },
});

export const saveApplyToWidget = (data) => ({
  type: DashboardActionType.SAVE_APPLY_TO_WIDGET,
  payload: { data },
});

export const resetApplyToWidget = () => ({
  type: DashboardActionType.RESET_APPLY_TO_WIDGET,
});

export const changeDashboardEndedStatus = (status) => ({
  type: DashboardActionType.CHANGE_DASHBOARD_ENDED_STATUS,
  payload: { status },
});
