export default {
  en: {
    permissionGroup: 'Permission Group',
    lFullAccess: 'full access',
    fullAccess: 'Full Access',
    supervisor: 'Supervisor',
    editInfo: 'Edit Info',
    lUsers: 'users',
    createAnother: 'Create Another',
    accessTo: 'Access to',
    fullAccessInfo: 'The user will have access to newly created brands and some partner-level pages if has permission to view them (Users, Permission Groups, IP Whitelist, User Management Logs)',
    permission: 'Permission',
    trustedIpAddresses: 'Trusted IP Addresses',
    lSymbol: 'symbol',
    groupName: 'Group Name',
    lPermissionGroup: 'permission group',
    permissionGroupName: 'Permission Group Name',
    cloneFromExistingList: 'Clone from existing list',
    lActivityStatus: 'activity status',
    lPermissions: 'permissions',
    permissionsOfUsers: 'Permissions of users',
    ipAddresses: 'IP addresses',
    ipAddress: 'IP address',
    ipAddressRange: 'IP address range',
    infoUserPermissions: 'Users in the group will lose their permissions.',
    brandAccess: 'Brand Access',
    resendQRCode: 'Resend QR Code',
    qrCode: 'QR Code',
    qrCodes: 'QR Codes',
    lUnlock: 'unlock',
    unlock: 'Unlock',
    maxSecurityLevel: 'Max. Security Level',
    permissionGroupsInfo: 'Predefined sets of permissions tailored to user roles. Additional permission outside the group is not allowed',
    temporaryPermissionGroups: 'Temporary Permission Groups',
    temporaryPermissionGroup: 'Temporary Permission Group',
    temporaryPermissionGroupsInfo: 'Select a temporary permission group for the user. This group will grant additional permissions for a limited time',
    expirationDateInfo: 'Select the date when the temporary permissions will expire',
    groupsPermissionsInfo: 'When the permission comes from a temporary permission group, the permissions will be highlighted with color <0/>',
  },
  es: {
    permissionGroup: 'Grupo de permisos',
    lFullAccess: 'acceso total',
    fullAccess: 'Acceso total',
    supervisor: 'Supervisor',
    editInfo: 'Editar info.',
    lUsers: 'usuarios',
    createAnother: 'Crear otro',
    accessTo: 'Acceso a',
    permission: 'Permiso',
    trustedIpAddresses: 'Direcciones IP de confianza',
    lSymbol: 'símbolo',
    groupName: 'Nombre del grupo',
    lPermissionGroup: 'grupo de permisos',
    permissionGroupName: 'Nombre del grupo de permiso',
    cloneFromExistingList: 'Clonar desde una lista existente',
    lActivityStatus: 'estado de actividad',
    lPermissions: 'permisos',
    permissionsOfUsers: 'Permisos de los usuarios',
    ipAddresses: 'Dirección IP',
    ipAddress: 'Direcciones IP',
    ipAddressRange: 'Gama de direcciones IP',
    infoUserPermissions: 'Los usuarios del grupo perderán sus permisos.',
    brandAccess: 'Acceso a la marca',
    resendQRCode: 'Reenviar Código QR',
    qrCode: 'Código QR',
    qrCodes: 'Códigos QR',
    fullAccessInfo:
      'El usuario tendrá acceso a las marcas recién creadas y a algunas páginas a nivel de socio si tiene permiso para verlas (Usuarios, Grupos de permisos, Lista blanca de IP, Registros de gestión de usuarios).',
    lUnlock: 'desbloquear',
    unlock: 'Desbloquear',
    maxSecurityLevel: 'Max. Nivel de Seguridad',
  },
  tr: {
    permissionGroup: 'İzin Grubu',
    lFullAccess: 'tam erişim',
    fullAccess: 'Tam Erişim',
    supervisor: 'Denetmen',
    editInfo: 'Bilgileri Düzenle',
    lUsers: 'kullanıcılar',
    createAnother: 'Başka Oluştur',
    accessTo: 'Erişim',
    permission: 'İzin',
    trustedIpAddresses: 'Güvenilir IP Adresleri',
    lSymbol: 'sembol',
    groupName: 'Grup Adı',
    lPermissionGroup: 'izin grubu',
    permissionGroupName: 'İzin Grubu Adı',
    cloneFromExistingList: 'Var olan listeden klonla',
    lActivityStatus: 'aktivite durumu',
    lPermissions: 'izinler',
    permissionsOfUsers: 'Kullanıcıların izinleri',
    ipAddresses: 'IP adresleri',
    ipAddress: 'IP adresi',
    ipAddressRange: 'IP adresi aralığı',
    infoUserPermissions: 'Gruptaki kullanıcılar izinlerini kaybedecek.',
    brandAccess: 'Marka Erişimi',
    resendQRCode: 'QR Kodunu Yeniden Gönder',
    qrCode: 'QR Kodu',
    qrCodes: 'QR Kodları',
    fullAccessInfo:
      'Kullanıcının yeni oluşturulan markalara ve bazı ortak sayfalarına, bunları görüntüleme izni varsa erişimi olacaktır (Kullanıcılar, İzin Grupları, IP Beyaz Listesi, Kullanıcı Yönetimi Günlükleri)',
    lUnlock: 'kilidi aç',
    unlock: 'Kilidi aç',
    maxSecurityLevel: 'Maks. Güvenlik Seviyesi',
  },
  ru: {
    permissionGroup: 'Группа разрешений',
    lFullAccess: 'полный доступ',
    fullAccess: 'Полный доступ',
    supervisor: 'Контролер',
    editInfo: 'Изменить сведения',
    lUsers: 'пользователи',
    createAnother: 'Создать другого',
    accessTo: 'Доступ к',
    permission: 'Разрешение',
    trustedIpAddresses: 'Проверенные IP-адреса',
    lSymbol: 'Символ',
    groupName: 'Название группы',
    lPermissionGroup: 'группа разрешений',
    permissionGroupName: 'Название группы разрешений',
    cloneFromExistingList: 'Клонировать из существующего списка',
    lActivityStatus: 'статус действия',
    lPermissions: 'Разрешения',
    permissionsOfUsers: 'Разрешения пользователей',
    ipAddresses: 'IP-адреса',
    ipAddress: 'IP-адрес',
    ipAddressRange: 'Диапазон IP-адресов',
    infoUserPermissions: 'Пользователи группы лишатся разрешений.',
    brandAccess: 'Доступ бренда',
    resendQRCode: 'Повторно отправить QR-код',
    qrCode: 'QR-код',
    qrCodes: 'QR-коды',
    fullAccessInfo:
      'Пользователь будет иметь доступ к вновь созданным брендам и некоторым страницам на уровне партнеров, если у него есть разрешение на их просмотр (Пользователи, Группы разрешений, Белый список IP-адресов, Журналы управления пользователями).   ',
    lUnlock: 'разблокировать',
    unlock: 'Разблокировать',
    maxSecurityLevel: 'Макс. уровень безопасности',
  },
  pt: {
    permissionGroup: 'Grupo de Permissão',
    lFullAccess: 'acesso total',
    fullAccess: 'Acesso Total',
    supervisor: 'Supervisor',
    editInfo: 'Editar Informações',
    lUsers: 'utilizadores',
    createAnother: 'Criar Outro',
    accessTo: 'Acesso a',
    permission: 'Permissão',
    trustedIpAddresses: 'Endereços IP Confiáveis',
    lSymbol: 'símbolo',
    groupName: 'Nome do Grupo',
    lPermissionGroup: 'grupo de permissão',
    permissionGroupName: 'Nome do Grupo de Permissão',
    cloneFromExistingList: 'Clonar da lista existente',
    lActivityStatus: 'estado da atividade',
    lPermissions: 'permissões',
    permissionsOfUsers: 'Permissões dos utilizadores',
    ipAddresses: 'Endereços IP',
    ipAddress: 'Endereço IP',
    ipAddressRange: 'Gama de endereços IP',
    infoUserPermissions: 'Os utilizadores do grupo perderão as suas permissões.',
    brandAccess: 'Acesso da Marca',
    resendQRCode: 'Reenviar Código QR',
    qrCode: 'Código QR',
    qrCodes: 'Códigos QR',
    fullAccessInfo:
      'O utilizador terá acesso a marcas recém-criadas e a algumas páginas ao nível do parceiro se tiver permissão para as visualizar (Utilizadores, Grupos de permissões, Lista branca de IP, Registos de gestão de utilizadores)',
    lUnlock: 'desbloquear',
    unlock: 'Desbloquear',
    maxSecurityLevel: 'Máx. Nível de segurança',
  },
  zh: {
    permissionGroup: '权限组',
    lFullAccess: '完整访问权限',
    fullAccess: '完整访问权限',
    supervisor: '主管',
    editInfo: '编辑信息',
    lUsers: '用户',
    createAnother: '创建另一个',
    accessTo: '访问',
    permission: '权限',
    trustedIpAddresses: '受信任的IP地址',
    lSymbol: '标志',
    groupName: '组名',
    lPermissionGroup: '权限组',
    permissionGroupName: '权限组名',
    cloneFromExistingList: '从现有列表中复制',
    lActivityStatus: '活动状态',
    lPermissions: '权限',
    permissionsOfUsers: '用户权限',
    ipAddresses: 'IP地址',
    ipAddress: 'IP地址',
    ipAddressRange: 'IP地址范围',
    infoUserPermissions: '组内用户将失去其拥有的权限。',
    brandAccess: '品牌访问',
    resendQRCode: '重发QR码',
    qrCode: 'QR码',
    qrCodes: 'QR码',
    fullAccessInfo: '如果有权限查看（用户、权限组、IP白名单、用户管理日志），用户可以访问新创建的品牌和某些合作伙伴级别的页面。',
    lUnlock: '解锁',
    unlock: '解锁',
    maxSecurityLevel: '最大安全等级',
  },
  ro: {
    permissionGroup: 'Grup de permisiuni',
    lFullAccess: 'acces complet',
    fullAccess: 'Acces complet',
    supervisor: 'Supraveghetor',
    editInfo: 'Editează informații',
    lUsers: 'Utilizatori',
    createAnother: 'Creează altul',
    accessTo: 'Accesare la',
    permission: 'Permisiune',
    trustedIpAddresses: 'Adrese IP de încredere',
    lSymbol: 'simbol',
    groupName: 'Numele grupului',
    lPermissionGroup: 'Grup de permisiuni',
    permissionGroupName: 'Numele grupului de permisiuni',
    cloneFromExistingList: 'Clonează din lista existentă',
    lActivityStatus: 'Starea de activitate',
    lPermissions: 'Permisiuni',
    permissionsOfUsers: 'Permisiunile utilizatorilor',
    ipAddresses: 'Adrese IP',
    ipAddress: 'Adresă IP',
    ipAddressRange: 'Interval de adresă IP',
    infoUserPermissions: 'Utilizatorii grupului își vor pierde permisiunile.',
    brandAccess: 'Acces la marcă (metoda de plată)',
    resendQRCode: 'Trimite codul QR din nou',
    qrCode: 'Codul QR',
    qrCodes: 'Coduri QR',
  },
  bg: {},
  el: {
    permissionGroup: 'Ομάδα άδειας',
    lFullAccess: 'πλήρης πρόσβαση',
    fullAccess: 'Πλήρης πρόσβαση',
    supervisor: 'Επιβλέπων',
    editInfo: 'Επεξεργασία πληροφοριών',
    lUsers: 'χρήστες',
    createAnother: 'Δημιουργία άλλου',
    accessTo: 'Πρόσβαση σε',
    permission: 'Άδεια',
    trustedIpAddresses: 'Αξιόπιστες διευθύνσεις IP',
    lSymbol: 'σύμβολο',
    groupName: 'Όνομα ομάδας',
    lPermissionGroup: 'ομάδα άδειας',
    permissionGroupName: 'Όνομα ομάδας άδειας',
    cloneFromExistingList: 'Κλωνοποίηση από υφιστάμενη λίστα',
    lActivityStatus: 'κατάσταση δραστηριότητας',
    lPermissions: 'άδειες',
    permissionsOfUsers: 'Άδειες χρηστών',
    ipAddresses: 'Διευθύνσεις IP',
    ipAddress: 'Διεύθυνση IP',
    ipAddressRange: 'Εύρος διευθύνσεων IP',
    infoUserPermissions: 'Οι χρήστες της ομάδας θα χάσουν τις άδειές τους.',
    brandAccess: 'Πρόσβαση επωνυμίας',
    resendQRCode: 'Αποστολή κωδικού QR εκ νέου',
    qrCode: 'Κωδικός QR',
    qrCodes: 'Κωδικοί QR',
    lUnlock: 'ξεκλείδωμα',
    unlock: 'Ξεκλείδωμα',
  },
};
