import {
  put, all, call, takeLatest,
} from 'redux-saga/effects';
import mime from 'mimetypes';
import qs from 'qs';
import { NetworkService, i18n, MessageService } from 'services';
import { saveBlacklist, saveCSVBlacklist, changeEndedStatus as changeBlacklistEndedStatus } from 'actions/blacklist/blacklistActionCreator';
import {
  changeEndedStatus, changeLoadingState, setErrorMessage, setSuccessMessage,
} from 'actions/setting/settingActionCreator';
import BlacklistActionType from 'actions/blacklist/blacklistActionType';
import { changePlayerActionEndedStatus, changePlayerDataUpdatedStatus } from 'actions/player/playerActionCreator';
import { downloadFile } from 'utils/utils';
import { AppConstants } from 'constants/index';
import { getError, getMessage } from 'utils/helpers';

function* getBlacklist({ payload }) {
  try {
    const { params } = payload;
    const options = {
      params,
    };
    const { PlayersAdmin, Blacklist } = AppConstants.api;
    yield put(changeLoadingState(true));
    const { data } = yield call(NetworkService.makeAPIGetRequest, [PlayersAdmin, Blacklist, payload.data.brandId], options);
    yield put(changeLoadingState(false));
    yield put(saveBlacklist(data));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* exportBlacklist({ payload }) {
  try {
    const { params } = payload;
    const options = {
      params,
      responseType: 'blob',
      paramsSerializer: (param) => qs.stringify(param, { allowDots: true }),
    };
    const { PlayersAdmin, Blacklist, Export } = AppConstants.api;
    yield put(changeLoadingState(true));
    const { data, headers } = yield call(NetworkService.makeAPIGetRequest, [PlayersAdmin, Blacklist, Export, payload.data.brandId], options);
    downloadFile(data, i18n.t('blacklist'), mime.detectExtension(headers['content-type']));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getCsvBlacklist({ payload }) {
  try {
    yield put(changeLoadingState(true));
    const { PlayersAdmin, Blacklist, Validate } = AppConstants.api;
    const options = {
      data: payload.data,
      headers: { 'content-type': 'multipart/form-data' },
    };
    const { data } = yield call(NetworkService.makeAPIPostRequest, [PlayersAdmin, Blacklist, Validate], options);
    yield put(saveCSVBlacklist(data));
    yield put(changeLoadingState(false));
    yield put(changeBlacklistEndedStatus(true));
    if (!data.length) {
      MessageService.error(i18n.t('notification:emptyFile'));
    }
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
      yield put(changeBlacklistEndedStatus(true));
    }
    yield put(changeLoadingState(false));
  }
}

function* deleteBlacklist({ payload }) {
  try {
    yield put(changeLoadingState(true));
    const { PlayersAdmin, Blacklist } = AppConstants.api;
    const options = {
      data: payload.data,
    };
    yield call(NetworkService.makeAPIDeleteRequest, [PlayersAdmin, Blacklist], options);
    yield put(changeEndedStatus(true));
    yield put(changeLoadingState(false));
    yield put(setSuccessMessage(getMessage(i18n.t('blacklist'), i18n.t('notification:slUpdated'))));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* importBlacklist({ payload }) {
  try {
    yield put(changeLoadingState(true));
    const { PlayersAdmin, Blacklist, Import } = AppConstants.api;
    const options = {
      data: payload.data,
    };
    yield call(NetworkService.makeAPIPutRequest, [PlayersAdmin, Blacklist, Import, payload.brandId], options);
    yield put(changeEndedStatus(true));
    yield put(changeLoadingState(false));
    yield put(setSuccessMessage(getMessage(i18n.t('blacklist'), i18n.t('notification:slUpdated'))));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* changePlayersBlacklistedStatus({ payload }) {
  try {
    yield put(changeLoadingState(true));
    const { PlayersAdmin, PlayerBulk, Blacklist } = AppConstants.api;
    const options = {
      data: payload.data,
    };
    yield call(NetworkService.makeAPIPutRequest, [PlayersAdmin, PlayerBulk, Blacklist, payload.brandId], options);
    yield put(changeLoadingState(false));
    const model = payload.isPlural ? 'accounts' : 'account';
    let action = '';
    if (payload.data.blacklist) {
      action = i18n.t(payload.isPlural ? 'notification:plBlacklisted' : 'notification:slBlacklisted');
    } else {
      action = i18n.t(payload.isPlural ? 'notification:plWhiteListed' : 'notification:slWhiteListed');
    }
    yield put(setSuccessMessage(getMessage(i18n.t(model), action)));

    yield put(changeEndedStatus(true));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* changePlayerBlacklistedStatus({ payload }) {
  try {
    const { playerId, params } = payload;
    yield put(changeLoadingState(true));
    const { PlayersAdmin, Actions, Blacklist } = AppConstants.api;
    const options = {
      params,
    };
    yield call(NetworkService.makeAPIPutRequest, [PlayersAdmin, Actions, Blacklist, playerId], options);
    yield put(changeLoadingState(false));
    yield put(setSuccessMessage(getMessage(i18n.t('account'), i18n.t(params.blacklist ? 'notification:slBlacklisted' : 'notification:slWhiteListed'))));
    yield put(changePlayerDataUpdatedStatus(true));
    yield put(changePlayerActionEndedStatus(true));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

export default function* blacklistSaga() {
  yield all([
    takeLatest(BlacklistActionType.GET_BLACKLIST, getBlacklist),
    takeLatest(BlacklistActionType.EXPORT_BLACKLIST, exportBlacklist),
    takeLatest(BlacklistActionType.GET_CSV_BLACKLIST, getCsvBlacklist),
    takeLatest(BlacklistActionType.DELETE_BLACKLIST, deleteBlacklist),
    takeLatest(BlacklistActionType.IMPORT_BLACKLIST, importBlacklist),
    takeLatest(BlacklistActionType.CHANGE_PLAYERS_BLACKLISTED_STATUS, changePlayersBlacklistedStatus),
    takeLatest(BlacklistActionType.CHANGE_PLAYER_BLACKLISTED_STATUS, changePlayerBlacklistedStatus),
  ]);
}
