const BlockedProviderActionType = {
  GET_PLAYER_BLOCKED_PROVIDERS_HISTORY: 'GET_PLAYER_BLOCKED_PROVIDERS_HISTORY',
  SAVE_PLAYER_BLOCKED_PROVIDERS_HISTORY: 'SAVE_PLAYER_BLOCKED_PROVIDERS_HISTORY',
  RESET_PLAYER_BLOCKED_PROVIDERS_HISTORY: 'RESET_PLAYER_BLOCKED_PROVIDERS_HISTORY',

  GET_PLAYER_BLOCKED_PROVIDERS: 'GET_PLAYER_BLOCKED_PROVIDERS',
  SAVE_PLAYER_BLOCKED_PROVIDERS: 'SAVE_PLAYER_BLOCKED_PROVIDERS',
  RESET_PLAYER_BLOCKED_PROVIDERS: 'RESET_PLAYER_BLOCKED_PROVIDERS',

  BLOCK_PLAYER_FOR_PROVIDERS: 'BLOCK_PLAYER_FOR_PROVIDERS',

  BLOCK_PLAYERS_PROVIDERS_BY_BULK: 'BLOCK_PLAYERS_PROVIDERS_BY_BULK',

  GET_BULK_BLOCKED_PROVIDERS_HISTORY: 'GET_BULK_BLOCKED_PROVIDERS_HISTORY',
  SAVE_BULK_BLOCKED_PROVIDERS_HISTORY: 'SAVE_BULK_BLOCKED_PROVIDERS_HISTORY',
  RESET_BULK_BLOCKED_PROVIDERS_HISTORY: 'RESET_BULK_BLOCKED_PROVIDERS_HISTORY',
};

export default BlockedProviderActionType;
