import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { changeLocaleLanguage } from 'actions/locale/localeActionCreator';
import { Languages } from 'components/layout/header';

const mapDispatchToProps = (dispatch) => bindActionCreators(
  {
    changeLocaleLanguage,
  },
  dispatch,
);

export default connect(null, mapDispatchToProps)(Languages);
