import styled from 'styled-components';

const StyledFlexContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: ${(props) => (props.flexWrap ? 'wrap' : 'inherit')};
  width: ${(p) => p.width};
  min-height: auto;
  background-color: unset;
  border: 1px solid transparent;
  flex-direction: ${(props) => (props.isColumn ? 'column' : 'row')};
  svg path {
    fill: ${(props) => props.theme.colors.white};
  }
`;

const StyledFlexItem = styled.div`
  display: flex;
  width: ${(p) => p.width};
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.85rem;
  > div {
    align-self: center;
  }
`;

export { StyledFlexContainer, StyledFlexItem };
