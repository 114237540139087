import { Input, Form } from 'antd';
import PropTypes from 'prop-types';
import {
  StyledFlexEndResend, StyledForgetLink, StyledFormItems, StyledTitleForm,
} from 'components/auth/Login.styled';
import { StyledButtonBlueUnbordered, StyledButtonLogin, StyledButtonWrapper } from 'components/styledComponents/button/Button.styled';
import { FormInputWrapper } from 'components/styledComponents/input/Input.styled';
import { FormItemMarginTopWrapper } from 'Global.styled';
import { FormItemErrorMessage } from 'components/common';

const LoginStep2 = (props) => {
  const { handleSubmit, changeTFAState } = props;

  return (
    <>
      <StyledTitleForm>Enter Code</StyledTitleForm>
      <StyledFormItems>
        <FormInputWrapper>
          <Form.Item
            label="Authentication Code"
            name="tfa_code"
            rules={[
              {
                required: true,
                message: <FormItemErrorMessage errorMassage="TFA code cannot be empty!" />,
              },
            ]}
          >
            <Input placeholder="TFA code" allowClear autoComplete="off" />
          </Form.Item>
        </FormInputWrapper>
      </StyledFormItems>
      <StyledFlexEndResend>
        <StyledForgetLink to="/resendTFA">Recover 2FA?</StyledForgetLink>
      </StyledFlexEndResend>
      <FormItemMarginTopWrapper>
        <StyledButtonWrapper>
          <StyledButtonBlueUnbordered type="primary" onClick={() => changeTFAState(false)}>
            Back
          </StyledButtonBlueUnbordered>
          <StyledButtonLogin type="primary" htmlType="submit" onClick={handleSubmit}>
            Send
          </StyledButtonLogin>
        </StyledButtonWrapper>
      </FormItemMarginTopWrapper>
    </>
  );
};

LoginStep2.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  changeTFAState: PropTypes.func.isRequired,
};

export default LoginStep2;
