import {
  all, call, put, takeLatest,
} from 'redux-saga/effects';
import qs from 'qs';
import mime from 'mimetypes';
import { changeLoadingState, setErrorMessage, setSuccessMessage } from 'actions/setting/settingActionCreator';
import {
  saveVerticalTagCategories,
  saveVerticalTagCategory,
  saveHorizontalTagCategories,
  saveVerticalTags,
  saveHorizontalTags,
  saveHorizontalTag,
  saveVerticalTag,
  saveHorizontalTagsPlayers,
  saveVerticalTagsPlayers,
  savePlayerHorizontalTags,
  savePlayerVerticalTags,
  savePlayerTopTags,
  saveHorizontalTagCategory,
  changePlayerTopTags,
  savePlayerTagLogs,
  saveTagLogs,
} from 'actions/tag/tagActionCreator';
import NetworkService from 'services/networkService';
import { getError, getMessage } from 'utils/helpers';
import { AppConstants } from 'constants/index';
import TagActionType from 'actions/tag/tagActionType';
import { changeEndedStatus } from 'actions/template/templateActionCreator';
import { i18n } from 'services';
import { changePlayerActionEndedStatus } from 'actions/player/playerActionCreator';
import { downloadFile } from 'utils/utils';

function* getVerticalTagCategories({ payload }) {
  try {
    const { params } = payload;
    const options = {
      params,
      paramsSerializer: (param) => qs.stringify(param),
    };
    const { PlayersAdmin, TagCategories, Vertical } = AppConstants.api;
    yield put(changeLoadingState(true));
    const { data } = yield call(NetworkService.makeAPIGetRequest, [PlayersAdmin, TagCategories, Vertical], options);
    yield put(saveVerticalTagCategories(data));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* createVerticalTagCategory({ payload }) {
  try {
    const { data } = payload;
    const { name } = payload.data;
    const options = {
      data,
    };
    const { PlayersAdmin, TagCategories, Vertical } = AppConstants.api;
    yield put(changeLoadingState(true));
    yield call(NetworkService.makeAPIPostRequest, [PlayersAdmin, TagCategories, Vertical], options);
    yield put(setSuccessMessage(getMessage(i18n.t('lCategory'), i18n.t('notification:slCreated'), name)));
    yield put(changeLoadingState(false));
    yield put(changeEndedStatus(true));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* editVerticalTagCategory({ payload }) {
  try {
    const { id, data } = payload;
    const { name } = data;
    const options = {
      data,
    };
    const { PlayersAdmin, TagCategories, Vertical } = AppConstants.api;
    yield put(changeLoadingState(true));
    yield call(NetworkService.makeAPIPutRequest, [PlayersAdmin, TagCategories, Vertical, id], options);
    yield put(setSuccessMessage(getMessage(i18n.t('lCategory'), i18n.t('notification:slUpdated'), name)));
    yield put(changeLoadingState(false));
    yield put(changeEndedStatus(true));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getVerticalTagCategory({ payload }) {
  const { id, params, isLoading } = payload;
  try {
    const options = {
      params,
      paramsSerializer: (param) => qs.stringify(param),
    };
    const { PlayersAdmin, TagCategories, Vertical } = AppConstants.api;
    if (isLoading) {
      yield put(changeLoadingState(true));
    }
    const { data } = yield call(NetworkService.makeAPIGetRequest, [PlayersAdmin, TagCategories, Vertical, id], options);
    yield put(saveVerticalTagCategory(data));

    if (isLoading) {
      yield put(changeLoadingState(false));
    }
  } catch (err) {
    if (err) {
      const error = getError(err, true);
      yield put(setErrorMessage(error));
    }
    if (isLoading) {
      yield put(changeLoadingState(false));
    }
  }
}

function* deleteVerticalTagCategory({ payload }) {
  try {
    const { id, params } = payload;
    const { name, ...other } = params;
    const options = {
      params: other,
    };
    yield put(changeLoadingState(true));
    const { PlayersAdmin, TagCategories, Vertical } = AppConstants.api;
    yield call(NetworkService.makeAPIDeleteRequest, [PlayersAdmin, TagCategories, Vertical, id], options);
    yield put(changeEndedStatus(true));
    yield put(setSuccessMessage(getMessage(i18n.t('lCategory'), i18n.t('notification:slDeleted'), name)));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* changeVerticalTagCategoryStatus({ payload }) {
  try {
    const { id, params } = payload;
    const { name, ...other } = params;
    const options = {
      params: other,
    };
    yield put(changeLoadingState(true));
    const {
      PlayersAdmin, TagCategories, Vertical, Enable,
    } = AppConstants.api;
    yield call(NetworkService.makeAPIPutRequest, [PlayersAdmin, TagCategories, Vertical, id, Enable], options);
    yield put(changeEndedStatus(true));
    yield put(setSuccessMessage(getMessage(i18n.t('lCategory'), i18n.t(params.enable ? 'notification:slEnabled' : 'notification:slDisabled'), name)));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* changeVerticalTagCategoryPriority({ payload }) {
  try {
    const { id, params } = payload;
    const { name, ...other } = params;
    const options = {
      params: other,
    };
    yield put(changeLoadingState(true));
    const {
      PlayersAdmin, TagCategories, Vertical, Priority,
    } = AppConstants.api;
    yield call(NetworkService.makeAPIPutRequest, [PlayersAdmin, TagCategories, Vertical, id, Priority], options);
    yield put(changeEndedStatus(true));
    yield put(setSuccessMessage(getMessage(i18n.t('categories'), i18n.t('notification:plUpdated'), name)));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getHorizontalTagCategories({ payload }) {
  try {
    const { params } = payload;
    const options = {
      params,
      paramsSerializer: (param) => qs.stringify(param),
    };
    const { PlayersAdmin, TagCategories, Horizontal } = AppConstants.api;
    yield put(changeLoadingState(true));
    const { data } = yield call(NetworkService.makeAPIGetRequest, [PlayersAdmin, TagCategories, Horizontal], options);
    yield put(saveHorizontalTagCategories(data));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* createHorizontalTagCategory({ payload }) {
  try {
    const { data } = payload;
    const { name } = payload.data;
    const options = {
      data,
    };
    const { PlayersAdmin, TagCategories, Horizontal } = AppConstants.api;
    yield put(changeLoadingState(true));
    yield call(NetworkService.makeAPIPostRequest, [PlayersAdmin, TagCategories, Horizontal], options);
    yield put(setSuccessMessage(getMessage(i18n.t('lCategory'), i18n.t('notification:slCreated'), name)));
    yield put(changeLoadingState(false));
    yield put(changeEndedStatus(true));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* editHorizontalTagCategory({ payload }) {
  try {
    const { id, data } = payload;
    const { name } = data;
    const options = {
      data,
    };
    const { PlayersAdmin, TagCategories, Horizontal } = AppConstants.api;
    yield put(changeLoadingState(true));
    yield call(NetworkService.makeAPIPutRequest, [PlayersAdmin, TagCategories, Horizontal, id], options);
    yield put(setSuccessMessage(getMessage(i18n.t('lCategory'), i18n.t('notification:slUpdated'), name)));
    yield put(changeLoadingState(false));
    yield put(changeEndedStatus(true));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getHorizontalTagCategory({ payload }) {
  try {
    const { id, params } = payload;
    const options = {
      params,
      paramsSerializer: (param) => qs.stringify(param),
    };
    const { PlayersAdmin, TagCategories, Horizontal } = AppConstants.api;
    yield put(changeLoadingState(true));
    const { data } = yield call(NetworkService.makeAPIGetRequest, [PlayersAdmin, TagCategories, Horizontal, id], options);
    yield put(saveHorizontalTagCategory(data));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err, true);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* deleteHorizontalTagCategory({ payload }) {
  try {
    const { id, params } = payload;
    const { name, ...other } = params;
    const options = {
      params: other,
    };
    yield put(changeLoadingState(true));
    const { PlayersAdmin, TagCategories, Horizontal } = AppConstants.api;
    yield call(NetworkService.makeAPIDeleteRequest, [PlayersAdmin, TagCategories, Horizontal, id], options);
    yield put(changeEndedStatus(true));
    yield put(setSuccessMessage(getMessage(i18n.t('lCategory'), i18n.t('notification:slDeleted'), name)));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getVerticalTags({ payload }) {
  try {
    const { params } = payload;
    const options = {
      params,
      paramsSerializer: (param) => qs.stringify(param),
    };
    const { PlayersAdmin, Tags, Vertical } = AppConstants.api;
    yield put(changeLoadingState(true));
    const { data } = yield call(NetworkService.makeAPIGetRequest, [PlayersAdmin, Tags, Vertical], options);
    yield put(saveVerticalTags(data));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err, true);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getHorizontalTags({ payload }) {
  try {
    const { params } = payload;
    const options = {
      params,
      paramsSerializer: (param) => qs.stringify(param),
    };
    const { PlayersAdmin, Tags, Horizontal } = AppConstants.api;
    yield put(changeLoadingState(true));
    const { data } = yield call(NetworkService.makeAPIGetRequest, [PlayersAdmin, Tags, Horizontal], options);
    yield put(saveHorizontalTags(data));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err, true);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* createHorizontalTags({ payload }) {
  try {
    const { data } = payload;
    const { PlayersAdmin, Tags, Horizontal } = AppConstants.api;
    yield put(changeLoadingState(true));
    yield call(NetworkService.makeAPIPostRequest, [PlayersAdmin, Tags, Horizontal], { data });
    yield put(setSuccessMessage(getMessage(i18n.t(data.length === 1 ? 'tool:theTag' : 'tags'), i18n.t(data.length === 1 ? 'notification:slCreated' : 'notification:plCreated'))));
    yield put(changeLoadingState(false));
    yield put(changeEndedStatus(true));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* createVerticalTags({ payload }) {
  try {
    const { data } = payload;
    const { PlayersAdmin, Tags, Vertical } = AppConstants.api;
    yield put(changeLoadingState(true));
    yield call(NetworkService.makeAPIPostRequest, [PlayersAdmin, Tags, Vertical], { data });
    yield put(setSuccessMessage(getMessage(i18n.t(data.length === 1 ? 'tool:theTag' : 'tags'), i18n.t(data.length === 1 ? 'notification:slCreated' : 'notification:plCreated'))));
    yield put(changeLoadingState(false));
    yield put(changeEndedStatus(true));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* editVerticalTag({ payload }) {
  try {
    const { id, data } = payload;
    const { name } = data;
    const options = {
      data,
    };
    const { PlayersAdmin, Tags, Vertical } = AppConstants.api;
    yield put(changeLoadingState(true));
    yield call(NetworkService.makeAPIPutRequest, [PlayersAdmin, Tags, Vertical, id], options);
    yield put(setSuccessMessage(getMessage(i18n.t('lTag'), i18n.t('notification:slUpdated'), name)));
    yield put(changeLoadingState(false));
    yield put(changeEndedStatus(true));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* editHorizontalTag({ payload }) {
  try {
    const { id, data } = payload;
    const { name } = data;
    const options = {
      data,
    };
    const { PlayersAdmin, Tags, Horizontal } = AppConstants.api;
    yield put(changeLoadingState(true));
    yield call(NetworkService.makeAPIPutRequest, [PlayersAdmin, Tags, Horizontal, id], options);
    yield put(setSuccessMessage(getMessage(i18n.t('lTag'), i18n.t('notification:slUpdated'), name)));
    yield put(changeLoadingState(false));
    yield put(changeEndedStatus(true));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* deleteVerticalTags({ payload }) {
  try {
    const { name, data } = payload;
    const isSingle = data.tags?.length === 1;
    yield put(changeLoadingState(true));
    const { PlayersAdmin, Tags, Vertical } = AppConstants.api;
    yield call(NetworkService.makeAPIDeleteRequest, [PlayersAdmin, Tags, Vertical], {
      data,
    });
    yield put(changeEndedStatus(true));
    yield put(setSuccessMessage(getMessage(isSingle ? i18n.t('lTag') : i18n.t('tags'), i18n.t(isSingle ? 'notification:slDeleted' : 'notification:plDeleted'), isSingle ? name : '')));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* deleteHorizontalTags({ payload }) {
  try {
    const { name, data } = payload;
    const isSingle = data.tags?.length === 1;
    yield put(changeLoadingState(true));
    const { PlayersAdmin, Tags, Horizontal } = AppConstants.api;
    yield call(NetworkService.makeAPIDeleteRequest, [PlayersAdmin, Tags, Horizontal], {
      data,
    });
    yield put(changeEndedStatus(true));
    yield put(setSuccessMessage(getMessage(isSingle ? i18n.t('lTag') : i18n.t('tags'), i18n.t(isSingle ? 'notification:slDeleted' : 'notification:plDeleted'), isSingle ? name : '')));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* mergeVerticalTags({ payload }) {
  try {
    const { data } = payload;
    const options = {
      data,
    };
    const {
      PlayersAdmin, Tags, Vertical, Merge,
    } = AppConstants.api;
    yield put(changeLoadingState(true));
    yield call(NetworkService.makeAPIPutRequest, [PlayersAdmin, Tags, Vertical, Merge], options);
    yield put(setSuccessMessage(getMessage(i18n.t('tags'), i18n.t('notification:plMerged'))));
    yield put(changeLoadingState(false));
    yield put(changeEndedStatus(true));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* mergeHorizontalTags({ payload }) {
  try {
    const { data } = payload;
    const options = {
      data,
    };
    const {
      PlayersAdmin, Tags, Horizontal, Merge,
    } = AppConstants.api;
    yield put(changeLoadingState(true));
    yield call(NetworkService.makeAPIPutRequest, [PlayersAdmin, Tags, Horizontal, Merge], options);
    yield put(setSuccessMessage(getMessage(i18n.t('tags'), i18n.t('notification:plMerged'))));
    yield put(changeLoadingState(false));
    yield put(changeEndedStatus(true));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* moveHorizontalTags({ payload }) {
  try {
    const { data } = payload;
    const options = {
      data,
    };
    const {
      PlayersAdmin, Tags, Horizontal, Move,
    } = AppConstants.api;
    yield put(changeLoadingState(true));
    yield call(NetworkService.makeAPIPutRequest, [PlayersAdmin, Tags, Horizontal, Move], options);
    yield put(setSuccessMessage(getMessage(i18n.t('tags'), i18n.t('notification:plMoved'))));
    yield put(changeLoadingState(false));
    yield put(changeEndedStatus(true));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* changeVerticalTagStatus({ payload }) {
  try {
    const { id, params } = payload;
    const { name, ...other } = params;
    const options = {
      params: other,
    };
    yield put(changeLoadingState(true));
    const {
      PlayersAdmin, Tags, Vertical, Enable,
    } = AppConstants.api;
    yield call(NetworkService.makeAPIPutRequest, [PlayersAdmin, Tags, Vertical, id, Enable], options);
    yield put(changeEndedStatus(true));
    yield put(setSuccessMessage(getMessage(i18n.t('lTag'), i18n.t(params.enable ? 'notification:slEnabled' : 'notification:slDisabled'), name)));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getVerticalTag({ payload }) {
  const { id, params, isLoading } = payload;
  try {
    const options = {
      params,
    };
    const { PlayersAdmin, Tags, Vertical } = AppConstants.api;

    if (isLoading) {
      yield put(changeLoadingState(true));
    }

    const { data } = yield call(NetworkService.makeAPIGetRequest, [PlayersAdmin, Tags, Vertical, id], options);
    yield put(saveVerticalTag(data));

    if (isLoading) {
      yield put(changeLoadingState(false));
    }
  } catch (err) {
    if (err) {
      const error = getError(err, true);
      yield put(setErrorMessage(error));
    }
    if (isLoading) {
      yield put(changeLoadingState(false));
    }
  }
}

function* getHorizontalTag({ payload }) {
  try {
    const { id, params } = payload;
    const options = {
      params,
    };
    const { PlayersAdmin, Tags, Horizontal } = AppConstants.api;
    yield put(changeLoadingState(true));
    const { data } = yield call(NetworkService.makeAPIGetRequest, [PlayersAdmin, Tags, Horizontal, id], options);
    yield put(saveHorizontalTag(data));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err, true);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getHorizontalTagsPlayers({ payload }) {
  try {
    const { brandId, tagId, params } = payload;
    const options = {
      params,
      paramsSerializer: (param) => qs.stringify(param),
    };
    const {
      PlayersAdmin, Tags, Horizontal, Players,
    } = AppConstants.api;
    yield put(changeLoadingState(true));
    const { data } = yield call(NetworkService.makeAPIGetRequest, [PlayersAdmin, Tags, Horizontal, Players, brandId, tagId], options);
    yield put(saveHorizontalTagsPlayers(data));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err, true);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getVerticalTagsPlayers({ payload }) {
  try {
    const { brandId, tagId, params } = payload;
    const options = {
      params,
      paramsSerializer: (param) => qs.stringify(param),
    };
    const {
      PlayersAdmin, Tags, Vertical, Players,
    } = AppConstants.api;
    yield put(changeLoadingState(true));
    const { data } = yield call(NetworkService.makeAPIGetRequest, [PlayersAdmin, Tags, Vertical, Players, brandId, tagId], options);
    yield put(saveVerticalTagsPlayers(data));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err, true);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* deletePlayersHorizontalTags({ payload }) {
  try {
    const { data } = payload;
    const { playerIds } = data;
    yield put(changeLoadingState(true));
    const {
      PlayersAdmin, Tags, Horizontal, UnAssign,
    } = AppConstants.api;
    yield call(NetworkService.makeAPIDeleteRequest, [PlayersAdmin, Tags, Horizontal, UnAssign], {
      data,
    });
    yield put(changeEndedStatus(true));
    const message = getMessage(i18n.t(playerIds.length > 1 ? 'players' : 'thePlayer'), i18n.t(playerIds.length > 1 ? 'notification:plUntagged' : 'notification:slUntagged'));
    yield put(setSuccessMessage(message));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* deletePlayersVerticalTags({ payload }) {
  try {
    const { data } = payload;
    const { playerIds } = data;
    yield put(changeLoadingState(true));
    const {
      PlayersAdmin, Tags, Vertical, UnAssign,
    } = AppConstants.api;
    yield call(NetworkService.makeAPIDeleteRequest, [PlayersAdmin, Tags, Vertical, UnAssign], {
      data,
    });
    yield put(changeEndedStatus(true));
    const message = getMessage(i18n.t(playerIds.length > 1 ? 'players' : 'thePlayer'), i18n.t(playerIds.length > 1 ? 'notification:plUntagged' : 'notification:slUntagged'));
    yield put(setSuccessMessage(message));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getPlayerHorizontalTags({ payload }) {
  try {
    const { playerId, params } = payload;
    const options = {
      params,
      paramsSerializer: (param) => qs.stringify(param),
    };
    const {
      PlayersAdmin, Tags, Horizontal, ByPlayer,
    } = AppConstants.api;
    yield put(changeLoadingState(true));
    const { data } = yield call(NetworkService.makeAPIGetRequest, [PlayersAdmin, Tags, Horizontal, ByPlayer, playerId], options);
    yield put(savePlayerHorizontalTags(data));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err, true);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getPlayerVerticalTags({ payload }) {
  try {
    const { playerId, params } = payload;
    const options = {
      params,
      paramsSerializer: (param) => qs.stringify(param),
    };
    const {
      PlayersAdmin, Tags, Vertical, ByPlayer,
    } = AppConstants.api;
    yield put(changeLoadingState(true));
    const { data } = yield call(NetworkService.makeAPIGetRequest, [PlayersAdmin, Tags, Vertical, ByPlayer, playerId], options);
    yield put(savePlayerVerticalTags(data));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err, true);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* deletePlayerHorizontalTag({ payload }) {
  try {
    const { id, name, data } = payload;
    const options = {
      data,
    };
    yield put(changeLoadingState(true));
    const {
      PlayersAdmin, Tags, Horizontal, UnAssign, ByPlayer,
    } = AppConstants.api;
    yield call(NetworkService.makeAPIDeleteRequest, [PlayersAdmin, Tags, Horizontal, UnAssign, ByPlayer, id], options);
    yield put(changeEndedStatus(true));
    yield put(setSuccessMessage(getMessage(i18n.t('notification:tagDeletedFromPlayerAccount', { name }))));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* deletePlayerVerticalTag({ payload }) {
  try {
    const { id, name, data } = payload;
    const options = {
      data,
    };
    yield put(changeLoadingState(true));
    const {
      PlayersAdmin, Tags, Vertical, UnAssign, ByPlayer,
    } = AppConstants.api;
    yield call(NetworkService.makeAPIDeleteRequest, [PlayersAdmin, Tags, Vertical, UnAssign, ByPlayer, id], options);
    yield put(changeEndedStatus(true));
    yield put(setSuccessMessage(getMessage(i18n.t('notification:tagDeletedFromPlayerAccount', { name }))));
    yield put(changeLoadingState(false));
    yield put(changePlayerTopTags(true));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* assignVerticalTagsPlayers({ payload }) {
  try {
    const { data } = payload;
    const options = {
      data,
    };
    const isSingle = data.tagIds?.length === 1;
    const {
      PlayersAdmin, Tags, Vertical, Assign,
    } = AppConstants.api;
    yield put(changeLoadingState(true));
    yield call(NetworkService.makeAPIPostRequest, [PlayersAdmin, Tags, Vertical, Assign], options);
    yield put(setSuccessMessage(getMessage(i18n.t(isSingle ? 'tool:theTag' : 'tags'), i18n.t(isSingle ? 'notification:slAssigned' : 'notification:plAssigned'))));
    yield put(changeLoadingState(false));
    yield put(changeEndedStatus(true));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* assignHorizontalTagsPlayers({ payload }) {
  try {
    const { data } = payload;
    const options = {
      data,
    };
    const isSingle = data.tagIds?.length === 1;
    const {
      PlayersAdmin, Tags, Horizontal, Assign,
    } = AppConstants.api;
    yield put(changeLoadingState(true));
    yield call(NetworkService.makeAPIPostRequest, [PlayersAdmin, Tags, Horizontal, Assign], options);
    yield put(setSuccessMessage(getMessage(i18n.t(isSingle ? 'tool:theTag' : 'tags'), i18n.t(isSingle ? 'notification:slAssigned' : 'notification:plAssigned'))));
    yield put(changeLoadingState(false));
    yield put(changeEndedStatus(true));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* assignVerticalTagPlayer({ payload }) {
  try {
    const {
      brandId, playerId, data, isFromActions,
    } = payload;
    const options = {
      data,
    };
    const isSingle = data.tagIds?.length === 1;
    const {
      PlayersAdmin, Tags, Vertical, Assign,
    } = AppConstants.api;
    yield put(changeLoadingState(true));
    yield call(NetworkService.makeAPIPostRequest, [PlayersAdmin, Tags, Vertical, Assign, brandId, playerId], options);
    yield put(setSuccessMessage(getMessage(i18n.t(isSingle ? 'tool:theTag' : 'tags'), i18n.t(isSingle ? 'notification:slAssigned' : 'notification:plAssigned'))));
    yield put(changeLoadingState(false));
    yield put(changePlayerTopTags(true));
    if (isFromActions) {
      yield put(changePlayerActionEndedStatus(true));
    } else {
      yield put(changeEndedStatus(true));
    }
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* assignHorizontalTagsPlayer({ payload }) {
  try {
    const {
      brandId, playerId, data, isFromActions,
    } = payload;
    const options = {
      data,
    };
    const isSingle = data.tagIds?.length === 1;
    const {
      PlayersAdmin, Tags, Horizontal, Assign,
    } = AppConstants.api;
    yield put(changeLoadingState(true));
    yield call(NetworkService.makeAPIPostRequest, [PlayersAdmin, Tags, Horizontal, Assign, brandId, playerId], options);
    yield put(setSuccessMessage(getMessage(i18n.t(isSingle ? 'tool:theTag' : 'tags'), i18n.t(isSingle ? 'notification:slAssigned' : 'notification:plAssigned'))));
    yield put(changeLoadingState(false));
    if (isFromActions) {
      yield put(changePlayerActionEndedStatus(true));
    } else {
      yield put(changeEndedStatus(true));
    }
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* createHorizontalTagsAndAssignPlayer({ payload }) {
  try {
    const { data } = payload;
    const options = {
      data,
    };
    const isSingle = data.tagIds?.length === 1;
    const { PlayersAdmin, Tags, Add } = AppConstants.api;
    yield put(changeLoadingState(true));
    yield call(NetworkService.makeAPIPostRequest, [PlayersAdmin, Tags, Add], options);
    yield put(setSuccessMessage(getMessage(i18n.t(isSingle ? 'tool:theTag' : 'tags'), i18n.t(isSingle ? 'notification:slAssigned' : 'notification:plAssigned'))));
    yield put(changeLoadingState(false));
    yield put(changeEndedStatus(true));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getPlayerTopTags({ payload }) {
  const { isLoading, playerId } = payload;
  try {
    const { PlayersAdmin, Tags, Top } = AppConstants.api;
    if (isLoading) {
      yield put(changeLoadingState(true));
    }

    const { data } = yield call(NetworkService.makeAPIGetRequest, [PlayersAdmin, Tags, Top, playerId]);
    yield put(savePlayerTopTags(data));

    if (isLoading) {
      yield put(changeLoadingState(false));
    }
  } catch (err) {
    if (err) {
      const error = getError(err, true);
      yield put(setErrorMessage(error));
    }
    if (isLoading) {
      yield put(changeLoadingState(false));
    }
  }
}

function* exportHorizontalTagsPlayers({ payload }) {
  try {
    const { tagId, params } = payload;
    const options = {
      params,
      paramsSerializer: (param) => qs.stringify(param, { allowDots: true }),
      responseType: 'blob',
    };
    yield put(changeLoadingState(true));
    const {
      PlayersAdmin, Tags, Players, Export, Horizontal,
    } = AppConstants.api;
    const { data, headers } = yield call(NetworkService.makeAPIGetRequest, [PlayersAdmin, Tags, Horizontal, tagId, Players, Export], options);
    downloadFile(data, i18n.t('tool:tagsPlayers'), mime.detectExtension(headers['content-type']));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* exportVerticalTagsPlayers({ payload }) {
  try {
    const { tagId, params } = payload;
    const options = {
      params,
      paramsSerializer: (param) => qs.stringify(param, { allowDots: true }),
      responseType: 'blob',
    };
    yield put(changeLoadingState(true));
    const {
      PlayersAdmin, Tags, Players, Export, Vertical,
    } = AppConstants.api;
    const { data, headers } = yield call(NetworkService.makeAPIGetRequest, [PlayersAdmin, Tags, Vertical, tagId, Players, Export], options);
    downloadFile(data, i18n.t('tool:tagsPlayers'), mime.detectExtension(headers['content-type']));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getPlayerTagLogs({ payload }) {
  const { playerId, params } = payload;
  const options = {
    params,
    paramsSerializer: (param) => qs.stringify(param),
  };
  try {
    const { PlayersAdmin, Tags, Log } = AppConstants.api;
    yield put(changeLoadingState(true));

    const { data } = yield call(NetworkService.makeAPIGetRequest, [PlayersAdmin, Tags, Log, playerId], options);
    yield put(savePlayerTagLogs(data));

    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err, true);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* exportPlayerTagLogs({ payload }) {
  try {
    const { playerId, params } = payload;
    const options = {
      params,
      paramsSerializer: (param) => qs.stringify(param, { allowDots: true }),
      responseType: 'blob',
    };
    yield put(changeLoadingState(true));
    const {
      PlayersAdmin, Tags, Log, Export,
    } = AppConstants.api;
    const { data, headers } = yield call(NetworkService.makeAPIGetRequest, [PlayersAdmin, Tags, Log, playerId, Export], options);
    downloadFile(data, i18n.t('tagLogs'), mime.detectExtension(headers['content-type']));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getTagLogs({ payload }) {
  const { params } = payload;
  const options = {
    params,
  };
  try {
    const { PlayersAdmin, Tags, Log } = AppConstants.api;
    yield put(changeLoadingState(true));

    const { data } = yield call(NetworkService.makeAPIGetRequest, [PlayersAdmin, Tags, Log], options);
    yield put(saveTagLogs(data));

    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err, true);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* exportTagLogs({ payload }) {
  try {
    const { params } = payload;
    const options = {
      params,
      paramsSerializer: (param) => qs.stringify(param, { allowDots: true }),
      responseType: 'blob',
    };
    yield put(changeLoadingState(true));
    const {
      PlayersAdmin, Tags, Log, Export,
    } = AppConstants.api;
    const { data, headers } = yield call(NetworkService.makeAPIGetRequest, [PlayersAdmin, Tags, Log, Export], options);
    downloadFile(data, i18n.t('tagLogs'), mime.detectExtension(headers['content-type']));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

export default function* tagSaga() {
  yield all([
    takeLatest(TagActionType.GET_VERTICAL_TAG_CATEGORIES, getVerticalTagCategories),
    takeLatest(TagActionType.CREATE_VERTICAL_TAG_CATEGORY, createVerticalTagCategory),
    takeLatest(TagActionType.EDIT_VERTICAL_TAG_CATEGORY, editVerticalTagCategory),
    takeLatest(TagActionType.GET_VERTICAL_TAG_CATEGORY, getVerticalTagCategory),
    takeLatest(TagActionType.DELETE_VERTICAL_TAG_CATEGORY, deleteVerticalTagCategory),
    takeLatest(TagActionType.CHANGE_VERTICAL_TAG_CATEGORY_STATUS, changeVerticalTagCategoryStatus),
    takeLatest(TagActionType.CHANGE_VERTICAL_TAG_CATEGORY_PRIORITY, changeVerticalTagCategoryPriority),
    takeLatest(TagActionType.GET_HORIZONTAL_TAG_CATEGORIES, getHorizontalTagCategories),
    takeLatest(TagActionType.CREATE_HORIZONTAL_TAG_CATEGORY, createHorizontalTagCategory),
    takeLatest(TagActionType.EDIT_HORIZONTAL_TAG_CATEGORY, editHorizontalTagCategory),
    takeLatest(TagActionType.GET_HORIZONTAL_TAG_CATEGORY, getHorizontalTagCategory),
    takeLatest(TagActionType.DELETE_HORIZONTAL_TAG_CATEGORY, deleteHorizontalTagCategory),
    takeLatest(TagActionType.GET_VERTICAL_TAGS, getVerticalTags),
    takeLatest(TagActionType.GET_HORIZONTAL_TAGS, getHorizontalTags),
    takeLatest(TagActionType.CREATE_HORIZONTAL_TAGS, createHorizontalTags),
    takeLatest(TagActionType.CREATE_VERTICAL_TAGS, createVerticalTags),
    takeLatest(TagActionType.EDIT_HORIZONTAL_TAG, editHorizontalTag),
    takeLatest(TagActionType.EDIT_VERTICAL_TAG, editVerticalTag),
    takeLatest(TagActionType.DELETE_HORIZONTAL_TAGS, deleteHorizontalTags),
    takeLatest(TagActionType.DELETE_VERTICAL_TAGS, deleteVerticalTags),
    takeLatest(TagActionType.MERGE_HORIZONTAL_TAGS, mergeHorizontalTags),
    takeLatest(TagActionType.MERGE_VERTICAL_TAGS, mergeVerticalTags),
    takeLatest(TagActionType.MOVE_HORIZONTAL_TAGS, moveHorizontalTags),
    takeLatest(TagActionType.CHANGE_VERTICAL_TAG_STATUS, changeVerticalTagStatus),
    takeLatest(TagActionType.GET_HORIZONTAL_TAG, getHorizontalTag),
    takeLatest(TagActionType.GET_VERTICAL_TAG, getVerticalTag),
    takeLatest(TagActionType.ASSIGN_HORIZONTAL_TAGS_PLAYERS, assignHorizontalTagsPlayers),
    takeLatest(TagActionType.ASSIGN_VERTICAL_TAGS_PLAYERS, assignVerticalTagsPlayers),
    takeLatest(TagActionType.GET_HORIZONTAL_TAGS_PLAYERS, getHorizontalTagsPlayers),
    takeLatest(TagActionType.GET_VERTICAL_TAGS_PLAYERS, getVerticalTagsPlayers),
    takeLatest(TagActionType.DELETE_PLAYERS_HORIZONTAL_TAGS, deletePlayersHorizontalTags),
    takeLatest(TagActionType.DELETE_PLAYERS_VERTICAL_TAGS, deletePlayersVerticalTags),
    takeLatest(TagActionType.GET_PLAYER_VERTICAL_TAGS, getPlayerVerticalTags),
    takeLatest(TagActionType.GET_PLAYER_HORIZONTAL_TAGS, getPlayerHorizontalTags),
    takeLatest(TagActionType.DELETE_PLAYER_HORIZONTAL_TAG, deletePlayerHorizontalTag),
    takeLatest(TagActionType.DELETE_PLAYER_VERTICAL_TAG, deletePlayerVerticalTag),
    takeLatest(TagActionType.ASSIGN_HORIZONTAL_TAGS_PLAYER, assignHorizontalTagsPlayer),
    takeLatest(TagActionType.ASSIGN_VERTICAL_TAG_PLAYER, assignVerticalTagPlayer),
    takeLatest(TagActionType.CREATE_HORIZONTAL_TAGS_AND_ASSIGN_PLAYER, createHorizontalTagsAndAssignPlayer),
    takeLatest(TagActionType.GET_PLAYER_TOP_TAGS, getPlayerTopTags),
    takeLatest(TagActionType.EXPORT_VERTICAL_TAGS_PLAYERS, exportVerticalTagsPlayers),
    takeLatest(TagActionType.EXPORT_HORIZONTAL_TAGS_PLAYERS, exportHorizontalTagsPlayers),
    takeLatest(TagActionType.GET_PLAYER_TAG_LOGS, getPlayerTagLogs),
    takeLatest(TagActionType.EXPORT_PLAYER_TAG_LOGS, exportPlayerTagLogs),
    takeLatest(TagActionType.GET_TAG_LOGS, getTagLogs),
    takeLatest(TagActionType.EXPORT_TAG_LOGS, exportTagLogs),
  ]);
}
