import AuthActionType from './authActionType';

export const login = (data) => ({
  type: AuthActionType.LOGIN,
  payload: { data },
});

export const setAuthenticated = (status) => ({
  type: AuthActionType.SET_AUTHENTICATED,
  payload: { status },
});

export const getAuthUser = (data) => ({
  type: AuthActionType.GET_AUTH_USER,
  payload: { data },
});

export const saveAuthUser = (data) => ({
  type: AuthActionType.SAVE_AUTH_USER,
  payload: { data },
});

export const forgotPassword = (data) => ({
  type: AuthActionType.FORGOT_PASSWORD,
  payload: { data },
});

export const resetPassword = (data, text) => ({
  type: AuthActionType.RESET_PASSWORD,
  payload: { data, text },
});

export const resendTFA = (data) => ({
  type: AuthActionType.RESEND_TFA,
  payload: { data },
});

export const getUserSuccess = (data) => ({
  type: AuthActionType.GET_USER_SUCCESS,
  payload: { data },
});

export const changePassword = (data) => ({
  type: AuthActionType.CHANGE_PASSWORD,
  payload: { data },
});

export const changeExpiredPassword = (data) => ({
  type: AuthActionType.CHANGE_EXPIRED_PASSWORD,
  payload: { data },
});

export const changeTFAState = (state) => ({
  type: AuthActionType.CHANGE_TFA_STATE,
  payload: { state },
});

export const getAuthUserPartnerAccesses = (includeMainPartner) => ({
  type: AuthActionType.GET_AUTH_USER_PARTNER_ACCESSES,
  payload: { includeMainPartner },
});

export const getAuthUserPartnerAccessesSuccess = (data) => ({
  type: AuthActionType.GET_AUTH_USER_PARTNER_ACCESSES_SUCCESS,
  payload: { data },
});

export const changePasswordExpiredState = (state) => ({
  type: AuthActionType.CHANGE_PASSWORD_EXPIRED_STATE,
  payload: { state },
});

export const changeTokenState = (state) => ({
  type: AuthActionType.CHANGE_TOKEN_STATE,
  payload: { state },
});
