import qs from 'qs';
import {
  put, all, call, takeLatest,
} from 'redux-saga/effects';
import mime from 'mimetypes';
import { NetworkService, i18n } from 'services';
import {
  changeEndedStatus, changeGenerateReportEndedStatus, changeLoadingState, setErrorMessage, setSuccessMessage,
} from 'actions/setting/settingActionCreator';
import {
  saveDeposits,
  saveWithdrawals,
  savePlayerDeposits,
  savePlayerWithdrawals,
  saveDepositTags,
  saveWithdrawalTags,
  saveAssignedTagCategories,
  saveAssignedTags,
  savePaymentNotes,
  savePaymentDetails,
  savePaymentLogs,
  saveTransactionPlayerInfo,
} from 'actions/payment/paymentActionCreator';
import PaymentActionType from 'actions/payment/paymentActionType';
import { downloadFile } from 'utils/utils';
import { changePlayerDataUpdatedStatus } from 'actions/player/playerActionCreator';
import { changePlayerWalletsInfoUpdatedStatus } from 'actions/playerWallet/playerWalletActionCreator';
import Config from 'config';
import { AppConstants } from 'constants/index';
import { getError, getMessage } from 'utils/helpers';

const { isBrRegulationAudit, brRegulationSpecialActionsBaseURL } = Config;

function* getDeposits({ payload }) {
  try {
    const { params } = payload;
    const options = {
      params,
      paramsSerializer: (param) => qs.stringify(param),
    };
    const { ReportingAdmin, PaymentReport, Deposits } = AppConstants.api;
    yield put(changeLoadingState(true));
    const { data } = yield call(NetworkService.makeAPIGetRequest, [ReportingAdmin, PaymentReport, Deposits], options);
    yield put(saveDeposits(data));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getWithdrawals({ payload }) {
  try {
    const { params } = payload;
    const options = {
      params,
      paramsSerializer: (param) => qs.stringify(param),
    };
    const { ReportingAdmin, PaymentReport, Withdrawals } = AppConstants.api;
    yield put(changeLoadingState(true));
    const { data } = yield call(NetworkService.makeAPIGetRequest, [ReportingAdmin, PaymentReport, Withdrawals], options);
    yield put(saveWithdrawals(data));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* addDepositNote({ payload }) {
  try {
    const options = {
      data: payload.data,
      params: payload.params,
    };
    const { PaymentAdmin, Note, Deposit } = AppConstants.api;
    yield put(changeLoadingState(true));
    yield call(NetworkService.makeAPIPostRequest, [PaymentAdmin, Note, Deposit], options);
    yield put(changeEndedStatus(true));
    yield put(changeLoadingState(false));
    yield put(setSuccessMessage(getMessage(i18n.t('note'), i18n.t('notification:slAdded'))));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* addWithdrawalNote({ payload }) {
  try {
    const options = {
      data: payload.data,
      params: payload.params,
    };
    const { PaymentAdmin, Note, Withdrawal } = AppConstants.api;
    yield put(changeLoadingState(true));
    yield call(NetworkService.makeAPIPostRequest, [PaymentAdmin, Note, Withdrawal], options);
    yield put(changeEndedStatus(true));
    yield put(changeLoadingState(false));
    yield put(setSuccessMessage(getMessage(i18n.t('note'), i18n.t('notification:slAdded'))));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getWithdrawalNotes({ payload }) {
  try {
    const { id, params } = payload;
    const options = {
      params,
    };
    const { PaymentAdmin, Note, Withdrawal } = AppConstants.api;
    yield put(changeLoadingState(true));
    const { data } = yield call(NetworkService.makeAPIGetRequest, [PaymentAdmin, Note, Withdrawal, id], options);
    yield put(savePaymentNotes(id, data));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getDepositNotes({ payload }) {
  try {
    const { id, params } = payload;
    const options = {
      params,
    };
    const { PaymentAdmin, Note, Deposit } = AppConstants.api;
    yield put(changeLoadingState(true));
    const { data } = yield call(NetworkService.makeAPIGetRequest, [PaymentAdmin, Note, Deposit, id], options);
    yield put(savePaymentNotes(id, data));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getDepositDetails({ payload }) {
  try {
    const { params, id } = payload;
    const options = {
      params,
    };
    const {
      ReportingAdmin, PaymentReport, Deposit, Details,
    } = AppConstants.api;
    yield put(changeLoadingState(true));
    const { data } = yield call(NetworkService.makeAPIGetRequest, [ReportingAdmin, PaymentReport, Deposit, Details, id], options);
    yield put(savePaymentDetails(id, data));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getWithdrawalDetails({ payload }) {
  try {
    const { params, id } = payload;
    const options = {
      params,
    };
    const {
      ReportingAdmin, PaymentReport, Withdrawal, Details,
    } = AppConstants.api;
    yield put(changeLoadingState(true));
    const { data } = yield call(NetworkService.makeAPIGetRequest, [ReportingAdmin, PaymentReport, Withdrawal, Details, id], options);
    yield put(savePaymentDetails(id, data));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getDepositLogs({ payload }) {
  try {
    const { id, params } = payload;
    const options = {
      params,
    };
    const { PaymentAdmin, PaymentRequest, Deposit } = AppConstants.api;
    yield put(changeLoadingState(true));
    const { data } = yield call(NetworkService.makeAPIGetRequest, [PaymentAdmin, PaymentRequest, Deposit, id], options);
    yield put(savePaymentLogs(id, data));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getWithdrawalLogs({ payload }) {
  try {
    const { id, params } = payload;
    const options = {
      params,
    };
    const { PaymentAdmin, PaymentRequest, Withdrawal } = AppConstants.api;
    yield put(changeLoadingState(true));
    const { data } = yield call(NetworkService.makeAPIGetRequest, [PaymentAdmin, PaymentRequest, Withdrawal, id], options);
    yield put(savePaymentLogs(id, data));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* changeWithdrawalStatusToInProcess({ payload }) {
  try {
    const options = {
      data: payload.data,
      params: payload.params,
    };
    const { PaymentAdmin, PaymentRequest, WithdrawalInProcess } = AppConstants.api;
    yield put(changeLoadingState(true));
    yield call(NetworkService.makeAPIPostRequest, [PaymentAdmin, PaymentRequest, WithdrawalInProcess], options);
    yield put(changeEndedStatus(true));
    yield put(changeLoadingState(false));
    yield put(setSuccessMessage(getMessage(i18n.t('transaction'), i18n.t('notification:slUpdated'))));
    if (payload.isPlayer) {
      yield put(changePlayerDataUpdatedStatus(true));
      yield put(changePlayerWalletsInfoUpdatedStatus(true));
    }
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* changeWithdrawalStatusToApproved({ payload }) {
  try {
    const options = {
      data: payload.data,
      params: payload.params,
      ...{ baseURL: isBrRegulationAudit && brRegulationSpecialActionsBaseURL },
    };
    const { PaymentAdmin, PaymentRequest, WithdrawalToApproved } = AppConstants.api;
    yield put(changeLoadingState(true));
    yield call(NetworkService.makeAPIPostRequest, [PaymentAdmin, PaymentRequest, WithdrawalToApproved], options);
    yield put(changeEndedStatus(true));
    yield put(changeLoadingState(false));
    yield put(setSuccessMessage(getMessage(i18n.t('transaction'), i18n.t('notification:slUpdated'))));
    if (payload.isPlayer) {
      yield put(changePlayerDataUpdatedStatus(true));
      yield put(changePlayerWalletsInfoUpdatedStatus(true));
    }
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* changeWithdrawalStatusToFrozen({ payload }) {
  try {
    const options = {
      data: payload.data,
      params: payload.params,
    };
    const { PaymentAdmin, PaymentRequest, WithdrawalToFrozen } = AppConstants.api;
    yield put(changeLoadingState(true));
    yield call(NetworkService.makeAPIPostRequest, [PaymentAdmin, PaymentRequest, WithdrawalToFrozen], options);
    yield put(changeEndedStatus(true));
    yield put(changeLoadingState(false));
    yield put(setSuccessMessage(getMessage(i18n.t('transaction'), i18n.t('notification:slUpdated'))));
    if (payload.isPlayer) {
      yield put(changePlayerDataUpdatedStatus(true));
      yield put(changePlayerWalletsInfoUpdatedStatus(true));
    }
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* changeWithdrawalStatusToCancelled({ payload }) {
  try {
    const options = {
      data: payload.data,
      params: payload.params,
    };
    const { PaymentAdmin, PaymentRequest, WithdrawalToCancelled } = AppConstants.api;
    yield put(changeLoadingState(true));
    yield call(NetworkService.makeAPIPostRequest, [PaymentAdmin, PaymentRequest, WithdrawalToCancelled], options);
    yield put(changeEndedStatus(true));
    yield put(changeLoadingState(false));
    yield put(setSuccessMessage(getMessage(i18n.t('transaction'), i18n.t('notification:slUpdated'))));
    if (payload.isPlayer) {
      yield put(changePlayerDataUpdatedStatus(true));
      yield put(changePlayerWalletsInfoUpdatedStatus(true));
    }
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* changeOfflineDepositStatus({ payload }) {
  try {
    const options = {
      params: payload.params,
      data: payload.data,
    };
    const { PaymentAdmin, PaymentRequest, OfflineDepositStatusChange } = AppConstants.api;
    yield put(changeLoadingState(true));
    yield call(NetworkService.makeAPIPostRequest, [PaymentAdmin, PaymentRequest, OfflineDepositStatusChange], options);
    yield put(changeEndedStatus(true));
    yield put(changeLoadingState(false));
    yield put(setSuccessMessage(getMessage(i18n.t('transaction'), i18n.t('notification:slUpdated'))));
    if (payload.isPlayer) {
      yield put(changePlayerDataUpdatedStatus(true));
      yield put(changePlayerWalletsInfoUpdatedStatus(true));
    }
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* generateDepositsReport({ payload }) {
  try {
    const options = {
      params: payload.data,
      paramsSerializer: (params) => qs.stringify(params, { allowDots: true }),
    };
    yield put(changeLoadingState(true));
    const {
      ReportingAdmin, PaymentReport, Deposits, Generate,
    } = AppConstants.api;
    yield call(NetworkService.makeAPIGetRequest, [ReportingAdmin, PaymentReport, Deposits, Generate], options);
    yield put(changeGenerateReportEndedStatus(true));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* generateWithdrawalsReport({ payload }) {
  try {
    const options = {
      params: payload.data,
      paramsSerializer: (params) => qs.stringify(params, { allowDots: true }),
    };
    yield put(changeLoadingState(true));
    const {
      ReportingAdmin, PaymentReport, Withdrawals, Generate,
    } = AppConstants.api;
    yield call(NetworkService.makeAPIGetRequest, [ReportingAdmin, PaymentReport, Withdrawals, Generate], options);
    yield put(changeGenerateReportEndedStatus(true));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* changeOfflineWithdrawalStatusToInProcess({ payload }) {
  try {
    const options = {
      data: payload.data,
      params: payload.params,
    };
    const { PaymentAdmin, PaymentRequest, OfflineWithdrawalToProcess } = AppConstants.api;
    yield put(changeLoadingState(true));
    yield call(NetworkService.makeAPIPostRequest, [PaymentAdmin, PaymentRequest, OfflineWithdrawalToProcess], options);
    yield put(changeEndedStatus(true));
    yield put(changeLoadingState(false));
    yield put(setSuccessMessage(getMessage(i18n.t('transaction'), i18n.t('notification:slUpdated'))));
    if (payload.isPlayer) {
      yield put(changePlayerDataUpdatedStatus(true));
      yield put(changePlayerWalletsInfoUpdatedStatus(true));
    }
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* changeOfflineWithdrawalStatusToApproved({ payload }) {
  try {
    const options = {
      data: payload.data,
      params: payload.params,
      ...{ baseURL: isBrRegulationAudit && brRegulationSpecialActionsBaseURL },
    };
    const { PaymentAdmin, PaymentRequest, OfflineWithdrawalToApproved } = AppConstants.api;
    yield put(changeLoadingState(true));
    yield call(NetworkService.makeAPIPostRequest, [PaymentAdmin, PaymentRequest, OfflineWithdrawalToApproved], options);
    yield put(changeEndedStatus(true));
    yield put(changeLoadingState(false));
    yield put(setSuccessMessage(getMessage(i18n.t('transaction'), i18n.t('notification:slUpdated'))));
    if (payload.isPlayer) {
      yield put(changePlayerDataUpdatedStatus(true));
      yield put(changePlayerWalletsInfoUpdatedStatus(true));
    }
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* changeOfflineWithdrawalStatusToDeclined({ payload }) {
  try {
    const options = {
      data: payload.data,
      params: payload.params,
    };
    const { PaymentAdmin, PaymentRequest, OfflineWithdrawalToDeclined } = AppConstants.api;
    yield put(changeLoadingState(true));
    yield call(NetworkService.makeAPIPostRequest, [PaymentAdmin, PaymentRequest, OfflineWithdrawalToDeclined], options);
    yield put(changeEndedStatus(true));
    yield put(changeLoadingState(false));
    yield put(setSuccessMessage(getMessage(i18n.t('transaction'), i18n.t('notification:slUpdated'))));
    if (payload.isPlayer) {
      yield put(changePlayerDataUpdatedStatus(true));
      yield put(changePlayerWalletsInfoUpdatedStatus(true));
    }
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* changeWithdrawalStatusToDeclined({ payload }) {
  try {
    const options = {
      data: payload.data,
      params: payload.params,
    };
    const { PaymentAdmin, PaymentRequest, WithdrawalToDeclined } = AppConstants.api;
    yield put(changeLoadingState(true));
    yield call(NetworkService.makeAPIPostRequest, [PaymentAdmin, PaymentRequest, WithdrawalToDeclined], options);
    yield put(changeEndedStatus(true));
    yield put(changeLoadingState(false));
    yield put(setSuccessMessage(getMessage(i18n.t('transaction'), i18n.t('notification:slUpdated'))));
    if (payload.isPlayer) {
      yield put(changePlayerDataUpdatedStatus(true));
      yield put(changePlayerWalletsInfoUpdatedStatus(true));
    }
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getTransactionPlayerInfo({ payload }) {
  try {
    const { params } = payload;
    const options = {
      params,
    };
    const {
      ReportingAdmin, PaymentReport, Transaction, PlayerInfo,
    } = AppConstants.api;
    yield put(changeLoadingState(true));
    const { data } = yield call(NetworkService.makeAPIGetRequest, [ReportingAdmin, PaymentReport, Transaction, PlayerInfo], options);
    yield put(saveTransactionPlayerInfo(params.id, data));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getPlayerDeposits({ payload }) {
  try {
    const { id, params } = payload;
    const options = {
      params,
      paramsSerializer: (param) => qs.stringify(param),
    };
    const { ReportingAdmin, Player, Deposits } = AppConstants.api;
    yield put(changeLoadingState(true));
    const { data } = yield call(NetworkService.makeAPIGetRequest, [ReportingAdmin, Player, id, Deposits], options);
    yield put(savePlayerDeposits(data));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* exportPlayerDeposits({ payload }) {
  try {
    const { id, params } = payload;
    const options = {
      params,
      paramsSerializer: (param) => qs.stringify(param, { allowDots: true }),
      responseType: 'blob',
    };
    yield put(changeLoadingState(true));
    const {
      ReportingAdmin, Player, Deposits, Export,
    } = AppConstants.api;
    const { data, headers } = yield call(NetworkService.makeAPIGetRequest, [ReportingAdmin, Player, id, Deposits, Export], options);
    downloadFile(data, i18n.t('deposits'), mime.detectExtension(headers['content-type']));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getPlayerWithdrawals({ payload }) {
  try {
    const { id, params } = payload;
    const options = {
      params,
      paramsSerializer: (param) => qs.stringify(param),
    };
    const { ReportingAdmin, Player, Withdrawals } = AppConstants.api;
    yield put(changeLoadingState(true));
    const { data } = yield call(NetworkService.makeAPIGetRequest, [ReportingAdmin, Player, id, Withdrawals], options);
    yield put(savePlayerWithdrawals(data));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* exportPlayerWithdrawals({ payload }) {
  try {
    const { id, params } = payload;
    const options = {
      params,
      paramsSerializer: (param) => qs.stringify(param, { allowDots: true }),
      responseType: 'blob',
    };
    yield put(changeLoadingState(true));
    const {
      ReportingAdmin, Player, Withdrawals, Export,
    } = AppConstants.api;
    const { data, headers } = yield call(NetworkService.makeAPIGetRequest, [ReportingAdmin, Player, id, Withdrawals, Export], options);
    downloadFile(data, i18n.t('withdrawals'), mime.detectExtension(headers['content-type']));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getDepositTags({ payload }) {
  try {
    const { PaymentAdmin, Tag, Deposit } = AppConstants.api;
    yield put(changeLoadingState(true));
    const { data } = yield call(NetworkService.makeAPIGetRequest, [PaymentAdmin, Tag, Deposit, payload.id]);
    yield put(saveDepositTags(data));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* assignDepositTags({ payload }) {
  try {
    const { id, data } = payload;
    const options = {
      data,
    };
    const { PaymentAdmin, Tag, Deposit } = AppConstants.api;
    yield put(changeLoadingState(true));
    yield call(NetworkService.makeAPIPostRequest, [PaymentAdmin, Tag, Deposit, id], options);
    yield put(changeEndedStatus(true));
    yield put(changeLoadingState(false));
    yield put(setSuccessMessage(getMessage(i18n.t('tag'), i18n.t('notification:assigned'))));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* exportDepositTags({ payload }) {
  try {
    const { id, columns } = payload;
    const options = {
      params: { columns },
      paramsSerializer: (param) => qs.stringify(param, { allowDots: true }),
      responseType: 'blob',
    };
    yield put(changeLoadingState(true));
    const {
      PaymentAdmin, Tag, Deposit, Export,
    } = AppConstants.api;
    const { data, headers } = yield call(NetworkService.makeAPIGetRequest, [PaymentAdmin, Tag, Deposit, id, Export], options);
    downloadFile(data, i18n.t('depositTags'), mime.detectExtension(headers['content-type']));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getWithdrawalTags({ payload }) {
  try {
    const { PaymentAdmin, Tag, Withdrawal } = AppConstants.api;
    yield put(changeLoadingState(true));
    const { data } = yield call(NetworkService.makeAPIGetRequest, [PaymentAdmin, Tag, Withdrawal, payload.id]);
    yield put(saveWithdrawalTags(data));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* assignWithdrawalTags({ payload }) {
  try {
    const { id, data } = payload;
    const options = {
      data,
    };
    const { PaymentAdmin, Tag, Withdrawal } = AppConstants.api;
    yield put(changeLoadingState(true));
    yield call(NetworkService.makeAPIPostRequest, [PaymentAdmin, Tag, Withdrawal, id], options);
    yield put(changeEndedStatus(true));
    yield put(changeLoadingState(false));
    yield put(setSuccessMessage(getMessage(i18n.t('tag'), i18n.t('notification:assigned'))));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* exportWithdrawalTags({ payload }) {
  try {
    const { id, columns } = payload;
    const options = {
      params: { columns },
      paramsSerializer: (param) => qs.stringify(param, { allowDots: true }),
      responseType: 'blob',
    };
    yield put(changeLoadingState(true));
    const {
      PaymentAdmin, Tag, Withdrawal, Export,
    } = AppConstants.api;
    const { data, headers } = yield call(NetworkService.makeAPIGetRequest, [PaymentAdmin, Tag, Withdrawal, id, Export], options);
    downloadFile(data, i18n.t('withdrawalTags'), mime.detectExtension(headers['content-type']));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getAssignedTagCategories({ payload }) {
  try {
    const { params } = payload;
    const options = {
      params,
    };
    const { PaymentAdmin, Tag, AssignedTagCategories } = AppConstants.api;
    yield put(changeLoadingState(true));
    const { data } = yield call(NetworkService.makeAPIGetRequest, [PaymentAdmin, Tag, AssignedTagCategories], options);
    yield put(saveAssignedTagCategories(data));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getAssignedTags({ payload }) {
  try {
    const { params } = payload;
    const options = {
      params,
    };
    const { PaymentAdmin, Tag, AssignedTags } = AppConstants.api;
    yield put(changeLoadingState(true));
    const { data } = yield call(NetworkService.makeAPIGetRequest, [PaymentAdmin, Tag, AssignedTags], options);
    yield put(saveAssignedTags(data));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* changeBulkWithdrawalStatus({ payload }) {
  try {
    const { PaymentAdmin, PaymentRequest, StatusChange } = AppConstants.api;
    yield put(changeLoadingState(true));
    yield call(NetworkService.makeAPIPostRequest, [PaymentAdmin, PaymentRequest, StatusChange], payload);
    yield put(changeEndedStatus(true));
    yield put(changeLoadingState(false));
    yield put(setSuccessMessage(getMessage(i18n.t('transactions'), i18n.t('notification:slUpdated'))));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

export default function* paymentSaga() {
  yield all([
    takeLatest(PaymentActionType.GET_DEPOSITS, getDeposits),
    takeLatest(PaymentActionType.GET_WITHDRAWALS, getWithdrawals),
    takeLatest(PaymentActionType.ADD_DEPOSIT_NOTE, addDepositNote),
    takeLatest(PaymentActionType.ADD_WITHDRAWAL_NOTE, addWithdrawalNote),
    takeLatest(PaymentActionType.GET_WITHDRAWAL_NOTES, getWithdrawalNotes),
    takeLatest(PaymentActionType.GET_DEPOSIT_NOTES, getDepositNotes),
    takeLatest(PaymentActionType.GET_WITHDRAWAL_DETAILS, getWithdrawalDetails),
    takeLatest(PaymentActionType.GET_DEPOSIT_DETAILS, getDepositDetails),
    takeLatest(PaymentActionType.GET_DEPOSIT_LOGS, getDepositLogs),
    takeLatest(PaymentActionType.GET_WITHDRAWAL_LOGS, getWithdrawalLogs),
    takeLatest(PaymentActionType.CHANGE_WITHDRAWAL_STATUS_TO_IN_PROCESS, changeWithdrawalStatusToInProcess),
    takeLatest(PaymentActionType.CHANGE_WITHDRAWAL_STATUS_TO_APPROVED, changeWithdrawalStatusToApproved),
    takeLatest(PaymentActionType.CHANGE_WITHDRAWAL_STATUS_TO_FROZEN, changeWithdrawalStatusToFrozen),
    takeLatest(PaymentActionType.CHANGE_WITHDRAWAL_STATUS_TO_CANCELLED, changeWithdrawalStatusToCancelled),
    takeLatest(PaymentActionType.CHANGE_WITHDRAWAL_STATUS_TO_DECLINED, changeWithdrawalStatusToDeclined),
    takeLatest(PaymentActionType.CHANGE_OFFLINE_DEPOSIT_STATUS, changeOfflineDepositStatus),
    takeLatest(PaymentActionType.GENERATE_DEPOSITS_REPORT, generateDepositsReport),
    takeLatest(PaymentActionType.GENERATE_WITHDRAWALS_REPORT, generateWithdrawalsReport),
    takeLatest(PaymentActionType.CHANGE_OFFLINE_WITHDRAWAL_STATUS_TO_IN_PROCESS, changeOfflineWithdrawalStatusToInProcess),
    takeLatest(PaymentActionType.CHANGE_OFFLINE_WITHDRAWAL_STATUS_TO_APPROVED, changeOfflineWithdrawalStatusToApproved),
    takeLatest(PaymentActionType.CHANGE_OFFLINE_WITHDRAWAL_STATUS_TO_DECLINED, changeOfflineWithdrawalStatusToDeclined),
    takeLatest(PaymentActionType.GET_TRANSACTION_PLAYER_INFO, getTransactionPlayerInfo),
    takeLatest(PaymentActionType.GET_PLAYER_DEPOSITS, getPlayerDeposits),
    takeLatest(PaymentActionType.EXPORT_PLAYER_DEPOSITS, exportPlayerDeposits),
    takeLatest(PaymentActionType.GET_PLAYER_WITHDRAWALS, getPlayerWithdrawals),
    takeLatest(PaymentActionType.EXPORT_PLAYER_WITHDRAWALS, exportPlayerWithdrawals),
    takeLatest(PaymentActionType.GET_DEPOSIT_TAGS, getDepositTags),
    takeLatest(PaymentActionType.ASSIGN_DEPOSIT_TAGS, assignDepositTags),
    takeLatest(PaymentActionType.EXPORT_DEPOSIT_TAGS, exportDepositTags),
    takeLatest(PaymentActionType.GET_WITHDRAWAL_TAGS, getWithdrawalTags),
    takeLatest(PaymentActionType.ASSIGN_WITHDRAWAL_TAGS, assignWithdrawalTags),
    takeLatest(PaymentActionType.EXPORT_WITHDRAWAL_TAGS, exportWithdrawalTags),
    takeLatest(PaymentActionType.GET_ASSIGNED_TAG_CATEGORIES, getAssignedTagCategories),
    takeLatest(PaymentActionType.GET_ASSIGNED_TAGS, getAssignedTags),
    takeLatest(PaymentActionType.CHANGE_BULK_WITHDRAWAL_STATUS, changeBulkWithdrawalStatus),
  ]);
}
