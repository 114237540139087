import styled from 'styled-components';

const StyledTabInfo = styled.div`
  display: ${(props) => (props.isInline ? 'inline-flex' : 'flex')};
  align-items: flex-start;
  width: ${(props) => props.width};
  padding: 0.85rem 1.71rem;
  border: ${(props) => (props.noBorder ? 'none' : `1px solid ${props.theme.colors.secondaryBorder}`)};
  color: ${(props) => props.theme.colors.primaryText};
  border-radius: ${(props) => props.theme.customBorderRadius.borderRadiusSmall};
  margin-bottom: 1.71rem;
  margin-top: ${(props) => (props.marginTop ? '.57rem' : '0')};
  background-color: ${(props) => (props.hasBg ? props.theme.colors.secondaryBg : 'unset')};
  max-width: 100%;
  svg {
    margin-right: 1.14rem;
  }
`;
const StyledTabInfoText = styled.div`
  font-size: 1rem;
  line-height: 1.57;
  margin-top: ${(props) => (props.marginTop ? '1rem' : '0')};
  text-align: left;
  > div {
    font-size: 1rem;
    text-align: left;
    line-height: 1.57;
  }
`;

export { StyledTabInfo, StyledTabInfoText };
