import styled from 'styled-components';
import { Switch, Tabs } from 'antd';

const StyledSwitch = styled(Switch)`
  &.ant-switch {
    background-color: ${(props) => props.theme.colors.secondaryBorder};
    height: 1.71rem;
    min-width: 42px;
    &-loading-icon,
    &::after {
      width: 1.43rem;
      height: 1.43rem;
    }
    span {
      color: ${(props) => props.theme.colors.secondaryBorder};
      font-weight: ${(props) => props.theme.typography.fontWeightMedium};
    }
    &.ant-switch-checked {
      background-color: ${(props) => props.theme.colors.blue};
      span {
        color: ${(props) => props.theme.colors.white};
      }
    }
  }
`;

const StyledSwitchBig = styled(StyledSwitch)`
  background-image: none;
  flex: 0 0 46px;
  .ant-switch-handle {
    width: 1.14rem;
    height: 1.14rem;
    top: 0.285rem;
    left: 0.285rem;

    &:before {
      border-radius: ${(props) => props.theme.customBorderRadius.borderRadiusMiddle};
    }
  }

  &,
  &:hover {
    background-color: #ff5656;
  }

  &.ant-switch-checked,
  &.ant-switch-checked:hover {
    background-color: #4bae4f;
    .ant-switch-handle {
      left: calc(100% - 20px);
    }
  }

  .ant-switch-inner {
    display: flex;
    align-items: center;
    padding: 0 8px;
    justify-content: space-between;

    .ant-switch-inner-unchecked,
    .ant-switch-inner-checked {
      margin-top: 0;
      min-height: unset;

      svg {
        width: 12px;
        height: 12px;
        line {
          stroke: ${(props) => props.theme.colors.white};
        }
        path {
          fill: ${(props) => props.theme.colors.white};
        }
      }
    }
  }
`;

const StyledSwitchSmall = styled(StyledSwitch)`
  &.ant-switch {
    background-color: ${(props) => props.theme.colors.secondaryColor}80;
    width: 2rem;
    height: 1.14rem;
    min-width: 2rem;
    &-loading-icon,
    &::after {
      transform: translate(-10px);
      margin-top: -5.6px;
      margin-left: 0.5rem;
      box-shadow: 0 1px 1px #0000003d;
      border: 1px solid #ffffff1f;
      background-color: ${(props) => props.theme.colors.primaryBg}30;
    }
    span {
      color: ${(props) => props.theme.colors.secondaryBorder};
    }
    &.ant-switch-checked {
      background-color: ${(props) => props.theme.colors.blue};

      &:after {
        margin-left: -0.5rem;
      }
      .ant-switch-handle {
        left: calc(100% - 1rem);
        &:before {
          background-color: ${(props) => props.theme.colors.white};
        }
      }
    }
    .ant-switch-handle {
      width: 0.86rem;
      height: 0.86rem;
      top: 50%;
      transform: translateY(-50%);
      left: 2px;
    }
  }
`;

const StyledToggle = styled(Tabs)`
  width: 2.5rem;
  height: 1.14rem;
  border-radius: 9px;
  cursor: pointer;
  background: ${(props) => (props.enabled ? props.theme.colors.blue : `${props.theme.colors.secondaryColor}80`)};
  .ant-tabs {
    &-nav {
      &:before {
        display: none;
      }
      &-more {
        display: none;
      }
    }
    &-nav-wrap {
      display: flex;
      align-items: center;
      padding: 0 6px 0 2px;
    }
    &-tab {
      width: 33.3%;
      padding: 0;
      height: 1.14rem;
      &-active {
        border: none;
      }
    }
    &-nav-list {
      width: 100%;
      height: 100%;
      align-items: center;
    }
    &-ink-bar {
      display: block;
      border-radius: 9px;
      box-shadow: 0 1px 1px #0000003d;
      border: none;
      width: 0.86rem !important;
      height: 0.86rem !important;
      bottom: 0.15rem !important;
      background: #ffffff;
    }
  }
  .ant-tabs-tab + .ant-tabs-tab {
    margin: 0;
  }
`;

export {
  StyledSwitch, StyledSwitchSmall, StyledToggle, StyledSwitchBig,
};
