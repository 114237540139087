import styled from 'styled-components';

const SearchItemsWrapper = styled.div`
  .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    border-right: none;
    transition: none;
    &:hover {
      border-right: 1px solid ${(props) => props.theme.colors.blue};
    }
  }
  .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector,
  .ant-select-open .ant-select-selector {
    border: 1px solid #1eb5bd;
    transition: none;
  }
  display: flex;
  > div {
    &:first-child {
      flex-basis: 30%;
      .ant-select-selector {
        border-bottom-right-radius: 0;
        border-top-right-radius: 0;
      }
    }
    &:last-child {
      flex-grow: 1;
      .ant-input-search .ant-input-group .ant-input-affix-wrapper:not(:last-child) {
        border-bottom-left-radius: 0;
        border-top-left-radius: 0;
      }
    }
  }
`;

const StyledSearchIcon = styled.div`
  .ant-input-affix-wrapper {
    padding-right: 1.71rem;
  }
  .ant-input-clear-icon {
    svg {
      width: 1.14rem;
      height: 1.14rem;
      fill: ${(props) => props.theme.colors.secondaryColor};
    }
  }
  > span {
    padding: 0.6rem 0.7rem;
    height: 2.57rem;
  }
  input {
    text-indent: 10px;
  }
  .ant-input-group-wrapper {
    padding: 0;
    .ant-input-wrapper.ant-input-group {
      position: relative;
      .ant-input-search.ant-input-search-enter-button {
        padding: 0;
        .ant-input-affix-wrapper {
          height: 2.8rem !important;
          border-radius: ${(props) => props.theme.customBorderRadius.borderRadiusSmall};
          border: 1px solid #cccccc;
          .ant-input-suffix {
            margin-right: 2.6rem !important;
          }
        }
      }
      .ant-input-group-addon {
        background-color: transparent;
        position: absolute;
        top: 0;
        right: 2rem;
        z-index: 9;
        button {
          padding: 0 0.8rem 0 0;
          background-color: transparent;
          border: none;
        }
      }
    }
  }
`;

const StyledSearchWithoutBorder = styled.div`
  .ant-input-search {
    width: 19rem;
    height: 2.29rem;
    cursor: pointer;
  }

  .ant-input-clear-icon {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 0.5rem;
    font-size: 1.14rem;
    z-index: 555;
    svg path {
      fill: ${(props) => props.theme.colors.secondaryColor};
    }
  }
  .ant-input-search-icon::before,
  .ant-input-search-icon {
    display: none;
  }

  .anticon.anticon-search {
    font-size: 1.28rem;
  }
  .ant-input-search > .ant-input-group > .ant-input-group-addon:last-child {
    background: transparent;
    border: 1px solid ${(props) => props.theme.colors.primaryBorder};
    border-left: none;
  }
  .ant-btn:hover,
  .ant-btn:focus {
    border: 1px solid transparent;
  }
`;

const StyledSearchWrapper = styled.div`
  .ant-input-wrapper.ant-input-group {
    display: flex;
    align-items: center;
    height: 100%;
  }

  .ant-input-search > .ant-input-group > .ant-input-group-addon,
  .ant-input-search > .ant-input-group > .ant-input-group-addon:last-child {
    left: -2.85rem;
  }
  .ant-input-search > .ant-input-group > .ant-input-group-addon,
  .ant-input-search > .ant-input-group > .ant-input-group-addon:last-child {
    border: none;
    height: 90%;
    z-index: 55;
  }

  .ant-input-clear-icon {
    right: ${(props) => (props.isInput ? '1.14rem' : '2.8rem')};
  }
  .ant-input-group-wrapper.ant-input-search .ant-input-affix-wrapper:focus,
  .ant-input-group-wrapper.ant-input-search .ant-input-affix-wrapper-focused {
    outline: 0;
  }
  .ant-input-affix-wrapper {
    padding-right: 1.14rem;
  }
  .ant-input-group > .ant-input-affix-wrapper:not(:last-child) .ant-input {
    padding-right: 2.1rem;
  }
`;
const StyledSearchDetails = styled.div`
  padding: 0.28rem;
  > div {
    display: flex;
    flex-wrap: wrap;
  }
`;
const StyledPlayerId = styled.div`
  margin-left: auto;
`;
const StyledRestictedWrapper = styled.ul`
  display: flex;
  flex-wrap: wrap;
`;
const StyledRestictedReasons = styled.li`
  padding: 0 0.57rem;
  border-radius: ${(props) => props.theme.customBorderRadius.borderRadiusSmall};
  background: ${(props) => props.theme.colors.primaryBg};
  display: inline-flex;
  font-size: 0.85rem;
  margin: 0.5rem 0.5rem 0 0;
`;
const StyledSearchWithIcon = styled(StyledSearchWithoutBorder)`
  flex-grow: 1;
  .ant-col {
    width: 100%;
  }
  .ant-input-group > .ant-input-affix-wrapper:not(:last-child) .ant-input {
    padding-right: 3.1rem;
  }
  .ant-input-search {
    height: 2.29rem;
    width: 100%;

    .ant-btn.ant-input-search-button {
      border-color: transparent;
    }
  }
  .ant-btn > .anticon {
    line-height: 0;
  }

  .ant-btn.ant-btn-icon-only.ant-input-search-button {
    background: transparent;
    &:disabled {
      background: ${(props) => props.theme.colors.primaryBg}80!important;
    }
  }
`;

export {
  StyledSearchIcon,
  StyledSearchWrapper,
  StyledSearchWithoutBorder,
  SearchItemsWrapper,
  StyledSearchWithIcon,
  StyledSearchDetails,
  StyledPlayerId,
  StyledRestictedWrapper,
  StyledRestictedReasons,
};
