const configVariables = {
  baseURL: process.env.REACT_APP_BASE_URL,
  siteBuilderURL: process.env.REACT_APP_SITE_BUILDER_URL,
  siteBuilderAccountURL: process.env.REACT_APP_SITE_BUILDER_ACCOUNT_URL,
  isBrRegulationAudit: process.env.REACT_APP_IS_BR_REGULATION_AUDIT && JSON.parse(process.env.REACT_APP_IS_BR_REGULATION_AUDIT),
  isBrRegulation: process.env.REACT_APP_IS_BR_REGULATION && JSON.parse(process.env.REACT_APP_IS_BR_REGULATION),
  brRegulationSpecialActionsBaseURL: process.env.REACT_APP_BR_REGULATION_SPECIAL_ACTIONS_BASE_URL,
  env: process.env.REACT_APP_ENV,
};

const Config = {
  ...configVariables,
  xhr: {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json; charset=UTF-8',
    },
  },
};

export default Config;
