import PartnerActionType from 'actions/partner/partnerActionType';
import { LocalStorageService } from 'services';

const initialState = {
  loading: false,
  partners: [],
  partner: {},
  currentPartnerId: LocalStorageService.get('current')?.partnerId,
  partnerAccesses: [],
  partnerAccessesLoading: false,
};

const partnerReducer = (state = initialState, { type, payload }) => {
  switch (type) {
  case PartnerActionType.CHANGE_PARTNER_ACCESSES_LOADING:
    return {
      ...state,
      partnerAccessesLoading: payload.state,
    };
  case PartnerActionType.GET_PARTNER_ACCESSES_SUCCESS:
    return {
      ...state,
      partnerAccesses: payload.data,
      partnerAccessesLoading: false,
    };
  case PartnerActionType.RESET_PARTNER_ACCESSES:
    return {
      ...state,
      partnerAccesses: [],
    };
  case PartnerActionType.GET_PARTNERS_SUCCESS:
    return {
      ...state,
      loading: false,
      partners: payload.data,
    };
  case PartnerActionType.RESET_PARTNERS:
    return {
      ...state,
      partners: [],
    };
  case PartnerActionType.GET_PARTNER_SUCCESS:
    return {
      ...state,
      partner: payload.data,
      loading: false,
    };
  case PartnerActionType.RESET_PARTNER:
    return {
      ...state,
      partner: {},
    };
  case PartnerActionType.SET_CURRENT_PARTNER:
    return {
      ...state,
      currentPartnerId: payload.id,
    };
  case PartnerActionType.CHANGE_PARTNER_LOADING_STATE:
    return {
      ...state,
      loading: payload.state,
    };
  default:
    return state;
  }
};

export default partnerReducer;
