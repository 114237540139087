import { Navigate } from 'react-router-dom';
import _ from 'lodash';
import { LocalStorageService } from 'services';
import enumTypes from 'constants/enumTypes';

const Can = (props) => {
  const {
    type, rule, children, fallback, redirect, claims,
  } = props;

  const user = LocalStorageService.get('user') || {};

  const { userClaims, permissions = {} } = user;

  const isSuperAdmin = userClaims?.includes(enumTypes.userClaimTypes.SuperAdmin);

  if (!user) {
    return false;
  }

  if (isSuperAdmin) {
    return children;
  }

  const getAvailability = (data) => {
    const ability = data ? data.split('.') : '';
    return permissions[ability[0]] && permissions[ability[0]][ability[1]] && permissions[ability[0]][ability[1]].filter((permission) => type === 'view' || _.camelCase(permission) === type).length > 0;
  };

  const fb = redirect ? <Navigate to="/notFound" replace /> : fallback;
  const noAccess = !_.isUndefined(fb) ? fb : <></>;

  const getHasClaimAccess = () => !!(claims && userClaims && _.intersection(userClaims, claims).length);
  const getHasPermissionAccess = () => (Array.isArray(rule) ? rule.some((el) => getAvailability(el)) : getAvailability(rule));

  return getHasClaimAccess() || (!claims && getHasPermissionAccess()) ? children : noAccess;
};

export default Can;
