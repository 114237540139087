import {
  all, call, put, takeLatest,
} from '@redux-saga/core/effects';
import qs from 'qs';
import GameSegmentActionType from 'actions/gameSegment/gameSegmentActionType';
import NetworkService from 'services/networkService';
import { saveGamesSegments, saveSegmentGames, saveGameSegment } from 'actions/gameSegment/gameSegmentActionCreator';
import { AppConstants } from 'constants/index';
import {
  changeEndedStatus, changeLoadingState, setErrorMessage, setSuccessMessage,
} from 'actions/setting/settingActionCreator';
import { getError, getMessage } from 'utils/helpers';
import { i18n } from 'services';

function* createGameSegment({ payload }) {
  try {
    yield put(changeLoadingState(true));
    const { Transaction, GameSegment } = AppConstants.api;
    yield call(NetworkService.makeAPIPostRequest, [Transaction, GameSegment], {
      data: payload.data,
    });
    yield put(setSuccessMessage(getMessage('', i18n.t('notification:slCreated'), payload.data.name)));
    yield put(changeLoadingState(false));
    yield put(changeEndedStatus(true));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* deleteGameSegment({ payload }) {
  try {
    yield put(changeLoadingState(true));
    const { Transaction, GameSegment } = AppConstants.api;
    yield call(NetworkService.makeAPIDeleteRequest, [Transaction, GameSegment, payload.id]);
    yield put(setSuccessMessage(getMessage('', i18n.t('notification:slDeleted'), payload.name)));
    yield put(changeLoadingState(false));
    yield put(changeEndedStatus(true));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getGamesSegments({ payload }) {
  try {
    const options = {
      params: payload.data,
      paramsSerializer: (params) => qs.stringify(params),
    };
    yield put(changeLoadingState(true));
    const { Transaction, GameSegment } = AppConstants.api;
    const { data } = yield call(NetworkService.makeAPIGetRequest, [Transaction, GameSegment], options);
    yield put(saveGamesSegments(data));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* editGameSegment({ payload }) {
  try {
    yield put(changeLoadingState(true));
    const { Transaction, GameSegment } = AppConstants.api;
    yield call(NetworkService.makeAPIPutRequest, [Transaction, GameSegment, payload.id], {
      data: payload.data,
    });
    yield put(setSuccessMessage(getMessage(i18n.t('tool:theSegment'), i18n.t('notification:slUpdated'))));
    yield put(changeLoadingState(false));
    yield put(changeEndedStatus(true));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getSegmentGames({ payload }) {
  const { segmentId, params, isLoading } = payload;
  try {
    const options = {
      params,
      paramsSerializer: (param) => qs.stringify(param),
    };
    const { Transaction, GameSegment, Games } = AppConstants.api;

    if (isLoading) {
      yield put(changeLoadingState(true));
    }

    const { data } = yield call(NetworkService.makeAPIGetRequest, [Transaction, GameSegment, segmentId, Games], options);
    yield put(saveSegmentGames(data));

    if (isLoading) {
      yield put(changeLoadingState(false));
    }
  } catch (err) {
    if (err) {
      const error = getError(err, true);
      yield put(setErrorMessage(error));
    }
    if (isLoading) {
      yield put(changeLoadingState(false));
    }
  }
}

function* getGameSegment({ payload }) {
  try {
    const { segmentId } = payload;
    const { Transaction, GameSegment } = AppConstants.api;
    yield put(changeLoadingState(true));
    const { data } = yield call(NetworkService.makeAPIGetRequest, [Transaction, GameSegment, segmentId]);
    yield put(saveGameSegment(data));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err, true);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* deleteGameSegmentGame({ payload }) {
  try {
    const { segmentId, id, name } = payload;
    yield put(changeLoadingState(true));
    const { Transaction, GameSegment, Games } = AppConstants.api;
    yield call(NetworkService.makeAPIDeleteRequest, [Transaction, GameSegment, segmentId, Games, id]);
    yield put(setSuccessMessage(i18n.t('notification:removedFromSegment', { name })));
    yield put(changeEndedStatus(true));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* updateGameSegment({ payload }) {
  try {
    yield put(changeLoadingState(true));
    const { Transaction, GameSegment, Games } = AppConstants.api;
    yield call(NetworkService.makeAPIPutRequest, [Transaction, GameSegment, payload.id, Games], {
      data: payload.data,
    });
    yield put(setSuccessMessage(getMessage(i18n.t('tool:theSegment'), i18n.t('notification:slUpdated'))));
    yield put(changeLoadingState(false));
    yield put(changeEndedStatus(true));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

export default function* gameSegmentSaga() {
  yield all([
    takeLatest(GameSegmentActionType.CREATE_GAME_SEGMENT, createGameSegment),
    takeLatest(GameSegmentActionType.GET_GAMES_SEGMENTS, getGamesSegments),
    takeLatest(GameSegmentActionType.DELETE_GAME_SEGMENT, deleteGameSegment),
    takeLatest(GameSegmentActionType.EDIT_GAME_SEGMENT, editGameSegment),
    takeLatest(GameSegmentActionType.GET_SEGMENT_GAMES, getSegmentGames),
    takeLatest(GameSegmentActionType.DELETE_GAME_SEGMENT_GAME, deleteGameSegmentGame),
    takeLatest(GameSegmentActionType.UPDATE_GAME_SEGMENT, updateGameSegment),
    takeLatest(GameSegmentActionType.GET_GAME_SEGMENT, getGameSegment),
  ]);
}
