import { createSelector } from 'reselect';

export const selectPushNotificationRecipientsStatistics = (state) => state.recipientsStatistics;

const selectSentNotifications = (state) => state.sentNotifications;

export const selectSentNotificationsData = (state) => selectSentNotifications(state)?.data;
export const selectSentNotificationsTotal = (state) => selectSentNotifications(state)?.total;

export const selectPushNotificationNameExists = (state) => state.nameExists;

const selectScheduledNotifications = (state) => state.scheduledNotifications;
export const selectScheduledNotificationsData = (state) => selectScheduledNotifications(state).data;
export const selectScheduledNotificationsTotal = (state) => selectScheduledNotifications(state).total;

export const selectPushNotificationsRecipientsTotals = (state) => state.pushNotificationsRecipientsTotals;

export const selectSentNotificationDetails = (state, id) => createSelector(
  () => selectPushNotificationsRecipientsTotals(state),
  (data) => data[id] || [],
)();

const selectPushNotificationRecipients = (state) => state.pushNotificationRecipients;
export const selectPushNotificationRecipientsData = (state) => selectPushNotificationRecipients(state)?.data;
export const selectPushNotificationRecipientsTotal = (state) => selectPushNotificationRecipients(state)?.total;

export const selectPushNotification = (state) => state.pushNotification;
export const selectPushNotificationName = (state) => selectPushNotification(state)?.name;

const selectPlayerPushNotifications = (state) => state.playerPushNotifications;
export const selectPlayerPushNotificationsData = (state) => selectPlayerPushNotifications(state)?.data;
export const selectPlayerPushNotificationsTotal = (state) => selectPlayerPushNotifications(state)?.total;
