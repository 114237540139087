import GameManagementActionType from 'actions/gameManagement/gameManagementActionType';

const initialState = {
  games: {},
  game: {},
  gameThemesResource: [],
  gameGroupsResource: [],
  gameThemes: [],
  gameBadgesResource: [],
  gameGroups: [],
  gameGroup: {},
  endedStatus: false,
  csvGames: [],
  gameTypes: {},
  gameType: {},
  csvGameTypeGames: [],
};

const gameManagementReducer = (state = initialState, { type, payload }) => {
  switch (type) {
  case GameManagementActionType.SAVE_GAMES:
    return {
      ...state,
      games: payload.data,
    };
  case GameManagementActionType.RESET_GAMES:
    return {
      ...state,
      games: {},
    };
  case GameManagementActionType.SAVE_GAME:
    return {
      ...state,
      game: payload.data,
    };
  case GameManagementActionType.RESET_GAME:
    return {
      ...state,
      game: {},
    };
  case GameManagementActionType.SAVE_GAME_THEMES_RESOURCE:
    return {
      ...state,
      gameThemesResource: payload.data,
    };
  case GameManagementActionType.RESET_GAME_THEMES_RESOURCE:
    return {
      ...state,
      gameThemesResource: [],
    };
  case GameManagementActionType.SAVE_GAME_BADGES_RESOURCE:
    return {
      ...state,
      gameBadgesResource: payload.data,
    };
  case GameManagementActionType.RESET_GAME_BADGES_RESOURCE:
    return {
      ...state,
      gameBadgesResource: [],
    };
  case GameManagementActionType.SAVE_GAME_GROUPS_RESOURCE:
    return {
      ...state,
      gameGroupsResource: payload.data,
    };
  case GameManagementActionType.RESET_GAME_GROUPS_RESOURCE:
    return {
      ...state,
      gameGroupsResource: [],
    };
  case GameManagementActionType.SAVE_GAME_GROUPS:
    return {
      ...state,
      gameGroups: payload.data,
    };
  case GameManagementActionType.RESET_GAME_GROUPS:
    return {
      ...state,
      gameGroups: [],
    };
  case GameManagementActionType.CHANGE_GAME_MANAGEMENT_ENDED_STATUS:
    return {
      ...state,
      endedStatus: payload.status,
    };

  case GameManagementActionType.SAVE_CSV_GAMES:
    return {
      ...state,
      csvGames: payload.data,
    };
  case GameManagementActionType.RESET_CSV_GAMES:
    return {
      ...state,
      csvGames: [],
    };
  case GameManagementActionType.SAVE_GAME_GROUP:
    return {
      ...state,
      gameGroup: payload.data,
    };
  case GameManagementActionType.RESET_GAME_GROUP:
    return {
      ...state,
      gameGroup: {},
    };
  case GameManagementActionType.SAVE_GAME_TYPES:
    return {
      ...state,
      gameTypes: payload.data,
    };
  case GameManagementActionType.RESET_GAME_TYPES:
    return {
      ...state,
      gameTypes: {},
    };
  case GameManagementActionType.SAVE_GAME_TYPE:
    return {
      ...state,
      gameType: payload.data,
    };
  case GameManagementActionType.RESET_GAME_TYPE:
    return {
      ...state,
      gameType: {},
    };
  case GameManagementActionType.SAVE_CSV_GAME_TYPE_GAMES:
    return {
      ...state,
      csvGameTypeGames: payload.data,
    };
  case GameManagementActionType.RESET_CSV_GAME_TYPE_GAMES:
    return {
      ...state,
      csvGameTypeGames: [],
    };
  default:
    return state;
  }
};

export default gameManagementReducer;
