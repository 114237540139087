import { AppConstants } from 'constants/index';
import { i18n, NetworkService } from 'services';
import {
  saveTaxationDaily,
  saveTaxationMonthly,
  saveSoftwareChanges,
  saveGameRatio,
  savePlayerExclusion,
  saveGameTypes,
  saveBalanceAdjustment,
  saveSessions,
  saveSafeServer,
  saveMirroringServer,
  saveInaccessibilityServer,
  saveSignificantEventsLargeTransactions,
  saveSignificantEventsRestrictions,
  saveChangeNotification,
  saveJackpotWinRatio,
  saveJackpotConfiguration,
  savePromotionalItems,
} from 'actions/regulatory/regulatoryActionCreator';
import qs from 'qs';
import {
  put, all, call, takeLatest,
} from 'redux-saga/effects';
import mime from 'mimetypes';
import {
  changeEndedStatus, changeLoadingState, setErrorMessage, setSuccessMessage,
} from 'actions/setting/settingActionCreator';
import RegulatoryActionType from 'actions/regulatory/regulatoryActionType';
import { downloadFile } from 'utils/utils';

import { getError, getMessage } from 'utils/helpers';

function* getSoftwareChanges({ payload }) {
  try {
    const { params } = payload;
    const options = {
      params,
      paramsSerializer: (param) => qs.stringify(param),
    };
    const {
      ReportingAdmin, External, Game, Changes,
    } = AppConstants.api;
    yield put(changeLoadingState(true));
    const { data } = yield call(NetworkService.makeAPIGetRequest, [ReportingAdmin, External, Game, Changes], options);
    yield put(saveSoftwareChanges(data));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* exportSoftwareChanges({ payload }) {
  try {
    const options = {
      params: payload.params,
      responseType: 'blob',
      paramsSerializer: (params) => qs.stringify(params, { allowDots: true }),
    };
    yield put(changeLoadingState(true));
    const {
      ReportingAdmin, External, Game, Changes, Export,
    } = AppConstants.api;
    const { data, headers } = yield call(NetworkService.makeAPIGetRequest, [ReportingAdmin, External, Game, Changes, Export], options);
    downloadFile(data, i18n.t('regulatory:softwareChanges'), mime.detectExtension(headers['content-type']));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getGameRatio({ payload }) {
  try {
    const { params } = payload;
    const options = {
      params,
      paramsSerializer: (param) => qs.stringify(param),
    };
    const { ReportingAdmin, External, Financial } = AppConstants.api;
    yield put(changeLoadingState(true));
    const { data } = yield call(NetworkService.makeAPIGetRequest, [ReportingAdmin, External, Financial], options);
    yield put(saveGameRatio(data));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* exportGameRatio({ payload }) {
  try {
    const options = {
      params: payload.params,
      responseType: 'blob',
      paramsSerializer: (params) => qs.stringify(params, { allowDots: true }),
    };
    yield put(changeLoadingState(true));
    const {
      ReportingAdmin, External, Financial, Export,
    } = AppConstants.api;
    const { data, headers } = yield call(NetworkService.makeAPIGetRequest, [ReportingAdmin, External, Financial, Export], options);
    downloadFile(data, i18n.t('regulatory:gameRatio'), mime.detectExtension(headers['content-type']));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getPlayerExclusion({ payload }) {
  try {
    const { params } = payload;
    const options = {
      params,
      paramsSerializer: (param) => qs.stringify(param),
    };
    const {
      ReportingAdmin, External, Player, Exclusions,
    } = AppConstants.api;
    yield put(changeLoadingState(true));
    const { data } = yield call(NetworkService.makeAPIGetRequest, [ReportingAdmin, External, Player, Exclusions], options);
    yield put(savePlayerExclusion(data));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* exportPlayerExclusion({ payload }) {
  try {
    const options = {
      params: payload.params,
      responseType: 'blob',
      paramsSerializer: (params) => qs.stringify(params, { allowDots: true }),
    };
    yield put(changeLoadingState(true));
    const {
      ReportingAdmin, External, Player, Exclusions, Export,
    } = AppConstants.api;
    const { data, headers } = yield call(NetworkService.makeAPIGetRequest, [ReportingAdmin, External, Player, Exclusions, Export], options);
    downloadFile(data, i18n.t('regulatory:playerExclusion'), mime.detectExtension(headers['content-type']));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getGameTypes({ payload }) {
  try {
    const { params } = payload;
    const options = {
      params,
      paramsSerializer: (param) => qs.stringify(param),
    };
    const {
      ReportingAdmin, External, Game, Types,
    } = AppConstants.api;
    yield put(changeLoadingState(true));
    const { data } = yield call(NetworkService.makeAPIGetRequest, [ReportingAdmin, External, Game, Types], options);
    yield put(saveGameTypes(data));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* exportGameTypes({ payload }) {
  try {
    const options = {
      params: payload.params,
      responseType: 'blob',
      paramsSerializer: (params) => qs.stringify(params, { allowDots: true }),
    };
    yield put(changeLoadingState(true));
    const {
      ReportingAdmin, External, Game, Types, Export,
    } = AppConstants.api;
    const { data, headers } = yield call(NetworkService.makeAPIGetRequest, [ReportingAdmin, External, Game, Types, Export], options);
    downloadFile(data, i18n.t('regulatory:gameTypes'), mime.detectExtension(headers['content-type']));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getBalanceAdjustment({ payload }) {
  try {
    const { params } = payload;
    const options = {
      params,
      paramsSerializer: (param) => qs.stringify(param),
    };
    const {
      ReportingAdmin, External, Financial, BalanceAdjustment,
    } = AppConstants.api;
    yield put(changeLoadingState(true));
    const { data } = yield call(NetworkService.makeAPIGetRequest, [ReportingAdmin, External, Financial, BalanceAdjustment], options);
    yield put(saveBalanceAdjustment(data));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* exportBalanceAdjustment({ payload }) {
  try {
    const options = {
      params: payload.params,
      responseType: 'blob',
      paramsSerializer: (params) => qs.stringify(params, { allowDots: true }),
    };
    yield put(changeLoadingState(true));
    const {
      ReportingAdmin, External, Financial, BalanceAdjustment, Export,
    } = AppConstants.api;
    const { data, headers } = yield call(NetworkService.makeAPIGetRequest, [ReportingAdmin, External, Financial, BalanceAdjustment, Export], options);
    downloadFile(data, i18n.t('regulatory:balanceAdjustment'), mime.detectExtension(headers['content-type']));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getSessions({ payload }) {
  try {
    const { params } = payload;
    const options = {
      params,
      paramsSerializer: (param) => qs.stringify(param),
    };
    const { ReportingAdmin, External, Sessions } = AppConstants.api;
    yield put(changeLoadingState(true));
    const { data } = yield call(NetworkService.makeAPIGetRequest, [ReportingAdmin, External, Sessions], options);
    yield put(saveSessions(data));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* exportSessions({ payload }) {
  try {
    const options = {
      params: payload.params,
      responseType: 'blob',
      paramsSerializer: (params) => qs.stringify(params, { allowDots: true }),
    };
    yield put(changeLoadingState(true));
    const {
      ReportingAdmin, External, Sessions, Export,
    } = AppConstants.api;
    const { data, headers } = yield call(NetworkService.makeAPIGetRequest, [ReportingAdmin, External, Sessions, Export], options);
    downloadFile(data, i18n.t('sessions'), mime.detectExtension(headers['content-type']));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getSafeServer({ payload }) {
  try {
    const { params } = payload;
    const options = {
      params,
      paramsSerializer: (param) => qs.stringify(param),
    };
    const {
      ReportingAdmin, External, Server, Safe, Reports,
    } = AppConstants.api;
    yield put(changeLoadingState(true));
    const { data } = yield call(NetworkService.makeAPIGetRequest, [ReportingAdmin, External, Server, Safe, Reports], options);
    yield put(saveSafeServer(data));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}
function* exportSafeServer({ payload }) {
  try {
    const options = {
      params: payload.params,
      responseType: 'blob',
      paramsSerializer: (params) => qs.stringify(params, { allowDots: true }),
    };
    yield put(changeLoadingState(true));
    const {
      ReportingAdmin, External, Server, Safe, Reports, Export,
    } = AppConstants.api;
    const { data, headers } = yield call(NetworkService.makeAPIGetRequest, [ReportingAdmin, External, Server, Safe, Reports, Export], options);
    downloadFile(data, i18n.t('regulatory:safeServer'), mime.detectExtension(headers['content-type']));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getMirroringServer({ payload }) {
  try {
    const { params } = payload;
    const options = {
      params,
      paramsSerializer: (param) => qs.stringify(param),
    };
    const {
      ReportingAdmin, External, Server, Mirroring, Reports,
    } = AppConstants.api;
    yield put(changeLoadingState(true));
    const { data } = yield call(NetworkService.makeAPIGetRequest, [ReportingAdmin, External, Server, Mirroring, Reports], options);
    yield put(saveMirroringServer(data));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* exportMirroringServer({ payload }) {
  try {
    const options = {
      params: payload.params,
      responseType: 'blob',
      paramsSerializer: (params) => qs.stringify(params, { allowDots: true }),
    };
    yield put(changeLoadingState(true));
    const {
      ReportingAdmin, External, Server, Mirroring, Reports, Export,
    } = AppConstants.api;
    const { data, headers } = yield call(NetworkService.makeAPIGetRequest, [ReportingAdmin, External, Server, Mirroring, Reports, Export], options);
    downloadFile(data, i18n.t('regulatory:mirroringServer'), mime.detectExtension(headers['content-type']));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* addChangeNotification({ payload }) {
  try {
    const { data } = payload;
    const options = {
      data,
    };
    const {
      ReportingAdmin, External, Server, Mirroring, Reports, ChangeNotification,
    } = AppConstants.api;
    yield put(changeLoadingState(true));
    yield call(NetworkService.makeAPIPostRequest, [ReportingAdmin, External, Server, Mirroring, Reports, ChangeNotification], options);
    yield put(changeEndedStatus(true));
    yield put(changeLoadingState(false));
    yield put(setSuccessMessage(getMessage(i18n.t('regulatory:change'), i18n.t('notification:slAdded'))));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}
function* getChangeNotification({ payload }) {
  try {
    const { params } = payload;
    const options = {
      params,
      paramsSerializer: (param) => qs.stringify(param),
    };
    const {
      ReportingAdmin, External, Server, Mirroring, Reports, ChangeNotification,
    } = AppConstants.api;
    yield put(changeLoadingState(true));
    const { data } = yield call(NetworkService.makeAPIGetRequest, [ReportingAdmin, External, Server, Mirroring, Reports, ChangeNotification], options);
    yield put(saveChangeNotification(data));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* exportChangeNotification({ payload }) {
  try {
    const options = {
      params: payload.params,
      responseType: 'blob',
      paramsSerializer: (params) => qs.stringify(params, { allowDots: true }),
    };
    yield put(changeLoadingState(true));
    const {
      ReportingAdmin, External, Server, Mirroring, Reports, ChangeNotification, Export,
    } = AppConstants.api;
    const { data, headers } = yield call(NetworkService.makeAPIGetRequest, [ReportingAdmin, External, Server, Mirroring, Reports, ChangeNotification, Export], options);
    downloadFile(data, i18n.t('regulatory:changeNotification'), mime.detectExtension(headers['content-type']));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getInaccessibilityServer({ payload }) {
  try {
    const { params } = payload;
    const options = {
      params,
      paramsSerializer: (param) => qs.stringify(param),
    };
    const {
      ReportingAdmin, External, Server, Mirroring, Reports, Inaccessibility,
    } = AppConstants.api;
    yield put(changeLoadingState(true));
    const { data } = yield call(NetworkService.makeAPIGetRequest, [ReportingAdmin, External, Server, Mirroring, Reports, Inaccessibility], options);
    yield put(saveInaccessibilityServer(data));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* exportInaccessibilityServer({ payload }) {
  try {
    const options = {
      params: payload.params,
      responseType: 'blob',
      paramsSerializer: (params) => qs.stringify(params, { allowDots: true }),
    };
    yield put(changeLoadingState(true));
    const {
      ReportingAdmin, External, Server, Mirroring, Reports, Inaccessibility, Export,
    } = AppConstants.api;
    const { data, headers } = yield call(NetworkService.makeAPIGetRequest, [ReportingAdmin, External, Server, Mirroring, Reports, Inaccessibility, Export], options);
    downloadFile(data, i18n.t('regulatory:inaccessibilityServer'), mime.detectExtension(headers['content-type']));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* addInaccessibilityServer({ payload }) {
  try {
    const options = {
      data: payload.data,
    };
    const {
      ReportingAdmin, External, Server, Mirroring, Reports, Inaccessibility,
    } = AppConstants.api;
    yield put(changeLoadingState(true));
    yield call(NetworkService.makeAPIPostRequest, [ReportingAdmin, External, Server, Mirroring, Reports, Inaccessibility], options);
    yield put(changeEndedStatus(true));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* deleteInaccessibilityServer({ payload }) {
  try {
    const {
      ReportingAdmin, External, Server, Mirroring, Reports, Inaccessibility,
    } = AppConstants.api;
    yield put(changeLoadingState(true));
    yield call(NetworkService.makeAPIDeleteRequest, [ReportingAdmin, External, Server, Mirroring, Reports, Inaccessibility, payload.id]);
    yield put(changeEndedStatus(true));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getSignificantEventsLargeTransactions({ payload }) {
  try {
    const { params } = payload;
    const options = {
      params,
      paramsSerializer: (param) => qs.stringify(param),
    };
    const {
      ReportingAdmin, External, Server, Mirroring, Reports, LargeTransactions,
    } = AppConstants.api;
    yield put(changeLoadingState(true));
    const { data } = yield call(NetworkService.makeAPIGetRequest, [ReportingAdmin, External, Server, Mirroring, Reports, LargeTransactions], options);
    yield put(saveSignificantEventsLargeTransactions(data));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* exportSignificantEventsLargeTransactions({ payload }) {
  try {
    const options = {
      params: payload.params,
      responseType: 'blob',
      paramsSerializer: (params) => qs.stringify(params, { allowDots: true }),
    };
    yield put(changeLoadingState(true));
    const {
      ReportingAdmin, External, Server, Mirroring, Reports, LargeTransactions, Export,
    } = AppConstants.api;
    const { data, headers } = yield call(NetworkService.makeAPIGetRequest, [ReportingAdmin, External, Server, Mirroring, Reports, LargeTransactions, Export], options);
    downloadFile(data, i18n.t('title:largeTransactions'), mime.detectExtension(headers['content-type']));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getSignificantEventsRestrictions({ payload }) {
  try {
    const { params } = payload;
    const options = {
      params,
      paramsSerializer: (param) => qs.stringify(param),
    };
    const {
      ReportingAdmin, External, Server, Mirroring, Reports, Restrictions,
    } = AppConstants.api;
    yield put(changeLoadingState(true));
    const { data } = yield call(NetworkService.makeAPIGetRequest, [ReportingAdmin, External, Server, Mirroring, Reports, Restrictions], options);
    yield put(saveSignificantEventsRestrictions(data));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* exportSignificantEventsRestrictions({ payload }) {
  try {
    const options = {
      params: payload.params,
      responseType: 'blob',
      paramsSerializer: (params) => qs.stringify(params, { allowDots: true }),
    };
    yield put(changeLoadingState(true));
    const {
      ReportingAdmin, External, Server, Mirroring, Reports, Restrictions, Export,
    } = AppConstants.api;
    const { data, headers } = yield call(NetworkService.makeAPIGetRequest, [ReportingAdmin, External, Server, Mirroring, Reports, Restrictions, Export], options);
    downloadFile(data, i18n.t('title:restrictions'), mime.detectExtension(headers['content-type']));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getJackPotWinRatio({ payload }) {
  try {
    const { params } = payload;
    const options = {
      params,
      paramsSerializer: (param) => qs.stringify(param),
    };
    const {
      ReportingAdmin, External, Server, Mirroring, Reports, JackpotWinRatio,
    } = AppConstants.api;
    yield put(changeLoadingState(true));
    const { data } = yield call(NetworkService.makeAPIGetRequest, [ReportingAdmin, External, Server, Mirroring, Reports, JackpotWinRatio], options);
    yield put(saveJackpotWinRatio(data));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* exportJackPotWinRatio({ payload }) {
  try {
    const options = {
      params: payload.params,
      responseType: 'blob',
      paramsSerializer: (params) => qs.stringify(params, { allowDots: true }),
    };
    yield put(changeLoadingState(true));
    const {
      ReportingAdmin, External, Server, Mirroring, Reports, JackpotWinRatio, Export,
    } = AppConstants.api;
    const { data, headers } = yield call(NetworkService.makeAPIGetRequest, [ReportingAdmin, External, Server, Mirroring, Reports, JackpotWinRatio, Export], options);
    downloadFile(data, i18n.t('regulatory:jackpotWinRatio'), mime.detectExtension(headers['content-type']));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getJackPotConfiguration({ payload }) {
  try {
    const { params } = payload;
    const options = {
      params,
      paramsSerializer: (param) => qs.stringify(param),
    };
    const {
      ReportingAdmin, External, Server, Mirroring, Reports, JackpotConfiguration,
    } = AppConstants.api;
    yield put(changeLoadingState(true));
    const { data } = yield call(NetworkService.makeAPIGetRequest, [ReportingAdmin, External, Server, Mirroring, Reports, JackpotConfiguration], options);
    yield put(saveJackpotConfiguration(data));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* exportJackPotConfiguration({ payload }) {
  try {
    const options = {
      params: payload.params,
      responseType: 'blob',
      paramsSerializer: (params) => qs.stringify(params, { allowDots: true }),
    };
    yield put(changeLoadingState(true));
    const {
      ReportingAdmin, External, Server, Mirroring, Reports, JackpotConfiguration, Export,
    } = AppConstants.api;
    const { data, headers } = yield call(NetworkService.makeAPIGetRequest, [ReportingAdmin, External, Server, Mirroring, Reports, JackpotConfiguration, Export], options);
    downloadFile(data, i18n.t('regulatory:jackpotConfiguration'), mime.detectExtension(headers['content-type']));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getPromotionalItems({ payload }) {
  try {
    const { params } = payload;
    const options = {
      params,
      paramsSerializer: (param) => qs.stringify(param),
    };
    const { ReportingAdmin, External, PromotionalItems } = AppConstants.api;
    yield put(changeLoadingState(true));
    const { data } = yield call(NetworkService.makeAPIGetRequest, [ReportingAdmin, External, PromotionalItems], options);
    yield put(savePromotionalItems(data));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* exportPromotionalItems({ payload }) {
  try {
    const options = {
      params: payload.params,
      responseType: 'blob',
      paramsSerializer: (params) => qs.stringify(params, { allowDots: true }),
    };
    yield put(changeLoadingState(true));
    const {
      ReportingAdmin, External, PromotionalItems, Export,
    } = AppConstants.api;
    const { data, headers } = yield call(NetworkService.makeAPIGetRequest, [ReportingAdmin, External, PromotionalItems, Export], options);
    downloadFile(data, i18n.t('regulatory:promotionalItems'), mime.detectExtension(headers['content-type']));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getTaxationDaily({ payload }) {
  try {
    const { params } = payload;
    const options = {
      params,
    };
    const { ReportingAdmin, External, Taxation } = AppConstants.api;
    yield put(changeLoadingState(true));
    const { data } = yield call(NetworkService.makeAPIGetRequest, [ReportingAdmin, External, Taxation], options);
    yield put(saveTaxationDaily(data));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* exportTaxationDaily({ payload }) {
  try {
    const options = {
      params: payload.params,
      paramsSerializer: (params) => qs.stringify(params, { allowDots: true }),
      responseType: 'blob',
    };
    yield put(changeLoadingState(true));
    const {
      ReportingAdmin, External, Taxation, Export,
    } = AppConstants.api;
    const { headers, data } = yield call(NetworkService.makeAPIGetRequest, [ReportingAdmin, External, Taxation, Export], options);
    downloadFile(data, i18n.t('regulatory:taxationDaily'), mime.detectExtension(headers['content-type']));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getTaxationMonthly({ payload }) {
  try {
    const { params } = payload;
    const options = {
      params,
    };
    const {
      ReportingAdmin, External, Taxation, Totals,
    } = AppConstants.api;
    yield put(changeLoadingState(true));
    const { data } = yield call(NetworkService.makeAPIGetRequest, [ReportingAdmin, External, Taxation, Totals], options);
    yield put(saveTaxationMonthly(data));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* exportTaxationMonthly({ payload }) {
  try {
    const options = {
      params: payload.params,
      paramsSerializer: (params) => qs.stringify(params, { allowDots: true }),
      responseType: 'blob',
    };
    yield put(changeLoadingState(true));
    const {
      ReportingAdmin, External, Taxation, Totals, Export,
    } = AppConstants.api;
    const { headers, data } = yield call(NetworkService.makeAPIGetRequest, [ReportingAdmin, External, Taxation, Totals, Export], options);
    downloadFile(data, i18n.t('regulatory:taxationMonthly'), mime.detectExtension(headers['content-type']));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* exportBettingSelfProtectionReport({ payload }) {
  try {
    const { name } = payload;
    const options = {
      params: payload.params,
      responseType: 'blob',
      paramsSerializer: (params) => qs.stringify(params, { allowDots: true }),
    };
    yield put(changeLoadingState(true));
    const {
      ReportingAdmin, Regulatory, BetSelfProtection, Export,
    } = AppConstants.api;
    const { data, headers } = yield call(NetworkService.makeAPIGetRequest, [ReportingAdmin, Regulatory, BetSelfProtection, Export], options);
    downloadFile(data, i18n.t(name), mime.detectExtension(headers['content-type']));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

export default function* regulatorySaga() {
  yield all([
    takeLatest(RegulatoryActionType.GET_SOFTWARE_CHANGES, getSoftwareChanges),
    takeLatest(RegulatoryActionType.EXPORT_SOFTWARE_CHANGES, exportSoftwareChanges),
    takeLatest(RegulatoryActionType.GET_GAME_RATIO, getGameRatio),
    takeLatest(RegulatoryActionType.EXPORT_GAME_RATIO, exportGameRatio),
    takeLatest(RegulatoryActionType.GET_PLAYER_EXCLUSION, getPlayerExclusion),
    takeLatest(RegulatoryActionType.EXPORT_PLAYER_EXCLUSION, exportPlayerExclusion),
    takeLatest(RegulatoryActionType.GET_GAME_TYPES_REPORT, getGameTypes),
    takeLatest(RegulatoryActionType.EXPORT_GAME_TYPES_REPORT, exportGameTypes),
    takeLatest(RegulatoryActionType.GET_BALANCE_ADJUSTMENT, getBalanceAdjustment),
    takeLatest(RegulatoryActionType.EXPORT_BALANCE_ADJUSTMENT, exportBalanceAdjustment),
    takeLatest(RegulatoryActionType.GET_SESSIONS, getSessions),
    takeLatest(RegulatoryActionType.EXPORT_SESSIONS, exportSessions),
    takeLatest(RegulatoryActionType.GET_MIRRORING_SERVER, getMirroringServer),
    takeLatest(RegulatoryActionType.EXPORT_MIRRORING_SERVER, exportMirroringServer),
    takeLatest(RegulatoryActionType.GET_SAFE_SERVER, getSafeServer),
    takeLatest(RegulatoryActionType.EXPORT_SAFE_SERVER, exportSafeServer),
    takeLatest(RegulatoryActionType.GET_CHANGE_NOTIFICATION, getChangeNotification),
    takeLatest(RegulatoryActionType.EXPORT_CHANGE_NOTIFICATION, exportChangeNotification),
    takeLatest(RegulatoryActionType.ADD_CHANGE_NOTIFICATION, addChangeNotification),
    takeLatest(RegulatoryActionType.GET_INACCESSIBILITY_SERVER, getInaccessibilityServer),
    takeLatest(RegulatoryActionType.EXPORT_INACCESSIBILITY_SERVER, exportInaccessibilityServer),
    takeLatest(RegulatoryActionType.ADD_INACCESSIBILITY_SERVER, addInaccessibilityServer),
    takeLatest(RegulatoryActionType.DELETE_INACCESSIBILITY_SERVER, deleteInaccessibilityServer),
    takeLatest(RegulatoryActionType.GET_SIGNIFICANT_EVENTS_LARGE_TRANSACTIONS, getSignificantEventsLargeTransactions),
    takeLatest(RegulatoryActionType.EXPORT_SIGNIFICANT_EVENTS_LARGE_TRANSACTIONS, exportSignificantEventsLargeTransactions),
    takeLatest(RegulatoryActionType.GET_SIGNIFICANT_EVENTS_RESTRICTIONS, getSignificantEventsRestrictions),
    takeLatest(RegulatoryActionType.EXPORT_SIGNIFICANT_EVENTS_RESTRICTIONS, exportSignificantEventsRestrictions),
    takeLatest(RegulatoryActionType.GET_JACKPOT_WIN_RATIO, getJackPotWinRatio),
    takeLatest(RegulatoryActionType.EXPORT_JACKPOT_WIN_RATIO, exportJackPotWinRatio),
    takeLatest(RegulatoryActionType.GET_JACKPOT_CONFIGURATION, getJackPotConfiguration),
    takeLatest(RegulatoryActionType.EXPORT_JACKPOT_CONFIGURATION, exportJackPotConfiguration),
    takeLatest(RegulatoryActionType.GET_PROMOTIONAL_ITEMS, getPromotionalItems),
    takeLatest(RegulatoryActionType.EXPORT_PROMOTIONAL_ITEMS, exportPromotionalItems),
    takeLatest(RegulatoryActionType.GET_TAXATION_DAILY, getTaxationDaily),
    takeLatest(RegulatoryActionType.EXPORT_TAXATION_DAILY, exportTaxationDaily),
    takeLatest(RegulatoryActionType.GET_TAXATION_MONTHLY, getTaxationMonthly),
    takeLatest(RegulatoryActionType.EXPORT_TAXATION_MONTHLY, exportTaxationMonthly),
    takeLatest(RegulatoryActionType.EXPORT_BETTING_SELF_PROTECTION_REPORT, exportBettingSelfProtectionReport),
  ]);
}
