import PromoCodeActionType from './promoCodeActionType';

export const getPromoCodesPacks = (params) => ({
  type: PromoCodeActionType.GET_PROMO_CODES_PACKS,
  payload: { params },
});

export const savePromoCodesPacks = (data) => ({
  type: PromoCodeActionType.SAVE_PROMO_CODES_PACKS,
  payload: { data },
});

export const resetPromoCodesPacks = () => ({
  type: PromoCodeActionType.RESET_PROMO_CODES_PACKS,
});

export const getPromoCode = (id) => ({
  type: PromoCodeActionType.GET_PROMO_CODE,
  payload: { id },
});

export const savePromoCode = (data) => ({
  type: PromoCodeActionType.SAVE_PROMO_CODE,
  payload: { data },
});

export const resetPromoCode = () => ({
  type: PromoCodeActionType.RESET_PROMO_CODE,
});

export const getUsedPromoCodes = (id, params) => ({
  type: PromoCodeActionType.GET_USED_PROMO_CODES,
  payload: { id, params },
});

export const saveUsedPromoCodes = (data) => ({
  type: PromoCodeActionType.SAVE_USED_PROMO_CODES,
  payload: { data },
});

export const resetUsedPromoCodes = () => ({
  type: PromoCodeActionType.RESET_USED_PROMO_CODES,
});

export const exportAvailablePromoCodes = (id, params) => ({
  type: PromoCodeActionType.EXPORT_AVAILABLE_PROMO_CODES,
  payload: { id, params },
});

export const getIsPromoCodeExists = (brandId, params) => ({
  type: PromoCodeActionType.GET_IS_PROMO_CODE_EXISTS,
  payload: { brandId, params },
});

export const saveIsPromoCodeExists = (data) => ({
  type: PromoCodeActionType.SAVE_IS_PROMO_CODE_EXISTS,
  payload: { data },
});

export const resetIsPromoCodeExists = () => ({
  type: PromoCodeActionType.RESET_IS_PROMO_CODE_EXISTS,
});

export const getPromoCodes = (id) => ({
  type: PromoCodeActionType.GET_PROMO_CODES,
  payload: { id },
});

export const savePromoCodes = (data) => ({
  type: PromoCodeActionType.SAVE_PROMO_CODES,
  payload: { data },
});

export const resetPromoCodes = () => ({
  type: PromoCodeActionType.RESET_PROMO_CODES,
});

export const exportUsedPromoCodes = (id, params) => ({
  type: PromoCodeActionType.EXPORT_USED_PROMO_CODES,
  payload: { id, params },
});

export const getCsvPromoCodes = (data) => ({
  type: PromoCodeActionType.GET_CSV_PROMO_CODES,
  payload: { data },
});

export const saveCsvPromoCodes = (data) => ({
  type: PromoCodeActionType.SAVE_CSV_PROMO_CODES,
  payload: { data },
});

export const resetCsvPromoCodes = () => ({
  type: PromoCodeActionType.RESET_CSV_PROMO_CODES,
});

export const changePromoCodeEndedStatus = (status) => ({
  type: PromoCodeActionType.CHANGE_PROMO_CODE_ENDED_STATUS,
  payload: { status },
});
