import { createSelector } from 'reselect';

const selectPaymentDeposits = (state) => state.deposits;
export const selectPaymentDepositsData = (state) => selectPaymentDeposits(state).data;
export const selectPaymentDepositsTotal = (state) => selectPaymentDeposits(state).total;
const selectPaymentWithdrawals = (state) => state.withdrawals;
export const selectPaymentWithdrawalsData = (state) => selectPaymentWithdrawals(state).data;
export const selectPaymentWithdrawalsTotal = (state) => selectPaymentWithdrawals(state).total;
const selectPlayerDeposits = (state) => state.playerDeposits;
export const selectPlayerDepositsData = (state) => selectPlayerDeposits(state).data;
export const selectPlayerDepositsTotal = (state) => selectPlayerDeposits(state).total;
const selectPlayerWithdrawals = (state) => state.playerWithdrawals;
export const selectPlayerWithdrawalsData = (state) => selectPlayerWithdrawals(state).data;
export const selectPlayerWithdrawalsTotal = (state) => selectPlayerWithdrawals(state).total;
export const selectDepositTags = (state) => state.depositTags;
export const selectWithdrawalTags = (state) => state.withdrawalTags;
export const selectAssignedTagCategories = (state) => state.assignedTagCategories;
export const selectAssignedTags = (state) => state.assignedTags;

export const selectPaymentsNotes = (state) => state.notes;
export const selectPaymentNotes = (state, id) => createSelector(
  () => selectPaymentsNotes(state),
  (data) => data[id] || [],
)();

export const selectPaymentsLogs = (state) => state.logs;
export const selectPaymentLogs = (state, id) => createSelector(
  () => selectPaymentsLogs(state),
  (data) => data[id] || [],
)();

export const selectPaymentsDetails = (state) => state.details;
export const selectPaymentDetails = (state, id) => createSelector(
  () => selectPaymentsDetails(state),
  (data) => data[id] || {},
)();

export const selectPaymentsPayeeData = (state) => state.payeeData;
export const selectPaymentPayeeData = (state, id) => createSelector(
  () => selectPaymentsPayeeData(state),
  (data) => data?.[id] || {},
)();
