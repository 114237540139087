import {
  select, takeLatest, all, put, call,
} from 'redux-saga/effects';
import {
  NetworkService, NavigationService, LocalStorageService, i18n,
} from 'services';
import {
  getBrandSuccess,
  getBrandsSuccess,
  changeBrandLoadingState,
  getMarketsSuccess,
  getIsBrandExistSuccess,
  getTemplatesSuccess,
  changeBrandCallEndedStatus,
} from 'actions/brand/brandActionCreator';
import { changeLoadingState, setErrorMessage, setSuccessMessage } from 'actions/setting/settingActionCreator';
import { brandSettingLoader, setBrandMainSetting } from 'actions/brandSetting/brandSettingActionCreator';
import BrandActionType from 'actions/brand/brandActionType';
import { selectBrand } from 'selectors/brandSelector';
import { AppConstants } from 'constants/index';
import enumTypes from 'constants/enumTypes';
import { getError, getMessage } from 'utils/helpers';
import { selectLocaleResource } from 'selectors';
import axios from 'utils/axiosInterceptors';

const getBrandStatusNotification = (status, name, disabledVersionNames, deviceTypes) => {
  switch (status) {
  case enumTypes.brandStatuses.Active:
    return getMessage(i18n.t('brand:lBrand'), i18n.t('notification:slEnabled'), name);
  case enumTypes.brandStatuses.ActivitiesDisabled:
    return getMessage(i18n.t('brand:wageringActivities'), i18n.t('notification:plDisabled'));
  case enumTypes.brandStatuses.Disabled:
    return getMessage(i18n.t('brand:lBrand'), i18n.t('notification:slDisabled'), name);
  case enumTypes.brandStatuses.Maintenance:
    return getMessage(i18n.t('brand:maintenanceSetup'), i18n.t('notification:slCompleted'));
  default:
    return getMessage('', i18n.t(disabledVersionNames.length > 1 ? 'notification:plDisabled' : 'notification:slDisabled'), disabledVersionNames.map((el) => deviceTypes[el]).join(', '));
  }
};

function* getBrand({ payload }) {
  try {
    yield put(brandSettingLoader(true));
    const { BrandAdmin, Brand } = AppConstants.api;
    const { data } = yield call(NetworkService.makeAPIGetRequest, [BrandAdmin, Brand, payload.id]);
    yield put(getBrandSuccess(data));
    const { mainCountry: country, mainLanguage: language, mainCurrency: currency } = data;
    yield put(setBrandMainSetting({ country, language, currency }));
    yield put(brandSettingLoader(false));
  } catch (err) {
    if (err) {
      const error = getError(err, payload.statusCode);
      yield put(setErrorMessage(error));
    }
    yield put(brandSettingLoader(false));
  }
}

function* getBrands({ payload }) {
  try {
    yield put(changeLoadingState(true));
    const { BrandAdmin, Brand, GetPartnerBrands } = AppConstants.api;
    const response = yield call(NetworkService.makeAPIGetRequest, [BrandAdmin, Brand, GetPartnerBrands], {
      params: payload,
    });
    yield put(getBrandsSuccess(response.data));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* addBrand({ payload }) {
  try {
    const { BrandAdmin, Brand } = AppConstants.api;
    yield put(changeLoadingState(true));
    yield call(NetworkService.makeAPIPostRequest, [BrandAdmin, Brand], payload);
    yield put(changeLoadingState(false));
    yield put(setSuccessMessage(getMessage(i18n.t('brand:lBrand'), i18n.t('notification:slCreated'), payload.data.name)));
    const auth = LocalStorageService.get('auth');
    auth.access_token = '';
    LocalStorageService.set('auth', auth);
    delete axios.defaults.headers.common.Authorization;
    NavigationService('/brands');
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* activateBrand({ payload }) {
  try {
    const { data } = payload;
    const {
      id, status, name, disabledVersionNames,
    } = data;
    yield put(changeLoadingState(true));
    const { BrandAdmin, Brand, Status } = AppConstants.api;
    yield call(NetworkService.makeAPIPutRequest, [BrandAdmin, Brand, Status, id], payload);
    const state = yield select();
    const brand = selectBrand(state.brands);
    const { deviceTypes } = selectLocaleResource(state.locale, { deviceTypes: enumTypes.deviceTypes });
    yield put(changeBrandCallEndedStatus(true));
    yield put(getBrandSuccess({ ...brand, ...data }));
    yield put(setSuccessMessage(getBrandStatusNotification(status, name, disabledVersionNames, deviceTypes)));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getMarkets() {
  try {
    yield put(changeBrandLoadingState(true));
    const { BrandAdmin, Brand, GetMarkets } = AppConstants.api;
    const { data } = yield call(NetworkService.makeAPIGetRequest, [BrandAdmin, Brand, GetMarkets]);
    yield put(getMarketsSuccess(data));
    yield put(changeBrandLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeBrandLoadingState(false));
  }
}

function* getIsBrandExist({ payload }) {
  try {
    const { partnerId, name } = payload;
    yield put(changeLoadingState(true));
    const { BrandAdmin, Brand, BrandNameExist } = AppConstants.api;
    const { data } = yield call(NetworkService.makeAPIGetRequest, [BrandAdmin, Brand, BrandNameExist, partnerId, name]);
    yield put(getIsBrandExistSuccess(data));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getTemplates() {
  try {
    yield put(changeBrandLoadingState(true));
    const { BrandAdmin, Brand, Templates } = AppConstants.api;
    const { data } = yield call(NetworkService.makeAPIGetRequest, [BrandAdmin, Brand, Templates]);
    yield put(getTemplatesSuccess(data));
    yield put(changeBrandLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeBrandLoadingState(false));
  }
}

function* editBrandTimeZone({ payload }) {
  try {
    const { brandId, params } = payload;

    const options = {
      params,
    };
    yield put(changeLoadingState(true));
    const { BrandAdmin, Brand, TimeZone } = AppConstants.api;
    yield call(NetworkService.makeAPIPutRequest, [BrandAdmin, Brand, TimeZone, brandId], options);
    const state = yield select();
    const brand = selectBrand(state.brands);
    yield put(getBrandSuccess({ ...brand, timeZone: params.timeZone }));
    yield put(changeBrandCallEndedStatus(true));
    yield put(setSuccessMessage(getMessage(i18n.t('timeZone'), i18n.t('notification:slUpdated'))));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* editBrand({ payload }) {
  try {
    const { brandId, data } = payload;

    const options = {
      data,
    };

    yield put(changeLoadingState(true));
    const { BrandAdmin, Brand } = AppConstants.api;
    yield call(NetworkService.makeAPIPutRequest, [BrandAdmin, Brand, brandId], options);
    const state = yield select();
    const brand = selectBrand(state.brands);
    yield put(getBrandSuccess({ ...brand, name: data.name }));
    yield put(changeBrandCallEndedStatus(true));
    yield put(setSuccessMessage(getMessage(i18n.t('brand:theBrand'), i18n.t('notification:slUpdated'))));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

export default function* brandSaga() {
  yield all([
    takeLatest(BrandActionType.GET_BRAND, getBrand),
    takeLatest(BrandActionType.GET_BRANDS, getBrands),
    takeLatest(BrandActionType.ADD_BRAND, addBrand),
    takeLatest(BrandActionType.ACTIVATE_BRAND, activateBrand),
    takeLatest(BrandActionType.GET_BRAND_MARKETS, getMarkets),
    takeLatest(BrandActionType.GET_IS_BRAND_EXIST, getIsBrandExist),
    takeLatest(BrandActionType.GET_BRAND_TEMPLATES, getTemplates),
    takeLatest(BrandActionType.EDIT_BRAND_TIME_ZONE, editBrandTimeZone),
    takeLatest(BrandActionType.EDIT_BRAND, editBrand),
  ]);
}
