import { connect } from 'react-redux';
import NoAccess from 'components/common/noAccess/NoAccess';
import { selectUserPermissions, selectHasClaimType } from 'selectors';

import enumTypes from 'constants/enumTypes';

const mapStateToProps = ({ auth }) => ({
  permissions: selectUserPermissions(auth),
  isSuperAdmin: selectHasClaimType(auth, enumTypes.userClaimTypes.SuperAdmin),
});

export default connect(mapStateToProps)(NoAccess);
