import BlacklistActionType from './blacklistActionType';

export const getBlacklist = (data, params) => ({
  type: BlacklistActionType.GET_BLACKLIST,
  payload: { data, params },
});

export const saveBlacklist = (data) => ({
  type: BlacklistActionType.SAVE_BLACKLIST,
  payload: { data },
});

export const resetBlacklist = () => ({
  type: BlacklistActionType.RESET_BLACKLIST,
});

export const exportBlacklist = (data, params) => ({
  type: BlacklistActionType.EXPORT_BLACKLIST,
  payload: { data, params },
});

export const importBlacklist = (brandId, data) => ({
  type: BlacklistActionType.IMPORT_BLACKLIST,
  payload: { brandId, data },
});

export const getCsvBlacklist = (data) => ({
  type: BlacklistActionType.GET_CSV_BLACKLIST,
  payload: { data },
});

export const saveCSVBlacklist = (data) => ({
  type: BlacklistActionType.SAVE_CSV_BLACKLIST,
  payload: { data },
});

export const resetCSVBlacklist = () => ({
  type: BlacklistActionType.RESET_CSV_BLACKLIST,
});

export const deleteBlacklist = (data) => ({
  type: BlacklistActionType.DELETE_BLACKLIST,
  payload: { data },
});

export const changeEndedStatus = (status) => ({
  type: BlacklistActionType.CHANGE_BLACKLIST_ENDED_STATUS,
  payload: { status },
});

export const changePlayersBlacklistedStatus = (brandId, data, isPlural) => ({
  type: BlacklistActionType.CHANGE_PLAYERS_BLACKLISTED_STATUS,
  payload: { brandId, data, isPlural },
});

export const changePlayerBlacklistedStatus = (playerId, params) => ({
  type: BlacklistActionType.CHANGE_PLAYER_BLACKLISTED_STATUS,
  payload: { playerId, params },
});
