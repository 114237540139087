import DashboardActionType from 'actions/dashboard/dashboardActionType';

const initialState = {
  dashboards: [],
  widgets: {},
  lastRequestedWidget: 0,
  widgetsData: {},
  widgetsLoading: {},
  applyToWidget: 0,
  endedStatus: false,
};

const dashboardReducer = (state = initialState, { type, payload }) => {
  switch (type) {
  case DashboardActionType.SAVE_DASHBOARDS:
    return {
      ...state,
      dashboards: payload.data,
    };
  case DashboardActionType.RESET_DASHBOARDS:
    return {
      ...state,
      dashboards: [],
    };
  case DashboardActionType.SAVE_WIDGETS:
    return {
      ...state,
      widgets: payload.data,
    };
  case DashboardActionType.RESET_WIDGETS:
    return {
      ...state,
      widgets: {},
    };
  case DashboardActionType.SAVE_LAST_REQUESTED_WIDGET:
    return {
      ...state,
      lastRequestedWidget: payload.data,
    };
  case DashboardActionType.RESET_LAST_REQUESTED_WIDGET:
    return {
      ...state,
      lastRequestedWidget: 0,
    };
  case DashboardActionType.SAVE_APPLY_TO_WIDGET:
    return {
      ...state,
      applyToWidget: payload.data,
    };
  case DashboardActionType.RESET_APPLY_TO_WIDGET:
    return {
      ...state,
      applyToWidget: 0,
    };
  case DashboardActionType.SAVE_WIDGET_DATA:
    return {
      ...state,
      widgetsData: {
        ...state.widgetsData,
        [payload.id]: payload.data,
      },
    };
  case DashboardActionType.RESET_WIDGETS_DATA:
    return {
      ...state,
      widgetsData: {},
    };
  case DashboardActionType.SAVE_WIDGET_LOADING:
    return {
      ...state,
      widgetsLoading: {
        ...state.widgetsLoading,
        [payload.id]: payload.loading,
      },
    };
  case DashboardActionType.CHANGE_DASHBOARD_ENDED_STATUS:
    return {
      ...state,
      endedStatus: payload.status,
    };
  default:
    return state;
  }
};

export default dashboardReducer;
