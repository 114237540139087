import { createSelector } from 'reselect';
import _ from 'lodash';

export const selectPermissionsData = (state) => state.permissions;
export const selectPermissions = (state, selectData = () => state.permissions, key = 'first') => createSelector(
  () => selectData(state, key),
  (items) => {
    const data = {};
    if (items?.length) {
      items.forEach((el) => {
        data[el.resource.category] = data[el.resource.category] || {};
        data[el.resource.category][el.resource.name] = data[el.resource.category][el.resource.name] || [];
        data[el.resource.category][el.resource.name].push(el);
      });
    }
    return data;
  },
)();
export const selectResources = (state) => state.resources;
export const selectPermissionsLoading = (state) => state.loading;
export const selectPermissionsResource = (state, selectData = () => state.permissionsResource) => createSelector(
  () => selectData(state),
  (items) => {
    const data = {};
    if (items && items.length) {
      items.forEach((el) => {
        data[el.resource.category] = data[el.resource.category] || {};
        data[el.resource.category][el.resource.name] = data[el.resource.category][el.resource.name] || [];
        data[el.resource.category][el.resource.name].push(el);
      });
    }
    return data;
  },
)();

export const selectUserManagementResource = createSelector(
  (state) => state.userManagementResource,
  (userManagementResource) => _.sortBy(userManagementResource),
);

export const selectAllActionResource = createSelector(
  (state) => state.allActionResource,
  (allActionResource) => _.sortBy(allActionResource),
);
