export default {
  en: {
    currencyFrom: 'Currency From',
    editConversionInfo: 'The conversion is always to {{currency}}',
    calculator: 'Calculator',
    releaseNotes: 'Release Notes',
    whoops: 'Whoops!',
    noContentMessage: "Sorry, there aren't any changes that pertain to you",
    gotIt: 'Got It!',
    skip: 'Skip',
    previous: 'Previous',
    createNote: 'Create Release Note',
    editNote: 'Edit Release Note',
    releaseName: 'Release Name',
    releaseDate: 'Release Date',
    changeName: 'Change Name',
    label: 'Label',
    lReleaseNote: 'release note',
    noReleaseNote: 'no release note',
    hasPermission: 'Has Permission',
  },
  es: {
    currencyFrom: 'Moneda de',
    editConversionInfo: 'La conversión es siempre a {{currency}}',
    calculator: 'Calculadora',
    releaseNotes: 'Notas de Publicación',
    whoops: '¡Ups!',
    noContentMessage: 'Lo sentimos, no hay cambios que le conciernan.',
    gotIt: '¡Ya lo tengo!',
    skip: 'Saltar',
    previous: 'Anterior',
    createNote: 'Crear Nota de Publicación',
    editNote: 'Editar Nota de Publicación',
    releaseName: 'Nombre de la Publicación',
    releaseDate: 'Fecha de Publicación',
    changeName: 'Cambiar el Nombre',
    label: 'Label',
    lReleaseNote: 'Nota de publicación',
    noReleaseNote: 'Sin nota de lanzamiento',
  },
  tr: {
    currencyFrom: 'Para Biriminden',
    editConversionInfo: "Para dönüṣümü her zaman {{currency}} 'a",
    calculator: 'Hesap makinesi',
    releaseNotes: 'Sürüm Notları',
    whoops: 'Ops!',
    noContentMessage: 'Maalesef, konuyla ilgili herhangi bir değişiklik yok',
    gotIt: 'Tamam!',
    skip: 'Atla',
    previous: 'Önceki',
    createNote: 'Sürüm Notu Oluştur',
    editNote: 'Sürüm Notunu Düzenle',
    releaseName: 'Sürüm Adı',
    releaseDate: 'Sürüm Tarihi',
    changeName: 'İsmi Değiştir',
    label: 'Etiket',
    lReleaseNote: 'sürüm notu',
    noReleaseNote: 'sürüm notu yok',
  },
  ru: {
    currencyFrom: 'Валюта из',
    editConversionInfo: 'Конвертация всегда проводится в {{currency}}',
    calculator: 'Калькулятор',
    releaseNotes: 'Примечания к релизу',
    whoops: 'Ой!',
    noContentMessage: 'Извините, никаких изменений, касающихся вас, не произошло.',
    gotIt: 'Понятно',
    skip: 'Пропустить',
    previous: 'Предыдущий',
    createNote: 'Создать примечание к релизу',
    editNote: 'Редактировать примечание к релизу',
    releaseName: 'Название релиза',
    releaseDate: 'Дата релиза',
    changeName: 'Поменять названуе',
    label: 'Лейбл',
    lReleaseNote: 'примечание к релизу',
    noReleaseNote: 'примечание к релизу отсутствует',
  },
  pt: {
    currencyFrom: 'Moeda de',
    editConversionInfo: 'A conversão é sempre para {{currency}}',
    calculator: 'Calculadora',
    releaseNotes: 'Notas de lançamento',
    whoops: 'Ops!',
    noContentMessage: 'Desculpe, não há alterações relacionadas a você',
    gotIt: 'Já está!',
    skip: 'Saltar',
    previous: 'Anterior',
    createNote: 'Criar nota de lançamento',
    editNote: 'Edita nota de lançamento',
    releaseName: 'Nome do lançamento',
    releaseDate: 'Data de lançamento',
    changeName: 'Alterar o nome',
    label: 'Etiqueta',
    lReleaseNote: 'nota de lançamento',
    noReleaseNote: 'sem nota de lançamento',
  },
  zh: {
    currencyFrom: '货币来源',
    editConversionInfo: '货币始终兑换为 {{currency}}',
    calculator: '计算器',
    releaseNotes: '发布说明',
    whoops: '哎呀！',
    noContentMessage: '抱歉，没有任何与您相关的更改',
    gotIt: '明白了！',
    skip: '跳过',
    previous: '先前',
    createNote: '创建发布说明',
    editNote: '编辑发布说明',
    releaseName: '发布名称',
    releaseDate: '发布日期',
    changeName: '更改名称',
    label: '标签',
    lReleaseNote: '发布说明',
    noReleaseNote: '无发布说明',
  },
  ro: {
    currencyFrom: 'Monedă din ',
    editConversionInfo: 'Conversia valutară va fi întotdeauna efectuată în {{currency}}',
    calculator: 'Calculator',
  },
  bg: {},
  el: {
    currencyFrom: 'Νόμισμα από',
    editConversionInfo: 'Η μετατροπή είναι πάντα προς {{currency}}',
    calculator: 'Εργαλείο υπολογισμού',
  },
};
