import TemplateActionType from 'actions/template/templateActionType';

const initialState = {
  ended: false,
  templates: {
    system: [],
    custom: [],
  },
  total: {
    system: 0,
    custom: 0,
  },
  template: {},
  systemTemplateTypes: {},
  customTemplatesResource: [],
};

const templateReducer = (state = initialState, { type, payload }) => {
  switch (type) {
  case TemplateActionType.CHANGE_ENDED_STATUS:
    return {
      ...state,
      ended: payload.status,
    };
  case TemplateActionType.SAVE_TEMPLATES:
    return {
      ...state,
      templates: payload.data,
    };
  case TemplateActionType.RESET_TEMPLATES:
    return {
      ...state,
      templates: {
        system: [],
        custom: [],
      },
      total: {
        system: 0,
        custom: 0,
      },
    };
  case TemplateActionType.SAVE_TEMPLATES_TOTAL:
    return {
      ...state,
      total: payload.data,
    };
  case TemplateActionType.SAVE_TEMPLATE:
    return {
      ...state,
      template: payload.data,
    };
  case TemplateActionType.RESET_TEMPLATE:
    return {
      ...state,
      template: {},
    };
  case TemplateActionType.SAVE_SYSTEM_TEMPLATE_TYPES:
    return {
      ...state,
      systemTemplateTypes: payload.data,
    };
  case TemplateActionType.SAVE_CUSTOM_TEMPLATES_RESOURCE:
    return {
      ...state,
      customTemplatesResource: payload.data,
    };
  case TemplateActionType.RESET_CUSTOM_TEMPLATES_RESOURCE:
    return {
      ...state,
      customTemplatesResource: [],
    };
  default:
    return state;
  }
};

export default templateReducer;
