import AgentSystemActionType from 'actions/agentSystem/agentSystemActionType';

export const getAgentSystemHistory = (params) => ({
  type: AgentSystemActionType.GET_AGENT_SYSTEM_HISTORY,
  payload: { params },
});

export const saveAgentSystemHistory = (data) => ({
  type: AgentSystemActionType.SAVE_AGENT_SYSTEM_HISTORY,
  payload: { data },
});

export const resetAgentSystemHistory = () => ({
  type: AgentSystemActionType.RESET_AGENT_SYSTEM_HISTORY,
});

export const resendAgentSystemData = (id) => ({
  type: AgentSystemActionType.RESEND_AGENT_SYSTEM_DATA,
  payload: { id },
});
