import styled from 'styled-components';

const StyledTXNDetails = styled.div`
  font-size: 1rem;
  color: ${(props) => props.theme.colors.blue};
  margin: 0.5rem 0 0.85rem;
  padding-left: 1.71rem;
`;

const StyledCasinoBetTransactionsWrapper = styled.div`
  height: 100%;
  .ant-table.ant-table-middle .ant-table-tbody > tr > td.ant-table-row-expand-icon-cell > div {
    display: none;
  }
  .ant-table-row.odd {
    background-color: ${(props) => props.theme.colors.tableTrEvenColor};
  }
  .ant-tabs {
    &-nav {
      margin-bottom: 1.14rem;
    }
  }
`;
const StyledExpandWrapper = styled.div`
  .ant-table-row {
    background: ${(props) => props.theme.colors.secondaryBg};
  }
  .ant-table.ant-table-middle .ant-table-thead > tr > th {
    background: ${(props) => props.theme.colors.secondaryBg};
    font-weight: ${(props) => props.theme.typography.fontWeightMedium};
  }
`;

const StyledSportCouponWrapper = styled.div`
  div {
    max-width: 12rem;
  }
`;

const StyledViewTable = styled.div`
  .ant-table.ant-table-middle {
    .ant-table-row-expand-icon-cell {
      display: none;
    }
  }
`;
export {
  StyledTXNDetails, StyledCasinoBetTransactionsWrapper, StyledExpandWrapper, StyledSportCouponWrapper, StyledViewTable,
};
