const TagActionType = {
  GET_VERTICAL_TAG_CATEGORIES: 'GET_VERTICAL_TAG_CATEGORIES',
  SAVE_VERTICAL_TAG_CATEGORIES: 'SAVE_VERTICAL_TAG_CATEGORIES',
  RESET_VERTICAL_TAG_CATEGORIES: 'RESET_VERTICAL_TAG_CATEGORIES',

  CREATE_VERTICAL_TAG_CATEGORY: 'CREATE_VERTICAL_TAG_CATEGORY',
  EDIT_VERTICAL_TAG_CATEGORY: 'EDIT_VERTICAL_TAG_CATEGORY',
  DELETE_VERTICAL_TAG_CATEGORY: 'DELETE_VERTICAL_TAG_CATEGORY',
  CHANGE_VERTICAL_TAG_CATEGORY_STATUS: 'CHANGE_VERTICAL_TAG_CATEGORY_STATUS',
  CHANGE_VERTICAL_TAG_CATEGORY_PRIORITY: 'CHANGE_VERTICAL_TAG_CATEGORY_PRIORITY',

  GET_VERTICAL_TAG_CATEGORY: 'GET_VERTICAL_TAG_CATEGORY',
  SAVE_VERTICAL_TAG_CATEGORY: 'SAVE_VERTICAL_TAG_CATEGORY',
  RESET_VERTICAL_TAG_CATEGORY: 'RESET_VERTICAL_TAG_CATEGORY',

  GET_HORIZONTAL_TAG_CATEGORIES: 'GET_HORIZONTAL_TAG_CATEGORIES',
  SAVE_HORIZONTAL_TAG_CATEGORIES: 'SAVE_HORIZONTAL_TAG_CATEGORIES',
  RESET_HORIZONTAL_TAG_CATEGORIES: 'RESET_HORIZONTAL_TAG_CATEGORIES',

  GET_HORIZONTAL_TAG_CATEGORY: 'GET_HORIZONTAL_TAG_CATEGORY',
  SAVE_HORIZONTAL_TAG_CATEGORY: 'SAVE_HORIZONTAL_TAG_CATEGORY',
  RESET_HORIZONTAL_TAG_CATEGORY: 'RESET_HORIZONTAL_TAG_CATEGORY',

  CREATE_HORIZONTAL_TAG_CATEGORY: 'CREATE_HORIZONTAL_TAG_CATEGORY',
  EDIT_HORIZONTAL_TAG_CATEGORY: 'EDIT_HORIZONTAL_TAG_CATEGORY',
  DELETE_HORIZONTAL_TAG_CATEGORY: 'DELETE_HORIZONTAL_TAG_CATEGORY',

  GET_HORIZONTAL_TAGS: 'GET_HORIZONTAL_TAGS',
  SAVE_HORIZONTAL_TAGS: 'SAVE_HORIZONTAL_TAGS',
  RESET_HORIZONTAL_TAGS: 'RESET_HORIZONTAL_TAGS',

  GET_HORIZONTAL_TAG: 'GET_HORIZONTAL_TAG',
  SAVE_HORIZONTAL_TAG: 'SAVE_HORIZONTAL_TAG',
  RESET_HORIZONTAL_TAG: 'RESET_HORIZONTAL_TAG',

  GET_VERTICAL_TAGS: 'GET_VERTICAL_TAGS',
  SAVE_VERTICAL_TAGS: 'SAVE_VERTICAL_TAGS',
  RESET_VERTICAL_TAGS: 'RESET_VERTICAL_TAGS',

  GET_VERTICAL_TAG: 'GET_VERTICAL_TAG',
  SAVE_VERTICAL_TAG: 'SAVE_VERTICAL_TAG',
  RESET_VERTICAL_TAG: 'RESET_VERTICAL_TAG',

  CREATE_VERTICAL_TAGS: 'CREATE_VERTICAL_TAGS',
  EDIT_VERTICAL_TAG: 'EDIT_VERTICAL_TAG',
  CHANGE_VERTICAL_TAG_STATUS: 'CHANGE_VERTICAL_TAG_STATUS',
  DELETE_VERTICAL_TAGS: 'DELETE_VERTICAL_TAGS',
  MERGE_VERTICAL_TAGS: 'MERGE_VERTICAL_TAGS',

  CREATE_HORIZONTAL_TAGS: 'CREATE_HORIZONTAL_TAGS',
  EDIT_HORIZONTAL_TAG: 'EDIT_HORIZONTAL_TAG',
  DELETE_HORIZONTAL_TAGS: 'DELETE_HORIZONTAL_TAGS',
  MERGE_HORIZONTAL_TAGS: 'MERGE_HORIZONTAL_TAGS',
  MOVE_HORIZONTAL_TAGS: 'MOVE_HORIZONTAL_TAGS',

  ASSIGN_VERTICAL_TAGS_PLAYERS: 'ASSIGN_VERTICAL_TAGS_PLAYERS',
  ASSIGN_HORIZONTAL_TAGS_PLAYERS: 'ASSIGN_HORIZONTAL_TAGS_PLAYERS',

  GET_HORIZONTAL_TAGS_PLAYERS: 'GET_HORIZONTAL_TAGS_PLAYERS',
  SAVE_HORIZONTAL_TAGS_PLAYERS: 'SAVE_HORIZONTAL_TAGS_PLAYERS',
  RESET_HORIZONTAL_TAGS_PLAYERS: 'RESET_HORIZONTAL_TAGS_PLAYERS',

  GET_VERTICAL_TAGS_PLAYERS: 'GET_VERTICAL_TAGS_PLAYERS',
  SAVE_VERTICAL_TAGS_PLAYERS: 'SAVE_VERTICAL_TAGS_PLAYERS',
  RESET_VERTICAL_TAGS_PLAYERS: 'RESET_VERTICAL_TAGS_PLAYERS',

  DELETE_PLAYERS_VERTICAL_TAGS: 'DELETE_PLAYERS_VERTICAL_TAGS',
  DELETE_PLAYERS_HORIZONTAL_TAGS: 'DELETE_PLAYERS_HORIZONTAL_TAGS',

  GET_PLAYER_HORIZONTAL_TAGS: 'GET_PLAYER_HORIZONTAL_TAGS',
  SAVE_PLAYER_HORIZONTAL_TAGS: 'SAVE_PLAYER_HORIZONTAL_TAGS',
  RESET_PLAYER_HORIZONTAL_TAGS: 'RESET_PLAYER_HORIZONTAL_TAGS',

  DELETE_PLAYER_VERTICAL_TAG: 'DELETE_PLAYER_VERTICAL_TAG',
  DELETE_PLAYER_HORIZONTAL_TAG: 'DELETE_PLAYER_HORIZONTAL_TAG',

  GET_PLAYER_VERTICAL_TAGS: 'GET_PLAYER_VERTICAL_TAGS',
  SAVE_PLAYER_VERTICAL_TAGS: 'SAVE_PLAYER_VERTICAL_TAGS',
  RESET_PLAYER_VERTICAL_TAGS: 'RESET_PLAYER_VERTICAL_TAGS',

  ASSIGN_VERTICAL_TAG_PLAYER: 'ASSIGN_VERTICAL_TAG_PLAYER',
  ASSIGN_HORIZONTAL_TAGS_PLAYER: 'ASSIGN_HORIZONTAL_TAGS_PLAYER',

  CREATE_HORIZONTAL_TAGS_AND_ASSIGN_PLAYER: 'CREATE_HORIZONTAL_TAGS_AND_ASSIGN_PLAYER',

  GET_PLAYER_TOP_TAGS: 'GET_PLAYER_TOP_TAGS',
  SAVE_PLAYER_TOP_TAGS: 'SAVE_PLAYER_TOP_TAGS',
  RESET_PLAYER_TOP_TAGS: 'RESET_PLAYER_TOP_TAGS',

  CHANGE_PLAYER_TOP_TAGS: 'CHANGE_PLAYER_TOP_TAGS',

  EXPORT_HORIZONTAL_TAGS_PLAYERS: 'EXPORT_HORIZONTAL_TAGS_PLAYERS',
  EXPORT_VERTICAL_TAGS_PLAYERS: 'EXPORT_VERTICAL_TAGS_PLAYERS',

  GET_PLAYER_TAG_LOGS: 'GET_PLAYER_TAG_LOGS',
  SAVE_PLAYER_TAG_LOGS: 'SAVE_PLAYER_TAG_LOGS',
  RESET_PLAYER_TAG_LOGS: 'RESET_PLAYER_TAG_LOGS',
  EXPORT_PLAYER_TAG_LOGS: 'EXPORT_PLAYER_TAG_LOGS',

  GET_TAG_LOGS: 'GET_TAG_LOGS',
  SAVE_TAG_LOGS: 'SAVE_TAG_LOGS',
  RESET_TAG_LOGS: 'RESET_TAG_LOGS',
  EXPORT_TAG_LOGS: 'EXPORT_TAG_LOGS',
};

export default TagActionType;
