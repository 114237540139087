import { withTheme } from 'styled-components';
import PropTypes from 'prop-types';
import { Translation } from 'react-i18next';
import _ from 'lodash';
import { StyledMessageWrapper } from 'components/player/detail/Account.styled';
import IconSvg from 'components/common/icon/IconSvg';

const FormItemErrorMessage = (props) => {
  const { errorMassage } = props;
  const {
    theme: { colors },
  } = props;

  return (
    <StyledMessageWrapper>
      <IconSvg icon="CrossThinIcon" width="1.43rem" height="1.43rem" color={colors.danger} />
      <span>{_.isString(errorMassage) ? errorMassage : <Translation>{(translate) => errorMassage(translate)}</Translation>}</span>
    </StyledMessageWrapper>
  );
};
FormItemErrorMessage.propTypes = {
  theme: PropTypes.object.isRequired,
  errorMassage: PropTypes.oneOfType([PropTypes.string, PropTypes.func]).isRequired,
};

export default withTheme(FormItemErrorMessage);
