import {
  all, call, put, takeLatest,
} from 'redux-saga/effects';
import qs from 'qs';
import mime from 'mimetypes';
import {
  changeEndedStatus, changeLoadingState, setErrorMessage, setSuccessMessage,
} from 'actions/setting/settingActionCreator';
import NetworkService from 'services/networkService';
import { AppConstants } from 'constants/index';
import { downloadFile } from 'utils/utils';
import { getError, getMessage } from 'utils/helpers';

import {
  saveLobbies,
  saveLobby,
  saveLobbyProvider,
  saveLobbyProviders,
  saveLobbyProviderGamesOrders,
  saveLobbyProvidersOrders,
  saveCsvLobbyProviderGamesOrders,
  saveCsvLobbyProvidersOrders,
  saveLobbyLogs,
  saveLobbyGameGroups,
  saveLobbyGameGroup,
  saveLobbyGeneralWidgets,
  saveLobbyJackpotWidgets,
  saveLobbyGeneralWidget,
  saveLobbyJackpotWidget,
  saveLobbyHomeWidget,
  saveLobbyHomeWidgets,
} from 'actions/lobbyManagement/lobbyManagementActionCreator';
import { i18n, MessageService } from 'services';
import LobbyManagementActionType from 'actions/lobbyManagement/lobbyManagementActionType';

function* getLobbies({ payload }) {
  try {
    const { params } = payload;
    const options = {
      params,
    };
    const { Transaction, Lobbies } = AppConstants.api;
    yield put(changeLoadingState(true));
    const { data } = yield call(NetworkService.makeAPIGetRequest, [Transaction, Lobbies], options);
    yield put(saveLobbies(data));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getLobby({ payload }) {
  try {
    const { params, id } = payload;
    const options = {
      params,
    };
    const { Transaction, Lobbies } = AppConstants.api;
    yield put(changeLoadingState(true));
    const { data } = yield call(NetworkService.makeAPIGetRequest, [Transaction, Lobbies, id], options);
    yield put(saveLobby(data));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* editLobby({ payload }) {
  try {
    const { data, id, name } = payload;
    const options = {
      data,
    };
    const { Transaction, Lobbies } = AppConstants.api;
    yield put(changeLoadingState(true));
    yield call(NetworkService.makeAPIPutRequest, [Transaction, Lobbies, id], options);
    yield put(setSuccessMessage(getMessage('', i18n.t('notification:slUpdated'), name)));
    yield put(changeEndedStatus(true));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* changeLobbyStatus({ payload }) {
  try {
    const { params, id, name } = payload;
    const options = {
      params,
    };
    const { Transaction, Lobbies, Status } = AppConstants.api;
    yield put(changeLoadingState(true));
    yield call(NetworkService.makeAPIPutRequest, [Transaction, Lobbies, id, Status], options);
    yield put(setSuccessMessage(getMessage('', i18n.t(!params.enable ? 'notification:slDisabled' : 'notification:slEnabled'), name)));
    yield put(changeEndedStatus(true));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getLobbyProviders({ payload }) {
  try {
    const { id, params } = payload;
    const options = {
      params,
      paramsSerializer: (param) => qs.stringify(param),
    };
    const { Transaction, Lobbies, Providers } = AppConstants.api;
    yield put(changeLoadingState(true));
    const { data } = yield call(NetworkService.makeAPIGetRequest, [Transaction, Lobbies, id, Providers], options);
    yield put(saveLobbyProviders(data));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* changeLobbyProviderStatus({ payload }) {
  try {
    const {
      params, lobbyId, providerId, name,
    } = payload;
    const options = {
      params,
    };
    const {
      Transaction, Lobbies, Providers, Status,
    } = AppConstants.api;
    yield put(changeLoadingState(true));
    yield call(NetworkService.makeAPIPutRequest, [Transaction, Lobbies, lobbyId, Providers, providerId, Status], options);
    yield put(changeEndedStatus(true));
    yield put(setSuccessMessage(getMessage('', i18n.t(!params.enable ? 'notification:slDisabled' : 'notification:slEnabled'), name)));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* editLobbyProvider({ payload }) {
  try {
    const {
      lobbyId, data, providerId, name,
    } = payload;
    const options = {
      data,
    };
    const { Transaction, Lobbies, Providers } = AppConstants.api;
    yield put(changeLoadingState(true));
    yield call(NetworkService.makeAPIPutRequest, [Transaction, Lobbies, lobbyId, Providers, providerId], options);
    yield put(setSuccessMessage(getMessage('', i18n.t('notification:slUpdated'), name)));
    yield put(changeEndedStatus(true));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getLobbyProvider({ payload }) {
  try {
    const { providerId, lobbyId, params } = payload;
    const options = {
      params,
      paramsSerializer: (param) => qs.stringify(param),
    };
    const { Transaction, Lobbies, Providers } = AppConstants.api;
    yield put(changeLoadingState(true));
    const { data } = yield call(NetworkService.makeAPIGetRequest, [Transaction, Lobbies, lobbyId, Providers, providerId], options);
    yield put(saveLobbyProvider(data));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* exportLobbyProviders({ payload }) {
  try {
    const { lobbyId, params } = payload;
    const options = {
      params,
      paramsSerializer: (param) => qs.stringify(param, { allowDots: true }),
      responseType: 'blob',
    };
    yield put(changeLoadingState(true));
    const {
      Transaction, Lobbies, Export, Providers,
    } = AppConstants.api;
    const { data, headers } = yield call(NetworkService.makeAPIGetRequest, [Transaction, Lobbies, lobbyId, Providers, Export], options);
    downloadFile(data, i18n.t('cms:lobbyProviders'), mime.detectExtension(headers['content-type']));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getLobbyProvidersOrders({ payload }) {
  try {
    const { params, lobbyId } = payload;
    const options = {
      params,
    };
    const {
      Transaction, Lobbies, Providers, Order,
    } = AppConstants.api;
    yield put(changeLoadingState(true));
    const { data } = yield call(NetworkService.makeAPIGetRequest, [Transaction, Lobbies, lobbyId, Providers, Order], options);
    yield put(saveLobbyProvidersOrders(data));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* editLobbyProvidersOrders({ payload }) {
  try {
    const {
      lobbyId, data, params, name,
    } = payload;
    const options = {
      data,
      params,
    };
    const {
      Transaction, Lobbies, Providers, Order,
    } = AppConstants.api;
    yield put(changeLoadingState(true));
    yield call(NetworkService.makeAPIPostRequest, [Transaction, Lobbies, lobbyId, Providers, Order], options);
    yield put(setSuccessMessage(getMessage('', i18n.t('notification:slUpdated'), name)));
    yield put(changeEndedStatus(true));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getCsvLobbyProvidersOrders({ payload }) {
  try {
    const { lobbyId } = payload;
    yield put(changeLoadingState(true));
    const {
      Transaction, Lobbies, Providers, Upload,
    } = AppConstants.api;
    const options = {
      data: payload.data,
      headers: { 'content-type': 'multipart/form-data' },
    };
    const { data } = yield call(NetworkService.makeAPIPostRequest, [Transaction, Lobbies, lobbyId, Providers, Upload], options);
    yield put(saveCsvLobbyProvidersOrders(data));
    yield put(changeLoadingState(false));
    if (!data.length) {
      MessageService.error(i18n.t('notification:emptyFile'));
    }
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

/**/
function* getLobbyProviderGamesOrders({ payload }) {
  try {
    const { params, lobbyId, providerId } = payload;
    const options = {
      params,
    };
    const {
      Transaction, Lobbies, Providers, Order, Games,
    } = AppConstants.api;
    yield put(changeLoadingState(true));
    const { data } = yield call(NetworkService.makeAPIGetRequest, [Transaction, Lobbies, lobbyId, Providers, providerId, Games, Order], options);
    yield put(saveLobbyProviderGamesOrders(data));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* editLobbyProviderGamesOrders({ payload }) {
  try {
    const {
      lobbyId, providerId, data, params, name,
    } = payload;
    const options = {
      data,
      params,
    };
    const {
      Transaction, Lobbies, Providers, Order, Games,
    } = AppConstants.api;
    yield put(changeLoadingState(true));
    yield call(NetworkService.makeAPIPostRequest, [Transaction, Lobbies, lobbyId, Providers, providerId, Games, Order], options);
    yield put(setSuccessMessage(getMessage('', i18n.t('notification:slUpdated'), name)));
    yield put(changeEndedStatus(true));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getCsvLobbyProviderGamesOrders({ payload }) {
  try {
    const { lobbyId, providerId } = payload;
    yield put(changeLoadingState(true));
    const {
      Transaction, Lobbies, Providers, Upload, Games,
    } = AppConstants.api;
    const options = {
      data: payload.data,
      headers: { 'content-type': 'multipart/form-data' },
    };
    const { data } = yield call(NetworkService.makeAPIPostRequest, [Transaction, Lobbies, lobbyId, Providers, providerId, Games, Upload], options);
    yield put(saveCsvLobbyProviderGamesOrders(data));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getLobbyLogs({ payload }) {
  try {
    const { lobbyId, params } = payload;
    const options = {
      params,
      paramsSerializer: (param) => qs.stringify(param),
    };
    const { Transaction, Lobbies, Logs } = AppConstants.api;
    yield put(changeLoadingState(true));
    const { data } = yield call(NetworkService.makeAPIGetRequest, [Transaction, Lobbies, lobbyId, Logs], options);
    yield put(saveLobbyLogs(data));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getLobbyGeneralWidgets({ payload }) {
  try {
    const { lobbyId, params } = payload;
    const options = { params };
    const { Transaction, Lobbies, GeneralWidgets } = AppConstants.api;
    yield put(changeLoadingState(true));
    const { data } = yield call(NetworkService.makeAPIGetRequest, [Transaction, Lobbies, lobbyId, GeneralWidgets], options);
    yield put(saveLobbyGeneralWidgets(data));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getLobbyJackpotWidgets({ payload }) {
  try {
    const { lobbyId, params } = payload;
    const options = { params };
    const { Transaction, Lobbies, JackpotWidgets } = AppConstants.api;
    yield put(changeLoadingState(true));
    const { data } = yield call(NetworkService.makeAPIGetRequest, [Transaction, Lobbies, lobbyId, JackpotWidgets], options);
    yield put(saveLobbyJackpotWidgets(data));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getLobbyGeneralWidget({ payload }) {
  try {
    const { lobbyId, widgetId, params } = payload;
    const options = {
      params,
      paramsSerializer: (param) => qs.stringify(param),
    };
    const { Transaction, Lobbies, GeneralWidgets } = AppConstants.api;
    yield put(changeLoadingState(true));
    const { data } = yield call(NetworkService.makeAPIGetRequest, [Transaction, Lobbies, lobbyId, GeneralWidgets, widgetId], options);
    yield put(saveLobbyGeneralWidget(data));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getLobbyJackpotWidget({ payload }) {
  try {
    const { lobbyId, widgetId, params } = payload;
    const options = {
      params,
      paramsSerializer: (param) => qs.stringify(param),
    };
    const { Transaction, Lobbies, JackpotWidgets } = AppConstants.api;
    yield put(changeLoadingState(true));
    const { data } = yield call(NetworkService.makeAPIGetRequest, [Transaction, Lobbies, lobbyId, JackpotWidgets, widgetId], options);
    yield put(saveLobbyJackpotWidget(data));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* manageLobbyJackpotWidget({ payload }) {
  try {
    const {
      lobbyId, widgetId, data, name,
    } = payload;
    const options = {
      data,
    };
    const { Transaction, Lobbies, JackpotWidgets } = AppConstants.api;
    yield put(changeLoadingState(true));
    yield call(NetworkService.makeAPIPutRequest, [Transaction, Lobbies, lobbyId, JackpotWidgets, widgetId], options);
    yield put(setSuccessMessage(getMessage('', i18n.t('notification:slUpdated'), name)));
    yield put(changeEndedStatus(true));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* manageLobbyGeneralWidget({ payload }) {
  try {
    const {
      lobbyId, widgetId, data, name,
    } = payload;
    const options = {
      data,
    };
    const { Transaction, Lobbies, GeneralWidgets } = AppConstants.api;
    yield put(changeLoadingState(true));
    yield call(NetworkService.makeAPIPutRequest, [Transaction, Lobbies, lobbyId, GeneralWidgets, widgetId], options);
    yield put(setSuccessMessage(getMessage('', i18n.t('notification:slUpdated'), name)));
    yield put(changeEndedStatus(true));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* changeLobbyGeneralWidgetStatus({ payload }) {
  try {
    const {
      name, lobbyId, data, widgetId,
    } = payload;
    const options = { data };
    const {
      Transaction, Lobbies, GeneralWidgets, Status,
    } = AppConstants.api;
    yield put(changeLoadingState(true));
    yield call(NetworkService.makeAPIPutRequest, [Transaction, Lobbies, lobbyId, GeneralWidgets, widgetId, Status], options);
    yield put(changeEndedStatus(true));
    yield put(changeLoadingState(false));
    yield put(setSuccessMessage(getMessage('', i18n.t('notification:slUpdated'), name)));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* changeLobbyJackpotWidgetStatus({ payload }) {
  try {
    const {
      name, lobbyId, data, widgetId,
    } = payload;
    const options = { data };
    const {
      Transaction, Lobbies, JackpotWidgets, Status,
    } = AppConstants.api;
    yield put(changeLoadingState(true));
    yield call(NetworkService.makeAPIPutRequest, [Transaction, Lobbies, lobbyId, JackpotWidgets, widgetId, Status], options);
    yield put(changeEndedStatus(true));
    yield put(changeLoadingState(false));
    yield put(setSuccessMessage(getMessage('', i18n.t('notification:slUpdated'), name)));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getLobbyGameGroups({ payload }) {
  try {
    const { params, lobbyId } = payload;
    const options = {
      params,
      paramsSerializer: (param) => qs.stringify(param),
    };
    const { Transaction, Lobbies, Groups } = AppConstants.api;
    yield put(changeLoadingState(true));
    const { data } = yield call(NetworkService.makeAPIGetRequest, [Transaction, Lobbies, lobbyId, Groups], options);
    yield put(saveLobbyGameGroups(data));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* reorderLobbyGameGroup({ payload }) {
  try {
    const { lobbyId, groupId, data } = payload;
    const options = {
      data,
    };
    yield put(changeLoadingState(true));
    const {
      Transaction, Lobbies, Groups, Order,
    } = AppConstants.api;
    yield call(NetworkService.makeAPIPutRequest, [Transaction, Lobbies, lobbyId, Groups, groupId, Order], options);
    yield put(changeLoadingState(false));
    yield put(changeEndedStatus(true));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* changeLobbyGameGroupStatus({ payload }) {
  try {
    const {
      lobbyId, groupId, data, name,
    } = payload;
    const options = {
      data,
    };
    yield put(changeLoadingState(true));
    const {
      Transaction, Lobbies, Groups, Status,
    } = AppConstants.api;
    yield call(NetworkService.makeAPIPutRequest, [Transaction, Lobbies, lobbyId, Groups, groupId, Status], options);
    yield put(setSuccessMessage(getMessage(i18n.t('cms:lGroup'), i18n.t(!data.status ? 'notification:slDisabled' : 'notification:slEnabled'), name)));
    yield put(changeLoadingState(false));
    yield put(changeEndedStatus(true));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getLobbyGameGroup({ payload }) {
  try {
    const { lobbyId, groupId, params } = payload;
    const options = {
      params,
    };
    yield put(changeLoadingState(true));
    const { Transaction, Lobbies, Groups } = AppConstants.api;
    const { data } = yield call(NetworkService.makeAPIGetRequest, [Transaction, Lobbies, lobbyId, Groups, groupId], options);
    yield put(saveLobbyGameGroup(data));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* editLobbyGameGroup({ payload }) {
  try {
    const {
      lobbyId, groupId, data, name,
    } = payload;
    const options = {
      data,
    };
    yield put(changeLoadingState(true));
    const { Transaction, Lobbies, Groups } = AppConstants.api;
    yield call(NetworkService.makeAPIPutRequest, [Transaction, Lobbies, lobbyId, Groups, groupId], options);
    yield put(changeLoadingState(false));
    yield put(changeEndedStatus(true));
    yield put(setSuccessMessage(getMessage(i18n.t('cms:lGroup'), i18n.t('notification:slUpdated'), name)));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getLobbyHomeWidgets({ payload }) {
  try {
    const { lobbyId, params } = payload;
    const options = {
      params,
      paramsSerializer: (param) => qs.stringify(param),
    };
    const { Transaction, Lobbies, HomeWidgets } = AppConstants.api;
    yield put(changeLoadingState(true));

    const { data } = yield call(NetworkService.makeAPIGetRequest, [Transaction, Lobbies, lobbyId, HomeWidgets], options);
    yield put(saveLobbyHomeWidgets(data));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* reorderLobbyHomeWidgets({ payload }) {
  try {
    const { lobbyId, widgetId, data } = payload;
    const options = {
      data,
    };
    yield put(changeLoadingState(true));
    const {
      Transaction, Lobbies, HomeWidgets, Order,
    } = AppConstants.api;
    yield call(NetworkService.makeAPIPutRequest, [Transaction, Lobbies, lobbyId, HomeWidgets, widgetId, Order], options);
    yield put(changeLoadingState(false));
    yield put(changeEndedStatus(true));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getLobbyHomeWidget({ payload }) {
  try {
    const { widgetId, params } = payload;
    const options = {
      params,
    };
    const { Transaction, Lobbies, HomeWidgets } = AppConstants.api;
    yield put(changeLoadingState(true));
    const { data } = yield call(NetworkService.makeAPIGetRequest, [Transaction, Lobbies, HomeWidgets, widgetId], options);
    yield put(saveLobbyHomeWidget(data));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* editLobbyHomeWidget({ payload }) {
  try {
    const { widgetId, data, name } = payload;
    const options = {
      data,
    };
    const { Transaction, Lobbies, HomeWidgets } = AppConstants.api;
    yield put(changeLoadingState(true));
    yield call(NetworkService.makeAPIPutRequest, [Transaction, Lobbies, HomeWidgets, widgetId], options);
    yield put(setSuccessMessage(getMessage('', i18n.t('notification:slUpdated'), name)));
    yield put(changeEndedStatus(true));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* createLobbyHomeWidget({ payload }) {
  try {
    const { lobbyId, data, name } = payload;
    const options = {
      data,
    };
    const { Transaction, Lobbies, HomeWidgets } = AppConstants.api;
    yield put(changeLoadingState(true));
    yield call(NetworkService.makeAPIPostRequest, [Transaction, Lobbies, lobbyId, HomeWidgets], options);
    yield put(setSuccessMessage(getMessage('', i18n.t('notification:slCreated'), name)));
    yield put(changeEndedStatus(true));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* deleteLobbyHomeWidget({ payload }) {
  try {
    const {
      id, lobbyId, data, name,
    } = payload;

    const { Transaction, Lobbies, HomeWidgets } = AppConstants.api;
    yield put(changeLoadingState(true));
    yield call(NetworkService.makeAPIDeleteRequest, [Transaction, Lobbies, lobbyId, HomeWidgets, id], { params: data });
    yield put(setSuccessMessage(getMessage('', i18n.t('notification:slDeleted'), name)));
    yield put(changeEndedStatus(true));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* changeLobbyHomeWidgetStatus({ payload }) {
  try {
    const { widgetId, data, name } = payload;
    const options = {
      data,
    };
    const {
      Transaction, Lobbies, HomeWidgets, Status,
    } = AppConstants.api;
    yield put(changeLoadingState(true));
    yield call(NetworkService.makeAPIPutRequest, [Transaction, Lobbies, HomeWidgets, widgetId, Status], options);
    yield put(setSuccessMessage(getMessage('', i18n.t(!data.enable ? 'notification:slDisabled' : 'notification:slEnabled'), name)));
    yield put(changeEndedStatus(true));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

export default function* lobbyManagementSaga() {
  yield all([
    takeLatest(LobbyManagementActionType.GET_LOBBIES, getLobbies),
    takeLatest(LobbyManagementActionType.GET_LOBBY_PROVIDERS, getLobbyProviders),
    takeLatest(LobbyManagementActionType.CHANGE_LOBBY_STATUS, changeLobbyStatus),
    takeLatest(LobbyManagementActionType.GET_LOBBY, getLobby),
    takeLatest(LobbyManagementActionType.EDIT_LOBBY, editLobby),
    takeLatest(LobbyManagementActionType.CHANGE_LOBBY_PROVIDER_STATUS, changeLobbyProviderStatus),
    takeLatest(LobbyManagementActionType.EDIT_LOBBY_PROVIDER, editLobbyProvider),
    takeLatest(LobbyManagementActionType.GET_LOBBY_PROVIDER, getLobbyProvider),
    takeLatest(LobbyManagementActionType.EXPORT_LOBBY_PROVIDERS, exportLobbyProviders),
    takeLatest(LobbyManagementActionType.GET_LOBBY_PROVIDERS_ORDERS, getLobbyProvidersOrders),
    takeLatest(LobbyManagementActionType.EDIT_LOBBY_PROVIDERS_ORDERS, editLobbyProvidersOrders),
    takeLatest(LobbyManagementActionType.GET_CSV_LOBBY_PROVIDERS_ORDERS, getCsvLobbyProvidersOrders),
    takeLatest(LobbyManagementActionType.GET_LOBBY_PROVIDER_GAMES_ORDERS, getLobbyProviderGamesOrders),
    takeLatest(LobbyManagementActionType.EDIT_LOBBY_PROVIDER_GAMES_ORDERS, editLobbyProviderGamesOrders),
    takeLatest(LobbyManagementActionType.GET_CSV_LOBBY_PROVIDER_GAMES_ORDERS, getCsvLobbyProviderGamesOrders),
    takeLatest(LobbyManagementActionType.GET_LOBBY_LOGS, getLobbyLogs),
    takeLatest(LobbyManagementActionType.GET_LOBBY_GAME_GROUPS, getLobbyGameGroups),
    takeLatest(LobbyManagementActionType.REORDER_LOBBY_GAME_GROUP, reorderLobbyGameGroup),
    takeLatest(LobbyManagementActionType.CHANGE_LOBBY_GAME_GROUP_STATUS, changeLobbyGameGroupStatus),
    takeLatest(LobbyManagementActionType.GET_LOBBY_GAME_GROUP, getLobbyGameGroup),
    takeLatest(LobbyManagementActionType.EDIT_LOBBY_GAME_GROUP, editLobbyGameGroup),
    takeLatest(LobbyManagementActionType.GET_LOBBY_GENERAL_WIDGETS, getLobbyGeneralWidgets),
    takeLatest(LobbyManagementActionType.GET_LOBBY_JACKPOT_WIDGETS, getLobbyJackpotWidgets),
    takeLatest(LobbyManagementActionType.GET_LOBBY_GENERAL_WIDGET, getLobbyGeneralWidget),
    takeLatest(LobbyManagementActionType.GET_LOBBY_JACKPOT_WIDGET, getLobbyJackpotWidget),
    takeLatest(LobbyManagementActionType.MANAGE_LOBBY_GENERAL_WIDGET, manageLobbyGeneralWidget),
    takeLatest(LobbyManagementActionType.MANAGE_LOBBY_JACKPOT_WIDGET, manageLobbyJackpotWidget),
    takeLatest(LobbyManagementActionType.CHANGE_LOBBY_GENERAL_WIDGET_STATUS, changeLobbyGeneralWidgetStatus),
    takeLatest(LobbyManagementActionType.CHANGE_LOBBY_JACKPOT_WIDGET_STATUS, changeLobbyJackpotWidgetStatus),
    takeLatest(LobbyManagementActionType.GET_LOBBY_HOME_WIDGETS, getLobbyHomeWidgets),
    takeLatest(LobbyManagementActionType.REORDER_LOBBY_HOME_WIDGETS, reorderLobbyHomeWidgets),
    takeLatest(LobbyManagementActionType.GET_LOBBY_HOME_WIDGET, getLobbyHomeWidget),
    takeLatest(LobbyManagementActionType.CREATE_LOBBY_HOME_WIDGET, createLobbyHomeWidget),
    takeLatest(LobbyManagementActionType.EDIT_LOBBY_HOME_WIDGET, editLobbyHomeWidget),
    takeLatest(LobbyManagementActionType.DELETE_LOBBY_HOME_WIDGET, deleteLobbyHomeWidget),
    takeLatest(LobbyManagementActionType.CHANGE_LOBBY_HOME_WIDGET_STATUS, changeLobbyHomeWidgetStatus),
  ]);
}
