const IpWhitelistActionType = {
  GET_IP_WHITELIST: 'GET_IP_WHITELIST',
  SAVE_IP_WHITELIST: 'SAVE_IP_WHITELIST',
  RESET_IP_WHITELIST: 'RESET_IP_WHITELIST',

  DELETE_IP_WHITELIST: 'DELETE_IP_WHITELIST',

  ADD_IP_WHITELIST: 'ADD_IP_WHITELIST',
};

export default IpWhitelistActionType;
