const SimilarAccountActionType = {
  GET_SIMILAR_ACCOUNTS: 'GET_SIMILAR_ACCOUNTS',
  SAVE_SIMILAR_ACCOUNTS: 'SAVE_SIMILAR_ACCOUNTS',
  RESET_SIMILAR_ACCOUNTS: 'RESET_SIMILAR_ACCOUNTS',
  EXPORT_SIMILAR_ACCOUNTS: 'EXPORT_SIMILAR_ACCOUNTS',

  GET_SIMILAR_ACCOUNTS_GROUPS: 'GET_SIMILAR_ACCOUNTS_GROUPS',
  SAVE_SIMILAR_ACCOUNTS_GROUPS: 'SAVE_SIMILAR_ACCOUNTS_GROUPS',
  RESET_SIMILAR_ACCOUNTS_GROUPS: 'RESET_SIMILAR_ACCOUNTS_GROUPS',
  EXPORT_SIMILAR_ACCOUNTS_GROUPS: 'EXPORT_SIMILAR_ACCOUNTS_GROUPS',

  GET_PLAYER_SIMILAR_ACCOUNTS_INFO: 'GET_PLAYER_SIMILAR_ACCOUNTS_INFO',
  SAVE_PLAYER_SIMILAR_ACCOUNTS_INFO: 'SAVE_PLAYER_SIMILAR_ACCOUNTS_INFO',
  RESET_PLAYER_SIMILAR_ACCOUNTS_INFO: 'RESET_PLAYER_SIMILAR_ACCOUNTS_INFO',
};

export default SimilarAccountActionType;
