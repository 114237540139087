import {
  all, call, put, takeLatest,
} from '@redux-saga/core/effects';
import ThirdPartyServicesActionType from 'actions/thirdPartyServices/thirdPartyServicesActionType';
import { AppConstants } from 'constants/index';
import {
  saveCustomScripts,
  saveBrandAffiliateServices,
  saveBrandAnalyticServices,
  saveBrandAnalyticService,
  saveBrandLiveChatSolutionServices,
  saveBrandLiveChatSolutionService,
  saveBrandAgSolutionServices,
  saveBrandAgSolutionService,
  saveBrandCrmServices,
  saveBrandCrmService,
  saveBrandGamificationServices,
  saveBrandGamificationService,
  saveBrandRetailSolutionServices,
  saveBrandRetailSolutionService,
  saveBrandAffiliateService,
  saveBrandKycService,
  saveBrandKycServices,
  saveBrandDigitainAgServicePaymentLimits,
} from 'actions/thirdPartyServices/thirdPartyServicesActionCreator';
import {
  setErrorMessage, changeLoadingState, setSuccessMessage, changeEndedStatus,
} from 'actions/setting/settingActionCreator';
import NetworkService from 'services/networkService';
import { getError, getMessage } from 'utils/helpers';
import { i18n } from 'services';

function* getCustomScripts({ payload }) {
  try {
    const { params } = payload;
    const { BuilderAdmin, CustomCodes } = AppConstants.api;
    yield put(changeLoadingState(true));
    const response = yield call(NetworkService.makeAPIGetRequest, [BuilderAdmin, CustomCodes], { params });
    yield put(saveCustomScripts(response.data));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* addCustomScript({ payload }) {
  try {
    const { name } = payload.data;
    const { BuilderAdmin, CustomCodes } = AppConstants.api;
    yield put(changeLoadingState(true));
    yield call(NetworkService.makeAPIPostRequest, [BuilderAdmin, CustomCodes], payload);
    yield put(changeEndedStatus(true));
    yield put(changeLoadingState(false));
    yield put(setSuccessMessage(getMessage(i18n.t('brand:lCustomScript'), i18n.t('notification:slAdded'), name)));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* editCustomScript({ payload }) {
  try {
    const { id, data } = payload;
    const { name } = data;
    const { BuilderAdmin, CustomCodes } = AppConstants.api;
    yield put(changeLoadingState(true));
    yield call(NetworkService.makeAPIPutRequest, [BuilderAdmin, CustomCodes, id], { data });
    yield put(changeEndedStatus(true));
    yield put(changeLoadingState(false));
    yield put(setSuccessMessage(getMessage(i18n.t('brand:lCustomScript'), i18n.t('notification:slUpdated'), name)));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* deleteCustomScript({ payload }) {
  try {
    const { name, id, brandId } = payload.data;
    const options = {
      params: { brandId },
    };
    const { BuilderAdmin, CustomCodes } = AppConstants.api;
    yield put(changeLoadingState(true));
    yield call(NetworkService.makeAPIDeleteRequest, [BuilderAdmin, CustomCodes, id], options);
    yield put(changeEndedStatus(true));
    yield put(changeLoadingState(false));
    yield put(setSuccessMessage(getMessage(i18n.t('brand:lCustomScript'), i18n.t('notification:slDeleted'), name)));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* changeCustomScriptStatus({ payload }) {
  try {
    const {
      name, id, brandId, isActive,
    } = payload.data;
    const options = {
      params: { brandId, isActive: !isActive },
    };
    const { BuilderAdmin, CustomCodes, ChangeStatus } = AppConstants.api;
    yield put(changeLoadingState(true));
    yield call(NetworkService.makeAPIPutRequest, [BuilderAdmin, CustomCodes, id, ChangeStatus], options);
    yield put(changeEndedStatus(true));
    yield put(changeLoadingState(false));
    yield put(setSuccessMessage(getMessage(i18n.t('brand:lCustomScript'), i18n.t(isActive ? 'notification:slDeactivated' : 'notification:slActivated'), name)));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}
function* getBrandAffiliateServices({ payload }) {
  try {
    const { params } = payload;
    const options = {
      params,
    };
    const { DataCollector, Affiliates } = AppConstants.api;
    yield put(changeLoadingState(true));
    const { data } = yield call(NetworkService.makeAPIGetRequest, [DataCollector, Affiliates], options);
    yield put(saveBrandAffiliateServices(data));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* addBrandEveryMatrixAffiliateService({ payload }) {
  try {
    const { brandId, data } = payload;
    const { name } = data;
    const options = {
      data: { brandId },
    };
    const { DataCollector, Affiliates, EveryMatrix } = AppConstants.api;
    yield put(changeLoadingState(true));
    yield call(NetworkService.makeAPIPostRequest, [DataCollector, Affiliates, EveryMatrix], options);
    yield put(changeEndedStatus(true));
    yield put(changeLoadingState(false));
    yield put(setSuccessMessage(getMessage('', i18n.t('notification:slActivated'), name)));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* deleteBrandEveryMatrixAffiliateService({ payload }) {
  try {
    const { data, brandId } = payload;
    const { name } = data;
    const options = {
      params: {
        brandId,
      },
    };
    const { DataCollector, Affiliates, EveryMatrix } = AppConstants.api;
    yield put(changeLoadingState(true));
    yield call(NetworkService.makeAPIDeleteRequest, [DataCollector, Affiliates, EveryMatrix], options);
    yield put(changeEndedStatus(true));
    yield put(changeLoadingState(false));
    yield put(setSuccessMessage(getMessage('', i18n.t('notification:slDeactivated'), name)));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* addBrandSmarticoAffiliateService({ payload }) {
  try {
    const { brandId, data } = payload;
    const { name } = data;
    const options = {
      data: { brandId },
    };
    const { DataCollector, Affiliates, Smartico } = AppConstants.api;
    yield put(changeLoadingState(true));
    yield call(NetworkService.makeAPIPostRequest, [DataCollector, Affiliates, Smartico], options);
    yield put(changeEndedStatus(true));
    yield put(changeLoadingState(false));
    yield put(setSuccessMessage(getMessage('', i18n.t('notification:slActivated'), name)));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* deleteBrandSmarticoAffiliateService({ payload }) {
  try {
    const { data, brandId } = payload;
    const { name } = data;
    const options = {
      params: {
        brandId,
      },
    };
    const { DataCollector, Affiliates, Smartico } = AppConstants.api;
    yield put(changeLoadingState(true));
    yield call(NetworkService.makeAPIDeleteRequest, [DataCollector, Affiliates, Smartico], options);
    yield put(changeEndedStatus(true));
    yield put(changeLoadingState(false));
    yield put(setSuccessMessage(getMessage('', i18n.t('notification:slDeactivated'), name)));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* addBrandMyAffiliatesService({ payload }) {
  try {
    const { brandId, data } = payload;
    const { name } = data;
    const options = {
      data: { brandId },
    };
    const { DataCollector, Affiliates, MyAffiliates } = AppConstants.api;
    yield put(changeLoadingState(true));
    yield call(NetworkService.makeAPIPostRequest, [DataCollector, Affiliates, MyAffiliates], options);
    yield put(changeEndedStatus(true));
    yield put(changeLoadingState(false));
    yield put(setSuccessMessage(getMessage('', i18n.t('notification:slActivated'), name)));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* deleteBrandMyAffiliatesService({ payload }) {
  try {
    const { data, brandId } = payload;
    const { name } = data;
    const options = {
      params: {
        brandId,
      },
    };
    const { DataCollector, Affiliates, MyAffiliates } = AppConstants.api;
    yield put(changeLoadingState(true));
    yield call(NetworkService.makeAPIDeleteRequest, [DataCollector, Affiliates, MyAffiliates], options);
    yield put(changeEndedStatus(true));
    yield put(changeLoadingState(false));
    yield put(setSuccessMessage(getMessage('', i18n.t('notification:slDeactivated'), name)));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getBrandAnalyticServices({ payload }) {
  try {
    const { params } = payload;
    const options = {
      params,
    };
    const { BuilderAdmin, Analytics } = AppConstants.api;
    yield put(changeLoadingState(true));
    const { data } = yield call(NetworkService.makeAPIGetRequest, [BuilderAdmin, Analytics], options);
    yield put(saveBrandAnalyticServices(data));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getBrandHotJarService({ payload }) {
  try {
    const { params } = payload;
    const options = {
      params,
    };
    const { BuilderAdmin, Analytics, HotJar } = AppConstants.api;
    yield put(changeLoadingState(true));
    const { data } = yield call(NetworkService.makeAPIGetRequest, [BuilderAdmin, Analytics, HotJar], options);
    yield put(saveBrandAnalyticService(data));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* addBrandHotJarService({ payload }) {
  try {
    const { data, name } = payload;
    const options = {
      data,
    };
    const { BuilderAdmin, Analytics, HotJar } = AppConstants.api;
    yield put(changeLoadingState(true));
    yield call(NetworkService.makeAPIPostRequest, [BuilderAdmin, Analytics, HotJar], options);
    yield put(changeEndedStatus(true));
    yield put(changeLoadingState(false));
    yield put(setSuccessMessage(getMessage('', i18n.t('notification:slIntegrated'), name)));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* editBrandHotJarService({ payload }) {
  try {
    const { name, data } = payload;
    const options = {
      data,
    };
    const { BuilderAdmin, Analytics, HotJar } = AppConstants.api;
    yield put(changeLoadingState(true));
    yield call(NetworkService.makeAPIPutRequest, [BuilderAdmin, Analytics, HotJar], options);
    yield put(changeEndedStatus(true));
    yield put(changeLoadingState(false));
    yield put(setSuccessMessage(getMessage('', i18n.t('notification:slUpdated'), name)));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* deleteBrandHotJarService({ payload }) {
  try {
    const { brandId, params } = payload;
    const options = {
      params: {
        brandId,
      },
    };
    const { BuilderAdmin, Analytics, HotJar } = AppConstants.api;
    yield put(changeLoadingState(true));
    yield call(NetworkService.makeAPIDeleteRequest, [BuilderAdmin, Analytics, HotJar], options);
    yield put(changeEndedStatus(true));
    yield put(changeLoadingState(false));
    yield put(setSuccessMessage(getMessage('', i18n.t('notification:slDeleted'), params.name)));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* changeBrandHotJarServiceStatus({ payload }) {
  try {
    const { brandId, data } = payload;
    const { name, isEnabled } = data;

    const options = {
      data: {
        isEnabled,
        brandId,
      },
    };
    const {
      BuilderAdmin, Analytics, HotJar, Status,
    } = AppConstants.api;
    yield put(changeLoadingState(true));
    yield call(NetworkService.makeAPIPutRequest, [BuilderAdmin, Analytics, HotJar, Status], options);
    yield put(changeEndedStatus(true));
    yield put(changeLoadingState(false));
    yield put(setSuccessMessage(getMessage('', i18n.t(!isEnabled ? 'notification:slDisabled' : 'notification:slEnabled'), name)));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}
function* getBrandGoogleAnalyticsService({ payload }) {
  try {
    const { params } = payload;
    const options = {
      params,
    };
    const { BuilderAdmin, Analytics, GoogleAnalytics } = AppConstants.api;
    yield put(changeLoadingState(true));
    const { data } = yield call(NetworkService.makeAPIGetRequest, [BuilderAdmin, Analytics, GoogleAnalytics], options);
    yield put(saveBrandAnalyticService(data));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* addBrandGoogleAnalyticsService({ payload }) {
  try {
    const { data, name } = payload;
    const options = {
      data,
    };
    const { BuilderAdmin, Analytics, GoogleAnalytics } = AppConstants.api;
    yield put(changeLoadingState(true));
    yield call(NetworkService.makeAPIPostRequest, [BuilderAdmin, Analytics, GoogleAnalytics], options);
    yield put(changeEndedStatus(true));
    yield put(changeLoadingState(false));
    yield put(setSuccessMessage(getMessage('', i18n.t('notification:slIntegrated'), name)));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* editBrandGoogleAnalyticsService({ payload }) {
  try {
    const { name, data } = payload;
    const options = {
      data,
    };
    const { BuilderAdmin, Analytics, GoogleAnalytics } = AppConstants.api;
    yield put(changeLoadingState(true));
    yield call(NetworkService.makeAPIPutRequest, [BuilderAdmin, Analytics, GoogleAnalytics], options);
    yield put(changeEndedStatus(true));
    yield put(changeLoadingState(false));
    yield put(setSuccessMessage(getMessage('', i18n.t('notification:slUpdated'), name)));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* deleteBrandGoogleAnalyticsService({ payload }) {
  try {
    const { brandId, params } = payload;
    const options = {
      params: {
        brandId,
      },
    };
    const { BuilderAdmin, Analytics, GoogleAnalytics } = AppConstants.api;
    yield put(changeLoadingState(true));
    yield call(NetworkService.makeAPIDeleteRequest, [BuilderAdmin, Analytics, GoogleAnalytics], options);
    yield put(changeEndedStatus(true));
    yield put(changeLoadingState(false));
    yield put(setSuccessMessage(getMessage('', i18n.t('notification:slDeleted'), params.name)));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* changeBrandGoogleAnalyticsServiceStatus({ payload }) {
  try {
    const { brandId, data } = payload;
    const { name, isEnabled } = data;

    const options = {
      data: {
        isEnabled,
        brandId,
      },
    };
    const {
      BuilderAdmin, Analytics, GoogleAnalytics, Status,
    } = AppConstants.api;
    yield put(changeLoadingState(true));
    yield call(NetworkService.makeAPIPutRequest, [BuilderAdmin, Analytics, GoogleAnalytics, Status], options);
    yield put(changeEndedStatus(true));
    yield put(changeLoadingState(false));
    yield put(setSuccessMessage(getMessage('', i18n.t(!isEnabled ? 'notification:slDisabled' : 'notification:slEnabled'), name)));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getBrandGoogleSearchConsoleService({ payload }) {
  try {
    const { params } = payload;
    const options = {
      params,
    };
    const { BuilderAdmin, Analytics, GoogleSearchConsole } = AppConstants.api;
    yield put(changeLoadingState(true));
    const { data } = yield call(NetworkService.makeAPIGetRequest, [BuilderAdmin, Analytics, GoogleSearchConsole], options);
    yield put(saveBrandAnalyticService(data));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* addBrandGoogleSearchConsoleService({ payload }) {
  try {
    const { data, name } = payload;
    const options = {
      data,
    };
    const { BuilderAdmin, Analytics, GoogleSearchConsole } = AppConstants.api;
    yield put(changeLoadingState(true));
    yield call(NetworkService.makeAPIPostRequest, [BuilderAdmin, Analytics, GoogleSearchConsole], options);
    yield put(changeEndedStatus(true));
    yield put(changeLoadingState(false));
    yield put(setSuccessMessage(getMessage('', i18n.t('notification:slIntegrated'), name)));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* editBrandGoogleSearchConsoleService({ payload }) {
  try {
    const { name, data } = payload;
    const options = {
      data,
    };
    const { BuilderAdmin, Analytics, GoogleSearchConsole } = AppConstants.api;
    yield put(changeLoadingState(true));
    yield call(NetworkService.makeAPIPutRequest, [BuilderAdmin, Analytics, GoogleSearchConsole], options);
    yield put(changeEndedStatus(true));
    yield put(changeLoadingState(false));
    yield put(setSuccessMessage(getMessage('', i18n.t('notification:slUpdated'), name)));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* deleteBrandGoogleSearchConsoleService({ payload }) {
  try {
    const { brandId, params } = payload;
    const options = {
      params: {
        brandId,
      },
    };
    const { BuilderAdmin, Analytics, GoogleSearchConsole } = AppConstants.api;
    yield put(changeLoadingState(true));
    yield call(NetworkService.makeAPIDeleteRequest, [BuilderAdmin, Analytics, GoogleSearchConsole], options);
    yield put(changeEndedStatus(true));
    yield put(changeLoadingState(false));
    yield put(setSuccessMessage(getMessage('', i18n.t('notification:slDeleted'), params.name)));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* changeBrandGoogleSearchConsoleServiceStatus({ payload }) {
  try {
    const { brandId, data } = payload;
    const { name, isEnabled } = data;

    const options = {
      data: {
        isEnabled,
        brandId,
      },
    };
    const {
      BuilderAdmin, Analytics, GoogleSearchConsole, Status,
    } = AppConstants.api;
    yield put(changeLoadingState(true));
    yield call(NetworkService.makeAPIPutRequest, [BuilderAdmin, Analytics, GoogleSearchConsole, Status], options);
    yield put(changeEndedStatus(true));
    yield put(changeLoadingState(false));
    yield put(setSuccessMessage(getMessage('', i18n.t(!isEnabled ? 'notification:slDisabled' : 'notification:slEnabled'), name)));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getBrandLiveChatSolutionServices({ payload }) {
  try {
    const { params } = payload;
    const options = {
      params,
    };
    const { BuilderAdmin, Chats } = AppConstants.api;
    yield put(changeLoadingState(true));
    const { data } = yield call(NetworkService.makeAPIGetRequest, [BuilderAdmin, Chats], options);
    yield put(saveBrandLiveChatSolutionServices(data));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getBrandLiveChatService({ payload }) {
  try {
    const { params } = payload;
    const options = {
      params,
    };
    const { BuilderAdmin, Chats, LiveChat } = AppConstants.api;
    yield put(changeLoadingState(true));
    const { data } = yield call(NetworkService.makeAPIGetRequest, [BuilderAdmin, Chats, LiveChat], options);
    yield put(saveBrandLiveChatSolutionService(data));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* addBrandLiveChatService({ payload }) {
  try {
    const { data, name } = payload;
    const options = {
      data,
    };
    const { BuilderAdmin, Chats, LiveChat } = AppConstants.api;
    yield put(changeLoadingState(true));
    yield call(NetworkService.makeAPIPostRequest, [BuilderAdmin, Chats, LiveChat], options);
    yield put(changeEndedStatus(true));
    yield put(changeLoadingState(false));
    yield put(setSuccessMessage(getMessage('', i18n.t('notification:slIntegrated'), name)));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* editBrandLiveChatService({ payload }) {
  try {
    const { name, data } = payload;
    const options = {
      data,
    };
    const { BuilderAdmin, Chats, LiveChat } = AppConstants.api;
    yield put(changeLoadingState(true));
    yield call(NetworkService.makeAPIPutRequest, [BuilderAdmin, Chats, LiveChat], options);
    yield put(changeEndedStatus(true));
    yield put(changeLoadingState(false));
    yield put(setSuccessMessage(getMessage('', i18n.t('notification:slUpdated'), name)));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* deleteBrandLiveChatService({ payload }) {
  try {
    const { brandId, params } = payload;
    const options = {
      params: {
        brandId,
      },
    };
    const { BuilderAdmin, Chats, LiveChat } = AppConstants.api;
    yield put(changeLoadingState(true));
    yield call(NetworkService.makeAPIDeleteRequest, [BuilderAdmin, Chats, LiveChat], options);
    yield put(changeEndedStatus(true));
    yield put(changeLoadingState(false));
    yield put(setSuccessMessage(getMessage('', i18n.t('notification:slDeleted'), params.name)));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* changeBrandLiveChatServiceStatus({ payload }) {
  try {
    const { brandId, data } = payload;
    const { name, isEnabled } = data;

    const options = {
      data: {
        isEnabled,
        brandId,
      },
    };
    const {
      BuilderAdmin, Chats, LiveChat, Status,
    } = AppConstants.api;
    yield put(changeLoadingState(true));
    yield call(NetworkService.makeAPIPutRequest, [BuilderAdmin, Chats, LiveChat, Status], options);
    yield put(changeEndedStatus(true));
    yield put(changeLoadingState(false));
    yield put(setSuccessMessage(getMessage('', i18n.t(!isEnabled ? 'notification:slDisabled' : 'notification:slEnabled'), name)));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getBrandLiveAgentService({ payload }) {
  try {
    const { params } = payload;
    const options = {
      params,
    };
    const { BuilderAdmin, Chats, LiveAgent } = AppConstants.api;
    yield put(changeLoadingState(true));
    const { data } = yield call(NetworkService.makeAPIGetRequest, [BuilderAdmin, Chats, LiveAgent], options);
    yield put(saveBrandLiveChatSolutionService(data));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* addBrandLiveAgentService({ payload }) {
  try {
    const { data, name } = payload;
    const options = {
      data,
    };
    const { BuilderAdmin, Chats, LiveAgent } = AppConstants.api;
    yield put(changeLoadingState(true));
    yield call(NetworkService.makeAPIPostRequest, [BuilderAdmin, Chats, LiveAgent], options);
    yield put(changeEndedStatus(true));
    yield put(changeLoadingState(false));
    yield put(setSuccessMessage(getMessage('', i18n.t('notification:slIntegrated'), name)));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* editBrandLiveAgentService({ payload }) {
  try {
    const { name, data } = payload;
    const options = {
      data,
    };
    const { BuilderAdmin, Chats, LiveAgent } = AppConstants.api;
    yield put(changeLoadingState(true));
    yield call(NetworkService.makeAPIPutRequest, [BuilderAdmin, Chats, LiveAgent], options);
    yield put(changeEndedStatus(true));
    yield put(changeLoadingState(false));
    yield put(setSuccessMessage(getMessage('', i18n.t('notification:slUpdated'), name)));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* deleteBrandLiveAgentService({ payload }) {
  try {
    const { brandId, params } = payload;
    const options = {
      params: {
        brandId,
      },
    };
    const { BuilderAdmin, Chats, LiveAgent } = AppConstants.api;
    yield put(changeLoadingState(true));
    yield call(NetworkService.makeAPIDeleteRequest, [BuilderAdmin, Chats, LiveAgent], options);
    yield put(changeEndedStatus(true));
    yield put(changeLoadingState(false));
    yield put(setSuccessMessage(getMessage('', i18n.t('notification:slDeleted'), params.name)));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* changeBrandLiveAgentServiceStatus({ payload }) {
  try {
    const { brandId, data } = payload;
    const { name, isEnabled } = data;

    const options = {
      data: {
        isEnabled,
        brandId,
      },
    };
    const {
      BuilderAdmin, Chats, LiveAgent, Status,
    } = AppConstants.api;
    yield put(changeLoadingState(true));
    yield call(NetworkService.makeAPIPutRequest, [BuilderAdmin, Chats, LiveAgent, Status], options);
    yield put(changeEndedStatus(true));
    yield put(changeLoadingState(false));
    yield put(setSuccessMessage(getMessage('', i18n.t(!isEnabled ? 'notification:slDisabled' : 'notification:slEnabled'), name)));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getBrandZendeskService({ payload }) {
  try {
    const { params } = payload;
    const options = {
      params,
    };
    const { BuilderAdmin, Chats, Zendesk } = AppConstants.api;
    yield put(changeLoadingState(true));
    const { data } = yield call(NetworkService.makeAPIGetRequest, [BuilderAdmin, Chats, Zendesk], options);
    yield put(saveBrandLiveChatSolutionService(data));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* addBrandZendeskService({ payload }) {
  try {
    const { data, name } = payload;
    const options = {
      data,
    };
    const { BuilderAdmin, Chats, Zendesk } = AppConstants.api;
    yield put(changeLoadingState(true));
    yield call(NetworkService.makeAPIPostRequest, [BuilderAdmin, Chats, Zendesk], options);
    yield put(changeEndedStatus(true));
    yield put(changeLoadingState(false));
    yield put(setSuccessMessage(getMessage('', i18n.t('notification:slIntegrated'), name)));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* editBrandZendeskService({ payload }) {
  try {
    const { name, data } = payload;
    const options = {
      data,
    };
    const { BuilderAdmin, Chats, Zendesk } = AppConstants.api;
    yield put(changeLoadingState(true));
    yield call(NetworkService.makeAPIPutRequest, [BuilderAdmin, Chats, Zendesk], options);
    yield put(changeEndedStatus(true));
    yield put(changeLoadingState(false));
    yield put(setSuccessMessage(getMessage('', i18n.t('notification:slUpdated'), name)));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* deleteBrandZendeskService({ payload }) {
  try {
    const { brandId, params } = payload;
    const options = {
      params: {
        brandId,
      },
    };
    const { BuilderAdmin, Chats, Zendesk } = AppConstants.api;
    yield put(changeLoadingState(true));
    yield call(NetworkService.makeAPIDeleteRequest, [BuilderAdmin, Chats, Zendesk], options);
    yield put(changeEndedStatus(true));
    yield put(changeLoadingState(false));
    yield put(setSuccessMessage(getMessage('', i18n.t('notification:slDeleted'), params.name)));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* changeBrandZendeskServiceStatus({ payload }) {
  try {
    const { brandId, data } = payload;
    const { name, isEnabled } = data;

    const options = {
      data: {
        isEnabled,
        brandId,
      },
    };
    const {
      BuilderAdmin, Chats, Zendesk, Status,
    } = AppConstants.api;
    yield put(changeLoadingState(true));
    yield call(NetworkService.makeAPIPutRequest, [BuilderAdmin, Chats, Zendesk, Status], options);
    yield put(changeEndedStatus(true));
    yield put(changeLoadingState(false));
    yield put(setSuccessMessage(getMessage('', i18n.t(!isEnabled ? 'notification:slDisabled' : 'notification:slEnabled'), name)));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getBrandIntercomService({ payload }) {
  try {
    const { params } = payload;
    const options = {
      params,
    };
    const { BuilderAdmin, Chats, Intercom } = AppConstants.api;
    yield put(changeLoadingState(true));
    const { data } = yield call(NetworkService.makeAPIGetRequest, [BuilderAdmin, Chats, Intercom], options);
    yield put(saveBrandLiveChatSolutionService(data));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* addBrandIntercomService({ payload }) {
  try {
    const { data, name } = payload;
    const options = {
      data,
    };
    const { BuilderAdmin, Chats, Intercom } = AppConstants.api;
    yield put(changeLoadingState(true));
    yield call(NetworkService.makeAPIPostRequest, [BuilderAdmin, Chats, Intercom], options);
    yield put(changeEndedStatus(true));
    yield put(changeLoadingState(false));
    yield put(setSuccessMessage(getMessage('', i18n.t('notification:slIntegrated'), name)));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* editBrandIntercomService({ payload }) {
  try {
    const { name, data } = payload;
    const options = {
      data,
    };
    const { BuilderAdmin, Chats, Intercom } = AppConstants.api;
    yield put(changeLoadingState(true));
    yield call(NetworkService.makeAPIPutRequest, [BuilderAdmin, Chats, Intercom], options);
    yield put(changeEndedStatus(true));
    yield put(changeLoadingState(false));
    yield put(setSuccessMessage(getMessage('', i18n.t('notification:slUpdated'), name)));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* deleteBrandIntercomService({ payload }) {
  try {
    const { brandId, params } = payload;
    const options = {
      params: {
        brandId,
      },
    };
    const { BuilderAdmin, Chats, Intercom } = AppConstants.api;
    yield put(changeLoadingState(true));
    yield call(NetworkService.makeAPIDeleteRequest, [BuilderAdmin, Chats, Intercom], options);
    yield put(changeEndedStatus(true));
    yield put(changeLoadingState(false));
    yield put(setSuccessMessage(getMessage('', i18n.t('notification:slDeleted'), params.name)));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* changeBrandIntercomServiceStatus({ payload }) {
  try {
    const { brandId, data } = payload;
    const { name, isEnabled } = data;

    const options = {
      data: {
        isEnabled,
        brandId,
      },
    };
    const {
      BuilderAdmin, Chats, Intercom, Status,
    } = AppConstants.api;
    yield put(changeLoadingState(true));
    yield call(NetworkService.makeAPIPutRequest, [BuilderAdmin, Chats, Intercom, Status], options);
    yield put(changeEndedStatus(true));
    yield put(changeLoadingState(false));
    yield put(setSuccessMessage(getMessage('', i18n.t(!isEnabled ? 'notification:slDisabled' : 'notification:slEnabled'), name)));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getBrandAgSolutionServices({ payload }) {
  try {
    const { params } = payload;
    const options = {
      params,
    };
    const { DataCollector, Agents } = AppConstants.api;
    yield put(changeLoadingState(true));
    const { data } = yield call(NetworkService.makeAPIGetRequest, [DataCollector, Agents], options);
    yield put(saveBrandAgSolutionServices(data));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* addBrandEveryMatrixAgService({ payload }) {
  try {
    const { brandId, data } = payload;
    const { name } = data;
    const options = {
      data: { brandId },
    };
    const { DataCollector, Agents, EveryMatrix } = AppConstants.api;
    yield put(changeLoadingState(true));
    yield call(NetworkService.makeAPIPostRequest, [DataCollector, Agents, EveryMatrix], options);
    yield put(changeEndedStatus(true));
    yield put(changeLoadingState(false));
    yield put(setSuccessMessage(getMessage('', i18n.t('notification:slActivated'), name)));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* deleteBrandEveryMatrixAgService({ payload }) {
  try {
    const { data, brandId } = payload;
    const { name } = data;
    const options = {
      params: {
        brandId,
      },
    };
    const { DataCollector, Agents, EveryMatrix } = AppConstants.api;
    yield put(changeLoadingState(true));
    yield call(NetworkService.makeAPIDeleteRequest, [DataCollector, Agents, EveryMatrix], options);
    yield put(changeEndedStatus(true));
    yield put(changeLoadingState(false));
    yield put(setSuccessMessage(getMessage('', i18n.t('notification:slDeactivated'), name)));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getBrandDigitainAgService({ payload }) {
  try {
    const { params } = payload;
    const options = {
      params,
    };
    const { DataCollector, Agents, Digitain } = AppConstants.api;
    yield put(changeLoadingState(true));
    const { data } = yield call(NetworkService.makeAPIGetRequest, [DataCollector, Agents, Digitain], options);
    yield put(saveBrandAgSolutionService(data));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* addBrandDigitainAgService({ payload }) {
  try {
    const { data, name } = payload;
    const options = {
      data,
    };
    const { DataCollector, Agents, Digitain } = AppConstants.api;
    yield put(changeLoadingState(true));
    yield call(NetworkService.makeAPIPostRequest, [DataCollector, Agents, Digitain], options);
    yield put(changeEndedStatus(true));
    yield put(changeLoadingState(false));
    yield put(setSuccessMessage(getMessage('', i18n.t('notification:slActivated'), name)));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* deleteBrandDigitainAgService({ payload }) {
  try {
    const { brandId, params } = payload;
    const options = {
      params: {
        brandId,
      },
    };
    const { DataCollector, Agents, Digitain } = AppConstants.api;
    yield put(changeLoadingState(true));
    yield call(NetworkService.makeAPIDeleteRequest, [DataCollector, Agents, Digitain], options);
    yield put(changeEndedStatus(true));
    yield put(changeLoadingState(false));
    yield put(setSuccessMessage(getMessage('', i18n.t('notification:slDeactivated'), params.name)));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* editBrandDigitainAgService({ payload }) {
  try {
    const { name, data } = payload;
    const options = {
      data,
    };
    const { DataCollector, Agents, Digitain } = AppConstants.api;
    yield put(changeLoadingState(true));
    yield call(NetworkService.makeAPIPutRequest, [DataCollector, Agents, Digitain], options);
    yield put(changeEndedStatus(true));
    yield put(changeLoadingState(false));
    yield put(setSuccessMessage(getMessage('', i18n.t('notification:slUpdated'), name)));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getBrandDigitainAgServicePaymentLimits({ payload }) {
  try {
    const { params } = payload;
    const options = {
      params,
    };
    const {
      DataCollector, Agents, Digitain, PaymentLimits,
    } = AppConstants.api;
    yield put(changeLoadingState(true));
    const { data } = yield call(NetworkService.makeAPIGetRequest, [DataCollector, Agents, Digitain, PaymentLimits], options);
    yield put(saveBrandDigitainAgServicePaymentLimits(data));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* configureBrandDigitainAgService({ payload }) {
  try {
    const { params, data, name } = payload;
    const options = {
      params,
      data,
    };
    const {
      DataCollector, Agents, Digitain, PaymentLimits,
    } = AppConstants.api;
    yield put(changeLoadingState(true));
    yield call(NetworkService.makeAPIPostRequest, [DataCollector, Agents, Digitain, PaymentLimits], options);
    yield put(changeEndedStatus(true));
    yield put(changeLoadingState(false));
    yield put(setSuccessMessage(getMessage('', i18n.t('notification:slUpdated'), name)));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getBrandCrmServices({ payload }) {
  try {
    const { params } = payload;
    const options = {
      params,
    };
    const { DataCollector, Crm } = AppConstants.api;
    yield put(changeLoadingState(true));
    const { data } = yield call(NetworkService.makeAPIGetRequest, [DataCollector, Crm], options);
    yield put(saveBrandCrmServices(data));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* addBrandSmarticoCrmService({ payload }) {
  try {
    const { data, name } = payload;
    const options = {
      data,
    };
    const { DataCollector, Crm, Smartico } = AppConstants.api;
    yield put(changeLoadingState(true));
    yield call(NetworkService.makeAPIPostRequest, [DataCollector, Crm, Smartico], options);
    yield put(changeEndedStatus(true));
    yield put(changeLoadingState(false));
    yield put(setSuccessMessage(getMessage('', i18n.t('notification:slActivated'), name)));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}
function* getBrandSmarticoCrmService({ payload }) {
  try {
    const { params } = payload;
    const options = {
      params,
    };
    const { DataCollector, Crm, Smartico } = AppConstants.api;
    yield put(changeLoadingState(true));
    const { data } = yield call(NetworkService.makeAPIGetRequest, [DataCollector, Crm, Smartico], options);
    yield put(saveBrandCrmService(data));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}
function* deleteBrandSmarticoCrmService({ payload }) {
  try {
    const { brandId, params } = payload;
    const options = {
      params: {
        brandId,
      },
    };
    const { DataCollector, Crm, Smartico } = AppConstants.api;
    yield put(changeLoadingState(true));
    yield call(NetworkService.makeAPIDeleteRequest, [DataCollector, Crm, Smartico], options);
    yield put(changeEndedStatus(true));
    yield put(changeLoadingState(false));
    yield put(setSuccessMessage(getMessage('', i18n.t('notification:slDeactivated'), params.name)));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* editBrandCrmSmarticoService({ payload }) {
  try {
    const { name, data } = payload;
    const options = {
      data,
    };
    const { DataCollector, Crm, Smartico } = AppConstants.api;
    yield put(changeLoadingState(true));
    yield call(NetworkService.makeAPIPutRequest, [DataCollector, Crm, Smartico], options);
    yield put(changeEndedStatus(true));
    yield put(changeLoadingState(false));
    yield put(setSuccessMessage(getMessage('', i18n.t('notification:slUpdated'), name)));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* changeBrandSmarticoCrmServiceStatus({ payload }) {
  try {
    const { brandId, data } = payload;
    const { name, isEnabled } = data;

    const options = {
      data: {
        isEnabled,
        brandId,
      },
    };
    const {
      DataCollector, Crm, Smartico, Status,
    } = AppConstants.api;
    yield put(changeLoadingState(true));
    yield call(NetworkService.makeAPIPutRequest, [DataCollector, Crm, Smartico, Status], options);
    yield put(changeEndedStatus(true));
    yield put(changeLoadingState(false));
    yield put(setSuccessMessage(getMessage('', i18n.t(!isEnabled ? 'notification:slDisabled' : 'notification:slEnabled'), name)));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}
function* getBrandGamificationServices({ payload }) {
  try {
    const { params } = payload;
    const options = {
      params,
    };
    const { BuilderAdmin, Gamifications } = AppConstants.api;
    yield put(changeLoadingState(true));
    const { data } = yield call(NetworkService.makeAPIGetRequest, [BuilderAdmin, Gamifications], options);
    yield put(saveBrandGamificationServices(data));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* addBrandSmarticoGamificationService({ payload }) {
  try {
    const { data, name } = payload;
    const options = {
      data,
    };
    const { BuilderAdmin, Gamifications, Smartico } = AppConstants.api;
    yield put(changeLoadingState(true));
    yield call(NetworkService.makeAPIPostRequest, [BuilderAdmin, Gamifications, Smartico], options);
    yield put(changeEndedStatus(true));
    yield put(changeLoadingState(false));
    yield put(setSuccessMessage(getMessage('', i18n.t('notification:slActivated'), name)));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}
function* getBrandSmarticoGamificationService({ payload }) {
  try {
    const { params } = payload;
    const options = {
      params,
    };
    const { BuilderAdmin, Gamifications, Smartico } = AppConstants.api;
    yield put(changeLoadingState(true));
    const { data } = yield call(NetworkService.makeAPIGetRequest, [BuilderAdmin, Gamifications, Smartico], options);
    yield put(saveBrandGamificationService(data));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}
function* deleteBrandSmarticoGamificationService({ payload }) {
  try {
    const { brandId, params } = payload;
    const options = {
      params: {
        brandId,
      },
    };
    const { BuilderAdmin, Gamifications, Smartico } = AppConstants.api;
    yield put(changeLoadingState(true));
    yield call(NetworkService.makeAPIDeleteRequest, [BuilderAdmin, Gamifications, Smartico], options);
    yield put(changeEndedStatus(true));
    yield put(changeLoadingState(false));
    yield put(setSuccessMessage(getMessage('', i18n.t('notification:slDeactivated'), params.name)));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* editBrandGamificationSmarticoService({ payload }) {
  try {
    const { name, data } = payload;
    const options = {
      data,
    };
    const { BuilderAdmin, Gamifications, Smartico } = AppConstants.api;
    yield put(changeLoadingState(true));
    yield call(NetworkService.makeAPIPutRequest, [BuilderAdmin, Gamifications, Smartico], options);
    yield put(changeEndedStatus(true));
    yield put(changeLoadingState(false));
    yield put(setSuccessMessage(getMessage('', i18n.t('notification:slUpdated'), name)));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* changeBrandSmarticoGamificationServiceStatus({ payload }) {
  try {
    const { brandId, data } = payload;
    const { name, isEnabled } = data;

    const options = {
      data: {
        isEnabled,
        brandId,
      },
    };
    const {
      BuilderAdmin, Gamifications, Smartico, Status,
    } = AppConstants.api;
    yield put(changeLoadingState(true));
    yield call(NetworkService.makeAPIPutRequest, [BuilderAdmin, Gamifications, Smartico, Status], options);
    yield put(changeEndedStatus(true));
    yield put(changeLoadingState(false));
    yield put(setSuccessMessage(getMessage('', i18n.t(!isEnabled ? 'notification:slDisabled' : 'notification:slEnabled'), name)));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getBrandRetailSolutionServices({ payload }) {
  try {
    const { params } = payload;
    const options = {
      params,
    };
    const { DataCollector, Retails } = AppConstants.api;
    yield put(changeLoadingState(true));
    const { data } = yield call(NetworkService.makeAPIGetRequest, [DataCollector, Retails], options);
    yield put(saveBrandRetailSolutionServices(data));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getBrandDigitainRetailService({ payload }) {
  try {
    const { params } = payload;
    const options = {
      params,
    };
    const { DataCollector, Retails, Digitain } = AppConstants.api;
    yield put(changeLoadingState(true));
    const { data } = yield call(NetworkService.makeAPIGetRequest, [DataCollector, Retails, Digitain], options);
    yield put(saveBrandRetailSolutionService(data));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* addBrandDigitainRetailService({ payload }) {
  try {
    const { data, name } = payload;
    const options = {
      data,
    };
    const { DataCollector, Retails, Digitain } = AppConstants.api;
    yield put(changeLoadingState(true));
    yield call(NetworkService.makeAPIPostRequest, [DataCollector, Retails, Digitain], options);
    yield put(changeEndedStatus(true));
    yield put(changeLoadingState(false));
    yield put(setSuccessMessage(getMessage('', i18n.t('notification:slActivated'), name)));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* deleteBrandDigitainRetailService({ payload }) {
  try {
    const { brandId, params } = payload;
    const options = {
      params: {
        brandId,
      },
    };
    const { DataCollector, Retails, Digitain } = AppConstants.api;
    yield put(changeLoadingState(true));
    yield call(NetworkService.makeAPIDeleteRequest, [DataCollector, Retails, Digitain], options);
    yield put(changeEndedStatus(true));
    yield put(changeLoadingState(false));
    yield put(setSuccessMessage(getMessage('', i18n.t('notification:slDeactivated'), params.name)));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}
function* editBrandDigitainRetailService({ payload }) {
  try {
    const { name, data } = payload;
    const options = {
      data,
    };
    const { DataCollector, Retails, Digitain } = AppConstants.api;
    yield put(changeLoadingState(true));
    yield call(NetworkService.makeAPIPutRequest, [DataCollector, Retails, Digitain], options);
    yield put(changeEndedStatus(true));
    yield put(changeLoadingState(false));
    yield put(setSuccessMessage(getMessage('', i18n.t('notification:slUpdated'), name)));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getBrandWhatsAppService({ payload }) {
  try {
    const { params } = payload;
    const options = {
      params,
    };
    const { BuilderAdmin, Chats, WhatsApp } = AppConstants.api;
    yield put(changeLoadingState(true));
    const { data } = yield call(NetworkService.makeAPIGetRequest, [BuilderAdmin, Chats, WhatsApp], options);
    yield put(saveBrandLiveChatSolutionService(data));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* addBrandWhatsAppService({ payload }) {
  try {
    const { data, name } = payload;
    const options = {
      data,
    };
    const { BuilderAdmin, Chats, WhatsApp } = AppConstants.api;
    yield put(changeLoadingState(true));
    yield call(NetworkService.makeAPIPostRequest, [BuilderAdmin, Chats, WhatsApp], options);
    yield put(changeEndedStatus(true));
    yield put(changeLoadingState(false));
    yield put(setSuccessMessage(getMessage('', i18n.t('notification:slIntegrated'), name)));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* editBrandWhatsAppService({ payload }) {
  try {
    const { name, data } = payload;
    const options = {
      data,
    };
    const { BuilderAdmin, Chats, WhatsApp } = AppConstants.api;
    yield put(changeLoadingState(true));
    yield call(NetworkService.makeAPIPutRequest, [BuilderAdmin, Chats, WhatsApp], options);
    yield put(changeEndedStatus(true));
    yield put(changeLoadingState(false));
    yield put(setSuccessMessage(getMessage('', i18n.t('notification:slUpdated'), name)));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* deleteBrandWhatsAppService({ payload }) {
  try {
    const { brandId, params } = payload;
    const options = {
      params: {
        brandId,
      },
    };
    const { BuilderAdmin, Chats, WhatsApp } = AppConstants.api;
    yield put(changeLoadingState(true));
    yield call(NetworkService.makeAPIDeleteRequest, [BuilderAdmin, Chats, WhatsApp], options);
    yield put(changeEndedStatus(true));
    yield put(changeLoadingState(false));
    yield put(setSuccessMessage(getMessage('', i18n.t('notification:slDeleted'), params.name)));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* changeBrandWhatsAppServiceStatus({ payload }) {
  try {
    const { brandId, data } = payload;
    const { name, isEnabled } = data;

    const options = {
      data: {
        isEnabled,
        brandId,
      },
    };
    const {
      BuilderAdmin, Chats, WhatsApp, Status,
    } = AppConstants.api;
    yield put(changeLoadingState(true));
    yield call(NetworkService.makeAPIPutRequest, [BuilderAdmin, Chats, WhatsApp, Status], options);
    yield put(changeEndedStatus(true));
    yield put(changeLoadingState(false));
    yield put(setSuccessMessage(getMessage('', i18n.t(!isEnabled ? 'notification:slDisabled' : 'notification:slEnabled'), name)));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* addBrandDigitainCrmService({ payload }) {
  try {
    const { brandId, data } = payload;
    const { name } = data;
    const options = {
      params: { brandId },
    };
    const { DataCollector, Crm, Digitain } = AppConstants.api;
    yield put(changeLoadingState(true));
    yield call(NetworkService.makeAPIPostRequest, [DataCollector, Crm, Digitain], options);
    yield put(changeEndedStatus(true));
    yield put(changeLoadingState(false));
    yield put(setSuccessMessage(getMessage('', i18n.t('notification:slActivated'), name)));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* deleteBrandDigitainCrmService({ payload }) {
  try {
    const { data, brandId } = payload;
    const { name } = data;
    const options = {
      params: {
        brandId,
      },
    };
    const { DataCollector, Crm, Digitain } = AppConstants.api;
    yield put(changeLoadingState(true));
    yield call(NetworkService.makeAPIDeleteRequest, [DataCollector, Crm, Digitain], options);
    yield put(changeEndedStatus(true));
    yield put(changeLoadingState(false));
    yield put(setSuccessMessage(getMessage('', i18n.t('notification:slDeactivated'), name)));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getBrandDigitainAffiliateService({ payload }) {
  try {
    const {
      data: { brandId },
    } = payload;
    const { DataCollector, Affiliates, Digitain } = AppConstants.api;
    yield put(changeLoadingState(true));
    const { data } = yield call(NetworkService.makeAPIGetRequest, [DataCollector, Affiliates, Digitain, brandId]);
    yield put(saveBrandAffiliateService(data));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* addBrandDigitainAffiliateService({ payload }) {
  try {
    const { name, data } = payload;
    const options = {
      data,
    };
    const { DataCollector, Affiliates, Digitain } = AppConstants.api;
    yield put(changeLoadingState(true));
    yield call(NetworkService.makeAPIPostRequest, [DataCollector, Affiliates, Digitain, data.brandId], options);
    yield put(changeEndedStatus(true));
    yield put(changeLoadingState(false));
    yield put(setSuccessMessage(getMessage('', i18n.t('notification:slIntegrated'), name)));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* editBrandDigitainAffiliateService({ payload }) {
  try {
    const { name, data } = payload;
    const options = {
      data,
    };
    const { DataCollector, Affiliates, Digitain } = AppConstants.api;
    yield put(changeLoadingState(true));
    yield call(NetworkService.makeAPIPutRequest, [DataCollector, Affiliates, Digitain, data.brandId], options);
    yield put(changeEndedStatus(true));
    yield put(changeLoadingState(false));
    yield put(setSuccessMessage(getMessage('', i18n.t('notification:slUpdated'), name)));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* deleteBrandDigitainAffiliateService({ payload }) {
  try {
    const { brandId, data } = payload;
    const options = {
      params: {
        brandId,
      },
    };
    const { DataCollector, Affiliates, Digitain } = AppConstants.api;
    yield put(changeLoadingState(true));
    yield call(NetworkService.makeAPIDeleteRequest, [DataCollector, Affiliates, Digitain, brandId], options);
    yield put(changeEndedStatus(true));
    yield put(changeLoadingState(false));
    yield put(setSuccessMessage(getMessage('', i18n.t('notification:slDeleted'), data.name)));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getBrandAlanbaseService({ payload }) {
  try {
    const {
      data: { brandId },
    } = payload;
    const { DataCollector, Affiliates, Alanbase } = AppConstants.api;
    yield put(changeLoadingState(true));
    const { data } = yield call(NetworkService.makeAPIGetRequest, [DataCollector, Affiliates, Alanbase, brandId]);
    yield put(saveBrandAffiliateService(data));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* addBrandAlanbaseService({ payload }) {
  try {
    const { name, data } = payload;
    const options = {
      data,
    };
    const { DataCollector, Affiliates, Alanbase } = AppConstants.api;
    yield put(changeLoadingState(true));
    yield call(NetworkService.makeAPIPostRequest, [DataCollector, Affiliates, Alanbase, data.brandId], options);
    yield put(changeEndedStatus(true));
    yield put(changeLoadingState(false));
    yield put(setSuccessMessage(getMessage('', i18n.t('notification:slIntegrated'), name)));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* editBrandAlanbaseService({ payload }) {
  try {
    const { name, data } = payload;
    const options = {
      data,
    };
    const { DataCollector, Affiliates, Alanbase } = AppConstants.api;
    yield put(changeLoadingState(true));
    yield call(NetworkService.makeAPIPutRequest, [DataCollector, Affiliates, Alanbase, data.brandId], options);
    yield put(changeEndedStatus(true));
    yield put(changeLoadingState(false));
    yield put(setSuccessMessage(getMessage('', i18n.t('notification:slUpdated'), name)));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* deleteBrandAlanbaseService({ payload }) {
  try {
    const { brandId, data } = payload;
    const options = {
      params: {
        brandId,
      },
    };
    const { DataCollector, Affiliates, Alanbase } = AppConstants.api;
    yield put(changeLoadingState(true));
    yield call(NetworkService.makeAPIDeleteRequest, [DataCollector, Affiliates, Alanbase, brandId], options);
    yield put(changeEndedStatus(true));
    yield put(changeLoadingState(false));
    yield put(setSuccessMessage(getMessage('', i18n.t('notification:slDeleted'), data.name)));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* addBrandThunderBiteGamificationService({ payload }) {
  try {
    const { data, name } = payload;
    const options = {
      data,
    };
    const { BuilderAdmin, Gamifications, ThunderBite } = AppConstants.api;
    yield put(changeLoadingState(true));
    yield call(NetworkService.makeAPIPostRequest, [BuilderAdmin, Gamifications, ThunderBite], options);
    yield put(changeEndedStatus(true));
    yield put(changeLoadingState(false));
    yield put(setSuccessMessage(getMessage('', i18n.t('notification:slActivated'), name)));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}
function* getBrandThunderBiteGamificationService({ payload }) {
  try {
    const { params } = payload;
    const options = {
      params,
    };
    const { BuilderAdmin, Gamifications, ThunderBite } = AppConstants.api;
    yield put(changeLoadingState(true));
    const { data } = yield call(NetworkService.makeAPIGetRequest, [BuilderAdmin, Gamifications, ThunderBite], options);
    yield put(saveBrandGamificationService(data));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}
function* deleteBrandThunderBiteGamificationService({ payload }) {
  try {
    const { brandId, params } = payload;
    const options = {
      params: {
        brandId,
      },
    };
    const { BuilderAdmin, Gamifications, ThunderBite } = AppConstants.api;
    yield put(changeLoadingState(true));
    yield call(NetworkService.makeAPIDeleteRequest, [BuilderAdmin, Gamifications, ThunderBite], options);
    yield put(changeEndedStatus(true));
    yield put(changeLoadingState(false));
    yield put(setSuccessMessage(getMessage('', i18n.t('notification:slDeactivated'), params.name)));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* editBrandGamificationThunderBiteService({ payload }) {
  try {
    const { name, data } = payload;
    const options = {
      data,
    };
    const { BuilderAdmin, Gamifications, ThunderBite } = AppConstants.api;
    yield put(changeLoadingState(true));
    yield call(NetworkService.makeAPIPutRequest, [BuilderAdmin, Gamifications, ThunderBite], options);
    yield put(changeEndedStatus(true));
    yield put(changeLoadingState(false));
    yield put(setSuccessMessage(getMessage('', i18n.t('notification:slUpdated'), name)));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* changeBrandThunderBiteGamificationServiceStatus({ payload }) {
  try {
    const { brandId, data } = payload;
    const { name, isEnabled } = data;

    const options = {
      data: {
        isEnabled,
        brandId,
      },
    };
    const {
      BuilderAdmin, Gamifications, ThunderBite, Status,
    } = AppConstants.api;
    yield put(changeLoadingState(true));
    yield call(NetworkService.makeAPIPutRequest, [BuilderAdmin, Gamifications, ThunderBite, Status], options);
    yield put(changeEndedStatus(true));
    yield put(changeLoadingState(false));
    yield put(setSuccessMessage(getMessage('', i18n.t(!isEnabled ? 'notification:slDisabled' : 'notification:slEnabled'), name)));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getBrandKycServices({ payload }) {
  try {
    const { params } = payload;
    const options = {
      params,
    };
    const { DataCollector, KYC } = AppConstants.api;
    yield put(changeLoadingState(true));
    const { data } = yield call(NetworkService.makeAPIGetRequest, [DataCollector, KYC], options);
    yield put(saveBrandKycServices(data));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getBrandMetaMapService({ payload }) {
  try {
    const { params } = payload;
    const options = {
      params,
    };
    const { DataCollector, KYC, MetaMap } = AppConstants.api;
    yield put(changeLoadingState(true));
    const { data } = yield call(NetworkService.makeAPIGetRequest, [DataCollector, KYC, MetaMap], options);
    yield put(saveBrandKycService(data));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* addBrandMetaMapService({ payload }) {
  try {
    const { name, data } = payload;
    const options = {
      data,
    };
    const { DataCollector, KYC, MetaMap } = AppConstants.api;
    yield put(changeLoadingState(true));
    yield call(NetworkService.makeAPIPostRequest, [DataCollector, KYC, MetaMap], options);
    yield put(changeLoadingState(false));
    yield put(changeEndedStatus(true));
    yield put(setSuccessMessage(getMessage('', i18n.t('notification:slIntegrated'), name)));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* editBrandMetaMapService({ payload }) {
  try {
    const { name, data } = payload;
    const options = {
      data,
    };
    const { DataCollector, KYC, MetaMap } = AppConstants.api;
    yield put(changeLoadingState(true));
    yield call(NetworkService.makeAPIPutRequest, [DataCollector, KYC, MetaMap], options);
    yield put(changeLoadingState(false));
    yield put(changeEndedStatus(true));
    yield put(setSuccessMessage(getMessage('', i18n.t('notification:slUpdated'), name)));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* deleteBrandMetaMapService({ payload }) {
  try {
    const { brandId, data } = payload;
    const options = {
      params: {
        brandId,
      },
    };
    const { DataCollector, KYC, MetaMap } = AppConstants.api;
    yield put(changeLoadingState(true));
    yield call(NetworkService.makeAPIDeleteRequest, [DataCollector, KYC, MetaMap], options);
    yield put(changeLoadingState(false));
    yield put(changeEndedStatus(true));
    yield put(setSuccessMessage(getMessage('', i18n.t('notification:slDeleted'), data.name)));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* changeBrandMetaMapServiceStatus({ payload }) {
  try {
    const { brandId, data } = payload;
    const { name, isEnabled } = data;

    const options = {
      data: {
        isEnabled,
        brandId,
      },
    };
    const {
      DataCollector, KYC, MetaMap, Status,
    } = AppConstants.api;
    yield put(changeLoadingState(true));
    yield call(NetworkService.makeAPIPutRequest, [DataCollector, KYC, MetaMap, Status], options);
    yield put(changeEndedStatus(true));
    yield put(changeLoadingState(false));
    yield put(setSuccessMessage(getMessage('', i18n.t(!isEnabled ? 'notification:slDisabled' : 'notification:slEnabled'), name)));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

export default function* thirdPartyServicesSaga() {
  yield all([
    takeLatest(ThirdPartyServicesActionType.GET_CUSTOM_SCRIPTS, getCustomScripts),
    takeLatest(ThirdPartyServicesActionType.ADD_CUSTOM_SCRIPT, addCustomScript),
    takeLatest(ThirdPartyServicesActionType.EDIT_CUSTOM_SCRIPT, editCustomScript),
    takeLatest(ThirdPartyServicesActionType.DELETE_CUSTOM_SCRIPT, deleteCustomScript),
    takeLatest(ThirdPartyServicesActionType.CHANGE_CUSTOM_SCRIPT_STATUS, changeCustomScriptStatus),

    takeLatest(ThirdPartyServicesActionType.GET_BRAND_AFFILIATE_SERVICES, getBrandAffiliateServices),
    takeLatest(ThirdPartyServicesActionType.ADD_BRAND_EVERY_MATRIX_AFFILIATE_SERVICE, addBrandEveryMatrixAffiliateService),
    takeLatest(ThirdPartyServicesActionType.DELETE_BRAND_EVERY_MATRIX_AFFILIATE_SERVICE, deleteBrandEveryMatrixAffiliateService),
    takeLatest(ThirdPartyServicesActionType.ADD_BRAND_SMARTICO_AFFILIATE_SERVICE, addBrandSmarticoAffiliateService),
    takeLatest(ThirdPartyServicesActionType.DELETE_BRAND_SMARTICO_AFFILIATE_SERVICE, deleteBrandSmarticoAffiliateService),
    takeLatest(ThirdPartyServicesActionType.ADD_BRAND_MY_AFFILIATES_AFFILIATE_SERVICE, addBrandMyAffiliatesService),
    takeLatest(ThirdPartyServicesActionType.DELETE_BRAND_MY_AFFILIATES_AFFILIATE_SERVICE, deleteBrandMyAffiliatesService),

    takeLatest(ThirdPartyServicesActionType.GET_BRAND_ANALYTIC_SERVICES, getBrandAnalyticServices),
    takeLatest(ThirdPartyServicesActionType.GET_BRAND_HOTJAR_SERVICE, getBrandHotJarService),
    takeLatest(ThirdPartyServicesActionType.ADD_BRAND_HOTJAR_SERVICE, addBrandHotJarService),
    takeLatest(ThirdPartyServicesActionType.EDIT_BRAND_HOTJAR_SERVICE, editBrandHotJarService),
    takeLatest(ThirdPartyServicesActionType.DELETE_BRAND_HOTJAR_SERVICE, deleteBrandHotJarService),
    takeLatest(ThirdPartyServicesActionType.CHANGE_BRAND_HOTJAR_SERVICE_STATUS, changeBrandHotJarServiceStatus),
    takeLatest(ThirdPartyServicesActionType.GET_BRAND_GOOGLE_ANALYTICS_SERVICE, getBrandGoogleAnalyticsService),
    takeLatest(ThirdPartyServicesActionType.ADD_BRAND_GOOGLE_ANALYTICS_SERVICE, addBrandGoogleAnalyticsService),
    takeLatest(ThirdPartyServicesActionType.EDIT_BRAND_GOOGLE_ANALYTICS_SERVICE, editBrandGoogleAnalyticsService),
    takeLatest(ThirdPartyServicesActionType.DELETE_BRAND_GOOGLE_ANALYTICS_SERVICE, deleteBrandGoogleAnalyticsService),
    takeLatest(ThirdPartyServicesActionType.CHANGE_BRAND_GOOGLE_ANALYTICS_SERVICE_STATUS, changeBrandGoogleAnalyticsServiceStatus),
    takeLatest(ThirdPartyServicesActionType.GET_BRAND_GOOGLE_SEARCH_CONSOLE_SERVICE, getBrandGoogleSearchConsoleService),
    takeLatest(ThirdPartyServicesActionType.ADD_BRAND_GOOGLE_SEARCH_CONSOLE_SERVICE, addBrandGoogleSearchConsoleService),
    takeLatest(ThirdPartyServicesActionType.EDIT_BRAND_GOOGLE_SEARCH_CONSOLE_SERVICE, editBrandGoogleSearchConsoleService),
    takeLatest(ThirdPartyServicesActionType.DELETE_BRAND_GOOGLE_SEARCH_CONSOLE_SERVICE, deleteBrandGoogleSearchConsoleService),
    takeLatest(ThirdPartyServicesActionType.CHANGE_BRAND_GOOGLE_SEARCH_CONSOLE_SERVICE_STATUS, changeBrandGoogleSearchConsoleServiceStatus),

    takeLatest(ThirdPartyServicesActionType.GET_BRAND_LIVE_CHAT_SOLUTION_SERVICES, getBrandLiveChatSolutionServices),
    takeLatest(ThirdPartyServicesActionType.GET_BRAND_LIVE_CHAT_SERVICE, getBrandLiveChatService),
    takeLatest(ThirdPartyServicesActionType.ADD_BRAND_LIVE_CHAT_SERVICE, addBrandLiveChatService),
    takeLatest(ThirdPartyServicesActionType.EDIT_BRAND_LIVE_CHAT_SERVICE, editBrandLiveChatService),
    takeLatest(ThirdPartyServicesActionType.DELETE_BRAND_LIVE_CHAT_SERVICE, deleteBrandLiveChatService),
    takeLatest(ThirdPartyServicesActionType.CHANGE_BRAND_LIVE_CHAT_SERVICE_STATUS, changeBrandLiveChatServiceStatus),
    takeLatest(ThirdPartyServicesActionType.GET_BRAND_LIVE_AGENT_SERVICE, getBrandLiveAgentService),
    takeLatest(ThirdPartyServicesActionType.ADD_BRAND_LIVE_AGENT_SERVICE, addBrandLiveAgentService),
    takeLatest(ThirdPartyServicesActionType.EDIT_BRAND_LIVE_AGENT_SERVICE, editBrandLiveAgentService),
    takeLatest(ThirdPartyServicesActionType.DELETE_BRAND_LIVE_AGENT_SERVICE, deleteBrandLiveAgentService),
    takeLatest(ThirdPartyServicesActionType.CHANGE_BRAND_LIVE_AGENT_SERVICE_STATUS, changeBrandLiveAgentServiceStatus),
    takeLatest(ThirdPartyServicesActionType.GET_BRAND_ZENDESK_SERVICE, getBrandZendeskService),
    takeLatest(ThirdPartyServicesActionType.ADD_BRAND_ZENDESK_SERVICE, addBrandZendeskService),
    takeLatest(ThirdPartyServicesActionType.EDIT_BRAND_ZENDESK_SERVICE, editBrandZendeskService),
    takeLatest(ThirdPartyServicesActionType.DELETE_BRAND_ZENDESK_SERVICE, deleteBrandZendeskService),
    takeLatest(ThirdPartyServicesActionType.CHANGE_BRAND_ZENDESK_SERVICE_STATUS, changeBrandZendeskServiceStatus),
    takeLatest(ThirdPartyServicesActionType.GET_BRAND_INTERCOM_SERVICE, getBrandIntercomService),
    takeLatest(ThirdPartyServicesActionType.ADD_BRAND_INTERCOM_SERVICE, addBrandIntercomService),
    takeLatest(ThirdPartyServicesActionType.EDIT_BRAND_INTERCOM_SERVICE, editBrandIntercomService),
    takeLatest(ThirdPartyServicesActionType.DELETE_BRAND_INTERCOM_SERVICE, deleteBrandIntercomService),
    takeLatest(ThirdPartyServicesActionType.CHANGE_BRAND_INTERCOM_SERVICE_STATUS, changeBrandIntercomServiceStatus),

    takeLatest(ThirdPartyServicesActionType.GET_BRAND_AG_SOLUTION_SERVICES, getBrandAgSolutionServices),
    takeLatest(ThirdPartyServicesActionType.ADD_BRAND_EVERY_MATRIX_AG_SERVICE, addBrandEveryMatrixAgService),
    takeLatest(ThirdPartyServicesActionType.DELETE_BRAND_EVERY_MATRIX_AG_SERVICE, deleteBrandEveryMatrixAgService),
    takeLatest(ThirdPartyServicesActionType.GET_BRAND_DIGITAIN_AG_SERVICE, getBrandDigitainAgService),
    takeLatest(ThirdPartyServicesActionType.ADD_BRAND_DIGITAIN_AG_SERVICE, addBrandDigitainAgService),
    takeLatest(ThirdPartyServicesActionType.DELETE_BRAND_DIGITAIN_AG_SERVICE, deleteBrandDigitainAgService),
    takeLatest(ThirdPartyServicesActionType.EDIT_BRAND_DIGITAIN_AG_SERVICE, editBrandDigitainAgService),
    takeLatest(ThirdPartyServicesActionType.CONFIGURE_BRAND_DIGITAIN_AG_SERVICE_PAYMENT_LIMITS, configureBrandDigitainAgService),
    takeLatest(ThirdPartyServicesActionType.GET_BRAND_DIGITAIN_AG_SERVICE_PAYMENT_LIMITS, getBrandDigitainAgServicePaymentLimits),

    takeLatest(ThirdPartyServicesActionType.GET_BRAND_CRM_SERVICES, getBrandCrmServices),
    takeLatest(ThirdPartyServicesActionType.ADD_BRAND_SMARTICO_CRM_SERVICE, addBrandSmarticoCrmService),
    takeLatest(ThirdPartyServicesActionType.DELETE_BRAND_SMARTICO_CRM_SERVICE, deleteBrandSmarticoCrmService),
    takeLatest(ThirdPartyServicesActionType.GET_BRAND_SMARTICO_CRM_SERVICE, getBrandSmarticoCrmService),
    takeLatest(ThirdPartyServicesActionType.EDIT_BRAND_SMARTICO_CRM_SERVICE, editBrandCrmSmarticoService),
    takeLatest(ThirdPartyServicesActionType.CHANGE_BRAND_SMARTICO_CRM_SERVICE_STATUS, changeBrandSmarticoCrmServiceStatus),
    takeLatest(ThirdPartyServicesActionType.ADD_BRAND_DIGITAIN_CRM_SERVICE, addBrandDigitainCrmService),
    takeLatest(ThirdPartyServicesActionType.DELETE_BRAND_DIGITAIN_CRM_SERVICE, deleteBrandDigitainCrmService),

    takeLatest(ThirdPartyServicesActionType.GET_BRAND_GAMIFICATION_SERVICES, getBrandGamificationServices),
    takeLatest(ThirdPartyServicesActionType.ADD_BRAND_SMARTICO_GAMIFICATION_SERVICE, addBrandSmarticoGamificationService),
    takeLatest(ThirdPartyServicesActionType.DELETE_BRAND_SMARTICO_GAMIFICATION_SERVICE, deleteBrandSmarticoGamificationService),
    takeLatest(ThirdPartyServicesActionType.GET_BRAND_SMARTICO_GAMIFICATION_SERVICE, getBrandSmarticoGamificationService),
    takeLatest(ThirdPartyServicesActionType.EDIT_BRAND_SMARTICO_GAMIFICATION_SERVICE, editBrandGamificationSmarticoService),
    takeLatest(ThirdPartyServicesActionType.CHANGE_BRAND_SMARTICO_GAMIFICATION_SERVICE_STATUS, changeBrandSmarticoGamificationServiceStatus),
    takeLatest(ThirdPartyServicesActionType.ADD_BRAND_THUNDERBITE_GAMIFICATION_SERVICE, addBrandThunderBiteGamificationService),
    takeLatest(ThirdPartyServicesActionType.DELETE_BRAND_THUNDERBITE_GAMIFICATION_SERVICE, deleteBrandThunderBiteGamificationService),
    takeLatest(ThirdPartyServicesActionType.GET_BRAND_THUNDERBITE_GAMIFICATION_SERVICE, getBrandThunderBiteGamificationService),
    takeLatest(ThirdPartyServicesActionType.EDIT_BRAND_THUNDERBITE_GAMIFICATION_SERVICE, editBrandGamificationThunderBiteService),
    takeLatest(ThirdPartyServicesActionType.CHANGE_BRAND_THUNDERBITE_GAMIFICATION_SERVICE_STATUS, changeBrandThunderBiteGamificationServiceStatus),

    takeLatest(ThirdPartyServicesActionType.GET_BRAND_RETAIL_SOLUTION_SERVICES, getBrandRetailSolutionServices),
    takeLatest(ThirdPartyServicesActionType.GET_BRAND_DIGITAIN_RETAIL_SERVICE, getBrandDigitainRetailService),
    takeLatest(ThirdPartyServicesActionType.ADD_BRAND_DIGITAIN_RETAIL_SERVICE, addBrandDigitainRetailService),
    takeLatest(ThirdPartyServicesActionType.DELETE_BRAND_DIGITAIN_RETAIL_SERVICE, deleteBrandDigitainRetailService),
    takeLatest(ThirdPartyServicesActionType.EDIT_BRAND_DIGITAIN_RETAIL_SERVICE, editBrandDigitainRetailService),

    takeLatest(ThirdPartyServicesActionType.GET_BRAND_WHATSAPP_SERVICE, getBrandWhatsAppService),
    takeLatest(ThirdPartyServicesActionType.ADD_BRAND_WHATSAPP_SERVICE, addBrandWhatsAppService),
    takeLatest(ThirdPartyServicesActionType.EDIT_BRAND_WHATSAPP_SERVICE, editBrandWhatsAppService),
    takeLatest(ThirdPartyServicesActionType.DELETE_BRAND_WHATSAPP_SERVICE, deleteBrandWhatsAppService),
    takeLatest(ThirdPartyServicesActionType.CHANGE_BRAND_WHATSAPP_SERVICE_STATUS, changeBrandWhatsAppServiceStatus),

    takeLatest(ThirdPartyServicesActionType.GET_BRAND_DIGITAIN_AFFILIATE_SERVICE, getBrandDigitainAffiliateService),
    takeLatest(ThirdPartyServicesActionType.ADD_BRAND_DIGITAIN_AFFILIATE_SERVICE, addBrandDigitainAffiliateService),
    takeLatest(ThirdPartyServicesActionType.EDIT_BRAND_DIGITAIN_AFFILIATE_SERVICE, editBrandDigitainAffiliateService),
    takeLatest(ThirdPartyServicesActionType.DELETE_BRAND_DIGITAIN_AFFILIATE_SERVICE, deleteBrandDigitainAffiliateService),

    takeLatest(ThirdPartyServicesActionType.GET_BRAND_ALANBASE_SERVICE, getBrandAlanbaseService),
    takeLatest(ThirdPartyServicesActionType.ADD_BRAND_ALANBASE_SERVICE, addBrandAlanbaseService),
    takeLatest(ThirdPartyServicesActionType.EDIT_BRAND_ALANBASE_SERVICE, editBrandAlanbaseService),
    takeLatest(ThirdPartyServicesActionType.DELETE_BRAND_ALANBASE_SERVICE, deleteBrandAlanbaseService),

    takeLatest(ThirdPartyServicesActionType.GET_BRAND_KYC_SERVICES, getBrandKycServices),
    takeLatest(ThirdPartyServicesActionType.GET_BRAND_METAMAP_SERVICE, getBrandMetaMapService),
    takeLatest(ThirdPartyServicesActionType.ADD_BRAND_METAMAP_SERVICE, addBrandMetaMapService),
    takeLatest(ThirdPartyServicesActionType.EDIT_BRAND_METAMAP_SERVICE, editBrandMetaMapService),
    takeLatest(ThirdPartyServicesActionType.DELETE_BRAND_METAMAP_SERVICE, deleteBrandMetaMapService),
    takeLatest(ThirdPartyServicesActionType.CHANGE_BRAND_METAMAP_SERVICE_STATUS, changeBrandMetaMapServiceStatus),
  ]);
}
