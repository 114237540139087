import {
  all, call, put, takeEvery, takeLatest,
} from 'redux-saga/effects';
import qs from 'qs';
import {
  changeEndedStatus, changeLoadingState, setErrorMessage, setSuccessMessage,
} from 'actions/setting/settingActionCreator';
import {
  saveCashierDeposit,
  saveCashierInventory,
  saveDepositPaymentMethodsResource,
  saveCashierDepositCategory,
  saveCashierWithdrawal,
  saveCashierWithdrawalCategory,
  saveWithdrawalPaymentMethodsResource,
  saveCashierDepositPaymentMethods,
  saveDepositCategoryResource,
  saveCashierWithdrawalPaymentMethods,
  saveWithdrawalCategoryResource,
  saveCashierGeneralSettings,
  saveCashierBrandSettings,
} from 'actions/cashier/cashierActionCreator';
import NetworkService from 'services/networkService';
import { AppConstants } from 'constants/index';
import CashierActionType from 'actions/cashier/cashierActionType';
import { getError, getMessage } from 'utils/helpers';
import { i18n } from 'services';

function* getCashierInventory({ payload }) {
  try {
    const { params } = payload;
    const options = {
      params,
      paramsSerializer: (param) => qs.stringify(param),
    };
    const { PaymentAdmin, CashierInventory } = AppConstants.api;
    yield put(changeLoadingState(true));
    const { data } = yield call(NetworkService.makeAPIGetRequest, [PaymentAdmin, CashierInventory], options);
    yield put(saveCashierInventory(data));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* editCashierInventory({ payload }) {
  try {
    const { id, brandId, data } = payload;
    yield put(changeLoadingState(true));
    const { PaymentAdmin, CashierInventory } = AppConstants.api;
    yield call(NetworkService.makeAPIPutRequest, [PaymentAdmin, CashierInventory, id, brandId], { data });
    yield put(changeEndedStatus(true));
    yield put(setSuccessMessage(getMessage(i18n.t('cms:thePaymentMethod'), i18n.t('notification:slUpdated'))));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getCashierDeposit({ payload }) {
  try {
    const { params } = payload;
    const options = {
      params,
      paramsSerializer: (param) => qs.stringify(param),
    };
    const { PaymentAdmin, CashierDeposit } = AppConstants.api;
    yield put(changeLoadingState(true));
    const { data } = yield call(NetworkService.makeAPIGetRequest, [PaymentAdmin, CashierDeposit], options);
    yield put(saveCashierDeposit(data));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getCashierWithdrawal({ payload }) {
  try {
    const { params } = payload;
    const options = {
      params,
      paramsSerializer: (param) => qs.stringify(param),
    };
    const { PaymentAdmin, CashierWithdrawal } = AppConstants.api;
    yield put(changeLoadingState(true));
    const { data } = yield call(NetworkService.makeAPIGetRequest, [PaymentAdmin, CashierWithdrawal], options);
    yield put(saveCashierWithdrawal(data));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* deleteCashierDeposit({ payload }) {
  try {
    const { id } = payload;
    yield put(changeLoadingState(true));
    const { PaymentAdmin, CashierDeposit } = AppConstants.api;
    yield call(NetworkService.makeAPIDeleteRequest, [PaymentAdmin, CashierDeposit, id]);
    yield put(changeEndedStatus(true));
    yield put(setSuccessMessage(getMessage(i18n.t('cms:theCategory'), i18n.t('notification:slDeleted'))));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* createCashierDeposit({ payload }) {
  try {
    const { PaymentAdmin, CashierDeposit } = AppConstants.api;
    yield put(changeLoadingState(true));
    yield call(NetworkService.makeAPIPostRequest, [PaymentAdmin, CashierDeposit], payload);
    yield put(setSuccessMessage(getMessage(i18n.t('cms:theCategory'), i18n.t('notification:slCreated'))));
    yield put(changeLoadingState(false));
    yield put(changeEndedStatus(true));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* editCashierDeposit({ payload }) {
  try {
    const { id, data } = payload;
    yield put(changeLoadingState(true));
    const { PaymentAdmin, CashierDeposit } = AppConstants.api;
    yield call(NetworkService.makeAPIPutRequest, [PaymentAdmin, CashierDeposit, id], { data });
    yield put(changeEndedStatus(true));
    yield put(setSuccessMessage(getMessage(i18n.t('cms:theCategory'), i18n.t('notification:slUpdated'))));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* reorderCashierDeposit({ payload }) {
  try {
    const { categoryId, data } = payload;
    const { PaymentAdmin, CashierDeposit, Reorder } = AppConstants.api;
    yield call(NetworkService.makeAPIPutRequest, [PaymentAdmin, CashierDeposit, Reorder, categoryId], { data });
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getDepositPaymentMethodsResource({ payload }) {
  const { isLoading } = payload;
  try {
    const { params } = payload;
    const options = {
      params,
    };
    const { PaymentAdmin, CashierDeposit, PaymentMethod } = AppConstants.api;
    if (isLoading) {
      yield put(changeLoadingState(true));
    }
    const { data } = yield call(NetworkService.makeAPIGetRequest, [PaymentAdmin, CashierDeposit, PaymentMethod], options);
    yield put(saveDepositPaymentMethodsResource(data));
    if (isLoading) {
      yield put(changeLoadingState(false));
    }
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    if (isLoading) {
      yield put(changeLoadingState(false));
    }
  }
}

function* getCashierDepositCategory({ payload }) {
  try {
    const { id, params } = payload;
    const options = {
      params,
    };
    const { PaymentAdmin, CashierDeposit } = AppConstants.api;
    yield put(changeLoadingState(true));
    const { data } = yield call(NetworkService.makeAPIGetRequest, [PaymentAdmin, CashierDeposit, id], options);
    yield put(saveCashierDepositCategory(data));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err, true);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getWithdrawalPaymentMethodsResource({ payload }) {
  const { isLoading } = payload;
  try {
    const { params } = payload;
    const options = {
      params,
    };
    const { PaymentAdmin, CashierWithdrawal, PaymentMethod } = AppConstants.api;
    if (isLoading) {
      yield put(changeLoadingState(true));
    }
    const { data } = yield call(NetworkService.makeAPIGetRequest, [PaymentAdmin, CashierWithdrawal, PaymentMethod], options);
    yield put(saveWithdrawalPaymentMethodsResource(data));
    if (isLoading) {
      yield put(changeLoadingState(false));
    }
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    if (isLoading) {
      yield put(changeLoadingState(false));
    }
  }
}

function* getCashierWithdrawalCategory({ payload }) {
  try {
    const { id, params } = payload;
    const options = {
      params,
    };
    const { PaymentAdmin, CashierWithdrawal } = AppConstants.api;
    yield put(changeLoadingState(true));
    const { data } = yield call(NetworkService.makeAPIGetRequest, [PaymentAdmin, CashierWithdrawal, id], options);
    yield put(saveCashierWithdrawalCategory(data));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err, true);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* deleteCashierWithdrawal({ payload }) {
  try {
    const { id } = payload;
    yield put(changeLoadingState(true));
    const { PaymentAdmin, CashierWithdrawal } = AppConstants.api;
    yield call(NetworkService.makeAPIDeleteRequest, [PaymentAdmin, CashierWithdrawal, id]);
    yield put(changeEndedStatus(true));
    yield put(setSuccessMessage(getMessage(i18n.t('cms:theCategory'), i18n.t('notification:slDeleted'))));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* createCashierWithdrawal({ payload }) {
  try {
    const { PaymentAdmin, CashierWithdrawal } = AppConstants.api;
    yield put(changeLoadingState(true));
    yield call(NetworkService.makeAPIPostRequest, [PaymentAdmin, CashierWithdrawal], payload);
    yield put(setSuccessMessage(getMessage(i18n.t('cms:theCategory'), i18n.t('notification:slCreated'))));
    yield put(changeLoadingState(false));
    yield put(changeEndedStatus(true));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* editCashierWithdrawal({ payload }) {
  try {
    const { id, data } = payload;
    yield put(changeLoadingState(true));
    const { PaymentAdmin, CashierWithdrawal } = AppConstants.api;
    yield call(NetworkService.makeAPIPutRequest, [PaymentAdmin, CashierWithdrawal, id], { data });
    yield put(changeEndedStatus(true));
    yield put(setSuccessMessage(getMessage(i18n.t('cms:theCategory'), i18n.t('notification:slUpdated'))));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* reorderCashierWithdrawal({ payload }) {
  try {
    const { categoryId, data } = payload;
    const { PaymentAdmin, CashierWithdrawal, Reorder } = AppConstants.api;
    yield call(NetworkService.makeAPIPutRequest, [PaymentAdmin, CashierWithdrawal, Reorder, categoryId], { data });
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getCashierDepositPaymentMethods({ payload }) {
  try {
    const { id, params } = payload;
    const options = {
      params,
      paramsSerializer: (param) => qs.stringify(param),
    };
    const { PaymentAdmin, CashierDepositPayment } = AppConstants.api;
    yield put(changeLoadingState(true));
    const { data } = yield call(NetworkService.makeAPIGetRequest, [PaymentAdmin, CashierDepositPayment, id], options);
    yield put(saveCashierDepositPaymentMethods(data));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err, true);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* depositPaymentMethodStateChange({ payload }) {
  try {
    const { id, data } = payload;
    const { isActive, ...others } = data;
    const options = {
      data: others,
    };
    yield put(changeLoadingState(true));
    const { PaymentAdmin, CashierDepositPayment, State } = AppConstants.api;
    yield call(NetworkService.makeAPIPutRequest, [PaymentAdmin, CashierDepositPayment, State, id], options);
    yield put(changeEndedStatus(true));
    yield put(setSuccessMessage(getMessage(i18n.t('cms:thePaymentMethod'), i18n.t(isActive ? 'notification:slEnabled' : 'notification:slDisabled'))));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* depositPaymentMethodMove({ payload }) {
  try {
    const { id, data } = payload;
    const { paymentMethods } = data;
    yield put(changeLoadingState(true));
    const { PaymentAdmin, CashierDepositPayment, Move } = AppConstants.api;
    yield call(NetworkService.makeAPIPutRequest, [PaymentAdmin, CashierDepositPayment, Move, id], { data });
    yield put(changeEndedStatus(true));
    yield put(
      setSuccessMessage(
        getMessage(i18n.t(paymentMethods.length > 1 ? 'cms:thePaymentMethods' : 'cms:thePaymentMethod'), i18n.t(paymentMethods.length > 1 ? 'notification:plMoved' : 'notification:slMoved')),
      ),
    );
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* depositPaymentMethodReorder({ payload }) {
  try {
    const { id, data } = payload;
    const { PaymentAdmin, CashierDepositPayment, Reorder } = AppConstants.api;
    yield call(NetworkService.makeAPIPutRequest, [PaymentAdmin, CashierDepositPayment, Reorder, id], { data });
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getDepositCategoryResource({ payload }) {
  const { isLoading } = payload;
  try {
    const { params } = payload;
    const options = {
      params,
    };
    const { PaymentAdmin, CashierDeposit, Category } = AppConstants.api;
    if (isLoading) {
      yield put(changeLoadingState(true));
    }
    const { data } = yield call(NetworkService.makeAPIGetRequest, [PaymentAdmin, CashierDeposit, Category], options);
    yield put(saveDepositCategoryResource(data));
    if (isLoading) {
      yield put(changeLoadingState(false));
    }
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    if (isLoading) {
      yield put(changeLoadingState(false));
    }
  }
}

function* getCashierWithdrawalPaymentMethods({ payload }) {
  try {
    const { id, params } = payload;
    const options = {
      params,
      paramsSerializer: (param) => qs.stringify(param),
    };
    const { PaymentAdmin, CashierWithdrawalPayment } = AppConstants.api;
    yield put(changeLoadingState(true));
    const { data } = yield call(NetworkService.makeAPIGetRequest, [PaymentAdmin, CashierWithdrawalPayment, id], options);
    yield put(saveCashierWithdrawalPaymentMethods(data));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err, true);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getWithdrawalCategoryResource({ payload }) {
  const { isLoading } = payload;
  try {
    const { params } = payload;
    const options = {
      params,
    };
    const { PaymentAdmin, CashierWithdrawal, Category } = AppConstants.api;
    if (isLoading) {
      yield put(changeLoadingState(true));
    }
    const { data } = yield call(NetworkService.makeAPIGetRequest, [PaymentAdmin, CashierWithdrawal, Category], options);
    yield put(saveWithdrawalCategoryResource(data));
    if (isLoading) {
      yield put(changeLoadingState(false));
    }
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    if (isLoading) {
      yield put(changeLoadingState(false));
    }
  }
}

function* withdrawalPaymentMethodStateChange({ payload }) {
  try {
    const { id, data } = payload;
    const { isActive, ...others } = data;
    const options = {
      data: others,
    };
    yield put(changeLoadingState(true));
    const { PaymentAdmin, CashierWithdrawalPayment, State } = AppConstants.api;
    yield call(NetworkService.makeAPIPutRequest, [PaymentAdmin, CashierWithdrawalPayment, State, id], options);
    yield put(changeEndedStatus(true));
    yield put(setSuccessMessage(getMessage(i18n.t('cms:thePaymentMethod'), i18n.t(isActive ? 'notification:slEnabled' : 'notification:slDisabled'))));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* withdrawalPaymentMethodMove({ payload }) {
  try {
    const { id, data } = payload;
    const { paymentMethods } = data;
    yield put(changeLoadingState(true));
    const { PaymentAdmin, CashierWithdrawalPayment, Move } = AppConstants.api;
    yield call(NetworkService.makeAPIPutRequest, [PaymentAdmin, CashierWithdrawalPayment, Move, id], { data });
    yield put(changeEndedStatus(true));
    yield put(
      setSuccessMessage(
        getMessage(i18n.t(paymentMethods.length > 1 ? 'cms:thePaymentMethods' : 'cms:thePaymentMethod'), i18n.t(paymentMethods.length > 1 ? 'notification:plMoved' : 'notification:slMoved')),
      ),
    );
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* withdrawalPaymentMethodReorder({ payload }) {
  try {
    const { id, data } = payload;
    const { PaymentAdmin, CashierWithdrawalPayment, Reorder } = AppConstants.api;
    yield call(NetworkService.makeAPIPutRequest, [PaymentAdmin, CashierWithdrawalPayment, Reorder, id], { data });
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getCashierSettings({ payload }) {
  try {
    const { brandId } = payload;
    const { BrandAdmin, GeneralSetting, CashierSetting } = AppConstants.api;
    yield put(changeLoadingState(true));
    const { data } = yield call(NetworkService.makeAPIGetRequest, [BrandAdmin, GeneralSetting, CashierSetting, brandId]);
    yield put(brandId ? saveCashierBrandSettings(data) : saveCashierGeneralSettings(data));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* editCashierGeneralSettings({ payload }) {
  try {
    const { brandId, data } = payload;
    yield put(changeLoadingState(true));
    const { BrandAdmin, GeneralSetting, CashierSetting } = AppConstants.api;
    yield call(NetworkService.makeAPIPutRequest, [BrandAdmin, GeneralSetting, CashierSetting, brandId], { data });
    yield put(changeEndedStatus(true));
    yield put(setSuccessMessage(getMessage(i18n.t('cms:cashierSettings'), i18n.t('notification:plUpdated'))));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

export default function* cashierSaga() {
  yield all([
    takeLatest(CashierActionType.GET_CASHIER_INVENTORY, getCashierInventory),
    takeLatest(CashierActionType.EDIT_CASHIER_INVENTORY, editCashierInventory),
    takeLatest(CashierActionType.GET_CASHIER_DEPOSIT, getCashierDeposit),
    takeLatest(CashierActionType.GET_CASHIER_WITHDRAWAL, getCashierWithdrawal),
    takeLatest(CashierActionType.DELETE_CASHIER_DEPOSIT, deleteCashierDeposit),
    takeLatest(CashierActionType.CREATE_CASHIER_DEPOSIT, createCashierDeposit),
    takeLatest(CashierActionType.EDIT_CASHIER_DEPOSIT, editCashierDeposit),
    takeLatest(CashierActionType.REORDER_CASHIER_DEPOSIT, reorderCashierDeposit),
    takeLatest(CashierActionType.GET_DEPOSIT_PAYMENT_METHODS_RESOURCE, getDepositPaymentMethodsResource),
    takeLatest(CashierActionType.GET_CASHIER_DEPOSIT_CATEGORY, getCashierDepositCategory),
    takeLatest(CashierActionType.GET_WITHDRAWAL_PAYMENT_METHODS_RESOURCE, getWithdrawalPaymentMethodsResource),
    takeLatest(CashierActionType.GET_CASHIER_WITHDRAWAL_CATEGORY, getCashierWithdrawalCategory),
    takeLatest(CashierActionType.DELETE_CASHIER_WITHDRAWAL, deleteCashierWithdrawal),
    takeLatest(CashierActionType.CREATE_CASHIER_WITHDRAWAL, createCashierWithdrawal),
    takeLatest(CashierActionType.EDIT_CASHIER_WITHDRAWAL, editCashierWithdrawal),
    takeLatest(CashierActionType.REORDER_CASHIER_WITHDRAWAL, reorderCashierWithdrawal),
    takeLatest(CashierActionType.GET_CASHIER_DEPOSIT_PAYMENT_METHODS, getCashierDepositPaymentMethods),
    takeLatest(CashierActionType.DEPOSIT_PAYMENT_METHOD_STATE_CHANGE, depositPaymentMethodStateChange),
    takeLatest(CashierActionType.DEPOSIT_PAYMENT_METHOD_MOVE, depositPaymentMethodMove),
    takeLatest(CashierActionType.DEPOSIT_PAYMENT_METHOD_REORDER, depositPaymentMethodReorder),
    takeLatest(CashierActionType.GET_DEPOSIT_CATEGORY_RESOURCE, getDepositCategoryResource),
    takeLatest(CashierActionType.GET_CASHIER_WITHDRAWAL_PAYMENT_METHODS, getCashierWithdrawalPaymentMethods),
    takeLatest(CashierActionType.GET_WITHDRAWAL_CATEGORY_RESOURCE, getWithdrawalCategoryResource),
    takeLatest(CashierActionType.WITHDRAWAL_PAYMENT_METHOD_STATE_CHANGE, withdrawalPaymentMethodStateChange),
    takeLatest(CashierActionType.WITHDRAWAL_PAYMENT_METHOD_MOVE, withdrawalPaymentMethodMove),
    takeLatest(CashierActionType.WITHDRAWAL_PAYMENT_METHOD_REORDER, withdrawalPaymentMethodReorder),
    takeEvery(CashierActionType.GET_CASHIER_SETTINGS, getCashierSettings),
    takeLatest(CashierActionType.EDIT_CASHIER_GENERAL_SETTINGS, editCashierGeneralSettings),
  ]);
}
