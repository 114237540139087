import styled from 'styled-components';
import { media, truncate } from 'utils/styledHelpers';
import { StyledSvgIconHover } from 'components/styledComponents/icon/Icon.styled';

const StyledCustomTableContainer = styled.div`
  border-radius: ${(props) => `${props.theme.customBorderRadius.borderRadiusSmall} ${props.theme.customBorderRadius.borderRadiusSmall} 0 0`};
  overflow: auto;
`;
const StyledCustomTableInner = styled.div`
  max-height: ${(props) => props.maxHeight};
  overflow: auto !important;
  ${({ scrollWidth }) => scrollWidth
    && `
    min-width: ${scrollWidth}px;
  `};
`;

const StyledCustomTableWrapper = styled.div`
  overflow-x: auto;
`;
const StyledCustomTable = styled.div`
  display: table;
  table-layout: fixed;
  width: 100%;
  border-collapse: collapse;
  font-size: 1rem;
  ${({ scrollWidth }) => scrollWidth
    && `
     min-width: calc( ${scrollWidth}px - 6px);
  `};
`;
const StyledCustomTableThead = styled.div`
  display: table-header-group;
  height: 3.43rem;

  ${media.sm`
      ${({ mobileLayoutVertical }) => mobileLayoutVertical
        && `
        display: none;   
     `}
  `}
`;
const StyledCustomTableTheadCell = styled.div`
  position: relative;
  display: table-cell;
  border-collapse: separate;
  border-spacing: 0;
  padding: 10px 16px 8px;
  background-color: ${(props) => props.theme.colors.tableHeadColor};
  text-align: justify;
  vertical-align: middle;
  width: ${(props) => (props.width ? props.width : 'auto')};
  text-align: ${(props) => (props.align ? props.align : 'left')};
  color: ${(props) => props.theme.colors.primaryText};
  ${truncate()}

  ${({ fixed }) => fixed
    && `
    position: sticky;
    ${fixed}: 0;
    z-index: 1;
  `};

  &:not(:last-child):before {
    position: absolute;
    top: 50%;
    right: 0;
    width: 1px;
    height: 1.6em;
    background-color: ${(props) => props.theme.colors.primaryBorder};
    transform: translateY(-50%);
    transition: background-color 0.3s;
    content: '';
  }

  &:first-child {
    border-top-left-radius: 8px;
  }
  &:last-child {
    border-top-right-radius: 8px;
  }
`;
const StyledCustomColgroup = styled.div`
  display: table-column-group;
`;
const StyledCustomCol = styled.div`
  display: table-column;
  width: ${(props) => props.colWidth};
`;
const StyledCustomTableTbody = styled.div`
  display: table-row-group;
`;
const StyledCustomTableRow = styled.div`
  display: table-row;
  height: 3.43rem;
  width: 100%;
  border-bottom: 1px solid ${(props) => props.theme.colors.secondaryBorder};

  ${media.sm`
    ${({ mobileLayoutVertical }) => mobileLayoutVertical
      && `
      display: flex;
      flex-direction: column;  
      height: auto;
    `}
  `};

  &:nth-child(even) {
    background-color: ${(props) => props.theme.colors.tableTrEvenColor};
  }
  &.odd {
    background-color: transparent;
  }

  ${(props) => props.fakeFooter
    && `
    &:nth-child(even):not(.odd) {
      background-color: transparent;
    }
    border: none;
  `}
`;
const StyledCustomTableRowCell = styled.div`
  display: table-cell;
  padding: 8px 16px;
  width: ${(props) => (props.width ? props.width : 'auto')};
  color: ${(props) => props.theme.colors.primaryText};
  vertical-align: ${(props) => (props.vAlign ? props.vAlign : 'unset')};
  text-align: ${(props) => (props.align ? props.align : 'left')};
  background-color: ${(props) => (props.hasBackground ? props.theme.colors.tableHeadColor : 'transparent')};
  box-shadow: ${(props) => (props.hasBackground ? `inset 0 -1px 0 ${props.theme.colors.secondaryBg}` : 'none')};

  ${({ mobileLayoutVertical }) => mobileLayoutVertical
    && `
    &:before {
      content: attr(data-title);
      display: none;
      min-width: 6rem;
    }
  `};

  &:before {
    content: attr(data-title);
    display: none;
    min-width: 6rem;
    ${media.sm`
      ${({ mobileLayoutVertical }) => mobileLayoutVertical
        && `
        display: block;
        justify-content: space-between;
        column-gap: 1.71rem; 
      `}
    `}
  }
  ${media.sm`
    ${({ mobileLayoutVertical }) => mobileLayoutVertical
      && `
      display: flex;
      justify-content: space-between;
      column-gap: 1.71rem; 
    `}
  `}
  & .ant-form-item {
    ${media.xs`
      ${({ mobileLayoutVertical }) => mobileLayoutVertical
        && `
        width: 100%;
      `}
    `}
  }

  ${({ fixed }) => fixed
    && `
    position: sticky;
    ${fixed}: 0;
    z-index: 1;
  `};

  & > div {
    justify-content: ${(props) => (props.align ? props.align : 'center')};
    ${media.sm`
       ${({ mobileLayoutVertical }) => mobileLayoutVertical
         && `
          width: 100%;
          justify-content: flex-end;
       `}
    `}
  }
  & > ${StyledSvgIconHover} {
    ${media.sm`
      ${({ mobileLayoutVertical }) => mobileLayoutVertical
        && `
        display: flex;
        justify-content: flex-end;
        width: auto;
      `}
    `}
  }

  .ant-form-item-control-input-content {
    .ant-input-number-disabled {
      opacity: 0.5;
    }
    .ant-input-number-input {
      background-color: ${(props) => props.theme.colors.selectBg};
    }
    textarea.ant-input {
      display: block;
      padding: 0.57rem 1.14rem;
      height: 52px !important;
    }
  }
`;
const StyledAddNewRow = styled.p`
  display: inline-block;
  color: ${(props) => (props.isDisabled ? props.theme.colors.primaryBorder : props.theme.colors.blue)};
  font-weight: ${(props) => props.theme.typography.fontWeightMedium};
  font-size: 1rem;
  cursor: ${(props) => (props.isDisabled ? 'not-allowed' : 'pointer')};
  margin: 1.14rem 0 1.14rem 0.6rem;
`;

export {
  StyledCustomTableContainer,
  StyledCustomTableInner,
  StyledCustomTable,
  StyledCustomTableThead,
  StyledCustomTableTheadCell,
  StyledCustomColgroup,
  StyledCustomCol,
  StyledCustomTableTbody,
  StyledCustomTableRow,
  StyledCustomTableRowCell,
  StyledAddNewRow,
  StyledCustomTableWrapper,
};
