const FileManagerActionType = {
  GET_DIRECTORIES_AND_FILES: 'GET_DIRECTORIES_AND_FILES',
  CHANGE_DIRECTORIES_AND_FILES_LOADING_STATE: 'CHANGE_DIRECTORIES_AND_FILES_LOADING_STATE',
  CHANGE_ALL_DIRECTORIES_LOADING_STATE: 'CHANGE_ALL_DIRECTORIES_LOADING_STATE',
  SAVE_DIRECTORIES_AND_FILES: 'SAVE_DIRECTORIES_AND_FILES',
  SAVE_BASE_DIRECTORIES_AND_FILES: 'SAVE_BASE_DIRECTORIES_AND_FILES',

  GET_FILE_TYPE_GROUPS: 'GET_FILE_TYPE_GROUPS',
  GET_FILE_TYPE_GROUPS_SUCCESS: 'GET_FILE_TYPE_GROUPS_SUCCESS',

  RENAME_PUBLIC_FILE: 'RENAME_PUBLIC_FILE',
  RENAME_PUBLIC_DIRECTORY: 'RENAME_PUBLIC_DIRECTORY',

  DELETE_PUBLIC_FILES: 'DELETE_PUBLIC_FILES',
  DELETE_PUBLIC_DIRECTORY: 'DELETE_PUBLIC_DIRECTORY',

  CHANGE_FILE_MANAGER_BRAND_ID: 'CHANGE_FILE_MANAGER_BRAND_ID',

  CHANGE_FILE_MANAGER_MODAL_VISIBILITY: 'CHANGE_FILE_MANAGER_MODAL_VISIBILITY',
  CHANGE_FILE_MANAGER_POPOVER_VISIBILITY: 'CHANGE_FILE_MANAGER_POPOVER_VISIBILITY',

  UPLOAD_FILE_INTO_DIRECTORIES: 'UPLOAD_FILE_INTO_DIRECTORIES',

  CHANGE_FILE_MANAGER_LOADING_STATE: 'CHANGE_FILE_MANAGER_LOADING_STATE',

  RESET_FILE_MANAGER_STATE: 'RESET_FILE_MANAGER_STATE',

  CREATE_DIRECTORY: 'CREATE_DIRECTORY',

  CHANGE_FILE_MANAGER_ENDED_STATUS: 'CHANGE_FILE_MANAGER_ENDED_STATUS',

  GET_DIRECTORIES_HIERARCHY: 'GET_DIRECTORIES_HIERARCHY',
  GET_DIRECTORIES_HIERARCHY_SUCCESS: 'GET_DIRECTORIES_HIERARCHY_SUCCESS',
  RESET_DIRECTORIES_HIERARCHY: 'RESET_DIRECTORIES_HIERARCHY',

  MOVE_PUBLIC_FILES: 'MOVE_PUBLIC_FILES',

  GET_FILES_BY_PATHS: 'GET_FILES_BY_PATHS',
  SAVE_FILES_BY_PATHS: 'SAVE_FILES_BY_PATHS',
  RESET_FILES_BY_PATHS: 'RESET_FILES_BY_PATHS',

  SAVE_UPLOADED_FILE: 'SAVE_UPLOADED_FILE',
  RESET_UPLOADED_FILE: 'RESET_UPLOADED_FILE',
};

export default FileManagerActionType;
