import styled from 'styled-components';
import { Steps, Form } from 'antd';
import { StyledTabWrapper } from 'components/styledComponents/tabs/Tabs.styled';
import { StyledBackgroundContent, StyledTruncate } from 'Global.styled';
import { StyledButtonBlueUnbordered } from 'components/styledComponents/button/Button.styled';
import { StyledAntCard, StyledCardIcon, StyledConditionTitle } from 'components/styledComponents/card/Card.styled';
import { truncate, handleCardsTypeColor, media } from 'utils/styledHelpers';

const StyledStepsContentWrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-grow: 1;
`;

const StyledAutomationWrapper = styled(StyledTabWrapper)`
  padding: ${(props) => (props.isPreview ? '0 2.85rem 4.1rem' : '2.85rem 1.85rem 0 ')};
  background: transparent;

  ${media.sm`
    padding-left:  1.4rem;
    padding-right:  1.4rem;
  `}
`;

// trigger step
const StyledTriggerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
`;

// name step
const StyledSettingsWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  > form {
    flex-grow: 1;
  }
`;

const StyledStepsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  > div {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  .ant-input-search {
    margin-bottom: 1.14rem;
  }
`;
const StyledCardDetails = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: ${(props) => (props.isPreview ? '0.85rem' : '0')};
  color: ${(props) => props.theme.colors.primaryText};
  > strong {
    height: auto;
  }
  width: 100%;
`;
const StyledDetailsWrapper = styled.div`
  &:last-child {
    padding-bottom: 0;
  }
  border-top: 1px solid ${(props) => props.theme.colors.primaryBorder}50;
  padding: 1.14rem 0;
  width: 100%;
`;
// email preview
const StyledEmailItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 0.57rem;
  margin-bottom: 0.57rem;
  color: ${(props) => props.theme.colors.primaryText};

  div {
    ${truncate()}
  }
`;
const StyledEmailOpenLink = styled.span`
  color: ${(props) => props.theme.colors.blue};
  font-weight: ${(props) => props.theme.typography.fontWeightMedium};
  cursor: pointer;
`;
const StyledEmailModalDetails = styled.div`
  > div {
    :not(:last-child) {
      border-bottom: 1px solid ${(props) => props.theme.colors.primaryBorder}99;
    }
    &:first-child {
      padding-top: 0;
    }
    padding: 1.14rem 0;
  }
`;
const StyledEmailModalDetailsTitle = styled.p`
  opacity: 0.6;
  margin-bottom: 0.3rem;
`;
// preview step
const StyledRangeDetails = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;
const StyledCurrencyAmountWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 0;
  width: 100%;
`;
const StyledOperationSeperateWrapper = styled.div`
  margin-top: 1.14rem;
`;
const StyledItemsMargin = styled.span`
  margin-bottom: 0.57rem;
`;

// configure step
const StyledConfigureSteps = styled(Steps)`
  border-bottom: 1px solid ${(props) => props.theme.colors.secondaryBorder};
  padding: 0.7rem 0.3rem 0;
  margin-bottom: 1.14rem;

  .ant-steps-item {
    &-disabled {
      cursor: not-allowed;
      .ant-steps-item-container {
        pointer-events: none;
        opacity: 0.5;
      }
    }

    cursor: pointer;
    padding-bottom: 0.7rem;
    &:last-child {
      padding-bottom: 0;
    }
    @media (max-width: 1200px) {
      padding-bottom: 0;
    }

    .ant-steps-item-container {
      .ant-steps-item-icon {
        margin-right: 0.7rem;
      }
      .ant-steps-item-tail {
        padding-bottom: 0;
        &:after {
          height: 100% !important;
        }
      }
      .ant-steps-item-title {
        line-height: 2.28rem;
        padding-right: 0;
        font-size: 1rem;
        font-weight: ${(props) => props.theme.typography.fontWeightMedium};
        display: block;
        &:hover {
          .ant-steps-item-icon {
            div {
              box-shadow: ${(props) => props.theme.colors[handleCardsTypeColor(props.type)]};
            }
          }
          > div {
            > div {
              color: ${(props) => props.theme.colors[handleCardsTypeColor(props.type)]};
              text-decoration: underline;
            }
            div {
              opacity: 1;
            }
          }
        }
      }
    }
  }
`;

const StyledStepsTitle = styled.div`
  font-weight: ${(props) => props.theme.typography.fontWeightMedium};
  display: flex;
  align-items: center;
  padding: 0 0 0 0.57rem;
  border-radius: ${(props) => props.theme.customBorderRadius.borderRadiusSmall};
  color: ${(props) => props.theme.colors.primaryText};
`;
const StyledCloseIcon = styled.div`
  padding: 0.28rem 0 0.28rem 1.14rem;
  opacity: 0;
`;
const StyledStepsName = styled.div`
  color: ${(props) => props.isSelected && props.theme.colors[handleCardsTypeColor(props.type)]};
  white-space: nowrap;
  overflow: hidden;
`;
const StyledStepCircle = styled.div`
  height: 2.28rem;
  width: 2.28rem;
  border-radius: ${(props) => props.theme.customBorderRadius.borderRadiusCircle};
  background: ${(props) => props.theme.colors[handleCardsTypeColor(props.type)]};
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 0 6px ${(props) => (props.isSelected ? props.theme.colors[handleCardsTypeColor(props.type)] : 'transparent')};
  svg g rect {
    fill: transparent;
  }
`;

const StyledAddConfigure = styled.div`
  display: inline-flex;
  align-items: center;
  padding-left: 0.3rem;
  cursor: pointer;
`;
const StyledStepDetailsWrapper = styled.div`
  border-bottom: 1px solid ${(props) => props.theme.colors.secondaryBorder};
  padding-bottom: 1.14rem;
`;
const StyledConfigureTitle = styled(StyledConditionTitle)`
  margin: 0 0 0 1rem;
  color: ${(props) => (props.isActive ? props.theme.colors.hover : props.theme.colors.primaryText)};
`;

const StyledCurrencyPopover = styled.div`
  padding: 0.65rem 0.86rem 1.14rem 1.42rem;
  min-width: 17.5rem;
  > div:last-child {
    div {
      border: none;
      padding-bottom: 0;
    }
  }
  & > div:not(:last-child) {
    margin-bottom: 0.85rem;
    padding-bottom: 0.85rem;
    border-bottom: 1px solid ${(props) => props.theme.colors.secondaryBorder};
  }
`;

const StyledItemDescription = styled.div`
  margin: ${(props) => (props.isDetails ? '0.57rem 0 0 3.2rem' : '0.6rem 0 0')};
  background: ${(props) => props.theme.colors.primaryBg};
  color: ${(props) => props.theme.colors.primaryText};
  border: 1px solid transparent;
  cursor: default;
  border-radius: ${(props) => props.theme.customBorderRadius.borderRadiusSmall};
  padding: 1.14rem;
  transition: all 0.4s ease-out;
  svg path {
    fill: ${(props) => props.theme.colors.secondaryColor};
  }
  > div:not(:last-of-type) {
    margin-bottom: 0.57rem;
  }
`;

const StyledItemRange = styled.div`
  color: ${(props) => props.theme.colors.primaryText};
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: 0.57rem;
  span {
    margin-right: 0.3rem;
  }
`;
const StyledNumericDescription = styled.div`
  width: 100%;
  & > div {
    margin-bottom: 1.08rem;
    font-size: 1rem;
    &:last-child {
      margin-bottom: 0.2rem;
    }
    span {
      font-weight: ${(props) => props.theme.typography.fontWeightRegular};
    }
  }
`;
const StyledItemRangeGray = styled.span`
  display: flex;
  align-items: center;
  gap: 4px;
  color: ${(props) => props.theme.colors.secondaryColor};
  font-size: 0.85rem;
`;
const StyledItemRangeTitle = styled.span`
  font-weight: ${(props) => props.theme.typography.fontWeightMedium};
`;
const StyledItemName = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: ${(props) => props.theme.typography.fontWeightMedium};
  font-size: 1rem;
  width: 100%;
  span {
    font-weight: ${(props) => props.theme.typography.fontWeightMedium};
  }
  ${truncate()};
`;
const StyledCurrencyDetailsWrapper = styled.div`
  display: ${(props) => (props.isBetween ? 'block' : 'flex')};
  align-items: center;
  justify-content: space-between;
  > div {
    text-align: right;
    margin-right: 1.14rem;
    &:last-child {
      margin-right: 0;
    }
  }
  span {
    margin: ${(props) => (props.isBetween ? '0' : '0 1.14rem')};
    &:last-child {
      margin-right: 0;
    }
  }
`;
const StyledItemDescriptionWrapper = styled(StyledItemDescription)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  line-height: 1.4rem;
  font-size: 1rem;
  cursor: default;
  flex-wrap: ${(props) => (props.isBetween ? 'wrap' : '')};
  @media (max-width: 1366px) {
    flex-wrap: wrap;
  }
  span {
    line-height: 1.5rem;
  }
  > div {
    height: 100%;
  }
  .ant-popover-inner-content {
    padding: 0.85rem 0;
  }
`;
const StyledDescriptionWrapper = styled(StyledItemDescription)`
  flex-direction: column;
`;
const StyledCurrencyItemWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: 1.14rem;
  > div:last-child {
    flex-grow: 1;
    display: flex;
    justify-content: flex-end;
  }
`;
const StyledCurrencyDescription = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 1rem;
  > div {
    width: 100%;
  }
  flex-wrap: ${(props) => (props.isBetween ? 'wrap' : '')};
  flex-direction: ${(props) => (props.isBetween ? 'column' : 'row')};
  &:last-child span {
    flex-grow: 1;
  }
`;
const StyleBetweenData = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-top: 0.2rem;
  &:first-child div {
    margin-left: 0.85rem;
  }
`;
const StyledBetweenPopoverWrapper = styled.div`
  padding-top: 0.57rem;
`;
const StyledCurrencyAmount = styled.div`
  display: flex;
  justify-content: space-between;
  flex-grow: 1;
  &:first-child div {
    margin-left: 0.85rem;
  }
`;
const StyledOperatorValueWrapper = styled.div`
  display: ${(props) => (props.isBetween ? 'block' : 'flex')};

  > div:not(:last-child) {
    margin-bottom: 0.57rem;
  }
`;
const StyledArrowWrapper = styled.div`
  border-left: 1px solid ${(props) => props.theme.colors.secondaryBorder};
  height: 1rem;
  display: flex;
  align-items: center;
  padding-left: 0.85rem;
  margin-left: 0.85rem;
  &:hover {
    cursor: pointer;
  }
`;
const StyledAddIcon = styled.div`
  margin: 0;
  height: 2.28rem;
  width: 2.28rem;
  border-radius: ${(props) => props.theme.customBorderRadius.borderRadiusCircle};
  background: ${(props) => props.theme.colors.hover};
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 0 6px ${(props) => (props.isActive ? props.theme.colors.hover : 'none')};
`;
const StyledComponentTitle = styled.div`
  font-size: 1.14rem;
  font-weight: ${(props) => props.theme.typography.fontWeightMedium};
  padding-bottom: 1.14rem;
  margin-bottom: 4.2rem;
  border-bottom: 1px solid ${(props) => props.theme.colors.secondaryBorder};
  color: ${(props) => props.theme.colors.primaryText};
`;
const StyledCurrencyFormComponent = styled(Form)`
  padding: 0 !important;
  height: 100% !important;
`;
const StyledViewAutomation = styled(StyledBackgroundContent)`
  padding-top: ${(props) => (props.isView ? '0' : '2.29rem')};
  .ant-descriptions-item-content:not(:last-of-type) {
    max-width: 11rem;
  }
  .ant-descriptions-view {
    padding-left: 0;
  }
`;

const StyledNameAndInfo = styled.div`
  display: flex;
  align-items: center;
  h4 {
    margin: 0 0.57rem 0 0;
  }
  margin: 0.5rem 0 1.71rem;
`;
// publish buttons
const StyledPublishButtons = styled.div`
  display: flex;
  position: absolute;
  top: 2.85rem;
  right: 3.4rem;
  .ant-btn:first-child {
    &:hover {
      text-decoration: underline;
      span {
        color: ${(props) => props.theme.colors.blue};
      }
    }
  }
  ${media.xs`
     top: 0.85rem;
     right: 1.4rem;
  `}
`;
// trigger cards
const StyledCardTriggerWrapper = styled.div`
  form {
    padding: 0;
  }
`;
const StyledBackToList = styled(StyledButtonBlueUnbordered)`
  margin-right: 0.7rem;
`;
const StyledAutomationCard = styled(StyledAntCard)`
  cursor: pointer;
  .ant-card-meta {
    margin: 0;
  }

  .ant-card-body,
  .ant-card-meta {
    flex: 1;
    overflow: hidden;
  }
  &.ant-card-meta-detail .ant-card-meta-title {
    margin-bottom: 1.14rem;
  }
  &.ant-card {
    position: relative;
    height: auto;
    box-shadow: none;
    padding: ${(props) => (props.trigger ? '0.85rem ' : '0.85rem')};
    background: ${(props) => (props.selected ? `${props.theme.colors[handleCardsTypeColor(props.type)]}10` : 'none')};
    border: 1px solid ${(props) => (props.selected ? `${props.theme.colors[handleCardsTypeColor(props.type)]}` : 'transparent')};
    border-radius: ${(props) => props.theme.customBorderRadius.borderRadiusSmall};
    transition: 0.4s all ease-in-out;
    .ant-card-body {
      position: initial;
    }
    &:hover {
      box-shadow: none;
      background: ${(props) => `${props.theme.colors[handleCardsTypeColor(props.type)]}10`};
      border: ${(props) => `1px solid ${props.theme.colors[handleCardsTypeColor(props.type)]}`};
    }
  }
  .ant-card-meta-detail .ant-card-meta-title {
    margin-top: 0.3rem !important;
  }
`;

const StyledAutomationCardIcon = styled(StyledCardIcon)`
  width: 2.28rem;
  height: 2.28rem;
  background: ${(props) => props.theme.colors[handleCardsTypeColor(props.type)]};
  svg {
    path {
      fill: ${(props) => props.theme.colors.white}!important;
    }
  }
`;
const StyledAutomationCardTitle = styled.div`
  font-weight: ${(props) => props.theme.typography.fontWeightMedium};
  color: ${(props) => props.theme.colors.primaryText};
  ${truncate()}
`;
const StyledAutomationCardContent = styled.div`
  display: flex;
  height: 100%;
  &:first-child {
    flex: 1;
  }
`;
const StyledCurrencyLabel = styled.div`
  margin-bottom: 0.57rem;
  font-size: 1rem;
  color: ${(props) => props.theme.colors.primaryText};
`;
const StyledPeriodDescription = styled.div`
  width: 100%;
`;
const StyledPeriodDescriptionItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 0.65rem 0;
  border-bottom: 1px solid ${(props) => props.theme.colors.secondaryBorder};
  ${truncate()}
  &:first-child {
    padding-top: 0.08rem;
  }
  &:last-child {
    border-bottom: none;
    padding-bottom: 0.08rem;
  }
  & > span {
    ${truncate()}
  }
`;
const StyledTruncateSmall = styled(StyledTruncate)`
  max-width: 8rem;
  @media (max-width: 1366px) {
    max-width: 5rem;
  }
`;
const StyledStepTitleTruncate = styled.div`
  max-width: 10rem;
  font-weight: ${(props) => props.theme.typography.fontWeightMedium};
  ${truncate()};
  @media (min-width: 1600px) {
    max-width: 14rem;
  }
  &:hover {
    cursor: pointer;
  }
`;

const StyledTagComponentHeading = styled.div`
  font-size: 1.14rem;
  margin-bottom: 1rem;
  font-weight: ${(props) => props.theme.typography.fontWeightMedium};
  color: ${(props) => props.theme.colors.primaryText};
  display: flex;
  align-items: center;
`;

const StyledTagConditionResult = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  > div {
    ${truncate()}
    &:last-child {
      flex: 0 0 auto;
      margin-left: 0.72rem;
      max-width: 60%;
    }

    span {
      max-width: 100%;
    }
  }
`;
const StyledTagResultLine = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 0.72rem;
  align-items: center;
  margin-bottom: 1.14rem !important;
  color: ${(props) => props.theme.colors.primaryText};

  &:not(:first-child) {
    margin-top: 1.14rem;
  }

  &:last-child {
    margin-bottom: 0 !important;
  }

  div {
    ${truncate()}
  }
`;

const StyledTagCategorySelectWrapper = styled.div`
  .ant-select-selection-item svg {
    display: none;
  }
`;
const StyledTagCategoryTypeIcon = styled.span`
  svg path {
    fill: ${(props) => props.color};
  }
`;
const StyledTagWithReason = styled.div`
  display: flex;
  align-items: center;
  gap: 0.4rem;
`;
export {
  StyledStepsContentWrapper,
  StyledAutomationWrapper,
  StyledTriggerWrapper,
  StyledSettingsWrapper,
  StyledItemsMargin,
  StyledStepsWrapper,
  StyledCardDetails,
  StyledDetailsWrapper,
  StyledEmailItem,
  StyledEmailOpenLink,
  StyledEmailModalDetails,
  StyledEmailModalDetailsTitle,
  StyledRangeDetails,
  StyledCurrencyAmountWrapper,
  StyledOperationSeperateWrapper,
  StyledConfigureSteps,
  StyledStepCircle,
  StyledStepsTitle,
  StyledStepsName,
  StyledCloseIcon,
  StyledAddConfigure,
  StyledAddIcon,
  StyledItemDescription,
  StyledItemRange,
  StyledNumericDescription,
  StyledItemRangeGray,
  StyledItemRangeTitle,
  StyledItemName,
  StyledItemDescriptionWrapper,
  StyleBetweenData,
  StyledBetweenPopoverWrapper,
  StyledCurrencyDescription,
  StyledConfigureTitle,
  StyledComponentTitle,
  StyledCurrencyFormComponent,
  StyledViewAutomation,
  StyledNameAndInfo,
  StyledPublishButtons,
  StyledBackToList,
  StyledCardTriggerWrapper,
  StyledAutomationCard,
  StyledAutomationCardIcon,
  StyledAutomationCardTitle,
  StyledAutomationCardContent,
  StyledCurrencyLabel,
  StyledCurrencyDetailsWrapper,
  StyledStepDetailsWrapper,
  StyledCurrencyPopover,
  StyledArrowWrapper,
  StyledOperatorValueWrapper,
  StyledPeriodDescription,
  StyledPeriodDescriptionItem,
  StyledTruncateSmall,
  StyledStepTitleTruncate,
  StyledDescriptionWrapper,
  StyledCurrencyItemWrapper,
  StyledCurrencyAmount,
  StyledTagComponentHeading,
  StyledTagConditionResult,
  StyledTagResultLine,
  StyledTagCategorySelectWrapper,
  StyledTagCategoryTypeIcon,
  StyledTagWithReason,
};
