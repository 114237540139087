import QuickSearchActionType from './quickSearchActionType';

export const getPlayerSearchResult = (searchKey, params) => ({
  type: QuickSearchActionType.GET_PLAYER_SEARCH_RESULT,
  payload: { searchKey, params },
});

export const getPaymentSearchResult = (searchKey, params) => ({
  type: QuickSearchActionType.GET_PAYMENT_SEARCH_RESULT,
  payload: { searchKey, params },
});

export const getBetSearchResult = (searchKey, params) => ({
  type: QuickSearchActionType.GET_BET_SEARCH_RESULT,
  payload: { searchKey, params },
});

export const saveSearchResult = (data) => ({
  type: QuickSearchActionType.SAVE_SEARCH_RESULT,
  payload: { data },
});

export const resetSearchResult = () => ({
  type: QuickSearchActionType.RESET_SEARCH_RESULT,
});
