import createSagaMiddleware from 'redux-saga';
import { applyMiddleware, createStore } from 'redux';
import { composeWithDevToolsDevelopmentOnly } from '@redux-devtools/extension';
import rootReducer from 'reducers';
import rootSaga from 'sagas';

export default function configureStore(initialState) {
  const sagaMiddleware = createSagaMiddleware();
  const middleware = composeWithDevToolsDevelopmentOnly(applyMiddleware(sagaMiddleware));

  const store = createStore(rootReducer, initialState, middleware);
  sagaMiddleware.run(rootSaga);

  return store;
}
