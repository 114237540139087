import { Dropdown, Menu as AntdMenu, Tooltip } from 'antd';
import { useEffect, useMemo, useState } from 'react';
import { NavigationService } from 'services';
import { useTranslation } from 'react-i18next';
import ChangePassword from 'containers/auth/ChangePassword';
import ReleaseNoteModal from 'containers/common/ReleaseNoteModal';
import { IconSvg } from 'components/common';
import { ConfirmModal } from 'components/common/modal';
import PropTypes from 'prop-types';
import { withTheme } from 'styled-components';
import _ from 'lodash';
import { StyledAccountWrapper, StyledListItemHeader } from 'components/layout/Layout.styled';
import { useLogout } from 'hooks';

const Menu = (props) => {
  const {
    user,
    isSearch,
    theme: { colors },
    resetLatestReleaseNote,
    latestReleaseNote,
  } = props;

  const [dropDownVisible, setDropDownVisible] = useState(false);
  const [action, setAction] = useState({
    type: '',
    visible: false,
  });
  const { t } = useTranslation();
  const { logout } = useLogout();

  const menuItems = useMemo(
    () => [
      user && {
        key: 'name',
        label: `${user.firstName} ${user.lastName}`,
      },
      {
        key: 'account',
        label: (
          <div onClick={() => NavigationService(isSearch ? '/account' : '/noAccessAccount')}>
            <IconSvg icon="AccountIcon" width="1.2rem" height="1.2rem" />
            {t('myAccount')}
          </div>
        ),
      },
      {
        key: 'changePassword',
        label: (
          <div>
            <IconSvg icon="UpdateAccount2FAIcon" width="1.2rem" height="1.2rem" />
            {t('changePass')}
          </div>
        ),
      },
      {
        key: 'releaseNote',
        label: (
          <div>
            <IconSvg icon="SeoNotificationIcon" width="1.2rem" height="1.2rem" />
            {t('systemSetting:releaseNotes')}
          </div>
        ),
      },
      {
        key: 'logout',
        label: (
          <div>
            <IconSvg icon="LogoutIcon" />
            {t('logOut')}
          </div>
        ),
      },
    ],
    [isSearch, t, user],
  );

  const onClose = () => setAction({
    type: '',
    visible: false,
  });

  const handleCloseReleaseNote = () => {
    resetLatestReleaseNote();
    onClose();
  };

  const actionTypes = {
    logout: <ConfirmModal visible={action.visible} changeModalState={onClose} text={t('confirm:logout')} handleConfirm={logout} />,
    changePassword: <ChangePassword visible={action.visible} changeModalState={onClose} />,
    releaseNote: <ReleaseNoteModal visible={action.visible} closeModal={handleCloseReleaseNote} />,
  };

  const handleMenuClick = ({ key }) => {
    if (Object.keys(actionTypes).includes(key)) {
      setAction({
        type: key,
        visible: true,
      });
    }
    setDropDownVisible(false);
  };

  const menu = () => (
    <StyledAccountWrapper>
      <AntdMenu onClick={handleMenuClick} items={menuItems} />
    </StyledAccountWrapper>
  );

  const Modal = actionTypes[action.type];

  useEffect(() => {
    if (!_.isEmpty(latestReleaseNote) && !latestReleaseNote?.isSeen) {
      setAction({
        type: 'releaseNote',
        visible: true,
      });
    }
  }, [latestReleaseNote]);

  return (
    <>
      {Modal}
      <Dropdown open={dropDownVisible} onOpenChange={setDropDownVisible} dropdownRender={menu} placement="bottom" trigger="click">
        <StyledListItemHeader>
          <Tooltip placement="bottom" title={t('account')}>
            <div>
              <IconSvg icon="AccountIcon" width="1rem" height="1rem" color={colors.white} />
            </div>
          </Tooltip>
        </StyledListItemHeader>
      </Dropdown>
    </>
  );
};
Menu.propTypes = {
  theme: PropTypes.object.isRequired,
  user: PropTypes.object,
  isSearch: PropTypes.bool,
  resetLatestReleaseNote: PropTypes.func.isRequired,
  latestReleaseNote: PropTypes.object,
};

Menu.defaultProps = {
  user: null,
  isSearch: true,
  latestReleaseNote: {},
};

export default withTheme(Menu);
