import LegalAgreementActionType from 'actions/legalAgreement/legalAgreementActionType';

const initialState = {
  versions: [],
  policies: [],
  notPendingPolicies: [],
  versionsByPlayer: {},
};

const legalAgreementReducer = (state = initialState, { type, payload }) => {
  switch (type) {
  case LegalAgreementActionType.SAVE_BRAND_LEGAL_AGREEMENTS_VERSIONS:
    return {
      ...state,
      versions: payload.data,
    };
  case LegalAgreementActionType.RESET_BRAND_LEGAL_AGREEMENTS_VERSIONS:
    return {
      ...state,
      versions: {},
    };
  case LegalAgreementActionType.SAVE_BRAND_LEGAL_AGREEMENTS_POLICIES:
    return {
      ...state,
      policies: payload.data,
    };
  case LegalAgreementActionType.RESET_BRAND_LEGAL_AGREEMENTS_POLICIES:
    return {
      ...state,
      policies: [],
    };
  case LegalAgreementActionType.SAVE_BRAND_LEGAL_AGREEMENTS_NOT_PENDING_POLICIES:
    return {
      ...state,
      notPendingPolicies: payload.data,
    };
  case LegalAgreementActionType.RESET_BRAND_LEGAL_AGREEMENTS_NOT_PENDING_POLICIES:
    return {
      ...state,
      notPendingPolicies: [],
    };
  case LegalAgreementActionType.SAVE_PLAYER_LEGAL_AGREEMENTS_VERSIONS:
    return {
      ...state,
      versionsByPlayer: payload.data,
    };
  case LegalAgreementActionType.RESET_PLAYER_LEGAL_AGREEMENTS_VERSIONS:
    return {
      ...state,
      versionsByPlayer: {},
    };
  default:
    return state;
  }
};

export default legalAgreementReducer;
