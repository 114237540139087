import PromotionActionType from './promotionActionType';

export const getPromotions = (params) => ({
  type: PromotionActionType.GET_PROMOTIONS,
  payload: { params },
});

export const savePromotions = (data) => ({
  type: PromotionActionType.SAVE_PROMOTIONS,
  payload: { data },
});

export const resetPromotions = () => ({
  type: PromotionActionType.RESET_PROMOTIONS,
});

export const getPromotion = (id, isLoading = true) => ({
  type: PromotionActionType.GET_PROMOTION,
  payload: { id, isLoading },
});

export const savePromotion = (data) => ({
  type: PromotionActionType.SAVE_PROMOTION,
  payload: { data },
});

export const resetPromotion = () => ({
  type: PromotionActionType.RESET_PROMOTION,
});

export const createPromotion = (data) => ({
  type: PromotionActionType.CREATE_PROMOTION,
  payload: { data },
});

export const saveCreatedPromotionId = (id) => ({
  type: PromotionActionType.SAVE_CREATED_PROMOTION_ID,
  payload: { id },
});

export const changeGeneralConfigEndedStatus = (status) => ({
  type: PromotionActionType.CHANGE_GENERAL_CONFIG_ENDED_STATUS,
  payload: { status },
});

export const editPromotion = (id, data) => ({
  type: PromotionActionType.EDIT_PROMOTION,
  payload: { id, data },
});

export const getSpinDistributions = (promotionId) => ({
  type: PromotionActionType.GET_SPIN_DISTRIBUTIONS,
  payload: { promotionId },
});

export const saveSpinDistributions = (data) => ({
  type: PromotionActionType.SAVE_SPIN_DISTRIBUTIONS,
  payload: { data },
});

export const resetSpinDistributions = () => ({
  type: PromotionActionType.RESET_SPIN_DISTRIBUTIONS,
});

export const createSpinDistribution = (promotionId, data, name) => ({
  type: PromotionActionType.CREATE_SPIN_DISTRIBUTION,
  payload: { promotionId, data, name },
});

export const editSpinDistribution = (promotionId, id, data, name) => ({
  type: PromotionActionType.EDIT_SPIN_DISTRIBUTION,
  payload: {
    promotionId,
    id,
    data,
    name,
  },
});

export const deleteSpinDistribution = (promotionId, id, name) => ({
  type: PromotionActionType.DELETE_SPIN_DISTRIBUTION,
  payload: {
    promotionId,
    id,
    name,
  },
});

export const changeSpinDistributionEndedStatus = (status) => ({
  type: PromotionActionType.CHANGE_SPIN_DISTRIBUTION_ENDED_STATUS,
  payload: { status },
});

export const getSpinDistribution = (promotionId, id) => ({
  type: PromotionActionType.GET_SPIN_DISTRIBUTION,
  payload: { promotionId, id },
});

export const saveSpinDistribution = (data) => ({
  type: PromotionActionType.SAVE_SPIN_DISTRIBUTION,
  payload: { data },
});

export const resetSpinDistribution = () => ({
  type: PromotionActionType.RESET_SPIN_DISTRIBUTION,
});

export const getMachines = (params) => ({
  type: PromotionActionType.GET_MACHINES,
  payload: { params },
});

export const saveMachines = (data) => ({
  type: PromotionActionType.SAVE_MACHINES,
  payload: { data },
});

export const resetMachines = () => ({
  type: PromotionActionType.RESET_MACHINES,
});

export const createMachine = (data) => ({
  type: PromotionActionType.CREATE_MACHINE,
  payload: { data },
});

export const editMachine = (id, data) => ({
  type: PromotionActionType.EDIT_MACHINE,
  payload: {
    id,
    data,
  },
});

export const deleteMachine = (id, name) => ({
  type: PromotionActionType.DELETE_MACHINE,
  payload: {
    id,
    name,
  },
});

export const changeMachineEndedStatus = (status) => ({
  type: PromotionActionType.CHANGE_MACHINE_ENDED_STATUS,
  payload: { status },
});

export const getMachine = (id) => ({
  type: PromotionActionType.GET_MACHINE,
  payload: { id },
});

export const saveMachine = (data) => ({
  type: PromotionActionType.SAVE_MACHINE,
  payload: { data },
});

export const resetMachine = () => ({
  type: PromotionActionType.RESET_MACHINE,
});

export const getMachineDetails = (id) => ({
  type: PromotionActionType.GET_MACHINE_DETAILS,
  payload: { id },
});

export const saveMachineDetails = (data) => ({
  type: PromotionActionType.SAVE_MACHINE_DETAILS,
  payload: { data },
});

export const resetMachineDetails = () => ({
  type: PromotionActionType.RESET_MACHINE_DETAILS,
});

export const getPlayingParticipants = (id) => ({
  type: PromotionActionType.GET_PLAYING_PARTICIPANTS,
  payload: { id },
});

export const savePlayingParticipants = (data) => ({
  type: PromotionActionType.SAVE_PLAYING_PARTICIPANTS,
  payload: { data },
});

export const resetPlayingParticipants = () => ({
  type: PromotionActionType.RESET_PLAYING_PARTICIPANTS,
});

export const createPlayingParticipant = (id, data) => ({
  type: PromotionActionType.CREATE_PLAYING_PARTICIPANT,
  payload: { id, data },
});

export const editPlayingParticipant = (id, participantId, data) => ({
  type: PromotionActionType.EDIT_PLAYING_PARTICIPANT,
  payload: {
    id,
    participantId,
    data,
  },
});

export const deletePlayingParticipant = (id, data) => ({
  type: PromotionActionType.DELETE_PLAYING_PARTICIPANT,
  payload: {
    id,
    data,
  },
});

export const changePlayingParticipantEndedStatus = (status) => ({
  type: PromotionActionType.CHANGE_PLAYING_PARTICIPANT_ENDED_STATUS,
  payload: { status },
});

export const getPlayingParticipant = (id, participantId) => ({
  type: PromotionActionType.GET_PLAYING_PARTICIPANT,
  payload: { id, participantId },
});

export const savePlayingParticipant = (data) => ({
  type: PromotionActionType.SAVE_PLAYING_PARTICIPANT,
  payload: { data },
});

export const resetPlayingParticipant = () => ({
  type: PromotionActionType.RESET_PLAYING_PARTICIPANT,
});

export const getPrizes = (promotionId) => ({
  type: PromotionActionType.GET_PRIZES,
  payload: { promotionId },
});

export const savePrizes = (data) => ({
  type: PromotionActionType.SAVE_PRIZES,
  payload: { data },
});

export const resetPrizes = () => ({
  type: PromotionActionType.RESET_PRIZES,
});

export const getPrize = (id) => ({
  type: PromotionActionType.GET_PRIZE,
  payload: { id },
});

export const savePrize = (data) => ({
  type: PromotionActionType.SAVE_PRIZE,
  payload: { data },
});

export const resetPrize = () => ({
  type: PromotionActionType.RESET_PRIZE,
});

export const createPrize = (data, name) => ({
  type: PromotionActionType.CREATE_PRIZE,
  payload: { data, name },
});

export const editPrize = (id, data) => ({
  type: PromotionActionType.EDIT_PRIZE,
  payload: { id, data },
});

export const deletePrize = (id, name) => ({
  type: PromotionActionType.DELETE_PRIZE,
  payload: { id, name },
});

export const changePrizeEndedStatus = (status) => ({
  type: PromotionActionType.CHANGE_PRIZE_ENDED_STATUS,
  payload: { status },
});

export const getMachineConfigs = (params) => ({
  type: PromotionActionType.GET_MACHINE_CONFIGS,
  payload: { params },
});

export const saveMachineConfigs = (data) => ({
  type: PromotionActionType.SAVE_MACHINE_CONFIGS,
  payload: { data },
});

export const resetMachineConfigs = () => ({
  type: PromotionActionType.RESET_MACHINE_CONFIGS,
});

export const getMachineConfig = (id) => ({
  type: PromotionActionType.GET_MACHINE_CONFIG,
  payload: { id },
});

export const saveMachineConfig = (data) => ({
  type: PromotionActionType.SAVE_MACHINE_CONFIG,
  payload: { data },
});

export const resetMachineConfig = () => ({
  type: PromotionActionType.RESET_MACHINE_CONFIG,
});

export const createMachineConfig = (id, data) => ({
  type: PromotionActionType.CREATE_MACHINE_CONFIG,
  payload: { id, data },
});

export const editMachineConfig = (id, data) => ({
  type: PromotionActionType.EDIT_MACHINE_CONFIG,
  payload: { id, data },
});

export const deleteMachineConfig = (id, name) => ({
  type: PromotionActionType.DELETE_MACHINE_CONFIG,
  payload: { id, name },
});

export const changeMachineConfigEndedStatus = (status) => ({
  type: PromotionActionType.CHANGE_MACHINE_CONFIG_ENDED_STATUS,
  payload: { status },
});

export const getGroupLimit = (id) => ({
  type: PromotionActionType.GET_GROUP_LIMIT,
  payload: { id },
});

export const saveGroupLimit = (data) => ({
  type: PromotionActionType.SAVE_GROUP_LIMIT,
  payload: { data },
});

export const resetGroupLimit = () => ({
  type: PromotionActionType.RESET_GROUP_LIMIT,
});

export const createGroupLimit = (id, data) => ({
  type: PromotionActionType.CREATE_GROUP_LIMIT,
  payload: { id, data },
});

export const editGroupLimit = (id, data) => ({
  type: PromotionActionType.EDIT_GROUP_LIMIT,
  payload: { id, data },
});

export const deleteGroupLimit = (id) => ({
  type: PromotionActionType.DELETE_GROUP_LIMIT,
  payload: { id },
});

export const changeGroupLimitEndedStatus = (status) => ({
  type: PromotionActionType.CHANGE_GROUP_LIMIT_ENDED_STATUS,
  payload: { status },
});

export const publishPromotion = (id, data) => ({
  type: PromotionActionType.PUBLISH_PROMOTION,
  payload: { id, data },
});

export const changePublishEndedStatus = (status) => ({
  type: PromotionActionType.CHANGE_PUBLISH_ENDED_STATUS,
  payload: { status },
});

export const deletePromotion = (id, name) => ({
  type: PromotionActionType.DELETE_PROMOTION,
  payload: { id, name },
});

export const finishPromotion = (id, name) => ({
  type: PromotionActionType.FINISH_PROMOTION,
  payload: { id, name },
});

export const getMachineContents = (promotionId) => ({
  type: PromotionActionType.GET_MACHINE_CONTENTS,
  payload: { promotionId },
});

export const saveMachineContents = (data) => ({
  type: PromotionActionType.SAVE_MACHINE_CONTENTS,
  payload: { data },
});

export const resetMachineContents = () => ({
  type: PromotionActionType.RESET_MACHINE_CONTENTS,
});

export const getMachineContent = (params) => ({
  type: PromotionActionType.GET_MACHINE_CONTENT,
  payload: { params },
});

export const saveMachineContent = (data) => ({
  type: PromotionActionType.SAVE_MACHINE_CONTENT,
  payload: { data },
});

export const resetMachineContent = () => ({
  type: PromotionActionType.RESET_MACHINE_CONTENT,
});

export const createMachineContent = (promotionId, data) => ({
  type: PromotionActionType.CREATE_MACHINE_CONTENT,
  payload: { promotionId, data },
});

export const editMachineContent = (params, data) => ({
  type: PromotionActionType.EDIT_MACHINE_CONTENT,
  payload: { params, data },
});

export const deleteMachineContent = (params) => ({
  type: PromotionActionType.DELETE_MACHINE_CONTENT,
  payload: { params },
});

export const changeMachineContentEndedStatus = (status) => ({
  type: PromotionActionType.CHANGE_MACHINE_CONTENT_ENDED_STATUS,
  payload: { status },
});

export const changePromotionStatus = (id, params, data) => ({
  type: PromotionActionType.CHANGE_PROMOTION_STATUS,
  payload: { id, params, data },
});

export const clonePromotion = (data) => ({
  type: PromotionActionType.CLONE_PROMOTION,
  payload: { data },
});

export const getPromotionTransactions = (id, params) => ({
  type: PromotionActionType.GET_PROMOTION_TRANSACTIONS,
  payload: { id, params },
});

export const exportPromotionTransactions = (id, params) => ({
  type: PromotionActionType.EXPORT_PROMOTION_TRANSACTIONS,
  payload: { id, params },
});

export const savePromotionTransactions = (data) => ({
  type: PromotionActionType.SAVE_PROMOTION_TRANSACTIONS,
  payload: { data },
});

export const resetPromotionTransactions = () => ({
  type: PromotionActionType.RESET_PROMOTION_TRANSACTIONS,
});

export const getPromotionMachines = (id, params) => ({
  type: PromotionActionType.GET_PROMOTION_MACHINES,
  payload: { id, params },
});

export const exportPromotionMachines = (id, params) => ({
  type: PromotionActionType.EXPORT_PROMOTION_MACHINES,
  payload: { id, params },
});

export const savePromotionMachines = (data) => ({
  type: PromotionActionType.SAVE_PROMOTION_MACHINES,
  payload: { data },
});

export const resetPromotionMachines = () => ({
  type: PromotionActionType.RESET_PROMOTION_MACHINES,
});

export const getPromotionPrizes = (id, params) => ({
  type: PromotionActionType.GET_PROMOTION_PRIZES,
  payload: { id, params },
});

export const exportPromotionPrizes = (id, params) => ({
  type: PromotionActionType.EXPORT_PROMOTION_PRIZES,
  payload: { id, params },
});

export const savePromotionPrizes = (data) => ({
  type: PromotionActionType.SAVE_PROMOTION_PRIZES,
  payload: { data },
});

export const resetPromotionPrizes = () => ({
  type: PromotionActionType.RESET_PROMOTION_PRIZES,
});

export const getPromotionPlayers = (id, params) => ({
  type: PromotionActionType.GET_PROMOTION_PLAYERS,
  payload: { id, params },
});

export const exportPromotionPlayers = (id, params) => ({
  type: PromotionActionType.EXPORT_PROMOTION_PLAYERS,
  payload: { id, params },
});

export const savePromotionPlayers = (data) => ({
  type: PromotionActionType.SAVE_PROMOTION_PLAYERS,
  payload: { data },
});

export const resetPromotionPlayers = () => ({
  type: PromotionActionType.RESET_PROMOTION_PLAYERS,
});

export const getPromotionCsvPlayers = (data) => ({
  type: PromotionActionType.GET_PROMOTION_CSV_PLAYERS,
  payload: { data },
});
export const savePromotionCsvPlayers = (data) => ({
  type: PromotionActionType.SAVE_PROMOTION_CSV_PLAYERS,
  payload: { data },
});

export const resetPromotionCsvPlayers = () => ({
  type: PromotionActionType.RESET_PROMOTION_CSV_PLAYERS,
});

export const changePlayersEndedStatus = (status) => ({
  type: PromotionActionType.CHANGE_PLAYERS_ENDED_STATUS,
  payload: { status },
});

export const adjustManualSpin = (data) => ({
  type: PromotionActionType.ADJUST_MANUAL_SPIN,
  payload: { data },
});

export const getPromotionManualSpinReport = (params) => ({
  type: PromotionActionType.GET_PROMOTION_MANUAL_SPIN_REPORT,
  payload: { params },
});

export const savePromotionManualSpinReport = (data) => ({
  type: PromotionActionType.SAVE_PROMOTION_MANUAL_SPIN_REPORT,
  payload: { data },
});

export const resetPromotionManualSpinReport = () => ({
  type: PromotionActionType.RESET_PROMOTION_MANUAL_SPIN_REPORT,
});

export const getPromoSpinAdjustmentPlayers = (id, params) => ({
  type: PromotionActionType.GET_PROMOTION_SPIN_ADJUSTMENT_PLAYERS,
  payload: { id, params },
});

export const savePromoSpinAdjustmentPlayers = (data) => ({
  type: PromotionActionType.SAVE_PROMOTION_SPIN_ADJUSTMENT_PLAYERS,
  payload: { data },
});

export const resetPromoSpinAdjustmentPlayers = () => ({
  type: PromotionActionType.RESET_PROMOTION_SPIN_ADJUSTMENT_PLAYERS,
});

export const getPlayerPromotionSpins = (playerId, params) => ({
  type: PromotionActionType.GET_PLAYER_PROMOTION_SPINS,
  payload: { playerId, params },
});

export const savePlayerPromotionSpins = (data) => ({
  type: PromotionActionType.SAVE_PLAYER_PROMOTION_SPINS,
  payload: { data },
});

export const resetPlayerPromotionSpins = () => ({
  type: PromotionActionType.RESET_PLAYER_PROMOTION_SPINS,
});

export const getPlayerPromotionTransactions = (playerId, params) => ({
  type: PromotionActionType.GET_PLAYER_PROMOTION_TRANSACTIONS,
  payload: { playerId, params },
});

export const savePlayerPromotionTransactions = (data) => ({
  type: PromotionActionType.SAVE_PLAYER_PROMOTION_TRANSACTIONS,
  payload: { data },
});

export const resetPlayerPromotionTransactions = () => ({
  type: PromotionActionType.RESET_PLAYER_PROMOTION_TRANSACTIONS,
});

export const adjustPlayerManualSpin = (promotionId, data) => ({
  type: PromotionActionType.ADJUST_PLAYER_MANUAL_SPIN,
  payload: { promotionId, data },
});

export const getIsPromotionValidForPreview = (id) => ({
  type: PromotionActionType.GET_PROMOTION_PREVIEW_VALIDATION_STATE,
  payload: { id },
});

export const saveIsPromotionValidForPreview = (state) => ({
  type: PromotionActionType.SAVE_PROMOTION_PREVIEW_VALIDATION_STATE,
  payload: { state },
});

export const resetIsPromotionValidForPreview = () => ({
  type: PromotionActionType.RESET_PROMOTION_PREVIEW_VALIDATION_STATE,
});
export const getPromotionContent = (params) => ({
  type: PromotionActionType.GET_PROMOTION_CONTENT,
  payload: { params },
});

export const savePromotionContent = (data) => ({
  type: PromotionActionType.SAVE_PROMOTION_CONTENT,
  payload: { data },
});

export const resetPromotionContent = () => ({
  type: PromotionActionType.RESET_PROMOTION_CONTENT,
});

export const getPromotionContents = (promotionId) => ({
  type: PromotionActionType.GET_PROMOTION_CONTENTS,
  payload: { promotionId },
});

export const savePromotionContents = (data) => ({
  type: PromotionActionType.SAVE_PROMOTION_CONTENTS,
  payload: { data },
});

export const resetPromotionContents = () => ({
  type: PromotionActionType.RESET_PROMOTION_CONTENTS,
});

export const createPromotionContent = (data) => ({
  type: PromotionActionType.CREATE_PROMOTION_CONTENT,
  payload: { data },
});

export const editPromotionContent = (params, data) => ({
  type: PromotionActionType.EDIT_PROMOTION_CONTENT,
  payload: { params, data },
});

export const deletePromotionContent = (params) => ({
  type: PromotionActionType.DELETE_PROMOTION_CONTENT,
  payload: { params },
});

export const changePromotionContentEndedStatus = (status) => ({
  type: PromotionActionType.CHANGE_PROMOTION_CONTENT_ENDED_STATUS,
  payload: { status },
});

export const getPrivatePromotionParticipants = (promotionId, params) => ({
  type: PromotionActionType.GET_PRIVATE_PROMOTION_PARTICIPANTS,
  payload: { promotionId, params },
});

export const savePrivatePromotionParticipants = (data) => ({
  type: PromotionActionType.SAVE_PRIVATE_PROMOTION_PARTICIPANTS,
  payload: { data },
});

export const resetPrivatePromotionParticipants = () => ({
  type: PromotionActionType.RESET_PRIVATE_PROMOTION_PARTICIPANTS,
});

export const exportPrivatePromotionParticipants = (promotionId, params) => ({
  type: PromotionActionType.EXPORT_PRIVATE_PROMOTION_PARTICIPANTS,
  payload: { promotionId, params },
});

export const getPromotionPrizeMaterialStatistics = (promotionId, params) => ({
  type: PromotionActionType.GET_PROMOTION_PRIZE_MATERIAL_STATISTICS,
  payload: { promotionId, params },
});

export const savePromotionPrizeMaterialStatistics = (data) => ({
  type: PromotionActionType.SAVE_PROMOTION_PRIZE_MATERIAL_STATISTICS,
  payload: { data },
});

export const resetPromotionPrizeMaterialStatistics = () => ({
  type: PromotionActionType.RESET_PROMOTION_PRIZE_MATERIAL_STATISTICS,
});

export const exportPromotionPrizeMaterialStatistics = (promotionId, params) => ({
  type: PromotionActionType.EXPORT_PROMOTION_PRIZE_MATERIAL_STATISTICS,
  payload: { promotionId, params },
});

export const getPromotionPrizeMachineSpinStatistics = (promotionId, params) => ({
  type: PromotionActionType.GET_PROMOTION_PRIZE_MACHINE_SPIN_STATISTICS,
  payload: { promotionId, params },
});

export const savePromotionPrizeMachineSpinStatistics = (data) => ({
  type: PromotionActionType.SAVE_PROMOTION_PRIZE_MACHINE_SPIN_STATISTICS,
  payload: { data },
});

export const resetPromotionPrizeMachineSpinStatistics = () => ({
  type: PromotionActionType.RESET_PROMOTION_PRIZE_MACHINE_SPIN_STATISTICS,
});

export const exportPromotionPrizeMachineSpinStatistics = (promotionId, params) => ({
  type: PromotionActionType.EXPORT_PROMOTION_PRIZE_MACHINE_SPIN_STATISTICS,
  payload: { promotionId, params },
});

export const getPromotionPrizeBalanceMultiplierStatistics = (promotionId, params) => ({
  type: PromotionActionType.GET_PROMOTION_PRIZE_BALANCE_MULTIPLIER_STATISTICS,
  payload: { promotionId, params },
});

export const savePromotionPrizeBalanceMultiplierStatistics = (data) => ({
  type: PromotionActionType.SAVE_PROMOTION_PRIZE_BALANCE_MULTIPLIER_STATISTICS,
  payload: { data },
});

export const resetPromotionPrizeBalanceMultiplierStatistics = () => ({
  type: PromotionActionType.RESET_PROMOTION_PRIZE_BALANCE_MULTIPLIER_STATISTICS,
});

export const exportPromotionPrizeBalanceMultiplierStatistics = (promotionId, params) => ({
  type: PromotionActionType.EXPORT_PROMOTION_PRIZE_BALANCE_MULTIPLIER_STATISTICS,
  payload: { promotionId, params },
});

export const changeMaterialPrizeStatus = (promotionId, data) => ({
  type: PromotionActionType.CHANGE_MATERIAL_PRIZE_STATUS,
  payload: { promotionId, data },
});

export const getSpinDistributionContents = (promotionId) => ({
  type: PromotionActionType.GET_SPIN_DISTRIBUTION_CONTENTS,
  payload: { promotionId },
});

export const saveSpinDistributionContents = (data) => ({
  type: PromotionActionType.SAVE_SPIN_DISTRIBUTION_CONTENTS,
  payload: { data },
});

export const resetSpinDistributionContents = () => ({
  type: PromotionActionType.RESET_SPIN_DISTRIBUTION_CONTENTS,
});

export const getSpinDistributionContent = (params) => ({
  type: PromotionActionType.GET_SPIN_DISTRIBUTION_CONTENT,
  payload: { params },
});

export const saveSpinDistributionContent = (data) => ({
  type: PromotionActionType.SAVE_SPIN_DISTRIBUTION_CONTENT,
  payload: { data },
});

export const resetSpinDistributionContent = () => ({
  type: PromotionActionType.RESET_SPIN_DISTRIBUTION_CONTENT,
});

export const createSpinDistributionContent = (data) => ({
  type: PromotionActionType.CREATE_SPIN_DISTRIBUTION_CONTENT,
  payload: { data },
});

export const deleteSpinDistributionContent = (params) => ({
  type: PromotionActionType.DELETE_SPIN_DISTRIBUTION_CONTENT,
  payload: { params },
});

export const editSpinDistributionContent = (params, data) => ({
  type: PromotionActionType.EDIT_SPIN_DISTRIBUTION_CONTENT,
  payload: { params, data },
});

export const changeSpinDistributionContentEndedStatus = (status) => ({
  type: PromotionActionType.CHANGE_SPIN_DISTRIBUTION_CONTENT_ENDED_STATUS,
  payload: { status },
});

export const getPromotionSpins = (promotionId, params) => ({
  type: PromotionActionType.GET_PROMOTION_SPINS,
  payload: { promotionId, params },
});

export const savePromotionSpins = (data) => ({
  type: PromotionActionType.SAVE_PROMOTION_SPINS,
  payload: { data },
});

export const resetPromotionSpins = () => ({
  type: PromotionActionType.RESET_PROMOTION_SPINS,
});

export const exportPromotionSpins = (promotionId, params) => ({
  type: PromotionActionType.EXPORT_PROMOTION_SPINS,
  payload: { promotionId, params },
});

export const getPromotionSpinStatistics = (promotionId, params) => ({
  type: PromotionActionType.GET_PROMOTION_SPIN_STATISTICS,
  payload: { promotionId, params },
});

export const savePromotionSpinStatistics = (data) => ({
  type: PromotionActionType.SAVE_PROMOTION_SPIN_STATISTICS,
  payload: { data },
});

export const resetPromotionSpinStatistics = () => ({
  type: PromotionActionType.RESET_PROMOTION_SPIN_STATISTICS,
});

export const exportPromotionSpinStatistics = (promotionId, params) => ({
  type: PromotionActionType.EXPORT_PROMOTION_SPIN_STATISTICS,
  payload: { promotionId, params },
});

export const changeOrderMachine = (data) => ({
  type: PromotionActionType.CHANGE_ORDER_MACHINE,
  payload: { data },
});

export const getPromotionExchangeItems = (promotionId) => ({
  type: PromotionActionType.GET_PROMOTION_EXCHANGE_ITEMS,
  payload: { promotionId },
});

export const savePromotionExchangeItems = (data) => ({
  type: PromotionActionType.SAVE_PROMOTION_EXCHANGE_CENTER,
  payload: { data },
});

export const resetPromotionExchangeItems = () => ({
  type: PromotionActionType.RESET_PROMOTION_EXCHANGE_CENTER,
});

export const getPromotionExchangeItem = (promotionId, id) => ({
  type: PromotionActionType.GET_PROMOTION_EXCHANGE_ITEM,
  payload: { promotionId, id },
});

export const savePromotionExchangeItem = (data) => ({
  type: PromotionActionType.SAVE_PROMOTION_EXCHANGE_ITEM,
  payload: { data },
});

export const resetPromotionExchangeItem = () => ({
  type: PromotionActionType.RESET_PROMOTION_EXCHANGE_ITEM,
});

export const createPromotionExchangeItem = (promotionId, data) => ({
  type: PromotionActionType.CREATE_PROMOTION_EXCHANGE_ITEM,
  payload: { data, promotionId },
});

export const editPromotionExchangeItem = (promotionId, id, data) => ({
  type: PromotionActionType.EDIT_PROMOTION_EXCHANGE_ITEM,
  payload: { data, promotionId, id },
});

export const deletePromotionExchangeItem = (promotionId, id) => ({
  type: PromotionActionType.DELETE_PROMOTION_EXCHANGE_ITEM,
  payload: { promotionId, id },
});

export const changePromotionExchangeEndedStatus = (status) => ({
  type: PromotionActionType.CHANGE_PROMOTION_EXCHANGE_CENTER_ENDED_STATUS,
  payload: { status },
});

export const createPrizeContent = (data) => ({
  type: PromotionActionType.CREATE_PROMOTION_PRIZE_CONTENT,
  payload: { data },
});

export const editPrizeContent = (data) => ({
  type: PromotionActionType.EDIT_PROMOTION_PRIZE_CONTENT,
  payload: { data },
});

export const deletePrizeContent = (params) => ({
  type: PromotionActionType.DELETE_PROMOTION_PRIZE_CONTENT,
  payload: { params },
});

export const getPrizeContent = (params) => ({
  type: PromotionActionType.GET_PROMOTION_PRIZE_CONTENT,
  payload: { params },
});

export const savePrizeContent = (data) => ({
  type: PromotionActionType.SAVE_PROMOTION_PRIZE_CONTENT,
  payload: { data },
});

export const resetPrizeContent = () => ({
  type: PromotionActionType.RESET_PROMOTION_PRIZE_CONTENT,
});

export const getPrizeContents = (promotionId) => ({
  type: PromotionActionType.GET_PROMOTION_PRIZE_CONTENTS,
  payload: { promotionId },
});

export const savePrizeContents = (data) => ({
  type: PromotionActionType.SAVE_PROMOTION_PRIZE_CONTENTS,
  payload: { data },
});

export const resetPrizeContents = () => ({
  type: PromotionActionType.RESET_PROMOTION_PRIZE_CONTENTS,
});

export const changePrizeContentEndedStatus = (status) => ({
  type: PromotionActionType.CHANGE_PROMOTION_PRIZE_CONTENT_ENDED_STATUS,
  payload: { status },
});

export const getPrizeContentConnected = (promotionId) => ({
  type: PromotionActionType.GET_PROMOTION_PRIZE_CONTENT_CONNECTED,
  payload: { promotionId },
});

export const savePrizeContentConnected = (data) => ({
  type: PromotionActionType.SAVE_PROMOTION_PRIZE_CONTENT_CONNECTED,
  payload: { data },
});

export const resetPrizeContentConnected = () => ({
  type: PromotionActionType.RESET_PROMOTION_PRIZE_CONTENT_CONNECTED,
});

export const getPromotionExchanges = (id, params) => ({
  type: PromotionActionType.GET_PROMOTION_EXCHANGES,
  payload: { id, params },
});

export const exportPromotionExchanges = (id, params) => ({
  type: PromotionActionType.EXPORT_PROMOTION_EXCHANGES,
  payload: { id, params },
});

export const savePromotionExchanges = (data) => ({
  type: PromotionActionType.SAVE_PROMOTION_EXCHANGES,
  payload: { data },
});

export const resetPromotionExchanges = () => ({
  type: PromotionActionType.RESET_PROMOTION_EXCHANGES,
});

export const getPromotionChangeLogs = (promotionId, params) => ({
  type: PromotionActionType.GET_PROMOTION_CHANGE_LOGS,
  payload: { promotionId, params },
});

export const savePromotionChangeLogs = (data) => ({
  type: PromotionActionType.SAVE_PROMOTION_CHANGE_LOGS,
  payload: { data },
});

export const resetPromotionChangeLogs = () => ({
  type: PromotionActionType.RESET_PROMOTION_CHANGE_LOGS,
});

export const getPromotionChangeLog = (promotionId, logId) => ({
  type: PromotionActionType.GET_PROMOTION_CHANGE_LOG,
  payload: { promotionId, logId },
});

export const savePromotionChangeLog = (data) => ({
  type: PromotionActionType.SAVE_PROMOTION_CHANGE_LOG,
  payload: { data },
});

export const resetPromotionChangeLog = () => ({
  type: PromotionActionType.RESET_PROMOTION_CHANGE_LOG,
});

export const addPrivateParticipants = (id, data) => ({
  type: PromotionActionType.ADD_PRIVATE_PARTICIPANTS,
  payload: { id, data },
});
