import { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setAuthenticated } from 'actions/auth/authActionCreator';
import { LocalStorageService } from 'services';
import { getRandomNumber } from 'utils/utils';

const useLogout = () => {
  const dispatch = useDispatch();

  const goToLoginPage = useCallback(() => {
    const { languageId, conversionId } = LocalStorageService.get('current') || {};

    LocalStorageService.clear();

    LocalStorageService.set('current', {
      languageId,
      conversionId,
    });

    window.location.href = '/login';
  }, []);

  const logout = useCallback(() => {
    dispatch(setAuthenticated(false));
    localStorage.setItem('logout', `logout${getRandomNumber()}`);
    goToLoginPage();
  }, [dispatch, goToLoginPage]);

  useEffect(() => {
    window.addEventListener(
      'storage',
      (event) => {
        if (event.key === 'logout') {
          goToLoginPage();
        }
      },
      false,
    );
  }, [goToLoginPage]);

  return {
    logout,
  };
};

export default useLogout;
