import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { ThemeColorsMode } from 'components/layout/header';

import { selectMode } from 'selectors';
import { changeMode } from 'actions/setting/settingActionCreator';

const mapStateToProps = ({ settings }) => ({
  isNightMode: selectMode(settings),
});

const mapDispatchToProps = (dispatch) => bindActionCreators(
  {
    changeMode,
  },
  dispatch,
);

export default connect(mapStateToProps, mapDispatchToProps)(ThemeColorsMode);
