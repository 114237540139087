import qs from 'qs';
import {
  all, call, put, takeLatest,
} from '@redux-saga/core/effects';
import mime from 'mimetypes';
import SystemConversionActionType from 'actions/systemConversion/systemConversionActionType';
import {
  changeEndedStatus, changeLoadingState, setErrorMessage, setSuccessMessage,
} from 'actions/setting/settingActionCreator';
import { downloadFile } from 'utils/utils';
import { saveSystemConversions, saveSystemConversionLogs, saveSystemConversionsResource } from 'actions/systemConversion/systemConversionActionCreator';
import { AppConstants } from 'constants/index';
import NetworkService from 'services/networkService';
import { i18n } from 'services';
import { getError, getMessage } from 'utils/helpers';

function* getSystemConversions({ payload }) {
  try {
    const { params } = payload;
    const options = {
      params,
      paramsSerializer: (param) => qs.stringify(param),
    };
    const { ReportingAdmin, SystemConversion } = AppConstants.api;
    yield put(changeLoadingState(true));
    const { data } = yield call(NetworkService.makeAPIGetRequest, [ReportingAdmin, SystemConversion], options);
    yield put(saveSystemConversions(data));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getSystemConversionsResource({ payload }) {
  const { isLoading } = payload;
  try {
    const { ReportingAdmin, SystemConversion, Resource } = AppConstants.api;
    if (isLoading) {
      yield put(changeLoadingState(true));
    }
    const { data } = yield call(NetworkService.makeAPIGetRequest, [ReportingAdmin, SystemConversion, Resource]);
    yield put(saveSystemConversionsResource(data));
    if (isLoading) {
      yield put(changeLoadingState(false));
    }
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    if (isLoading) {
      yield put(changeLoadingState(false));
    }
  }
}

function* exportSystemConversions({ payload }) {
  try {
    const { params } = payload;
    const options = {
      params,
      paramsSerializer: (param) => qs.stringify(param, { allowDots: true }),
      responseType: 'blob',
    };
    yield put(changeLoadingState(true));
    const { ReportingAdmin, SystemConversion, Export } = AppConstants.api;
    const { data, headers } = yield call(NetworkService.makeAPIGetRequest, [ReportingAdmin, SystemConversion, Export], options);
    downloadFile(data, i18n.t('conversionRates'), mime.detectExtension(headers['content-type']));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}
function* importSystemConversions({ payload }) {
  try {
    const options = {
      data: payload.data,
      headers: { 'content-type': 'multipart/form-data' },
    };
    yield put(changeLoadingState(true));
    const { ReportingAdmin, SystemConversion, Import } = AppConstants.api;
    yield call(NetworkService.makeAPIPutRequest, [ReportingAdmin, SystemConversion, Import], options);
    yield put(changeLoadingState(false));
    yield put(changeEndedStatus(true));
    yield put(setSuccessMessage(getMessage(i18n.t('conversionOrConversions'), i18n.t('notification:slUpdated'))));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* editSystemConversion({ payload }) {
  try {
    const { data, id } = payload;
    const options = {
      data,
    };
    yield put(changeLoadingState(true));
    const { ReportingAdmin, SystemConversion } = AppConstants.api;
    yield call(NetworkService.makeAPIPutRequest, [ReportingAdmin, SystemConversion, id], options);
    yield put(changeLoadingState(false));
    yield put(changeEndedStatus(true));
    yield put(setSuccessMessage(getMessage(i18n.t('conversionRate'), i18n.t('notification:slUpdated'))));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getSystemConversionLogs({ payload }) {
  try {
    const { id } = payload;
    const { ReportingAdmin, SystemConversion, Log } = AppConstants.api;
    yield put(changeLoadingState(true));
    const { data } = yield call(NetworkService.makeAPIGetRequest, [ReportingAdmin, SystemConversion, id, Log]);
    yield put(saveSystemConversionLogs(data));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

export default function* systemConversionSaga() {
  yield all([
    takeLatest(SystemConversionActionType.GET_SYSTEM_CONVERSIONS, getSystemConversions),
    takeLatest(SystemConversionActionType.GET_SYSTEM_CONVERSIONS_RESOURCE, getSystemConversionsResource),
    takeLatest(SystemConversionActionType.EXPORT_SYSTEM_CONVERSIONS, exportSystemConversions),
    takeLatest(SystemConversionActionType.IMPORT_SYSTEM_CONVERSIONS, importSystemConversions),
    takeLatest(SystemConversionActionType.EDIT_SYSTEM_CONVERSION, editSystemConversion),
    takeLatest(SystemConversionActionType.GET_SYSTEM_CONVERSION_LOGS, getSystemConversionLogs),
  ]);
}
