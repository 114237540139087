import styled from 'styled-components';

const StyledStandardFeature = styled.div`
  display: flex;
  align-items: center;

  & > div > div {
    background: ${(props) => props.theme.colors.primaryBg};
    border-radius: ${(props) => props.theme.customBorderRadius.borderRadiusSmall};
    width: 2.57rem;
    height: 2.57rem;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 1.71rem;
    svg {
      g path,
      path {
        fill: ${(props) => props.theme.colors.blue};
      }
    }
  }
`;
const StyledStandardWrapper = styled.div`
  tr.child-group-feature > td:first-child > div {
    padding-left: 1.14rem;
  }
  tr:not(.ant-table-measure-row) > td {
    background: ${(props) => props.theme.colors.secondaryBg};

    & > div {
      padding: 0.7rem 0;

      > p {
        font-size: 1rem;
        white-space: pre-wrap;
      }
    }
  }

  .parent-group-feature {
    background: ${(props) => props.theme.colors.tableTrEvenColor};
    td {
      background: ${(props) => props.theme.colors.tableTrEvenColor}!important;
    }
    > td:first-child > div {
      padding: 0.7rem 0;
      > div > div {
        background: ${(props) => props.theme.colors.blue}!important;
        svg {
          g path,
          path {
            fill: ${(props) => props.theme.colors.white}!important;
          }
        }
      }
      > p {
        font-size: 1rem;
        font-weight: ${(props) => props.theme.typography.fontWeightMedium};
      }
    }
  }
`;

const StyledModalText = styled.div`
  padding: ${(props) => (props.isActivate ? '0 4.28rem' : '')};
  line-height: ${(props) => (props.isActivate ? '22px' : '')};
`;

const StyledStandardTabs = styled.div`
  overflow: scroll;
`;
const StyledStandardTabsWrapper = styled.div`
  margin: 1.14rem 0;
  .ant-input-wrapper.ant-input-group {
    width: 21.4rem;
    max-width: 100%;
  }
`;

const StyledStandardDescription = styled.div`
  min-width: 30rem;

  p:first-child {
    margin-bottom: 0.4rem;
  }
`;

export {
  StyledStandardFeature, StyledStandardWrapper, StyledModalText, StyledStandardTabs, StyledStandardTabsWrapper, StyledStandardDescription,
};
