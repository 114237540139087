import KycActionType from 'actions/kyc/kycActionType';

const initialState = {
  kyc: {},
  playerAttachments: [],
  playerAttachmentsHistory: [],
};

const kycReducer = (state = initialState, { type, payload }) => {
  switch (type) {
  case KycActionType.SAVE_KYC_REPORT:
    return {
      ...state,
      kyc: payload.data,
    };
  case KycActionType.RESET_KYC_REPORT:
    return {
      ...state,
      kyc: {},
    };
  case KycActionType.SAVE_PLAYER_ATTACHMENTS:
    return {
      ...state,
      playerAttachments: payload.data,
    };
  case KycActionType.RESET_PLAYER_ATTACHMENTS:
    return {
      ...state,
      playerAttachments: [],
    };
  case KycActionType.SAVE_PLAYER_ATTACHMENTS_HISTORY:
    return {
      ...state,
      playerAttachmentsHistory: payload.data,
    };
  case KycActionType.RESET_PLAYER_ATTACHMENTS_HISTORY:
    return {
      ...state,
      playerAttachmentsHistory: [],
    };
  default:
    return state;
  }
};

export default kycReducer;
