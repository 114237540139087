import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { selectAuthUser } from 'selectors';
import { getUser } from 'actions/user/userActionCreator';
import { AccountDetails } from 'components/auth';
import { Banner } from 'components/common';
import { StyledUserWrapper } from 'components/brand/Brand.styled';
import { StyledAccountMarginTop } from 'components/layout/Layout.styled';
import { StyledBannerWrapper, StyledBannerWrapperBg } from 'Global.styled';

const Account = (props) => {
  const { user } = props;
  const {
    firstName, lastName,
  } = user;

  return (
    <StyledAccountMarginTop>
      <StyledBannerWrapper>
        <Banner text={`${firstName} ${lastName}`} isSmallText type={0} />
        <StyledBannerWrapperBg>
          <StyledUserWrapper>
            <AccountDetails user={user} />
          </StyledUserWrapper>
        </StyledBannerWrapperBg>
      </StyledBannerWrapper>
    </StyledAccountMarginTop>
  );
};

const mapStateToProps = ({ auth }) => ({
  user: selectAuthUser(auth),
});

const mapDispatchToProps = (dispatch) => bindActionCreators(
  {
    getUserProp: getUser,
  },
  dispatch,
);

Account.propTypes = {
  user: PropTypes.object.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(Account);
