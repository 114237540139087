const PlayerPerformanceActionType = {
  GET_PLAYER_WALLET_AGGREGATION: 'GET_PLAYER_WALLET_AGGREGATION',
  SAVE_PLAYER_WALLET_AGGREGATION: 'SAVE_PLAYER_WALLET_AGGREGATION',
  RESET_PLAYER_WALLET_AGGREGATION: 'RESET_PLAYER_WALLET_AGGREGATION',

  GET_PLAYER_TRANSACTION_AGGREGATION: 'GET_PLAYER_TRANSACTION_AGGREGATION',
  SAVE_PLAYER_TRANSACTION_AGGREGATION: 'SAVE_PLAYER_TRANSACTION_AGGREGATION',
  RESET_PLAYER_TRANSACTION_AGGREGATION: 'RESET_PLAYER_TRANSACTION_AGGREGATION',

  CHANGE_WALLET_LOADING_STATE: 'CHANGE_WALLET_LOADING_STATE',
  CHANGE_TRANSACTION_LOADING_STATE: 'CHANGE_TRANSACTION_LOADING_STATE',
  CHANGE_MIXED_TRANSACTION_LOADING_STATE: 'CHANGE_MIXED_TRANSACTION_LOADING_STATE',

  GET_PLAYERS_AGGREGATIONS: 'GET_PLAYERS_AGGREGATIONS',
  SAVE_PLAYERS_AGGREGATIONS: 'SAVE_PLAYERS_AGGREGATIONS',
  RESET_PLAYERS_AGGREGATIONS: 'RESET_PLAYERS_AGGREGATIONS',
  GENERATE_PLAYERS_AGGREGATIONS_REPORT: 'GENERATE_PLAYERS_AGGREGATIONS_REPORT',

  GET_PLAYER_MIXED_TRANSACTION: 'GET_PLAYER_MIXED_TRANSACTION',
  SAVE_PLAYER_MIXED_TRANSACTION: 'SAVE_PLAYER_MIXED_TRANSACTION',
  RESET_PLAYER_MIXED_TRANSACTION: 'RESET_PLAYER_MIXED_TRANSACTION',
};

export default PlayerPerformanceActionType;
