import PropTypes from 'prop-types';
import { StyledSpinnerWrapper, StyledPreventClickOnLoading, StyledSpin } from 'components/styledComponents/spin/Spin.styled';

const Loading = (props) => {
  const { pageLoading, menuCollapse } = props;

  return (
    <StyledSpinnerWrapper className={`spin ${pageLoading ? 'spin-loading' : ''}`}>
      <StyledSpin spinning={pageLoading} collapsed={menuCollapse ? 1 : 0} />
      <StyledPreventClickOnLoading />
    </StyledSpinnerWrapper>
  );
};

Loading.propTypes = {
  pageLoading: PropTypes.bool,
  menuCollapse: PropTypes.bool,
};

Loading.defaultProps = {
  pageLoading: false,
  menuCollapse: false,
};

export default Loading;
