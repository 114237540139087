const PartnerActionType = {
  GET_PARTNERS: 'GET_PARTNERS',
  GET_PARTNERS_SUCCESS: 'GET_PARTNERS_SUCCESS',
  RESET_PARTNERS: 'RESET_PARTNERS',

  ADD_PARTNER: 'ADD_PARTNER',

  EDIT_PARTNER: 'EDIT_PARTNER',

  GET_PARTNER: 'GET_PARTNER',
  GET_PARTNER_SUCCESS: 'GET_PARTNER_SUCCESS',
  RESET_PARTNER: 'RESET_PARTNER',

  SET_CURRENT_PARTNER: 'SET_CURRENT_PARTNER',

  GET_PARTNER_ACCESSES: 'GET_PARTNER_ACCESSES',
  CHANGE_PARTNER_ACCESSES_LOADING: 'CHANGE_PARTNER_ACCESSES_LOADING',
  GET_PARTNER_ACCESSES_SUCCESS: 'GET_PARTNER_ACCESSES_SUCCESS',
  RESET_PARTNER_ACCESSES: 'RESET_PARTNER_ACCESSES',

  CHANGE_PARTNER_LOADING_STATE: 'CHANGE_PARTNER_LOADING_STATE',

  CHANGE_PARTNER_BRANDS_STATUSES: 'CHANGE_PARTNER_BRANDS_STATUSES',
};

export default PartnerActionType;
