import PushNotificationActionType from 'actions/pushNotifcation/pushNotificationActionType';

const initialState = {
  recipientsStatistics: {},
  sentNotifications: {},
  scheduledNotifications: {},
  nameExists: null,
  pushNotificationsRecipientsTotals: {},
  pushNotificationRecipients: {},
  playerPushNotifications: {},
  pushNotification: {},
};

const pushNotificationReducer = (state = initialState, { type, payload }) => {
  switch (type) {
  case PushNotificationActionType.SAVE_PUSH_NOTIFICATION_RECIPIENTS_STATISTICS:
    return {
      ...state,
      recipientsStatistics: payload.data,
    };
  case PushNotificationActionType.RESET_PUSH_NOTIFICATION_RECIPIENTS_STATISTICS:
    return {
      ...state,
      recipientsStatistics: {},
    };

  case PushNotificationActionType.SAVE_SENT_PUSH_NOTIFICATIONS:
    return {
      ...state,
      sentNotifications: payload.data,
    };
  case PushNotificationActionType.RESET_SENT_PUSH_NOTIFICATIONS:
    return {
      ...state,
      sentNotifications: {},
    };
  case PushNotificationActionType.SAVE_PUSH_NOTIFICATION_NAME_EXISTS:
    return {
      ...state,
      nameExists: payload.data,
    };
  case PushNotificationActionType.RESET_PUSH_NOTIFICATION_NAME_EXISTS:
    return {
      ...state,
      nameExists: null,
    };
  case PushNotificationActionType.SAVE_SCHEDULED_PUSH_NOTIFICATIONS:
    return {
      ...state,
      scheduledNotifications: payload.data,
    };
  case PushNotificationActionType.RESET_SCHEDULED_PUSH_NOTIFICATIONS:
    return {
      ...state,
      scheduledNotifications: {},
    };
  case PushNotificationActionType.SAVE_PUSH_NOTIFICATION_RECIPIENTS_TOTALS:
    return {
      ...state,
      pushNotificationsRecipientsTotals: payload.data,
    };
  case PushNotificationActionType.RESET_PUSH_NOTIFICATION_RECIPIENTS_TOTALS:
    return {
      ...state,
      pushNotificationsRecipientsTotals: {},
    };
  case PushNotificationActionType.SAVE_PUSH_NOTIFICATION_RECIPIENTS:
    return {
      ...state,
      pushNotificationRecipients: payload.data,
    };
  case PushNotificationActionType.RESET_PUSH_NOTIFICATION_RECIPIENTS:
    return {
      ...state,
      pushNotificationRecipients: {},
    };
  case PushNotificationActionType.SAVE_PUSH_NOTIFICATION:
    return {
      ...state,
      pushNotification: payload.data,
    };
  case PushNotificationActionType.RESET_PUSH_NOTIFICATION:
    return {
      ...state,
      pushNotification: {},
    };
  case PushNotificationActionType.SAVE_PLAYER_PUSH_NOTIFICATIONS:
    return {
      ...state,
      playerPushNotifications: payload.data,
    };
  case PushNotificationActionType.RESET_PLAYER_PUSH_NOTIFICATIONS:
    return {
      ...state,
      playerPushNotifications: {},
    };
  default:
    return state;
  }
};

export default pushNotificationReducer;
