const PushNotificationActionType = {
  CREATE_PUSH_NOTIFICATION: 'CREATE_PUSH_NOTIFICATION',

  GET_PUSH_NOTIFICATION_RECIPIENTS_STATISTICS: 'GET_PUSH_NOTIFICATION_RECIPIENTS_STATISTICS',
  SAVE_PUSH_NOTIFICATION_RECIPIENTS_STATISTICS: 'SAVE_PUSH_NOTIFICATION_RECIPIENTS_STATISTICS',
  RESET_PUSH_NOTIFICATION_RECIPIENTS_STATISTICS: 'RESET_PUSH_NOTIFICATION_RECIPIENTS_STATISTICS',

  GET_SENT_PUSH_NOTIFICATIONS: 'GET_SENT_PUSH_NOTIFICATIONS',
  SAVE_SENT_PUSH_NOTIFICATIONS: 'SAVE_SENT_PUSH_NOTIFICATIONS',
  RESET_SENT_PUSH_NOTIFICATIONS: 'RESET_SENT_PUSH_NOTIFICATIONS',

  GET_PUSH_NOTIFICATION: 'GET_PUSH_NOTIFICATION',
  SAVE_PUSH_NOTIFICATION: 'SAVE_PUSH_NOTIFICATION',
  RESET_PUSH_NOTIFICATION: 'RESET_PUSH_NOTIFICATION',

  CHECK_PUSH_NOTIFICATION_NAME_EXISTS: 'CHECK_PUSH_NOTIFICATION_NAME_EXISTS',
  SAVE_PUSH_NOTIFICATION_NAME_EXISTS: 'SAVE_PUSH_NOTIFICATION_NAME_EXISTS',
  RESET_PUSH_NOTIFICATION_NAME_EXISTS: 'RESET_PUSH_NOTIFICATION_NAME_EXISTS',

  DELETE_PUSH_NOTIFICATION: 'DELETE_PUSH_NOTIFICATION',

  GET_SCHEDULED_PUSH_NOTIFICATIONS: 'GET_SCHEDULED_PUSH_NOTIFICATIONS',
  SAVE_SCHEDULED_PUSH_NOTIFICATIONS: 'SAVE_SCHEDULED_PUSH_NOTIFICATIONS',
  RESET_SCHEDULED_PUSH_NOTIFICATIONS: 'RESET_SCHEDULED_PUSH_NOTIFICATIONS',

  GET_PUSH_NOTIFICATION_RECIPIENTS_TOTALS: 'GET_PUSH_NOTIFICATION_RECIPIENTS_TOTALS',
  SAVE_PUSH_NOTIFICATION_RECIPIENTS_TOTALS: 'SAVE_PUSH_NOTIFICATION_RECIPIENTS_TOTALS',
  RESET_PUSH_NOTIFICATION_RECIPIENTS_TOTALS: 'RESET_PUSH_NOTIFICATION_RECIPIENTS_TOTALS',

  GET_PUSH_NOTIFICATION_RECIPIENTS: 'GET_PUSH_NOTIFICATION_RECIPIENTS',
  SAVE_PUSH_NOTIFICATION_RECIPIENTS: 'SAVE_PUSH_NOTIFICATION_RECIPIENTS',
  RESET_PUSH_NOTIFICATION_RECIPIENTS: 'RESET_PUSH_NOTIFICATION_RECIPIENTS',
  GENERATE_PUSH_NOTIFICATION_RECIPIENTS: 'GENERATE_PUSH_NOTIFICATION_RECIPIENTS',

  GET_PLAYER_PUSH_NOTIFICATIONS: 'GET_PLAYER_PUSH_NOTIFICATIONS',
  SAVE_PLAYER_PUSH_NOTIFICATIONS: 'SAVE_PLAYER_PUSH_NOTIFICATIONS',
  RESET_PLAYER_PUSH_NOTIFICATIONS: 'RESET_PLAYER_PUSH_NOTIFICATIONS',
};

export default PushNotificationActionType;
