import {
  all, call, put, takeLatest,
} from '@redux-saga/core/effects';
import qs from 'qs';
import PlayerSegmentActionType from 'actions/playerSegment/playerSegmentActionType';
import NetworkService from 'services/networkService';
import {
  savePlayersSegmentsResource,
  saveSegmentPlayersResource,
  savePlayersSegments,
  saveSegmentsPlayers,
  savePlayerIncludedSegments,
  savePlayerExcludedSegments,
  saveStatusUpdatedPlayersSegments,
  savePlayerSegment,
} from 'actions/playerSegment/playerSegmentActionCreator';
import { AppConstants } from 'constants/index';
import {
  changeEndedStatus, changeLoadingState, setErrorMessage, setSuccessMessage,
} from 'actions/setting/settingActionCreator';
import { getError, getMessage } from 'utils/helpers';
import { i18n } from 'services';

function* getSegmentsResource({ payload }) {
  const { isLoading } = payload;
  try {
    if (isLoading) {
      yield put(changeLoadingState(true));
    }
    const { ReportingAdmin, PlayerSegment, Resource } = AppConstants.api;
    const response = yield call(NetworkService.makeAPIGetRequest, [ReportingAdmin, PlayerSegment, Resource], {
      params: payload.data,
    });
    yield put(savePlayersSegmentsResource(response.data));
    if (isLoading) {
      yield put(changeLoadingState(false));
    }
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    if (isLoading) {
      yield put(changeLoadingState(false));
    }
  }
}

function* getSegmentPlayersResource({ payload }) {
  const { isLoading } = payload;
  try {
    const options = {
      params: payload,
      paramsSerializer: (param) => qs.stringify(param),
    };
    if (isLoading) {
      yield put(changeLoadingState(true));
    }
    const {
      PlayersAdmin, Segment, Players, Resource,
    } = AppConstants.api;
    const { data } = yield call(NetworkService.makeAPIGetRequest, [PlayersAdmin, Segment, Players, Resource], options);
    yield put(saveSegmentPlayersResource(data));
    if (isLoading) {
      yield put(changeLoadingState(false));
    }
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    if (isLoading) {
      yield put(changeLoadingState(false));
    }
  }
}

// with pagination
function* getSegmentsPlayers({ payload }) {
  try {
    const { segmentId, params } = payload;
    const options = {
      params,
      paramsSerializer: (param) => qs.stringify(param),
    };
    const { PlayersAdmin, Segment, Players } = AppConstants.api;
    yield put(changeLoadingState(true));
    const { data } = yield call(NetworkService.makeAPIGetRequest, [PlayersAdmin, Segment, segmentId, Players], options);
    yield put(saveSegmentsPlayers(data));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err, true);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* createPlayerSegment({ payload }) {
  try {
    yield put(changeLoadingState(true));
    const { PlayersAdmin, Segment } = AppConstants.api;
    yield call(NetworkService.makeAPIPostRequest, [PlayersAdmin, Segment], {
      data: payload.data,
    });
    yield put(setSuccessMessage(getMessage('', i18n.t('notification:slCreated'), payload.data.name)));
    yield put(changeLoadingState(false));
    yield put(changeEndedStatus(true));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getPlayersSegments({ payload }) {
  try {
    const options = {
      params: payload.params,
      paramsSerializer: (params) => qs.stringify(params),
    };
    yield put(changeLoadingState(true));
    const { PlayersAdmin, Segment } = AppConstants.api;
    const { data } = yield call(NetworkService.makeAPIGetRequest, [PlayersAdmin, Segment], options);
    yield put(savePlayersSegments(data));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* deletePlayerSegment({ payload }) {
  try {
    yield put(changeLoadingState(true));
    const { PlayersAdmin, Segment } = AppConstants.api;
    yield call(NetworkService.makeAPIDeleteRequest, [PlayersAdmin, Segment, payload.id]);
    yield put(changeLoadingState(false));
    yield put(setSuccessMessage(getMessage('', i18n.t('notification:slDeleted'), payload.name)));
    yield put(changeEndedStatus(true));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* editPlayerSegment({ payload }) {
  try {
    yield put(changeLoadingState(true));
    const { PlayersAdmin, Segment } = AppConstants.api;
    yield call(NetworkService.makeAPIPutRequest, [PlayersAdmin, Segment, payload.id], {
      data: payload.data,
    });
    yield put(setSuccessMessage(getMessage(i18n.t('tool:theSegment'), i18n.t('notification:slUpdated'))));
    yield put(changeLoadingState(false));
    yield put(changeEndedStatus(true));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* deletePlayerSegmentPlayer({ payload }) {
  try {
    const { segmentId, id, name } = payload;
    const options = {
      params: { segmentId },
    };
    const { PlayersAdmin, Segment, Players } = AppConstants.api;
    yield put(changeLoadingState(true));
    yield call(NetworkService.makeAPIDeleteRequest, [PlayersAdmin, Segment, Players, id], options);
    yield put(changeLoadingState(false));
    yield put(setSuccessMessage(i18n.t('notification:removedFromSegment', { name })));
    yield put(changeEndedStatus(true));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* updatePlayerSegment({ payload }) {
  try {
    const { segmentId, playerIds } = payload.data;
    yield put(changeLoadingState(true));
    const { PlayersAdmin, Segment, Update } = AppConstants.api;
    yield call(NetworkService.makeAPIPutRequest, [PlayersAdmin, Segment, Update, segmentId], {
      data: { playerIds },
    });
    yield put(setSuccessMessage(getMessage(i18n.t('tool:theSegment'), i18n.t('notification:slUpdated'))));
    yield put(changeLoadingState(false));
    yield put(changeEndedStatus(true));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getPlayerIncludedSegments({ payload }) {
  try {
    const options = {
      params: payload.params,
    };
    yield put(changeLoadingState(true));
    const { PlayersAdmin, Player, Segments } = AppConstants.api;
    const { data } = yield call(NetworkService.makeAPIGetRequest, [PlayersAdmin, Player, payload.playerId, Segments], options);
    yield put(savePlayerIncludedSegments(data));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getPlayerExcludedSegments({ payload }) {
  const { isLoading, playerId } = payload;
  try {
    if (isLoading) {
      yield put(changeLoadingState(true));
    }
    const { PlayersAdmin, Segment, Except } = AppConstants.api;
    const { data } = yield call(NetworkService.makeAPIGetRequest, [PlayersAdmin, Segment, Except, playerId]);
    yield put(savePlayerExcludedSegments(data));

    if (isLoading) {
      yield put(changeLoadingState(false));
    }
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    if (isLoading) {
      yield put(changeLoadingState(false));
    }
  }
}

function* deletePlayerFromSegment({ payload }) {
  try {
    const options = {
      params: payload.params,
    };
    const { PlayersAdmin, Segment, Players } = AppConstants.api;
    yield put(changeLoadingState(true));
    yield call(NetworkService.makeAPIDeleteRequest, [PlayersAdmin, Segment, Players, payload.playerId], options);
    yield put(changeLoadingState(false));
    yield put(setSuccessMessage(i18n.t('notification:playerRemovedFromSegment', { name: payload.segmentName })));
    yield put(changeEndedStatus(true));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getStatusUpdatedPlayersSegments({ payload }) {
  const { id, isLoading } = payload;
  try {
    if (isLoading) {
      yield put(changeLoadingState(true));
    }
    const { ReportingAdmin, Player, BulkActionSegments } = AppConstants.api;
    const { data } = yield call(NetworkService.makeAPIGetRequest, [ReportingAdmin, Player, BulkActionSegments, id]);
    yield put(saveStatusUpdatedPlayersSegments(data));

    if (isLoading) {
      yield put(changeLoadingState(false));
    }
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    if (isLoading) {
      yield put(changeLoadingState(false));
    }
  }
}

function* addPlayerIntoSegment({ payload }) {
  try {
    const { segmentId, playerId } = payload;
    yield put(changeLoadingState(true));
    const { PlayersAdmin, Segment, AddPlayer } = AppConstants.api;
    yield call(NetworkService.makeAPIPutRequest, [PlayersAdmin, Segment, AddPlayer, segmentId, playerId]);
    yield put(setSuccessMessage(i18n.t('notification:playerAddedToSegment', { name: payload.segmentName })));
    yield put(changeLoadingState(false));
    yield put(changeEndedStatus(true));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getPlayerSegment({ payload }) {
  try {
    yield put(changeLoadingState(true));
    const { PlayersAdmin, Segment } = AppConstants.api;
    const { data } = yield call(NetworkService.makeAPIGetRequest, [PlayersAdmin, Segment, payload.segmentId]);
    yield put(savePlayerSegment(data));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

export default function* playerSegmentSaga() {
  yield all([
    takeLatest(PlayerSegmentActionType.GET_PLAYERS_SEGMENTS, getPlayersSegments),
    takeLatest(PlayerSegmentActionType.CREATE_PLAYER_SEGMENT, createPlayerSegment),
    takeLatest(PlayerSegmentActionType.DELETE_PLAYER_SEGMENT, deletePlayerSegment),
    takeLatest(PlayerSegmentActionType.GET_PLAYERS_SEGMENTS_RESOURCE, getSegmentsResource),
    takeLatest(PlayerSegmentActionType.GET_SEGMENT_PLAYERS_RESOURCE, getSegmentPlayersResource),
    takeLatest(PlayerSegmentActionType.EDIT_PLAYER_SEGMENT, editPlayerSegment),
    takeLatest(PlayerSegmentActionType.GET_SEGMENTS_PLAYERS, getSegmentsPlayers),
    takeLatest(PlayerSegmentActionType.DELETE_PLAYER_SEGMENT_PLAYER, deletePlayerSegmentPlayer),
    takeLatest(PlayerSegmentActionType.UPDATE_PLAYER_SEGMENT, updatePlayerSegment),
    takeLatest(PlayerSegmentActionType.GET_PLAYER_INCLUDED_SEGMENTS, getPlayerIncludedSegments),
    takeLatest(PlayerSegmentActionType.DELETE_PLAYER_FROM_SEGMENT, deletePlayerFromSegment),
    takeLatest(PlayerSegmentActionType.GET_PLAYER_EXCLUDED_SEGMENTS, getPlayerExcludedSegments),
    takeLatest(PlayerSegmentActionType.GET_STATUS_UPDATED_PLAYERS_SEGMENTS, getStatusUpdatedPlayersSegments),
    takeLatest(PlayerSegmentActionType.ADD_PLAYER_TO_SEGMENT, addPlayerIntoSegment),
    takeLatest(PlayerSegmentActionType.GET_PLAYER_SEGMENT, getPlayerSegment),
  ]);
}
