const AccountUpdatesActionType = {
  GET_PLAYER_ACCOUNT_UPDATES: 'GET_PLAYER_ACCOUNT_UPDATES',
  SAVE_PLAYER_ACCOUNT_UPDATES: 'SAVE_PLAYER_ACCOUNT_UPDATES',
  RESET_PLAYER_ACCOUNT_UPDATES: 'RESET_PLAYER_ACCOUNT_UPDATES',
  EXPORT_PLAYER_ACCOUNT_UPDATES: 'EXPORT_PLAYER_ACCOUNT_UPDATES',

  GET_PLAYER_PROFILE_CONTROL_NAMES: 'GET_PLAYER_PROFILE_CONTROL_NAMES',
  SAVE_PLAYER_PROFILE_CONTROL_NAMES: 'SAVE_PLAYER_PROFILE_CONTROL_NAMES',
  RESET_PLAYER_PROFILE_CONTROL_NAMES: 'RESET_PLAYER_PROFILE_CONTROL_NAMES',
};

export default AccountUpdatesActionType;
