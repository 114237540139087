import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { Row, Col } from 'antd';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import Template from 'components/brand/create/Template';
import { StyledButtonBlue, StyledButtonBlueUnbordered, StyledButtonWrapperCentered } from 'components/styledComponents/button/Button.styled';
import { StyledCreateBrand, StyledTemplateWrapper, StyledTemplateWrap } from 'components/brand/Brand.styled';

const ChooseTemplate = (props) => {
  const {
    back, data, addBrand, currentPartnerId, setData, getTemplates, templates, loading, changeLoadingState,
  } = props;

  const { t } = useTranslation();

  useEffect(() => {
    getTemplates();
  }, [getTemplates]);

  useEffect(() => {
    changeLoadingState(loading);
  }, [changeLoadingState, loading]);

  const onSelectClick = (id) => {
    const tempDate = { ...data };
    tempDate.templateId = id;
    setData(tempDate);
  };

  const backStep = () => {
    back();
  };

  const onGenerateClick = () => {
    const tempDate = { ...data };
    tempDate.partnerId = currentPartnerId;
    addBrand(tempDate);
  };
  return (
    <StyledCreateBrand>
      <Row type="flex">
        <StyledTemplateWrapper>
          <Row type="flex" gutter={[24]}>
            {templates.map((template) => (
              <StyledTemplateWrap key={template.id}>
                <Template key={template.id} onSelectClick={onSelectClick} template={template} isSelected={data.templateId === template.id ? 1 : 0} />
              </StyledTemplateWrap>
            ))}
          </Row>
        </StyledTemplateWrapper>
      </Row>
      <Row type="flex">
        <Col span={8} offset={8}>
          <StyledButtonWrapperCentered>
            <StyledButtonBlueUnbordered type="default" onClick={backStep} size="large">
              {t('back')}
            </StyledButtonBlueUnbordered>
            <StyledButtonBlue htmlType="submit" onClick={onGenerateClick} disabled={_.isUndefined(data.templateId)} size="large">
              {t('create')}
            </StyledButtonBlue>
          </StyledButtonWrapperCentered>
        </Col>
      </Row>
    </StyledCreateBrand>
  );
};

ChooseTemplate.propTypes = {
  back: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired,
  addBrand: PropTypes.func.isRequired,
  currentPartnerId: PropTypes.number,
  setData: PropTypes.func.isRequired,
  getTemplates: PropTypes.func.isRequired,
  templates: PropTypes.array,
  loading: PropTypes.bool,
  changeLoadingState: PropTypes.func.isRequired,
};

ChooseTemplate.defaultProps = {
  currentPartnerId: null,
  templates: [],
  loading: false,
};

export default ChooseTemplate;
