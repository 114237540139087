import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { AdminList } from 'components/common';
import { createOrEditUserConfig } from 'actions/user/userActionCreator';
import { selectUserConfig } from 'selectors';
import { settingKeys } from 'constants/configKeys';

const mapStateToProps = ({ users }) => ({
  pageSize: selectUserConfig(users, settingKeys.pageSize),
});

const mapDispatchToProps = (dispatch) => bindActionCreators(
  {
    createOrEditUserConfig,
  },
  dispatch,
);

export default connect(mapStateToProps, mapDispatchToProps)(AdminList);
