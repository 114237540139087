export const selectPlayerOverviewFinancial = (state) => state.financial;
export const selectPlayerOverviewFinancialLoadingState = (state) => state.financialLoading;
export const selectPlayerOverviewActivities = (state) => state.activities;
export const selectPlayerOverviewActivitiesLoadingState = (state) => state.activitiesLoading;
export const selectPlayerOverviewHasRGLimit = (state) => state.hasRGLimit;
export const selectPlayerOverviewRevenues = (state) => state.revenues;
export const selectPlayerOverviewRevenuesLoadingState = (state) => state.revenuesLoading;
export const selectPlayerOverviewVerticalTags = (state) => state.verticalTags;
export const selectPlayerOverviewVerticalTagsLoadingState = (state) => state.verticalTagsLoading;
export const selectPlayerOverviewSportBonuses = (state) => state.sportBonuses;
export const selectPlayerOverviewCasinoBonuses = (state) => state.casinoBonuses;
export const selectPlayerOverviewBonusesLoadingState = (state) => state.bonusesLoading;
export const selectPlayerOverviewPayments = (state) => state.payments;
export const selectPlayerOverviewPaymentsLoadingState = (state) => state.paymentsLoading;
export const selectPlayerOverviewSportIndicators = (state) => state.sportIndicators;
export const selectPlayerOverviewCasinoIndicators = (state) => state.casinoIndicators;
export const selectPlayerOverviewIndicatorsLoadingState = (state) => state.indicatorsLoading;
