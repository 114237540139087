import { lazy } from 'react';
import { types, resource } from 'constants/accessControl';
import { conversionRouteKey } from 'constants/mapping';
import { title } from 'constants/tabs/title';
import Can from 'components/common/Can';

export const brandRouters = [
  {
    id: 1,
    menuItem: true,
    name: 'brands',
    component: lazy(() => import('pages/Brands')),
    exact: true,
    icon: 'LeftIcon',
    path: '/brands',
    key: '/brands',
    showTimeZone: true,
    breadcrumb: [
      {
        name: 'home',
        path: '/account',
      },
      {
        name: 'brands',
        path: '/brands',
      },
    ],
    hide: () => Can({
      type: types.view,
      rule: [`${resource.brands}.${resource.brands}`],
      children: false,
      fallback: true,
    }),
  },
  {
    id: 2,
    menuItem: true,
    name: 'mainSettings',
    component: lazy(() => import('pages/BrandMainSettings')),
    icon: 'BrandSettingIcon',
    path: 'mainSettings',
    key: 'mainSettings',
    showTimeZone: true,
    breadcrumb: [
      {
        name: 'home',
        path: '/account',
      },
      {
        name: 'brands',
        path: '/brands',
      },
      {
        key: 'name',
      },
    ],
    hide: () => Can({
      type: types.view,
      rule: `${resource.brands}.${resource.mainSettings}`,
      children: false,
      fallback: true,
    }),
  },
  {
    id: 3,
    menuItem: true,
    name: 'sportsBook',
    component: lazy(() => import('pages/BrandSportsBook')),
    icon: 'BrandSportIcon',
    path: 'sportsbook',
    key: 'sportsbook',
    showTimeZone: true,
    breadcrumb: [
      {
        name: 'home',
        path: '/account',
      },
      {
        name: 'brands',
        path: '/brands',
      },
      {
        key: 'name',
      },
    ],
    hide: () => Can({
      type: types.view,
      rule: `${resource.brands}.${resource.sportsBook}`,
      children: false,
      fallback: true,
    }),
  },
  {
    id: 4,
    menuItem: true,
    name: 'casinoProduct',
    component: lazy(() => import('pages/BrandCasinoProduct')),
    icon: 'GamesAndProvidersIcon',
    path: 'casinoProduct',
    key: 'casinoProduct',
    showTimeZone: true,
    breadcrumb: [
      {
        name: 'home',
        path: '/account',
      },
      {
        name: 'brands',
        path: '/brands',
      },
      {
        key: 'name',
      },
    ],
    hide: () => Can({
      type: types.view,
      rule: [`${resource.brands}.${resource.casinoProduct}`, `${resource.brands}.${resource.casinoSettings}`, `${resource.brands}.${resource.brandJackpots}`],
      children: false,
      fallback: true,
    }),
  },
  {
    id: 6,
    menuItem: true,
    name: 'payments',
    component: lazy(() => import('pages/BrandPayments')),
    icon: 'BrandPaymentIcon',
    exact: true,
    path: 'payments',
    key: 'payments',
    showTimeZone: true,
    breadcrumb: [
      {
        name: 'home',
        path: '/account',
      },
      {
        name: 'brands',
        path: '/brands',
      },
      {
        key: 'name',
      },
    ],
    hide: () => Can({
      type: types.view,
      rule: [`${resource.brands}.${resource.failedPayments}`, `${resource.brands}.${resource.payments}`],
      children: false,
      fallback: true,
    }),
  },
  {
    id: 22,
    menuItem: true,
    name: 'taxes',
    component: lazy(() => import('pages/BrandTaxes')),
    icon: 'TaxesIcon',
    exact: true,
    path: 'taxes',
    key: 'taxes',
    showTimeZone: true,
    breadcrumb: [
      {
        name: 'home',
        path: '/account',
      },
      {
        name: 'brands',
        path: '/brands',
      },
      {
        key: 'name',
      },
    ],
    hide: () => Can({
      type: types.view,
      rule: [`${resource.brands}.${resource.taxOnDeposit}`, `${resource.brands}.${resource.taxOnWithdrawal}`],
      children: false,
      fallback: true,
    }),
  },
  {
    id: 16,
    menuItem: true,
    name: 'operatorLimits',
    component: lazy(() => import('pages/BrandOperatorLimits')),
    icon: 'OperatorLimitsIcon',
    exact: true,
    path: 'operatorLimits',
    key: 'operatorLimits',
    showTimeZone: true,
    breadcrumb: [
      {
        name: 'home',
        path: '/account',
      },
      {
        name: 'brands',
        path: '/brands',
      },
      {
        key: 'name',
      },
    ],
    hide: () => Can({
      type: types.view,
      rule: [`${resource.brands}.${resource.largeTransactions}`, `${resource.brands}.${resource.playerLimits}`],
      children: false,
      fallback: true,
    }),
  },
  {
    id: 5,
    menuItem: true,
    name: 'thirdPartyServices',
    component: lazy(() => import('pages/BrandThirdPartyServices')),
    icon: 'BrandThirdPartyServicesIcon',
    path: 'thirdPartyServices',
    key: 'thirdPartyServices',
    showTimeZone: true,
    breadcrumb: [
      {
        name: 'home',
        path: '/account',
      },
      {
        name: 'brands',
        path: '/brands',
      },
      {
        key: 'name',
      },
    ],
    hide: () => Can({
      type: types.view,
      rule: `${resource.brands}.${resource.thirdPartyServices}`,
      children: false,
      fallback: true,
    }),
  },
  {
    id: 7,
    menuItem: true,
    name: 'notificationSettings',
    component: lazy(() => import('pages/BrandNotificationSettings')),
    icon: 'BrandNotificationServiceIcon',
    exact: true,
    path: 'notificationSettings',
    key: 'notificationSettings',
    showTimeZone: true,
    breadcrumb: [
      {
        name: 'home',
        path: '/account',
      },
      {
        name: 'brands',
        path: '/brands',
      },
      {
        key: 'name',
      },
    ],
    hide: () => Can({
      type: types.view,
      rule: [`${resource.brands}.${resource.notificationSettings}`, `${resource.brands}.${resource.smtpConfigurations}`],
      children: false,
      fallback: true,
    }),
  },
  {
    id: 8,
    menuItem: true,
    name: 'seoSettings',
    component: lazy(() => import('pages/BrandSeoSettings')),
    icon: 'BrandSeoSettingsIcon',
    exact: true,
    path: 'seoSettings',
    key: 'seoSettings',
    showTimeZone: true,
    breadcrumb: [
      {
        name: 'home',
        path: '/account',
      },
      {
        name: 'brands',
        path: '/brands',
      },
      {
        key: 'name',
      },
    ],
    hide: () => Can({
      type: types.view,
      rule: [`${resource.brands}.${resource.seoSettings}`],
      children: false,
      fallback: true,
    }),
  },
  {
    id: 15,
    menuItem: true,
    name: 'locationServices',
    component: lazy(() => import('pages/BrandLocationServices')),
    icon: 'LocationServices',
    exact: true,
    path: 'locationServices',
    key: 'locationServices',
    showTimeZone: true,
    breadcrumb: [
      {
        name: 'home',
        path: '/account',
      },
      {
        name: 'brands',
        path: '/brands',
      },
      {
        key: 'name',
      },
    ],
    hide: () => Can({
      type: types.view,
      rule: [`${resource.brands}.${resource.locationServices}`],
      children: false,
      fallback: true,
    }),
  },
  {
    id: 9,
    menuItem: true,
    name: 'conversionSettings',
    component: lazy(() => import('pages/BrandConversionSettings')),
    icon: 'ConversionSettingsIcon',
    exact: true,
    path: 'conversionSettings',
    key: 'conversionSettings',
    showTimeZone: true,
    breadcrumb: [
      {
        name: 'home',
        path: '/account',
      },
      {
        name: 'brands',
        path: '/brands',
      },
      {
        key: 'name',
      },
    ],
    hide: () => Can({
      type: types.view,
      rule: [`${resource.brands}.${resource.conversionSettingsDepositAndWithdrawals}`, `${resource.brands}.${resource.conversionSettingsOthers}`],
      children: false,
      fallback: true,
    }),
  },
  {
    id: 18,
    name: 'legal',
    menuItem: true,
    component: lazy(() => import('pages/BrandLegalAgreements')),
    icon: 'LegalIcon',
    exact: true,
    path: 'legalAgreements',
    key: 'legalAgreements',
    showTimeZone: true,
    breadcrumb: [
      {
        name: 'home',
        path: '/account',
      },
      {
        name: 'brands',
        path: '/brands',
      },
      {
        key: 'name',
      },
    ],
    hide: () => Can({
      type: types.view,
      rule: `${resource.brands}.${resource.legal}`,
      children: false,
      fallback: true,
    }),
  },
  {
    id: 10,
    menuItem: true,
    name: 'standards',
    component: lazy(() => import('pages/BrandStandardSettings')),
    icon: 'StandardIcon',
    exact: true,
    path: 'standards',
    key: 'standards',
    showTimeZone: true,
    breadcrumb: [
      {
        name: 'home',
        path: '/account',
      },
      {
        name: 'brands',
        path: '/brands',
      },
      {
        key: 'name',
      },
    ],
    hide: () => Can({
      type: types.view,
      rule: `${resource.brands}.${resource.standards}`,
      children: false,
      fallback: true,
    }),
  },
  {
    id: 11,
    menuParentId: 4,
    component: lazy(() => import('pages/BrandProviderGames')),
    icon: 'BrandSettingIcon',
    path: 'providerGames',
    key: 'providerGames',
    exact: true,
    showTimeZone: true,
    breadcrumb: [
      {
        name: 'home',
        path: '/account',
      },
      {
        name: 'brands',
        path: '/brands',
      },
      {
        key: 'name',
        isActive: false,
      },
      {
        name: 'providersAndGames',
      },
    ],
    hide: () => Can({
      type: types.view,
      rule: `${resource.brands}.${resource.casinoProduct}`,
      children: false,
      fallback: true,
    }),
  },
  {
    id: 12,
    menuParentId: 9,
    component: lazy(() => import('pages/DepositLogs')),
    icon: 'BrandSettingIcon',
    path: `${conversionRouteKey[title.brandSetting.deposit]}`,
    key: `${conversionRouteKey[title.brandSetting.deposit]}`,
    exact: true,
    showTimeZone: true,
    breadcrumb: [
      {
        name: 'home',
        path: '/account',
      },
      {
        name: 'brands',
        path: '/brands',
      },
      {
        key: 'name',
      },
    ],
    hide: () => Can({
      type: types.view,
      rule: `${resource.brands}.${resource.conversionSettingsDepositAndWithdrawals}`,
      children: false,
      fallback: true,
    }),
  },
  {
    id: 13,
    menuParentId: 9,
    component: lazy(() => import('pages/WithdrawalLogs')),
    icon: 'BrandSettingIcon',
    path: `${conversionRouteKey[title.brandSetting.withdrawal]}`,
    key: `${conversionRouteKey[title.brandSetting.withdrawal]}`,
    exact: true,
    showTimeZone: true,
    breadcrumb: [
      {
        name: 'home',
        path: '/account',
      },
      {
        name: 'brands',
        path: '/brands',
      },
      {
        key: 'name',
      },
    ],
  },
  {
    id: 14,
    menuParentId: 9,
    component: lazy(() => import('pages/OtherLogs')),
    icon: 'BrandSettingIcon',
    path: `${conversionRouteKey[title.brandSetting.other]}`,
    key: `${conversionRouteKey[title.brandSetting.other]}`,
    exact: true,
    showTimeZone: true,
    breadcrumb: [
      {
        name: 'home',
        path: '/account',
      },
      {
        name: 'brands',
        path: '/brands',
      },
      {
        key: 'name',
      },
    ],
    hide: () => Can({
      type: types.view,
      rule: `${resource.brands}.${resource.conversionSettingsOthers}`,
      children: false,
      fallback: true,
    }),
  },
  {
    id: 17,
    name: 'operatorLimits',
    menuParentId: 16,
    component: lazy(() => import('pages/BrandLargeTransactionLogs')),
    icon: 'ProductIcon',
    exact: true,
    path: 'largeTransactionLogs',
    key: 'largeTransactionLogs',
    showTimeZone: true,
    breadcrumb: [
      {
        name: 'home',
        path: '/account',
      },
      {
        name: 'brands',
        path: '/brands',
      },
      {
        key: 'name',
      },
    ],
    hide: () => Can({
      type: types.view,
      rule: `${resource.brands}.${resource.largeTransactions}`,
      children: false,
      fallback: true,
    }),
  },
  {
    id: 19,
    menuParentId: 6,
    component: lazy(() => import('pages/BrandPaymentMethodLogs')),
    icon: 'BrandPaymentIcon',
    exact: true,
    path: 'paymentMethodLogs',
    key: 'paymentMethodLogs',
    showTimeZone: true,
    breadcrumb: [
      {
        name: 'home',
        path: '/account',
      },
      {
        name: 'brands',
        path: '/brands',
      },
      {
        key: 'name',
      },
    ],
    hide: () => Can({
      type: types.view,
      rule: `${resource.brands}.${resource.payments}`,
      children: false,
      fallback: true,
    }),
  },
  {
    id: 20,
    menuParentId: 6,
    component: lazy(() => import('pages/ConfigureBrandPaymentMethod')),
    icon: 'BrandPaymentIcon',
    exact: true,
    path: 'configurePayment',
    key: 'configurePayment',
    showTimeZone: true,
    breadcrumb: [
      {
        name: 'Home',
        path: '/account',
      },
      {
        name: 'Brands',
        path: '/brands',
      },
      {
        key: 'name',
      },
    ],
    hide: () => Can({
      type: types.edit,
      rule: `${resource.brands}.${resource.payments}`,
      children: false,
      fallback: true,
    }),
  },
  {
    id: 21,
    menuParentId: 6,
    component: lazy(() => import('pages/ViewBrandPaymentMethod')),
    icon: 'BrandPaymentIcon',
    exact: true,
    path: 'paymentMethod',
    key: 'paymentMethod',
    showTimeZone: true,
    breadcrumb: [
      {
        name: 'home',
        path: '/account',
      },
      {
        name: 'brands',
        path: '/brands',
      },
      {
        key: 'name',
      },
    ],
    hide: () => Can({
      type: types.view,
      rule: `${resource.brands}.${resource.payments}`,
      children: false,
      fallback: true,
    }),
  },
  {
    id: 22,
    menuParentId: 4,
    component: lazy(() => import('pages/BrandJackpotFeedGames')),
    icon: 'BrandSettingIcon',
    path: 'jackpotFeedGames/:jackpotFeedId',
    key: 'jackpotFeedGames/:jackpotFeedId',
    exact: true,
    showTimeZone: true,
    breadcrumb: [
      {
        name: 'home',
        path: '/account',
      },
      {
        name: 'brands',
        path: '/brands',
      },
      {
        key: 'name',
        isActive: false,
      },
      {
        name: 'providersAndGames',
      },
    ],
    hide: () => Can({
      type: types.view,
      rule: `${resource.brands}.${resource.brandJackpots}`,
      children: false,
      fallback: true,
    }),
  },
];
