import _ from 'lodash';
import { selectLocaleResource } from './localeSelector';

const selectPlayerAccountUpdates = (state) => state.playerAccountUpdates;
export const selectPlayerAccountUpdatesData = (state) => selectPlayerAccountUpdates(state).data;
export const selectPlayerAccountUpdatesTotal = (state) => selectPlayerAccountUpdates(state).total;
const selectPlayerProfileControlNames = (state) => state.playerProfileControlNames;
export const selectPlayerAlteration = (state) => {
  const { locale, accountUpdate } = state;
  const keys = selectPlayerProfileControlNames(accountUpdate);

  return selectLocaleResource(locale, {
    dataAlterationLog: _.reduce(keys, (acc, key) => ({ ...acc, [key]: key }), {}),
  });
};
