import Can from 'components/common/Can';
import { resource, types } from 'constants/accessControl';
import Search from './Search';

const getFieldAccesses = () => ({
  playerId: !Can({
    type: types.view,
    rule: `${resource.players}.${resource.players}`,
    children: false,
    fallback: true,
  }),
  username: !(
    Can({
      type: types.view,
      rule: `${resource.players}.${resource.players}`,
      children: false,
      fallback: true,
    })
    || Can({
      type: types.privateView4,
      rule: `${resource.players}.${resource.players}`,
      children: false,
      fallback: true,
    })
  ),
  firstName: !(
    Can({
      type: types.view,
      rule: `${resource.players}.${resource.players}`,
      children: false,
      fallback: true,
    })
    || Can({
      type: types.privateView1,
      rule: `${resource.players}.${resource.players}`,
      children: false,
      fallback: true,
    })
  ),
  lastName: !(
    Can({
      type: types.view,
      rule: `${resource.players}.${resource.players}`,
      children: false,
      fallback: true,
    })
    || Can({
      type: types.privateView1,
      rule: `${resource.players}.${resource.players}`,
      children: false,
      fallback: true,
    })
  ),
  documentNumber: !(
    Can({
      type: types.view,
      rule: `${resource.players}.${resource.players}`,
      children: false,
      fallback: true,
    })
    || Can({
      type: types.privateView3,
      rule: `${resource.players}.${resource.players}`,
      children: false,
      fallback: true,
    })
  ),
  mobileNumber: !(
    Can({
      type: types.view,
      rule: `${resource.players}.${resource.players}`,
      children: false,
      fallback: true,
    })
    || Can({
      type: types.privateView2,
      rule: `${resource.players}.${resource.players}`,
      children: false,
      fallback: true,
    })
  ),
  email: !(
    Can({
      type: types.view,
      rule: `${resource.players}.${resource.players}`,
      children: false,
      fallback: true,
    })
    || Can({
      type: types.privateView2,
      rule: `${resource.players}.${resource.players}`,
      children: false,
      fallback: true,
    })
  ),
  betId: !Can({
    type: types.view,
    rule: [`${resource.bets}.${resource.casinoBets}`, `${resource.bets}.${resource.sportBets}`, `${resource.players}.${resource.playerCasinoBets}`, `${resource.players}.${resource.playerSportBets}`],
    children: false,
    fallback: true,
  }),
  roundId: !Can({
    type: types.view,
    rule: [`${resource.bets}.${resource.casinoBets}`, `${resource.bets}.${resource.sportBets}`, `${resource.players}.${resource.playerCasinoBets}`, `${resource.players}.${resource.playerSportBets}`],
    children: false,
    fallback: true,
  }),
  paymentRequestId: !Can({
    type: types.view,
    rule: [
      `${resource.financials}.${resource.withdrawals}`,
      `${resource.financials}.${resource.deposits}`,
      `${resource.players}.${resource.playerWithdrawals}`,
      `${resource.players}.${resource.playerDeposits}`,
    ],
    children: false,
    fallback: true,
  }),
});

const QuickSearch = () => {
  const fieldAccesses = getFieldAccesses();
  const hasFieldAccess = Object.values(fieldAccesses).some(Boolean);

  return hasFieldAccess && <Search fieldAccesses={fieldAccesses} />;
};
export default QuickSearch;
