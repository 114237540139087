import common from './common';
import brand from './brand';
import validate from './validate';
import mapping from './mapping';
import title from './title';
import confirm from './confirm';
import notification from './notification';
import bonus from './bonus';
import cms from './cms';
import player from './player';
import payment from './payment';
import bet from './bet';
import communication from './communication';
import tool from './tool';
import auditLog from './auditLog';
import riskManagement from './riskManagement';
import reporting from './reporting';
import partner from './partner';
import userManagement from './userManagement';
import dashboard from './dashboard';
import systemSetting from './systemSetting';
import devTool from './devTool';
import promotion from './promotion';
import regulatory from './regulatory';

export const resources = {
  common,
  brand,
  validate,
  mapping,
  title,
  confirm,
  notification,
  bonus,
  cms,
  player,
  payment,
  bet,
  communication,
  tool,
  auditLog,
  riskManagement,
  reporting,
  partner,
  userManagement,
  dashboard,
  systemSetting,
  devTool,
  promotion,
  regulatory,
};
