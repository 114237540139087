import PlayerSessionActionType from './playerSessionActionType';

export const getPlayerSessions = (playerId, params) => ({
  type: PlayerSessionActionType.GET_PLAYER_SESSIONS,
  payload: { playerId, params },
});

export const savePlayerSessions = (data) => ({
  type: PlayerSessionActionType.SAVE_PLAYER_SESSIONS,
  payload: { data },
});

export const resetPlayerSessions = () => ({
  type: PlayerSessionActionType.RESET_PLAYER_SESSIONS,
});

export const getPlayerActiveSessions = (playerId) => ({
  type: PlayerSessionActionType.GET_PLAYER_ACTIVE_SESSIONS,
  payload: { playerId },
});

export const savePlayerActiveSessions = (data) => ({
  type: PlayerSessionActionType.SAVE_PLAYER_ACTIVE_SESSIONS,
  payload: { data },
});

export const resetPlayerActiveSessions = () => ({
  type: PlayerSessionActionType.RESET_PLAYER_ACTIVE_SESSIONS,
});

export const endPlayerSessions = (playerId, data) => ({
  type: PlayerSessionActionType.END_PLAYER_SESSIONS,
  payload: { playerId, data },
});

export const getApplicationSessions = (params) => ({
  type: PlayerSessionActionType.GET_APPLICATION_SESSIONS,
  payload: { params },
});

export const saveApplicationSessions = (data) => ({
  type: PlayerSessionActionType.SAVE_APPLICATION_SESSIONS,
  payload: { data },
});

export const resetApplicationSessions = () => ({
  type: PlayerSessionActionType.RESET_APPLICATION_SESSIONS,
});

export const generateApplicationSessions = (params) => ({
  type: PlayerSessionActionType.GENERATE_APPLICATION_SESSIONS,
  payload: { params },
});

export const getWebSessions = (params) => ({
  type: PlayerSessionActionType.GET_WEB_SESSIONS,
  payload: { params },
});

export const saveWebSessions = (data) => ({
  type: PlayerSessionActionType.SAVE_WEB_SESSIONS,
  payload: { data },
});

export const resetWebSessions = () => ({
  type: PlayerSessionActionType.RESET_WEB_SESSIONS,
});

export const generateWebSessions = (params) => ({
  type: PlayerSessionActionType.GENERATE_WEB_SESSIONS,
  payload: { params },
});
