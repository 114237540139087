import ExportCenterActionType from './exportCenterActionType';

export const getExportHistory = (params) => ({
  type: ExportCenterActionType.GET_EXPORT_HISTORY,
  payload: { params },
});

export const saveExportHistory = (data) => ({
  type: ExportCenterActionType.SAVE_EXPORT_HISTORY,
  payload: { data },
});

export const resetExportHistory = () => ({
  type: ExportCenterActionType.RESET_EXPORT_HISTORY,
});

export const deleteExportHistoryFile = (id, name) => ({
  type: ExportCenterActionType.DELETE_EXPORT_HISTORY_FILE,
  payload: { id, name },
});

export const cancelExportHistoryFileGeneration = (id, name) => ({
  type: ExportCenterActionType.CANCEL_EXPORT_HISTORY_FILE_GENERATION,
  payload: { id, name },
});
