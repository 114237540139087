import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { StyledAccountMainWrapper, StyledAccountWrapper } from 'components/auth/AccountDetails.styled';

const AccountDetails = (props) => {
  const { user } = props;
  const {
    id, userName, firstName, lastName, company,
  } = user;

  const { t } = useTranslation();

  return (
    <StyledAccountMainWrapper>
      <h3>{t('accountInfo')}</h3>
      <StyledAccountWrapper>
        <div>
          <span>{`${t('userId')}:`}</span>
          <span>{`${t('fullName')}:`}</span>
          <span>{`${t('email')}:`}</span>
          <span>{`${t('company')}:`}</span>
        </div>
        <div>
          <strong>{id}</strong>
          <strong>{`${firstName} ${lastName}`}</strong>
          <strong>{userName}</strong>
          <strong>{company && company.name}</strong>
        </div>
      </StyledAccountWrapper>
    </StyledAccountMainWrapper>
  );
};
AccountDetails.propTypes = {
  user: PropTypes.object.isRequired,
};

export default AccountDetails;
