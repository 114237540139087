import SeoSettingActionType from 'actions/seoSetting/seoSettingActionType';

const initialState = {
  socialMediaConfig: {},
  schemaMarkup: {},
  pageGroups: {},
  pagesSettings: {},
  robots: {},
};

const seoSettingReducer = (state = initialState, { type, payload }) => {
  switch (type) {
  case SeoSettingActionType.SAVE_SEO_PAGES_SETTINGS:
    return {
      ...state,
      pagesSettings: payload.data,
    };
  case SeoSettingActionType.RESET_SEO_PAGES_SETTINGS:
    return {
      ...state,
      pagesSettings: {},
    };
  case SeoSettingActionType.SAVE_SEO_SOCIAL_MEDIA_CONFIG:
    return {
      ...state,
      socialMediaConfig: payload.data,
    };
  case SeoSettingActionType.RESET_SEO_SOCIAL_MEDIA_CONFIG:
    return {
      ...state,
      socialMediaConfig: {},
    };
  case SeoSettingActionType.SAVE_SEO_SCHEMA_MARKUP:
    return {
      ...state,
      schemaMarkup: payload.data,
    };
  case SeoSettingActionType.RESET_SEO_SCHEMA_MARKUP:
    return {
      ...state,
      schemaMarkup: {},
    };
  case SeoSettingActionType.SAVE_SEO_PAGE_GROUPS:
    return {
      ...state,
      pageGroups: payload.data,
    };
  case SeoSettingActionType.RESET_SEO_PAGE_GROUPS:
    return {
      ...state,
      pageGroups: {},
    };
  case SeoSettingActionType.SAVE_SEO_ROBOTS:
    return {
      ...state,
      robots: payload.data,
    };
  case SeoSettingActionType.RESET_SEO_ROBOTS:
    return {
      ...state,
      robots: {},
    };
  default:
    return state;
  }
};

export default seoSettingReducer;
