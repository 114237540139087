const CampaignV2ActionType = {
  GET_CAMPAIGNS_V2: 'GET_CAMPAIGNS_V2',
  SAVE_CAMPAIGNS_V2: 'SAVE_CAMPAIGNS_V2',
  RESET_CAMPAIGNS_V2: 'RESET_CAMPAIGNS_V2',

  GET_CAMPAIGN_V2: 'GET_CAMPAIGN_V2',
  SAVE_CAMPAIGN_V2: 'SAVE_CAMPAIGN_V2',
  RESET_CAMPAIGN_V2: 'RESET_CAMPAIGN_V2',

  CREATE_CAMPAIGN_V2: 'CREATE_CAMPAIGN_V2',
  SAVE_CREATED_CAMPAIGN_V2_ID: 'SAVE_CREATED_CAMPAIGN_V2_ID',
  CHANGE_CAMPAIGN_V2_GENERAL_CONFIG_ENDED_STATUS: 'CHANGE_CAMPAIGN_V2_GENERAL_CONFIG_ENDED_STATUS',
  EDIT_CAMPAIGN_V2: 'EDIT_CAMPAIGN_V2',

  GET_CAMPAIGN_V2_PARTICIPATION_GROUPS: 'GET_CAMPAIGN_V2_PARTICIPATION_GROUPS',
  SAVE_CAMPAIGN_V2_PARTICIPATION_GROUPS: 'SAVE_CAMPAIGN_V2_PARTICIPATION_GROUPS',
  RESET_CAMPAIGN_V2_PARTICIPATION_GROUPS: 'RESET_CAMPAIGN_V2_PARTICIPATION_GROUPS',

  GET_CAMPAIGN_V2_PARTICIPATION_GROUP: 'GET_CAMPAIGN_V2_PARTICIPATION_GROUP',
  SAVE_CAMPAIGN_V2_PARTICIPATION_GROUP: 'SAVE_CAMPAIGN_V2_PARTICIPATION_GROUP',
  RESET_CAMPAIGN_V2_PARTICIPATION_GROUP: 'RESET_CAMPAIGN_V2_PARTICIPATION_GROUP',

  CREATE_CAMPAIGN_V2_PARTICIPATION_GROUP: 'CREATE_CAMPAIGN_V2_PARTICIPATION_GROUP',
  EDIT_CAMPAIGN_V2_PARTICIPATION_GROUP: 'EDIT_CAMPAIGN_V2_PARTICIPATION_GROUP',
  DELETE_CAMPAIGN_V2_PARTICIPATION_GROUP: 'DELETE_CAMPAIGN_V2_PARTICIPATION_GROUP',
  CHANGE_CAMPAIGN_V2_PARTICIPATION_GROUP_ENDED_STATUS: 'CHANGE_CAMPAIGN_V2_PARTICIPATION_GROUP_ENDED_STATUS',

  GET_CAMPAIGN_V2_BONUSES: 'GET_CAMPAIGN_V2_BONUSES',
  SAVE_CAMPAIGN_V2_BONUSES: 'SAVE_CAMPAIGN_V2_BONUSES',
  RESET_CAMPAIGN_V2_BONUSES: 'RESET_CAMPAIGN_V2_BONUSES',

  CREATE_CAMPAIGN_V2_FREE_BET_BONUS: 'CREATE_CAMPAIGN_V2_FREE_BET_BONUS',
  EDIT_CAMPAIGN_V2_FREE_BET_BONUS: 'EDIT_CAMPAIGN_V2_FREE_BET_BONUS',
  GET_CAMPAIGN_V2_FREE_BET_BONUS: 'GET_CAMPAIGN_V2_FREE_BET_BONUS',
  RESET_CAMPAIGN_V2_FREE_BET_BONUS: 'RESET_CAMPAIGN_V2_FREE_BET_BONUS',
  SAVE_CAMPAIGN_V2_FREE_BET_BONUS: 'SAVE_CAMPAIGN_V2_FREE_BET_BONUS',
  DELETE_CAMPAIGN_V2_FREE_BET_BONUS: 'DELETE_CAMPAIGN_V2_FREE_BET_BONUS',

  CREATE_CAMPAIGN_V2_FREE_SPIN_BONUS: 'CREATE_CAMPAIGN_V2_FREE_SPIN_BONUS',
  EDIT_CAMPAIGN_V2_FREE_SPIN_BONUS: 'EDIT_CAMPAIGN_V2_FREE_SPIN_BONUS',
  GET_CAMPAIGN_V2_FREE_SPIN_BONUS: 'GET_CAMPAIGN_V2_FREE_SPIN_BONUS',
  RESET_CAMPAIGN_V2_FREE_SPIN_BONUS: 'RESET_CAMPAIGN_V2_FREE_SPIN_BONUS',
  SAVE_CAMPAIGN_V2_FREE_SPIN_BONUS: 'SAVE_CAMPAIGN_V2_FREE_SPIN_BONUS',
  DELETE_CAMPAIGN_V2_FREE_SPIN_BONUS: 'DELETE_CAMPAIGN_V2_FREE_SPIN_BONUS',

  CHANGE_CAMPAIGN_V2_BONUS_ENDED_STATUS: 'CHANGE_CAMPAIGN_V2_BONUS_ENDED_STATUS',

  GET_CAMPAIGN_V2_TRIGGER_CONDITIONS: 'GET_CAMPAIGN_V2_TRIGGER_CONDITIONS',
  SAVE_CAMPAIGN_V2_TRIGGER_CONDITIONS: 'SAVE_CAMPAIGN_V2_TRIGGER_CONDITIONS',
  RESET_CAMPAIGN_V2_TRIGGER_CONDITIONS: 'RESET_CAMPAIGN_V2_TRIGGER_CONDITIONS',

  GET_CAMPAIGN_V2_TRIGGER_CONDITION: 'GET_CAMPAIGN_V2_TRIGGER_CONDITION',
  SAVE_CAMPAIGN_V2_TRIGGER_CONDITION: 'SAVE_CAMPAIGN_V2_TRIGGER_CONDITION',
  RESET_CAMPAIGN_V2_TRIGGER_CONDITION: 'RESET_CAMPAIGN_V2_TRIGGER_CONDITION',
  CREATE_CAMPAIGN_V2_TRIGGER_CONDITION: 'CREATE_CAMPAIGN_V2_TRIGGER_CONDITION',
  EDIT_CAMPAIGN_V2_TRIGGER_CONDITION: 'EDIT_CAMPAIGN_V2_TRIGGER_CONDITION',
  DELETE_CAMPAIGN_V2_TRIGGER_CONDITION: 'DELETE_CAMPAIGN_V2_TRIGGER_CONDITION',
  CHANGE_CAMPAIGN_V2_TRIGGER_CONDITION_ENDED_STATUS: 'CHANGE_CAMPAIGN_V2_TRIGGER_CONDITION_ENDED_STATUS',
};

export default CampaignV2ActionType;
