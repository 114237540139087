import { useEffect, useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { Form, Grid } from 'antd';
import { StyledAuthMainWrapper } from 'components/auth/Login.styled';
import { LocalStorageService, MessageService } from 'services';
import ExpirePassword from 'containers/auth/ExpirePassword';
import LoginStep1 from './LoginStep1';
import LoginStep2 from './LoginStep2';

const Login = (props) => {
  const {
    login, getAuthUser, getUserConfigs, isAuthenticated, isTFAEnabled, changeTFAState, isPasswordExpired, getCurrenciesPrecisions,
  } = props;

  const { useBreakpoint } = Grid;

  const screens = useBreakpoint();

  const [form] = Form.useForm();
  const [data, setData] = useState({});

  const handleSubmit = (e, additionalData = {}) => {
    if (e) {
      e.preventDefault();
    }
    form.validateFields().then((values) => {
      const { userName, password } = { ...data, ...values };
      if (userName.trim() === password) {
        return MessageService.error('Your password cannot be the same as your username. Please choose a different password');
      }
      if (!isTFAEnabled) {
        setData({ ...values, ...additionalData });
      }
      login({ ...data, ...values, ...additionalData });
    });
  };

  useEffect(
    () => () => {
      if (isTFAEnabled) {
        changeTFAState(false);
      }
    },
    [changeTFAState, isTFAEnabled],
  );

  const loginBuilder = useCallback(() => {
    const iframe = document.getElementById('ui-customization');
    if (iframe) {
      const win = iframe.contentWindow;
      const authorizationData = LocalStorageService.get('auth');
      win.postMessage(JSON.stringify({ key: 'authorizationData', method: 'add', data: authorizationData }), '*');
    }
  }, []);

  useEffect(() => {
    if (isAuthenticated && LocalStorageService.get('auth')) {
      getAuthUser();
      getUserConfigs();
      loginBuilder();
      getCurrenciesPrecisions();
    }
  }, [isAuthenticated, getAuthUser, getUserConfigs, loginBuilder, isTFAEnabled, changeTFAState, getCurrenciesPrecisions]);

  return (
    <StyledAuthMainWrapper md={screens.md ? 1 : 0} xs={screens.xs ? 1 : 0}>
      {!isPasswordExpired && (
        <Form
          layout="vertical"
          initialValues={{
            userName: data.userName,
            password: data.password,
          }}
          form={form}
        >
          {!isTFAEnabled ? <LoginStep1 handleSubmit={handleSubmit} form={form} /> : <LoginStep2 handleSubmit={handleSubmit} form={form} changeTFAState={changeTFAState} />}
        </Form>
      )}
      {isPasswordExpired && <ExpirePassword handleSubmit={handleSubmit} data={data} setData={setData} loginForm={form} />}
    </StyledAuthMainWrapper>
  );
};

Login.propTypes = {
  login: PropTypes.func.isRequired,
  getAuthUser: PropTypes.func.isRequired,
  getUserConfigs: PropTypes.func.isRequired,
  isTFAEnabled: PropTypes.bool,
  changeTFAState: PropTypes.func.isRequired,
  isAuthenticated: PropTypes.bool.isRequired,
  isPasswordExpired: PropTypes.bool,
  getCurrenciesPrecisions: PropTypes.func.isRequired,
};

Login.defaultProps = {
  isTFAEnabled: false,
  isPasswordExpired: false,
};

export default Login;
