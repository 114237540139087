import PropTypes from 'prop-types';
import { Form, Input, Tooltip } from 'antd';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { useEffect, useMemo, useState } from 'react';
import { FormInputWrapper, StyledEditablePermissions, StyledSavePermissions } from 'components/styledComponents/input/Input.styled';
import { onlyNumbersFormatter } from 'utils/utils';
import { StyledSvgIconHover } from 'components/styledComponents/icon/Icon.styled';
import { IconSvg } from 'components/common';

const EditCommonRow = (props) => {
  const {
    columns, actions, form, record, isInitiallyValidate, ...rowProps
  } = props;

  const { className } = rowProps;
  const [error, setError] = useState(false);

  const { t } = useTranslation();

  const { validateFields, resetFields } = form;

  const cols = useMemo(() => columns.filter(({ edit }) => edit).map(({ dataIndex }) => dataIndex), [columns]);

  const save = () => {
    validateFields(cols).then((values) => {
      const preparedObj = {};
      Object.keys(values).forEach((key) => {
        preparedObj[key] = values[key] ? +values[key] : undefined;
      });

      actions.save(preparedObj);
      resetFields(cols);
    });
  };

  useEffect(() => {
    if (isInitiallyValidate) {
      validateFields(cols);
    }
  }, [isInitiallyValidate, validateFields, cols]);

  const handleChange = () => validateFields(cols)
    .then(() => setError(false))
    .catch(() => setError(true));

  const renderEditableCell = (col) => (col.edit ? (
    <StyledEditablePermissions key={col.key}>
      <FormInputWrapper>
        <Form.Item name={col.dataIndex} rules={_.isFunction(col.rules) ? col.rules(record) : col.rules || []} getValueFromEvent={(e) => onlyNumbersFormatter(e, 2, col.isNegative)}>
          <Input placeholder={t('typeHere')} size="large" maxLength={10} onChange={handleChange} autoComplete="off" />
        </Form.Item>
      </FormInputWrapper>
    </StyledEditablePermissions>
  ) : (
    <StyledSavePermissions key={col.key} className="ant-table-ping-right ant-table-cell-fix-right-first">
      <div>
        <Tooltip placement="top" title={t('cancel')} arrow={{ pointAtCenter: true }} key={0}>
          <StyledSvgIconHover
            onClick={(e) => {
              e.preventDefault();
              actions.cancel();
            }}
          >
            <IconSvg icon="DeletePopoverIcon" width="1.14rem" height="1.14rem" />
          </StyledSvgIconHover>
        </Tooltip>
        <Tooltip placement="top" title={t('save')} arrow={{ pointAtCenter: true }} key={1}>
          <StyledSvgIconHover
            onClick={(e) => {
              e.preventDefault();
              save();
            }}
          >
            <IconSvg icon="SaveIcon" width="1.28rem" height="1.14rem" color="" />
          </StyledSvgIconHover>
        </Tooltip>
      </div>
    </StyledSavePermissions>
  ));

  const doRender = () => rowProps.children.map((el) => (el.props.additionalProps && (el.props.additionalProps.edit || el.props.additionalProps.dataIndex === 'action') ? (
    renderEditableCell(el.props.additionalProps)
  ) : (
    <td key={el.key}>{el.props.render(actions, record)}</td>
  )));
  return <tr className={`${className} editable ${error ? 'validation-error' : ''}`}>{doRender()}</tr>;
};

EditCommonRow.propTypes = {
  form: PropTypes.object.isRequired,
  columns: PropTypes.array,
  actions: PropTypes.object,
  record: PropTypes.object,
  isInitiallyValidate: PropTypes.bool,
};

EditCommonRow.defaultProps = {
  columns: [],
  actions: {},
  record: {},
  isInitiallyValidate: false,
};
export default EditCommonRow;
