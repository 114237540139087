export default {
  en: {
    createPartner: 'Create Partner',
    partnerName: 'Partner Name',
    createUserForPartner: 'Create user for the partner',
    checkEmailAndFollowInstructionsInfo: 'Please check email and follow the instructions provided in the link.',
    editPartner: 'Edit Partner',
    lPartner: 'partner',
    removeMaintenance: 'Remove Maintenance',
    manageMaintenanceInfo: 'Maintenance will only apply to Active brands. The Start Date will not apply to brands in Maintenance status.',
    removeMaintenanceInfo: 'Partner brands that are in “Maintenance” state will be immediately changed to “Active',
    hasMaintenance: 'Has Maintenance',
    bulkRemoveMaintenanceInfo: 'Selected partner brands that are in Maintenance status will be immediately changed to Active',
  },
  es: {
    createPartner: 'Crear socio',
    partnerName: 'Nombre del socio',
    createUserForPartner: 'Crear usuario para el socio',
    checkEmailAndFollowInstructionsInfo: 'Compruebe el correo electrónico y siga las instrucciones proporcionadas en el enlace.',
    editPartner: 'Editar socio',
    lPartner: 'socio',
  },
  tr: {
    createPartner: 'Partner Oluştur',
    partnerName: 'Partnerin Adı',
    createUserForPartner: 'Partner için kullanıcı oluştur',
    checkEmailAndFollowInstructionsInfo: 'Lütfen e-postanızı kontrol edin ve linkte belirtilen talimatları uygulayın.',
    editPartner: 'Partner Düzenle',
    lPartner: 'Partner',
  },
  ru: {
    createPartner: 'Создать партнера',
    partnerName: 'Имя партнера',
    createUserForPartner: 'Создать пользователя для партнера',
    checkEmailAndFollowInstructionsInfo: 'Проверьте почту и следуйте инструкциям, доступным по ссылке.',
    editPartner: 'Редактировать партнера',
    lPartner: 'Партнер',
  },
  pt: {
    createPartner: 'Criar Parceiro',
    partnerName: 'Nome do Parceiro',
    createUserForPartner: 'Criar utilizador para o parceiro',
    checkEmailAndFollowInstructionsInfo: 'Por favor, verifique o e-mail e siga as instruções fornecidas no link.',
    editPartner: 'Editar Parceiro',
    lPartner: 'parceiro',
  },
  zh: {
    createPartner: '创建合作伙伴',
    partnerName: '合作伙伴名称',
    createUserForPartner: '为合作伙伴创建用户',
    checkEmailAndFollowInstructionsInfo: '请查收电子邮件并按照链接中提供的指示进行操作。',
    editPartner: '编辑合作伙伴',
    lPartner: '合作伙伴',
  },
  ro: {
    createPartner: 'Creează un partener',
    partnerName: 'Numele partenerului',
    createUserForPartner: 'Creează utilizator pentru partener',
    checkEmailAndFollowInstructionsInfo: 'Te rugăm să verifici e-mailul și să urmezi instrucțiunile furnizate în link',
    editPartner: 'Editează partenerul',
    lPartner: 'partener',
  },
  bg: {},
  el: {
    createPartner: 'Δημιουργία συνεργάτη',
    partnerName: 'Όνομα συνεργάτη',
    createUserForPartner: 'Δημιουργία χρήστη για τον συνεργάτη',
    checkEmailAndFollowInstructionsInfo: 'Ελέγξτε το email και ακολουθήστε τις οδηγίες που παρέχονται στον σύνδεσμο.',
    editPartner: 'Επεξεργασία συνεργάτη',
    lPartner: 'συνεργάτης',
  },
};
