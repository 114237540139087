import esES from 'antd/locale/es_ES';
import enUS from 'antd/locale/en_US';
import trTR from 'antd/locale/tr_TR';
import ruRU from 'antd/locale/ru_RU';
import ptBR from 'antd/locale/pt_BR';
import zhCN from 'antd/locale/zh_CN';
import roRO from 'antd/locale/ro_RO';
import bgBG from 'antd/locale/bg_BG';
import elGR from 'antd/locale/el_GR';
import 'dayjs/locale/ru';
import 'dayjs/locale/tr';
import 'dayjs/locale/es';
import 'dayjs/locale/pt-br';
import 'dayjs/locale/zh-cn';
import 'dayjs/locale/ro';
import 'dayjs/locale/bg';
import 'dayjs/locale/el';

const locales = {
  en: enUS,
  es: esES,
  tr: trTR,
  ru: ruRU,
  pt: ptBR,
  zh: zhCN,
  ro: roRO,
  bg: bgBG,
  el: elGR,
};

export { locales };
