import TaxesActionType from 'actions/taxes/taxesActionType';

const initialState = {
  depositTaxes: [],
  depositTax: [],
  withdrawalTaxes: [],
  withdrawalTax: [],
  setting: {},
};

const taxesReducer = (state = initialState, { type, payload }) => {
  switch (type) {
  case TaxesActionType.SAVE_DEPOSIT_TAXES:
    return {
      ...state,
      depositTaxes: payload.data,
    };
  case TaxesActionType.RESET_DEPOSIT_TAXES:
    return {
      ...state,
      depositTaxes: [],
    };
  case TaxesActionType.SAVE_DEPOSIT_TAX:
    return {
      ...state,
      depositTax: payload.data,
    };
  case TaxesActionType.RESET_DEPOSIT_TAX:
    return {
      ...state,
      depositTax: [],
    };
  case TaxesActionType.SAVE_WITHDRAWAL_TAXES:
    return {
      ...state,
      withdrawalTaxes: payload.data,
    };
  case TaxesActionType.RESET_WITHDRAWAL_TAXES:
    return {
      ...state,
      withdrawalTaxes: [],
    };
  case TaxesActionType.SAVE_WITHDRAWAL_TAX:
    return {
      ...state,
      withdrawalTax: payload.data,
    };
  case TaxesActionType.RESET_WITHDRAWAL_TAX:
    return {
      ...state,
      withdrawalTax: [],
    };
  case TaxesActionType.SAVE_TAX_SETTING:
    return {
      ...state,
      setting: payload.data,
    };
  case TaxesActionType.RESET_TAX_SETTING:
    return {
      ...state,
      setting: {},
    };
  default:
    return state;
  }
};

export default taxesReducer;
