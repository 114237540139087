import PermissionGroupActionType from './permissionGroupActionType';

export const getPermissionGroup = (id, data, key = 'first') => ({
  type: PermissionGroupActionType.GET_PERMISSION_GROUP,
  payload: { id, data, key },
});

export const getPermissionGroupSuccess = (data, key = 'first') => ({
  type: PermissionGroupActionType.GET_PERMISSION_GROUP_SUCCESS,
  payload: { data, key },
});

export const getPermissionGroups = (data) => ({
  type: PermissionGroupActionType.GET_PERMISSION_GROUPS,
  payload: { data },
});

export const getPermissionGroupsSuccess = (data) => ({
  type: PermissionGroupActionType.GET_PERMISSION_GROUPS_SUCCESS,
  payload: { data },
});

export const getAllPermissionGroups = (data, isLoading) => ({
  type: PermissionGroupActionType.GET_ALL_PERMISSION_GROUPS,
  payload: { data, isLoading },
});

export const getAllPermissionGroupsSuccess = (data) => ({
  type: PermissionGroupActionType.GET_ALL_PERMISSION_GROUPS_SUCCESS,
  payload: { data },
});

export const resetAllPermissionGroups = () => ({
  type: PermissionGroupActionType.RESET_ALL_PERMISSION_GROUPS,
});

export const deletePermissionGroup = (data) => ({
  type: PermissionGroupActionType.DELETE_PERMISSION_GROUP,
  payload: { data },
});

export const deletePermissionGroupSuccess = (data) => ({
  type: PermissionGroupActionType.DELETE_PERMISSION_GROUP_SUCCESS,
  payload: { data },
});

export const editPermissionGroup = (data) => ({
  type: PermissionGroupActionType.EDIT_PERMISSION_GROUP,
  payload: { data },
});

export const createPermissionGroup = (data) => ({
  type: PermissionGroupActionType.CREATE_PERMISSION_GROUP,
  payload: { data },
});

export const resetPermissionGroup = (key = 'first') => ({
  type: PermissionGroupActionType.RESET_PERMISSION_GROUP,
  payload: { key },
});

export const changePermissionGroupLoadingState = (state, key = 'first') => ({
  type: PermissionGroupActionType.CHANGE_PERMISSION_GROUP_LOADING_STATE,
  payload: { state, key },
});
