import { FloatButton, Layout } from 'antd';
import PropTypes from 'prop-types';
import { useRef } from 'react';
import { StyledBackTopButtonWrapper } from 'components/styledComponents/button/Button.styled';

const Content = (props) => {
  const { children, noPadding } = props;

  const contentRef = useRef(null);

  return (
    <>
      <Layout.Content ref={contentRef} className={noPadding ? 'no-padding' : ''}>
        {children}
      </Layout.Content>
      <StyledBackTopButtonWrapper>
        <FloatButton.BackTop target={() => contentRef.current} />
      </StyledBackTopButtonWrapper>
    </>
  );
};

Content.propTypes = {
  children: PropTypes.node,
  noPadding: PropTypes.bool,
};

Content.defaultProps = {
  children: null,
  noPadding: false,
};

export default Content;
