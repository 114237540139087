import { createSelector } from 'reselect';

export const selectCasinoBets = (state) => state.casinoBets;
export const selectCasinoBetsHasNext = (state) => state.casinoBetsHasNext;
export const selectPlayerCasinoBets = (state) => state.playerCasinoBets;
export const selectPlayerCasinoBetsHasNext = (state) => state.playerCasinoBetsHasNext;
export const selectCasinoBet = (state) => state.casinoBet;
export const selectSportBets = (state) => state.sportBets;
export const selectSportBetsHasNext = (state) => state.sportBetsHasNext;
export const selectPlayerSportBets = (state) => state.playerSportBets;
export const selectPlayerSportBetsHasNext = (state) => state.playerSportBetsTotalHasNext;
export const selectSportBet = (state) => state.sportBet;

export const selectCasinoBetsDetails = (state) => state.casinoBetsDetails;
export const selectCasinoBetDetails = (state, id) => createSelector(
  () => selectCasinoBetsDetails(state),
  (data) => data[id] || [],
)();

export const selectSportBetsDetails = (state) => state.sportBetsDetails;
export const selectSportBetDetails = (state, id) => createSelector(
  () => selectSportBetsDetails(state),
  (data) => data[id] || {},
)();
