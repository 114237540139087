import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { SystemConversions } from 'components/layout/header';
import { getSystemConversionsResource, setCurrentConversion } from 'actions/systemConversion/systemConversionActionCreator';
import { selectSystemConversionsResource, selectCurrentConversionId } from 'selectors';

const mapDispatchToProps = (dispatch) => bindActionCreators(
  {
    setCurrentConversion,
    getSystemConversionsResource,
  },
  dispatch,
);

const mapStateToProps = ({ systemConversion }) => ({
  systemConversions: selectSystemConversionsResource(systemConversion),
  conversionId: selectCurrentConversionId(systemConversion),
});

export default connect(mapStateToProps, mapDispatchToProps)(SystemConversions);
