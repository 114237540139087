/**
 * App global theme for styled components
 */
const { rgba } = require('polished');

const token = {
  // Colors
  colorPrimary: '#00a1af',
  colorText: '#FFFFFF',
  colorTextDisabled: '#9FA4AC',
  colorIcon: '#999999',
  colorTextPlaceholder: '#9FA4AC',
  colorPrimaryHover: '#00a1af',
  colorBgElevated: '#FFFFFF',
  colorBorder: '#C2C6CB',
  colorBorderSecondary: rgba(0, 0, 0, 0.16),
  colorBgMask: rgba(38, 38, 38, 0.45),
  colorSplit: rgba(0, 0, 0, 0.16),
  colorTextDescription: '#999999',

  // Typography
  fontFamily: 'Roboto, sans-serif',
  fontSize: 14,
  fontSizeBase: 1,
  fontSizeIcon: 11,
  lineHeight: 1.2,
  fontWeightStrong: 500,

  // Layout
  paddingXXS: 4,
  paddingXS: 8,
  paddingSM: 12,
  padding: 16,
  paddingMD: 12,
  paddingLG: 24,
  marginLG: 0,
  popoverPadding: 500,

  borderWidthBase: 1,
  borderRadius: 8,
  borderRadiusBase: 4,
  borderRadiusLG: 8,
  controlHeightLG: 36,
  controlPaddingHorizontal: 12,
};

const theme = {};

// colors
const nightModeColors = {
  danger: '#e20a16',
  red: '#42323F',
  success: '#009900',
  yellow: '#ffb700',
  purple: '#74276b',
  purpleHover: '#521E4C',
  white: '#ffffff',
  grayButton: '#353F4F',
  hoverBg: '#242B36',
  primaryBorder: '#47505E',
  cardBg: '#2A323F',
  black: '#ffffff',
  primaryText: '#ffffffde',
  primaryBg: '#1E242D',
  secondaryBg: '#2A323F',
  secondaryShadow: '#00000016',
  secondaryBorder: '#47505E',
  tertiaryBg: '#303947',
  blue: '#00AABE',
  secondaryColor: '#E5E7EA',
  hover: '#116984',
  tableHeadColor: '#353F4F',
  tableTrEvenColor: '#303947',
  tableTrHoverColor: '#242B36',
  selectBg: '#303947',
  filterLabelColor: '#8D939C',
  darkColor: '#1E242D',
  mainCardBg: '#353F4F',
  pinkyPurple: '#ad2b9d',
  notSeen: '#273C4B',
};

const lightModeColors = {
  danger: '#e20a16',
  red: '#fff1f0',
  success: '#009900',
  yellow: '#ffb700',
  purple: '#74276b',
  purpleHover: '#521E4C',
  white: '#ffffff',
  grayButton: '#EEF0F2',
  hoverBg: '#00AABE19',
  primaryBorder: '#C2C6CB',
  cardBg: '#47505E',
  black: '#000000',
  primaryBg: '#F7F8F9',
  secondaryBg: '#ffffff',
  secondaryShadow: '#E5E7EA',
  secondaryBorder: '#E5E7EA',
  tertiaryBg: '#353F4F',
  primaryText: '#47505E',
  blue: '#00AABE',
  secondaryColor: '#8D939C',
  hover: '#116984',
  tableHeadColor: '#E5E7EA',
  tableTrEvenColor: '#F7F8F9',
  tableTrHoverColor: '#EEF0F2',
  selectBg: '#ffffff',
  filterLabelColor: '#9FA4AC',
  lightBlue: '#E5F5F715',
  darkColor: '#2A323F',
  mainCardBg: '#ffffff',
  pinkyPurple: '#ad2b9d',
  notSeen: '#f2f9fa',
};

theme.darkColors = {};

// Typography
theme.typography = {
  fontSizeSmall: `${token.fontSizeBase - 0.14}rem`,
  fontSizeDefault: `${token.fontSizeBase}rem`,
  fontSizeMiddle: `${token.fontSizeBase + 0.14}rem`,
  fontSizeLarge: `${token.fontSizeBase + 10}px`,

  fontWeightRegular: 400,
  fontWeightMedium: 500,
  fontWeightBold: 700,

  fontFamilyBase: 'Roboto, sans-serif',
};

// Shadow
theme.shadows = {
  default: `0 3px 6px ${rgba(nightModeColors.secondaryShadow, 0.16)}`,
  headerShadow: `0 2px 18px ${rgba(nightModeColors.secondaryShadow, 0.06)}`,
  topButtonsHover: `0 2px 10px ${rgba(nightModeColors.secondaryShadow, 0.3)}`,
};

// Border radius
theme.customBorderRadius = {
  borderRadiusDefault: `${token.borderRadiusBase}px`,
  borderRadiusSmall: `${token.borderRadiusBase + 4}px`, // 8px
  borderRadiusMiddle: `${token.borderRadiusBase + 6}px`, // 10px
  borderRadiusLarge: `${token.borderRadiusBase + 14}px`, // 18px
  borderRadiusSwitch: `${token.borderRadiusBase + 28}px`,
  borderRadiusCircle: '50%',
};

theme.borderWidth = {
  borderWidthDefault: `${token.borderWidthBase}px`,
  borderWidthSmall: `${token.borderWidthBase + 1}px`,
};

module.exports = {
  nightModeColors,
  lightModeColors,
  theme,
  token,
};
