import StaticCollectionActionType from 'actions/staticCollection/staticCollectionActionType';

const initialState = {
  languages: [],
  countries: [],
  currencies: [],
  cities: [],
  regions: [],
  loading: false,
  timeZones: [],
  operationTypes: [],
};
const staticCollectionReducer = (state = initialState, { type, payload }) => {
  switch (type) {
  case StaticCollectionActionType.GET_COUNTRIES_SUCCESS:
    return {
      ...state,
      countries: payload.data,
    };
  case StaticCollectionActionType.GET_CURRENCIES_SUCCESS:
    return {
      ...state,
      currencies: payload.data,
    };
  case StaticCollectionActionType.GET_LANGUAGES_SUCCESS:
    return {
      ...state,
      languages: payload.data,
    };
  case StaticCollectionActionType.GET_CITIES_SUCCESS:
    return {
      ...state,
      cities: payload.data,
    };
  case StaticCollectionActionType.RESET_CITIES:
    return {
      ...state,
      cities: [],
    };
  case StaticCollectionActionType.GET_TIME_ZONES_SUCCESS:
    return {
      ...state,
      timeZones: payload.data,
    };
  case StaticCollectionActionType.CHANGE_STATIC_COLLECTION_LOADING_STATE:
    return {
      ...state,
      loading: payload.state,
    };
  case StaticCollectionActionType.SAVE_OPERATION_TYPES:
    return {
      ...state,
      operationTypes: payload.data,
    };
  case StaticCollectionActionType.RESET_OPERATION_TYPES:
    return {
      ...state,
      operationTypes: [],
    };
  case StaticCollectionActionType.SAVE_REGIONS:
    return {
      ...state,
      regions: payload.data,
    };
  case StaticCollectionActionType.RESET_REGIONS:
    return {
      ...state,
      regions: [],
    };
  default:
    return state;
  }
};

export default staticCollectionReducer;
