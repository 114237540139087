import OverviewActionType from 'actions/overview/overviewActionType';

const initialState = {
  financial: {},
  financialLoading: false,
  activities: {},
  activitiesLoading: false,
  revenues: {},
  revenuesLoading: false,
  hasRGLimit: false,
  verticalTags: [],
  verticalTagsLoading: false,
  casinoBonuses: [],
  sportBonuses: [],
  bonusesLoading: false,
  payments: [],
  paymentsLoading: false,
  casinoIndicators: {},
  sportIndicators: {},
  indicatorsLoading: false,
};

const overviewReducer = (state = initialState, { type, payload }) => {
  switch (type) {
  case OverviewActionType.SAVE_PLAYER_OVERVIEW_FINANCIAL:
    return {
      ...state,
      financial: payload.data,
    };
  case OverviewActionType.RESET_PLAYER_OVERVIEW_FINANCIAL:
    return {
      ...state,
      financial: {},
    };
  case OverviewActionType.CHANGE_PLAYER_OVERVIEW_FINANCIAL_LOADING_STATE:
    return {
      ...state,
      financialLoading: payload.state,
    };
  case OverviewActionType.SAVE_PLAYER_OVERVIEW_ACTIVITIES:
    return {
      ...state,
      activities: payload.data,
    };
  case OverviewActionType.RESET_PLAYER_OVERVIEW_ACTIVITIES:
    return {
      ...state,
      activities: {},
    };
  case OverviewActionType.CHANGE_PLAYER_OVERVIEW_ACTIVITIES_LOADING_STATE:
    return {
      ...state,
      activitiesLoading: payload.state,
    };
  case OverviewActionType.SAVE_PLAYER_OVERVIEW_REVENUES:
    return {
      ...state,
      revenues: payload.data,
    };
  case OverviewActionType.RESET_PLAYER_OVERVIEW_REVENUES:
    return {
      ...state,
      revenues: {},
    };
  case OverviewActionType.CHANGE_PLAYER_OVERVIEW_REVENUES_LOADING_STATE:
    return {
      ...state,
      revenuesLoading: payload.state,
    };
  case OverviewActionType.SAVE_PLAYER_OVERVIEW_LIMITS_CHECK:
    return {
      ...state,
      hasRGLimit: payload.data,
    };
  case OverviewActionType.RESET_PLAYER_OVERVIEW_LIMITS_CHECK:
    return {
      ...state,
      hasRGLimit: false,
    };
  case OverviewActionType.CHANGE_PLAYER_OVERVIEW_VERTICAL_TAGS_LOADING_STATE:
    return {
      ...state,
      verticalTagsLoading: payload.state,
    };
  case OverviewActionType.SAVE_PLAYER_OVERVIEW_VERTICAL_TAGS:
    return {
      ...state,
      verticalTags: payload.data,
    };
  case OverviewActionType.RESET_PLAYER_OVERVIEW_VERTICAL_TAGS:
    return {
      ...state,
      verticalTags: [],
    };
  case OverviewActionType.SAVE_PLAYER_OVERVIEW_CASINO_BONUSES:
    return {
      ...state,
      casinoBonuses: payload.data,
    };
  case OverviewActionType.RESET_PLAYER_OVERVIEW_CASINO_BONUSES:
    return {
      ...state,
      casinoBonuses: [],
    };
  case OverviewActionType.SAVE_PLAYER_OVERVIEW_SPORT_BONUSES:
    return {
      ...state,
      sportBonuses: payload.data,
    };
  case OverviewActionType.RESET_PLAYER_OVERVIEW_SPORT_BONUSES:
    return {
      ...state,
      sportBonuses: [],
    };
  case OverviewActionType.CHANGE_PLAYER_OVERVIEW_BONUSES_LOADING_STATE:
    return {
      ...state,
      bonusesLoading: payload.state,
    };
  case OverviewActionType.SAVE_PLAYER_OVERVIEW_PAYMENTS:
    return {
      ...state,
      payments: payload.data,
    };
  case OverviewActionType.RESET_PLAYER_OVERVIEW_PAYMENTS:
    return {
      ...state,
      payments: [],
    };
  case OverviewActionType.CHANGE_PLAYER_OVERVIEW_PAYMENTS_LOADING_STATE:
    return {
      ...state,
      paymentsLoading: payload.state,
    };
  case OverviewActionType.SAVE_PLAYER_OVERVIEW_CASINO_INDICATORS:
    return {
      ...state,
      casinoIndicators: payload.data,
    };
  case OverviewActionType.RESET_PLAYER_OVERVIEW_CASINO_INDICATORS:
    return {
      ...state,
      casinoIndicators: {},
    };
  case OverviewActionType.SAVE_PLAYER_OVERVIEW_SPORT_INDICATORS:
    return {
      ...state,
      sportIndicators: payload.data,
    };
  case OverviewActionType.RESET_PLAYER_OVERVIEW_SPORT_INDICATORS:
    return {
      ...state,
      sportIndicators: {},
    };
  case OverviewActionType.CHANGE_PLAYER_OVERVIEW_INDICATORS_LOADING_STATE:
    return {
      ...state,
      indicatorsLoading: payload.state,
    };
  default:
    return state;
  }
};

export default overviewReducer;
