import BetActionType from 'actions/bet/betActionType';

const initialState = {
  casinoBets: [],
  casinoBetsHasNext: false,
  casinoBet: {},
  playerCasinoBets: [],
  playerCasinoBetsHasNext: false,
  sportBets: [],
  sportBetsHasNext: false,
  sportBet: {},
  playerSportBets: [],
  playerSportBetsTotalHasNext: false,

  casinoBetsDetails: {},
  sportBetsDetails: {},
};

const betReducer = (state = initialState, { type, payload }) => {
  switch (type) {
  case BetActionType.SAVE_CASINO_BETS:
    return {
      ...state,
      casinoBets: payload.data.data,
      casinoBetsHasNext: payload.data.hasNext,
    };
  case BetActionType.RESET_CASINO_BETS:
    return {
      ...state,
      casinoBets: [],
      casinoBetsHasNext: false,
    };
  case BetActionType.SAVE_PLAYER_CASINO_BETS:
    return {
      ...state,
      playerCasinoBets: payload.data.data,
      playerCasinoBetsHasNext: payload.data.hasNext,
    };
  case BetActionType.RESET_PLAYER_CASINO_BETS:
    return {
      ...state,
      playerCasinoBets: [],
      playerCasinoBetsHasNext: false,
    };
  case BetActionType.SAVE_SPORT_BETS:
    return {
      ...state,
      sportBets: payload.data.data,
      sportBetsHasNext: payload.data.hasNext,
    };
  case BetActionType.RESET_SPORT_BETS:
    return {
      ...state,
      sportBets: [],
      sportBetsHasNext: false,
    };
  case BetActionType.SAVE_PLAYER_SPORT_BETS:
    return {
      ...state,
      playerSportBets: payload.data.data,
      playerSportBetsTotalHasNext: payload.data.hasNext,
    };
  case BetActionType.RESET_PLAYER_SPORT_BETS:
    return {
      ...state,
      playerSportBets: [],
      playerSportBetsTotalHasNext: false,
    };
  case BetActionType.SAVE_CASINO_BET_DETAILS:
    return {
      ...state,
      casinoBetsDetails: payload.data,
    };
  case BetActionType.RESET_CASINO_BET_DETAILS:
    return {
      ...state,
      casinoBetsDetails: {},
    };
  case BetActionType.SAVE_SPORT_BET_DETAILS:
    return {
      ...state,
      sportBetsDetails: payload.data,
    };
  case BetActionType.RESET_SPORT_BET_DETAILS:
    return {
      ...state,
      sportBetsDetails: {},
    };
  case BetActionType.SAVE_SPORT_BET: {
    return {
      ...state,
      sportBet: payload.data,
    };
  }
  case BetActionType.RESET_SPORT_BET: {
    return {
      ...state,
      sportBet: {},
    };
  }
  case BetActionType.SAVE_CASINO_BET: {
    return {
      ...state,
      casinoBet: payload.data,
    };
  }
  case BetActionType.RESET_CASINO_BET: {
    return {
      ...state,
      casinoBet: {},
    };
  }
  default:
    return state;
  }
};

export default betReducer;
