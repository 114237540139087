import styled from 'styled-components';
import { media } from 'utils/styledHelpers';

const StyledPaginationWrapper = styled.div`
  .ant-pagination {
    margin-top: ${(props) => (props.isMarginTop ? '5rem' : '0')};
    width: 100%;
    display: flex;
    .ant-pagination-total-text {
      flex-grow: 1;
      line-height: 2.28rem;
      color: ${(props) => props.theme.colors.primaryText};
    }
    .ant-pagination-prev {
      margin-right: 0.7rem !important;
    }
  }
  .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    border: 1px solid ${(props) => props.theme.colors.secondaryBorder};
  }
  .ant-table-pagination.ant-pagination,
  .ant-list-pagination .ant-pagination {
    display: flex;
    align-items: center;
    ${media.xs`
      flex-wrap: wrap;
      justify-content: center;
    `}
    .ant-pagination-total-text {
      margin-right: auto;
      font-size: 1rem;
      color: ${(props) => props.theme.colors.primaryText};

      ${media.xs`
        order: 100;
        flex: 0 0 100%;
        text-align: center;
        line-height: auto;
        height: auto;
        margin-top: 0.71rem;
      `}
    }
  }
  .ant-pagination-disabled a,
  .ant-pagination-disabled:hover a,
  .ant-pagination-disabled:focus a,
  .ant-pagination-disabled .ant-pagination-item-link,
  .ant-pagination-disabled:hover .ant-pagination-item-link,
  .ant-pagination-disabled:focus .ant-pagination-item-link {
    border: none;
  }
  .ant-pagination-item {
    border: none;
    white-space: nowrap;
    a {
      padding: 0;
    }
  }
  .ant-pagination.ant-pagination-mini {
    .ant-pagination-item {
      margin: 0 2px !important;
      & a,
      &-active a {
        font-size: ${(props) => props.theme.typography.fontSizeSmall};
      }
    }
    .ant-pagination-prev > button.ant-pagination-item-link,
    .ant-pagination-next > button.ant-pagination-item-link {
      margin: 0 !important;
    }
  }
  .ant-list-pagination {
    margin-top: 16px;
  }
  .ant-list-pagination,
  .ant-pagination {
    text-align: left !important;
    padding-bottom: 1.43rem;
    .ant-pagination-item,
    .ant-pagination-prev .ant-pagination-item-link,
    .ant-pagination-next .ant-pagination-item-link {
      min-width: 2.28rem !important;
      height: 2.28rem !important;
      padding: 0 0.57rem;
      line-height: 2.28rem !important;
      background-color: transparent;
      border: none;
      margin: 0 4px !important;
      a {
        font-size: ${(props) => props.theme.typography.fontSizeDefault};
        color: ${(props) => props.theme.colors.primaryText};
        &:hover {
          color: ${(props) => props.theme.colors.blue};
        }
      }
    }

    .ant-pagination-item-link .anticon {
      color: ${(props) => props.theme.colors.secondaryColor};
      &:hover {
        color: ${(props) => props.theme.colors.blue};
      }
    }
    .ant-select-arrow .anticon {
      color: ${(props) => props.theme.colors.secondaryColor};
    }
    .ant-pagination {
      &-jump-prev,
      &-jump-next,
      &-prev {
        margin: 0;
      }
      &-prev,
      &-next,
      &-jump-prev,
      &-jump-next,
      &.mini .ant-pagination-prev,
      &.mini .ant-pagination-next {
        width: 2.28rem;
        min-width: 2.28rem !important;
        height: 2.28rem !important;
        line-height: 2.28rem !important;
        > button.ant-pagination-item-link {
          margin: 0 auto;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }

      &-options {
        height: 2.28rem;
        .ant-select-selector {
          .ant-select-selection-item {
            height: 2.28rem;
            display: flex;
            align-items: center;
            font-size: 1rem;
            color: ${(props) => props.theme.colors.primaryText};
          }
        }
        &-size-changer.ant-select {
          margin-right: 0;
        }
      }
    }
    .ant-pagination-item-ellipsis {
      color: ${(props) => props.theme.colors.secondaryColor};
    }
    .ant-pagination-item-active {
      background-color: ${(props) => props.theme.colors.hoverBg};
      color: ${(props) => props.theme.colors.blue};
      border-radius: ${(props) => props.theme.customBorderRadius.borderRadiusSmall};
      font-weight: ${(props) => props.theme.typography.fontWeightMedium};
      a {
        color: ${(props) => props.theme.colors.blue};
      }
      &:hover,
      &:focus {
        a {
          color: ${(props) => props.theme.colors.blue};
        }
      }
    }
  }
`;
const StyledStickyPagination = styled(StyledPaginationWrapper)`
  width: 100%;
  .ant-pagination {
    padding-bottom: 0;
  }
`;

export { StyledPaginationWrapper, StyledStickyPagination };
