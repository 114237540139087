import ReferralActionType from './referralActionType';

export const createReferral = (data) => ({
  type: ReferralActionType.CREATE_REFERRAL,
  payload: { data },
});

export const getExistingPlayers = (params) => ({
  type: ReferralActionType.GET_EXISTING_PLAYERS,
  payload: { params },
});

export const saveExistingPlayers = (data) => ({
  type: ReferralActionType.SAVE_EXISTING_PLAYERS,
  payload: { data },
});

export const resetExistingPlayers = () => ({
  type: ReferralActionType.RESET_EXISTING_PLAYERS,
});

export const getReferrals = (brandId, params) => ({
  type: ReferralActionType.GET_REFERRALS,
  payload: { brandId, params },
});

export const saveReferrals = (data) => ({
  type: ReferralActionType.SAVE_REFERRALS,
  payload: { data },
});

export const resetReferrals = () => ({
  type: ReferralActionType.RESET_REFERRALS,
});

export const getReferral = (referralId) => ({
  type: ReferralActionType.GET_REFERRAL,
  payload: { referralId },
});

export const saveReferral = (data) => ({
  type: ReferralActionType.SAVE_REFERRAL,
  payload: { data },
});

export const resetReferral = () => ({
  type: ReferralActionType.RESET_REFERRAL,
});

export const editReferral = (referralId, data) => ({
  type: ReferralActionType.EDIT_REFERRAL,
  payload: { referralId, data },
});

export const deleteReferral = (data) => ({
  type: ReferralActionType.DELETE_REFERRAL,
  payload: { data },
});

export const activateReferral = (data) => ({
  type: ReferralActionType.ACTIVATE_REFERRAL,
  payload: { data },
});

export const finishReferral = (data) => ({
  type: ReferralActionType.FINISH_REFERRAL,
  payload: { data },
});

export const getReferralStatistics = (referralId, params) => ({
  type: ReferralActionType.GET_REFERRAL_STATISTICS,
  payload: {
    referralId,
    params,
  },
});

export const saveReferralAccumulated = (data) => ({
  type: ReferralActionType.SAVE_REFERRAL_ACCUMULATED,
  payload: { data },
});

export const saveReferralTransferred = (data) => ({
  type: ReferralActionType.SAVE_REFERRAL_TRANSFERRED,
  payload: { data },
});

export const saveReferralFailed = (data) => ({
  type: ReferralActionType.SAVE_REFERRAL_FAILED,
  payload: { data },
});

export const resetReferralTransferred = () => ({
  type: ReferralActionType.RESET_REFERRAL_TRANSFERRED,
});

export const resetReferralAccumulated = () => ({
  type: ReferralActionType.RESET_REFERRAL_ACCUMULATED,
});

export const resetReferralFailed = () => ({
  type: ReferralActionType.RESET_REFERRAL_FAILED,
});

export const getReferralCommissionPeriods = (referralId, params) => ({
  type: ReferralActionType.GET_REFERRAL_COMMISSION_PERIODS,
  payload: {
    referralId,
    params,
  },
});

export const transferReferralCommissions = (referralId, data, params) => ({
  type: ReferralActionType.TRANSFER_REFERRAL_COMMISSIONS,
  payload: { referralId, data, params },
});

export const deleteReferralCommissions = (referralId, data, params) => ({
  type: ReferralActionType.DELETE_REFERRAL_COMMISSIONS,
  payload: { referralId, data, params },
});

export const getReferralReferees = (batchId, params) => ({
  type: ReferralActionType.GET_REFERRAL_REFEREES,
  payload: { batchId, params },
});

export const saveReferralReferees = (data) => ({
  type: ReferralActionType.SAVE_REFERRAL_REFEREES,
  payload: { data },
});

export const resetReferralReferees = () => ({
  type: ReferralActionType.RESET_REFERRAL_REFEREES,
});

export const getReferralParticipants = (referralId, params) => ({
  type: ReferralActionType.GET_REFERRAL_PARTICIPANTS,
  payload: { referralId, params },
});

export const saveReferralParticipants = (data) => ({
  type: ReferralActionType.SAVE_REFERRAL_PARTICIPANTS,
  payload: { data },
});

export const resetReferralParticipants = () => ({
  type: ReferralActionType.RESET_REFERRAL_PARTICIPANTS,
});

export const deleteReferralParticipant = (referralId, data) => ({
  type: ReferralActionType.DELETE_REFERRAL_PARTICIPANT,
  payload: { referralId, data },
});

export const getPlayerReferralReferees = (playerId, params) => ({
  type: ReferralActionType.GET_PLAYER_REFERRAL_REFEREES,
  payload: { playerId, params },
});

export const savePlayerReferralReferees = (data) => ({
  type: ReferralActionType.SAVE_PLAYER_REFERRAL_REFEREES,
  payload: { data },
});

export const resetPlayerReferralReferees = () => ({
  type: ReferralActionType.RESET_PLAYER_REFERRAL_REFEREES,
});

export const getPlayerReferrals = (playerId, params) => ({
  type: ReferralActionType.GET_PLAYER_REFERRALS,
  payload: { playerId, params },
});

export const savePlayerReferrals = (data) => ({
  type: ReferralActionType.SAVE_PLAYER_REFERRALS,
  payload: { data },
});

export const resetPlayerReferrals = () => ({
  type: ReferralActionType.RESET_PLAYER_REFERRALS,
});

export const getReferralAllParticipants = (referralId, params) => ({
  type: ReferralActionType.GET_REFERRAL_ALL_PARTICIPANTS,
  payload: { referralId, params },
});

export const saveReferralAllParticipants = (data) => ({
  type: ReferralActionType.SAVE_REFERRAL_ALL_PARTICIPANTS,
  payload: { data },
});

export const resetReferralAllParticipants = () => ({
  type: ReferralActionType.RESET_REFERRAL_ALL_PARTICIPANTS,
});

export const editReferralParticipants = (referralId, data) => ({
  type: ReferralActionType.EDIT_REFERRAL_PARTICIPANTS,
  payload: { referralId, data },
});

export const savePlayerReferralPeriods = (data) => ({
  type: ReferralActionType.SAVE_PLAYER_REFERRAL_PERIODS,
  payload: { data },
});

export const resetPlayerReferralPeriods = () => ({
  type: ReferralActionType.RESET_PLAYER_REFERRAL_PERIODS,
});

export const saveReferralAccumulatedPeriods = (data) => ({
  type: ReferralActionType.SAVE_REFERRAL_ACCUMULATED_PERIODS,
  payload: { data },
});

export const resetReferralAccumulatedPeriods = () => ({
  type: ReferralActionType.RESET_REFERRAL_ACCUMULATED_PERIODS,
});

export const saveReferralTransferredPeriods = (data) => ({
  type: ReferralActionType.SAVE_REFERRAL_TRANSFERRED_PERIODS,
  payload: { data },
});

export const resetReferralTransferredPeriods = () => ({
  type: ReferralActionType.RESET_REFERRAL_TRANSFERRED_PERIODS,
});

export const saveReferralFailedPeriods = (data) => ({
  type: ReferralActionType.SAVE_REFERRAL_FAILED_PERIODS,
  payload: { data },
});

export const resetReferralFailedPeriods = () => ({
  type: ReferralActionType.RESET_REFERRAL_FAILED_PERIODS,
});

export const exportReferralStatistics = (id, params) => ({
  type: ReferralActionType.EXPORT_REFERRAL_STATISTICS,
  payload: { id, params },
});
