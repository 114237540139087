import SystemConversionActionType from 'actions/systemConversion/systemConversionActionType';
import { LocalStorageService } from 'services';

const initialState = {
  systemConversions: [],
  systemConversionLogs: {},
  currentConversionId: LocalStorageService.get('current')?.conversionId,
  systemConversionsResource: [],
};
const systemConversionReducer = (state = initialState, { type, payload }) => {
  switch (type) {
  case SystemConversionActionType.SAVE_SYSTEM_CONVERSIONS:
    return {
      ...state,
      systemConversions: payload.data,
    };
  case SystemConversionActionType.RESET_SYSTEM_CONVERSIONS:
    return {
      ...state,
      systemConversions: [],
    };
  case SystemConversionActionType.SAVE_SYSTEM_CONVERSION_LOGS:
    return {
      ...state,
      systemConversionLogs: payload.data,
    };
  case SystemConversionActionType.RESET_SYSTEM_CONVERSION_LOGS:
    return {
      ...state,
      systemConversionLogs: {},
    };
  case SystemConversionActionType.SET_CURRENT_CONVERSION:
    return {
      ...state,
      currentConversionId: payload.id,
    };
  case SystemConversionActionType.SAVE_SYSTEM_CONVERSIONS_RESOURCE:
    return {
      ...state,
      systemConversionsResource: payload.data,
    };
  case SystemConversionActionType.RESET_SYSTEM_CONVERSIONS_RESOURCE:
    return {
      ...state,
      systemConversionsResource: [],
    };
  default:
    return state;
  }
};

export default systemConversionReducer;
