import LocaleActionType from './localeActionType';

export const getLocaleResources = () => ({
  type: LocaleActionType.GET_LOCALE_RESOURCES,
});

export const saveLocaleResources = (data) => ({
  type: LocaleActionType.SAVE_LOCALE_RESOURCES,
  payload: { data },
});

export const changeLocaleLanguage = (language) => ({
  type: LocaleActionType.CHANGE_LOCALE_LANGUAGE,
  payload: { language },
});
