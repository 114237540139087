import PreviousAccountActionType from 'actions/previousAccount/previousAccountActionType';

export const getPreviousAccountsGroups = (params) => ({
  type: PreviousAccountActionType.GET_PREVIOUS_ACCOUNTS_GROUPS,
  payload: { params },
});

export const savePreviousAccountsGroups = (data) => ({
  type: PreviousAccountActionType.SAVE_PREVIOUS_ACCOUNTS_GROUPS,
  payload: { data },
});

export const resetPreviousAccountsGroups = () => ({
  type: PreviousAccountActionType.RESET_PREVIOUS_ACCOUNTS_GROUPS,
});

export const getPlayerPreviousAccounts = (playerId, params) => ({
  type: PreviousAccountActionType.GET_PLAYER_PREVIOUS_ACCOUNTS,
  payload: { playerId, params },
});

export const savePlayerPreviousAccounts = (data) => ({
  type: PreviousAccountActionType.SAVE_PLAYER_PREVIOUS_ACCOUNTS,
  payload: { data },
});

export const resetPlayerPreviousAccounts = () => ({
  type: PreviousAccountActionType.RESET_PLAYER_PREVIOUS_ACCOUNTS,
});

export const getPlayerPreviousAccountsInfo = (playerId, isLoading) => ({
  type: PreviousAccountActionType.GET_PLAYER_PREVIOUS_ACCOUNTS_INFO,
  payload: { playerId, isLoading },
});

export const savePlayerPreviousAccountsInfo = (data) => ({
  type: PreviousAccountActionType.SAVE_PLAYER_PREVIOUS_ACCOUNTS_INFO,
  payload: { data },
});

export const resetPlayerPreviousAccountsInfo = () => ({
  type: PreviousAccountActionType.RESET_PLAYER_PREVIOUS_ACCOUNTS_INFO,
});
