import PlayerComplaintActionType from './playerComplaintActionType';

export const getPlayerComplaints = (params) => ({
  type: PlayerComplaintActionType.GET_PLAYER_COMPLAINTS,
  payload: { params },
});

export const savePlayerComplaints = (data) => ({
  type: PlayerComplaintActionType.SAVE_PLAYER_COMPLAINTS,
  payload: { data },
});

export const resetPlayerComplaints = () => ({
  type: PlayerComplaintActionType.RESET_PLAYER_COMPLAINTS,
});

export const generatePlayerComplaintsReport = (params) => ({
  type: PlayerComplaintActionType.GENERATE_PLAYER_COMPLAINTS_REPORT,
  payload: { params },
});

export const changePlayerComplaintsStatus = (data) => ({
  type: PlayerComplaintActionType.CHANGE_PLAYER_COMPLAINTS_STATUS,
  payload: { data },
});
