import AuditLogActionType from './auditLogActionType';

export const getUserLogs = (params) => ({
  type: AuditLogActionType.GET_USER_LOGS,
  payload: { params },
});

export const saveUserLogs = (data) => ({
  type: AuditLogActionType.SAVE_USER_LOGS,
  payload: { data },
});

export const resetUserLogs = () => ({
  type: AuditLogActionType.RESET_USER_LOGS,
});

export const getPlayerLogs = (params) => ({
  type: AuditLogActionType.GET_PLAYER_LOGS,
  payload: { params },
});

export const savePlayerLogs = (data) => ({
  type: AuditLogActionType.SAVE_PLAYER_LOGS,
  payload: { data },
});

export const resetPlayerLogs = () => ({
  type: AuditLogActionType.RESET_PLAYER_LOGS,
});

export const getUserManagementLogs = (params) => ({
  type: AuditLogActionType.GET_USER_MANAGEMENT_LOGS,
  payload: { params },
});

export const saveUserManagementLogs = (data) => ({
  type: AuditLogActionType.SAVE_USER_MANAGEMENT_LOGS,
  payload: { data },
});

export const resetUserManagementLogs = () => ({
  type: AuditLogActionType.RESET_USER_MANAGEMENT_LOGS,
});

export const generateUserLogs = (params) => ({
  type: AuditLogActionType.GENERATE_USER_LOGS,
  payload: { params },
});

export const generatePlayerLogs = (params) => ({
  type: AuditLogActionType.GENERATE_PLAYER_LOGS,
  payload: { params },
});

export const generateUserManagementLogs = (params) => ({
  type: AuditLogActionType.GENERATE_USER_MANAGEMENT_LOGS,
  payload: { params },
});
