export default {
  en: {
    lCache: 'cache',
    clean: 'Clean',
    values: 'Values',
    sync: 'Sync',
    syncAll: 'Sync All',
    theTables: 'The tables',
    links: 'Links',
    jobServices: 'Job Services',
    cacheName: 'Cache Name',
    mode: 'Mode',
    userConfigTabInfo: {
      text1: "In this section you can reset a specific key's user configuration.",
      text2: 'Will be helpful to solve the problems related to user configurations in different environments.',
    },
    configKey: 'Config Key',
  },
  es: {
    lCache: 'caché',
    clean: 'Limpiar',
    values: 'Valores',
    sync: 'Sincroniza',
    syncAll: 'Sincroniza Todo',
    theTables: 'Tablas',
    jobServices: 'Servicio de Empleo',
    cacheName: 'Nombre del Caché',
    links: 'Enlaces',
    mode: 'Modo',
    configKey: 'Clave de Configuración',
  },
  tr: {
    lCache: 'önbellek',
    clean: 'Temizle',
    values: 'Değerler',
    sync: 'Senkronize',
    syncAll: 'Tümünü Senkronize Et',
    theTables: 'Tablolar',
    links: 'Bağlantılar',
    jobServices: 'İş Hizmetleri',
    cacheName: 'Önbellek Adı',
    mode: 'Mod',
    configKey: 'Tuşu yapılandırma',
  },
  ru: {
    lCache: 'кэш',
    clean: 'Очистить',
    values: 'Значения',
    sync: 'Синхронизировать',
    syncAll: 'Синхронизировать все',
    theTables: 'Таблицы',
    links: 'Ссылки',
    jobServices: 'Сервис задач',
    cacheName: 'Имя кэша',
    mode: 'Режим',
    configKey: 'Ключ конфигурации',
  },
  pt: {
    lCache: 'cache',
    clean: 'Limpar',
    values: 'Valores',
    sync: 'Sincronizar',
    syncAll: 'Sincronizar tudo',
    theTables: 'Tabelas',
    links: 'Links',
    jobServices: 'Serviços de trabalho',
    cacheName: 'Nome do cache',
    mode: 'Modo',
    configKey: 'Chave de configuração',
  },
  zh: {
    lCache: '缓存',
    clean: '清理',
    values: '值',
    sync: '同步',
    syncAll: '同步所有',
    theTables: '表格',
    links: '链接',
    jobServices: '任务服务',
    cacheName: '缓存名称',
    mode: '模式',
    configKey: '配置密钥',
  },
  ro: {
    lCache: 'cache',
    clean: 'Ștergere',
    values: 'Valori ',
    sync: 'Sincronizare',
    syncAll: 'Sincronizează totul',
    theTables: 'Tabele',
    links: 'Linkuri',
    jobServices: 'Servicii de serviciu',
    cacheName: 'Numele cache-ului',
  },
  bg: {},
  el: {
    lCache: 'cache',
    clean: 'Εκκαθάριση',
    values: 'Τιμές',
    sync: 'Συγχρονισμός',
    syncAll: 'Συγχρονισμός όλων',
    theTables: 'Οι πίνακες',
    links: 'Σύνδεσμοι',
    jobServices: 'Υπηρεσίες εργασίας',
    cacheName: 'Όνομα cache',
    mode: 'Λειτουργία',
  },
};
