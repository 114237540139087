import { createSelector } from 'reselect';
import { selectStaticCollectionCountries, selectStaticCollectionCurrencies, selectStaticCollectionLanguages } from './staticCollectionSelector';

export const selectBrandCurrencies = (state) => state.currencies;
export const selectUnusedCurrency = (state, staticCollectionState) => createSelector(
  () => selectStaticCollectionCurrencies(staticCollectionState),
  () => selectBrandCurrencies(state),
  (allCurrencies, brandCurrencies) => allCurrencies.reduce((result, el) => {
    const current = brandCurrencies.find((elem) => elem.currencyId === el.id);
    if (!current) {
      result.push(el);
    }
    return result;
  }, []),
)();
const selectBrandRestrictions = (state) => state.restrictions;
export const selectBrandBlockedCountriesResource = (state) => selectBrandRestrictions(state).blockedCountriesResource;
export const selectBrandBlockedIPs = (state) => selectBrandRestrictions(state).blockedIPs;
export const selectBrandCountries = (state) => state.countries;
export const selectUnusedCountry = (state, staticCollectionState) => createSelector(
  () => selectStaticCollectionCountries(staticCollectionState),
  () => selectBrandCountries(state),
  () => selectBrandBlockedCountriesResource(state),
  (allCountries, brandCountries, brandBlockedCountries) => allCountries.reduce((result, el) => {
    if (!(brandCountries.find((elem) => elem.countryCode === el.code) || brandBlockedCountries.find((elem) => elem.id === el.code))) {
      result.push(el);
    }
    return result;
  }, []),
)();
export const selectBrandLanguages = (state) => state.languages;
export const selectUnusedLanguage = (state, staticCollectionState) => createSelector(
  () => selectStaticCollectionLanguages(staticCollectionState),
  () => selectBrandLanguages(state),
  (allLanguages, brandLanguages) => allLanguages.reduce((result, el) => {
    const current = brandLanguages.find((elem) => elem.languageId === el.id);
    if (!current) {
      result.push(el);
    }
    return result;
  }, []),
)();
export const selectBrandSettingLoading = (state) => state.loading;
export const selectBrandSettingSuccessStatus = (state) => state.successStatus;
export const selectBrandMainSetting = (state) => state.main;
export const selectBrandMainLanguageId = (state) => selectBrandMainSetting(state).language.id;
export const selectDomains = (state) => state.domains;
export const selectSportDomains = (state) => state.sportDomains;
export const selectBrandSmsSettings = (state) => state.smsSettings;
export const selectBrandGeneralSettings = (state) => state.generalSettings;
export const selectBrandSmtpSettings = (state) => state.smtpSettings;
export const selectBrandCaptchaSettings = (state) => state.captchaSettings;
export const selectBrandActiveLanguages = createSelector(selectBrandLanguages, (languages) => languages.filter((language) => language.isActive));
export const selectBrandActiveCurrencies = createSelector(selectBrandCurrencies, (currencies) => currencies.filter((currency) => currency.isActive));
export const selectBrandActiveCountries = createSelector(selectBrandCountries, (countries) => countries.filter((country) => country.isActive));
export const selectBrandEmailContacts = (state) => state.emailContacts;
const selectBrandConversions = (state) => state.conversions;
export const selectBrandDepositConversions = (state) => selectBrandConversions(state).deposits;
export const selectBrandWithdrawalsConversions = (state) => selectBrandConversions(state).withdrawals;
export const selectBrandOthersConversions = (state) => selectBrandConversions(state).others;
export const selectBrandConversion = (state) => selectBrandConversions(state).conversion;
export const selectBrandConversionLogs = (state) => selectBrandConversions(state).logs;
export const selectBrandStandardSettings = (state) => state.standardSettings;
export const selectBrandSportsBookLayouts = (state) => state.sportsBookLayouts;
export const selectBrandSportsBookLayoutsCallEndedStatus = (state) => state.sportsBookLayoutsEndedStatus;
const selectBrandSportsBookSettings = (state) => state.sportsBookSettings;
export const selectBrandSportsBookIsPrematchTopMatchesEnabled = (state) => selectBrandSportsBookSettings(state).enableSportPrematchTopMatches;
export const selectBrandSportsBookIsPrematchTotalTopMatchesEnabled = (state) => selectBrandSportsBookSettings(state).enableSportPrematchTotalTopMatches;
export const selectBrandSportsBookIsLiveTopMatchesEnabled = (state) => selectBrandSportsBookSettings(state).enableSportLiveTopMatches;
export const selectBrandSportsBookIsLivePrematchTopMatchesEnabled = (state) => selectBrandSportsBookSettings(state).enableSportLivePrematchTopMatches;
export const selectBrandSportsBookIsJackpotEnabled = (state) => selectBrandSportsBookSettings(state).enableSportJackpot;
export const selectBrandSportsBookIsFixedScrollEnabled = (state) => selectBrandSportsBookSettings(state).enableSportFixedScroll;
export const selectBrandSportsBookIsTournamentBalanceEnabled = (state) => selectBrandSportsBookSettings(state).enableSportTournamentBalance;
export const selectBrandSportsBookColorInheritanceEnabled = (state) => selectBrandSportsBookSettings(state).enableSportSiteStyles;
export const selectBrandSportsBookMobileIntegrationType = (state) => selectBrandSportsBookSettings(state).sportIntegrationType;
export const selectBrandPreferencesSettings = (state) => state.preferencesSettings;
export const selectBrandSportsBookWalletsSettings = (state) => state.sportsBookWalletsSettings;
export const selectBrandCasinoProductSettings = (state) => state.casinoProductSettings;
export const selectBrandCasinoProductWalletsSettings = (state) => state.casinoProductWalletsSettings;
export const selectBrandCurrencyFormat = (state) => state.currencyFormat;
export const selectBrandInactivitySettings = (state) => state.inactivity;
export const selectBrandSportStatusSettings = (state) => state.sportStatusSettings;
