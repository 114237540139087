import { lazy } from 'react';
import { types, resource } from 'constants/accessControl';
import { title } from 'constants/tabs/title';
import enumTypes from 'constants/enumTypes';
import Can from 'components/common/Can';
import _ from 'lodash';
import Config from 'config';

const { env } = Config;

export const allRouters = (params = {}) => [
  {
    id: 1,
    name: 'home',
    component: lazy(() => import('pages/Account')),
    icon: 'HomeIcon',
    path: '/',
    key: '/home',
    exact: true,
  },
  {
    menuItem: true,
    id: 2,
    name: 'dashboard',
    showBrand: true,
    showConversion: true,
    component: lazy(() => import('pages/Dashboard')),
    icon: 'DashboardIcon',
    path: '/dashboard',
    key: '/dashboard',
    exact: true,
    breadcrumb: [
      {
        name: 'home',
        path: '/account',
      },
      {
        name: 'dashboard',
        path: '/dashboard',
      },
    ],
    hide: () => Can({
      type: types.manage,
      rule: [
        `${resource.dashboards}.${resource.customersData}`,
        `${resource.dashboards}.${resource.bettingData}`,
        `${resource.dashboards}.${resource.paymentsData}`,
        `${resource.dashboards}.${resource.bonusesData}`,
        `${resource.dashboards}.${resource.financialTrends}`,
        `${resource.dashboards}.${resource.customersData}`,
      ],
      children: false,
      fallback: true,
    }),
  },
  {
    menuItem: true,
    id: 16,
    name: 'brands',
    component: lazy(() => import('pages/Brands')),
    exact: true,
    icon: 'PartnerIcon',
    path: '/brands',
    key: '/brands',
    showPartner: true,
    showTimeZone: true,
    breadcrumb: [
      {
        name: 'home',
        path: '/account',
      },
      {
        name: 'brands',
        path: '/brands',
      },
    ],
    hide: () => Can({
      type: types.view,
      rule: `${resource.brands}.${resource.brands}`,
      children: false,
      fallback: true,
    }),
  },
  {
    menuItem: true,
    id: 3,
    name: 'players',
    component: lazy(() => import('pages/NoMatch')),
    exact: true,
    icon: 'PlayersIcon',
    path: '/allPlayers',
    key: '/allPlayers',
    hide: () => Can({
      type: types.view,
      rule: [`${resource.players}.${resource.players}`, `${resource.players}.${resource.testPlayers}`, `${resource.players}.${resource.kyc}`],
      children: false,
      fallback: true,
    }),
  },
  {
    menuItem: true,
    id: 4,
    name: 'payments',
    component: lazy(() => import('pages/NoMatch')),
    exact: true,
    icon: 'FinancesIcon',
    path: '/payments',
    key: '/payments',
    hide: () => Can({
      type: types.view,
      rule: [`${resource.financials}.${resource.withdrawals}`, `${resource.financials}.${resource.deposits}`],
      children: false,
      fallback: true,
    }),
  },
  {
    menuItem: true,
    id: 5,
    name: 'bets',
    component: lazy(() => import('pages/Bets')),
    exact: true,
    icon: 'MenuBetsIcon',
    path: '/bets',
    key: '/bets',
    showTimeZone: true,
    showBrand: true,
    breadcrumb: [
      {
        name: 'home',
        path: '/account',
      },
      {
        name: 'bets',
        path: '/bets',
      },
    ],
    hide: () => Can({
      type: types.view,
      rule: [`${resource.bets}.${resource.casinoBets}`, `${resource.bets}.${resource.sportBets}`],
      children: false,
      fallback: true,
    }),
  },
  {
    menuItem: true,
    id: 6,
    name: 'bonusManagement',
    component: lazy(() => import('pages/NoMatch')),
    exact: true,
    icon: 'BonusEngineIcon',
    path: '/bonusManagement',
    key: '/bonusManagement',
    hide: () => Can({
      type: types.view,
      rule: [`${resource.bonusManagement}.${resource.bonus}`, `${resource.bonusManagement}.${resource.cashback}`, `${resource.bonusManagement}.${resource.referralBonus}`],
      children: false,
      fallback: true,
    }),
  },
  {
    menuItem: true,
    id: 7,
    name: 'riskManagement',
    component: lazy(() => import('pages/NoMatch')),
    exact: true,
    icon: 'RiskIcon',
    path: '/riskManagement',
    key: '/riskManagement',
    hide: () => Can({
      type: types.view,
      rule: [
        `${resource.riskManagement}.${resource.balanceAdjustment}`,
        `${resource.riskManagement}.${resource.bulkStatusUpdate}`,
        `${resource.riskManagement}.${resource.similarAccounts}`,
        `${resource.riskManagement}.${resource.blacklist}`,
        `${resource.riskManagement}.${resource.previousAccounts}`,
      ],
      children: false,
      fallback: true,
    }),
  },
  {
    menuItem: true,
    id: 101,
    name: 'tools',
    component: lazy(() => import('pages/NoMatch')),
    exact: true,
    icon: 'ToolsIcon',
    path: '/tool',
    key: '/tool',
    hide: () => Can({
      type: types.view,
      rule: [
        `${resource.tools}.${resource.verticalTags}`,
        `${resource.tools}.${resource.horizontalTags}`,
        `${resource.tools}.${resource.brandAutomation}`,
        `${resource.tools}.${resource.gameSegments}`,
        `${resource.tools}.${resource.playerSegments}`,
        `${resource.tools}.${resource.reasonsSystem}`,
        `${resource.tools}.${resource.agSentData}`,
      ],
      children: false,
      fallback: true,
    }),
  },
  {
    id: 182,
    menuItem: true,
    name: 'promotion:promotionTool',
    icon: 'PromotionIcon',
    component: lazy(() => import('pages/Promotions')),
    exact: true,
    path: '/promotions',
    key: '/promotions',
    showTimeZone: true,
    showBrand: true,
    breadcrumb: [
      {
        name: 'home',
        path: '/account',
      },
      {
        name: 'promotion:promotions',
      },
    ],
    hide: () => Can({
      type: types.view,
      rule: `${resource.gamification}.${resource.gamification}`,
      children: false,
      fallback: true,
    }),
  },
  {
    id: 9,
    menuItem: true,
    name: 'crm',
    component: lazy(() => import('pages/NoMatch')),
    exact: true,
    icon: 'CommsIcon',
    path: '/communications',
    key: '/communications',
    hide: () => Can({
      type: types.view,
      rule: [
        `${resource.communications}.${resource.inboxMessages}`,
        `${resource.communications}.${resource.massMessages}`,
        `${resource.communications}.${resource.notificationTemplates}`,
        `${resource.communications}.${resource.systemNotifications}`,
        `${resource.communications}.${resource.pushNotifications}`,
        `${resource.communications}.${resource.playerComplaints}`,
      ],
      children: false,
      fallback: true,
    }),
  },
  {
    id: 106,
    menuItem: true,
    menuParentId: 9,
    showBrand: true,
    name: 'massMessages',
    component: lazy(() => import('pages/MassMessages')),
    exact: true,
    path: '/massMessages',
    key: '/massMessages',
    showTimeZone: true,
    breadcrumb: [
      {
        name: 'home',
        path: '/account',
      },
      {
        name: 'massMessages',
        path: '/massMessages',
      },
    ],
    hide: () => Can({
      type: types.view,
      rule: `${resource.communications}.${resource.massMessages}`,
      children: false,
      fallback: true,
    }),
  },
  {
    menuItem: true,
    id: 11,
    menuParentId: 9,
    name: 'inboxMessages',
    component: lazy(() => import('pages/InboxMessages')),
    exact: true,
    path: '/inboxMessages',
    key: '/inboxMessages',
    showBrand: true,
    showTimeZone: true,
    breadcrumb: [
      {
        name: 'home',
        path: '/account',
      },
      {
        name: 'inboxMessages',
      },
    ],
    hide: () => Can({
      type: types.view,
      rule: `${resource.communications}.${resource.inboxMessages}`,
      children: false,
      fallback: true,
    }),
  },
  {
    menuItem: true,
    id: 13,
    name: 'cms',
    component: lazy(() => import('pages/NoMatch')),
    exact: true,
    icon: 'BuilderIcon',
    path: '/CMS',
    key: '/CMS',
    hide: () => Can({
      type: types.view,
      rule: [
        `${resource.contentManagement}.${resource.fileManager}`,
        `${resource.contentManagement}.${resource.translations}`,
        `${resource.contentManagement}.${resource.cashierInventory}`,
        `${resource.contentManagement}.${resource.cashierSettings}`,
        `${resource.contentManagement}.${resource.cashierDepositAndWithdrawal}`,
        `${resource.contentManagement}.${resource.groups}`,
        `${resource.contentManagement}.${resource.games}`,
        `${resource.contentManagement}.${resource.lobbyManagement}`,
        `${resource.contentManagement}.${resource.gameTypes}`,
      ],
      children: false,
      fallback: true,
    }),
  },
  {
    menuItem: true,
    id: 15,
    name: 'reporting',
    component: lazy(() => import('pages/NoMatch')),
    exact: true,
    icon: 'ReportingIcon',
    path: '/reporting',
    key: '/reporting',
    hide: () => Can({
      type: types.view,
      rule: [
        `${resource.playersReports}.${resource.closedAccounts}`,
        `${resource.playersReports}.${resource.playersTotals}`,
        `${resource.financialReports}.${resource.balanceAdjustments}`,
        `${resource.compliance}.${resource.largeTransaction}`,
        `${resource.compliance}.${resource.negativeBalance}`,
        `${resource.businessIntelligence}.${resource.betAggregations}`,
        `${resource.businessIntelligence}.${resource.paymentAggregations}`,
        `${resource.providers}.${resource.providersReport}`,
        `${resource.bonusesReport}.${resource.bonusesReport}`,
        `${resource.playersReports}.${resource.inactiveAccounts}`,
        `${resource.playersReports}.${resource.topPlayers}`,
        `${resource.deviceSessions}.${resource.deviceSessions}`,
      ],
      children: false,
      fallback: true,
    }),
  },
  {
    menuItem: true,
    id: 198,
    name: 'regulatory',
    component: lazy(() => import('pages/NoMatch')),
    exact: true,
    icon: 'RegulatoryIcon',
    path: '/regulatory',
    key: '/regulatory',
    hide: () => Can({
      type: types.view,
      rule: [
        `${resource.cypriotRegulatory}.${resource.taxation}`,
        `${resource.romanianRegulatory}.${resource.romanianRegulatory}`,
        `${resource.cypriotRegulatory}.${resource.bettingAndSelfProtection}`,
        `${resource.europeanRegulatory}.${resource.europeanRegulatory}`,
      ],
      children: false,
      fallback: true,
    }),
  },
  {
    menuItem: true,
    id: 199,
    menuParentId: 198,
    name: 'cypriot',
    component: lazy(() => import('pages/CypriotReports')),
    exact: true,
    path: '/cypriot',
    key: '/cypriot',
    showBrand: true,
    showTimeZone: true,
    breadcrumb: [
      {
        name: 'home',
        path: '/account',
      },
      {
        name: 'cypriot',
      },
    ],
    hide: () => Can({
      type: types.view,
      rule: [`${resource.cypriotRegulatory}.${resource.taxation}`, `${resource.cypriotRegulatory}.${resource.bettingAndSelfProtection}`],
      children: false,
      fallback: true,
    }),
  },
  {
    menuItem: true,
    id: 17,
    name: 'partners',
    component: lazy(() => import('pages/Partners')),
    exact: true,
    icon: 'BrandIcon',
    path: '/partners',
    key: '/partners',
    showTimeZone: true,
    breadcrumb: [
      {
        name: 'home',
        path: '/account',
      },
      {
        name: 'partners',
        path: '/partners',
      },
    ],
    hide: () => _.isEmpty(params.partnerAccessIds)
      || Can({
        type: types.view,
        rule: `${resource.partners}.${resource.partners}`,
        children: false,
        fallback: true,
      }),
  },
  {
    menuItem: true,
    id: 18,
    name: 'userManagement',
    component: lazy(() => import('pages/NoMatch')),
    exact: true,
    icon: 'UserManagementIcon',
    path: '/userManagement',
    key: '/userManagement',
    hide: () => _.isEmpty(params.partnerAccessIds)
      || Can({
        type: types.view,
        rule: [
          `${resource.userManagement}.${resource.permissions}`,
          `${resource.userManagement}.${resource.permissionGroups}`,
          `${resource.userManagement}.${resource.users}`,
          `${resource.userManagement}.${resource.ipWhitelist}`,
        ],
        children: false,
        fallback: true,
      }),
  },
  {
    menuItem: true,
    id: 107,
    name: 'auditLog',
    component: lazy(() => import('pages/AuditLogs')),
    exact: true,
    icon: 'AuditLogIcon',
    path: '/auditLogs',
    key: '/auditLogs',
    showTimeZone: true,
    breadcrumb: [
      {
        name: 'home',
        path: '/account',
      },
      {
        name: 'auditLogs',
      },
    ],
    hide: () => Can({
      type: types.view,
      rule: [`${resource.auditLogs}.${resource.userLogs}`, `${resource.auditLogs}.${resource.playerLogs}`, `${resource.auditLogs}.${resource.userManagementLogs}`],
      children: false,
      fallback: true,
    }),
  },
  {
    id: 176,
    menuItem: true,
    name: 'developmentTools',
    component: lazy(() => import('pages/DevTools')),
    exact: true,
    icon: 'DevToolsIcon',
    path: '/devTools',
    key: '/devTools',
    breadcrumb: [
      {
        name: 'home',
        path: '/account',
      },
      {
        name: 'developmentTools',
      },
    ],
    hide: () => Can({
      claims: [enumTypes.userClaimTypes.Developer],
      children: false,
      fallback: true,
    }),
  },
  {
    id: 177,
    name: 'systemCacheData',
    component: lazy(() => import('pages/SystemCacheData')),
    exact: true,
    path: '/devTools/systemCashes/:name',
    key: '/devTools/systemCaches/:name',
    breadcrumb: [
      {
        name: 'home',
        path: '/account',
      },
      {
        name: 'developmentTools',
        path: `/devTools?tab=${title.devTools.systemCaches}`,
      },
      {
        key: 'name',
      },
    ],
    hide: () => Can({
      claims: [enumTypes.userClaimTypes.Developer],
      children: false,
      fallback: true,
    }),
  },
  {
    menuItem: true,
    id: 19,
    name: 'systemSettings',
    component: lazy(() => import('pages/NoMatch')),
    exact: true,
    icon: 'ProductsIcon',
    path: '/systemSettings',
    key: '/systemSettings',
    hide: () => Can({
      type: types.view,
      rule: [`${resource.systemSettings}.${resource.systemConversion}`],
      children: false,
      fallback: true,
    })
      && Can({
        type: types.manage,
        rule: `${resource.systemSettings}.${resource.releaseNotes}`,
        children: false,
        fallback: true,
      }),
  },
  {
    id: 23,
    component: lazy(() => import('pages/Player')),
    exact: true,
    menuParentId: 81,
    path: '/players/:playerId/*',
    key: '/players/:playerId/*',
    showTimeZone: true,
    breadcrumb: [
      {
        name: 'home',
        path: '/account',
      },
      {
        name: 'players',
        path: '/players',
      },
      {
        key: 'name',
      },
    ],
    hide: () => Can({
      type: types.view,
      rule: `${resource.players}.${resource.players}`,
      children: false,
      fallback: true,
    }),
  },
  {
    id: 24,
    component: lazy(() => import('pages/CreatePartner')),
    exact: true,
    menuParentId: 17,
    showTimeZone: { viewMode: true },
    path: '/partners/create',
    key: '/partners/create',
    breadcrumb: [
      {
        name: 'home',
        path: '/account',
      },
      {
        name: 'partners',
        path: '/partners',
      },
      {
        name: 'create',
        path: '/partners/create',
      },
    ],
    hide: () => Can({
      type: types.add,
      rule: `${resource.partners}.${resource.partners}`,
      children: false,
      fallback: true,
    }),
  },
  {
    id: 25,
    component: lazy(() => import('pages/EditPartner')),
    exact: true,
    menuParentId: 17,
    showTimeZone: { viewMode: true },
    path: '/partners/:id',
    key: '/partners/:id',
    breadcrumb: [
      {
        name: 'home',
        path: '/account',
      },
      {
        name: 'partners',
        path: '/partners',
      },
      {
        key: 'name',
      },
    ],
    hide: () => Can({
      type: types.edit,
      rule: `${resource.partners}.${resource.partners}`,
      children: false,
      fallback: true,
    }),
  },
  {
    id: 26,
    menuParentId: 18,
    menuItem: true,
    name: 'users',
    component: lazy(() => import('pages/Users')),
    exact: true,
    path: '/users',
    key: '/users',
    showPartner: { includeMainPartner: true },
    showTimeZone: true,
    breadcrumb: [
      {
        name: 'home',
        path: '/account',
      },
      {
        name: 'users',
        path: '/users',
      },
    ],
    hide: () => _.isEmpty(params.partnerAccessIds)
      || Can({
        type: types.view,
        rule: `${resource.userManagement}.${resource.users}`,
        children: false,
        fallback: true,
      }),
  },
  {
    id: 27,
    menuParentId: 18,
    menuItem: true,
    name: 'permissionGroups',
    component: lazy(() => import('pages/PermissionGroups')),
    exact: true,
    path: '/permissionGroups',
    key: '/permissionGroups',
    showPartner: { includeMainPartner: true },
    showTimeZone: true,
    breadcrumb: [
      {
        name: 'home',
        path: '/account',
      },
      {
        name: 'permissionGroups',
        path: '/permissionGroups',
      },
    ],
    hide: () => _.isEmpty(params.partnerAccessIds)
      || Can({
        type: types.view,
        rule: `${resource.userManagement}.${resource.permissionGroups}`,
        children: false,
        fallback: true,
      }),
  },
  {
    id: 28,
    menuParentId: 18,
    menuItem: true,
    name: 'permissions',
    component: lazy(() => import('pages/Permissions')),
    exact: true,
    path: '/permissions',
    key: '/permissions',
    breadcrumb: [
      {
        name: 'home',
        path: '/account',
      },
      {
        name: 'permissions',
      },
    ],
    hide: () => _.isEmpty(params.partnerAccessIds)
      || Can({
        type: types.view,
        rule: `${resource.userManagement}.${resource.permissions}`,
        children: false,
        fallback: true,
      }),
  },
  {
    id: 29,
    name: 'Permission Group Edit',
    menuParentId: 27,
    component: lazy(() => import('pages/EditPermissionGroup')),
    exact: true,
    path: '/permissionGroups/:id/edit',
    key: '/permissionGroups/:id/edit',
    showPartner: { viewMode: true, includeMainPartner: true },
    breadcrumb: [
      {
        name: 'home',
        path: '/account',
      },
      {
        name: 'permissionGroups',
        path: '/permissionGroups',
      },
      {
        key: 'name',
      },
    ],
    hide: () => Can({
      type: types.edit,
      rule: `${resource.userManagement}.${resource.permissionGroups}`,
      children: false,
      fallback: true,
    }),
  },
  {
    id: 30,
    menuParentId: 27,
    name: 'View Permission Group',
    component: lazy(() => import('pages/ViewPermissionGroup')),
    exact: true,
    path: '/permissionGroups/:id/view',
    key: '/permissionGroups/:id/view',
    showPartner: { viewMode: true, includeMainPartner: true },
    breadcrumb: [
      {
        name: 'home',
        path: '/account',
      },
      {
        name: 'permissionGroups',
        path: '/permissionGroups',
      },
      {
        key: 'name',
      },
    ],
    hide: () => Can({
      type: types.view,
      rule: `${resource.userManagement}.${resource.permissionGroups}`,
      children: false,
      fallback: true,
    }),
  },
  {
    id: 31,
    name: 'User Account',
    component: lazy(() => import('pages/Account')),
    exact: true,
    path: '/account',
    key: '/account',
  },
  {
    id: 32,
    name: 'Compose',
    menuParentId: 106,
    component: lazy(() => import('pages/Compose')),
    exact: true,
    path: '/massMessages/compose',
    key: '/compose',
    showTimeZone: { viewMode: true },
    showBrand: { viewMode: true },
    breadcrumb: [
      {
        name: 'home',
        path: '/account',
      },
      {
        name: 'massMessages',
        path: '/massMessages',
      },
      {
        name: 'composeOptions',
        path: '/massMessages/chooseCompose',
      },
      {
        name: 'compose',
      },
    ],
    hide: () => Can({
      type: types.add,
      rule: `${resource.communications}.${resource.massMessages}`,
      children: false,
      fallback: true,
    }),
  },
  {
    id: 171,
    name: 'Compose',
    menuParentId: 106,
    component: lazy(() => import('pages/Compose')),
    exact: true,
    path: '/massMessages/compose/templates/:templateId',
    key: '/compose',
    showTimeZone: { viewMode: true },
    showBrand: { viewMode: true },
    breadcrumb: [
      {
        name: 'home',
        path: '/account',
      },
      {
        name: 'massMessages',
        path: '/massMessages',
      },
      {
        name: 'composeOptions',
        path: '/massMessages/chooseCompose',
      },
      {
        name: 'compose',
      },
    ],
    hide: () => Can({
      type: types.add,
      rule: `${resource.communications}.${resource.massMessages}`,
      children: false,
      fallback: true,
    }),
  },
  {
    id: 33,
    menuParentId: 13,
    menuItem: true,
    name: 'translations',
    component: lazy(() => import('pages/Translations')),
    exact: true,
    path: '/translations',
    key: '/translations',
    showBrand: true,
    breadcrumb: [
      {
        name: 'home',
        path: '/account',
      },
      {
        name: 'translations',
        path: '/translations',
      },
    ],
    hide: () => Can({
      type: types.view,
      rule: `${resource.contentManagement}.${resource.translations}`,
      children: false,
      fallback: true,
    }),
  },
  {
    id: 34,
    menuParentId: 9,
    menuItem: true,
    name: 'systemNotifications',
    component: lazy(() => import('pages/SystemNotifications')),
    exact: true,
    path: '/systemNotifications',
    key: '/systemNotifications',
    showBrand: true,
    showTimeZone: true,
    breadcrumb: [
      {
        name: 'home',
        path: '/account',
      },
      {
        name: 'systemNotifications',
      },
    ],
    hide: () => Can({
      type: types.view,
      rule: `${resource.communications}.${resource.systemNotifications}`,
      children: false,
      fallback: true,
    }),
  },
  {
    id: 35,
    name: 'CreateCustomTemplate',
    component: lazy(() => import('pages/CreateEditNotificationTemplate')),
    exact: true,
    menuParentId: 69,
    path: '/notificationTemplates/create',
    key: '/notificationTemplates/create',
    showBrand: { viewMode: true },
    showTimeZone: { viewMode: true },
    breadcrumb: [
      {
        name: 'home',
        path: '/account',
      },
      {
        name: 'notificationTemplates',
        path: '/notificationTemplates',
      },
      {
        name: 'create',
      },
    ],
    hide: () => Can({
      type: types.add,
      rule: `${resource.communications}.${resource.notificationTemplates}`,
      children: false,
      fallback: true,
    }),
  },
  {
    id: 36,
    name: 'EditCustomTemplate',
    menuParentId: 69,
    component: lazy(() => import('pages/CreateEditNotificationTemplate')),
    exact: true,
    path: '/notificationTemplates/:templateId/edit',
    key: '/notificationTemplates/:templateId/edit',
    showTimeZone: { viewMode: true },
    showBrand: { viewMode: true },
    breadcrumb: [
      {
        name: 'home',
        path: '/account',
      },
      {
        name: 'notificationTemplates',
        path: '/notificationTemplates',
      },
      {
        key: 'name',
      },
    ],
    hide: () => Can({
      type: types.edit,
      rule: `${resource.communications}.${resource.notificationTemplates}`,
      children: false,
      fallback: true,
    }),
  },
  {
    id: 37,
    menuParentId: 34,
    name: 'View System Notification',
    component: lazy(() => import('pages/ViewTemplate')),
    exact: true,
    showTimeZone: { viewMode: true },
    showBrand: { viewMode: true },
    path: '/systemNotifications/:systemTemplateId',
    key: '/systemNotifications/:systemTemplateId',
    breadcrumb: [
      {
        name: 'home',
        path: '/account',
      },
      {
        name: 'systemNotifications',
        path: '/systemNotifications',
      },
      {
        key: 'name',
      },
    ],
    hide: () => Can({
      type: types.view,
      rule: `${resource.communications}.${resource.systemNotifications}`,
      children: false,
      fallback: true,
    }),
  },
  {
    id: 38,
    name: 'ComposeTemplates',
    menuParentId: 106,
    showTimeZone: { viewMode: true },
    showBrand: { viewMode: true },
    component: lazy(() => import('pages/ComposeTemplates')),
    exact: true,
    path: '/massMessages/chooseCompose',
    key: '/massMessages/chooseCompose',
    breadcrumb: [
      {
        name: 'home',
        path: '/account',
      },
      {
        name: 'massMessages',
        path: '/massMessages',
      },
      {
        name: 'composeOptions',
      },
    ],
    hide: () => Can({
      type: types.add,
      rule: `${resource.communications}.${resource.massMessages}`,
      children: false,
      fallback: true,
    }),
  },
  {
    id: 40,
    name: 'EditSystemTemplate',
    menuParentId: 34,
    component: lazy(() => import('pages/EditSystemTemplate')),
    exact: true,
    path: '/systemNotifications/:templateId/edit',
    key: '/systemNotifications/:templateId/edit',
    showBrand: { viewMode: true },
    showTimeZone: { viewMode: true },
    breadcrumb: [
      {
        name: 'home',
        path: '/account',
      },
      {
        name: 'systemNotifications',
        path: '/systemNotifications',
      },
      {
        key: 'name',
      },
    ],
    hide: () => Can({
      type: types.edit,
      rule: `${resource.communications}.${resource.systemNotifications}`,
      children: false,
      fallback: true,
    }),
  },
  {
    id: 41,
    menuParentId: 13,
    menuItem: true,
    name: 'fileManager',
    component: lazy(() => import('pages/FileManager')),
    exact: true,
    path: '/fileManager',
    key: '/fileManager',
    showBrand: true,
    breadcrumb: [
      {
        name: 'home',
        path: '/account',
      },
      {
        name: 'fileManager',
      },
    ],
    hide: () => Can({
      type: types.view,
      rule: `${resource.contentManagement}.${resource.fileManager}`,
      children: false,
      fallback: true,
    }),
  },
  {
    id: 163,
    menuParentId: 6,
    name: 'campaigns',
    menuItem: true,
    showBrand: true,
    component: lazy(() => import('pages/Campaigns')),
    exact: true,
    path: '/campaigns',
    key: '/campaigns',
    showTimeZone: true,
    breadcrumb: [
      {
        name: 'home',
        path: '/account',
      },
      {
        name: 'campaigns',
      },
    ],
    hide: () => Can({
      type: types.view,
      rule: `${resource.bonusManagement}.${resource.bonus}`,
      children: false,
      fallback: true,
    }),
  },
  {
    id: 42,
    menuParentId: 6,
    menuItem: true,
    name: 'bonuses',
    component: lazy(() => import('pages/Bonuses')),
    exact: true,
    path: '/bonuses',
    key: '/bonuses',
    showBrand: true,
    showTimeZone: true,
    breadcrumb: [
      {
        name: 'home',
        path: '/account',
      },
      {
        name: 'bonuses',
      },
    ],
    hide: () => Can({
      type: types.view,
      rule: `${resource.bonusManagement}.${resource.bonus}`,
      children: false,
      fallback: true,
    }),
  },
  {
    id: 45,
    menuParentId: 6,
    menuItem: true,
    name: 'cashback',
    component: lazy(() => import('pages/CashBack')),
    exact: true,
    showBrand: true,
    path: '/cashbacks',
    key: '/cashbacks',
    showTimeZone: true,
    breadcrumb: [
      {
        name: 'home',
        path: '/account',
      },
      {
        name: 'cashback',
      },
    ],
    hide: () => Can({
      type: types.view,
      rule: `${resource.bonusManagement}.${resource.cashback}`,
      children: false,
      fallback: true,
    }),
  },
  {
    id: 46,
    menuParentId: 6,
    menuItem: true,
    name: 'promoCodes',
    component: lazy(() => import('pages/PromoCodes')),
    exact: true,
    showBrand: true,
    path: '/promoCodes',
    key: '/promoCodes',
    breadcrumb: [
      {
        name: 'home',
        path: '/account',
      },
      {
        name: 'promoCodes',
      },
    ],
    hide: () => Can({
      type: types.view,
      rule: `${resource.bonusManagement}.${resource.bonus}`,
      children: false,
      fallback: true,
    }),
  },
  {
    id: 47,
    menuParentId: 6,
    menuItem: true,
    name: 'referralBonus',
    component: lazy(() => import('pages/ReferralManagement')),
    exact: true,
    showBrand: true,
    path: '/referrals',
    key: '/referrals',
    showTimeZone: true,
    breadcrumb: [
      {
        name: 'home',
        path: '/account',
      },
      {
        name: 'referralBonus',
      },
    ],
    hide: () => Can({
      type: types.view,
      rule: `${resource.bonusManagement}.${resource.referralBonus}`,
      children: false,
      fallback: true,
    }),
  },
  {
    id: 51,
    name: 'Games in Segment',
    menuParentId: 52,
    component: lazy(() => import('pages/SegmentGames')),
    exact: true,
    path: '/gameSegments/:id/games',
    key: '/gameSegments/:id/games',
    showBrand: { viewMode: true },
    showTimeZone: true,
    breadcrumb: [
      {
        name: 'home',
        path: '/account',
      },
      {
        name: 'gameSegments',
        path: '/gameSegments',
      },
      {
        key: 'name',
      },
    ],
    hide: () => Can({
      type: types.view,
      rule: `${resource.tools}.${resource.gameSegments}`,
      children: false,
      fallback: true,
    }),
  },
  {
    id: 53,
    name: 'playersInSegment',
    menuParentId: 50,
    component: lazy(() => import('pages/SegmentPlayers')),
    exact: true,
    path: '/playerSegments/:id/players',
    key: '/playerSegments/:id/players',
    showBrand: { viewMode: true },
    showTimeZone: true,
    breadcrumb: [
      {
        name: 'home',
        path: '/account',
      },
      {
        name: 'playerSegments',
        path: '/playerSegments',
      },
      {
        key: 'name',
      },
    ],
    hide: () => Can({
      type: types.view,
      rule: `${resource.tools}.${resource.playerSegments}`,
      children: false,
      fallback: true,
    }),
  },
  {
    id: 58,
    name: 'UsedPromoCodes',
    menuParentId: 46,
    component: lazy(() => import('pages/UsedPromoCodes')),
    exact: true,
    showBrand: { viewMode: true },
    path: '/promoCodes/:id/used/:type',
    key: '/promoCodes/:id/used/:type',
    showTimeZone: true,
    breadcrumb: [
      {
        name: 'home',
        path: '/account',
      },
      {
        name: 'promoCodes',
        path: '/promoCodes',
      },
      {
        key: 'name',
      },
    ],
    hide: () => Can({
      type: types.view,
      rule: `${resource.bonusManagement}.${resource.bonus}`,
      children: false,
      fallback: true,
    }),
  },
  {
    id: 59,
    name: 'ViewMassMessage',
    menuParentId: 106,
    component: lazy(() => import('pages/ViewMessage')),
    exact: true,
    showBrand: { viewMode: true },
    path: '/massMessages/:messageId',
    key: '/massMessages/:messageId',
    breadcrumb: [
      {
        name: 'home',
        path: '/account',
      },
      {
        name: 'massMessages',
        path: '/massMessages',
      },
      {
        key: 'name',
      },
    ],
    hide: () => Can({
      type: types.view,
      rule: `${resource.communications}.${resource.massMessages}`,
      children: false,
      fallback: true,
    }),
  },
  {
    id: 61,
    name: 'Bonus Statistics',
    menuParentId: 42,
    component: lazy(() => import('pages/BonusStatistics')),
    exact: true,
    path: '/bonuses/:bonusId/:bonusType/statistics',
    key: '/bonuses/:bonusId/:bonusType/statistics',
    showBrand: { viewMode: true },
    showTimeZone: true,
    breadcrumb: [
      {
        name: 'home',
        path: '/account',
      },
      {
        name: 'bonuses',
        path: '/bonuses',
      },
      {
        key: 'name',
      },
    ],
    hide: () => Can({
      type: types.view,
      rule: `${resource.bonusManagement}.${resource.bonus}`,
      children: false,
      fallback: true,
    }),
  },
  {
    id: 63,
    menuItem: true,
    menuParentId: 7,
    name: 'similarAccounts',
    component: lazy(() => import('pages/SimilarAccountsGroups')),
    exact: true,
    path: '/similarAccounts',
    key: '/similarAccounts',
    showTimeZone: true,
    breadcrumb: [
      {
        name: 'home',
        path: '/account',
      },
      {
        name: 'similarAccounts',
      },
    ],
    hide: () => Can({
      type: types.view,
      rule: `${resource.riskManagement}.${resource.similarAccounts}`,
      children: false,
      fallback: true,
    }),
  },
  {
    id: 160,
    menuParentId: 63,
    name: 'similarAccounts',
    component: lazy(() => import('pages/SimilarAccounts')),
    exact: true,
    path: '/similarAccounts/:key',
    key: '/similarAccounts/:key',
    showTimeZone: true,
    breadcrumb: [
      {
        name: 'home',
        path: '/account',
      },
      {
        name: 'similarAccounts',
        path: '/similarAccounts',
      },
      {
        key: 'name',
      },
    ],
    hide: () => Can({
      type: types.view,
      rule: `${resource.riskManagement}.${resource.similarAccounts}`,
      children: false,
      fallback: true,
    }),
  },
  {
    menuItem: true,
    id: 64,
    menuParentId: 7,
    name: 'bulkActions',
    component: lazy(() => import('pages/BulkActions')),
    exact: true,
    path: '/bulkActions',
    key: '/bulkActions',
    showBrand: true,
    showTimeZone: true,
    breadcrumb: [
      {
        name: 'home',
        path: '/account',
      },
      {
        name: 'bulkActions',
      },
    ],
    hide: () => Can({
      type: types.view,
      rule: [
        `${resource.riskManagement}.${resource.balanceAdjustment}`,
        `${resource.riskManagement}.${resource.bulkStatusUpdate}`,
        `${resource.riskManagement}.${resource.bulkBlockProvider}`,
        `${resource.riskManagement}.${resource.bulkBlockAccess}`,
      ],
      children: false,
      fallback: true,
    })
      && Can({
        type: types.manage,
        rule: `${resource.gamification}.${resource.gamification}`,
        children: false,
        fallback: true,
      }),
  },
  {
    id: 65,
    component: lazy(() => import('pages/StatusUpdatePlayerReport')),
    exact: true,
    menuParentId: 64,
    showTimeZone: { viewMode: true },
    showBrand: { viewMode: true },
    path: '/bulkActions/statusUpdates/:id/players',
    key: '/bulkActions/statusUpdates/:id/players',
    breadcrumb: [
      {
        name: 'home',
        path: '/account',
      },
      {
        name: 'bulkActions',
        path: `/bulkActions?tab=${title.bulkActions.statusUpdates}`,
      },
      {
        name: 'statusUpdate',
      },
    ],
    hide: () => Can({
      type: types.view,
      rule: `${resource.riskManagement}.${resource.bulkStatusUpdate}`,
      children: false,
      fallback: true,
    }),
  },
  {
    id: 66,
    name: 'MassMessage Edit',
    menuParentId: 106,
    component: lazy(() => import('pages/Compose')),
    exact: true,
    path: '/massMessages/:messageId/edit',
    key: '/massMessages/:messageId/edit',
    showTimeZone: { viewMode: true },
    showBrand: { viewMode: true },
    breadcrumb: [
      {
        name: 'home',
        path: '/account',
      },
      {
        name: 'massMessages',
        path: '/massMessages',
      },
      {
        key: 'name',
      },
    ],
    hide: () => Can({
      type: types.edit,
      rule: `${resource.communications}.${resource.massMessages}`,
      children: false,
      fallback: true,
    }),
  },
  {
    id: 67,
    component: lazy(() => import('pages/BalanceAdjustmentTransactionReport')),
    menuParentId: 64,
    exact: true,
    path: '/bulkActions/balanceAdjustments/:id/transactions',
    key: '/bulkActions/balanceAdjustments/:id/transactions',
    breadcrumb: [
      {
        name: 'home',
        path: '/account',
      },
      {
        name: 'bulkActions',
        path: `/bulkActions?tab=${title.bulkActions.balanceAdjustments}`,
      },
      {
        name: 'balanceAdjustments',
      },
    ],
    hide: () => Can({
      type: types.view,
      rule: `${resource.riskManagement}.${resource.balanceAdjustment}`,
      children: false,
      fallback: true,
    }),
  },
  {
    id: 68,
    name: 'MassMessage Recipients',
    menuParentId: 106,
    component: lazy(() => import('pages/MassMessageRecipients')),
    exact: true,
    path: '/massMessages/:messageId/recipients',
    key: '/massMessages/:messageId/recipients',
    showBrand: { viewMode: true },
    breadcrumb: [
      {
        name: 'home',
        path: '/account',
      },
      {
        name: 'massMessages',
        path: '/massMessages',
      },
      {
        key: 'name',
      },
    ],
    hide: () => Can({
      type: types.view,
      rule: `${resource.communications}.${resource.massMessages}`,
      children: false,
      fallback: true,
    }),
  },
  {
    id: 69,
    menuParentId: 9,
    menuItem: true,
    name: 'notificationTemplates',
    component: lazy(() => import('pages/NotificationTemplates')),
    exact: true,
    path: '/notificationTemplates',
    key: '/notificationTemplates',
    showBrand: true,
    showTimeZone: true,
    breadcrumb: [
      {
        name: 'home',
        path: '/account',
      },
      {
        name: 'notificationTemplates',
        path: '/notificationTemplates',
      },
    ],
    hide: () => Can({
      type: types.view,
      rule: `${resource.communications}.${resource.notificationTemplates}`,
      children: false,
      fallback: true,
    }),
  },
  {
    id: 70,
    name: 'View Custom Template',
    menuParentId: 69,
    component: lazy(() => import('pages/ViewTemplate')),
    exact: true,
    showTimeZone: { viewMode: true },
    showBrand: { viewMode: true },
    path: '/notificationTemplates/:customTemplateId',
    key: '/notificationTemplates/:customTemplateId',
    breadcrumb: [
      {
        name: 'home',
        path: '/account',
      },
      {
        name: 'notificationTemplates',
        path: '/notificationTemplates',
      },
      {
        key: 'name',
      },
    ],
  },
  {
    id: 77,
    menuParentId: 45,
    name: 'Cashback Statistics',
    component: lazy(() => import('pages/CashBackStatistics')),
    exact: true,
    showBrand: { viewMode: true },
    path: '/cashbacks/:cashbackId/:calculationFrom/statistics',
    key: '/cashbacks/:cashbackId/:calculationFrom/statistics',
    showTimeZone: true,
    breadcrumb: [
      {
        name: 'home',
        path: '/account',
      },
      {
        name: 'cashback',
        path: '/cashbacks',
      },
      {
        key: 'name',
      },
    ],
    hide: () => Can({
      type: types.view,
      rule: `${resource.bonusManagement}.${resource.cashback}`,
      children: false,
      fallback: true,
    }),
  },
  {
    id: 78,
    menuParentId: 45,
    name: 'Cashback Players',
    component: lazy(() => import('pages/CashBackPlayers')),
    exact: true,
    showBrand: { viewMode: true },
    path: '/cashbacks/:cashbackId/players/:status',
    key: '/cashbacks/:cashbackId/players/:status',
    showTimeZone: true,
    breadcrumb: [
      {
        name: 'home',
        path: '/account',
      },
      {
        name: 'cashback',
        path: '/cashbacks',
      },
      {
        key: 'name',
      },
    ],
    hide: () => Can({
      type: types.view,
      rule: `${resource.bonusManagement}.${resource.cashback}`,
      children: false,
      fallback: true,
    }),
  },
  {
    menuItem: true,
    id: 81,
    name: 'players',
    menuParentId: 3,
    component: lazy(() => import('pages/Players')),
    exact: true,
    path: '/players',
    key: '/players',
    showBrand: true,
    showTimeZone: true,
    breadcrumb: [
      {
        name: 'home',
        path: '/account',
      },
      {
        name: 'players',
      },
    ],
    hide: () => Can({
      type: types.view,
      rule: `${resource.players}.${resource.players}`,
      children: false,
      fallback: true,
    }),
  },
  {
    menuItem: true,
    id: 82,
    name: 'testPlayers',
    menuParentId: 3,
    component: lazy(() => import('pages/TestPlayers')),
    exact: true,
    path: '/testPlayers',
    key: '/testPlayers',
    showBrand: true,
    showTimeZone: true,
    breadcrumb: [
      {
        name: 'home',
        path: '/account',
      },
      {
        name: 'testPlayers',
      },
    ],
    hide: () => Can({
      type: types.view,
      rule: `${resource.players}.${resource.testPlayers}`,
      children: false,
      fallback: true,
    }),
  },
  {
    id: 83,
    name: 'Test players',
    menuParentId: 82,
    component: lazy(() => import('pages/TestPlayerUsers')),
    exact: true,
    path: '/testPlayers/:playerId/users',
    key: '/testPlayers/:playerId/users',
    showBrand: true,
    showTimeZone: true,
    breadcrumb: [
      {
        name: 'home',
        path: '/account',
      },
      {
        name: 'testPlayers',
        path: '/testPlayers',
      },
      {
        key: 'name',
      },
    ],
    hide: () => Can({
      type: types.view,
      rule: `${resource.players}.${resource.testPlayers}`,
      children: false,
      fallback: true,
    }),
  },
  {
    id: 84,
    menuParentId: 82,
    component: lazy(() => import('pages/Player')),
    exact: true,
    path: '/testPlayers/:playerId',
    key: '/testPlayers/:playerId',
    showTimeZone: true,
    breadcrumb: [
      {
        name: 'home',
        path: '/account',
      },
      {
        name: 'testPlayers',
        path: '/testPlayers',
      },
      {
        key: 'name',
      },
    ],
    hide: () => Can({
      type: types.view,
      rule: `${resource.players}.${resource.players}`,
      children: false,
      fallback: true,
    }),
  },
  {
    id: 85,
    menuParentId: 47,
    name: 'Create Referral',
    component: lazy(() => import('pages/CreateEditReferralManagement')),
    exact: true,
    path: '/referrals/create',
    key: '/referrals/create',
    showTimeZone: { viewMode: true },
    showBrand: { viewMode: true },
    breadcrumb: [
      {
        name: 'home',
        path: '/account',
      },
      {
        name: 'referralBonus',
        path: '/referrals',
      },
      {
        name: 'create',
      },
    ],
    hide: () => Can({
      type: types.add,
      rule: `${resource.bonusManagement}.${resource.referralBonus}`,
      children: false,
      fallback: true,
    }),
  },
  {
    id: 86,
    menuParentId: 47,
    name: 'Edit Referral',
    component: lazy(() => import('pages/CreateEditReferralManagement')),
    exact: true,
    showTimeZone: { viewMode: true },
    showBrand: { viewMode: true },
    path: '/referrals/:referralId/edit',
    key: '/referrals/:referralId/edit',
    breadcrumb: [
      {
        name: 'home',
        path: '/account',
      },
      {
        name: 'referralBonus',
        path: '/referrals',
      },
      {
        key: 'name',
      },
    ],
    hide: () => Can({
      type: types.edit,
      rule: `${resource.bonusManagement}.${resource.referralBonus}`,
      children: false,
      fallback: true,
    }),
  },
  {
    id: 87,
    menuParentId: 47,
    name: 'View Referral',
    component: lazy(() => import('pages/ViewReferral')),
    exact: true,
    showTimeZone: { viewMode: true },
    showBrand: { viewMode: true },
    path: '/referrals/:referralId',
    key: '/referrals/:referralId',
    breadcrumb: [
      {
        name: 'home',
        path: '/account',
      },
      {
        name: 'referralBonus',
        path: '/referrals',
      },
      {
        key: 'name',
      },
    ],
    hide: () => Can({
      type: types.view,
      rule: `${resource.bonusManagement}.${resource.referralBonus}`,
      children: false,
      fallback: true,
    }),
  },
  {
    id: 88,
    menuParentId: 47,
    name: 'Referral Statistics',
    component: lazy(() => import('pages/ReferralStatistics')),
    exact: true,
    showBrand: { viewMode: true },
    path: '/referrals/:referralId/statistics',
    key: '/referrals/:referralId/statistics',
    showTimeZone: true,
    breadcrumb: [
      {
        name: 'home',
        path: '/account',
      },
      {
        name: 'referralBonus',
        path: '/referrals',
      },
      {
        key: 'name',
      },
    ],
    hide: () => Can({
      type: types.view,
      rule: `${resource.bonusManagement}.${resource.referralBonus}`,
      children: false,
      fallback: true,
    }),
  },
  {
    id: 89,
    menuParentId: 47,
    name: 'Referral Referees',
    component: lazy(() => import('pages/ReferralReferees')),
    exact: true,
    showBrand: { viewMode: true },
    path: '/referrals/:referralId/:referrerId/:batchId/referees',
    key: '/referrals/:referralId/:referrerId/:batchId/referees',
    breadcrumb: [
      {
        name: 'home',
        path: '/account',
      },
      {
        name: 'referralBonus',
        path: '/referrals',
      },
      {
        key: 'name',
        path: '/referrals/:referralId/statistics',
      },
      {
        name: 'referees',
      },
    ],
    hide: () => Can({
      type: types.view,
      rule: `${resource.bonusManagement}.${resource.referralBonus}`,
      children: false,
      fallback: true,
    }),
  },
  {
    id: 90,
    menuParentId: 47,
    name: 'Referral Participants',
    component: lazy(() => import('pages/ReferralParticipants')),
    exact: true,
    showBrand: { viewMode: true },
    path: '/referrals/:referralId/participants',
    key: '/referrals/:referralId/participants',
    breadcrumb: [
      {
        name: 'home',
        path: '/account',
      },
      {
        name: 'referralBonus',
        path: '/referrals',
      },
      {
        key: 'name',
      },
    ],
    hide: () => Can({
      type: types.view,
      rule: `${resource.bonusManagement}.${resource.referralBonus}`,
      children: false,
      fallback: true,
    }),
  },
  {
    id: 92,
    name: 'NotFoundPage',
    component: lazy(() => import('pages/NoMatch')),
    exact: true,
    path: '/notFound',
    key: '/notFound',
  },
  {
    id: 92,
    menuParentId: 26,
    name: 'Create User',
    component: lazy(() => import('pages/CreateUser')),
    exact: true,
    path: '/users/create',
    key: '/users/create',
    showPartner: { viewMode: true, includeMainPartner: true },
    showTimeZone: { viewMode: true },
    breadcrumb: [
      {
        name: 'home',
        path: '/account',
      },
      {
        name: 'users',
        path: '/users',
      },
      {
        name: 'create',
      },
    ],
    hide: () => Can({
      type: types.add,
      rule: `${resource.userManagement}.${resource.users}`,
      children: false,
      fallback: true,
    }),
  },
  {
    id: 93,
    menuParentId: 26,
    name: 'Edit User',
    component: lazy(() => import('pages/EditUser')),
    exact: true,
    path: '/users/:userId/edit',
    key: '/users/:userId/edit',
    showPartner: { viewMode: true, includeMainPartner: true },
    showTimeZone: { viewMode: true },
    breadcrumb: [
      {
        name: 'home',
        path: '/account',
      },
      {
        name: 'users',
        path: '/users',
      },
      {
        key: 'name',
      },
    ],
    hide: () => Can({
      type: types.edit,
      rule: `${resource.userManagement}.${resource.users}`,
      children: false,
      fallback: true,
    }),
  },
  {
    id: 94,
    menuParentId: 27,
    name: 'Create Permission Group',
    component: lazy(() => import('pages/CreatePermissionGroup')),
    exact: true,
    path: '/permissionGroups/create',
    key: '/permissionGroups/create',
    showPartner: { viewMode: true, includeMainPartner: true },
    showTimeZone: { viewMode: true },
    breadcrumb: [
      {
        name: 'home',
        path: '/account',
      },
      {
        name: 'permissionGroups',
        path: '/permissionGroups',
      },
      {
        name: 'create',
      },
    ],
    hide: () => Can({
      type: types.add,
      rule: `${resource.userManagement}.${resource.permissionGroups}`,
      children: false,
      fallback: true,
    }),
  },
  {
    id: 95,
    menuParentId: 26,
    name: 'Manage Bulk Permissions',
    component: lazy(() => import('pages/BulkManagePermissions')),
    exact: true,
    path: '/users/bulkManagePermissions',
    key: '/users/bulkManagePermissions',
    showPartner: { viewMode: true, includeMainPartner: true },
    breadcrumb: [
      {
        name: 'home',
        path: '/account',
      },
      {
        name: 'users',
        path: '/users',
      },
      {
        name: 'managePermissions',
      },
    ],
    hide: () => Can({
      type: types.manage,
      rule: `${resource.userManagement}.${resource.users}`,
      children: false,
      fallback: true,
    }),
  },
  {
    id: 96,
    menuParentId: 26,
    name: 'Manage Permissions',
    component: lazy(() => import('pages/ManagePermissions')),
    exact: true,
    path: '/users/:userId/managePermissions',
    key: '/users/:userId/managePermissions',
    showPartner: { viewMode: true, includeMainPartner: true },
    showTimeZone: { viewMode: true },
    breadcrumb: [
      {
        name: 'home',
        path: '/account',
      },
      {
        name: 'users',
        path: '/users',
      },
      {
        key: 'name',
      },
    ],
    hide: () => Can({
      type: types.manage,
      rule: `${resource.userManagement}.${resource.users}`,
      children: false,
      fallback: true,
    }),
  },
  {
    id: 124,
    name: 'brandAutomation',
    menuParentId: 101,
    component: lazy(() => import('pages/BrandAutomation')),
    exact: true,
    showBrand: true,
    menuItem: true,
    path: '/brandAutomation',
    key: '/brandAutomation',
    showTimeZone: true,
    breadcrumb: [
      {
        name: 'home',
        path: '/account',
      },
      {
        name: 'brandAutomation',
        path: '/brandAutomation',
      },
    ],
    hide: () => Can({
      type: types.view,
      rule: `${resource.tools}.${resource.brandAutomation}`,
      children: false,
      fallback: true,
    }),
  },
  {
    id: 50,
    menuParentId: 101,
    menuItem: true,
    name: 'playerSegments',
    component: lazy(() => import('pages/PlayerSegments')),
    exact: true,
    path: '/playerSegments',
    key: '/playerSegments',
    showBrand: true,
    showTimeZone: true,
    breadcrumb: [
      {
        name: 'home',
        path: '/account',
      },
      {
        name: 'playerSegments',
      },
    ],
    hide: () => Can({
      type: types.view,
      rule: `${resource.tools}.${resource.playerSegments}`,
      children: false,
      fallback: true,
    }),
  },
  {
    id: 52,
    menuParentId: 101,
    menuItem: true,
    name: 'gameSegments',
    component: lazy(() => import('pages/GameSegments')),
    exact: true,
    path: '/gameSegments',
    key: '/gameSegments',
    showBrand: true,
    showTimeZone: true,
    breadcrumb: [
      {
        name: 'home',
        path: '/account',
      },
      {
        name: 'gameSegments',
      },
    ],
    hide: () => Can({
      type: types.view,
      rule: `${resource.tools}.${resource.gameSegments}`,
      children: false,
      fallback: true,
    }),
  },
  {
    menuItem: true,
    id: 103,
    menuParentId: 7,
    name: 'blacklist',
    component: lazy(() => import('pages/Blacklist')),
    exact: true,
    path: '/blackList',
    key: '/blackList',
    showBrand: true,
    showTimeZone: true,
    breadcrumb: [
      {
        name: 'home',
        path: '/account',
      },
      {
        name: 'blacklist',
      },
    ],
    hide: () => Can({
      type: types.view,
      rule: `${resource.riskManagement}.${resource.blacklist}`,
      children: false,
      fallback: true,
    }),
  },
  {
    id: 108,
    menuItem: true,
    name: 'deposits',
    menuParentId: 4,
    component: lazy(() => import('pages/Deposits')),
    exact: true,
    path: '/deposits',
    key: '/deposits',
    showTimeZone: true,
    showBrand: true,
    breadcrumb: [
      {
        name: 'home',
        path: '/account',
      },
      {
        name: 'deposits',
      },
    ],
    hide: () => Can({
      type: types.view,
      rule: `${resource.financials}.${resource.deposits}`,
      children: false,
      fallback: true,
    }),
  },
  {
    id: 109,
    menuItem: true,
    name: 'withdrawals',
    menuParentId: 4,
    component: lazy(() => import('pages/Withdrawals')),
    exact: true,
    path: '/withdrawals',
    key: '/withdrawals',
    showTimeZone: true,
    showBrand: true,
    breadcrumb: [
      {
        name: 'home',
        path: '/account',
      },
      {
        name: 'withdrawals',
      },
    ],
    hide: () => Can({
      type: types.view,
      rule: `${resource.financials}.${resource.withdrawals}`,
      children: false,
      fallback: true,
    }),
  },
  {
    id: 120,
    menuItem: true,
    name: 'players',
    menuParentId: 15,
    showBrand: true,
    component: lazy(() => import('pages/PlayerReports')),
    exact: true,
    path: '/playerReports',
    key: '/playerReports',
    showTimeZone: true,
    breadcrumb: [
      {
        name: 'home',
        path: '/account',
      },
      {
        name: 'players',
      },
    ],
    hide: () => Can({
      type: types.view,
      rule: [
        `${resource.playersReports}.${resource.closedAccounts}`,
        `${resource.playersReports}.${resource.playersTotals}`,
        `${resource.playersReports}.${resource.inactiveAccounts}`,
        `${resource.playersReports}.${resource.topPlayers}`,
      ],
      children: false,
      fallback: true,
    }),
  },
  {
    id: 140,
    menuItem: true,
    name: 'businessIntelligence',
    menuParentId: 15,
    component: lazy(() => import('pages/BiReports')),
    exact: true,
    path: '/bi',
    key: '/bi',
    showTimeZone: true,
    breadcrumb: [
      {
        name: 'home',
        path: '/account',
      },
      {
        name: 'businessIntelligence',
      },
    ],
    hide: () => Can({
      type: types.view,
      rule: [`${resource.businessIntelligence}.${resource.betAggregations}`, `${resource.businessIntelligence}.${resource.paymentAggregations}`],
      children: false,
      fallback: true,
    }),
  },
  {
    id: 121,
    menuItem: true,
    name: 'compliance',
    menuParentId: 15,
    component: lazy(() => import('pages/ComplianceReports')),
    exact: true,
    showTimeZone: true,
    path: '/compliances',
    key: '/compliances',
    breadcrumb: [
      {
        name: 'home',
        path: '/account',
      },
      {
        name: 'compliance',
      },
    ],
    hide: () => Can({
      type: types.view,
      rule: [`${resource.compliance}.${resource.largeTransaction}`, `${resource.compliance}.${resource.negativeBalance}`],
      children: false,
      fallback: true,
    }),
  },
  {
    id: 207,
    menuItem: true,
    name: 'deviceSessions',
    menuParentId: 15,
    component: lazy(() => import('pages/DeviceSessions')),
    exact: true,
    path: '/deviceSessions',
    key: '/deviceSessions',
    showBrand: true,
    showTimeZone: true,
    breadcrumb: [
      {
        name: 'home',
        path: '/account',
      },
      {
        name: 'deviceSessions',
      },
    ],
    hide: () => Can({
      type: types.view,
      rule: `${resource.deviceSessions}.${resource.deviceSessions}`,
      children: false,
      fallback: true,
    }),
  },
  {
    id: 122,
    name: 'Casino Bet TransactionDetails',
    menuParentId: 5,
    component: lazy(() => import('pages/CasinoBetTransactionDetails')),
    exact: true,
    path: '/bets/casinoTxnDetails/:brandId/:betId',
    key: '/bets/casinoTxnDetails/:brandId/:betId',
    showBrand: { viewMode: true },
    showTimeZone: true,
    breadcrumb: [
      {
        name: 'home',
        path: '/account',
      },
      {
        name: 'casinoBetsReport',
        path: '/bets',
      },
      {
        name: 'txnDetails',
      },
    ],
    hide: () => Can({
      type: types.view,
      rule: `${resource.bets}.${resource.casinoBets}`,
      children: false,
      fallback: true,
    }),
  },
  {
    id: 123,
    name: 'Sport Bet TransactionDetails',
    menuParentId: 5,
    component: lazy(() => import('pages/SportBetTransactionDetails')),
    exact: true,
    path: '/bets/sportTxnDetails/:brandId/:betId',
    key: '/bets/sportTxnDetails/:brandId/:betId',
    showBrand: { viewMode: true },
    showTimeZone: true,
    breadcrumb: [
      {
        name: 'home',
        path: '/account',
      },
      {
        name: 'sportBetsReport',
        path: `/bets?tab=${title.bets.sport}`,
      },
      {
        name: 'txnDetails',
      },
    ],
    hide: () => Can({
      type: types.view,
      rule: `${resource.bets}.${resource.sportBets}`,
      children: false,
      fallback: true,
    }),
  },
  {
    id: 125,
    menuItem: true,
    name: 'ipWhitelist',
    showPartner: { includeMainPartner: true },
    menuParentId: 18,
    component: lazy(() => import('pages/IpWhitelist')),
    exact: true,
    path: '/ipWhitelist',
    key: '/ipWhitelist',
    showTimeZone: true,
    breadcrumb: [
      {
        name: 'home',
        path: '/account',
      },
      {
        name: 'ipWhitelist',
        path: '/ipWhitelist',
      },
    ],
    hide: () => _.isEmpty(params.partnerAccessIds)
      || Can({
        type: types.view,
        rule: `${resource.userManagement}.${resource.ipWhitelist}`,
        children: false,
        fallback: true,
      }),
  },
  {
    id: 126,
    name: 'Brand Automation Create',
    menuParentId: 124,
    component: lazy(() => import('pages/CreateEditBrandAutomation')),
    exact: true,
    showBrand: { viewMode: true },
    showTimeZone: { viewMode: true },
    path: '/brandAutomation/create',
    key: '/brandAutomation/create',
    breadcrumb: [
      {
        name: 'home',
        path: '/account',
      },
      {
        name: 'brandAutomation',
        path: '/brandAutomation',
      },
      {
        name: 'create',
      },
    ],
    hide: () => Can({
      type: types.add,
      rule: `${resource.tools}.${resource.brandAutomation}`,
      children: false,
      fallback: true,
    }),
  },
  {
    id: 127,
    name: 'Brand Automation Edit',
    menuParentId: 124,
    component: lazy(() => import('pages/CreateEditBrandAutomation')),
    exact: true,
    showBrand: { viewMode: true },
    path: '/brandAutomation/:ruleId/edit',
    key: '/brandAutomation/:ruleId/edit',
    showTimeZone: { viewMode: true },
    breadcrumb: [
      {
        name: 'home',
        path: '/account',
      },
      {
        name: 'brandAutomation',
        path: '/brandAutomation',
      },
      {
        key: 'name',
      },
    ],
    hide: () => Can({
      type: types.edit,
      rule: `${resource.tools}.${resource.brandAutomation}`,
      children: false,
      fallback: true,
    }),
  },
  {
    id: 128,
    name: 'Brand Automation Clone',
    menuParentId: 124,
    component: lazy(() => import('pages/CreateEditBrandAutomation')),
    exact: true,
    showBrand: { viewMode: true },
    path: '/brandAutomation/:cloneRuleId/clone',
    key: '/brandAutomation/:cloneRuleId/clone',
    showTimeZone: { viewMode: true },
    breadcrumb: [
      {
        name: 'home',
        path: '/account',
      },
      {
        name: 'brandAutomation',
        path: '/brandAutomation',
      },
      {
        key: 'name',
      },
    ],
    hide: () => Can({
      type: types.add,
      rule: `${resource.tools}.${resource.brandAutomation}`,
      children: false,
      fallback: true,
    }),
  },
  {
    id: 129,
    name: 'Brand Automation View',
    menuParentId: 124,
    component: lazy(() => import('pages/ViewBrandAutomation')),
    exact: true,
    showBrand: { viewMode: true },
    path: '/brandAutomation/:ruleId',
    key: '/brandAutomation/:ruleId',
    showTimeZone: { viewMode: true },
    breadcrumb: [
      {
        name: 'home',
        path: '/account',
      },
      {
        name: 'brandAutomation',
        path: '/brandAutomation',
      },
      {
        key: 'name',
      },
    ],
    hide: () => Can({
      type: types.view,
      rule: `${resource.tools}.${resource.brandAutomation}`,
      children: false,
      fallback: true,
    }),
  },
  {
    id: 130,
    name: 'Brand Automation Statistics',
    menuParentId: 124,
    component: lazy(() => import('pages/BrandAutomationStatistics')),
    exact: true,
    showBrand: { viewMode: true },
    path: '/brandAutomation/:ruleId/:type/statistics',
    key: '/brandAutomation/:ruleId/:type/statistics',
    showTimeZone: true,
    breadcrumb: [
      {
        name: 'home',
        path: '/account',
      },
      {
        name: 'brandAutomation',
        path: '/brandAutomation',
      },
      {
        key: 'name',
      },
    ],
    hide: () => Can({
      type: types.view,
      rule: `${resource.tools}.${resource.brandAutomation}`,
      children: false,
      fallback: true,
    }),
  },
  {
    id: 141,
    menuItem: true,
    name: 'financials',
    menuParentId: 15,
    component: lazy(() => import('pages/FinancialReports')),
    exact: true,
    showBrand: true,
    showTimeZone: true,
    path: '/financials',
    key: '/financials',
    breadcrumb: [
      {
        name: 'home',
        path: '/account',
      },
      {
        name: 'financials',
      },
    ],
    hide: () => Can({
      type: types.view,
      rule: [`${resource.financialReports}.${resource.balanceAdjustments}`, `${resource.financialReports}.${resource.playersWallets}`, `${resource.financialReports}.${resource.firstDeposits}`],
      children: false,
      fallback: true,
    }),
  },
  {
    menuItem: true,
    id: 142,
    name: 'kycReport',
    menuParentId: 3,
    component: lazy(() => import('pages/KycReport')),
    exact: true,
    path: '/kycReport',
    key: '/kycReport',
    showTimeZone: true,
    breadcrumb: [
      {
        name: 'home',
        path: '/account',
      },
      {
        name: 'kycReport',
      },
    ],
    hide: () => Can({
      type: types.view,
      rule: `${resource.players}.${resource.kyc}`,
      children: false,
      fallback: true,
    }),
  },
  {
    id: 144,
    name: 'CashBack Statistics Details',
    menuParentId: 77,
    component: lazy(() => import('pages/CashBackStatisticsDetails')),
    exact: true,
    path: '/cashbacks/:cashbackId/:calculationFrom/statistics/:id',
    key: '/cashbacks/:cashbackId/:calculationFrom/statistics/:id',
    showTimeZone: true,
    breadcrumb: [
      {
        name: 'home',
        path: '/account',
      },
      {
        name: 'cashback',
        path: '/cashbacks',
      },
      {
        name: 'statistics',
        path: '/cashbacks/:cashbackId/:calculationFrom/statistics',
      },
      {
        name: 'details',
      },
    ],
    hide: () => Can({
      type: types.view,
      rule: `${resource.bonusManagement}.${resource.cashback}`,
      children: false,
      fallback: true,
    }),
  },
  {
    id: 148,
    name: 'cms:cashier',
    menuItem: true,
    component: lazy(() => import('pages/Cashier')),
    exact: true,
    menuParentId: 13,
    showBrand: true,
    path: '/cashiers',
    key: '/cashiers',
    showTimeZone: true,
    breadcrumb: [
      {
        name: 'home',
        path: '/account',
      },
      {
        name: 'cms:cashier',
        path: '/cashiers',
      },
    ],
    hide: () => Can({
      type: types.view,
      rule: [
        `${resource.contentManagement}.${resource.cashierInventory}`,
        `${resource.contentManagement}.${resource.cashierSettings}`,
        `${resource.contentManagement}.${resource.cashierDepositAndWithdrawal}`,
      ],
      children: false,
      fallback: true,
    }),
  },
  {
    id: 149,
    component: lazy(() => import('pages/CashierDepositPaymentMethods')),
    exact: true,
    menuParentId: 148,
    path: '/cashiers/:brandId/:categoryId/deposits',
    key: '/cashiers/:brandId/:categoryId/deposits',
    showBrand: { viewMode: true },
    breadcrumb: [
      {
        name: 'home',
        path: '/account',
      },
      {
        name: 'cms:cashierDeposit',
        path: `/cashiers?tab=${title.cms.deposit}`,
      },
      {
        key: 'name',
      },
    ],
    hide: () => Can({
      type: types.view,
      rule: `${resource.contentManagement}.${resource.cashierDepositAndWithdrawal}`,
      children: false,
      fallback: true,
    }),
  },
  {
    id: 150,
    component: lazy(() => import('pages/CashierWithdrawalPaymentMethods')),
    exact: true,
    menuParentId: 148,
    path: '/cashiers/:brandId/:categoryId/withdrawals',
    key: '/cashiers/:brandId/:categoryId/withdrawals',
    showBrand: { viewMode: true },
    breadcrumb: [
      {
        name: 'home',
        path: '/account',
      },
      {
        name: 'cms:cashierWithdrawal',
        path: `/cashiers?tab=${title.cms.withdrawal}`,
      },
      {
        key: 'name',
      },
    ],
    hide: () => Can({
      type: types.view,
      rule: `${resource.contentManagement}.${resource.cashierDepositAndWithdrawal}`,
      children: false,
      fallback: true,
    }),
  },
  {
    id: 151,
    name: 'Select Cashback Type',
    menuParentId: 45,
    component: lazy(() => import('pages/ChooseCashbackType')),
    exact: true,
    showBrand: { viewMode: true },
    path: '/cashbacks/chooseCashbackType/create',
    key: '/cashbacks/chooseCashbackType/create',
    showTimeZone: { viewMode: true },
    breadcrumb: [
      {
        name: 'home',
        path: '/account',
      },
      {
        name: 'cashback',
        path: '/cashbacks',
      },
      {
        name: 'selectCashbackType',
      },
    ],
    hide: () => Can({
      type: types.add,
      rule: `${resource.bonusManagement}.${resource.cashback}`,
      children: false,
      fallback: true,
    }),
  },
  {
    id: 74,
    menuParentId: 45,
    name: 'CreateCashBack',
    component: lazy(() => import('pages/CreateEditCashBack')),
    exact: true,
    showBrand: { viewMode: true },
    path: '/cashbacks/:cashbackType/create',
    key: '/cashbacks/:cashbackType/create',
    showTimeZone: { viewMode: true },
    breadcrumb: [
      {
        name: 'home',
        path: '/account',
      },
      {
        name: 'cashback',
        path: '/cashbacks',
      },
      {
        name: 'selectCashbackType',
        path: '/cashbacks/chooseCashbackType/create',
      },
      {
        name: 'create',
      },
    ],
    hide: () => Can({
      type: types.add,
      rule: `${resource.bonusManagement}.${resource.cashback}`,
      children: false,
      fallback: true,
    }),
  },
  {
    id: 153,
    menuParentId: 45,
    name: 'Edit CashBack',
    component: lazy(() => import('pages/CreateEditCashBack')),
    exact: true,
    showBrand: { viewMode: true },
    path: '/cashbacks/:cashbackId/:cashbackType/edit',
    key: '/cashbacks/:cashbackId/:cashbackType/edit',
    showTimeZone: { viewMode: true },
    breadcrumb: [
      {
        name: 'home',
        path: '/account',
      },
      {
        name: 'cashback',
        path: '/cashbacks',
      },
      {
        key: 'name',
      },
    ],
    hide: () => Can({
      type: types.edit,
      rule: `${resource.bonusManagement}.${resource.cashback}`,
      children: false,
      fallback: true,
    }),
  },
  {
    id: 154,
    menuParentId: 45,
    name: 'View CashBack',
    component: lazy(() => import('pages/ViewCashBack')),
    exact: true,
    showBrand: { viewMode: true },
    path: '/cashbacks/:cashbackId/:cashbackType',
    key: '/cashbacks/:cashbackId/:cashbackType',
    showTimeZone: { viewMode: true },
    breadcrumb: [
      {
        name: 'home',
        path: '/account',
      },
      {
        name: 'cashback',
        path: '/cashbacks',
      },
      {
        key: 'name',
      },
    ],
    hide: () => Can({
      type: types.view,
      rule: `${resource.bonusManagement}.${resource.cashback}`,
      children: false,
      fallback: true,
    }),
  },
  {
    id: 155,
    menuParentId: 26,
    name: 'View User',
    component: lazy(() => import('pages/ViewUser')),
    exact: true,
    path: '/users/:userId',
    key: '/users/:userId',
    showPartner: { viewMode: true, includeMainPartner: true },
    showTimeZone: { viewMode: true },
    breadcrumb: [
      {
        name: 'home',
        path: '/account',
      },
      {
        name: 'users',
        path: '/users',
      },
      {
        key: 'name',
      },
    ],
    hide: () => Can({
      type: types.view,
      rule: `${resource.userManagement}.${resource.users}`,
      children: false,
      fallback: true,
    }),
  },
  {
    id: 156,
    menuParentId: 19,
    name: 'systemConversion',
    menuItem: true,
    component: lazy(() => import('pages/SystemConversion')),
    exact: true,
    path: '/systemConversions',
    key: '/systemConversions',
    breadcrumb: [
      {
        name: 'home',
        path: '/account',
      },
      {
        name: 'systemConversion',
        path: '/systemConversions',
      },
    ],
    hide: () => Can({
      type: types.view,
      rule: `${resource.systemSettings}.${resource.systemConversion}`,
      children: false,
      fallback: true,
    }),
  },
  {
    id: 157,
    menuParentId: 19,
    name: 'systemConversionLogs',
    component: lazy(() => import('pages/SystemConversionLogs')),
    exact: true,
    path: '/systemConversions/:id/logs',
    key: '/systemConversions/:id/logs',
    showTimeZone: true,
    breadcrumb: [
      {
        name: 'home',
        path: '/account',
      },
      {
        name: 'systemConversion',
        path: '/systemConversions',
      },
      {
        key: 'name',
        path: '/systemConversions/:id/logs',
      },
    ],
    hide: () => Can({
      type: types.view,
      rule: `${resource.systemSettings}.${resource.systemConversion}`,
      children: false,
      fallback: true,
    }),
  },
  {
    id: 158,
    menuParentId: 13,
    name: 'gameManagement',
    menuItem: true,
    component: lazy(() => import('pages/GameManagement')),
    exact: true,
    path: '/gameManagement',
    key: '/gameManagement',
    showBrand: true,
    showTimeZone: true,
    breadcrumb: [
      {
        name: 'home',
        path: '/account',
      },
      {
        name: 'gameManagement',
      },
    ],
    hide: () => Can({
      type: types.view,
      rule: [`${resource.contentManagement}.${resource.games}`, `${resource.contentManagement}.${resource.groups}`, `${resource.contentManagement}.${resource.gameTypes}`],
      children: false,
      fallback: true,
    }),
  },
  {
    id: 159,
    menuParentId: 158,
    name: 'viewGame',
    component: lazy(() => import('pages/ViewGame')),
    exact: true,
    path: '/gameManagement/games/:id',
    key: '/gameManagement/games/:id',
    showTimeZone: { viewMode: true },
    showBrand: { viewMode: true },
    breadcrumb: [
      {
        name: 'home',
        path: '/account',
      },
      {
        name: 'gameManagement',
        path: `/gameManagement?tab=${title.cms.games}`,
      },
      {
        key: 'name',
      },
    ],
    hide: () => Can({
      type: types.view,
      rule: `${resource.contentManagement}.${resource.games}`,
      children: false,
      fallback: true,
    }),
  },
  {
    id: 161,
    menuParentId: 101,
    menuItem: true,
    name: 'reasonsSystem',
    component: lazy(() => import('pages/ReasonsSystem')),
    exact: true,
    path: '/reasonsSystem',
    key: '/reasonsSystem',
    showBrand: true,
    showTimeZone: true,
    breadcrumb: [
      {
        name: 'home',
        path: '/account',
      },
      {
        name: 'reasonsSystem',
      },
    ],
    hide: () => Can({
      type: types.view,
      rule: `${resource.tools}.${resource.reasonsSystem}`,
      children: false,
      fallback: true,
    }),
  },
  {
    id: 162,
    menuParentId: 161,
    name: 'reasonsSystem',
    component: lazy(() => import('pages/ResourceReasons')),
    exact: true,
    path: '/resources/:id/reasons',
    key: '/resources/:id/reasons',
    showTimeZone: true,
    showBrand: { viewMode: true },
    breadcrumb: [
      {
        name: 'home',
        path: '/account',
      },
      {
        name: 'reasonsSystem',
        path: `/reasonsSystem?tab=${title.tools.resources}`,
      },
      {
        key: 'name',
      },
    ],
    hide: () => Can({
      type: types.view,
      rule: `${resource.tools}.${resource.reasonsSystem}`,
      children: false,
      fallback: true,
    }),
  },
  {
    id: 164,
    menuParentId: 163,
    component: lazy(() => import('pages/CreateEditCampaign')),
    exact: true,
    path: '/campaigns/:type/create',
    key: '/campaigns/:type/create',
    showBrand: { viewMode: true },
    showTimeZone: { viewMode: true },
    breadcrumb: [
      {
        name: 'home',
        path: '/account',
      },
      {
        name: 'campaigns',
        path: '/campaigns',
      },
      {
        name: 'create',
      },
    ],
    hide: () => Can({
      type: types.add,
      rule: `${resource.bonusManagement}.${resource.bonus}`,
      children: false,
      fallback: true,
    }),
  },
  {
    id: 165,
    menuParentId: 163,
    component: lazy(() => import('pages/CreateEditCampaign')),
    exact: true,
    path: '/campaigns/:cloneId/:type/clone',
    key: '/campaigns/:cloneId/:type/clone',
    showBrand: { viewMode: true },
    showTimeZone: { viewMode: true },
    breadcrumb: [
      {
        name: 'home',
        path: '/account',
      },
      {
        name: 'campaigns',
        path: '/campaigns',
      },
      {
        key: 'name',
      },
    ],
    hide: () => Can({
      type: types.add,
      rule: `${resource.bonusManagement}.${resource.bonus}`,
      children: false,
      fallback: true,
    }),
  },
  {
    id: 166,
    menuParentId: 163,
    component: lazy(() => import('pages/ViewCampaign')),
    exact: true,
    path: '/campaigns/:campaignId/:type',
    key: '/campaigns/:campaignId/:type',
    showBrand: { viewMode: true },
    showTimeZone: { viewMode: true },
    breadcrumb: [
      {
        name: 'home',
        path: '/account',
      },
      {
        name: 'campaigns',
        path: '/campaigns',
      },
      {
        key: 'name',
      },
    ],
    hide: () => Can({
      type: types.view,
      rule: `${resource.bonusManagement}.${resource.bonus}`,
      children: false,
      fallback: true,
    }),
  },
  {
    id: 167,
    name: 'Bonus Campaign Players',
    menuParentId: 163,
    component: lazy(() => import('pages/CampaignPlayers')),
    exact: true,
    path: '/campaigns/:campaignId/:status/:participantType/players',
    key: '/campaigns/:campaignId/:status/:participantType/players',
    showBrand: { viewMode: true },
    breadcrumb: [
      {
        name: 'home',
        path: '/account',
      },
      {
        name: 'campaigns',
        path: '/campaigns',
      },
      {
        key: 'name',
      },
    ],
    hide: () => Can({
      type: types.view,
      rule: `${resource.bonusManagement}.${resource.bonus}`,
      children: false,
      fallback: true,
    }),
  },
  {
    id: 168,
    name: 'Bonus Campaign Statistics',
    menuParentId: 163,
    component: lazy(() => import('pages/CampaignStatistics')),
    exact: true,
    path: '/campaigns/:campaignId/:type/:triggerType/statistics',
    key: '/campaigns/:campaignId/:type/:triggerType/statistics',
    showBrand: { viewMode: true },
    showTimeZone: true,
    breadcrumb: [
      {
        name: 'home',
        path: '/account',
      },
      {
        name: 'campaigns',
        path: '/campaigns',
      },
      {
        key: 'name',
      },
    ],
    hide: () => Can({
      type: types.view,
      rule: `${resource.bonusManagement}.${resource.bonus}`,
      children: false,
      fallback: true,
    }),
  },
  {
    id: 209,
    name: 'Bonus Campaign Usages',
    menuParentId: 163,
    component: lazy(() => import('pages/CrossPlatformCampaignUsages')),
    exact: true,
    path: '/campaigns/:campaignId/usages',
    key: '/campaigns/:campaignId/usages',
    showBrand: { viewMode: true },
    breadcrumb: [
      {
        name: 'home',
        path: '/account',
      },
      {
        name: 'campaigns',
        path: '/campaigns',
      },
      {
        key: 'name',
      },
    ],
    hide: () => Can({
      type: types.view,
      rule: `${resource.bonusManagement}.${resource.bonus}`,
      children: false,
      fallback: true,
    }),
  },
  {
    id: 169,
    name: 'romanian',
    menuParentId: 198,
    showBrand: true,
    menuItem: true,
    component: lazy(() => import('pages/RomanianReports')),
    exact: true,
    path: '/romanian',
    key: '/romanian',
    showTimeZone: true,
    breadcrumb: [
      {
        name: 'home',
        path: '/account',
      },
      {
        name: 'romanian',
      },
    ],
    hide: () => Can({
      type: types.view,
      rule: `${resource.romanianRegulatory}.${resource.romanianRegulatory}`,
      children: false,
      fallback: true,
    }),
  },
  {
    id: 208,
    name: 'european',
    menuParentId: 198,
    showBrand: true,
    menuItem: true,
    component: lazy(() => import('pages/EuropeanReports')),
    exact: true,
    path: '/european',
    key: '/european',
    showTimeZone: true,
    breadcrumb: [
      {
        name: 'home',
        path: '/account',
      },
      {
        name: 'european',
      },
    ],
    hide: () => Can({
      type: types.view,
      rule: `${resource.europeanRegulatory}.${resource.europeanRegulatory}`,
      children: false,
      fallback: true,
    }),
  },
  {
    id: 104,
    name: 'tagCategories',
    menuParentId: 101,
    component: lazy(() => import('pages/TagCategories')),
    exact: true,
    showBrand: true,
    menuItem: true,
    path: '/tagCategories',
    key: '/tagCategories',
    showTimeZone: true,
    breadcrumb: [
      {
        name: 'home',
        path: '/account',
      },
      {
        name: 'tagCategories',
      },
    ],
    hide: () => Can({
      type: types.view,
      rule: [`${resource.tools}.${resource.verticalTags}`, `${resource.tools}.${resource.horizontalTags}`],
      children: false,
      fallback: true,
    }),
  },
  {
    id: 102,
    menuParentId: 104,
    name: 'Tags',
    component: lazy(() => import('pages/HorizontalTags')),
    exact: true,
    showBrand: { viewMode: true },
    path: '/tagCategories/horizontal/:categoryId/tags',
    key: '/tagCategories/horizontal/:categoryId/tags',
    showTimeZone: true,
    breadcrumb: [
      {
        name: 'home',
        path: '/account',
      },
      {
        name: 'tagCategories',
        path: `/tagCategories?tab=${title.tools.horizontal}`,
      },
      {
        key: 'name',
      },
    ],
    hide: () => Can({
      type: types.view,
      rule: `${resource.tools}.${resource.horizontalTags}`,
      children: false,
      fallback: true,
    }),
  },
  {
    id: 105,
    menuParentId: 104,
    name: 'Tags',
    component: lazy(() => import('pages/VerticalTags')),
    exact: true,
    showBrand: { viewMode: true },
    path: '/tagCategories/vertical/:categoryId/tags',
    key: '/tagCategories/vertical/:categoryId/tags',
    showTimeZone: true,
    breadcrumb: [
      {
        name: 'home',
        path: '/account',
      },
      {
        name: 'tagCategories',
        path: `/tagCategories?tab=${title.tools.vertical}`,
      },
      {
        key: 'name',
      },
    ],
    hide: () => Can({
      type: types.view,
      rule: `${resource.tools}.${resource.verticalTags}`,
      children: false,
      fallback: true,
    }),
  },
  {
    id: 170,
    menuParentId: 104,
    name: 'Tag Players',
    component: lazy(() => import('pages/VerticalTagPlayers')),
    exact: true,
    showBrand: { viewMode: true },
    path: '/tagCategories/vertical/:categoryId/tags/:tagId/players',
    key: '/tagCategories/vertical/:categoryId/tags/:tagId/players',
    showTimeZone: true,
    breadcrumb: [
      {
        name: 'home',
        path: '/account',
      },
      {
        name: 'tagCategories',
        path: `/tagCategories?tab=${title.tools.vertical}`,
      },
      {
        key: 'category',
        path: '/tagCategories/vertical/:categoryId/tags',
      },
      {
        key: 'tag',
      },
    ],
    hide: () => Can({
      type: types.view,
      rule: `${resource.tools}.${resource.verticalTags}`,
      children: false,
      fallback: true,
    }),
  },
  {
    id: 171,
    menuParentId: 104,
    name: 'Tag Players',
    component: lazy(() => import('pages/HorizontalTagPlayers')),
    exact: true,
    showBrand: { viewMode: true },
    path: '/tagCategories/horizontal/:categoryId/tags/:tagId/players',
    key: '/tagCategories/horizontal/:categoryId/tags/:tagId/players',
    showTimeZone: true,
    breadcrumb: [
      {
        name: 'home',
        path: '/account',
      },
      {
        name: 'tagCategories',
        path: `/tagCategories?tab=${title.tools.horizontal}`,
      },
      {
        key: 'category',
        path: '/tagCategories/horizontal/:categoryId/tags',
      },
      {
        key: 'tag',
      },
    ],
    hide: () => Can({
      type: types.view,
      rule: `${resource.tools}.${resource.horizontalTags}`,
      children: false,
      fallback: true,
    }),
  },
  {
    id: 172,
    name: 'View Bonus',
    menuParentId: 42,
    component: lazy(() => import('pages/ViewBonus')),
    exact: true,
    path: '/bonuses/:bonusId/:bonusType',
    key: '/bonuses/:bonusId/:bonusType',
    showBrand: { viewMode: true },
    showTimeZone: { viewMode: true },
    breadcrumb: [
      {
        name: 'home',
        path: '/account',
      },
      {
        name: 'bonuses',
        path: '/bonuses',
      },
      {
        key: 'name',
      },
    ],
    hide: () => Can({
      type: types.view,
      rule: `${resource.bonusManagement}.${resource.bonus}`,
      children: false,
      fallback: true,
    }),
  },
  {
    id: 173,
    menuItem: true,
    menuParentId: 7,
    name: 'previousAccounts',
    component: lazy(() => import('pages/PreviousAccountsGroups')),
    exact: true,
    path: '/previousAccounts',
    key: '/previousAccounts',
    showTimeZone: true,
    showBrand: true,
    breadcrumb: [
      {
        name: 'home',
        path: '/account',
      },
      {
        name: 'previousAccounts',
      },
    ],
    hide: () => Can({
      type: types.view,
      rule: `${resource.riskManagement}.${resource.previousAccounts}`,
      children: false,
      fallback: true,
    }),
  },
  {
    id: 174,
    menuParentId: 7,
    component: lazy(() => import('pages/PreviousAccounts')),
    exact: true,
    path: '/previousAccounts/:id',
    key: '/previousAccounts/:id',
    showTimeZone: true,
    showBrand: { viewMode: true },
    breadcrumb: [
      {
        name: 'home',
        path: '/account',
      },
      {
        name: 'previousAccounts',
        path: '/previousAccounts',
      },
      {
        key: 'name',
      },
    ],
    hide: () => Can({
      type: types.view,
      rule: `${resource.riskManagement}.${resource.previousAccounts}`,
      children: false,
      fallback: true,
    }),
  },
  {
    id: 175,
    name: 'agSentData',
    menuParentId: 101,
    component: lazy(() => import('pages/AgSentData')),
    exact: true,
    menuItem: true,
    path: '/agSentData',
    key: '/agSentData',
    showTimeZone: true,
    breadcrumb: [
      {
        name: 'home',
        path: '/account',
      },
      {
        name: 'agSentData',
        path: '/agSentData',
      },
    ],
    hide: () => Can({
      type: types.view,
      rule: `${resource.tools}.${resource.agSentData}`,
      children: false,
      fallback: true,
    }),
  },
  {
    id: 178,
    name: 'lobbyManagement',
    menuParentId: 13,
    component: lazy(() => import('pages/LobbyManagement')),
    exact: true,
    menuItem: true,
    path: '/lobbies',
    key: '/lobbies',
    showTimeZone: true,
    showBrand: true,
    breadcrumb: [
      {
        name: 'home',
        path: '/account',
      },
      {
        name: 'lobbyManagement',
        path: '/lobbies',
      },
    ],
    hide: () => Can({
      type: types.view,
      rule: `${resource.contentManagement}.${resource.lobbyManagement}`,
      children: false,
      fallback: true,
    }),
  },
  {
    id: 179,
    name: 'lobbyManagement',
    menuParentId: 178,
    component: lazy(() => import('pages/ManageLobby')),
    exact: true,
    path: '/lobbies/:id/manage',
    key: '/lobbies/:id/manage',
    showTimeZone: { viewMode: true },
    showBrand: { viewMode: true },
    breadcrumb: [
      {
        name: 'home',
        path: '/account',
      },
      {
        name: 'lobbyManagement',
        path: '/lobbies',
      },
      {
        key: 'name',
      },
    ],
    hide: () => Can({
      type: types.edit,
      rule: `${resource.contentManagement}.${resource.lobbyManagement}`,
      children: false,
      fallback: true,
    }),
  },
  {
    id: 180,
    name: 'lobbyManagement',
    menuParentId: 178,
    component: lazy(() => import('pages/ViewLobby')),
    exact: true,
    path: '/lobbies/:id',
    key: '/lobbies/:id',
    showTimeZone: { viewMode: true },
    showBrand: { viewMode: true },
    breadcrumb: [
      {
        name: 'home',
        path: '/account',
      },
      {
        name: 'lobbyManagement',
        path: '/lobbies',
      },
      {
        key: 'name',
      },
    ],
    hide: () => Can({
      type: types.view,
      rule: `${resource.contentManagement}.${resource.lobbyManagement}`,
      children: false,
      fallback: true,
    }),
  },
  {
    id: 181,
    name: 'lobbyProviders',
    menuParentId: 178,
    component: lazy(() => import('pages/LobbyProviders')),
    exact: true,
    path: '/lobbies/:id/providers',
    key: '/lobbies/:id/providers',
    showTimeZone: true,
    showBrand: true,
    breadcrumb: [
      {
        name: 'home',
        path: '/account',
      },
      {
        name: 'lobbyManagement',
        path: '/lobbies',
      },
      {
        key: 'name',
      },
      {
        name: 'providers',
      },
    ],
    hide: () => Can({
      type: types.view,
      rule: `${resource.contentManagement}.${resource.providersAndGamesManagement}`,
      children: false,
      fallback: true,
    })
      || Can({
        type: types.view,
        rule: `${resource.brands}.${resource.casinoProduct}`,
        children: false,
        fallback: true,
      }),
  },
  {
    id: 183,
    menuParentId: 182,
    name: 'Create Promotion',
    icon: 'PartnerIcon',
    component: lazy(() => import('pages/CreateEditPromotion')),
    exact: true,
    path: '/promotions/configure',
    key: '/promotions/configure',
    showTimeZone: { viewMode: true },
    showBrand: { viewMode: true },
    breadcrumb: [
      {
        name: 'home',
        path: '/account',
      },
      {
        name: 'promotion:promotions',
        path: '/promotions',
      },
      {
        name: 'configure',
      },
    ],
    hide: () => Can({
      type: types.add,
      rule: `${resource.gamification}.${resource.gamification}`,
      children: false,
      fallback: true,
    }),
  },
  {
    id: 184,
    menuParentId: 182,
    name: 'View Promotion',
    icon: 'PartnerIcon',
    component: lazy(() => import('pages/ViewPromotion')),
    exact: true,
    path: '/promotions/view',
    key: '/promotions/view',
    showTimeZone: { viewMode: true },
    showBrand: { viewMode: true },
    breadcrumb: [
      {
        name: 'home',
        path: '/account',
      },
      {
        name: 'promotion:promotions',
        path: '/promotions',
      },
      {
        name: 'view',
      },
    ],
    hide: () => Can({
      type: types.view,
      rule: `${resource.gamification}.${resource.gamification}`,
      children: false,
      fallback: true,
    }),
  },
  {
    id: 185,
    menuParentId: 182,
    name: 'Promotion Statistics',
    icon: 'PartnerIcon',
    component: lazy(() => import('pages/PromotionStatistics')),
    exact: true,
    path: '/promotions/:promotionId/statistics',
    key: '/promotions/:promotionId/statistics',
    showTimeZone: true,
    showBrand: { viewMode: true },
    breadcrumb: [
      {
        name: 'home',
        path: '/account',
      },
      {
        name: 'promotion:promotions',
        path: '/promotions',
      },
      {
        key: 'name',
      },
    ],
    hide: () => Can({
      type: types.view,
      rule: `${resource.gamification}.${resource.gamification}`,
      children: false,
      fallback: true,
    }),
  },
  {
    id: 167,
    name: 'Promotion Players',
    menuParentId: 182,
    component: lazy(() => import('pages/PromotionPlayers')),
    exact: true,
    path: '/promotions/:promotionId/players',
    key: '/promotions/:promotionId/players',
    showBrand: { viewMode: true },
    breadcrumb: [
      {
        name: 'home',
        path: '/account',
      },
      {
        name: 'promotion:promotions',
        path: '/promotions',
      },
      {
        key: 'name',
      },
    ],
    hide: () => Can({
      type: types.view,
      rule: `${resource.gamification}.${resource.gamification}`,
      children: false,
      fallback: true,
    }),
  },
  {
    id: 186,
    menuParentId: 158,
    name: 'gameManagement',
    component: lazy(() => import('pages/CreateEditGameGroup')),
    exact: true,
    path: '/gameManagement/groups/create',
    key: '/gameManagement/groups/create',
    showBrand: true,
    showTimeZone: true,
    breadcrumb: [
      {
        name: 'home',
        path: '/account',
      },
      {
        name: 'gameManagement',
        path: `/gameManagement?tab=${title.cms.groups}`,
      },
      {
        name: 'create',
      },
    ],
    hide: () => Can({
      type: types.add,
      rule: [`${resource.contentManagement}.${resource.groups}`],
      children: false,
      fallback: true,
    }),
  },
  {
    id: 187,
    menuParentId: 178,
    name: 'Lobby Logs',
    component: lazy(() => import('pages/LobbyLogs')),
    exact: true,
    path: '/lobbies/:id/logs',
    key: '/lobbies/:id/logs',
    showBrand: { viewMode: true },
    showTimeZone: true,
    breadcrumb: [
      {
        name: 'home',
        path: '/account',
      },
      {
        name: 'lobbyManagement',
        path: '/lobbies',
      },
      {
        key: 'name',
      },
    ],
    hide: () => Can({
      type: types.view,
      rule: [`${resource.contentManagement}.${resource.lobbyManagement}`],
      children: false,
      fallback: true,
    }),
  },
  {
    id: 187,
    menuParentId: 158,
    name: 'Lobby Logs',
    component: lazy(() => import('pages/LobbyLogs')),
    exact: true,
    path: '/lobbies/:id/logs',
    key: '/lobbies/:id/logs',
    showBrand: { viewMode: true },
    showTimeZone: true,
    breadcrumb: [
      {
        name: 'home',
        path: '/account',
      },
      {
        name: 'lobbyManagement',
        path: '/lobbies',
      },
      {
        key: 'name',
      },
    ],
    hide: () => Can({
      type: types.view,
      rule: [`${resource.contentManagement}.${resource.lobbyManagement}`],
      children: false,
      fallback: true,
    }),
  },
  {
    id: 188,
    menuParentId: 158,
    name: 'gameManagement',
    component: lazy(() => import('pages/CreateEditGameGroup')),
    exact: true,
    path: '/gameManagement/groups/:id/edit',
    key: '/gameManagement/groups/:id/edit',
    showBrand: { viewMode: true },
    breadcrumb: [
      {
        name: 'home',
        path: '/account',
      },
      {
        name: 'gameManagement',
        path: `/gameManagement?tab=${title.cms.groups}`,
      },
      {
        key: 'name',
      },
    ],
    hide: () => Can({
      type: types.add,
      rule: [`${resource.contentManagement}.${resource.groups}`],
      children: false,
      fallback: true,
    }),
  },
  {
    id: 189,
    menuItem: true,
    name: 'providers',
    menuParentId: 15,
    showBrand: true,
    showConversion: true,
    showTimeZone: true,
    component: lazy(() => import('pages/Providers')),
    exact: true,
    path: '/providers',
    key: '/providers',
    breadcrumb: [
      {
        name: 'home',
        path: '/account',
      },
      {
        name: 'providers',
      },
    ],
    hide: () => Can({
      type: types.view,
      rule: [`${resource.providers}.${resource.providersReport}`],
      children: false,
      fallback: true,
    }),
  },
  {
    id: 190,
    component: lazy(() => import('pages/PromoSpinsAdjustmentPlayers')),
    exact: true,
    menuParentId: 64,
    showTimeZone: { viewMode: true },
    showBrand: { viewMode: true },
    path: '/bulkActions/manualSpins/:id/players',
    key: '/bulkActions/manualSpins/:id/players',
    breadcrumb: [
      {
        name: 'home',
        path: '/account',
      },
      {
        name: 'bulkActions',
        path: `/bulkActions?tab=${title.bulkActions.promoSpinsAdjustments}`,
      },
      {
        name: 'promotion:manualSpinsPlayers',
      },
    ],
    hide: () => Can({
      type: types.manage,
      rule: `${resource.gamification}.${resource.gamification}`,
      children: false,
      fallback: true,
    }),
  },
  {
    id: 191,
    name: 'exportCenter',
    menuParentId: 101,
    component: lazy(() => import('pages/ExportHistory')),
    exact: true,
    menuItem: true,
    path: '/exportHistory',
    key: '/exportCenter',
    showTimeZone: true,
    breadcrumb: [
      {
        name: 'home',
        path: '/account',
      },
      {
        name: 'exportCenter',
        path: '/exportHistory',
      },
    ],
    hide: () => Can({
      type: types.export,
      rule: `${resource.players}.${resource.players}`,
      children: false,
      fallback: true,
    })
      && Can({
        type: types.export,
        rule: `${resource.bets}.${resource.casinoBets}`,
        children: false,
        fallback: true,
      })
      && Can({
        type: types.export,
        rule: `${resource.bets}.${resource.sportBets}`,
        children: false,
        fallback: true,
      })
      && Can({
        type: types.export,
        rule: `${resource.financials}.${resource.deposits}`,
        children: false,
        fallback: true,
      })
      && Can({
        type: types.export,
        rule: `${resource.financials}.${resource.withdrawals}`,
        children: false,
        fallback: true,
      })
      && Can({
        type: types.export,
        rule: `${resource.playersReports}.${resource.playersTotals}`,
        children: false,
        fallback: true,
      })
      && Can({
        type: types.export,
        rule: `${resource.playersReports}.${resource.inactiveAccounts}`,
        children: true,
        fallback: false,
      })
      && Can({
        type: types.export,
        rule: `${resource.bonusesReport}.${resource.bonusesReport}`,
        children: false,
        fallback: true,
      })
      && Can({
        type: types.export,
        rule: `${resource.auditLogs}.${resource.playerLogs}`,
        children: true,
        fallback: false,
      })
      && Can({
        type: types.export,
        rule: `${resource.auditLogs}.${resource.userLogs}`,
        children: true,
        fallback: false,
      })
      && Can({
        type: types.export,
        rule: `${resource.auditLogs}.${resource.userManagementLogs}`,
        children: true,
        fallback: false,
      })
      && Can({
        type: types.export,
        rule: `${resource.communications}.${resource.pushNotifications}`,
        children: false,
        fallback: true,
      })
      && Can({
        type: types.export,
        rule: `${resource.businessIntelligence}.${resource.betAggregations}`,
        children: true,
        fallback: false,
      })
      && Can({
        type: types.export,
        rule: `${resource.compliance}.${resource.largeTransaction}`,
        children: true,
        fallback: false,
      })
      && Can({
        type: types.export,
        rule: `${resource.financialReports}.${resource.playersWallets}`,
        children: true,
        fallback: false,
      })
      && Can({
        type: types.export,
        rule: `${resource.deviceSessions}.${resource.deviceSessions}`,
        children: false,
        fallback: true,
      }),
  },
  {
    id: 192,
    menuItem: true,
    menuParentId: 9,
    showBrand: true,
    name: 'pushNotifications',
    component: lazy(() => import('pages/PushNotifications')),
    exact: true,
    path: '/pushNotifications',
    key: '/pushNotifications',
    showTimeZone: true,
    breadcrumb: [
      {
        name: 'home',
        path: '/account',
      },
      {
        name: 'pushNotifications',
        path: '/pushNotifications',
      },
    ],
    hide: () => Can({
      type: types.view,
      rule: `${resource.communications}.${resource.pushNotifications}`,
      children: false,
      fallback: true,
    }),
  },
  {
    id: 193,
    name: 'ViewPushNotification',
    menuParentId: 192,
    component: lazy(() => import('pages/ViewPushNotification')),
    exact: true,
    showBrand: { viewMode: true },
    path: '/pushNotifications/:notificationId',
    key: '/pushNotifications/:notificationId',
    breadcrumb: [
      {
        name: 'home',
        path: '/account',
      },
      {
        name: 'pushNotifications',
        path: '/pushNotifications',
      },
      {
        key: 'name',
      },
    ],
    hide: () => Can({
      type: types.view,
      rule: `${resource.communications}.${resource.pushNotifications}`,
      children: false,
      fallback: true,
    }),
  },
  {
    id: 194,
    name: 'Compose',
    menuParentId: 192,
    component: lazy(() => import('pages/ComposePushNotification')),
    exact: true,
    path: '/pushNotifications/create',
    key: '/pushNotifications/create',
    showTimeZone: { viewMode: true },
    showBrand: { viewMode: true },
    breadcrumb: [
      {
        name: 'home',
        path: '/account',
      },
      {
        name: 'pushNotifications',
        path: '/pushNotifications',
      },
      {
        name: 'compose',
      },
    ],
    hide: () => Can({
      type: types.add,
      rule: `${resource.communications}.${resource.pushNotifications}`,
      children: false,
      fallback: true,
    }),
  },
  {
    id: 195,
    name: 'Compose',
    menuParentId: 192,
    component: lazy(() => import('pages/PushNotificationRecipients')),
    exact: true,
    path: '/pushNotifications/:notificationId/recipients',
    key: '/pushNotifications/:notificationId/recipients',
    showTimeZone: { viewMode: true },
    showBrand: { viewMode: true },
    breadcrumb: [
      {
        name: 'home',
        path: '/account',
      },
      {
        name: 'pushNotifications',
        path: '/pushNotifications',
      },
      {
        key: 'name',
      },
    ],
    hide: () => Can({
      type: types.add,
      rule: `${resource.communications}.${resource.pushNotifications}`,
      children: false,
      fallback: true,
    }),
  },
  {
    id: 196,
    menuParentId: 163,
    component: lazy(() => import('pages/CampaignEditContents')),
    exact: true,
    path: '/campaigns/:campaignId/:type/editContent',
    key: '/campaigns/:campaignId/:type/editContent',
    showBrand: { viewMode: true },
    showTimeZone: { viewMode: true },
    breadcrumb: [
      {
        name: 'home',
        path: '/account',
      },
      {
        name: 'campaigns',
        path: '/campaigns',
      },
      {
        key: 'name',
      },
    ],
    hide: () => Can({
      type: types.manage,
      rule: `${resource.bonusManagement}.${resource.bonus}`,
      children: false,
      fallback: true,
    }),
  },
  {
    id: 197,
    menuParentId: 182,
    name: 'Promotion Prize Statistics',
    icon: 'PartnerIcon',
    component: lazy(() => import('pages/PromotionPrizeStatistics')),
    exact: true,
    path: '/promotions/:promotionId/:prizeId/:prizeType/statistics',
    key: '/promotions/:promotionId/:prizeId/:prizeType/statistics',
    showTimeZone: true,
    showBrand: { viewMode: true },
    breadcrumb: [
      {
        name: 'home',
        path: '/account',
      },
      {
        name: 'promotion:promotions',
        path: '/promotions',
      },
      {
        key: 'name',
      },
      {
        key: 'prizeName',
      },
    ],
    hide: () => Can({
      type: types.view,
      rule: `${resource.gamification}.${resource.gamification}`,
      children: false,
      fallback: true,
    }),
  },
  {
    id: 202,
    name: 'View Bonus Content',
    menuParentId: 42,
    component: lazy(() => import('pages/ViewBonusContent')),
    exact: true,
    path: '/bonuses/:bonusId/:bonusFamily/content',
    key: '/bonuses/:bonusId/:bonusFamily/content',
    showBrand: { viewMode: true },
    showTimeZone: { viewMode: true },
    breadcrumb: [
      {
        name: 'home',
        path: '/account',
      },
      {
        name: 'bonuses',
        path: '/bonuses',
      },
      {
        key: 'name',
      },
    ],
    hide: () => Can({
      type: types.view,
      rule: `${resource.bonusManagement}.${resource.bonus}`,
      children: false,
      fallback: true,
    }),
  },
  {
    id: 200,
    menuItem: true,
    name: 'bonuses',
    menuParentId: 15,
    showBrand: true,
    component: lazy(() => import('pages/BonusReports')),
    exact: true,
    path: '/bonusReports',
    key: '/bonusReports',
    showTimeZone: true,
    breadcrumb: [
      {
        name: 'home',
        path: '/account',
      },
      {
        name: 'bonuses',
      },
    ],
    hide: () => Can({
      type: types.view,
      rule: `${resource.bonusesReport}.${resource.bonusesReport}`,
      children: false,
      fallback: true,
    }),
  },
  {
    id: 201,
    menuItem: true,
    menuParentId: 7,
    name: 'betStatusCorrection',
    component: lazy(() => import('pages/BetStatusCorrections')),
    exact: true,
    path: '/betStatusCorrections',
    key: '/betStatusCorrections',
    showTimeZone: true,
    showBrand: true,
    breadcrumb: [
      {
        name: 'home',
        path: '/account',
      },
      {
        name: 'betStatusCorrection',
      },
    ],
    hide: () => Can({
      type: types.view,
      rule: `${resource.riskManagement}.${resource.casinoBetCorrection}`,
      children: false,
      fallback: true,
    }),
  },
  {
    id: 202,
    menuParentId: 7,
    name: 'Bet Status Corrections Statistics',
    component: lazy(() => import('pages/BetStatusCorrectionsStatistics')),
    exact: true,
    path: '/betStatusCorrections/:id/statistics',
    key: '/betStatusCorrections/:id/statistics',
    showTimeZone: true,
    showBrand: { viewMode: true },
    breadcrumb: [
      {
        name: 'home',
        path: '/account',
      },
      {
        name: 'betStatusCorrection',
        path: '/betStatusCorrections',
      },
      {
        key: 'id',
      },
    ],
    hide: () => Can({
      type: types.view,
      rule: `${resource.riskManagement}.${resource.casinoBetCorrection}`,
      children: false,
      fallback: true,
    }),
  },
  {
    id: 203,
    name: 'Promotion Spins Statistics',
    menuParentId: 182,
    component: lazy(() => import('pages/PromotionSpinStatistics')),
    exact: true,
    path: '/promotions/:promotionId/spins/:type/:machineId/:spinDistributionId/statistics',
    key: '/promotions/:promotionId/spins/:type/:machineId/:spinDistributionId/statistics',
    showBrand: { viewMode: true },
    showTimeZone: true,
    breadcrumb: [
      {
        name: 'home',
        path: '/account',
      },
      {
        name: 'promotion:promotions',
        path: '/promotions',
      },
      {
        key: 'name',
        path: '/promotions/:promotionId/statistics',
      },
      {
        name: 'promotion:spinStatistics',
      },
    ],
    hide: () => Can({
      type: types.view,
      rule: `${resource.gamification}.${resource.gamification}`,
      children: false,
      fallback: true,
    }),
  },
  {
    id: 203,
    menuParentId: 19,
    menuItem: true,
    name: 'systemSetting:releaseNotes',
    component: lazy(() => import('pages/ReleaseNotes')),
    exact: true,
    path: '/releaseNotes',
    key: '/releaseNotes',
    showTimeZone: true,
    breadcrumb: [
      {
        name: 'home',
        path: '/account',
      },
      {
        name: 'systemSetting:releaseNotes',
      },
    ],
    hide: () => Can({
      type: types.manage,
      rule: `${resource.systemSettings}.${resource.releaseNotes}`,
      children: false,
      fallback: true,
    }),
  },
  {
    id: 204,
    menuParentId: 203,
    name: 'Create Release Note',
    component: lazy(() => import('pages/CreateEditReleaseNote')),
    exact: true,
    path: '/releaseNotes/create',
    key: '/releaseNotes/create',
    showTimeZone: { viewMode: true },
    breadcrumb: [
      {
        name: 'home',
        path: '/account',
      },
      {
        name: 'systemSetting:releaseNotes',
        path: '/releaseNotes',
      },
      {
        name: 'create',
      },
    ],
    hide: () => Can({
      type: types.manage,
      rule: `${resource.systemSettings}.${resource.releaseNotes}`,
      children: false,
      fallback: true,
    }),
  },
  {
    id: 205,
    menuParentId: 203,
    name: 'Edit Release Note',
    component: lazy(() => import('pages/CreateEditReleaseNote')),
    exact: true,
    path: '/releaseNotes/:id/edit',
    key: '/releaseNotes/:id/edit',
    showTimeZone: { viewMode: true },
    breadcrumb: [
      {
        name: 'home',
        path: '/account',
      },
      {
        name: 'systemSetting:releaseNotes',
        path: '/releaseNotes',
      },
      {
        key: 'name',
      },
    ],
    hide: () => Can({
      type: types.manage,
      rule: `${resource.systemSettings}.${resource.releaseNotes}`,
      children: false,
      fallback: true,
    }),
  },
  {
    id: 206,
    name: 'Game Type Games',
    menuParentId: 158,
    component: lazy(() => import('pages/GameTypeGames')),
    exact: true,
    path: '/gameManagement/gameTypes/:id',
    key: '/gameManagement/gameTypes/:id',
    showBrand: { viewMode: true },
    showTimeZone: true,
    breadcrumb: [
      {
        name: 'home',
        path: '/account',
      },
      {
        name: 'gameManagement',
        path: `/gameManagement?tab=${title.cms.gameTypes}`,
      },
      {
        key: 'name',
      },
    ],
    hide: () => Can({
      type: types.view,
      rule: `${resource.contentManagement}.${resource.gameTypes}`,
      children: false,
      fallback: true,
    }),
  },
  {
    id: 207,
    name: 'Promotion Change Logs',
    menuParentId: 182,
    component: lazy(() => import('pages/PromotionChangeLogs')),
    exact: true,
    path: '/promotions/:promotionId/changeLogs',
    key: '/promotions/:promotionId/changeLogs',
    showBrand: { viewMode: true },
    showTimeZone: true,
    breadcrumb: [
      {
        name: 'home',
        path: '/account',
      },
      {
        name: 'promotion:promotions',
        path: '/promotions',
      },
      {
        key: 'name',
      },
    ],
    hide: () => Can({
      type: types.view,
      rule: `${resource.gamification}.${resource.gamification}`,
      children: false,
      fallback: true,
    }),
  },
  {
    id: 210,
    name: 'Lobby Game Groups',
    menuParentId: 178,
    component: lazy(() => import('pages/LobbyGameGroups')),
    exact: true,
    path: '/lobbies/:id/gameGroups',
    key: '/lobbies/:id/gameGroups',
    showBrand: { viewMode: true },
    showTimeZone: true,
    breadcrumb: [
      {
        name: 'home',
        path: '/account',
      },
      {
        name: 'lobbyManagement',
        path: '/lobbies',
      },
      {
        key: 'name',
      },
      {
        name: 'cms:groups',
      },
    ],
    hide: () => Can({
      type: types.view,
      rule: `${resource.contentManagement}.${resource.groupsManagement}`,
      children: false,
      fallback: true,
    }),
  },
  {
    id: 211,
    component: lazy(() => import('pages/BlockedPaymentMethodPlayers')),
    exact: true,
    menuParentId: 64,
    showTimeZone: { viewMode: true },
    showBrand: { viewMode: true },
    path: '/bulkActions/blockedPaymentMethod/:id/players',
    key: '/bulkActions/blockedPaymentMethod/:id/players',
    breadcrumb: [
      {
        name: 'home',
        path: '/account',
      },
      {
        name: 'bulkActions',
        path: `/bulkActions?tab=${title.bulkActions.paymentMethods}`,
      },
      {
        name: 'riskManagement:blockedPaymentMethodPlayers',
      },
    ],
    hide: () => Can({
      type: types.view,
      rule: `${resource.riskManagement}.${resource.bulkBlockAccess}`,
      children: false,
      fallback: true,
    }),
  },
  {
    id: 212,
    name: 'Lobby Widgets',
    menuParentId: 178,
    component: lazy(() => import('pages/LobbyWidgets')),
    exact: true,
    path: '/lobbies/:id/widgets',
    key: '/lobbies/:id/widgets',
    showBrand: { viewMode: true },
    showTimeZone: true,
    breadcrumb: [
      {
        name: 'home',
        path: '/account',
      },
      {
        name: 'lobbyManagement',
        path: '/lobbies',
      },
      {
        key: 'name',
      },
      {
        name: 'widgets',
      },
    ],
    hide: () => Can({
      type: types.view,
      rule: `${resource.contentManagement}.${resource.widgets}`,
      children: false,
      fallback: true,
    }),
  },
  {
    id: 213,
    name: 'Create Lobby Widget',
    component: lazy(() => import('pages/CreateEditLobbyWidget')),
    exact: true,
    menuParentId: 178,
    path: '/lobbies/:id/widgets/create',
    key: '/lobbies/:id/widgets/create',
    showBrand: { viewMode: true },
    showTimeZone: true,
    breadcrumb: [
      {
        name: 'home',
        path: '/account',
      },
      {
        name: 'lobbyManagement',
        path: '/lobbies',
      },
      {
        name: 'create',
      },
    ],
    hide: () => Can({
      type: types.manage,
      rule: `${resource.contentManagement}.${resource.widgets}`,
      children: false,
      fallback: true,
    }),
  },
  {
    id: 214,
    name: 'Edit Lobby Widget',
    component: lazy(() => import('pages/CreateEditLobbyWidget')),
    exact: true,
    menuParentId: 178,
    path: '/lobbies/:id/widgets/:widgetId/edit',
    key: '/lobbies/:id/widgets/:widgetId/edit',
    showBrand: { viewMode: true },
    showTimeZone: true,
    breadcrumb: [
      {
        name: 'home',
        path: '/account',
      },
      {
        name: 'lobbyManagement',
        path: '/lobbies',
      },
      {
        key: 'name',
        path: '/lobbies/:id/widgets',
      },
      {
        key: 'widgetName',
      },
    ],
    hide: () => Can({
      type: types.manage,
      rule: `${resource.contentManagement}.${resource.widgets}`,
      children: false,
      fallback: true,
    }),
  },
  {
    id: 1024,
    menuParentId: 6,
    name: 'campaignsv2',
    menuItem: true,
    showBrand: true,
    component: lazy(() => import('pages/CampaignsV2')),
    exact: true,
    path: '/campaigns/v2',
    key: '/campaigns/v2',
    showTimeZone: true,
    breadcrumb: [
      {
        name: 'home',
        path: '/account',
      },
      {
        name: 'campaignsv2',
      },
    ],
    hide: () => env !== 'dev'
      || Can({
        type: types.view,
        rule: `${resource.bonusManagement}.${resource.bonus}`,
        children: false,
        fallback: true,
      }),
  },
  {
    id: 1025,
    menuParentId: 1024,
    component: lazy(() => import('pages/CreateEditCampaignV2')),
    exact: true,
    path: '/campaigns/v2/:type/configure',
    key: '/campaigns/v2/:type/configure',
    showBrand: { viewMode: true },
    showTimeZone: { viewMode: true },
    breadcrumb: [
      {
        name: 'home',
        path: '/account',
      },
      {
        name: 'campaignsv2',
        path: '/campaigns/v2',
      },
      {
        name: 'configure',
      },
    ],
    hide: () => env !== 'dev'
      || Can({
        type: types.add,
        rule: `${resource.bonusManagement}.${resource.bonus}`,
        children: false,
        fallback: true,
      }),
  },
  {
    id: 1026,
    menuParentId: 1024,
    component: lazy(() => import('pages/ViewCampaignV2')),
    exact: true,
    path: '/campaigns/v2/:type/view',
    key: '/campaigns/v2/:type/view',
    showBrand: { viewMode: true },
    showTimeZone: { viewMode: true },
    breadcrumb: [
      {
        name: 'home',
        path: '/account',
      },
      {
        name: 'campaignsv2',
        path: '/campaigns/v2',
      },
      {
        name: 'view',
      },
    ],
    hide: () => env !== 'dev'
      || Can({
        type: types.view,
        rule: `${resource.bonusManagement}.${resource.bonus}`,
        children: false,
        fallback: true,
      }),
  },
  {
    menuItem: true,
    id: 213,
    menuParentId: 9,
    name: 'playerComplaints',
    component: lazy(() => import('pages/PlayerComplaints')),
    exact: true,
    path: '/playerComplaints',
    key: '/playerComplaints',
    showBrand: true,
    showTimeZone: true,
    breadcrumb: [
      {
        name: 'home',
        path: '/account',
      },
      {
        name: 'playerComplaints',
      },
    ],
    hide: () => Can({
      type: types.view,
      rule: `${resource.communications}.${resource.playerComplaints}`,
      children: false,
      fallback: true,
    }),
  },
];
