import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { selectLocaleLanguage, selectResourceSportsBookLayouts } from 'selectors';
import { getResourceSportsBookLayouts } from 'actions/resource/resourceActionCreator';
import Layouts from 'components/brand/create/Layouts';

const mapStateToProps = ({ resource, locale }) => ({
  templates: selectResourceSportsBookLayouts(resource),
  localeLanguage: selectLocaleLanguage(locale),
});

const mapDispatchToProps = (dispatch) => bindActionCreators(
  {
    getSportsBookLayouts: getResourceSportsBookLayouts,
  },
  dispatch,
);

export default connect(mapStateToProps, mapDispatchToProps)(Layouts);
