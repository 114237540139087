const PermissionGroupActionType = {
  GET_PERMISSION_GROUP: 'GET_PERMISSION_GROUP',
  GET_PERMISSION_GROUP_SUCCESS: 'GET_PERMISSION_GROUP_SUCCESS',
  RESET_PERMISSION_GROUP: 'RESET_PERMISSION_GROUP',

  GET_PERMISSION_GROUPS: 'GET_PERMISSION_GROUPS',
  GET_PERMISSION_GROUPS_SUCCESS: 'GET_PERMISSION_GROUPS_SUCCESS',

  GET_ALL_PERMISSION_GROUPS: 'GET_ALL_PERMISSION_GROUPS',
  GET_ALL_PERMISSION_GROUPS_SUCCESS: 'GET_ALL_PERMISSION_GROUPS_SUCCESS',
  RESET_ALL_PERMISSION_GROUPS: 'RESET_ALL_PERMISSION_GROUPS',

  DELETE_PERMISSION_GROUP: 'DELETE_PERMISSION_GROUP',
  DELETE_PERMISSION_GROUP_SUCCESS: 'DELETE_PERMISSION_GROUP_SUCCESS',

  EDIT_PERMISSION_GROUP: 'EDIT_PERMISSION_GROUP',

  CREATE_PERMISSION_GROUP: 'CREATE_PERMISSION_GROUP',

  CHANGE_PERMISSION_GROUP_LOADING_STATE: 'CHANGE_PERMISSION_GROUP_LOADING_STATE',
};

export default PermissionGroupActionType;
