import {
  all, call, put, select, takeLatest,
} from 'redux-saga/effects';
import qs from 'qs';
import TemplateActionType from 'actions/template/templateActionType';
import {
  changeLoadingState, setErrorMessage, setSuccessMessage, changeEndedStatus,
} from 'actions/setting/settingActionCreator';
import {
  saveTemplates, saveTemplatesTotal, saveTemplate, saveSystemTemplateTypes, saveCustomTemplatesResource,
} from 'actions/template/templateActionCreator';
import NetworkService from 'services/networkService';
import { getError, getMessage } from 'utils/helpers';
import { AppConstants } from 'constants/index';
import { selectTemplatesTotal, selectTemplates } from 'selectors';
import { i18n } from 'services';

function* createCustomTemplate({ payload }) {
  try {
    const { name, isDraft } = payload.data;
    const { NotificationAdmin, CustomTemplate } = AppConstants.api;
    yield put(changeLoadingState(true));
    yield call(NetworkService.makeAPIPostRequest, [NotificationAdmin, CustomTemplate], payload);
    yield put(setSuccessMessage(getMessage(i18n.t('communication:lTemplate'), i18n.t(isDraft ? 'notification:slSavedAsDraft' : 'notification:slCreated'), name)));
    yield put(changeLoadingState(false));
    yield put(changeEndedStatus(true));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* editCustomTemplate({ payload }) {
  try {
    const { name } = payload.data;
    const { NotificationAdmin, CustomTemplate } = AppConstants.api;
    yield put(changeLoadingState(true));
    yield call(NetworkService.makeAPIPutRequest, [NotificationAdmin, CustomTemplate], payload);
    yield put(setSuccessMessage(getMessage(i18n.t('communication:lTemplate'), i18n.t('notification:slUpdated'), name)));
    yield put(changeLoadingState(false));
    yield put(changeEndedStatus(true));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* editSystemTemplate({ payload }) {
  try {
    const { name } = payload.data;
    const { NotificationAdmin, SystemTemplate } = AppConstants.api;
    yield put(changeLoadingState(true));
    yield call(NetworkService.makeAPIPutRequest, [NotificationAdmin, SystemTemplate], payload);
    yield put(setSuccessMessage(getMessage(i18n.t('communication:lTemplate'), i18n.t('notification:slUpdated'), name)));
    yield put(changeLoadingState(false));
    yield put(changeEndedStatus(true));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getCustomTemplates({ payload }) {
  try {
    const options = {
      params: payload.data,
      paramsSerializer: (params) => qs.stringify(params),
    };
    yield put(changeLoadingState(true));
    const { NotificationAdmin, CustomTemplate } = AppConstants.api;
    const response = yield call(NetworkService.makeAPIGetRequest, [NotificationAdmin, CustomTemplate], options);
    const { data, total } = response.data;
    const state = yield select();
    const templates = selectTemplates(state.template);
    const totalState = selectTemplatesTotal(state.template);
    yield put(
      saveTemplates({
        ...templates,
        custom: data,
      }),
    );
    yield put(
      saveTemplatesTotal({
        ...totalState,
        custom: total,
      }),
    );
    yield put(changeLoadingState(false));
  } catch (err) {
    yield put(changeLoadingState(false));
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
  }
}

function* getSystemTemplates({ payload }) {
  try {
    const { brandId, params } = payload;
    const options = {
      params,
      paramsSerializer: (param) => qs.stringify(param),
    };
    yield put(changeLoadingState(true));
    const { NotificationAdmin, SystemTemplate } = AppConstants.api;
    const response = yield call(NetworkService.makeAPIGetRequest, [NotificationAdmin, SystemTemplate, brandId], options);
    const { data, total } = response.data;
    const state = yield select();
    const templates = selectTemplates(state.template);
    const totalState = selectTemplatesTotal(state.template);
    yield put(
      saveTemplates({
        ...templates,
        system: data,
      }),
    );
    yield put(
      saveTemplatesTotal({
        ...totalState,
        system: total,
      }),
    );
    yield put(changeLoadingState(false));
  } catch (err) {
    yield put(changeLoadingState(false));
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
  }
}

function* getSystemTemplateTypes() {
  try {
    yield put(changeLoadingState(true));
    const { NotificationAdmin, SystemTemplate, SystemTemplateTypes } = AppConstants.api;
    const response = yield call(NetworkService.makeAPIGetRequest, [NotificationAdmin, SystemTemplate, SystemTemplateTypes]);
    yield put(saveSystemTemplateTypes(response.data));
    yield put(changeLoadingState(false));
  } catch (err) {
    yield put(changeLoadingState(false));
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
  }
}

function* activateSystemTemplate({ payload }) {
  try {
    const {
      isEnabled, name, typeId, brandId,
    } = payload.data;
    const data = { brandId, typeId, isActive: !isEnabled };
    const { NotificationAdmin, SystemTemplate, ChangeStatus } = AppConstants.api;
    yield put(changeLoadingState(true));
    yield call(NetworkService.makeAPIPutRequest, [NotificationAdmin, SystemTemplate, ChangeStatus], { data });

    const state = yield select();
    const templates = selectTemplates(state.template);
    const system = templates.system.map((el) => (el.typeId === typeId ? { ...el, isEnabled: !isEnabled } : el));

    yield put(
      saveTemplates({
        ...templates,
        system,
      }),
    );
    yield put(setSuccessMessage(getMessage(i18n.t('systemNotification'), i18n.t(isEnabled ? 'notification:slDisabled' : 'notification:slEnabled'), name)));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getCustomTemplate({ payload }) {
  const { id, isMain } = payload;
  try {
    yield put(changeLoadingState(true));
    const { NotificationAdmin, CustomTemplate, Resource } = AppConstants.api;
    const response = yield call(NetworkService.makeAPIGetRequest, [NotificationAdmin, CustomTemplate, id, Resource]);
    yield put(saveTemplate(response.data));
    yield put(changeLoadingState(false));
  } catch (err) {
    yield put(changeLoadingState(false));
    if (err) {
      const error = getError(err, isMain);
      if (isMain) {
        yield put(setErrorMessage(error));
      }
    }
  }
}

function* getSystemTemplate({ payload }) {
  try {
    const { brandId, typeId } = payload;
    yield put(changeLoadingState(true));
    const { NotificationAdmin, SystemTemplate } = AppConstants.api;
    const response = yield call(NetworkService.makeAPIGetRequest, [NotificationAdmin, SystemTemplate, brandId, typeId]);
    yield put(saveTemplate(response.data));
    yield put(changeLoadingState(false));
  } catch (err) {
    yield put(changeLoadingState(false));
    if (err) {
      const error = getError(err, true);
      yield put(setErrorMessage(error));
    }
  }
}

function* deleteCustomTemplate({ payload }) {
  try {
    const { id, name } = payload.data;
    yield put(changeLoadingState(true));
    const { NotificationAdmin, CustomTemplate } = AppConstants.api;
    yield call(NetworkService.makeAPIDeleteRequest, [NotificationAdmin, CustomTemplate, id]);
    yield put(changeEndedStatus(true));
    yield put(setSuccessMessage(getMessage(i18n.t('communication:lTemplate'), i18n.t('notification:slDeleted'), name)));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getCustomTemplatesResource({ payload }) {
  try {
    const { brandId, params } = payload;
    const options = {
      params,
      paramsSerializer: (param) => qs.stringify(param),
    };
    yield put(changeLoadingState(true));
    const { NotificationAdmin, CustomTemplate, Simple } = AppConstants.api;
    const { data } = yield call(NetworkService.makeAPIGetRequest, [NotificationAdmin, CustomTemplate, Simple, brandId], options);
    yield put(saveCustomTemplatesResource(data));
    yield put(changeLoadingState(false));
  } catch (err) {
    yield put(changeLoadingState(false));
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
  }
}

export default function* templateSaga() {
  yield all([
    takeLatest(TemplateActionType.CREATE_CUSTOM_TEMPLATE, createCustomTemplate),
    takeLatest(TemplateActionType.EDIT_CUSTOM_TEMPLATE, editCustomTemplate),
    takeLatest(TemplateActionType.EDIT_SYSTEM_TEMPLATE, editSystemTemplate),
    takeLatest(TemplateActionType.ACTIVATE_SYSTEM_TEMPLATE, activateSystemTemplate),
    takeLatest(TemplateActionType.GET_CUSTOM_TEMPLATES, getCustomTemplates),
    takeLatest(TemplateActionType.GET_SYSTEM_TEMPLATES, getSystemTemplates),
    takeLatest(TemplateActionType.GET_SYSTEM_TEMPLATE_TYPES, getSystemTemplateTypes),
    takeLatest(TemplateActionType.GET_CUSTOM_TEMPLATE, getCustomTemplate),
    takeLatest(TemplateActionType.GET_SYSTEM_TEMPLATE, getSystemTemplate),
    takeLatest(TemplateActionType.DELETE_CUSTOM_TEMPLATE, deleteCustomTemplate),
    takeLatest(TemplateActionType.GET_CUSTOM_TEMPLATES_RESOURCE, getCustomTemplatesResource),
  ]);
}
