const CashbackActionType = {
  CREATE_CASH_BACK: 'CREATE_CASH_BACK',

  GET_CASH_BACKS: 'GET_CASH_BACKS',
  SAVE_CASH_BACKS: 'SAVE_CASH_BACKS',
  RESET_CASH_BACKS: 'RESET_CASH_BACKS',

  GET_CASH_BACK: 'GET_CASH_BACK',
  SAVE_CASH_BACK: 'SAVE_CASH_BACK',

  RESET_CASH_BACK: 'RESET_CASH_BACK',

  EDIT_CASH_BACK: 'EDIT_CASH_BACK',

  DELETE_CASH_BACK: 'DELETE_CASH_BACK',
  DELETE_INCLUDED_PLAYER: 'DELETE_INCLUDED_PLAYER',

  ENABLE_CASH_BACK: 'ENABLE_CASH_BACK',
  DISABLE_CASH_BACK: 'DISABLE_CASH_BACK',
  FINISH_CASH_BACK: 'FINISH_CASH_BACK',

  GET_CASH_BACK_STATISTICS: 'GET_CASH_BACK_STATISTICS',
  SAVE_CASH_BACK_ACCUMULATED: 'SAVE_CASH_BACK_ACCUMULATED',
  SAVE_CASH_BACK_TRANSFERRED: 'SAVE_CASH_BACK_TRANSFERRED',
  SAVE_CASH_BACK_FAILED: 'SAVE_CASH_BACK_FAILED',
  EXPORT_CASH_BACK_STATISTICS: 'EXPORT_CASH_BACK_STATISTICS',

  GET_PLAYER_CASH_BACK_PERIODS: 'GET_PLAYER_CASH_BACK_PERIODS',

  RESET_CASH_BACK_TRANSFERRED: 'RESET_CASH_BACK_TRANSFERRED',
  RESET_CASH_BACK_ACCUMULATED: 'RESET_CASH_BACK_ACCUMULATED',
  RESET_CASH_BACK_FAILED: 'RESET_CASH_BACK_FAILED',

  GET_CASH_BACK_PLAYERS: 'GET_CASH_BACK_PLAYERS',
  SAVE_CASH_BACK_PLAYERS: 'SAVE_CASH_BACK_PLAYERS',
  RESET_CASH_BACK_PLAYERS: 'RESET_CASH_BACK_PLAYERS',

  TRANSFER_CASH_BACK: 'TRANSFER_CASH_BACK',

  GET_CASH_BACK_INCLUDED_PLAYERS: 'GET_CASH_BACK_INCLUDED_PLAYERS',
  SAVE_CASH_BACK_INCLUDED_PLAYERS: 'SAVE_CASH_BACK_INCLUDED_PLAYERS',
  RESET_CASH_BACK_INCLUDED_PLAYERS: 'RESET_CASH_BACK_INCLUDED_PLAYERS',
  UPDATE_CASH_BACK_INCLUDED_PLAYERS: 'UPDATE_CASH_BACK_INCLUDED_PLAYERS',

  GET_PLAYER_CASH_BACKS: 'GET_PLAYER_CASH_BACKS',
  SAVE_PLAYER_CASH_BACKS: 'SAVE_PLAYER_CASH_BACKS',
  RESET_PLAYER_CASH_BACKS: 'RESET_PLAYER_CASH_BACKS',

  EXPORT_PLAYER_CASH_BACKS: 'EXPORT_PLAYER_CASH_BACKS',

  GET_PLAYER_ACCOUNT_CASH_BACK_PERIODS: 'GET_PLAYER_ACCOUNT_CASH_BACK_PERIODS',
  SAVE_PLAYER_ACCOUNT_CASH_BACK_PERIODS: 'SAVE_PLAYER_ACCOUNT_CASH_BACK_PERIODS',
  RESET_PLAYER_ACCOUNT_CASH_BACK_PERIODS: 'RESET_PLAYER_ACCOUNT_CASH_BACK_PERIODS',

  DELETE_CASH_BACK_PERIODS: 'DELETE_CASH_BACK_PERIODS',
  RESEND_CASH_BACK_PERIODS: 'RESEND_CASH_BACK_PERIODS',
  DELETE_CASH_BACK_NEW_PERIODS: 'DELETE_CASH_BACK_NEW_PERIODS',

  GET_CASH_BACK_STATISTICS_DETAILS: 'GET_CASH_BACK_STATISTICS_DETAILS',
  SAVE_CASH_BACK_STATISTICS_DETAILS: 'SAVE_CASH_BACK_STATISTICS_DETAILS',
  RESET_CASH_BACK_STATISTICS_DETAILS: 'RESET_CASH_BACK_STATISTICS_DETAILS',

  GET_CASH_BACK_PERIODS_RESOURCE: 'GET_CASH_BACK_PERIODS_RESOURCE',
  SAVE_CASH_BACK_PERIODS_RESOURCE: 'SAVE_CASH_BACK_PERIODS_RESOURCE',
  RESET_CASH_BACK_PERIODS_RESOURCE: 'RESET_CASH_BACK_PERIODS_RESOURCE',

  SAVE_PLAYER_ACCUMULATED_PERIODS: 'SAVE_PLAYER_ACCUMULATED_PERIODS',
  RESET_PLAYER_ACCUMULATED_PERIODS: 'RESET_PLAYER_ACCUMULATED_PERIODS',

  SAVE_PLAYER_FAILED_PERIODS: 'SAVE_PLAYER_FAILED_PERIODS',
  RESET_PLAYER_FAILED_PERIODS: 'RESET_PLAYER_FAILED_PERIODS',

  SAVE_PLAYER_TRANSFERRED_PERIODS: 'SAVE_PLAYER_TRANSFERRED_PERIODS',
  RESET_PLAYER_TRANSFERRED_PERIODS: 'RESET_PLAYER_TRANSFERRED_PERIODS',
};

export default CashbackActionType;
