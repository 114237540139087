import { createSelector } from 'reselect';
import enumTypes from 'constants/enumTypes';

export const selectCashBacks = (state) => state.cashBacks;
export const selectCashBacksTotal = (state) => state.total;
export const selectCashBack = (state) => state.cashBack;
export const selectCashBackName = (state) => selectCashBack(state).name;
export const selectCashBackAccumulated = (state) => state.accumulated;
export const selectCashBackAccumulatedTotal = (state) => state.accumulatedTotal;
export const selectCashBackTransferred = (state) => state.transferred;
export const selectCashBackTransferredTotal = (state) => state.transferredTotal;
export const selectCashBackFailed = (state) => state.failed;
export const selectCashBackFailedTotal = (state) => state.failedTotal;
export const selectCashBackPlayers = (state) => state.players;
export const selectCashBackPlayersTotal = (state) => state.playersTotal;
export const selectIncludedPlayers = (state) => state.includedPlayers;
export const selectPlayerCashBacks = (state) => state.playerCashBacks;
export const selectPlayerCashBacksTotal = (state) => state.playerCashBacksTotal;
export const selectCashBackStatisticsDetails = (state) => state.statisticsDetails;
export const selectCashBackPeriodsResource = (state) => state.cashBackPeriodsResource;

export const selectPlayerAccountCashBackPeriods = (state) => state.playerAccountCashBackPeriods;
export const selectPlayerAccountCashBackPeriod = (state, id) => createSelector(
  () => selectPlayerAccountCashBackPeriods(state),
  (data) => data[id] || [],
)();

export const selectPlayerAccumulatedPeriods = (state) => state.playerAccumulatedPeriods;
export const selectPlayerTransferredPeriods = (state) => state.playerTransferredPeriods;
export const selectPlayerFailedPeriods = (state) => state.playerFailedPeriods;

export const selectPlayerPeriod = (state, id, periodStatus) => createSelector(
  () => {
    let selectFunc = () => {};
    switch (periodStatus) {
    case enumTypes.cashBackReferralPeriodStatus.Transferred: {
      selectFunc = selectPlayerTransferredPeriods;
      break;
    }
    case enumTypes.cashBackReferralPeriodStatus.Failed: {
      selectFunc = selectPlayerFailedPeriods;
      break;
    }
    default: {
      selectFunc = selectPlayerAccumulatedPeriods;
      break;
    }
    }
    return selectFunc(state);
  },
  (data) => data[id] || [],
)();
