import axios from 'axios';
import qs from 'qs';
import Config from 'config';
import LocalStorageService from 'services/localStorageService';
import NavigationService from 'services/navigationService';
import { AppConstants } from 'constants/index';
import history from 'services/history';
import { changeTokenState } from 'actions/auth/authActionCreator';
import StoreService from 'services/storeService';
import MessageService from 'services/messageService';
import { resources } from 'constants/locale';

const { notification } = resources;

let isRefreshing = false;
let failedQueue = [];

const processQueue = (error, token = null) => {
  failedQueue.forEach((prom) => {
    if (error) {
      prom.reject();
    } else {
      prom.resolve(token);
    }
  });

  failedQueue = [];
};

const loginBuilder = (data) => {
  const iframe = document.getElementById('ui-customization');
  if (iframe) {
    const win = iframe.contentWindow;
    win.postMessage(JSON.stringify({ key: 'authorizationData', method: 'add', data }), '*');
  }
};

const clearAndLogout = () => {
  const currentData = LocalStorageService.get('current');
  LocalStorageService.clear();
  LocalStorageService.set('current', currentData);
  const currentLanguageId = currentData?.languageId;
  if (currentLanguageId) {
    const errorArr = {
      response: { data: [{ errorMessage: notification[currentLanguageId].sessionExpired }] },
    };
    processQueue(errorArr, null);
  }
  const { pathname, search } = history.location;
  // save state before redirecting login page
  NavigationService(pathname + search);
  if (currentLanguageId) {
    MessageService.error(notification[currentLanguageId].sessionExpired);
  }
};

axios.interceptors.response.use(
  (response) => response,
  (error) => {
    const originalRequest = error.config;
    if (error.response.status === 401 && !originalRequest.retry) {
      if (isRefreshing) {
        return new Promise((resolve, reject) => {
          failedQueue.push({ resolve, reject });
        })
          .then((token) => {
            originalRequest.headers.Authorization = `Bearer ${token}`;
            return axios(originalRequest);
          })
          .catch((err) => Promise.reject(err));
      }

      const auth = LocalStorageService.get('auth');
      const refToken = auth ? auth.refresh_token : null;

      // logout if no refresh token
      if (!refToken) {
        if (auth) {
          clearAndLogout();
        }
        return Promise.reject();
      }

      originalRequest.retry = true;
      isRefreshing = true;
      const formData = qs.stringify({
        grant_type: 'refresh_token',
        client_id: 'admin_client',
        refresh_token: refToken,
      });

      const options = {
        data: formData,
        headers: { 'content-type': 'application/x-www-form-urlencoded' },
      };

      const { Admin, Account, Token } = AppConstants.api;
      const url = [Admin, Account, Token].join('/');
      return new Promise((resolve, reject) => {
        axios({
          method: AppConstants.methods.POST,
          baseURL: Config.baseURL,
          url,
          ...options,
        })
          .then(({ data }) => {
            LocalStorageService.set('auth', data);
            loginBuilder(data);
            axios.defaults.headers.common.Authorization = `Bearer ${data.access_token}`;
            originalRequest.headers.Authorization = `Bearer ${data.access_token}`;
            processQueue(null, data.access_token);
            StoreService.getStore().dispatch(changeTokenState(true));
            resolve(axios(originalRequest));
          })
          .catch(() => {
            clearAndLogout();
            reject();
          })
          .then(() => {
            isRefreshing = false;
          });
      });
    }

    return Promise.reject(error);
  },
);

export default axios;
