import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { withTheme } from 'styled-components';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { StyledBreadcrump } from 'components/styledComponents/breadcrump/Breadcrump.styled';
import { handleNavigate } from 'utils/helpers';
import IconSvg from 'components/common/icon/IconSvg';

const BreadcrumbNavigation = (props) => {
  const {
    breadcrumb,
    theme: { colors },
    resetBreadCrumbParams,
  } = props;

  const params = useParams();

  const { t } = useTranslation();

  useEffect(() => () => resetBreadCrumbParams(), [resetBreadCrumbParams]);
  const itemRender = ({ path, title, isActive }) => <span onMouseDown={(e) => (isActive === false ? null : handleNavigate(e, path))}>{title}</span>;

  const getItems = () => {
    if (breadcrumb) {
      return breadcrumb.map((route) => {
        let { path } = route;
        if (Object.keys(params).length && path) {
          path = Object.keys(params).reduce((result, param) => result.replace(`:${param}`, params[param]), path);
        }

        return {
          ...route,
          className: route.isActive === false ? 'inactive-item' : '',
          title: route.key ? route.name : t(route.name),
          path,
        };
      });
    }

    return [];
  };

  return <StyledBreadcrump separator={<IconSvg icon="ArrowRightIcon" width="0.29rem" height="0.5rem" color={colors.primaryText} />} items={getItems()} itemRender={itemRender} />;
};

BreadcrumbNavigation.propTypes = {
  breadcrumb: PropTypes.array.isRequired,
  theme: PropTypes.object.isRequired,
  resetBreadCrumbParams: PropTypes.func.isRequired,
};

export default withTheme(BreadcrumbNavigation);
