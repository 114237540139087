import styled from 'styled-components';
import { flexContainer, media } from 'utils/styledHelpers';
import { StyledBrandsName } from 'components/brand/Brand.styled';

const StyledBanner = styled.div`
  ${flexContainer()};
  position: relative;
  background: linear-gradient(to right, #74276b, #00a1af);
  height: 4.57rem;
  flex: 0;
  color: ${(props) => props.theme.colors.white};
  border-radius: ${(props) => props.theme.customBorderRadius.borderRadiusMiddle};
  padding: 1rem 1.71rem;
  margin-bottom: 1.14rem;
`;
const StyledBannerWrapper = styled.div`
  height: 4.57rem;
  > div {
    margin-bottom: 0;
    height: 4.57rem;
  }
`;

const StyledBannerRadius = styled(StyledBanner)`
  // If props.type is equal to 1, gradient color would be from purple to blue(ltr). Else if props.type is equal to 0, gradient color woult be from dark blue to light blue((ltr))
  background: ${(props) => (!props.type ? 'transparent linear-gradient(90deg, #116984 0%, #00A1AF 100%) 0% 0% no-repeat padding-box;' : '')};
  position: relative;
  height: 4.57rem;
  ${({ src }) => src
    && `
    &:before {
      position: absolute;
      top: 0;
      right: 0;
      content: '';
      background: url(${src} top right no-repeat;
      border-radius: ${(props) => props.theme.customBorderRadius.borderRadiusMiddle};
      @media (max-width: 1200px) {
        background-size: 90% 100%;
      }
      width: 100%;
      height: 100%;
    }
  `}
`;
const StyledBannersTitle = styled(StyledBrandsName)`
  p {
    font-size: ${(props) => props.theme.typography.fontSizeDefault};
    color: ${(props) => props.theme.colors.white};
    font-weight: ${(props) => props.theme.typography.fontWeightRegular};
  }
  height: 4.57rem;
  display: flex;
  flex-direction: column;
  justify-content: center;

  ${media.sm`
    height: 2rem;
  `}
`;

export {
  StyledBannerWrapper, StyledBannerRadius, StyledBannersTitle, StyledBanner,
};
