export const selectNotificationSentStatus = (state) => state.sentStatus;
export const selectTemplateTagsResource = (state) => state.tagsResource;
export const selectMassMessages = (state) => state.massMessages;
export const selectMessagesTotal = (state) => state.total;
export const selectMassMessage = (state) => state.massMessage;
export const selectMassMessageName = (state) => selectMassMessage(state).name;
export const selectInboxMessages = (state) => state.inboxMessages;
export const selectMassMessageRecipients = (state) => state.recipients.data;
export const selectMassMessageRecipientsTotal = (state) => state.recipients.total;
export const selectMassMessageTitleExists = (state) => state.titleExists;

const selectBonusNotifications = (state) => state.bonusNotifications;
export const selectBonusNotificationsData = (state) => selectBonusNotifications(state).data;
export const selectBonusNotificationsTotal = (state) => selectBonusNotifications(state).total;

export const selectBonusNotification = (state) => state.bonusNotification;
