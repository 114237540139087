import TranslationActionType from 'actions/translation/translationActionType';

const initialState = {
  sections: [],
  loading: false,
  translations: [],
  translationsByKey: [],
  isDataChanged: false,
  languages: [],
  translatedLanguagesLoading: false,
};

const translationReducer = (state = initialState, { type, payload }) => {
  switch (type) {
  case TranslationActionType.GET_TRANSLATION_SECTIONS_SUCCESS:
    return {
      ...state,
      sections: payload.data,
    };
  case TranslationActionType.CHANGE_TRANSLATION_LOADING_STATE:
    return {
      ...state,
      loading: payload.state,
    };
  case TranslationActionType.RESET_TRANSLATION_SECTIONS:
    return {
      ...state,
      sections: [],
    };
  case TranslationActionType.CHANGE_TRANSLATED_LANGUAGES_LOADING_STATE:
    return {
      ...state,
      translatedLanguagesLoading: payload.state,
    };
  case TranslationActionType.GET_ALL_TRANSLATIONS_BY_KEY_SUCCESS:
    return {
      ...state,
      translationsByKey: payload.data,
    };
  case TranslationActionType.RESET_ALL_TRANSLATIONS_BY_KEY:
    return {
      ...state,
      translationsByKey: [],
    };
  case TranslationActionType.SAVE_ALL_TRANSLATIONS:
    return {
      ...state,
      translations: payload.data,
    };
  case TranslationActionType.RESET_ALL_TRANSLATIONS:
    return {
      ...state,
      translations: [],
    };
  case TranslationActionType.SET_TRANSLATIONS_CHANGES_STATE:
    return {
      ...state,
      isDataChanged: payload.state,
    };
  case TranslationActionType.GET_TRANSLATED_LANGUAGES_SUCCESS:
    return {
      ...state,
      languages: payload.data,
    };
  case TranslationActionType.RESET_TRANSLATED_LANGUAGES:
    return {
      ...state,
      languages: [],
    };
  default:
    return state;
  }
};

export default translationReducer;
