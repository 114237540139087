import PropTypes from 'prop-types';
import { Form } from 'antd';
import { useTranslation } from 'react-i18next';
import { FormItemErrorMessage } from 'components/common';
import { FormInputWrapper } from 'components/styledComponents/input/Input.styled';
import { StyledStatusChange } from 'components/brandSetting/BrandSettings.styled';
import { StyledFormMainWrapper } from 'components/styledComponents/form/Form.styled';
import { AdminSelect } from 'components/common/form';

const ChangeTimeZoneForm = (props) => {
  const { brand, form, timeZones } = props;
  const { t } = useTranslation();

  return (
    <StyledStatusChange>
      <StyledFormMainWrapper form={form} initialValues={brand} layout="vertical">
        <FormInputWrapper midSize>
          <Form.Item
            name="timeZone"
            label={t('timeZone')}
            rules={[
              {
                required: true,
                message: <FormItemErrorMessage errorMassage={(translate) => translate('validate:requiredField', { fieldName: translate('timeZone') })} />,
              },
            ]}
          >
            <AdminSelect size="" placeholder={t('select')} filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())} data={timeZones} isToString={false} />
          </Form.Item>
        </FormInputWrapper>
      </StyledFormMainWrapper>
    </StyledStatusChange>
  );
};

ChangeTimeZoneForm.propTypes = {
  brand: PropTypes.object.isRequired,
  form: PropTypes.object.isRequired,
  timeZones: PropTypes.array.isRequired,
};

export default ChangeTimeZoneForm;
