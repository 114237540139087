export const selectPlayersSegmentsResource = (state) => state.playersSegmentsResource;
export const selectSegmentPlayersResource = (state) => state.segmentPlayersResource;
const selectPlayersSegments = (state) => state.playersSegments;
export const selectPlayersSegmentsData = (state) => selectPlayersSegments(state).data;
export const selectPlayersSegmentsTotal = (state) => selectPlayersSegments(state).total;
const selectSegmentsPlayers = (state) => state.segmentsPlayers;
export const selectSegmentsPlayersData = (state) => selectSegmentsPlayers(state).data;
export const selectSegmentsPlayersTotal = (state) => selectSegmentsPlayers(state).total;
const selectPlayerIncludedSegments = (state) => state.playerIncludedSegments;
export const selectPlayerIncludedSegmentsData = (state) => selectPlayerIncludedSegments(state).data;
export const selectPlayerIncludedSegmentsTotal = (state) => selectPlayerIncludedSegments(state).total;
export const selectPlayerExcludedSegments = (state) => state.playerExcludedSegments;
export const selectStatusUpdatedPlayersSegments = (state) => state.statusUpdatedPlayersSegments;
export const selectPlayerSegment = (state) => state.playerSegment;
export const selectPlayerSegmentName = (state) => selectPlayerSegment(state).name;
