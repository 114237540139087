import { useSelector } from 'react-redux';
import { selectUserConfig } from 'selectors';
import { DateService } from 'services';
import { settingKeys } from 'constants/configKeys';

const {
  getFormattedDate,
  setDateTz,
  getEndDate,
  getStartDate,
  setFormattedDate,
  getIsoDate,
  getDateRanges,
  convertDateObjectToDateString,
  dateTz,
  getLocalDate,
  date,
  getDateTz,
  getFormattedDateByTimeZone,
} = DateService;

const useTimeZone = () => {
  const timeZone = useSelector(({ users }) => selectUserConfig(users, settingKeys.timeZone));

  setDateTz(timeZone);

  return {
    getFormattedDate: (...arg) => getFormattedDate(...arg),
    getFormattedDateByTimeZone: (...arg) => getFormattedDateByTimeZone(...arg),
    getEndDate,
    getStartDate,
    setFormattedDate,
    getIsoDate,
    getDateRanges: (arg) => getDateRanges(arg),
    convertDateObjectToDateString,
    dateTz,
    getLocalDate,
    setDateTz,
    date,
    getDateTz,
  };
};

export default useTimeZone;
