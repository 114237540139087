import PartnerActionType from './partnerActionType';

export const addPartner = (data) => ({
  type: PartnerActionType.ADD_PARTNER,
  payload: { data },
});

export const getPartners = (params) => ({
  type: PartnerActionType.GET_PARTNERS,
  payload: { params },
});

export const getPartnersSuccess = (data) => ({
  type: PartnerActionType.GET_PARTNERS_SUCCESS,
  payload: { data },
});

export const editPartner = (id, data) => ({
  type: PartnerActionType.EDIT_PARTNER,
  payload: { id, data },
});

export const getPartner = (id) => ({
  type: PartnerActionType.GET_PARTNER,
  payload: { id },
});

export const resetPartner = () => ({
  type: PartnerActionType.RESET_PARTNER,
});

export const getPartnerSuccess = (data) => ({
  type: PartnerActionType.GET_PARTNER_SUCCESS,
  payload: { data },
});

export const resetPartners = () => ({
  type: PartnerActionType.RESET_PARTNERS,
});

export const setCurrentPartner = (id) => ({
  type: PartnerActionType.SET_CURRENT_PARTNER,
  payload: { id },
});

export const getPartnerAccesses = (partnerId) => ({
  type: PartnerActionType.GET_PARTNER_ACCESSES,
  payload: { partnerId },
});

export const getPartnerAccessesSuccess = (data) => ({
  type: PartnerActionType.GET_PARTNER_ACCESSES_SUCCESS,
  payload: { data },
});

export const resetPartnerAccesses = () => ({
  type: PartnerActionType.RESET_PARTNER_ACCESSES,
});

export const changePartnerAccessesLoading = (state) => ({
  type: PartnerActionType.CHANGE_PARTNER_ACCESSES_LOADING,
  payload: { state },
});

export const changePartnerLoadingState = (state) => ({
  type: PartnerActionType.CHANGE_PARTNER_LOADING_STATE,
  payload: { state },
});

export const changePartnerBrandsStatuses = (data, hasMaintenance, name) => ({
  type: PartnerActionType.CHANGE_PARTNER_BRANDS_STATUSES,
  payload: { data, hasMaintenance, name },
});
