import styled from 'styled-components';
import { Button } from 'antd';
import { StyledInputAddWrapper, StyledInputAddGroup } from 'components/styledComponents/input/Input.styled';
import { StyledRadioHover } from 'components/styledComponents/radio/Radio.styled';
import { getBoxShadow, media } from 'utils/styledHelpers';

const SelectBonusGroupTitle = styled.span`
  font-size: ${(props) => props.theme.typography.fontSizeMiddle};
  font-weight: ${(props) => props.theme.typography.fontWeightBold};
  display: inline-block;
  position: relative;
  margin-bottom: 2.1rem;
  &:before,
  &:after {
    content: '';
    width: 2.29rem;
    background-color: ${(props) => props.theme.colors.primaryBorder};
    height: 1px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }
  &:before {
    left: -3rem;
  }
  &:after {
    right: -3rem;
  }
`;
const ChooseBonusTypeContent = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  flex-grow: 1;
  padding: 2.85rem 0 0;
  .ant-radio-inner:after {
    display: block;
  }
  ul {
    flex-wrap: nowrap;
    ${media.sm`
      flex-direction: column;
    `}
  }
`;
const BonusTypeSelectList = styled.div`
  position: relative;
  width: 100%;

  .ant-radio-group {
    display: flex;
    flex-direction: column;
    padding: 0 2.28rem;
    width: 100%;
    height: 100%;
    margin-bottom: 2.1rem;
    span {
      color: ${(props) => (props.isSelected ? props.theme.colors.filterLabelColor : props.theme.colors.primaryText)};
    }
    label.ant-radio-wrapper {
      margin-right: 0;
      margin-top: 1.14rem;
      width: 100%;
      white-space: pre-wrap;
      display: flex;
      align-items: center;

      span.ant-radio + * {
        padding-right: 0;
        width: 100%;
        height: 100%;
        word-break: break-all;
        line-height: 1.43rem;
        font-size: ${(props) => props.theme.typography.fontSizeDefault};
        display: flex;
        align-items: center;
        svg {
          margin-left: 0.57rem;
        }
      }
    }
  }
`;
const StyledRadioListItem = styled(StyledRadioHover)`
  min-width: 16rem;
  display: flex;
  flex-direction: column;
  .ant-radio-button-wrapper-checked + span {
    color: ${(props) => props.theme.colors.blue};
  }
  .ant-radio-wrapper-disabled span {
    color: ${(props) => props.theme.colors.filterLabelColor};
  }
  > div {
    padding: 2.28rem 0;
    height: 100%;
    box-shadow: ${() => getBoxShadow('#000000')}10;
    cursor: pointer;
    border-radius: ${(props) => props.theme.customBorderRadius.borderRadiusMiddle};
    border: 1px solid ${(props) => props.theme.colors.secondaryBorder}60;
    background: ${(props) => props.theme.colors.mainCardBg};
    transition: 0.2s box-shadow ease-in-out;
    box-shadow: ${(props) => (props.isActive ? `${getBoxShadow('#000000')}35` : '')};
    &:hover {
      box-shadow: ${() => getBoxShadow('#000000')}35;
    }
    label.ant-radio-button-wrapper {
      border: none;
      border-radius: 50% !important;
      background-color: ${(props) => (props.isActive ? props.color : props.theme.colors.tableTrEvenColor)};
    }
  }
  transition: all 0.1s ease-in-out;

  &:hover {
    .ant-radio-button-wrapper {
      transform: scale(1.1);
      transition: all 0.1s ease-in-out;
      + span {
        color: ${(props) => props.color};
      }
    }
    label.ant-radio-button-wrapper {
      background: ${(props) => !props.isActive && props.color}20;
    }
    > span {
      color: ${(props) => props.theme.colors[props.color]};
    }
  }
`;
const StyledRadioListItemTitle = styled.span`
  font-size: ${(props) => props.theme.typography.fontSizeMiddle};
  font-weight: ${(props) => props.theme.typography.fontWeightMedium};
  text-align: center;
  display: block;
  margin-top: 1.14rem;
  color: ${(props) => (props.isActive ? props.color : props.theme.colors.primaryText)};
`;

const BonusTriggerDescription = styled.div`
  width: 11.2rem;
  font-size: ${(props) => props.theme.typography.fontSizeDefault};
  margin: 1.14rem auto 0;
  line-height: 1.43rem;
  text-align: center;
  color: ${(props) => props.theme.colors.primaryText};
`;
const StyledPlaythroughWrapper = styled.div`
  .ant-row.ant-form-item-row {
    flex-direction: row !important;

    .ant-col:first-child {
      order: 2;
    }
    .ant-col:last-child {
      width: auto;
      order: 1;
      flex-grow: 0;
    }
  }
  .ant-form-item-control-input-content {
    margin-right: 0.71rem;
  }
  .ant-form-item-label {
    padding-bottom: 0;
    label {
      cursor: pointer;
    }
  }
`;

const GameConfigTablesWrapper = styled.div`
  .custom-checkbox {
    padding-left: 0.85rem !important;
  }
  .ant-checkbox-wrapper {
    padding-left: 1rem;
  }
  border: 1px solid ${(props) => props.theme.colors.primaryBorder};
  border-radius: ${(props) => props.theme.customBorderRadius.borderRadiusSmall};
  .ant-input-prefix {
    position: absolute;
    right: 20px;
    top: 50%;
    transform: translateY(-50%);
  }
  .ant-table-container .ant-table-body {
    overflow-y: auto !important;
  }

  .ant-table-wrapper {
    padding: 0 1rem 0 0;

    .ant-table-container {
      height: 100%;
      background-color: transparent;
    }
  }
  .ant-table-selection {
    display: flex;
  }
  .ant-empty-normal {
    margin: 0;
  }
  .ant-input-suffix {
    margin-right: 3.2rem;
  }
  .ant-input-clear-icon {
    font-size: 1.14rem;
    svg path {
      fill: ${(props) => props.theme.colors.secondaryColor};
    }
  }

  .ant-input-number-sm {
    .ant-input-number-input-wrap {
      display: flex;
      align-items: center;
      height: 100%;
    }
    input {
      height: 100%;
      font-size: ${(props) => props.theme.typography.fontSizeSmall};
    }
  }
  .ant-input-affix-wrapper {
    border: 1px solid transparent;
  }
`;

const BonusTemplatesGameConfig = styled.div`
  .ant-table-thead > tr > th:before,
  .ant-table-thead > tr > td:before {
    display: none;
  }
  .ant-checkbox-wrapper-disabled {
    opacity: 0.4;
  }
  .ant-table-container {
    .ant-table-thead,
    .ant-table-thead > tr {
      height: 2.29rem !important;
      > th,
      > td {
        background-color: ${(props) => props.theme.colors.secondaryBg}!important;
        font-weight: ${(props) => props.theme.typography.fontWeightMedium}!important;
        box-shadow: none;
      }
    }
    .ant-table-placeholder {
      background: transparent;

      .ant-empty {
        margin: 7rem 0;
      }
    }
    th.ant-table-cell-scrollbar {
      display: none;
    }
    tr,
    th,
    td,
    .ant-table-row,
    .ant-table-cell {
      border: none !important;
    }
  }
  .ant-input-number {
    width: 3.3rem;
    height: 1.43rem;

    &-disabled {
      opacity: 0.4;
    }
  }
`;
const SelectTableWrapper = styled.div`
  .ant-table-wrapper,
  .ant-spin-nested-loading,
  .ant-spin-container,
  .ant-table-empty {
    height: 100%;
  }
  .ant-table-container {
    margin: 1rem 0;
  }
  .ant-empty-normal {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  > div {
    height: 100%;
  }
`;
const SelectProviderTableWrapper = styled(SelectTableWrapper)`
  .ant-table-wrapper .ant-table .ant-table-tbody > tr:not(.ant-table-expanded-row) > td {
    background: transparent;
    &:not(.custom-checkbox) {
      text-align: left;
    }
  }
  height: calc(100% - 3.2rem);
  background: ${(props) => props.theme.colors.secondaryBg};
  min-height: 10rem;
  border-radius: ${(props) => props.theme.customBorderRadius.borderRadiusSmall};

  ${media.sm`
    min-height: unset;  
  `}
`;

const SelectGameTypeTableWrapper = styled(SelectTableWrapper)`
  height: calc(100% - 3.2rem);
  border-left: 1px solid ${(props) => props.theme.colors.primaryBorder};
  border-right: 1px solid ${(props) => props.theme.colors.primaryBorder};
  background: ${(props) => props.theme.colors.secondaryBg};

  ${media.sm`
    border: none;  
  `}

  .ant-table-wrapper .ant-table .ant-table-tbody > tr:not(.ant-table-expanded-row) > td {
    background: transparent;
  }
`;
const GameListTableWrapper = styled(SelectTableWrapper)`
  height: calc(100% - 3.2rem);
  border-radius: ${(props) => props.theme.customBorderRadius.borderRadiusSmall};
  background: ${(props) => props.theme.colors.secondaryBg};
  .ant-table-wrapper .ant-table .ant-table-tbody > tr:not(.ant-table-expanded-row) > td {
    background: transparent;
  }
`;

const ProviderCategoryWrapper = styled.div`
  text-align: center;
  background-color: ${(props) => props.theme.colors.primaryBg};
  border-radius: ${(props) => props.theme.customBorderRadius.borderRadiusSmall};
  padding: 0.29rem 0.64rem;
  font-size: 0.79rem;
  word-break: break-word;
  white-space: pre-wrap;
  display: inline-block;
  border: 1px solid ${(props) => props.theme.colors.primaryBorder};
`;

const StyledDepositAmount = styled.span`
  background-color: ${(props) => props.theme.colors.primaryBg};
  border-radius: ${(props) => props.theme.customBorderRadius.borderRadiusSmall};
  display: inline-block;
  padding: 0.4rem;
  font-size: ${(props) => props.theme.typography.fontSizeDefault};
  margin-left: 0.8rem;
`;
const OptionsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 1.14rem;
`;
const OptionsItem = styled.div`
  background-color: ${(props) => (props.dark ? props.theme.colors.tableHeadColor : props.theme.colors.primaryBg)};
  border-radius: ${(props) => props.theme.customBorderRadius.borderRadiusSmall};
  padding: 0.36rem 0.57rem;
  color: ${(props) => props.theme.colors.primaryText};
  font-size: 1rem;
`;

const GameConfigSearch = styled.div`
  height: 3.1rem;
  .ant-input-affix-wrapper {
    height: 100%;
    padding-left: 1.71rem;
    &:focus,
    &-focused {
      box-shadow: none;
    }
    .ant-input {
      background: transparent;
      &::placeholder {
        color: ${(props) => props.theme.colors.filterLabelColor};
        padding-left: 0.1rem;
      }

      &::-ms-input-placeholder {
        color: ${(props) => props.theme.colors.primaryText};
        padding-left: 0.1rem;
      }
      &::-ms-input-placeholder {
        color: ${(props) => props.theme.colors.primaryText};
        padding-left: 0.1rem;
      }
      text-indent: 0;
    }
  }
`;
const GameConfigSearch1 = styled(GameConfigSearch)`
  height: 100%;

  .ant-input-affix-wrapper {
    border-bottom: 1px solid ${(props) => props.theme.colors.primaryBorder};
    border-radius: ${(props) => props.theme.customBorderRadius.borderRadiusSmall} 0 0 0;
  }
  > div {
    height: 100%;
  }
`;
const GameConfigSearch3 = styled(GameConfigSearch)`
  height: 100%;
  .ant-input-affix-wrapper {
    border-bottom: 1px solid ${(props) => props.theme.colors.primaryBorder};
    border-radius: 0 4px 0 0;

    ${media.sm`
      border-top-color: ${(props) => props.theme.colors.primaryBorder};
      border-radius: 0;
    `}
  }
  > div {
    height: 100%;
  }
`;
const GameConfigSearch2 = styled(GameConfigSearch)`
  height: 100%;
  .ant-input-affix-wrapper {
    border-color: ${(props) => props.theme.colors.primaryBorder};
    border-top: 1px solid transparent;
    border-radius: 0;

    ${media.sm`
      border-top-color: ${(props) => props.theme.colors.primaryBorder};
      border-left: none;
      border-right: none;
    `}
  }
  > div {
    height: 100%;
  }
`;
const LeftAlignedText = styled.p`
  text-align: left;
`;
const MarginBottomDistance = styled.div`
  margin-bottom: ${(props) => (props.isThin ? '2.28rem' : '3rem')};
  p {
    margin-bottom: 1.14rem;
  }
  .ant-radio-wrapper span {
    font-size: 1rem;
  }
`;
const StyledTableDeposit = styled.div`
  margin: 1.71rem 0 0 0;
`;
const StyledRolloverInfo = styled.div`
  margin: 2.14rem 0 1.15rem;
`;

const StyledProvidersAndGames = styled.div`
  .ant-table.ant-table-middle .ant-table-tbody > tr,
  .ant-table .ant-table-thead > tr {
    height: 2.29rem;
  }
  .ant-table-container {
    height: 100%;
  }
`;

const StyledConfigMarginWrapper = styled.div`
  max-height: 6rem;
  opacity: 0;
  animation: animateHeight 0.8s forwards;
  overflow: hidden;

  ${media.sm`
      animation: none;
      opacity: 1;
      max-height: 100%;
      overflow: visible;
  `}

  @keyframes animateHeight {
    to {
      max-height: 40rem;
      opacity: 1;
    }
  }
`;

const StyledInputAddTabs = styled(StyledInputAddWrapper)`
  margin: 0 0 2.28rem 0;
`;

const StyledOpenPopupIcon = styled.div`
  margin: 0;
`;
const StyledApplyWrapper = styled.div`
  position: absolute;
  z-index: 1001;
  top: -1.43rem;
  padding: 1.71rem;
  left: ${(props) => `${props.left + 1}px`};
  box-shadow: ${(props) => getBoxShadow(props.theme.colors.secondaryShadow)};
  background: ${(props) => props.theme.colors.secondaryBg};
`;

const StyledApplyGroupWrapper = styled(StyledInputAddGroup)`
  display: flex;
  flex-direction: column;
  div {
    color: ${(props) => props.theme.colors.primaryText}60;
  }
`;

const StyledApplyButton = styled(Button)`
  margin: 1.14rem 0 0 auto;
`;

const StyledAddButton = styled(Button)`
  font-size: 1rem;
  height: 2.57rem;
  padding: 0.4rem 0.85rem;
  min-width: 7.1rem;
  &:hover {
    background: ${(props) => props.theme.colors.hover};
  }
  transition: 0.3s all ease-in-out;
`;

const StyledDepositAmountTabWrapper = styled.div`
  position: ${(props) => (props.isRelative ? 'relative' : 'initial')};
`;

const StyledCustomCheckboxLabel = styled.label`
  cursor: pointer;
`;

// free spin
const StyledFreeSpinGamesWrapper = styled.div`
  position: relative;
  border: 1px solid ${(props) => props.theme.colors.secondaryBorder};
  padding: 1.71rem;
  margin: 1.71rem 0 0;
  border-radius: ${(props) => props.theme.customBorderRadius.borderRadiusMiddle};
  box-shadow: ${(props) => getBoxShadow(props.theme.colors.secondaryShadow)};
  transition: 0.5s all;
  max-height: ${(props) => (props.expanded ? '50rem' : '12rem')};
  overflow: hidden;
`;
const StyledFreeSpinGamesLabel = styled.p`
  margin-bottom: 1.14rem;
  font-weight: ${(props) => props.theme.typography.fontWeightMedium};
`;

const StyledFreeSpinGamesInfo = styled.div`
  color: 1px solid ${(props) => props.theme.colors.primaryText};
  > span {
    font-weight: ${(props) => props.theme.typography.fontWeightMedium};
  }
`;

const StyledSelectedGamesCount = styled.div`
  color: ${(props) => props.theme.colors.primaryText};
  font-size: ${(props) => props.theme.typography.fontSizeDefault};
  span {
    font-weight: ${(props) => props.theme.typography.fontWeightMedium};
    padding-left: 0.3rem;
  }
`;

const StyledFreeSpinAmountConfigWrapper = styled.div`
  position: relative;
  border: ${(props) => (props.expanded ? 'none' : `1px solid ${props.theme.colors.secondaryBorder}`)};
  padding: ${(props) => (props.expanded ? '.2rem 0 0' : '1.71rem 1.71rem 0')};
  border-radius: ${(props) => (props.expanded ? '0' : props.theme.customBorderRadius.borderRadiusMiddle)};
  box-shadow: ${(props) => (props.expanded ? 'none' : getBoxShadow(props.theme.colors.secondaryShadow))};
  max-height: ${(props) => (props.expanded ? '50rem' : '12rem')};
  transition: 0.5s max-height;
  overflow: hidden;
`;

export {
  SelectBonusGroupTitle,
  StyledProvidersAndGames,
  StyledTableDeposit,
  ChooseBonusTypeContent,
  BonusTypeSelectList,
  StyledRadioListItem,
  StyledRadioListItemTitle,
  BonusTriggerDescription,
  BonusTemplatesGameConfig,
  GameListTableWrapper,
  SelectProviderTableWrapper,
  SelectGameTypeTableWrapper,
  GameConfigTablesWrapper,
  ProviderCategoryWrapper,
  StyledDepositAmount,
  OptionsWrapper,
  StyledRolloverInfo,
  OptionsItem,
  GameConfigSearch,
  GameConfigSearch1,
  GameConfigSearch2,
  GameConfigSearch3,
  LeftAlignedText,
  MarginBottomDistance,
  SelectTableWrapper,
  StyledConfigMarginWrapper,
  StyledInputAddTabs,
  StyledOpenPopupIcon,
  StyledApplyWrapper,
  StyledApplyGroupWrapper,
  StyledApplyButton,
  StyledAddButton,
  StyledDepositAmountTabWrapper,
  StyledPlaythroughWrapper,
  StyledCustomCheckboxLabel,
  StyledFreeSpinGamesWrapper,
  StyledFreeSpinGamesLabel,
  StyledFreeSpinGamesInfo,
  StyledSelectedGamesCount,
  StyledFreeSpinAmountConfigWrapper,
};
