import SystemConversionActionType from './systemConversionActionType';

export const getSystemConversions = (params) => ({
  type: SystemConversionActionType.GET_SYSTEM_CONVERSIONS,
  payload: { params },
});

export const saveSystemConversions = (data) => ({
  type: SystemConversionActionType.SAVE_SYSTEM_CONVERSIONS,
  payload: { data },
});

export const resetSystemConversions = () => ({
  type: SystemConversionActionType.RESET_SYSTEM_CONVERSIONS,
});

export const importSystemConversions = (data) => ({
  type: SystemConversionActionType.IMPORT_SYSTEM_CONVERSIONS,
  payload: { data },
});

export const exportSystemConversions = (params) => ({
  type: SystemConversionActionType.EXPORT_SYSTEM_CONVERSIONS,
  payload: { params },
});

export const editSystemConversion = (id, data) => ({
  type: SystemConversionActionType.EDIT_SYSTEM_CONVERSION,
  payload: { id, data },
});

export const getSystemConversionLogs = (id) => ({
  type: SystemConversionActionType.GET_SYSTEM_CONVERSION_LOGS,
  payload: { id },
});

export const saveSystemConversionLogs = (data) => ({
  type: SystemConversionActionType.SAVE_SYSTEM_CONVERSION_LOGS,
  payload: { data },
});

export const resetSystemConversionLogs = () => ({
  type: SystemConversionActionType.RESET_SYSTEM_CONVERSION_LOGS,
});

export const setCurrentConversion = (id) => ({
  type: SystemConversionActionType.SET_CURRENT_CONVERSION,
  payload: { id },
});

export const getSystemConversionsResource = (isLoading) => ({
  type: SystemConversionActionType.GET_SYSTEM_CONVERSIONS_RESOURCE,
  payload: { isLoading },
});

export const saveSystemConversionsResource = (data) => ({
  type: SystemConversionActionType.SAVE_SYSTEM_CONVERSIONS_RESOURCE,
  payload: { data },
});
