import styled from 'styled-components';
import _ from 'lodash';
import { StyledButtonGrayIcon } from 'components/styledComponents/button/Button.styled';
import { media } from 'utils/styledHelpers';

const FormItemWrapper = styled.div`
  .ant-form-item-required:after {
    content: '${(props) => (props.isHintLabel ? '' : '*')}' !important;
    display: ${(props) => (props.isHintLabel ? 'none' : 'inline-block')}!important;
    color: ${(props) => props.theme.colors.filterLabelColor}!important;
  }

  .ant-picker-panel-container,
  .ant-picker-range-arrow::after {
    background: ${(props) => props.theme.colors.tableTrEvenColor};
  }
  & > span:first-child {
    display: block;
    color: ${(props) => props.theme.colors.secondaryColor};
    margin-bottom: 8px;
  }

  .ant-form-item .ant-form-item-label > label {
    color: ${(props) => (props.notFilterLabel || props.darkColor ? props.theme.colors.primaryText : props.theme.colors.filterLabelColor)};
  }
  .ant-form-item-label .ant-form-item-required:after {
    color: ${(props) => (props.notFilterLabel || props.darkColor ? props.theme.colors.primaryText : props.theme.colors.filterLabelColor)}!important;
  }
  .ant-picker.ant-picker-range,
  .ant-input {
    height: ${(props) => (props.midSize ? '2.57rem' : '')};
  }
  .ant-picker-header {
    > button:hover {
      color: ${(props) => props.theme.colors.hover};
    }
  }
`;

const FilterButtonGroup = styled.div`
  display: flex;
  height: 100%;
  align-items: flex-end;

  ${media.sm`
    padding: 0 0.87rem;
  `}

  > .ant-btn {
    width: auto;
    padding: 0 2.29rem;
    min-width: 7.15rem;
    font-size: 1rem;
    font-weight: 500;
    &:first-child {
      margin-right: 0.57rem;
    }
    &:last-child {
      > span {
        color: ${(props) => props.theme.colors.primaryText};
      }
    }
  }
`;

const FilterWrapper = styled.div`
  height: auto;
  display: ${(props) => (!props.isExpand ? 'none' : 'block')};
  margin-top: ${(props) => props.isExpand && '1.71rem'};
  margin-bottom: ${(props) => (props.manageColumns && props.isExpand ? '' : '1.5rem')};
  padding-bottom: ${(props) => props.isExpand && '1.71rem'};

  & > .ant-btn {
    margin: 0 0 1.14rem auto;
  }

  .ant-form-item-label {
    > label {
      color: ${(props) => props.theme.colors.secondaryColor};
      &::after {
        display: none;
        content: '';
      }
    }
  }
  @media only screen and (min-width: 1921px) {
    .ant-form .ant-col:not(:last-of-type) {
      max-width: 320px;
    }
  }

  ${media.sm`
    display: block;
    position: fixed;
    overflow-y: auto;
    z-index: 99999;
    left: ${(props) => (_.isNull(props.mobileExpand) || (!_.isNull(props.mobileExpand) && !props.mobileExpand) ? '-100%' : '0')};
    top: 0;
    transition: 0.3s all;
    width: 100%;
    height: 100%;
    padding: 5rem 1.71rem;
    margin: 0;
    background-color: ${(props) => props.theme.colors.secondaryBg};
    
    .ant-form {
      & > .ant-row {
        & > .ant-col {
          width: 50%;
          
          &:last-of-type {
            position: fixed;
            z-index: 1051;
            left: ${(props) => (_.isNull(props.mobileExpand) || (!_.isNull(props.mobileExpand) && !props.mobileExpand) ? '-100%' : '0')};
            bottom: 0;
            transition: 0.3s all;
            padding-top: 0.71rem;
            padding-bottom: 0.71rem;
            width: 100%;
            background-color: ${(props) => props.theme.colors.secondaryBg};
            box-shadow: 0 0 6px rgba(0, 0, 0, 0.1);
          }
        }
      }
    }
  `}

  ${media.xs`
    .ant-form {
      & > .ant-row {
        & > .ant-col {
          width: 100%;
          flex: 0 0 100%;
          max-width: 100%;
        }
      }
    }
  `}
`;

const StyledFilterButtons = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  flex-wrap: wrap;
  margin-bottom: 1.14rem;
  row-gap: 0.52rem;

  // case 0 - when there is export button with filters
  // case 1 - when there is column button between filters and table
  // case 2 - when there isn't column button between filters and table

  ${media.sm`
    column-gap: 0.52rem;
    row-gap: 0.52rem;
    margin-bottom: 1.14rem;
  `}
  .ant-btn {
    margin: 0 0 0 1.14rem;
    ${media.sm`
      margin-bottom: 0;
  `}
  }
`;

const FilterIconWrapper = styled.div`
  margin-right: 0.57rem;
  svg {
    margin-right: 0;
    path:not(g + path) {
      fill: ${(props) => props.theme.colors.secondaryColor};
    }
  }
`;

const MStyledCloseFilterButton = styled.div`
  display: ${(props) => (_.isNull(props.mobileExpand) || (!_.isNull(props.mobileExpand) && !props.mobileExpand) ? 'none' : 'flex')};
  align-items: center;
  justify-content: flex-end;
  position: fixed;
  z-index: 1051;
  left: 0;
  top: 0;
  width: 100%;
  height: 4rem;
  padding-right: 1.71rem;
  background-color: ${(props) => props.theme.colors.secondaryBg};
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.1);
  cursor: pointer;
`;

const StyledManageFiltersWrapper = styled.div`
  .ant-popover-inner-content {
    border-radius: ${(props) => props.theme.customBorderRadius.borderRadiusMiddle};
    width: 18.1rem;
    padding: 1rem 0;

    .ant-btn {
      margin: 1.71rem auto 0;
    }
  }

  ${StyledButtonGrayIcon} {
    margin-right: 1.14rem;

    &.ant-popover-open {
      background-color: ${(props) => props.theme.colors.grayButton};
    }
  }
`;

export {
  FormItemWrapper, FilterButtonGroup, FilterWrapper, StyledFilterButtons, FilterIconWrapper, MStyledCloseFilterButton, StyledManageFiltersWrapper,
};
