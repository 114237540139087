export default {
  en: {
    custom: 'Custom',
    financial: 'Financial',
    analytics: 'Analytics',
    players: 'Players',
    casino: 'Casino',
    sport: 'Sport',
    other: 'Other',
    bonus: 'Bonus',
    customers: 'Customers',
    betting: 'Betting',
    payments: 'Payments',
    bonuses: 'Bonuses',
    financialTrends: 'Financial Trends',
    customerTrends: 'Customer Trends',
    share: 'Share',
    default: 'Default',
    pages: 'Pages',
    rules: 'Rules',
    promotions: 'Promotions',
    system: 'System',
    jackpots: 'Jackpots',
    multiCashback: 'Multi Cashback',
    tournaments: 'Tournaments',
    ultraCashback: 'Ultra Cashback/ Accumulator Bonus',
    groupStage: 'Group Stage Promo',
    boreDraw: 'Bore Draw',
    depositAmount: 'Deposit Amount',
    betAmount: 'Bet Amount',
  },
  es: {
    custom: 'Personalizado',
    financial: 'Bancario',
    analytics: 'Análisis',
    players: 'Jugadores',
    casino: 'Casino',
    sport: 'Deporte',
    other: 'Otros',
    bonus: 'Bono',
    customers: 'Clientes',
    betting: 'Apuestas',
    payments: 'Pagos',
    bonuses: 'Bonos',
    financialTrends: 'Tendencias financieras',
    customerTrends: 'Tendencias de clientes',
    share: 'Compartir',
    default: 'Predeterminado',
    pages: 'Páginas',
    rules: 'Reglas',
    promotions: 'Promoción',
    system: 'Sistema',
    jackpots: 'Jackpots',
    multiCashback: 'Multi Cashback',
    tournaments: 'Torneos',
    ultraCashback: 'Ultra Cashback / Bono Acumulador',
    groupStage: 'Promo de la Fase de Grupos',
    boreDraw: 'Bore Draw',
    depositAmount: 'Importe del Depósito',
    betAmount: 'Importe de la Apuesta',
  },
  tr: {
    custom: 'Özel',
    financial: 'Finansal',
    analytics: 'Analiz',
    players: 'Oyuncular',
    casino: 'Casino',
    sport: 'Spor',
    other: 'Diğer',
    bonus: 'Bonus',
    customers: 'Müşteriler',
    betting: 'Bahis Oynama',
    payments: 'Ödemeler',
    bonuses: 'Bonuslar',
    financialTrends: 'Finansal Trendler',
    customerTrends: 'Müşteri Trendleri',
    share: 'Paylaş',
    default: 'Varsayılan',
    pages: 'sayfalar',
    rules: 'kurallar',
    promotions: 'promosyonlar',
    jackpots: 'Jackpotlar',
    multiCashback: 'Multi Cashback',
    tournaments: 'Turnuvalar',
    ultraCashback: 'Ultra Cashback/ Akümülatör Bonusu',
    groupStage: 'Grup Aşaması Promosyon',
    boreDraw: '0-0 sona erer',
    depositAmount: 'Para Yatırma Tutarı',
    betAmount: 'Bahis Tutarı',
  },
  ru: {
    custom: 'Пользовательские настройки',
    financial: 'Финансовые показатели',
    analytics: 'Аналитика',
    players: 'Игроки',
    casino: 'Казино',
    sport: 'Спорт',
    other: 'Другое',
    bonus: 'Бонус',
    customers: 'Клиенты',
    betting: 'Ставки',
    payments: 'Платежи',
    bonuses: 'Бонусы',
    financialTrends: 'Финансовые тренды',
    customerTrends: 'Пользовательские тренды',
    share: 'Поделиться',
    default: 'По умолчанию',
    pages: 'Страницы',
    rules: 'Правила',
    promotions: 'Промоакции',
    system: 'Система',
    jackpots: 'Джекпоты',
    multiCashback: 'Мульти Кэшбэк',
    tournaments: 'Турниры',
    ultraCashback: 'Ультра Кэшбэк / Накопительный бонус',
    groupStage: 'Промо-ролик группового этапа',
    boreDraw: 'Ничья',
    depositAmount: 'Сумма депозита',
    betAmount: 'Сумма ставки',
  },
  pt: {
    custom: 'Personalizado',
    financial: 'Finanças',
    analytics: 'Análises',
    players: 'Jogadores',
    casino: 'Casino',
    sport: 'Desporto',
    other: 'Outros',
    bonus: 'Bónus',
    customers: 'Clientes',
    betting: 'Apostas',
    payments: 'Pagamentos',
    bonuses: 'Bónus',
    financialTrends: 'Tendências Financeiras',
    customerTrends: 'Tendências de Cliente',
    share: 'Partilhar',
    default: 'Padrão',
    pages: 'Páginas',
    rules: 'Regras',
    promotions: 'Promoções',
    system: 'Sistema',
    jackpots: 'Jackpots',
    multiCashback: 'Multi Cashback',
    tournaments: 'Torneios',
    ultraCashback: 'Ultra Cashback/Bónus Acumulador',
    groupStage: 'Promoção Fase de Grupos',
    boreDraw: 'Empate 0-0',
    depositAmount: 'Valor do depósito',
    betAmount: 'Valor da aposta ',
  },
  zh: {
    custom: '自定义',
    financial: '财务的',
    analytics: '分析',
    players: '玩家',
    casino: '娱乐场',
    sport: '体育',
    other: '其他',
    bonus: '奖金',
    customers: '客户',
    betting: '投注',
    payments: '付款',
    bonuses: '奖金',
    financialTrends: '财务趋势',
    customerTrends: '客户趋势',
    share: '分享',
    default: '默认',
    pages: '页面',
    rules: '规则',
    promotions: '促销',
    system: '系统',
    jackpots: '大奖',
    multiCashback: '多重返现',
    tournaments: '锦标赛',
    ultraCashback: '超级返现/累积奖金',
    groupStage: '小组赛促销',
    boreDraw: '零分平局',
    depositAmount: '存款额',
    betAmount: '投注额',
  },
  ro: {
    custom: 'Personalizat',
    financial: 'Financiar',
    analytics: 'Analize',
    players: 'Jucători',
    casino: 'Casino',
    sport: 'Sport',
    other: 'Alt',
    bonus: 'Bonus',
    customers: 'Clienți',
    betting: 'Pariere',
    payments: 'Plăți',
    bonuses: 'Bonusuri',
    financialTrends: 'Tendințe financiare',
    customerTrends: 'Tendințele clienților',
    share: 'Împărtășire',
    default: 'Implicit',
    pages: 'Pagini',
    rules: 'Reguli',
    promotions: 'Promoții',
  },
  bg: {},
  el: {
    custom: 'Προσαρμοσμένο',
    financial: 'Οικονομικά',
    analytics: 'Αναλυτικά στοιχεία',
    players: 'Παίκτες',
    casino: 'Καζίνο',
    sport: 'Άθλημα',
    other: 'Άλλο',
    bonus: 'Μπόνους',
    customers: 'Πελάτες',
    betting: 'Στοιχήματα',
    payments: 'Πληρωμές',
    bonuses: 'Μπόνους',
    financialTrends: 'Οικονομικές τάσεις',
    customerTrends: 'Τάσεις πελατών',
    share: 'Κοινοποίηση',
    default: 'Προεπιλεγμένο',
    pages: 'Σελίδες',
    rules: 'Κανόνες',
    promotions: 'Προωθητικές ενέργειες',
    system: 'Σύστημα',
    jackpots: 'Τζάκποτ',
    multiCashback: 'Multi επιστροφή μετρητών',
    tournaments: 'Τουρνουά',
    ultraCashback: 'Ultra επιστροφή μετρητών / Μπόνους παρολί',
    groupStage: 'Προωθητική ενέργεια στη φάση των ομίλων',
    boreDraw: 'Λευκή ισοπαλία',
  },
};
