const selectPlayerBlockedPaymentMethodsHistory = (state) => state.playerBlockedPaymentMethodsHistory;
export const selectPlayerBlockedPaymentMethodsHistoryData = (state) => selectPlayerBlockedPaymentMethodsHistory(state).data;
export const selectPlayerBlockedPaymentMethodsHistoryTotal = (state) => selectPlayerBlockedPaymentMethodsHistory(state).total;

const selectPlayersBlockedPaymentMethodsHistory = (state) => state.playersBlockedPaymentMethodsHistory;
export const selectPlayersBlockedPaymentMethodsHistoryData = (state) => selectPlayersBlockedPaymentMethodsHistory(state).data;
export const selectPlayersBlockedPaymentMethodsHistoryTotal = (state) => selectPlayersBlockedPaymentMethodsHistory(state).total;

const selectBlockedPaymentMethodPlayers = (state) => state.paymentMethodBlockedPlayers;
export const selectBlockedPaymentMethodPlayersData = (state) => selectBlockedPaymentMethodPlayers(state).data;
export const selectBlockedPaymentMethodPlayersTotal = (state) => selectBlockedPaymentMethodPlayers(state).total;
