import styled from 'styled-components';

const StyledCarouselWrapper = styled.div`
  .ant-carousel .slick-dots-bottom {
    bottom: ${(props) => (props.showDetails ? ' -0.8rem' : ' -1rem')};
  }
  .slick-slider {
    width: ${(props) => (props.showDetails ? ' 34rem' : ' auto')};
  }
  .slick-slide {
    background: ${(props) => props.theme.colors.primaryBg};
    border-radius: ${(props) => props.theme.customBorderRadius.borderRadiusMiddle};
  }
  .ant-carousel .slick-dots li {
    background: ${(props) => props.theme.colors.primaryBorder};
    width: 2.8rem;
    height: 0.28rem;
    border-radius: 2px;
    margin: 2px;
  }
  .ant-carousel .slick-dots li.slick-active,
  .ant-carousel .slick-dots li.slick-active button {
    background: ${(props) => props.theme.colors.blue};
    width: 2.8rem;
    height: 0.28rem;
    border-radius: 2px;
  }
  .ant-carousel .slick-slider .slick-list img {
    max-width: 46rem;
    width: 100%;
    object-fit: contain;
  }
  .ant-carousel .slick-slider .slick-track {
    display: flex;
    align-items: center;
  }
  .ant-carousel .slick-arrow svg g rect {
    fill: none;
  }
  .ant-carousel .slick-prev {
    left: ${(props) => (props.showDetails ? ' -4.3rem' : ' -2.5rem')};
  }
  .ant-carousel .slick-next {
    right: ${(props) => (props.showDetails ? ' -4.3rem' : ' -2.5rem')};
  }
  .ant-carousel .slick-slide {
    height: 100%;
  }
  .slick-slide.slick-active.slick-current {
    div,
    img {
      width: 100%;
    }
  }
`;
export { StyledCarouselWrapper };
