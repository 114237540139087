import { Dropdown, Menu, Tooltip } from 'antd';
import { useEffect, useMemo, useState } from 'react';
import { StyledListItemHeader, StyleFlagsWrapper } from 'components/layout/Layout.styled';
import LocalStorageService from 'services/localStorageService';
import { StyledFlag, StyledFlagWrapper, StyledLanguageName } from 'components/styledComponents/icon/Icon.styled';
import { AppConstants } from 'constants/index';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { locales } from 'constants/mapping';
import { useTimeZone } from 'hooks';

const { languages } = AppConstants;

const Languages = (props) => {
  const { changeLocaleLanguage } = props;

  const [open, setOpen] = useState(false);
  const [language, setLanguage] = useState(LocalStorageService.get('current')?.languageId || AppConstants.defaultLanguage);
  const { date } = useTimeZone();
  const { i18n } = useTranslation();

  useEffect(() => {
    i18n.changeLanguage(language);
    date.locale(locales[language]);
    changeLocaleLanguage(language);
  }, [date, changeLocaleLanguage, i18n, language]);

  const items = useMemo(
    () => Object.keys(languages).map((key) => ({
      key,
      label: (
        <StyledFlagWrapper>
          <StyledFlag className="f24">
            <div className={`${key} lang flag`} />
          </StyledFlag>
          <StyledLanguageName>{languages[key]}</StyledLanguageName>
        </StyledFlagWrapper>
      ),
    })),
    [],
  );

  const handleMenuClick = ({ key }) => {
    setLanguage(key);
    const currentData = LocalStorageService.get('current') || {};

    LocalStorageService.set('current', {
      ...currentData,
      languageId: key,
    });

    setOpen(false);
  };

  return (
    <Dropdown
      open={open}
      onOpenChange={setOpen}
      dropdownRender={() => (
        <StyleFlagsWrapper>
          <Menu onClick={handleMenuClick} items={items} />
        </StyleFlagsWrapper>
      )}
      placement="bottomRight"
      trigger="click"
    >
      <StyledListItemHeader>
        <Tooltip placement="bottom" title={languages[language]}>
          <div className="f24">
            <div className={`${language} lang flag`} />
          </div>
        </Tooltip>
      </StyledListItemHeader>
    </Dropdown>
  );
};

Languages.propTypes = {
  changeLocaleLanguage: PropTypes.func.isRequired,
};

export default Languages;
