import BlockedProviderActionType from 'actions/blockedProvider/blockedProviderActionType';

const initialState = {
  playerBlockedProvidersHistory: {},
  playerBlockedProviders: {},

  playersBlockedProvidersHistory: {},
};

const blockedProviderReducer = (state = initialState, { type, payload }) => {
  switch (type) {
  case BlockedProviderActionType.SAVE_PLAYER_BLOCKED_PROVIDERS_HISTORY: {
    return {
      ...state,
      playerBlockedProvidersHistory: payload.data,
    };
  }
  case BlockedProviderActionType.RESET_PLAYER_BLOCKED_PROVIDERS_HISTORY:
    return {
      ...state,
      playerBlockedProvidersHistory: {},
    };
  case BlockedProviderActionType.SAVE_PLAYER_BLOCKED_PROVIDERS:
    return {
      ...state,
      playerBlockedProviders: payload.data,
    };
  case BlockedProviderActionType.RESET_PLAYER_BLOCKED_PROVIDERS:
    return {
      ...state,
      playerBlockedProviders: {},
    };
  case BlockedProviderActionType.SAVE_BULK_BLOCKED_PROVIDERS_HISTORY:
    return {
      ...state,
      playersBlockedProvidersHistory: payload.data,
    };
  case BlockedProviderActionType.RESET_BULK_BLOCKED_PROVIDERS_HISTORY:
    return {
      ...state,
      playersBlockedProvidersHistory: {},
    };
  default:
    return state;
  }
};

export default blockedProviderReducer;
