import { Form, Input, Select } from 'antd';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import IconSvg from 'components/common/icon/IconSvg';
import { StyledIconDefault } from 'components/styledComponents/icon/Icon.styled';
import { StyledSelectWrapper } from 'components/styledComponents/select/Select.styled';
import { FormInputWrapper } from 'components/styledComponents/input/Input.styled';
import { FormItemWrapper } from 'components/styledComponents/filter/Filter.styled';
import { SearchItemsWrapper, StyledSearchWrapper } from 'components/styledComponents/search/Search.styled';
import { onlyNumbersFormatter } from 'utils/utils';

const { Option } = Select;
const Search = (props) => {
  const {
    changeSearchType, options, onSearch, label, placeholder, selectName, form, inputName, isInput, disabled,
  } = props;

  const { t } = useTranslation();

  const { setFieldsValue, getFieldValue } = form;

  const handleChange = (event) => {
    const name = getFieldValue(selectName);
    const index = options.findIndex((el) => el.value === name);
    setFieldsValue({ [inputName]: options[index].isNumeric ? onlyNumbersFormatter(event) : event.target.value });
  };

  return (
    <SearchItemsWrapper>
      <FormItemWrapper>
        <Form.Item label={label} name={selectName}>
          <StyledSelectWrapper
            getPopupContainer={(trigger) => trigger.parentNode}
            onChange={changeSearchType}
            suffixIcon={(
              <StyledIconDefault>
                <IconSvg icon="DropdownIcon" />
              </StyledIconDefault>
            )}
          >
            {options.map((option) => (
              <Option value={option.value} key={option.value}>
                {t(option.name)}
              </Option>
            ))}
          </StyledSelectWrapper>
        </Form.Item>
      </FormItemWrapper>
      <StyledSearchWrapper isInput={isInput ? 1 : 0}>
        <FormItemWrapper>
          <FormInputWrapper isInput>
            <Form.Item name={inputName}>
              {isInput ? (
                <Input onChange={handleChange} placeholder={placeholder} allowClear autoComplete="off" disabled={disabled} />
              ) : (
                <Input.Search onChange={handleChange} onSearch={onSearch} placeholder={placeholder} allowClear autoComplete="off" disabled={disabled} />
              )}
            </Form.Item>
          </FormInputWrapper>
        </FormItemWrapper>
      </StyledSearchWrapper>
    </SearchItemsWrapper>
  );
};

Search.propTypes = {
  options: PropTypes.array.isRequired,
  onSearch: PropTypes.func,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  form: PropTypes.object.isRequired,
  changeSearchType: PropTypes.func,
  selectName: PropTypes.string,
  inputName: PropTypes.string,
  isInput: PropTypes.bool,
  disabled: PropTypes.bool,
};

Search.defaultProps = {
  label: '',
  onSearch: () => {},
  placeholder: '',
  changeSearchType: () => {},
  selectName: '',
  inputName: '',
  isInput: false,
  disabled: false,
};

export default Search;
