import PropTypes from 'prop-types';
import { Form, Input } from 'antd';
import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';
import { AppConstants } from 'constants/index';
import { FormItemErrorMessage } from 'components/common';
import { FormItemMarginTopWrapper } from 'Global.styled';
import { FormInputWrapper } from 'components/styledComponents/input/Input.styled';
import { StyledFormMainWrapper } from 'components/styledComponents/form/Form.styled';
import { i18n } from 'services';

const defaultTranslation = i18n.getFixedT('en');

const ChangePasswordForm = (props) => {
  const {
    form: { getFieldValue },
    form,
    translatable,
  } = props;

  const translation = useTranslation();

  const t = useMemo(() => (translatable ? translation.t : defaultTranslation), [translatable, translation.t]);

  const inputs = {
    oldPassword: 'oldPassword',
    newPassword: 'newPassword',
    confirmPassword: 'confirmPassword',
  };

  const compareToFirstPassword = (rule, value) => {
    if (value && value !== getFieldValue(inputs.newPassword)) {
      return Promise.reject(
        <FormItemErrorMessage
          errorMassage={(tran) => {
            const translate = translatable ? tran : defaultTranslation;
            return translate('validate:passwordDontMatch');
          }}
        />,
      );
    }
    return Promise.resolve();
  };

  return (
    <StyledFormMainWrapper layout="vertical" form={form}>
      <FormInputWrapper>
        <Form.Item
          label={t('oldPassword')}
          name={inputs.oldPassword}
          rules={[
            {
              required: true,
              message: (
                <FormItemErrorMessage
                  errorMassage={(tran) => {
                    const translate = translatable ? tran : defaultTranslation;
                    return translate('validate:requiredField', { fieldName: translate('oldPassword') });
                  }}
                />
              ),
            },
            {
              pattern: AppConstants.regex.password,
              message: (
                <FormItemErrorMessage
                  errorMassage={(tran) => {
                    const translate = translatable ? tran : defaultTranslation;
                    return translate('validate:password8to40NUL');
                  }}
                />
              ),
            },
          ]}
        >
          <Input.Password type="password" placeholder={t('oldPassword')} />
        </Form.Item>
      </FormInputWrapper>
      <FormItemMarginTopWrapper>
        <FormInputWrapper>
          <Form.Item
            label={t('newPassword')}
            name={inputs.newPassword}
            rules={[
              {
                required: true,
                message: (
                  <FormItemErrorMessage
                    errorMassage={(tran) => {
                      const translate = translatable ? tran : defaultTranslation;
                      return translate('validate:requiredField', { fieldName: translate('newPassword') });
                    }}
                  />
                ),
              },
              {
                pattern: AppConstants.regex.password,
                message: (
                  <FormItemErrorMessage
                    errorMassage={(tran) => {
                      const translate = translatable ? tran : defaultTranslation;
                      return translate('validate:password8to40NUL');
                    }}
                  />
                ),
              },
            ]}
          >
            <Input.Password type="password" placeholder={t('newPassword')} />
          </Form.Item>
        </FormInputWrapper>
      </FormItemMarginTopWrapper>
      <FormItemMarginTopWrapper>
        <FormInputWrapper>
          <Form.Item
            label={t('confirmPassword')}
            name={inputs.confirmPassword}
            dependencies={[inputs.newPassword]}
            rules={[
              {
                required: true,
                message: (
                  <FormItemErrorMessage
                    errorMassage={(tran) => {
                      const translate = translatable ? tran : defaultTranslation;
                      return translate('validate:requiredField', { fieldName: translate('confirmPassword') });
                    }}
                  />
                ),
              },
              {
                validator: compareToFirstPassword,
              },
            ]}
          >
            <Input.Password type="password" placeholder={t('confirmPassword')} />
          </Form.Item>
        </FormInputWrapper>
      </FormItemMarginTopWrapper>
    </StyledFormMainWrapper>
  );
};
ChangePasswordForm.propTypes = {
  form: PropTypes.object.isRequired,
  translatable: PropTypes.bool,
};

ChangePasswordForm.defaultProps = {
  translatable: true,
};
export default ChangePasswordForm;
