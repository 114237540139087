import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { AdminTable } from 'components/common/table';
import { createOrEditUserConfig } from 'actions/user/userActionCreator';
import { selectUserConfig } from 'selectors';
import { settingKeys } from 'constants/configKeys';

const mapStateToProps = ({ users }, ownProps) => ({
  pageSize: selectUserConfig(users, ownProps?.pageSizeKey || settingKeys.pageSize),
  configColumns: selectUserConfig(users, ownProps?.tableKey),
});

const mapDispatchToProps = (dispatch) => bindActionCreators(
  {
    createOrEditUserConfig,
  },
  dispatch,
);

export default connect(mapStateToProps, mapDispatchToProps)(AdminTable);
