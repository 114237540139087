import BetStatusCorrectionActionType from './betStatusCorrectionActionType';

export const getSingleBetStatusCorrections = (params) => ({
  type: BetStatusCorrectionActionType.GET_SINGLE_BET_STATUS_CORRECTIONS,
  payload: { params },
});

export const saveSingleBetStatusCorrections = (data) => ({
  type: BetStatusCorrectionActionType.SAVE_SINGLE_BET_STATUS_CORRECTIONS,
  payload: { data },
});

export const resetSingleBetStatusCorrections = () => ({
  type: BetStatusCorrectionActionType.RESET_SINGLE_BET_STATUS_CORRECTIONS,
});

export const getBulkBetStatusCorrections = (params) => ({
  type: BetStatusCorrectionActionType.GET_BULK_BET_STATUS_CORRECTIONS,
  payload: { params },
});

export const saveBulkBetStatusCorrections = (data) => ({
  type: BetStatusCorrectionActionType.SAVE_BULK_BET_STATUS_CORRECTIONS,
  payload: { data },
});

export const resetBulkBetStatusCorrections = () => ({
  type: BetStatusCorrectionActionType.RESET_BULK_BET_STATUS_CORRECTIONS,
});

export const createSingleBetStatusCorrection = (data) => ({
  type: BetStatusCorrectionActionType.CREATE_SINGLE_BET_STATUS_CORRECTION,
  payload: { data },
});

export const createBulkBetStatusCorrection = (data) => ({
  type: BetStatusCorrectionActionType.CREATE_BULK_BET_STATUS_CORRECTION,
  payload: { data },
});

export const exportBetStatusCorrection = (id, params) => ({
  type: BetStatusCorrectionActionType.EXPORT_BET_STATUS_CORRECTIONS,
  payload: { id, params },
});

export const getBetStatusCorrectionsStatistics = (id, params) => ({
  type: BetStatusCorrectionActionType.GET_BET_STATUS_CORRECTIONS_STATISTICS,
  payload: { id, params },
});

export const saveBetStatusCorrectionsStatistics = (data) => ({
  type: BetStatusCorrectionActionType.SAVE_BET_STATUS_CORRECTIONS_STATISTICS,
  payload: { data },
});

export const resetBetStatusCorrectionsStatistics = () => ({
  type: BetStatusCorrectionActionType.RESET_BET_STATUS_CORRECTIONS_STATISTICS,
});
