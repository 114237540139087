import PlayerActionType from './playerActionType';

export const getPlayerData = (playerId) => ({
  type: PlayerActionType.GET_PLAYER_DATA,
  payload: { playerId },
});

export const resetPlayerData = () => ({
  type: PlayerActionType.RESET_PLAYER_DATA,
});

export const savePlayerData = (data) => ({
  type: PlayerActionType.SAVE_PLAYER_DATA,
  payload: { data },
});

export const getPlayersSuccess = ({ data, total }) => ({
  type: PlayerActionType.GET_PLAYERS_SUCCESS,
  payload: {
    data,
    total,
  },
});

export const getPlayers = (brandId, data) => ({
  type: PlayerActionType.GET_PLAYERS,
  payload: { brandId, data },
});

export const resetPlayers = () => ({
  type: PlayerActionType.RESET_PLAYERS,
});

export const generatePlayersReport = (params) => ({
  type: PlayerActionType.GENERATE_PLAYERS_REPORT,
  payload: { params },
});

export const changePlayerLoadingState = (state) => ({
  type: PlayerActionType.CHANGE_PLAYER_LOADING_STATE,
  payload: { state },
});

export const changePlayerModalLoadingState = (state) => ({
  type: PlayerActionType.CHANGE_PLAYER_MODAL_LOADING_STATE,
  payload: { state },
});

export const changePlayerReportLoadingState = (state) => ({
  type: PlayerActionType.CHANGE_PLAYER_REPORT_LOADING_STATE,
  payload: { state },
});

export function getPlayer(id, data, showError = true) {
  return {
    type: PlayerActionType.GET_PLAYER,
    payload: { id, data, showError },
  };
}

export const savePlayer = (data) => ({
  type: PlayerActionType.SAVE_PLAYER,
  payload: {
    data,
  },
});

export const resetPlayer = () => ({
  type: PlayerActionType.RESET_PLAYER,
});

export const editPlayer = (playerId, data) => ({
  type: PlayerActionType.EDIT_PLAYER,
  payload: { playerId, data },
});

export const editPlayerNotificationPreferences = (playerId, data) => ({
  type: PlayerActionType.EDIT_PLAYER_NOTIFICATION_PREFERENCE,
  payload: { playerId, data },
});

export const getCsvPlayers = (data) => ({
  type: PlayerActionType.GET_CSV_PLAYERS,
  payload: { data },
});

export const getCsvPlayersSuccess = (data) => ({
  type: PlayerActionType.GET_CSV_PLAYERS_SUCCESS,
  payload: { data },
});

export const resetCsvPlayers = () => ({
  type: PlayerActionType.RESET_CSV_PLAYERS,
});

export const getBrandPlayersResource = (id, data, isLoading) => ({
  type: PlayerActionType.GET_BRAND_PLAYERS_RESOURCE,
  payload: { id, data, isLoading },
});

export const resetBrandPlayersResource = () => ({
  type: PlayerActionType.RESET_BRAND_PLAYERS_RESOURCE,
});

export const saveBrandPlayersResource = (data) => ({
  type: PlayerActionType.SAVE_BRAND_PLAYERS_RESOURCE,
  payload: { data },
});

export const getPlayerNotes = (data) => ({
  type: PlayerActionType.GET_PLAYER_NOTES,
  payload: { data },
});

export const resetPlayerNotes = () => ({
  type: PlayerActionType.RESET_PLAYER_NOTES,
});

export const getPlayerNotesSuccess = ({ data, total }) => ({
  type: PlayerActionType.GET_PLAYER_NOTES_SUCCESS,
  payload: { data, total },
});

export const resetPlayerNoteTypes = () => ({
  type: PlayerActionType.RESET_PLAYER_NOTE_TYPES,
});

export const getPlayerNoteTypes = (isLoading) => ({
  type: PlayerActionType.GET_PLAYER_NOTE_TYPES,
  payload: { isLoading },
});

export const getPlayerNoteTypesSuccess = (data) => ({
  type: PlayerActionType.GET_PLAYER_NOTE_TYPES_SUCCESS,
  payload: { data },
});

export const changePlayerModalVisibility = (state) => ({
  type: PlayerActionType.CHANGE_PLAYER_MODAL_VISIBILITY,
  payload: { state },
});

export const exportPlayerNotes = (playerId, data) => ({
  type: PlayerActionType.EXPORT_PLAYER_NOTES,
  payload: { playerId, data },
});

export const addPlayerNote = (playerId, data) => ({
  type: PlayerActionType.ADD_PLAYER_NOTE,
  payload: { playerId, data },
});

export const getPlayerInboxMessages = (playerId, data) => ({
  type: PlayerActionType.GET_PLAYER_INBOX_MESSAGES,
  payload: { playerId, data },
});

export const resetPlayerInboxMessages = () => ({
  type: PlayerActionType.RESET_PLAYER_INBOX_MESSAGES,
});

export const getPlayerMassMessages = (playerId, data) => ({
  type: PlayerActionType.GET_PLAYER_MASS_MESSAGES,
  payload: { playerId, data },
});

export const getPlayerMessage = (playerId, messageId) => ({
  type: PlayerActionType.GET_PLAYER_MESSAGE,
  payload: { playerId, messageId },
});

export const savePlayerMessages = (data) => ({
  type: PlayerActionType.SAVE_PLAYER_MESSAGES,
  payload: { data },
});

export const resetPlayerMassMessages = () => ({
  type: PlayerActionType.RESET_PLAYER_MASS_MESSAGES,
});

export const savePlayerMessage = (data) => ({
  type: PlayerActionType.SAVE_PLAYER_MESSAGE,
  payload: { data },
});

export const resetPlayerMessage = () => ({
  type: PlayerActionType.RESET_PLAYER_MESSAGE,
});

export const savePlayerMessagesTotal = (data) => ({
  type: PlayerActionType.SAVE_PLAYER_MESSAGES_TOTAL,
  payload: { data },
});

export const playerSendMessage = (data) => ({
  type: PlayerActionType.PLAYER_SEND_MESSAGE,
  payload: { data },
});

export const changeEndedStatus = (status) => ({
  type: PlayerActionType.CHANGE_PLAYER_ENDED_STATUS,
  payload: { status },
});

export const getPlayerControls = (params) => ({
  type: PlayerActionType.GET_PLAYER_CONTROLS,
  payload: { params },
});

export const savePlayerControls = (data) => ({
  type: PlayerActionType.SAVE_PLAYER_CONTROLS,
  payload: { data },
});

export const editPlayerMarkAttention = (playerId, params, data) => ({
  type: PlayerActionType.EDIT_PLAYER_MARK_ATTENTION,
  payload: { playerId, params, data },
});

export const unlockPlayer = (playerId) => ({
  type: PlayerActionType.UNLOCK_PLAYER,
  payload: { playerId },
});

export const closePlayerAccount = (playerId, data) => ({
  type: PlayerActionType.CLOSE_PLAYER_ACCOUNT,
  payload: { playerId, data },
});

export const blockPlayerForMessages = (playerId, params) => ({
  type: PlayerActionType.BLOCK_PLAYER_FOR_MESSAGES,
  payload: { playerId, params },
});

export const changePlayerStatus = (playerId, params, data, type) => ({
  type: PlayerActionType.CHANGE_PLAYER_STATUS,
  payload: {
    playerId,
    params,
    data,
    type,
  },
});

export const activateDormantPlayer = (playerId) => ({
  type: PlayerActionType.ACTIVATE_DORMANT_PLAYER,
  payload: { playerId },
});

export const getPlayersBulkStatusUpdateHistory = (brandId, data) => ({
  type: PlayerActionType.GET_PLAYERS_BULK_STATUS_UPDATE_HISTORY,
  payload: { brandId, data },
});

export const getPlayersBulkStatusUpdateHistorySuccess = (data) => ({
  type: PlayerActionType.GET_PLAYERS_BULK_STATUS_UPDATE_HISTORY_SUCCESS,
  payload: { data },
});

export const resetPlayersBulkStatusUpdateHistory = () => ({
  type: PlayerActionType.RESET_PLAYERS_BULK_STATUS_UPDATE_HISTORY,
});

export const getBulkStatusUpdatedPlayers = (id, data) => ({
  type: PlayerActionType.GET_BULK_STATUS_UPDATED_PLAYERS,
  payload: { id, data },
});

export const getBulkStatusUpdatedPlayersSuccess = (data) => ({
  type: PlayerActionType.GET_BULK_STATUS_UPDATED_PLAYERS_SUCCESS,
  payload: { data },
});

export const resetBulkStatusUpdatedPlayers = () => ({
  type: PlayerActionType.RESET_BULK_STATUS_UPDATED_PLAYERS,
});

export const changePlayersStatusInBulk = (brandId, data) => ({
  type: PlayerActionType.CHANGE_PLAYERS_STATUS_IN_BULK,
  payload: { brandId, data },
});

export const getPlayerActions = (id, params) => ({
  type: PlayerActionType.GET_PLAYER_ACTIONS,
  payload: { id, params },
});

export const savePlayerActions = (data) => ({
  type: PlayerActionType.SAVE_PLAYER_ACTIONS,
  payload: { data },
});

export const exportPlayerActions = (id, params) => ({
  type: PlayerActionType.EXPORT_PLAYER_ACTIONS,
  payload: { id, params },
});

export const resetPlayerActions = () => ({
  type: PlayerActionType.RESET_PLAYER_ACTIONS,
});

export const addTestPlayer = (data) => ({
  type: PlayerActionType.ADD_TEST_PLAYER,
  payload: { data },
});

export const getTestPlayers = (params) => ({
  type: PlayerActionType.GET_TEST_PLAYERS,
  payload: { params },
});

export const saveTestPlayers = (data) => ({
  type: PlayerActionType.SAVE_TEST_PLAYERS,
  payload: { data },
});

export const resetTestPlayers = () => ({
  type: PlayerActionType.RESET_TEST_PLAYERS,
});

export const assignTestPlayerToUser = (data) => ({
  type: PlayerActionType.ASSIGN_TEST_PLAYER_TO_USER,
  payload: { data },
});

export const finishUseTestPlayer = (params) => ({
  type: PlayerActionType.FINISH_USE_TEST_PLAYER,
  payload: { params },
});

export const adjustTestPlayerBalance = (data) => ({
  type: PlayerActionType.ADJUST_TEST_PLAYER_BALANCE,
  payload: { data },
});

export const getTestPlayerUsers = (params) => ({
  type: PlayerActionType.GET_TEST_PLAYER_USERS,
  payload: { params },
});

export const saveTestPlayerUsers = (data) => ({
  type: PlayerActionType.SAVE_TEST_PLAYER_USERS,
  payload: { data },
});

export const resetTestPlayerUsers = () => ({
  type: PlayerActionType.RESET_TEST_PLAYER_USERS,
});

export const editTestUser = (id, data) => ({
  type: PlayerActionType.EDIT_TEST_USER,
  payload: { id, data },
});

export const changePlayerDataUpdatedStatus = (status) => ({
  type: PlayerActionType.CHANGE_PLAYER_DATA_UPDATED_STATUS,
  payload: { status },
});

export const updatePlayerVerification = (playerId, params) => ({
  type: PlayerActionType.UPDATE_PLAYER_VERIFICATION,
  payload: { playerId, params },
});

export const updatePlayerContactInfoVerification = (playerId, params) => ({
  type: PlayerActionType.UPDATE_PLAYER_CONTACT_INFO_VERIFICATION,
  payload: { playerId, params },
});

export const getPlayerSelfExclusionSettings = (params) => ({
  type: PlayerActionType.GET_PLAYER_SELF_EXCLUSION_SETTINGS,
  payload: { params },
});

export const savePlayerSelfExclusionSettings = (data) => ({
  type: PlayerActionType.SAVE_PLAYER_SELF_EXCLUSION_SETTINGS,
  payload: { data },
});

export const editPlayerSelfExclusionSettings = (data, isEdit) => ({
  type: PlayerActionType.EDIT_PLAYER_SELF_EXCLUSION_SETTINGS,
  payload: { data, isEdit },
});

export const getPlayerTimeoutSettings = (params) => ({
  type: PlayerActionType.GET_PLAYER_TIMOUT_SETTINGS,
  payload: { params },
});

export const savePlayerTimeoutSettings = (data) => ({
  type: PlayerActionType.SAVE_PLAYER_TIMOUT_SETTINGS,
  payload: { data },
});

export const editPlayerTimeoutSettings = (data, isEdit) => ({
  type: PlayerActionType.EDIT_PLAYER_TIMOUT_SETTINGS,
  payload: { data, isEdit },
});

export const getPlayerDepositSettings = (params) => ({
  type: PlayerActionType.GET_PLAYER_DEPOSIT_SETTINGS,
  payload: { params },
});

export const savePlayerDepositSettings = (data) => ({
  type: PlayerActionType.SAVE_PLAYER_DEPOSIT_SETTINGS,
  payload: { data },
});

export const editPlayerDepositSettings = (data, isEdit) => ({
  type: PlayerActionType.EDIT_PLAYER_DEPOSIT_SETTINGS,
  payload: { data, isEdit },
});

export const getPlayerWithdrawalSettings = (params) => ({
  type: PlayerActionType.GET_PLAYER_WITHDRAWAL_SETTINGS,
  payload: { params },
});

export const savePlayerWithdrawalSettings = (data) => ({
  type: PlayerActionType.SAVE_PLAYER_WITHDRAWAL_SETTINGS,
  payload: { data },
});

export const editPlayerWithdrawalSettings = (data, isEdit) => ({
  type: PlayerActionType.EDIT_PLAYER_WITHDRAWAL_SETTINGS,
  payload: { data, isEdit },
});

export const getPlayerBetSettings = (params) => ({
  type: PlayerActionType.GET_PLAYER_BET_SETTINGS,
  payload: { params },
});

export const savePlayerBetSettings = (data) => ({
  type: PlayerActionType.SAVE_PLAYER_BET_SETTINGS,
  payload: { data },
});

export const editPlayerBetSettings = (data, isEdit) => ({
  type: PlayerActionType.EDIT_PLAYER_BET_SETTINGS,
  payload: { data, isEdit },
});

export const getPlayerRealityCheckSettings = (params) => ({
  type: PlayerActionType.GET_PLAYER_REALITY_CHECK_SETTINGS,
  payload: { params },
});

export const savePlayerRealityCheckSettings = (data) => ({
  type: PlayerActionType.SAVE_PLAYER_REALITY_CHECK_SETTINGS,
  payload: { data },
});

export const editPlayerRealityCheckSettings = (data, isEdit) => ({
  type: PlayerActionType.EDIT_PLAYER_REALITY_CHECK_SETTINGS,
  payload: { data, isEdit },
});

export const resetPlayerResponsibleGamingSettings = () => ({
  type: PlayerActionType.RESET_PLAYER_RESPONSIBLE_GAMING_SETTINGS,
});

export const getPlayerResponsibleGamingLogs = (params) => ({
  type: PlayerActionType.GET_RESPONSIBLE_GAMING_LOGS,
  payload: { params },
});

export const savePlayerResponsibleGamingLogs = (data) => ({
  type: PlayerActionType.SAVE_RESPONSIBLE_GAMING_LOGS,
  payload: { data },
});

export const resetPlayerResponsibleGamingLogs = () => ({
  type: PlayerActionType.RESET_RESPONSIBLE_GAMING_LOGS,
});

export const getResponsibleGamingReminderLimits = (playerId) => ({
  type: PlayerActionType.GET_RESPONSIBLE_GAMING_REMINDER_LIMITS,
  payload: { playerId },
});

export const saveResponsibleGamingReminderLimits = (data) => ({
  type: PlayerActionType.SAVE_RESPONSIBLE_GAMING_REMINDER_LIMITS,
  payload: { data },
});

export const resetResponsibleGamingReminderLimits = () => ({
  type: PlayerActionType.RESET_RESPONSIBLE_GAMING_REMINDER_LIMITS,
});

export const getPlayerSystemNotifications = (playerId, params) => ({
  type: PlayerActionType.GET_PLAYER_SYSTEM_NOTIFICATIONS,
  payload: { playerId, params },
});

export const savePlayerSystemNotifications = (data) => ({
  type: PlayerActionType.SAVE_PLAYER_SYSTEM_NOTIFICATIONS,
  payload: { data },
});

export const resetPlayerSystemNotifications = () => ({
  type: PlayerActionType.RESET_PLAYER_SYSTEM_NOTIFICATIONS,
});

export const getPlayerSystemNotification = (playerId, messageId) => ({
  type: PlayerActionType.GET_PLAYER_SYSTEM_NOTIFICATION,
  payload: { messageId, playerId },
});

export const savePlayerSystemNotification = (data) => ({
  type: PlayerActionType.SAVE_PLAYER_SYSTEM_NOTIFICATION,
  payload: { data },
});

export const resetPlayerSystemNotification = () => ({
  type: PlayerActionType.RESET_PLAYER_SYSTEM_NOTIFICATION,
});

export const getPlayerPersonalInfo = (id, isLoading) => ({
  type: PlayerActionType.GET_PLAYER_PERSONAL_INFO,
  payload: { id, isLoading },
});

export const savePlayerPersonalInfo = (data) => ({
  type: PlayerActionType.SAVE_PLAYER_PERSONAL_INFO,
  payload: { data },
});

export const resetPlayerPersonalInfo = () => ({
  type: PlayerActionType.RESET_PLAYER_PERSONAL_INFO,
});

export const getPlayerAccountInfo = (id, isLoading) => ({
  type: PlayerActionType.GET_PLAYER_ACCOUNT_INFO,
  payload: { id, isLoading },
});

export const savePlayerAccountInfo = (data) => ({
  type: PlayerActionType.SAVE_PLAYER_ACCOUNT_INFO,
  payload: { data },
});

export const resetPlayerAccountInfo = () => ({
  type: PlayerActionType.RESET_PLAYER_ACCOUNT_INFO,
});

export const changePlayerPersonalInfoUpdatedStatus = (status) => ({
  type: PlayerActionType.CHANGE_PLAYER_PERSONAL_INFO_UPDATED_STATUS,
  payload: { status },
});

export const changePlayerAccountInfoUpdatedStatus = (status) => ({
  type: PlayerActionType.CHANGE_PLAYER_ACCOUNT_INFO_UPDATED_STATUS,
  payload: { status },
});

export const changePlayerActionEndedStatus = (status) => ({
  type: PlayerActionType.CHANGE_PLAYER_ACTIONS_ENDED_STATUS,
  payload: { status },
});

export const changePlayerLimitsLoading = (id, loading) => ({
  type: PlayerActionType.CHANGE_PLAYER_LIMITS_LOADING,
  payload: { id, loading },
});

export const resetTestPlayerPassword = (params) => ({
  type: PlayerActionType.RESET_TEST_PLAYER_PASSWORD,
  payload: { params },
});

export const disablePlayerLoginTFA = (playerId, data) => ({
  type: PlayerActionType.DISABLE_PLAYER_LOGIN_TFA,
  payload: { playerId, data },
});

export const getPlayerAccessLimitSettings = (params) => ({
  type: PlayerActionType.GET_PLAYER_ACCESS_LIMIT_SETTINGS,
  payload: { params },
});

export const savePlayerAccessLimitSettings = (data) => ({
  type: PlayerActionType.SAVE_PLAYER_ACCESS_LIMIT_SETTINGS,
  payload: { data },
});

export const editPlayerAccessLimitSettings = (data, isEdit) => ({
  type: PlayerActionType.EDIT_PLAYER_ACCESS_LIMIT_SETTINGS,
  payload: { data, isEdit },
});

export const getPlayerAccessLimitOverviewSettings = (params) => ({
  type: PlayerActionType.GET_PLAYER_ACCESS_LIMIT_OVERVIEW_SETTINGS,
  payload: { params },
});

export const savePlayerAccessLimitOverviewSettings = (data) => ({
  type: PlayerActionType.SAVE_PLAYER_ACCESS_LIMIT_OVERVIEW_SETTINGS,
  payload: { data },
});
export const getPlayerAccessLimitPendingSettings = (params) => ({
  type: PlayerActionType.GET_PLAYER_ACCESS_LIMIT_PENDING_SETTINGS,
  payload: { params },
});

export const savePlayerAccessLimitPendingSettings = (data) => ({
  type: PlayerActionType.SAVE_PLAYER_ACCESS_LIMIT_PENDING_SETTINGS,
  payload: { data },
});

export const getPlayerAccessLimitSettingsLog = (params) => ({
  type: PlayerActionType.GET_PLAYER_ACCESS_LIMIT_SETTINGS_LOG,
  payload: { params },
});

export const savePlayerAccessLimitSettingsLog = (data) => ({
  type: PlayerActionType.SAVE_PLAYER_ACCESS_LIMIT_SETTINGS_LOG,
  payload: { data },
});

export const getPlayerLossLimitSettings = (params) => ({
  type: PlayerActionType.GET_PLAYER_LOSS_LIMIT_SETTINGS,
  payload: { params },
});

export const savePlayerLossLimitSettings = (data) => ({
  type: PlayerActionType.SAVE_PLAYER_LOSS_LIMIT_SETTINGS,
  payload: { data },
});

export const editPlayerLossLimitSettings = (data, isEdit) => ({
  type: PlayerActionType.EDIT_PLAYER_LOSS_LIMIT_SETTINGS,
  payload: { data, isEdit },
});

export const getPlayerSingleBetSettings = (params) => ({
  type: PlayerActionType.GET_PLAYER_SINGLE_BET_SETTINGS,
  payload: { params },
});

export const savePlayerSingleBetSettings = (data) => ({
  type: PlayerActionType.SAVE_PLAYER_SINGLE_BET_SETTINGS,
  payload: { data },
});

export const editPlayerSingleBetSettings = (data, isEdit) => ({
  type: PlayerActionType.EDIT_PLAYER_SINGLE_BET_SETTINGS,
  payload: { data, isEdit },
});

export const getHasPlayerTransaction = (playerId) => ({
  type: PlayerActionType.GET_HAS_PLAYER_TRANSACTION,
  payload: { playerId },
});

export const saveHasPlayerTransaction = (data) => ({
  type: PlayerActionType.SAVE_HAS_PLAYER_TRANSACTION,
  payload: { data },
});

export const resetHasPlayerTransaction = () => ({
  type: PlayerActionType.RESET_HAS_PLAYER_TRANSACTION,
});

export const changeHasPlayerTransactionLoadingState = (state) => ({
  type: PlayerActionType.CHANGE_HAS_PLAYER_TRANSACTION_LOADING_STATE,
  payload: { state },
});
