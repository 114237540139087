const selectPlayerSessions = (state) => state.sessions;
export const selectPlayerSessionsData = (state) => selectPlayerSessions(state).data;
export const selectPlayerSessionsTotal = (state) => selectPlayerSessions(state).total;
export const selectPlayerActiveSessions = (state) => state.playerActiveSessions;

const selectApplicationSessions = (state) => state.applicationSessions;
export const selectApplicationSessionsData = (state) => selectApplicationSessions(state).data;
export const selectApplicationSessionsTotal = (state) => selectApplicationSessions(state).total;

const selectWebSessions = (state) => state.webSessions;
export const selectWebSessionsData = (state) => selectWebSessions(state).data;
export const selectWebSessionsTotal = (state) => selectWebSessions(state).total;
