const PlayerWalletActionType = {
  GET_PLAYER_WALLET_HISTORY: 'GET_PLAYER_WALLET_HISTORY',
  SAVE_PLAYER_WALLET_HISTORY: 'SAVE_PLAYER_WALLET_HISTORY',
  RESET_PLAYER_WALLET_HISTORY: 'RESET_PLAYER_WALLET_HISTORY',
  EXPORT_PLAYER_WALLET_HISTORY: 'EXPORT_PLAYER_WALLET_HISTORY',

  GET_PLAYER_WALLETS_INFO: 'GET_PLAYER_WALLETS_INFO',
  SAVE_PLAYER_WALLETS_INFO: 'SAVE_PLAYER_WALLETS_INFO',
  RESET_PLAYER_WALLETS_INFO: 'RESET_PLAYER_WALLETS_INFO',

  CHANGE_PLAYER_WALLETS_INFO_UPDATED_STATUS: 'CHANGE_PLAYER_WALLETS_INFO_UPDATED_STATUS',

  GET_PLAYER_NET_BALANCE: 'GET_PLAYER_NET_BALANCE',
  SAVE_PLAYER_NET_BALANCE: 'SAVE_PLAYER_NET_BALANCE',
  RESET_PLAYER_NET_BALANCE: 'RESET_PLAYER_NET_BALANCE',

  PLAYER_NET_BALANCE_LOADING: 'PLAYER_NET_BALANCE_LOADING',
  CHANGE_PLAYER_NET_BALANCE_UPDATED_STATUS: 'CHANGE_PLAYER_NET_BALANCE_UPDATED_STATUS',

  GENERATE_PLAYERS_REAL_WALLETS_SUM_REPORT: 'GENERATE_PLAYERS_REAL_WALLETS_SUM_REPORT',

  GET_BALANCE_ADJUSTMENTS: 'GET_BALANCE_ADJUSTMENTS',
  SAVE_BALANCE_ADJUSTMENTS: 'SAVE_BALANCE_ADJUSTMENTS',
  RESET_BALANCE_ADJUSTMENTS: 'RESET_BALANCE_ADJUSTMENTS',

  GET_PLAYER_BALANCE_ADJUSTMENTS: 'GET_PLAYER_BALANCE_ADJUSTMENTS',
  CREATE_PLAYER_BALANCE_ADJUSTMENT: 'CREATE_PLAYER_BALANCE_ADJUSTMENT',
  CREATE_BULK_BALANCE_ADJUSTMENT: 'CREATE_BULK_BALANCE_ADJUSTMENT',

  GET_BALANCE_ADJUSTMENT_TRANSACTIONS: 'GET_BALANCE_ADJUSTMENT_TRANSACTIONS',
  SAVE_BALANCE_ADJUSTMENT_TRANSACTIONS: 'SAVE_BALANCE_ADJUSTMENT_TRANSACTIONS',
  EXPORT_PLAYER_BALANCE_ADJUSTMENTS: 'EXPORT_PLAYER_BALANCE_ADJUSTMENTS',
  RESET_BALANCE_ADJUSTMENT_TRANSACTIONS: 'RESET_BALANCE_ADJUSTMENT_TRANSACTIONS',

  GET_ADJUSTMENT_CSV_PLAYERS: 'GET_ADJUSTMENT_CSV_PLAYERS',
  SAVE_ADJUSTMENT_CSV_PLAYERS: 'SAVE_ADJUSTMENT_CSV_PLAYERS',
  RESET_ADJUSTMENT_CSV_PLAYERS: 'RESET_ADJUSTMENT_CSV_PLAYERS',
};

export default PlayerWalletActionType;
