const selectPlayerWalletHistory = (state) => state.walletHistory;
export const selectPlayerWalletHistoryData = (state) => selectPlayerWalletHistory(state).data;
export const selectPlayerWalletHistoryTotal = (state) => selectPlayerWalletHistory(state).total;

export const selectPlayerWalletsInfo = (state) => state.walletsInfo;

export const selectPlayerWalletsInfoChanged = (state) => state.isPlayerWalletsInfoChanged;

export const selectPlayerNetBalance = (state) => state.netBalance;

export const selectPlayerNetBalanceLoading = (state) => state.netBalanceLoading;

export const selectPlayerNetBalanceChanged = (state) => state.isPlayerNetBalanceChanged;

export const selectPlayersBalanceAdjustments = (state) => state.balanceAdjustments.data;
export const selectPlayersBalanceAdjustmentsTotal = (state) => state.balanceAdjustments.total;

const selectBalanceAdjustmentTransactions = (state) => state.balanceAdjustmentTransactions;
export const selectBalanceAdjustmentTransactionsData = (state) => selectBalanceAdjustmentTransactions(state).data;
export const selectBalanceAdjustmentsTransactionTotal = (state) => selectBalanceAdjustmentTransactions(state).total;

export const selectAdjustmentCsvPlayers = (state) => state.csvPlayers;
