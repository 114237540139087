import qs from 'qs';
import {
  put, call, all, takeLatest,
} from 'redux-saga/effects';
import mime from 'mimetypes';
import { NetworkService } from 'services';
import {
  setErrorMessage, setSuccessMessage, changeLoadingState, changeEndedStatus,
} from 'actions/setting/settingActionCreator';
import {
  changeTranslationLoadingState,
  saveAllTranslations,
  getAllTranslationsByKeySuccess,
  getTranslationSectionsSuccess,
  setTranslationsChangesState,
  getTranslatedLanguagesSuccess,
  changeTranslatedLanguagesLoadingState,
} from 'actions/translation/translationActionCreator';
import TranslationActionType from 'actions/translation/translationActionType';
import { downloadFile } from 'utils/utils';
import { AppConstants } from 'constants/index';
import i18n from 'services/i18n';
import { getError, getMessage } from 'utils/helpers';

function* getAllTranslations({ payload }) {
  try {
    const options = {
      params: payload.data,
    };
    yield put(changeTranslationLoadingState(true));
    const { BrandAdmin, Translation, TranslationsForForm } = AppConstants.api;
    const { data } = yield call(NetworkService.makeAPIGetRequest, [BrandAdmin, Translation, TranslationsForForm], options);
    yield put(changeTranslationLoadingState(false));
    yield put(saveAllTranslations(data));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeTranslationLoadingState(false));
  }
}

function* getTranslationSections({ payload }) {
  try {
    const { translationGroups } = payload;
    const options = {
      params: { translationGroups },
    };

    yield put(changeTranslationLoadingState(true));
    const { BrandAdmin, Translation, Sections } = AppConstants.api;
    const { data } = yield call(NetworkService.makeAPIGetRequest, [BrandAdmin, Translation, Sections], options);
    yield put(getTranslationSectionsSuccess(data));

    yield put(changeTranslationLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }

    yield put(changeTranslationLoadingState(false));
  }
}

function* getAllTranslationsByKey({ payload }) {
  try {
    const options = {
      params: payload.data,
    };
    yield put(changeLoadingState(true));
    const { BrandAdmin, Translation, InAllSections } = AppConstants.api;
    const { data } = yield call(NetworkService.makeAPIGetRequest, [BrandAdmin, Translation, InAllSections], options);
    yield put(getAllTranslationsByKeySuccess(data));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* editTranslations({ payload }) {
  try {
    const options = {
      data: payload.data,
    };
    yield put(changeTranslationLoadingState(true));
    const { BrandAdmin, Translation } = AppConstants.api;
    yield call(NetworkService.makeAPIPostRequest, [BrandAdmin, Translation], options);
    yield put(changeEndedStatus(true));
    yield put(setSuccessMessage(getMessage(i18n.t('cms:theTranslation'), i18n.t('notification:slUpdated'))));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeTranslationLoadingState(false));
  }
}

function* exportTranslations({ payload }) {
  try {
    const options = {
      params: payload.data,
      paramsSerializer: (params) => qs.stringify(params, { allowDots: true }),
      responseType: 'blob',
    };
    yield put(changeLoadingState(true));
    const { BrandAdmin, Translation, Export } = AppConstants.api;
    const { data, headers } = yield call(NetworkService.makeAPIGetRequest, [BrandAdmin, Translation, Export], options);
    downloadFile(data, payload.data.languageIds, mime.detectExtension(headers['content-type']));
    yield put(setTranslationsChangesState(true));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* importTranslations({ payload }) {
  try {
    const options = {
      data: payload.data,
      headers: { 'content-type': 'multipart/form-data' },
    };
    yield put(changeLoadingState(true));
    const { BrandAdmin, Translation, Import } = AppConstants.api;
    yield call(NetworkService.makeAPIPostRequest, [BrandAdmin, Translation, Import], options);
    yield put(changeLoadingState(false));
    yield put(changeEndedStatus(true));
    yield put(setSuccessMessage(getMessage(i18n.t('communication:theTemplate'), i18n.t('notification:slImported'))));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* resetTranslationsToDefault({ payload }) {
  try {
    const { languagesNames, ...data } = payload.data;
    const options = {
      data,
    };
    yield put(changeLoadingState(true));
    const { BrandAdmin, Translation, ResetForLanguages } = AppConstants.api;
    yield call(NetworkService.makeAPIPostRequest, [BrandAdmin, Translation, ResetForLanguages], options);
    yield put(changeLoadingState(false));
    yield put(changeEndedStatus(true));
    yield put(setSuccessMessage(getMessage(i18n.t('cms:lSection'), i18n.t('notification:slReset'), languagesNames)));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getTranslatedLanguages({ payload }) {
  try {
    const options = {
      params: payload.data,
    };

    yield put(changeTranslatedLanguagesLoadingState(true));
    const { BrandAdmin, Translation, Languages } = AppConstants.api;
    const { data } = yield call(NetworkService.makeAPIGetRequest, [BrandAdmin, Translation, Languages], options);
    yield put(getTranslatedLanguagesSuccess(data));

    yield put(changeTranslatedLanguagesLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }

    yield put(changeTranslatedLanguagesLoadingState(false));
  }
}

export default function* translationSaga() {
  yield all([
    takeLatest(TranslationActionType.GET_TRANSLATION_SECTIONS, getTranslationSections),
    takeLatest(TranslationActionType.GET_ALL_TRANSLATIONS_BY_KEY, getAllTranslationsByKey),
    takeLatest(TranslationActionType.GET_ALL_TRANSLATIONS, getAllTranslations),
    takeLatest(TranslationActionType.EDIT_TRANSLATIONS, editTranslations),
    takeLatest(TranslationActionType.EXPORT_TRANSLATIONS, exportTranslations),
    takeLatest(TranslationActionType.IMPORT_TRANSLATIONS, importTranslations),
    takeLatest(TranslationActionType.RESET_TRANSLATIONS_TO_DEFAULT, resetTranslationsToDefault),
    takeLatest(TranslationActionType.GET_TRANSLATED_LANGUAGES, getTranslatedLanguages),
  ]);
}
