import PermissionGroupActionType from 'actions/permissionGroup/permissionGroupActionType';

const initialState = {
  loading: {},
  permissionGroups: [],
  allPermissionGroups: [],
  permissionGroupData: {},
  permissionGroupPermissions: {},
  lastUpdateTime: '',
};

const permissionGroupReducer = (state = initialState, { type, payload }) => {
  switch (type) {
  case PermissionGroupActionType.GET_PERMISSION_GROUP_SUCCESS:
    return {
      ...state,
      permissionGroupData: { ...state.permissionGroupData, [payload.key]: payload.data },
      permissionGroupPermissions: { ...state.permissionGroupPermissions, [payload.key]: payload.data.permissions },
    };
  case PermissionGroupActionType.RESET_PERMISSION_GROUP:
    return {
      ...state,
      permissionGroupData: { ...state.permissionGroupData, [payload.key]: {} },
      permissionGroupPermissions: { ...state.permissionGroupPermissions, [payload.key]: [] },
    };
  case PermissionGroupActionType.GET_PERMISSION_GROUPS_SUCCESS:
    return {
      ...state,
      permissionGroups: payload.data.data || [],
      lastUpdateTime: payload.data.lastUpdateTime,
    };
  case PermissionGroupActionType.GET_ALL_PERMISSION_GROUPS_SUCCESS:
    return {
      ...state,
      allPermissionGroups: payload.data.data || [],
    };
  case PermissionGroupActionType.RESET_ALL_PERMISSION_GROUPS:
    return {
      ...state,
      allPermissionGroups: [],
    };
  case PermissionGroupActionType.DELETE_PERMISSION_GROUP_SUCCESS:
    return {
      ...state,
      permissionGroups: payload.data,
    };
  case PermissionGroupActionType.CHANGE_PERMISSION_GROUP_LOADING_STATE:
    return {
      ...state,
      loading: { ...state.loading, [payload.key]: payload.state },
    };
  default:
    return state;
  }
};

export default permissionGroupReducer;
