import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Grid } from 'antd';
import { withTheme } from 'styled-components';
import { StyledCollapseMenuMobile, StyledIconMenu } from 'components/styledComponents/icon/Icon.styled';
import { settingKeys } from 'constants/configKeys';
import { IconSvg } from 'components/common';
import SiderMenu from './SiderMenu';
import { StyledSidebar, StyledSiderWrapper } from './Layout.styled';

const Sider = (props) => {
  const {
    menus,
    width,
    collapsedWidth,
    page,
    createOrEditUserConfig,
    activeMenu,
    theme: { colors },
    menuCollapse,
  } = props;

  const { useBreakpoint } = Grid;
  const screens = useBreakpoint();
  const isMobile = !screens.md;

  // initially collapsed for mobile case
  const [collapsed, setCollapsed] = useState(true);
  // open sub-menu for initial load
  const [initialSubMenu, setInitialSubMenu] = useState(true);

  // breakpoint hook works with delay
  useEffect(() => {
    setCollapsed(isMobile || menuCollapse);
  }, [isMobile, menuCollapse]);

  const collapseMenu = () => {
    setCollapsed(!collapsed);
    // only for desktop sizes
    if (!isMobile) {
      setInitialSubMenu(!collapsed);
      createOrEditUserConfig({ key: settingKeys.menuCollapse, configValue: !collapsed }, false);
    }
  };

  return (
    <>
      <StyledCollapseMenuMobile onClick={collapseMenu} collapsed={collapsed}>
        <div>
          <span />
        </div>
      </StyledCollapseMenuMobile>
      <StyledSiderWrapper width={width} collapsedWidth={collapsedWidth} collapsed={collapsed} className={page} reverseArrow>
        <StyledSidebar className="side-menu">
          <SiderMenu menus={menus} page={page} collapsed={collapsed} activeMenu={activeMenu} initialSubMenu={initialSubMenu} setInitialSubMenu={setInitialSubMenu} setCollapsed={setCollapsed} />
          <StyledIconMenu onClick={collapseMenu}>
            <IconSvg icon={collapsed ? 'MenuUnFold' : 'MenuFold'} width="0.93rem" height="0.8rem" color={colors.primaryText} />
          </StyledIconMenu>
        </StyledSidebar>
      </StyledSiderWrapper>
    </>
  );
};

Sider.propTypes = {
  menus: PropTypes.array,
  width: PropTypes.string.isRequired,
  collapsedWidth: PropTypes.string.isRequired,
  page: PropTypes.string,
  createOrEditUserConfig: PropTypes.func.isRequired,
  theme: PropTypes.object.isRequired,
  activeMenu: PropTypes.number.isRequired,
  menuCollapse: PropTypes.bool,
};

Sider.defaultProps = {
  menus: [],
  page: '',
  menuCollapse: undefined,
};

export default withTheme(Sider);
