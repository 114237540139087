import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { withTheme } from 'styled-components';
import {
  StyledHeaderSelectClose, StyledHeaderSelectToggle, StyledHeaderSelectWrapper, StyledSystemConversion,
} from 'components/layout/Layout.styled';
import { IconSvg } from 'components/common';
import { AdminSelect } from 'components/common/form';
import { LocalStorageService } from 'services';

const SystemConversions = (props) => {
  const {
    theme: { colors },
    conversionId,
    showConversion,
    systemConversions,
    setCurrentConversion,
    getSystemConversionsResource,
  } = props;
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (systemConversions.length) {
      const current = LocalStorageService.get('current') || {};

      let id = current?.conversionId;
      if (!id) {
        const defaultConversion = systemConversions.find(({ isDefault }) => isDefault);
        id = defaultConversion.id;
      }
      setCurrentConversion(id);

      LocalStorageService.set('current', {
        ...current,
        conversionId: id,
      });
    }
  }, [systemConversions, setCurrentConversion]);

  useEffect(() => {
    getSystemConversionsResource();
  }, [getSystemConversionsResource]);

  const openDropDown = () => {
    setOpen(true);
  };

  const closeDropDown = () => {
    setOpen(false);
  };

  const conversionChange = (id) => {
    const currentData = LocalStorageService.get('current') || {};

    LocalStorageService.set('current', {
      ...currentData,
      conversionId: id,
    });

    setCurrentConversion(id);
    closeDropDown();
  };

  return (
    !!systemConversions.length
    && showConversion && (
      <div>
        <StyledHeaderSelectToggle onClick={openDropDown}>
          <IconSvg icon="ManageCurrencyIcon" width="1.4rem" height="1.4rem" color={colors.white} />
        </StyledHeaderSelectToggle>
        <StyledHeaderSelectWrapper isOpen={open ? 1 : 0}>
          <StyledSystemConversion>
            <AdminSelect
              onChange={conversionChange}
              value={conversionId}
              allowClear={false}
              data={systemConversions.map((conversion) => ({
                id: conversion.id,
                name: conversion.id,
              }))}
              isToString={false}
            />
            <StyledHeaderSelectClose onClick={closeDropDown}>
              <IconSvg icon="CloseModalIcon" width="1.14rem" height="1.14rem" color={colors.white} />
            </StyledHeaderSelectClose>
          </StyledSystemConversion>
        </StyledHeaderSelectWrapper>
      </div>
    )
  );
};

SystemConversions.propTypes = {
  theme: PropTypes.object.isRequired,
  conversionId: PropTypes.string,
  showConversion: PropTypes.bool,
  systemConversions: PropTypes.array,
  setCurrentConversion: PropTypes.func.isRequired,
  getSystemConversionsResource: PropTypes.func.isRequired,
};

SystemConversions.defaultProps = {
  conversionId: null,
  showConversion: false,
  systemConversions: [],
};

export default withTheme(SystemConversions);
