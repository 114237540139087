import BetStatusCorrectionActionType from 'actions/betStatusCorrection/betStatusCorrectionActionType';

const initialState = {
  singleBetStatusCorrections: {},
  bulkBetStatusCorrections: {},
  betStatusCorrectionsStatistics: {},
};

const betStatusCorrectionReducer = (state = initialState, { type, payload }) => {
  switch (type) {
  case BetStatusCorrectionActionType.SAVE_SINGLE_BET_STATUS_CORRECTIONS:
    return {
      ...state,
      singleBetStatusCorrections: payload.data,
    };
  case BetStatusCorrectionActionType.RESET_SINGLE_BET_STATUS_CORRECTIONS:
    return {
      ...state,
      singleBetStatusCorrections: {},
    };
  case BetStatusCorrectionActionType.SAVE_BULK_BET_STATUS_CORRECTIONS:
    return {
      ...state,
      bulkBetStatusCorrections: payload.data,
    };
  case BetStatusCorrectionActionType.RESET_BULK_BET_STATUS_CORRECTIONS:
    return {
      ...state,
      bulkBetStatusCorrections: {},
    };
  case BetStatusCorrectionActionType.SAVE_BET_STATUS_CORRECTIONS_STATISTICS:
    return {
      ...state,
      betStatusCorrectionsStatistics: payload.data,
    };
  case BetStatusCorrectionActionType.RESET_BET_STATUS_CORRECTIONS_STATISTICS:
    return {
      ...state,
      betStatusCorrectionsStatistics: {},
    };
  default:
    return state;
  }
};

export default betStatusCorrectionReducer;
