import {
  all, call, put, takeLatest,
} from 'redux-saga/effects';
import {
  changeEndedStatus, changeLoadingState, setErrorMessage, setSuccessMessage,
} from 'actions/setting/settingActionCreator';
import NetworkService from 'services/networkService';
import { AppConstants } from 'constants/index';
import DevToolActionType from 'actions/devTool/devToolActionType';
import { getError, getMessage } from 'utils/helpers';
import {
  saveDbServices, saveDbServiceTables, saveSystemCacheData, saveSystemCaches, saveJobs,
} from 'actions/devTool/devToolActionCreator';
import { i18n } from 'services';

function* getSystemCaches({ payload }) {
  try {
    const { params } = payload;
    const options = {
      params,
    };
    const { BrandAdmin, IgniteCache, SystemCaches } = AppConstants.api;
    yield put(changeLoadingState(true));
    const { data } = yield call(NetworkService.makeAPIGetRequest, [BrandAdmin, IgniteCache, SystemCaches], options);
    yield put(saveSystemCaches(data));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getSystemCacheData({ payload }) {
  try {
    const { name } = payload;
    const {
      BrandAdmin, IgniteCache, SystemCaches, Data,
    } = AppConstants.api;
    yield put(changeLoadingState(true));
    const { data } = yield call(NetworkService.makeAPIGetRequest, [BrandAdmin, IgniteCache, SystemCaches, name, Data]);
    yield put(saveSystemCacheData(data));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* cleanSystemCache({ payload }) {
  try {
    const { name } = payload;
    const { BrandAdmin, IgniteCache, SystemCaches } = AppConstants.api;
    yield put(changeLoadingState(true));
    yield call(NetworkService.makeAPIDeleteRequest, [BrandAdmin, IgniteCache, SystemCaches, name]);
    yield put(changeEndedStatus(true));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getJobs() {
  try {
    const {
      ReportingAdmin, Jobs, Services, Dashboards,
    } = AppConstants.api;
    yield put(changeLoadingState(true));
    const { data } = yield call(NetworkService.makeAPIGetRequest, [ReportingAdmin, Jobs, Services, Dashboards]);
    yield put(saveJobs(data));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getDbServices() {
  try {
    const { DbSync, Services } = AppConstants.api;
    yield put(changeLoadingState(true));
    const { data } = yield call(NetworkService.makeAPIGetRequest, [DbSync, Services]);
    yield put(saveDbServices(data));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* syncDbService({ payload }) {
  try {
    const { data, name } = payload;
    const options = {
      data,
    };
    const { DbSync, Services, Sync } = AppConstants.api;
    yield put(changeLoadingState(true));
    yield call(NetworkService.makeAPIPostRequest, [DbSync, Services, name, Sync], options);
    if (data.tables?.length) {
      yield put(setSuccessMessage(getMessage(i18n.t('devTool:theTables'), i18n.t('notification:plSynchronized'))));
    } else {
      yield put(setSuccessMessage(getMessage(i18n.t('brand:service'), i18n.t('notification:slSynchronized'), name)));
    }
    yield put(changeEndedStatus(true));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getDbServiceTables({ payload }) {
  try {
    const { name } = payload;
    const { DbSync, Services, Tables } = AppConstants.api;
    yield put(changeLoadingState(true));
    const { data } = yield call(NetworkService.makeAPIGetRequest, [DbSync, Services, name, Tables]);
    yield put(saveDbServiceTables(data));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

export default function* devToolSaga() {
  yield all([
    takeLatest(DevToolActionType.GET_SYSTEM_CACHES, getSystemCaches),
    takeLatest(DevToolActionType.GET_SYSTEM_CACHE_DATA, getSystemCacheData),
    takeLatest(DevToolActionType.CLEAN_SYSTEM_CACHE, cleanSystemCache),
    takeLatest(DevToolActionType.GET_DB_SERVICES, getDbServices),
    takeLatest(DevToolActionType.SYNC_DB_SERVICE, syncDbService),
    takeLatest(DevToolActionType.GET_DB_SERVICE_TABLES, getDbServiceTables),
    takeLatest(DevToolActionType.GET_JOBS, getJobs),
  ]);
}
