import _ from 'lodash';
import { createSelector } from 'reselect';

export const selectStaticCollectionLoading = (state) => state.loading;
export const selectStaticCollectionLanguages = (state) => state.languages;
export const selectStaticCollectionCountries = (state) => state.countries;
export const selectStaticCollectionCurrencies = (state) => state.currencies;
export const selectStaticCollectionCities = (state) => state.cities;
export const selectStaticCollectionTimeZones = (state) => state.timeZones;
export const selectTransactionsOperationTypes = createSelector(
  (state) => state.operationTypes,
  (operationTypes) => _.sortBy(operationTypes, 'name'),
);
export const selectStaticCollectionRegions = (state) => state.regions;
