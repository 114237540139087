import {
  useState, useEffect, useCallback, useMemo,
} from 'react';
import PropTypes from 'prop-types';
import { Editor } from '@tinymce/tinymce-react';
import { LoadingOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { MessageService } from 'services';
import { AppConstants } from 'constants/index';
import NetworkService from 'services/networkService';
import { getFileNameAndExtension } from 'utils/utils';
import { getError, getFileSizeByMb } from 'utils/helpers';
import i18n from 'services/i18n';
import { AdminEditorWrapper, AdminEditorLoader } from 'components/common/adminEditor/AdminEditor.styled';
import _ from 'lodash';

const accept = ['png', 'jpg', 'jpeg'];
const maxFileSize = 5;

const AdminEditor = (props) => {
  const {
    editorChange, value, tags, textMode, height, setErrorMessage, brandId, directoryPath, noToolbar, changeLoadingState, readOnly, isNightMode, initialValue, plugins, directoryRules,
  } = props;

  const { t } = useTranslation();
  const { language } = i18n;

  const [key, setKey] = useState(false);

  const nightModeConf = useMemo(
    () => (isNightMode
      ? {
        skin: 'oxide-dark',
        content_css: 'dark',
      }
      : {}),
    [isNightMode],
  );

  useEffect(() => {
    setKey((prevKey) => !prevKey);
  }, [language, isNightMode, tags, directoryRules]);

  const generateTagsMenu = useCallback(
    (editor) => tags.map((el) => ({
      type: 'menuitem',
      text: el.key,
      icon: 'copy',
      onAction() {
        return editor.insertContent(el.key);
      },
    })),
    [tags],
  );

  const handleUpload = useCallback(
    (file) => {
      changeLoadingState?.(true);
      const formData = new FormData();
      if (brandId) {
        formData.append('brandId', brandId);
      }
      formData.append('file', file);
      formData.append('directoryPath', directoryPath);
      formData.append('generateNewFileName', true);
      const options = {
        data: formData,
        headers: { 'content-type': 'multipart/form-data' },
      };
      const { BrandAdmin, FileManager, UploadFile } = AppConstants.api;
      return NetworkService.makeAPIPostRequest([BrandAdmin, FileManager, UploadFile], options);
    },
    [brandId, changeLoadingState, directoryPath],
  );

  const defaultToolbar = textMode
    ? 'undo redo '
    : `a11ycheck undo redo | bold italic | forecolor backcolor |
     template | alignleft aligncenter alignright alignjustify
       | bullist numlist | link image tinydrive code | table `;

  const toolbar = tags.length ? defaultToolbar.concat('insertfield') : defaultToolbar;
  const noToolbarConfig = useMemo(
    () => (noToolbar
      ? {
        menubar: false,
        toolbar: false,
      }
      : {}),
    [noToolbar],
  );

  const getBaseConfig = useCallback(
    () => ({
      width: '100%',
      height,
      resize: false,
      branding: false,
      visual: false,
      language,
      statusbar: false,
      language_url: `/tinymce/langs/${language}.js`,
      menu: {
        file: { title: 'File', items: 'newdocument  | fullscreen ' },
        edit: { title: 'Edit', items: 'undo redo | cut copy | selectall | searchreplace' },
        view: { title: 'View', items: 'preview | visualchars visualblocks' },
        insert: { title: 'Insert', items: '' },
        format: { title: 'Format', items: 'underline strikethrough superscript subscript codeformat | formats blockformats fontformats fontsizes | removeformat' },
        tools: { title: 'Tools', items: '' },
        table: { title: 'Table', items: '' },
        help: { title: 'Help', items: 'help' },
      },
      autosave_ask_before_unload: false,
      powerpaste_allow_local_images: true,
      plugins,
      custom_elements: 'style,link,~link',
      image_title: true,
      automatic_uploads: false,
      file_picker_types: 'image',
      file_picker_callback(cb) {
        const input = document.createElement('input');
        input.setAttribute('type', 'file');
        input.setAttribute('accept', 'image/*');

        input.onchange = (e) => {
          const { files } = e.target;
          const file = files[0];
          const [, extension] = getFileNameAndExtension(file.name);
          const ext = `.${extension}`;
          if (!accept.includes(extension) || (brandId && !directoryRules.find((el) => el.permittedFileTypeExtension === ext))) {
            MessageService.error(t('notification:wrongFileFormat'));
            return false;
          }

          const allowedMaxFileSize = brandId
            ? getFileSizeByMb(directoryRules.find((el) => (_.isNull(el.permittedFileTypeExtension) || el.permittedFileTypeExtension === ext) && !_.isNull(el.maxFileSize)).maxFileSize)
            : null;
          const allowedFileSize = !_.isNull(allowedMaxFileSize) ? Math.min(maxFileSize, allowedMaxFileSize) : maxFileSize;
          const fileSize = getFileSizeByMb(file.size);

          if (fileSize > allowedFileSize) {
            MessageService.error(`${t('notification:maxUploadSize')} ${allowedFileSize}MB`);
            return false;
          }

          handleUpload(file)
            .then(({ data }) => {
              changeLoadingState?.(false);
              const {
                fileInfo: { cdnUrl },
              } = data;
              const reader = new FileReader();
              reader.onload = () => {
                cb(cdnUrl, { title: file.name });
              };
              reader.readAsDataURL(file);
            })
            .catch((err) => {
              changeLoadingState?.(false);
              if (err) {
                const error = getError(err);
                setErrorMessage?.(error);
              }
            });
        };

        input.click();
      },
      templates: [],
      toolbar,
      spellchecker_dialog: true,
      setup(editor) {
        editor.ui.registry.addMenuButton('insertfield', {
          icon: 'code-sample',
          text: t('tags'),
          fetch(callback) {
            callback(generateTagsMenu(editor));
          },
        });
      },
      ...noToolbarConfig,
      ...nightModeConf,
    }),
    [height, language, plugins, toolbar, noToolbarConfig, nightModeConf, brandId, directoryRules, handleUpload, t, changeLoadingState, setErrorMessage, generateTagsMenu],
  );

  const onlyTextConfig = {
    ...getBaseConfig(),
    plugins: [],
    toolbar,
    context_menu: false,
    menubar: false,
  };

  const config = textMode ? onlyTextConfig : getBaseConfig();

  const handleEditorChange = (content) => {
    if (content !== value) {
      editorChange?.(content);
    }
  };

  return (
    <AdminEditorWrapper>
      <AdminEditorLoader indicator={<LoadingOutlined spin />} />
      <Editor disabled={readOnly} key={key} init={{ ...config, ...nightModeConf }} onEditorChange={handleEditorChange} initialValue={initialValue} value={value} />
    </AdminEditorWrapper>
  );
};

AdminEditor.propTypes = {
  changeLoadingState: PropTypes.func,
  textMode: PropTypes.bool,
  editorChange: PropTypes.func,
  setErrorMessage: PropTypes.func,
  value: PropTypes.string,
  tags: PropTypes.array,
  height: PropTypes.string,
  directoryPath: PropTypes.string,
  brandId: PropTypes.number,
  noToolbar: PropTypes.bool,
  readOnly: PropTypes.bool,
  isNightMode: PropTypes.bool.isRequired,
  initialValue: PropTypes.any,
  plugins: PropTypes.array,
  directoryRules: PropTypes.array,
};

AdminEditor.defaultProps = {
  editorChange: null,
  setErrorMessage: null,
  value: '',
  tags: [],
  textMode: false,
  height: '500',
  directoryPath: 'messages',
  brandId: null,
  noToolbar: false,
  readOnly: false,
  initialValue: undefined,
  changeLoadingState: null,
  plugins: ['autolink', 'codesample', 'fullscreen', 'image', 'lists', 'link', 'searchreplace', 'table', 'visualblocks', 'wordcount', 'code', 'preview'],
  directoryRules: [],
};

export default AdminEditor;
