import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import ChooseTemplate from 'components/brand/create/ChooseTemplate';
import { addBrand, getTemplates } from 'actions/brand/brandActionCreator';
import { changeLoadingState } from 'actions/setting/settingActionCreator';
import { selectBrandLoading, selectCurrentPartnerId, selectTemplates } from 'selectors';

const mapStateToProps = ({ brands, partners }) => ({
  templates: selectTemplates(brands),
  currentPartnerId: selectCurrentPartnerId(partners),
  loading: selectBrandLoading(brands),
});

const mapDispatchToProps = (dispatch) => bindActionCreators(
  {
    addBrand,
    getTemplates,
    changeLoadingState,
  },
  dispatch,
);

export default connect(mapStateToProps, mapDispatchToProps)(ChooseTemplate);
