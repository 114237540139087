import {
  all, call, put, takeLatest,
} from 'redux-saga/effects';
import qs from 'qs';
import mime from 'mimetypes';
import PromoCodeActionType from 'actions/promoCode/promoCodeActionType';
import { AppConstants } from 'constants/index';
import { downloadFile } from 'utils/utils';
import { getError } from 'utils/helpers';
import {
  savePromoCodesPacks, savePromoCode, saveUsedPromoCodes, saveIsPromoCodeExists, savePromoCodes, changePromoCodeEndedStatus, saveCsvPromoCodes,
} from 'actions/promoCode/promoCodeActionCreator';
import { setErrorMessage, changeLoadingState } from 'actions/setting/settingActionCreator';
import NetworkService from 'services/networkService';
import { i18n, MessageService } from 'services';

function* getPromoCodesPacks({ payload }) {
  try {
    const { params } = payload;
    const options = {
      params,
      paramsSerializer: (param) => qs.stringify(param),
    };
    const { BonusAdmin, PromoCodePack } = AppConstants.api;
    yield put(changeLoadingState(true));
    const response = yield call(NetworkService.makeAPIGetRequest, [BonusAdmin, PromoCodePack], options);
    yield put(savePromoCodesPacks(response.data));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getPromoCode({ payload }) {
  try {
    const { BonusAdmin, PromoCodePack } = AppConstants.api;
    yield put(changeLoadingState(true));
    const response = yield call(NetworkService.makeAPIGetRequest, [BonusAdmin, PromoCodePack, payload.id]);
    yield put(savePromoCode(response.data));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err, true);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getUsedPromoCodes({ payload }) {
  try {
    const { id, params } = payload;
    const options = {
      params,
      paramsSerializer: (param) => qs.stringify(param),
    };
    const {
      BonusAdmin, PromoCodePack, PromoCodes, Used,
    } = AppConstants.api;
    yield put(changeLoadingState(true));
    const { data } = yield call(NetworkService.makeAPIGetRequest, [BonusAdmin, PromoCodePack, id, PromoCodes, Used], options);
    yield put(saveUsedPromoCodes(data));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err, true);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* exportAvailablePromoCodes({ payload }) {
  try {
    const options = {
      params: payload.params,
      paramsSerializer: (params) => qs.stringify(params, { allowDots: true }),
      responseType: 'blob',
    };
    const {
      BonusAdmin, PromoCodePack, PromoCodes, Available, Export,
    } = AppConstants.api;

    yield put(changeLoadingState(true));
    const { headers, data } = yield call(NetworkService.makeAPIGetRequest, [BonusAdmin, PromoCodePack, payload.id, PromoCodes, Available, Export], options);
    downloadFile(data, i18n.t('bonus:availablePromoCodes'), mime.detectExtension(headers['content-type']));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err, true);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getIsPromoCodeExists({ payload }) {
  try {
    const { brandId, params } = payload;
    const {
      BonusAdmin, PromoCode, Exists, Code,
    } = AppConstants.api;
    yield put(changeLoadingState(true));
    const { data } = yield call(NetworkService.makeAPIGetRequest, [BonusAdmin, PromoCode, Exists, Code, brandId], { params });
    yield put(saveIsPromoCodeExists(data));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getPromoCodes({ payload }) {
  try {
    const { id } = payload;
    const { BonusAdmin, PromoCodePack, PromoCodes } = AppConstants.api;
    yield put(changeLoadingState(true));
    const { data } = yield call(NetworkService.makeAPIGetRequest, [BonusAdmin, PromoCodePack, id, PromoCodes]);
    yield put(savePromoCodes(data));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* exportUsedPromoCodes({ payload }) {
  try {
    const options = {
      params: payload.params,
      paramsSerializer: (params) => qs.stringify(params, { allowDots: true }),
      responseType: 'blob',
    };
    const {
      BonusAdmin, PromoCodePack, PromoCodes, Used, Export,
    } = AppConstants.api;

    yield put(changeLoadingState(true));
    const { headers, data } = yield call(NetworkService.makeAPIGetRequest, [BonusAdmin, PromoCodePack, payload.id, PromoCodes, Used, Export], options);
    downloadFile(data, i18n.t('bonus:usedPromoCodes'), mime.detectExtension(headers['content-type']));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err, true);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getCsvPromoCodes({ payload }) {
  try {
    yield put(changeLoadingState(true));
    const { BonusAdmin, PromoCode, Validate } = AppConstants.api;
    const options = {
      data: payload.data,
      headers: { 'content-type': 'multipart/form-data' },
    };
    const { data } = yield call(NetworkService.makeAPIPostRequest, [BonusAdmin, PromoCode, Validate], options);
    yield put(saveCsvPromoCodes(data?.items));
    yield put(changeLoadingState(false));
    yield put(changePromoCodeEndedStatus(true));
    if (!data?.items?.length) {
      MessageService.error(i18n.t('notification:atLeastOnePromoCodeMustBeUploaded'));
    }
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
      yield put(changePromoCodeEndedStatus(true));
    }
    yield put(changeLoadingState(false));
  }
}

export default function* promoCodeSaga() {
  yield all([
    takeLatest(PromoCodeActionType.GET_PROMO_CODES_PACKS, getPromoCodesPacks),
    takeLatest(PromoCodeActionType.GET_PROMO_CODE, getPromoCode),
    takeLatest(PromoCodeActionType.GET_USED_PROMO_CODES, getUsedPromoCodes),
    takeLatest(PromoCodeActionType.EXPORT_AVAILABLE_PROMO_CODES, exportAvailablePromoCodes),
    takeLatest(PromoCodeActionType.GET_IS_PROMO_CODE_EXISTS, getIsPromoCodeExists),
    takeLatest(PromoCodeActionType.GET_PROMO_CODES, getPromoCodes),
    takeLatest(PromoCodeActionType.EXPORT_USED_PROMO_CODES, exportUsedPromoCodes),
    takeLatest(PromoCodeActionType.GET_CSV_PROMO_CODES, getCsvPromoCodes),
  ]);
}
