import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Sider } from 'components/layout';
import { createOrEditUserConfig } from 'actions/user/userActionCreator';
import { selectActiveMenu, selectUserConfig } from 'selectors';
import { settingKeys } from 'constants/configKeys';

const mapDispatchToProps = (dispatch) => bindActionCreators(
  {
    createOrEditUserConfig,
  },
  dispatch,
);

const mapStateToProps = ({ users, settings }) => ({
  menuCollapse: selectUserConfig(users, settingKeys.menuCollapse),
  activeMenu: selectActiveMenu(settings),
});

export default connect(mapStateToProps, mapDispatchToProps)(Sider);
