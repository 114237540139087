import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  getLanguages, getCurrencies, getCountries, getTimeZones,
} from 'actions/staticCollection/staticCollectionActionCreator';
import { changeLoadingState } from 'actions/setting/settingActionCreator';
import {
  selectStaticCollectionCountries,
  selectStaticCollectionCurrencies,
  selectStaticCollectionLanguages,
  selectStaticCollectionLoading,
  selectBrandMarkets,
  selectCurrentPartnerId,
  selectIsBrandExist,
  selectStaticCollectionTimeZones,
} from 'selectors';
import BasicSettings from 'components/brand/create/BasicSettings';
import {
  changeBrandLoadingState, getMarkets, getIsBrandExist, resetIsBrandExist,
} from 'actions/brand/brandActionCreator';

const mapStateToProps = ({ staticCollection, brands, partners }) => ({
  loading: selectStaticCollectionLoading(staticCollection),
  languages: selectStaticCollectionLanguages(staticCollection),
  countries: selectStaticCollectionCountries(staticCollection),
  currencies: selectStaticCollectionCurrencies(staticCollection),
  timeZones: selectStaticCollectionTimeZones(staticCollection),
  markets: selectBrandMarkets(brands),
  partnerId: selectCurrentPartnerId(partners),
  isBrandExist: selectIsBrandExist(brands),
});

const mapDispatchToProps = (dispatch) => bindActionCreators(
  {
    getLanguages,
    getCurrencies,
    getCountries,
    getTimeZones,
    changeLoadingState,
    changeBrandLoadingState,
    getMarkets,
    getIsBrandExist,
    resetIsBrandExist,
  },
  dispatch,
);

export default connect(mapStateToProps, mapDispatchToProps)(BasicSettings);
