const DevToolActionType = {
  GET_SYSTEM_CACHES: 'GET_SYSTEM_CACHES',
  SAVE_SYSTEM_CACHES: 'SAVE_SYSTEM_CACHES',
  RESET_SYSTEM_CACHES: 'RESET_SYSTEM_CACHES',

  CLEAN_SYSTEM_CACHE: 'CLEAN_SYSTEM_CACHE',

  GET_SYSTEM_CACHE_DATA: 'GET_SYSTEM_CACHE_DATA',
  SAVE_SYSTEM_CACHE_DATA: 'SAVE_SYSTEM_CACHE_DATA',
  RESET_SYSTEM_CACHE_DATA: 'RESET_SYSTEM_CACHE_DATA',

  GET_DB_SERVICES: 'GET_DB_SERVICES',
  SAVE_DB_SERVICES: 'SAVE_DB_SERVICES',
  RESET_DB_SERVICES: 'RESET_DB_SERVICES',

  GET_DB_SERVICE_TABLES: 'GET_DB_SERVICE_TABLES',
  SAVE_DB_SERVICE_TABLES: 'SAVE_DB_SERVICE_TABLES',
  RESET_DB_SERVICE_TABLES: 'RESET_DB_SERVICE_TABLES',

  SYNC_DB_SERVICE: 'SYNC_DB_SERVICE',

  GET_JOBS: 'GET_JOBS',
  SAVE_JOBS: 'SAVE_JOBS',
  RESET_JOBS: 'RESET_JOBS',
};

export default DevToolActionType;
