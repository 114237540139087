import styled from 'styled-components';
import { media } from 'utils/styledHelpers';
import { StyledCustomTable } from 'components/styledComponents/customTable/CustomTable.styled';

const StyledVerificationWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  height: 100%;
  .ant-form {
    height: 100%;
    .ant-table {
      .ant-table-tbody > tr:not(.ant-table-expanded-row) > td:not(.custom-checkbox):last-child {
        padding: 0 1rem;
      }
      .ant-form-item {
        width: 16.4rem;
        max-width: 100%;

        @media (max-width: 1280px) {
          width: 17.3rem;
        }
      }
    }
  }
  .ant-table-wrapper,
  ${StyledCustomTable} {
    flex-grow: 1;
    margin-bottom: 1.71rem;
  }
`;

const StyledVerificationText = styled.p`
  display: inline-block;
  border: 1px solid ${(props) => props.theme.colors.primaryBorder};
  padding: 0.36rem 0.71rem;
  border-radius: ${(props) => props.theme.customBorderRadius.borderRadiusSmall};
`;
const StyledAndContainer = styled.div`
  display: flex;
  justify-content: center;
`;
const StyledPlayerVerification = styled.div`
  height: 100%;
  .ant-form {
    display: flex;
    flex-direction: column;
    .ant-input-number,
    .ant-form-item-control {
      width: 25.5rem;
    }
    .ant-form-item-label label {
      width: 100%;
    }
  }
`;

const StyledButtonsWrapper = styled.div`
  margin-top: auto;

  ${media.sm`
    margin-top: 2rem;
  `}
`;

export {
  StyledVerificationWrapper, StyledVerificationText, StyledAndContainer, StyledPlayerVerification, StyledButtonsWrapper,
};
