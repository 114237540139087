import PropTypes from 'prop-types';
import _ from 'lodash';
import AdminTableCell from './AdminTableCell';

const AdminTableRow = ({
  form, children: cells, className, actions, isExpanded, ...rest
}) => (_.isArray(cells) ? (
  <tr className={className} {...rest}>
    {cells.map(({ props, key }) => (
      <AdminTableCell {...props} isExpanded={isExpanded} key={key} form={form} actions={actions} />
    ))}
  </tr>
) : (
  <tr className={className} {...rest}>
    {cells}
  </tr>
));

AdminTableRow.propTypes = {
  form: PropTypes.object,
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]).isRequired,
  actions: PropTypes.object,
  className: PropTypes.string,
  isExpanded: PropTypes.bool,
};

AdminTableRow.defaultProps = {
  actions: {},
  form: {},
  className: '',
  isExpanded: false,
};

export default AdminTableRow;
