import {
  put, call, takeLatest, all, select,
} from 'redux-saga/effects';
import qs from 'qs';
import NetworkService from 'services/networkService';
import {
  changeEndedStatus, changeLoadingState, setErrorMessage, setSuccessMessage,
} from 'actions/setting/settingActionCreator';
import {
  saveBrandFailedPaymentsSettings,
  saveBrandPaymentMethods,
  saveBrandPaymentSettings,
  saveBrandPaymentMethodsFees,
  saveBrandPaymentMethodsLimits,
  saveBrandPaymentMethodSettings,
  saveBrandPaymentAllowedCurrencies,
  saveBrandPaymentAllowedCountries,
  savePaymentCurrencies,
  saveBrandPaymentsActiveCurrencies,
  saveBrandPaymentMethodTags,
} from 'actions/brandPayment/brandPaymentActionCreator';
import BrandPaymentActionType from 'actions/brandPayment/brandPaymentActionType';
import { AppConstants } from 'constants/index';
import { getError, getMessage } from 'utils/helpers';
import { i18n } from 'services';
import { selectBrandPaymentsActiveCurrencies } from 'selectors/brandPaymentSelector';

function* getBrandFailedPaymentsSettings({ payload }) {
  try {
    const { brandId } = payload;
    yield put(changeLoadingState(true));
    const { BrandAdmin, BrandMarketSetting, FailedPayments } = AppConstants.api;
    const { data } = yield call(NetworkService.makeAPIGetRequest, [BrandAdmin, BrandMarketSetting, FailedPayments, brandId]);
    yield put(saveBrandFailedPaymentsSettings(data));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* editBrandFailedPaymentsSettings({ payload }) {
  try {
    const { brandId } = payload;
    const options = {
      data: payload.data,
    };
    yield put(changeLoadingState(true));
    const { BrandAdmin, BrandMarketSetting, FailedPayments } = AppConstants.api;
    const { data } = yield call(NetworkService.makeAPIPostRequest, [BrandAdmin, BrandMarketSetting, FailedPayments, brandId], options);
    yield put(saveBrandFailedPaymentsSettings(data));
    yield put(changeLoadingState(false));
    yield put(setSuccessMessage(getMessage(i18n.t('payment:failedPaymentSettings'), i18n.t('notification:plUpdated'))));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getBrandPaymentMethods({ payload }) {
  try {
    const { params, id } = payload;
    const options = {
      params,
      paramsSerializer: (param) => qs.stringify(param),
    };
    yield put(changeLoadingState(true));
    const { PaymentAdmin, BrandPaymentMethod, ByBrand } = AppConstants.api;
    const { data } = yield call(NetworkService.makeAPIGetRequest, [PaymentAdmin, BrandPaymentMethod, ByBrand, id], options);
    yield put(saveBrandPaymentMethods(data));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getBrandPaymentSettings({ payload }) {
  try {
    const { params } = payload;
    const options = {
      params,
    };
    yield put(changeLoadingState(true));
    const { PaymentAdmin, BrandPaymentGlobal, BrandPaymentGlobalSetting } = AppConstants.api;
    const { data } = yield call(NetworkService.makeAPIGetRequest, [PaymentAdmin, BrandPaymentGlobal, BrandPaymentGlobalSetting], options);
    yield put(saveBrandPaymentSettings(data));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* editBrandPaymentSettings({ payload }) {
  try {
    const { data, brandId } = payload;
    const options = {
      data,
    };
    yield put(changeLoadingState(true));
    const { PaymentAdmin, BrandPaymentGlobal, BrandPaymentGlobalSetting } = AppConstants.api;
    yield call(NetworkService.makeAPIPutRequest, [PaymentAdmin, BrandPaymentGlobal, BrandPaymentGlobalSetting, brandId], options);
    yield put(changeEndedStatus(true));
    yield put(changeLoadingState(false));
    yield put(setSuccessMessage(getMessage(i18n.t('payment:paymentSettings'), i18n.t('notification:plUpdated'))));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getBrandPaymentMethodsFees({ payload }) {
  try {
    const { params, id } = payload;
    const options = {
      params,
      paramsSerializer: (param) => qs.stringify(param),
    };
    yield put(changeLoadingState(true));
    const { PaymentAdmin, BrandPaymentMethod, Fees } = AppConstants.api;
    const { data } = yield call(NetworkService.makeAPIGetRequest, [PaymentAdmin, BrandPaymentMethod, Fees, id], options);
    yield put(saveBrandPaymentMethodsFees(data));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getBrandPaymentMethodsLimits({ payload }) {
  try {
    const { params, id } = payload;
    const options = {
      params,
      paramsSerializer: (param) => qs.stringify(param),
    };
    yield put(changeLoadingState(true));
    const { PaymentAdmin, BrandPaymentMethod, Limits } = AppConstants.api;
    const { data } = yield call(NetworkService.makeAPIGetRequest, [PaymentAdmin, BrandPaymentMethod, Limits, id], options);
    yield put(saveBrandPaymentMethodsLimits(data));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getBrandPaymentMethodSettings({ payload }) {
  try {
    const { id, brandId } = payload;
    const options = {
      params: {
        id,
        brandId,
      },
    };
    yield put(changeLoadingState(true));
    const { PaymentAdmin, BrandPaymentMethod } = AppConstants.api;
    const { data } = yield call(NetworkService.makeAPIGetRequest, [PaymentAdmin, BrandPaymentMethod], options);
    yield put(saveBrandPaymentMethodSettings(data));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* editBrandPaymentMethodSettings({ payload }) {
  try {
    const { data, name } = payload;
    const options = {
      data,
      name,
    };
    yield put(changeLoadingState(true));
    const { PaymentAdmin, BrandPaymentMethod } = AppConstants.api;
    yield call(NetworkService.makeAPIPutRequest, [PaymentAdmin, BrandPaymentMethod], options);
    yield put(changeEndedStatus(true));
    yield put(changeLoadingState(false));
    yield put(setSuccessMessage(getMessage(i18n.t('payment:lPaymentMethod'), i18n.t('notification:slUpdated'), name)));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getBrandPaymentAllowedCurrencies({ payload }) {
  try {
    const { id, params } = payload;
    const options = {
      params,
    };
    yield put(changeLoadingState(true));
    const { PaymentAdmin, BrandPaymentMethod, AllowedCurrencies } = AppConstants.api;
    const { data } = yield call(NetworkService.makeAPIGetRequest, [PaymentAdmin, BrandPaymentMethod, AllowedCurrencies, id], options);
    yield put(saveBrandPaymentAllowedCurrencies(data));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getBrandPaymentAllowedCountries({ payload }) {
  const { isLoading, params } = payload;
  try {
    if (isLoading) {
      yield put(changeLoadingState(true));
    }

    const options = {
      params,
    };
    const { PaymentAdmin, BrandPaymentMethod, AllowedCountries } = AppConstants.api;
    const { data } = yield call(NetworkService.makeAPIGetRequest, [PaymentAdmin, BrandPaymentMethod, AllowedCountries], options);
    yield put(saveBrandPaymentAllowedCountries(data));

    if (isLoading) {
      yield put(changeLoadingState(false));
    }
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    if (isLoading) {
      yield put(changeLoadingState(false));
    }
  }
}

function* getPaymentCurrencies({ payload }) {
  try {
    const { id } = payload;
    yield put(changeLoadingState(true));
    const { PaymentAdmin, BrandPaymentMethod, PaymentCurrencies } = AppConstants.api;
    const { data } = yield call(NetworkService.makeAPIGetRequest, [PaymentAdmin, BrandPaymentMethod, PaymentCurrencies, id]);
    yield put(savePaymentCurrencies(data));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getBrandPaymentActiveCurrencies({ payload }) {
  try {
    const { brandId, paymentId } = payload;
    yield put(changeLoadingState(true));
    const { PaymentAdmin, BrandPaymentMethod, Currencies } = AppConstants.api;
    const { data } = yield call(NetworkService.makeAPIGetRequest, [PaymentAdmin, BrandPaymentMethod, brandId, paymentId, Currencies]);
    const { brandPayment } = yield select();
    const activeCurrencies = selectBrandPaymentsActiveCurrencies(brandPayment);
    activeCurrencies[paymentId] = data;
    yield put(saveBrandPaymentsActiveCurrencies(activeCurrencies));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getBrandPaymentMethodTags({ payload }) {
  try {
    const { params } = payload;
    const options = {
      params,
    };
    yield put(changeLoadingState(true));
    const { PaymentAdmin, BrandPaymentMethod, Tags } = AppConstants.api;
    const { data } = yield call(NetworkService.makeAPIGetRequest, [PaymentAdmin, BrandPaymentMethod, Tags], options);
    yield put(saveBrandPaymentMethodTags(data));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* editBrandPaymentMethodTags({ payload }) {
  try {
    const { data, params, name } = payload;
    const options = {
      data,
      params,
    };
    yield put(changeLoadingState(true));
    const { PaymentAdmin, BrandPaymentMethod, Tags } = AppConstants.api;
    yield call(NetworkService.makeAPIPostRequest, [PaymentAdmin, BrandPaymentMethod, Tags], options);
    yield put(changeEndedStatus(true));
    yield put(setSuccessMessage(i18n.t('notification:visibilityConfigurationUpdated', { name })));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

export default function* brandPaymentSaga() {
  yield all([
    takeLatest(BrandPaymentActionType.GET_BRAND_FAILED_PAYMENTS_SETTINGS, getBrandFailedPaymentsSettings),
    takeLatest(BrandPaymentActionType.EDIT_BRAND_FAILED_PAYMENTS_SETTINGS, editBrandFailedPaymentsSettings),
    takeLatest(BrandPaymentActionType.GET_BRAND_PAYMENT_METHODS, getBrandPaymentMethods),
    takeLatest(BrandPaymentActionType.GET_BRAND_PAYMENT_SETTINGS, getBrandPaymentSettings),
    takeLatest(BrandPaymentActionType.EDIT_BRAND_PAYMENT_SETTINGS, editBrandPaymentSettings),
    takeLatest(BrandPaymentActionType.GET_BRAND_PAYMENT_METHODS_FEES, getBrandPaymentMethodsFees),
    takeLatest(BrandPaymentActionType.GET_BRAND_PAYMENT_METHODS_LIMITS, getBrandPaymentMethodsLimits),
    takeLatest(BrandPaymentActionType.GET_BRAND_PAYMENT_METHOD_SETTINGS, getBrandPaymentMethodSettings),
    takeLatest(BrandPaymentActionType.EDIT_BRAND_PAYMENT_METHOD_SETTINGS, editBrandPaymentMethodSettings),
    takeLatest(BrandPaymentActionType.GET_BRAND_PAYMENT_ALLOWED_CURRENCIES, getBrandPaymentAllowedCurrencies),
    takeLatest(BrandPaymentActionType.GET_BRAND_PAYMENT_ALLOWED_COUNTRIES, getBrandPaymentAllowedCountries),
    takeLatest(BrandPaymentActionType.GET_PAYMENT_CURRENCIES, getPaymentCurrencies),
    takeLatest(BrandPaymentActionType.GET_BRAND_PAYMENTS_ACTIVE_CURRENCIES, getBrandPaymentActiveCurrencies),
    takeLatest(BrandPaymentActionType.GET_BRAND_PAYMENT_METHOD_TAGS, getBrandPaymentMethodTags),
    takeLatest(BrandPaymentActionType.EDIT_BRAND_PAYMENT_METHOD_TAGS, editBrandPaymentMethodTags),
  ]);
}
