const PaymentActionType = {
  GET_DEPOSITS: 'GET_DEPOSITS',
  SAVE_DEPOSITS: 'SAVE_DEPOSITS',
  RESET_DEPOSITS: 'RESET_DEPOSITS',
  GENERATE_DEPOSITS_REPORT: 'GENERATE_DEPOSITS_REPORT',

  GET_PLAYER_DEPOSITS: 'GET_PLAYER_DEPOSITS',
  SAVE_PLAYER_DEPOSITS: 'SAVE_PLAYER_DEPOSITS',
  RESET_PLAYER_DEPOSITS: 'RESET_PLAYER_DEPOSITS',
  EXPORT_PLAYER_DEPOSITS: 'EXPORT_PLAYER_DEPOSITS',

  GET_PLAYER_WITHDRAWALS: 'GET_PLAYER_WITHDRAWALS',
  SAVE_PLAYER_WITHDRAWALS: 'SAVE_PLAYER_WITHDRAWALS',
  RESET_PLAYER_WITHDRAWALS: 'RESET_PLAYER_WITHDRAWALS',
  EXPORT_PLAYER_WITHDRAWALS: 'EXPORT_PLAYER_WITHDRAWALS',

  GET_WITHDRAWALS: 'GET_WITHDRAWALS',
  SAVE_WITHDRAWALS: 'SAVE_WITHDRAWALS',
  RESET_WITHDRAWALS: 'RESET_WITHDRAWALS',
  GENERATE_WITHDRAWALS_REPORT: 'GENERATE_WITHDRAWALS_REPORT',

  GET_WITHDRAWAL_NOTES: 'GET_WITHDRAWAL_NOTES',
  GET_DEPOSIT_NOTES: 'GET_DEPOSIT_NOTES',
  SAVE_PAYMENT_NOTES: 'SAVE_PAYMENT_NOTES',
  RESET_PAYMENT_NOTES: 'RESET_PAYMENT_NOTES',
  ADD_WITHDRAWAL_NOTE: 'ADD_WITHDRAWAL_NOTE',
  ADD_DEPOSIT_NOTE: 'ADD_DEPOSIT_NOTE',

  GET_DEPOSIT_DETAILS: 'GET_DEPOSIT_DETAILS',
  GET_WITHDRAWAL_DETAILS: 'GET_WITHDRAWAL_DETAILS',
  SAVE_PAYMENT_DETAILS: 'SAVE_PAYMENT_DETAILS',
  RESET_PAYMENT_DETAILS: 'RESET_PAYMENT_DETAILS',

  GET_DEPOSIT_LOGS: 'GET_DEPOSIT_LOGS',
  GET_WITHDRAWAL_LOGS: 'GET_WITHDRAWAL_LOGS',
  SAVE_PAYMENT_LOGS: 'SAVE_PAYMENT_LOGS',
  RESET_PAYMENT_LOGS: 'RESET_PAYMENT_LOGS',

  GET_TRANSACTION_PLAYER_INFO: 'GET_TRANSACTION_PLAYER_INFO',
  SAVE_TRANSACTION_PLAYER_INFO: 'SAVE_TRANSACTION_PLAYER_INFO',
  RESET_TRANSACTION_PLAYER_INFO: 'RESET_TRANSACTION_PLAYER_INFO',

  CHANGE_OFFLINE_DEPOSIT_STATUS: 'CHANGE_OFFLINE_DEPOSIT_STATUS',
  CHANGE_WITHDRAWAL_STATUS_TO_IN_PROCESS: 'CHANGE_WITHDRAWAL_STATUS_TO_IN_PROCESS',
  CHANGE_WITHDRAWAL_STATUS_TO_APPROVED: 'CHANGE_WITHDRAWAL_STATUS_TO_APPROVED',
  CHANGE_WITHDRAWAL_STATUS_TO_FROZEN: 'CHANGE_WITHDRAWAL_STATUS_TO_FROZEN',
  CHANGE_WITHDRAWAL_STATUS_TO_CANCELLED: 'CHANGE_WITHDRAWAL_STATUS_TO_CANCELLED',
  CHANGE_WITHDRAWAL_STATUS_TO_DECLINED: 'CHANGE_WITHDRAWAL_STATUS_TO_DECLINED',

  CHANGE_OFFLINE_WITHDRAWAL_STATUS_TO_IN_PROCESS: 'CHANGE_OFFLINE_WITHDRAWAL_STATUS_TO_IN_PROCESS',
  CHANGE_OFFLINE_WITHDRAWAL_STATUS_TO_APPROVED: 'CHANGE_OFFLINE_WITHDRAWAL_STATUS_TO_APPROVED',
  CHANGE_OFFLINE_WITHDRAWAL_STATUS_TO_DECLINED: 'CHANGE_OFFLINE_WITHDRAWAL_STATUS_TO_DECLINED',
  CHANGE_BULK_WITHDRAWAL_STATUS: 'CHANGE_BULK_WITHDRAWAL_STATUS',

  GET_DEPOSIT_TAGS: 'GET_DEPOSIT_TAGS',
  SAVE_DEPOSIT_TAGS: 'SAVE_DEPOSIT_TAGS',
  RESET_DEPOSIT_TAGS: 'RESET_DEPOSIT_TAGS',

  ASSIGN_DEPOSIT_TAGS: 'ASSIGN_DEPOSIT_TAGS',
  EXPORT_DEPOSIT_TAGS: 'EXPORT_DEPOSIT_TAGS',

  GET_WITHDRAWAL_TAGS: 'GET_WITHDRAWAL_TAGS',
  SAVE_WITHDRAWAL_TAGS: 'SAVE_WITHDRAWAL_TAGS',
  RESET_WITHDRAWAL_TAGS: 'RESET_WITHDRAWAL_TAGS',

  ASSIGN_WITHDRAWAL_TAGS: 'ASSIGN_WITHDRAWAL_TAGS',
  EXPORT_WITHDRAWAL_TAGS: 'EXPORT_WITHDRAWAL_TAGS',

  GET_ASSIGNED_TAG_CATEGORIES: 'GET_ASSIGNED_TAG_CATEGORIES',
  SAVE_ASSIGNED_TAG_CATEGORIES: 'SAVE_ASSIGNED_TAG_CATEGORIES',
  RESET_ASSIGNED_TAG_CATEGORIES: 'RESET_ASSIGNED_TAG_CATEGORIES',

  GET_ASSIGNED_TAGS: 'GET_ASSIGNED_TAGS',
  SAVE_ASSIGNED_TAGS: 'SAVE_ASSIGNED_TAGS',
  RESET_ASSIGNED_TAGS: 'RESET_ASSIGNED_TAGS',
};

export default PaymentActionType;
