import { Popover } from 'antd';
import { useCallback, useEffect, useState } from 'react';
import { IconSvg } from 'components/common';
import { AdminSelect } from 'components/common/form';
import { LocalStorageService } from 'services';
import PropTypes from 'prop-types';
import { withTheme } from 'styled-components';
import _ from 'lodash';
import { isOwnUser } from 'utils/helpers';
import {
  StyledHeaderSelectClose, StyledHeaderSelectToggle, StyledHeaderSelectWrapper, StyledListItemViewMode,
} from 'components/layout/Layout.styled';

const Partners = (props) => {
  const {
    showPartner,
    partner,
    partners,
    partnerId,
    setCurrentPartner,
    theme: { colors },
    getAuthUserPartnerAccesses,
    getResourcePartner,
  } = props;

  const [open, setOpen] = useState(false);

  const getCurrentPartner = useCallback(() => {
    const storageId = LocalStorageService.get('current')?.partnerId;
    // include partners false case
    if (isOwnUser(storageId) && showPartner?.includeMainPartner) {
      return storageId;
    }

    const currentPartnerId = partners.length ? partners[0].id : null;
    return !_.isNull(storageId) && partners.find((el) => el.id === storageId) ? storageId : currentPartnerId;
  }, [partners, showPartner]);

  useEffect(() => {
    getAuthUserPartnerAccesses(!!showPartner.includeMainPartner);
  }, [getAuthUserPartnerAccesses, showPartner?.includeMainPartner]);

  useEffect(() => {
    if (partners.length) {
      const partId = getCurrentPartner();
      const currentData = LocalStorageService.get('current') || {};

      setCurrentPartner(partId);
      LocalStorageService.set('current', {
        ...currentData,
        partnerId: partId,
      });
    }
  }, [partners, setCurrentPartner, getCurrentPartner]);

  useEffect(() => {
    if (showPartner?.viewMode && partnerId) {
      getResourcePartner(partnerId);
    }
  }, [showPartner, partnerId, getResourcePartner]);

  if (showPartner?.viewMode) {
    const { name } = partner;
    return (
      <Popover placement="bottomLeft" content={name} title="" overlayClassName="description-popover">
        <StyledListItemViewMode isBold>{name}</StyledListItemViewMode>
      </Popover>
    );
  }

  const openDropDown = () => {
    setOpen(true);
  };

  const closeDropDown = () => {
    setOpen(false);
  };

  const partnerChange = (id) => {
    const currentData = LocalStorageService.get('current') || {};

    LocalStorageService.set('current', {
      ...currentData,
      partnerId: id,
    });

    setCurrentPartner(id);
    closeDropDown();
  };

  return (
    !!partners.length && (
      <div>
        <StyledHeaderSelectToggle onClick={openDropDown}>
          <IconSvg icon="BrandIcon" width="1.4rem" height="1.4rem" color={colors.white} />
        </StyledHeaderSelectToggle>
        <StyledHeaderSelectWrapper isOpen={open ? 1 : 0}>
          <AdminSelect onChange={partnerChange} value={partnerId} allowClear={false} data={partners} isToString={false} />
          <StyledHeaderSelectClose onClick={closeDropDown}>
            <IconSvg icon="CloseModalIcon" width="1.14rem" height="1.14rem" color={colors.white} />
          </StyledHeaderSelectClose>
        </StyledHeaderSelectWrapper>
      </div>
    )
  );
};

Partners.propTypes = {
  theme: PropTypes.object.isRequired,
  setCurrentPartner: PropTypes.func.isRequired,
  partnerId: PropTypes.number,
  showPartner: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
  partners: PropTypes.array,
  partner: PropTypes.object,
  getAuthUserPartnerAccesses: PropTypes.func.isRequired,
  getResourcePartner: PropTypes.func.isRequired,
};

Partners.defaultProps = {
  partnerId: null,
  showPartner: false,
  partners: [],
  partner: {},
};

export default withTheme(Partners);
