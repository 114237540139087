const selectCampaigns = (state) => state.campaigns;
export const selectCampaignsV2Data = (state) => selectCampaigns(state).data;
export const selectCampaignsV2Total = (state) => selectCampaigns(state).total;
export const selectCampaignV2 = (state) => state.campaign;
export const selectCreatedCampaignV2Id = (state) => state.createdCampaignId;
export const selectCampaignV2GeneralConfigEndedStatus = (state) => state.generalConfigEndedStatus;

export const selectCampaignV2BrandId = (state) => selectCampaignV2(state).brandId;
export const selectCampaignV2ParticipationGroups = (state) => state.participationGroups;
export const selectCampaignV2ParticipationGroup = (state) => state.participationGroup;
export const selectCampaignV2ParticipationGroupEndedStatus = (state) => state.participationGroupEndedStatus;

export const selectCampaignV2BonusEndedStatus = (state) => state.bonusEndedStatus;
export const selectCampaignV2FreeBetBonus = (state) => state.freeBetBonus;
export const selectCampaignV2FreeSpinBonus = (state) => state.freeSpinBonus;
export const selectCampaignV2Bonuses = (state) => state.bonuses;

export const selectCampaignV2TriggerConditions = (state) => state.triggerConditions;
export const selectCampaignV2TriggerCondition = (state) => state.triggerCondition;
export const selectCampaignV2TriggerConditionEndedStatus = (state) => state.triggerConditionEndedStatus;
