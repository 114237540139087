const SeoSettingActionType = {
  GET_SEO_PAGE_GROUPS: 'GET_SEO_PAGE_GROUPS',
  SAVE_SEO_PAGE_GROUPS: 'SAVE_SEO_PAGE_GROUPS',
  RESET_SEO_PAGE_GROUPS: 'RESET_SEO_PAGE_GROUPS',

  GET_SEO_PAGE_SETTINGS: 'GET_SEO_PAGE_SETTINGS',
  SAVE_SEO_PAGES_SETTINGS: 'SAVE_SEO_PAGES_SETTINGS',
  RESET_SEO_PAGES_SETTINGS: 'RESET_SEO_PAGES_SETTINGS',

  EDIT_SEO_PAGES_SETTINGS: 'EDIT_SEO_PAGES_SETTINGS',

  GET_SEO_SOCIAL_MEDIA_CONFIG: 'GET_SEO_SOCIAL_MEDIA_CONFIG',
  SAVE_SEO_SOCIAL_MEDIA_CONFIG: 'SAVE_SEO_SOCIAL_MEDIA_CONFIG',
  RESET_SEO_SOCIAL_MEDIA_CONFIG: 'RESET_SEO_SOCIAL_MEDIA_CONFIG',

  EDIT_SEO_SOCIAL_MEDIA_CONFIG: 'EDIT_SEO_SOCIAL_MEDIA_CONFIG',

  GET_SEO_SCHEMA_MARKUP: 'GET_SEO_SCHEMA_MARKUP',
  SAVE_SEO_SCHEMA_MARKUP: 'SAVE_SEO_SCHEMA_MARKUP',
  RESET_SEO_SCHEMA_MARKUP: 'RESET_SEO_SCHEMA_MARKUP',
  EDIT_SEO_SCHEMA_MARKUP: 'EDIT_SEO_SCHEMA_MARKUP',

  GET_SEO_ROBOTS: 'GET_SEO_ROBOTS',
  SAVE_SEO_ROBOTS: 'SAVE_SEO_ROBOTS',
  RESET_SEO_ROBOTS: 'RESET_SEO_ROBOTS',
  EDIT_SEO_ROBOTS: 'EDIT_SEO_ROBOTS',
};

export default SeoSettingActionType;
