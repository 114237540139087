import { useState } from 'react';
import { Tag } from 'antd';
import PropTypes from 'prop-types';

const { CheckableTag } = Tag;

const SelectTag = (props) => {
  const {
    data: { text },
  } = props;
  const [checked, setChecked] = useState(false);

  return (
    <CheckableTag checked={checked} onChange={() => setChecked(!checked)}>
      {text}
    </CheckableTag>
  );
};

SelectTag.propTypes = {
  data: PropTypes.object.isRequired,
};

export default SelectTag;
