const GameSegmentActionType = {
  GET_SEGMENT_GAMES: 'GET_SEGMENT_GAMES',
  SAVE_SEGMENT_GAMES: 'SAVE_SEGMENT_GAMES',
  RESET_SEGMENT_GAMES: 'RESET_SEGMENT_GAMES',

  GET_GAMES_SEGMENTS: 'GET_GAMES_SEGMENTS',
  SAVE_GAMES_SEGMENTS: 'SAVE_GAMES_SEGMENTS',
  RESET_GAMES_SEGMENTS: 'RESET_GAMES_SEGMENTS',

  CREATE_GAME_SEGMENT: 'CREATE_GAME_SEGMENT',
  DELETE_GAME_SEGMENT: 'DELETE_GAME_SEGMENT',
  EDIT_GAME_SEGMENT: 'EDIT_GAME_SEGMENT',
  UPDATE_GAME_SEGMENT: 'UPDATE_GAME_SEGMENT',

  DELETE_GAME_SEGMENT_GAME: 'DELETE_GAME_SEGMENT_GAME',

  GET_GAME_SEGMENT: 'GET_GAME_SEGMENT',
  SAVE_GAME_SEGMENT: 'SAVE_GAME_SEGMENT',
  RESET_GAME_SEGMENT: 'RESET_GAME_SEGMENT',
};

export default GameSegmentActionType;
