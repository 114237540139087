import UserActionType from 'actions/user/userActionType';
import { LocalStorageService } from 'services';

const userConfigs = LocalStorageService.get('userConfigs') || null;

const initialState = {
  users: [],
  user: {},
  loading: false,
  total: 0,
  permissions: [],
  permissionGroup: {},
  permissionGroupPermissions: [],
  partnerAccesses: [],
  brandAccesses: [],
  partnerBrands: [],
  positions: [],
  supervisors: [],
  userConfigs,
};
const userReducer = (state = initialState, { type, payload }) => {
  switch (type) {
  case UserActionType.GET_USERS_SUCCESS:
    return {
      ...state,
      users: payload.data,
      total: payload.total,
    };
  case UserActionType.RESET_USERS:
    return {
      ...state,
      users: [],
      total: 0,
    };
  case UserActionType.CHANGE_USER_LOADING_STATE:
    return {
      ...state,
      loading: payload.state,
    };
  case UserActionType.GET_USER_BRAND_ACCESSES_SUCCESS:
    return {
      ...state,
      brandAccesses: payload.data,
    };
  case UserActionType.GET_USER_PARTNER_ACCESSES_SUCCESS:
    return {
      ...state,
      partnerAccesses: payload.data,
    };
  case UserActionType.RESET_USER_PARTNER_ACCESSES:
    return {
      ...state,
      partnerAccesses: [],
    };
  case UserActionType.RESET_USER_BRAND_ACCESSES:
    return {
      ...state,
      brandAccesses: [],
    };
  case UserActionType.SAVE_USER_CONFIGS:
    return {
      ...state,
      userConfigs: payload.data,
    };
  case UserActionType.GET_USER_PERMISSIONS_SUCCESS:
    return {
      ...state,
      permissions: payload.data,
    };
  case UserActionType.RESET_USER_PERMISSIONS:
    return {
      ...state,
      permissions: [],
    };
  case UserActionType.GET_USER_PERMISSION_GROUP_SUCCESS:
    return {
      ...state,
      permissionGroup: payload.data.permissionGroup,
      permissionGroupPermissions: payload.data.permissions,
    };
  case UserActionType.RESET_USER_PERMISSION_GROUP:
    return {
      ...state,
      permissionGroup: {},
      permissionGroupPermissions: [],
    };
  case UserActionType.GET_USER_BRANDS_BY_PARTNER_SUCCESS:
    return {
      ...state,
      partnerBrands: payload.data,
    };
  case UserActionType.RESET_USER_BRANDS_BY_PARTNER:
    return {
      ...state,
      partnerBrands: [],
    };
  case UserActionType.GET_USER_POSITIONS_SUCCESS:
    return {
      ...state,
      positions: payload.data,
    };
  case UserActionType.RESET_USER_POSITIONS:
    return {
      ...state,
      positions: [],
    };
  case UserActionType.GET_SUPERVISOR_USERS_SUCCESS:
    return {
      ...state,
      supervisors: payload.data,
    };
  case UserActionType.RESET_SUPERVISOR_USERS:
    return {
      ...state,
      supervisors: [],
    };
  case UserActionType.SAVE_USER:
    return {
      ...state,
      user: payload.data,
    };
  case UserActionType.RESET_USER:
    return {
      ...state,
      user: {},
    };
  default:
    return state;
  }
};

export default userReducer;
