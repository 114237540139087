import ReasonsActionType from 'actions/reasons/reasonsActionType';

export const getReasons = (params) => ({
  type: ReasonsActionType.GET_REASONS,
  payload: { params },
});

export const saveReasons = (data) => ({
  type: ReasonsActionType.SAVE_REASONS,
  payload: { data },
});

export const resetReasons = () => ({
  type: ReasonsActionType.RESET_REASONS,
});

export const getReason = (reasonId, params) => ({
  type: ReasonsActionType.GET_REASON,
  payload: { reasonId, params },
});

export const saveReason = (data) => ({
  type: ReasonsActionType.SAVE_REASON,
  payload: { data },
});

export const resetReason = () => ({
  type: ReasonsActionType.RESET_REASON,
});

export const createReason = (data) => ({
  type: ReasonsActionType.CREATE_REASON,
  payload: { data },
});

export const editReason = (reasonId, data) => ({
  type: ReasonsActionType.EDIT_REASON,
  payload: { reasonId, data },
});

export const deleteReason = (reasonId, name) => ({
  type: ReasonsActionType.DELETE_REASON,
  payload: { reasonId, name },
});

export const enableReason = (reasonId, data, name) => ({
  type: ReasonsActionType.ENABLE_REASON,
  payload: { reasonId, data, name },
});

export const getReasonsResources = (params) => ({
  type: ReasonsActionType.GET_REASONS_RESOURCES,
  payload: { params },
});

export const saveReasonsResources = (data) => ({
  type: ReasonsActionType.SAVE_REASONS_RESOURCES,
  payload: { data },
});

export const resetReasonsResources = () => ({
  type: ReasonsActionType.RESET_REASONS_RESOURCES,
});

export const getReasonsByResource = (resourceId, params) => ({
  type: ReasonsActionType.GET_REASONS_BY_RESOURCE,
  payload: { resourceId, params },
});

export const saveReasonsByResource = (data) => ({
  type: ReasonsActionType.SAVE_REASONS_BY_RESOURCE,
  payload: { data },
});

export const resetReasonsByResource = () => ({
  type: ReasonsActionType.RESET_REASONS_BY_RESOURCE,
});

export const deleteResourceReason = (resourceId, reasonId, name, params) => ({
  type: ReasonsActionType.DELETE_RESOURCE_REASON,
  payload: {
    resourceId,
    reasonId,
    name,
    params,
  },
});

export const enableResourceReason = (resourceId, reasonId, name, data) => ({
  type: ReasonsActionType.ENABLE_RESOURCE_REASON,
  payload: {
    resourceId,
    reasonId,
    name,
    data,
  },
});
