const selectGames = (state) => state.games;
export const selectGamesData = (state) => selectGames(state).data;
export const selectGamesTotal = (state) => selectGames(state).total;
export const selectGame = (state) => state.game;
export const selectGameThemesResource = (state) => state.gameThemesResource;
export const selectGameBadgesResource = (state) => state.gameBadgesResource;
export const selectGameGroupsResource = (state) => state.gameGroupsResource;
export const selectGameGroups = (state) => state.gameGroups;
export const selectCsvGames = (state) => state.csvGames;
export const selectGameManagementEndedStatus = (state) => state.endedStatus;
export const selectGameGroup = (state) => state.gameGroup;
const selectGameTypes = (state) => state.gameTypes;
export const selectGameTypesData = (state) => selectGameTypes(state)?.data;
export const selectGameTypesTotal = (state) => selectGameTypes(state)?.total;
export const selectGameType = (state) => state.gameType;
export const selectGameTypeName = (state) => selectGameType(state)?.name;
export const selectCsvGameTypeGames = (state) => state.csvGameTypeGames;
