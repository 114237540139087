import LocaleActionType from 'actions/locale/localeActionType';
import { LocalStorageService } from 'services';
import { AppConstants } from 'constants/index';

const initialState = {
  resources: {},
  language: LocalStorageService.get('current')?.languageId || AppConstants.defaultLanguage,
};

const localeReducer = (state = initialState, { type, payload }) => {
  switch (type) {
  case LocaleActionType.SAVE_LOCALE_RESOURCES:
    return {
      ...state,
      resources: payload.data,
    };
  case LocaleActionType.CHANGE_LOCALE_LANGUAGE:
    return {
      ...state,
      language: payload.language,
    };
  default:
    return state;
  }
};

export default localeReducer;
