import PromotionActionType from 'actions/promotion/promotionActionType';

const initialState = {
  promotions: {
    data: [],
    total: 0,
  },
  promotion: {},
  createdPromotionId: null,
  generalConfigEndedStatus: false,
  spinDistributions: [],
  spinDistribution: {},
  spinDistributionEndedStatus: false,
  machines: [],
  machine: {},
  machineDetails: {},
  machineEndedStatus: false,
  playingParticipants: [],
  playingParticipant: {},
  playingParticipantEndedStatus: false,
  prizes: [],
  prize: {},
  prizeEndedStatus: false,
  machineConfig: {},
  machineConfigEndedStatus: false,
  groupLimit: {},
  groupLimitEndedStatus: false,
  publishEndedStatus: false,
  machineContent: {},
  machineContents: [],
  machineContentEndedStatus: false,
  promotionContent: {},
  promotionContents: {},
  promotionContentEndedStatus: false,
  spinDistributionContent: {},
  spinDistributionContents: [],
  spinDistributionContentEndedStatus: false,
  prizeContent: {},
  prizeContents: {},
  prizeContentEndedStatus: false,
  promotionTransactions: {
    data: [],
    total: 0,
  },
  promotionMachines: {
    data: [],
    total: 0,
  },
  promotionPrizes: {
    data: [],
    total: 0,
  },
  promotionPlayers: {
    data: [],
    total: 0,
  },
  promotionExchanges: {
    data: [],
    total: 0,
  },
  csvPlayers: [],
  playersEndedStatus: false,
  promotionManualSpinReport: {
    data: [],
    total: 0,
  },
  promoSpinAdjustmentPlayers: {
    data: [],
    total: 0,
  },
  playerPromotionSpins: {},
  playerPromotionTransactions: {},

  isPromotionPreviewAvaliable: false,

  privatePromotionParticipants: {
    data: [],
    total: 0,
  },
  prizeMaterialStatistics: {
    data: [],
    total: 0,
  },
  prizeMachineSpinStatistics: {
    data: [],
    total: 0,
  },
  prizeBalanceMultiplierStatistics: {
    data: [],
    total: 0,
  },
  spins: {
    data: [],
    total: 0,
  },
  spinStatistics: {
    data: [],
    total: 0,
  },
  exchangeItems: {},
  exchangeItem: {},
  exchangeCenterEndedStatus: false,
  connectedPrizes: {},
  machineConfigs: [],
  changeLogs: {},
  changeLog: {},
};

const promotionReducer = (state = initialState, { type, payload }) => {
  switch (type) {
  case PromotionActionType.SAVE_PROMOTIONS:
    return {
      ...state,
      promotions: payload.data,
    };
  case PromotionActionType.RESET_PROMOTIONS:
    return {
      ...state,
      promotions: {
        data: [],
        total: 0,
      },
    };
  case PromotionActionType.SAVE_PROMOTION: {
    return {
      ...state,
      promotion: payload.data,
    };
  }
  case PromotionActionType.RESET_PROMOTION:
    return {
      ...state,
      promotion: {},
    };
  case PromotionActionType.SAVE_CREATED_PROMOTION_ID: {
    return {
      ...state,
      createdPromotionId: payload.id,
    };
  }
  case PromotionActionType.CHANGE_GENERAL_CONFIG_ENDED_STATUS: {
    return {
      ...state,
      generalConfigEndedStatus: payload.status,
    };
  }
  case PromotionActionType.SAVE_SPIN_DISTRIBUTIONS:
    return {
      ...state,
      spinDistributions: payload.data,
    };
  case PromotionActionType.RESET_SPIN_DISTRIBUTIONS:
    return {
      ...state,
      spinDistributions: [],
    };
  case PromotionActionType.CHANGE_SPIN_DISTRIBUTION_ENDED_STATUS: {
    return {
      ...state,
      spinDistributionEndedStatus: payload.status,
    };
  }
  case PromotionActionType.SAVE_SPIN_DISTRIBUTION:
    return {
      ...state,
      spinDistribution: payload.data,
    };
  case PromotionActionType.RESET_SPIN_DISTRIBUTION:
    return {
      ...state,
      spinDistribution: {},
    };
  case PromotionActionType.SAVE_MACHINES:
    return {
      ...state,
      machines: payload.data,
    };
  case PromotionActionType.RESET_MACHINES:
    return {
      ...state,
      machines: [],
    };
  case PromotionActionType.CHANGE_MACHINE_ENDED_STATUS: {
    return {
      ...state,
      machineEndedStatus: payload.status,
    };
  }
  case PromotionActionType.SAVE_MACHINE:
    return {
      ...state,
      machine: payload.data,
    };
  case PromotionActionType.RESET_MACHINE:
    return {
      ...state,
      machine: {},
    };
  case PromotionActionType.SAVE_MACHINE_DETAILS:
    return {
      ...state,
      machineDetails: payload.data,
    };
  case PromotionActionType.RESET_MACHINE_DETAILS:
    return {
      ...state,
      machineDetails: {},
    };
  case PromotionActionType.SAVE_PLAYING_PARTICIPANTS:
    return {
      ...state,
      playingParticipants: payload.data,
    };
  case PromotionActionType.RESET_PLAYING_PARTICIPANTS:
    return {
      ...state,
      playingParticipants: [],
    };
  case PromotionActionType.CHANGE_PLAYING_PARTICIPANT_ENDED_STATUS: {
    return {
      ...state,
      playingParticipantEndedStatus: payload.status,
    };
  }
  case PromotionActionType.SAVE_PLAYING_PARTICIPANT:
    return {
      ...state,
      playingParticipant: payload.data,
    };
  case PromotionActionType.RESET_PLAYING_PARTICIPANT:
    return {
      ...state,
      playingParticipant: {},
    };
  case PromotionActionType.SAVE_PRIZES:
    return {
      ...state,
      prizes: payload.data,
    };
  case PromotionActionType.RESET_PRIZES:
    return {
      ...state,
      prizes: [],
    };
  case PromotionActionType.SAVE_PRIZE:
    return {
      ...state,
      prize: payload.data,
    };
  case PromotionActionType.RESET_PRIZE:
    return {
      ...state,
      prize: {},
    };
  case PromotionActionType.CHANGE_PRIZE_ENDED_STATUS: {
    return {
      ...state,
      prizeEndedStatus: payload.status,
    };
  }
  case PromotionActionType.SAVE_MACHINE_CONFIG:
    return {
      ...state,
      machineConfig: payload.data,
    };
  case PromotionActionType.RESET_MACHINE_CONFIG:
    return {
      ...state,
      machineConfig: {},
    };
  case PromotionActionType.CHANGE_MACHINE_CONFIG_ENDED_STATUS: {
    return {
      ...state,
      machineConfigEndedStatus: payload.status,
    };
  }
  case PromotionActionType.SAVE_GROUP_LIMIT:
    return {
      ...state,
      groupLimit: payload.data,
    };
  case PromotionActionType.RESET_GROUP_LIMIT:
    return {
      ...state,
      groupLimit: {},
    };
  case PromotionActionType.CHANGE_GROUP_LIMIT_ENDED_STATUS: {
    return {
      ...state,
      groupLimitEndedStatus: payload.status,
    };
  }
  case PromotionActionType.CHANGE_PUBLISH_ENDED_STATUS: {
    return {
      ...state,
      publishEndedStatus: payload.status,
    };
  }
  case PromotionActionType.SAVE_MACHINE_CONTENTS: {
    return {
      ...state,
      machineContents: payload.data,
    };
  }
  case PromotionActionType.RESET_MACHINE_CONTENTS: {
    return {
      ...state,
      machineContents: [],
    };
  }
  case PromotionActionType.SAVE_MACHINE_CONTENT: {
    return {
      ...state,
      machineContent: payload.data,
    };
  }
  case PromotionActionType.RESET_MACHINE_CONTENT: {
    return {
      ...state,
      machineContent: {},
    };
  }
  case PromotionActionType.CHANGE_MACHINE_CONTENT_ENDED_STATUS: {
    return {
      ...state,
      machineContentEndedStatus: payload.status,
    };
  }
  case PromotionActionType.SAVE_PROMOTION_CONTENTS: {
    return {
      ...state,
      promotionContents: payload.data,
    };
  }
  case PromotionActionType.RESET_PROMOTION_CONTENTS: {
    return {
      ...state,
      promotionContents: {},
    };
  }
  case PromotionActionType.SAVE_PROMOTION_CONTENT: {
    return {
      ...state,
      promotionContent: payload.data,
    };
  }
  case PromotionActionType.RESET_PROMOTION_CONTENT: {
    return {
      ...state,
      promotionContent: {},
    };
  }
  case PromotionActionType.CHANGE_PROMOTION_CONTENT_ENDED_STATUS: {
    return {
      ...state,
      promotionContentEndedStatus: payload.status,
    };
  }
  case PromotionActionType.SAVE_SPIN_DISTRIBUTION_CONTENTS: {
    return {
      ...state,
      spinDistributionContents: payload.data,
    };
  }
  case PromotionActionType.RESET_SPIN_DISTRIBUTION_CONTENTS: {
    return {
      ...state,
      spinDistributionContents: [],
    };
  }
  case PromotionActionType.SAVE_SPIN_DISTRIBUTION_CONTENT: {
    return {
      ...state,
      spinDistributionContent: payload.data,
    };
  }
  case PromotionActionType.RESET_SPIN_DISTRIBUTION_CONTENT: {
    return {
      ...state,
      spinDistributionContent: {},
    };
  }
  case PromotionActionType.CHANGE_SPIN_DISTRIBUTION_CONTENT_ENDED_STATUS: {
    return {
      ...state,
      spinDistributionContentEndedStatus: payload.status,
    };
  }
  case PromotionActionType.SAVE_PROMOTION_PRIZE_CONTENTS: {
    return {
      ...state,
      prizeContents: payload.data,
    };
  }
  case PromotionActionType.RESET_PROMOTION_PRIZE_CONTENTS: {
    return {
      ...state,
      prizeContents: {},
    };
  }
  case PromotionActionType.SAVE_PROMOTION_PRIZE_CONTENT: {
    return {
      ...state,
      prizeContent: payload.data,
    };
  }
  case PromotionActionType.RESET_PROMOTION_PRIZE_CONTENT: {
    return {
      ...state,
      prizeContent: {},
    };
  }
  case PromotionActionType.CHANGE_PROMOTION_PRIZE_CONTENT_ENDED_STATUS: {
    return {
      ...state,
      prizeContentEndedStatus: payload.status,
    };
  }
  case PromotionActionType.SAVE_PROMOTION_TRANSACTIONS:
    return {
      ...state,
      promotionTransactions: payload.data,
    };
  case PromotionActionType.RESET_PROMOTION_TRANSACTIONS:
    return {
      ...state,
      promotionTransactions: {
        data: [],
        total: 0,
      },
    };
  case PromotionActionType.SAVE_PROMOTION_MACHINES:
    return {
      ...state,
      promotionMachines: payload.data,
    };
  case PromotionActionType.RESET_PROMOTION_MACHINES:
    return {
      ...state,
      promotionMachines: {
        data: [],
        total: 0,
      },
    };
  case PromotionActionType.SAVE_PROMOTION_PRIZES:
    return {
      ...state,
      promotionPrizes: payload.data,
    };
  case PromotionActionType.RESET_PROMOTION_PRIZES:
    return {
      ...state,
      promotionPrizes: {
        data: [],
        total: 0,
      },
    };
  case PromotionActionType.SAVE_PROMOTION_PLAYERS:
    return {
      ...state,
      promotionPlayers: payload.data,
    };
  case PromotionActionType.RESET_PROMOTION_PLAYERS:
    return {
      ...state,
      promotionPlayers: {
        data: [],
        total: 0,
      },
    };
  case PromotionActionType.SAVE_PROMOTION_CSV_PLAYERS:
    return {
      ...state,
      csvPlayers: payload.data,
    };
  case PromotionActionType.RESET_PROMOTION_CSV_PLAYERS:
    return {
      ...state,
      csvPlayers: [],
    };
  case PromotionActionType.CHANGE_PLAYERS_ENDED_STATUS:
    return {
      ...state,
      playersEndedStatus: payload.status,
    };
  case PromotionActionType.SAVE_PROMOTION_MANUAL_SPIN_REPORT:
    return {
      ...state,
      promotionManualSpinReport: payload.data,
    };
  case PromotionActionType.RESET_PROMOTION_MANUAL_SPIN_REPORT:
    return {
      ...state,
      promotionManualSpinReport: {
        data: [],
        total: 0,
      },
    };
  case PromotionActionType.SAVE_PROMOTION_SPIN_ADJUSTMENT_PLAYERS:
    return {
      ...state,
      promoSpinAdjustmentPlayers: payload.data,
    };
  case PromotionActionType.RESET_PROMOTION_SPIN_ADJUSTMENT_PLAYERS:
    return {
      ...state,
      promoSpinAdjustmentPlayers: {
        data: [],
        total: 0,
      },
    };
  case PromotionActionType.SAVE_PLAYER_PROMOTION_SPINS:
    return {
      ...state,
      playerPromotionSpins: payload.data,
    };
  case PromotionActionType.RESET_PLAYER_PROMOTION_SPINS:
    return {
      ...state,
      playerPromotionSpins: {},
    };
  case PromotionActionType.SAVE_PLAYER_PROMOTION_TRANSACTIONS:
    return {
      ...state,
      playerPromotionTransactions: payload.data,
    };
  case PromotionActionType.RESET_PLAYER_PROMOTION_TRANSACTIONS:
    return {
      ...state,
      playerPromotionTransactions: {},
    };
  case PromotionActionType.SAVE_PROMOTION_PREVIEW_VALIDATION_STATE:
    return {
      ...state,
      isPromotionValidForPreview: payload.state,
    };
  case PromotionActionType.RESET_PROMOTION_PREVIEW_VALIDATION_STATE:
    return {
      ...state,
      isPromotionValidForPreview: false,
    };
  case PromotionActionType.SAVE_PRIVATE_PROMOTION_PARTICIPANTS: {
    return {
      ...state,
      privatePromotionParticipants: payload.data,
    };
  }
  case PromotionActionType.RESET_PRIVATE_PROMOTION_PARTICIPANTS: {
    return {
      ...state,
      privatePromotionParticipants: {
        data: [],
        total: 0,
      },
    };
  }
  case PromotionActionType.SAVE_PROMOTION_PRIZE_MATERIAL_STATISTICS:
    return {
      ...state,
      prizeMaterialStatistics: payload.data,
    };
  case PromotionActionType.RESET_PROMOTION_PRIZE_MATERIAL_STATISTICS:
    return {
      ...state,
      prizeMaterialStatistics: {
        data: [],
        total: 0,
      },
    };
  case PromotionActionType.SAVE_PROMOTION_PRIZE_MACHINE_SPIN_STATISTICS:
    return {
      ...state,
      prizeMachineSpinStatistics: payload.data,
    };
  case PromotionActionType.RESET_PROMOTION_PRIZE_MACHINE_SPIN_STATISTICS:
    return {
      ...state,
      prizeMachineSpinStatistics: {
        data: [],
        total: 0,
      },
    };
  case PromotionActionType.SAVE_PROMOTION_PRIZE_BALANCE_MULTIPLIER_STATISTICS:
    return {
      ...state,
      prizeBalanceMultiplierStatistics: payload.data,
    };
  case PromotionActionType.RESET_PROMOTION_PRIZE_BALANCE_MULTIPLIER_STATISTICS:
    return {
      ...state,
      prizeBalanceMultiplierStatistics: {
        data: [],
        total: 0,
      },
    };
  case PromotionActionType.SAVE_PROMOTION_SPINS: {
    return {
      ...state,
      spins: payload.data,
    };
  }
  case PromotionActionType.RESET_PROMOTION_SPINS: {
    return {
      ...state,
      spins: {
        data: [],
        total: 0,
      },
    };
  }
  case PromotionActionType.SAVE_PROMOTION_SPIN_STATISTICS: {
    return {
      ...state,
      spinStatistics: payload.data,
    };
  }
  case PromotionActionType.RESET_PROMOTION_SPIN_STATISTICS: {
    return {
      ...state,
      spinStatistics: {
        data: [],
        total: 0,
      },
    };
  }
  case PromotionActionType.SAVE_PROMOTION_EXCHANGE_CENTER: {
    return {
      ...state,
      exchangeItems: payload.data,
    };
  }
  case PromotionActionType.RESET_PROMOTION_EXCHANGE_CENTER: {
    return {
      ...state,
      exchangeItems: {},
    };
  }
  case PromotionActionType.SAVE_PROMOTION_EXCHANGE_ITEM: {
    return {
      ...state,
      exchangeItem: payload.data,
    };
  }
  case PromotionActionType.RESET_PROMOTION_EXCHANGE_ITEM: {
    return {
      ...state,
      exchangeItem: {},
    };
  }
  case PromotionActionType.CHANGE_PROMOTION_EXCHANGE_CENTER_ENDED_STATUS: {
    return {
      ...state,
      exchangeCenterEndedStatus: payload.status,
    };
  }
  case PromotionActionType.SAVE_PROMOTION_PRIZE_CONTENT_CONNECTED: {
    return {
      ...state,
      connectedPrizes: payload.data,
    };
  }
  case PromotionActionType.RESET_PROMOTION_PRIZE_CONTENT_CONNECTED: {
    return {
      ...state,
      connectedPrizes: {},
    };
  }
  case PromotionActionType.SAVE_MACHINE_CONFIGS:
    return {
      ...state,
      machineConfigs: payload.data,
    };
  case PromotionActionType.RESET_MACHINE_CONFIGS:
    return {
      ...state,
      machineConfigs: [],
    };
  case PromotionActionType.SAVE_PROMOTION_EXCHANGES:
    return {
      ...state,
      promotionExchanges: payload.data,
    };
  case PromotionActionType.RESET_PROMOTION_EXCHANGES:
    return {
      ...state,
      promotionExchanges: {
        data: [],
        total: 0,
      },
    };
  case PromotionActionType.SAVE_PROMOTION_CHANGE_LOGS:
    return {
      ...state,
      changeLogs: payload.data,
    };
  case PromotionActionType.RESET_PROMOTION_CHANGE_LOGS:
    return {
      ...state,
      changeLogs: {},
    };
  case PromotionActionType.SAVE_PROMOTION_CHANGE_LOG:
    return {
      ...state,
      changeLog: payload.data,
    };
  case PromotionActionType.RESET_PROMOTION_CHANGE_LOG:
    return {
      ...state,
      changeLog: {},
    };
  default:
    return state;
  }
};

export default promotionReducer;
