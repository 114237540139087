import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { StyledButtonBlue } from 'components/styledComponents/button/Button.styled';
import { NavigationService } from 'services';
import { useLayout } from 'hooks';
import {
  StyledErrorPageBody,
  StyledErrorPageContent,
  StyledErrorBlock,
  StyledErrorBlockImg1,
  StyledErrorBlockImg2,
  StyledErrorBlockImg3,
  ErrorPageCircleWrapper,
  ErrorPageText,
  GoBackButtonWrapper,
} from './NoMatch.styled';

function NoMatchContent() {
  const { setHideLayout } = useLayout();

  const { t } = useTranslation();

  useEffect(() => {
    setHideLayout(true);
    return () => setHideLayout(false);
  }, [setHideLayout]);

  return (
    <StyledErrorPageContent className="no-match-content">
      <ErrorPageCircleWrapper>
        <ErrorPageText>{t('pageNotFound')}</ErrorPageText>
        <StyledErrorPageBody>
          <div>
            <StyledErrorBlock />
            <StyledErrorBlockImg1 />
          </div>
          <div>
            <StyledErrorBlock />
            <StyledErrorBlockImg2 />
          </div>
          <div>
            <StyledErrorBlock />
            <StyledErrorBlockImg3 />
          </div>
        </StyledErrorPageBody>
        <GoBackButtonWrapper>
          <div onClick={() => NavigationService('/account')}>
            <StyledButtonBlue size="medium">{t('home')}</StyledButtonBlue>
          </div>
        </GoBackButtonWrapper>
      </ErrorPageCircleWrapper>
    </StyledErrorPageContent>
  );
}

export default NoMatchContent;
