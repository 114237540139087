import {
  put, call, takeLatest, all,
} from 'redux-saga/effects';
import NetworkService from 'services/networkService';
import {
  getPartnersSuccess, getPartnerSuccess, changePartnerAccessesLoading, getPartnerAccessesSuccess, changePartnerLoadingState,
} from 'actions/partner/partnerActionCreator';
import PartnerActionType from 'actions/partner/partnerActionType';
import { i18n, LocalStorageService, NavigationService } from 'services';
import {
  changeEndedStatus, changeLoadingState, setErrorMessage, setSuccessMessage,
} from 'actions/setting/settingActionCreator';
import { getError, getMessage } from 'utils/helpers';
import { AppConstants } from 'constants/index';
import axios from 'utils/axiosInterceptors';
import enumTypes from 'constants/enumTypes';

function* getPartners({ payload }) {
  try {
    yield put(changePartnerLoadingState(true));
    const { BrandAdmin, Partner } = AppConstants.api;
    const response = yield call(NetworkService.makeAPIGetRequest, [BrandAdmin, Partner], payload);
    yield put(getPartnersSuccess(response.data));
    yield put(changePartnerLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changePartnerLoadingState(false));
  }
}

function* addPartner({ payload }) {
  try {
    yield put(changeLoadingState(true));
    const { BrandAdmin, Partner } = AppConstants.api;
    yield call(NetworkService.makeAPIPostRequest, [BrandAdmin, Partner], payload);
    yield put(setSuccessMessage(getMessage(i18n.t('partner:lPartner'), i18n.t('notification:slCreated'), payload.data.name)));
    const auth = LocalStorageService.get('auth');
    auth.access_token = '';
    LocalStorageService.set('auth', auth);
    delete axios.defaults.headers.common.Authorization;
    yield put(changeLoadingState(false));
    NavigationService('/partners');
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* editPartner({ payload }) {
  try {
    yield put(changeLoadingState(true));
    const { BrandAdmin, Partner } = AppConstants.api;
    yield call(NetworkService.makeAPIPutRequest, [BrandAdmin, Partner, payload.id], payload);
    yield put(changeLoadingState(false));
    yield put(setSuccessMessage(getMessage(i18n.t('partner:lPartner'), i18n.t('notification:slUpdated'), payload.data.name)));
    NavigationService('/partners');
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getPartner({ payload }) {
  try {
    yield put(changePartnerLoadingState(true));
    const { BrandAdmin, Partner } = AppConstants.api;
    const { data } = yield call(NetworkService.makeAPIGetRequest, [BrandAdmin, Partner, payload.id]);
    yield put(getPartnerSuccess(data));
    yield put(changePartnerLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err, true);
      yield put(setErrorMessage(error));
    }
    yield put(changePartnerLoadingState(false));
  }
}

function* getPartnerAccesses({ payload }) {
  try {
    yield put(changePartnerAccessesLoading(true));
    const { Admin, Partner, PartnerAccesses } = AppConstants.api;
    const response = yield call(NetworkService.makeAPIGetRequest, [Admin, Partner, PartnerAccesses, payload.partnerId]);
    yield put(getPartnerAccessesSuccess(response.data));
    yield put(changePartnerAccessesLoading(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changePartnerAccessesLoading(false));
  }
}

function* changePartnerBrandsStatuses({ payload }) {
  try {
    const { data, hasMaintenance, name } = payload;
    const options = {
      data,
    };
    yield put(changeLoadingState(true));
    const { BrandAdmin, Partner, Status } = AppConstants.api;
    yield call(NetworkService.makeAPIPutRequest, [BrandAdmin, Partner, Status], options);
    yield put(changeLoadingState(false));
    yield put(changeEndedStatus(true));
    if (data.status === enumTypes.brandStatuses.Maintenance) {
      yield put(setSuccessMessage(data.partnerIds?.length > 1 ? i18n.t('notification:bulkManageMaintenance') : i18n.t('notification:manageMaintenance', { fieldName: name })));
    } else if (data.status === enumTypes.brandStatuses.Active && hasMaintenance) {
      yield put(setSuccessMessage(data.partnerIds?.length > 1 ? i18n.t('notification:bulkRemoveMaintenance') : i18n.t('notification:removeMaintenance', { fieldName: name })));
    } else {
      yield put(setSuccessMessage(getMessage(i18n.t('statuses'), i18n.t('notification:slUpdated'))));
    }
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

export default function* partnerSaga() {
  yield all([
    takeLatest(PartnerActionType.GET_PARTNERS, getPartners),
    takeLatest(PartnerActionType.ADD_PARTNER, addPartner),
    takeLatest(PartnerActionType.EDIT_PARTNER, editPartner),
    takeLatest(PartnerActionType.GET_PARTNER, getPartner),
    takeLatest(PartnerActionType.GET_PARTNER_ACCESSES, getPartnerAccesses),
    takeLatest(PartnerActionType.CHANGE_PARTNER_BRANDS_STATUSES, changePartnerBrandsStatuses),
  ]);
}
