import styled from 'styled-components';
import { Tag } from 'antd';

const { CheckableTag } = Tag;

const StyledTag = styled.span`
  .ant-tag {
    height: 28px;
    line-height: 26px;
    background: ${(props) => (props.checked ? '#fffbe6' : props.theme.colors.primaryBg)} 0 0 no-repeat padding-box;
    border: 1px solid ${(props) => (props.checked ? props.theme.colors.yellow : props.theme.colors.primaryBorder)};
    border-radius: ${(props) => props.theme.customBorderRadius.borderRadiusDefault};
    padding: 0 3px 0 7px;
    margin: 8px 8px 8px 0;
  }
`;

const StyledTagsNumber = styled.span`
  height: 14px;
  color: ${(props) => props.theme.colors.white};
  background: ${(props) => props.theme.colors.secondaryColor} 0 0 no-repeat padding-box;
  border-radius: ${(props) => props.theme.customBorderRadius.borderRadiusSmall};
  padding: 3px;
  margin: 4px 4px 4px 21px;
`;

const StyledCheckableTagsWrapper = styled.div`
  max-height: 10.5rem;
  overflow-y: auto;
  > div {
    font-weight: ${(props) => props.theme.typography.fontWeightMedium};
    color: ${(props) => props.theme.colors.primaryText};
    margin-bottom: 1.14rem;
  }
`;
const StyledCheckableTag = styled(CheckableTag)`
  display: inline-flex;
  align-items: center;
  gap: 4px;
  font-size: 1rem;
  line-height: 1rem;
  background: ${(props) => props.theme.colors.grayButton};
  color: ${(props) => props.theme.colors.primaryText};
  padding: 0.3rem 0.85rem;
  margin-bottom: 0.57rem;
  user-select: none;
  pointer-events: ${(props) => (props.noaction ? 'none' : 'auto')};
  &.ant-tag-checkable-checked {
    background: ${(props) => props.theme.colors.blue};
    color: ${(props) => props.theme.colors.white};

    svg path {
      fill: ${(props) => props.theme.colors.white};
    }
  }
`;

export default StyledTag;
export { StyledTagsNumber, StyledCheckableTag, StyledCheckableTagsWrapper };
