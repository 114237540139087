import styled from 'styled-components';
import { Spin } from 'antd';

const AdminEditorWrapper = styled.div`
  position: relative;
`;
const AdminEditorLoader = styled(Spin)`
  width: 100%;
  position: absolute;
  z-index: 0;
  top: 12rem;
`;
export { AdminEditorWrapper, AdminEditorLoader };
