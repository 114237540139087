import {
  all, call, put, select, takeLatest,
} from '@redux-saga/core/effects';
import qs from 'qs';
import mime from 'mimetypes';
import BrandSettingActionType from 'actions/brandSetting/brandSettingActionType';
import {
  saveBrandCurrencies,
  saveBrandCountries,
  saveBrandLanguages,
  changeSuccessStatus,
  saveDomains,
  saveBrandSmsSettings,
  saveBrandSmtpSettings,
  saveBrandGeneralSettings,
  saveBrandCaptchaSettings,
  saveBrandEmailContacts,
  saveBrandDepositConversions,
  saveBrandWithdrawalConversions,
  saveBrandOtherConversions,
  saveBrandConversionLogs,
  saveBrandConversion,
  saveBrandStandardSettings,
  saveBrandBlockedCountriesResource,
  saveBrandBlockedIPs,
  saveBrandSportsBookLayouts,
  saveBrandSportsBookSettings,
  saveBrandPreferencesSettings,
  saveBrandSportsBookWalletsSettings,
  saveBrandCasinoProductSettings,
  saveBrandCurrencyFormat,
  changeSportsBookTemplatesCallEndedStatus,
  saveBrandInactivitySettings,
  saveBrandSportStatusSettings,
  saveSportDomains,
  saveBrandCasinoProductWalletsSettings,
} from 'actions/brandSetting/brandSettingActionCreator';
import { AppConstants } from 'constants/index';
import NetworkService from 'services/networkService';
import { selectDomains, selectBrandSmsSettings } from 'selectors';
import {
  changeEndedStatus, changeLoadingState, setErrorMessage, setSuccessMessage,
} from 'actions/setting/settingActionCreator';
import { downloadFile } from 'utils/utils';
import { i18n } from 'services';
import { getError, getMessage } from 'utils/helpers';

function* addBrandCurrency({ payload }) {
  try {
    yield put(changeLoadingState(true));
    const { settings } = payload.data;
    const { BrandAdmin, BrandCurrency } = AppConstants.api;
    yield call(NetworkService.makeAPIPostRequest, [BrandAdmin, BrandCurrency], payload);
    yield put(changeEndedStatus(true));
    yield put(setSuccessMessage(getMessage(i18n.t(settings.length === 1 ? 'brand:theCurrency' : 'currencies'), i18n.t(settings.length === 1 ? 'notification:slUpdated' : 'notification:plUpdated'))));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* setDefaultCurrency({ payload }) {
  try {
    const { id } = payload.data;
    yield put(changeLoadingState(true));
    const { BrandAdmin, BrandCurrency, SetMain } = AppConstants.api;
    yield call(NetworkService.makeAPIPutRequest, [BrandAdmin, BrandCurrency, SetMain, id]);
    yield put(changeEndedStatus(true));
    yield put(setSuccessMessage(getMessage(i18n.t('brand:mainCurrency'), i18n.t('notification:slUpdated'))));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* enableBrandCurrency({ payload }) {
  try {
    const { id, isActive } = payload.data;
    const data = { isActive: !isActive };
    yield put(changeLoadingState(true));
    const { BrandAdmin, BrandCurrency, Status } = AppConstants.api;
    yield call(NetworkService.makeAPIPutRequest, [BrandAdmin, BrandCurrency, Status, id], { data });
    yield put(changeEndedStatus(true));
    yield put(setSuccessMessage(getMessage(i18n.t('brand:theCurrency'), i18n.t(isActive ? 'notification:slDisabled' : 'notification:slEnabled'))));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* changeOrderCurrency({ payload }) {
  try {
    const { currencySettingId, order } = payload.data;
    const { BrandAdmin, BrandCurrency, ChangeOrder } = AppConstants.api;
    yield call(NetworkService.makeAPIPutRequest, [BrandAdmin, BrandCurrency, ChangeOrder, currencySettingId, order]);
    yield put(changeEndedStatus(true));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
  }
}

function* addBrandCountry({ payload }) {
  try {
    yield put(changeLoadingState(true));
    const { settings } = payload.data;
    const { BrandAdmin, BrandCountry } = AppConstants.api;
    yield call(NetworkService.makeAPIPostRequest, [BrandAdmin, BrandCountry], payload);
    yield put(setSuccessMessage(getMessage(i18n.t(settings.length === 1 ? 'brand:theCountry' : 'countries'), i18n.t(settings.length === 1 ? 'notification:slAdded' : 'notification:plAdded'))));
    yield put(changeLoadingState(false));
    yield put(changeEndedStatus(true));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* setDefaultCountry({ payload }) {
  try {
    const { id } = payload.data;
    yield put(changeLoadingState(true));
    const { BrandAdmin, BrandCountry, SetMain } = AppConstants.api;
    yield call(NetworkService.makeAPIPutRequest, [BrandAdmin, BrandCountry, SetMain, id]);
    yield put(changeSuccessStatus(true));
    yield put(changeEndedStatus(true));
    yield put(setSuccessMessage(getMessage(i18n.t('brand:mainCountry'), i18n.t('notification:slUpdated'))));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* enableBrandCountry({ payload }) {
  try {
    const { id, isActive } = payload.data;
    const data = { isActive: !isActive };
    yield put(changeLoadingState(true));
    const { BrandAdmin, BrandCountry, Status } = AppConstants.api;
    yield call(NetworkService.makeAPIPutRequest, [BrandAdmin, BrandCountry, Status, id], { data });
    yield put(changeEndedStatus(true));
    yield put(setSuccessMessage(getMessage(i18n.t('brand:theCountry'), i18n.t(isActive ? 'notification:slDisabled' : 'notification:slEnabled'))));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* addBrandLanguage({ payload }) {
  try {
    const { settings } = payload.data;
    yield put(changeLoadingState(true));
    const { BrandAdmin, BrandLanguage } = AppConstants.api;
    yield call(NetworkService.makeAPIPostRequest, [BrandAdmin, BrandLanguage], payload);
    yield put(setSuccessMessage(getMessage(i18n.t(settings.length === 1 ? 'brand:theLanguage' : 'languages'), i18n.t(settings.length === 1 ? 'notification:slAdded' : 'notification:plAdded'))));
    yield put(changeLoadingState(false));
    yield put(changeEndedStatus(true));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* setDefaultLanguage({ payload }) {
  try {
    const { id } = payload.data;
    yield put(changeLoadingState(true));
    const { BrandAdmin, BrandLanguage, SetMain } = AppConstants.api;
    yield call(NetworkService.makeAPIPutRequest, [BrandAdmin, BrandLanguage, SetMain, id]);
    yield put(changeEndedStatus(true));
    yield put(setSuccessMessage(getMessage(i18n.t('brand:mainLanguage'), i18n.t('notification:slUpdated'))));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* enableBrandLanguage({ payload }) {
  try {
    const { id, isActive } = payload.data;
    const data = { isActive: !isActive };
    yield put(changeLoadingState(true));
    const { BrandAdmin, BrandLanguage, Status } = AppConstants.api;
    yield call(NetworkService.makeAPIPutRequest, [BrandAdmin, BrandLanguage, Status, id], { data });
    yield put(changeEndedStatus(true));
    yield put(setSuccessMessage(getMessage(i18n.t('brand:theLanguage'), i18n.t(isActive ? 'notification:slDisabled' : 'notification:slEnabled'))));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* changeOrderLanguage({ payload }) {
  try {
    const { languageId, order } = payload.data;
    const { BrandAdmin, BrandLanguage, ChangeOrder } = AppConstants.api;
    yield call(NetworkService.makeAPIPutRequest, [BrandAdmin, BrandLanguage, ChangeOrder, languageId, order]);
    yield put(changeEndedStatus(true));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
  }
}

function* getDomains({ payload }) {
  try {
    yield put(changeLoadingState(true));
    const { BrandAdmin, BrandDomain } = AppConstants.api;
    const response = yield call(NetworkService.makeAPIGetRequest, [BrandAdmin, BrandDomain], { params: payload });
    yield put(saveDomains(response.data));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* addDomain({ payload }) {
  try {
    yield put(changeLoadingState(true));
    const { domainName } = payload.data;
    const { BrandAdmin, BrandDomain } = AppConstants.api;
    yield call(NetworkService.makeAPIPostRequest, [BrandAdmin, BrandDomain], payload);
    yield put(changeSuccessStatus(true));
    yield put(setSuccessMessage(getMessage(i18n.t('lDomain'), i18n.t('notification:slAdded'), domainName)));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* editDomain({ payload }) {
  try {
    const { id, data } = payload;
    yield put(changeLoadingState(true));
    const { BrandAdmin, BrandDomain } = AppConstants.api;
    yield call(NetworkService.makeAPIPutRequest, [BrandAdmin, BrandDomain, id], { data });
    yield put(changeEndedStatus(true));
    yield put(setSuccessMessage(getMessage(i18n.t('brand:domainName'), i18n.t('notification:slUpdated'))));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeEndedStatus(true));
    yield put(changeLoadingState(false));
  }
}

function* deleteDomain({ payload }) {
  try {
    yield put(changeLoadingState(true));
    const { BrandAdmin, BrandDomain } = AppConstants.api;
    yield call(NetworkService.makeAPIDeleteRequest, [BrandAdmin, BrandDomain, payload.id]);
    const state = yield select();
    const domains = selectDomains(state.brandSetting);
    const newData = domains.filter((el) => el.id !== payload.id);
    yield put(saveDomains(newData));
    yield put(changeLoadingState(false));
    yield put(setSuccessMessage(getMessage(i18n.t('brand:theDomain'), i18n.t('notification:slDeleted'))));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* connectDomain({ payload }) {
  try {
    const { id, name } = payload;
    yield put(changeLoadingState(true));
    const { BrandAdmin, BrandDomain, Connect } = AppConstants.api;
    yield call(NetworkService.makeAPIPutRequest, [BrandAdmin, BrandDomain, Connect, id]);
    const state = yield select();
    const domains = selectDomains(state.brandSetting);
    const newData = domains.map((el) => (id === el.id
      ? {
        ...el,
        isPaused: false,
      }
      : el));
    yield put(saveDomains(newData));
    yield put(changeLoadingState(false));
    yield put(setSuccessMessage(getMessage(i18n.t('lDomain'), i18n.t('notification:slConnected'), name)));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* disconnectDomain({ payload }) {
  try {
    const { id, name } = payload;
    yield put(changeLoadingState(true));
    const { BrandAdmin, BrandDomain, DisConnect } = AppConstants.api;
    yield call(NetworkService.makeAPIPutRequest, [BrandAdmin, BrandDomain, DisConnect, id]);
    const state = yield select();
    const domains = selectDomains(state.brandSetting);
    const newData = domains.map((el) => (id === el.id
      ? {
        ...el,
        isPaused: true,
      }
      : el));
    yield put(saveDomains(newData));
    yield put(changeLoadingState(false));
    yield put(setSuccessMessage(getMessage(i18n.t('lDomain'), i18n.t('notification:slDisconnected'), name)));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getSportAllDomains({ payload }) {
  try {
    yield put(changeLoadingState(true));
    const { BrandAdmin, BrandSportDomain } = AppConstants.api;
    const response = yield call(NetworkService.makeAPIGetRequest, [BrandAdmin, BrandSportDomain], { params: payload });
    yield put(saveSportDomains(response.data));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* setSportMainDomain({ payload }) {
  try {
    yield put(changeLoadingState(true));
    const { BrandAdmin, BrandSportDomain, SetMain } = AppConstants.api;
    yield call(NetworkService.makeAPIPutRequest, [BrandAdmin, BrandSportDomain, SetMain, payload.id]);
    yield put(changeEndedStatus(true));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}
function* addSportDomain({ payload }) {
  try {
    yield put(changeLoadingState(true));
    const { domainName } = payload.data;
    const options = {
      data: payload.data,
    };
    const { BrandAdmin, BrandSportDomain } = AppConstants.api;
    yield call(NetworkService.makeAPIPostRequest, [BrandAdmin, BrandSportDomain], options);
    yield put(changeEndedStatus(true));
    yield put(setSuccessMessage(getMessage(i18n.t('lDomain'), i18n.t('notification:slAdded'), domainName)));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* editSportDomain({ payload }) {
  try {
    const { id, data } = payload;
    yield put(changeLoadingState(true));
    const { BrandAdmin, BrandSportDomain } = AppConstants.api;
    yield call(NetworkService.makeAPIPutRequest, [BrandAdmin, BrandSportDomain, id], { data });
    yield put(changeEndedStatus(true));
    yield put(setSuccessMessage(getMessage(i18n.t('brand:domainName'), i18n.t('notification:slUpdated'))));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeEndedStatus(true));
    yield put(changeLoadingState(false));
  }
}
function* connectSportDomain({ payload }) {
  try {
    const { id, name } = payload;
    yield put(changeLoadingState(true));
    const { BrandAdmin, BrandSportDomain, Connect } = AppConstants.api;
    yield call(NetworkService.makeAPIPutRequest, [BrandAdmin, BrandSportDomain, Connect, id]);
    yield put(changeEndedStatus(true));
    yield put(changeLoadingState(false));
    yield put(setSuccessMessage(getMessage(i18n.t('lDomain'), i18n.t('notification:slConnected'), name)));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}
function* disconnectSportDomain({ payload }) {
  try {
    const { id, name } = payload;
    yield put(changeLoadingState(true));
    const { BrandAdmin, BrandSportDomain, DisConnect } = AppConstants.api;
    yield call(NetworkService.makeAPIPutRequest, [BrandAdmin, BrandSportDomain, DisConnect, id]);
    yield put(changeEndedStatus(true));
    yield put(changeLoadingState(false));
    yield put(setSuccessMessage(getMessage(i18n.t('lDomain'), i18n.t('notification:slDisconnected'), name)));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* deleteSportDomain({ payload }) {
  try {
    yield put(changeLoadingState(true));
    const { BrandAdmin, BrandSportDomain } = AppConstants.api;
    yield call(NetworkService.makeAPIDeleteRequest, [BrandAdmin, BrandSportDomain, payload.id]);
    yield put(changeEndedStatus(true));
    yield put(changeLoadingState(false));
    yield put(setSuccessMessage(getMessage(i18n.t('brand:theDomain'), i18n.t('notification:slDeleted'))));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getBrandSmsSettings({ payload }) {
  try {
    yield put(changeLoadingState(true));
    const { NotificationAdmin, SMSSettings } = AppConstants.api;
    const { data } = yield call(NetworkService.makeAPIGetRequest, [NotificationAdmin, SMSSettings, payload.id]);
    yield put(saveBrandSmsSettings(data || {}));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* editBrandSmsSettings({ payload }) {
  try {
    const options = {
      data: payload.data,
    };
    yield put(changeLoadingState(true));
    const { NotificationAdmin, SMSSettings } = AppConstants.api;
    yield call(NetworkService.makeAPIPutRequest, [NotificationAdmin, SMSSettings, payload.brandId], options);
    const state = yield select();
    const smsSettings = selectBrandSmsSettings(state.brandSetting);
    yield put(saveBrandSmsSettings(payload.data));
    yield put(changeLoadingState(false));
    if (Object.keys(smsSettings).length) {
      yield put(setSuccessMessage(getMessage(i18n.t('brand:smsSettings'), i18n.t('notification:plUpdated'))));
    } else {
      yield put(setSuccessMessage(getMessage(i18n.t('brand:smsSettings'), i18n.t('notification:plConfigured'))));
    }
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getBrandGeneralSettings({ payload }) {
  try {
    yield put(changeLoadingState(true));
    const { BrandAdmin, BrandSetting } = AppConstants.api;
    const { data } = yield call(NetworkService.makeAPIGetRequest, [BrandAdmin, BrandSetting], payload);
    yield put(saveBrandGeneralSettings(data));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* addBrandGeneralSettings({ payload }) {
  try {
    yield put(changeLoadingState(true));
    const { BrandAdmin, BrandSetting } = AppConstants.api;
    yield call(NetworkService.makeAPIPostRequest, [BrandAdmin, BrandSetting], payload);
    yield put(changeEndedStatus(true));
    yield put(setSuccessMessage(getMessage(i18n.t('brand:verificationSettings'), i18n.t('notification:plUpdated'))));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}
function* deleteBrandSmsSettings({ payload }) {
  try {
    yield put(changeLoadingState(true));
    const { NotificationAdmin, SMSSettings } = AppConstants.api;
    yield call(NetworkService.makeAPIDeleteRequest, [NotificationAdmin, SMSSettings, payload.brandId]);
    yield put(changeEndedStatus(true));
    yield put(changeLoadingState(false));
    yield put(setSuccessMessage(getMessage(i18n.t('brand:smsSettings'), i18n.t('notification:plReset'))));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getBrandSmtpSettings({ payload }) {
  try {
    yield put(changeLoadingState(true));
    const { NotificationAdmin, SmtpSettings } = AppConstants.api;
    const { data } = yield call(NetworkService.makeAPIGetRequest, [NotificationAdmin, SmtpSettings, payload.brandId]);
    yield put(saveBrandSmtpSettings(data));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* editBrandSmtpSettings({ payload }) {
  try {
    const { providerType, ...others } = payload.data;
    const options = {
      data: others,
    };
    yield put(changeLoadingState(true));
    const { NotificationAdmin, SmtpSettings } = AppConstants.api;
    yield call(NetworkService.makeAPIPostRequest, [NotificationAdmin, SmtpSettings, payload.brandId], options);
    yield put(saveBrandSmtpSettings(payload.data));
    yield put(changeLoadingState(false));
    yield put(setSuccessMessage(getMessage(i18n.t('brand:smtpSettings'), i18n.t('notification:plUpdated'))));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* editBrandCustomSmtpSettings({ payload }) {
  try {
    const options = {
      data: payload.data,
    };
    yield put(changeLoadingState(true));
    const { NotificationAdmin, SmtpSettings, Custom } = AppConstants.api;
    yield call(NetworkService.makeAPIPostRequest, [NotificationAdmin, SmtpSettings, Custom, payload.brandId], options);
    yield put(saveBrandSmtpSettings(payload.data));
    yield put(changeLoadingState(false));
    yield put(setSuccessMessage(getMessage(i18n.t('brand:smtpSettings'), i18n.t('notification:plUpdated'))));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getBrandCaptchaSettings({ payload }) {
  try {
    yield put(changeLoadingState(true));
    const { BrandAdmin, Captcha } = AppConstants.api;
    const { data } = yield call(NetworkService.makeAPIGetRequest, [BrandAdmin, Captcha, payload.brandId]);
    yield put(saveBrandCaptchaSettings(data));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* editBrandCaptchaSettings({ payload }) {
  try {
    const options = {
      data: payload.data,
    };
    yield put(changeLoadingState(true));
    const { BrandAdmin, Captcha } = AppConstants.api;
    yield call(NetworkService.makeAPIPostRequest, [BrandAdmin, Captcha, payload.brandId], options);
    yield put(saveBrandCaptchaSettings(payload.data));
    yield put(changeLoadingState(false));
    yield put(setSuccessMessage(getMessage(i18n.t('brand:captchaSettings'), i18n.t('notification:plUpdated'))));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getBrandEmailContacts({ payload }) {
  try {
    const options = {
      params: payload.params,
    };
    yield put(changeLoadingState(true));
    const { NotificationAdmin, EmailContact } = AppConstants.api;
    const { data } = yield call(NetworkService.makeAPIGetRequest, [NotificationAdmin, EmailContact], options);
    yield put(saveBrandEmailContacts(data));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* addBrandEmailContact({ payload }) {
  try {
    const options = {
      data: payload.data,
    };
    yield put(changeLoadingState(true));
    const { NotificationAdmin, EmailContact } = AppConstants.api;
    yield call(NetworkService.makeAPIPostRequest, [NotificationAdmin, EmailContact], options);
    yield put(changeEndedStatus(true));
    yield put(setSuccessMessage(getMessage(i18n.t('brand:theEmail'), i18n.t('notification:slAdded'))));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* setBrandEmailContactAsDefault({ payload }) {
  try {
    const options = {
      params: payload.params,
    };
    yield put(changeLoadingState(true));
    const { NotificationAdmin, EmailContact } = AppConstants.api;
    yield call(NetworkService.makeAPIPutRequest, [NotificationAdmin, EmailContact, payload.id], options);
    yield put(changeEndedStatus(true));
    yield put(setSuccessMessage(i18n.t('notification:mailSetAsDefault', { name: payload.address })));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* deleteBrandEmailContact({ payload }) {
  try {
    const options = {
      params: payload.params,
    };
    yield put(changeLoadingState(true));
    const { NotificationAdmin, EmailContact } = AppConstants.api;
    yield call(NetworkService.makeAPIDeleteRequest, [NotificationAdmin, EmailContact, payload.id], options);
    yield put(changeEndedStatus(true));
    yield put(setSuccessMessage(getMessage(i18n.t('brand:theEmail'), i18n.t('notification:slDeleted'))));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* addBrandDepositConversion({ payload }) {
  try {
    const options = {
      data: payload.data,
    };
    yield put(changeLoadingState(true));
    const { ReportingAdmin, Conversion, Deposit } = AppConstants.api;
    yield call(NetworkService.makeAPIPostRequest, [ReportingAdmin, Conversion, Deposit], options);
    yield put(changeEndedStatus(true));
    yield put(changeLoadingState(false));
    yield put(
      setSuccessMessage(
        getMessage(
          i18n.t(payload.data.reverseConversionRate ? 'brand:conversions' : 'brand:theConversion'),
          i18n.t(payload.data.reverseConversionRate ? 'notification:plAdded' : 'notification:slAdded'),
        ),
      ),
    );
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}
function* addBrandWithdrawalConversion({ payload }) {
  try {
    const options = {
      data: payload.data,
    };
    yield put(changeLoadingState(true));
    const { ReportingAdmin, Conversion, Withdrawal } = AppConstants.api;
    yield call(NetworkService.makeAPIPostRequest, [ReportingAdmin, Conversion, Withdrawal], options);
    yield put(changeEndedStatus(true));
    yield put(changeLoadingState(false));
    yield put(
      setSuccessMessage(
        getMessage(
          i18n.t(payload.data.reverseConversionRate ? 'brand:conversions' : 'brand:theConversion'),
          i18n.t(payload.data.reverseConversionRate ? 'notification:plAdded' : 'notification:slAdded'),
        ),
      ),
    );
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* addBrandOtherConversion({ payload }) {
  try {
    const options = {
      data: payload.data,
    };
    yield put(changeLoadingState(true));
    const { ReportingAdmin, Conversion, Others } = AppConstants.api;
    yield call(NetworkService.makeAPIPostRequest, [ReportingAdmin, Conversion, Others], options);
    yield put(changeEndedStatus(true));
    yield put(changeLoadingState(false));
    yield put(
      setSuccessMessage(
        getMessage(
          i18n.t(payload.data.reverseConversionRate ? 'brand:conversions' : 'brand:theConversion'),
          i18n.t(payload.data.reverseConversionRate ? 'notification:plAdded' : 'notification:slAdded'),
        ),
      ),
    );
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* editBrandDepositConversion({ payload }) {
  try {
    const options = {
      data: payload.data,
    };
    yield put(changeLoadingState(true));
    const { ReportingAdmin, Conversion, Deposit } = AppConstants.api;
    yield call(NetworkService.makeAPIPutRequest, [ReportingAdmin, Conversion, Deposit], options);
    yield put(changeEndedStatus(true));
    yield put(changeLoadingState(false));
    yield put(setSuccessMessage(getMessage(i18n.t('conversionRate'), i18n.t('notification:slUpdated'))));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}
function* editBrandWithdrawalConversion({ payload }) {
  try {
    const options = {
      data: payload.data,
    };
    yield put(changeLoadingState(true));
    const { ReportingAdmin, Conversion, Withdrawal } = AppConstants.api;
    yield call(NetworkService.makeAPIPutRequest, [ReportingAdmin, Conversion, Withdrawal], options);
    yield put(changeEndedStatus(true));
    yield put(changeLoadingState(false));
    yield put(setSuccessMessage(getMessage(i18n.t('conversionRate'), i18n.t('notification:slUpdated'))));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* editBrandOtherConversion({ payload }) {
  try {
    const options = {
      data: payload.data,
    };
    yield put(changeLoadingState(true));
    const { ReportingAdmin, Conversion, Others } = AppConstants.api;
    yield call(NetworkService.makeAPIPutRequest, [ReportingAdmin, Conversion, Others], options);
    yield put(changeEndedStatus(true));
    yield put(changeLoadingState(false));
    yield put(setSuccessMessage(getMessage(i18n.t('conversionRate'), i18n.t('notification:slUpdated'))));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getBrandDepositConversions({ payload }) {
  try {
    const options = {
      params: payload.params,
      paramsSerializer: (params) => qs.stringify(params),
    };
    yield put(changeLoadingState(true));
    const {
      ReportingAdmin, Conversion, Deposit, All,
    } = AppConstants.api;
    const { data } = yield call(NetworkService.makeAPIGetRequest, [ReportingAdmin, Conversion, Deposit, All, payload.brandId], options);
    yield put(saveBrandDepositConversions(data));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}
function* getBrandWithdrawalConversions({ payload }) {
  try {
    const options = {
      params: payload.params,
      paramsSerializer: (params) => qs.stringify(params),
    };
    yield put(changeLoadingState(true));
    const {
      ReportingAdmin, Conversion, Withdrawal, All,
    } = AppConstants.api;
    const { data } = yield call(NetworkService.makeAPIGetRequest, [ReportingAdmin, Conversion, Withdrawal, All, payload.brandId], options);
    yield put(saveBrandWithdrawalConversions(data));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getBrandOtherConversions({ payload }) {
  try {
    const options = {
      params: payload.params,
      paramsSerializer: (params) => qs.stringify(params),
    };
    yield put(changeLoadingState(true));
    const {
      ReportingAdmin, Conversion, Others, All,
    } = AppConstants.api;
    const { data } = yield call(NetworkService.makeAPIGetRequest, [ReportingAdmin, Conversion, Others, All, payload.brandId], options);
    yield put(saveBrandOtherConversions(data));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getBrandDepositConversion({ payload }) {
  try {
    const options = {
      params: payload.params,
    };
    yield put(changeLoadingState(true));
    const { ReportingAdmin, Conversion, Deposit } = AppConstants.api;
    const { data } = yield call(NetworkService.makeAPIGetRequest, [ReportingAdmin, Conversion, Deposit, payload.brandId], options);
    yield put(saveBrandConversion(data));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}
function* getBrandWithdrawalConversion({ payload }) {
  try {
    const options = {
      params: payload.params,
    };
    yield put(changeLoadingState(true));
    const { ReportingAdmin, Conversion, Withdrawal } = AppConstants.api;
    const { data } = yield call(NetworkService.makeAPIGetRequest, [ReportingAdmin, Conversion, Withdrawal, payload.brandId], options);
    yield put(saveBrandConversion(data));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getBrandOtherConversion({ payload }) {
  try {
    const options = {
      params: payload.params,
    };
    yield put(changeLoadingState(true));
    const { ReportingAdmin, Conversion, Others } = AppConstants.api;
    const { data } = yield call(NetworkService.makeAPIGetRequest, [ReportingAdmin, Conversion, Others, payload.brandId], options);
    yield put(saveBrandConversion(data));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* deleteBrandDepositConversion({ payload }) {
  try {
    const options = {
      params: payload.params,
    };
    yield put(changeLoadingState(true));
    const { ReportingAdmin, Conversion, Deposit } = AppConstants.api;
    yield call(NetworkService.makeAPIDeleteRequest, [ReportingAdmin, Conversion, Deposit, payload.brandId], options);
    yield put(changeEndedStatus(true));
    yield put(changeLoadingState(false));
    yield put(setSuccessMessage(getMessage(i18n.t('brand:theConversion'), i18n.t('notification:slDeleted'))));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}
function* deleteBrandWithdrawalConversion({ payload }) {
  try {
    const options = {
      params: payload.params,
    };
    yield put(changeLoadingState(true));
    const { ReportingAdmin, Conversion, Withdrawal } = AppConstants.api;
    yield call(NetworkService.makeAPIDeleteRequest, [ReportingAdmin, Conversion, Withdrawal, payload.brandId], options);
    yield put(changeEndedStatus(true));
    yield put(changeLoadingState(false));
    yield put(setSuccessMessage(getMessage(i18n.t('brand:theConversion'), i18n.t('notification:slDeleted'))));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* deleteBrandOtherConversion({ payload }) {
  try {
    const options = {
      params: payload.params,
    };
    yield put(changeLoadingState(true));
    const { ReportingAdmin, Conversion, Others } = AppConstants.api;
    yield call(NetworkService.makeAPIDeleteRequest, [ReportingAdmin, Conversion, Others, payload.brandId], options);
    yield put(setSuccessMessage(getMessage(i18n.t('brand:theConversion'), i18n.t('notification:slDeleted'))));
    yield put(changeEndedStatus(true));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getBrandDepositConversionLogs({ payload }) {
  try {
    const options = {
      params: payload.params,
    };
    yield put(changeLoadingState(true));
    const {
      ReportingAdmin, Conversion, Deposit, Log,
    } = AppConstants.api;
    const { data } = yield call(NetworkService.makeAPIGetRequest, [ReportingAdmin, Conversion, Deposit, Log, payload.brandId], options);
    yield put(saveBrandConversionLogs(data));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getBrandWithdrawalConversionLogs({ payload }) {
  try {
    const options = {
      params: payload.params,
    };
    yield put(changeLoadingState(true));
    const {
      ReportingAdmin, Conversion, Withdrawal, Log,
    } = AppConstants.api;
    const { data } = yield call(NetworkService.makeAPIGetRequest, [ReportingAdmin, Conversion, Withdrawal, Log, payload.brandId], options);
    yield put(saveBrandConversionLogs(data));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getBrandOtherConversionLogs({ payload }) {
  try {
    const options = {
      params: payload.params,
    };
    yield put(changeLoadingState(true));
    const {
      ReportingAdmin, Conversion, Others, Log,
    } = AppConstants.api;
    const { data } = yield call(NetworkService.makeAPIGetRequest, [ReportingAdmin, Conversion, Others, Log, payload.brandId], options);
    yield put(saveBrandConversionLogs(data));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* exportBrandDepositConversion({ payload }) {
  try {
    const options = {
      params: payload.params,
      paramsSerializer: (params) => qs.stringify(params, { allowDots: true }),
      responseType: 'blob',
    };
    yield put(changeLoadingState(true));
    const {
      ReportingAdmin, Conversion, Deposit, Export,
    } = AppConstants.api;
    const { data, headers } = yield call(NetworkService.makeAPIGetRequest, [ReportingAdmin, Conversion, Deposit, Export, payload.brandId], options);
    downloadFile(data, i18n.t('conversionRates'), mime.detectExtension(headers['content-type']));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* exportBrandWithdrawalConversion({ payload }) {
  try {
    const options = {
      params: payload.params,
      paramsSerializer: (params) => qs.stringify(params, { allowDots: true }),
      responseType: 'blob',
    };
    yield put(changeLoadingState(true));
    const {
      ReportingAdmin, Conversion, Withdrawal, Export,
    } = AppConstants.api;
    const { data, headers } = yield call(NetworkService.makeAPIGetRequest, [ReportingAdmin, Conversion, Withdrawal, Export, payload.brandId], options);
    downloadFile(data, i18n.t('conversionRates'), mime.detectExtension(headers['content-type']));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* exportBrandOtherConversion({ payload }) {
  try {
    const options = {
      params: payload.params,
      paramsSerializer: (params) => qs.stringify(params, { allowDots: true }),
      responseType: 'blob',
    };
    yield put(changeLoadingState(true));
    const {
      ReportingAdmin, Conversion, Others, Export,
    } = AppConstants.api;
    const { data, headers } = yield call(NetworkService.makeAPIGetRequest, [ReportingAdmin, Conversion, Others, Export, payload.brandId], options);
    downloadFile(data, i18n.t('conversionRates'), mime.detectExtension(headers['content-type']));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* importBrandDepositConversion({ payload }) {
  try {
    const options = {
      data: payload.data,
      headers: { 'content-type': 'multipart/form-data' },
    };
    yield put(changeLoadingState(true));
    const {
      ReportingAdmin, Conversion, Deposit, Import,
    } = AppConstants.api;
    yield call(NetworkService.makeAPIPutRequest, [ReportingAdmin, Conversion, Deposit, Import, payload.brandId], options);
    yield put(setSuccessMessage(getMessage(i18n.t('conversionOrConversions'), i18n.t('notification:plAdded'))));
    yield put(changeEndedStatus(true));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* importBrandWithdrawalConversion({ payload }) {
  try {
    const options = {
      data: payload.data,
      headers: { 'content-type': 'multipart/form-data' },
    };
    yield put(changeLoadingState(true));
    const {
      ReportingAdmin, Conversion, Withdrawal, Import,
    } = AppConstants.api;
    yield call(NetworkService.makeAPIPutRequest, [ReportingAdmin, Conversion, Withdrawal, Import, payload.brandId], options);
    yield put(setSuccessMessage(getMessage(i18n.t('conversionOrConversions'), i18n.t('notification:plAdded'))));
    yield put(changeEndedStatus(true));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* importBrandOtherConversion({ payload }) {
  try {
    const options = {
      data: payload.data,
      headers: { 'content-type': 'multipart/form-data' },
    };
    yield put(changeLoadingState(false));
    const {
      ReportingAdmin, Conversion, Others, Import,
    } = AppConstants.api;
    yield call(NetworkService.makeAPIPutRequest, [ReportingAdmin, Conversion, Others, Import, payload.brandId], options);
    yield put(setSuccessMessage(getMessage(i18n.t('conversionOrConversions'), i18n.t('notification:plAdded'))));
    yield put(changeEndedStatus(true));
    yield put(changeLoadingState(true));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getBrandStandardSettings({ payload }) {
  const { isLoading, brandId } = payload;
  try {
    if (isLoading) {
      yield put(changeLoadingState(true));
    }
    const { BrandAdmin, Standards } = AppConstants.api;
    const { data } = yield call(NetworkService.makeAPIGetRequest, [BrandAdmin, Standards, brandId]);
    yield put(saveBrandStandardSettings(data));

    if (isLoading) {
      yield put(changeLoadingState(false));
    }
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }

    if (isLoading) {
      yield put(changeLoadingState(false));
    }
  }
}

function* editBrandStandardSettings({ payload }) {
  try {
    const {
      brandId, data, name, enabled,
    } = payload;
    const options = {
      data,
    };
    yield put(changeLoadingState(true));
    const { BrandAdmin, Standards } = AppConstants.api;
    yield call(NetworkService.makeAPIPutRequest, [BrandAdmin, Standards, brandId], options);
    yield put(changeEndedStatus(true));
    yield put(changeLoadingState(false));
    yield put(setSuccessMessage(getMessage('', i18n.t(enabled ? 'notification:slActivated' : 'notification:slDeactivated'), name)));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getBrandBlockedCountriesResource({ payload }) {
  const { isLoading } = payload;
  try {
    const { brandId } = payload;
    if (isLoading) {
      yield put(changeLoadingState(true));
    }
    const {
      BrandAdmin, Restriction, CountryCodes, Resource,
    } = AppConstants.api;
    const { data } = yield call(NetworkService.makeAPIGetRequest, [BrandAdmin, Restriction, CountryCodes, brandId, Resource]);
    yield put(saveBrandBlockedCountriesResource(data));
    if (isLoading) {
      yield put(changeLoadingState(false));
    }
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    if (isLoading) {
      yield put(changeLoadingState(false));
    }
  }
}

function* addBrandBlockedCountries({ payload }) {
  try {
    const { brandId, data } = payload;
    const options = {
      data,
    };
    yield put(changeLoadingState(true));
    const { BrandAdmin, Restriction, CountryCodes } = AppConstants.api;
    yield call(NetworkService.makeAPIPostRequest, [BrandAdmin, Restriction, CountryCodes, brandId], options);
    yield put(changeEndedStatus(true));
    yield put(changeLoadingState(false));
    yield put(setSuccessMessage(getMessage(i18n.t(data.length > 1 ? 'countries' : 'brand:theCountry'), i18n.t(data.length > 1 ? 'notification:plRestricted' : 'notification:slRestricted'))));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* deleteBrandBlockedCountry({ payload }) {
  try {
    const { data, brandId, name } = payload;
    const options = {
      data,
    };
    yield put(changeLoadingState(true));
    const { BrandAdmin, Restriction, CountryCodes } = AppConstants.api;
    yield call(NetworkService.makeAPIDeleteRequest, [BrandAdmin, Restriction, CountryCodes, brandId], options);
    yield put(changeEndedStatus(true));
    yield put(changeLoadingState(false));
    yield put(setSuccessMessage(i18n.t('notification:countryDeletedFromRestrictionList', { name })));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getBrandBlockedIPs({ payload }) {
  try {
    const { brandId } = payload;
    yield put(changeLoadingState(true));
    const { BrandAdmin, Restriction, IpRanges } = AppConstants.api;
    const { data } = yield call(NetworkService.makeAPIGetRequest, [BrandAdmin, Restriction, IpRanges, brandId]);
    yield put(saveBrandBlockedIPs(data));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* addBrandBlockedIPs({ payload }) {
  try {
    const { brandId, data } = payload;
    const options = {
      data,
    };
    yield put(changeLoadingState(true));
    const { BrandAdmin, Restriction, IpRanges } = AppConstants.api;
    yield call(NetworkService.makeAPIPostRequest, [BrandAdmin, Restriction, IpRanges, brandId], options);
    yield put(changeEndedStatus(true));
    yield put(changeLoadingState(false));
    yield put(setSuccessMessage(getMessage(i18n.t(payload.data[0].indexOf('/') !== -1 ? 'userManagement:ipAddressRange' : 'ipAddress'), i18n.t('notification:slRestricted'))));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* deleteBrandBlockedIP({ payload }) {
  try {
    const { brandId, data } = payload;
    const options = {
      data,
    };
    yield put(changeLoadingState(true));
    const { BrandAdmin, Restriction, IpRanges } = AppConstants.api;
    yield call(NetworkService.makeAPIDeleteRequest, [BrandAdmin, Restriction, IpRanges, brandId], options);
    yield put(changeEndedStatus(true));
    yield put(changeLoadingState(false));
    yield put(setSuccessMessage(i18n.t('notification:ipAddressDeletedFromRestrictionList', { name: data })));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* addDomainRedirectUrl({ payload }) {
  try {
    yield put(changeLoadingState(true));
    const { brandId, data } = payload;
    const { BrandAdmin, BrandDomain, ForwardUrl } = AppConstants.api;
    yield call(NetworkService.makeAPIPostRequest, [BrandAdmin, BrandDomain, ForwardUrl, brandId], { data });
    yield put(changeSuccessStatus(true));
    yield put(setSuccessMessage(getMessage(i18n.t('brand:domainRedirectionRule'), i18n.t('notification:slAdded'))));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* deleteDomainRedirectUrl({ payload }) {
  try {
    const { brandId, params } = payload;
    yield put(changeLoadingState(true));
    const { BrandAdmin, BrandDomain, ForwardUrl } = AppConstants.api;
    yield call(NetworkService.makeAPIDeleteRequest, [BrandAdmin, BrandDomain, ForwardUrl, brandId], { params });
    yield put(changeSuccessStatus(true));
    yield put(changeLoadingState(false));
    yield put(setSuccessMessage(getMessage(i18n.t('brand:domainRedirectionRule'), i18n.t('notification:slDeleted'))));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getBrandCountries({ payload }) {
  const { isLoading } = payload;
  try {
    if (isLoading) {
      yield put(changeLoadingState(true));
    }
    const { BrandAdmin, BrandCountry, Countries } = AppConstants.api;
    const { data } = yield call(NetworkService.makeAPIGetRequest, [BrandAdmin, BrandCountry, Countries, payload.brandId]);
    yield put(saveBrandCountries(data));
    if (isLoading) {
      yield put(changeLoadingState(false));
    }
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    if (isLoading) {
      yield put(changeLoadingState(false));
    }
  }
}

function* getBrandCurrencies({ payload }) {
  const { isLoading } = payload;
  try {
    if (isLoading) {
      yield put(changeLoadingState(true));
    }
    const { BrandAdmin, BrandCurrency, Currencies } = AppConstants.api;
    const { data } = yield call(NetworkService.makeAPIGetRequest, [BrandAdmin, BrandCurrency, Currencies, payload.brandId]);
    yield put(saveBrandCurrencies(data));
    if (isLoading) {
      yield put(changeLoadingState(false));
    }
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }

    if (isLoading) {
      yield put(changeLoadingState(false));
    }
  }
}

function* getBrandLanguages({ payload }) {
  const { isLoading } = payload;
  try {
    if (isLoading) {
      yield put(changeLoadingState(true));
    }
    const { BrandAdmin, BrandLanguage, Languages } = AppConstants.api;
    const { data } = yield call(NetworkService.makeAPIGetRequest, [BrandAdmin, BrandLanguage, Languages, payload.brandId]);
    yield put(saveBrandLanguages(data));
    if (isLoading) {
      yield put(changeLoadingState(false));
    }
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    if (isLoading) {
      yield put(changeLoadingState(false));
    }
  }
}

function* getBrandSportsBookLayouts({ payload }) {
  try {
    yield put(changeLoadingState(true));
    const { Transaction, Provider, SportSettings } = AppConstants.api;
    const { data } = yield call(NetworkService.makeAPIGetRequest, [Transaction, Provider, SportSettings, payload.brandId]);
    yield put(saveBrandSportsBookLayouts(data));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* activateBrandSportsBookProvider({ payload }) {
  try {
    yield put(changeLoadingState(true));
    const {
      BrandAdmin, Provider, SportsBook, Activate,
    } = AppConstants.api;
    yield call(NetworkService.makeAPIPutRequest, [BrandAdmin, Provider, SportsBook, Activate, payload.brandId]);
    yield put(changeLoadingState(false));
    yield put(setSuccessMessage(getMessage(i18n.t('brand:sportsBook'), i18n.t('notification:slActivated'))));
    yield put(changeEndedStatus(true));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* setBrandSportsBookDefaultTemplate({ payload }) {
  try {
    const { brandId, templateId } = payload;
    yield put(changeLoadingState(true));
    const { Transaction, Provider, Templates } = AppConstants.api;
    yield call(NetworkService.makeAPIPutRequest, [Transaction, Provider, Templates, templateId, brandId]);
    yield put(changeLoadingState(false));
    yield put(setSuccessMessage(getMessage(i18n.t('brand:theSportsBookView'), i18n.t('notification:slUpdated'))));
    yield put(changeSportsBookTemplatesCallEndedStatus(true));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* activateBrandRgs({ payload }) {
  try {
    yield put(changeLoadingState(true));
    const { Transaction, Rgs, Activate } = AppConstants.api;
    yield call(NetworkService.makeAPIPutRequest, [Transaction, Rgs, Activate, payload.brandId]);
    yield put(changeLoadingState(false));
    yield put(setSuccessMessage(getMessage(i18n.t('casino'), i18n.t('notification:slActivated'))));
    yield put(changeEndedStatus(true));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* enableBrandSportsBookPrematchTopMatches({ payload }) {
  try {
    const { brandId, params, name } = payload;
    const options = {
      params,
    };
    yield put(changeLoadingState(true));
    const {
      Transaction, Provider, SportConfiguration, PrematchTopMatches,
    } = AppConstants.api;
    yield call(NetworkService.makeAPIPutRequest, [Transaction, Provider, SportConfiguration, PrematchTopMatches, brandId], options);
    yield put(changeEndedStatus(true));
    yield put(setSuccessMessage(getMessage(name, i18n.t(!params.isActive ? 'notification:slDisabled' : 'notification:slEnabled'))));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}
function* enableBrandSportsBookJackpot({ payload }) {
  try {
    const { brandId, params, name } = payload;
    const options = {
      params,
    };
    yield put(changeLoadingState(true));
    const {
      Transaction, Provider, SportConfiguration, Jackpot,
    } = AppConstants.api;
    yield call(NetworkService.makeAPIPutRequest, [Transaction, Provider, SportConfiguration, Jackpot, brandId], options);
    yield put(changeEndedStatus(true));
    yield put(setSuccessMessage(getMessage(name, i18n.t(!params.isActive ? 'notification:slDisabled' : 'notification:slEnabled'))));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* enableBrandSportsBookPrematchTotalTopMatches({ payload }) {
  try {
    const { brandId, params, name } = payload;
    const options = {
      params,
    };
    yield put(changeLoadingState(true));
    const {
      Transaction, Provider, SportConfiguration, PrematchTotalTopMatches,
    } = AppConstants.api;
    yield call(NetworkService.makeAPIPutRequest, [Transaction, Provider, SportConfiguration, PrematchTotalTopMatches, brandId], options);
    yield put(changeEndedStatus(true));
    yield put(setSuccessMessage(getMessage(name, i18n.t(!params.isActive ? 'notification:slDisabled' : 'notification:slEnabled'))));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* enableBrandSportsBookLivePrematchTopMatches({ payload }) {
  try {
    const { brandId, params, name } = payload;
    const options = {
      params,
    };
    yield put(changeLoadingState(true));
    const {
      Transaction, Provider, SportConfiguration, LivePrematchTopMatches,
    } = AppConstants.api;
    yield call(NetworkService.makeAPIPutRequest, [Transaction, Provider, SportConfiguration, LivePrematchTopMatches, brandId], options);
    yield put(changeEndedStatus(true));
    yield put(setSuccessMessage(getMessage(name, i18n.t(!params.isActive ? 'notification:slDisabled' : 'notification:slEnabled'))));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* enableBrandSportsBookLiveTopMatches({ payload }) {
  try {
    const { brandId, params, name } = payload;
    const options = {
      params,
    };
    yield put(changeLoadingState(true));
    const {
      Transaction, Provider, SportConfiguration, LiveTopMatches,
    } = AppConstants.api;
    yield call(NetworkService.makeAPIPutRequest, [Transaction, Provider, SportConfiguration, LiveTopMatches, brandId], options);
    yield put(changeEndedStatus(true));
    yield put(setSuccessMessage(getMessage(name, i18n.t(!params.isActive ? 'notification:slDisabled' : 'notification:slEnabled'))));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}
function* enableBrandSportsBookSectionScrolling({ payload }) {
  try {
    const { brandId, params, name } = payload;
    const options = {
      params,
    };
    yield put(changeLoadingState(true));
    const {
      Transaction, Provider, SportConfiguration, SectionScroling,
    } = AppConstants.api;
    yield call(NetworkService.makeAPIPutRequest, [Transaction, Provider, SportConfiguration, SectionScroling, brandId], options);
    yield put(changeEndedStatus(true));
    yield put(setSuccessMessage(getMessage(name, i18n.t(!params.isActive ? 'notification:slDisabled' : 'notification:slEnabled'))));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}
function* enableBrandSportsBookTournamentBalance({ payload }) {
  try {
    const { brandId, params, name } = payload;
    const options = {
      params,
    };
    yield put(changeLoadingState(true));
    const {
      Transaction, Provider, SportConfiguration, TournamentBalance,
    } = AppConstants.api;
    yield call(NetworkService.makeAPIPutRequest, [Transaction, Provider, SportConfiguration, TournamentBalance, brandId], options);
    yield put(changeEndedStatus(true));
    yield put(setSuccessMessage(getMessage(name, i18n.t(!params.isActive ? 'notification:slDisabled' : 'notification:slEnabled'))));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}
function* changeBrandSportsBookMobileIntegrationType({ payload }) {
  try {
    const { brandId, params, name } = payload;
    const options = {
      params,
    };
    yield put(changeLoadingState(true));
    const {
      Transaction, Provider, SportConfiguration, IntegrationType,
    } = AppConstants.api;
    yield call(NetworkService.makeAPIPutRequest, [Transaction, Provider, SportConfiguration, IntegrationType, brandId], options);
    yield put(changeEndedStatus(true));
    yield put(setSuccessMessage(getMessage(i18n.t('notification:setAsDefault', { name }))));

    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}
function* enableBrandSportsBookColorInheritance({ payload }) {
  try {
    const { brandId, params, name } = payload;
    const options = {
      params,
    };
    yield put(changeLoadingState(true));
    const {
      Transaction, Provider, SportConfiguration, SiteStyle,
    } = AppConstants.api;
    yield call(NetworkService.makeAPIPutRequest, [Transaction, Provider, SportConfiguration, SiteStyle, brandId], options);
    yield put(changeEndedStatus(true));
    yield put(setSuccessMessage(getMessage(name, i18n.t(!params.isActive ? 'notification:slDisabled' : 'notification:slEnabled'))));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getBrandSportsBookSettings({ payload }) {
  try {
    const { brandId } = payload;
    yield put(changeLoadingState(true));
    const { Transaction, Provider, SportConfiguration } = AppConstants.api;
    const { data } = yield call(NetworkService.makeAPIGetRequest, [Transaction, Provider, SportConfiguration, brandId]);
    yield put(saveBrandSportsBookSettings(data));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getBrandPreferencesSettings({ payload }) {
  const { brandId } = payload;
  try {
    yield put(changeLoadingState(true));
    const { NotificationAdmin, notificationPreferences } = AppConstants.api;
    const { data } = yield call(NetworkService.makeAPIGetRequest, [NotificationAdmin, notificationPreferences, brandId]);
    yield put(saveBrandPreferencesSettings(data));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* editBrandPreferencesDepositSettings({ payload }) {
  try {
    const {
      brandId, data, name, enabled,
    } = payload;
    const options = {
      data,
    };
    yield put(changeLoadingState(true));
    const { NotificationAdmin, notificationPreferences, depositPreferences } = AppConstants.api;
    yield call(NetworkService.makeAPIPostRequest, [NotificationAdmin, notificationPreferences, brandId, depositPreferences], options);
    yield put(changeEndedStatus(true));
    yield put(changeLoadingState(false));
    yield put(setSuccessMessage(getMessage('', i18n.t(enabled ? 'notification:slActivated' : 'notification:slDeactivated'), i18n.t(name))));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* editBrandPreferencesWithdrawalSettings({ payload }) {
  try {
    const {
      brandId, data, name, enabled,
    } = payload;
    const options = {
      data,
    };
    yield put(changeLoadingState(true));
    const { NotificationAdmin, notificationPreferences, withdrawalsPreferences } = AppConstants.api;
    yield call(NetworkService.makeAPIPostRequest, [NotificationAdmin, notificationPreferences, brandId, withdrawalsPreferences], options);
    yield put(changeEndedStatus(true));
    yield put(changeLoadingState(false));
    yield put(setSuccessMessage(getMessage('', i18n.t(enabled ? 'notification:slActivated' : 'notification:slDeactivated'), i18n.t(name))));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* editBrandPreferencesPromotionSettings({ payload }) {
  try {
    const {
      brandId, data, name, enabled,
    } = payload;
    const options = {
      data,
    };
    yield put(changeLoadingState(true));
    const { NotificationAdmin, notificationPreferences, promotionPreferences } = AppConstants.api;
    yield call(NetworkService.makeAPIPostRequest, [NotificationAdmin, notificationPreferences, brandId, promotionPreferences], options);
    yield put(changeEndedStatus(true));
    yield put(changeLoadingState(false));
    yield put(setSuccessMessage(getMessage('', i18n.t(enabled ? 'notification:slActivated' : 'notification:slDeactivated'), i18n.t(name))));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getBrandSportsBookWalletsSettings({ payload }) {
  try {
    const { params } = payload;
    const options = {
      params,
    };
    yield put(changeLoadingState(true));
    const { BrandAdmin, GeneralSetting, SportWalletConfiguration } = AppConstants.api;
    const { data } = yield call(NetworkService.makeAPIGetRequest, [BrandAdmin, GeneralSetting, SportWalletConfiguration], options);
    yield put(saveBrandSportsBookWalletsSettings(data));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* editBrandSportsBookWalletsSettings({ payload }) {
  try {
    const { data, brandId } = payload;
    const options = {
      data,
    };
    yield put(changeLoadingState(true));
    const { BrandAdmin, GeneralSetting, SportWalletConfiguration } = AppConstants.api;
    yield call(NetworkService.makeAPIPutRequest, [BrandAdmin, GeneralSetting, SportWalletConfiguration, brandId], options);
    yield put(changeEndedStatus(true));
    yield put(setSuccessMessage(getMessage(i18n.t('brand:walletsSettings'), i18n.t('notification:slUpdated'))));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getBrandCasinoProductSettings({ payload }) {
  try {
    const { brandId } = payload;
    yield put(changeLoadingState(true));
    const { BrandAdmin, GeneralSetting, CasinoGameSetting } = AppConstants.api;
    const { data } = yield call(NetworkService.makeAPIGetRequest, [BrandAdmin, GeneralSetting, brandId, CasinoGameSetting]);
    yield put(saveBrandCasinoProductSettings(data));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* editBrandCasinoProductSettings({ payload }) {
  try {
    const {
      data, brandId, name, param,
    } = payload;
    const options = {
      data,
    };
    yield put(changeLoadingState(true));
    const { BrandAdmin, GeneralSetting, CasinoGameSetting } = AppConstants.api;
    yield call(NetworkService.makeAPIPutRequest, [BrandAdmin, GeneralSetting, brandId, CasinoGameSetting], options);
    yield put(changeEndedStatus(true));
    if (param) {
      yield put(setSuccessMessage(getMessage('', i18n.t(param.enabled ? 'notification:slActivated' : 'notification:slDeactivated'), name)));
    } else {
      yield put(setSuccessMessage(i18n.t('notification:setAsDefault', { name })));
    }
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getBrandCasinoProductWalletsSettings({ payload }) {
  try {
    const { params } = payload;
    const options = {
      params,
    };
    yield put(changeLoadingState(true));
    const { BrandAdmin, GeneralSetting, CasinoWalletConfiguration } = AppConstants.api;
    const { data } = yield call(NetworkService.makeAPIGetRequest, [BrandAdmin, GeneralSetting, CasinoWalletConfiguration], options);
    yield put(saveBrandCasinoProductWalletsSettings(data));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* editBrandCasinoProductWalletsSettings({ payload }) {
  try {
    const { data, brandId } = payload;
    const options = {
      data,
    };
    yield put(changeLoadingState(true));
    const { BrandAdmin, GeneralSetting, CasinoWalletConfiguration } = AppConstants.api;
    yield call(NetworkService.makeAPIPutRequest, [BrandAdmin, GeneralSetting, CasinoWalletConfiguration, brandId], options);
    yield put(changeEndedStatus(true));
    yield put(setSuccessMessage(getMessage(i18n.t('brand:walletsSettings'), i18n.t('notification:slUpdated'))));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* editBrandPreferencesBonusesSettings({ payload }) {
  try {
    const {
      brandId, data, name, enabled,
    } = payload;
    const options = {
      data,
    };
    yield put(changeLoadingState(true));
    const { NotificationAdmin, notificationPreferences, BonusesPreferences } = AppConstants.api;
    yield call(NetworkService.makeAPIPostRequest, [NotificationAdmin, notificationPreferences, brandId, BonusesPreferences], options);
    yield put(changeEndedStatus(true));
    yield put(changeLoadingState(false));
    yield put(setSuccessMessage(getMessage('', i18n.t(enabled ? 'notification:slActivated' : 'notification:slDeactivated'), i18n.t(name))));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getBrandCurrencyFormat({ payload }) {
  try {
    const { brandId } = payload;
    yield put(changeLoadingState(true));
    const { BrandAdmin, BrandCurrency, Format } = AppConstants.api;
    const { data } = yield call(NetworkService.makeAPIGetRequest, [BrandAdmin, BrandCurrency, brandId, Format]);
    yield put(saveBrandCurrencyFormat(data));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* editBrandCurrencyFormat({ payload }) {
  try {
    const { data, brandId } = payload;
    const options = {
      data,
    };
    yield put(changeLoadingState(true));
    const { BrandAdmin, BrandCurrency, Format } = AppConstants.api;
    yield call(NetworkService.makeAPIPostRequest, [BrandAdmin, BrandCurrency, brandId, Format], options);
    yield put(changeEndedStatus(true));
    yield put(saveBrandCurrencyFormat(data));
    yield put(setSuccessMessage(getMessage(i18n.t('brand:currencyFormat'), i18n.t('notification:slUpdated'))));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getBrandInactivitySettings({ payload }) {
  try {
    const { brandId } = payload;
    yield put(changeLoadingState(true));
    const { BrandAdmin, BrandMarketSetting, Inactivity } = AppConstants.api;
    const { data } = yield call(NetworkService.makeAPIGetRequest, [BrandAdmin, BrandMarketSetting, Inactivity, brandId]);
    yield put(saveBrandInactivitySettings(data));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* editBrandInactivitySettings({ payload }) {
  try {
    const { data, brandId } = payload;
    const options = {
      data,
    };
    yield put(changeLoadingState(true));
    const { BrandAdmin, BrandMarketSetting, Inactivity } = AppConstants.api;
    yield call(NetworkService.makeAPIPostRequest, [BrandAdmin, BrandMarketSetting, Inactivity, brandId], options);
    yield put(changeEndedStatus(true));
    yield put(setSuccessMessage(getMessage(i18n.t('brand:inactivity'), i18n.t('notification:slUpdated'))));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getBrandSportStatusSettings({ payload }) {
  try {
    const { brandId } = payload;
    yield put(changeLoadingState(true));
    const { BrandAdmin, Brand, SportStatusSettings } = AppConstants.api;
    const { data } = yield call(NetworkService.makeAPIGetRequest, [BrandAdmin, Brand, brandId, SportStatusSettings]);
    yield put(saveBrandSportStatusSettings(data));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* editBrandSportStatusSettings({ payload }) {
  try {
    const {
      id, data, name, brandId,
    } = payload;
    const options = {
      data,
    };
    yield put(changeLoadingState(true));
    const { BrandAdmin, Brand, SportStatusSettings } = AppConstants.api;
    yield call(NetworkService.makeAPIPutRequest, [BrandAdmin, Brand, brandId, SportStatusSettings, id], options);
    yield put(changeEndedStatus(true));
    yield put(setSuccessMessage(getMessage(i18n.t('brand:lIcon'), i18n.t(data.iconUrl ? 'notification:slUpdated' : 'notification:slReset'), name)));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

export default function* brandSettingSaga() {
  yield all([
    takeLatest(BrandSettingActionType.ENABLE_BRAND_CURRENCY, enableBrandCurrency),
    takeLatest(BrandSettingActionType.SET_DEFAULT_CURRENCY, setDefaultCurrency),
    takeLatest(BrandSettingActionType.CHANGE_ORDER_CURRENCY, changeOrderCurrency),
    takeLatest(BrandSettingActionType.ADD_BRAND_CURRENCY, addBrandCurrency),
    takeLatest(BrandSettingActionType.ENABLE_BRAND_COUNTRY, enableBrandCountry),
    takeLatest(BrandSettingActionType.SET_DEFAULT_COUNTRY, setDefaultCountry),
    takeLatest(BrandSettingActionType.ADD_BRAND_COUNTRY, addBrandCountry),
    takeLatest(BrandSettingActionType.ENABLE_BRAND_LANGUAGE, enableBrandLanguage),
    takeLatest(BrandSettingActionType.SET_DEFAULT_LANGUAGE, setDefaultLanguage),
    takeLatest(BrandSettingActionType.CHANGE_ORDER_LANGUAGE, changeOrderLanguage),
    takeLatest(BrandSettingActionType.ADD_BRAND_LANGUAGE, addBrandLanguage),
    takeLatest(BrandSettingActionType.GET_DOMAINS, getDomains),
    takeLatest(BrandSettingActionType.ADD_DOMAIN, addDomain),
    takeLatest(BrandSettingActionType.EDIT_DOMAIN, editDomain),
    takeLatest(BrandSettingActionType.DELETE_DOMAIN, deleteDomain),
    takeLatest(BrandSettingActionType.CONNECT_DOMAIN, connectDomain),
    takeLatest(BrandSettingActionType.DISCONNECT_DOMAIN, disconnectDomain),
    takeLatest(BrandSettingActionType.GET_SPORT_DOMAINS, getSportAllDomains),
    takeLatest(BrandSettingActionType.SET_SPORT_MAIN_DOMAIN, setSportMainDomain),
    takeLatest(BrandSettingActionType.ADD_SPORT_DOMAIN, addSportDomain),
    takeLatest(BrandSettingActionType.EDIT_SPORT_DOMAIN, editSportDomain),
    takeLatest(BrandSettingActionType.CONNECT_SPORT_DOMAIN, connectSportDomain),
    takeLatest(BrandSettingActionType.DISCONNECT_SPORT_DOMAIN, disconnectSportDomain),
    takeLatest(BrandSettingActionType.DELETE_SPORT_DOMAIN, deleteSportDomain),
    takeLatest(BrandSettingActionType.GET_BRAND_SMS_SETTINGS, getBrandSmsSettings),
    takeLatest(BrandSettingActionType.EDIT_BRAND_SMS_SETTINGS, editBrandSmsSettings),
    takeLatest(BrandSettingActionType.GET_BRAND_GENERAL_SETTINGS, getBrandGeneralSettings),
    takeLatest(BrandSettingActionType.ADD_BRAND_GENERAL_SETTINGS, addBrandGeneralSettings),
    takeLatest(BrandSettingActionType.DELETE_BRAND_SMS_SETTINGS, deleteBrandSmsSettings),
    takeLatest(BrandSettingActionType.GET_BRAND_SMTP_SETTINGS, getBrandSmtpSettings),
    takeLatest(BrandSettingActionType.EDIT_BRAND_SMTP_SETTINGS, editBrandSmtpSettings),
    takeLatest(BrandSettingActionType.EDIT_BRAND_CUSTOM_SMTP_SETTINGS, editBrandCustomSmtpSettings),
    takeLatest(BrandSettingActionType.GET_BRAND_CAPTCHA_SETTINGS, getBrandCaptchaSettings),
    takeLatest(BrandSettingActionType.EDIT_BRAND_CAPTCHA_SETTINGS, editBrandCaptchaSettings),
    takeLatest(BrandSettingActionType.GET_BRAND_EMAIL_CONTACTS, getBrandEmailContacts),
    takeLatest(BrandSettingActionType.ADD_BRAND_EMAIL_CONTACT, addBrandEmailContact),
    takeLatest(BrandSettingActionType.SET_BRAND_EMAIL_CONTACT_AS_DEFAULT, setBrandEmailContactAsDefault),
    takeLatest(BrandSettingActionType.DELETE_BRAND_EMAIL_CONTACT, deleteBrandEmailContact),
    takeLatest(BrandSettingActionType.ADD_BRAND_DEPOSIT_CONVERSION, addBrandDepositConversion),
    takeLatest(BrandSettingActionType.ADD_BRAND_WITHDRAWAL_CONVERSION, addBrandWithdrawalConversion),
    takeLatest(BrandSettingActionType.ADD_BRAND_OTHER_CONVERSION, addBrandOtherConversion),
    takeLatest(BrandSettingActionType.EDIT_BRAND_DEPOSIT_CONVERSION, editBrandDepositConversion),
    takeLatest(BrandSettingActionType.EDIT_BRAND_WITHDRAWAL_CONVERSION, editBrandWithdrawalConversion),
    takeLatest(BrandSettingActionType.EDIT_BRAND_OTHER_CONVERSION, editBrandOtherConversion),
    takeLatest(BrandSettingActionType.GET_BRAND_DEPOSIT_CONVERSIONS, getBrandDepositConversions),
    takeLatest(BrandSettingActionType.GET_BRAND_WITHDRAWAL_CONVERSIONS, getBrandWithdrawalConversions),
    takeLatest(BrandSettingActionType.GET_BRAND_OTHER_CONVERSIONS, getBrandOtherConversions),
    takeLatest(BrandSettingActionType.GET_BRAND_DEPOSIT_CONVERSION, getBrandDepositConversion),
    takeLatest(BrandSettingActionType.GET_BRAND_WITHDRAWAL_CONVERSION, getBrandWithdrawalConversion),
    takeLatest(BrandSettingActionType.GET_BRAND_OTHER_CONVERSION, getBrandOtherConversion),
    takeLatest(BrandSettingActionType.DELETE_BRAND_DEPOSIT_CONVERSION, deleteBrandDepositConversion),
    takeLatest(BrandSettingActionType.DELETE_BRAND_WITHDRAWAL_CONVERSION, deleteBrandWithdrawalConversion),
    takeLatest(BrandSettingActionType.DELETE_BRAND_OTHER_CONVERSION, deleteBrandOtherConversion),
    takeLatest(BrandSettingActionType.IMPORT_BRAND_DEPOSIT_CONVERSION, importBrandDepositConversion),
    takeLatest(BrandSettingActionType.IMPORT_BRAND_WITHDRAWAL_CONVERSION, importBrandWithdrawalConversion),
    takeLatest(BrandSettingActionType.IMPORT_BRAND_OTHER_CONVERSION, importBrandOtherConversion),
    takeLatest(BrandSettingActionType.EXPORT_BRAND_DEPOSIT_CONVERSION, exportBrandDepositConversion),
    takeLatest(BrandSettingActionType.EXPORT_BRAND_WITHDRAWAL_CONVERSION, exportBrandWithdrawalConversion),
    takeLatest(BrandSettingActionType.EXPORT_BRAND_OTHER_CONVERSION, exportBrandOtherConversion),
    takeLatest(BrandSettingActionType.GET_BRAND_DEPOSIT_CONVERSION_LOGS, getBrandDepositConversionLogs),
    takeLatest(BrandSettingActionType.GET_BRAND_WITHDRAWAL_CONVERSION_LOGS, getBrandWithdrawalConversionLogs),
    takeLatest(BrandSettingActionType.GET_BRAND_OTHER_CONVERSION_LOGS, getBrandOtherConversionLogs),
    takeLatest(BrandSettingActionType.GET_BRAND_STANDARD_SETTINGS, getBrandStandardSettings),
    takeLatest(BrandSettingActionType.EDIT_BRAND_STANDARD_SETTINGS, editBrandStandardSettings),
    takeLatest(BrandSettingActionType.GET_BRAND_BLOCKED_COUNTRIES_RESOURCE, getBrandBlockedCountriesResource),
    takeLatest(BrandSettingActionType.GET_BRAND_BLOCKED_IPS, getBrandBlockedIPs),
    takeLatest(BrandSettingActionType.ADD_BRAND_BLOCKED_COUNTRIES, addBrandBlockedCountries),
    takeLatest(BrandSettingActionType.ADD_BRAND_BLOCKED_IPS, addBrandBlockedIPs),
    takeLatest(BrandSettingActionType.DELETE_BRAND_BLOCKED_COUNTRY, deleteBrandBlockedCountry),
    takeLatest(BrandSettingActionType.DELETE_BRAND_BLOCKED_IP, deleteBrandBlockedIP),
    takeLatest(BrandSettingActionType.ADD_DOMAIN_REDIRECT_URL, addDomainRedirectUrl),
    takeLatest(BrandSettingActionType.DELETE_DOMAIN_REDIRECT_URL, deleteDomainRedirectUrl),
    takeLatest(BrandSettingActionType.GET_BRAND_LANGUAGES, getBrandLanguages),
    takeLatest(BrandSettingActionType.GET_BRAND_COUNTRIES, getBrandCountries),
    takeLatest(BrandSettingActionType.GET_BRAND_CURRENCIES, getBrandCurrencies),
    takeLatest(BrandSettingActionType.GET_BRAND_SPORTSBOOK_LAYOUTS, getBrandSportsBookLayouts),
    takeLatest(BrandSettingActionType.ACTIVATE_BRAND_SPORTSBOOK_PROVIDER, activateBrandSportsBookProvider),
    takeLatest(BrandSettingActionType.SET_BRAND_SPORTSBOOK_DEFAULT_TEMPLATE, setBrandSportsBookDefaultTemplate),
    takeLatest(BrandSettingActionType.ACTIVATE_BRAND_RGS, activateBrandRgs),
    takeLatest(BrandSettingActionType.ENABLE_BRAND_SPORTSBOOK_PREMATCH_TOP_MATCHES, enableBrandSportsBookPrematchTopMatches),
    takeLatest(BrandSettingActionType.ENABLE_BRAND_SPORTSBOOK_PREMATCH_TOTAL_TOP_MATCHES, enableBrandSportsBookPrematchTotalTopMatches),
    takeLatest(BrandSettingActionType.ENABLE_BRAND_SPORTSBOOK_LIVE_PREMATCH_TOP_MATCHES, enableBrandSportsBookLivePrematchTopMatches),
    takeLatest(BrandSettingActionType.ENABLE_BRAND_SPORTSBOOK_LIVE_TOP_MATCHES, enableBrandSportsBookLiveTopMatches),
    takeLatest(BrandSettingActionType.ENABLE_BRAND_SPORTSBOOK_SECTION_SCROLLING, enableBrandSportsBookSectionScrolling),
    takeLatest(BrandSettingActionType.ENABLE_BRAND_SPORTSBOOK_TOURNAMENT_BALANCE, enableBrandSportsBookTournamentBalance),
    takeLatest(BrandSettingActionType.ENABLE_BRAND_SPORTSBOOK_JACKPOT, enableBrandSportsBookJackpot),
    takeLatest(BrandSettingActionType.CHANGE_BRAND_SPORTSBOOK_MOBILE_INTEGRATION_TYPE, changeBrandSportsBookMobileIntegrationType),
    takeLatest(BrandSettingActionType.GET_BRAND_SPORTSBOOK_SETTINGS, getBrandSportsBookSettings),
    takeLatest(BrandSettingActionType.ENABLE_BRAND_SPORTSBOOK_COLOR_INHERITANCE, enableBrandSportsBookColorInheritance),
    takeLatest(BrandSettingActionType.GET_BRAND_PREFERENCES_SETTINGS, getBrandPreferencesSettings),
    takeLatest(BrandSettingActionType.EDIT_BRAND_PREFERENCES_DEPOSIT_SETTINGS, editBrandPreferencesDepositSettings),
    takeLatest(BrandSettingActionType.EDIT_BRAND_PREFERENCES_WITHDRAWAL_SETTINGS, editBrandPreferencesWithdrawalSettings),
    takeLatest(BrandSettingActionType.EDIT_BRAND_PREFERENCES_PROMOTION_SETTINGS, editBrandPreferencesPromotionSettings),
    takeLatest(BrandSettingActionType.EDIT_BRAND_PREFERENCES_BONUSES_SETTINGS, editBrandPreferencesBonusesSettings),
    takeLatest(BrandSettingActionType.GET_BRAND_SPORTSBOOK_WALLETS_SETTINGS, getBrandSportsBookWalletsSettings),
    takeLatest(BrandSettingActionType.EDIT_BRAND_SPORTSBOOK_WALLETS_SETTINGS, editBrandSportsBookWalletsSettings),
    takeLatest(BrandSettingActionType.GET_BRAND_CASINO_PRODUCT_SETTINGS, getBrandCasinoProductSettings),
    takeLatest(BrandSettingActionType.EDIT_BRAND_CASINO_PRODUCT_SETTINGS, editBrandCasinoProductSettings),
    takeLatest(BrandSettingActionType.GET_BRAND_CASINO_PRODUCT_WALLETS_SETTINGS, getBrandCasinoProductWalletsSettings),
    takeLatest(BrandSettingActionType.EDIT_BRAND_CASINO_PRODUCT_WALLETS_SETTINGS, editBrandCasinoProductWalletsSettings),
    takeLatest(BrandSettingActionType.GET_BRAND_CURRENCY_FORMAT, getBrandCurrencyFormat),
    takeLatest(BrandSettingActionType.EDIT_BRAND_CURRENCY_FORMAT, editBrandCurrencyFormat),
    takeLatest(BrandSettingActionType.GET_BRAND_INACTIVITY_SETTINGS, getBrandInactivitySettings),
    takeLatest(BrandSettingActionType.EDIT_BRAND_INACTIVITY_SETTINGS, editBrandInactivitySettings),
    takeLatest(BrandSettingActionType.GET_BRAND_SPORT_STATUS_SETTINGS, getBrandSportStatusSettings),
    takeLatest(BrandSettingActionType.EDIT_BRAND_SPORT_STATUS_SETTINGS, editBrandSportStatusSettings),
  ]);
}
