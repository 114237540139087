import PropTypes from 'prop-types';

const AdminIframe = (props) => {
  const {
    src, title, id, isHidden,
  } = props;

  return <iframe src={src} title={title} id={id} className={isHidden ? 'hidden' : ''} />;
};

AdminIframe.propTypes = {
  src: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  isHidden: PropTypes.bool,
};

AdminIframe.defaultProps = {
  isHidden: false,
};

export default AdminIframe;
