import SimilarAccountActionType from 'actions/similarAccount/similarAccountActionType';

const initialState = {
  similarAccountsGroups: {},
  similarAccounts: {},
  similarAccountsInfo: {},
};
const similarAccountReducer = (state = initialState, { type, payload }) => {
  switch (type) {
  case SimilarAccountActionType.SAVE_SIMILAR_ACCOUNTS_GROUPS:
    return {
      ...state,
      similarAccountsGroups: payload.data,
    };
  case SimilarAccountActionType.RESET_SIMILAR_ACCOUNTS_GROUPS:
    return {
      ...state,
      similarAccountsGroups: {},
    };
  case SimilarAccountActionType.SAVE_SIMILAR_ACCOUNTS:
    return {
      ...state,
      similarAccounts: payload.data,
    };
  case SimilarAccountActionType.RESET_SIMILAR_ACCOUNTS:
    return {
      ...state,
      similarAccounts: {},
    };
  case SimilarAccountActionType.SAVE_PLAYER_SIMILAR_ACCOUNTS_INFO:
    return {
      ...state,
      similarAccountsInfo: payload.data,
    };
  case SimilarAccountActionType.RESET_PLAYER_SIMILAR_ACCOUNTS_INFO:
    return {
      ...state,
      similarAccountsInfo: {},
    };
  default:
    return state;
  }
};

export default similarAccountReducer;
