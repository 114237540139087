import IpWhitelistActionType from './ipWhitelistActionType';

export const getIpWhitelist = (partnerId, params) => ({
  type: IpWhitelistActionType.GET_IP_WHITELIST,
  payload: { partnerId, params },
});

export const saveIpWhitelist = (data) => ({
  type: IpWhitelistActionType.SAVE_IP_WHITELIST,
  payload: { data },
});

export const resetIpWhitelist = () => ({
  type: IpWhitelistActionType.RESET_IP_WHITELIST,
});

export const deleteIpWhitelist = (data) => ({
  type: IpWhitelistActionType.DELETE_IP_WHITELIST,
  payload: { data },
});

export const addIpWhitelist = (partnerId, data) => ({
  type: IpWhitelistActionType.ADD_IP_WHITELIST,
  payload: { partnerId, data },
});
