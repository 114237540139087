import BlockedProviderActionType from './blockedProviderActionType';

export const getPlayerBlockedProvidersHistory = (playerId, data) => ({
  type: BlockedProviderActionType.GET_PLAYER_BLOCKED_PROVIDERS_HISTORY,
  payload: { playerId, data },
});

export const savePlayerBlockedProvidersHistory = (data) => ({
  type: BlockedProviderActionType.SAVE_PLAYER_BLOCKED_PROVIDERS_HISTORY,
  payload: { data },
});

export const resetPlayerBlockedProvidersHistory = () => ({
  type: BlockedProviderActionType.RESET_PLAYER_BLOCKED_PROVIDERS_HISTORY,
});

export const blockPlayerForProviders = (playerId, data) => ({
  type: BlockedProviderActionType.BLOCK_PLAYER_FOR_PROVIDERS,
  payload: { playerId, data },
});

export const getPlayerBlockedProviders = (playerId) => ({
  type: BlockedProviderActionType.GET_PLAYER_BLOCKED_PROVIDERS,
  payload: { playerId },
});

export const savePlayerBlockedProviders = (data) => ({
  type: BlockedProviderActionType.SAVE_PLAYER_BLOCKED_PROVIDERS,
  payload: { data },
});

export const resetPlayerBlockedProviders = () => ({
  type: BlockedProviderActionType.RESET_PLAYER_BLOCKED_PROVIDERS,
});

export const getBulkBlockedProvidersHistory = (brandId, params) => ({
  type: BlockedProviderActionType.GET_BULK_BLOCKED_PROVIDERS_HISTORY,
  payload: { brandId, params },
});

export const saveBulkBlockedProvidersHistory = (data) => ({
  type: BlockedProviderActionType.SAVE_BULK_BLOCKED_PROVIDERS_HISTORY,
  payload: { data },
});

export const resetBulkBlockedProvidersHistory = () => ({
  type: BlockedProviderActionType.RESET_BULK_BLOCKED_PROVIDERS_HISTORY,
});

export const blockPlayersProvidersByBulk = (brandId, data) => ({
  type: BlockedProviderActionType.BLOCK_PLAYERS_PROVIDERS_BY_BULK,
  payload: { brandId, data },
});
