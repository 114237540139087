import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { changePassword } from 'actions/auth/authActionCreator';
import { ChangePassword } from 'components/auth';
import { selectCallEndedStatus } from 'selectors';
import { changeEndedStatus } from 'actions/setting/settingActionCreator';

const mapStateToProps = ({ settings }) => ({
  endedStatus: selectCallEndedStatus(settings),
});

const mapDispatchToProps = (dispatch) => bindActionCreators(
  {
    changePassword,
    changeEndedStatus,
  },
  dispatch,
);

export default connect(mapStateToProps, mapDispatchToProps)(ChangePassword);
