import {
  all, call, put, select, takeLatest,
} from '@redux-saga/core/effects';
import qs from 'qs';
import mime from 'mimetypes';
import ReferralActionType from 'actions/referral/referralActionType';
import { AppConstants } from 'constants/index';
import {
  changeEndedStatus, changeLoadingState, setErrorMessage, setSuccessMessage,
} from 'actions/setting/settingActionCreator';
import NetworkService from 'services/networkService';
import {
  saveExistingPlayers,
  saveReferrals,
  saveReferral,
  saveReferralAccumulated,
  saveReferralTransferred,
  saveReferralFailed,
  saveReferralReferees,
  saveReferralParticipants,
  savePlayerReferralReferees,
  savePlayerReferrals,
  saveReferralAllParticipants,
  savePlayerReferralPeriods,
  saveReferralTransferredPeriods,
  saveReferralFailedPeriods,
  saveReferralAccumulatedPeriods,
} from 'actions/referral/referralActionCreator';
import { getError, getMessage } from 'utils/helpers';
import enumTypes from 'constants/enumTypes';
import {
  selectPlayerReferralPeriods, selectReferralFailedPeriods, selectReferralTransferredPeriods, selectReferralAccumulatedPeriods,
} from 'selectors';
import { i18n } from 'services';
import { downloadFile } from 'utils/utils';

function* createReferral({ payload }) {
  try {
    const { name } = payload.data;
    const { DataCollector, Referral } = AppConstants.api;
    yield put(changeLoadingState(true));
    yield call(NetworkService.makeAPIPostRequest, [DataCollector, Referral], payload);
    yield put(changeLoadingState(false));
    yield put(changeEndedStatus(true));
    yield put(setSuccessMessage(getMessage(i18n.t('bonus:lReferral'), i18n.t('notification:slCreated'), name)));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getReferrals({ payload }) {
  try {
    const { brandId, params } = payload;
    const options = {
      params,
      paramsSerializer: (param) => qs.stringify(param),
    };
    const { DataCollector, Referral, All } = AppConstants.api;
    yield put(changeLoadingState(true));
    const response = yield call(NetworkService.makeAPIGetRequest, [DataCollector, Referral, All, brandId], options);
    yield put(saveReferrals(response.data));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getExistingPlayers({ payload }) {
  try {
    const { params } = payload;
    const options = {
      params,
    };
    const { DataCollector, Referral, ExistingPlayers } = AppConstants.api;
    yield put(changeLoadingState(true));
    const response = yield call(NetworkService.makeAPIGetRequest, [DataCollector, Referral, ExistingPlayers], options);
    yield put(saveExistingPlayers(response.data));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getReferral({ payload }) {
  try {
    const { referralId } = payload;
    const { DataCollector, Referral } = AppConstants.api;
    yield put(changeLoadingState(true));
    const response = yield call(NetworkService.makeAPIGetRequest, [DataCollector, Referral, referralId]);
    yield put(saveReferral(response.data));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err, true);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* editReferral({ payload }) {
  try {
    const { referralId, data } = payload;
    const { name } = data;
    const { DataCollector, Referral } = AppConstants.api;
    yield put(changeLoadingState(true));
    yield call(NetworkService.makeAPIPutRequest, [DataCollector, Referral, referralId], { data });
    yield put(changeLoadingState(false));
    yield put(changeEndedStatus(true));
    yield put(setSuccessMessage(getMessage(i18n.t('bonus:lReferralBonus'), i18n.t('notification:slUpdated'), name)));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* deleteReferral({ payload }) {
  try {
    const { id, name } = payload.data;
    yield put(changeLoadingState(true));
    const { DataCollector, Referral } = AppConstants.api;
    yield call(NetworkService.makeAPIDeleteRequest, [DataCollector, Referral, id]);
    yield put(changeEndedStatus(true));
    yield put(changeLoadingState(false));
    yield put(setSuccessMessage(getMessage(i18n.t('bonus:lReferralBonus'), i18n.t('notification:slDeleted'), name)));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* activateReferral({ payload }) {
  try {
    const { id, name, enable } = payload.data;
    const params = { enable };
    const { DataCollector, Referral, Enable } = AppConstants.api;
    yield put(changeLoadingState(true));
    yield call(NetworkService.makeAPIPutRequest, [DataCollector, Referral, Enable, id], { params });
    yield put(changeLoadingState(false));
    yield put(changeEndedStatus(true));
    yield put(setSuccessMessage(getMessage(i18n.t('bonus:lReferralBonus'), i18n.t(enable ? 'notification:slEnabled' : 'notification:slDisabled'), name)));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* finishReferral({ payload }) {
  try {
    const { id, name, discardCommissions } = payload.data;
    const params = { discardCommissions };
    const { DataCollector, Referral, Finish } = AppConstants.api;
    yield put(changeLoadingState(true));
    yield call(NetworkService.makeAPIPutRequest, [DataCollector, Referral, Finish, id], { params });
    yield put(changeLoadingState(false));
    yield put(changeEndedStatus(true));
    yield put(setSuccessMessage(getMessage(i18n.t('bonus:lReferralBonus'), i18n.t('notification:slFinished'), name)));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getReferralStatistics({ payload }) {
  try {
    const { referralId, params } = payload;
    const { periodStatus } = params;
    const options = {
      params,
      paramsSerializer: (param) => qs.stringify(param),
    };
    const { DataCollector, Referral, Commissions } = AppConstants.api;
    yield put(changeLoadingState(true));
    const { data } = yield call(NetworkService.makeAPIGetRequest, [DataCollector, Referral, Commissions, referralId], options);
    let saveFunc = () => {};
    switch (periodStatus) {
    case enumTypes.cashBackReferralPeriodStatus.Transferred: {
      saveFunc = saveReferralTransferred;
      break;
    }
    case enumTypes.cashBackReferralPeriodStatus.Failed: {
      saveFunc = saveReferralFailed;
      break;
    }
    default: {
      saveFunc = saveReferralAccumulated;
      break;
    }
    }
    yield put(saveFunc(data));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err, true);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getReferralCommissionPeriods({ payload }) {
  try {
    const { referralId, params } = payload;
    const { periodStatus, referrerId } = params;
    const { DataCollector, Referral, CommissionDetails } = AppConstants.api;
    yield put(changeLoadingState(true));
    const { data: periods } = yield call(NetworkService.makeAPIGetRequest, [DataCollector, Referral, CommissionDetails, referralId], { params });
    const { referral } = yield select();

    let selectFunc = () => {};
    let saveFunc = () => {};
    switch (periodStatus) {
    case enumTypes.cashBackReferralPeriodStatus.Transferred: {
      selectFunc = selectReferralTransferredPeriods;
      saveFunc = saveReferralTransferredPeriods;
      break;
    }
    case enumTypes.cashBackReferralPeriodStatus.Failed: {
      selectFunc = selectReferralFailedPeriods;
      saveFunc = saveReferralFailedPeriods;
      break;
    }
    case enumTypes.cashBackReferralPeriodStatus.Accumulated: {
      selectFunc = selectReferralAccumulatedPeriods;
      saveFunc = saveReferralAccumulatedPeriods;
      break;
    }
    default: {
      selectFunc = selectPlayerReferralPeriods;
      saveFunc = savePlayerReferralPeriods;
    }
    }

    // no period case is player's bonus case
    const rowId = periodStatus ? referrerId : referralId;
    const data = selectFunc(referral);
    data[rowId] = periods;
    yield put(saveFunc(data));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* transferReferralCommissions({ payload }) {
  try {
    const { referralId, data, params } = payload;
    const options = {
      params,
      data,
    };
    yield put(changeLoadingState(true));
    const { DataCollector, Referral, Transfer } = AppConstants.api;
    yield call(NetworkService.makeAPIPutRequest, [DataCollector, Referral, Transfer, referralId], options);
    yield put(changeEndedStatus(true));
    yield put(changeLoadingState(false));
    yield put(setSuccessMessage(getMessage(i18n.t('bonus:commissions'), i18n.t('notification:plSent'))));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* deleteReferralCommissions({ payload }) {
  try {
    const { referralId, data, params } = payload;
    const options = {
      params,
      data,
    };
    yield put(changeLoadingState(true));
    const { DataCollector, Referral, Commissions } = AppConstants.api;
    yield call(NetworkService.makeAPIDeleteRequest, [DataCollector, Referral, Commissions, referralId], options);
    yield put(changeEndedStatus(true));
    yield put(changeLoadingState(false));
    yield put(setSuccessMessage(getMessage(i18n.t('bonus:commissions'), i18n.t('notification:plDeleted'))));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getReferralReferees({ payload }) {
  try {
    const { batchId, params } = payload;
    const options = {
      params,
      paramsSerializer: (param) => qs.stringify(param),
    };
    const { DataCollector, Referral, Referees } = AppConstants.api;
    yield put(changeLoadingState(true));
    const response = yield call(NetworkService.makeAPIGetRequest, [DataCollector, Referral, Referees, batchId], options);
    yield put(saveReferralReferees(response.data));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err, true);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getReferralParticipants({ payload }) {
  try {
    const { referralId, params } = payload;
    const options = {
      params,
      paramsSerializer: (param) => qs.stringify(param),
    };
    const { DataCollector, Referral, Players } = AppConstants.api;
    yield put(changeLoadingState(true));
    const response = yield call(NetworkService.makeAPIGetRequest, [DataCollector, Referral, Players, referralId], options);
    yield put(saveReferralParticipants(response.data));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* deleteReferralParticipant({ payload }) {
  try {
    const { id, userName } = payload.data;
    yield put(changeLoadingState(true));
    const { DataCollector, Referral, Players } = AppConstants.api;
    yield call(NetworkService.makeAPIDeleteRequest, [DataCollector, Referral, Players, payload.referralId, id]);
    yield put(changeEndedStatus(true));
    yield put(changeLoadingState(false));
    yield put(setSuccessMessage(getMessage(i18n.t('lPlayer'), i18n.t('notification:plRemoved'), userName)));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getPlayerReferralReferees({ payload }) {
  try {
    const { playerId, params } = payload;
    const options = {
      params,
      paramsSerializer: (param) => qs.stringify(param),
    };
    const { DataCollector, Referral, ByPlayerReferees } = AppConstants.api;
    yield put(changeLoadingState(true));
    const response = yield call(NetworkService.makeAPIGetRequest, [DataCollector, Referral, ByPlayerReferees, playerId], options);
    yield put(savePlayerReferralReferees(response.data));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getPlayerReferrals({ payload }) {
  try {
    const { playerId, params } = payload;
    const options = {
      params,
      paramsSerializer: (param) => qs.stringify(param),
    };
    const { DataCollector, Referral, ByPlayer } = AppConstants.api;
    yield put(changeLoadingState(true));
    const response = yield call(NetworkService.makeAPIGetRequest, [DataCollector, Referral, ByPlayer, playerId], options);
    yield put(savePlayerReferrals(response.data));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getReferralAllParticipants({ payload }) {
  try {
    const { referralId, params } = payload;
    const options = {
      params,
      paramsSerializer: (param) => qs.stringify(param),
    };
    const { DataCollector, Referral, AllPlayers } = AppConstants.api;
    yield put(changeLoadingState(true));
    const response = yield call(NetworkService.makeAPIGetRequest, [DataCollector, Referral, AllPlayers, referralId], options);
    yield put(saveReferralAllParticipants(response.data));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err, true);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* editReferralParticipants({ payload }) {
  try {
    const { referralId, data } = payload;
    const { DataCollector, Referral, Manage } = AppConstants.api;
    yield put(changeLoadingState(true));
    yield call(NetworkService.makeAPIPutRequest, [DataCollector, Referral, Manage, referralId], { data });
    yield put(changeLoadingState(false));
    yield put(changeEndedStatus(true));
    yield put(setSuccessMessage(getMessage(i18n.t('bonus:referralParticipants'), i18n.t('notification:plUpdated'))));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* exportReferralStatistics({ payload }) {
  try {
    const { id, params } = payload;
    const options = {
      params,
      responseType: 'blob',
      paramsSerializer: (param) => qs.stringify(param, { allowDots: true }),
    };
    yield put(changeLoadingState(true));
    const {
      DataCollector, Referral, Commissions, Export,
    } = AppConstants.api;
    const { data, headers } = yield call(NetworkService.makeAPIGetRequest, [DataCollector, Referral, Commissions, id, Export], options);
    downloadFile(data, i18n.t('bonus:referralStatistics'), mime.detectExtension(headers['content-type']));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

export default function* referralSaga() {
  yield all([
    takeLatest(ReferralActionType.CREATE_REFERRAL, createReferral),
    takeLatest(ReferralActionType.GET_REFERRALS, getReferrals),
    takeLatest(ReferralActionType.GET_EXISTING_PLAYERS, getExistingPlayers),
    takeLatest(ReferralActionType.GET_REFERRAL, getReferral),
    takeLatest(ReferralActionType.EDIT_REFERRAL, editReferral),
    takeLatest(ReferralActionType.DELETE_REFERRAL, deleteReferral),
    takeLatest(ReferralActionType.ACTIVATE_REFERRAL, activateReferral),
    takeLatest(ReferralActionType.FINISH_REFERRAL, finishReferral),
    takeLatest(ReferralActionType.GET_REFERRAL_STATISTICS, getReferralStatistics),
    takeLatest(ReferralActionType.GET_REFERRAL_COMMISSION_PERIODS, getReferralCommissionPeriods),
    takeLatest(ReferralActionType.TRANSFER_REFERRAL_COMMISSIONS, transferReferralCommissions),
    takeLatest(ReferralActionType.DELETE_REFERRAL_COMMISSIONS, deleteReferralCommissions),
    takeLatest(ReferralActionType.GET_REFERRAL_REFEREES, getReferralReferees),
    takeLatest(ReferralActionType.GET_REFERRAL_PARTICIPANTS, getReferralParticipants),
    takeLatest(ReferralActionType.DELETE_REFERRAL_PARTICIPANT, deleteReferralParticipant),
    takeLatest(ReferralActionType.GET_PLAYER_REFERRAL_REFEREES, getPlayerReferralReferees),
    takeLatest(ReferralActionType.GET_PLAYER_REFERRALS, getPlayerReferrals),
    takeLatest(ReferralActionType.GET_REFERRAL_ALL_PARTICIPANTS, getReferralAllParticipants),
    takeLatest(ReferralActionType.EDIT_REFERRAL_PARTICIPANTS, editReferralParticipants),
    takeLatest(ReferralActionType.EXPORT_REFERRAL_STATISTICS, exportReferralStatistics),
  ]);
}
