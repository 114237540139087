import styled from 'styled-components';
import { media } from 'utils/styledHelpers';

const StyledSteps = styled.div`
  padding: 1.71rem 2.86rem 0;
  margin-bottom: 3.43rem;
  ${media.sm`
    margin-bottom: 1.14rem;
  `}
  .ant-steps {
    svg path {
      color: ${(props) => props.theme.colors.white};
    }
    .ant-steps-item-wait:not(.ant-steps-item-active) > .ant-steps-item-container[role='button'] {
      cursor: initial;
      pointer-events: none;
    }
    .ant-steps-item {
      .ant-steps-item-content {
        vertical-align: middle;
      }
      .ant-steps-item-icon {
        width: 2.85rem;
        height: 2.85rem;
        line-height: 2.85rem;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        vertical-align: middle;
        .ant-steps-icon {
          font-weight: ${(props) => props.theme.typography.fontWeightMedium};
          font-size: 1.14rem;
          margin-top: 0.1rem;
        }
      }
      .ant-steps-item-title {
        font-weight: ${(props) => props.theme.typography.fontWeightRegular};
        font-size: ${(props) => props.theme.typography.fontSizeDefault};
        color: ${(props) => props.theme.colors.secondaryBorder};
        &::after {
          background-color: ${(props) => props.theme.colors.secondaryBorder};
        }
      }
      &.ant-steps-item-process {
        .ant-steps-item-title {
          font-weight: ${(props) => props.theme.typography.fontWeightRegular};
          font-size: ${(props) => props.theme.typography.fontSizeDefault};
          color: ${(props) => props.theme.colors.primaryText};
        }
        .ant-steps-item-icon {
          background-color: ${(props) => props.theme.colors.secondaryBorder};
          border-color: ${(props) => props.theme.colors.secondaryBorder};
          .ant-steps-icon {
            color: ${(props) => props.theme.colors.primaryText};
          }
        }
      }
      &.ant-steps-item-finish {
        .ant-steps-item-title {
          font-weight: ${(props) => props.theme.typography.fontWeightMedium};
          color: ${(props) => props.theme.colors.blue};
        }
        .ant-steps-item-icon {
          background-color: ${(props) => props.theme.colors.blue};
          border-color: ${(props) => props.theme.colors.blue};
          .ant-steps-icon {
            color: ${(props) => props.theme.colors.white};
            top: initial;
            left: initial;
            width: 40px;
            height: 40px;
            display: flex;
            align-items: center;
            justify-content: center;
            line-height: 1;
          }
        }
      }
      &.ant-steps-item-wait {
        .ant-steps-item-title {
          color: ${(props) => props.theme.colors.primaryText};
        }
        .ant-steps-item-icon {
          background-color: ${(props) => props.theme.colors.secondaryBg};
          border-color: ${(props) => props.theme.colors.secondaryBorder};
          .ant-steps-icon {
            color: ${(props) => props.theme.colors.primaryText};
          }
        }
      }
    }
  }
`;

export { StyledSteps };
