import PlayerPerformanceActionType from './playerPerformanceActionType';

export const getPlayerWalletAggregation = (id, params) => ({
  type: PlayerPerformanceActionType.GET_PLAYER_WALLET_AGGREGATION,
  payload: { id, params },
});

export const savePlayerWalletAggregation = (data) => ({
  type: PlayerPerformanceActionType.SAVE_PLAYER_WALLET_AGGREGATION,
  payload: { data },
});

export const resetPlayerWalletAggregation = () => ({
  type: PlayerPerformanceActionType.RESET_PLAYER_WALLET_AGGREGATION,
});

export const getPlayerTransactionAggregation = (id, params) => ({
  type: PlayerPerformanceActionType.GET_PLAYER_TRANSACTION_AGGREGATION,
  payload: { id, params },
});

export const savePlayerTransactionAggregation = (data) => ({
  type: PlayerPerformanceActionType.SAVE_PLAYER_TRANSACTION_AGGREGATION,
  payload: { data },
});

export const resetPlayerTransactionAggregation = () => ({
  type: PlayerPerformanceActionType.RESET_PLAYER_TRANSACTION_AGGREGATION,
});

export const changeWalletLoadingState = (state) => ({
  type: PlayerPerformanceActionType.CHANGE_WALLET_LOADING_STATE,
  payload: state,
});

export const changeTransactionLoadingState = (state) => ({
  type: PlayerPerformanceActionType.CHANGE_TRANSACTION_LOADING_STATE,
  payload: state,
});

export const getPlayersAggregations = (brandId, params) => ({
  type: PlayerPerformanceActionType.GET_PLAYERS_AGGREGATIONS,
  payload: { brandId, params },
});

export const savePlayersAggregations = (data) => ({
  type: PlayerPerformanceActionType.SAVE_PLAYERS_AGGREGATIONS,
  payload: { data },
});

export const resetPlayersAggregations = () => ({
  type: PlayerPerformanceActionType.RESET_PLAYERS_AGGREGATIONS,
});

export const generatePlayersAggregationsReport = (params) => ({
  type: PlayerPerformanceActionType.GENERATE_PLAYERS_AGGREGATIONS_REPORT,
  payload: { params },
});

export const getPlayerMixedTransaction = (playerId, params) => ({
  type: PlayerPerformanceActionType.GET_PLAYER_MIXED_TRANSACTION,
  payload: { playerId, params },
});

export const savePlayerMixedTransaction = (data) => ({
  type: PlayerPerformanceActionType.SAVE_PLAYER_MIXED_TRANSACTION,
  payload: { data },
});

export const resetPlayerMixedTransaction = () => ({
  type: PlayerPerformanceActionType.RESET_PLAYER_MIXED_TRANSACTION,
});

export const changePlayerMixedTransactionLoadingState = (state) => ({
  type: PlayerPerformanceActionType.CHANGE_MIXED_TRANSACTION_LOADING_STATE,
  payload: state,
});
