import PaymentMethodActionType from './paymentMethodActionType';

export const getPlayerBlockedPaymentMethodsHistory = (playerId, data) => ({
  type: PaymentMethodActionType.GET_PLAYER_BLOCKED_PAYMENT_METHODS_HISTORY,
  payload: { playerId, data },
});

export const savePlayerBlockedPaymentMethodsHistory = (data) => ({
  type: PaymentMethodActionType.SAVE_PLAYER_BLOCKED_PAYMENT_METHODS_HISTORY,
  payload: { data },
});

export const resetPlayerBlockedPaymentMethodsHistory = () => ({
  type: PaymentMethodActionType.RESET_PLAYER_BLOCKED_PAYMENT_METHODS_HISTORY,
});

export const blockPlayerPaymentMethods = (playerId, data) => ({
  type: PaymentMethodActionType.BLOCK_PLAYER_PAYMENT_METHODS,
  payload: { playerId, data },
});

export const unblockPlayerPaymentMethods = (playerId, data) => ({
  type: PaymentMethodActionType.UNBLOCK_PLAYER_PAYMENT_METHODS,
  payload: { playerId, data },
});

export const getBulkBlockedPaymentMethodsHistory = (brandId, params) => ({
  type: PaymentMethodActionType.GET_BULK_BLOCKED_PAYMENT_METHODS_HISTORY,
  payload: { brandId, params },
});

export const saveBulkBlockedPaymentMethodsHistory = (data) => ({
  type: PaymentMethodActionType.SAVE_BULK_BLOCKED_PAYMENT_METHODS_HISTORY,
  payload: { data },
});

export const resetBulkBlockedPaymentMethodsHistory = () => ({
  type: PaymentMethodActionType.RESET_BULK_BLOCKED_PAYMENT_METHODS_HISTORY,
});

export const blockPlayersPaymentMethodsByBulk = (brandId, data) => ({
  type: PaymentMethodActionType.BLOCK_PLAYERS_PAYMENT_METHODS_BY_BULK,
  payload: { brandId, data },
});

export const unblockPlayersPaymentMethodsByBulk = (brandId, data) => ({
  type: PaymentMethodActionType.UNBLOCK_PLAYERS_PAYMENT_METHODS_BY_BULK,
  payload: { brandId, data },
});

export const getBlockedPaymentMethodPlayers = (id, params) => ({
  type: PaymentMethodActionType.GET_BLOCKED_PAYMENT_METHOD_PLAYERS,
  payload: { id, params },
});

export const saveBlockedPaymentMethodPlayers = (data) => ({
  type: PaymentMethodActionType.SAVE_BLOCKED_PAYMENT_METHOD_PLAYERS,
  payload: { data },
});

export const resetBlockedPaymentMethodPlayers = () => ({
  type: PaymentMethodActionType.RESET_BLOCKED_PAYMENT_METHOD_PLAYERS,
});

export const exportBlockedPaymentMethodPlayers = (id, params) => ({
  type: PaymentMethodActionType.EXPORT_BLOCKED_PAYMENT_METHOD_PLAYERS,
  payload: { id, params },
});
