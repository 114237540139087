const BrandPaymentActionType = {
  GET_BRAND_FAILED_PAYMENTS_SETTINGS: 'GET_BRAND_FAILED_PAYMENTS_SETTINGS',
  SAVE_BRAND_FAILED_PAYMENTS_SETTINGS: 'SAVE_BRAND_FAILED_PAYMENTS_SETTINGS',
  RESET_BRAND_FAILED_PAYMENTS_SETTINGS: 'RESET_BRAND_FAILED_PAYMENTS_SETTINGS',

  EDIT_BRAND_FAILED_PAYMENTS_SETTINGS: 'EDIT_BRAND_FAILED_PAYMENTS_SETTINGS',

  GET_BRAND_PAYMENT_METHODS: 'GET_BRAND_PAYMENT_METHODS',
  SAVE_BRAND_PAYMENT_METHODS: 'SAVE_BRAND_PAYMENT_METHODS',
  RESET_BRAND_PAYMENT_METHODS: 'RESET_BRAND_PAYMENT_METHODS',

  GET_BRAND_PAYMENT_SETTINGS: 'GET_BRAND_PAYMENT_SETTINGS',
  SAVE_BRAND_PAYMENT_SETTINGS: 'SAVE_BRAND_PAYMENT_SETTINGS',
  RESET_BRAND_PAYMENT_SETTINGS: 'RESET_BRAND_PAYMENT_SETTINGS',
  EDIT_BRAND_PAYMENT_SETTINGS: 'EDIT_BRAND_PAYMENT_SETTINGS',

  GET_BRAND_PAYMENT_METHOD_SETTINGS: 'GET_BRAND_PAYMENT_METHOD_SETTINGS',
  SAVE_BRAND_PAYMENT_METHOD_SETTINGS: 'SAVE_BRAND_PAYMENT_METHOD_SETTINGS',
  EDIT_BRAND_PAYMENT_METHOD_SETTINGS: 'EDIT_BRAND_PAYMENT_METHOD_SETTINGS',
  RESET_BRAND_PAYMENT_METHOD_SETTINGS: 'RESET_BRAND_PAYMENT_METHOD_SETTINGS',

  GET_BRAND_PAYMENT_METHODS_FEES: 'GET_BRAND_PAYMENT_METHODS_FEES',
  SAVE_BRAND_PAYMENT_METHODS_FEES: 'SAVE_BRAND_PAYMENT_METHODS_FEES',
  RESET_BRAND_PAYMENT_METHODS_FEES: 'RESET_BRAND_PAYMENT_METHODS_FEES',

  GET_BRAND_PAYMENT_METHODS_LIMITS: 'GET_BRAND_PAYMENT_METHODS_LIMITS',
  SAVE_BRAND_PAYMENT_METHODS_LIMITS: 'SAVE_BRAND_PAYMENT_METHODS_LIMITS',
  RESET_BRAND_PAYMENT_METHODS_LIMITS: 'RESET_BRAND_PAYMENT_METHODS_LIMITS',

  GET_BRAND_PAYMENT_ALLOWED_CURRENCIES: 'GET_BRAND_PAYMENT_ALLOWED_CURRENCIES',
  SAVE_BRAND_PAYMENT_ALLOWED_CURRENCIES: 'SAVE_BRAND_PAYMENT_ALLOWED_CURRENCIES',
  RESET_BRAND_PAYMENT_ALLOWED_CURRENCIES: 'RESET_BRAND_PAYMENT_ALLOWED_CURRENCIES',

  GET_PAYMENT_CURRENCIES: 'GET_PAYMENT_CURRENCIES',
  SAVE_PAYMENT_CURRENCIES: 'SAVE_PAYMENT_CURRENCIES',
  RESET_PAYMENT_CURRENCIES: 'RESET_PAYMENT_CURRENCIES',

  GET_BRAND_PAYMENT_ALLOWED_COUNTRIES: 'GET_BRAND_PAYMENT_ALLOWED_COUNTRIES',
  SAVE_BRAND_PAYMENT_ALLOWED_COUNTRIES: 'SAVE_BRAND_PAYMENT_ALLOWED_COUNTRIES',
  RESET_BRAND_PAYMENT_ALLOWED_COUNTRIES: 'RESET_BRAND_PAYMENT_ALLOWED_COUNTRIES',

  GET_BRAND_PAYMENTS_ACTIVE_CURRENCIES: 'GET_BRAND_PAYMENTS_ACTIVE_CURRENCIES',
  SAVE_BRAND_PAYMENTS_ACTIVE_CURRENCIES: 'SAVE_BRAND_PAYMENTS_ACTIVE_CURRENCIES',
  RESET_BRAND_PAYMENTS_ACTIVE_CURRENCIES: 'RESET_BRAND_PAYMENTS_ACTIVE_CURRENCIES',

  GET_BRAND_PAYMENT_METHOD_TAGS: 'GET_BRAND_PAYMENT_METHOD_TAGS',
  SAVE_BRAND_PAYMENT_METHOD_TAGS: 'SAVE_BRAND_PAYMENT_METHOD_TAGS',
  RESET_BRAND_PAYMENT_METHOD_TAGS: 'RESET_BRAND_PAYMENT_METHOD_TAGS',
  EDIT_BRAND_PAYMENT_METHOD_TAGS: 'EDIT_BRAND_PAYMENT_METHOD_TAGS',
};

export default BrandPaymentActionType;
