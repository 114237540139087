import BrandSettingActionType from 'actions/brandSetting/brandSettingActionType';

const initialState = {
  loading: false,
  currencies: [],
  unusedCurrencies: [],
  countries: [],
  unusedCountries: [],
  languages: [],
  unusedLanguages: [],
  successStatus: false,
  main: {
    country: {},
    language: {},
    currency: {},
  },
  domains: [],
  sportDomains: [],
  smsSettings: {},
  smtpSettings: {},
  generalSettings: {},
  captchaSettings: {},
  emailContacts: [],
  conversions: {
    deposits: [],
    withdrawals: [],
    others: [],
    conversion: {},
    logs: [],
  },
  standardSettings: {},
  restrictions: {
    blockedCountriesResource: [],
    blockedIPs: [],
  },
  sportsBookLayouts: {},
  sportsBookLayoutsEndedStatus: false,
  sportsBookSettings: {},
  preferencesSettings: {},
  sportsBookWalletsSettings: {},
  casinoProductSettings: {},
  casinoProductWalletsSettings: {},
  currencyFormat: {},
  inactivity: {},
  sportStatusSettings: [],
};

const brandSettingReducer = (state = initialState, { type, payload }) => {
  switch (type) {
  case BrandSettingActionType.SAVE_BRAND_CURRENCIES:
    return {
      ...state,
      currencies: payload.data,
    };
  case BrandSettingActionType.RESET_BRAND_CURRENCIES:
    return {
      ...state,
      currencies: [],
    };
  case BrandSettingActionType.BRAND_SETTING_LOADER:
    return {
      ...state,
      loading: payload.loading,
    };
  case BrandSettingActionType.SAVE_BRAND_COUNTRIES:
    return {
      ...state,
      countries: payload.data,
    };
  case BrandSettingActionType.RESET_BRAND_COUNTRIES:
    return {
      ...state,
      countries: [],
    };
  case BrandSettingActionType.SAVE_BRAND_LANGUAGES:
    return {
      ...state,
      languages: payload.data,
    };
  case BrandSettingActionType.RESET_BRAND_LANGUAGES:
    return {
      ...state,
      languages: [],
    };
  case BrandSettingActionType.CHANGE_SUCCESS_STATUS:
    return {
      ...state,
      successStatus: payload.status,
    };
  case BrandSettingActionType.SET_BRAND_MAIN_SETTING:
    return {
      ...state,
      main: payload.data,
    };
  case BrandSettingActionType.SAVE_DOMAINS:
    return {
      ...state,
      domains: payload.data,
    };
  case BrandSettingActionType.SAVE_SPORT_DOMAINS:
    return {
      ...state,
      sportDomains: payload.data,
    };
  case BrandSettingActionType.SAVE_BRAND_SMS_SETTINGS:
    return {
      ...state,
      smsSettings: payload.data,
    };
  case BrandSettingActionType.SAVE_BRAND_GENERAL_SETTINGS:
    return {
      ...state,
      generalSettings: payload.data,
    };
  case BrandSettingActionType.SAVE_BRAND_SMTP_SETTINGS:
    return {
      ...state,
      smtpSettings: payload.data,
    };
  case BrandSettingActionType.RESET_BRAND_SMS_SETTINGS:
    return {
      ...state,
      smsSettings: {},
    };
  case BrandSettingActionType.RESET_BRAND_SMTP_SETTINGS:
    return {
      ...state,
      smtpSettings: {},
    };
  case BrandSettingActionType.RESET_BRAND_CAPTCHA_SETTINGS:
    return {
      ...state,
      captchaSettings: {},
    };
  case BrandSettingActionType.SAVE_BRAND_CAPTCHA_SETTINGS:
    return {
      ...state,
      captchaSettings: payload.data,
    };
  case BrandSettingActionType.SAVE_BRAND_EMAIL_CONTACTS:
    return {
      ...state,
      emailContacts: payload.data,
    };
  case BrandSettingActionType.RESET_BRAND_EMAIL_CONTACTS:
    return {
      ...state,
      emailContacts: [],
    };
  case BrandSettingActionType.SAVE_BRAND_DEPOSIT_CONVERSIONS:
    return {
      ...state,
      conversions: {
        ...state.conversion,
        deposits: payload.data,
      },
    };
  case BrandSettingActionType.SAVE_BRAND_WITHDRAWAL_CONVERSIONS:
    return {
      ...state,
      conversions: {
        ...state.conversion,
        withdrawals: payload.data,
      },
    };
  case BrandSettingActionType.SAVE_BRAND_OTHER_CONVERSIONS:
    return {
      ...state,
      conversions: {
        ...state.conversion,
        others: payload.data,
      },
    };
  case BrandSettingActionType.RESET_BRAND_DEPOSIT_CONVERSIONS:
    return {
      ...state,
      conversions: {
        ...state.conversion,
        deposits: [],
      },
    };
  case BrandSettingActionType.RESET_BRAND_WITHDRAWAL_CONVERSIONS:
    return {
      ...state,
      conversions: {
        ...state.conversion,
        withdrawals: [],
      },
    };
  case BrandSettingActionType.RESET_BRAND_OTHER_CONVERSIONS:
    return {
      ...state,
      conversions: {
        ...state.conversion,
        others: [],
      },
    };
  case BrandSettingActionType.SAVE_BRAND_CONVERSION:
    return {
      ...state,
      conversions: {
        ...state.conversions,
        conversion: payload.data,
      },
    };
  case BrandSettingActionType.RESET_BRAND_CONVERSION:
    return {
      ...state,
      conversions: {
        ...state.conversions,
        conversion: {},
      },
    };
  case BrandSettingActionType.SAVE_BRAND_CONVERSION_LOGS:
    return {
      ...state,
      conversions: {
        ...state.conversions,
        logs: payload.data,
      },
    };
  case BrandSettingActionType.RESET_BRAND_CONVERSION_LOGS:
    return {
      ...state,
      conversions: {
        ...state.conversions,
        logs: [],
      },
    };
  case BrandSettingActionType.SAVE_BRAND_STANDARD_SETTINGS:
    return {
      ...state,
      standardSettings: payload.data,
    };
  case BrandSettingActionType.RESET_BRAND_STANDARD_SETTINGS:
    return {
      ...state,
      standardSettings: {},
    };
  case BrandSettingActionType.SAVE_BRAND_BLOCKED_COUNTRIES_RESOURCE: {
    return {
      ...state,
      restrictions: {
        ...state.restrictions,
        blockedCountriesResource: payload.data,
      },
    };
  }
  case BrandSettingActionType.RESET_BRAND_BLOCKED_COUNTRIES_RESOURCE: {
    return {
      ...state,
      restrictions: {
        ...state.restrictions,
        blockedCountriesResource: [],
      },
    };
  }
  case BrandSettingActionType.SAVE_BRAND_BLOCKED_IPS: {
    return {
      ...state,
      restrictions: {
        ...state.restrictions,
        blockedIPs: payload.data,
      },
    };
  }
  case BrandSettingActionType.RESET_BRAND_BLOCKED_IPS: {
    return {
      ...state,
      restrictions: {
        ...state.restrictions,
        blockedIPs: [],
      },
    };
  }

  case BrandSettingActionType.SAVE_BRAND_SPORTSBOOK_LAYOUTS:
    return {
      ...state,
      sportsBookLayouts: payload.data,
    };
  case BrandSettingActionType.RESET_BRAND_SPORTSBOOK_LAYOUTS:
    return {
      ...state,
      sportsBookLayouts: {},
    };
  case BrandSettingActionType.CHANGE_BRAND_SPORTSBOOK_LAYOUTS_CALL_ENDED_STATUS:
    return {
      ...state,
      sportsBookLayoutsEndedStatus: payload.status,
    };
  case BrandSettingActionType.SAVE_BRAND_SPORTSBOOK_SETTINGS:
    return {
      ...state,
      sportsBookSettings: payload.data,
    };
  case BrandSettingActionType.RESET_BRAND_SPORTSBOOK_SETTINGS:
    return {
      ...state,
      sportsBookSettings: {},
    };
  case BrandSettingActionType.RESET_BRAND_SETTINGS:
    return initialState;
  case BrandSettingActionType.SAVE_BRAND_PREFERENCES_SETTINGS:
    return {
      ...state,
      preferencesSettings: payload.data,
    };
  case BrandSettingActionType.RESET_BRAND_PREFERENCES_SETTINGS:
    return {
      ...state,
      preferencesSettings: {},
    };
  case BrandSettingActionType.SAVE_BRAND_SPORTSBOOK_WALLETS_SETTINGS:
    return {
      ...state,
      sportsBookWalletsSettings: payload.data,
    };
  case BrandSettingActionType.RESET_BRAND_SPORTSBOOK_WALLETS_SETTINGS:
    return {
      ...state,
      sportsBookWalletsSettings: {},
    };
  case BrandSettingActionType.SAVE_BRAND_CASINO_PRODUCT_WALLETS_SETTINGS:
    return {
      ...state,
      casinoProductWalletsSettings: payload.data,
    };
  case BrandSettingActionType.RESET_BRAND_CASINO_PRODUCT_WALLETS_SETTINGS:
    return {
      ...state,
      casinoProductWalletsSettings: {},
    };
  case BrandSettingActionType.SAVE_BRAND_CASINO_PRODUCT_SETTINGS:
    return {
      ...state,
      casinoProductSettings: payload.data,
    };
  case BrandSettingActionType.RESET_BRAND_CASINO_PRODUCT_SETTINGS:
    return {
      ...state,
      casinoProductSettings: {},
    };
  case BrandSettingActionType.SAVE_BRAND_CURRENCY_FORMAT:
    return {
      ...state,
      currencyFormat: payload.data,
    };
  case BrandSettingActionType.RESET_BRAND_CURRENCY_FORMAT:
    return {
      ...state,
      currencyFormat: {},
    };
  case BrandSettingActionType.SAVE_BRAND_INACTIVITY_SETTINGS:
    return {
      ...state,
      inactivity: payload.data,
    };
  case BrandSettingActionType.RESET_BRAND_INACTIVITY_SETTINGS:
    return {
      ...state,
      inactivity: {},
    };
  case BrandSettingActionType.SAVE_BRAND_SPORT_STATUS_SETTINGS:
    return {
      ...state,
      sportStatusSettings: payload.data,
    };
  case BrandSettingActionType.RESET_BRAND_SPORT_STATUS_SETTINGS:
    return {
      ...state,
      sportStatusSettings: [],
    };
  default:
    return state;
  }
};

export default brandSettingReducer;
