import {
  put, call, takeLatest, all,
} from 'redux-saga/effects';
import NetworkService from 'services/networkService';
import { setErrorMessage } from 'actions/setting/settingActionCreator';
import {
  getLanguagesSuccess,
  changeSCLoadingState,
  getCountriesSuccess,
  getCurrenciesSuccess,
  getCitiesSuccess,
  getTimeZonesSuccess,
  saveOperationTypes,
  saveRegions,
} from 'actions/staticCollection/staticCollectionActionCreator';
import StaticCollectionActionType from 'actions/staticCollection/staticCollectionActionType';
import { getError } from 'utils/helpers';
import { AppConstants } from 'constants/index';

function* getLanguages({ payload }) {
  const { isLoading } = payload;
  try {
    const { BrandAdmin, Language } = AppConstants.api;
    if (isLoading) {
      yield put(changeSCLoadingState(true));
    }
    const response = yield call(NetworkService.makeAPIGetRequest, [BrandAdmin, Language]);
    yield put(getLanguagesSuccess(response.data));
    if (isLoading) {
      yield put(changeSCLoadingState(false));
    }
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    if (isLoading) {
      yield put(changeSCLoadingState(false));
    }
  }
}

function* getCurrencies({ payload }) {
  const { isLoading } = payload;
  try {
    const { BrandAdmin, Currency } = AppConstants.api;
    if (isLoading) {
      yield put(changeSCLoadingState(true));
    }
    const response = yield call(NetworkService.makeAPIGetRequest, [BrandAdmin, Currency]);
    yield put(getCurrenciesSuccess(response.data));
    if (isLoading) {
      yield put(changeSCLoadingState(false));
    }
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    if (isLoading) {
      yield put(changeSCLoadingState(false));
    }
  }
}

function* getCountries({ payload }) {
  const { isLoading } = payload;
  try {
    const { BrandAdmin, Country } = AppConstants.api;
    if (isLoading) {
      yield put(changeSCLoadingState(true));
    }
    const response = yield call(NetworkService.makeAPIGetRequest, [BrandAdmin, Country]);
    yield put(getCountriesSuccess(response.data));
    if (isLoading) {
      yield put(changeSCLoadingState(false));
    }
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    if (isLoading) {
      yield put(changeSCLoadingState(false));
    }
  }
}

function* getCities({ payload }) {
  const { isLoading } = payload;
  try {
    const { BrandAdmin, BrandCountry, GetCities } = AppConstants.api;
    if (isLoading) {
      yield put(changeSCLoadingState(true));
    }
    const response = yield call(NetworkService.makeAPIGetRequest, [BrandAdmin, BrandCountry, GetCities], payload);
    yield put(getCitiesSuccess(response.data));
    if (isLoading) {
      yield put(changeSCLoadingState(false));
    }
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    if (isLoading) {
      yield put(changeSCLoadingState(false));
    }
  }
}

function* getTimeZones({ payload }) {
  const { isLoading } = payload;
  try {
    const { BrandAdmin, Brand, TimeZones } = AppConstants.api;
    if (isLoading) {
      yield put(changeSCLoadingState(true));
    }
    const { data } = yield call(NetworkService.makeAPIGetRequest, [BrandAdmin, Brand, TimeZones]);
    yield put(getTimeZonesSuccess(data));
    if (isLoading) {
      yield put(changeSCLoadingState(false));
    }
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    if (isLoading) {
      yield put(changeSCLoadingState(false));
    }
  }
}

function* getOperationTypes({ payload }) {
  const { isLoading } = payload;
  try {
    if (isLoading) {
      yield put(changeSCLoadingState(true));
    }
    const { ReportingAdmin, Knowledge, OperationTypes } = AppConstants.api;
    const { data } = yield call(NetworkService.makeAPIGetRequest, [ReportingAdmin, Knowledge, OperationTypes]);
    yield put(saveOperationTypes(data));
    if (isLoading) {
      yield put(changeSCLoadingState(false));
    }
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    if (isLoading) {
      yield put(changeSCLoadingState(false));
    }
  }
}

function* getBrandRegions({ payload }) {
  const { params, isLoading } = payload;
  const options = {
    params,
  };
  try {
    if (isLoading) {
      yield put(changeSCLoadingState(true));
    }
    const { BrandAdmin, BrandCountry, Regions } = AppConstants.api;
    const { data } = yield call(NetworkService.makeAPIGetRequest, [BrandAdmin, BrandCountry, Regions], options);
    yield put(saveRegions(data));
    if (isLoading) {
      yield put(changeSCLoadingState(false));
    }
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    if (isLoading) {
      yield put(changeSCLoadingState(false));
    }
  }
}

export default function* staticCollectionSaga() {
  yield all([
    takeLatest(StaticCollectionActionType.GET_COUNTRIES, getCountries),
    takeLatest(StaticCollectionActionType.GET_CURRENCIES, getCurrencies),
    takeLatest(StaticCollectionActionType.GET_LANGUAGES, getLanguages),
    takeLatest(StaticCollectionActionType.GET_CITIES, getCities),
    takeLatest(StaticCollectionActionType.GET_TIME_ZONES, getTimeZones),
    takeLatest(StaticCollectionActionType.GET_OPERATION_TYPES, getOperationTypes),
    takeLatest(StaticCollectionActionType.GET_REGIONS, getBrandRegions),
  ]);
}
