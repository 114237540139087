import {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import PropTypes from 'prop-types';
import {
  Checkbox, Grid, Popover, Tabs, Tooltip,
} from 'antd';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import IconSvg from 'components/common/icon/IconSvg';
import {
  StyledAdminTabs, StyledSubTabAddButtonWrapper, StyledTabItem, StyledTabItemText, StyledDynamicSubTabs,
} from 'components/styledComponents/tabs/Tabs.styled';
import { FilterService } from 'services';
import { arrayMove } from '@dnd-kit/sortable';
import _ from 'lodash';
import { StyledManageTabItemsButton } from 'components/styledComponents/button/Button.styled';
import { StyledPopoverWithCheckboxes } from 'components/styledComponents/popover/Popover.styled';
import { StyledIconDefault } from 'components/styledComponents/icon/Icon.styled';
import DraggableTabContext from './DraggableTabContext';

const tabTypes = ['line', 'editable-card'];
const { useBreakpoint } = Grid;

const AdminTabs = (props) => {
  const {
    tabs, tabChange, sharedProps, translationNamespace, userTabConfigs, createOrEditUserConfig, tabKey, isPlayerPage, width, height, isSubTab, isSubQuery,
  } = props;

  const screens = useBreakpoint();
  const isMobile = !screens.md;

  const [itemList, setItemList] = useState([]);
  const [visible, setVisible] = useState(false);
  const [addingItems, setAddingItems] = useState([]);

  const { search } = useLocation();

  const { t } = useTranslation();

  const { getQuery, setQuery } = FilterService;

  const mainQuery = useMemo(() => getQuery(search), [search, getQuery]);
  const { inside } = mainQuery;
  const query = useMemo(() => (inside ? JSON.parse(inside) : mainQuery), [mainQuery, inside]);

  // type prop is used in case of sub tab
  const { tab, type } = isSubQuery ? query : mainQuery;

  const key = useMemo(() => (isSubTab ? type : tab), [type, tab, isSubTab]);

  const tabsMapping = useMemo(
    () => tabs.reduce((res, el) => {
      res[el.title] = el;
      return res;
    }, {}),
    [tabs],
  );

  const activeKey = useMemo(() => {
    // if key - tab or type exists
    // const element = tabs.find((el) => el.title === key && !el.hide);
    if (tabsMapping[key] && !tabsMapping[key].hide) {
      return tabsMapping[key].title;
    }

    if (!_.isEmpty(userTabConfigs)) {
      const item = userTabConfigs.find(({ checked, title }) => checked && tabsMapping[title] && !tabsMapping[title].hide);

      if (item) {
        return item.title;
      }
    }

    return Object.keys(tabsMapping).find((title) => tabsMapping[title] && !tabsMapping[title].hide);
  }, [key, userTabConfigs, tabsMapping]);

  const [selected, setSelected] = useState(activeKey);

  useEffect(() => {
    setSelected(activeKey);
  }, [activeKey]);

  const handleTabChange = useCallback(
    (activeTab) => {
      if (activeTab !== key) {
        if (!_.isUndefined(tabChange)) {
          return tabChange?.(activeTab);
        }
        setSelected(activeTab);

        const newQueryParams = isSubTab
          ? {
            tab,
            type: activeTab,
          }
          : { tab: activeTab };

        return setQuery(
          isSubQuery
            ? {
              ...mainQuery,
              inside: JSON.stringify(newQueryParams),
            }
            : newQueryParams,
          false,
        );
      }
    },
    [mainQuery, setQuery, key, tabChange, isSubTab, tab, isSubQuery],
  );

  const saveConfig = useCallback(
    (panes) => {
      if (!tabKey) {
        return;
      }
      const configValue = panes.map(({ title, checked }) => ({ title, checked }));
      createOrEditUserConfig?.({ key: tabKey, configValue }, false);
    },
    [createOrEditUserConfig, tabKey],
  );

  const getItem = useCallback(
    (item = {}, closable) => {
      const itemDetails = isSubTab
        ? {
          ...item,
          icon: (
            <div>
              <StyledIconDefault>
                <IconSvg icon={item.icon} width={item.iconWidth || width} height={item.iconHeight || height} />
              </StyledIconDefault>
            </div>
          ),
          label: <p>{t(translationNamespace ? `${translationNamespace}:${item.title}` : item.title)}</p>,
        }
        : {
          label: (
            <StyledTabItem>
              {item.icon && <IconSvg icon={item.icon} color="" height="1.14rem" />}
              <StyledTabItemText isLowercase={item.isLowercase ? 0 : 1}>{t(translationNamespace ? `${translationNamespace}:${item.title}` : item.title)}</StyledTabItemText>
            </StyledTabItem>
          ),
          children: <item.content {...sharedProps} tab={selected} />,
        };

      return {
        title: item.title,
        checked: item.checked,
        closable: selected !== item.title && closable,
        closeIcon: (
          <Tooltip title={t('remove')} arrow={{ pointAtCenter: true }}>
            <div>
              <IconSvg icon="CloseIconSolid" width="0.71rem" height="0.71rem" />
            </div>
          </Tooltip>
        ),
        ...itemDetails,
      };
    },
    [isSubTab, width, height, t, translationNamespace, sharedProps, selected],
  );

  // first time
  useEffect(() => {
    let panes = [];
    if (_.isEmpty(userTabConfigs)) {
      panes = tabs.filter(({ hide }) => !hide).map((el) => ({ ...el, checked: true }));
    } else {
      let newItems = [];
      // different counts
      if (_.countBy(tabs, (el) => !!el.hide).false !== userTabConfigs.length) {
        newItems = _.differenceBy(tabs, userTabConfigs, 'title').map((el) => ({ ...el, checked: true }));
      }

      panes = userTabConfigs.reduce((res, el) => {
        if (tabsMapping[el.title] && !tabsMapping[el.title].hide) {
          res.push({ ...tabsMapping[el.title], checked: el.checked });
        }
        return res;
      }, newItems);
    }

    setItemList(panes.map((row) => getItem(row, _.countBy(panes, (el) => el.checked).true !== 1)));
  }, [userTabConfigs, tabs, getItem, tabsMapping]);

  const onDragEnd = ({ active, over }) => {
    if (active.id !== over?.id) {
      setItemList((prev) => {
        const activeIndex = prev.findIndex((i) => i.title === active.id);
        const overIndex = prev.findIndex((i) => i.title === over?.id);
        const newPanes = arrayMove(prev, activeIndex, overIndex);
        saveConfig(newPanes);
        return newPanes;
      });
    }
  };

  const remove = (targetKey) => {
    setItemList((prev) => {
      const newPanes = prev.map((pane) => (pane.title !== targetKey ? pane : { ...pane, checked: false }));

      saveConfig(newPanes);
      return newPanes?.length === 1 ? [{ ...newPanes[0], closable: false }] : newPanes;
    });
  };

  const onEdit = (targetKey, action) => {
    if (action !== 'add') {
      remove(targetKey);
    }
  };

  const handleAdd = () => {
    const newPanes = userTabConfigs.reduce((res, el) => {
      res.push(getItem({ ...tabsMapping[el.title], checked: addingItems.includes(el.title) || el.checked }, true));
      return res;
    }, []);
    setItemList(newPanes);
    saveConfig(newPanes);
  };

  // temporary tab include in list
  useEffect(() => {
    if (key && !_.isEmpty(userTabConfigs) && userTabConfigs.some((el) => el.title === key && !el.checked) && !tabsMapping[key]?.hide) {
      // reset tab to avoid temporary logic
      handleTabChange(key);
      setItemList((prev) => {
        const newPanes = prev.map((el) => (el.title === key ? { ...el, checked: true } : el));
        saveConfig(newPanes);
        return newPanes;
      });
    }
  }, [key, userTabConfigs, saveConfig, handleTabChange, tabsMapping]);

  const handleOpenChange = (open) => {
    if (!open) {
      if (!_.isEmpty(addingItems)) {
        handleAdd();
      }
      setAddingItems([]);
    }
    setVisible(open);
  };

  const preparedTabs = useMemo(
    () => itemList.reduce((res, el) => {
      if (el.checked) {
        res.push({
          ...el,
          key: el.title,
        });
      }
      return res;
    }, []),
    [itemList],
  );

  const getExtraContent = () => {
    const visibleItems = preparedTabs.map(({ key: val }) => val);
    if (_.isEmpty(userTabConfigs) || _.isEmpty(visibleItems) || visibleItems.length === tabs.length) {
      return;
    }

    const panes = tabs.filter(({ hide, title }) => !hide && !visibleItems.includes(title));

    const options = panes.map((row) => ({
      value: row.title,
      label: <p>{t(translationNamespace ? `${translationNamespace}:${row.title}` : row.title)}</p>,
    }));

    const content = (
      <StyledPopoverWithCheckboxes>
        <Checkbox.Group options={options} value={addingItems} onChange={setAddingItems} />
      </StyledPopoverWithCheckboxes>
    );

    const addContent = isSubTab ? (
      <StyledSubTabAddButtonWrapper isPlayerPage={isPlayerPage}>
        <StyledManageTabItemsButton size="medium" hidemobile="true">
          <IconSvg icon="BigAddIcon" width="1.3rem" height="1.3rem" />
        </StyledManageTabItemsButton>
        {t('add')}
      </StyledSubTabAddButtonWrapper>
    ) : (
      <StyledManageTabItemsButton size="medium" hidemobile="true">
        <IconSvg icon="BigAddIcon" width="1.14rem" height="1.14rem" />
      </StyledManageTabItemsButton>
    );

    return (
      <Popover placement="bottomRight" content={content} trigger="click" open={visible} onOpenChange={handleOpenChange} getPopupContainer={(trigger) => trigger.parentNode}>
        {addContent}
      </Popover>
    );
  };

  const adminTabs = (
    <Tabs
      hideAdd
      onChange={handleTabChange}
      activeKey={selected}
      items={preparedTabs}
      onEdit={onEdit}
      type={tabTypes[+!!tabKey]}
      destroyInactiveTabPane
      tabBarExtraContent={preparedTabs.length !== tabs.length ? { right: getExtraContent() } : undefined}
      renderTabBar={
        tabKey && !isMobile && preparedTabs.length !== 1
          ? (tabBarProps, DefaultTabBar) => <DraggableTabContext items={preparedTabs.map((i) => i.key)} onDragEnd={onDragEnd} tabBarProps={tabBarProps} DefaultTabBar={DefaultTabBar} />
          : null
      }
    />
  );

  return isSubTab ? (
    <StyledDynamicSubTabs color="blue" colorText="blue" isPlayerPage={isPlayerPage}>
      {adminTabs}
    </StyledDynamicSubTabs>
  ) : (
    <StyledAdminTabs isPlayerPage={isPlayerPage}>{adminTabs}</StyledAdminTabs>
  );
};

AdminTabs.propTypes = {
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      icon: PropTypes.string,
    }),
  ).isRequired,
  tabChange: PropTypes.func,
  sharedProps: PropTypes.object,
  translationNamespace: PropTypes.string,
  userTabConfigs: PropTypes.array,
  createOrEditUserConfig: PropTypes.func,
  tabKey: PropTypes.string,
  isPlayerPage: PropTypes.bool,
  isSubTab: PropTypes.bool,
  width: PropTypes.string,
  height: PropTypes.string,
  isSubQuery: PropTypes.bool,
};

AdminTabs.defaultProps = {
  tabChange: undefined,
  sharedProps: {},
  translationNamespace: 'title',
  userTabConfigs: [],
  tabKey: '',
  isPlayerPage: false,
  isSubTab: false,
  width: '1.43rem',
  height: '1.43rem',
  isSubQuery: false,
  createOrEditUserConfig: null,
};

export default AdminTabs;
