import FileManagerActionType from 'actions/fileManager/fileManagerActionType';

const initialState = {
  loading: false,
  allDirectoriesLoading: false,
  directoriesAndFilesLoading: false,
  baseDirectories: [],
  directoriesAndFiles: [],
  fileTypeGroups: [],
  modalVisible: false,
  popoverVisible: false,
  brandId: null,
  endedStatus: false,
  directoriesHierarchy: {},
  filesByPaths: [],
  uploadedFile: {},
};

const fileManagerReducer = (state = initialState, { type, payload }) => {
  switch (type) {
  case FileManagerActionType.SAVE_DIRECTORIES_AND_FILES:
    return {
      ...state,
      loading: false,
      directoriesAndFiles: payload.data,
    };
  case FileManagerActionType.SAVE_BASE_DIRECTORIES_AND_FILES:
    return {
      ...state,
      baseDirectories: payload.data,
    };
  case FileManagerActionType.CHANGE_DIRECTORIES_AND_FILES_LOADING_STATE: {
    return {
      ...state,
      directoriesAndFilesLoading: payload.state,
    };
  }
  case FileManagerActionType.CHANGE_ALL_DIRECTORIES_LOADING_STATE: {
    return {
      ...state,
      allDirectoriesLoading: payload.state,
    };
  }
  case FileManagerActionType.CHANGE_FILE_MANAGER_LOADING_STATE:
    return {
      ...state,
      loading: payload.state,
    };
  case FileManagerActionType.GET_FILE_TYPE_GROUPS_SUCCESS:
    return {
      ...state,
      fileTypeGroups: payload.data,
      loading: false,
    };
  case FileManagerActionType.CHANGE_FILE_MANAGER_MODAL_VISIBILITY:
    return {
      ...state,
      modalVisible: payload.state,
    };
  case FileManagerActionType.CHANGE_FILE_MANAGER_BRAND_ID:
    return {
      ...state,
      brandId: payload.brandId,
    };
  case FileManagerActionType.CHANGE_FILE_MANAGER_ENDED_STATUS:
    return {
      ...state,
      endedStatus: payload.state,
    };
  case FileManagerActionType.GET_DIRECTORIES_HIERARCHY_SUCCESS:
    return {
      ...state,
      directoriesHierarchy: payload.data,
    };
  case FileManagerActionType.RESET_DIRECTORIES_HIERARCHY:
    return {
      ...state,
      directoriesHierarchy: {},
    };
  case FileManagerActionType.CHANGE_FILE_MANAGER_POPOVER_VISIBILITY:
    return {
      ...state,
      popoverVisible: payload.state,
    };
  case FileManagerActionType.RESET_FILE_MANAGER_STATE:
    return initialState;
  case FileManagerActionType.SAVE_FILES_BY_PATHS:
    return {
      ...state,
      filesByPaths: payload.data,
    };
  case FileManagerActionType.RESET_FILES_BY_PATHS:
    return {
      ...state,
      filesByPaths: [],
    };
  case FileManagerActionType.SAVE_UPLOADED_FILE:
    return {
      ...state,
      uploadedFile: payload.data,
    };
  case FileManagerActionType.RESET_UPLOADED_FILE:
    return {
      ...state,
      uploadedFile: {},
    };
  default:
    return state;
  }
};

export default fileManagerReducer;
