import {
  all, call, put, select, takeLatest,
} from '@redux-saga/core/effects';
import qs from 'qs';
import mime from 'mimetypes';
import CashbackActionType from 'actions/cashBack/cashBackActionType';
import { AppConstants } from 'constants/index';
import { downloadFile } from 'utils/utils';
import {
  changeLoadingState, setErrorMessage, setSuccessMessage, changeEndedStatus,
} from 'actions/setting/settingActionCreator';
import NetworkService from 'services/networkService';
import {
  saveCashBacks,
  saveCashBack,
  saveCashBackAccumulated,
  saveCashBackTransferred,
  saveCashBackPlayers,
  saveCashBackIncludedPlayers,
  savePlayerCashBacks,
  saveCashBackFailed,
  saveCashBackStatisticsDetails,
  saveCashBackPeriodsResource,
  savePlayerAccountCashBackPeriods,
  savePlayerAccumulatedPeriods,
  savePlayerTransferredPeriods,
  savePlayerFailedPeriods,
} from 'actions/cashBack/cashBackActionCreator';
import {
  selectPlayerAccumulatedPeriods, selectPlayerAccountCashBackPeriods, selectPlayerTransferredPeriods, selectPlayerFailedPeriods,
} from 'selectors';
import enumTypes from 'constants/enumTypes';
import { i18n } from 'services';
import { getError, getMessage } from 'utils/helpers';

function* createCashBack({ payload }) {
  try {
    const { name } = payload.data;
    const { DataCollector, Cashback } = AppConstants.api;
    yield put(changeLoadingState(true));
    yield call(NetworkService.makeAPIPostRequest, [DataCollector, Cashback], payload);
    yield put(setSuccessMessage(getMessage(i18n.t('lCashback'), i18n.t('notification:slCreated'), name)));
    yield put(changeLoadingState(false));
    yield put(changeEndedStatus(true));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getCashBacks({ payload }) {
  try {
    const { id, params } = payload;
    const options = {
      params,
      paramsSerializer: (param) => qs.stringify(param),
    };
    const { DataCollector, Cashback, ByBrand } = AppConstants.api;
    yield put(changeLoadingState(true));
    const response = yield call(NetworkService.makeAPIGetRequest, [DataCollector, Cashback, ByBrand, id], options);
    yield put(saveCashBacks(response.data));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getCashBack({ payload }) {
  try {
    const { id, params } = payload;
    const { DataCollector, Cashback } = AppConstants.api;
    yield put(changeLoadingState(true));
    const response = yield call(NetworkService.makeAPIGetRequest, [DataCollector, Cashback, id], { params });
    yield put(saveCashBack(response.data));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err, true);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* editCashBack({ payload }) {
  try {
    const { name } = payload.data;
    yield put(changeLoadingState(true));
    const { DataCollector, Cashback } = AppConstants.api;
    yield call(NetworkService.makeAPIPutRequest, [DataCollector, Cashback], payload);
    yield put(changeEndedStatus(true));
    yield put(setSuccessMessage(getMessage(i18n.t('lCashback'), i18n.t('notification:slUpdated'), name)));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* deleteCashBack({ payload }) {
  try {
    const { id, name } = payload.data;
    yield put(changeLoadingState(true));
    const { DataCollector, Cashback } = AppConstants.api;
    yield call(NetworkService.makeAPIDeleteRequest, [DataCollector, Cashback, id]);
    yield put(changeEndedStatus(true));
    yield put(setSuccessMessage(getMessage(i18n.t('lCashback'), i18n.t('notification:slDeleted'), name)));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* enableCashBack({ payload }) {
  try {
    const { id, data } = payload;
    const { name } = data;
    yield put(changeLoadingState(true));
    const { DataCollector, Cashback, Enable } = AppConstants.api;
    yield call(NetworkService.makeAPIPutRequest, [DataCollector, Cashback, Enable, id]);
    yield put(changeEndedStatus(true));
    yield put(setSuccessMessage(getMessage(i18n.t('lCashback'), i18n.t('notification:slEnabled'), name)));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* disableCashBack({ payload }) {
  try {
    const { id, data } = payload;
    const { name } = data;
    yield put(changeLoadingState(true));
    const { DataCollector, Cashback, Disable } = AppConstants.api;
    yield call(NetworkService.makeAPIPutRequest, [DataCollector, Cashback, Disable, id]);
    yield put(changeEndedStatus(true));
    yield put(setSuccessMessage(getMessage(i18n.t('lCashback'), i18n.t('notification:slDisabled'), name)));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* finishCashBack({ payload }) {
  try {
    const { id, data } = payload;
    const { name } = data;
    yield put(changeLoadingState(true));
    const { DataCollector, Cashback, Finish } = AppConstants.api;
    yield call(NetworkService.makeAPIPutRequest, [DataCollector, Cashback, Finish, id]);
    yield put(changeEndedStatus(true));
    yield put(setSuccessMessage(getMessage(i18n.t('lCashback'), i18n.t('notification:slFinished'), name)));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getCashBackStatistics({ payload }) {
  try {
    const { cashbackId, params } = payload;
    const { periodStatus } = params;
    const options = {
      params,
      paramsSerializer: (param) => qs.stringify(param),
    };
    const { DataCollector, Cashback, Statistics } = AppConstants.api;
    yield put(changeLoadingState(true));
    const { data } = yield call(NetworkService.makeAPIGetRequest, [DataCollector, Cashback, Statistics, cashbackId], options);
    let saveFunc = () => {};
    switch (periodStatus) {
    case enumTypes.cashBackReferralPeriodStatus.Transferred: {
      saveFunc = saveCashBackTransferred;
      break;
    }
    case enumTypes.cashBackReferralPeriodStatus.Failed: {
      saveFunc = saveCashBackFailed;
      break;
    }
    default: {
      saveFunc = saveCashBackAccumulated;
      break;
    }
    }
    yield put(saveFunc(data));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err, true);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getPlayerAccountCashBackPeriods({ payload }) {
  try {
    const { cashbackId, playerId } = payload;
    const { DataCollector, Cashback, ByPlayerPeriods } = AppConstants.api;
    yield put(changeLoadingState(true));
    const { data } = yield call(NetworkService.makeAPIGetRequest, [DataCollector, Cashback, ByPlayerPeriods, cashbackId, playerId]);
    const { cashBack } = yield select();

    const periods = selectPlayerAccountCashBackPeriods(cashBack);
    periods[cashbackId] = data;
    yield put(savePlayerAccountCashBackPeriods(periods));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getPlayerCashBackPeriods({ payload }) {
  try {
    const { cashbackId, playerId, periodStatus } = payload;
    const options = {
      params: { periodStatus },
    };
    const { DataCollector, Cashback, Periods } = AppConstants.api;
    yield put(changeLoadingState(true));
    const { data: cashbackPeriods } = yield call(NetworkService.makeAPIGetRequest, [DataCollector, Cashback, Periods, cashbackId, playerId], options);
    const { cashBack } = yield select();

    let saveFunc = () => {};
    let selectFunc = () => {};
    switch (periodStatus) {
    case enumTypes.cashBackReferralPeriodStatus.Transferred: {
      selectFunc = selectPlayerTransferredPeriods;
      saveFunc = savePlayerTransferredPeriods;
      break;
    }
    case enumTypes.cashBackReferralPeriodStatus.Failed: {
      selectFunc = selectPlayerFailedPeriods;
      saveFunc = savePlayerFailedPeriods;
      break;
    }
    default: {
      selectFunc = selectPlayerAccumulatedPeriods;
      saveFunc = savePlayerAccumulatedPeriods;
      break;
    }
    }

    const data = selectFunc(cashBack);
    data[playerId] = cashbackPeriods;
    yield put(saveFunc(data));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getCashBackPlayers({ payload }) {
  try {
    const { cashbackId, params } = payload;
    const options = {
      params,
      paramsSerializer: (param) => qs.stringify(param),
    };
    const { DataCollector, Cashback, IncludedPlayer } = AppConstants.api;
    yield put(changeLoadingState(true));
    const { data } = yield call(NetworkService.makeAPIGetRequest, [DataCollector, Cashback, IncludedPlayer, cashbackId], options);
    yield put(saveCashBackPlayers(data));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err, true);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* deleteIncludedPlayer({ payload }) {
  try {
    const { id, params } = payload;
    const { userName, cashbackId } = params;
    yield put(changeLoadingState(true));
    const { DataCollector, Cashback, IncludedPlayer } = AppConstants.api;
    yield call(NetworkService.makeAPIDeleteRequest, [DataCollector, Cashback, IncludedPlayer, id], { params: { cashbackId } });
    yield put(changeEndedStatus(true));
    yield put(setSuccessMessage(i18n.t('notification:playerRemovedFromRestrictionList', { name: userName })));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* transferCashBack({ payload }) {
  try {
    const { cashbackId, data } = payload;
    yield put(changeLoadingState(true));
    const { DataCollector, Cashback, Transfer } = AppConstants.api;
    yield call(NetworkService.makeAPIPutRequest, [DataCollector, Cashback, Transfer, cashbackId], { data });
    yield put(changeEndedStatus(true));
    yield put(setSuccessMessage(getMessage(i18n.t('bonus:playersCashBack'), i18n.t('notification:slSent'))));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getCashBackIncludedPlayers({ payload }) {
  try {
    const { cashbackId } = payload;
    const { DataCollector, Cashback, ManageIncludedPlayer } = AppConstants.api;
    yield put(changeLoadingState(true));
    const { data } = yield call(NetworkService.makeAPIGetRequest, [DataCollector, Cashback, ManageIncludedPlayer, cashbackId]);
    yield put(saveCashBackIncludedPlayers(data));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* updateCashBackIncludedPlayers({ payload }) {
  try {
    const { cashbackId, params } = payload;
    const options = {
      params,
      paramsSerializer: (param) => qs.stringify(param),
    };
    yield put(changeLoadingState(true));
    const { DataCollector, Cashback, IncludedPlayer } = AppConstants.api;
    yield call(NetworkService.makeAPIPutRequest, [DataCollector, Cashback, IncludedPlayer, cashbackId], options);
    yield put(changeEndedStatus(true));
    yield put(setSuccessMessage(getMessage(i18n.t('bonus:playersCashBack'), i18n.t('notification:slUpdated'))));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getPlayerCashBacks({ payload }) {
  try {
    const { playerId, params } = payload;
    const options = {
      params,
      paramsSerializer: (param) => qs.stringify(param),
    };
    const { DataCollector, Cashback, ByPlayer } = AppConstants.api;
    yield put(changeLoadingState(true));
    const response = yield call(NetworkService.makeAPIGetRequest, [DataCollector, Cashback, ByPlayer, playerId], options);
    yield put(savePlayerCashBacks(response.data));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* resendCashBackPeriods({ payload }) {
  try {
    const { cashbackId, data } = payload;
    yield put(changeLoadingState(true));
    const {
      DataCollector, Cashback, Periods, TransferFailed,
    } = AppConstants.api;
    yield call(NetworkService.makeAPIPutRequest, [DataCollector, Cashback, Periods, TransferFailed, cashbackId], { data });
    yield put(changeEndedStatus(true));
    yield put(setSuccessMessage(getMessage(i18n.t('bonus:theCashback'), i18n.t('notification:slResent'))));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* deleteCashBackPeriods({ payload }) {
  try {
    const { cashbackId, params } = payload;
    const options = {
      params,
      paramsSerializer: (param) => qs.stringify(param),
    };
    yield put(changeLoadingState(true));
    const {
      DataCollector, Cashback, Periods, Failed,
    } = AppConstants.api;
    yield call(NetworkService.makeAPIDeleteRequest, [DataCollector, Cashback, Periods, Failed, cashbackId], options);
    yield put(changeEndedStatus(true));
    yield put(setSuccessMessage(getMessage(i18n.t('bonus:theCashback'), i18n.t('notification:slDeleted'))));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* deleteCashBackNewPeriods({ payload }) {
  try {
    const { cashbackId, params } = payload;
    const options = {
      params,
      paramsSerializer: (param) => qs.stringify(param),
    };
    yield put(changeLoadingState(true));
    const {
      DataCollector, Cashback, Periods, New,
    } = AppConstants.api;
    yield call(NetworkService.makeAPIDeleteRequest, [DataCollector, Cashback, Periods, New, cashbackId], options);
    yield put(changeEndedStatus(true));
    yield put(setSuccessMessage(getMessage(i18n.t('bonus:theCashback'), i18n.t('notification:slDeleted'))));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getCashBackStatisticsDetails({ payload }) {
  try {
    const { id, params } = payload;
    const options = {
      params,
      paramsSerializer: (param) => qs.stringify(param),
    };
    const {
      DataCollector, Cashback, Periods, Details,
    } = AppConstants.api;
    yield put(changeLoadingState(true));
    const response = yield call(NetworkService.makeAPIGetRequest, [DataCollector, Cashback, Periods, Details, id], options);
    yield put(saveCashBackStatisticsDetails(response.data));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err, true);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getCashBackPeriodsResource({ payload }) {
  const { id, params, isLoading } = payload;
  try {
    const options = {
      params,
    };
    const { DataCollector, Cashback, Periods } = AppConstants.api;
    if (isLoading) {
      yield put(changeLoadingState(true));
    }

    const response = yield call(NetworkService.makeAPIGetRequest, [DataCollector, Cashback, Periods, id], options);
    yield put(saveCashBackPeriodsResource(response.data));

    if (isLoading) {
      yield put(changeLoadingState(false));
    }
  } catch (err) {
    if (err) {
      const error = getError(err, true);
      yield put(setErrorMessage(error));
    }
    if (isLoading) {
      yield put(changeLoadingState(false));
    }
  }
}

function* exportCashBackStatistics({ payload }) {
  try {
    const { id, params } = payload;
    const options = {
      params,
      responseType: 'blob',
      paramsSerializer: (param) => qs.stringify(param, { allowDots: true }),
    };
    yield put(changeLoadingState(true));
    const { DataCollector, Cashback, Export } = AppConstants.api;
    const { data, headers } = yield call(NetworkService.makeAPIGetRequest, [DataCollector, Cashback, Export, id], options);
    downloadFile(data, i18n.t('bonus:cashbackStatistics'), mime.detectExtension(headers['content-type']));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* exportPlayerCashbacks({ payload }) {
  try {
    const { playerId, params } = payload;
    const options = {
      params,
      responseType: 'blob',
      paramsSerializer: (param) => qs.stringify(param, { allowDots: true }),
    };
    yield put(changeLoadingState(true));
    const {
      DataCollector, Cashback, ByPlayer, Export,
    } = AppConstants.api;
    const { data, headers } = yield call(NetworkService.makeAPIGetRequest, [DataCollector, Cashback, ByPlayer, playerId, Export], options);
    downloadFile(data, i18n.t('cashback'), mime.detectExtension(headers['content-type']));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

export default function* cashBackSaga() {
  yield all([
    takeLatest(CashbackActionType.CREATE_CASH_BACK, createCashBack),
    takeLatest(CashbackActionType.GET_CASH_BACKS, getCashBacks),
    takeLatest(CashbackActionType.GET_CASH_BACK, getCashBack),
    takeLatest(CashbackActionType.EDIT_CASH_BACK, editCashBack),
    takeLatest(CashbackActionType.DELETE_CASH_BACK, deleteCashBack),
    takeLatest(CashbackActionType.ENABLE_CASH_BACK, enableCashBack),
    takeLatest(CashbackActionType.DISABLE_CASH_BACK, disableCashBack),
    takeLatest(CashbackActionType.FINISH_CASH_BACK, finishCashBack),
    takeLatest(CashbackActionType.GET_CASH_BACK_STATISTICS, getCashBackStatistics),
    takeLatest(CashbackActionType.GET_PLAYER_CASH_BACK_PERIODS, getPlayerCashBackPeriods),
    takeLatest(CashbackActionType.GET_CASH_BACK_PLAYERS, getCashBackPlayers),
    takeLatest(CashbackActionType.DELETE_INCLUDED_PLAYER, deleteIncludedPlayer),
    takeLatest(CashbackActionType.TRANSFER_CASH_BACK, transferCashBack),
    takeLatest(CashbackActionType.DELETE_CASH_BACK_NEW_PERIODS, deleteCashBackNewPeriods),
    takeLatest(CashbackActionType.GET_CASH_BACK_INCLUDED_PLAYERS, getCashBackIncludedPlayers),
    takeLatest(CashbackActionType.UPDATE_CASH_BACK_INCLUDED_PLAYERS, updateCashBackIncludedPlayers),
    takeLatest(CashbackActionType.GET_PLAYER_CASH_BACKS, getPlayerCashBacks),
    takeLatest(CashbackActionType.GET_PLAYER_ACCOUNT_CASH_BACK_PERIODS, getPlayerAccountCashBackPeriods),
    takeLatest(CashbackActionType.RESEND_CASH_BACK_PERIODS, resendCashBackPeriods),
    takeLatest(CashbackActionType.DELETE_CASH_BACK_PERIODS, deleteCashBackPeriods),
    takeLatest(CashbackActionType.GET_CASH_BACK_STATISTICS_DETAILS, getCashBackStatisticsDetails),
    takeLatest(CashbackActionType.GET_CASH_BACK_PERIODS_RESOURCE, getCashBackPeriodsResource),
    takeLatest(CashbackActionType.EXPORT_CASH_BACK_STATISTICS, exportCashBackStatistics),
    takeLatest(CashbackActionType.EXPORT_PLAYER_CASH_BACKS, exportPlayerCashbacks),
  ]);
}
