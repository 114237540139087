const selectSoftwareChanges = (state) => state.softwareChanges;
export const selectSoftwareChangesData = (state) => selectSoftwareChanges(state).data;
export const selectSoftwareChangesTotal = (state) => selectSoftwareChanges(state).total;

const selectGameRatio = (state) => state.gameRatio;
export const selectGameRatioData = (state) => selectGameRatio(state).data;
export const selectGameRatioTotal = (state) => selectGameRatio(state).total;

const selectPlayerExclusion = (state) => state.playerExclusion;
export const selectPlayerExclusionData = (state) => selectPlayerExclusion(state).data;
export const selectPlayerExclusionTotal = (state) => selectPlayerExclusion(state).total;

const selectGameTypesReport = (state) => state.gameTypes;
export const selectGameTypesReportData = (state) => selectGameTypesReport(state).data;
export const selectGameTypesReportTotal = (state) => selectGameTypesReport(state).total;

const selectBalanceAdjustment = (state) => state.balanceAdjustment;
export const selectBalanceAdjustmentData = (state) => selectBalanceAdjustment(state).data;
export const selectBalanceAdjustmentTotal = (state) => selectBalanceAdjustment(state).total;

const selectSessions = (state) => state.sessions;
export const selectSessionsData = (state) => selectSessions(state).data;
export const selectSessionsTotal = (state) => selectSessions(state).total;

const selectSafeServer = (state) => state.safeServer;
export const selectSafeServerData = (state) => selectSafeServer(state).data;
export const selectSafeServerTotal = (state) => selectSafeServer(state).total;

const selectMirroringServer = (state) => state.mirroringServer;
export const selectMirroringServerData = (state) => selectMirroringServer(state).data;
export const selectMirroringServerTotal = (state) => selectMirroringServer(state).total;

const selectChangeNotification = (state) => state.changeNotification;
export const selectChangeNotificationData = (state) => selectChangeNotification(state).data;
export const selectChangeNotificationTotal = (state) => selectChangeNotification(state).total;

const selectInaccessibilityServer = (state) => state.inaccessibilityServer;
export const selectInaccessibilityServerData = (state) => selectInaccessibilityServer(state).data;
export const selectInaccessibilityServerTotal = (state) => selectInaccessibilityServer(state).total;

const selectSignificantEventsLargeTransactions = (state) => state.largeTransactions;
export const selectSignificantEventsLargeTransactionsData = (state) => selectSignificantEventsLargeTransactions(state).data;
export const selectSignificantEventsLargeTransactionsTotal = (state) => selectSignificantEventsLargeTransactions(state).total;

const selectSignificantEventsRestrictions = (state) => state.restrictions;
export const selectSignificantEventsRestrictionsData = (state) => selectSignificantEventsRestrictions(state).data;
export const selectSignificantEventsRestrictionsTotal = (state) => selectSignificantEventsRestrictions(state).total;

const selectJackpotWinRatio = (state) => state.jackpotWinRatio;
export const selectJackpotWinRatioData = (state) => selectJackpotWinRatio(state).data;
export const selectJackpotWinRatioTotal = (state) => selectJackpotWinRatio(state).total;

const selectJackpotConfiguration = (state) => state.jackpotConfiguration;
export const selectJackpotConfigurationData = (state) => selectJackpotConfiguration(state).data;
export const selectJackpotConfigurationTotal = (state) => selectJackpotConfiguration(state).total;

const selectPromotionalItems = (state) => state.promotionalItems;
export const selectPromotionalItemsData = (state) => selectPromotionalItems(state).data;
export const selectPromotionalItemsTotal = (state) => selectPromotionalItems(state).total;

const selectTaxationDaily = (state) => state.taxationDaily;
export const selectTaxationDailyData = (state) => selectTaxationDaily(state).data;
export const selectTaxationDailyTotal = (state) => selectTaxationDaily(state).total;

export const selectTaxationMonthly = (state) => state.taxationMonthly;
