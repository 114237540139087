import QuickSearchActionType from 'actions/quickSearch/quickSearchActionType';

const initialState = {
  searchResult: [],
  searchKey: '',
};

const quickSearchReducer = (state = initialState, { type, payload }) => {
  switch (type) {
  case QuickSearchActionType.SAVE_SEARCH_RESULT:
    return {
      ...state,
      searchResult: payload.data.searchResult,
      searchKey: payload.data.searchKey,
    };
  case QuickSearchActionType.RESET_SEARCH_RESULT:
    return {
      ...state,
      searchResult: [],
      searchKey: '',
    };
  default:
    return state;
  }
};

export default quickSearchReducer;
