const BonusActionType = {
  GET_CASINO_BONUSES: 'GET_CASINO_BONUSES',
  SAVE_CASINO_BONUSES: 'SAVE_CASINO_BONUSES',
  RESET_CASINO_BONUSES: 'RESET_CASINO_BONUSES',

  GET_SPIN_BONUSES: 'GET_SPIN_BONUSES',
  SAVE_SPIN_BONUSES: 'SAVE_SPIN_BONUSES',
  RESET_SPIN_BONUSES: 'RESET_SPIN_BONUSES',

  GET_SPORT_BONUSES: 'GET_SPORT_BONUSES',
  SAVE_SPORT_BONUSES: 'SAVE_SPORT_BONUSES',
  RESET_SPORT_BONUSES: 'RESET_SPORT_BONUSES',

  GET_SPECIAL_BONUSES: 'GET_SPECIAL_BONUSES',
  SAVE_SPECIAL_BONUSES: 'SAVE_SPECIAL_BONUSES',
  RESET_SPECIAL_BONUSES: 'RESET_SPECIAL_BONUSES',

  GET_PLAYER_CASINO_BONUSES: 'GET_PLAYER_CASINO_BONUSES',
  SAVE_PLAYER_CASINO_BONUSES: 'SAVE_PLAYER_CASINO_BONUSES',
  RESET_PLAYER_CASINO_BONUSES: 'RESET_PLAYER_CASINO_BONUSES',

  GET_PLAYER_SPIN_BONUSES: 'GET_PLAYER_SPIN_BONUSES',
  SAVE_PLAYER_SPIN_BONUSES: 'SAVE_PLAYER_SPIN_BONUSES',
  RESET_PLAYER_SPIN_BONUSES: 'RESET_PLAYER_SPIN_BONUSES',

  GET_PLAYER_SPORT_BONUSES: 'GET_PLAYER_SPORT_BONUSES',
  SAVE_PLAYER_SPORT_BONUSES: 'SAVE_PLAYER_SPORT_BONUSES',
  RESET_PLAYER_SPORT_BONUSES: 'RESET_PLAYER_SPORT_BONUSES',

  GET_PLAYER_SPECIAL_BONUSES: 'GET_PLAYER_SPECIAL_BONUSES',
  SAVE_PLAYER_SPECIAL_BONUSES: 'SAVE_PLAYER_SPECIAL_BONUSES',
  RESET_PLAYER_SPECIAL_BONUSES: 'RESET_PLAYER_SPECIAL_BONUSES',

  GET_CASINO_BONUS_STATISTICS: 'GET_CASINO_BONUS_STATISTICS',
  SAVE_CASINO_BONUS_STATISTICS: 'SAVE_CASINO_BONUS_STATISTICS',
  RESET_CASINO_BONUS_STATISTICS: 'RESET_CASINO_BONUS_STATISTICS',
  EXPORT_CASINO_BONUS_STATISTICS: 'EXPORT_CASINO_BONUS_STATISTICS',

  GET_SPIN_BONUS_STATISTICS: 'GET_SPIN_BONUS_STATISTICS',
  SAVE_SPIN_BONUS_STATISTICS: 'SAVE_SPIN_BONUS_STATISTICS',
  RESET_SPIN_BONUS_STATISTICS: 'RESET_SPIN_BONUS_STATISTICS',
  EXPORT_SPIN_BONUS_STATISTICS: 'EXPORT_SPIN_BONUS_STATISTICS',

  GET_SPORT_BONUS_STATISTICS: 'GET_SPORT_BONUS_STATISTICS',
  SAVE_SPORT_BONUS_STATISTICS: 'SAVE_SPORT_BONUS_STATISTICS',
  RESET_SPORT_BONUS_STATISTICS: 'RESET_SPORT_BONUS_STATISTICS',
  EXPORT_SPORT_BONUS_STATISTICS: 'EXPORT_SPORT_BONUS_STATISTICS',

  GET_SPECIAL_BONUS_STATISTICS: 'GET_SPECIAL_BONUS_STATISTICS',
  SAVE_SPECIAL_BONUS_STATISTICS: 'SAVE_SPECIAL_BONUS_STATISTICS',
  RESET_SPECIAL_BONUS_STATISTICS: 'RESET_SPECIAL_BONUS_STATISTICS',
  EXPORT_SPECIAL_BONUS_STATISTICS: 'EXPORT_SPECIAL_BONUS_STATISTICS',

  GET_CASINO_BONUS: 'GET_CASINO_BONUS',
  SAVE_CASINO_BONUS: 'SAVE_CASINO_BONUS',
  RESET_CASINO_BONUS: 'RESET_CASINO_BONUS',

  GET_SPIN_BONUS: 'GET_SPIN_BONUS',
  SAVE_SPIN_BONUS: 'SAVE_SPIN_BONUS',
  RESET_SPIN_BONUS: 'RESET_SPIN_BONUS',

  GET_SPORT_BONUS: 'GET_SPORT_BONUS',
  SAVE_SPORT_BONUS: 'SAVE_SPORT_BONUS',
  RESET_SPORT_BONUS: 'RESET_SPORT_BONUS',

  GET_SPECIAL_BONUS: 'GET_SPECIAL_BONUS',
  SAVE_SPECIAL_BONUS: 'SAVE_SPECIAL_BONUS',
  RESET_SPECIAL_BONUS: 'RESET_SPECIAL_BONUS',

  CANCEL_BONUS: 'CANCEL_BONUS',
  CANCEL_PLAYER_BONUS: 'CANCEL_PLAYER_BONUS',

  GET_BONUS_CONTENT: 'GET_BONUS_CONTENT',
  SAVE_BONUS_CONTENT: 'SAVE_BONUS_CONTENT',
  RESET_BONUS_CONTENT: 'RESET_BONUS_CONTENT',
};

export default BonusActionType;
