import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import Config from 'config';
import AdminIframe from 'components/common/AdminIframe';
import LocalStorageService from 'services/localStorageService';
import configureStore from 'store/configureStore';
import { StoreService } from 'services';
import Routers from './router';
import 'services/i18n';
// import * as serviceWorker from './serviceWorker';
import './index.css';

const store = configureStore({});
StoreService.setStore(store);

const container = document.getElementById('root');
const root = createRoot(container);

function receiveMsg(e) {
  const whiteList = [`https:${process.env.REACT_APP_SITE_BUILDER_ACCOUNT_URL}`, `http:${process.env.REACT_APP_SITE_BUILDER_ACCOUNT_URL}`, 'http://localhost:3000'];
  if (!whiteList.find((el) => el.includes(e.origin))) {
    return;
  }
  if (e?.data) {
    try {
      const data = JSON.parse(e.data);
      if (data.method === 'update') {
        LocalStorageService.set('auth', data);
      }
    } catch (error) {
      return false;
    }
  }
}
window.addEventListener('message', receiveMsg);

root.render(
  <>
    <AdminIframe id="ui-customization" title="ui-customization" src={Config.siteBuilderAccountURL} isHidden />
    <Provider store={store}>
      <Routers />
    </Provider>
  </>,
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();
