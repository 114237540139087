import DevToolActionType from './devToolActionType';

export const getSystemCaches = (params) => ({
  type: DevToolActionType.GET_SYSTEM_CACHES,
  payload: { params },
});

export const saveSystemCaches = (data) => ({
  type: DevToolActionType.SAVE_SYSTEM_CACHES,
  payload: { data },
});

export const resetSystemCaches = () => ({
  type: DevToolActionType.RESET_SYSTEM_CACHES,
});

export const cleanSystemCache = (name) => ({
  type: DevToolActionType.CLEAN_SYSTEM_CACHE,
  payload: { name },
});

export const getSystemCacheData = (name) => ({
  type: DevToolActionType.GET_SYSTEM_CACHE_DATA,
  payload: { name },
});

export const saveSystemCacheData = (data) => ({
  type: DevToolActionType.SAVE_SYSTEM_CACHE_DATA,
  payload: { data },
});

export const resetSystemCacheData = () => ({
  type: DevToolActionType.RESET_SYSTEM_CACHE_DATA,
});

export const getDbServices = () => ({
  type: DevToolActionType.GET_DB_SERVICES,
});

export const saveDbServices = (data) => ({
  type: DevToolActionType.SAVE_DB_SERVICES,
  payload: { data },
});

export const resetDbServices = () => ({
  type: DevToolActionType.RESET_DB_SERVICES,
});

export const getDbServiceTables = (name) => ({
  type: DevToolActionType.GET_DB_SERVICE_TABLES,
  payload: { name },
});

export const saveDbServiceTables = (data) => ({
  type: DevToolActionType.SAVE_DB_SERVICE_TABLES,
  payload: { data },
});

export const resetDbServiceTables = () => ({
  type: DevToolActionType.RESET_DB_SERVICE_TABLES,
});

export const syncDbService = (name, data) => ({
  type: DevToolActionType.SYNC_DB_SERVICE,
  payload: { name, data },
});

export const getJobs = () => ({
  type: DevToolActionType.GET_JOBS,
});

export const saveJobs = (data) => ({
  type: DevToolActionType.SAVE_JOBS,
  payload: { data },
});

export const resetJobs = () => ({
  type: DevToolActionType.RESET_JOBS,
});
