import BrandPaymentActionType from 'actions/brandPayment/brandPaymentActionType';

const initialState = {
  brandFailedPaymentsSettings: {},
  brandPaymentMethods: {},
  brandPaymentSettings: {},
  brandFees: {},
  brandLimits: {},
  brandPaymentMethodSettings: {},
  brandPaymentAllowedCurrencies: [],
  brandPaymentAllowedCountries: [],
  brandPaymentsActiveCurrencies: {},
  paymentCurrencies: null,
  brandPaymentMethodTags: [],
};

const brandPaymentReducer = (state = initialState, { type, payload }) => {
  switch (type) {
  case BrandPaymentActionType.SAVE_BRAND_FAILED_PAYMENTS_SETTINGS:
    return {
      ...state,
      brandFailedPaymentsSettings: payload.data,
    };
  case BrandPaymentActionType.RESET_BRAND_FAILED_PAYMENTS_SETTINGS:
    return {
      ...state,
      brandFailedPaymentsSettings: {},
    };
  case BrandPaymentActionType.SAVE_BRAND_PAYMENT_METHODS:
    return {
      ...state,
      brandPaymentMethods: payload.data,
    };
  case BrandPaymentActionType.RESET_BRAND_PAYMENT_METHODS:
    return {
      ...state,
      brandPaymentMethods: {},
    };
  case BrandPaymentActionType.SAVE_BRAND_PAYMENT_SETTINGS:
    return {
      ...state,
      brandPaymentSettings: payload.data,
    };
  case BrandPaymentActionType.RESET_BRAND_PAYMENT_SETTINGS:
    return {
      ...state,
      brandPaymentSettings: {},
    };
  case BrandPaymentActionType.SAVE_BRAND_PAYMENT_METHODS_FEES:
    return {
      ...state,
      brandFees: payload.data,
    };
  case BrandPaymentActionType.RESET_BRAND_PAYMENT_METHODS_FEES:
    return {
      ...state,
      brandFees: {},
    };
  case BrandPaymentActionType.SAVE_BRAND_PAYMENT_METHODS_LIMITS:
    return {
      ...state,
      brandLimits: payload.data,
    };
  case BrandPaymentActionType.RESET_BRAND_PAYMENT_METHODS_LIMITS:
    return {
      ...state,
      brandLimits: {},
    };
  case BrandPaymentActionType.SAVE_BRAND_PAYMENT_METHOD_SETTINGS:
    return {
      ...state,
      brandPaymentMethodSettings: payload.data,
    };
  case BrandPaymentActionType.RESET_BRAND_PAYMENT_METHOD_SETTINGS:
    return {
      ...state,
      brandPaymentMethodSettings: {},
    };
  case BrandPaymentActionType.SAVE_BRAND_PAYMENT_ALLOWED_CURRENCIES:
    return {
      ...state,
      brandPaymentAllowedCurrencies: payload.data,
    };
  case BrandPaymentActionType.RESET_BRAND_PAYMENT_ALLOWED_CURRENCIES:
    return {
      ...state,
      brandPaymentAllowedCurrencies: [],
    };
  case BrandPaymentActionType.SAVE_PAYMENT_CURRENCIES:
    return {
      ...state,
      paymentCurrencies: payload.data,
    };
  case BrandPaymentActionType.RESET_PAYMENT_CURRENCIES:
    return {
      ...state,
      paymentCurrencies: null,
    };
  case BrandPaymentActionType.SAVE_BRAND_PAYMENT_ALLOWED_COUNTRIES:
    return {
      ...state,
      brandPaymentAllowedCountries: payload.data,
    };
  case BrandPaymentActionType.RESET_BRAND_PAYMENT_ALLOWED_COUNTRIES:
    return {
      ...state,
      brandPaymentAllowedCountries: [],
    };
  case BrandPaymentActionType.SAVE_BRAND_PAYMENTS_ACTIVE_CURRENCIES:
    return {
      ...state,
      brandPaymentsActiveCurrencies: payload.data,
    };
  case BrandPaymentActionType.RESET_BRAND_PAYMENTS_ACTIVE_CURRENCIES:
    return {
      ...state,
      brandPaymentsActiveCurrencies: {},
    };
  case BrandPaymentActionType.SAVE_BRAND_PAYMENT_METHOD_TAGS:
    return {
      ...state,
      brandPaymentMethodTags: payload.data,
    };
  case BrandPaymentActionType.RESET_BRAND_PAYMENT_METHOD_TAGS:
    return {
      ...state,
      brandPaymentMethodTags: [],
    };
  default:
    return state;
  }
};

export default brandPaymentReducer;
