const selectPromotions = (state) => state.promotions;
export const selectPromotionsData = (state) => selectPromotions(state).data;
export const selectPromotionsTotal = (state) => selectPromotions(state).total;
export const selectPromotion = (state) => state.promotion;
export const selectPromotionBrandId = (state) => selectPromotion(state).brandId;
export const selectPromotionJoiningState = (state) => selectPromotion(state)?.onJoin;
export const selectCreatedPromotionId = (state) => state.createdPromotionId;
export const selectGeneralConfigEndedStatus = (state) => state.generalConfigEndedStatus;
export const selectSpinDistributions = (state) => state.spinDistributions;
export const selectSpinDistributionEndedStatus = (state) => state.spinDistributionEndedStatus;
export const selectSpinDistribution = (state) => state.spinDistribution;
export const selectSpinDistributionType = (state) => selectSpinDistribution(state).type;
export const selectMachines = (state) => state.machines;
export const selectMachineEndedStatus = (state) => state.machineEndedStatus;
export const selectMachine = (state) => state.machine;
export const selectMachineDetails = (state) => state.machineDetails;
export const selectMachineDetailsSections = (state) => selectMachineDetails(state).sections;

export const selectPlayingParticipants = (state) => state.playingParticipants;
export const selectPlayingParticipantEndedStatus = (state) => state.playingParticipantEndedStatus;
export const selectPlayingParticipant = (state) => state.playingParticipant;
export const selectPrizes = (state) => state.prizes;
export const selectPrize = (state) => state.prize;
export const selectPrizeName = (state) => selectPrize(state).name;

export const selectPrizeEndedStatus = (state) => state.prizeEndedStatus;
export const selectPromotionCurrencies = (state) => selectPromotion(state).currencies;
export const selectMachineContents = (state) => state.machineContents;
export const selectMachineContent = (state) => state.machineContent;
export const selectMachineContentEndedStatus = (state) => state.machineContentEndedStatus;

export const selectMachineConfigs = (state) => state.machineConfigs;

export const selectMachineConfig = (state) => state.machineConfig;
export const selectMachineConfigEndedStatus = (state) => state.machineConfigEndedStatus;

export const selectGroupLimit = (state) => state.groupLimit;
export const selectGroupLimitEndedStatus = (state) => state.groupLimitEndedStatus;

export const selectPublishEndedStatus = (state) => state.publishEndedStatus;
export const selectPromotionName = (state) => selectPromotion(state)?.name;

const selectPromotionTransactions = (state) => state.promotionTransactions;
export const selectPromotionTransactionsData = (state) => selectPromotionTransactions(state).data;
export const selectPromotionTransactionsTotal = (state) => selectPromotionTransactions(state).total;

const selectPromotionMachines = (state) => state.promotionMachines;
export const selectPromotionMachinesData = (state) => selectPromotionMachines(state).data;
export const selectPromotionMachinesTotal = (state) => selectPromotionMachines(state).total;

const selectPromotionPrizes = (state) => state.promotionPrizes;
export const selectPromotionPrizesData = (state) => selectPromotionPrizes(state).data;
export const selectPromotionPrizesTotal = (state) => selectPromotionPrizes(state).total;

const selectPromotionPlayers = (state) => state.promotionPlayers;
export const selectPromotionPlayersData = (state) => selectPromotionPlayers(state).data;
export const selectPromotionPlayersTotal = (state) => selectPromotionPlayers(state).total;

export const selectPromotionCsvPlayers = (state) => state.csvPlayers;
export const selectPromotionPlayersEndedStatus = (state) => state.playersEndedStatus;

const selectPromotionManualSpinReport = (state) => state.promotionManualSpinReport;
export const selectPromotionManualSpinReportData = (state) => selectPromotionManualSpinReport(state).data;
export const selectPromotionManualSpinReportTotal = (state) => selectPromotionManualSpinReport(state).total;

const selectPromoSpinAdjustmentPlayers = (state) => state.promoSpinAdjustmentPlayers;
export const selectPromoSpinAdjustmentPlayersData = (state) => selectPromoSpinAdjustmentPlayers(state).data;
export const selectPromoSpinAdjustmentPlayersTotal = (state) => selectPromoSpinAdjustmentPlayers(state).total;

const selectPlayerPromotionSpins = (state) => state.playerPromotionSpins;
export const selectPlayerPromotionSpinsData = (state) => selectPlayerPromotionSpins(state)?.data;
export const selectPlayerPromotionSpinsTotal = (state) => selectPlayerPromotionSpins(state)?.total;

const selectPlayerPromotionTransactions = (state) => state.playerPromotionTransactions;
export const selectPlayerPromotionTransactionsData = (state) => selectPlayerPromotionTransactions(state)?.data;
export const selectPlayerPromotionTransactionsTotal = (state) => selectPlayerPromotionTransactions(state)?.total;

export const selectPromotionPreviewValidationState = (state) => state.isPromotionValidForPreview;
export const selectPromotionContent = (state) => state.promotionContent;
export const selectPromotionContents = (state) => state.promotionContents;

export const selectPromotionContentEndedStatus = (state) => state.promotionContentEndedStatus;

const selectPrivatePromotionParticipants = (state) => state.privatePromotionParticipants;
export const selectPrivatePromotionParticipantsData = (state) => selectPrivatePromotionParticipants(state).data;
export const selectPrivatePromotionParticipantsTotal = (state) => selectPrivatePromotionParticipants(state).total;

const selectPromotionPrizeMaterialStatistics = (state) => state.prizeMaterialStatistics;
export const selectPromotionPrizeMaterialStatisticsData = (state) => selectPromotionPrizeMaterialStatistics(state).data;
export const selectPromotionPrizeMaterialStatisticsTotal = (state) => selectPromotionPrizeMaterialStatistics(state).total;

const selectPromotionPrizeMachineSpinStatistics = (state) => state.prizeMachineSpinStatistics;
export const selectPromotionPrizeMachineSpinStatisticsData = (state) => selectPromotionPrizeMachineSpinStatistics(state).data;
export const selectPromotionPrizeMachineSpinStatisticsTotal = (state) => selectPromotionPrizeMachineSpinStatistics(state).total;

const selectPromotionPrizeBalanceMultiplierStatistics = (state) => state.prizeBalanceMultiplierStatistics;
export const selectPromotionPrizeBalanceMultiplierStatisticsData = (state) => selectPromotionPrizeBalanceMultiplierStatistics(state)?.data;
export const selectPromotionPrizeBalanceMultiplierStatisticsTotal = (state) => selectPromotionPrizeBalanceMultiplierStatistics(state)?.total;

export const selectPromotionSpinDistributionContents = (state) => state.spinDistributionContents;
export const selectPromotionSpinDistributionContent = (state) => state.spinDistributionContent;
export const selectPromotionSpinDistributionContentEndedStatus = (state) => state.spinDistributionContentEndedStatus;

export const selectPrizeContents = (state) => state.prizeContents;
export const selectPrizeContent = (state) => state.prizeContent;
export const selectPrizeContentEndedStatus = (state) => state.prizeContentEndedStatus;

const selectPromotionSpins = (state) => state.spins;
export const selectPromotionSpinsData = (state) => selectPromotionSpins(state).data;
export const selectPromotionSpinsTotal = (state) => selectPromotionSpins(state).total;

const selectPromotionSpinStatistics = (state) => state.spinStatistics;
export const selectPromotionSpinStatisticsData = (state) => selectPromotionSpinStatistics(state).data;
export const selectPromotionSpinStatisticsTotal = (state) => selectPromotionSpinStatistics(state).total;

export const selectPromotionExchangeItems = (state) => state.exchangeItems;
export const selectPromotionExchangeItem = (state) => state.exchangeItem;
export const selectPromotionExchangeCenterEndedStatus = (state) => state.exchangeCenterEndedStatus;

export const selectPrizeContentConnected = (state) => state.connectedPrizes;

const selectPromotionExchanges = (state) => state.promotionExchanges;
export const selectPromotionExchangesData = (state) => selectPromotionExchanges(state).data;
export const selectPromotionExchangesTotal = (state) => selectPromotionExchanges(state).total;

const selectPromotionChangeLogs = (state) => state.changeLogs;
export const selectPromotionChangeLogsData = (state) => selectPromotionChangeLogs(state)?.data;
export const selectPromotionChangeLogsTotal = (state) => selectPromotionChangeLogs(state)?.total;

export const selectPromotionChangeLog = (state) => state.changeLog;
