import { Grid } from 'antd';
import { StyledAuthMainWrapper, StyledTitleForm, StyledForgetP } from 'components/auth/Login.styled';
import { StyledButtonWrapper, StyledButtonLogin, StyledButtonBlueUnbordered } from 'components/styledComponents/button/Button.styled';
import { FilterService, NavigationService } from 'services';
import { useLocation } from 'react-router-dom';
import { Trans } from 'react-i18next';

function EmailSent() {
  const { useBreakpoint } = Grid;

  const screens = useBreakpoint();
  const location = useLocation();
  const { getQuery } = FilterService;
  const query = getQuery(location.search);
  const { email } = query;

  return (
    <StyledAuthMainWrapper md={screens.md ? 1 : 0} xs={screens.xs ? 1 : 0}>
      <div>
        <StyledTitleForm>Success!</StyledTitleForm>
        {email && (
          <StyledForgetP>
            <Trans i18nKey="notification:passwordResetLinkSent" values={{ email }} components={[<strong />, <br />]} />
          </StyledForgetP>
        )}
        <StyledButtonWrapper>
          <StyledButtonBlueUnbordered onClick={() => NavigationService('/login')}>Back to Log in</StyledButtonBlueUnbordered>
          <StyledButtonLogin type="primary" htmlType="submit" onClick={() => NavigationService('/forgotPassword')}>
            Resend
          </StyledButtonLogin>
        </StyledButtonWrapper>
      </div>
    </StyledAuthMainWrapper>
  );
}
export default EmailSent;
