import ReasonsActionType from 'actions/reasons/reasonsActionType';

const initialState = {
  reasons: {},
  reason: {},
  resources: [],
  reasonsByResource: [],
};

const reasonsReducer = (state = initialState, { type, payload }) => {
  switch (type) {
  case ReasonsActionType.SAVE_REASONS:
    return {
      ...state,
      reasons: payload.data,
    };
  case ReasonsActionType.RESET_REASONS:
    return {
      ...state,
      reasons: {},
    };
  case ReasonsActionType.SAVE_REASON:
    return {
      ...state,
      reason: payload.data,
    };
  case ReasonsActionType.RESET_REASON:
    return {
      ...state,
      reason: {},
    };
  case ReasonsActionType.SAVE_REASONS_RESOURCES:
    return {
      ...state,
      resources: payload.data,
    };
  case ReasonsActionType.RESET_REASONS_RESOURCES:
    return {
      ...state,
      resources: [],
    };
  case ReasonsActionType.SAVE_REASONS_BY_RESOURCE:
    return {
      ...state,
      reasonsByResource: payload.data,
    };
  case ReasonsActionType.RESET_REASONS_BY_RESOURCE:
    return {
      ...state,
      reasonsByResource: [],
    };
  default:
    return state;
  }
};

export default reasonsReducer;
