import { Table } from 'antd';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { StyledExpandedTabs } from 'components/styledComponents/tabs/Tabs.styled';
import { StyledViewTable } from 'components/bets/Bets.styled';
import { media, getBoxShadow, truncate } from 'utils/styledHelpers';

const StyledTable = styled(Table)`
  .ant-table-content {
    overflow-x: overlay !important;
    &:hover {
      ::-webkit-scrollbar {
        height: 10px;
      }
    }
  }
  &:after {
    border-right: 1px solid ${(props) => props.theme.colors.secondaryBg};
    border-radius: ${(props) => props.theme.customBorderRadius.borderRadiusSmall} ${(props) => props.theme.customBorderRadius.borderRadiusSmall} 0 0;
  }
  &:before {
    border-radius: ${(props) => props.theme.customBorderRadius.borderRadiusSmall} ${(props) => props.theme.customBorderRadius.borderRadiusSmall} 0 0;
  }

  .ant-table-content:not(.ant-table-expanded-row-fixed .ant-table-content, .ant-table-expanded-row .ant-table-content) {
    border-radius: ${(props) => props.theme.customBorderRadius.borderRadiusSmall} ${(props) => props.theme.customBorderRadius.borderRadiusSmall} 0 0;
  }
  .ant-table-tbody > tr > td.ant-table-cell-row-hover {
    background: ${(props) => props.theme.colors.secondaryBg};
  }
  .ant-table-expanded-row-fixed .ant-table .ant-table-container {
    &:after {
      right: -0.07rem;
    }
  }
  .ant-table-tbody > tr.ant-table-placeholder:hover > td {
    background: ${(props) => props.theme.colors.secondaryBg};
  }

  .ant-table {
    z-index: 2;

    .ant-table-cell-scrollbar {
      box-shadow: 0 1px 0 1px ${(props) => props.theme.colors.tableHeadColor};
    }
    .ant-table-tbody > tr.ant-table-row-selected.even > td {
      background: ${(props) => props.theme.colors.tableTrEvenColor};
    }
    .ant-table-tbody > tr.ant-table-row-selected.odd > td {
      background: ${(props) => props.theme.colors.secondaryBg};
    }
  }

  .ant-table-sticky-holder {
    z-index: 3;
    background-color: transparent;
  }
  .ant-table-cell,
  .ant-table-thead > tr > th {
    text-align: left;
  }

  .ant-table.ant-table-middle .ant-table-tbody .ant-table-wrapper:only-child .ant-table {
    margin: 0;
  }

  // Disable resize feature for fixed actions column
  th.ant-table-cell-fix-right-first {
    .react-resizable-handle {
      pointer-events: none;
    }
  }
  // Disable drag feature for expand and select columns, and nested tables
  th[class*='ant-table-cell-fix'],
  th.ant-table-row-expand-icon-cell,
  th.ant-table-selection-column,
  .ant-table-expanded-row th,
  .ant-table-expanded-row-fixed th {
    cursor: auto !important;
  }
  .ant-table-row.emptyExpandedRow + .ant-table-expanded-row {
    display: none;
  }
  .emptyExpandedRow {
    .ant-table-cell:first-child {
      cursor: not-allowed;
    }
    .ant-table-row-expand-icon {
      opacity: 0;
    }
    .ant-table-cell.ant-table-row-expand-icon-cell {
      div {
        pointer-events: none;
      }
      svg {
        opacity: 0.5;
      }
    }
    .custom-checkbox {
      pointer-events: auto;
    }
  }
  .ant-table-selection-column {
    .ant-table-selection {
      display: flex;
    }
  }
  .ant-checkbox-wrapper {
    display: flex;
    justify-content: flex-end;
  }
  .ant-table-expanded-row {
    strong,
    span {
      &:not(.ant-tag) {
        line-height: initial;
      }
    }
    .ant-table-cell {
      padding-right: 0;
    }
    &-fixed {
      width: 100%;
    }
  }

  .ant-table.ant-table-middle,
  .ant-table.ant-table-small {
    font-size: 1rem;
  }
  .ant-table {
    font-size: 1rem;
    scrollbar-color: unset;
    .ant-table-tbody {
      overflow: auto !important;
      margin: 0;
      tr > th,
      tr > td {
        vertical-align: middle !important;
      }
    }

    .ant-table-thead {
      background-color: ${(props) => props.theme.colors.secondaryBorder};
      & > tr {
        .custom-checkbox {
          display: table-cell;
        }
        & > th {
          color: ${(props) => props.theme.colors.primaryText};
          background-color: ${(props) => props.theme.colors.secondaryBorder};
          transition: initial;
          font-weight: ${(props) => props.theme.typography.fontWeightRegular};
          &:not(.ant-table-cell-fix-left):not(.ant-table-cell-fix-right) {
            ${truncate()}
          }
          &.ant-table-column-sort {
            background: ${(props) => props.theme.colors.primaryBorder}60;
          }
          .ant-table-column-title {
            ${truncate()}
          }
        }

        & > .no-header-separator::before {
          content: none !important;
        }
      }
    }

    .ant-table-thead > tr:not(.ant-table-expanded-row) > th,
    .ant-table-tbody > tr:not(.ant-table-expanded-row) > td:not(.ant-table-cell-with-append):not(.no-truncate) {
      white-space: nowrap;
      vertical-align: middle;
    }
    .ant-table-tbody > tr.validation-error > td {
      padding-top: 12px;
      vertical-align: top !important;
    }
    .ant-table-tbody {
      & > tr {
        border-bottom: 1px solid ${(props) => props.theme.colors.secondaryBorder};

        &.ant-table-placeholder {
          background-color: ${(props) => props.theme.colors.primaryBg}60;
        }
        &:not(.ant-table-expanded-row) {
          & > td {
            // Truncate all table columns except fixed columns, and custom cases(eg. status, columns which contains buttons)
            &:not(.ant-table-cell-fix-left):not(.ant-table-cell-fix-right):not(.ant-table-selection-column):not(.no-truncate) {
              ${truncate()}
            }
          }
        }
        & > td {
          color: ${(props) => props.theme.colors.primaryText};
          border-bottom: 1px solid ${(props) => props.theme.colors.secondaryBorder};
          &.ant-table-column-sort {
            background: ${(props) => props.theme.colors.primaryBorder}30;
          }
        }
      }
      .ant-table-expanded-row {
        background-color: ${(props) => props.theme.colors.secondaryBg};
      }
    }

    &-scroll {
      table {
        border-collapse: collapse;
      }
    }
  }

  .ant-table.ant-table-middle {
    .ant-table-content .ant-table-tbody {
      & > tr {
        &[draggable]:hover {
          box-shadow: ${(props) => getBoxShadow(props.theme.colors.secondaryShadow)};
          background-color: ${(props) => props.theme.colors.primaryBg};
          border-radius: ${(props) => props.theme.customBorderRadius.borderRadiusMiddle};
        }
      }
    }
    tr {
      height: 3.43rem;

      ${media.sm`
        height: 2.2rem;
      `}
    }
  }
  .ant-table-thead > tr.ant-table-row-hover:not(.ant-table-expanded-row):not(.ant-table-row-selected) > td,
  .ant-table-tbody > tr.ant-table-row-hover:not(.ant-table-expanded-row):not(.ant-table-row-selected) > td,
  .ant-table-thead > tr:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected) > td,
  .ant-table-tbody > tr:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected) > td:not(.ant-table-cell-fix-right, .ant-table-cell-fix-left) {
    background: transparent;
  }
  .ant-table-thead > tr,
  .ant-table-thead > tr th,
  .ant-table-thead > tr td {
    border-bottom: 1px solid transparent;
    border-top: 1px solid transparent;
  }
  .ant-table-tbody > tr > td {
    border-bottom: 1px solid ${(props) => props.theme.colors.secondaryBorder};
  }
  .ant-table-row {
    transition: initial;
    &:hover td {
      background-color: ${(props) => props.theme.colors.tableTrHoverColor}!important;
    }
  }
  .ant-table-row.even td {
    background-color: ${(props) => props.theme.colors.tableTrEvenColor};
  }
  .ant-table-row.odd td {
    background-color: ${(props) => props.theme.colors.secondaryBg};
  }
  .ant-table-row.configured td {
    background-color: rgba(82, 196, 26, 0.1);
  }
  .ant-table-row.configured:hover td {
    background-color: rgba(82, 196, 26, 0.1) !important;
  }
  .ant-table-row td.has-background {
    background-color: ${(props) => props.theme.colors.tableHeadColor} !important;
    border-color: ${(props) => props.theme.colors.secondaryBg} !important;
  }
  tr.ant-table-expanded-row > td {
    .ant-descriptions-view {
      border-top: initial;
      border-left: initial;
      border-right: initial;
      border-radius: 0;
    }
  }
  .status {
    text-transform: capitalize;
  }
  .status:before {
    content: '';
    display: inline-block;
    width: 6px;
    height: 6px;
    vertical-align: middle;
    border-radius: ${(props) => props.theme.customBorderRadius.borderRadiusCircle};
    margin-right: 6px;
  }

  .finished > td:first-child,
  .approved > td:first-child,
  .win > td:first-child,
  .verified > td:first-child:not(.domain) {
    border-left: 2px solid ${(props) => props.theme.colors.success};
  }
  .finished > .status:before,
  .approved > .status:before,
  .win > .status:before,
  .verified > .status:before {
    background-color: ${(props) => props.theme.colors.success};
  }

  .active > .status:before {
    background-color: ${(props) => props.theme.colors.success};
  }
  .pending > .status:before {
    background-color: ${(props) => props.theme.colors.yellow};
  }

  .new > td:first-child,
  .live > td:first-child {
    border-left: 2px solid ${(props) => props.theme.colors.blue};
  }
  .new > .status:before,
  .live > .status:before {
    background-color: ${(props) => props.theme.colors.blue};
  }

  .canceled > td:first-child,
  .lost > td:first-child {
    border-left: 2px solid ${(props) => props.theme.colors.danger};
  }
  .canceled > .status:before,
  .lost > .status:before {
    background-color: ${(props) => props.theme.colors.danger};
  }

  .editable-cell {
    position: relative;
  }

  .verified .domain,
  .active .domain {
    pointer-events: none;
  }

  .domain {
    overflow: initial !important;
    .ant-row {
      margin: 0;
      .ant-form-item-control {
        line-height: 2rem;
        min-height: 2rem;
      }
      & input {
        height: 2rem;
        line-height: 2rem;
        padding: 0;
        margin: 0;
        text-indent: 11px;
      }
    }
  }
  .domain > .editable-cell-value-wrap {
    border: 1px solid transparent;
    line-height: 2rem;
    height: 2rem;
    border-radius: ${(props) => props.theme.customBorderRadius.borderRadiusSmall};
    text-indent: 11px;
  }
  .domain:hover > .editable-cell-value-wrap {
    border: 1px solid ${(props) => props.theme.colors.primaryBorder};
  }
  .drop-over-downward td {
    border-bottom: 2px dashed ${(props) => props.theme.colors.blue};
  }

  .drop-over-upward td {
    border-top: 2px dashed ${(props) => props.theme.colors.blue};
  }
  .ant-table.ant-table-middle .ant-table-title,
  .ant-table.ant-table-middle .ant-table-footer,
  .ant-table.ant-table-middle .ant-table-thead > tr > th,
  .ant-table.ant-table-middle .ant-table-tbody > tr > td,
  .ant-table.ant-table-middle tfoot > tr > th,
  .ant-table.ant-table-middle tfoot > tr > td {
    box-sizing: border-box;
    vertical-align: middle;
  }

  .ant-table.ant-table-small .ant-table-thead > tr > td.ant-table-row-expand-icon-cell + th,
  .ant-table.ant-table-small .ant-table-tbody > tr > td.ant-table-row-expand-icon-cell + td {
    padding-left: 0;

    &.ant-table-cell-fix-right.ant-table-cell-fix-right-first {
      padding-left: 1.14rem;
    }
  }

  .ant-table .ant-table-thead > tr > th,
  .ant-table .ant-table-thead > tr > th.ant-table-column-has-sorters:hover,
  .ant-table .ant-table-thead > tr > td {
    background-color: ${(props) => props.theme.colors.tableHeadColor};
  }
  .ant-table-column-sorter-up,
  .ant-table-column-sorter-down {
    color: #999999;
  }
  .ant-table-cell-fix-right {
    right: 0;
    background-color: transparent;
  }
  .ant-table-cell-fix-left {
    left: 0;
  }
  th.ant-table-cell-fix-left,
  th.ant-table-row-expand-icon-cell {
    input[type='checkbox'] {
      pointer-events: initial;
    }
  }
  .ant-table-layout-fixed {
    .ant-table-header {
      border-radius: ${(props) => props.theme.customBorderRadius.borderRadiusSmall} ${(props) => props.theme.customBorderRadius.borderRadiusSmall} 0 0;
    }
  }
  .ant-spin-container::after {
    background: ${(props) => props.theme.colors.secondaryBg};
  }
  .ant-table-row.row-highlight td {
    background: ${(props) => props.theme.colors.red}!important;
    border-bottom: 1px solid ${(props) => props.theme.colors.primaryText}30 !important;
    > td:not(.custom-checkbox):last-child {
      background: ${(props) => props.theme.colors.red};
    }
    &:hover td {
      background: ${(props) => props.theme.colors.red};
    }
  }
  .ant-table-thead > tr > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan]):before,
  .ant-table-thead > tr > td:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan]):before {
    background: ${(props) => props.theme.colors.primaryBorder};
  }
  .ant-table-sticky-scroll {
    display: none;
  }

  // Table td sizes ( medium, small )
  .ant-table.ant-table-middle .ant-table-title,
  .ant-table.ant-table-middle .ant-table-footer,
  .ant-table.ant-table-middle .ant-table-thead > tr > th,
  .ant-table.ant-table-middle .ant-table-tbody > tr > td,
  .ant-table.ant-table-middle tfoot > tr > th,
  .ant-table.ant-table-middle tfoot > tr > td {
    padding: 8px 16px;

    ${media.sm`
      padding: 6px 10px;
    `}
  }

  .ant-table.ant-table-small .ant-table-title,
  .ant-table.ant-table-small .ant-table-footer,
  .ant-table.ant-table-small .ant-table-thead > tr > th,
  .ant-table.ant-table-small .ant-table-tbody > tr > td,
  .ant-table.ant-table-small tfoot > tr > th,
  .ant-table.ant-table-small tfoot > tr > td {
    padding: 8px 12px;

    ${media.sm`
      padding: 6px 10px;
    `}
  }

  .ant-table.ant-table-small .ant-table-tbody > tr > td.ant-table-row-expand-icon-cell,
  .ant-table.ant-table-middle .ant-table-tbody > tr > td.ant-table-row-expand-icon-cell {
    padding-right: 0;
  }

  // Virtual table styles
  .ant-table {
    .ant-table-tbody-virtual {
      margin-right: -1px;
      .ant-table-row {
        .ant-table-cell {
          color: ${(props) => props.theme.colors.primaryText};
          border-bottom: 1px solid ${(props) => props.theme.colors.secondaryBorder};
          background-color: transparent;
          padding: 8px 16px;

          ${media.sm`
            padding: 6px 10px;
          `}
        }
        &.even .ant-table-cell {
          background-color: ${(props) => props.theme.colors.tableTrEvenColor};
        }
        &.odd .ant-table-cell {
          background-color: ${(props) => props.theme.colors.secondaryBg};
        }
        &:hover {
          .ant-table-cell {
            background: ${(props) => props.theme.colors.tableTrHoverColor};
          }
        }
      }
      .ant-table-tbody-virtual-scrollbar {
        width: 6px !important;

        &-thumb {
          background-color: ${(props) => props.theme.colors.primaryBorder} !important;
        }
      }
    }
    &.ant-table-small .ant-table-tbody-virtual .ant-table-row .ant-table-cell {
      padding: 8px 12px;
      ${media.sm`
        padding: 6px 10px;
      `}
    }
  }
`;

const StyledSearchableTable = styled.div`
  position: relative;
  padding: 0.43rem 0;
  margin-bottom: auto;
  label + div {
    margin-top: 2.85rem;
  }
  ${({ showTableHead }) => !showTableHead
    && `
    .ant-table {
      max-height: calc(100vh - 38rem);
      min-height: 18rem;
      overflow-y: auto;
    }
  `}
  .ant-table {
    ${media.sm`
       min-height: auto;
    `}
  }
  .ant-checkbox-wrapper:after {
    display: none;
  }
  .ant-table-wrapper {
    height: 100%;
    .ant-spin-nested-loading,
    .ant-spin-container,
    .ant-table {
      height: 100%;
    }
    .ant-table-container {
      height: 100%;

      .ant-table-content {
        overflow-x: unset !important;
      }

      table {
        .ant-table-tbody {
          > tr:not(.ant-table-expanded-row) {
            > td:not(.checkbox):last-child {
              padding-right: 0.71rem !important;
            }
          }
        }
        .ant-table-thead {
          position: sticky;
          top: 0;
          z-index: 3;
        }
      }
    }
  }
  .ant-table.ant-table-middle .ant-table-tbody > tr {
    height: 2.86rem !important;
  }
  && .ant-table {
    &-thead {
      tr {
        position: relative;
        & > th,
        & > td {
          border-bottom: 1px solid transparent !important;
          vertical-align: middle;
        }
      }
    }
    &-tbody {
      tr {
        &:first-child > td {
          border-top: 1px solid ${(props) => props.theme.colors.secondaryBorder};
        }
        & > td {
          padding: ${(props) => (props.showTableHead ? 'inherit' : 'padding: 0 !important')};
          &.checkbox {
            display: table-cell;
          }
        }
      }
      .ant-table-measure-row {
        display: none;
      }
    }
    .ant-empty-normal {
      margin-block: 32px;
    }
  }
`;

const StyledEditableCell = styled.div`
  padding-right: 1.71rem;
  box-sizing: border-box;
  display: flex;
  align-items: center;

  .editable-cell-value-wrapper {
    flex: 1;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 0;
    textarea {
      font-size: 1rem;
    }
  }
`;
const StyledLinkItem = styled(Link)`
  display: block;
  color: ${(props) => (props.warning ? `${props.theme.colors.danger}` : `${props.theme.colors.blue}`)}!important;
  font-size: ${(props) => props.theme.typography.fontSizeDefault};
  max-width: 100%;
  ${truncate()}
  &:hover {
    text-decoration: underline;
  }
`;
const BlueBackgroundItem = styled(Link)`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 1.71rem;
  padding: 0 0.85rem;
  line-height: 1.14rem;
  border-radius: ${(props) => props.theme.customBorderRadius.borderRadiusSmall};
  background-color: ${(props) => (props.warning ? `${props.theme.colors.danger}10` : `${props.theme.colors.blue}20`)};
  cursor: pointer;
  &:hover {
    span {
      text-decoration: underline;
    }
  }
`;
const BlueBackgroundLinkItem = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 1.71rem;
  padding: 0 0.85rem;
  line-height: 1.14rem;
  border-radius: ${(props) => props.theme.customBorderRadius.borderRadiusSmall};
  background-color: ${(props) => (props.warning ? `${props.theme.colors.danger}10` : `${props.theme.colors.blue}20`)};
  cursor: pointer;
  color: ${(props) => props.theme.colors.blue};
  &:hover {
    span {
      text-decoration: underline;
    }
  }
`;
const StyledGrayBackgroundItem = styled(BlueBackgroundLinkItem)`
  background-color: ${(props) => (props.dark ? props.theme.colors.tableHeadColor : props.theme.colors.tableTrHoverColor)};
  color: ${(props) => props.theme.colors.secondaryColor};
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'unset')};

  ${(props) => props.small
    && `
    font-size: ${props.theme.typography.fontSizeSmall};
    line-height: 1rem;
    height: 1.32rem;
  `}
`;

const HighlightedText = styled.span`
  color: ${(props) => (props.warning ? `${props.theme.colors.danger}` : `${props.theme.colors.blue}`)}!important;
  font-size: ${(props) => props.theme.fontSizeDefault};
  &:hover {
    text-decoration: underline;
  }
`;

const CellAlign = styled.div`
  text-align: ${(props) => props.type};
  color: ${(props) => props.theme.colors.primaryText};
`;
const StyledRightTd = styled.div`
  > div {
    justify-content: flex-end;
  }
`;

const StyledAdminList = styled.div`
  .ant-list-items > li {
    border-bottom: 1px solid ${(props) => props.theme.colors.secondaryBorder};
    &:last-child {
      border-bottom: none;
    }
  }
`;

const StyledEditableTable = styled.div`
  .ant-tabs {
    overflow: initial;
  }
`;

const StyledTabsSeperateTable = styled(StyledViewTable)`
  margin-top: ${(props) => (props.isDetails ? '2.85rem' : 0)};
  .ant-table table:not(.ant-table-expanded-row .ant-table table, .ant-table-body table) {
    border: none;
  }

  .ant-table-expanded-row .ant-table table {
    .ant-table-thead > tr > th {
      border-bottom: 1px solid ${(props) => props.theme.colors.secondaryBorder};
    }
    border: 1px solid ${(props) => props.theme.colors.secondaryBorder};
    border-bottom: none;
  }
  .ant-table .ant-table-thead > tr > th {
    font-weight: ${(props) => props.theme.typography.fontWeightRegular};
  }
`;

const StyledTableArrowPadding = styled.div`
  .ant-table-wrapper {
    .ant-table {
      .ant-table-tbody {
        & > tr.ant-table-expanded-row > td {
          padding: ${(props) => (props.isPadding ? '0' : '0.5rem 1.71rem')};
        }
        .ant-table-expanded-row td > div {
          :not(:last-child) {
            border-bottom: 1px solid ${(props) => props.theme.colors.secondaryBorder};
          }
        }
        & > tr:not(.ant-table-expanded-row) > td:not(.custom-checkbox):last-child {
          text-align: left;
        }
        & > tr.ant-table-row-selected > td {
          background-color: ${(props) => props.theme.colors.secondaryBg};
        }
        & > tr.ant-table-row-selected.odd > td {
          background-color: ${(props) => props.theme.colors.secondaryBg};
        }
      }
      &.ant-table-small .ant-table-expanded-row-fixed {
        margin: -1.14rem;
      }
      &.ant-table-middle {
        .ant-table-thead > tr > th:last-of-type::before {
          content: none;
        }
      }
    }
  }
`;
const StyledTableCheckboxPadding = styled.div`
  th.ant-table-cell.ant-table-selection-column {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 3.4rem;
  }
  td.ant-table-cell.ant-table-selection-column {
    display: flex;
    height: 3.5rem;
    justify-content: flex-start;
    align-items: center;
  }
`;
const ConversationTableWrapper = styled.div`
  .ant-table-cell {
    background-color: ${(props) => props.theme.colors.secondaryBg};
  }
  .read td {
    background-color: ${(props) => props.theme.colors.notSeen} !important;
  }
  .deleted td {
    background-color: ${(props) => props.theme.colors.red} !important;
  }
  .ant-table-row-expand-icon-cell {
    &:hover {
      cursor: pointer;
    }
  }
`;
const InvalidKeyTableWrapper = styled.div`
  margin-top: 1.71rem;

  .ant-table {
    max-height: calc(100vh - 34.2rem);
    min-height: 11rem;
    overflow-y: scroll;
  }

  .ant-list-pagination {
    .ant-pagination {
      justify-content: flex-end;
    }
  }
`;
const StyledRedColor = styled.span`
  color: ${(props) => props.theme.colors.danger};
  font-weight: ${(props) => props.theme.typography.fontWeightMedium};
`;

const StyledHighlightedDisabled = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: ${(props) => props.theme.customBorderRadius.borderRadiusSmall};
  background-color: ${(props) => (props.isDisabled ? props.theme.colors.yellow : '')}20;
  color: ${(props) => (props.isDisabled ? props.theme.colors.yellow : '')};
  padding: ${(props) => (props.isDisabled ? '0.42rem 0.57rem' : '0')};
  line-height: 1.21;
  max-width: 100%;
`;

const StyledHighlightedRed = styled(StyledHighlightedDisabled)`
  background-color: ${(props) => props.theme.colors.danger}20;
  color: ${(props) => props.theme.colors.danger};
  padding: 0.42rem 0.57rem;
`;
const SearchableTableWithoutHeader = styled.div`
  .ant-table-thead {
    display: none;
  }
`;
const StyledInfoIconWithTextInTables = styled.div`
  display: flex;
  align-items: center;
  > div:first-of-type {
    margin: 0 1.14rem 0 0;
  }
`;

const StyledTabsTable = styled.div`
  height: ${(props) => (props.isFullHeight ? '100%' : 'auto')};
  .ant-tabs-content {
    border: 1px solid ${(props) => props.theme.colors.secondaryBorder};
    border: ${(props) => (props.noBorder ? 'none' : `1px solid ${props.theme.colors.secondaryBorder}`)};
    border-top: none;
    border-bottom: none;
    height: 100%;
    .ant-list-empty-text {
      border-bottom: 1px solid ${(props) => props.theme.colors.secondaryBorder};
      background-color: ${(props) => props.theme.colors.secondaryBg};
    }
  }
  .ant-tabs {
    height: 100%;
  }
  .ant-tabs-card.ant-tabs-top > .ant-tabs-nav {
    .ant-tabs-tab-active {
      border-bottom-color: transparent;
    }
    .ant-tabs-tab:not(:last-of-type) {
      margin-right: 0.29rem;
      max-height: 2.85rem;
    }
  }
`;

const StyledExpandFixTable = styled(StyledExpandedTabs)`
  .ant-table-wrapper {
    .ant-table.ant-table-middle {
      .ant-table-thead > tr > th:last-of-type::before {
        content: none;
      }

      .ant-table-footer {
        padding: ${(props) => props.isFooter};
        border-bottom: 1px solid ${(props) => props.theme.colors.secondaryBorder};
      }
    }

    .ant-table {
      .ant-table-expanded-row-fixed {
        margin: -12px -16px;

        .ant-table-content {
          border-radius: ${(props) => `${props.theme.customBorderRadius.borderRadiusSmall} ${props.theme.customBorderRadius.borderRadiusSmall} 0 0`};
        }

        .ant-tabs-content-holder .ant-table {
          border-top: none;
          border-bottom: 1px solid ${(props) => props.theme.colors.secondaryBorder};
          border-radius: 0;
        }

        .ant-tabs-top > .ant-tabs-nav::before {
          width: 99.5%;
        }

        .ant-tabs-content-holder {
          .ant-spin-container {
            border-radius: ${(props) => `0 ${props.theme.customBorderRadius.borderRadiusSmall} 0 0`};
            border-top: none;

            .ant-table-container table > thead > tr:first-child th:first-child {
              border-radius: 0;
            }
          }
        }

        .ant-spin-container {
          border: 1px solid ${(props) => props.theme.colors.secondaryBorder};
          border-bottom: none;
          border-radius: ${(props) => `${props.theme.customBorderRadius.borderRadiusSmall}`};

          .ant-table-container {
            border-radius: ${(props) => `${props.theme.customBorderRadius.borderRadiusSmall} ${props.theme.customBorderRadius.borderRadiusSmall} 0 0`};
          }

          .ant-table-thead > tr > th {
            background-color: ${(props) => props.theme.colors.secondaryBg};
            font-weight: ${(props) => props.theme.typography.fontWeightMedium};
            border-bottom: 1px solid ${(props) => props.theme.colors.secondaryBorder};

            &:first-child,
            th:last-child {
              border-radius: ${(props) => `${props.theme.customBorderRadius.borderRadiusSmall} 0 0 0`};
            }
          }
        }
      }
    }
  }

  tr.ant-table-expanded-row > td,
  tr.ant-table-expanded-row:hover > td {
    background: ${(props) => props.theme.colors.tableTrEvenColor};
  }

  .ant-table-expanded-row {
    > td {
      background: ${(props) => props.theme.colors.primaryBg};
    }
    .ant-table-row.even {
      background-color: ${(props) => props.theme.colors.secondaryBg};
      &:hover td {
        background-color: ${(props) => props.theme.colors.secondaryBg};
      }
    }
  }

  .ant-table .ant-table-tbody > tr:not(.odd):not(.even).expanded-tab-table {
    &:last-child {
      > td {
        border-bottom: none;
      }
    }
    > td {
      background-color: ${(props) => props.theme.colors.secondaryBg};
      border-bottom: 1px solid ${(props) => props.theme.colors.secondaryBorder};
    }
  }
  .ant-table .ant-table-tbody > tr:not(.odd):not(.even) {
    & + .ant-table-expanded-row > td,
    tr.ant-table-expanded-row:hover > td {
      padding: 0.57rem 1.14rem 1.14rem 1.14rem;
    }
  }
  .ant-empty-normal {
    .ant-empty-description {
      font-size: 1rem;
    }
  }
  .ant-table.ant-table-small {
    .ant-table-tbody .ant-table-wrapper:only-child .ant-table {
      margin: 0;
    }
  }
  .ant-tabs-content-holder .ant-table-container::before {
    box-shadow: none;
  }
`;

const StyledExpandedTableWithTab = styled(StyledExpandFixTable)`
  border: ${(props) => (props.border ? ` 1px solid ${props.theme.colors.secondaryBorder} ` : 'none')};
  border-bottom: ${(props) => (props.border ? `1px solid ${props.theme.colors.secondaryBorder}` : 'none')};
  border-radius: ${(props) => props.theme.customBorderRadius.borderRadiusSmall};
  .ant-table .ant-table-tbody > tr:last-child > td {
    border-bottom: none;
  }
  .ant-tabs-top > .ant-tabs-nav {
    margin-bottom: 0;
    &::before {
      border-bottom: 1px solid ${(props) => props.theme.colors.secondaryBorder};
    }
  }
  .ant-tabs-card.ant-tabs-top > .ant-tabs-nav .ant-tabs-tab-active {
    .ant-tabs-tab-btn {
      font-weight: ${(props) => props.theme.typography.fontWeightRegular};
    }
  }
  .ant-tabs-card.ant-tabs-top > .ant-tabs-nav .ant-tabs-tab-active {
    border: 1px solid ${(props) => props.theme.colors.secondaryBorder};
    border-bottom: none;
  }

  .ant-tabs-card > .ant-tabs-nav .ant-tabs-tab {
    border: 1px solid ${(props) => props.theme.colors.secondaryBorder};
    border-bottom: none;

    &:not(.ant-tabs-tab.ant-tabs-tab-active) {
      background: ${(props) => props.theme.colors.primaryBg};
    }
    border-radius: ${(props) => `${props.theme.customBorderRadius.borderRadiusSmall} ${props.theme.customBorderRadius.borderRadiusSmall} 0 0`};
    padding: 0.86rem 1.71rem;
    height: 2.85rem;
  }
  .ant-table-empty {
    .ant-table-expanded-row-fixed {
      width: auto !important;
    }
  }
  .ant-tabs-content {
    .ant-table-content {
      border-radius: 0;
    }
  }
`;

const StyledBalanceWrapper = styled.div`
  display: flex;
  padding: 1rem 0 1rem 1.71rem;
  align-items: center;
  border-bottom: 1px solid ${(props) => props.theme.colors.secondaryBorder};
  border-top: none;
  border-left: ${(props) => (props.noBorders ? 'none' : `1px solid ${props.theme.colors.secondaryBorder}`)};
  border-right: ${(props) => (props.noBorders ? 'none' : `1px solid ${props.theme.colors.secondaryBorder}`)};
  background: ${(props) => props.theme.colors.secondaryBg};
  border-radius: ${(props) => (props.noBorders ? '0' : `0 0 ${props.theme.customBorderRadius.borderRadiusMiddle} ${props.theme.customBorderRadius.borderRadiusMiddle}`)};
  width: 100%;
  flex-wrap: wrap;
  p {
    font-size: 1rem;
    font-weight: ${(props) => props.theme.typography.fontWeightMedium};
    position: relative;
    margin: ${(props) => (props.isSmall ? '0 0.85rem' : '0 1.71rem 0 0')}!important;
    div {
      display: inline-block;
    }
    &:not(:last-child) {
      &:after {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        right: -0.83rem;
        width: 1px;
        height: 100%;
        background-color: ${(props) => props.theme.colors.secondaryBorder};
      }
    }
  }
`;
const StyledBalanceDetails = styled.div`
  font-size: 1rem;
  font-weight: ${(props) => props.theme.typography.fontWeightMedium}!important;
  position: relative;
  margin: ${(props) => (props.isSmall ? '0 0.85rem' : '0 1.71rem 0 0')}!important;
  div {
    display: inline-block;
  }
  &:not(:last-child) {
    &:after {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      right: -0.83rem;
      width: 1px;
      height: 100%;
      background-color: ${(props) => props.theme.colors.secondaryBorder};
    }
  }
`;
const StyledWithoutEvenBackground = styled.div`
  margin-bottom: 1.71rem;
  .ant-table-row.even {
    background: ${(props) => props.theme.colors.secondaryBg};
  }
  .ant-table-row:hover > td {
    background: ${(props) => props.theme.colors.secondaryBg};
  }
  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):focus-within {
    box-shadow: none;
  }
`;

const StyledTableWithFooter = styled.div`
  .ant-table.ant-table-middle .ant-table-footer {
    padding: 0;
  }
`;
const StyledExpandedChackboxPadding = styled.div`
  .ant-checkbox {
    top: 1px;
  }
  .ant-table-expanded-row-fixed {
    padding: 0.57rem 0;
  }
`;
const StyledTableNoScroll = styled.div`
  @media (max-width: 1366px) {
    .ant-table-content {
      overflow: auto;
    }
  }
`;

const StyledCurrencyInfo = styled.div`
  display: flex;
  align-items: center;
  span {
    color: ${(props) => (props.isMisconfigured ? props.theme.colors.danger : props.theme.colors.primaryText)};
    margin: ${(props) => (props.isMisconfigured ? '0.2rem 0 0 1.14rem ' : 0)};
  }
  svg {
    g circle {
      fill: ${(props) => props.theme.colors.danger};
    }
  }
`;
const StyledExpandedColorsWrapper = styled(StyledExpandedTabs)`
  .ant-tabs-nav {
    position: relative;
    top: 1px;
    z-index: 5;
    margin-bottom: 0 !important;
  }
  .ant-table-expanded-row-fixed .ant-spin-container {
    border: none;
  }
`;
const StyledBoldColumn = styled.div`
  font-weight: ${(props) => props.theme.typography.fontWeightMedium};
`;

const StyledExpandTableHeader = styled.div`
  background-color: ${(props) => props.theme.colors.tableHeadColor};
  color: ${(props) => props.theme.colors.primaryText};
  height: 3.43rem;
  display: flex;
  padding: 0 1.71rem;
  align-items: center;
  border-radius: ${(props) => `${props.theme.customBorderRadius.borderRadiusSmall} ${props.theme.customBorderRadius.borderRadiusSmall} 0 0`};
  .ant-checkbox-wrapper {
    margin: 0 0.65rem 0 auto;
  }
`;
const StyledExpandTableHeaderCell = styled.div`
  text-align: left;
  font-size: ${(props) => props.theme.typography.fontSizeDefault};

  &:first-of-type {
    width: 14.7rem;
  }
  &:nth-child(2) {
    width: 21rem;
  }

  ${({ alignRight }) => alignRight
    && `
    margin-left: auto;
    white-space: nowrap;
    
    &:not(:last-child) {
      padding-right: 2.6rem;
    }
    
    & + .ant-checkbox-wrapper {
      margin-left: unset;
    }
  `}
`;
const StyledExpandToggle = styled.span`
  display: inline-block;
  cursor: pointer;
  transform: rotate(${(props) => (props.isExpand ? '90deg' : '0')});
  padding: 1rem 0.5rem;
  width: 30px;
  margin: 0 10px;
`;
const StyledResizableTitle = styled.th`
  ${({ isResizing, width }) => isResizing
    && `
    pointer-events: none;
    width: ${width || 'auto'};
    .react-resizable-handle {
      pointer-events: initial;
    }
    .ant-table-column-sorter {
      visibility: hidden;
    }
  `}
`;
const StyledResizableHandle = styled.span`
  position: absolute;
  right: -10px;
  bottom: 0;
  z-index: 1;
  width: 20px;
  height: 100%;
  cursor: col-resize;
  pointer-events: initial;
`;

// Fix tables, which have 6px empty th(.ant-table-cell-scrollbar) in thead
const StyledTableFixedThead = styled.div`
  .ant-table {
    margin-bottom: 0;
    .ant-table-thead > tr > th.ant-table-cell-fix-right {
      transform: translateX(6px);

      &:before {
        content: none !important;
      }
    }
    .ant-table-body {
      overflow: auto !important;
    }
  }
`;

const StyledTableMarginTop = styled.div`
  margin-top: 1.71rem;
`;

const StyledConnectedTables = styled.div`
  border-radius: 8px 8px 0 0;
  overflow: hidden;
`;

const StyledTableSimplifiedHead = styled.div`
  .ant-table-content {
    border-radius: 0 !important;
  }
  .ant-table {
    border-radius: 0;
    margin-bottom: 0;
    table {
      border-radius: 0;
      .ant-table-thead {
        > tr > th,
        > tr > td {
          border-radius: 0 !important;
        }
      }
    }
  }
`;

const StyledAddNewRow = styled.p`
  display: inline-block;
  align-self: flex-start;
  color: ${(props) => (props.isDisabled ? props.theme.colors.primaryBorder : props.theme.colors.blue)};
  font-weight: ${(props) => props.theme.typography.fontWeightMedium};
  font-size: 1rem;
  cursor: ${(props) => (props.isDisabled ? 'not-allowed' : 'pointer')};
  margin: 1.14rem 0 1.14rem 0.6rem;
`;

const StyledFormItemInRow = styled.div`
  max-width: 18rem;
`;

export {
  StyledTable,
  StyledSearchableTable,
  StyledBalanceWrapper,
  StyledExpandedTableWithTab,
  StyledHighlightedDisabled,
  StyledHighlightedRed,
  StyledEditableCell,
  StyledLinkItem,
  StyledTableCheckboxPadding,
  StyledRedColor,
  BlueBackgroundItem,
  HighlightedText,
  CellAlign,
  StyledTableArrowPadding,
  ConversationTableWrapper,
  InvalidKeyTableWrapper,
  SearchableTableWithoutHeader,
  StyledRightTd,
  StyledAdminList,
  StyledEditableTable,
  StyledInfoIconWithTextInTables,
  StyledTabsTable,
  StyledExpandFixTable,
  StyledWithoutEvenBackground,
  StyledTableWithFooter,
  StyledExpandedChackboxPadding,
  StyledTabsSeperateTable,
  BlueBackgroundLinkItem,
  StyledTableNoScroll,
  StyledBalanceDetails,
  StyledCurrencyInfo,
  StyledExpandedColorsWrapper,
  StyledBoldColumn,
  StyledExpandTableHeader,
  StyledExpandTableHeaderCell,
  StyledExpandToggle,
  StyledGrayBackgroundItem,
  StyledResizableTitle,
  StyledResizableHandle,
  StyledTableFixedThead,
  StyledTableMarginTop,
  StyledConnectedTables,
  StyledTableSimplifiedHead,
  StyledAddNewRow,
  StyledFormItemInRow,
};
