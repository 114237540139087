import { connect } from 'react-redux';
import { Logo } from 'components/layout/header';
import { selectAuthUser, selectUserConfig } from 'selectors';
import { settingKeys } from 'constants/configKeys';

const mapStateToProps = ({ auth, users }) => ({
  user: selectAuthUser(auth),
  menuCollapse: selectUserConfig(users, settingKeys.menuCollapse),
});

export default connect(mapStateToProps)(Logo);
