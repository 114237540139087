import BlacklistActionType from 'actions/blacklist/blacklistActionType';

const initialState = {
  blacklist: {},
  csvBlacklist: [],
  endedStatus: false,
};

const blacklistReducer = (state = initialState, { type, payload }) => {
  switch (type) {
  case BlacklistActionType.SAVE_BLACKLIST:
    return {
      ...state,
      blacklist: payload.data,
    };
  case BlacklistActionType.RESET_BLACKLIST:
    return {
      ...state,
      blacklist: {},
    };
  case BlacklistActionType.SAVE_CSV_BLACKLIST:
    return {
      ...state,
      csvBlacklist: payload.data,
    };
  case BlacklistActionType.RESET_CSV_BLACKLIST:
    return {
      ...state,
      csvBlacklist: [],
    };
  case BlacklistActionType.CHANGE_BLACKLIST_ENDED_STATUS:
    return {
      ...state,
      endedStatus: payload.status,
    };
  default:
    return state;
  }
};

export default blacklistReducer;
