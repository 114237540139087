import PropTypes from 'prop-types';
import { Form } from 'antd';
import { useTranslation } from 'react-i18next';
import { IconSvg } from 'components/common';
import { StyledIconDefault } from 'components/styledComponents/icon/Icon.styled';
import Buttons from 'components/common/button/Buttons';
import { StyledModal } from 'components/styledComponents/modal/Modal.styled';
import ChangeNameForm from 'components/brandSetting/ChangeNameForm';

const ChangeName = (props) => {
  const {
    visible, editBrand, onClose, brand,
  } = props;

  const { id, name } = brand;

  const { t } = useTranslation();

  const [form] = Form.useForm();
  const { validateFields } = form;

  const onApply = () => {
    validateFields().then((values) => {
      if (values.name !== name) {
        editBrand(id, values);
      } else {
        onClose();
      }
    });
  };

  return (
    <StyledModal
      maskClosable={false}
      destroyOnClose
      open={visible}
      title={t('edit')}
      closable
      onCancel={onClose}
      width="34.3rem"
      centered
      closeIcon={(
        <StyledIconDefault>
          <IconSvg icon="CloseModalIcon" width="1.14rem" height="1.14rem" />
        </StyledIconDefault>
      )}
      footer={<Buttons onClose={onClose} onApply={onApply} okText="apply" size="large" />}
    >
      <ChangeNameForm name={name} form={form} />
    </StyledModal>
  );
};

ChangeName.propTypes = {
  visible: PropTypes.bool,
  brand: PropTypes.object,
  editBrand: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

ChangeName.defaultProps = {
  visible: false,
  brand: {},
};

export default ChangeName;
