const selectPromoCodesPacks = (state) => state.promoCodesPacks;
export const selectPromoCodesPacksData = (state) => selectPromoCodesPacks(state).data;
export const selectPromoCodesPacksTotal = (state) => selectPromoCodesPacks(state).total;

export const selectPromoCode = (state) => state.promoCode;
export const selectPromoCodeName = (state) => selectPromoCode(state).promoName;

const selectUsedPromoCodes = (state) => state.usedPromoCodes;
export const selectUsedPromoCodesData = (state) => selectUsedPromoCodes(state).data;
export const selectUsedPromoCodesTotal = (state) => selectUsedPromoCodes(state).total;

export const selectPromoCodeExists = (state) => state.isPromoCodeExists;
export const selectPromoCodes = (state) => state.promoCodes;

export const selectCsvPromoCodes = (state) => state.csvPromoCodes;

export const selectPromoCodeEndedStatus = (state) => state.endedStatus;
