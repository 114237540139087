import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { StyledButtonBlue, StyledButtonWrapper, StyledButtonBlueUnbordered } from 'components/styledComponents/button/Button.styled';

const Buttons = (props) => {
  const {
    onClose, onApply, okText, cancelText, okDisabled, size, isCenter, cancelDisabled,
  } = props;

  const { t } = useTranslation();

  return (
    <StyledButtonWrapper isCenter={isCenter ? 1 : 0}>
      <StyledButtonBlueUnbordered type="submit" onClick={onClose} size={size} disabled={cancelDisabled}>
        {t(cancelText)}
      </StyledButtonBlueUnbordered>
      <StyledButtonBlue htmlType="submit" onClick={onApply} size={size} disabled={okDisabled}>
        {t(okText)}
      </StyledButtonBlue>
    </StyledButtonWrapper>
  );
};

Buttons.propTypes = {
  okText: PropTypes.string,
  cancelText: PropTypes.string,
  size: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  onApply: PropTypes.func.isRequired,
  isCenter: PropTypes.bool,
  okDisabled: PropTypes.bool,
  cancelDisabled: PropTypes.bool,
};

Buttons.defaultProps = {
  okText: 'save',
  cancelText: 'cancel',
  size: 'large',
  okDisabled: false,
  cancelDisabled: false,
  isCenter: false,
};
export default Buttons;
