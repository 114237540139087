import styled from 'styled-components';

const StyledCashbackCheckbox = styled.div`
  display: flex;
  flex-direction: column;
  margin: 2.5rem 0 0;
  .ant-checkbox-wrapper {
    margin-bottom: 1.14rem;
  }
  .ant-checkbox-wrapper + .ant-checkbox-wrapper {
    margin-left: 0;
  }
  .ant-checkbox + span {
    padding-left: 1.14rem;
  }
`;

const StyledTabMargin = styled.div`
  margin-top: 1rem;
`;
const StyledViewAmount = styled.div`
  display: flex;
  align-items: center;
  padding-left: 1.71rem;
  > p {
    margin-right: 3.57rem;
    font-size: 1rem;
  }
`;

const StyledGamesSegment = styled.div`
  p {
    margin: 1.14rem 0;
    font-size: 1rem;
  }
  margin-bottom: 0.71rem;
`;

const StyledAmountTable = styled.div`
  .ant-table .ant-table-tbody > tr:not(.ant-table-expanded-row) > td:not(.custom-checkbox):last-child {
    text-align: left;
  }
`;

const StyledMarginTopTable = styled.div`
  margin-top: 1.71rem;
  position: relative;
  z-index: 1;
`;
const StyledPeriodsInModal = styled.div`
  background: ${(props) => props.theme.colors.primaryBg};
  max-height: 14rem;
  border-radius: ${(props) => props.theme.customBorderRadius.borderRadiusSmall};
  overflow-y: auto;
  border-top: 10px solid ${(props) => props.theme.colors.primaryBg};
  border-bottom: 10px solid ${(props) => props.theme.colors.primaryBg};
`;

export {
  StyledMarginTopTable, StyledCashbackCheckbox, StyledGamesSegment, StyledAmountTable, StyledViewAmount, StyledTabMargin, StyledPeriodsInModal,
};
