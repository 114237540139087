import StaticCollectionActionType from './staticCollectionActionType';

export const getLanguages = (isLoading) => ({
  type: StaticCollectionActionType.GET_LANGUAGES,
  payload: { isLoading },
});

export const getLanguagesSuccess = (data) => ({
  type: StaticCollectionActionType.GET_LANGUAGES_SUCCESS,
  payload: { data },
});

export const getCurrencies = (isLoading) => ({
  type: StaticCollectionActionType.GET_CURRENCIES,
  payload: { isLoading },
});

export const getCurrenciesSuccess = (data) => ({
  type: StaticCollectionActionType.GET_CURRENCIES_SUCCESS,
  payload: { data },
});

export const getCountries = (isLoading) => ({
  type: StaticCollectionActionType.GET_COUNTRIES,
  payload: { isLoading },
});

export const getCountriesSuccess = (data) => ({
  type: StaticCollectionActionType.GET_COUNTRIES_SUCCESS,
  payload: { data },
});

export const changeSCLoadingState = (state) => ({
  type: StaticCollectionActionType.CHANGE_STATIC_COLLECTION_LOADING_STATE,
  payload: { state },
});

export const getCities = (params, isLoading) => ({
  type: StaticCollectionActionType.GET_CITIES,
  payload: { params, isLoading },
});

export const getCitiesSuccess = (data) => ({
  type: StaticCollectionActionType.GET_CITIES_SUCCESS,
  payload: { data },
});

export const resetCities = () => ({
  type: StaticCollectionActionType.RESET_CITIES,
});

export const getTimeZones = (params, isLoading) => ({
  type: StaticCollectionActionType.GET_TIME_ZONES,
  payload: { params, isLoading },
});

export const getTimeZonesSuccess = (data) => ({
  type: StaticCollectionActionType.GET_TIME_ZONES_SUCCESS,
  payload: { data },
});

export const getOperationTypes = (isLoading) => ({
  type: StaticCollectionActionType.GET_OPERATION_TYPES,
  payload: { isLoading },
});

export const saveOperationTypes = (data) => ({
  type: StaticCollectionActionType.SAVE_OPERATION_TYPES,
  payload: { data },
});

export const resetOperationTypes = () => ({
  type: StaticCollectionActionType.RESET_OPERATION_TYPES,
});

export const getRegions = (params, isLoading) => ({
  type: StaticCollectionActionType.GET_REGIONS,
  payload: { params, isLoading },
});

export const saveRegions = (data) => ({
  type: StaticCollectionActionType.SAVE_REGIONS,
  payload: { data },
});

export const resetRegions = () => ({
  type: StaticCollectionActionType.RESET_REGIONS,
});
