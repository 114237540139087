import PlayerSegmentActionType from './playerSegmentActionType';

export const getPlayersSegmentsResource = (data, isLoading) => ({
  type: PlayerSegmentActionType.GET_PLAYERS_SEGMENTS_RESOURCE,
  payload: { data, isLoading },
});

export const savePlayersSegmentsResource = (data) => ({
  type: PlayerSegmentActionType.SAVE_PLAYERS_SEGMENTS_RESOURCE,
  payload: { data },
});

export const resetPlayersSegmentsResource = () => ({
  type: PlayerSegmentActionType.RESET_PLAYERS_SEGMENTS_RESOURCE,
});

export const getSegmentPlayersResource = (segmentIds, isLoading) => ({
  type: PlayerSegmentActionType.GET_SEGMENT_PLAYERS_RESOURCE,
  payload: { segmentIds, isLoading },
});

export const saveSegmentPlayersResource = (data) => ({
  type: PlayerSegmentActionType.SAVE_SEGMENT_PLAYERS_RESOURCE,
  payload: { data },
});

export const resetSegmentPlayersResource = () => ({
  type: PlayerSegmentActionType.RESET_SEGMENT_PLAYERS_RESOURCE,
});

export const createPlayerSegment = (data) => ({
  type: PlayerSegmentActionType.CREATE_PLAYER_SEGMENT,
  payload: { data },
});

export const getPlayersSegments = (params) => ({
  type: PlayerSegmentActionType.GET_PLAYERS_SEGMENTS,
  payload: { params },
});

export const savePlayersSegments = (data) => ({
  type: PlayerSegmentActionType.SAVE_PLAYERS_SEGMENTS,
  payload: { data },
});

export const deletePlayerSegment = (id, name) => ({
  type: PlayerSegmentActionType.DELETE_PLAYER_SEGMENT,
  payload: { id, name },
});

export const editPlayerSegment = (id, data) => ({
  type: PlayerSegmentActionType.EDIT_PLAYER_SEGMENT,
  payload: { id, data },
});

export const getSegmentsPlayers = (segmentId, params) => ({
  type: PlayerSegmentActionType.GET_SEGMENTS_PLAYERS,
  payload: {
    segmentId,
    params,
  },
});

export const saveSegmentsPlayers = (data) => ({
  type: PlayerSegmentActionType.SAVE_SEGMENTS_PLAYERS,
  payload: { data },
});

export const deletePlayerSegmentPlayer = (id, name, segmentId) => ({
  type: PlayerSegmentActionType.DELETE_PLAYER_SEGMENT_PLAYER,
  payload: {
    id,
    name,
    segmentId,
  },
});

export const resetSegmentsPlayers = () => ({
  type: PlayerSegmentActionType.RESET_SEGMENTS_PLAYERS,
});

export const updatePlayerSegment = (data, segmentName) => ({
  type: PlayerSegmentActionType.UPDATE_PLAYER_SEGMENT,
  payload: { data, segmentName },
});

export const resetPlayersSegments = () => ({
  type: PlayerSegmentActionType.RESET_PLAYERS_SEGMENTS,
});

export const getPlayerIncludedSegments = (playerId, params) => ({
  type: PlayerSegmentActionType.GET_PLAYER_INCLUDED_SEGMENTS,
  payload: { playerId, params },
});

export const savePlayerIncludedSegments = (data) => ({
  type: PlayerSegmentActionType.SAVE_PLAYER_INCLUDED_SEGMENTS,
  payload: { data },
});

export const resetPlayerIncludedSegments = () => ({
  type: PlayerSegmentActionType.RESET_PLAYER_INCLUDED_SEGMENTS,
});

export const getPlayerExcludedSegments = (playerId, isLoading) => ({
  type: PlayerSegmentActionType.GET_PLAYER_EXCLUDED_SEGMENTS,
  payload: { playerId, isLoading },
});

export const savePlayerExcludedSegments = (data) => ({
  type: PlayerSegmentActionType.SAVE_PLAYER_EXCLUDED_SEGMENTS,
  payload: { data },
});

export const resetPlayerExcludedSegments = () => ({
  type: PlayerSegmentActionType.RESET_PLAYER_EXCLUDED_SEGMENTS,
});

export const deletePlayerFromSegment = (playerId, segmentName, params) => ({
  type: PlayerSegmentActionType.DELETE_PLAYER_FROM_SEGMENT,
  payload: { playerId, segmentName, params },
});

export const getStatusUpdatedPlayersSegments = (id, isLoading) => ({
  type: PlayerSegmentActionType.GET_STATUS_UPDATED_PLAYERS_SEGMENTS,
  payload: { id, isLoading },
});

export const saveStatusUpdatedPlayersSegments = (data) => ({
  type: PlayerSegmentActionType.SAVE_STATUS_UPDATED_PLAYERS_SEGMENTS,
  payload: { data },
});

export const resetStatusUpdatedPlayersSegments = () => ({
  type: PlayerSegmentActionType.RESET_STATUS_UPDATED_PLAYERS_SEGMENTS,
});

export const addPlayerToSegment = (segmentId, playerId, segmentName) => ({
  type: PlayerSegmentActionType.ADD_PLAYER_TO_SEGMENT,
  payload: { segmentId, playerId, segmentName },
});

export const getPlayerSegment = (segmentId) => ({
  type: PlayerSegmentActionType.GET_PLAYER_SEGMENT,
  payload: { segmentId },
});

export const savePlayerSegment = (data) => ({
  type: PlayerSegmentActionType.SAVE_PLAYER_SEGMENT,
  payload: { data },
});

export const resetPlayerSegment = () => ({
  type: PlayerSegmentActionType.RESET_PLAYER_SEGMENT,
});
