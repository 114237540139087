export default {
  en: {
    operationType: 'Operation Type',
    feeFromUsedPercent: 'Fee From Used, %',
    feeFromUnusedPercent: 'Fee From Unused, %',
    lPaymentMethod: 'payment method',
    failedPaymentSettings: 'Failed payment settings',
    paymentSettings: 'Payment settings',
    pay: 'Pay',
    decline: 'Decline',
    deposits: 'Deposits',
    withdrawals: 'Withdrawals',
    transactionInitData: 'Transaction Initial Data',
    transactionDetails: 'Transaction Details',
    betshop: 'Betshop',
    lastTag: 'Last Tag',
    viewTags: 'View Tags',
    taggedDate: 'Tagged Date',
    taggedBy: 'Tagged By',
    assignedTags: 'Assigned Tags',
    ruleId: 'Rule ID',
    automationId: 'Automation ID',
    withdrawalsWhichStatusWillChanged: 'Withdrawals which status will be changed',
    withdrawalsWhichStatusWillNotChanged: 'Withdrawals which status will not be changed',
    autoRefreshDepositsInfo: 'Activating auto-refresh mode in the report will display the deposits created within the last hour',
    autoRefreshWithdrawalsInfo: 'Activating auto-refresh mode in the report will display the withdrawals created within the last hour',
  },
  es: {
    operationType: 'Tipo de operación',
    feeFromUsedPercent: 'Cuota de uso, %',
    feeFromUnusedPercent: 'Cuota de no uso, %',
    lPaymentMethod: 'método de pago',
    failedPaymentSettings: 'Ajustes de pago fallidos',
    paymentSettings: 'Ajustes de pago',
    pay: 'Pagar',
    decline: 'Rechazar',
    deposits: 'Depósitos',
    withdrawals: 'Retiros',
    transactionInitData: 'Datos iniciales de la transacción',
    transactionDetails: 'Detalles de la transacción',
    betshop: 'Casa de Apuestas',
    lastTag: 'Última Etiqueta',
    viewTags: 'Ver Etiquetas',
    taggedDate: 'Fecha de Etiquetado',
    taggedBy: 'Etiquetado Por',
    assignedTags: 'Etiquetas Asignadas',
    ruleId: 'ID de Regla',
    automationId: 'ID de Automatización',
    withdrawalsWhichStatusWillChanged: 'Retiros cuyo estado será cambiado',
    withdrawalsWhichStatusWillNotChanged: 'Retiros cuyo estado no será cambiado',
  },
  tr: {
    operationType: 'Operasyon Türü',
    feeFromUsedPercent: 'Kullanılandan Ücret, %',
    feeFromUnusedPercent: 'Kullanılmayandan Ücret, %',
    lPaymentMethod: 'ödeme yöntemi',
    failedPaymentSettings: 'Ödeme ayarları başarısız',
    paymentSettings: 'Ödeme ayarları',
    pay: 'Öde',
    decline: 'Reddet',
    deposits: 'Para Yatırma İşlemleri',
    withdrawals: 'Para Çekme İşlemleri',
    transactionInitData: 'İşlem Başlangıç Verileri',
    transactionDetails: 'İşlem Detayları',
    betshop: 'Bayii',
    lastTag: 'Son Etiket',
    viewTags: 'Etiketleri Görüntüle',
    taggedDate: 'Etiketlenen Tarih',
    taggedBy: 'Etiketleyen',
    assignedTags: 'Atanmış Etiketler',
    ruleId: 'Kural ID',
    automationId: 'Otomasyon ID',
    withdrawalsWhichStatusWillChanged: 'Durumu değiştirilecek çekimler',
    withdrawalsWhichStatusWillNotChanged: 'Durumu değiştirilmeyecek çekimler',
  },
  ru: {
    operationType: 'Тип операции',
    feeFromUsedPercent: 'Комиссия с использованного, %',
    feeFromUnusedPercent: 'Комиссия с неиспользованного, %',
    lPaymentMethod: 'способ оплаты',
    failedPaymentSettings: 'Настройки отклоненных платежей',
    paymentSettings: 'Настройки платежей',
    pay: 'Оплата',
    decline: 'Отклонить',
    deposits: 'Депозиты',
    withdrawals: 'Вывод средств',
    transactionInitData: 'Исходные данные транзакции',
    transactionDetails: 'Подробности транзакции',
    betshop: 'Букмекерская контора',
    lastTag: 'Последний тег',
    viewTags: 'Смотреть теги',
    taggedDate: 'Дата по тегам',
    taggedBy: 'Отмечен тегом',
    assignedTags: 'Назначенные теги',
    ruleId: 'ID правила',
    automationId: 'ID автоматизации',
    withdrawalsWhichStatusWillChanged: 'Вывод средств, статус которого будет изменен',
    withdrawalsWhichStatusWillNotChanged: 'Вывод средств, статус которого не будет изменен',
  },
  pt: {
    operationType: 'Tipo de Operação',
    feeFromUsedPercent: 'Taxa de Utilizado, %',
    feeFromUnusedPercent: 'Taxa de Não Utilizado, %',
    lPaymentMethod: 'forma de pagamento',
    failedPaymentSettings: 'Falha nas configurações de pagamento',
    paymentSettings: 'Configurações de pagamento',
    pay: 'Pagar',
    decline: 'Recusar',
    deposits: 'Depósitos',
    withdrawals: 'Levantamentos',
    transactionInitData: 'Dados Iniciais da Transação',
    transactionDetails: 'Detalhes da Transação',
    betshop: 'Loja de apostas',
    lastTag: 'Última Tag',
    viewTags: 'Ver Tags',
    taggedDate: 'Data das etiquetas',
    taggedBy: 'Marcado por',
    assignedTags: 'Etiquetas atribuídas',
    ruleId: 'ID da Regra',
    automationId: 'ID de automatização',
    withdrawalsWhichStatusWillChanged: 'Retiradas cujo estado será alterado',
    withdrawalsWhichStatusWillNotChanged: 'Retiradas cujo estado não será alterado',
  },
  zh: {
    operationType: '运营类型',
    feeFromUsedPercent: '来自使用过的费用，％',
    feeFromUnusedPercent: '未使用的费用，％。',
    lPaymentMethod: '付款方式',
    failedPaymentSettings: '支付设置失败',
    paymentSettings: '付款设置',
    pay: '付款',
    decline: '拒绝',
    deposits: '存款',
    withdrawals: '取款',
    transactionInitData: '交易初始数据',
    transactionDetails: '交易细节',
    betshop: '投注站',
    lastTag: '最后标签',
    viewTags: '查看标签',
    taggedDate: '标签日期',
    taggedBy: '标签',
    assignedTags: '已分配标签',
    ruleId: '规则ID',
    automationId: '自动化ID',
    withdrawalsWhichStatusWillChanged: '将更改状态的提款',
    withdrawalsWhichStatusWillNotChanged: '状态不会改变的提款',
  },
  ro: {
    operationType: 'Tip de operație',
    feeFromUsedPercent: 'Taxa din utilizat, %',
    feeFromUnusedPercent: 'Taxa din neutilizat, %',
    lPaymentMethod: 'metodă de plată',
    failedPaymentSettings: 'Setări de plată eșuate',
    paymentSettings: 'Setări de plată',
    pay: 'Plată',
    decline: 'Respingere',
    deposits: 'Depuneri',
    withdrawals: 'Retrageri',
    transactionInitData: 'Data inițială de tranzacție',
    transactionDetails: 'Detalii de tranzacție',
    betshop: 'Case de pariuri',
  },
  bg: {},
  el: {
    operationType: 'Τύπος λειτουργίας',
    feeFromUsedPercent: 'Χρέωση από χρησιμοποιημένο, %',
    feeFromUnusedPercent: 'Χρέωση από αχρησιμοποίητο, %',
    lPaymentMethod: 'μέθοδος πληρωμής',
    failedPaymentSettings: 'Ρυθμίσεις αποτυχημένης πληρωμής',
    paymentSettings: 'Ρυθμίσεις πληρωμής',
    pay: 'Πληρωμή',
    decline: 'Απόρριψη',
    deposits: 'Καταθέσεις',
    withdrawals: 'Αναλήψεις',
    transactionInitData: 'Αρχικά δεδομένα συναλλαγής',
    transactionDetails: 'Στοιχεία συναλλαγής',
    betshop: 'Κατάστημα στοιχηματισμού',
    lastTag: 'Τελευταία ετικέτα',
    viewTags: 'Προβολή ετικετών',
    taggedDate: 'Ημερομηνία ανάθεσης ετικέτας',
    taggedBy: 'Η ετικέτα ανατέθηκε από',
    assignedTags: 'Ανατεθειμένες ετικέτες',
    ruleId: 'Αναγνωριστικό κανόνα',
  },
};
