import TagActionType from './tagActionType';

export const getVerticalTagCategories = (params) => ({
  type: TagActionType.GET_VERTICAL_TAG_CATEGORIES,
  payload: { params },
});

export const saveVerticalTagCategories = (data) => ({
  type: TagActionType.SAVE_VERTICAL_TAG_CATEGORIES,
  payload: { data },
});

export const resetVerticalTagCategories = () => ({
  type: TagActionType.RESET_VERTICAL_TAG_CATEGORIES,
});

export const createVerticalTagCategory = (data) => ({
  type: TagActionType.CREATE_VERTICAL_TAG_CATEGORY,
  payload: { data },
});

export const editVerticalTagCategory = (id, data) => ({
  type: TagActionType.EDIT_VERTICAL_TAG_CATEGORY,
  payload: { id, data },
});

export const getVerticalTagCategory = (id, params, isLoading) => ({
  type: TagActionType.GET_VERTICAL_TAG_CATEGORY,
  payload: { id, params, isLoading },
});

export const saveVerticalTagCategory = (data) => ({
  type: TagActionType.SAVE_VERTICAL_TAG_CATEGORY,
  payload: { data },
});

export const resetVerticalTagCategory = () => ({
  type: TagActionType.RESET_VERTICAL_TAG_CATEGORY,
});

export const deleteVerticalTagCategory = (id, params) => ({
  type: TagActionType.DELETE_VERTICAL_TAG_CATEGORY,
  payload: { id, params },
});

export const changeVerticalTagCategoryStatus = (id, params) => ({
  type: TagActionType.CHANGE_VERTICAL_TAG_CATEGORY_STATUS,
  payload: { id, params },
});

export const changeVerticalTagCategoryPriority = (id, params) => ({
  type: TagActionType.CHANGE_VERTICAL_TAG_CATEGORY_PRIORITY,
  payload: { id, params },
});

export const getHorizontalTagCategories = (params) => ({
  type: TagActionType.GET_HORIZONTAL_TAG_CATEGORIES,
  payload: { params },
});

export const saveHorizontalTagCategories = (data) => ({
  type: TagActionType.SAVE_HORIZONTAL_TAG_CATEGORIES,
  payload: { data },
});

export const resetHorizontalTagCategories = () => ({
  type: TagActionType.RESET_HORIZONTAL_TAG_CATEGORIES,
});

export const createHorizontalTagCategory = (data) => ({
  type: TagActionType.CREATE_HORIZONTAL_TAG_CATEGORY,
  payload: { data },
});

export const editHorizontalTagCategory = (id, data) => ({
  type: TagActionType.EDIT_HORIZONTAL_TAG_CATEGORY,
  payload: { id, data },
});

export const getHorizontalTagCategory = (id, params) => ({
  type: TagActionType.GET_HORIZONTAL_TAG_CATEGORY,
  payload: { id, params },
});

export const saveHorizontalTagCategory = (data) => ({
  type: TagActionType.SAVE_HORIZONTAL_TAG_CATEGORY,
  payload: { data },
});

export const resetHorizontalTagCategory = () => ({
  type: TagActionType.RESET_HORIZONTAL_TAG_CATEGORY,
});

export const deleteHorizontalTagCategory = (id, params) => ({
  type: TagActionType.DELETE_HORIZONTAL_TAG_CATEGORY,
  payload: { id, params },
});

export const getHorizontalTags = (categoryId, params) => ({
  type: TagActionType.GET_HORIZONTAL_TAGS,
  payload: { categoryId, params },
});

export const saveHorizontalTags = (data) => ({
  type: TagActionType.SAVE_HORIZONTAL_TAGS,
  payload: { data },
});

export const resetHorizontalTags = () => ({
  type: TagActionType.RESET_HORIZONTAL_TAGS,
});

export const getHorizontalTag = (id, params) => ({
  type: TagActionType.GET_HORIZONTAL_TAG,
  payload: { id, params },
});

export const saveHorizontalTag = (data) => ({
  type: TagActionType.SAVE_HORIZONTAL_TAG,
  payload: { data },
});

export const resetHorizontalTag = () => ({
  type: TagActionType.RESET_HORIZONTAL_TAG,
});

export const getVerticalTags = (params) => ({
  type: TagActionType.GET_VERTICAL_TAGS,
  payload: { params },
});

export const saveVerticalTags = (data) => ({
  type: TagActionType.SAVE_VERTICAL_TAGS,
  payload: { data },
});

export const resetVerticalTags = () => ({
  type: TagActionType.RESET_VERTICAL_TAGS,
});

export const getVerticalTag = (id, params, isLoading) => ({
  type: TagActionType.GET_VERTICAL_TAG,
  payload: { id, params, isLoading },
});

export const saveVerticalTag = (data) => ({
  type: TagActionType.SAVE_VERTICAL_TAG,
  payload: { data },
});

export const resetVerticalTag = () => ({
  type: TagActionType.RESET_VERTICAL_TAG,
});

export const createVerticalTags = (data) => ({
  type: TagActionType.CREATE_VERTICAL_TAGS,
  payload: { data },
});

export const createHorizontalTags = (data) => ({
  type: TagActionType.CREATE_HORIZONTAL_TAGS,
  payload: { data },
});

export const editHorizontalTag = (id, data) => ({
  type: TagActionType.EDIT_HORIZONTAL_TAG,
  payload: { id, data },
});

export const editVerticalTag = (id, data) => ({
  type: TagActionType.EDIT_VERTICAL_TAG,
  payload: { id, data },
});

export const deleteVerticalTags = (data, name) => ({
  type: TagActionType.DELETE_VERTICAL_TAGS,
  payload: { data, name },
});

export const deleteHorizontalTags = (data, name) => ({
  type: TagActionType.DELETE_HORIZONTAL_TAGS,
  payload: { data, name },
});

export const mergeHorizontalTags = (data) => ({
  type: TagActionType.MERGE_HORIZONTAL_TAGS,
  payload: { data },
});

export const mergeVerticalTags = (data) => ({
  type: TagActionType.MERGE_VERTICAL_TAGS,
  payload: { data },
});

export const moveHorizontalTags = (data) => ({
  type: TagActionType.MOVE_HORIZONTAL_TAGS,
  payload: { data },
});

export const changeVerticalTagStatus = (id, params) => ({
  type: TagActionType.CHANGE_VERTICAL_TAG_STATUS,
  payload: { id, params },
});

export const assignVerticalTagsPlayers = (data) => ({
  type: TagActionType.ASSIGN_VERTICAL_TAGS_PLAYERS,
  payload: { data },
});

export const assignHorizontalTagsPlayers = (data) => ({
  type: TagActionType.ASSIGN_HORIZONTAL_TAGS_PLAYERS,
  payload: { data },
});

export const getHorizontalTagsPlayers = (brandId, tagId, params) => ({
  type: TagActionType.GET_HORIZONTAL_TAGS_PLAYERS,
  payload: { brandId, tagId, params },
});

export const saveHorizontalTagsPlayers = (data) => ({
  type: TagActionType.SAVE_HORIZONTAL_TAGS_PLAYERS,
  payload: { data },
});

export const resetHorizontalTagsPlayers = () => ({
  type: TagActionType.RESET_HORIZONTAL_TAGS_PLAYERS,
});

export const getVerticalTagsPlayers = (brandId, tagId, params) => ({
  type: TagActionType.GET_VERTICAL_TAGS_PLAYERS,
  payload: { brandId, tagId, params },
});

export const saveVerticalTagsPlayers = (data) => ({
  type: TagActionType.SAVE_VERTICAL_TAGS_PLAYERS,
  payload: { data },
});

export const resetVerticalTagsPlayers = () => ({
  type: TagActionType.RESET_VERTICAL_TAGS_PLAYERS,
});

export const deletePlayersVerticalTags = (data) => ({
  type: TagActionType.DELETE_PLAYERS_VERTICAL_TAGS,
  payload: { data },
});

export const deletePlayersHorizontalTags = (data) => ({
  type: TagActionType.DELETE_PLAYERS_HORIZONTAL_TAGS,
  payload: { data },
});

export const getPlayerHorizontalTags = (playerId, params) => ({
  type: TagActionType.GET_PLAYER_HORIZONTAL_TAGS,
  payload: { playerId, params },
});

export const savePlayerHorizontalTags = (data) => ({
  type: TagActionType.SAVE_PLAYER_HORIZONTAL_TAGS,
  payload: { data },
});

export const resetPlayerHorizontalTags = () => ({
  type: TagActionType.RESET_PLAYER_HORIZONTAL_TAGS,
});

export const getPlayerVerticalTags = (playerId, params) => ({
  type: TagActionType.GET_PLAYER_VERTICAL_TAGS,
  payload: { playerId, params },
});

export const savePlayerVerticalTags = (data) => ({
  type: TagActionType.SAVE_PLAYER_VERTICAL_TAGS,
  payload: { data },
});

export const resetPlayerVerticalTags = () => ({
  type: TagActionType.RESET_PLAYER_VERTICAL_TAGS,
});

export const deletePlayerHorizontalTag = (id, name, data) => ({
  type: TagActionType.DELETE_PLAYER_HORIZONTAL_TAG,
  payload: { id, name, data },
});

export const deletePlayerVerticalTag = (id, name, data) => ({
  type: TagActionType.DELETE_PLAYER_VERTICAL_TAG,
  payload: { id, name, data },
});

export const assignVerticalTagPlayer = (brandId, playerId, data, isFromActions) => ({
  type: TagActionType.ASSIGN_VERTICAL_TAG_PLAYER,
  payload: {
    brandId,
    playerId,
    data,
    isFromActions,
  },
});

export const assignHorizontalTagsPlayer = (brandId, playerId, data, isFromActions) => ({
  type: TagActionType.ASSIGN_HORIZONTAL_TAGS_PLAYER,
  payload: {
    brandId,
    playerId,
    data,
    isFromActions,
  },
});

export const createHorizontalTagsAndAssignPlayer = (data) => ({
  type: TagActionType.CREATE_HORIZONTAL_TAGS_AND_ASSIGN_PLAYER,
  payload: { data },
});

export const getPlayerTopTags = (playerId, isLoading) => ({
  type: TagActionType.GET_PLAYER_TOP_TAGS,
  payload: { playerId, isLoading },
});

export const savePlayerTopTags = (data) => ({
  type: TagActionType.SAVE_PLAYER_TOP_TAGS,
  payload: { data },
});

export const resetPlayerTopTags = () => ({
  type: TagActionType.RESET_PLAYER_TOP_TAGS,
});

export const changePlayerTopTags = (status) => ({
  type: TagActionType.CHANGE_PLAYER_TOP_TAGS,
  payload: { status },
});

export const exportHorizontalTagsPlayers = (tagId, params) => ({
  type: TagActionType.EXPORT_HORIZONTAL_TAGS_PLAYERS,
  payload: { tagId, params },
});

export const exportVerticalTagsPlayers = (tagId, params) => ({
  type: TagActionType.EXPORT_VERTICAL_TAGS_PLAYERS,
  payload: { tagId, params },
});

export const getPlayerTagLogs = (playerId, params) => ({
  type: TagActionType.GET_PLAYER_TAG_LOGS,
  payload: { playerId, params },
});

export const savePlayerTagLogs = (data) => ({
  type: TagActionType.SAVE_PLAYER_TAG_LOGS,
  payload: { data },
});

export const resetPlayerTagLogs = () => ({
  type: TagActionType.RESET_PLAYER_TAG_LOGS,
});

export const exportPlayerTagLogs = (playerId, params) => ({
  type: TagActionType.EXPORT_PLAYER_TAG_LOGS,
  payload: { playerId, params },
});

export const getTagLogs = (params) => ({
  type: TagActionType.GET_TAG_LOGS,
  payload: { params },
});

export const saveTagLogs = (data) => ({
  type: TagActionType.SAVE_TAG_LOGS,
  payload: { data },
});

export const resetTagLogs = () => ({
  type: TagActionType.RESET_TAG_LOGS,
});

export const exportTagLogs = (params) => ({
  type: TagActionType.EXPORT_TAG_LOGS,
  payload: { params },
});
