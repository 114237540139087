import {
  put, call, takeLatest, all, select,
} from 'redux-saga/effects';
import qs from 'qs';
import { AppConstants } from 'constants/index';
import {
  changeEndedStatus, changeGenerateReportEndedStatus, changeLoadingState, setErrorMessage, setSuccessMessage,
} from 'actions/setting/settingActionCreator';
import NetworkService from 'services/networkService';
import { DateService, i18n } from 'services';
import { getError, getMessage } from 'utils/helpers';
import PushNotificationActionType from 'actions/pushNotifcation/pushNotificationActionType';
import {
  savePlayerPushNotifications,
  savePushNotification,
  savePushNotificationRecipientsStatistics,
  savePushNotificationTitleExist,
  saveScheduledNotifications,
  savePushNotificationRecipientsTotals,
  savePushNotificationRecipients,
  saveSentNotifications,
} from 'actions/pushNotifcation/pushNotificationActionCreator';
import { selectPushNotificationsRecipientsTotals } from 'selectors';

const { getFormattedDate } = DateService;

function* createPushNotification({ payload }) {
  try {
    const { data } = payload;
    const options = {
      data,
    };
    const { NotificationAdmin, PushNotifications } = AppConstants.api;
    yield put(changeLoadingState(true));
    yield call(NetworkService.makeAPIPostRequest, [NotificationAdmin, PushNotifications], options);
    const { scheduleDate } = data;
    let message = i18n.t('notification:slSent');
    if (scheduleDate) {
      message = i18n.t('notification:slScheduledForDate', { date: getFormattedDate(scheduleDate, AppConstants.format.dateTime) });
    }
    yield put(setSuccessMessage(getMessage(i18n.t('notification'), message)));
    yield put(changeLoadingState(false));
    yield put(changeEndedStatus(true));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getPushNotificationRecipientsStatistics({ payload }) {
  try {
    const options = {
      params: payload.params,
      paramsSerializer: (params) => qs.stringify(params),
    };
    const {
      NotificationAdmin, PushNotifications, Recipients, Statistics,
    } = AppConstants.api;
    yield put(changeLoadingState(true));
    const { data } = yield call(NetworkService.makeAPIGetRequest, [NotificationAdmin, PushNotifications, Recipients, Statistics], options);
    yield put(savePushNotificationRecipientsStatistics(data));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getSentNotifications({ payload }) {
  try {
    const options = {
      params: payload.params,
      paramsSerializer: (params) => qs.stringify(params),
    };
    yield put(changeLoadingState(true));
    const { NotificationAdmin, PushNotifications, Sent } = AppConstants.api;
    const { data } = yield call(NetworkService.makeAPIGetRequest, [NotificationAdmin, PushNotifications, Sent], options);
    yield put(saveSentNotifications(data));
    yield put(changeLoadingState(false));
  } catch (err) {
    yield put(changeLoadingState(false));
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
  }
}

function* getPushNotification({ payload }) {
  try {
    const { NotificationAdmin, PushNotifications } = AppConstants.api;
    yield put(changeLoadingState(true));
    const { data } = yield call(NetworkService.makeAPIGetRequest, [NotificationAdmin, PushNotifications, payload.id]);
    yield put(savePushNotification(data));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err, true);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* checkPushNotificationNameExists({ payload }) {
  try {
    const { params } = payload;
    const { NotificationAdmin, PushNotifications, NameExists } = AppConstants.api;
    const { data } = yield call(NetworkService.makeAPIGetRequest, [NotificationAdmin, PushNotifications, NameExists], { params });
    yield put(savePushNotificationTitleExist(data));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
  }
}
function* deletePushNotification({ payload }) {
  try {
    const { id, name } = payload;
    yield put(changeLoadingState(true));
    const { NotificationAdmin, PushNotifications } = AppConstants.api;
    yield call(NetworkService.makeAPIDeleteRequest, [NotificationAdmin, PushNotifications, id]);
    yield put(changeEndedStatus(true));
    yield put(setSuccessMessage(getMessage(i18n.t('communication:lNotification'), i18n.t('notification:slDeleted'), name)));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getPushNotificationRecipientsTotals({ payload }) {
  try {
    const { id } = payload;
    const { NotificationAdmin, PushNotifications, Details } = AppConstants.api;
    yield put(changeLoadingState(true));
    const { data } = yield call(NetworkService.makeAPIGetRequest, [NotificationAdmin, PushNotifications, id, Details]);
    const { pushNotification } = yield select();
    const details = selectPushNotificationsRecipientsTotals(pushNotification);
    details[id] = data;
    yield put(savePushNotificationRecipientsTotals(details));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err, true);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getPushNotificationRecipients({ payload }) {
  try {
    const { id, params } = payload;
    const options = {
      params,
      paramsSerializer: (param) => qs.stringify(param),
    };
    yield put(changeLoadingState(true));
    const { NotificationAdmin, PushNotifications, Recipients } = AppConstants.api;
    const { data } = yield call(NetworkService.makeAPIGetRequest, [NotificationAdmin, PushNotifications, id, Recipients], options);
    yield put(savePushNotificationRecipients(data));
    yield put(changeLoadingState(false));
  } catch (err) {
    yield put(changeLoadingState(false));
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
  }
}

function* getScheduledNotifications({ payload }) {
  try {
    const options = {
      params: payload.params,
      paramsSerializer: (params) => qs.stringify(params),
    };
    yield put(changeLoadingState(true));
    const { NotificationAdmin, PushNotifications, Scheduled } = AppConstants.api;
    const { data } = yield call(NetworkService.makeAPIGetRequest, [NotificationAdmin, PushNotifications, Scheduled], options);
    yield put(saveScheduledNotifications(data));
    yield put(changeLoadingState(false));
  } catch (err) {
    yield put(changeLoadingState(false));
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
  }
}

function* getPlayerPushNotifications({ payload }) {
  try {
    const { id, params } = payload;
    const options = {
      params,
      paramsSerializer: (param) => qs.stringify(param),
    };
    const {
      NotificationAdmin, PushNotifications, Players, Notifications,
    } = AppConstants.api;
    yield put(changeLoadingState(true));
    const { data } = yield call(NetworkService.makeAPIGetRequest, [NotificationAdmin, PushNotifications, Players, id, Notifications], options);
    yield put(savePlayerPushNotifications(data));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err, true);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* generatePushNotificationRecipients({ payload }) {
  try {
    const { id, data } = payload;
    const options = {
      params: data,
      paramsSerializer: (params) => qs.stringify(params, { allowDots: true }),
    };
    yield put(changeLoadingState(true));
    const {
      NotificationAdmin, PushNotifications, Recipients, Generate,
    } = AppConstants.api;
    yield call(NetworkService.makeAPIGetRequest, [NotificationAdmin, PushNotifications, id, Recipients, Generate], options);
    yield put(changeGenerateReportEndedStatus(true));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

export default function* pushNotificationSaga() {
  yield all([
    takeLatest(PushNotificationActionType.CREATE_PUSH_NOTIFICATION, createPushNotification),
    takeLatest(PushNotificationActionType.GET_PUSH_NOTIFICATION_RECIPIENTS_STATISTICS, getPushNotificationRecipientsStatistics),
    takeLatest(PushNotificationActionType.GET_SENT_PUSH_NOTIFICATIONS, getSentNotifications),
    takeLatest(PushNotificationActionType.GET_PUSH_NOTIFICATION, getPushNotification),
    takeLatest(PushNotificationActionType.CHECK_PUSH_NOTIFICATION_NAME_EXISTS, checkPushNotificationNameExists),
    takeLatest(PushNotificationActionType.DELETE_PUSH_NOTIFICATION, deletePushNotification),
    takeLatest(PushNotificationActionType.GET_PUSH_NOTIFICATION_RECIPIENTS_TOTALS, getPushNotificationRecipientsTotals),
    takeLatest(PushNotificationActionType.GET_PUSH_NOTIFICATION_RECIPIENTS, getPushNotificationRecipients),
    takeLatest(PushNotificationActionType.GET_SCHEDULED_PUSH_NOTIFICATIONS, getScheduledNotifications),
    takeLatest(PushNotificationActionType.GET_PLAYER_PUSH_NOTIFICATIONS, getPlayerPushNotifications),
    takeLatest(PushNotificationActionType.GENERATE_PUSH_NOTIFICATION_RECIPIENTS, generatePushNotificationRecipients),
  ]);
}
