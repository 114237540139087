const ExportCenterActionType = {
  GET_EXPORT_HISTORY: 'GET_EXPORT_HISTORY',
  SAVE_EXPORT_HISTORY: 'SAVE_EXPORT_HISTORY',
  RESET_EXPORT_HISTORY: 'RESET_EXPORT_HISTORY',

  DELETE_EXPORT_HISTORY_FILE: 'DELETE_EXPORT_HISTORY_FILE',

  CANCEL_EXPORT_HISTORY_FILE_GENERATION: 'CANCEL_EXPORT_HISTORY_FILE_GENERATION',
};

export default ExportCenterActionType;
