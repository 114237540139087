export default {
  en: {
    cashier: 'Cashier',
    paymentLogo: 'Payment Logo',
    methodName: 'Method Name',
    pgState: 'PG State',
    platformState: 'Platform State',
    allowedActivities: 'Allowed Activities',
    supportedCountries: 'Supported Countries',
    depositSupportedCurrencies: 'Deposit Supported Currencies',
    withdrawalSupportedCurrencies: 'Withdrawal Supported Currencies',
    showForTags: 'Show For Tags',
    hideForTags: 'Hide For Tags',
    depositProcessingPeriod: 'Deposit Processing Period',
    withdrawalProcessingPeriod: 'Withdrawal Processing Period',
    processingPeriod: 'Processing Period',
    info: 'Info',
    showInfoForDeposit: 'Show Info for Deposit',
    showInfoForWithdrawal: 'Show Info for Withdrawal',
    processingRange: 'Processing Range',
    categoryName: 'Category Name',
    lCategory: 'category',
    translationKey: 'Translation Key',
    translationKeyInfo: 'Make sure to add translations for the key, so that language-specific names are displayed on the website',
    deleteCategoryInfo: 'All the payment methods of the category will be moved to Other system category',
    cashierDeposit: 'Cashier Deposit',
    cashierWithdrawal: 'Cashier Withdrawal',
    depositState: 'Deposit State',
    withdrawalState: 'Withdrawal State',
    generalComponentsInfo: 'General Components are marked with',
    lSymbol: 'symbol',
    resetTranslationsInfo: 'Resetting will remove all custom translations in the current section.',
    exportTranslationInfo: 'Translation keys will be included',
    defaultTranslation: 'Default Translation',
    defaultTranslationInfo: 'Translations in this column will be applied when custom translation gets removed',
    addHintLanguage: 'Add Hint Language',
    selectHintsLanguage: 'Select Hints Language',
    deleteHintLanguage: 'Delete Hint Language',
    section: 'Section',
    chooseLanguage: 'Choose Language',
    availableTranslationsBySections: 'Available Translations by Sections',
    key: 'Key',
    availableTranslations: 'Available Translations',
    createFolder: 'Create Folder',
    moveTo: 'Move to',
    makeSureFileIsNotUsedInfo: 'Make sure this file is not used anywhere.',
    makeSureFilesAreNotUsedInfo: 'Make sure these files are not used anywhere.',
    folderName: 'Folder Name',
    filesUrlsChangedInfo: 'Files URLs will get changed.',
    searchFolder: 'Search Folder',
    move: 'Move',
    folders: 'Folders',
    rename: 'Rename',
    folderFilesDeletedInfo: 'Files in the folder will also be deleted.',
    totalSize: 'Total Size',
    contains: 'Contains',
    file: 'File',
    lFile: 'file',
    lFiles: 'files',
    theFile: 'The file',
    files: 'Files',
    lFolder: 'folder',
    folder: 'Folder',
    lFolders: 'folders',
    fileName: 'File Name',
    dropHereOrUseUploadButton: '<0>Drop files here</0><1> or use the Upload button.</1>',
    copyUrl: 'Copy URL',
    extension: 'Extension',
    size: 'Size',
    dimensions: 'Dimensions',
    url: 'URL',
    droppedFileInfo: 'Dropped files will be immediatly uploaded to this folder',
    theTranslation: 'The translation',
    lSection: 'section',
    thePaymentMethod: 'The payment method',
    theCategory: 'The category',
    cashierSettings: 'Cashier settings',
    fileOrFiles: 'File(s)',
    showingUpdates: 'Showing updates on the website can take up to 30 seconds.',
    inputLanguageKeyHere: '{{languageName}}: Input here',
    subCategory: 'Sub - Category',
    themes: 'Themes',
    systemState: 'System State',
    maintenanceState: 'Maintenance State',
    maintenanceDates: 'Maintenance Dates',
    newTill: 'New Till',
    fixedTill: 'Fixed Till',
    promoTill: 'Promo Till',
    dateTill: 'Date Till',
    newGame: 'New Game',
    fixedGame: 'Fixed Game',
    promoGame: 'Promo Game',
    minLimit: 'Min Limit',
    maxLimit: 'Max Limit',
    isCustom: 'Is Custom',
    standard: 'Standard',
    vertical: 'Vertical',
    horizontal: 'Horizontal',
    background: 'Background',
    maintenanceDate: 'Maintenance Date',
    lGame: 'game',
    infinity: 'Infinity',
    new: 'New',
    isNew: 'Is New',
    fixed: 'Fixed',
    isFixed: 'Is Fixed',
    promo: 'Promo',
    isPromo: 'Is Promo',
    gameSettings: 'Game Settings',
    maintenance: 'Maintenance',
    selectBadges: 'Select Badges',
    gameGroup: 'Game Group',
    badge: 'Badge',
    orderBy: 'Order By',
    createGroup: 'Create a Group',
    custom: 'Custom',
    lGroup: 'group',
    set: 'Set',
    groupType: 'Group Type',
    uploadListInfo: 'You can upload lists with game IDs. Only CSV format is acceptable',
    thePaymentMethods: 'The payment methods',
    gridType: 'Grid Type',
    providersPositioning: 'Providers Positioning',
    groups: 'Groups',
    gameDetailsPreviewInfo: 'The game details are shown on the website with previewing',
    gameDetailsCardInfo: 'The game details are shown on the website with Card Footer',
    providerGamesCount: 'Provider Games Count',
    secondaryOrdering: 'Secondary Ordering',
    advancedView: 'Advanced View',
    lobbyProviders: 'Lobby Providers',
    providerOrder: 'Provider Order',
    order: 'Order',
    gameOrder: 'Game Order',
    secondaryOrderingNote: 'Secondary Game Ordering works when  the config is active and only one  provider is selected, in any other case group specific ordering is applied.',
    providerOrderingNote: 'By default or when unchecked, provider alphabetic order is set. When checked, custom set order is applied',
    searchId: 'Search ID',
    uploadGames: 'Upload Games',
    uploadProviders: 'Upload Providers',
    providerOrdering: 'Provider Ordering',
    pageCap: 'Page Cap',
    playerSpecificGamesAllowed: 'Player Specific Games Allowed',
    loggedInOnly: 'Logged In Only',
    visibleInMyCasino: 'Visible in My Casino',
    visibleOnGameView: 'Visible on Game View',
    visibleOnTheLeftBarMenu: 'Visible on the Left Bar Menu',
    visibleOnMultiGameMenu: 'Visible on Multigame Menu',
    visibleOnNavigation: 'Visible on Navigation',
    myCasino: 'My Casino',
    gameView: 'Game View',
    leftBarMenu: 'Left Bar Menu',
    multiGameMenu: 'Multigame Menu',
    navigation: 'Navigation',
    lobbies: 'Lobbies',
    visibleOn: 'Visible On',
    optional: 'Optional',
    lobbyWidgetsJackpotInfo: 'Please note, only available for EGT jackpot feed',
    gameStatus: 'Game Status',
    customOrderUnavailable: 'In this state custom order is not available, you will be able to order games by suggested system order criteria',
    gamesInfoNote: 'Games Information Note',
    gamesInfoNoteHint: 'Games info note button gets available on the website',
    providerId: 'Provider ID',
    gameBackground: 'Game Background',
    gameBackgroundHint: 'If enabled, games background images get available on the website, when launching from the lobbies',
    myCasinoHint: 'If enabled, the "My Casino" sub-section gets available on website mobile view',
    media: 'Media',
    mobilePageCap: 'Page Cap for Mobile Apps',
    lGames: 'games',
    cmsNoAccessInfo: 'CMS Inventory, Deposit, Withdrawal for cashier section should be done from Paydrom admin panel',
    lGameType: 'game type',
    typeName: 'Type Name',
    gameVerticalType: 'Game Vertical Type',
    haveAssignedType: 'Have Assigned Type',
    createGameType: 'Create Game Type',
    jackpotAmountViewingType: 'Jackpot Amount Viewing Type',
    jackpotAmountOnHoverInfo: 'The jackpot amount is displayed when the user hovers over a game thumbnail in the lobby',
    jackpotAmountOnThumbnailInfo: 'The jackpot amount is displayed within the game thumbnail frame',
    gamesSeoPage: "Games' SEO page",
    visibleInGamesSeoPage: "Visible in Games' SEO page",
    gamesSeoPageNote: 'In case the setting is active games will be  available on a unique static page for slots.',
    providerViewingType: 'Provider Viewing Type',
    providerLogo: 'Provider Logo',
    createWidget: 'Create Widget',
    widgetName: 'Widget Name',
    widgetIcon: 'Widget Icon',
    widgetContentType: 'Content Type',
    desktopBackground: 'Desktop Background',
    mobileBackground: 'Mobile Background',
    gameMainSettings: 'Game Main Settings',
    imageGridType: 'Image Grid Type',
    small: 'Small',
    row: 'Row',
    gameCap: 'Game Cap',
    gameSelection: 'Game Selection',
    lobbyHome: 'Lobby Home',
    lobbyHomeHint: 'Specifies the availability of a static Widget-Base "Lobby Home" Page for the lobby.',
    navigationBar: 'Navigation Bar',
    navigationBarHint: 'Specifies the availability of a Navigation Bar for both Main Lobby and "Lobby Home" pages.',
    isSmallImageSizeInfo: 'In case Small is selected,  image sizes are changed to smaller sizes on the Website.',
    widgetVisibilityInfo: 'The Widget will be visible to logged in players only',
  },
  es: {
    cashier: 'Cajero',
    paymentLogo: 'Logo de pago',
    methodName: 'Nombre de método',
    pgState: 'Estado PG',
    platformState: 'Estado de la plataforma',
    allowedActivities: 'Actividades permitidas',
    supportedCountries: 'Países admitidos',
    depositSupportedCurrencies: 'Monedas admitidas para el depósito',
    withdrawalSupportedCurrencies: 'Monedas admitidas para la retirada',
    showForTags: 'Mostrar por etiquetas',
    hideForTags: 'Ocultar por etiquetas',
    depositProcessingPeriod: 'Periodo de procesamiento de depósitos',
    withdrawalProcessingPeriod: 'Periodo de procesamiento de retiradas',
    processingPeriod: 'Periodo de procesamiento',
    info: 'Información',
    showInfoForDeposit: 'Mostrar información del depósito',
    showInfoForWithdrawal: 'Mostrar información de la retirada',
    processingRange: 'Rango de procesamiento',
    categoryName: 'Nombre de categoría',
    lCategory: 'categoría',
    translationKey: 'Traducción de clave',
    translationKeyInfo: 'Asegúrese de añadir traducciones para la clave de modo que los nombres específicos de cada idioma se muestren en la página web',
    deleteCategoryInfo: 'Todos los métodos de pago de la categoría se trasladarán a la categoría Otro sistema',
    cashierDeposit: 'Depósito cajero',
    cashierWithdrawal: 'Retirada cajero',
    depositState: 'Estado depósito',
    withdrawalState: 'Estado retirada',
    generalComponentsInfo: 'Los componentes generales están marcados con',
    lSymbol: 'símbolo',
    resetTranslationsInfo: 'Reiniciar eliminará todas las traducciones personalizadas de la sección actual.',
    exportTranslationInfo: 'Se incluirán las traducciones de clave',
    defaultTranslation: 'Traducción predeterminada',
    defaultTranslationInfo: 'Las traducciones de esta columna se aplicarán cuando se elimine la traducción personalizada',
    addHintLanguage: 'Añadir idioma de las sugerencias',
    selectHintsLanguage: 'Seleccionar idioma de las sugerencias',
    deleteHintLanguage: 'Eliminar idioma de las sugerencias',
    section: 'Sección',
    chooseLanguage: 'Seleccionar idioma',
    availableTranslationsBySections: 'Traducciones disponibles por secciones',
    key: 'Clave',
    availableTranslations: 'Traducciones disponibles',
    createFolder: 'Crear carpeta',
    moveTo: 'Mover a',
    makeSureFileIsNotUsedInfo: 'Asegúrese de que este archivo no se utiliza en ningún sitio.',
    makeSureFilesAreNotUsedInfo: 'Asegúrese de que estos archivos no se utilizan en ningún sitio.',
    folderName: 'Nombre de la carpeta',
    filesUrlsChangedInfo: 'Las URL de los archivos se cambiarán.',
    searchFolder: 'Buscar Carpeta',
    move: 'Mover',
    folders: 'Carpetas',
    rename: 'Renombrar',
    folderFilesDeletedInfo: 'Los archivos de la carpeta también se eliminarán.',
    totalSize: 'Tamaño total',
    contains: 'Contiene',
    file: 'Archivo',
    lFile: 'archivo',
    lFiles: 'archivos',
    theFile: 'El archivo',
    files: 'Archivos',
    lFolder: 'carpeta',
    folder: 'Carpeta',
    lFolders: 'carpetas',
    fileName: 'Nombre de archivo',
    dropHereOrUseUploadButton: '<0>Arrastre aquí los archivos</0><1>o utilice el botón Subir.</1>',
    copyUrl: 'Copiar URL',
    extension: 'Extensión',
    size: 'Tamaño',
    dimensions: 'Dimensiones',
    url: 'URL',
    droppedFileInfo: 'Los archivos enviados se cargarán inmediatamente en esta carpeta',
    theTranslation: 'La traducción',
    lSection: 'sección',
    thePaymentMethod: 'El método de pago',
    theCategory: 'La categoría',
    cashierSettings: 'Opciones de cajero',
    fileOrFiles: 'Archivo(s)',
    showingUpdates: 'Mostrar las actualizaciones en el sitio web puede tardar hasta 30 segundos.',
    inputLanguageKeyHere: '{{languageName}}: Introduzca aquí',
    subCategory: 'Subcategoría',
    themes: 'Temas',
    systemState: 'Estado del sistema',
    maintenanceState: 'Estado del mantenimiento',
    maintenanceDates: 'Fechas del mantenimiento',
    newTill: 'Caja nueva',
    fixedTill: 'Caja fija',
    promoTill: 'Caja promocional',
    dateTill: 'Caja fecha',
    newGame: 'Juego nuevo',
    fixedGame: 'Juego fijo',
    promoGame: 'Juego promocional',
    minLimit: 'Límite mín.',
    maxLimit: 'Límite máx.',
    isCustom: 'Es personalizado',
    standard: 'Estándar',
    vertical: 'Vertical',
    horizontal: 'Horizontal',
    background: 'Fondo',
    maintenanceDate: 'Fecha de mantenimiento',
    lGame: 'juego',
    infinity: 'Infinito',
    new: 'Nuevo',
    isNew: 'Es nuevo',
    fixed: 'Fijo',
    isFixed: 'Es fijo',
    promo: 'Promo',
    isPromo: 'Es promo',
    gameSettings: 'Opciones de juego',
    maintenance: 'Opciones de juego',
    selectBadges: 'Seleccionar Insignias',
    gameGroup: 'Grupo de Juego',
    badge: 'Insignia',
    orderBy: 'Ordenar por',
    createGroup: 'Crear un Grupo',
    custom: 'Personalizado',
    lGroup: 'grupo',
    set: 'Seta',
    groupType: 'Tipo de Grupo',
    uploadListInfo: 'Puede cargar listas con ID de juegos. Sólo se acepta el formato CSV',
    thePaymentMethods: 'El Método de Pago',
    gridType: 'Tipo de Agarre',
    providersPositioning: 'Proveedores Posicionamiento',
    groups: 'Grupos',
    gameDetailsPreviewInfo: 'Los detalles del juego se muestran en el sitio web con vista previa',
    gameDetailsCardInfo: 'Los detalles del juego se muestran en el sitio web con el Card Footer',
    providerGamesCount: 'Recuento de Juegos de Proveedores',
    secondaryOrdering: 'Pedidos Secundarios',
    advancedView: 'Vista Avanzada',
    lobbyProviders: 'Proveedores de Lobby',
    providerOrder: 'Orden del Proveedor',
    order: 'Orden',
    gameOrder: 'Orden del Juego',
    secondaryOrderingNote:
      'El ordenamiento secundario de juegos funciona cuando la configuración está activa y solo se selecciona un proveedor; en cualquier otro caso, se aplica un ordenamiento específico por grupo.',
    providerOrderingNote: 'Por defecto o cuando no está marcado, se establece el orden alfabético del proveedor. Cuando está marcado, se aplica el orden personalizado establecido.',
    searchId: 'Buscar ID',
    uploadGames: 'Subir juegos',
    uploadProviders: 'Proveedores de Carga',
    providerOrdering: 'Ordenamiento de Proveedores',
    pageCap: 'Límite de Páginas',
    playerSpecificGamesAllowed: 'Juegos Permitidos para Jugadores Específicos',
    loggedInOnly: 'Solo para sesiones iniciadas',
    visibleInMyCasino: 'Visible en Mi Casino',
    visibleOnGameView: 'Visible en la Vista del Juego',
    visibleOnTheLeftBarMenu: 'Visible en el Menú de la Barra Izquierda',
    visibleOnMultiGameMenu: 'Visible en el Menú de Multijuegos',
    visibleOnNavigation: 'Visible en la Navegación',
    myCasino: 'Mi Casino',
    gameView: 'Vista del Juego',
    leftBarMenu: 'Menú de la Barra Izquierda',
    multiGameMenu: 'Menú Multijuegos',
    navigation: 'Navegación',
    lobbies: 'Salas de Espera',
    visibleOn: 'Visible en',
    optional: 'Opcional',
    lobbyWidgetsJackpotInfo: 'Por favor, ten en cuenta que solo está disponible para el feed de jackpot de EGT.',
    gameStatus: 'Estado del Juego',
    customOrderUnavailable: 'En este estado, el orden personalizado no está disponible. Podrás ordenar los juegos según los criterios de orden del sistema sugeridos.',
    gamesInfoNote: 'Nota de Información de Juegos',
    gamesInfoNoteHint: 'El botón de la nota informativa de juegos está disponible en el sitio web',
    providerId: 'ID del Proveedor',
    gameBackground: 'Antecedentes del Juego',
    gameBackgroundHint: 'Si está habilitado, las imágenes de fondo de los juegos estarán disponibles en el sitio web al iniciarlos desde el Inicio',
    myCasinoHint: 'Si está habilitado, la subsección "Mi Casino" estará disponible en la vista móvil del sitio web.',
    media: 'Media',
    mobilePageCap: 'Tope de Páginas para Apps Móviles',
    lGames: 'juegos',
    cmsNoAccessInfo: 'El inventario del CMS, depósitos y retiros para la sección de cajeros deben realizarse desde el panel de administración de Paydrom.',
    lGameType: 'Tipo de Juego',
    typeName: 'Tipo de Nombre',
    gameVerticalType: 'Tipo Vertical de Juego',
    haveAssignedType: 'Tipo Asignado',
    createGameType: 'Crear Tipo de Juego',
  },
  tr: {
    cashier: 'Kasiyer',
    paymentLogo: 'Ödeme Logosu',
    methodName: 'Yöntem Adı',
    pgState: 'PG Durumu',
    platformState: 'Platform Durumu',
    allowedActivities: 'İzin Verilen Faaliyetler',
    supportedCountries: 'Desteklenen Ülkeler',
    depositSupportedCurrencies: 'Para Yatırmak için Desteklenen Para Birimleri',
    withdrawalSupportedCurrencies: 'Para Çekme için Desteklenen Para Birimleri',
    showForTags: 'Etiketler için Göster',
    hideForTags: 'Etiketler için Gizle',
    depositProcessingPeriod: 'Para Yatırma İşlem Süresi',
    withdrawalProcessingPeriod: 'Para Çekme İşlem Süresi',
    processingPeriod: 'İşlem Süresi',
    info: 'Bilgi',
    showInfoForDeposit: 'Para Yatırma Bilgilerini Göster',
    showInfoForWithdrawal: 'Para Çekme Bilgilerini Göster',
    processingRange: 'İşlem Aralığı',
    categoryName: 'Kategori Adı',
    lCategory: 'kategori',
    translationKey: 'Çeviri Anahtarı',
    translationKeyInfo: 'Web sitesinde dile özgü adların görüntülenmesini sağlayan anahtar için çeviriler eklediğinizden emin olun.',
    deleteCategoryInfo: 'Kategorinin tüm ödeme yöntemleri Diğer sistem kategorisine taşınacaktır.',
    cashierDeposit: 'Para Yatırma Kasası',
    cashierWithdrawal: 'Para Çekme Kasası',
    depositState: 'Para Yatırma Durumu',
    withdrawalState: 'Para Çekme Durumu',
    generalComponentsInfo: 'Genel Bileşenler ile işaretlenmiştir',
    lSymbol: 'sembol',
    resetTranslationsInfo: 'Sıfırlama, mevcut bölümdeki tüm özel çevirileri kaldırır.',
    exportTranslationInfo: 'Çeviri anahtarları dahil edilecek',
    defaultTranslation: 'Varsayılan Çeviri',
    defaultTranslationInfo: 'Özel çeviri kaldırıldığında bu sütundaki çeviriler uygulanacaktır',
    addHintLanguage: 'İpucu Dili Ekle',
    selectHintsLanguage: 'İpuçları Dilini Seçin',
    deleteHintLanguage: 'İpucu Dilini Sil',
    section: 'Bölüm',
    chooseLanguage: 'Dil seçin',
    availableTranslationsBySections: 'Bölümlere Göre Mevcut Çeviriler',
    key: 'Anahtar',
    availableTranslations: 'Mevcut Çeviriler',
    createFolder: 'Klasör Oluştur',
    moveTo: 'Taşı',
    makeSureFileIsNotUsedInfo: 'Bu dosyanın hiçbir yerde kullanılmadığından emin olun.',
    makeSureFilesAreNotUsedInfo: 'Bu dosyaların hiçbir yerde kullanılmadığından emin olun.',
    folderName: 'Klasör Adı',
    filesUrlsChangedInfo: "Dosya URL'leri değişecek.",
    searchFolder: 'Arama Klasörü',
    move: 'Taşı',
    folders: 'Klasörler',
    rename: 'Yeniden isimlendir',
    folderFilesDeletedInfo: 'Klasördeki dosyalar da silinecektir.',
    totalSize: 'Toplam Boyut',
    contains: 'İçeren',
    file: 'Dosya',
    lFile: 'dosya',
    lFiles: 'Dosyalar',
    theFile: 'Dosya',
    files: 'Dosyalar',
    lFolder: 'klasör',
    folder: 'Klasör',
    lFolders: 'klasörler',
    fileName: 'Dosya Adı',
    dropHereOrUseUploadButton: '‎<0>Dosyaları buraya bırakın</0><1>veya Yükle butonunu kullanın.</1>',
    copyUrl: "Url'yi kopyala",
    extension: 'Uzantı',
    size: 'Boyut',
    dimensions: 'Boyutlar',
    url: 'URL',
    droppedFileInfo: 'Bırakılan dosyalar hemen bu klasöre yüklenecek',
    theTranslation: 'Çeviri',
    lSection: 'bölüm',
    thePaymentMethod: 'Ödeme yöntemi',
    theCategory: 'Kategori',
    cashierSettings: 'Kasa ayarları',
    fileOrFiles: 'Dosya(lar)',
    showingUpdates: 'Web sitesinde güncellemelerin gösterilmesi 30 saniye kadar sürebilir.',
    inputLanguageKeyHere: '‎{{languageName}}: Buraya girin',
    subCategory: 'Alt - Kategori',
    themes: 'Temalar',
    systemState: 'Sistem Durumu',
    maintenanceState: 'Bakım Durumu',
    maintenanceDates: 'Bakım Tarihleri',
    newTill: 'Yeni:',
    fixedTill: 'Sabit:',
    promoTill: 'Promosyon:',
    dateTill: 'Tarih:',
    newGame: 'Yeni oyun',
    fixedGame: 'Sabit Oyun',
    promoGame: 'Promosyon Oyunu',
    minLimit: 'Minimum Limit',
    maxLimit: 'Maksimum Limit',
    isCustom: 'Özel',
    standard: 'Standart',
    vertical: 'Dikey',
    horizontal: 'Yatay',
    background: 'Arka Plan',
    maintenanceDate: 'Bakım Tarihi',
    lGame: 'oyun',
    infinity: 'Sonsuzluk',
    new: 'Yeni',
    isNew: 'Yeni',
    fixed: 'Sabit',
    isFixed: 'Sabit',
    promo: 'Promosyon',
    isPromo: 'Promosyon',
    gameSettings: 'Oyun ayarları',
    maintenance: 'Bakım',
    selectBadges: 'Rozetleri Seçin',
    gameGroup: 'Oyun Grubu',
    badge: 'Rozet',
    orderBy: 'Sıraya Göre',
    createGroup: 'Grup Oluşturma',
    custom: 'Özel',
    lGroup: 'grup',
    set: 'Ayarla',
    groupType: 'Grup Türü',
    uploadListInfo: "Listeleri oyun ID'leri ile yükleyebilirsiniz. Sadece CSV formatı kabul edilir",
    thePaymentMethods: 'Ödeme yöntemleri',
    gridType: 'Grid Türü',
    providersPositioning: 'Sağlayıcıların Konumlandırılması',
    groups: 'Gruplar',
    gameDetailsPreviewInfo: 'Oyun detayları web sitesinde ön izleme ile gösterilir',
    gameDetailsCardInfo: 'Oyun detayları web sitesinde Kart Altbilgisi ile gösterilir',
    providerGamesCount: 'Sağlayıcı Oyun Sayısı',
    secondaryOrdering: 'İkincil Sıralama',
    advancedView: 'Gelişmiş Görünüm',
    lobbyProviders: 'Lobi Sağlayıcılar',
    providerOrder: 'Sağlayıcı Sıralama',
    order: 'Sıralama',
    gameOrder: 'Oyun Sıralaması',
    secondaryOrderingNote: 'İkincil Oyun Sıralaması, yapılandırma etkin olduğunda ve yalnızca bir sağlayıcı seçildiğinde çalışır, diğer tüm durumlarda gruba özel sıralama uygulanır.',
    providerOrderingNote: 'Varsayılan olarak veya işaretlenmediğinde, sağlayıcının alfabetik sıralaması uygulanır. İşaretlendiğinde, özel ayar sıralaması uygulanır',
    searchId: 'ID Arama',
    uploadGames: 'Oyun Yükle ',
    uploadProviders: 'Sağlayıcıları Yükle',
    providerOrdering: 'Sağlayıcı Sıralaması',
    pageCap: 'Sayfa Başlığı',
    playerSpecificGamesAllowed: 'Oyuncuya Özel Oyunlara İzin Verildi',
    loggedInOnly: 'Yalnızca Oturum Açılan',
    visibleInMyCasino: 'Casinomda Görünür',
    visibleOnGameView: 'Oyun Görünümünde Görünür',
    visibleOnTheLeftBarMenu: 'Sol Çubuk Menüsünde Görünür',
    visibleOnMultiGameMenu: 'Çoklu Oyun Menüsünde Görünür',
    visibleOnNavigation: 'Navigasyonda Görünür',
    myCasino: "Casino'm",
    gameView: 'Oyun Görünümü',
    leftBarMenu: 'Sol Çubuk Menüsü',
    multiGameMenu: 'Çoklu Oyun Menüsü',
    navigation: 'Navigasyon',
    lobbies: 'Lobiler',
    visibleOn: 'Görünür',
    optional: 'Opsiyonel',
    lobbyWidgetsJackpotInfo: 'Lütfen unutmayın, sadece EGT jackpot feed için kullanılabilir',
    gameStatus: 'Oyun Durumu',
    customOrderUnavailable: 'Bu durumda özel sıralama mevcut değildir, önerilen sistem sıralama kriterlerine göre oyun sıralaması yapabileceksiniz',
    gamesInfoNote: 'Oyun Bilgi Notu',
    gamesInfoNoteHint: 'Oyun bilgi notu butonu web sitesinde kullanılabilir ',
    providerId: 'Sağlayıcı ID',
    gameBackground: 'Oyun Arka Planı',
    gameBackgroundHint: 'Etkinleştirilirse, oyunların arka plan görüntüleri lobilerden başlatıldığında web sitesinde görünür ',
    myCasinoHint: 'Etkinleştirilirse, "Casino\'m" alt bölümü web sitesinin mobil görünümünde mevcut olur',
    media: 'Medya',
    mobilePageCap: 'Mobil Uygulamalar için Sayfa Başlığı',
    lGames: 'oyunlar',
    cmsNoAccessInfo: 'Kasiyer bölümü için CMS Envanter, Para Yatırma, Para Çekme işlemleri Paydrom yönetici panelinden yapılmalıdır',
    lGameType: 'oyun türü',
    typeName: 'Adını yazın',
    gameVerticalType: 'Oyun Dikey Türü',
    haveAssignedType: 'Atanan tür',
    createGameType: 'Oyun Türü Oluştur',
  },
  ru: {
    cashier: 'Касса',
    paymentLogo: 'Логотип платежной системы',
    methodName: 'Название метода',
    pgState: 'Состояние PG',
    platformState: 'Состояние платформы',
    allowedActivities: 'Разрешенные действия',
    supportedCountries: 'Поддерживаемые страны',
    depositSupportedCurrencies: 'Поддерживаемые валюты для внесения средств',
    withdrawalSupportedCurrencies: 'Поддерживаемые валюты для вывода средств',
    showForTags: 'Показать теги',
    hideForTags: 'Скрыть теги',
    depositProcessingPeriod: 'Период обработки депозитов',
    withdrawalProcessingPeriod: 'Вид обработки вывода средств',
    processingPeriod: 'Период обработки',
    info: 'Информация',
    showInfoForDeposit: 'Показать информацию по депозитам',
    showInfoForWithdrawal: 'Показать информацию по выводу средств',
    processingRange: 'Диапазон обработки',
    categoryName: 'Название категории',
    lCategory: 'категория',
    translationKey: 'Ключ перевода',
    translationKeyInfo: 'Обязательно добавьте переводы для ключа, чтобы на сайте отображались названия для конкретных языков.',
    deleteCategoryInfo: 'Все методы оплаты этой категории будут перемещены в системную категорию «Другие»',
    cashierDeposit: 'Внесение средств через кассу',
    cashierWithdrawal: 'Вывод средств через кассу',
    depositState: 'Состояние депозитов',
    withdrawalState: 'Состояние вывода средств',
    generalComponentsInfo: 'Общие компоненты отмечены',
    lSymbol: 'символ',
    resetTranslationsInfo: 'Сброс удалит все пользовательские переводы в выбранном разделе.',
    exportTranslationInfo: 'Ключи перевода будут включены',
    defaultTranslation: 'Стандартный перевод',
    defaultTranslationInfo: 'После удаления пользовательского перевода будет использоваться перевод из этой колонки',
    addHintLanguage: 'Добавить язык подсказок',
    selectHintsLanguage: 'Выбрать язык подсказок',
    deleteHintLanguage: 'Удалить язык подсказок',
    section: 'Раздел',
    chooseLanguage: 'Выбрать язык',
    availableTranslationsBySections: 'Доступный перевод по разделам',
    key: 'Ключ',
    availableTranslations: 'Доступные переводы',
    createFolder: 'Создать папку',
    moveTo: 'Переместить в',
    makeSureFileIsNotUsedInfo: 'Убедитесь, что этот файл нигде не используется.',
    makeSureFilesAreNotUsedInfo: 'Убедитесь, что эти файлы нигде не используются.',
    folderName: 'Название папки',
    filesUrlsChangedInfo: 'URL файлов будут изменены.',
    searchFolder: 'Найти папку',
    move: 'Переместить',
    folders: 'Папки',
    rename: 'Переименовать',
    folderFilesDeletedInfo: 'Файлы в папке также будут удалены.',
    totalSize: 'Общий размер',
    contains: 'Содержит',
    file: 'Файл',
    lFile: 'файл',
    lFiles: 'файлы',
    theFile: 'Файл',
    files: 'Файлы',
    lFolder: 'папка',
    folder: 'Папка',
    lFolders: 'папки',
    fileName: 'Название файла',
    dropHereOrUseUploadButton: '<0>Перетащите файлы сюда</0><1>или используйте кнопку «Загрузить».</1>',
    copyUrl: 'Копировать URL',
    extension: 'Расширение',
    size: 'Размер',
    dimensions: 'Параметры',
    url: 'URL',
    droppedFileInfo: 'Перетянутые файлы будут сразу же загружены в эту папку',
    theTranslation: 'Перевод',
    lSection: 'раздел',
    thePaymentMethod: 'Способ оплаты',
    theCategory: 'Категория',
    cashierSettings: 'Настройки кассы',
    fileOrFiles: 'Файл(ы)',
    showingUpdates: 'Отображение обновлений на сайте может занять до 30 секунд.',
    inputLanguageKeyHere: '{{languageName}}: Введите сюда',
    subCategory: 'Подкатегория',
    themes: 'Темы',
    systemState: 'Состояние системы',
    maintenanceState: 'Состояние обслуживания',
    maintenanceDates: 'Даты обслуживания',
    newTill: 'Новая касса',
    fixedTill: 'Фиксированная касса',
    promoTill: 'Промокасса',
    dateTill: 'Дата кассы',
    newGame: 'Новая игра',
    fixedGame: 'Фиксированная игра',
    promoGame: 'Промо-игра',
    minLimit: 'Мин. лимит',
    maxLimit: 'Макс. лимит',
    isCustom: 'Пользовательские настройки',
    standard: 'Стандартные',
    vertical: 'Вертикальные',
    horizontal: 'Горизонтальные',
    background: 'Фон',
    maintenanceDate: 'Дата обслуживания',
    lGame: 'игра',
    infinity: 'Бесконечность',
    new: 'Новые',
    isNew: 'Новый(-ая)',
    fixed: 'Фиксированные',
    isFixed: 'Фиксированный(-ая)',
    promo: 'Промо',
    isPromo: 'Промо',
    gameSettings: 'Настройки игры',
    maintenance: 'Обслуживание',
    selectBadges: 'Выбрать значки',
    gameGroup: 'Группа игр',
    badge: 'Значок',
    orderBy: 'Упорядочить по',
    createGroup: 'Создать группу',
    custom: 'Пользовательский',
    lGroup: 'группа',
    set: 'Установить',
    groupType: 'Тип группы',
    uploadListInfo: 'Вы можете загружать списки с игровыми ID. Принимается только формат CSV',
    thePaymentMethods: 'Способы оплаты',
    gridType: 'Тип сетки',
    providersPositioning: 'Порядок размещения провайдеров',
    groups: 'Группы',
    gameDetailsPreviewInfo: 'Детали игры отображаются на сайте с возможностью предварительного просмотра',
    gameDetailsCardInfo: 'Детали игры показаны на веб-сайте с помощью Card Footer.',
    providerGamesCount: 'Количество игр провайдера',
    secondaryOrdering: 'Вторичное упорядочение',
    advancedView: 'Расширенный вид',
    lobbyProviders: 'Провайдеры лобби',
    providerOrder: 'Порядок провайдера',
    order: 'Порядок',
    gameOrder: 'Порядок игры',
    secondaryOrderingNote: 'Вторичное упорядочивание игр работает, когда конфигурация активна и выбран только один провайдер, в любом другом случае применяется групповое упорядочивание.',
    providerOrderingNote: 'По умолчанию или если флажок снят, устанавливается алфавитный порядок провайдера. Если флажок установлен, применяется пользовательский порядок',
    searchId: 'ID поиска',
    uploadGames: 'Загрузить игры',
    uploadProviders: 'Загрузить провайдеров',
    providerOrdering: 'Упорядочивание провайдеров',
    pageCap: 'Шапка страницы',
    playerSpecificGamesAllowed: 'Разрешенные игры для конкретного игрока',
    loggedInOnly: 'Только для входа в систему',
    visibleInMyCasino: 'Видно в Моем казино',
    visibleOnGameView: 'Видно в режиме просмотра игры',
    visibleOnTheLeftBarMenu: 'Видно в меню левой панели',
    visibleOnMultiGameMenu: 'Видно в меню нескольких игр',
    visibleOnNavigation: 'Видно в навигации',
    myCasino: 'Мое казино',
    gameView: 'Вид игры',
    leftBarMenu: 'Меню левой панели',
    multiGameMenu: 'Меню нескольких игр',
    navigation: 'Навигация',
    lobbies: 'Лобби',
    visibleOn: 'Видно на',
    optional: 'Дополнительно',
    lobbyWidgetsJackpotInfo: 'Обратите внимание, доступно только для подачи джекпота EGT',
    gameStatus: 'Статус игры',
    customOrderUnavailable: 'В этом состоянии пользовательский заказ недоступен, вы сможете заказать игры по предложенным системным критериям заказа',
    gamesInfoNote: 'Информационная заметка об играх',
    gamesInfoNoteHint: 'Кнопка информационной заметки об играх становится доступной на сайте',
    providerId: 'ID провайдера',
    gameBackground: 'Фон игры',
    gameBackgroundHint: 'Если эта функция включена, фоновые изображения игр будут доступны на сайте при запуске из лобби',
    myCasinoHint: 'Если эта функция включена, то подраздел «Мое казино» становится доступным при просмотре мобильной версии сайта',
    media: 'Медиа',
    mobilePageCap: 'Шапка страницы для мобильных приложений',
    lGames: 'игры',
    cmsNoAccessInfo: 'CMS инвентаризация, депозит, вывод средств для раздела кассы должны осуществляться из панели администратора Paydrom',
    lGameType: 'тип игры',
    typeName: 'Название типа',
    gameVerticalType: 'Вертикальный тип игры',
    haveAssignedType: 'Назначенный тип',
    createGameType: 'Создать тип игры',
  },
  pt: {
    cashier: 'Caixa',
    paymentLogo: 'Logótipo de Pagamento',
    methodName: 'Nome do Método',
    pgState: 'Estado PG',
    platformState: 'Estado da Plataforma',
    allowedActivities: 'Atividades Permitidas',
    supportedCountries: 'Países Compatíveis',
    depositSupportedCurrencies: 'Moedas Compatíveis para Depósito',
    withdrawalSupportedCurrencies: 'Moedas Compatíveis para Levantamento',
    showForTags: 'Mostrar para Tags',
    hideForTags: 'Ocultar para Tags',
    depositProcessingPeriod: 'Período de Processamento de Depósito',
    withdrawalProcessingPeriod: 'Período de Processamento de Levantamento',
    processingPeriod: 'Período de Processamento',
    info: 'Informação',
    showInfoForDeposit: 'Mostrar Informação para Depósito',
    showInfoForWithdrawal: 'Mostrar Informação para Levantamento',
    processingRange: 'Intervalo de Processamento',
    categoryName: 'Nome da Categoria',
    lCategory: 'categoria',
    translationKey: 'Chave de Tradução',
    translationKeyInfo: 'Certifique-se de que adiciona traduções para a chave, por forma a que os nomes específicos dos idiomas sejam exibidos no site',
    deleteCategoryInfo: 'Todos os métodos de pagamento da categoria serão movidos para a categoria de sistema Outros',
    cashierDeposit: 'Depósito em Caixa',
    cashierWithdrawal: 'Levantamento em Caixa',
    depositState: 'Estado do Depósito',
    withdrawalState: 'Estado do Levantamento',
    generalComponentsInfo: 'Os Componentes Gerais são marcados com',
    lSymbol: 'símbolo',
    resetTranslationsInfo: 'Ao reiniciar, removerá todas as traduções personalizadas na seção atual.',
    exportTranslationInfo: 'As chaves de tradução serão incluídas',
    defaultTranslation: 'Tradução Padrão',
    defaultTranslationInfo: 'As traduções nesta coluna serão aplicadas quando a tradução personalizada for removida.',
    addHintLanguage: 'Acrescentar Idioma de Dicas',
    selectHintsLanguage: 'Selecionar Idioma de Dicas',
    deleteHintLanguage: 'Excluir Idimoma de Dicas',
    section: 'Seção',
    chooseLanguage: 'Escolha o Idioma',
    availableTranslationsBySections: 'Traduções Disponíveis por Seções',
    key: 'Chave',
    availableTranslations: 'Traduções Disponíveis',
    createFolder: 'Criar Pasta',
    moveTo: 'Mover para',
    makeSureFileIsNotUsedInfo: 'Certifique-se de que este arquivo não é utilizado em qualquer lugar.',
    makeSureFilesAreNotUsedInfo: 'Certifique-se de que estes arquivos não sejam utilizados em qualquer lugar.',
    folderName: 'Nome da Pasta',
    filesUrlsChangedInfo: 'As URLs dos arquivos serão alteradas.',
    searchFolder: 'Procurar Pasta',
    move: 'Mover',
    folders: 'Pastas',
    rename: 'Renomear',
    folderFilesDeletedInfo: 'Os arquivos na pasta também serão excluídos.',
    totalSize: 'Tamanho Total',
    contains: 'Contém',
    file: 'Arquivo',
    lFile: 'arquivo',
    lFiles: 'arquivos',
    theFile: 'O arquivo',
    files: 'Arquivos',
    lFolder: 'pasta',
    folder: 'Pasta',
    lFolders: 'pastas',
    fileName: 'Nome do Arquivo',
    dropHereOrUseUploadButton: '<0>Solte os arquivos aqui</0><1> ou use o botão Upload.</1>',
    copyUrl: 'Copiar URL',
    extension: 'Extensão',
    size: 'Tamanho',
    dimensions: 'Dimensões',
    url: 'URL',
    droppedFileInfo: 'Os arquivos soltos aqui serão imediatamente carregados para esta pasta',
    theTranslation: 'A tradução',
    lSection: 'seção',
    thePaymentMethod: 'O método de pagamento',
    theCategory: 'A categoria',
    cashierSettings: 'Configurações do caixa',
    fileOrFiles: 'Arquivo(s)',
    showingUpdates: 'Pode levar até 30 segundos para as atualizações serem exibidas no site.',
    inputLanguageKeyHere: '{{languageName}}: Informações aqui',
    subCategory: 'Sub - Categoria',
    themes: 'Temas',
    systemState: 'Estado do Sistema',
    maintenanceState: 'Estado de Manutenção',
    maintenanceDates: 'Datas de Manutenção',
    newTill: 'Nova Caixa',
    fixedTill: 'Caixa Fixa',
    promoTill: 'Caixa Promocional',
    dateTill: 'Datar a Caixa',
    newGame: 'Novo Jogo',
    fixedGame: 'Jogo Fixo',
    promoGame: 'Jogo Promocional',
    minLimit: 'Limite Mín.',
    maxLimit: 'Limite Máx.',
    isCustom: 'É Personalizado',
    standard: 'Padrão',
    vertical: 'Vertical',
    horizontal: 'Horizontal',
    background: 'Fundo',
    maintenanceDate: 'Data de Manutenção',
    lGame: 'jogo',
    infinity: 'Infinito',
    new: 'Novo',
    isNew: 'É Novo',
    fixed: 'Fixo',
    isFixed: 'Está Fixo',
    promo: 'Promoção',
    isPromo: 'É Promoção',
    gameSettings: 'Configurações do jogo',
    maintenance: 'Manutenção',
    selectBadges: 'Selecionar Distintivos',
    gameGroup: 'Grupo do Jogo',
    badge: 'Distintivo',
    orderBy: 'Ordenar por',
    createGroup: 'Criar um Grupo',
    custom: 'Personalizado',
    lGroup: 'grupo',
    set: 'Conjunto',
    groupType: 'Tipo de Grupo',
    uploadListInfo: 'Pode carregar listas com as ID dos jogos. É aceite apenas o formato CSV',
    thePaymentMethods: 'As formas de pagamento',
    gridType: 'Tipo de grade',
    providersPositioning: 'Posicionamento do Provedor',
    groups: 'Grupos',
    gameDetailsPreviewInfo: 'Os detalhes do jogo são mostrados no site com visualização prévia',
    gameDetailsCardInfo: 'Os detalhes do jogo são mostrados no site com Card Footer',
    providerGamesCount: 'Contagem de jogos do provedor',
    secondaryOrdering: 'Pedidos Secundários',
    advancedView: 'Visualização avançada',
    lobbyProviders: 'Fornecedores do Lobby',
    providerOrder: 'Ordem do prestador',
    order: 'Ordem',
    gameOrder: 'Ordem de jogo',
    secondaryOrderingNote:
      'A ordenação secundária do jogo funciona quando a configuração está ativa e apenas um fornecedor está selecionado, em qualquer outro caso é aplicada uma ordenação específica do grupo.',
    providerOrderingNote: 'Por predefinição ou quando não está selecionada, é definida a ordem alfabética do fornecedor. Quando selecionada, é aplicada a ordem personalizada definida',
    searchId: 'Procurar ID',
    uploadGames: 'Transferir jogos',
    uploadProviders: 'Carregar fornecedores',
    providerOrdering: 'Encomenda de fornecedores',
    pageCap: 'Limite da página',
    playerSpecificGamesAllowed: 'Jogos específicos do jogador permitidos',
    loggedInOnly: 'Apenas com registo',
    visibleInMyCasino: 'Visível em Meu Casino',
    visibleOnGameView: 'Visível na Vista do Jogo',
    visibleOnTheLeftBarMenu: 'Visível na barra do menu esquerda',
    visibleOnMultiGameMenu: 'Visível no menu multi-jogo',
    visibleOnNavigation: 'Visível na Navegação',
    myCasino: 'Meu Casino',
    gameView: 'Visualização do jogo',
    leftBarMenu: 'Barra de Menu Esquerda',
    multiGameMenu: 'Menu Multijogo',
    navigation: 'Navegação',
    lobbies: 'Lobbies',
    visibleOn: 'Visível em',
    optional: 'Opcional',
    lobbyWidgetsJackpotInfo: 'Nota: apenas disponível para a alimentação do jackpot EGT',
    gameStatus: 'Estado do Jogo',
    customOrderUnavailable: 'Neste estado, a encomenda personalizada não está disponível, mas pode encomendar jogos através dos critérios de encomenda sugeridos pelo sistema',
    gamesInfoNote: 'Nota informativa sobre os jogos',
    gamesInfoNoteHint: 'O botão da nota informativa dos jogos fica disponível no sítio Web',
    providerId: 'ID do fornecedor',
    gameBackground: 'Fundo do jogo',
    gameBackgroundHint: 'Se ativado, as imagens de fundo dos jogos ficam disponíveis no sítio Web, ao iniciar a partir dos lobbies',
    myCasinoHint: 'Se ativado, a subsecção "O meu Casino" fica disponível na vista móvel do sítio Web',
    media: 'Média',
    mobilePageCap: 'Limite de páginas para aplicações móveis',
    lGames: 'jogos',
    cmsNoAccessInfo: 'Inventário CMS, Depósito, Retirada para a secção de caixa deve ser feito a partir do painel de administração Paydrom',
    lGameType: 'tipo do jogo',
    typeName: 'Nome do tipo',
    gameVerticalType: 'Tipo de jogo vertical',
    haveAssignedType: 'Tem tipo atribuído',
    createGameType: 'Criar tipo de jogo',
  },
  zh: {
    cashier: '出纳员',
    paymentLogo: '付款徽标',
    methodName: '方式名称',
    pgState: '支付网关状态',
    platformState: '平台状态',
    allowedActivities: '允许的活动',
    supportedCountries: '支持的国家',
    depositSupportedCurrencies: '存款支持货币',
    withdrawalSupportedCurrencies: '受支持的取款货币',
    showForTags: '显示标记',
    hideForTags: '隐藏标记',
    depositProcessingPeriod: '存款处理周期',
    withdrawalProcessingPeriod: '取款处理周期',
    processingPeriod: '处理周期',
    info: '信息',
    showInfoForDeposit: '显示存款信息',
    showInfoForWithdrawal: '显示取款信息',
    processingRange: '处理范围',
    categoryName: '类别名称',
    lCategory: '类别',
    translationKey: '翻译密钥',
    translationKeyInfo: '请确保为该密钥添加翻译，以便在网站上显示特定语言的名称',
    deleteCategoryInfo: '该类别的所有付款方式将移至其他系统类别',
    cashierDeposit: '出纳存款',
    cashierWithdrawal: '出纳取款',
    depositState: '存款状态',
    withdrawalState: '取款状态',
    generalComponentsInfo: '一般部件都标有',
    lSymbol: '标志',
    resetTranslationsInfo: '重置将删除当前部分的所有自定义翻译。',
    exportTranslationInfo: '将包括翻译钥匙',
    defaultTranslation: '默认翻译',
    defaultTranslationInfo: '当自定义翻译被删除时，此列中的翻译将被应用。',
    addHintLanguage: '添加提示语言',
    selectHintsLanguage: '选择提示语言',
    deleteHintLanguage: '删除提示语言',
    section: '部分',
    chooseLanguage: '选择语言',
    availableTranslationsBySections: '按版块划分的可用翻译',
    key: '密钥',
    availableTranslations: '可用翻译',
    createFolder: '创建文件夹',
    moveTo: '移至',
    makeSureFileIsNotUsedInfo: '确保此文件不用于任何地方。',
    makeSureFilesAreNotUsedInfo: '确保这些文件不用于任何地方。',
    folderName: '文件夹名称',
    filesUrlsChangedInfo: '文件的URL将进行更改。',
    searchFolder: '搜索文件夹',
    move: '移动',
    folders: '文件夹',
    rename: '重命名',
    folderFilesDeletedInfo: '文件夹中的文件也将一并删除。',
    totalSize: '总大小',
    contains: '包含',
    file: '文件',
    lFile: '文件',
    lFiles: '文件',
    theFile: '文件',
    files: '文件',
    lFolder: '文件夹',
    folder: '文件夹',
    lFolders: '文件夹',
    fileName: '文件名',
    dropHereOrUseUploadButton: '<0>在这里投放文件</0><1>或使用上传按钮</1>。',
    copyUrl: '复制URL',
    extension: '扩展',
    size: '大小',
    dimensions: '尺寸',
    url: 'URL',
    droppedFileInfo: '掉落的文件将被立即上传到这个文件夹。',
    theTranslation: '翻译',
    lSection: '部分',
    thePaymentMethod: '付款方式',
    theCategory: '类别',
    cashierSettings: '出纳设置',
    fileOrFiles: '文件',
    showingUpdates: '在网站上显示更新最长可能需要30秒。',
    inputLanguageKeyHere: '{{languageName}}：请在此处输入',
    subCategory: '子—类别',
    themes: '主题',
    systemState: '系统状态',
    maintenanceState: '维护状态',
    maintenanceDates: '维护日期',
    newTill: '新至',
    fixedTill: '固定至',
    promoTill: '促销至',
    dateTill: '日期至',
    newGame: '新游戏',
    fixedGame: '固定游戏',
    promoGame: '促销游戏',
    minLimit: '最小限制',
    maxLimit: '最大限制',
    isCustom: '自定义的',
    standard: '标准',
    vertical: '垂直',
    horizontal: '水平',
    background: '背景',
    maintenanceDate: '维护日期',
    lGame: '游戏',
    infinity: '无限',
    new: '新',
    isNew: '新',
    fixed: '固定',
    isFixed: '固定的',
    promo: '促销',
    isPromo: '促销',
    gameSettings: '游戏设置',
    maintenance: '维护',
    selectBadges: '选择奖章',
    gameGroup: '游戏组',
    badge: '奖章',
    orderBy: '订购',
    createGroup: '创建组',
    custom: '自定义',
    lGroup: '组',
    set: '设置',
    groupType: '组类型',
    uploadListInfo: '您可以上传带有比赛ID的列表。我们仅接受CSV格式',
    thePaymentMethods: '付款方式',
    gridType: '网格类型',
    providersPositioning: '提供商定位',
    groups: '小组',
    gameDetailsPreviewInfo: '游戏细节显示在网站上，并附有预览',
    gameDetailsCardInfo: '游戏细节显示在网站的卡脚上',
    providerGamesCount: '提供商游戏计数',
    secondaryOrdering: '二级订单组织',
    advancedView: '高级视图',
    lobbyProviders: '大厅提供商',
    providerOrder: '提供商订单',
    order: '订单',
    gameOrder: '游戏订单',
    secondaryOrderingNote: '当配置处于活跃状态且只选择了一个提供商时，二级游戏排序才会起作用，在任何其他情况下，都会应用特定组的排序。',
    providerOrderingNote: '默认情况下或未选中时，将设置提供商字母顺序。选中时，应用自定义设置的顺序',
    searchId: '搜索ID',
    uploadGames: '上传游戏',
    uploadProviders: '上传提供商',
    providerOrdering: '提供商订购',
    pageCap: '\n页首\n',
    playerSpecificGamesAllowed: '允许的特定玩家游戏',
    loggedInOnly: '仅限已登录',
    visibleInMyCasino: '在我的赌场可见',
    visibleOnGameView: '游戏视图中可见',
    visibleOnTheLeftBarMenu: '可见于左侧栏菜单',
    visibleOnMultiGameMenu: '多游戏菜单上可见',
    visibleOnNavigation: '导航中可见',
    myCasino: '我的赌场',
    gameView: '游戏视图',
    leftBarMenu: '左侧栏',
    multiGameMenu: '多游戏菜单',
    navigation: '导航',
    lobbies: '大厅',
    visibleOn: '可见于',
    optional: '可选',
    lobbyWidgetsJackpotInfo: '请注意，仅适用于EGT大奖功能',
    gameStatus: '游戏状态',
    customOrderUnavailable: '在此状态下，自定义订单不可用，您可以根据建议的系统订单标准订购游戏',
    gamesInfoNote: '游戏须知',
    gamesInfoNoteHint: '网站上有游戏须知按钮',
    providerId: '提供商ID',
    gameBackground: '游戏背景',
    gameBackgroundHint: '如果启用，从大厅启动游戏时，网站上会显示游戏背景图片',
    myCasinoHint: '如果启用，“我的赌场”子部分将在网站移动视图中显示',
    media: '媒体',
    mobilePageCap: '移动应用程序页面上限',
    lGames: '游戏',
    cmsNoAccessInfo: '出纳部分的CMS库存、存款和取款应通过Paydrom管理面板完成',
    lGameType: '游戏类型',
    typeName: '类型名',
    gameVerticalType: '游戏垂直类型',
    haveAssignedType: '已指定类型',
    createGameType: '创建游戏类型',
  },
  ro: {
    cashier: 'Casier',
    paymentLogo: 'Sigla sistemului de plată',
    methodName: 'Numele de metodă',
    pgState: 'Stare de sistem de plată',
    platformState: 'Starea de platformă',
    allowedActivities: 'Acțiuni permise',
    supportedCountries: 'Țări disponibile',
    depositSupportedCurrencies: 'Valute pentru depozit',
    withdrawalSupportedCurrencies: 'Valute valutare disponibile pentru retragere',
    showForTags: 'Afișează pentru etichete',
    hideForTags: 'Ascunde pentru etichete',
    depositProcessingPeriod: 'Perioadă de procesare a depunerii',
    withdrawalProcessingPeriod: 'Perioadă de procesare a retragerii',
    processingPeriod: 'Perioadă de procesare',
    info: 'Informație',
    showInfoForDeposit: 'Afișează informații despre depozite',
    showInfoForWithdrawal: 'Afișează informații pentru retragere',
    processingRange: 'Interval de timp pentru procesare ',
    categoryName: 'Numele de categorie',
    lCategory: 'categorie',
    translationKey: 'Cheie de traducere',
    translationKeyInfo: 'Asigură-te că adaugi traduceri pentru cheie, pentru ca numele specifice limbii să fie afișate pe site-ul web',
    deleteCategoryInfo: 'Toate metodele de plată a categoriei vor fi mutate în categoria de Alte sisteme',
    cashierDeposit: 'Depunere de fonduri în casierie',
    cashierWithdrawal: 'Retragere prin casierie',
    depositState: 'Starea de depozit',
    withdrawalState: 'Starea de retagere',
    generalComponentsInfo: 'Componentele comune sunt marcate cu',
    lSymbol: 'simbol',
    resetTranslationsInfo: 'Resetarea va șterge toate traducerile personalizate din secțiunea curentă.',
    exportTranslationInfo: 'Vor fi incluse cheile de traducere',
    defaultTranslation: 'Traducere implicită',
    defaultTranslationInfo: 'Se vor utiliza traducerile din această coloană, atunci când traducerea personalizată va fi eliminată',
    addHintLanguage: 'Adaugă limbajul indicativ',
    selectHintsLanguage: 'Selectează limba de sugestii',
    deleteHintLanguage: 'Șterge limba de sugestii',
    section: 'Secțiune',
    chooseLanguage: 'Alege limba',
    availableTranslationsBySections: 'Traduceri disponibile după secțiuni',
    key: 'Cheie',
    availableTranslations: 'Traduceri disponibile',
    createFolder: 'Crează fișier',
    moveTo: 'Mută la',
    makeSureFileIsNotUsedInfo: 'Asigură-te că acest fișier nu este întrebuinţat altundeva.',
    makeSureFilesAreNotUsedInfo: 'Asigură-te că aceste fișiere nu sunt întrebuinţate altundeva.',
    folderName: 'Numele de dosar',
    filesUrlsChangedInfo: 'Adresele URL ale fișierelor vor fi schimbate.',
    searchFolder: 'Căuta folderul',
    move: 'Muta',
    folders: 'Foldere',
    rename: 'Renumi',
    folderFilesDeletedInfo: 'Fișierele din folder vor fi și ele șterse.',
    totalSize: 'Mărimea totală',
    contains: 'Conține',
    file: 'Fișier',
    lFile: 'fișier',
    lFiles: 'fișiere',
    theFile: 'Fișierul',
    files: 'Fișiere',
    lFolder: 'fișier',
    folder: 'Fișier',
    lFolders: 'fișiere',
    fileName: 'Nume de fișier',
    dropHereOrUseUploadButton: '<0>Plasează fișierele aici</0><1> sau folosește butonul Încărcare.</1>',
    copyUrl: 'Copia adresa URL',
    extension: 'Extensie',
    size: 'Mărime',
    dimensions: 'Dimensiuni',
    url: 'URL',
    droppedFileInfo: 'Fișierele plasate vor fi încărcate imediat în acest folder',
    theTranslation: 'Traducerea',
    lSection: 'secțiune',
    thePaymentMethod: 'Metoda de plată',
    theCategory: 'Categorie',
    cashierSettings: 'Setări de plată/casierie',
    fileOrFiles: 'Fișiere',
    showingUpdates: 'Afișare a actualizărilor pe site poate dura până la 30 de secunde.',
    inputLanguageKeyHere: '{{languageName}}: Introduce aici',
    subCategory: 'Subcategorie',
    themes: 'Teme',
    systemState: 'Stare de sistem',
    maintenanceState: 'Stare de mentenanță',
    maintenanceDates: 'Date de mentenanță',
    newTill: 'Nou până la',
    fixedTill: 'Fixat până la',
    promoTill: 'Promoție până la',
    dateTill: 'Data până la',
    newGame: 'Un joc nou',
    fixedGame: 'Joc fixat',
    promoGame: 'Joc promoțional',
    minLimit: 'Limită minimă',
    maxLimit: 'Limită maximă',
    isCustom: 'Este personalizat',
    standard: 'Standart',
    vertical: 'Vertical',
    horizontal: 'Orizontală',
    background: 'Fundal',
    maintenanceDate: 'Data de mentenanță',
    lGame: 'Joc',
    infinity: 'Infinit',
    new: 'Nou',
    isNew: 'Este nou',
    fixed: 'Fix',
    isFixed: 'Este fix',
    promo: 'Promo',
    isPromo: 'Este promo',
    gameSettings: 'Setări de joc',
    maintenance: 'Mentenanță',
    selectBadges: 'Selectează insigne',
    gameGroup: 'Grup de joc',
    badge: 'Insignă',
    orderBy: 'Comanda de',
    createGroup: 'Crează un grup',
    custom: 'Personalizat',
    lGroup: 'grup',
    set: 'Seta',
    groupType: 'Tip de grup',
    uploadListInfo: 'Poți încărca liste cu ID-uri de joc. Doar formatul CSV este acceptabil',
    thePaymentMethods: 'Metode de plată',
    gridType: 'Tip grilă',
    providersPositioning: 'Poziționarea furnizorilor, modul de plasare a furnizorilor',
    groups: 'Grupuri',
    gameDetailsPreviewInfo: 'Detaliile jocului sunt afișate pe site-ul web împreună cu funcția previzualizare',
    gameDetailsCardInfo: 'Detaliile jocului sunt afișate pe site-ul web împreună cu Card Footer',
    providerGamesCount: 'Numărul furnizorilor de jocuri',
    secondaryOrdering: 'Comandă secundară',
    advancedView: 'Vizualizare avansată',
  },
  bg: {},
  el: {
    cashier: 'Ταμείο',
    paymentLogo: 'Λογότυπο πληρωμής',
    methodName: 'Όνομα μεθόδου',
    pgState: 'Κατάσταση PG',
    platformState: 'Κατάσταση πλατφόρμας',
    allowedActivities: 'Επιτρεπόμενες δραστηριότητες',
    supportedCountries: 'Υποστηριζόμενες χώρες',
    depositSupportedCurrencies: 'Υποστηριζόμενα νομίσματα κατάθεσης',
    withdrawalSupportedCurrencies: 'Υποστηριζόμενα νομίσματα ανάληψης',
    showForTags: 'Εμφάνιση για ετικέτες',
    hideForTags: 'Απόκρυψη για ετικέτες',
    depositProcessingPeriod: 'Περίοδος επεξεργασίας κατάθεσης',
    withdrawalProcessingPeriod: 'Περίοδος επεξεργασίας ανάληψης',
    processingPeriod: 'Περίοδος επεξεργασίας',
    info: 'Πληροφορίες',
    showInfoForDeposit: 'Εμφάνιση πληροφοριών για κατάθεση',
    showInfoForWithdrawal: 'Εμφάνιση πληροφοριών για ανάληψη',
    processingRange: 'Εύρος επεξεργασίας',
    categoryName: 'Όνομα κατηγορίας',
    lCategory: 'κατηγορία',
    translationKey: 'Κλειδί μετάφρασης',
    translationKeyInfo: 'Βεβαιωθείτε ότι προσθέσατε μεταφράσεις για το κλειδί, ώστε τα ονόματα που είναι συγκεκριμένα για τη γλώσσα να εμφανίζονται στον ιστότοπο',
    deleteCategoryInfo: 'Όλες οι μέθοδοι πληρωμής της κατηγορίας θα μετακινηθούν στην κατηγορία συστήματος Άλλα',
    cashierDeposit: 'Κατάθεση στο ταμείο',
    cashierWithdrawal: 'Ανάληψη από ταμείο',
    depositState: 'Κατάσταση κατάθεσης',
    withdrawalState: 'Κατάσταση ανάληψης',
    generalComponentsInfo: 'Τα Γενικά συστατικά επισημαίνονται με',
    lSymbol: 'σύμβολο',
    resetTranslationsInfo: 'Αν γίνει επαναφορά, θα αφαιρεθούν όλες οι προσαρμοσμένες μεταφράσεις της τρέχουσας ενότητας.',
    exportTranslationInfo: 'Θα συμπεριληφθούν κλειδιά μετάφρασης',
    defaultTranslation: 'Προεπιλεγμένη μετάφραση',
    defaultTranslationInfo: 'Οι μεταφράσεις αυτής της στήλης θα εφαρμόζονται όταν αφαιρείται η προσαρμοσμένη μετάφραση',
    addHintLanguage: 'Προσθήκη γλώσσας στοιχείου',
    selectHintsLanguage: 'Επιλογή γλώσσας στοιχείων',
    deleteHintLanguage: 'Διαγραφή γλώσσας στοιχείου',
    section: 'Ενότητα',
    chooseLanguage: 'Επιλογή γλώσσας',
    availableTranslationsBySections: 'Διαθέσιμες μεταφράσεις ανά ενότητες',
    key: 'Κλειδί',
    availableTranslations: 'Διαθέσιμες μεταφράσεις',
    createFolder: 'Δημιουργία φακέλου',
    moveTo: 'Μετακίνηση σε',
    makeSureFileIsNotUsedInfo: 'Βεβαιωθείτε ότι αυτό το αρχείο δεν χρησιμοποιείται αλλού.',
    makeSureFilesAreNotUsedInfo: 'Βεβαιωθείτε ότι αυτά τα αρχεία δεν χρησιμοποιούνται αλλού.',
    folderName: 'Όνομα φακέλου',
    filesUrlsChangedInfo: 'Τα URL των αρχείων θα αλλάξουν.',
    searchFolder: 'Αναζήτηση φακέλου',
    move: 'Μετακίνηση',
    folders: 'Φάκελοι',
    rename: 'Μετονομασία',
    folderFilesDeletedInfo: 'Τα αρχεία που βρίσκονται στον φάκελο θα διαγραφούν επίσης.',
    totalSize: 'Συνολικό μέγεθος',
    contains: 'Περιέχει',
    file: 'Αρχείο',
    lFile: 'αρχείο',
    lFiles: 'αρχεία',
    theFile: 'Το αρχείο',
    files: 'Αρχεία',
    lFolder: 'φάκελος',
    folder: 'Φάκελος',
    lFolders: 'φάκελοι',
    fileName: 'Όνομα αρχείου',
    dropHereOrUseUploadButton: '<0>Αποθέστε τα αρχεία εδώ</0><1> ή χρησιμοποιήστε το κουμπί Μεταφόρτωση.</1>',
    copyUrl: 'Αντιγραφή URL',
    extension: 'Επέκταση',
    size: 'Μέγεθος',
    dimensions: 'Διαστάσεις',
    url: 'URL',
    droppedFileInfo: 'Τα αρχεία που αποτέθηκαν θα μεταφορτωθούν αμέσως σε αυτόν τον φάκελο',
    theTranslation: 'Η μετάφραση',
    lSection: 'ενότητα',
    thePaymentMethod: 'Η μέθοδος πληρωμής',
    theCategory: 'Η κατηγορία',
    cashierSettings: 'Ρυθμίσεις ταμείου',
    fileOrFiles: 'Αρχείο(-α)',
    showingUpdates: 'Η εμφάνιση των ενημερώσεων στον ιστότοπο μπορεί να χρειαστεί μέχρι και 30 δευτερόλεπτα.',
    inputLanguageKeyHere: '{{languageName}}: Εισαγάγετε εδώ',
    subCategory: 'Υποκατηγορία',
    themes: 'Θέματα',
    systemState: 'Κατάσταση συστήματος',
    maintenanceState: 'Κατάσταση συντήρησης',
    maintenanceDates: 'Ημερομηνίες συντήρησης',
    newTill: 'Νέο μέχρι',
    fixedTill: 'Σταθερό μέχρι',
    promoTill: 'Προωθητική ενέργεια μέχρι',
    dateTill: 'Ημερομηνία μέχρι',
    newGame: 'Νέο παιχνίδι',
    fixedGame: 'Σταθερό παιχνίδι',
    promoGame: 'Παιχνίδι προωθητικής ενέργειας',
    minLimit: 'Ελάχιστο όριο',
    maxLimit: 'Μέγιστο όριο',
    isCustom: 'Είναι προσαρμοσμένο',
    standard: 'Βασικό',
    vertical: 'Κάθετη',
    horizontal: 'Οριζόντια',
    background: 'Φόντο',
    maintenanceDate: 'Ημερομηνία συντήρησης',
    lGame: 'παιχνίδι',
    infinity: 'Άπειρο',
    new: 'Νέο',
    isNew: 'Είναι νέο',
    fixed: 'Σταθερό',
    isFixed: 'Είναι σταθερό',
    promo: 'Προωθητική ενέργεια',
    isPromo: 'Είναι προωθητική ενέργεια',
    gameSettings: 'Ρυθμίσεις παιχνιδιού',
    maintenance: 'Συντήρηση',
    selectBadges: 'Επιλογή σημάτων',
    gameGroup: 'Ομάδα παιχνιδιού',
    badge: 'Σήμα',
    orderBy: 'Ταξινόμηση κατά',
    createGroup: 'Δημιουργία ομάδας',
    custom: 'Προσαρμοσμένο',
    lGroup: 'ομάδα',
    set: 'Ρύθμιση',
    groupType: 'Τύπος ομάδας',
    uploadListInfo: 'Μπορείτε να μεταφορτώσετε λίστες με Αναγνωριστικά παιχνιδιού. Μόνο η μορφή CSV είναι αποδεκτή',
    thePaymentMethods: 'Οι μέθοδοι πληρωμής',
    gridType: 'Τύπος πίνακα',
    providersPositioning: 'Τοποθέτηση παρόχων',
    groups: 'Ομάδες',
    gameDetailsPreviewInfo: 'Τα στοιχεία του παιχνιδιού εμφανίζονται στον ιστότοπο με προεπισκόπηση',
    gameDetailsCardInfo: 'Τα στοιχεία του παιχνιδιού εμφανίζονται στον ιστότοπο με Card Footer',
    providerGamesCount: 'Αριθμός παιχνιδιών παρόχου',
    secondaryOrdering: 'Δευτερεύουσα ταξινόμηση',
    advancedView: 'Προηγμένη προβολή',
    lobbyProviders: 'Πάροχοι λόμπι',
    providerOrder: 'Σειρά παρόχων',
    order: 'Ταξινόμηση',
    gameOrder: 'Σειρά παιχνιδιού',
    secondaryOrderingNote:
      'Η Δευτερεύουσα ταξινόμηση παιχνιδιού λειτουργεί όταν η διαμόρφωση είναι ενεργή και έχει επιλεγεί μόνο ένας πάροχος, σε κάθε άλλη περίπτωση εφαρμόζεται η συγκεκριμένη ταξινόμηση ομάδας.',
    providerOrderingNote: 'Είτε από προεπιλογή είτε όταν δεν είναι επιλεγμένο, η σειρά των παρόχων είναι αλφαβητική. Όταν είναι επιλεγμένο, εφαρμόζεται προσαρμοσμένη σειρά',
    searchId: 'Αναγνωριστικό αναζήτησης',
    uploadGames: 'Μεταφόρτωση παιχνιδιών',
    uploadProviders: 'Μεταφόρτωση παρόχων',
    providerOrdering: 'Ταξινόμηση παρόχων',
    pageCap: 'Όριο σελίδας',
    playerSpecificGamesAllowed: 'Επιτρέπονται συγκεκριμένα παιχνίδια για τον παίκτη',
    loggedInOnly: 'Συνδεδεμένος μόνο',
    visibleInMyCasino: 'Εμφανίζεται στο Το Καζίνο μου',
    visibleOnGameView: 'Εμφανίζεται στην Προβολή παιχνιδιού',
    visibleOnTheLeftBarMenu: 'Εμφανίζεται στο Μενού Αριστερής μπάρας',
    visibleOnMultiGameMenu: 'Εμφανίζεται στο Μενού Πολλαπλών παιχνιδιών',
    visibleOnNavigation: 'Εμφανίζεται στην Πλοήγηση',
    myCasino: 'Το Καζίνο μου',
    gameView: 'Προβολή παιχνιδιού',
    leftBarMenu: 'Μενού Αριστερής μπάρας',
    multiGameMenu: 'Μενού Πολλαπλών παιχνιδιών',
    navigation: 'Πλοήγηση',
    lobbies: 'Λόμπι',
    visibleOn: 'Εμφανίζεται στο',
    optional: 'Προαιρετικό',
    lobbyWidgetsJackpotInfo: 'Θα πρέπει να σημειωθεί ότι είναι μόνο διαθέσιμη στην τροφοδότηση του τζάκποτ της EGT',
    gameStatus: 'Κατάσταση παιχνιδιού',
    customOrderUnavailable: 'Σε αυτή την κατάσταση δεν είναι διαθέσιμη η προσαρμοσμένη σειρά, θα μπορείτε να ταξινομήσετε τα παιχνίδια σύμφωνα με τα προτεινόμενα κριτήρια ταξινόμησης συστήματος',
    gamesInfoNote: 'Σημείωση πληροφοριών παιχνιδιών',
    gamesInfoNoteHint: 'Το πλήκτρο Σημείωσης πληροφοριών παιχνιδιών γίνεται διαθέσιμο στον ιστότοπο',
    providerId: 'Αναγνωριστικό παρόχου',
    gameBackground: 'Φόντο παιχνιδιού',
    gameBackgroundHint: 'Αν είναι ενεργοποιημένο, οι εικόνες φόντου των παιχνιδιών γίνονται διαθέσιμες στον ιστότοπο όταν γίνεται έναρξή τους από τα λόμπι',
    myCasinoHint: 'Αν είναι ενεργοποιημένη, η υποενότητα «Το Καζίνο μου» γίνεται διαθέσιμη στην προβολή για κινητά',
    media: 'Μέσα',
  },
};
