import OperatorLimitActionType from './operatorLimitActionType';

export const getLargeTransactionsLimitsByBrand = (brandId, params) => ({
  type: OperatorLimitActionType.GET_LARGE_TRANSACTIONS_LIMITS_BY_BRAND,
  payload: { brandId, params },
});

export const saveLargeTransactionsLimitsByBrand = (data) => ({
  type: OperatorLimitActionType.SAVE_LARGE_TRANSACTIONS_LIMITS_BY_BRAND,
  payload: { data },
});

export const resetLargeTransactionsLimitsByBrand = () => ({
  type: OperatorLimitActionType.RESET_LARGE_TRANSACTIONS_LIMITS_BY_BRAND,
});

export const editLargeTransactionLimitsByBrand = (brandId, data) => ({
  type: OperatorLimitActionType.EDIT_LARGE_TRANSACTIONS_LIMITS_BY_BRAND,
  payload: { brandId, data },
});

export const getLargeTransactionLimitsLogs = (brandId, params) => ({
  type: OperatorLimitActionType.GET_LARGE_TRANSACTION_LIMITS_LOGS,
  payload: { brandId, params },
});

export const saveLargeTransactionLimitsLogs = (data) => ({
  type: OperatorLimitActionType.SAVE_LARGE_TRANSACTION_LIMITS_LOGS,
  payload: { data },
});

export const resetLargeTransactionLimitsLogs = () => ({
  type: OperatorLimitActionType.RESET_LARGE_TRANSACTION_LIMITS_LOGS,
});

export const getBrandPlayerLimits = (brandId) => ({
  type: OperatorLimitActionType.GET_BRAND_PLAYER_LIMITS,
  payload: { brandId },
});

export const saveBrandPlayerLimits = (data) => ({
  type: OperatorLimitActionType.SAVE_BRAND_PLAYER_LIMITS,
  payload: { data },
});

export const resetBrandPlayerLimits = () => ({
  type: OperatorLimitActionType.RESET_BRAND_PLAYER_LIMITS,
});

export const changeBrandPlayerLimits = (brandId, data) => ({
  type: OperatorLimitActionType.CHANGE_BRAND_PLAYER_LIMITS,
  payload: { brandId, data },
});

export const getPeriodsLimits = (params) => ({
  type: OperatorLimitActionType.GET_PERIODS_LIMITS,
  payload: { params },
});

export const savePeriodsLimits = (data) => ({
  type: OperatorLimitActionType.SAVE_PERIODS_LIMITS,
  payload: { data },
});

export const resetPeriodsLimits = () => ({
  type: OperatorLimitActionType.RESET_PERIODS_LIMITS,
});

export const editPeriodsLimits = (params, data) => ({
  type: OperatorLimitActionType.EDIT_PERIODS_LIMITS,
  payload: { params, data },
});
