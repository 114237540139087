import FileManagerActionType from './fileManagerActionType';

export const getDirectoriesAndFiles = (params) => ({
  type: FileManagerActionType.GET_DIRECTORIES_AND_FILES,
  payload: { params },
});

export const changeDirectoriesAndFilesLoadingState = (state) => ({
  type: FileManagerActionType.CHANGE_DIRECTORIES_AND_FILES_LOADING_STATE,
  payload: { state },
});

export const changeAllDirectoriesLoadingState = (state) => ({
  type: FileManagerActionType.CHANGE_ALL_DIRECTORIES_LOADING_STATE,
  payload: { state },
});

export const saveDirectoriesAndFiles = (data) => ({
  type: FileManagerActionType.SAVE_DIRECTORIES_AND_FILES,
  payload: { data },
});

export const saveBaseDirectoriesAndFiles = (data) => ({
  type: FileManagerActionType.SAVE_BASE_DIRECTORIES_AND_FILES,
  payload: { data },
});

export const changeFileManagerLoadingState = (state) => ({
  type: FileManagerActionType.CHANGE_FILE_MANAGER_LOADING_STATE,
  payload: { state },
});

export const getFileTypeGroups = () => ({
  type: FileManagerActionType.GET_FILE_TYPE_GROUPS,
});

export const getFileTypeGroupsSuccess = (data) => ({
  type: FileManagerActionType.GET_FILE_TYPE_GROUPS_SUCCESS,
  payload: { data },
});

export const uploadFileIntoDirectories = (data) => ({
  type: FileManagerActionType.UPLOAD_FILE_INTO_DIRECTORIES,
  payload: { data },
});

export const renamePublicFile = (data) => ({
  type: FileManagerActionType.RENAME_PUBLIC_FILE,
  payload: { data },
});

export const renamePublicDirectory = (data) => ({
  type: FileManagerActionType.RENAME_PUBLIC_DIRECTORY,
  payload: { data },
});

export const changeModalVisibility = (state) => ({
  type: FileManagerActionType.CHANGE_FILE_MANAGER_MODAL_VISIBILITY,
  payload: { state },
});

export const changePopoverVisibility = (state) => ({
  type: FileManagerActionType.CHANGE_FILE_MANAGER_POPOVER_VISIBILITY,
  payload: { state },
});

export const deletePublicFiles = (data) => ({
  type: FileManagerActionType.DELETE_PUBLIC_FILES,
  payload: { data },
});

export const deletePublicDirectory = (data) => ({
  type: FileManagerActionType.DELETE_PUBLIC_DIRECTORY,
  payload: { data },
});

export const changeFileManagerBrandId = (brandId) => ({
  type: FileManagerActionType.CHANGE_FILE_MANAGER_BRAND_ID,
  payload: { brandId },
});

export const resetFileManagerState = () => ({
  type: FileManagerActionType.RESET_FILE_MANAGER_STATE,
});

export const createDirectory = (data) => ({
  type: FileManagerActionType.CREATE_DIRECTORY,
  payload: { data },
});

export const changeFileManageEndedStatus = (state) => ({
  type: FileManagerActionType.CHANGE_FILE_MANAGER_ENDED_STATUS,
  payload: { state },
});

export const getDirectoriesHierarchy = (params) => ({
  type: FileManagerActionType.GET_DIRECTORIES_HIERARCHY,
  payload: { params },
});

export const getDirectoriesHierarchySuccess = (data) => ({
  type: FileManagerActionType.GET_DIRECTORIES_HIERARCHY_SUCCESS,
  payload: { data },
});

export const resetDirectoriesHierarchy = () => ({
  type: FileManagerActionType.RESET_DIRECTORIES_HIERARCHY,
});

export const movePublicFiles = (data) => ({
  type: FileManagerActionType.MOVE_PUBLIC_FILES,
  payload: { data },
});

export const getFilesByPaths = (params) => ({
  type: FileManagerActionType.GET_FILES_BY_PATHS,
  payload: { params },
});

export const saveFilesByPaths = (data) => ({
  type: FileManagerActionType.SAVE_FILES_BY_PATHS,
  payload: { data },
});

export const resetFilesByPaths = () => ({
  type: FileManagerActionType.RESET_FILES_BY_PATHS,
});

export const saveUploadedFile = (data) => ({
  type: FileManagerActionType.SAVE_UPLOADED_FILE,
  payload: { data },
});

export const resetUploadedFile = () => ({
  type: FileManagerActionType.RESET_UPLOADED_FILE,
});
