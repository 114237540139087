import {
  put, all, call, takeLatest,
} from 'redux-saga/effects';
import { NetworkService } from 'services';
import { setErrorMessage, changeLoadingState } from 'actions/setting/settingActionCreator';
import { getError } from 'utils/helpers';
import OverviewActionType from 'actions/overview/overviewActionType';
import {
  changePlayerOverviewActivitiesLoadingState,
  changePlayerOverviewFinancialLoadingState,
  changePlayerOverviewRevenuesLoadingState,
  changePlayerOverviewVerticalTagsLoadingState,
  savePlayerOverviewActivities,
  savePlayerOverviewFinancial,
  savePlayerOverviewLimitsCheck,
  savePlayerOverviewRevenues,
  savePlayerOverviewVerticalTags,
  savePlayerOverviewCasinoBonuses,
  savePlayerOverviewSportBonus,
  changePlayerOverviewBonusesLoadingState,
  savePlayerOverviewPayments,
  changePlayerOverviewPaymentsLoadingState,
  changePlayerOverviewIndicatorsLoadingState,
  savePlayerOverviewCasinoIndicators,
  savePlayerOverviewSportIndicators,
} from 'actions/overview/overviewActionCreator';
import { AppConstants } from 'constants/index';

function* getPlayerOverviewFinancial({ payload }) {
  try {
    const { id } = payload;
    const {
      ReportingAdmin, PaymentReport, Overview, Financial,
    } = AppConstants.api;
    yield put(changePlayerOverviewFinancialLoadingState(true));
    const { data } = yield call(NetworkService.makeAPIGetRequest, [ReportingAdmin, PaymentReport, Overview, Financial, id]);
    yield put(savePlayerOverviewFinancial(data));
    yield put(changePlayerOverviewFinancialLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changePlayerOverviewFinancialLoadingState(false));
  }
}

function* getPlayerOverviewActivities({ payload }) {
  try {
    const { id } = payload;
    const {
      ReportingAdmin, Player, Overview, Activities,
    } = AppConstants.api;
    yield put(changePlayerOverviewActivitiesLoadingState(true));
    const { data } = yield call(NetworkService.makeAPIGetRequest, [ReportingAdmin, Player, Overview, Activities, id]);
    yield put(savePlayerOverviewActivities(data));
    yield put(changePlayerOverviewActivitiesLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changePlayerOverviewActivitiesLoadingState(false));
  }
}

function* getPlayerOverviewRevenues({ payload }) {
  try {
    const { id, params } = payload;
    const {
      ReportingAdmin, BetReport, Overview, Revenues,
    } = AppConstants.api;
    const options = {
      params,
    };
    yield put(changePlayerOverviewRevenuesLoadingState(true));
    const { data } = yield call(NetworkService.makeAPIGetRequest, [ReportingAdmin, BetReport, Overview, Revenues, id], options);
    yield put(changePlayerOverviewRevenuesLoadingState(false));
    yield put(savePlayerOverviewRevenues(data));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changePlayerOverviewRevenuesLoadingState(false));
  }
}

function* getPlayerOverviewLimitsCheck({ payload }) {
  const { id, isLoading } = payload;
  try {
    const {
      ReportingAdmin, ResponsibleGaming, Overview, Limits, Check,
    } = AppConstants.api;

    if (isLoading) {
      yield put(changeLoadingState(true));
    }

    const { data } = yield call(NetworkService.makeAPIGetRequest, [ReportingAdmin, ResponsibleGaming, Overview, Limits, id, Check]);
    yield put(savePlayerOverviewLimitsCheck(data));

    if (isLoading) {
      yield put(changeLoadingState(false));
    }
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    if (isLoading) {
      yield put(changeLoadingState(false));
    }
  }
}

function* getPlayerOverviewVerticalTags({ payload }) {
  try {
    const { id, params } = payload;
    const options = {
      params,
    };
    const { PlayersAdmin, Tags, Overview } = AppConstants.api;
    yield put(changePlayerOverviewVerticalTagsLoadingState(true));
    const { data } = yield call(NetworkService.makeAPIGetRequest, [PlayersAdmin, Tags, Overview, id], options);
    yield put(savePlayerOverviewVerticalTags(data));
    yield put(changePlayerOverviewVerticalTagsLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changePlayerOverviewVerticalTagsLoadingState(false));
  }
}

function* getPlayerOverviewCasinoBonuses({ payload }) {
  try {
    const { params } = payload;
    const options = {
      params,
    };
    const { ReportingAdmin, Bonus, Casino } = AppConstants.api;
    yield put(changePlayerOverviewBonusesLoadingState(true));
    const { data } = yield call(NetworkService.makeAPIGetRequest, [ReportingAdmin, Bonus, Casino], options);
    yield put(savePlayerOverviewCasinoBonuses(data));
    yield put(changePlayerOverviewBonusesLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changePlayerOverviewBonusesLoadingState(false));
  }
}

function* getPlayerOverviewSportBonuses({ payload }) {
  try {
    const { params } = payload;
    const options = {
      params,
    };
    const { ReportingAdmin, Bonus, Sport } = AppConstants.api;
    yield put(changePlayerOverviewBonusesLoadingState(true));
    const { data } = yield call(NetworkService.makeAPIGetRequest, [ReportingAdmin, Bonus, Sport], options);
    yield put(savePlayerOverviewSportBonus(data));
    yield put(changePlayerOverviewBonusesLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changePlayerOverviewBonusesLoadingState(false));
  }
}

function* getPlayerOverviewPayments({ payload }) {
  try {
    const { params } = payload;
    const options = {
      params,
    };
    const {
      ReportingAdmin, PaymentReport, Overview, Payments,
    } = AppConstants.api;
    yield put(changePlayerOverviewPaymentsLoadingState(true));
    const { data } = yield call(NetworkService.makeAPIGetRequest, [ReportingAdmin, PaymentReport, Overview, Payments], options);
    yield put(savePlayerOverviewPayments(data));
    yield put(changePlayerOverviewPaymentsLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changePlayerOverviewPaymentsLoadingState(false));
  }
}

function* getPlayerOverviewCasinoIndicators({ payload }) {
  try {
    const { playerId } = payload;
    const {
      ReportingAdmin, BetReport, Overview, Indicators, Casino,
    } = AppConstants.api;
    yield put(changePlayerOverviewIndicatorsLoadingState(true));
    const { data } = yield call(NetworkService.makeAPIGetRequest, [ReportingAdmin, BetReport, Overview, Indicators, Casino, playerId]);
    yield put(savePlayerOverviewCasinoIndicators(data));
    yield put(changePlayerOverviewIndicatorsLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changePlayerOverviewIndicatorsLoadingState(false));
  }
}

function* getPlayerOverviewSportIndicators({ payload }) {
  try {
    const { playerId } = payload;
    const {
      ReportingAdmin, BetReport, Overview, Indicators, Sport,
    } = AppConstants.api;
    yield put(changePlayerOverviewIndicatorsLoadingState(true));
    const { data } = yield call(NetworkService.makeAPIGetRequest, [ReportingAdmin, BetReport, Overview, Indicators, Sport, playerId]);
    yield put(savePlayerOverviewSportIndicators(data));
    yield put(changePlayerOverviewIndicatorsLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changePlayerOverviewIndicatorsLoadingState(false));
  }
}

export default function* overviewSaga() {
  yield all([
    takeLatest(OverviewActionType.GET_PLAYER_OVERVIEW_FINANCIAL, getPlayerOverviewFinancial),
    takeLatest(OverviewActionType.GET_PLAYER_OVERVIEW_ACTIVITIES, getPlayerOverviewActivities),
    takeLatest(OverviewActionType.GET_PLAYER_OVERVIEW_REVENUES, getPlayerOverviewRevenues),
    takeLatest(OverviewActionType.GET_PLAYER_OVERVIEW_LIMITS_CHECK, getPlayerOverviewLimitsCheck),
    takeLatest(OverviewActionType.GET_PLAYER_OVERVIEW_VERTICAL_TAGS, getPlayerOverviewVerticalTags),
    takeLatest(OverviewActionType.GET_PLAYER_OVERVIEW_CASINO_BONUSES, getPlayerOverviewCasinoBonuses),
    takeLatest(OverviewActionType.GET_PLAYER_OVERVIEW_SPORT_BONUSES, getPlayerOverviewSportBonuses),
    takeLatest(OverviewActionType.GET_PLAYER_OVERVIEW_PAYMENTS, getPlayerOverviewPayments),
    takeLatest(OverviewActionType.GET_PLAYER_OVERVIEW_CASINO_INDICATORS, getPlayerOverviewCasinoIndicators),
    takeLatest(OverviewActionType.GET_PLAYER_OVERVIEW_SPORT_INDICATORS, getPlayerOverviewSportIndicators),
  ]);
}
