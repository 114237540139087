import PlayerWalletActionType from './playerWalletActionType';

export const getPlayerWalletHistory = (params) => ({
  type: PlayerWalletActionType.GET_PLAYER_WALLET_HISTORY,
  payload: { params },
});

export const savePlayerWalletHistory = (data) => ({
  type: PlayerWalletActionType.SAVE_PLAYER_WALLET_HISTORY,
  payload: { data },
});

export const resetPlayerWalletHistory = () => ({
  type: PlayerWalletActionType.RESET_PLAYER_WALLET_HISTORY,
});

export const exportPlayerWalletHistory = (params) => ({
  type: PlayerWalletActionType.EXPORT_PLAYER_WALLET_HISTORY,
  payload: { params },
});

export const getPlayerWalletsInfo = (id, isLoading) => ({
  type: PlayerWalletActionType.GET_PLAYER_WALLETS_INFO,
  payload: { id, isLoading },
});

export const savePlayerWalletsInfo = (data) => ({
  type: PlayerWalletActionType.SAVE_PLAYER_WALLETS_INFO,
  payload: { data },
});

export const resetPlayerWalletsInfo = () => ({
  type: PlayerWalletActionType.RESET_PLAYER_WALLETS_INFO,
});

export const getPlayerNetBalance = (id) => ({
  type: PlayerWalletActionType.GET_PLAYER_NET_BALANCE,
  payload: { id },
});

export const savePlayerNetBalance = (data) => ({
  type: PlayerWalletActionType.SAVE_PLAYER_NET_BALANCE,
  payload: { data },
});

export const changePlayerNetBalanceUpdatedStatus = (status) => ({
  type: PlayerWalletActionType.CHANGE_PLAYER_NET_BALANCE_UPDATED_STATUS,
  payload: { status },
});

export const changePlayerWalletsInfoUpdatedStatus = (status) => ({
  type: PlayerWalletActionType.CHANGE_PLAYER_WALLETS_INFO_UPDATED_STATUS,
  payload: { status },
});

export const resetPlayerNetBalance = () => ({
  type: PlayerWalletActionType.RESET_PLAYER_NET_BALANCE,
});

export const changePlayerNetBalanceLoading = (state) => ({
  type: PlayerWalletActionType.PLAYER_NET_BALANCE_LOADING,
  payload: { state },
});

export const getBalanceAdjustments = (data) => ({
  type: PlayerWalletActionType.GET_BALANCE_ADJUSTMENTS,
  payload: { data },
});

export const getPlayerBalanceAdjustments = (data) => ({
  type: PlayerWalletActionType.GET_PLAYER_BALANCE_ADJUSTMENTS,
  payload: { data },
});

export const exportPlayerBalanceAdjustments = (params) => ({
  type: PlayerWalletActionType.EXPORT_PLAYER_BALANCE_ADJUSTMENTS,
  payload: { params },
});

export const saveBalanceAdjustments = (data) => ({
  type: PlayerWalletActionType.SAVE_BALANCE_ADJUSTMENTS,
  payload: { data },
});

export const resetBalanceAdjustments = () => ({
  type: PlayerWalletActionType.RESET_BALANCE_ADJUSTMENTS,
});

export const createPlayerBalanceAdjustment = (data) => ({
  type: PlayerWalletActionType.CREATE_PLAYER_BALANCE_ADJUSTMENT,
  payload: { data },
});

export const createBulkBalanceAdjustment = (data) => ({
  type: PlayerWalletActionType.CREATE_BULK_BALANCE_ADJUSTMENT,
  payload: { data },
});

export const getBalanceAdjustmentTransactions = (params) => ({
  type: PlayerWalletActionType.GET_BALANCE_ADJUSTMENT_TRANSACTIONS,
  payload: { params },
});

export const saveBalanceAdjustmentTransactions = (data) => ({
  type: PlayerWalletActionType.SAVE_BALANCE_ADJUSTMENT_TRANSACTIONS,
  payload: { data },
});

export const resetBalanceAdjustmentTransactions = () => ({
  type: PlayerWalletActionType.RESET_BALANCE_ADJUSTMENT_TRANSACTIONS,
});

export const generatePlayersRealWalletsSumReport = (params) => ({
  type: PlayerWalletActionType.GENERATE_PLAYERS_REAL_WALLETS_SUM_REPORT,
  payload: { params },
});

export const getAdjustmentCsvPlayers = (data) => ({
  type: PlayerWalletActionType.GET_ADJUSTMENT_CSV_PLAYERS,
  payload: { data },
});

export const saveAdjustmentCsvPlayers = (data) => ({
  type: PlayerWalletActionType.SAVE_ADJUSTMENT_CSV_PLAYERS,
  payload: { data },
});

export const resetAdjustmentCsvPlayers = () => ({
  type: PlayerWalletActionType.RESET_ADJUSTMENT_CSV_PLAYERS,
});
