import styled from 'styled-components';
import { media, truncate } from 'utils/styledHelpers';

const StyledPopoverContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-width: 8rem;
  width: 100%;
  padding: 0;
  white-space: nowrap;
  div:last-child {
    display: flex !important;
    padding-left: ${(props) => (props.padding === 'lastChild' ? '1.35rem' : '')};
  }
  > div {
    width: 100%;
    display: flex;
    align-items: center;
    cursor: pointer;
    padding: 0.7rem 1.14rem;
    box-sizing: border-box;
    &:hover {
      background: ${(props) => props.theme.colors.hoverBg};
    }

    > div {
      margin-right: 1.14rem;
    }
    > span {
      font-size: 1rem;
    }
  }
`;

const StyledManageColumns = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-wrap: wrap;

  ${media.sm`
    position: static;
    flex-direction: column;
    align-items: flex-end;
  `}

  > .ant-btn {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-top: 0;
  }
  .ant-popover {
    .ant-popover-inner-content {
      border-radius: ${(props) => props.theme.customBorderRadius.borderRadiusMiddle};
      width: 18.1rem;
      padding: 1rem 0;
    }
  }
  button {
    margin: 1.71rem auto 0;
  }
  .ant-checkbox-wrapper {
    ${truncate()}
  }
  .ant-checkbox + span {
    font-size: 1rem;
    ${truncate()}
  }
`;
const StyledColumnsText = styled.div`
  margin-right: 0.57rem;
  display: flex;
  align-items: center;
  font-size: 1rem;
  color: ${(props) => props.theme.colors.primaryText};
`;

const StyledAggregationColumn = styled.div`
  border-right: 1px solid ${(props) => props.theme.colors.secondaryBorder};
  display: flex;
  padding-right: 1.71rem;
  height: 18px;

  ${media.sm`
    border: none;
    padding-right: 0;
  `}
`;
const StyledColumnsWrapper = styled.div`
  cursor: pointer;
  padding-left: 1.71rem;
  display: flex;
  align-items: center;
  margin-bottom: 0.57rem;
  &:hover {
    div {
      color: ${(props) => props.theme.colors.blue};
    }
    svg path {
      fill: ${(props) => props.theme.colors.blue};
    }
  }

  ${media.sm`
    display: ${(props) => (props.isCriteria ? 'block' : 'none')};
    margin-top: 0.71rem;
  `}
`;
const StyledPopoverWithCheckboxes = styled.div`
  max-height: 25rem;
  overflow-y: auto;

  .ant-checkbox-wrapper {
    padding: 0.8rem 1.14rem;
    display: flex;
    align-items: center;
    white-space: nowrap;
    min-width: 120px;
    font-weight: ${(props) => props.theme.typography.fontWeightMedium};
    &:hover {
      background: ${(props) => props.theme.colors.hoverBg};
    }
    &.sticky-top {
      position: sticky;
      z-index: 9;
      top: 0;
      background-color: ${(props) => props.theme.colors.selectBg};
    }
    .ant-checkbox + span {
      text-transform: uppercase;
      padding: 0 1.14rem 0 0.71rem;
    }
  }
`;
const StyledMoveToContent = styled.div`
  height: 19.1rem;
  overflow-y: auto;
  padding: 0.23rem 0;
`;

const StyledMoveToPopover = styled.div`
  .ant-popover {
    padding-top: 0;
  }
  .ant-tree .ant-tree-node-content-wrapper:hover,
  .ant-tree .ant-tree-node-content-wrapper:active {
    background: none;
  }
  .ant-tree-treenode {
    border-top: 1px solid transparent;
    border-bottom: 1px solid transparent;
  }
  .ant-input-affix-wrapper > input.ant-input::-webkit-input-placeholder {
    font-size: 1rem;
    line-height: 18;
  }
  .ant-tree .ant-tree-switcher.ant-tree-switcher-noop {
    width: 0.71rem;
  }

  .site-tree-search-value {
    color: inherit;
    background: ${(props) => props.theme.colors.blue}50;
    font-weight: ${(props) => props.theme.typography.fontWeightMedium};
  }
  .ant-tree .ant-tree-node-content-wrapper.ant-tree-node-selected {
    background-color: unset;
    width: 100%;
  }
  .ant-tree .ant-tree-node-content-wrapper {
    line-height: 1.5;
    display: flex;
    align-items: center;
    width: 100%;
  }
  .ant-tree .ant-tree-node-content-wrapper .ant-tree-iconEle {
    height: auto;
  }
  .ant-popover-inner-content {
    padding: 0 0 1.14rem;
  }
  .ant-tree-title {
    margin-left: 0.57rem;
    font-size: 1rem;
  }
  .ant-tree-indent-unit {
    width: 0.9rem;
  }

  .ant-tree .ant-tree-switcher {
    display: flex;
    justify-content: center;
    width: auto;
    margin-right: 0.7rem;
    height: auto;
  }
  .ant-tree-switcher_close {
    > div {
      transform: rotate(270deg);
    }
  }
  .ant-popover-inner {
    border-radius: ${(props) => props.theme.customBorderRadius.borderRadiusMiddle};
    width: 19.1rem;
  }
  .ant-tree {
    background: transparent;
    .ant-tree-title {
      color: ${(props) => props.theme.colors.primaryText};
    }
    .ant-tree-treenode {
      &.ant-tree-treenode-selected {
        background: ${(props) => props.theme.colors.hoverBg};
        border-top: 1px solid ${(props) => props.theme.colors.blue}50;
        border-bottom: 1px solid ${(props) => props.theme.colors.blue}50;
      }
      &:hover {
        background: ${(props) => props.theme.colors.hoverBg};
      }
    }
  }
  .ant-tree .ant-tree-treenode {
    width: 100%;
    padding: 0.57rem 1.14rem;
  }
`;
const StyledFilterManageWrapper = styled.div`
  position: relative;
`;
const ActionMenu = styled.div`
  .ant-dropdown-menu-item .ant-dropdown-menu-submenu-arrow,
  .ant-dropdown-menu-submenu-title .ant-dropdown-menu-submenu-arrow {
    right: 1.14rem;
  }
  .ant-dropdown-menu-submenu-arrow {
    display: none;
  }
  .ant-btn-default {
    span {
      font-size: ${(props) => props.theme.typography.fontSizeDefault};
    }
  }
  li:not(.has-border) + li.has-border {
    position: relative;
    &::before {
      position: absolute;
      top: 0;
      left: 0;
      content: '';
      width: 100%;
      height: 1px;
      background: ${(props) => props.theme.colors.secondaryBorder};
    }
  }
  .ant-dropdown-trigger {
    border-top-right-radius: ${(props) => props.theme.customBorderRadius.borderRadiusSmall};
    border-bottom-right-radius: ${(props) => props.theme.customBorderRadius.borderRadiusSmall};
  }
  .ant-menu {
    .ant-menu-item,
    .ant-menu-submenu div {
      display: flex;
      align-items: center;
      font-size: ${(props) => props.theme.typography.fontSizeDefault};
    }

    .ant-menu-item {
      color: ${(props) => props.theme.colors.primaryText};
      height: 2.57rem;
      padding: 0.57rem 0;

      &-selected span {
        color: ${(props) => props.theme.colors.primaryText};
      }
      &:hover {
        background: ${(props) => props.theme.colors.hoverBg};
      }
      & > div {
        margin: 0 1rem;
      }
    }
    .ant-menu-submenu {
      .ant-menu-submenu-title {
        color: ${(props) => props.theme.colors.primaryText};
        > span {
          margin-left: 1rem;
          div {
            margin-right: 1rem;
          }
        }
        > div {
          color: ${(props) => props.theme.colors.primaryText};
          position: absolute;
          right: 0.7rem;

          > svg {
            width: 1.85rem;
            height: 2.14rem;
          }
        }
        padding: 0 1.14rem 0 0;
        display: flex;
        justify-content: space-between;
        span > .anticon:first-child {
          font-size: 0.86rem;
          display: none;
        }
        > div > div {
          margin: 0 2rem;
        }
      }
      ${media.sm`
        .ant-menu-sub {
          padding: 0 1.14rem 0 3rem;
        }
      `}
    }
  }
`;
const StyledConfigureStepPopover = styled.div`
  .ant-popover-content > .ant-popover-arrow {
    display: block;
  }
  .ant-popover-title {
    padding: 1.14rem;
  }
`;

const StyledMenuAndIcon = styled.div`
  color: ${(props) => props.theme.colors.primaryText};
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

export {
  StyledPopoverContent,
  StyledMoveToPopover,
  StyledManageColumns,
  StyledColumnsText,
  StyledPopoverWithCheckboxes,
  StyledFilterManageWrapper,
  StyledMoveToContent,
  ActionMenu,
  StyledColumnsWrapper,
  StyledConfigureStepPopover,
  StyledMenuAndIcon,
  StyledAggregationColumn,
};
