import PlayerSegmentActionType from 'actions/playerSegment/playerSegmentActionType';

const initialState = {
  playersSegmentsResource: [],
  segmentPlayersResource: [],
  playersSegments: {},
  segmentsPlayers: {},
  playerSegment: {},
  playerIncludedSegments: {},
  playerExcludedSegments: [],
  statusUpdatedPlayersSegments: [],
};

const playerSegmentReducer = (state = initialState, { type, payload }) => {
  switch (type) {
  case PlayerSegmentActionType.SAVE_PLAYERS_SEGMENTS_RESOURCE:
    return {
      ...state,
      playersSegmentsResource: payload.data,
    };
  case PlayerSegmentActionType.RESET_PLAYERS_SEGMENTS_RESOURCE:
    return {
      ...state,
      playersSegmentsResource: [],
    };
  case PlayerSegmentActionType.SAVE_SEGMENT_PLAYERS_RESOURCE:
    return {
      ...state,
      segmentPlayersResource: payload.data,
    };
  case PlayerSegmentActionType.RESET_SEGMENT_PLAYERS_RESOURCE:
    return {
      ...state,
      segmentPlayersResource: [],
    };
  case PlayerSegmentActionType.RESET_SEGMENTS_PLAYERS:
    return {
      ...state,
      segmentsPlayers: {},
    };
  case PlayerSegmentActionType.SAVE_PLAYERS_SEGMENTS:
    return {
      ...state,
      playersSegments: payload.data,
    };
  case PlayerSegmentActionType.SAVE_SEGMENTS_PLAYERS:
    return {
      ...state,
      segmentsPlayers: payload.data,
    };
  case PlayerSegmentActionType.RESET_PLAYERS_SEGMENTS:
    return {
      ...state,
      playersSegments: {},
    };
  case PlayerSegmentActionType.SAVE_PLAYER_INCLUDED_SEGMENTS:
    return {
      ...state,
      playerIncludedSegments: payload.data,
    };
  case PlayerSegmentActionType.RESET_PLAYER_INCLUDED_SEGMENTS:
    return {
      ...state,
      playerIncludedSegments: {},
    };
  case PlayerSegmentActionType.SAVE_PLAYER_EXCLUDED_SEGMENTS:
    return {
      ...state,
      playerExcludedSegments: payload.data,
    };
  case PlayerSegmentActionType.RESET_PLAYER_EXCLUDED_SEGMENTS:
    return {
      ...state,
      playerExcludedSegments: [],
    };
  case PlayerSegmentActionType.SAVE_STATUS_UPDATED_PLAYERS_SEGMENTS:
    return {
      ...state,
      statusUpdatedPlayersSegments: payload.data,
    };
  case PlayerSegmentActionType.RESET_STATUS_UPDATED_PLAYERS_SEGMENTS:
    return {
      ...state,
      statusUpdatedPlayersSegments: [],
    };
  case PlayerSegmentActionType.SAVE_PLAYER_SEGMENT: {
    return {
      ...state,
      playerSegment: payload.data,
    };
  }
  case PlayerSegmentActionType.RESET_PLAYER_SEGMENT: {
    return {
      ...state,
      playerSegment: {},
    };
  }
  default:
    return state;
  }
};

export default playerSegmentReducer;
