import Header from 'containers/layout/header/Header';
import { StyledAccountMarginTop } from 'components/layout/Layout.styled';
import Account from 'pages/Account';

const NoAccessAccount = () => (
  <>
    <Header isSearch={false} />
    <StyledAccountMarginTop>
      <Account />
    </StyledAccountMarginTop>
  </>
);
export default NoAccessAccount;
