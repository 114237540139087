const TranslationActionType = {
  GET_TRANSLATION_SECTIONS: 'GET_TRANSLATION_SECTIONS',
  GET_TRANSLATION_SECTIONS_SUCCESS: 'GET_TRANSLATION_SECTIONS_SUCCESS',
  RESET_TRANSLATION_SECTIONS: 'RESET_TRANSLATION_SECTIONS',

  CHANGE_TRANSLATION_LOADING_STATE: 'CHANGE_TRANSLATION_LOADING_STATE',
  CHANGE_TRANSLATED_LANGUAGES_LOADING_STATE: 'CHANGE_TRANSLATED_LANGUAGES_LOADING_STATE',

  GET_ALL_TRANSLATIONS_BY_KEY: 'GET_ALL_TRANSLATIONS_BY_KEY',
  GET_ALL_TRANSLATIONS_BY_KEY_SUCCESS: 'GET_ALL_TRANSLATIONS_BY_KEY_SUCCESS',
  RESET_ALL_TRANSLATIONS_BY_KEY: 'RESET_ALL_TRANSLATIONS_BY_KEY',

  GET_ALL_TRANSLATIONS: 'GET_ALL_TRANSLATIONS',
  SAVE_ALL_TRANSLATIONS: 'SAVE_ALL_TRANSLATIONS',
  RESET_ALL_TRANSLATIONS: 'RESET_ALL_TRANSLATIONS',

  EDIT_TRANSLATIONS: 'EDIT_TRANSLATIONS',

  EXPORT_TRANSLATIONS: 'EXPORT_TRANSLATIONS',

  IMPORT_TRANSLATIONS: 'IMPORT_TRANSLATIONS',

  SET_TRANSLATIONS_CHANGES_STATE: 'SET_TRANSLATIONS_CHANGES_STATE',

  RESET_TRANSLATIONS_TO_DEFAULT: 'RESET_TRANSLATIONS_TO_DEFAULT',

  GET_TRANSLATED_LANGUAGES: 'GET_TRANSLATED_LANGUAGES',
  GET_TRANSLATED_LANGUAGES_SUCCESS: 'GET_TRANSLATED_LANGUAGES_SUCCESS',
  RESET_TRANSLATED_LANGUAGES: 'RESET_TRANSLATED_LANGUAGES',
};

export default TranslationActionType;
