import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Popover, Tooltip } from 'antd';
import qs from 'qs';
import { useTranslation } from 'react-i18next';
import enumTypes from 'constants/enumTypes';
import { NavigationService } from 'services';
import { IconSvg } from 'components/common';
import { StyledIconsWrapper, StyledIconHoverBrand } from 'components/styledComponents/icon/Icon.styled';
import BrandLogo from 'assets/img/brand_logo.png';
import {
  StyledLanguage, StyledBrandItemStatus, StyledBrandStatus, StyleBrandItem, StyledCardBrandName, StyledCardImageThumb,
} from 'components/brand/Brand.styled';
import {
  StyledCardFlexItem, StyledCardItem, StyledCardList, StyledCardPreview,
} from 'components/styledComponents/card/Card.styled';
import { StyledMaintenancePeriodDates, StyledTruncate } from 'Global.styled';
import Config from 'config';
import Can from 'components/common/Can';
import { resource, types } from 'constants/accessControl';
import { useTimeZone } from 'hooks';
import { AppConstants } from 'constants';

const { isBrRegulationAudit } = Config;

const settingsAvailable = () => {
  const permissions = [
    {
      type: types.view,
      rule: `${resource.brands}.${resource.mainSettings}`,
      key: 'mainSettings',
    },
    {
      type: types.view,
      rule: `${resource.brands}.${resource.sportsBook}`,
      key: 'sportsbook',
    },
    {
      type: types.view,
      rule: `${resource.brands}.${resource.casinoProduct}`,
      key: 'casino',
    },
    {
      type: types.view,
      rule: `${resource.brands}.${resource.verificationConfigurations}`,
      key: 'mainSettings',
    },
    {
      type: types.view,
      rule: `${resource.brands}.${resource.payments}`,
      key: 'payment',
    },
    {
      type: types.view,
      rule: `${resource.brands}.${resource.thirdPartyServices}`,
      key: 'thirdPartyServices',
    },
    {
      type: types.view,
      rule: `${resource.brands}.${resource.notificationSettings}`,
      key: 'notificationSettings',
    },
    {
      type: types.view,
      rule: `${resource.brands}.${resource.smtpConfigurations}`,
      key: 'smtpConfigurations',
    },
    {
      type: types.view,
      rule: `${resource.brands}.${resource.locationServices}`,
      key: 'locationServices',
    },
    {
      type: types.view,
      rule: `${resource.brands}.${resource.conversionSettingsDepositAndWithdrawals}`,
      key: 'conversionSettings',
    },
    {
      type: types.view,
      rule: `${resource.brands}.${resource.conversionSettingsOthers}`,
      key: 'conversionSettings',
    },
    {
      type: types.edit,
      rule: `${resource.brands}.${resource.mainSettings}`,
      key: 'mainSettings',
    },
    {
      type: types.view,
      rule: [`${resource.brands}.${resource.largeTransactions}`, `${resource.brands}.${resource.playerLimits}`],
      key: 'operatorLimits',
    },
    {
      type: types.view,
      rule: `${resource.brands}.${resource.failedPayments}`,
      key: 'payments',
    },
    {
      type: types.view,
      rule: `${resource.brands}.${resource.legal}`,
      key: 'legalAgreements',
    },
    {
      type: types.view,
      rule: `${resource.brands}.${resource.seoSettings}`,
      key: 'seoSettings',
    },
    {
      type: types.view,
      rule: `${resource.brands}.${resource.standards}`,
      key: 'standards',
    },
    {
      type: types.view,
      rule: [`${resource.brands}.${resource.taxOnDeposit}`, `${resource.brands}.${resource.taxOnWithdrawal}`],
      key: 'taxes',
    },
    {
      type: types.view,
      rule: `${resource.brands}.${resource.domainConfigurations}`,
      key: 'mainSettings',
    },
  ];

  return permissions.find(({ type, rule }) => Can({
    type,
    rule,
    children: true,
    fallback: false,
  }));
};

const builderAvailable = () => [
  {
    type: types.manage,
    rule: `${resource.uiCustomization}.${resource.businessRulesManagement}`,
  },
  {
    type: types.manage,
    rule: `${resource.uiCustomization}.${resource.promotionManager}`,
  },
  {
    type: types.manage,
    rule: `${resource.uiCustomization}.${resource.uiBackgroundImages}`,
  },
  {
    type: types.add,
    rule: `${resource.uiCustomization}.${resource.uiBanner}`,
  },
  {
    type: types.edit,
    rule: `${resource.uiCustomization}.${resource.uiBanner}`,
  },
  {
    type: types.delete,
    rule: `${resource.uiCustomization}.${resource.uiBanner}`,
  },
  {
    type: types.edit,
    rule: `${resource.uiCustomization}.${resource.uiButtons}`,
  },
  {
    type: types.edit,
    rule: `${resource.uiCustomization}.${resource.uiColor}`,
  },
  {
    type: types.edit,
    rule: `${resource.uiCustomization}.${resource.uiFont}`,
  },
  {
    type: types.edit,
    rule: `${resource.uiCustomization}.${resource.uiForms}`,
  },
  {
    type: types.edit,
    rule: `${resource.uiCustomization}.${resource.uiIcon}`,
  },
  {
    type: types.edit,
    rule: `${resource.uiCustomization}.${resource.uiInputs}`,
  },
  {
    type: types.edit,
    rule: `${resource.uiCustomization}.${resource.uiLogos}`,
  },
  {
    type: types.edit,
    rule: `${resource.uiCustomization}.${resource.uiMenu}`,
  },
  {
    type: types.add,
    rule: `${resource.uiCustomization}.${resource.uiMenu}`,
  },
  {
    type: types.edit,
    rule: `${resource.uiCustomization}.${resource.uiMenu}`,
  },
  {
    type: types.delete,
    rule: `${resource.uiCustomization}.${resource.uiMenu}`,
  },
  {
    type: types.edit,
    rule: `${resource.uiCustomization}.${resource.uiPagesContent}`,
  },
  {
    type: types.manage,
    rule: `${resource.uiCustomization}.${resource.uiPagesContent}`,
  },
  {
    type: types.edit,
    rule: `${resource.uiCustomization}.${resource.uiPagesContent}`,
  },
  {
    type: types.view,
    rule: `${resource.uiCustomization}.${resource.uiPagesList}`,
  },
].some(
  ({ type, rule }) => !Can({
    type,
    rule,
    children: false,
    fallback: true,
  }),
);
const BrandItem = (props) => {
  const { data, partnerId, locale } = props;

  const { brandStatuses, deviceTypes } = locale;
  const { getFormattedDate } = useTimeZone();

  const { t } = useTranslation();

  const query = {
    cid: partnerId,
    p: data.id,
  };
  const uiCusUrl = `${Config.siteBuilderURL}edit/customize/${data.id}?${qs.stringify(query)}`;

  const availablePage = settingsAvailable();

  const getStatus = () => {
    switch (+data.status) {
    case enumTypes.brandStatuses.VersionNamesDisabled:
      return (
        <Popover placement="bottomLeft" content={`${data.disabledVersionNames.map((el) => deviceTypes.get(el)).join(', ')} ${t('disabled')}`} title="" overlayClassName="description-popover">
          <StyledTruncate>{`${data.disabledVersionNames.map((el) => deviceTypes.get(el)).join(', ')} ${t('disabled')}`}</StyledTruncate>
        </Popover>
      );
    case enumTypes.brandStatuses.Maintenance:
    case enumTypes.brandStatuses.Active: {
      const content = !!data.maintenancePeriodFrom && (
        <StyledMaintenancePeriodDates>
          {`${getFormattedDate(data.maintenancePeriodFrom, AppConstants.format.dateTime)} - `}
          {data.maintenancePeriodTo ? getFormattedDate(data.maintenancePeriodTo, AppConstants.format.dateTime) : <IconSvg icon="InfinityIcon" width="1.28rem" height="1.28rem" />}
        </StyledMaintenancePeriodDates>
      );

      return (
        <>
          <Popover placement="bottomLeft" content={brandStatuses[data.status]} title="" overlayClassName="description-popover">
            <StyledTruncate>{brandStatuses[data.status]}</StyledTruncate>
          </Popover>
          {content && (
            <Popover placement="bottom" content={content} title="" overlayClassName="description-popover">
              {' '}
              <IconSvg icon="MaintenanceIcon" width="1.14rem" height="1rem" />
            </Popover>
          )}
        </>
      );
    }
    default:
      return (
        <Popover placement="bottomLeft" content={brandStatuses[data.status]} title="" overlayClassName="description-popover">
          <StyledTruncate>{brandStatuses[data.status]}</StyledTruncate>
        </Popover>
      );
    }
  };

  const cardStatuses = ['', 'seen', 'unseen', 'unseen', 'unseen', 'unseen'];

  return (
    <StyledCardFlexItem height="12.4rem">
      <StyledCardItem>
        <StyledCardImageThumb>
          <img alt={data.name} src={data.logo || BrandLogo} />
          <StyledBrandItemStatus status={cardStatuses[data.status]} />
        </StyledCardImageThumb>
        <div>
          <StyledCardBrandName>
            <Popover placement="bottomLeft" content={data.name.toUpperCase()} title="" overlayClassName="description-popover">
              <StyledTruncate>{data.name}</StyledTruncate>
            </Popover>
          </StyledCardBrandName>
          <StyledBrandStatus>{data.id && getStatus()}</StyledBrandStatus>
          <StyledCardList>
            <li>
              <IconSvg icon="InfoIcon" width="1.12rem" height="1.12rem" />
              <h3>
                {t('id')}
                :
                <strong>{data.id}</strong>
              </h3>
            </li>
            <li>
              <IconSvg icon="Calendar2Icon" width="1.12rem" height="1.12rem" />
              <h3>
                {t('registrationDate')}
                :
                <strong>{getFormattedDate(data.createdDate)}</strong>
              </h3>
            </li>
            <li>
              <IconSvg icon="LocationIcon" width="1.12rem" height="1.12rem" />
              <h3>
                <StyledTruncate>
                  {t('country')}
                  :
                  <strong>{data.mainCountry && data.mainCountry.name}</strong>
                </StyledTruncate>
              </h3>
            </li>
            <li>
              <IconSvg icon="LanguageIcon" width="1.12rem" height="1.12rem" />
              <h3>
                {t('language')}
                :
                <span>
                  <strong>{data.mainLanguage && data.mainLanguage.name}</strong>
                </span>
              </h3>
            </li>
            <li>
              <IconSvg icon="CurrencyIcon" width="1.12rem" height="1.12rem" />
              <h3>
                {t('currency')}
                :
                <StyledLanguage>
                  <strong>{data.mainCurrency && data.mainCurrency.id}</strong>
                </StyledLanguage>
              </h3>
            </li>
          </StyledCardList>
        </div>
      </StyledCardItem>
      <StyledIconsWrapper>
        <StyledCardPreview disabled={data.status ? 0 : 1}>
          <Tooltip placement="left" title={t('overview')} getPopupContainer={(trigger) => trigger.parentNode}>
            <StyledIconHoverBrand>
              <Link to={`//${data.domain}`} target="_blank">
                <IconSvg icon="PreviewIcon" />
              </Link>
            </StyledIconHoverBrand>
          </Tooltip>
        </StyledCardPreview>
        {!isBrRegulationAudit && builderAvailable() && (
          <StyleBrandItem>
            <Tooltip placement="left" title={t('brand:builder')} getPopupContainer={(trigger) => trigger.parentNode}>
              <StyledIconHoverBrand>
                <Link to={uiCusUrl} target="_blank">
                  <IconSvg icon="BrandBuilderIcon" />
                </Link>
              </StyledIconHoverBrand>
            </Tooltip>
          </StyleBrandItem>
        )}
        {availablePage && (
          <StyleBrandItem>
            <Tooltip placement="left" title={t('settings')} getPopupContainer={(trigger) => trigger.parentNode}>
              <StyledIconHoverBrand onClick={() => NavigationService(`/brands/${data.id}/${availablePage.key}`)}>
                <IconSvg icon="BrandSettingsIcon" />
              </StyledIconHoverBrand>
            </Tooltip>
          </StyleBrandItem>
        )}
      </StyledIconsWrapper>
    </StyledCardFlexItem>
  );
};

BrandItem.propTypes = {
  data: PropTypes.object.isRequired,
  partnerId: PropTypes.number.isRequired,
  locale: PropTypes.object.isRequired,
};

export default BrandItem;
