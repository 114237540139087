import ResourceActionType from './resourceActionType';

export const getResourcePaymentMethods = (params, isLoading) => ({
  type: ResourceActionType.GET_RESOURCE_PAYMENT_METHODS,
  payload: { params, isLoading },
});

export const saveResourcePaymentMethods = (data) => ({
  type: ResourceActionType.SAVE_RESOURCE_PAYMENT_METHODS,
  payload: { data },
});

export const resetResourcePaymentMethods = () => ({
  type: ResourceActionType.RESET_RESOURCE_PAYMENT_METHODS,
});

export const getResourceCountries = (params, isLoading) => ({
  type: ResourceActionType.GET_RESOURCE_COUNTRIES,
  payload: { params, isLoading },
});

export const saveResourceCountries = (data) => ({
  type: ResourceActionType.SAVE_RESOURCE_COUNTRIES,
  payload: { data },
});

export const resetResourceCountries = () => ({
  type: ResourceActionType.RESET_RESOURCE_COUNTRIES,
});

export const getResourceSegments = (brandId, isLoading) => ({
  type: ResourceActionType.GET_RESOURCE_SEGMENTS,
  payload: { brandId, isLoading },
});

export const saveResourceSegments = (data) => ({
  type: ResourceActionType.SAVE_RESOURCE_SEGMENTS,
  payload: { data },
});

export const resetResourceSegments = () => ({
  type: ResourceActionType.RESET_RESOURCE_SEGMENTS,
});

export const getResourceTags = (brandId, params, isLoading, key = 'first') => ({
  type: ResourceActionType.GET_RESOURCE_TAGS,
  payload: {
    brandId,
    params,
    isLoading,
    key,
  },
});

export const saveResourceTags = (data, key = 'first') => ({
  type: ResourceActionType.SAVE_RESOURCE_TAGS,
  payload: { data, key },
});

export const resetResourceTags = (key = 'first') => ({
  type: ResourceActionType.RESET_RESOURCE_TAGS,
  payload: { key },
});

export const getResourceGameCategories = (params, isProvider, isLoading) => ({
  type: ResourceActionType.GET_RESOURCE_GAME_CATEGORIES,
  payload: { params, isProvider, isLoading },
});

export const saveResourceGameCategories = (data) => ({
  type: ResourceActionType.SAVE_RESOURCE_GAME_CATEGORIES,
  payload: { data },
});

export const resetResourceGameCategories = () => ({
  type: ResourceActionType.RESET_RESOURCE_GAME_CATEGORIES,
});

export const changeResourceGameCategoriesLoadingState = (state) => ({
  type: ResourceActionType.CHANGE_RESOURCE_GAME_CATEGORIES_STATE,
  payload: { state },
});

export const getResourceUsers = (brandId, isLoading) => ({
  type: ResourceActionType.GET_RESOURCE_USERS,
  payload: { brandId, isLoading },
});

export const saveResourceUsers = (data) => ({
  type: ResourceActionType.SAVE_RESOURCE_USERS,
  payload: { data },
});

export const resetResourceUsers = () => ({
  type: ResourceActionType.RESET_RESOURCE_USERS,
});

export const getResourcePaymentMethodCurrencies = (id, params, isLoading) => ({
  type: ResourceActionType.GET_RESOURCE_PAYMENT_METHOD_CURRENCIES,
  payload: { id, params, isLoading },
});

export const saveResourcePaymentMethodCurrencies = (data) => ({
  type: ResourceActionType.SAVE_RESOURCE_PAYMENT_METHOD_CURRENCIES,
  payload: { data },
});

export const resetResourcePaymentMethodCurrencies = () => ({
  type: ResourceActionType.RESET_RESOURCE_PAYMENT_METHOD_CURRENCIES,
});

export const getResourceCurrencies = (params, isLoading) => ({
  type: ResourceActionType.GET_RESOURCE_CURRENCIES,
  payload: { params, isLoading },
});

export const saveResourceCurrencies = (data) => ({
  type: ResourceActionType.SAVE_RESOURCE_CURRENCIES,
  payload: { data },
});

export const resetResourceCurrencies = () => ({
  type: ResourceActionType.RESET_RESOURCE_CURRENCIES,
});

export const getResourceProviders = (params, isLoading, key = 'first') => ({
  type: ResourceActionType.GET_RESOURCE_PROVIDERS,
  payload: { params, isLoading, key },
});

export const saveResourceProviders = (data, key = 'first') => ({
  type: ResourceActionType.SAVE_RESOURCE_PROVIDERS,
  payload: { data, key },
});

export const resetResourceProviders = (key = 'first') => ({
  type: ResourceActionType.RESET_RESOURCE_PROVIDERS,
  payload: { key },
});

export const saveResourceGameCategoriesInProviders = (data) => ({
  type: ResourceActionType.SAVE_RESOURCE_GAME_CATEGORIES_IN_PROVIDERS,
  payload: { data },
});

export const resetResourceGameProvidersInCategories = () => ({
  type: ResourceActionType.RESET_RESOURCE_GAME_CATEGORIES_IN_PROVIDERS,
});

export const getResourceGames = (params) => ({
  type: ResourceActionType.GET_RESOURCE_GAMES,
  payload: { params },
});

export const saveResourceGames = (data) => ({
  type: ResourceActionType.SAVE_RESOURCE_GAMES,
  payload: { data },
});

export const resetResourceGames = () => ({
  type: ResourceActionType.RESET_RESOURCE_GAMES,
});

export const getResourceReasons = (brandId, params, isLoading) => ({
  type: ResourceActionType.GET_RESOURCE_REASONS,
  payload: { brandId, params, isLoading },
});

export const saveResourceReasons = (data) => ({
  type: ResourceActionType.SAVE_RESOURCE_REASONS,
  payload: { data },
});

export const resetResourceReasons = () => ({
  type: ResourceActionType.RESET_RESOURCE_REASONS,
});

export const getResourceCampaigns = (brandId, isLoading) => ({
  type: ResourceActionType.GET_RESOURCE_CAMPAIGNS,
  payload: { brandId, isLoading },
});

export const saveResourceCampaigns = (data) => ({
  type: ResourceActionType.SAVE_RESOURCE_CAMPAIGNS,
  payload: { data },
});

export const resetResourceCampaigns = () => ({
  type: ResourceActionType.RESET_RESOURCE_CAMPAIGNS,
});

export const getResourceTagCategories = (params, isLoading, key = 'first') => ({
  type: ResourceActionType.GET_RESOURCE_TAG_CATEGORIES,
  payload: { params, isLoading, key },
});

export const saveResourceTagCategories = (data, key = 'first') => ({
  type: ResourceActionType.SAVE_RESOURCE_TAG_CATEGORIES,
  payload: { data, key },
});

export const resetResourceTagCategories = (key = 'first') => ({
  type: ResourceActionType.RESET_RESOURCE_TAG_CATEGORIES,
  payload: { key },
});

export const getResourceTagCategory = (id, params, isLoading) => ({
  type: ResourceActionType.GET_RESOURCE_TAG_CATEGORY,
  payload: { id, params, isLoading },
});

export const saveResourceTagCategory = (data) => ({
  type: ResourceActionType.SAVE_RESOURCE_TAG_CATEGORY,
  payload: { data },
});

export const resetResourceTagCategory = () => ({
  type: ResourceActionType.RESET_RESOURCE_TAG_CATEGORY,
});

export const getResourceTagsNames = (brandId, params, isLoading) => ({
  type: ResourceActionType.GET_RESOURCE_TAGS_NAMES,
  payload: { brandId, params, isLoading },
});

export const saveResourceTagsNames = (data) => ({
  type: ResourceActionType.SAVE_RESOURCE_TAGS_NAMES,
  payload: { data },
});

export const resetResourceTagsNames = () => ({
  type: ResourceActionType.RESET_RESOURCE_TAGS_NAMES,
});

export const getResourcePlayerTags = (brandId, playerId, params, isLoading) => ({
  type: ResourceActionType.GET_RESOURCE_PLAYER_TAGS,
  payload: {
    brandId,
    playerId,
    params,
    isLoading,
  },
});

export const saveResourcePlayerTags = (data) => ({
  type: ResourceActionType.SAVE_RESOURCE_PLAYER_TAGS,
  payload: { data },
});

export const resetResourcePlayerTags = () => ({
  type: ResourceActionType.RESET_RESOURCE_PLAYER_TAGS,
});

export const getResourceVerticalCategoryReasons = (params, isLoading, key = 'first') => ({
  type: ResourceActionType.GET_RESOURCE_VERTICAL_CATEGORY_REASONS,
  payload: { params, isLoading, key },
});

export const saveResourceVerticalCategoryReasons = (data, key = 'first') => ({
  type: ResourceActionType.SAVE_RESOURCE_VERTICAL_CATEGORY_REASONS,
  payload: { data, key },
});

export const resetResourceVerticalCategoryReasons = (key = 'first') => ({
  type: ResourceActionType.RESET_RESOURCE_VERTICAL_CATEGORY_REASONS,
  payload: { key },
});

export const getResourceBrand = (id) => ({
  type: ResourceActionType.GET_RESOURCE_BRAND,
  payload: { id },
});

export const saveResourceBrand = (data) => ({
  type: ResourceActionType.SAVE_RESOURCE_BRAND,
  payload: { data },
});

export const resetResourceBrand = () => ({
  type: ResourceActionType.RESET_RESOURCE_BRAND,
});

export const getResourcePartner = (id) => ({
  type: ResourceActionType.GET_RESOURCE_PARTNER,
  payload: { id },
});

export const saveResourcePartner = (data) => ({
  type: ResourceActionType.SAVE_RESOURCE_PARTNER,
  payload: { data },
});

export const resetResourcePartner = () => ({
  type: ResourceActionType.RESET_RESOURCE_PARTNER,
});

export const getResourceBetshops = (params) => ({
  type: ResourceActionType.GET_RESOURCE_BETSHOPS,
  payload: { params },
});

export const saveResourceBetshops = (data) => ({
  type: ResourceActionType.SAVE_RESOURCE_BETSHOPS,
  payload: { data },
});

export const resetResourceBetshops = () => ({
  type: ResourceActionType.RESET_RESOURCE_BETSHOPS,
});

export const getResourceNegativeBalanceExists = (brandId, params) => ({
  type: ResourceActionType.GET_RESOURCE_NEGATIVE_BALANCE_EXISTS,
  payload: { brandId, params },
});

export const saveResourceNegativeBalanceExists = (data) => ({
  type: ResourceActionType.SAVE_RESOURCE_NEGATIVE_BALANCE_EXISTS,
  payload: { data },
});

export const resetResourceNegativeBalanceExists = () => ({
  type: ResourceActionType.RESET_RESOURCE_NEGATIVE_BALANCE_EXISTS,
});

export const getResourcePartnerTFAState = (id) => ({
  type: ResourceActionType.GET_RESOURCE_PARTNER_TFA_STATE,
  payload: { id },
});

export const saveResourcePartnerTFAState = (data) => ({
  type: ResourceActionType.SAVE_RESOURCE_PARTNER_TFA_STATE,
  payload: { data },
});

export const resetResourcePartnerTFAState = () => ({
  type: ResourceActionType.RESET_RESOURCE_PARTNER_TFA_STATE,
});

export const getResourceGamesSegments = (brandId, params, isLoading) => ({
  type: ResourceActionType.GET_RESOURCE_GAMES_SEGMENTS,
  payload: { brandId, params, isLoading },
});

export const saveResourceGamesSegments = (data) => ({
  type: ResourceActionType.SAVE_RESOURCE_GAMES_SEGMENTS,
  payload: { data },
});

export const resetResourceGamesSegments = () => ({
  type: ResourceActionType.RESET_RESOURCE_GAMES_SEGMENTS,
});

export const getResourceCrossPlatformCampaigns = (params) => ({
  type: ResourceActionType.GET_RESOURCE_CROSS_PLATFORM_CAMPAIGNS,
  payload: { params },
});

export const saveResourceCrossPlatformCampaigns = (data) => ({
  type: ResourceActionType.SAVE_RESOURCE_CROSS_PLATFORM_CAMPAIGNS,
  payload: { data },
});

export const resetResourceCrossPlatformCampaigns = () => ({
  type: ResourceActionType.RESET_RESOURCE_CROSS_PLATFORM_CAMPAIGNS,
});

export const getResourceCrossPlatformCampaignBonuses = (id, params) => ({
  type: ResourceActionType.GET_RESOURCE_CROSS_PLATFORM_CAMPAIGN_BONUSES,
  payload: { id, params },
});

export const saveResourceCrossPlatformCampaignBonuses = (data) => ({
  type: ResourceActionType.SAVE_RESOURCE_CROSS_PLATFORM_CAMPAIGN_BONUSES,
  payload: { data },
});

export const resetResourceCrossPlatformCampaignBonuses = () => ({
  type: ResourceActionType.RESET_RESOURCE_CROSS_PLATFORM_CAMPAIGN_BONUSES,
});

export const changeResourceGamesLoadingState = (state) => ({
  type: ResourceActionType.CHANGE_RESOURCE_GAMES_LOADING,
  payload: { state },
});

export const getResourcePromotions = (params, isLoading, key = 'first') => ({
  type: ResourceActionType.GET_RESOURCE_PROMOTIONS,
  payload: { params, isLoading, key },
});

export const saveResourcePromotions = (data, key = 'first') => ({
  type: ResourceActionType.SAVE_RESOURCE_PROMOTIONS,
  payload: { data, key },
});

export const resetResourcePromotions = (key = 'first') => ({
  type: ResourceActionType.RESET_RESOURCE_PROMOTIONS,
  payload: { key },
});

export const getResourcePromotionMachines = (params, isLoading, key = 'first') => ({
  type: ResourceActionType.GET_RESOURCE_PROMOTION_MACHINES,
  payload: { params, isLoading, key },
});

export const saveResourcePromotionMachines = (data, key = 'first') => ({
  type: ResourceActionType.SAVE_RESOURCE_PROMOTION_MACHINES,
  payload: { data, key },
});

export const resetResourcePromotionMachines = (key = 'first') => ({
  type: ResourceActionType.RESET_RESOURCE_PROMOTION_MACHINES,
  payload: { key },
});

export const getResourceBrandPeriodsLimits = (sourceId, params) => ({
  type: ResourceActionType.GET_RESOURCE_BRAND_PERIODS_LIMITS,
  payload: { sourceId, params },
});

export const saveResourceBrandPeriodsLimits = (data) => ({
  type: ResourceActionType.SAVE_RESOURCE_BRAND_PERIODS_LIMITS,
  payload: { data },
});

export const resetResourceBrandPeriodsLimits = () => ({
  type: ResourceActionType.RESET_RESOURCE_BRAND_PERIODS_LIMITS,
});

export const getResourceGameGroupsNames = (params, isLoading) => ({
  type: ResourceActionType.GET_RESOURCE_GAME_GROUPS_NAMES,
  payload: { params, isLoading },
});

export const saveResourceGameGroupsNames = (data) => ({
  type: ResourceActionType.SAVE_RESOURCE_GAME_GROUPS_NAMES,
  payload: { data },
});

export const resetResourceGameGroupsNames = () => ({
  type: ResourceActionType.RESET_RESOURCE_GAME_GROUPS_NAMES,
});

export const getResourceCurrenciesPrecisions = (isLoading) => ({
  type: ResourceActionType.GET_RESOURCE_CURRENCIES_PRECISIONS,
  payload: { isLoading },
});

export const saveResourceCurrenciesPrecisions = (data) => ({
  type: ResourceActionType.SAVE_RESOURCE_CURRENCIES_PRECISIONS,
  payload: { data },
});

export const getResourceBrandTaxDda = (brandId) => ({
  type: ResourceActionType.GET_RESOURCE_BRAND_TAX_DDA,
  payload: { brandId },
});

export const saveResourceBrandTaxDda = (data) => ({
  type: ResourceActionType.SAVE_RESOURCE_BRAND_TAX_DDA,
  payload: { data },
});

export const resetResourceBrandTaxDda = () => ({
  type: ResourceActionType.RESET_RESOURCE_BRAND_TAX_DDA,
});

export const getResourceFixedWithdrawalAmount = (brandId) => ({
  type: ResourceActionType.GET_RESOURCE_FIXED_WITHDRAWAL_AMOUNT,
  payload: { brandId },
});

export const saveResourceFixedWithdrawalAmount = (data) => ({
  type: ResourceActionType.SAVE_RESOURCE_FIXED_WITHDRAWAL_AMOUNT,
  payload: { data },
});

export const resetResourceFixedWithdrawalAmount = () => ({
  type: ResourceActionType.RESET_RESOURCE_FIXED_WITHDRAWAL_AMOUNT,
});

export const getResourceProvidersAggregators = (params) => ({
  type: ResourceActionType.GET_RESOURCE_PROVIDERS_AGGREGATORS,
  payload: { params },
});

export const saveResourceProvidersAggregators = (data) => ({
  type: ResourceActionType.SAVE_RESOURCE_PROVIDERS_AGGREGATORS,
  payload: { data },
});

export const resetResourceProvidersAggregators = () => ({
  type: ResourceActionType.RESET_RESOURCE_PROVIDERS_AGGREGATORS,
});

export const changeResourceTagsLoading = (state) => ({
  type: ResourceActionType.CHANGE_RESOURCE_TAGS_LOADING,
  payload: { state },
});

export const getResourceSportsBookLayouts = () => ({
  type: ResourceActionType.GET_RESOURCE_SPORTSBOOK_LAYOUTS,
});

export const saveResourceSportsBookLayouts = (data) => ({
  type: ResourceActionType.SAVE_RESOURCE_SPORTSBOOK_LAYOUTS,
  payload: { data },
});

export const resetResourceSportsBookLayouts = () => ({
  type: ResourceActionType.RESET_RESOURCE_SPORTSBOOK_LAYOUTS,
});

export const getResourceGridMachineDimensionTypes = () => ({
  type: ResourceActionType.GET_RESOURCE_GRID_MACHINE_DIMENSION_TYPES,
});

export const saveResourceGridMachineDimensionTypes = (data) => ({
  type: ResourceActionType.SAVE_RESOURCE_GRID_MACHINE_DIMENSION_TYPES,
  payload: { data },
});

export const resetResourceGridMachineDimensionTypes = () => ({
  type: ResourceActionType.RESET_RESOURCE_GRID_MACHINE_DIMENSION_TYPES,
});

export const getResourceCustomPages = (params) => ({
  type: ResourceActionType.GET_RESOURCE_CUSTOM_PAGES,
  payload: { params },
});

export const saveResourceCustomPages = (data) => ({
  type: ResourceActionType.SAVE_RESOURCE_CUSTOM_PAGES,
  payload: { data },
});

export const resetResourceCustomPages = () => ({
  type: ResourceActionType.RESET_RESOURCE_CUSTOM_PAGES,
});

export const getResourceCashierType = (params) => ({
  type: ResourceActionType.GET_RESOURCE_CASHIER_TYPE,
  payload: { params },
});

export const saveResourceCashierType = (data) => ({
  type: ResourceActionType.SAVE_RESOURCE_CASHIER_TYPE,
  payload: { data },
});

export const resetResourceCashierType = () => ({
  type: ResourceActionType.RESET_RESOURCE_CASHIER_TYPE,
});

export const getResourceReleaseNotes = () => ({
  type: ResourceActionType.GET_RESOURCE_RELEASE_NOTES,
});

export const saveResourceReleaseNotes = (data) => ({
  type: ResourceActionType.SAVE_RESOURCE_RELEASE_NOTES,
  payload: { data },
});

export const resetResourceReleaseNotes = () => ({
  type: ResourceActionType.RESET_RESOURCE_RELEASE_NOTES,
});

export const getResourceGameTypes = (params) => ({
  type: ResourceActionType.GET_RESOURCE_GAME_TYPES,
  payload: { params },
});

export const saveResourceGameTypes = (data) => ({
  type: ResourceActionType.SAVE_RESOURCE_GAME_TYPES,
  payload: { data },
});

export const resetResourceGameTypes = () => ({
  type: ResourceActionType.RESET_RESOURCE_GAME_TYPES,
});

export const getResourceDeepLinkPromotions = (params) => ({
  type: ResourceActionType.GET_RESOURCE_DEEP_LINK_PROMOTIONS,
  payload: { params },
});

export const saveResourceDeepLinkPromotions = (data) => ({
  type: ResourceActionType.SAVE_RESOURCE_DEEP_LINK_PROMOTIONS,
  payload: { data },
});

export const resetResourceDeepLinkPromotions = () => ({
  type: ResourceActionType.RESET_RESOURCE_DEEP_LINK_PROMOTIONS,
});

export const getResourcePermissionGroups = (params, key = 'first') => ({
  type: ResourceActionType.GET_RESOURCE_PERMISSION_GROUPS,
  payload: { params, key },
});

export const saveResourcePermissionGroups = (data, key = 'first') => ({
  type: ResourceActionType.SAVE_RESOURCE_PERMISSION_GROUPS,
  payload: { data, key },
});

export const resetResourcePermissionGroups = () => ({
  type: ResourceActionType.RESET_RESOURCE_PERMISSION_GROUPS,
});

export const getResourceKycType = (params) => ({
  type: ResourceActionType.GET_RESOURCE_KYC_TYPE,
  payload: { params },
});

export const saveResourceKycType = (data) => ({
  type: ResourceActionType.SAVE_RESOURCE_KYC_TYPE,
  payload: { data },
});

export const resetResourceKycType = () => ({
  type: ResourceActionType.RESET_RESOURCE_KYC_TYPE,
});

export const getResourceDirectoryRules = (path, params, isLoading) => ({
  type: ResourceActionType.GET_RESOURCE_DIRECTORY_RULES,
  payload: { path, params, isLoading },
});

export const saveResourceDirectoryRules = (data) => ({
  type: ResourceActionType.SAVE_RESOURCE_DIRECTORY_RULES,
  payload: { data },
});

export const resetResourceDirectoryRules = () => ({
  type: ResourceActionType.RESET_RESOURCE_DIRECTORY_RULES,
});
