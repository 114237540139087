import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useCallback, useMemo, useState } from 'react';
import { AppConstants } from 'constants/index';
import { StyledIconDefault } from 'components/styledComponents/icon/Icon.styled';
import { StyledRangePicker } from 'components/styledComponents/datepicker/Datepicker.styled';
import IconSvg from 'components/common/icon/IconSvg';
import { useTimeZone } from 'hooks';

const AdminRangePicker = (props) => {
  const { t } = useTranslation();
  const { date } = useTimeZone();

  const [open, setOpen] = useState(false);

  const {
    now, setNow, name, form, className, onFocus: onFocusProp, onChange: onChangeProp, renderExtraFooter: renderExtraFooterProp, ...restProps
  } = props;

  const [showNow, setShowNow] = useState(false);

  const { allowEmpty, onOpenChange: onOpenChangeProp, onOk: onOkProp } = props;

  const { getFieldsValue, setFieldsValue, validateFields } = form;

  const handleNow = () => {
    setNow(true);
    const selector = `.custom-date .ant-picker-input input:not(#${name})`;
    const currentDate = document.querySelector(selector);
    setFieldsValue?.({ [name]: [undefined, currentDate?.value ? date(currentDate.value, AppConstants.format.dateTime) : undefined] });
    currentDate.focus();
    if (currentDate?.value) {
      validateFields([name]);
    }
  };

  const onOpenChange = useCallback(
    (visible) => {
      setOpen(visible);
      onOpenChangeProp?.(visible);
    },
    [onOpenChangeProp],
  );

  // after ok, if now and second date is selected close picker
  const onOk = useCallback(() => {
    if (name) {
      const { [name]: currentDate } = getFieldsValue?.();
      if (now && currentDate?.[1]) {
        setOpen(false);
      }
    }
    onOkProp?.();
  }, [getFieldsValue, now, name, onOkProp]);

  const conditionalProps = useMemo(
    () => setNow && {
      open,
      onOk,
      onOpenChange,
    },
    [onOk, onOpenChange, open, setNow],
  );

  const onFocus = (e) => {
    if (setNow) {
      setShowNow(e.target.id === name);
    }
    onFocusProp?.(e);
  };

  // after closing, delete now if required field is not selected
  const onBlur = (e) => {
    if (now && !allowEmpty?.[1] && !e.target.id && !e.target.value) {
      setNow?.(false);
    }
  };

  const onChange = (currentDate) => {
    if (setNow && (currentDate?.[0] || !currentDate)) {
      setNow(false);
    }
    onChangeProp?.(currentDate);
  };

  const renderExtraFooter = () => (showNow ? <div onClick={handleNow}>{t('now')}</div> : renderExtraFooterProp);

  return (
    <StyledRangePicker
      getPopupContainer={(trigger) => trigger.parentNode}
      midSize
      isSubmissionDate={+now}
      format={AppConstants.format.date}
      placeholder={[t(now ? 'submissionDate' : 'startDate'), t('endDate')]}
      suffixIcon={(
        <StyledIconDefault>
          <IconSvg icon="Calendar2Icon" />
        </StyledIconDefault>
      )}
      renderExtraFooter={renderExtraFooter}
      className={`${className} ${setNow ? 'custom-date' : ''}`}
      onFocus={onFocus}
      onChange={onChange}
      onBlur={onBlur}
      changeOnBlur
      {...restProps}
      {...conditionalProps}
    />
  );
};

AdminRangePicker.propTypes = {
  now: PropTypes.bool,
  form: PropTypes.object,
  setNow: PropTypes.func,
  name: PropTypes.string,
  allowEmpty: PropTypes.array,
  onOpenChange: PropTypes.func,
  onOk: PropTypes.func,
  className: PropTypes.string,
  onFocus: PropTypes.func,
  onChange: PropTypes.func,
  renderExtraFooter: PropTypes.func,
};

AdminRangePicker.defaultProps = {
  now: false,
  form: {},
  setNow: null,
  name: undefined,
  allowEmpty: undefined,
  onOpenChange: null,
  onOk: null,
  className: undefined,
  onFocus: undefined,
  onChange: undefined,
  renderExtraFooter: undefined,
};

export default AdminRangePicker;
