import styled from 'styled-components';
import { StyledTagsWrapper } from 'components/styledComponents/tag/Tag.styled';
import { StyledActions } from 'components/styledComponents/icon/Icon.styled';
import { media } from 'utils/styledHelpers';

const StyledBrandInner = styled.div`
  padding: 0.85rem 0 3.4rem;
  height: 100%;
  .ant-form-item {
    .ant-form-item-label {
      label {
        color: ${(props) => props.theme.colors.primaryText};
      }
    }
  }
`;
const StyledBrandInnerTop = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  margin-bottom: ${(props) => (props.isMargin ? 0 : '1.14rem')};
`;
const StyledBrandInnerTopRight = styled(StyledBrandInnerTop)`
  justify-content: flex-end;
  align-items: center;
  .domain-popover {
    .ant-popover-content > .ant-popover-arrow {
      display: block;
    }
  }
  .ant-btn {
    margin-left: 1.14rem;
    &.ant-popover-open {
      background-color: ${(props) => props.theme.colors.secondaryBorder};
    }
  }
`;

const StyledSettingsMainWrapper = styled(StyledBrandInner)`
  height: 100%;
  .ant-form {
    height: 100%;
  }
  .ant-form-item-label > label {
    color: ${(props) => props.theme.colors.primaryText};
  }
  .ant-form-item-control-input-content .ant-input-affix-wrapper input.ant-input {
    padding-right: 1.28rem;
  }
  position: relative;
`;
const StyledSettingsPlayerWrapper = styled.div`
  padding-top: 1rem;
  height: 100%;
  .ant-form {
    height: 100%;
  }
  .ant-form-item-label > label {
    color: ${(props) => props.theme.colors.primaryText};
  }
  .ant-form .ant-input {
    width: 25.5rem;
    max-width: 100%;
    height: 2.57rem;

    ${media.sm`
      width: 30rem;
    `}
  }
  .ant-input-number-input {
    color: ${(props) => props.theme.colors.primaryText};
  }
  .ant-form-vertical .ant-form-item-label > .ant-form-item-required:after {
    display: none !important;
  }
`;
const StyledSettingsWrapper = styled.div`
  position: relative;
  width: 25.3rem;
  max-width: 100%;
  .ant-form.ant-form-horizontal,
  .ant-tabs-content,
  .ant-tabs-content {
    height: 100%;
  }

  ${media.sm`
    width: 100%;
  `}
`;
const StyledCaptchaWrapper = styled(StyledBrandInner)`
  height: 100%;
  .ant-form {
    height: 100%;
  }
`;

const StyledTabInfo = styled.div`
  margin-bottom: 2.89rem;
`;

const StyledChangeStatusCheckbox = styled.div`
  margin-top: 1rem;
  .ant-checkbox-wrapper.ant-checkbox-group-item:nth-of-type(1) {
    order: 1;
  }
  .ant-checkbox-wrapper.ant-checkbox-group-item:nth-of-type(2) {
    order: 4;
  }
  .ant-checkbox-wrapper.ant-checkbox-group-item:nth-of-type(3) {
    order: 2;
  }
  .ant-checkbox-wrapper.ant-checkbox-group-item:nth-of-type(4) {
    order: 3;
  }
  .ant-checkbox-wrapper.ant-checkbox-group-item:last-child {
    margin-right: 8px;
  }
  .ant-checkbox-group {
    display: flex;
    flex-wrap: wrap;
    flex-grow: 1;
  }
  .ant-checkbox-wrapper {
    margin: 1.43rem 0 0;
    width: 45%;
  }
  .ant-checkbox + span {
    padding-left: 1.42rem;
  }
`;
const StyledSMTPWrapper = styled.div`
  .ant-form-item-label > label::after {
    left: 0;
  }

  margin-top: 0;
  display: flex;

  ${media.sm`
    flex-direction: column;
    align-items: center;
    max-height: unset;
  `}
`;
const StyledSMTPCols = styled.div`
  margin-right: 2rem;
  ${media.sm`
      margin-right: 0rem;
      padding: 0;
      width: 100%;
    `}
`;

const StyledSMSButton = styled.div`
  display: flex;
  justify-content: ${(props) => (props.isLeft ? 'flex-start' : 'flex-end')};
  > .ant-btn {
    padding: 0 1.29rem;
  }
`;
const StyledModalCheckbox = styled.div`
  display: flex;
  align-items: center;
  .ant-checkbox-wrapper {
    width: 2.14rem;
  }
  span {
    font-size: 1rem;
  }
  .ant-row.ant-form-item {
    position: relative;
    width: 100%;
  }
  .ant-col.ant-form-item-label {
    position: absolute;
    left: 2.14rem;
    font-weight: ${(props) => props.theme.typography.fontWeightMedium};
  }
`;

const StyledEmailWrapper = styled.div`
  .ant-table.ant-table-small .ant-table-tbody > tr > td {
    padding: 1.14rem;
  }
`;
const StyledStatusChange = styled.div`
  .ant-select-clear {
    display: none;
  }
`;
const StyledTimeZoneMargin = styled.div`
  margin-bottom: 1.71rem;
`;

const StyledInfoWrapper = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  margin-bottom: 1.14rem;
  width: 100%;
  > div:first-child {
    max-width: 44rem;
  }
  > div:last-child {
    margin-bottom: 0;
  }

  ${media.sm`
    flex-direction: column;
  `}
`;

const StyledDeleteWrapper = styled(StyledTagsWrapper)`
  margin: 0;
  cursor: initial;
  background: ${(props) => props.theme.colors.black}10;
  .ant-tag-close-icon {
    margin-left: 1.14rem;
    cursor: pointer;
  }
`;

const StyledConfirmInfoWrapper = styled(StyledActions)`
  text-align: left;
  margin-top: 1.42rem;
`;

export {
  StyledBrandInner,
  StyledBrandInnerTop,
  StyledBrandInnerTopRight,
  StyledSettingsMainWrapper,
  StyledStatusChange,
  StyledTimeZoneMargin,
  StyledEmailWrapper,
  StyledModalCheckbox,
  StyledSettingsWrapper,
  StyledTabInfo,
  StyledChangeStatusCheckbox,
  StyledSMSButton,
  StyledSMTPWrapper,
  StyledSMTPCols,
  StyledCaptchaWrapper,
  StyledSettingsPlayerWrapper,
  StyledInfoWrapper,
  StyledDeleteWrapper,
  StyledConfirmInfoWrapper,
};
