import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { selectStaticCollectionTimeZones, selectUserConfig } from 'selectors';
import { createOrEditUserConfig } from 'actions/user/userActionCreator';
import { settingKeys } from 'constants/configKeys';
import { TimeZones } from 'components/layout/header';
import { getTimeZones } from 'actions/staticCollection/staticCollectionActionCreator';

const mapDispatchToProps = (dispatch) => bindActionCreators(
  {
    createOrEditUserConfig,
    getTimeZones,
  },
  dispatch,
);

const mapStateToProps = ({ users, staticCollection }) => ({
  timeZones: selectStaticCollectionTimeZones(staticCollection),
  timeZone: selectUserConfig(users, settingKeys.timeZone),
});

export default connect(mapStateToProps, mapDispatchToProps)(TimeZones);
