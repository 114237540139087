import {
  all, call, put, takeLatest,
} from 'redux-saga/effects';
import {
  changeEndedStatus, changeLoadingState, setErrorMessage, setSuccessMessage,
} from 'actions/setting/settingActionCreator';
import NetworkService from 'services/networkService';
import { AppConstants } from 'constants/index';
import AgentSystemActionType from 'actions/agentSystem/agentSystemActionType';
import { saveAgentSystemHistory } from 'actions/agentSystem/agentSystemActionCreator';
import { getError, getMessage } from 'utils/helpers';
import { i18n } from 'services';

function* getAgentSystemHistory({ payload }) {
  try {
    const { params } = payload;
    const options = {
      params,
    };
    const { ReportingAdmin, AgentSystems, History } = AppConstants.api;
    yield put(changeLoadingState(true));
    const { data } = yield call(NetworkService.makeAPIGetRequest, [ReportingAdmin, AgentSystems, History], options);
    yield put(saveAgentSystemHistory(data));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* resendAgentSystemData({ payload }) {
  try {
    const {
      ReportingAdmin, AgentSystems, History, Resend,
    } = AppConstants.api;
    yield put(changeLoadingState(true));
    yield call(NetworkService.makeAPIPostRequest, [ReportingAdmin, AgentSystems, History, payload.id, Resend]);
    yield put(setSuccessMessage(getMessage(i18n.t('tool:package'), i18n.t('notification:slSent'))));
    yield put(changeLoadingState(false));
    yield put(changeEndedStatus(true));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

export default function* agentSystemSaga() {
  yield all([takeLatest(AgentSystemActionType.GET_AGENT_SYSTEM_HISTORY, getAgentSystemHistory), takeLatest(AgentSystemActionType.RESEND_AGENT_SYSTEM_DATA, resendAgentSystemData)]);
}
