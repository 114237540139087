const ReferralActionType = {
  GET_EXISTING_PLAYERS: 'GET_EXISTING_PLAYERS',
  SAVE_EXISTING_PLAYERS: 'SAVE_EXISTING_PLAYERS',
  RESET_EXISTING_PLAYERS: 'RESET_EXISTING_PLAYERS',

  CREATE_REFERRAL: 'CREATE_REFERRAL',

  GET_REFERRALS: 'GET_REFERRALS',
  SAVE_REFERRALS: 'SAVE_REFERRALS',
  RESET_REFERRALS: 'RESET_REFERRALS',

  GET_REFERRAL: 'GET_REFERRAL',
  SAVE_REFERRAL: 'SAVE_REFERRAL',
  RESET_REFERRAL: 'RESET_REFERRAL',

  EDIT_REFERRAL: 'EDIT_REFERRAL',

  DELETE_REFERRAL: 'DELETE_REFERRAL',
  ACTIVATE_REFERRAL: 'ACTIVATE_REFERRAL',
  FINISH_REFERRAL: 'FINISH_REFERRAL',

  GET_REFERRAL_STATISTICS: 'GET_REFERRAL_STATISTICS',
  SAVE_REFERRAL_ACCUMULATED: 'SAVE_REFERRAL_ACCUMULATED',
  SAVE_REFERRAL_TRANSFERRED: 'SAVE_REFERRAL_TRANSFERRED',
  SAVE_REFERRAL_FAILED: 'SAVE_REFERRAL_FAILED',
  EXPORT_REFERRAL_STATISTICS: 'EXPORT_REFERRAL_STATISTICS',

  RESET_REFERRAL_TRANSFERRED: 'RESET_REFERRAL_TRANSFERRED',
  RESET_REFERRAL_ACCUMULATED: 'RESET_REFERRAL_ACCUMULATED',
  RESET_REFERRAL_FAILED: 'RESET_REFERRAL_FAILED',

  TRANSFER_REFERRAL_COMMISSIONS: 'TRANSFER_REFERRAL_COMMISSIONS',
  DELETE_REFERRAL_COMMISSIONS: 'DELETE_REFERRAL_COMMISSIONS',

  GET_REFERRAL_REFEREES: 'GET_REFERRAL_REFEREES',
  SAVE_REFERRAL_REFEREES: 'SAVE_REFERRAL_REFEREES',
  RESET_REFERRAL_REFEREES: 'RESET_REFERRAL_REFEREES',

  GET_REFERRAL_PARTICIPANTS: 'GET_REFERRAL_PARTICIPANTS',
  SAVE_REFERRAL_PARTICIPANTS: 'SAVE_REFERRAL_PARTICIPANTS',
  RESET_REFERRAL_PARTICIPANTS: 'RESET_REFERRAL_PARTICIPANTS',
  DELETE_REFERRAL_PARTICIPANT: 'DELETE_REFERRAL_PARTICIPANT',

  GET_PLAYER_REFERRAL_REFEREES: 'GET_PLAYER_REFERRAL_REFEREES',
  SAVE_PLAYER_REFERRAL_REFEREES: 'SAVE_PLAYER_REFERRAL_REFEREES',
  RESET_PLAYER_REFERRAL_REFEREES: 'RESET_PLAYER_REFERRAL_REFEREES',

  GET_PLAYER_REFERRALS: 'GET_PLAYER_REFERRALS',
  SAVE_PLAYER_REFERRALS: 'SAVE_PLAYER_REFERRALS',
  RESET_PLAYER_REFERRALS: 'RESET_PLAYER_REFERRALS',

  GET_REFERRAL_ALL_PARTICIPANTS: 'GET_REFERRAL_ALL_PARTICIPANTS',
  SAVE_REFERRAL_ALL_PARTICIPANTS: 'SAVE_REFERRAL_ALL_PARTICIPANTS',
  RESET_REFERRAL_ALL_PARTICIPANTS: 'RESET_REFERRAL_ALL_PARTICIPANTS',

  EDIT_REFERRAL_PARTICIPANTS: 'EDIT_REFERRAL_PARTICIPANTS',

  GET_REFERRAL_COMMISSION_PERIODS: 'GET_REFERRAL_COMMISSION_PERIODS',

  SAVE_PLAYER_REFERRAL_PERIODS: 'SAVE_PLAYER_REFERRAL_PERIODS',
  RESET_PLAYER_REFERRAL_PERIODS: 'RESET_PLAYER_REFERRAL_PERIODS',

  SAVE_REFERRAL_ACCUMULATED_PERIODS: 'SAVE_REFERRAL_ACCUMULATED_PERIODS',
  RESET_REFERRAL_ACCUMULATED_PERIODS: 'RESET_REFERRAL_ACCUMULATED_PERIODS',

  SAVE_REFERRAL_TRANSFERRED_PERIODS: 'SAVE_REFERRAL_TRANSFERRED_PERIODS',
  RESET_REFERRAL_TRANSFERRED_PERIODS: 'RESET_REFERRAL_TRANSFERRED_PERIODS',

  SAVE_REFERRAL_FAILED_PERIODS: 'SAVE_REFERRAL_FAILED_PERIODS',
  RESET_REFERRAL_FAILED_PERIODS: 'RESET_REFERRAL_FAILED_PERIODS',
};

export default ReferralActionType;
