const SettingActionType = {
  RESET_STATE: 'RESET_STATE',
  CHANGE_MODE: 'CHANGE_MODE',
  CHANGE_LOADING_STATE: 'CHANGE_LOADING_STATE',

  SET_SUCCESS_MESSAGE: 'SET_SUCCESS_MESSAGE',
  SET_ERROR_MESSAGE: 'SET_ERROR_MESSAGE',
  RESET_MESSAGE: 'RESET_MESSAGE',

  SET_BREADCRUMB_PARAMS: 'SET_BREADCRUMB_PARAMS',
  RESET_BREADCRUMB_PARAMS: 'RESET_BREADCRUMB_PARAMS',

  CHANGE_ENDED_STATUS: 'CHANGE_ENDED_STATUS',

  CHANGE_GENERATE_REPORT_ENDED_STATUS: 'CHANGE_GENERATE_REPORT_ENDED_STATUS',

  SET_ACTIVE_MENU: 'SET_ACTIVE_MENU',
};

export default SettingActionType;
