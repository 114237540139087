import {
  put, all, call, takeLatest,
} from 'redux-saga/effects';
import { NetworkService, i18n } from 'services';
import { saveIpWhitelist } from 'actions/ipWhitelist/ipWhitelistActionCreator';
import {
  changeEndedStatus, changeLoadingState, setErrorMessage, setSuccessMessage,
} from 'actions/setting/settingActionCreator';
import IpWhitelistActionType from 'actions/ipWhitelist/ipWhitelistActionType';
import { AppConstants } from 'constants/index';
import { getError, getMessage } from 'utils/helpers';

function* getIpWhitelist({ payload }) {
  try {
    const { partnerId, params } = payload;
    const options = {
      params,
    };
    const { Admin, WhiteList } = AppConstants.api;
    yield put(changeLoadingState(true));
    const { data } = yield call(NetworkService.makeAPIGetRequest, [Admin, WhiteList, partnerId], options);
    yield put(changeLoadingState(false));
    yield put(saveIpWhitelist(data));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* deleteIpWhitelist({ payload }) {
  try {
    const { data } = payload;
    yield put(changeLoadingState(true));
    const { Admin, WhiteList } = AppConstants.api;
    yield call(NetworkService.makeAPIDeleteRequest, [Admin, WhiteList], {
      data,
    });
    yield put(changeEndedStatus(true));
    yield put(
      setSuccessMessage(
        getMessage(i18n.t(data.length > 1 ? 'userManagement:ipAddresses' : 'userManagement:ipAddress'), i18n.t(data.length > 1 ? 'notification:plDeleted' : 'notification:slDeleted'), ''),
      ),
    );
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* addIpWhitelist({ payload }) {
  try {
    const { partnerId, data } = payload;
    const { ip, range } = data;
    yield put(changeLoadingState(true));
    const { Admin, WhiteList } = AppConstants.api;
    const reqData = [ip, range].filter(Boolean).join('/');
    yield call(NetworkService.makeAPIPostRequest, [Admin, WhiteList, partnerId], {
      data: [reqData],
    });
    yield put(changeEndedStatus(true));
    yield put(setSuccessMessage(getMessage(i18n.t(range ? 'userManagement:ipAddressRange' : 'userManagement:ipAddress'), i18n.t('notification:slAdded'))));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

export default function* ipWhitelistSaga() {
  yield all([
    takeLatest(IpWhitelistActionType.GET_IP_WHITELIST, getIpWhitelist),
    takeLatest(IpWhitelistActionType.DELETE_IP_WHITELIST, deleteIpWhitelist),
    takeLatest(IpWhitelistActionType.ADD_IP_WHITELIST, addIpWhitelist),
  ]);
}
