const ReasonsActionType = {
  GET_REASONS: 'GET_REASONS',
  SAVE_REASONS: 'SAVE_REASONS',
  RESET_REASONS: 'RESET_REASONS',

  GET_REASON: 'GET_REASON',
  SAVE_REASON: 'SAVE_REASON',
  RESET_REASON: 'RESET_REASON',
  CREATE_REASON: 'CREATE_REASON',
  EDIT_REASON: 'EDIT_REASON',
  DELETE_REASON: 'DELETE_REASON',
  ENABLE_REASON: 'ENABLE_REASON',

  GET_REASONS_RESOURCES: 'GET_REASONS_RESOURCES',
  SAVE_REASONS_RESOURCES: 'SAVE_REASONS_RESOURCES',
  RESET_REASONS_RESOURCES: 'RESET_REASONS_RESOURCES',

  GET_REASONS_BY_RESOURCE: 'GET_REASONS_BY_RESOURCE',
  SAVE_REASONS_BY_RESOURCE: 'SAVE_REASONS_BY_RESOURCE',
  RESET_REASONS_BY_RESOURCE: 'RESET_REASONS_BY_RESOURCE',

  DELETE_RESOURCE_REASON: 'DELETE_RESOURCE_REASON',
  ENABLE_RESOURCE_REASON: 'ENABLE_RESOURCE_REASON',
};

export default ReasonsActionType;
