import ReleaseNoteActionType from 'actions/releaseNote/releaseNoteActionType';

export const getReleaseNotes = (params) => ({
  type: ReleaseNoteActionType.GET_RELEASE_NOTES,
  payload: { params },
});

export const saveReleaseNotes = (data) => ({
  type: ReleaseNoteActionType.SAVE_RELEASE_NOTES,
  payload: { data },
});

export const resetReleaseNotes = () => ({
  type: ReleaseNoteActionType.RESET_RELEASE_NOTES,
});

export const getReleaseNote = (id) => ({
  type: ReleaseNoteActionType.GET_RELEASE_NOTE,
  payload: { id },
});

export const saveReleaseNote = (data) => ({
  type: ReleaseNoteActionType.SAVE_RELEASE_NOTE,
  payload: { data },
});

export const resetReleaseNote = () => ({
  type: ReleaseNoteActionType.RESET_RELEASE_NOTE,
});

export const createReleaseNote = (data) => ({
  type: ReleaseNoteActionType.CREATE_RELEASE_NOTE,
  payload: { data },
});

export const deleteReleaseNote = (id, data) => ({
  type: ReleaseNoteActionType.DELETE_RELEASE_NOTE,
  payload: { id, data },
});

export const editReleaseNote = (id, data) => ({
  type: ReleaseNoteActionType.EDIT_RELEASE_NOTE,
  payload: {
    id,
    data,
  },
});

export const changeReleaseNoteStatus = (id, data) => ({
  type: ReleaseNoteActionType.CHANGE_RELEASE_NOTE_STATUS,
  payload: {
    id,
    data,
  },
});

export const markAsSeenReleaseNote = (id) => ({
  type: ReleaseNoteActionType.MARK_AS_SEEN_RELEASE_NOTE,
  payload: {
    id,
  },
});

export const getLatestReleaseNote = () => ({
  type: ReleaseNoteActionType.GET_LATEST_RELEASE_NOTE,
});

export const saveLatestReleaseNote = (data) => ({
  type: ReleaseNoteActionType.SAVE_LATEST_RELEASE_NOTE,
  payload: { data },
});

export const resetLatestReleaseNote = () => ({
  type: ReleaseNoteActionType.RESET_LATEST_RELEASE_NOTE,
});
