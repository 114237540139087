import TagActionType from 'actions/tag/tagActionType';

const initialState = {
  verticalTagCategories: [],
  verticalTagCategory: {},
  horizontalTagCategories: {},
  horizontalTagCategory: {},
  horizontalTags: {},
  verticalTags: {},
  horizontalTag: {},
  verticalTag: {},
  horizontalTagsPlayers: {},
  verticalTagsPlayers: {},
  playerHorizontalTags: {},
  playerVerticalTags: {},
  playerTopTags: [],
  playerTopTagsChanged: false,
  playerTagLogs: {},
  tagLogs: {},
};

const tagReducer = (state = initialState, { type, payload }) => {
  switch (type) {
  case TagActionType.SAVE_VERTICAL_TAG_CATEGORIES:
    return {
      ...state,
      verticalTagCategories: payload.data,
    };
  case TagActionType.RESET_VERTICAL_TAG_CATEGORIES:
    return {
      ...state,
      verticalTagCategories: [],
    };
  case TagActionType.SAVE_VERTICAL_TAG_CATEGORY:
    return {
      ...state,
      verticalTagCategory: payload.data,
    };
  case TagActionType.RESET_VERTICAL_TAG_CATEGORY:
    return {
      ...state,
      verticalTagCategory: {},
    };
  case TagActionType.SAVE_HORIZONTAL_TAG_CATEGORIES:
    return {
      ...state,
      horizontalTagCategories: payload.data,
    };
  case TagActionType.RESET_HORIZONTAL_TAG_CATEGORIES:
    return {
      ...state,
      horizontalTagCategories: {},
    };
  case TagActionType.SAVE_HORIZONTAL_TAG_CATEGORY:
    return {
      ...state,
      horizontalTagCategory: payload.data,
    };
  case TagActionType.RESET_HORIZONTAL_TAG_CATEGORY:
    return {
      ...state,
      horizontalTagCategory: {},
    };
  case TagActionType.SAVE_HORIZONTAL_TAGS:
    return {
      ...state,
      horizontalTags: payload.data,
    };
  case TagActionType.RESET_HORIZONTAL_TAGS:
    return {
      ...state,
      horizontalTags: {},
    };
  case TagActionType.SAVE_VERTICAL_TAGS:
    return {
      ...state,
      verticalTags: payload.data,
    };
  case TagActionType.RESET_VERTICAL_TAGS:
    return {
      ...state,
      verticalTags: {},
    };
  case TagActionType.SAVE_VERTICAL_TAG:
    return {
      ...state,
      verticalTag: payload.data,
    };
  case TagActionType.RESET_VERTICAL_TAG:
    return {
      ...state,
      verticalTag: {},
    };
  case TagActionType.SAVE_HORIZONTAL_TAG:
    return {
      ...state,
      horizontalTag: payload.data,
    };
  case TagActionType.RESET_HORIZONTAL_TAG:
    return {
      ...state,
      horizontalTag: {},
    };
  case TagActionType.SAVE_HORIZONTAL_TAGS_PLAYERS:
    return {
      ...state,
      horizontalTagsPlayers: payload.data,
    };
  case TagActionType.RESET_HORIZONTAL_TAGS_PLAYERS:
    return {
      ...state,
      horizontalTagsPlayers: {},
    };
  case TagActionType.SAVE_VERTICAL_TAGS_PLAYERS:
    return {
      ...state,
      verticalTagsPlayers: payload.data,
    };
  case TagActionType.RESET_VERTICAL_TAGS_PLAYERS:
    return {
      ...state,
      verticalTagsPlayers: {},
    };
  case TagActionType.SAVE_PLAYER_VERTICAL_TAGS:
    return {
      ...state,
      playerVerticalTags: payload.data,
    };
  case TagActionType.RESET_PLAYER_VERTICAL_TAGS:
    return {
      ...state,
      playerVerticalTags: {},
    };
  case TagActionType.SAVE_PLAYER_HORIZONTAL_TAGS:
    return {
      ...state,
      playerHorizontalTags: payload.data,
    };
  case TagActionType.RESET_PLAYER_HORIZONTAL_TAGS:
    return {
      ...state,
      playerHorizontalTags: {},
    };
  case TagActionType.SAVE_PLAYER_TOP_TAGS:
    return {
      ...state,
      playerTopTags: payload.data,
    };
  case TagActionType.RESET_PLAYER_TOP_TAGS:
    return {
      ...state,
      playerTopTags: [],
    };
  case TagActionType.CHANGE_PLAYER_TOP_TAGS:
    return {
      ...state,
      playerTopTagsChanged: payload.status,
    };
  case TagActionType.SAVE_PLAYER_TAG_LOGS:
    return {
      ...state,
      playerTagLogs: payload.data,
    };
  case TagActionType.RESET_PLAYER_TAG_LOGS:
    return {
      ...state,
      playerTagLogs: {},
    };
  case TagActionType.SAVE_TAG_LOGS:
    return {
      ...state,
      tagLogs: payload.data,
    };
  case TagActionType.RESET_TAG_LOGS:
    return {
      ...state,
      tagLogs: {},
    };
  default:
    return state;
  }
};

export default tagReducer;
