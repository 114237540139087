import { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Tabs, Select } from 'antd';
import { useTranslation } from 'react-i18next';
import IconSvg from 'components/common/icon/IconSvg';
import { StyledTabItem, StyledTabItemBold } from 'components/styledComponents/tabs/Tabs.styled';
import { StyledIconDefault } from 'components/styledComponents/icon/Icon.styled';
import { StyledUserManagementSelectWrapper } from 'components/styledComponents/select/Select.styled';

const { Option } = Select;

const VerticalTabs = (props) => {
  const {
    position, tabs, tabChange, activeKey, initial, setInitial, destroyInactiveTabPane,
  } = props;

  const { t } = useTranslation();

  const [selectedIds, setSelectedIds] = useState([]);
  const [data, setData] = useState(tabs);
  const [key, setKey] = useState();

  useEffect(() => {
    if (activeKey) {
      setKey(activeKey);
    }
  }, [activeKey]);

  const handleTabChange = useCallback(
    (actKey) => {
      setKey(actKey);
      if (tabChange) {
        tabChange(actKey);
      }
    },
    [tabChange],
  );

  // if we start to select keys, set 0 item as active key. If we start to clear selectedIds set 0 item as active
  useEffect(() => {
    if (selectedIds.length === 1) {
      handleTabChange(data[0].title);
    } else if (!selectedIds.length && data.length < tabs.length) {
      handleTabChange(tabs[0].title);
    }
  }, [data, handleTabChange, selectedIds.length, tabChange, tabs]);

  useEffect(() => {
    if (initial) {
      setSelectedIds([]);
      handleTabChange(tabs[0].title);
      setInitial(false);
    }
  }, [handleTabChange, initial, setInitial, tabs]);

  useEffect(() => {
    let tempData = tabs;
    if (selectedIds.length) {
      tempData = tabs.filter((el) => selectedIds.includes(el.title)).sort((a, b) => selectedIds.indexOf(a.title) - selectedIds.indexOf(b.title));
    }
    setData(tempData);
  }, [selectedIds, tabs]);

  const handleChange = (value) => {
    setSelectedIds(value);
  };

  const tabsOptions = () => tabs.map((el) => (
    <Option key={el.title} value={el.title}>
      {el.title}
    </Option>
  ));

  const handleDeselect = (value) => {
    if (key === value) {
      const firstAvailable = data.find((el) => el.title !== value);
      if (firstAvailable) {
        handleTabChange(firstAvailable.title);
      }
    }
  };

  const tabBarExtraContent = {
    [position]: (
      <StyledUserManagementSelectWrapper
        getPopupContainer={(trigger) => trigger.parentNode}
        allowClear
        showSearch
        mode="multiple"
        popupClassName="multi-select"
        placeholder={t('all')}
        optionFilterProp="children"
        onChange={handleChange}
        onDeselect={handleDeselect}
        value={selectedIds}
        maxTagCount={1}
        suffixIcon={(
          <StyledIconDefault>
            <IconSvg icon="DropdownIcon" />
          </StyledIconDefault>
        )}
        filterOption={(input, option) => option.children.toLowerCase().startsWith(input.toLowerCase())}
      >
        {tabsOptions()}
      </StyledUserManagementSelectWrapper>
    ),
  };

  const renderTabBar = (passProps, DefaultTabBar) => <DefaultTabBar {...passProps} />;
  const tabItems = data.map(
    (pane) => !pane.hide && {
      key: pane.title,
      label: (
        <StyledTabItem paneIcon={pane.icon}>
          <StyledTabItemBold isAssigned={pane.isAssigned ? 1 : 0} title={pane.title}>
            {pane.title}
          </StyledTabItemBold>
        </StyledTabItem>
      ),
      children: pane.content,
    },
  );

  return (
    <Tabs
      tabBarExtraContent={tabs.length ? tabBarExtraContent : null}
      onChange={handleTabChange}
      tabPosition={position}
      renderTabBar={renderTabBar}
      defaultActiveKey={data[0] ? data[0].title : undefined}
      activeKey={key}
      destroyInactiveTabPane={destroyInactiveTabPane}
      items={tabItems}
    />
  );
};

VerticalTabs.propTypes = {
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      content: PropTypes.any.isRequired,
    }),
  ).isRequired,
  tabChange: PropTypes.func,
  position: PropTypes.string,
  activeKey: PropTypes.string,
  initial: PropTypes.bool,
  setInitial: PropTypes.func,
  destroyInactiveTabPane: PropTypes.bool,
};

VerticalTabs.defaultProps = {
  tabChange: null,
  position: 'left',
  activeKey: undefined,
  initial: false,
  setInitial: () => {},
  destroyInactiveTabPane: false,
};

export default VerticalTabs;
