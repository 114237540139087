import PropTypes from 'prop-types';
import { Form, Grid, Input } from 'antd';
import { StyledAuthMainWrapper, StyledTitleForm, StyledForgetP } from 'components/auth/Login.styled';
import { StyledButtonWrapper, StyledButtonLogin, StyledButtonBlueUnbordered } from 'components/styledComponents/button/Button.styled';
import { NavigationService } from 'services';
import { FormInputWrapper } from 'components/styledComponents/input/Input.styled';
import { FormItemErrorMessage } from 'components/common';
import { AppConstants } from 'constants/index';
import { StyledFormMainWrapper } from 'components/styledComponents/form/Form.styled';
import { useEffect } from 'react';

function Forgot(props) {
  const {
    send, title, text, isPassword, endedStatus, changeEndedStatus,
  } = props;

  const [form] = Form.useForm();
  const { getFieldValue, validateFields } = form;

  const { useBreakpoint } = Grid;

  const screens = useBreakpoint();

  const handleSubmit = (event) => {
    event.preventDefault();
    validateFields().then((values) => {
      send(values);
    });
  };

  useEffect(() => {
    if (isPassword && endedStatus) {
      changeEndedStatus?.(false);
      const email = getFieldValue('email');
      NavigationService(`/emailSent?email=${email}`);
    }
  }, [changeEndedStatus, endedStatus, getFieldValue, isPassword]);

  return (
    <StyledAuthMainWrapper md={screens.md ? 1 : 0} xs={screens.xs ? 1 : 0}>
      <StyledFormMainWrapper layout="vertical" form={form}>
        <StyledTitleForm>{title}</StyledTitleForm>
        <FormInputWrapper>
          <Form.Item
            label="Email"
            name="email"
            rules={[
              {
                required: true,
                message: <FormItemErrorMessage errorMassage="Email cannot be empty!" />,
              },
              {
                pattern: AppConstants.regex.email,
                message: <FormItemErrorMessage errorMassage="Wrong email address, please try again!" />,
              },
            ]}
          >
            <Input placeholder="Email" allowClear />
          </Form.Item>
        </FormInputWrapper>
        <StyledForgetP>{text}</StyledForgetP>
        <StyledButtonWrapper>
          <StyledButtonBlueUnbordered
            type="primary"
            onClick={() => {
              NavigationService('/login');
            }}
          >
            Back
          </StyledButtonBlueUnbordered>
          <StyledButtonLogin type="primary" htmlType="submit" onClick={handleSubmit}>
            Send
          </StyledButtonLogin>
        </StyledButtonWrapper>
      </StyledFormMainWrapper>
    </StyledAuthMainWrapper>
  );
}

Forgot.propTypes = {
  send: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  isPassword: PropTypes.bool,
  endedStatus: PropTypes.bool,
  changeEndedStatus: PropTypes.func,
};

Forgot.defaultProps = {
  isPassword: false,
  endedStatus: false,
  changeEndedStatus: null,
};

export default Forgot;
