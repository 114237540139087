const BrandActionType = {
  GET_BRAND: 'GET_BRAND',
  GET_BRAND_REQUEST: 'GET_BRAND_REQUEST',
  GET_BRAND_SUCCESS: 'GET_BRAND_SUCCESS',
  RESET_BRAND: 'RESET_BRAND',

  EDIT_BRAND: 'EDIT_BRAND',

  GET_BRANDS: 'GET_BRANDS',
  GET_BRANDS_REQUEST: 'GET_BRANDS_REQUEST',
  GET_BRANDS_SUCCESS: 'GET_BRANDS_SUCCESS',

  ADD_BRAND: 'ADD_BRAND',

  ACTIVATE_BRAND: 'ACTIVATE_BRAND',
  ACTIVATE_BRAND_FAILURE: 'ACTIVATE_BRAND_FAILURE',

  SET_CURRENT_BRAND_ID: 'SET_CURRENT_BRAND_ID',

  GET_BRAND_MARKETS: 'GET_BRAND_MARKETS',
  GET_BRAND_MARKETS_SUCCESS: 'GET_BRAND_MARKETS_SUCCESS',

  CHANGE_BRAND_LOADING_STATE: 'CHANGE_BRAND_LOADING_STATE',

  GET_IS_BRAND_EXIST: 'GET_IS_BRAND_EXIST',
  GET_IS_BRAND_EXIST_SUCCESS: 'GET_IS_BRAND_EXIST_SUCCESS',
  RESET_IS_BRAND_EXIST: 'RESET_IS_BRAND_EXIST',

  GET_BRAND_TEMPLATES: 'GET_BRAND_TEMPLATES',
  GET_BRAND_TEMPLATES_SUCCESS: 'GET_BRAND_TEMPLATES_SUCCESS',
  RESET_BRAND_TEMPLATES: 'RESET_BRAND_TEMPLATES',

  EDIT_BRAND_TIME_ZONE: 'EDIT_BRAND_TIME_ZONE',

  CHANGE_BRAND_CALL_ENDED_STATUS: 'CHANGE_BRAND_CALL_ENDED_STATUS',
};

export default BrandActionType;
