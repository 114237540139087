export default {
  en: {
    registrationPlatform: 'Registration Platform',
    regIp: 'Reg. IP',
    closureDate: 'Closure Date',
    closedBy: 'Closed By',
    closureIp: 'Closure IP',
    branded: 'Branded',
    aggregationCriteria: 'Aggregation Criteria',
    realGGR: 'Real GGR',
    bonusGGR: 'Bonus GGR',
    playersCount: 'Players Count',
    successDepositAmount: 'Success Deposit Amount',
    successDepositCount: 'Success Deposit Count',
    canceledDepositAmount: 'Canceled Deposit Amount',
    failedDepositAmount: 'Failed Deposit Amount',
    failedDepositCount: 'Failed Deposit Count',
    canceledDepositCount: 'Canceled Deposit Count',
    successWithdrawalAmount: 'Success Withdrawal Amount',
    successWithdrawalCount: 'Success Withdrawal Count',
    canceledWithdrawalAmount: 'Canceled Withdrawal Amount',
    canceledWithdrawalCount: 'Canceled Withdrawal Count',
    failedWithdrawalAmount: 'Failed Withdrawal Amount',
    failedWithdrawalCount: 'Failed Withdrawal Count',
    hold: 'Hold',
    playerCount: 'Player Count',
    adjustmentId: 'Adjustment ID',
    currentConfig: 'Current Config',
    actualConfig: 'Actual Config',
    extTransactionId: 'Ext. Transaction ID',
    gameCategory: 'Game Category',
    countOfBets: 'Count Of Bets',
    totalBetAmount: 'Total Bet Amount',
    countOfWins: 'Count Of Wins',
    totalWinAmount: 'Total Win Amount',
    countOfDeposits: 'Lifetime Count Of Deposits',
    totalDepositAmount: 'Total Deposit Amount',
    countOfWithdrawals: 'Lifetime Count Of Withdrawals',
    totalWithdrawalAmount: 'Total Withdrawal Amount',
    countOfOtherTransactions: 'Count Of Other Transactions',
    totalAmountOfOtherTransactions: 'Total Amount Of Other Transactions',
    totalsAmounts: 'Totals Amounts',
    totalsCounts: 'Totals Counts',
    largeTransactionsByBrand: 'Large Transactions By Brand',
    largeTransactionsByPlayer: 'Large Transactions By Player',
    negativeBalance: 'Negative Balance',
    betReportByCurrency: 'Bet Report By Currency',
    financialReportByPlayer: 'Financial Report By Player',
    financialReportByCurrency: 'Financial Report By Currency',
    closedAccounts: 'Closed Accounts',
    totalDepositsCount: 'Total Deposits Count',
    totalWithdrawalsCount: 'Total Withdrawals Count',
    totalSportBets: 'Total Sport Bets',
    totalSportBetsCount: 'Total Sport Bets Count',
    totalSportWins: 'Total Sport Wins',
    totalSportWinsCount: 'Total Sport Wins Count',
    totalCasinoBets: 'Total Casino Bets',
    totalCasinoBetsCount: 'Total Casino Bets Count',
    totalCasinoWins: 'Total Casino Wins',
    totalCasinoWinsCount: 'Total Casino Wins Count',
    viewLogs: 'View Logs',
    realCalculatedBetAmount: 'Real Calculated Bet Amount',
    realCalculatedWinAmount: 'Real Calculated Win Amount',
    realCalculatedBetsCount: 'Real Calculated Bets Count',
    bonusCalculatedBetAmount: 'Bonus Calculated Bet Amount',
    bonusCalculatedWinAmount: 'Bonus Calculated Win Amount',
    bonusCalculatedBetsCount: 'Bonus Calculated Bets Count',
    realCancelledBetAmount: 'Real Cancelled Bet Amount',
    realCancelledBetCount: 'Real Cancelled Bet Count',
    realCancelledWinAmount: 'Real Cancelled Win Amount',
    realCancelledWinCount: 'Real Cancelled Win Count',
    realCalculatedWinCount: 'Real Calculated Win Count',
    bonusCancelledBetAmount: 'Bonus Cancelled Bet Amount',
    bonusCancelledBetCount: 'Bonus Cancelled Bet Count',
    bonusCancelledWinAmount: 'Bonus Cancelled Win Amount',
    bonusCancelledWinCount: 'Bonus Cancelled Win Count',
    bonusCalculatedWinCount: 'Bonus Calculated Win Count',
    bonusWinCount: 'Bonus Win Count',
    bonusBetMinusWin: 'Bonus Bet-Win',
    realBetMinusWin: 'Real Bet-Win',
    mixed: 'Mixed',
    calculated: 'Calculated',
    mixedInfo: 'The report shows the aggregated information on bets created in the selected period including all open and calculated bets.',
    calculatedInfo:
      'The report shows the aggregated information on bets calculated in the selected period including only calculated bets. Note, that calculation means that a bet received its result in the selected period.',
    depositTaxes: 'Deposits Tax Amount',
    withdrawalTaxes: 'Withdrawals Tax Amount',
    netDepositTotal: 'Net of Tax Total Deposits Amount',
    netWithdrawalTotal: 'Net of Tax Total Withdrawals Amount',
    netDepositSuccess: 'Net of Tax Success Deposit Amount',
    netWithdrawalSuccess: 'Net of Tax Success Withdrawal Amount',
    usedBalance: 'Used Balance',
    unusedBalance: 'Unused Balance',
    rolloverBalance: 'Rollover Balance',
    casinoBonusBalance: 'Casino Bonus Balance',
    debtBalance: 'Debt Balance',
    closedAccount: 'Closed Account',
    betShareAmountPercent: 'Bet Share (Amount) %',
    uniquePlayers: 'Unique Players',
    financialReportByProvider: 'Financial Report By Provider',
    cancelledBetCount: 'Cancelled Bet Count',
    bonusBetCount: 'Bonus Bet Count',
    sportBonusBalance: 'Sport Bonus Balance',
    totalSportBonusAmount: 'Total Sport Bonus Amount',
    sportBonusCount: 'Sport Bonus Count',
    totalSportBonusRedeemAmount: 'Total Sport Bonus Redeem Amount',
    totalCasinoBonusAmount: 'Total Casino Bonus Amount',
    casinoBonusCount: 'Casino Bonus Count',
    totalCasinoBonusRedeemAmount: 'Total Casino Bonus Redeem Amount',
    totalFreeSpinAmount: 'Total Free Spin Amount',
    freeSpinCount: 'Free Spin Count',
    totalFreeAmount: 'Total Free Amount Value',
    freeAmountCount: 'Free Amount Count',
    totalSpecialBonusAmount: 'Total Special Bonus Amount',
    specialBonusCount: 'Special Bonus Count',
    bonusTotalsByPlayer: 'Bonus Totals by Player',
    bonusTotalsByType: 'Bonus Totals by Type',
    totalRedeemedAmount: 'Total Redeemed Amount',
    bonusTotalsByStatus: 'Bonus Totals by Status',
    bonusCount: 'Bonus Count',
    issuedDate: 'Issued Date',
    lastDepositDate: 'Last Deposit Date',
    lastWithdrawalDate: 'Last Withdrawal Date',
    realBalanceLeftoverAmount: 'Real Balance Leftover Amount',
    bonusBalanceLeftoverAmount: 'Bonus Balance Leftover Amount',
    lastTransactionDate: 'Last Transaction Date',
    inactivityDate: 'Inactivity Date',
    transferredToPlayersInfo: 'The selected players will be notified that money was transferred to them.',
    transferredToGovernmentInfo: 'The selected players will be notified that money was transferred to the government.',
    transferredTo: 'Transferred To',
    transferredDate: 'Transferred Date',
    transferredBy: 'Transferred By',
    transferredToPlayers: 'Transferred To Players',
    transferredToGovernment: 'Transferred To Government',
    positive: 'Positive',
    negative: 'Negative',
    topPlayersByGgr: 'Top Players By GGR',
    topPlayersByDeposits: 'Top Players By Deposits',
    topPlayersByWithdrawals: 'Top Players By Withdrawals',
    topPlayersCurrencyInfo: 'All the calculations are done and converted based on the system conversions {{currency}} default currency.',
    bonusTotalsByProvider: 'Bonus Totals by Provider',
    providedDate: 'Provided Date',
    deviceToken: 'Device Token',
    applicationVersion: 'Application Version',
    deviceModel: 'Device Model',
    sessionStartDate: 'Session Start Date',
    originalCurrency: 'Original Currency',
    convertTo: 'Convert To',
  },
  es: {
    registrationPlatform: 'Plataforma de registro',
    regIp: 'IP de reg.',
    closureDate: 'Fecha de cierre',
    closedBy: 'Cerrada por',
    closureIp: 'IP de cierre',
    branded: 'Catalogado',
    byCreationDate: 'Por fecha de creación',
    byCalculationDate: 'Por fecha de cálculo',
    aggregationCriteria: 'Criterios de acumulación',
    realBetAmount: 'Importe de apuesta real',
    realWinAmount: 'Importe de ganancia real',
    realGGR: 'GGR real',
    realBetsCount: 'Recuento de apuestas reales',
    bonusGGR: 'GGR del bono',
    bonusBetsCount: 'Recuento de las apuestas de bono',
    playersCount: 'Recuento de los jugadores',
    successDepositAmount: 'Importe del depósito con éxito',
    successDepositCount: 'Recuento del depósito con éxito',
    canceledDepositAmount: 'Importe del depósito candelado',
    failedDepositAmount: 'Importe del depósito fallido',
    failedDepositCount: 'Recuento del depósito fallido',
    canceledDepositCount: 'Recuento del depósito candelado',
    successWithdrawalAmount: 'Importe del retiro con éxito',
    successWithdrawalCount: 'Recuento del retiro con éxito',
    canceledWithdrawalAmount: 'Importe retirable cancelado',
    canceledWithdrawalCount: 'Recuento retirable cancelado',
    failedWithdrawalAmount: 'Importe retirable fallido',
    failedWithdrawalCount: 'Recuento retirable fallido',
    hold: 'Conservar',
    playerCount: 'Recuento del jugador',
    adjustmentId: 'ID del ajuste',
    currentConfig: 'Config. actual',
    actualConfig: 'Config. vigente',
    extTransactionId: 'ID de la transacción ext.',
    gameCategory: 'Categoría del juego',
    countOfBets: 'Recuento de apuestas',
    totalBetAmount: 'Importe total de la apuesta',
    countOfWins: 'Recuento de ganancias',
    totalWinAmount: 'Importe total de la ganancia',
    countOfDeposits: 'Recuento de depósitos de por vida',
    totalDepositAmount: 'Recuento total del depósito',
    countOfWithdrawals: 'Recuento de retiros de por vida',
    totalWithdrawalAmount: 'Importe total de retiros',
    countOfOtherTransactions: 'Recuento de otras transacciones',
    totalAmountOfOtherTransactions: 'Importe total de otras transacciones',
    totalsAmounts: 'Importes totales',
    totalsCounts: 'Recuentos totales',
    largeTransactionsByBrand: 'Grandes transacciones por marca',
    largeTransactionsByPlayer: 'Grandes transacciones por jugador',
    negativeBalance: 'Saldo negativo',
    betReportByCurrency: 'Informe de apuestas por moneda',
    financialReportByPlayer: 'Informe financiero por jugador',
    financialReportByCurrency: 'Informe financiero por moneda',
    closedAccounts: 'Cuentas cerradas',
    totalDepositsCount: 'Recuento total de depósitos',
    totalWithdrawalsCount: 'Recuento total de retiros',
    totalSportBets: 'Apuestas deportivas totales',
    totalSportBetsCount: 'Recuento total de apuestas deportivas',
    totalSportWins: 'Ganancias deportivas totales',
    totalSportWinsCount: 'Recuento total de ganancias deportivas',
    totalCasinoBets: 'Apuestas de casino totales',
    totalCasinoBetsCount: 'Recuento total de apuestas de casino',
    totalCasinoWins: 'Ganancias de casino totales',
    totalCasinoWinsCount: 'Recuento total de ganancias de casino',
    viewLogs: 'Ver registros',
    realCalculatedBetAmount: 'Importe real calculado de la apuesta',
    realCalculatedWinAmount: 'Cantidad de ganancia real calculada',
    realCalculatedBetsCount: 'Recuento de apuestas reales calculadas',
    bonusCalculatedBetAmount: 'Importe de la apuesta calculado por el bono',
    bonusCalculatedWinAmount: 'Cantidad de ganancia calculada del bono',
    bonusCalculatedBetsCount: 'Recuento de apuestas calculadas con bonos',
    realCancelledBetAmount: 'Importe real de la apuesta cancelada',
    realCancelledBetCount: 'Recuento de apuestas reales canceladas',
    realCancelledWinAmount: 'Importe real de las ganancias canceladas',
    realCancelledWinCount: 'Recuento de victorias reales anuladas',
    realCalculatedWinCount: 'Recuento de victorias real calculado',
    bonusCancelledBetAmount: 'Cantidad de la apuesta cancelada por el bono',
    bonusCancelledBetCount: 'Recuento de apuestas canceladas',
    bonusCancelledWinAmount: 'Bono cancelado Importe de la ganancia',
    bonusCancelledWinCount: 'Recuento de victorias anuladas del bono',
    bonusCalculatedWinCount: 'Recuento de victorias calculado con bonos',
    bonusWinCount: 'Recuento de ganancias de bonificación',
    bonusBetMinusWin: 'Bono de apuesta-ganancia',
    realBetMinusWin: 'Apuesta real-gana',
    mixed: 'Mixto',
    calculated: 'Calculado',
    mixedInfo: 'El informe muestra la información agregada sobre las apuestas creadas en el periodo seleccionado, incluyendo todas las apuestas abiertas y calculadas.',
    calculatedInfo:
      'El informe muestra la información agregada de las apuestas calculadas en el periodo seleccionado, incluyendo sólo las apuestas calculadas. Tenga en cuenta que el cálculo significa que una apuesta recibió su resultado en el periodo seleccionado.',
    netDepositTotal: 'Importe Total del Deposito del Impuesto en Neto',
    netWithdrawalTotal: 'Importe del Impuesto Total de la Retirada en Neto',
    depositTaxes: 'Importe del Impuesto del Deposito',
    withdrawalTaxes: 'Importe del Impuesto de Retiradas',
    netDepositSuccess: 'Importe de Depósito Exitoso Neto de Impuestos',
    netWithdrawalSuccess: 'Importe de Retiro exitoso neto de impuestos',
    usedBalance: 'Saldo Utilizado',
    unusedBalance: 'Saldo no Utilizado',
    rolloverBalance: 'Saldo Prorrogado',
    casinoBonusBalance: 'Saldo de Bonos de Casino',
    debtBalance: 'Saldo Deudor',
    createdDate: 'Fecha de creación',
    closedAccount: 'Cuenta Cerrada',
    betShareAmountPercent: 'Participación en la Apuesta (Importe) %',
    uniquePlayers: 'Jugadores únicos',
    financialReportByProvider: 'Informe Financiero por Proveedor',
    cancelledBetCount: 'Recuento de Apuestas Anuladas',
    bonusBetCount: 'Recuento de Apuestas Bonificadas',
    sportBonusBalance: 'Saldo de Bonificación Deportiva ',
    totalSportBonusAmount: 'Importe Total de la Bonificación por Deporte',
    sportBonusCount: 'Recuento de Bonificaciones Deportivas',
    totalSportBonusRedeemAmount: 'Importe Total del Canje de la Bonificación por Deporte',
    totalCasinoBonusAmount: 'Importe Total de la Bonificación del Casino',
    casinoBonusCount: 'Recuento de Bonificaciones de Casino',
    totalCasinoBonusRedeemAmount: 'Importe Total de Bonos de Casino Canjeados',
    totalFreeSpinAmount: 'Importe Total de Free Spins',
    totalSpecialBonusAmount: 'Cantidad Total de Bonos Especiales',
    specialBonusCount: 'Cantidad de Bonos Especiales',
    bonusTotalsByPlayer: 'Totales de Bonos por Jugador',
    bonusTotalsByType: 'Totales de Bonos por Tipo',
    totalRedeemedAmount: 'Cantidad Total Redimida',
    bonusTotalsByStatus: 'Totales de Bonos por Estado',
    bonusCount: 'Cantidad de Bonos',
    issuedDate: 'Fecha de Emisión',
    lastDepositDate: 'Última Fecha de Depósito',
    lastWithdrawalDate: 'Última Fecha de Retiro',
    realBalanceLeftoverAmount: 'Saldo Real Restante',
    bonusBalanceLeftoverAmount: 'Saldo de Bonos Restante',
    lastTransactionDate: 'Última Fecha de Transacción',
    inactivityDate: 'Fecha de Inactividad',
    transferredToPlayersInfo: 'Los jugadores seleccionados serán notificados de que se les transfirió dinero.',
    transferredToGovernmentInfo: 'Los jugadores seleccionados serán notificados de que se transfirió dinero al gobierno',
    transferredTo: 'Transferido A',
    transferredDate: 'Fecha de Transferencia',
    transferredBy: 'Transferido Por',
    transferredToPlayers: 'Transferido a Jugadores',
    transferredToGovernment: 'Transferido al Gobierno',
    positive: 'Positivo',
    negative: 'Negativo',
    topPlayersByGgr: 'Mejores Jugadores por Ingreso Bruto de Juego (GGR)',
    topPlayersByDeposits: 'Mejores Jugadores por Depósitos',
    topPlayersByWithdrawals: 'Mejores Jugadores por Retiros',
    topPlayersCurrencyInfo: 'Todos los cálculos se realizan y convierten según las conversiones del sistema {{currency}} moneda predeterminada.',
  },
  tr: {
    registrationPlatform: 'Kayıt Platformu',
    regIp: 'Kayıt IP',
    closureDate: 'Kapanış Tarihi',
    closedBy: 'Kapatan',
    closureIp: 'Kapanış IP',
    branded: 'Markalı',
    byCreationDate: 'Oluşturulma Tarihine Göre',
    byCalculationDate: 'Hesaplama Tarihine Göre',
    aggregationCriteria: 'Toplama Kriterleri',
    realBetAmount: 'Gerçek Bahis Miktarı',
    realWinAmount: 'Gerçek Kazanç Miktarı',
    realGGR: 'Gerçek GGR',
    realBetsCount: 'Gerçek Bahis Sayısı',
    bonusGGR: 'Bonus GGR',
    bonusBetsCount: 'Bonus Bahis Sayısı',
    playersCount: 'Oyuncu Sayısı',
    successDepositAmount: 'Başarılı Para Yatırma İşlemi Miktarı',
    successDepositCount: 'Başarılı Para Yatırma İşlemi Sayısı',
    canceledDepositAmount: 'İptal Edilen Para Yatırma İşlemi Miktarı',
    failedDepositAmount: 'Başarısız Para Yatırma İşlemi Miktarı',
    failedDepositCount: 'Başarısız Para Yatırma İşlemi Sayısı',
    canceledDepositCount: 'İptal Edilen Para Yatırma İşlemi Sayısı',
    successWithdrawalAmount: 'Başarılı Para Çekme İşlemi Miktarı',
    successWithdrawalCount: 'Başarılı Para Çekme İşlemi Sayısı',
    canceledWithdrawalAmount: 'İptal Edilen Para Çekme İşlemi Miktarı',
    canceledWithdrawalCount: 'İptal Edilen Para Çekme İşlemi Sayısı',
    failedWithdrawalAmount: 'Başarısız Para Çekme İşlemi Miktarı',
    failedWithdrawalCount: 'Başarısız Para Çekme İşlemi Sayısı',
    hold: 'Tut',
    playerCount: 'Oyuncu Sayısı',
    adjustmentId: 'Ayarlama Kimliği',
    currentConfig: 'Mevcut Yapılandırma',
    actualConfig: 'Asıl Yapılandırma',
    extTransactionId: 'Harici İşlem Kimliği',
    gameCategory: 'Oyun Kategorisi',
    countOfBets: 'Bahis Sayısı',
    totalBetAmount: 'Toplam Bahis Miktarı',
    countOfWins: 'Kazanç Sayısı',
    totalWinAmount: 'Toplam Kazanç Miktarı',
    countOfDeposits: 'Ömür Boyu Para Yatırma İşlemi Sayısı',
    totalDepositAmount: 'Toplam Para Yatırma İşlemi Miktarı',
    countOfWithdrawals: 'Ömür Boyu Para Çekme İşlemi Sayısı',
    totalWithdrawalAmount: 'Toplam Para Çekme Miktarı',
    countOfOtherTransactions: 'Diğer İşlemlerin Sayısı',
    totalAmountOfOtherTransactions: 'Diğer İşlemlerin Toplam Miktarı',
    totalsAmounts: 'Toplam Miktarlar',
    totalsCounts: 'Toplam Sayılar',
    largeTransactionsByBrand: 'Markaya Göre Büyük İşlemler',
    largeTransactionsByPlayer: 'Oyuncuya Göre Büyük İşlemler',
    negativeBalance: 'Negatif Bakiye',
    betReportByCurrency: 'Para Birimine Göre Bahis Raporu',
    financialReportByPlayer: 'Oyuncuya Göre Finansal Rapor',
    financialReportByCurrency: 'Para Birimine Göre Finansal Rapor',
    closedAccounts: 'Kapatılan Hesaplar',
    totalDepositsCount: 'Toplam Para Yatırma İşlemi Sayısı',
    totalWithdrawalsCount: 'Toplam Para Çekme İşlemi Sayısı',
    totalSportBets: 'Toplam Spor Bahisleri',
    totalSportBetsCount: 'Toplam Spor Bahisleri Sayısı',
    totalSportWins: 'Toplam Spor Kazancı',
    totalSportWinsCount: 'Toplam Spor Kazancı Sayısı',
    totalCasinoBets: 'Toplam Casino Bahisleri',
    totalCasinoBetsCount: 'Toplam Casino Bahisleri Sayısı',
    totalCasinoWins: 'Toplam Casino Kazancı',
    totalCasinoWinsCount: 'Toplam Casino Kazancı Sayısı',
    viewLogs: 'Günlükleri Görüntüle',
    realCalculatedBetAmount: 'Hesaplanan Gerçek Bahis Tutarı',
    realCalculatedWinAmount: ' Hesaplanan Gerçek Kazanç Tutarı',
    realCalculatedBetsCount: 'Hesaplanan Gerçek Bahis Sayısı',
    bonusCalculatedBetAmount: ' Hesaplanan Bonus Bahis Tutarı',
    bonusCalculatedWinAmount: 'Hesaplanan Bonus Kazanç Tutarı',
    bonusCalculatedBetsCount: ' Hesaplanan Bonus Bahis Sayısı',
    realCancelledBetAmount: 'İptal Edilen Gerçek Bahis Tutarı',
    realCancelledBetCount: 'İptal Edilen Gerçek Bahis Sayısı',
    realCancelledWinAmount: 'Gerçek İptal Edilen Kazanç Tutarı',
    realCancelledWinCount: 'İptal Edilen Gerçek Kazanç Sayısı',
    realCalculatedWinCount: 'Hesaplanan Gerçek Kazanç Sayısı',
    bonusCancelledBetAmount: 'İptal Edilen Bonus Bahis Tutarı',
    bonusCancelledBetCount: 'İptal Edilen Bonus Bahis Sayısı',
    bonusCancelledWinAmount: 'İptal Edilen Bonus Kazanç Tutarı',
    bonusCancelledWinCount: 'İptal Edilen Bonus Kazanç Sayısı',
    bonusCalculatedWinCount: ' Hesaplanan Bonus Kazanç Sayısı',
    bonusWinCount: 'Bonus Kazanç Sayısı',
    bonusBetMinusWin: 'Bonus Bahis-Kazanç',
    realBetMinusWin: 'Gerçek Bahis-Kazanç',
    mixed: 'Karışık',
    calculated: 'Hesaplanan',
    mixedInfo: 'Rapor, tüm açık ve hesaplanmış bahisler dahil olmak üzere seçilen dönemde oluşturulan bahislerle ilgili toplu bilgileri gösterir.',
    calculatedInfo:
      'Rapor, yalnızca hesaplanan bahisler dahil olmak üzere seçilen dönemdeki bahis hesaplamaları ile ilgili toplu bilgileri gösterir.  Hesaplamanın, bir bahsin sonuçlandığı zamanın seçilen dönemde gerçekleştiği anlamına geldiğini unutmayın.',
    depositTaxes: 'Para Yatırma Vergi Tutarı',
    withdrawalTaxes: 'Para Çekme Vergi Tutarı',
    netDepositTotal: 'Toplam Para Yatırma Tutarından Net Vergi ',
    netWithdrawalTotal: 'Toplam Para Çekme Tutarından Net Vergi ',
    netDepositSuccess: 'Başarılı Para Yatırma Tutarından Net Vergi',
    netWithdrawalSuccess: 'Başarılı Para Çekme Tutarından Net Vergi',
    usedBalance: 'Kullanılmış Bakiye',
    unusedBalance: 'Kullanılmamış Bakiye',
    rolloverBalance: 'Rollover Bakiyesi',
    casinoBonusBalance: 'Casino Bonus Bakiyesi',
    debtBalance: 'Borç Bakiyesi',
    createdDate: 'Oluşturulma Tarihi',
    closedAccount: 'Kapatılmış Hesap',
    betShareAmountPercent: 'Bahis Oranı (Tutar) %',
    uniquePlayers: 'Benzersiz Oyuncular',
    financialReportByProvider: 'Sağlayıcı Tarafından Finansal Rapor',
    cancelledBetCount: 'İptal Edilen Bahis Sayısı',
    bonusBetCount: 'Bonus Bahis Sayısı',
    sportBonusBalance: 'Spor Bonus Bakiyesi',
    totalSportBonusAmount: 'Toplam Spor Bonus Tutarı',
    sportBonusCount: 'Spor Bonus Sayısı',
    totalSportBonusRedeemAmount: 'Toplam Spor Bonusu Kullanım Tutarı',
    totalCasinoBonusAmount: 'Toplam Casino Bonus Tutarı',
    casinoBonusCount: 'Casino Bonus Sayısı',
    totalCasinoBonusRedeemAmount: 'Toplam Casino Bonusu Kullanım Tutarı',
    totalFreeSpinAmount: 'Toplam Free Spin Tutarı',
    totalSpecialBonusAmount: 'Toplam Özel Bonus Tutarı',
    specialBonusCount: 'Özel Bonus Sayısı',
    bonusTotalsByPlayer: 'Oyuncu Bazında Bonus Toplamı',
    bonusTotalsByType: 'Türüne Göre Bonus Toplamı',
    totalRedeemedAmount: 'Toplam Kullanılan Tutar',
    bonusTotalsByStatus: 'Duruma Göre Bonus Toplamı',
    bonusCount: 'Bonus Sayısı',
    issuedDate: 'Veriliş Tarihi',
    lastDepositDate: 'Son Yatırım Tarihi',
    lastWithdrawalDate: 'Son Çekim Tarihi',
    realBalanceLeftoverAmount: 'Gerçek Bakiye Kalan Tutar',
    bonusBalanceLeftoverAmount: 'Bonus Bakiye Kalan Tutar',
    lastTransactionDate: 'Son İşlem Tarihi',
    inactivityDate: 'Etkin Olmama Tarihi',
    transferredToPlayersInfo: 'Seçilen oyunculara paranın kendilerine transfer edildiği bildirilecektir.',
    transferredToGovernmentInfo: 'Seçilen oyunculara paranın devlete aktarıldığı bildirilecektir.',
    transferredTo: 'Transfer Edilen',
    transferredDate: 'Transfer Tarihi',
    transferredBy: 'Transfer Eden',
    transferredToPlayers: 'Oyunculara Transfer Edildi',
    transferredToGovernment: 'Devlete Transfer Edildi',
    positive: 'Pozitif',
    negative: 'Negatif',
    topPlayersByGgr: 'GGR Bazında En İyi Oyuncular',
    topPlayersByDeposits: 'Yatırıma Göre En İyi Oyuncular',
    topPlayersByWithdrawals: 'Çekime Göre En İyi Oyuncular',
    topPlayersCurrencyInfo: 'Tüm hesaplamalar sistem çeviri işlemlerine {{currency}} varsayılan para birimine göre yapılır ve çevrilir.',
  },
  ru: {
    registrationPlatform: 'Платформа регистрации',
    regIp: 'IP рег․',
    closureDate: 'Дата закрытия',
    closedBy: 'Закрыто (кем)',
    closureIp: 'IP закрытия',
    branded: 'Помечено',
    byCreationDate: 'По дате создания',
    byCalculationDate: 'По дате расчета',
    aggregationCriteria: 'Критерии агрегирования',
    realBetAmount: 'Сумма ставок за реальные средства',
    realWinAmount: 'Сумма выигрышей в реальных средствах',
    realGGR: 'Реальный GGR',
    realBetsCount: 'Количество ставок на реальные средства',
    bonusGGR: 'Бонусный GGR',
    bonusBetsCount: 'Количество ставок на бонусные средства',
    playersCount: 'Количество игроков',
    successDepositAmount: 'Сумма успешных депозитов',
    successDepositCount: 'Количество успешных депозитов',
    canceledDepositAmount: 'Сумма отмененных депозитов',
    failedDepositAmount: 'Сумма отклоненных депозитов',
    failedDepositCount: 'Количество отклоненных депозитов',
    canceledDepositCount: 'Количество отмененных депозитов',
    successWithdrawalAmount: 'Сумма успешно выведенных средств',
    successWithdrawalCount: 'Количество успешных выводов средств',
    canceledWithdrawalAmount: 'Сумма отмененных выводов средств',
    canceledWithdrawalCount: 'Количество отмененных выводов средств',
    failedWithdrawalAmount: 'Сумма отклоненных выводов средств',
    failedWithdrawalCount: 'Количество отклоненных выводов средств',
    hold: 'Удержание',
    playerCount: 'Количество игроков',
    adjustmentId: 'ID корректировки',
    currentConfig: 'Текущие настройки',
    actualConfig: 'Действующие настройки',
    extTransactionId: 'ID внешней транзакции',
    gameCategory: 'Категория игры',
    countOfBets: 'Количество ставок',
    totalBetAmount: 'Общая сумма ставок',
    countOfWins: 'Количество выигрышей',
    totalWinAmount: 'Общая сумма выигрышей',
    countOfDeposits: 'Количество депозитов за жизненный цикл',
    totalDepositAmount: 'Общая сумма депозитов',
    countOfWithdrawals: 'Количество выводов средств за жизненный цикл',
    totalWithdrawalAmount: 'Общая сумма выведенных средств',
    countOfOtherTransactions: 'Количество других транзакций',
    totalAmountOfOtherTransactions: 'Общая сумма других транзакций',
    totalsAmounts: 'Всего сумма',
    totalsCounts: 'Всего количество',
    largeTransactionsByBrand: 'Крупные транзакции по брендам',
    largeTransactionsByPlayer: 'Крупные транзакции по игрокам',
    negativeBalance: 'Отрицательный баланс',
    betReportByCurrency: 'Отчет о ставках по валютам',
    financialReportByPlayer: 'Финансовый отчет по игрокам',
    financialReportByCurrency: 'Финансовый отчет по валютам',
    closedAccounts: 'Закрытые учетные записи',
    totalDepositsCount: 'Всего количество депозитов',
    totalWithdrawalsCount: 'Всего количество выводов средств',
    totalSportBets: 'Всего ставок на спорт',
    totalSportBetsCount: 'Всего количество ставок на спорт',
    totalSportWins: 'Всего выигрышей в ставках на спорт',
    totalSportWinsCount: 'Всего количество выигравших ставок на спорт',
    totalCasinoBets: 'Всего ставок в казино',
    totalCasinoBetsCount: 'Всего количество ставок в казино',
    totalCasinoWins: 'Всего выигрышей в казино',
    totalCasinoWinsCount: 'Общее количество ставок в казино',
    viewLogs: 'Просмотреть журналы',
    realCalculatedBetAmount: 'Реальная расчетная сумма ставки',
    realCalculatedWinAmount: 'Реальная расчетная сумма выигрыша',
    realCalculatedBetsCount: 'Реальное расчетное количество ставок',
    bonusCalculatedBetAmount: 'Расчетная сумма бонусной ставки',
    bonusCalculatedWinAmount: 'Расчетная сумма выигрыша бонусной ставки',
    bonusCalculatedBetsCount: 'Расчетное количество бонусных ставок',
    realCancelledBetAmount: 'Реальная сумма отмененной ставки',
    realCancelledBetCount: 'Реальное количество отмененных ставок',
    realCancelledWinAmount: 'Реальная сумма отмененого выигрыша',
    realCancelledWinCount: 'Реальное количество отмененных выигрышей',
    realCalculatedWinCount: 'Реальное расчетное количество выигрышей',
    bonusCancelledBetAmount: 'Сумма отмененных бонусных ставок',
    bonusCancelledBetCount: 'Количество отмененных бонусных ставок',
    bonusCancelledWinAmount: 'Сумма выигрышей отмененных бонусных ставок',
    bonusCancelledWinCount: 'Количество выигрышей отмененных бонусных ставок',
    bonusCalculatedWinCount: 'Расчетное количество бонусных выигрышей',
    bonusWinCount: 'Количество бонусных выигрышей',
    bonusBetMinusWin: 'Бонус Ставка-Выигрыш',
    realBetMinusWin: 'Реальная Ставка-Выигрыш',
    mixed: 'Смешанные',
    calculated: 'Рассчитанные',
    mixedInfo: 'Отчет показывает агрегированную информацию о ставках, созданных за выбранный период, включая все открытые и рассчитанные ставки.',
    calculatedInfo:
      'Отчет показывает агрегированную информацию о ставках, рассчитанных за выбранный период, включая только рассчитанные ставки. Обратите внимание, что расчет означает, что ставка получила свой результат в выбранном периоде.',
    depositTaxes: 'Сумма налога на депозиты',
    withdrawalTaxes: 'Сумма налога на выводы средств',
    netDepositTotal: 'Общая сумма депозитов за вычетом налогов',
    netWithdrawalTotal: 'Общая сумма выводов средств за вычетом налогов',
    netDepositSuccess: 'Сумма успешного депозита за вычетом налогов',
    netWithdrawalSuccess: 'Сумма успешного вывода средств за вычетом налогов',
    usedBalance: 'Использованный баланс',
    unusedBalance: 'Неиспользованный баланс',
    rolloverBalance: 'Пролонгированный баланс',
    casinoBonusBalance: 'Бонусный баланс казино',
    debtBalance: 'Остаток задолженности',
    createdDate: 'Дата создания',
    closedAccount: 'Закрытый аккаунт',
    betShareAmountPercent: 'Доля ставки (сумма) %',
    uniquePlayers: 'Уникальные игроки',
    financialReportByProvider: 'Финансовый отчет по провайдерам',
    cancelledBetCount: 'Счет отмененных ставок',
    bonusBetCount: 'Счет бонусных ставок',
    sportBonusBalance: 'Спортивный бонусный баланс',
    totalSportBonusAmount: 'Общая сумма спортивного бонуса',
    sportBonusCount: 'Счет спортивных бонусов',
    totalSportBonusRedeemAmount: 'Общая сумма отыгрыша спортивного бонуса',
    totalCasinoBonusAmount: 'Общая сумма бонуса казино',
    casinoBonusCount: 'Счет бонусов казино',
    totalCasinoBonusRedeemAmount: 'Общая сумма отыгрыша бонуса казино',
    totalFreeSpinAmount: 'Общая сумма фриспинов',
    totalSpecialBonusAmount: 'Общая сумма специального бонуса',
    specialBonusCount: 'Общий счет специального бонуса',
    bonusTotalsByPlayer: 'Всего бонусов по игрокам',
    bonusTotalsByType: 'Всего бонусов по типу',
    totalRedeemedAmount: 'Всего сумма отыгрыша',
    bonusTotalsByStatus: 'Всего бонусов по статусам',
    bonusCount: 'Счет бонусов',
    issuedDate: 'Дата выпуска',
    lastDepositDate: 'Дата последнего депозита',
    lastWithdrawalDate: 'Дата последнего вывода средств',
    realBalanceLeftoverAmount: 'Остаточная сумма реального баланса',
    bonusBalanceLeftoverAmount: 'Остаточная сумма бонусного баланса',
    lastTransactionDate: 'Дата последней транзакции',
    inactivityDate: 'Дата бездействия',
    transferredToPlayersInfo: 'Выбранные игроки получат уведомление о том, что им были переведены деньги.',
    transferredToGovernmentInfo: 'Выбранные игроки получат уведомление о том, что деньги были переведены правительству.',
    transferredTo: 'Переведено в',
    transferredDate: 'Дата перевода',
    transferredBy: 'Переведено',
    transferredToPlayers: 'Переведено игрокам',
    transferredToGovernment: 'Переведено правительству',
    positive: 'Положительный',
    negative: 'Отрицательный',
    topPlayersByGgr: 'Топ-игроки по GGR',
    topPlayersByDeposits: 'Топ-игроки по депозитам',
    topPlayersByWithdrawals: 'Топ-игроки по выводам средств',
    topPlayersCurrencyInfo: 'Все расчеты производятся и конвертируются на основе системных конверсий {{currency}} валюты по умолчанию.',
  },
  pt: {
    registrationPlatform: 'Plataforma de Registo',
    regIp: 'IP de Reg.',
    closureDate: 'Data de Encerramento',
    closedBy: 'Encerrado por',
    closureIp: 'IP de Encerramento',
    branded: 'Marca',
    byCreationDate: '',
    byCalculationDate: '',
    aggregationCriteria: 'Critérios de Agregação',
    realBetAmount: '',
    realWinAmount: '',
    realGGR: 'GGR Real',
    realBetsCount: '',
    bonusGGR: 'GGR Bónus',
    bonusBetsCount: '',
    playersCount: 'Contagem de Jogadores',
    successDepositAmount: 'Valor de Depósitos Bem-sucedidos',
    successDepositCount: 'Contagem de Depósitos Bem-sucedidos',
    canceledDepositAmount: 'Valor de Depósitos Cancelados',
    failedDepositAmount: 'Valor de Depósitos Sem Sucesso',
    failedDepositCount: 'Contagem de Depósitos Sem Sucesso',
    canceledDepositCount: 'Contagem de Depósitos Cancelados',
    successWithdrawalAmount: 'Valor de Levantamentos Bem-sucedidos',
    successWithdrawalCount: 'Contagem de Levantamentos Bem-sucedidos',
    canceledWithdrawalAmount: 'Valor de Levantamentos Cancelados',
    canceledWithdrawalCount: 'Contagem de Levantamentos Cancelados',
    failedWithdrawalAmount: 'Valor de Levantamentos Sem Sucesso',
    failedWithdrawalCount: 'Contagem de Levantamentos Sem Sucesso',
    hold: 'Aguardar',
    playerCount: 'Contagem de Jogadores',
    adjustmentId: 'ID de Ajuste',
    currentConfig: 'Configuração Atual',
    actualConfig: 'Configuração Real',
    extTransactionId: 'Ext. da ID de Transação',
    gameCategory: 'Categoria de Jogo',
    countOfBets: 'Contagem de Apostas',
    totalBetAmount: 'Valor Total da Aposta',
    countOfWins: 'Contagem de Ganhos',
    totalWinAmount: 'Valor Total de Ganho',
    countOfDeposits: 'Contagem de Depósitos Desde o Início',
    totalDepositAmount: 'Valor Total de Depósito',
    countOfWithdrawals: 'Contagem Vitalícia de Levantamentos',
    totalWithdrawalAmount: 'Valor Total de Levantamento',
    countOfOtherTransactions: 'Contagem de Outras Transações',
    totalAmountOfOtherTransactions: 'Valor Total de Outras Transações',
    totalsAmounts: 'Valores Totais',
    totalsCounts: 'Contagens Totais',
    largeTransactionsByBrand: 'Grandes Transações por Marca',
    largeTransactionsByPlayer: 'Grandes Transações por Jogador',
    negativeBalance: 'Saldo Negativo',
    betReportByCurrency: 'Relatório de Apostas por Moeda',
    financialReportByPlayer: 'Relatório Financeiro por Jogador',
    financialReportByCurrency: 'Relatório Financeiro por Moeda',
    closedAccounts: 'Contas Encerradas',
    totalDepositsCount: 'Contagem Total de Depósitos',
    totalWithdrawalsCount: 'Contagem Total de Levantamentos',
    totalSportBets: 'Total de Apostas Desportivas',
    totalSportBetsCount: 'Contagem Total de Apostas Desportivas',
    totalSportWins: 'Total de Ganhos Desportivos',
    totalSportWinsCount: 'Contagem Total de Ganhos Desportivos',
    totalCasinoBets: 'Apostas Totais em Casino',
    totalCasinoBetsCount: 'Contagem Total de Apostas em Casino',
    totalCasinoWins: 'Total de Ganhos em Casino',
    totalCasinoWinsCount: 'Contagem Total de Ganhos em Casino',
    viewLogs: 'Visualizar Logs',
    realCalculatedBetAmount: 'Valor de Aposta Real Calculado',
    realCalculatedWinAmount: 'Valor de Ganho Real Calculado',
    realCalculatedBetsCount: 'Contagem de Apostas Real Calculadas',
    bonusCalculatedBetAmount: 'Valor de Aposta de Bónus Calculado',
    bonusCalculatedWinAmount: 'Valor de Ganho de Bónus Calculado',
    bonusCalculatedBetsCount: 'Contagem de Apostas de Bónus Calculado',
    realCancelledBetAmount: 'Valor de Aposta Real Cancelado',
    realCancelledBetCount: 'Contagem de Apostas Reais Canceladas',
    realCancelledWinAmount: 'Valor de Ganho Real Cancelado',
    realCancelledWinCount: 'Contagem de Ganhos Reais Cancelados',
    realCalculatedWinCount: 'Contagem de Ganhos Reais Calculados',
    bonusCancelledBetAmount: 'Valor de Aposta de Bónus Cancelado',
    bonusCancelledBetCount: 'Contagem de Apostas Bónus Canceladas',
    bonusCancelledWinAmount: 'Valor de Ganho de Bónus Cancelado',
    bonusCancelledWinCount: 'Contagem de Ganhos de Bónus Cancelados',
    bonusCalculatedWinCount: 'Contagem de Ganhos de Bónus Calculados',
    bonusWinCount: 'Contagem de Ganhos de Bónus',
    bonusBetMinusWin: 'Ganho de Aposta de Bónus',
    realBetMinusWin: 'Ganho de Aposta Real',
    mixed: 'Misto',
    calculated: 'Calculado',
    mixedInfo: 'O relatório mostra informações agregadas sobre apostas criadas no período selecionado, incluindo todas as apostas abertas e calculadas.',
    calculatedInfo:
      'O relatório mostra informações agregadas sobre as apostas calculadas no período selecionado, incluindo apenas as apostas calculadas. Note que esse cálculo significa que uma aposta recebeu o seu resultado durante o período selecionado.',
    depositTaxes: 'Valor do Imposto de Depósitos',
    withdrawalTaxes: 'Valor do Imposto de Retirada',
    netDepositTotal: 'Valor total de depósitos líquido de impostos',
    netWithdrawalTotal: 'Valor total de saques líquido de impostos',
    netDepositSuccess: 'Valor do depósito bem-sucedido líquido de impostos',
    netWithdrawalSuccess: 'Valor de saque bem-sucedido líquido de impostos ',
    usedBalance: 'Saldo Utilizado',
    unusedBalance: 'Saldo Não Utilizado',
    rolloverBalance: 'Saldo Acumulado',
    casinoBonusBalance: 'Saldo de Bônus do Cassino',
    debtBalance: 'Saldo Devedor',
    createdDate: 'Data de Criação',
    closedAccount: 'Conta fechada',
    betShareAmountPercent: 'Aposta Quota (Montante) %',
    uniquePlayers: 'Jogadores únicos',
    financialReportByProvider: 'Relatório financeiro por fornecedor',
    cancelledBetCount: 'Contagem de apostas canceladas',
    bonusBetCount: 'Contagem de apostas de bónus',
    sportBonusBalance: 'Saldo do bónus desportivo',
    totalSportBonusAmount: 'Montante total do bónus desportivo',
    sportBonusCount: 'Contagem do bónus desportivo',
    totalSportBonusRedeemAmount: 'Montante total do resgate do bónus desportivo',
    totalCasinoBonusAmount: 'Montante total do Bónus do Casino',
    casinoBonusCount: 'Contagem do Bónus do Casino',
    totalCasinoBonusRedeemAmount: 'Valor total do resgate do Bónus do Casino',
    totalFreeSpinAmount: 'Montante Total de Giros Grátis',
    totalSpecialBonusAmount: 'Montante total do Bónus Especial',
    specialBonusCount: 'Contagem de Bónus Especial',
    bonusTotalsByPlayer: 'Totais de bónus por jogador',
    bonusTotalsByType: 'Totais de Bónus por Tipo',
    totalRedeemedAmount: 'Montante total resgatado',
    bonusTotalsByStatus: 'Totais de Bónus por Estado',
    bonusCount: 'Contagem de Bónus',
    issuedDate: 'Data de Emissão',
    lastDepositDate: 'Data do último depósito',
    lastWithdrawalDate: 'Data do último levantamento',
    realBalanceLeftoverAmount: 'Montante restante do saldo real',
    bonusBalanceLeftoverAmount: 'Montante restante do saldo de bónus',
    lastTransactionDate: 'Data da última transação',
    inactivityDate: 'Data de inatividade',
    transferredToPlayersInfo: 'Os jogadores seleccionados serão notificados de que o dinheiro foi transferido para eles.',
    transferredToGovernmentInfo: 'Os jogadores seleccionados serão notificados de que o dinheiro foi transferido para o governo.',
    transferredTo: 'Transferido para',
    transferredDate: 'Data de Transferência',
    transferredBy: 'Transferido por',
    transferredToPlayers: 'Transferido para Jogadores',
    transferredToGovernment: 'Transferido para o Governo',
    positive: 'Positivo',
    negative: 'Negativo',
    topPlayersByGgr: 'Principais jogadores por GGR',
    topPlayersByDeposits: 'Principais jogadores por depósitos',
    topPlayersByWithdrawals: 'Principais jogadores por levantamentos',
    topPlayersCurrencyInfo: 'Todos os cálculos são efectuados e convertidos com base nas conversões do sistema {{currency}} moeda padrão.',
  },
  zh: {
    registrationPlatform: '注册平台',
    regIp: '注册IP',
    closureDate: '关闭日期',
    closedBy: '关闭',
    closureIp: '关闭IP',
    branded: '品牌',
    byCreationDate: '',
    byCalculationDate: '',
    aggregationCriteria: '汇总条件',
    realBetAmount: '',
    realWinAmount: '',
    realGGR: '真实博彩总收入',
    realBetsCount: '',
    bonusGGR: '奖金博彩总收入',
    bonusBetsCount: '',
    playersCount: '玩家数量',
    successDepositAmount: '成功存款金额',
    successDepositCount: '成功存款次数',
    canceledDepositAmount: '已取消的存款金额',
    failedDepositAmount: '存款失败金额',
    failedDepositCount: '存款失败次数',
    canceledDepositCount: '已取消的存款次数',
    successWithdrawalAmount: '成功取款金额',
    successWithdrawalCount: '成功取款次数',
    canceledWithdrawalAmount: '已取消的取款金额',
    canceledWithdrawalCount: '已取消的取款次数',
    failedWithdrawalAmount: '取款失败金额',
    failedWithdrawalCount: '取款失败次数',
    hold: '持有',
    playerCount: '玩家数量',
    adjustmentId: '调整ID',
    currentConfig: '当前配置',
    actualConfig: '实际配置',
    extTransactionId: '扩展交易ID',
    gameCategory: '游戏类别',
    countOfBets: '投注次数',
    totalBetAmount: '总投注金额',
    countOfWins: '获胜次数',
    totalWinAmount: '总获胜金额',
    countOfDeposits: '存款终身次数',
    totalDepositAmount: '总存款金额',
    countOfWithdrawals: '取款终身次数',
    totalWithdrawalAmount: '总取款金额',
    countOfOtherTransactions: '其他交易数量',
    totalAmountOfOtherTransactions: '其他交易总额',
    totalsAmounts: '总金额',
    totalsCounts: '总次数',
    largeTransactionsByBrand: '按品牌分类的大额交易',
    largeTransactionsByPlayer: '按玩家分类的大额交易',
    negativeBalance: '负余额',
    betReportByCurrency: '按货币分类的投注报告',
    financialReportByPlayer: '按玩家分类的财务报告',
    financialReportByCurrency: '按货币分类的财务报告',
    closedAccounts: '已关闭帐户',
    totalDepositsCount: '总存款次数',
    totalWithdrawalsCount: '总取款次数',
    totalSportBets: '总体育投注',
    totalSportBetsCount: '总体育投注次数',
    totalSportWins: '总体育获胜',
    totalSportWinsCount: '总体育获胜次数',
    totalCasinoBets: '娱乐场总投注',
    totalCasinoBetsCount: '娱乐场总投注次数',
    totalCasinoWins: '娱乐场总获胜',
    totalCasinoWinsCount: '娱乐场获胜总次数',
    viewLogs: '查看日志',
    realCalculatedBetAmount: '真实计算的投注金额',
    realCalculatedWinAmount: '真实计算的获胜金额',
    realCalculatedBetsCount: '真实计算的投注次数',
    bonusCalculatedBetAmount: '奖金计入的投注金额',
    bonusCalculatedWinAmount: '奖金计入的获胜金额',
    bonusCalculatedBetsCount: '奖金计入的投注次数',
    realCancelledBetAmount: '真实取消的投注金额',
    realCancelledBetCount: '真实取消的投注次数',
    realCancelledWinAmount: '真实取消的获胜金额',
    realCancelledWinCount: '真实取消的获胜次数',
    realCalculatedWinCount: '真实计算的获胜次数',
    bonusCancelledBetAmount: '已取消奖金的投注金额',
    bonusCancelledBetCount: '已取消奖金的投注次数',
    bonusCancelledWinAmount: '已取消奖金的获胜金额',
    bonusCancelledWinCount: '已取消奖金的获胜次数',
    bonusCalculatedWinCount: '奖金计入的获胜次数',
    bonusWinCount: '赢得奖金次数',
    bonusBetMinusWin: '奖金 投注—赢',
    realBetMinusWin: '真实投注—获胜',
    mixed: '混合',
    calculated: '计算',
    mixedInfo: '该报告显示了在所选时期创建的投注的汇总信息，其中包括所有开放和计算的投注。',
    calculatedInfo: '该报告显示了在所选时期计算的投注的汇总信息，其中只包括计算的投注。请注意，计算意味着一个投注在选定的时期内已经得到了结果。',
    depositTaxes: '存款税额',
    withdrawalTaxes: '取款税额',
    netDepositTotal: '扣除税款后的存款总额',
    netWithdrawalTotal: '扣除税收后的总取款金额',
    netDepositSuccess: '扣除税收成功存款金额',
    netWithdrawalSuccess: '扣除税收后的成功取款金额',
    usedBalance: '使用的余额',
    unusedBalance: '未使用的余额',
    rolloverBalance: '结转余额',
    casinoBonusBalance: '娱乐场奖金余额',
    debtBalance: '债务余额',
    createdDate: '创建日期',
    closedAccount: '已关闭账户',
    betShareAmountPercent: '投注份额（金额） %',
    uniquePlayers: '独特玩家',
    financialReportByProvider: '按提供商分列的财务报告',
    cancelledBetCount: '取消投注次数',
    bonusBetCount: '奖金投注次数',
    sportBonusBalance: '体育奖金余额',
    totalSportBonusAmount: '体育奖金总额',
    sportBonusCount: '体育奖金计数',
    totalSportBonusRedeemAmount: '体育奖金兑换总额',
    totalCasinoBonusAmount: '赌场奖金总额',
    casinoBonusCount: '赌场奖金计数',
    totalCasinoBonusRedeemAmount: '赌场奖金兑换总额',
    totalFreeSpinAmount: '免费旋转总额',
    totalSpecialBonusAmount: '特别奖金总额',
    specialBonusCount: '特别奖金计数',
    bonusTotalsByPlayer: '玩家奖金总额',
    bonusTotalsByType: '按类型分列的奖金总额',
    totalRedeemedAmount: '兑换总额',
    bonusTotalsByStatus: '按状态分列的奖金总额',
    bonusCount: '奖金计数',
    issuedDate: '发布日期',
    lastDepositDate: '最后存款日期',
    lastWithdrawalDate: '最后提款日期',
    realBalanceLeftoverAmount: '实际余额剩余金额',
    bonusBalanceLeftoverAmount: '奖金余额剩余金额',
    lastTransactionDate: '最后交易日期',
    inactivityDate: '不活跃日期',
    transferredToPlayersInfo: '入选玩家将收到汇款通知。',
    transferredToGovernmentInfo: '被选中的玩家将收到资金已转入政府的通知。',
    transferredTo: '转至',
    transferredDate: '转入日期',
    transferredBy: '转账方',
    transferredToPlayers: '转至玩家',
    transferredToGovernment: '转至政府',
    positive: '正',
    negative: '负',
    topPlayersByGgr: '按GGR排列的顶级玩家',
    topPlayersByDeposits: '按存款排列的顶级玩家',
    topPlayersByWithdrawals: '按取款排列的顶级玩家',
    topPlayersCurrencyInfo: '所有计算均根据系统转换{{currency}}默认货币进行。',
  },
  ro: {
    registrationPlatform: 'Platformă de înregistrare',
    regIp: 'IP-ul de înr.',
    closureDate: 'Data de încheiere',
    closedBy: 'Închis de',
    closureIp: 'IP-ul de închidere',
    branded: 'Marcat',
    aggregationCriteria: 'Criterii de agregare',
    realGGR: 'Venit brut real',
    bonusGGR: 'Bonus (din Venit Brut)',
    playersCount: 'Numărul jucătorilor',
    successDepositAmount: 'Suma depunerii cu succes',
    successDepositCount: 'Număr de depunere cu succes',
    canceledDepositAmount: 'Suma de depunere anulată',
    failedDepositAmount: 'Suma de depunere eșuată',
    failedDepositCount: 'Numărul de depunere eșuată',
    canceledDepositCount: 'Numărul de depunere anulată',
    successWithdrawalAmount: 'Suma de retragere cu succes',
    successWithdrawalCount: 'Numărul de retragere cu succes',
    canceledWithdrawalAmount: 'Suma de retragere anulată',
    canceledWithdrawalCount: 'Numărul de retragere anulată',
    failedWithdrawalAmount: 'Suma de retragere eșuată',
    failedWithdrawalCount: 'Numărul de retragere eșuată',
    hold: 'În așteptare',
    playerCount: 'Numărul de jucători',
    adjustmentId: 'ID-ul de ajustare',
    currentConfig: 'Configurație curentă',
    actualConfig: 'Configurație actuală',
    extTransactionId: 'ID-urile externe de tranzacție',
    gameCategory: 'Categorie de joc',
    countOfBets: 'Număr de pariuri',
    totalBetAmount: 'Suma totală de pariu',
    countOfWins: 'Numărul câștigurilor',
    totalWinAmount: 'Numărul total de câștiguri',
    countOfDeposits: 'Numărul depunerilor pe ciclu de viață',
    totalDepositAmount: 'Suma totală de depunere',
    countOfWithdrawals: 'Numărul de retrageri pe durata vieții',
    totalWithdrawalAmount: 'Suma totală de retrageri',
    countOfOtherTransactions: 'Numărul de alte tranzacții',
    totalAmountOfOtherTransactions: 'Suma totală a altor tranzacții',
    totalsAmounts: 'Sume totale',
    totalsCounts: 'Cantități totale',
    largeTransactionsByBrand: 'Tranzacții mari în funcție de marcă (metodă de plată)',
    largeTransactionsByPlayer: 'Tranzacții mari după jucători',
    negativeBalance: 'Sold negativ',
    betReportByCurrency: 'Raport de pariu după valută',
    financialReportByPlayer: 'Raport financiar după jucător',
    financialReportByCurrency: 'Raport financiar după valută',
    closedAccounts: 'Conturi închise',
    totalDepositsCount: 'Număr total de depozite',
    totalWithdrawalsCount: 'Număr total de retrageri',
    totalSportBets: 'Pariuri totale sportive',
    totalSportBetsCount: 'Număr total de pariuri sportive',
    totalSportWins: 'Câștiguri totale la pariuri sportive',
    totalSportWinsCount: 'Numărul total de pariuri sportive',
    totalCasinoBets: 'Pariuri totale de casino',
    totalCasinoBetsCount: 'Număr total de pariuri la casino',
    totalCasinoWins: 'Câștiguri totale de casino',
    totalCasinoWinsCount: 'Numărul total de câștiguri de cazino',
    viewLogs: 'Vizualiza log-urile',
    realCalculatedBetAmount: 'Suma reală de pariu calculată',
    realCalculatedWinAmount: 'Suma reală de câștig calculată',
    realCalculatedBetsCount: 'Numărul real de pariuri calculate',
    bonusCalculatedBetAmount: 'Suma pariului calculată pentru bonus',
    bonusCalculatedWinAmount: 'Suma de câștig calculată pentru bonus',
    bonusCalculatedBetsCount: 'Numărul de pariuri calculate de bonus',
    realCancelledBetAmount: 'Suma reală a pariului anulat',
    realCancelledBetCount: 'Numărul real de pariuri anulate',
    realCancelledWinAmount: 'Suma reală de câștig anulată',
    realCancelledWinCount: 'Numărul real de câștiguri anulate',
    realCalculatedWinCount: 'Numărul real de câștiguri calculat',
    bonusCancelledBetAmount: 'Suma pariului anulat bonus',
    bonusCancelledBetCount: 'Numărul de pariuri anulate bonus',
    bonusCancelledWinAmount: 'Suma anulată a câștigului bonus',
    bonusCancelledWinCount: 'Numărul anulat de câștig bonus',
    bonusCalculatedWinCount: 'Numărul calculat de câștig pentru bonus',
    bonusWinCount: 'Numărul de câștiguri bonus',
    bonusBetMinusWin: 'Bonus pariu-câștig',
    realBetMinusWin: 'Pariu-câștig real',
    mixed: 'Amestecat',
    calculated: 'Calculat/Validat',
    mixedInfo: 'Raportul arată informațiile agregate despre pariurile create în perioada selectată, inclusiv toate pariurile deschise și calculate.',
    calculatedInfo:
      'Raportul arată informațiile agregate despre pariurile calculate în perioada selectată, inclusiv doar pariurile calculate. Reține că acest calcul înseamnă că un pariu a primit rezultatul în perioada selectată.',
    depositTaxes: 'Suma impozitului pe depuneri',
    withdrawalTaxes: 'Suma impozitului pe retrageri',
    netDepositTotal: 'Suma totală a depunerilor, netă de impozite',
    netWithdrawalTotal: 'Suma totală a retragerilor, netă de impozite',
    netDepositSuccess: 'Suma depunerii cu succes, netă de impozite',
    netWithdrawalSuccess: 'Suma retragerii cu succes netă de impozite',
    usedBalance: 'Balanța folosită ',
    unusedBalance: 'Balanța neutilizat',
    rolloverBalance: 'Balanța Rollover-ului',
    casinoBonusBalance: 'Balanța bonus la casino',
    debtBalance: 'Balanța datoriei',
    createdDate: 'Data creării',
  },
  bg: {},
  el: {
    registrationPlatform: 'Πλατφόρμα εγγραφής',
    regIp: 'IP εγγραφής',
    closureDate: 'Ημερομηνία κλεισίματος',
    closedBy: 'Έκλεισε από',
    closureIp: 'IP κλεισίματος',
    branded: 'Με επωνυμία',
    aggregationCriteria: 'Κριτήρια συγκέντρωσης',
    realGGR: 'Πραγματικό GGR',
    bonusGGR: 'GGR μπόνους',
    playersCount: 'Αριθμός παικτών',
    successDepositAmount: 'Ποσό επιτυχημένων καταθέσεων',
    successDepositCount: 'Αριθμός επιτυχημένων καταθέσεων',
    canceledDepositAmount: 'Ποσό ακυρωμένων καταθέσεων',
    failedDepositAmount: 'Ποσό αποτυχημένων καταθέσεων',
    failedDepositCount: 'Αριθμός αποτυχημένων καταθέσεων',
    canceledDepositCount: 'Αριθμός ακυρωμένων καταθέσεων',
    successWithdrawalAmount: 'Ποσό επιτυχημένων αναλήψεων',
    successWithdrawalCount: 'Αριθμός επιτυχημένων αναλήψεων',
    canceledWithdrawalAmount: 'Ποσό ακυρωμένων αναλήψεων',
    canceledWithdrawalCount: 'Αριθμός ακυρωμένων αναλήψεων',
    failedWithdrawalAmount: 'Ποσό αποτυχημένων αναλήψεων',
    failedWithdrawalCount: 'Αριθμός αποτυχημένων αναλήψεων',
    hold: 'Αναμονή',
    playerCount: 'Αριθμός παικτών',
    adjustmentId: 'Αναγνωριστικό προσαρμογής',
    currentConfig: 'Τρέχουσα διαμόρφωση',
    actualConfig: 'Πραγματική διαμόρφωση',
    extTransactionId: 'Αναγνωριστικό εξωτ. συναλλαγής',
    gameCategory: 'Κατηγορία παιχνιδιού',
    countOfBets: 'Αριθμός στοιχημάτων',
    totalBetAmount: 'Συνολικό ποσό στοιχήματος',
    countOfWins: 'Αριθμός κερδών',
    totalWinAmount: 'Συνολικό ποσό κέρδους',
    countOfDeposits: 'Αριθμός καταθέσεων σε όλη τη διάρκεια ζωής του λογαριασμού',
    totalDepositAmount: 'Συνολικό ποσό κατάθεσης',
    countOfWithdrawals: 'Αριθμός αναλήψεων σε όλη τη διάρκεια ζωής του λογαριασμού',
    totalWithdrawalAmount: 'Συνολικό ποσό ανάληψης',
    countOfOtherTransactions: 'Αριθμός άλλων συναλλαγών',
    totalAmountOfOtherTransactions: 'Συνολικό ποσό άλλων συναλλαγών',
    totalsAmounts: 'Ποσά συνολικών',
    totalsCounts: 'Αριθμός συνολικών',
    largeTransactionsByBrand: 'Μεγάλες συναλλαγές ανά επωνυμία',
    largeTransactionsByPlayer: 'Μεγάλες συναλλαγές ανά παίκτη',
    negativeBalance: 'Αρνητικό υπόλοιπο',
    betReportByCurrency: 'Αναφορά στοιχημάτων ανά νόμισμα',
    financialReportByPlayer: 'Οικονομική αναφορά ανά παίκτη',
    financialReportByCurrency: 'Οικονομική αναφορά ανά νόμισμα',
    closedAccounts: 'Κλειστοί λογαριασμοί',
    totalDepositsCount: 'Αριθμός συνολικών καταθέσεων',
    totalWithdrawalsCount: 'Αριθμός συνολικών αναλήψεων',
    totalSportBets: 'Συνολικά αθλητικά στοιχήματα',
    totalSportBetsCount: 'Αριθμός συνολικών αθλητικών στοιχημάτων',
    totalSportWins: 'Συνολικά κέρδη αθλητικού στοιχήματος',
    totalSportWinsCount: 'Αριθμός συνολικών κερδών αθλητικού στοιχήματος',
    totalCasinoBets: 'Συνολικά στοιχήματα στο καζίνο',
    totalCasinoBetsCount: 'Αριθμός συνολικών στοιχημάτων στο καζίνο',
    totalCasinoWins: 'Συνολικά κέρδη στο καζίνο',
    totalCasinoWinsCount: 'Αριθμός συνολικών κερδών στο καζίνο',
    viewLogs: 'Προβολή αρχείων καταγραφής',
    realCalculatedBetAmount: 'Ποσό υπολογισμένων στοιχημάτων με πραγματικά χρήματα',
    realCalculatedWinAmount: 'Ποσό υπολογισμένων κερδών με πραγματικά χρήματα',
    realCalculatedBetsCount: 'Αριθμός υπολογισμένων στοιχημάτων με πραγματικά χρήματα',
    bonusCalculatedBetAmount: 'Ποσό υπολογισμένων στοιχημάτων μπόνους',
    bonusCalculatedWinAmount: 'Ποσό υπολογισμένων κερδών μπόνους',
    bonusCalculatedBetsCount: 'Αριθμός υπολογισμένων στοιχημάτων μπόνους',
    realCancelledBetAmount: 'Ποσό ακυρωμένων στοιχημάτων με πραγματικά χρήματα',
    realCancelledBetCount: 'Αριθμός ακυρωμένων στοιχημάτων με πραγματικά χρήματα',
    realCancelledWinAmount: 'Ποσό ακυρωμένων κερδών με πραγματικά χρήματα',
    realCancelledWinCount: 'Αριθμός ακυρωμένων κερδών με πραγματικά χρήματα',
    realCalculatedWinCount: 'Αριθμός υπολογισμένων κερδών με πραγματικά χρήματα',
    bonusCancelledBetAmount: 'Ποσό ακυρωμένων στοιχημάτων μπόνους',
    bonusCancelledBetCount: 'Αριθμός ακυρωμένων στοιχημάτων μπόνους',
    bonusCancelledWinAmount: 'Ποσό ακυρωμένων κερδών μπόνους',
    bonusCancelledWinCount: 'Αριθμός ακυρωμένων κερδών μπόνους',
    bonusCalculatedWinCount: 'Αριθμός υπολογισμένων κερδών μπόνους',
    bonusWinCount: 'Αριθμός κέρδους μπόνους',
    bonusBetMinusWin: 'Μπόνους Στοίχημα - Κέρδος',
    realBetMinusWin: 'Στοίχημα - Κέρδος με πραγματικά χρήματα',
    mixed: 'Μεικτά',
    calculated: 'Υπολογισμένα',
    mixedInfo: 'Η αναφορά εμφανίζει τα συγκεντρωτικά στοιχεία για στοιχήματα που δημιουργήθηκαν την επιλεγμένη περίοδο, συμπεριλαμβανομένων όλων των ανοικτών και υπολογισμένων στοιχημάτων.',
    calculatedInfo:
      'Η αναφορά εμφανίζει τα συγκεντρωτικά στοιχεία για στοιχήματα που υπολογίστηκαν στην επιλεγμένη περίοδο, συμπεριλαμβανομένων μόνο των υπολογισμένων στοιχημάτων. Θα πρέπει να σημειωθεί ότι υπολογισμός σημαίνει ότι ένα στοίχημα έχει λάβει το αποτέλεσμά του στην επιλεγμένη περίοδο.',
    depositTaxes: 'Ποσό φόρου καταθέσεων',
    withdrawalTaxes: 'Ποσό φόρου αναλήψεων',
    netDepositTotal: 'Καθαρός φόρος από συνολικό ποσό καταθέσεων',
    netWithdrawalTotal: 'Καθαρός φόρος από συνολικό ποσό αναλήψεων',
    netDepositSuccess: 'Καθαρός φόρος από ποσό επιτυχημένων καταθέσεων',
    netWithdrawalSuccess: 'Καθαρός φόρος από ποσό επιτυχημένων αναλήψεων',
    usedBalance: 'Χρησιμοποιημένο υπόλοιπο',
    unusedBalance: 'Αχρησιμοποίητο υπόλοιπο',
    rolloverBalance: 'Υπόλοιπο για κάλυψη απαιτήσεων στοιχηματισμού',
    casinoBonusBalance: 'Υπόλοιπο μπόνους καζίνο',
    debtBalance: 'Υπόλοιπο οφειλής',
    closedAccount: 'Κλειστός λογαριασμός',
    betCount: 'Αριθμός στοιχημάτων',
    betShareAmountPercent: 'Μέρος στοιχήματος (Ποσό) %',
    winCount: 'Αριθμός κερδών',
    uniquePlayers: 'Μοναδικοί παίκτες',
    financialReportByProvider: 'Οικονομική αναφορά ανά πάροχο',
    createdDate: 'Ημερομηνία δημιουργίας',
  },
};
