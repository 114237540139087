import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Notification } from 'components/layout';
import { selectSuccessMessage, selectErrorMessage } from 'selectors';
import { resetMessage } from 'actions/setting/settingActionCreator';

const mapStateToProps = ({ settings }) => ({
  successMessage: selectSuccessMessage(settings),
  errorMessage: selectErrorMessage(settings),
});

const mapDispatchToProps = (dispatch) => bindActionCreators(
  {
    resetMessage,
  },
  dispatch,
);

export default connect(mapStateToProps, mapDispatchToProps)(Notification);
