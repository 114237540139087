const PlayerSegmentActionType = {
  GET_PLAYERS_SEGMENTS_RESOURCE: 'GET_PLAYERS_SEGMENTS_RESOURCE',
  SAVE_PLAYERS_SEGMENTS_RESOURCE: 'SAVE_PLAYERS_SEGMENTS_RESOURCE',
  RESET_PLAYERS_SEGMENTS_RESOURCE: 'RESET_PLAYERS_SEGMENTS_RESOURCE',

  GET_SEGMENT_PLAYERS_RESOURCE: 'GET_SEGMENT_PLAYERS_RESOURCE',
  SAVE_SEGMENT_PLAYERS_RESOURCE: 'GET_SEGMENT_PLAYERS_SUCCESS',
  RESET_SEGMENT_PLAYERS_RESOURCE: 'RESET_SEGMENT_PLAYERS_RESOURCE',

  GET_PLAYERS_SEGMENTS: 'GET_PLAYERS_SEGMENTS',
  SAVE_PLAYERS_SEGMENTS: 'SAVE_PLAYERS_SEGMENTS',
  RESET_PLAYERS_SEGMENTS: 'RESET_PLAYERS_SEGMENTS',

  CREATE_PLAYER_SEGMENT: 'CREATE_PLAYER_SEGMENT',
  DELETE_PLAYER_SEGMENT: 'DELETE_PLAYER_SEGMENT',
  EDIT_PLAYER_SEGMENT: 'EDIT_PLAYER_SEGMENT',
  UPDATE_PLAYER_SEGMENT: 'UPDATE_PLAYER_SEGMENT',

  DELETE_PLAYER_FROM_SEGMENT: 'DELETE_PLAYER_FROM_SEGMENT',

  GET_SEGMENTS_PLAYERS: 'GET_SEGMENTS_PLAYERS',
  SAVE_SEGMENTS_PLAYERS: 'SAVE_SEGMENTS_PLAYERS',
  RESET_SEGMENTS_PLAYERS: 'RESET_SEGMENTS_PLAYERS',

  DELETE_PLAYER_SEGMENT_PLAYER: 'DELETE_PLAYER_SEGMENT_PLAYER',

  GET_PLAYER_INCLUDED_SEGMENTS: 'GET_PLAYER_INCLUDED_SEGMENTS',
  SAVE_PLAYER_INCLUDED_SEGMENTS: 'SAVE_PLAYER_INCLUDED_SEGMENTS',
  RESET_PLAYER_INCLUDED_SEGMENTS: 'RESET_PLAYER_INCLUDED_SEGMENTS',

  GET_PLAYER_EXCLUDED_SEGMENTS: 'GET_PLAYER_EXCLUDED_SEGMENTS',
  SAVE_PLAYER_EXCLUDED_SEGMENTS: 'SAVE_PLAYER_EXCLUDED_SEGMENTS',
  RESET_PLAYER_EXCLUDED_SEGMENTS: 'RESET_PLAYER_EXCLUDED_SEGMENTS',

  GET_STATUS_UPDATED_PLAYERS_SEGMENTS: 'GET_STATUS_UPDATED_PLAYERS_SEGMENTS',
  SAVE_STATUS_UPDATED_PLAYERS_SEGMENTS: 'SAVE_STATUS_UPDATED_PLAYERS_SEGMENTS',
  RESET_STATUS_UPDATED_PLAYERS_SEGMENTS: 'RESET_STATUS_UPDATED_PLAYERS_SEGMENTS',

  // single add
  ADD_PLAYER_TO_SEGMENT: 'ADD_PLAYER_TO_SEGMENT',

  GET_PLAYER_SEGMENT: 'GET_PLAYER_SEGMENT',
  SAVE_PLAYER_SEGMENT: 'SAVE_PLAYER_SEGMENT',
  RESET_PLAYER_SEGMENT: 'RESET_PLAYER_SEGMENT',
};

export default PlayerSegmentActionType;
