const ThirdPartyServicesActionType = {
  GET_CUSTOM_SCRIPTS: 'GET_CUSTOM_SCRIPTS',
  SAVE_CUSTOM_SCRIPTS: 'SAVE_CUSTOM_SCRIPTS',
  RESET_CUSTOM_SCRIPTS: 'RESET_CUSTOM_SCRIPTS',

  ADD_CUSTOM_SCRIPT: 'ADD_CUSTOM_SCRIPT',
  EDIT_CUSTOM_SCRIPT: 'EDIT_CUSTOM_SCRIPT',
  DELETE_CUSTOM_SCRIPT: 'DELETE_CUSTOM_SCRIPT',
  CHANGE_CUSTOM_SCRIPT_STATUS: 'CHANGE_CUSTOM_SCRIPT_STATUS',

  GET_BRAND_AFFILIATE_SERVICES: 'GET_BRAND_AFFILIATE_SERVICES',
  SAVE_BRAND_AFFILIATE_SERVICES: 'SAVE_BRAND_AFFILIATE_SERVICES',
  RESET_BRAND_AFFILIATE_SERVICES: 'RESET_BRAND_AFFILIATE_SERVICES',

  ADD_BRAND_EVERY_MATRIX_AFFILIATE_SERVICE: 'ADD_BRAND_EVERY_MATRIX_AFFILIATE_SERVICE',
  DELETE_BRAND_EVERY_MATRIX_AFFILIATE_SERVICE: 'DELETE_BRAND_EVERY_MATRIX_AFFILIATE_SERVICE',

  ADD_BRAND_SMARTICO_AFFILIATE_SERVICE: 'ADD_BRAND_SMARTICO_AFFILIATE_SERVICE',
  DELETE_BRAND_SMARTICO_AFFILIATE_SERVICE: 'DELETE_BRAND_SMARTICO_AFFILIATE_SERVICE',

  ADD_BRAND_MY_AFFILIATES_AFFILIATE_SERVICE: 'ADD_BRAND_MY_AFFILIATES_AFFILIATE_SERVICE',
  DELETE_BRAND_MY_AFFILIATES_AFFILIATE_SERVICE: 'DELETE_BRAND_MY_AFFILIATES_AFFILIATE_SERVICE',

  GET_BRAND_ANALYTIC_SERVICES: 'GET_BRAND_ANALYTIC_SERVICES',
  SAVE_BRAND_ANALYTIC_SERVICES: 'SAVE_BRAND_ANALYTIC_SERVICES',
  RESET_BRAND_ANALYTIC_SERVICES: 'RESET_BRAND_ANALYTIC_SERVICES',

  GET_BRAND_HOTJAR_SERVICE: 'GET_BRAND_HOTJAR_SERVICE',
  EDIT_BRAND_HOTJAR_SERVICE: 'EDIT_BRAND_HOTJAR_SERVICE',
  ADD_BRAND_HOTJAR_SERVICE: 'ADD_BRAND_HOTJAR_SERVICE',
  DELETE_BRAND_HOTJAR_SERVICE: 'DELETE_BRAND_HOTJAR_SERVICE',
  CHANGE_BRAND_HOTJAR_SERVICE_STATUS: 'CHANGE_BRAND_HOTJAR_SERVICE_STATUS',

  GET_BRAND_GOOGLE_ANALYTICS_SERVICE: 'GET_BRAND_GOOGLE_ANALYTICS_SERVICE',
  EDIT_BRAND_GOOGLE_ANALYTICS_SERVICE: 'EDIT_BRAND_GOOGLE_ANALYTICS_SERVICE',
  ADD_BRAND_GOOGLE_ANALYTICS_SERVICE: 'ADD_BRAND_GOOGLE_ANALYTICS_SERVICE',
  DELETE_BRAND_GOOGLE_ANALYTICS_SERVICE: 'DELETE_BRAND_GOOGLE_ANALYTICS_SERVICE',
  CHANGE_BRAND_GOOGLE_ANALYTICS_SERVICE_STATUS: 'CHANGE_BRAND_GOOGLE_ANALYTICS_SERVICE_STATUS',

  GET_BRAND_GOOGLE_SEARCH_CONSOLE_SERVICE: 'GET_BRAND_GOOGLE_SEARCH_CONSOLE_SERVICE',
  EDIT_BRAND_GOOGLE_SEARCH_CONSOLE_SERVICE: 'EDIT_BRAND_GOOGLE_SEARCH_CONSOLE_SERVICE',
  ADD_BRAND_GOOGLE_SEARCH_CONSOLE_SERVICE: 'ADD_BRAND_GOOGLE_SEARCH_CONSOLE_SERVICE',
  DELETE_BRAND_GOOGLE_SEARCH_CONSOLE_SERVICE: 'DELETE_BRAND_GOOGLE_SEARCH_CONSOLE_SERVICE',
  CHANGE_BRAND_GOOGLE_SEARCH_CONSOLE_SERVICE_STATUS: 'CHANGE_BRAND_GOOGLE_SEARCH_CONSOLE_SERVICE_STATUS',

  SAVE_BRAND_ANALYTIC_SERVICE: 'SAVE_BRAND_ANALYTIC_SERVICE',
  RESET_BRAND_ANALYTIC_SERVICE: 'RESET_BRAND_ANALYTIC_SERVICE',

  GET_BRAND_LIVE_CHAT_SOLUTION_SERVICES: 'GET_BRAND_LIVE_CHAT_SOLUTION_SERVICES',
  SAVE_BRAND_LIVE_CHAT_SOLUTION_SERVICES: 'SAVE_BRAND_LIVE_CHAT_SOLUTION_SERVICES',
  RESET_BRAND_LIVE_CHAT_SOLUTION_SERVICES: 'RESET_BRAND_LIVE_CHAT_SOLUTION_SERVICES',

  GET_BRAND_LIVE_CHAT_SERVICE: 'GET_BRAND_LIVE_CHAT_SERVICE',
  EDIT_BRAND_LIVE_CHAT_SERVICE: 'EDIT_BRAND_LIVE_CHAT_SERVICE',
  ADD_BRAND_LIVE_CHAT_SERVICE: 'ADD_BRAND_LIVE_CHAT_SERVICE',
  DELETE_BRAND_LIVE_CHAT_SERVICE: 'DELETE_BRAND_LIVE_CHAT_SERVICE',
  CHANGE_BRAND_LIVE_CHAT_SERVICE_STATUS: 'CHANGE_BRAND_LIVE_CHAT_SERVICE_STATUS',

  GET_BRAND_LIVE_AGENT_SERVICE: 'GET_BRAND_LIVE_AGENT_SERVICE',
  EDIT_BRAND_LIVE_AGENT_SERVICE: 'EDIT_BRAND_LIVE_AGENT_SERVICE',
  ADD_BRAND_LIVE_AGENT_SERVICE: 'ADD_BRAND_LIVE_AGENT_SERVICE',
  DELETE_BRAND_LIVE_AGENT_SERVICE: 'DELETE_BRAND_LIVE_AGENT_SERVICE',
  CHANGE_BRAND_LIVE_AGENT_SERVICE_STATUS: 'CHANGE_BRAND_LIVE_AGENT_SERVICE_STATUS',

  GET_BRAND_ZENDESK_SERVICE: 'GET_BRAND_ZENDESK_SERVICE',
  EDIT_BRAND_ZENDESK_SERVICE: 'EDIT_BRAND_ZENDESK_SERVICE',
  ADD_BRAND_ZENDESK_SERVICE: 'ADD_BRAND_ZENDESK_SERVICE',
  DELETE_BRAND_ZENDESK_SERVICE: 'DELETE_BRAND_ZENDESK_SERVICE',
  CHANGE_BRAND_ZENDESK_SERVICE_STATUS: 'CHANGE_BRAND_ZENDESK_SERVICE_STATUS',

  GET_BRAND_INTERCOM_SERVICE: 'GET_BRAND_INTERCOM_SERVICE',
  EDIT_BRAND_INTERCOM_SERVICE: 'EDIT_BRAND_INTERCOM_SERVICE',
  ADD_BRAND_INTERCOM_SERVICE: 'ADD_BRAND_INTERCOM_SERVICE',
  DELETE_BRAND_INTERCOM_SERVICE: 'DELETE_BRAND_INTERCOM_SERVICE',
  CHANGE_BRAND_INTERCOM_SERVICE_STATUS: 'CHANGE_BRAND_INTERCOM_SERVICE_STATUS',

  SAVE_BRAND_LIVE_CHAT_SOLUTION_SERVICE: 'SAVE_BRAND_LIVE_CHAT_SOLUTION_SERVICE',
  RESET_BRAND_LIVE_CHAT_SOLUTION_SERVICE: 'RESET_BRAND_LIVE_CHAT_SOLUTION_SERVICE',

  GET_BRAND_AG_SOLUTION_SERVICES: 'GET_BRAND_AG_SOLUTION_SERVICES',
  SAVE_BRAND_AG_SOLUTION_SERVICES: 'SAVE_BRAND_AG_SOLUTION_SERVICES',
  RESET_BRAND_AG_SOLUTION_SERVICES: 'RESET_BRAND_AG_SOLUTION_SERVICES',

  SAVE_BRAND_AG_SOLUTION_SERVICE: 'SAVE_BRAND_AG_SOLUTION_SERVICE',
  RESET_BRAND_AG_SOLUTION_SERVICE: 'RESET_BRAND_AG_SOLUTION_SERVICE',

  ADD_BRAND_EVERY_MATRIX_AG_SERVICE: 'ADD_BRAND_EVERY_MATRIX_AG_SERVICE',
  DELETE_BRAND_EVERY_MATRIX_AG_SERVICE: 'DELETE_BRAND_EVERY_MATRIX_AG_SERVICE',

  GET_BRAND_DIGITAIN_AG_SERVICE: 'GET_BRAND_DIGITAIN_AG_SERVICE',
  ADD_BRAND_DIGITAIN_AG_SERVICE: 'ADD_BRAND_DIGITAIN_AG_SERVICE',
  EDIT_BRAND_DIGITAIN_AG_SERVICE: 'EDIT_BRAND_DIGITAIN_AG_SERVICE',
  DELETE_BRAND_DIGITAIN_AG_SERVICE: 'DELETE_BRAND_DIGITAIN_AG_SERVICE',
  GET_BRAND_DIGITAIN_AG_SERVICE_PAYMENT_LIMITS: 'GET_BRAND_DIGITAIN_AG_SERVICE_PAYMENT_LIMITS',
  SAVE_BRAND_DIGITAIN_AG_SERVICE_PAYMENT_LIMITS: 'SAVE_BRAND_DIGITAIN_AG_SERVICE_PAYMENT_LIMITS',
  RESET_BRAND_DIGITAIN_AG_SERVICE_PAYMENT_LIMITS: 'RESET_BRAND_DIGITAIN_AG_SERVICE_PAYMENT_LIMITS',
  CONFIGURE_BRAND_DIGITAIN_AG_SERVICE_PAYMENT_LIMITS: 'CONFIGURE_BRAND_DIGITAIN_AG_SERVICE_PAYMENT_LIMITS',

  GET_BRAND_RETAIL_SOLUTION_SERVICES: 'GET_BRAND_RETAIL_SOLUTION_SERVICES',
  SAVE_BRAND_RETAIL_SOLUTION_SERVICES: 'SAVE_BRAND_RETAIL_SOLUTION_SERVICES',
  RESET_BRAND_RETAIL_SOLUTION_SERVICES: 'RESET_BRAND_RETAIL_SOLUTION_SERVICES',

  SAVE_BRAND_RETAIL_SOLUTION_SERVICE: 'SAVE_BRAND_RETAIL_SOLUTION_SERVICE',
  RESET_BRAND_RETAIL_SOLUTION_SERVICE: 'RESET_BRAND_RETAIL_SOLUTION_SERVICE',

  GET_BRAND_DIGITAIN_RETAIL_SERVICE: 'GET_BRAND_DIGITAIN_RETAIL_SERVICE',
  ADD_BRAND_DIGITAIN_RETAIL_SERVICE: 'ADD_BRAND_DIGITAIN_RETAIL_SERVICE',
  EDIT_BRAND_DIGITAIN_RETAIL_SERVICE: 'EDIT_BRAND_DIGITAIN_RETAIL_SERVICE',
  DELETE_BRAND_DIGITAIN_RETAIL_SERVICE: 'DELETE_BRAND_DIGITAIN_RETAIL_SERVICE',

  GET_BRAND_CRM_SERVICES: 'GET_BRAND_CRM_SERVICES',
  SAVE_BRAND_CRM_SERVICES: 'SAVE_BRAND_CRM_SERVICES',
  RESET_BRAND_CRM_SERVICES: 'RESET_BRAND_CRM_SERVICES',

  GET_BRAND_SMARTICO_CRM_SERVICE: 'GET_BRAND_SMARTICO_CRM_SERVICE',
  ADD_BRAND_SMARTICO_CRM_SERVICE: 'ADD_BRAND_SMARTICO_CRM_SERVICE',
  EDIT_BRAND_SMARTICO_CRM_SERVICE: 'EDIT_BRAND_SMARTICO_CRM_SERVICE',
  DELETE_BRAND_SMARTICO_CRM_SERVICE: 'DELETE_BRAND_SMARTICO_CRM_SERVICE',
  CHANGE_BRAND_SMARTICO_CRM_SERVICE_STATUS: 'CHANGE_BRAND_SMARTICO_CRM_SERVICE_STATUS',

  SAVE_BRAND_CRM_SERVICE: 'SAVE_BRAND_CRM_SERVICE',
  RESET_BRAND_CRM_SERVICE: 'RESET_BRAND_CRM_SERVICE',

  GET_BRAND_GAMIFICATION_SERVICES: 'GET_BRAND_GAMIFICATION_SERVICES',
  SAVE_BRAND_GAMIFICATION_SERVICES: 'SAVE_BRAND_GAMIFICATION_SERVICES',
  RESET_BRAND_GAMIFICATION_SERVICES: 'RESET_BRAND_GAMIFICATION_SERVICES',

  GET_BRAND_SMARTICO_GAMIFICATION_SERVICE: 'GET_BRAND_SMARTICO_GAMIFICATION_SERVICE',
  ADD_BRAND_SMARTICO_GAMIFICATION_SERVICE: 'ADD_BRAND_SMARTICO_GAMIFICATION_SERVICE',
  EDIT_BRAND_SMARTICO_GAMIFICATION_SERVICE: 'EDIT_BRAND_SMARTICO_GAMIFICATION_SERVICE',
  DELETE_BRAND_SMARTICO_GAMIFICATION_SERVICE: 'DELETE_BRAND_SMARTICO_GAMIFICATION_SERVICE',
  CHANGE_BRAND_SMARTICO_GAMIFICATION_SERVICE_STATUS: 'CHANGE_BRAND_SMARTICO_GAMIFICATION_SERVICE_STATUS',

  GET_BRAND_THUNDERBITE_GAMIFICATION_SERVICE: 'GET_BRAND_THUNDERBITE_GAMIFICATION_SERVICE',
  ADD_BRAND_THUNDERBITE_GAMIFICATION_SERVICE: 'ADD_BRAND_THUNDERBITE_GAMIFICATION_SERVICE',
  EDIT_BRAND_THUNDERBITE_GAMIFICATION_SERVICE: 'EDIT_BRAND_THUNDERBITE_GAMIFICATION_SERVICE',
  DELETE_BRAND_THUNDERBITE_GAMIFICATION_SERVICE: 'DELETE_BRAND_THUNDERBITE_GAMIFICATION_SERVICE',
  CHANGE_BRAND_THUNDERBITE_GAMIFICATION_SERVICE_STATUS: 'CHANGE_BRAND_THUNDERBITE_GAMIFICATION_SERVICE_STATUS',

  SAVE_BRAND_GAMIFICATION_SERVICE: 'SAVE_BRAND_GAMIFICATION_SERVICE',
  RESET_BRAND_GAMIFICATION_SERVICE: 'RESET_BRAND_GAMIFICATION_SERVICE',

  GET_BRAND_WHATSAPP_SERVICE: 'GET_BRAND_WHATSAPP_SERVICE',
  EDIT_BRAND_WHATSAPP_SERVICE: 'EDIT_BRAND_WHATSAPP_SERVICE',
  ADD_BRAND_WHATSAPP_SERVICE: 'ADD_BRAND_WHATSAPP_SERVICE',
  DELETE_BRAND_WHATSAPP_SERVICE: 'DELETE_BRAND_WHATSAPP_SERVICE',
  CHANGE_BRAND_WHATSAPP_SERVICE_STATUS: 'CHANGE_BRAND_WHATSAPP_SERVICE_STATUS',

  ADD_BRAND_DIGITAIN_CRM_SERVICE: 'ADD_BRAND_DIGITAIN_CRM_SERVICE',
  DELETE_BRAND_DIGITAIN_CRM_SERVICE: 'DELETE_BRAND_DIGITAIN_CRM_SERVICE',

  GET_BRAND_DIGITAIN_AFFILIATE_SERVICE: 'GET_BRAND_DIGITAIN_AFFILIATE_SERVICE',
  ADD_BRAND_DIGITAIN_AFFILIATE_SERVICE: 'ADD_BRAND_DIGITAIN_AFFILIATE_SERVICE',
  EDIT_BRAND_DIGITAIN_AFFILIATE_SERVICE: 'EDIT_BRAND_DIGITAIN_AFFILIATE_SERVICE',
  DELETE_BRAND_DIGITAIN_AFFILIATE_SERVICE: 'DELETE_BRAND_DIGITAIN_AFFILIATE_SERVICE',

  GET_BRAND_ALANBASE_SERVICE: 'GET_BRAND_ALANBASE_SERVICE',
  ADD_BRAND_ALANBASE_SERVICE: 'ADD_BRAND_ALANBASE_SERVICE',
  EDIT_BRAND_ALANBASE_SERVICE: 'EDIT_BRAND_ALANBASE_SERVICE',
  DELETE_BRAND_ALANBASE_SERVICE: 'DELETE_BRAND_ALANBASE_SERVICE',

  SAVE_BRAND_AFFILIATE_SERVICE: 'SAVE_BRAND_AFFILIATE_SERVICE',
  RESET_BRAND_AFFILIATE_SERVICE: 'RESET_BRAND_AFFILIATE_SERVICE',

  GET_BRAND_KYC_SERVICES: 'GET_BRAND_KYC_SERVICES',
  SAVE_BRAND_KYC_SERVICES: 'SAVE_BRAND_KYC_SERVICES',
  RESET_BRAND_KYC_SERVICES: 'RESET_BRAND_KYC_SERVICES',

  SAVE_BRAND_KYC_SERVICE: 'SAVE_BRAND_KYC_SERVICE',
  RESET_BRAND_KYC_SERVICE: 'RESET_BRAND_KYC_SERVICE',

  GET_BRAND_METAMAP_SERVICE: 'GET_BRAND_METAMAP_SERVICE',
  ADD_BRAND_METAMAP_SERVICE: 'ADD_BRAND_METAMAP_SERVICE',
  EDIT_BRAND_METAMAP_SERVICE: 'EDIT_BRAND_METAMAP_SERVICE',
  DELETE_BRAND_METAMAP_SERVICE: 'DELETE_BRAND_METAMAP_SERVICE',
  CHANGE_BRAND_METAMAP_SERVICE_STATUS: 'CHANGE_BRAND_METAMAP_SERVICE_STATUS',
};

export default ThirdPartyServicesActionType;
