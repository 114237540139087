import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Select } from 'antd';
import { StyledIconDefault } from 'components/styledComponents/icon/Icon.styled';
import IconSvg from 'components/common/icon/IconSvg';
import AdminMultiSelect from 'components/common/form/AdminSelect/AdminMultiSelect';
import AdminSingleSelect from 'components/common/form/AdminSelect/AdminSingleSelect';

const { Option } = Select;

const AdminSelect = (props) => {
  const {
    isMultiple, loading, isToString, ...rest
  } = props;

  const { t } = useTranslation();

  const commonProps = {
    placeholder: t('all'),
    size: 'middle',
    loading,
    allowClear: true,
    showSearch: true,
    disabled: loading,
    suffixIcon: !loading ? (
      <StyledIconDefault>
        <IconSvg icon="DropdownIcon" />
      </StyledIconDefault>
    ) : undefined,
    optionFilterProp: 'children',
    renderOptions: (options) => options.map((el) => (
      <Option key={el.id} value={isToString ? el.id.toString() : el.id} label={el?.label} disabled={el.disabled} className={el.className ?? ''}>
        {el.displayName || el.name}
      </Option>
    )),
    ...rest,
  };

  return isMultiple ? <AdminMultiSelect isToString={isToString} {...commonProps} /> : <AdminSingleSelect {...commonProps} />;
};

AdminSelect.propTypes = {
  isMultiple: PropTypes.bool,
  loading: PropTypes.bool,
  isToString: PropTypes.bool,
};

AdminSelect.defaultProps = {
  isMultiple: false,
  loading: false,
  isToString: true,
};

export default AdminSelect;
