import PermissionActionType from 'actions/permission/permissionActionType';

const initialState = {
  loading: false,
  permissions: [],
  resources: [],
  permissionsResource: [],
  userManagementResource: [],
  allActionResource: [],
};

const permissionReducer = (state = initialState, { type, payload }) => {
  switch (type) {
  case PermissionActionType.GET_PERMISSIONS_SUCCESS:
    return {
      ...state,
      permissions: payload.data,
    };
  case PermissionActionType.GET_RESOURCES_SUCCESS:
    return {
      ...state,
      resources: payload.data,
    };
  case PermissionActionType.RESET_PERMISSIONS:
    return {
      ...state,
      permissions: [],
    };
  case PermissionActionType.CHANGE_PERMISSION_LOADING_STATE:
    return {
      ...state,
      loading: payload.state,
    };
  case PermissionActionType.SAVE_PERMISSIONS_RESOURCE:
    return {
      ...state,
      permissionsResource: payload.data,
    };
  case PermissionActionType.RESET_PERMISSIONS_RESOURCE:
    return {
      ...state,
      permissionsResource: [],
    };
  case PermissionActionType.SAVE_USER_MANAGEMENT_RESOURCE:
    return {
      ...state,
      userManagementResource: payload.data,
    };
  case PermissionActionType.RESET_USER_MANAGEMENT_RESOURCE:
    return {
      ...state,
      userManagementResource: [],
    };
  case PermissionActionType.SAVE_ALL_ACTION_RESOURCE:
    return {
      ...state,
      allActionResource: payload.data,
    };
  case PermissionActionType.RESET_ALL_ACTION_RESOURCE:
    return {
      ...state,
      allActionResource: [],
    };
  default:
    return state;
  }
};

export default permissionReducer;
