import styled from 'styled-components';
import { StyledVerticalTabs } from 'components/styledComponents/tabs/Tabs.styled';
import { StyledButtonGrayIcon } from 'components/styledComponents/button/Button.styled';
import { stickyContainer } from 'utils/styledHelpers';

const StyledSeoFullHeight = styled.div`
  height: 100%;
`;
const StyledTheadWrapper = styled.div`
  display: flex;
  align-items: center;
  span {
    margin-right: 0.57rem;
  }
`;
const StyledResetButton = styled(StyledButtonGrayIcon)`
  margin: 1.14rem 0 0 auto;
`;
const StyledButtonsWrapper = styled.div`
  ${stickyContainer};
  background: ${(props) => props.theme.colors.selectBg};
  margin-top: auto;
  padding: 0 2.85rem 0 1.71rem;
  width: calc(100% + 5.7rem);
  bottom: 0;
  transform: translateX(-2.85rem);
  &:last-child .ant-btn {
    margin: 0 0 0 1.14rem;
  }
`;
const StyledSeoVerticalTabs = styled(StyledVerticalTabs)`
  margin: 0;
  .ant-tabs-content-holder {
    padding: 0;

    .ant-table {
      .ant-table-content {
        border-radius: 0;
        table {
          table-layout: fixed !important;
        }
      }
      &-middle .ant-table-tbody td.ant-table-row-expand-icon-cell,
      &-middle .ant-table-tbody td.ant-table-row-expand-icon-cell + td {
        padding: 0.7rem 0;
      }
      .ant-form-item-control-input,
      .ant-form-item-control-input-content {
        height: auto;
      }
    }
  }
`;

export {
  StyledSeoFullHeight, StyledTheadWrapper, StyledResetButton, StyledButtonsWrapper, StyledSeoVerticalTabs,
};
