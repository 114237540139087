import ReferralActionType from 'actions/referral/referralActionType';

const initialState = {
  isAllPlayers: false,
  existingPlayers: [],
  referrals: [],
  total: 0,
  referral: {},
  accumulated: [],
  accumulatedTotal: 0,
  transferred: [],
  transferredTotal: 0,
  failed: [],
  failedTotal: 0,
  referees: [],
  refereesTotal: 0,
  participants: [],
  participantsTotal: 0,
  playerReferralReferees: [],
  playerReferralRefereesTotal: 0,
  playerReferrals: [],
  playerReferralsTotal: 0,
  allParticipants: [],
  playerReferralPeriods: {},
  referralAccumulatedPeriods: {},
  referralTransferredPeriods: {},
  referralFailedPeriods: {},
};

const referralReducer = (state = initialState, { type, payload }) => {
  switch (type) {
  case ReferralActionType.SAVE_EXISTING_PLAYERS:
    return {
      ...state,
      isAllPlayers: payload.data.allPlayers,
      existingPlayers: payload.data.playerIds,
    };
  case ReferralActionType.RESET_EXISTING_PLAYERS:
    return {
      ...state,
      isAllPlayers: false,
      existingPlayers: [],
    };
  case ReferralActionType.SAVE_REFERRALS:
    return {
      ...state,
      referrals: payload.data.data,
      total: payload.data.total,
    };
  case ReferralActionType.RESET_REFERRALS:
    return {
      ...state,
      referrals: [],
      total: 0,
    };
  case ReferralActionType.SAVE_REFERRAL:
    return {
      ...state,
      referral: payload.data,
    };
  case ReferralActionType.RESET_REFERRAL:
    return {
      ...state,
      referral: {},
    };
  case ReferralActionType.SAVE_REFERRAL_ACCUMULATED:
    return {
      ...state,
      accumulated: payload.data.data,
      accumulatedTotal: payload.data.total,
    };
  case ReferralActionType.SAVE_REFERRAL_TRANSFERRED:
    return {
      ...state,
      transferred: payload.data.data,
      transferredTotal: payload.data.total,
    };
  case ReferralActionType.SAVE_REFERRAL_FAILED:
    return {
      ...state,
      failed: payload.data.data,
      failedTotal: payload.data.total,
    };
  case ReferralActionType.RESET_REFERRAL_ACCUMULATED:
    return {
      ...state,
      accumulated: [],
      accumulatedTotal: 0,
    };
  case ReferralActionType.RESET_REFERRAL_TRANSFERRED:
    return {
      ...state,
      transferred: [],
      transferredTotal: 0,
    };
  case ReferralActionType.RESET_REFERRAL_FAILED:
    return {
      ...state,
      failed: [],
      failedTotal: 0,
    };
  case ReferralActionType.SAVE_REFERRAL_REFEREES:
    return {
      ...state,
      referees: payload.data.data,
      refereesTotal: payload.data.total,
    };
  case ReferralActionType.RESET_REFERRAL_REFEREES:
    return {
      ...state,
      referees: [],
      refereesTotal: 0,
    };
  case ReferralActionType.SAVE_REFERRAL_PARTICIPANTS:
    return {
      ...state,
      participants: payload.data.data,
      participantsTotal: payload.data.total,
    };
  case ReferralActionType.RESET_REFERRAL_PARTICIPANTS:
    return {
      ...state,
      participants: [],
      participantsTotal: 0,
    };
  case ReferralActionType.SAVE_PLAYER_REFERRAL_REFEREES:
    return {
      ...state,
      playerReferralReferees: payload.data.data,
      playerReferralRefereesTotal: payload.data.total,
    };
  case ReferralActionType.RESET_PLAYER_REFERRAL_REFEREES:
    return {
      ...state,
      playerReferralReferees: [],
      playerReferralRefereesTotal: 0,
    };
  case ReferralActionType.SAVE_PLAYER_REFERRALS:
    return {
      ...state,
      playerReferrals: payload.data.data,
      playerReferralsTotal: payload.data.total,
    };
  case ReferralActionType.RESET_PLAYER_REFERRALS:
    return {
      ...state,
      playerReferrals: [],
      playerReferralsTotal: 0,
    };
  case ReferralActionType.SAVE_REFERRAL_ALL_PARTICIPANTS:
    return {
      ...state,
      allParticipants: payload.data,
    };
  case ReferralActionType.RESET_REFERRAL_ALL_PARTICIPANTS:
    return {
      ...state,
      allParticipants: [],
    };
  case ReferralActionType.SAVE_PLAYER_REFERRAL_PERIODS:
    return {
      ...state,
      playerReferralPeriods: payload.data,
    };
  case ReferralActionType.RESET_PLAYER_REFERRAL_PERIODS:
    return {
      ...state,
      playerReferralPeriods: {},
    };
  case ReferralActionType.SAVE_REFERRAL_ACCUMULATED_PERIODS:
    return {
      ...state,
      referralAccumulatedPeriods: payload.data,
    };
  case ReferralActionType.RESET_REFERRAL_ACCUMULATED_PERIODS:
    return {
      ...state,
      referralAccumulatedPeriods: {},
    };
  case ReferralActionType.SAVE_REFERRAL_TRANSFERRED_PERIODS:
    return {
      ...state,
      referralTransferredPeriods: payload.data,
    };
  case ReferralActionType.RESET_REFERRAL_TRANSFERRED_PERIODS:
    return {
      ...state,
      referralTransferredPeriods: {},
    };
  case ReferralActionType.SAVE_REFERRAL_FAILED_PERIODS:
    return {
      ...state,
      referralFailedPeriods: payload.data,
    };
  case ReferralActionType.RESET_REFERRAL_FAILED_PERIODS:
    return {
      ...state,
      referralFailedPeriods: {},
    };
  default:
    return state;
  }
};

export default referralReducer;
