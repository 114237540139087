import { connect } from 'react-redux';
import { selectCurrentPartner } from 'selectors';
import { Banner } from 'components/brand';

const mapStateToProps = ({ partners, auth }) => ({
  partner: selectCurrentPartner({ partners, auth }),
});

export default connect(
  mapStateToProps,
  null,
)(Banner);
