import { createSelector } from 'reselect';
import { selectUserBrandsByPartner } from './userSelector';

export const selectBrand = (state) => state.brand;
export const selectBrands = (state) => state.brands;
export const selectBrandLoading = (state) => state.loading;
export const selectCurrentBrandId = (state) => state.currentBrandId;
export const selectBrandName = (state) => selectBrand(state).name;
export const selectCurrentBrandGuid = (state) => createSelector(
  () => selectUserBrandsByPartner(state.users),
  () => selectCurrentBrandId(state.brands),
  (partnerBrands, id) => {
    const currentBrand = partnerBrands.find((brand) => brand.id === id);
    return currentBrand ? currentBrand.brandGuid : '';
  },
)();
export const selectBrandMarkets = (state) => state.markets;
export const selectIsBrandExist = (state) => state.isBrandExist;
export const selectBrandTemplates = (state) => state.templates;
export const selectBrandCallEndedStatus = (state) => state.callEndedStatus;
export const selectBrandSportsBookState = (state) => selectBrand(state).isSport;
