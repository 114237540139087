import NotificationActionType from './notificationActionType';

export const saveMassMessages = (data) => ({
  type: NotificationActionType.SAVE_MASS_MESSAGES,
  payload: { data },
});

export const updateMassMessage = (data) => ({
  type: NotificationActionType.UPDATE_MASS_MESSAGE,
  payload: { data },
});

export const moveToDraft = (id) => ({
  type: NotificationActionType.MOVE_TO_DRAFT,
  payload: { id },
});

export const changeNotificationSentStatus = (status) => ({
  type: NotificationActionType.CHANGE_NOTIFICATION_SENT_STATUS,
  payload: { status },
});

export const getCustomTemplateTagsResource = (params) => ({
  type: NotificationActionType.GET_CUSTOM_TEMPLATE_TAGS_RESOURCE,
  payload: { params },
});

export const getSystemTemplateTagsResource = (brandId, typeId, params) => ({
  type: NotificationActionType.GET_SYSTEM_TEMPLATE_TAGS_RESOURCE,
  payload: { brandId, typeId, params },
});

export const saveTemplateTagsResource = (data) => ({
  type: NotificationActionType.SAVE_TEMPLATE_TAGS_RESOURCE,
  payload: { data },
});

export const resetTemplateTagsResource = () => ({
  type: NotificationActionType.RESET_TEMPLATE_TAGS_RESOURCE,
});

export const getSentMassMessages = (data) => ({
  type: NotificationActionType.GET_SENT_MASS_MESSAGES,
  payload: { data },
});

export const getScheduledMassMessages = (data) => ({
  type: NotificationActionType.GET_SCHEDULED_MASS_MESSAGES,
  payload: { data },
});

export const getDraftMassMessages = (data) => ({
  type: NotificationActionType.GET_DRAFT_MASS_MESSAGES,
  payload: { data },
});

export const getMassMessageRecipients = (data) => ({
  type: NotificationActionType.GET_MASS_MESSAGE_RECIPIENTS,
  payload: { data },
});

export const saveMassMessageRecipients = (data) => ({
  type: NotificationActionType.SAVE_MASS_MESSAGE_RECIPIENTS,
  payload: { data },
});

export const resetMassMessageRecipients = () => ({
  type: NotificationActionType.RESET_MASS_MESSAGE_RECIPIENTS,
});

export const deleteMassMessage = (id, name) => ({
  type: NotificationActionType.DELETE_MASS_MESSAGE,
  payload: { id, name },
});

export const saveMassMessagesState = (data) => ({
  type: NotificationActionType.SAVE_MASS_MESSAGES_STATE,
  payload: { data },
});

export const saveMessagesTotal = (data) => ({
  type: NotificationActionType.SAVE_MESSAGES_TOTAL,
  payload: { data },
});

export const getMassMessage = (id) => ({
  type: NotificationActionType.GET_MASS_MESSAGE,
  payload: { id },
});

export const getMassMessageSuccess = (data) => ({
  type: NotificationActionType.GET_MASS_MESSAGE_SUCCESS,
  payload: { data },
});

export const resetMassMessage = () => ({
  type: NotificationActionType.RESET_MASS_MESSAGE,
});

export const getInboxMessages = (id, data) => ({
  type: NotificationActionType.GET_INBOX_MESSAGES,
  payload: { id, data },
});

export const saveInboxMessages = (data) => ({
  type: NotificationActionType.SAVE_INBOX_MESSAGES,
  payload: { data },
});

export const resetInboxMessages = () => ({
  type: NotificationActionType.RESET_INBOX_MESSAGES,
});

export const getInboxMessage = (id, isPlayer) => ({
  type: NotificationActionType.GET_INBOX_MESSAGE,
  payload: { id, isPlayer },
});

export const changeInboxMessageReplyState = (id, canReply, isPlayer) => ({
  type: NotificationActionType.CHANGE_INBOX_MESSAGE_REPLY_STATE,
  payload: { id, canReply, isPlayer },
});

export const replyInboxMessage = (id, data, isPlayer) => ({
  type: NotificationActionType.REPLY_INBOX_MESSAGE,
  payload: { id, data, isPlayer },
});

export const recallInboxMessage = (id, index, isPlayer) => ({
  type: NotificationActionType.RECALL_INBOX_MESSAGE,
  payload: { id, index, isPlayer },
});

export const markInboxMessageAsRead = (id, isPlayer) => ({
  type: NotificationActionType.MARK_INBOX_MESSAGE_AS_READ,
  payload: { id, isPlayer },
});

export const checkTitleExists = (brandId, params, id) => ({
  type: NotificationActionType.CHECK_TITLE_EXISTS,
  payload: { brandId, params, id },
});

export const saveTitleExists = (data) => ({
  type: NotificationActionType.SAVE_TITLE_EXISTS,
  payload: { data },
});

export const getBonusNotifications = (id, params) => ({
  type: NotificationActionType.GET_BONUS_NOTIFICATIONS,
  payload: { id, params },
});

export const saveBonusNotifications = (data) => ({
  type: NotificationActionType.SAVE_BONUS_NOTIFICATIONS,
  payload: { data },
});

export const resetBonusNotifications = () => ({
  type: NotificationActionType.RESET_BONUS_NOTIFICATIONS,
});

export const getBonusNotification = (playerId, messageId) => ({
  type: NotificationActionType.GET_BONUS_NOTIFICATION,
  payload: { messageId, playerId },
});

export const saveBonusNotification = (data) => ({
  type: NotificationActionType.SAVE_BONUS_NOTIFICATION,
  payload: { data },
});

export const resetBonusNotification = () => ({
  type: NotificationActionType.RESET_BONUS_NOTIFICATION,
});
