import TaxesActionType from './taxesActionType';

export const getDepositTaxes = (brandId) => ({
  type: TaxesActionType.GET_DEPOSIT_TAXES,
  payload: { brandId },
});

export const saveDepositTaxes = (data) => ({
  type: TaxesActionType.SAVE_DEPOSIT_TAXES,
  payload: { data },
});

export const resetDepositTaxes = () => ({
  type: TaxesActionType.RESET_DEPOSIT_TAXES,
});

export const getDepositTax = (brandId, currencyId) => ({
  type: TaxesActionType.GET_DEPOSIT_TAX,
  payload: { brandId, currencyId },
});

export const saveDepositTax = (data) => ({
  type: TaxesActionType.SAVE_DEPOSIT_TAX,
  payload: { data },
});

export const resetDepositTax = () => ({
  type: TaxesActionType.RESET_DEPOSIT_TAX,
});

export const editDepositTax = (brandId, currencyId, data) => ({
  type: TaxesActionType.EDIT_DEPOSIT_TAX,
  payload: { brandId, currencyId, data },
});

export const getWithdrawalTaxes = (brandId) => ({
  type: TaxesActionType.GET_WITHDRAWAL_TAXES,
  payload: { brandId },
});

export const saveWithdrawalTaxes = (data) => ({
  type: TaxesActionType.SAVE_WITHDRAWAL_TAXES,
  payload: { data },
});

export const resetWithdrawalTaxes = () => ({
  type: TaxesActionType.RESET_WITHDRAWAL_TAXES,
});

export const getWithdrawalTax = (brandId, currencyId) => ({
  type: TaxesActionType.GET_WITHDRAWAL_TAX,
  payload: { brandId, currencyId },
});

export const saveWithdrawalTax = (data) => ({
  type: TaxesActionType.SAVE_WITHDRAWAL_TAX,
  payload: { data },
});

export const resetWithdrawalTax = () => ({
  type: TaxesActionType.RESET_WITHDRAWAL_TAX,
});

export const editWithdrawalTax = (brandId, currencyId, data) => ({
  type: TaxesActionType.EDIT_WITHDRAWAL_TAX,
  payload: { brandId, currencyId, data },
});

export const getTaxSetting = (brandId) => ({
  type: TaxesActionType.GET_TAX_SETTING,
  payload: { brandId },
});

export const saveTaxSetting = (data) => ({
  type: TaxesActionType.SAVE_TAX_SETTING,
  payload: { data },
});

export const resetTaxSetting = () => ({
  type: TaxesActionType.RESET_TAX_SETTING,
});

export const addTaxSetting = (brandId, data) => ({
  type: TaxesActionType.ADD_TAX_SETTING,
  payload: { brandId, data },
});
