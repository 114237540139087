const SystemConversionActionType = {
  GET_SYSTEM_CONVERSIONS: 'GET_SYSTEM_CONVERSIONS',
  SAVE_SYSTEM_CONVERSIONS: 'SAVE_SYSTEM_CONVERSIONS',
  RESET_SYSTEM_CONVERSIONS: 'RESET_SYSTEM_CONVERSIONS',

  IMPORT_SYSTEM_CONVERSIONS: 'IMPORT_SYSTEM_CONVERSIONS',
  EXPORT_SYSTEM_CONVERSIONS: 'EXPORT_SYSTEM_CONVERSIONS',

  EDIT_SYSTEM_CONVERSION: 'EDIT_SYSTEM_CONVERSION',

  GET_SYSTEM_CONVERSION_LOGS: 'GET_SYSTEM_CONVERSION_LOGS',
  SAVE_SYSTEM_CONVERSION_LOGS: 'SAVE_SYSTEM_CONVERSION_LOGS',
  RESET_SYSTEM_CONVERSION_LOGS: 'RESET_SYSTEM_CONVERSION_LOGS',

  SET_CURRENT_CONVERSION: 'SET_CURRENT_CONVERSION',

  GET_SYSTEM_CONVERSIONS_RESOURCE: 'GET_SYSTEM_CONVERSIONS_RESOURCE',
  SAVE_SYSTEM_CONVERSIONS_RESOURCE: 'SAVE_SYSTEM_CONVERSIONS_RESOURCE',
  RESET_SYSTEM_CONVERSIONS_RESOURCE: 'RESET_SYSTEM_CONVERSIONS_RESOURCE',
};

export default SystemConversionActionType;
