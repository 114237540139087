import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Brands } from 'components/layout/header';
import { setCurrentBrand } from 'actions/brand/brandActionCreator';
import {
  selectAuthUser, selectUserBrandsByPartner, selectCurrentBrandId, selectResourceBrand,
} from 'selectors';
import { getUserBrandsByPartner } from 'actions/user/userActionCreator';
import { getResourceBrand } from 'actions/resource/resourceActionCreator';

const mapDispatchToProps = (dispatch) => bindActionCreators(
  {
    setCurrentBrand,
    getUserBrandsByPartner,
    getResourceBrand,
  },
  dispatch,
);

const mapStateToProps = ({
  auth, users, brands, resource,
}) => ({
  user: selectAuthUser(auth),
  brand: selectResourceBrand(resource),
  partnerBrands: selectUserBrandsByPartner(users),
  brandId: selectCurrentBrandId(brands),
});

export default connect(mapStateToProps, mapDispatchToProps)(Brands);
