import PropTypes from 'prop-types';
import { withTheme } from 'styled-components';
import _ from 'lodash';
import { StyledSvgIcon } from 'components/styledComponents/icon/Icon.styled';
import * as icons from './Icons';

const IconSvg = (props) => {
  const {
    icon,
    color,
    width,
    height,
    theme: { colors },
  } = props;

  const colorProp = _.isUndefined(color) ? colors.secondaryColor : color;

  const IconComponent = icons[icon];
  return IconComponent ? (
    <StyledSvgIcon color={color}>
      <IconComponent width={width} height={height} color={colorProp} />
    </StyledSvgIcon>
  ) : null;
};

IconSvg.propTypes = {
  icon: PropTypes.string.isRequired,
  theme: PropTypes.object,
  color: PropTypes.string,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

IconSvg.defaultProps = {
  color: undefined,
  width: '1.29rem',
  height: '1.29rem',
  theme: {},
};

export default withTheme(IconSvg);
