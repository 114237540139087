export const selectVerticalTagCategories = (state) => state.verticalTagCategories;
export const selectVerticalTagCategory = (state) => state.verticalTagCategory;
const selectHorizontalTagCategories = (state) => state.horizontalTagCategories;
export const selectHorizontalTagCategoriesData = (state) => selectHorizontalTagCategories(state).data;
export const selectHorizontalTagCategoriesTotal = (state) => selectHorizontalTagCategories(state).total;
export const selectHorizontalTagCategory = (state) => state.horizontalTagCategory;
const selectVerticalTags = (state) => state.verticalTags;
export const selectVerticalTagsData = (state) => selectVerticalTags(state).data;
export const selectVerticalTagsTotal = (state) => selectVerticalTags(state).total;
const selectHorizontalTags = (state) => state.horizontalTags;
export const selectHorizontalTagsData = (state) => selectHorizontalTags(state).data;
export const selectHorizontalTagsTotal = (state) => selectHorizontalTags(state).total;
export const selectHorizontalTag = (state) => state.horizontalTag;
export const selectVerticalTag = (state) => state.verticalTag;
const selectHorizontalTagPlayers = (state) => state.horizontalTagsPlayers;
export const selectHorizontalTagPlayersData = (state) => selectHorizontalTagPlayers(state).data;
export const selectHorizontalTagPlayersTotal = (state) => selectHorizontalTagPlayers(state).total;
const selectVerticalTagPlayers = (state) => state.verticalTagsPlayers;
export const selectVerticalTagPlayersData = (state) => selectVerticalTagPlayers(state).data;
export const selectVerticalTagPlayersTotal = (state) => selectVerticalTagPlayers(state).total;
const selectPlayerHorizontalTags = (state) => state.playerHorizontalTags;
export const selectPlayerHorizontalTagsData = (state) => selectPlayerHorizontalTags(state).data;
export const selectPlayerHorizontalTagsTotal = (state) => selectPlayerHorizontalTags(state).total;
const selectPlayerVerticalTags = (state) => state.playerVerticalTags;
export const selectPlayerVerticalTagsData = (state) => selectPlayerVerticalTags(state).data;
export const selectPlayerVerticalTagsTotal = (state) => selectPlayerVerticalTags(state).total;
export const selectPlayerTopTags = (state) => state.playerTopTags;
export const selectPlayerTopTagsChanged = (state) => state.playerTopTagsChanged;
const selectPlayerTagLogs = (state) => state.playerTagLogs;
export const selectPlayerTagLogsData = (state) => selectPlayerTagLogs(state).data;
export const selectPlayerTagLogsTotal = (state) => selectPlayerTagLogs(state).total;
const selectTagLogs = (state) => state.tagLogs;
export const selectTagLogsData = (state) => selectTagLogs(state).data;
export const selectTagLogsTotal = (state) => selectTagLogs(state).total;
