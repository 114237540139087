import { createSelector } from 'reselect';
import { selectAuthUserPartnerAccesses } from './authSelector';

export const selectPartners = (state) => state.partners;
export const selectPartnerLoading = (state) => state.loading;
export const selectPartner = (state) => state.partner;
export const selectCurrentPartnerId = (state) => state.currentPartnerId;
export const selectPartnerAccesses = (state) => state.partnerAccesses;
export const selectPartnerAccessesLoading = (state) => state.partnerAccessesLoading;
export const selectCurrentPartner = (state) => createSelector(
  () => selectAuthUserPartnerAccesses(state.auth),
  () => selectCurrentPartnerId(state.partners),
  (partners, id) => partners.find((partner) => partner.id === id),
)();
