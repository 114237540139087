import qs from 'qs';
import {
  all, call, put, takeLatest,
} from '@redux-saga/core/effects';
import { changePlayerAccountInfoUpdatedStatus, changePlayerActionEndedStatus, changePlayerDataUpdatedStatus } from 'actions/player/playerActionCreator';
import { saveBulkBlockedProvidersHistory, savePlayerBlockedProviders, savePlayerBlockedProvidersHistory } from 'actions/blockedProvider/blockedProviderActionCreator';
import {
  changeLoadingState, setErrorMessage, setSuccessMessage, changeEndedStatus,
} from 'actions/setting/settingActionCreator';
import NetworkService from 'services/networkService';
import BlockedProvidersActionType from 'actions/blockedProvider/blockedProviderActionType';
import { getError, getMessage } from 'utils/helpers';
import { i18n } from 'services';
import { AppConstants } from 'constants/index';

function* getPlayerBlockedProvidersHistory({ payload }) {
  try {
    const { playerId } = payload;
    const options = {
      params: payload.data,
      paramsSerializer: (params) => qs.stringify(params),
    };
    yield put(changeLoadingState(true));
    const { Transaction, Player, BlockProvidersHistory } = AppConstants.api;
    const { data } = yield call(NetworkService.makeAPIGetRequest, [Transaction, Player, playerId, BlockProvidersHistory], options);
    yield put(savePlayerBlockedProvidersHistory(data));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* blockPlayerForProviders({ payload }) {
  try {
    const { playerId } = payload;
    const options = {
      data: payload.data,
    };
    yield put(changeLoadingState(true));
    const { Transaction, Player, BlockProviders } = AppConstants.api;
    yield call(NetworkService.makeAPIPostRequest, [Transaction, Player, playerId, BlockProviders], options);
    yield put(changePlayerDataUpdatedStatus(true));
    yield put(changePlayerAccountInfoUpdatedStatus(true));
    yield put(setSuccessMessage(getMessage(i18n.t('player:blockedProvidersList'), i18n.t('notification:slUpdated'))));
    yield put(changePlayerActionEndedStatus(true));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getPlayerBlockedProviders({ payload }) {
  try {
    const { playerId } = payload;
    const options = {
      params: payload.data,
    };
    yield put(changeLoadingState(true));
    const { Transaction, Player, BlockProviders } = AppConstants.api;
    const { data } = yield call(NetworkService.makeAPIGetRequest, [Transaction, Player, playerId, BlockProviders], options);
    yield put(savePlayerBlockedProviders(data));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getBulkBlockedProvidersHistory({ payload }) {
  try {
    const { brandId } = payload;
    const options = {
      params: payload.params,
      paramsSerializer: (params) => qs.stringify(params),
    };
    yield put(changeLoadingState(true));
    const { Transaction, Player, BulkBlockProvidersHistory } = AppConstants.api;
    const { data } = yield call(NetworkService.makeAPIGetRequest, [Transaction, Player, BulkBlockProvidersHistory, brandId], options);
    yield put(saveBulkBlockedProvidersHistory(data));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* blockPlayersProvidersByBulk({ payload }) {
  try {
    const { brandId, data } = payload;
    const options = {
      data,
    };
    yield put(changeLoadingState(true));
    const { Transaction, Player, BulkBlockProviders } = AppConstants.api;
    yield call(NetworkService.makeAPIPostRequest, [Transaction, Player, BulkBlockProviders, brandId], options);
    yield put(changeEndedStatus(true));
    yield put(setSuccessMessage(getMessage(i18n.t('player:blockedProvidersList'), i18n.t('notification:slUpdated'))));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

export default function* blockedProviderSaga() {
  yield all([
    takeLatest(BlockedProvidersActionType.GET_PLAYER_BLOCKED_PROVIDERS_HISTORY, getPlayerBlockedProvidersHistory),
    takeLatest(BlockedProvidersActionType.BLOCK_PLAYER_FOR_PROVIDERS, blockPlayerForProviders),
    takeLatest(BlockedProvidersActionType.GET_PLAYER_BLOCKED_PROVIDERS, getPlayerBlockedProviders),
    takeLatest(BlockedProvidersActionType.GET_BULK_BLOCKED_PROVIDERS_HISTORY, getBulkBlockedProvidersHistory),
    takeLatest(BlockedProvidersActionType.BLOCK_PLAYERS_PROVIDERS_BY_BULK, blockPlayersProvidersByBulk),
  ]);
}
