import {
  all, call, put, select, takeLatest,
} from 'redux-saga/effects';
import qs from 'qs';
import { setErrorMessage, setSuccessMessage, changeEndedStatus } from 'actions/setting/settingActionCreator';
import {
  changeFileManagerLoadingState,
  saveBaseDirectoriesAndFiles,
  changeModalVisibility,
  saveDirectoriesAndFiles,
  getFileTypeGroupsSuccess,
  changeFileManagerBrandId,
  changeDirectoriesAndFilesLoadingState,
  changeAllDirectoriesLoadingState,
  changeFileManageEndedStatus,
  getDirectoriesHierarchySuccess,
  changePopoverVisibility,
  saveFilesByPaths,
  saveUploadedFile,
} from 'actions/fileManager/fileManagerActionCreator';
import NetworkService from 'services/networkService';
import FileManagerActionType from 'actions/fileManager/fileManagerActionType';
import { selectFileManagerDirectoriesAndFiles } from 'selectors';
import { AppConstants } from 'constants/index';
import { getError, getMessage } from 'utils/helpers';
import { i18n } from 'services';

function* getDirectoriesAndFiles({ payload }) {
  try {
    const { params } = payload;
    const options = {
      params,
    };
    const { BrandAdmin, FileManager, Directories } = AppConstants.api;
    if (!params.parentPath) {
      yield put(changeAllDirectoriesLoadingState(true));
    } else {
      yield put(changeDirectoriesAndFilesLoadingState(true));
    }
    const { data } = yield call(NetworkService.makeAPIGetRequest, [BrandAdmin, FileManager, Directories], options);
    if (!params.parentPath) {
      yield put(changeFileManagerBrandId(params.brandId));
      yield put(changeAllDirectoriesLoadingState(false));
      yield put(saveBaseDirectoriesAndFiles(data));
    } else {
      yield put(changeDirectoriesAndFilesLoadingState(false));
      yield put(saveDirectoriesAndFiles(data));
    }
  } catch (err) {
    const { params } = payload;
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    if (!params.parentPath) {
      yield put(changeAllDirectoriesLoadingState(false));
    } else {
      yield put(changeDirectoriesAndFilesLoadingState(false));
    }
  }
}

function* getFileTypeGroups() {
  try {
    const { BrandAdmin, FileManager, FileTypeGroups } = AppConstants.api;
    yield put(changeFileManagerLoadingState(true));
    const response = yield call(NetworkService.makeAPIGetRequest, [BrandAdmin, FileManager, FileTypeGroups]);
    yield put(getFileTypeGroupsSuccess(response.data));
    yield put(changeFileManagerLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeFileManagerLoadingState(false));
  }
}

function* uploadFile({ payload }) {
  try {
    const options = {
      data: payload.data,
      headers: { 'content-type': 'multipart/form-data' },
    };
    yield put(changeFileManagerLoadingState(true));
    const { BrandAdmin, FileManager, UploadFile } = AppConstants.api;
    const { data } = yield call(NetworkService.makeAPIPostRequest, [BrandAdmin, FileManager, UploadFile], options);
    yield put(saveUploadedFile(data));
    yield put(changeFileManagerLoadingState(false));
    yield put(changeFileManageEndedStatus(true));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
      yield put(changeFileManageEndedStatus(true));
    }
    yield put(changeFileManagerLoadingState(false));
  }
}

function* renamePublicFile({ payload }) {
  try {
    const options = {
      data: payload.data,
    };
    yield put(changeFileManagerLoadingState(true));
    const { BrandAdmin, FileManager, RenamePublicFile } = AppConstants.api;
    yield call(NetworkService.makeAPIPutRequest, [BrandAdmin, FileManager, RenamePublicFile], options);
    yield put(changeFileManagerLoadingState(false));
    yield put(setSuccessMessage(getMessage(i18n.t('cms:file'), i18n.t('notification:slRenamed'))));
    yield put(changeModalVisibility(false));
    yield put(changeEndedStatus(true));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeFileManagerLoadingState(false));
  }
}

function* renamePublicDirectory({ payload }) {
  try {
    const options = {
      data: payload.data,
    };
    yield put(changeFileManagerLoadingState(true));
    const { BrandAdmin, FileManager, RenamePublicDirectory } = AppConstants.api;
    yield call(NetworkService.makeAPIPutRequest, [BrandAdmin, FileManager, RenamePublicDirectory], options);
    yield put(changeFileManagerLoadingState(false));
    yield put(setSuccessMessage(getMessage(i18n.t('cms:folder'), i18n.t('notification:slRenamed'))));
    yield put(changeModalVisibility(false));
    yield put(changeEndedStatus(true));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeFileManagerLoadingState(false));
  }
}

function* deletePublicFiles({ payload }) {
  try {
    const options = {
      data: payload.data,
    };
    yield put(changeFileManagerLoadingState(true));
    const { BrandAdmin, FileManager, DeleteFiles } = AppConstants.api;
    yield call(NetworkService.makeAPIDeleteRequest, [BrandAdmin, FileManager, DeleteFiles], options);
    const state = yield select();
    const directories = selectFileManagerDirectoriesAndFiles(state.fileManager);
    const newDirectories = directories.filter((el) => !payload.data.filePaths.includes(el.path));
    yield put(saveDirectoriesAndFiles(newDirectories));
    yield put(changeModalVisibility(false));
    yield put(changeFileManagerLoadingState(false));
    yield put(
      setSuccessMessage(
        getMessage(i18n.t(payload.data.filePaths.length === 1 ? 'cms:theFile' : 'cms:files'), i18n.t(payload.data.filePaths.length === 1 ? 'notification:slDeleted' : 'notification:plDeleted')),
      ),
    );
    yield put(changeEndedStatus(true));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeFileManagerLoadingState(false));
  }
}

function* deletePublicDirectory({ payload }) {
  try {
    const options = {
      data: payload.data,
    };
    yield put(changeFileManagerLoadingState(true));
    const { BrandAdmin, FileManager, DeletePublicDirectory } = AppConstants.api;
    yield call(NetworkService.makeAPIDeleteRequest, [BrandAdmin, FileManager, DeletePublicDirectory], options);
    yield put(changeModalVisibility(false));
    yield put(changeFileManagerLoadingState(false));
    yield put(changeFileManagerLoadingState(false));
    yield put(setSuccessMessage(getMessage(i18n.t('cms:folder'), i18n.t('notification:slDeleted'))));
    yield put(changeEndedStatus(true));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeFileManagerLoadingState(false));
  }
}

function* createDirectory({ payload }) {
  try {
    const options = {
      data: payload.data,
    };
    yield put(changeFileManagerLoadingState(true));
    const { BrandAdmin, FileManager, Directories } = AppConstants.api;
    yield call(NetworkService.makeAPIPostRequest, [BrandAdmin, FileManager, Directories], options);
    yield put(changeFileManagerLoadingState(false));
    yield put(setSuccessMessage(getMessage(i18n.t('cms:folder'), i18n.t('notification:slCreated'))));
    yield put(changeModalVisibility(false));
    yield put(changeEndedStatus(true));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeFileManagerLoadingState(false));
  }
}

function* getDirectoriesHierarchy({ payload }) {
  try {
    const options = {
      params: payload.params,
    };
    yield put(changeFileManagerLoadingState(true));
    const { BrandAdmin, FileManager, DirectoryHierarchy } = AppConstants.api;
    const { data } = yield call(NetworkService.makeAPIGetRequest, [BrandAdmin, FileManager, DirectoryHierarchy], options);
    yield put(changeFileManagerLoadingState(false));
    yield put(getDirectoriesHierarchySuccess(data));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeFileManagerLoadingState(false));
  }
}

function* movePublicFiles({ payload }) {
  try {
    const options = {
      data: payload.data,
    };
    const { filePaths } = payload.data;
    yield put(changeFileManagerLoadingState(true));
    const { BrandAdmin, FileManager, MovePublicFiles } = AppConstants.api;
    yield call(NetworkService.makeAPIPostRequest, [BrandAdmin, FileManager, MovePublicFiles], options);
    yield put(changeFileManagerLoadingState(false));
    yield put(changeModalVisibility(false));
    yield put(changePopoverVisibility(false));
    yield put(changeEndedStatus(true));
    yield put(setSuccessMessage(getMessage(i18n.t(filePaths.length > 1 ? 'cms:files' : 'cms:file'), i18n.t(filePaths.length > 1 ? 'notification:plMoved' : 'notification:slMoved'))));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeFileManagerLoadingState(false));
  }
}

function* getFilesByPaths({ payload }) {
  try {
    const options = {
      params: payload.params,
      paramsSerializer: (param) => qs.stringify(param),
    };
    yield put(changeFileManagerLoadingState(true));
    const { BrandAdmin, FileManager, Files } = AppConstants.api;
    const { data } = yield call(NetworkService.makeAPIGetRequest, [BrandAdmin, FileManager, Files], options);
    yield put(changeFileManagerLoadingState(false));
    yield put(saveFilesByPaths(data));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeFileManagerLoadingState(false));
  }
}

export default function* fileManagerSaga() {
  yield all([
    takeLatest(FileManagerActionType.GET_DIRECTORIES_AND_FILES, getDirectoriesAndFiles),
    takeLatest(FileManagerActionType.GET_FILE_TYPE_GROUPS, getFileTypeGroups),
    takeLatest(FileManagerActionType.UPLOAD_FILE_INTO_DIRECTORIES, uploadFile),
    takeLatest(FileManagerActionType.RENAME_PUBLIC_FILE, renamePublicFile),
    takeLatest(FileManagerActionType.DELETE_PUBLIC_FILES, deletePublicFiles),
    takeLatest(FileManagerActionType.RENAME_PUBLIC_DIRECTORY, renamePublicDirectory),
    takeLatest(FileManagerActionType.DELETE_PUBLIC_DIRECTORY, deletePublicDirectory),
    takeLatest(FileManagerActionType.CREATE_DIRECTORY, createDirectory),
    takeLatest(FileManagerActionType.GET_DIRECTORIES_HIERARCHY, getDirectoriesHierarchy),
    takeLatest(FileManagerActionType.MOVE_PUBLIC_FILES, movePublicFiles),
    takeLatest(FileManagerActionType.GET_FILES_BY_PATHS, getFilesByPaths),
  ]);
}
