import PropTypes from 'prop-types';
import { StyledActions, StyledActionsToggle, StyledActionsWrapper } from 'components/styledComponents/icon/Icon.styled';
import { IconSvg } from 'components/common';
import { useEffect, useState } from 'react';

const TableActions = (props) => {
  const { children } = props;

  const [showIcons, setShowIcons] = useState(false);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setShowIcons(true);
    }, 100);

    return () => clearTimeout(timeout);
  }, []);

  return (
    <StyledActionsWrapper>
      <StyledActionsToggle>
        <IconSvg width="1.4rem" height="1.4rem" icon="ThirdPartyDotsIcon" />
      </StyledActionsToggle>
      {!!showIcons && <StyledActions>{children}</StyledActions>}
    </StyledActionsWrapper>
  );
};

TableActions.propTypes = {
  children: PropTypes.node.isRequired,
};

export default TableActions;
