import { useState, useEffect } from 'react';
import { Checkbox, Popover } from 'antd';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { StyledButtonBlue } from 'components/styledComponents/button/Button.styled';
import { StyledIconDefault } from 'components/styledComponents/icon/Icon.styled';
import { StyledColumnsText, StyledPopoverWithCheckboxes, StyledColumnsWrapper } from 'components/styledComponents/popover/Popover.styled';
import IconSvg from './icon/IconSvg';

const ManageColumns = (props) => {
  const {
    columns, configColumns, createOrEditUserConfig, tableKey, loading, changeLoadingState, title, forceUpdate,
  } = props;

  const hiddenClassName = 'hidden';
  const { t } = useTranslation();

  const [visible, setVisible] = useState(false);
  const [initialData, setInitialData] = useState(null);
  const [data, setData] = useState([]);

  useEffect(() => {
    // when changing column list and config already stored
    if (!_.isUndefined(configColumns) && forceUpdate) {
      const existsColumnsList = configColumns.map(({ dataIndex }) => dataIndex);
      const columnsList = columns.map(({ dataIndex }) => dataIndex);
      if (!_.isEqual(existsColumnsList.sort(), columnsList.sort())) {
        const checkedColumns = {};
        configColumns.forEach(({ dataIndex, checked }) => {
          checkedColumns[dataIndex] = checked;
        });

        const configValue = columns.map(({ dataIndex, width }) => ({
          dataIndex,
          checked: _.isUndefined(checkedColumns[dataIndex]) ? true : checkedColumns[dataIndex],
          width,
        }));
        createOrEditUserConfig({ key: tableKey, configValue });
      }
    }
  }, [configColumns, tableKey, columns, forceUpdate, createOrEditUserConfig]);

  useEffect(() => {
    if (visible) {
      const columnsData = {};

      columns.forEach((col) => {
        columnsData[col.dataIndex] = { ...col, title: col.titleText || col.title, checked: true };
      });

      let newData = Object.values(columnsData);
      if (!_.isUndefined(configColumns)) {
        newData = configColumns.map((el) => ({
          ...el,
          disabled: columnsData[el.dataIndex].disabled,
          title: columnsData[el.dataIndex].titleText || columnsData[el.dataIndex].title,
          className: columnsData[el.dataIndex].className,
        }));
      }
      setData(newData);
      if (_.isNull(initialData)) {
        setInitialData(newData);
      }
    } else {
      setInitialData(null);
    }
  }, [columns, configColumns, tableKey, visible, initialData]);

  useEffect(() => {
    changeLoadingState(loading);
  }, [loading, changeLoadingState]);

  const handleShowColumns = () => setVisible(!visible);

  const handleSelectAll = (checked) => {
    const newData = data.map((el) => (!(el.disabled || el.className === hiddenClassName) ? { ...el, checked } : el));
    setData(newData);
  };

  const handleSelect = (checked, index) => {
    const newData = [...data];
    newData[index].checked = checked;
    setData(newData);
  };

  const handleApply = () => {
    handleShowColumns();
    const configValue = data.map(({ dataIndex, checked, width }) => ({ dataIndex, checked, width }));
    createOrEditUserConfig({ key: tableKey, configValue });
  };

  const doRender = () => {
    const content = data.map((col, index) => (
      <div title={t(col.title)} key={col.title}>
        <Checkbox checked={col.checked} disabled={col.disabled} onChange={(e) => handleSelect(e.target.checked, index)} className={col.className}>
          {t(col.title)}
        </Checkbox>
      </div>
    ));

    return (
      <div>
        <StyledPopoverWithCheckboxes>
          <Checkbox
            onChange={(e) => handleSelectAll(e.target.checked)}
            checked={data.length && !data.some((el) => !el.checked)}
            indeterminate={data.some((el) => el.checked && !el.disabled) && data.filter((el) => el.checked).length !== data.length}
            className="sticky-top"
          >
            <strong>{t('selectAll')}</strong>
          </Checkbox>
          {content}
        </StyledPopoverWithCheckboxes>
        <StyledButtonBlue onClick={handleApply} disabled={_.isEqual(initialData, data)} size="medium">
          {t('apply')}
        </StyledButtonBlue>
      </div>
    );
  };

  return (
    <Popover placement="bottomRight" content={doRender()} trigger="click" open={visible} onOpenChange={handleShowColumns} getPopupContainer={(trigger) => trigger.parentNode}>
      <StyledColumnsWrapper onClick={handleShowColumns}>
        <StyledColumnsText>{t(title)}</StyledColumnsText>
        <StyledIconDefault>
          <IconSvg icon="SettingsIcon" />
        </StyledIconDefault>
      </StyledColumnsWrapper>
    </Popover>
  );
};

ManageColumns.propTypes = {
  columns: PropTypes.array.isRequired,
  configColumns: PropTypes.array,
  createOrEditUserConfig: PropTypes.func.isRequired,
  changeLoadingState: PropTypes.func.isRequired,
  tableKey: PropTypes.string.isRequired,
  loading: PropTypes.bool.isRequired,
  title: PropTypes.string,
  forceUpdate: PropTypes.bool,
};

ManageColumns.defaultProps = {
  title: 'columns',
  configColumns: undefined,
  forceUpdate: true,
};

export default ManageColumns;
