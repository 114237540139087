import { useCallback, useState } from 'react';
import { AppConstants } from 'constants';
import { LocalStorageService } from 'services';
import Config from 'config';

const signalR = require('@microsoft/signalr');

const { isBrRegulation } = Config;

const useConnection = () => {
  const [connection, setConnection] = useState(null);

  const connect = useCallback(() => {
    const auth = LocalStorageService.get('auth');
    // todo temporary not allowed for pm
    if (auth && auth.access_token && !isBrRegulation) {
      const url = [AppConstants.api.NotificationAdmin, AppConstants.api.Hubs, AppConstants.api.NotificationHub].join('/');
      const newConnection = new signalR.HubConnectionBuilder()
        .configureLogging(signalR.LogLevel.None)
        .withUrl(`${Config.baseURL}${url}?access_token=${auth.access_token}`, { skipNegotiation: true, transport: signalR.HttpTransportType.WebSockets })
        .withAutomaticReconnect()
        .build();

      setConnection(newConnection);
    }
  }, []);

  return { connection, setConnection, connect };
};

export default useConnection;
