import { createSelector } from 'reselect';

export const selectDashboards = (state) => state.dashboards;
export const selectDashboard = (dashboard, id) => createSelector(
  () => selectDashboards(dashboard),
  (data) => {
    const item = data.find((el) => el.id === id);
    return item || {};
  },
)();

export const selectDashboardLayouts = (state) => state.layouts;

export const selectDashboardWidgetsList = (state) => state.widgets;

export const selectDashboardWidgets = (dashboard) => createSelector(
  () => selectDashboardWidgetsList(dashboard),
  (data) => Object.values(data) || [],
)();

export const selectDashboardWidget = (dashboard, id) => createSelector(
  () => selectDashboardWidgetsList(dashboard),
  (data) => data[id] || {},
)();

export const selectLastRequestedWidget = (state) => state.lastRequestedWidget;
export const selectApplyToWidget = (state) => state.applyToWidget;

export const selectWidgetsData = (state) => state.widgetsData;
export const selectWidgetData = (dashboard, id) => createSelector(
  () => selectWidgetsData(dashboard),
  (data) => data[id],
)();

export const selectWidgetsLoading = (state) => state.widgetsLoading;
export const selectWidgetLoading = (dashboard, id) => createSelector(
  () => selectWidgetsLoading(dashboard),
  (data) => data[id],
)();

export const selectDashboardEndedStatus = (state) => state.endedStatus;
