import AccountUpdatesActionType from 'actions/accountUpdates/accountUpdatesActionType';

const initialState = {
  playerAccountUpdates: {},
  playerProfileControlNames: [],
};

const accountUpdateReducer = (state = initialState, { type, payload }) => {
  switch (type) {
  case AccountUpdatesActionType.SAVE_PLAYER_ACCOUNT_UPDATES:
    return {
      ...state,
      playerAccountUpdates: payload.data,
    };
  case AccountUpdatesActionType.RESET_PLAYER_ACCOUNT_UPDATES:
    return {
      ...state,
      playerAccountUpdates: {},
    };
  case AccountUpdatesActionType.SAVE_PLAYER_PROFILE_CONTROL_NAMES:
    return {
      ...state,
      playerProfileControlNames: payload.data,
    };
  case AccountUpdatesActionType.RESET_PLAYER_PROFILE_CONTROL_NAMES:
    return {
      ...state,
      playerProfileControlNames: [],
    };
  default:
    return state;
  }
};

export default accountUpdateReducer;
