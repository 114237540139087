import {
  all, call, put, takeLatest, takeEvery,
} from 'redux-saga/effects';
import qs from 'qs';
import {
  changeEndedStatus, changeLoadingState, setErrorMessage, setSuccessMessage,
} from 'actions/setting/settingActionCreator';
import NetworkService from 'services/networkService';
import {
  saveBrandLegalAgreementsNotPendingPolicies,
  saveBrandLegalAgreementsPolicies,
  saveBrandLegalAgreementsVersions,
  savePlayerLegalAgreementsVersions,
} from 'actions/legalAgreement/legalAgreementActionCreator';
import LegalAgreementActionType from 'actions/legalAgreement/legalAgreementActionType';
import { AppConstants } from 'constants/index';
import { getError, getMessage } from 'utils/helpers';
import { i18n } from 'services';

function* getBrandLegalAgreementsVersions({ payload }) {
  try {
    const { params } = payload;
    const options = {
      params,
      paramsSerializer: (param) => qs.stringify(param),
    };
    const { BrandAdmin, LegalAgreements, Versions } = AppConstants.api;
    yield put(changeLoadingState(true));
    const { data } = yield call(NetworkService.makeAPIGetRequest, [BrandAdmin, LegalAgreements, Versions], options);
    yield put(saveBrandLegalAgreementsVersions(data));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getBrandLegalAgreementsPolicies({ payload }) {
  const { params, isLoading } = payload;
  try {
    const options = {
      params,
      paramsSerializer: (param) => qs.stringify(param),
    };
    const { BrandAdmin, LegalAgreements, Policies } = AppConstants.api;

    if (isLoading) {
      yield put(changeLoadingState(true));
    }

    const { data } = yield call(NetworkService.makeAPIGetRequest, [BrandAdmin, LegalAgreements, Policies], options);
    if (params.notHavingPendingVersion) {
      yield put(saveBrandLegalAgreementsNotPendingPolicies(data));
    } else {
      yield put(saveBrandLegalAgreementsPolicies(data));
    }

    if (isLoading) {
      yield put(changeLoadingState(false));
    }
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    if (isLoading) {
      yield put(changeLoadingState(false));
    }
  }
}

function* deleteBrandLegalAgreementsVersion({ payload }) {
  try {
    const { params, legalAgreementVersionId } = payload;
    const options = {
      params,
    };
    const { BrandAdmin, LegalAgreements, Versions } = AppConstants.api;
    yield put(changeLoadingState(true));
    yield call(NetworkService.makeAPIDeleteRequest, [BrandAdmin, LegalAgreements, Versions, legalAgreementVersionId], options);
    yield put(changeEndedStatus(true));
    yield put(changeLoadingState(false));
    yield put(setSuccessMessage(getMessage(i18n.t('version'), i18n.t('notification:slDeleted'))));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* addBrandLegalAgreementsVersion({ payload }) {
  try {
    const { data } = payload;
    const options = {
      data,
    };
    const { BrandAdmin, LegalAgreements, Versions } = AppConstants.api;
    yield put(changeLoadingState(true));
    yield call(NetworkService.makeAPIPostRequest, [BrandAdmin, LegalAgreements, Versions], options);
    yield put(changeEndedStatus(true));
    yield put(changeLoadingState(false));
    yield put(setSuccessMessage(getMessage(i18n.t('version'), i18n.t('notification:slCreated'))));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* editBrandLegalAgreementsVersion({ payload }) {
  try {
    const { data, legalAgreementVersionId } = payload;
    const options = {
      data,
    };
    const { BrandAdmin, LegalAgreements, Versions } = AppConstants.api;
    yield put(changeLoadingState(true));
    yield call(NetworkService.makeAPIPutRequest, [BrandAdmin, LegalAgreements, Versions, legalAgreementVersionId], options);
    yield put(changeEndedStatus(true));
    yield put(changeLoadingState(false));
    yield put(setSuccessMessage(getMessage(i18n.t('version'), i18n.t('notification:slUpdated'))));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getPlayerLegalAgreementsVersions({ payload }) {
  try {
    const { params } = payload;
    const options = {
      params,
      paramsSerializer: (param) => qs.stringify(param),
    };
    const { ReportingAdmin, Player, LegalAgreements } = AppConstants.api;
    yield put(changeLoadingState(true));
    const { data } = yield call(NetworkService.makeAPIGetRequest, [ReportingAdmin, Player, LegalAgreements], options);
    yield put(savePlayerLegalAgreementsVersions(data));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

export default function* legalAgreementSaga() {
  yield all([
    takeLatest(LegalAgreementActionType.GET_BRAND_LEGAL_AGREEMENTS_VERSIONS, getBrandLegalAgreementsVersions),
    takeEvery(LegalAgreementActionType.GET_BRAND_LEGAL_AGREEMENTS_POLICIES, getBrandLegalAgreementsPolicies),
    takeLatest(LegalAgreementActionType.DELETE_BRAND_LEGAL_AGREEMENTS_VERSION, deleteBrandLegalAgreementsVersion),
    takeLatest(LegalAgreementActionType.ADD_BRAND_LEGAL_AGREEMENTS_VERSION, addBrandLegalAgreementsVersion),
    takeLatest(LegalAgreementActionType.EDIT_BRAND_LEGAL_AGREEMENTS_VERSION, editBrandLegalAgreementsVersion),
    takeLatest(LegalAgreementActionType.GET_PLAYER_LEGAL_AGREEMENTS_VERSIONS, getPlayerLegalAgreementsVersions),
  ]);
}
