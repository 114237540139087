import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import AdminEditor from 'components/common/adminEditor/AdminEditor';
import { selectCurrentBrandId, selectMode, selectResourceDirectoryRules } from 'selectors';
import { changeLoadingState, setErrorMessage } from 'actions/setting/settingActionCreator';

const mapStateToProps = ({ brands, settings, resource }) => ({
  brandId: selectCurrentBrandId(brands),
  isNightMode: selectMode(settings),
  directoryRules: selectResourceDirectoryRules(resource),
});

const mapDispatchToProps = (dispatch) => bindActionCreators(
  {
    setErrorMessage,
    changeLoadingState,
  },
  dispatch,
);

export default connect(mapStateToProps, mapDispatchToProps)(AdminEditor);
