import DevToolActionType from 'actions/devTool/devToolActionType';

const initialState = {
  systemCaches: [],
  systemCacheData: [],
  dbServices: [],
  dbServiceTables: [],
  jobs: [],
};

const devToolReducer = (state = initialState, { type, payload }) => {
  switch (type) {
  case DevToolActionType.SAVE_SYSTEM_CACHES:
    return {
      ...state,
      systemCaches: payload.data,
    };
  case DevToolActionType.RESET_SYSTEM_CACHES:
    return {
      ...state,
      systemCaches: [],
    };
  case DevToolActionType.SAVE_SYSTEM_CACHE_DATA:
    return {
      ...state,
      systemCacheData: payload.data,
    };
  case DevToolActionType.RESET_SYSTEM_CACHE_DATA:
    return {
      ...state,
      systemCacheData: [],
    };
  case DevToolActionType.SAVE_DB_SERVICES:
    return {
      ...state,
      dbServices: payload.data,
    };
  case DevToolActionType.RESET_DB_SERVICES:
    return {
      ...state,
      dbServices: [],
    };
  case DevToolActionType.SAVE_DB_SERVICE_TABLES:
    return {
      ...state,
      dbServiceTables: payload.data,
    };
  case DevToolActionType.RESET_DB_SERVICE_TABLES:
    return {
      ...state,
      dbServiceTables: [],
    };
  case DevToolActionType.SAVE_JOBS:
    return {
      ...state,
      jobs: payload.data,
    };
  case DevToolActionType.RESET_JOBS:
    return {
      ...state,
      jobs: [],
    };
  default:
    return state;
  }
};

export default devToolReducer;
