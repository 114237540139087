import {
  all, call, put, takeLatest,
} from 'redux-saga/effects';
import TaxesActionType from 'actions/taxes/taxesActionType';
import { changeLoadingState, setErrorMessage, setSuccessMessage } from 'actions/setting/settingActionCreator';
import {
  saveDepositTaxes, saveDepositTax, saveWithdrawalTaxes, saveWithdrawalTax, saveTaxSetting,
} from 'actions/taxes/taxesActionCreator';
import NetworkService from 'services/networkService';
import { getError, getMessage } from 'utils/helpers';
import { AppConstants } from 'constants/index';
import { i18n } from 'services';
import { changeEndedStatus } from 'actions/template/templateActionCreator';

function* getDepositTaxes({ payload }) {
  try {
    const { brandId } = payload;
    const { BrandAdmin, Taxes, Deposit } = AppConstants.api;
    yield put(changeLoadingState(true));
    const { data } = yield call(NetworkService.makeAPIGetRequest, [BrandAdmin, Taxes, brandId, Deposit]);
    yield put(saveDepositTaxes(data));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getDepositTax({ payload }) {
  try {
    const { brandId, currencyId } = payload;
    const { BrandAdmin, Taxes, Deposit } = AppConstants.api;
    yield put(changeLoadingState(true));
    const { data } = yield call(NetworkService.makeAPIGetRequest, [BrandAdmin, Taxes, brandId, Deposit, currencyId]);
    yield put(saveDepositTax(data));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* editDepositTax({ payload }) {
  try {
    const { brandId, currencyId, data } = payload;
    const { BrandAdmin, Taxes, Deposit } = AppConstants.api;
    const options = {
      data,
    };
    yield put(changeLoadingState(true));
    yield call(NetworkService.makeAPIPostRequest, [BrandAdmin, Taxes, brandId, Deposit, currencyId], options);
    yield put(setSuccessMessage(getMessage(i18n.t('taxes'), i18n.t(data.length ? 'notification:slUpdated' : 'notification:slReset'))));
    yield put(changeLoadingState(false));
    yield put(changeEndedStatus(true));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getWithdrawalTaxes({ payload }) {
  try {
    const { brandId } = payload;
    const { BrandAdmin, Taxes, Withdrawal } = AppConstants.api;
    yield put(changeLoadingState(true));
    const { data } = yield call(NetworkService.makeAPIGetRequest, [BrandAdmin, Taxes, brandId, Withdrawal]);
    yield put(saveWithdrawalTaxes(data));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getWithdrawalTax({ payload }) {
  try {
    const { brandId, currencyId } = payload;
    const { BrandAdmin, Taxes, Withdrawal } = AppConstants.api;
    yield put(changeLoadingState(true));
    const { data } = yield call(NetworkService.makeAPIGetRequest, [BrandAdmin, Taxes, brandId, Withdrawal, currencyId]);
    yield put(saveWithdrawalTax(data));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* editWithdrawalTax({ payload }) {
  try {
    const { brandId, currencyId, data } = payload;
    const { BrandAdmin, Taxes, Withdrawal } = AppConstants.api;
    const options = {
      data,
    };
    yield put(changeLoadingState(true));
    yield call(NetworkService.makeAPIPostRequest, [BrandAdmin, Taxes, brandId, Withdrawal, currencyId], options);
    yield put(setSuccessMessage(getMessage(i18n.t('brandSetting:taxes'), i18n.t('notification:slReset'))));
    yield put(changeLoadingState(false));
    yield put(changeEndedStatus(true));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getTaxSetting({ payload }) {
  try {
    const { brandId } = payload;
    const { BrandAdmin, Taxes } = AppConstants.api;
    yield put(changeLoadingState(true));
    const { data } = yield call(NetworkService.makeAPIGetRequest, [BrandAdmin, Taxes, brandId]);
    yield put(saveTaxSetting(data));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* addTaxSetting({ payload }) {
  try {
    const { brandId, data } = payload;
    const { BrandAdmin, Taxes } = AppConstants.api;
    yield put(changeLoadingState(true));
    yield call(NetworkService.makeAPIPostRequest, [BrandAdmin, Taxes, brandId], { data });
    yield put(setSuccessMessage(getMessage(i18n.t('brand:taxSettings'), i18n.t('notification:slUpdated'))));
    yield put(changeLoadingState(false));
    yield put(changeEndedStatus(true));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

export default function* taxesSaga() {
  yield all([
    takeLatest(TaxesActionType.GET_DEPOSIT_TAXES, getDepositTaxes),
    takeLatest(TaxesActionType.GET_DEPOSIT_TAX, getDepositTax),
    takeLatest(TaxesActionType.EDIT_DEPOSIT_TAX, editDepositTax),
    takeLatest(TaxesActionType.GET_WITHDRAWAL_TAXES, getWithdrawalTaxes),
    takeLatest(TaxesActionType.GET_WITHDRAWAL_TAX, getWithdrawalTax),
    takeLatest(TaxesActionType.EDIT_WITHDRAWAL_TAX, editWithdrawalTax),
    takeLatest(TaxesActionType.GET_TAX_SETTING, getTaxSetting),
    takeLatest(TaxesActionType.ADD_TAX_SETTING, addTaxSetting),
  ]);
}
