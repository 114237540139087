import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { StyledButtonBlue, StyledPreviewButton } from 'components/styledComponents/button/Button.styled';
import { StyledTemplate, StyledImg, StyledAbsolute } from 'components/brand/Brand.styled';

const Template = (props) => {
  const { onSelectClick, template, isSelected } = props;
  const { t } = useTranslation();

  const {
    name, thumbnailUrl, id, previewUrl,
  } = template;

  return (
    <StyledTemplate isSelected={isSelected} onClick={() => onSelectClick(id)}>
      <StyledImg alt="img" src={thumbnailUrl} />
      <p>{name}</p>
      <StyledAbsolute>
        <StyledPreviewButton to={`//${previewUrl}`} target="_blank" size="large">
          {t('preview')}
        </StyledPreviewButton>
        <StyledButtonBlue type="button" onClick={() => onSelectClick(id)} size="large">
          {t('select')}
        </StyledButtonBlue>
      </StyledAbsolute>
    </StyledTemplate>
  );
};

Template.propTypes = {
  template: PropTypes.shape({
    name: PropTypes.string.isRequired,
    thumbnailUrl: PropTypes.string.isRequired,
    previewUrl: PropTypes.string.isRequired,
    id: PropTypes.number.isRequired,
  }).isRequired,
  onSelectClick: PropTypes.func.isRequired,
  isSelected: PropTypes.number.isRequired,
};

export default Template;
