const PermissionActionType = {
  GET_RESOURCES: 'GET_RESOURCES',
  GET_RESOURCES_SUCCESS: 'GET_RESOURCES_SUCCESS',

  GET_PERMISSIONS: 'GET_PERMISSIONS',
  GET_PERMISSIONS_SUCCESS: 'GET_PERMISSIONS_SUCCESS',
  RESET_PERMISSIONS: 'RESET_PERMISSIONS',

  GET_PERMISSIONS_RESOURCE: 'GET_PERMISSIONS_RESOURCE',
  SAVE_PERMISSIONS_RESOURCE: 'SAVE_PERMISSIONS_RESOURCE',
  RESET_PERMISSIONS_RESOURCE: 'RESET_PERMISSIONS_RESOURCE',

  GET_USER_MANAGEMENT_RESOURCE: 'GET_USER_MANAGEMENT_RESOURCE',
  SAVE_USER_MANAGEMENT_RESOURCE: 'SAVE_USER_MANAGEMENT_RESOURCE',
  RESET_USER_MANAGEMENT_RESOURCE: 'RESET_USER_MANAGEMENT_RESOURCE',

  CHANGE_PERMISSION_LOADING_STATE: 'CHANGE_PERMISSION_LOADING_STATE',

  GET_ALL_ACTION_RESOURCE: 'GET_ALL_ACTION_RESOURCE',
  SAVE_ALL_ACTION_RESOURCE: 'SAVE_ALL_ACTION_RESOURCE',
  RESET_ALL_ACTION_RESOURCE: 'RESET_ALL_ACTION_RESOURCE',

  UPDATE_PERMISSION_LEVEL: 'UPDATE_PERMISSION_LEVEL',
};

export default PermissionActionType;
