import CashierActionType from 'actions/cashier/cashierActionType';

const initialState = {
  inventory: [],
  deposit: [],
  withdrawal: [],
  depositPaymentMethodsResource: [],
  cashierDepositCategory: {},
  withdrawalPaymentMethodsResource: [],
  cashierWithdrawalCategory: {},
  cashierDepositPaymentMethods: [],
  depositCategoryResource: [],
  cashierWithdrawalPaymentMethods: [],
  withdrawalCategoryResource: [],
  cashierGeneralSettings: {},
  cashierBrandSettings: {},
};

const cashierReducer = (state = initialState, { type, payload }) => {
  switch (type) {
  case CashierActionType.SAVE_CASHIER_INVENTORY:
    return {
      ...state,
      inventory: payload.data,
    };
  case CashierActionType.RESET_CASHIER_INVENTORY:
    return {
      ...state,
      inventory: [],
    };
  case CashierActionType.SAVE_CASHIER_DEPOSIT:
    return {
      ...state,
      deposit: payload.data,
    };
  case CashierActionType.RESET_CASHIER_DEPOSIT:
    return {
      ...state,
      deposit: [],
    };
  case CashierActionType.SAVE_DEPOSIT_PAYMENT_METHODS_RESOURCE:
    return {
      ...state,
      depositPaymentMethodsResource: payload.data,
    };
  case CashierActionType.RESET_DEPOSIT_PAYMENT_METHODS_RESOURCE:
    return {
      ...state,
      depositPaymentMethodsResource: [],
    };
  case CashierActionType.SAVE_CASHIER_DEPOSIT_CATEGORY:
    return {
      ...state,
      cashierDepositCategory: payload.data,
    };
  case CashierActionType.RESET_CASHIER_DEPOSIT_CATEGORY:
    return {
      ...state,
      cashierDepositCategory: {},
    };
  case CashierActionType.SAVE_CASHIER_WITHDRAWAL:
    return {
      ...state,
      withdrawal: payload.data,
    };
  case CashierActionType.RESET_CASHIER_WITHDRAWAL:
    return {
      ...state,
      withdrawal: [],
    };
  case CashierActionType.SAVE_WITHDRAWAL_PAYMENT_METHODS_RESOURCE:
    return {
      ...state,
      withdrawalPaymentMethodsResource: payload.data,
    };
  case CashierActionType.RESET_WITHDRAWAL_PAYMENT_METHODS_RESOURCE:
    return {
      ...state,
      withdrawalPaymentMethodsResource: [],
    };
  case CashierActionType.SAVE_CASHIER_WITHDRAWAL_CATEGORY:
    return {
      ...state,
      cashierWithdrawalCategory: payload.data,
    };
  case CashierActionType.RESET_CASHIER_WITHDRAWAL_CATEGORY:
    return {
      ...state,
      cashierWithdrawalCategory: {},
    };
  case CashierActionType.SAVE_CASHIER_DEPOSIT_PAYMENT_METHODS:
    return {
      ...state,
      cashierDepositPaymentMethods: payload.data,
    };
  case CashierActionType.RESET_CASHIER_DEPOSIT_PAYMENT_METHODS:
    return {
      ...state,
      cashierDepositPaymentMethods: [],
    };
  case CashierActionType.SAVE_DEPOSIT_CATEGORY_RESOURCE:
    return {
      ...state,
      depositCategoryResource: payload.data,
    };
  case CashierActionType.RESET_DEPOSIT_CATEGORY_RESOURCE:
    return {
      ...state,
      depositCategoryResource: [],
    };
  case CashierActionType.SAVE_CASHIER_WITHDRAWAL_PAYMENT_METHODS:
    return {
      ...state,
      cashierWithdrawalPaymentMethods: payload.data,
    };
  case CashierActionType.RESET_CASHIER_WITHDRAWAL_PAYMENT_METHODS:
    return {
      ...state,
      cashierWithdrawalPaymentMethods: [],
    };
  case CashierActionType.SAVE_WITHDRAWAL_CATEGORY_RESOURCE:
    return {
      ...state,
      withdrawalCategoryResource: payload.data,
    };
  case CashierActionType.RESET_WITHDRAWAL_CATEGORY_RESOURCE:
    return {
      ...state,
      withdrawalCategoryResource: [],
    };
  case CashierActionType.SAVE_CASHIER_GENERAL_SETTINGS:
    return {
      ...state,
      cashierGeneralSettings: payload.data,
    };
  case CashierActionType.RESET_CASHIER_GENERAL_SETTINGS:
    return {
      ...state,
      cashierGeneralSettings: {},
    };
  case CashierActionType.SAVE_CASHIER_BRAND_SETTINGS:
    return {
      ...state,
      cashierBrandSettings: payload.data,
    };
  case CashierActionType.RESET_CASHIER_BRAND_SETTINGS:
    return {
      ...state,
      cashierBrandSettings: {},
    };
  default:
    return state;
  }
};

export default cashierReducer;
