import {
  put, call, takeLatest, all, select,
} from 'redux-saga/effects';
import NetworkService from 'services/networkService';
import { getError, getMessage } from 'utils/helpers';
import {
  getPermissionGroupSuccess,
  getPermissionGroupsSuccess,
  getAllPermissionGroupsSuccess,
  deletePermissionGroupSuccess,
  changePermissionGroupLoadingState,
} from 'actions/permissionGroup/permissionGroupActionCreator';
import {
  changeLoadingState, setSuccessMessage, setErrorMessage, changeEndedStatus,
} from 'actions/setting/settingActionCreator';
import PermissionGroupActionType from 'actions/permissionGroup/permissionGroupActionType';
import { selectPermissionGroups } from 'selectors';
import { i18n } from 'services';
import { AppConstants } from 'constants/index';
import qs from 'qs';
import { takeEvery } from '@redux-saga/core/effects';

function* getPermissionGroup({ payload }) {
  try {
    yield put(changePermissionGroupLoadingState(true, payload.key));
    const { Admin, PermissionGroup } = AppConstants.api;
    const response = yield call(NetworkService.makeAPIGetRequest, [Admin, PermissionGroup, payload.id], { params: payload.data });
    yield put(getPermissionGroupSuccess(response.data, payload.key));
    yield put(changePermissionGroupLoadingState(false, payload.key));
  } catch (err) {
    if (err) {
      const error = getError(err, true);
      yield put(setErrorMessage(error));
    }
    yield put(changePermissionGroupLoadingState(false, payload.key));
  }
}

function* getPermissionGroups({ payload }) {
  try {
    const options = {
      params: payload.data,
      paramsSerializer: (param) => qs.stringify(param),
    };
    yield put(changePermissionGroupLoadingState(true));
    const { Admin, PermissionGroup } = AppConstants.api;
    const response = yield call(NetworkService.makeAPIGetRequest, [Admin, PermissionGroup], options);
    yield put(getPermissionGroupsSuccess(response.data));
    yield put(changePermissionGroupLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changePermissionGroupLoadingState(false));
  }
}

function* getAllPermissionGroups({ payload }) {
  const { isLoading, data } = payload;
  try {
    const { Admin, PermissionGroup, All } = AppConstants.api;
    if (isLoading) {
      yield put(changePermissionGroupLoadingState(true));
    }
    const response = yield call(NetworkService.makeAPIGetRequest, [Admin, PermissionGroup, All], {
      params: data,
    });
    yield put(getAllPermissionGroupsSuccess(response.data));
    if (isLoading) {
      yield put(changePermissionGroupLoadingState(false));
    }
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    if (isLoading) {
      yield put(changePermissionGroupLoadingState(false));
    }
  }
}

function* createPermissionGroup({ payload }) {
  try {
    const { name } = payload.data;
    const { Admin, PermissionGroup } = AppConstants.api;
    yield put(changeLoadingState(true));
    yield call(NetworkService.makeAPIPostRequest, [Admin, PermissionGroup], payload);
    yield put(setSuccessMessage(getMessage(i18n.t('userManagement:lPermissionGroup'), i18n.t('notification:slCreated'), name)));
    yield put(changeEndedStatus(true));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* editPermissionGroup({ payload }) {
  try {
    const { id, reqData } = payload.data;
    const { name } = reqData;
    const { Admin, PermissionGroup } = AppConstants.api;
    yield put(changeLoadingState(true));
    yield call(NetworkService.makeAPIPutRequest, [Admin, PermissionGroup, id], {
      data: reqData,
    });
    yield put(setSuccessMessage(getMessage(i18n.t('userManagement:lPermissionGroup'), i18n.t('notification:slUpdated'), name)));
    yield put(changeEndedStatus(true));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* deletePermissionGroup({ payload }) {
  try {
    const { id, name } = payload.data;
    yield put(changeLoadingState(true));
    const { Admin, PermissionGroup } = AppConstants.api;
    yield call(NetworkService.makeAPIDeleteRequest, [Admin, PermissionGroup, id]);
    const state = yield select();
    const permissionGroup = selectPermissionGroups(state.permissionGroup);
    const newData = permissionGroup.filter((el) => el.id !== id);
    yield put(deletePermissionGroupSuccess(newData));
    yield put(setSuccessMessage(getMessage(i18n.t('userManagement:lPermissionGroup'), i18n.t('notification:slDeleted'), name)));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

export default function* permissionGroupSaga() {
  yield all([
    takeEvery(PermissionGroupActionType.GET_PERMISSION_GROUP, getPermissionGroup),
    takeLatest(PermissionGroupActionType.GET_PERMISSION_GROUPS, getPermissionGroups),
    takeLatest(PermissionGroupActionType.GET_ALL_PERMISSION_GROUPS, getAllPermissionGroups),
    takeLatest(PermissionGroupActionType.CREATE_PERMISSION_GROUP, createPermissionGroup),
    takeLatest(PermissionGroupActionType.EDIT_PERMISSION_GROUP, editPermissionGroup),
    takeLatest(PermissionGroupActionType.DELETE_PERMISSION_GROUP, deletePermissionGroup),
  ]);
}
