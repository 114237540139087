import {
  useState, useEffect, useRef, useCallback,
} from 'react';
import PropTypes from 'prop-types';
import { Input, AutoComplete } from 'antd';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { StyledIconDefault } from 'components/styledComponents/icon/Icon.styled';
import {
  StyledDrawerWrapper,
  StyledSearchDetails,
  StyledProviderName,
  StyledBrandName,
  StyledSearchWrapper,
  StyledProviderDetails,
  StyledProviderType,
  StyledProviderDot,
  StyledPlayerDetails,
} from 'components/layout/Layout.styled';
import { LocalStorageService, NavigationService } from 'services';
import { IconSvg } from 'components/common';
import { onlyNumbersFormatter } from 'utils/utils';
import { convertDateArrayToJSON, getUrlSearchParams } from 'utils/helpers';
import Can from 'components/common/Can';
import { resource, types } from 'constants/accessControl';
import enumTypes from 'constants/enumTypes';
import { title } from 'constants/tabs/title';
import { useTimeZone } from 'hooks';
import qs from 'qs';

// setInLocalStorage is used when there is brandId in header, and you want to change it after receiving fastSearch result, but don't clear filters.

const SearchFields = (props) => {
  const {
    visible, changeModalState, getPlayerSearchResult, resetSearchResult, searchResult, searchKey, getBetSearchResult, getPaymentSearchResult, locale, setCurrentBrand, fieldAccesses,
  } = props;

  const { quickSearchResponseItemType } = locale;
  const { getDateTz, convertDateObjectToDateString } = useTimeZone();

  const { t } = useTranslation();

  const referenceCount = 1;

  const [drawerVisible, setDrawerVisible] = useState(false);

  useEffect(() => {
    setDrawerVisible(visible);
  }, [visible]);

  const keyCodes = {
    up: 38,
    down: 40,
  };

  const redirect = useCallback(
    (data, getUrl) => {
      setCurrentBrand(data.brandId);
      const currentData = LocalStorageService.get('current') || {};

      LocalStorageService.set('current', {
        ...currentData,
        brandId: data.brandId,
      });

      if (data.setInLocalStorage && !data.isOneMatch) {
        LocalStorageService.set('isFromFastSearch', true);
      }
      NavigationService(getUrl(data));
    },
    [setCurrentBrand],
  );
  const generatePlayerRow = (data, getUrl) => {
    const {
      id, firstName, lastName, brandName,
    } = data;

    return (
      <div onClick={() => redirect(data, getUrl)}>
        <StyledSearchWrapper>
          <StyledSearchDetails isMarginBottom>
            <div>{id}</div>
            <StyledBrandName>{brandName}</StyledBrandName>
          </StyledSearchDetails>
          <StyledPlayerDetails>{`${firstName || '-'} ${lastName || '-'}`}</StyledPlayerDetails>
        </StyledSearchWrapper>
      </div>
    );
  };

  const generatePaymentRow = (data, getUrl) => {
    const { id, brandName, type } = data;

    return (
      <div onClick={() => redirect(data, getUrl)}>
        <StyledSearchWrapper>
          <StyledSearchDetails>
            <div>{id}</div>
            <StyledBrandName>{brandName}</StyledBrandName>
          </StyledSearchDetails>
          <StyledProviderDetails>
            <StyledProviderType>{quickSearchResponseItemType[type]}</StyledProviderType>
          </StyledProviderDetails>
        </StyledSearchWrapper>
      </div>
    );
  };

  const generateBetRow = (data, getUrl) => {
    const {
      id, type, brandName, gameProviderName,
    } = data;

    return (
      <div onClick={() => redirect(data, getUrl)} key={data.type}>
        <StyledSearchWrapper>
          <StyledSearchDetails>
            <div>{id}</div>
            <StyledBrandName>{brandName}</StyledBrandName>
          </StyledSearchDetails>
          <StyledProviderDetails>
            <StyledProviderName>{gameProviderName}</StyledProviderName>
            {quickSearchResponseItemType[type] && (
              <>
                <StyledProviderDot />
                <StyledProviderType>{quickSearchResponseItemType[type]}</StyledProviderType>
              </>
            )}
          </StyledProviderDetails>
        </StyledSearchWrapper>
      </div>
    );
  };

  const searchFields = [
    {
      label: 'playerId',
      field: 'playerId',
      hide: !fieldAccesses.playerId,
      isNumeric: true,
      getSearchResult: getPlayerSearchResult,
      getUrl: (data) => `/players/${data.id}`,
      generateRow: generatePlayerRow,
    },
    {
      label: 'username',
      field: 'userName',
      hide: !fieldAccesses.username,
      showAsFiltered: true,
      getSearchResult: getPlayerSearchResult,
      getUrl: (data) => (data.isOneMatch ? `/players/${data.id}` : `/players?userName=${data.userNamePrefix}&expand=true`),
      generateRow: generatePlayerRow,
      setInLocalStorage: true,
    },
    {
      label: 'firstName',
      field: 'firstName',
      hide: !fieldAccesses.firstName,
      showAsFiltered: true,
      getSearchResult: getPlayerSearchResult,
      getUrl: (data) => (data.isOneMatch ? `/players/${data.id}` : `/players?firstName=${data.firstNamePrefix}&expand=true`),
      generateRow: generatePlayerRow,
      setInLocalStorage: true,
    },
    {
      label: 'lastName',
      field: 'lastName',
      hide: !fieldAccesses.lastName,
      showAsFiltered: true,
      getSearchResult: getPlayerSearchResult,
      getUrl: (data) => (data.isOneMatch ? `/players/${data.id}` : `/players?lastName=${data.lastNamePrefix}&expand=true`),
      generateRow: generatePlayerRow,
      setInLocalStorage: true,
    },
    {
      label: 'documentNumber',
      field: 'documentNumber',
      hide: !fieldAccesses.documentNumber,
      getSearchResult: getPlayerSearchResult,
      getUrl: (data) => `/players/${data.id}`,
      generateRow: generatePlayerRow,
    },
    {
      label: 'mobileNumber',
      field: 'mobileNumber',
      hide: !fieldAccesses.mobileNumber,
      getSearchResult: getPlayerSearchResult,
      getUrl: (data) => `/players/${data.id}`,
      generateRow: generatePlayerRow,
    },
    {
      label: 'email',
      field: 'email',
      hide: !fieldAccesses.email,
      showAsFiltered: true,
      getSearchResult: getPlayerSearchResult,
      getUrl: (data) => (data.isOneMatch ? `/players/${data.id}` : `/players?email=${data.emailPrefix}&expand=true`),
      generateRow: generatePlayerRow,
      setInLocalStorage: true,
    },
    {
      label: 'betId',
      field: 'id',
      isNumeric: true,
      hide: !fieldAccesses.betId,
      getSearchResult: getBetSearchResult,
      getUrl: (data) => {
        const isCasino = enumTypes.quickSearchResponseItemType.CasinoBet === data.type;
        const isPlayer = Can({
          type: types.view,
          rule: [`${resource.bets}.${resource.casinoBets}`, `${resource.bets}.${resource.sportBets}`],
          children: false,
          fallback: true,
        });
        if (isPlayer) {
          return `/players/${data.playerId}?tab=${title.player.bets}&type=${isCasino ? title.player.casino : title.player.sport}&${getUrlSearchParams({ brandId: data.brandId, betId: data.id })}`;
        }
        return `/bets/${isCasino ? 'casinoTxnDetails' : 'sportTxnDetails'}/${data.brandId}/${data.id}?tab=${
          isCasino ? title.bets.casino : title.bets.sport
        }&createdDateFrom=${convertDateObjectToDateString(getDateTz().subtract(3, 'months').startOf('day'))}`;
      },
      generateRow: generateBetRow,
    },
    {
      label: 'roundIdCouponNumber',
      field: 'roundId',
      hide: !fieldAccesses.roundId,
      getSearchResult: getBetSearchResult,
      getUrl: (data) => {
        const isCasino = enumTypes.quickSearchResponseItemType.CasinoBet === data.type;
        const isPlayer = Can({
          type: types.view,
          rule: [`${resource.bets}.${resource.casinoBets}`, `${resource.bets}.${resource.sportBets}`],
          children: false,
          fallback: true,
        });
        if (isPlayer) {
          return `/players/${data.playerId}?tab=${title.player.bets}&type=${isCasino ? title.player.casino : title.player.sport}&${getUrlSearchParams({ brandId: data.brandId, betId: data.id })}`;
        }
        return `/bets/${isCasino ? 'casinoTxnDetails' : 'sportTxnDetails'}/${data.brandId}/${data.id}?tab=${
          isCasino ? title.bets.casino : title.bets.sport
        }&createdDateFrom=${convertDateObjectToDateString(getDateTz().subtract(3, 'months').startOf('day'))}`;
      },
      generateRow: generateBetRow,
    },
    {
      label: 'paymentRequestId',
      field: 'paymentRequestId',
      isNumeric: true,
      hide: !fieldAccesses.paymentRequestId,
      getSearchResult: getPaymentSearchResult,
      getUrl: (data) => {
        const isDeposit = data.type === enumTypes.quickSearchResponseItemType.Deposit;
        const isPlayer = Can({
          type: types.view,
          rule: [`${resource.financials}.${resource.withdrawals}`, `${resource.financials}.${resource.deposits}`],
          children: false,
          fallback: true,
        });
        if (isPlayer) {
          return `/players/${data.playerId}?tab=${title.player.payments}&type=${isDeposit ? title.player.deposits : title.player.withdrawals}&transactionId=${
            data.id
          }&createdDateFrom=${convertDateObjectToDateString(getDateTz().subtract(6, 'months').startOf('day'))}`;
        }
        return `/${isDeposit ? 'deposits' : 'withdrawals'}?transactionId=${data.id}&${qs.stringify({ createdDate: convertDateArrayToJSON([getDateTz().subtract(6, 'months').startOf('day'), null]) })}`;
      },
      generateRow: generatePaymentRow,
    },
  ];

  const filteredFields = searchFields.filter((el) => !el.hide);

  const autoFocusInst = useRef({});
  const fieldsValue = {};
  filteredFields.forEach(({ field }) => {
    fieldsValue[field] = '';
  });
  const [values, setValues] = useState(fieldsValue);
  const [options, setOptions] = useState({});
  const [open, setOpen] = useState('');
  const [focus, setFocus] = useState('');
  const [current, setCurrent] = useState({});

  const handleClose = useCallback(() => {
    resetSearchResult();
    setOptions({});
    changeModalState(false);
    setCurrent({});
  }, [resetSearchResult, changeModalState]);

  const handleSelect = () => handleClose();

  const handleSearch = (field) => {
    const value = values[field];
    let tempCurrent = {};
    if (value && value.trim()) {
      // clear inputs
      const newValues = {};
      filteredFields.forEach((el) => {
        if (field === el.field) {
          newValues[el.field] = value;
          tempCurrent = _.cloneDeep(el);
          setCurrent(tempCurrent);
        } else {
          newValues[el.field] = '';
        }
      });
      setValues(newValues);
      // clear last options
      setOptions({});
      // search by field
      tempCurrent.getSearchResult(field, {
        [field]: value,
      });
    }
  };
  const handleChange = (field, value) => {
    const index = filteredFields.findIndex((el) => el.field === field);
    return setValues((prevValues) => ({
      ...prevValues,
      [field]: filteredFields[index].isNumeric ? onlyNumbersFormatter({ target: { value: value || '' } }) : value,
    }));
  };

  const handleVisibleChange = (isOpen) => {
    if (isOpen) {
      const firstFocus = filteredFields[0].field;
      setFocus(firstFocus);
    }
  };

  const handleKeyDown = (e) => {
    const { keyCode } = e;
    if (Object.values(keyCodes).includes(keyCode)) {
      let index = filteredFields.findIndex((el) => el.field === focus);
      if (e.keyCode === keyCodes.up && index) {
        index -= 1;
      } else if (e.keyCode === keyCodes.down && index < filteredFields.length - 1) {
        index += 1;
      }
      setFocus(filteredFields[index].field);
    }
  };

  useEffect(() => {
    if (focus) {
      autoFocusInst.current[focus].focus();
    }
  }, [focus]);

  useEffect(() => {
    if (!visible) {
      handleClose();
    }
  }, [visible, handleClose]);

  useEffect(() => {
    if (searchKey && current.field === searchKey && focus === searchKey) {
      let newOptions = [];
      if (searchResult.length) {
        if (searchResult.length === referenceCount || (current.showAsFiltered && searchResult.every(({ brandId }) => brandId === searchResult[0].brandId))) {
          handleClose();
          return redirect(
            {
              ...searchResult[0],
              [`${current.field}Prefix`]: values[current.field],
              isOneMatch: searchResult.length === referenceCount,
              setInLocalStorage: current.setInLocalStorage,
            },
            current.getUrl,
          );
        }
        newOptions = searchResult.map((el, index) => ({
          value: el.id.toString(),
          label: current.generateRow({ ...el, isOneMatch: true, [`${current.field}Prefix`]: values[current.field] }, current.getUrl),
          key: index,
        }));
      }
      setOptions({
        [searchKey]: newOptions,
      });
      setOpen(searchKey);
    }
  }, [focus, values, current, searchResult, searchKey, handleClose, redirect]);

  const doRender = () => filteredFields.map(({ label, field }) => (
    <div key={field}>
      <div>{t(label)}</div>
      <AutoComplete
        notFoundContent={t('noResults')}
        onChange={(value) => handleChange(field, value)}
        value={values[field]}
        allowClear
        options={options[field] || []}
        onSelect={handleSelect}
        open={open === field}
      >
        <Input
          ref={(el) => {
            autoFocusInst.current[field] = el;
          }}
          suffix={(
            <StyledIconDefault onClick={() => handleSearch(field)}>
              <IconSvg icon="SearchIcon" width="1.14rem" height="1.14rem" />
            </StyledIconDefault>
          )}
          onPressEnter={() => handleSearch(field)}
          size="large"
          placeholder="Type"
          onBlur={() => setOpen('')}
          onFocus={() => {
            setFocus(field);
            return options[field] ? setOpen(field) : null;
          }}
          onKeyDown={handleKeyDown}
        />
      </AutoComplete>
    </div>
  ));

  return (
    <StyledDrawerWrapper
      closeIcon={(
        <StyledIconDefault>
          <IconSvg icon="CloseModalIcon" width="1.14rem" height="1.14rem" />
        </StyledIconDefault>
      )}
      title={t('search')}
      placement="right"
      key="right"
      onClose={handleClose}
      open={drawerVisible}
      width="31.1rem"
      afterOpenChange={handleVisibleChange}
    >
      {doRender()}
    </StyledDrawerWrapper>
  );
};

SearchFields.propTypes = {
  getPlayerSearchResult: PropTypes.func.isRequired,
  getBetSearchResult: PropTypes.func.isRequired,
  getPaymentSearchResult: PropTypes.func.isRequired,
  setCurrentBrand: PropTypes.func.isRequired,
  resetSearchResult: PropTypes.func.isRequired,
  changeModalState: PropTypes.func.isRequired,
  visible: PropTypes.bool.isRequired,
  searchResult: PropTypes.array.isRequired,
  searchKey: PropTypes.string.isRequired,
  locale: PropTypes.object.isRequired,
  fieldAccesses: PropTypes.object.isRequired,
};

export default SearchFields;
