/**
 * useScroll React custom hook
 * @param   {scroll}     func     callback.
 * @param   {keepScrollPositionCallback}     func     callback function after which return scroll position.
 * Usage:
 *    const { node } = useScroll();
 */

import { useEffect } from 'react';

const useScroll = (scroll, keepScrollPositionCallback) => {
  const node = document.querySelector('.ant-layout-content');

  const keepScrollPosition = () => {
    if (node && keepScrollPositionCallback) {
      const { scrollTop } = node;
      keepScrollPositionCallback();
      node.scrollTop = scrollTop;
    }
  };

  useEffect(() => {
    if (node && scroll) {
      node.addEventListener('scroll', scroll);
      return () => {
        node.removeEventListener('scroll', scroll);
      };
    }
  });

  return {
    node,
    keepScrollPosition,
  };
};

export default useScroll;
