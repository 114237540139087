import { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Form, Grid, Input, Tooltip,
} from 'antd';
import qs from 'qs';
import { useLocation } from 'react-router-dom';
import {
  StyledAuthMainWrapper, StyledTitleForm, StyledFlexLabel, InfoIconWrapper,
} from 'components/auth/Login.styled';
import { StyledButtonLogin } from 'components/styledComponents/button/Button.styled';
import { AppConstants } from 'constants/index';
import { FormItemMarginTopWrapper } from 'Global.styled';
import { FormInputWrapper } from 'components/styledComponents/input/Input.styled';
import IconSvg from 'components/common/icon/IconSvg';
import { FormItemErrorMessage } from 'components/common';
import { StyledFormMainWrapper } from 'components/styledComponents/form/Form.styled';

const ModifyPassword = (props) => {
  const { send, title } = props;

  const location = useLocation();

  const [form] = Form.useForm();
  const { validateFields, getFieldValue } = form;

  const [confirmDirty, setConfirmDirty] = useState(false);

  const inputs = {
    newPassword: 'newPassword',
    confirmPassword: 'confirmPassword',
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const { code } = qs.parse(location.search, { ignoreQueryPrefix: true });
    validateFields().then((values) => {
      send({ ...values, code }, title.toLowerCase());
    });
  };

  const { useBreakpoint } = Grid;

  const screens = useBreakpoint();

  const handleConfirmBlur = (e) => {
    const { value } = e.target;
    setConfirmDirty(confirmDirty || !!value);
  };

  const content = <div>Only passwords with minimum of 8 characters including at least 1 number and 1 uppercase letter are accepted.</div>;

  return (
    <StyledAuthMainWrapper md={screens.md ? 1 : 0} xs={screens.xs ? 1 : 0}>
      <StyledFormMainWrapper layout="vertical" form={form}>
        <StyledTitleForm>{`${title} Password`}</StyledTitleForm>
        <FormInputWrapper>
          <Form.Item
            label={(
              <StyledFlexLabel>
                <div>New Password</div>
                <Tooltip placement="top" title={content}>
                  <InfoIconWrapper right="-2.14rem">
                    <IconSvg icon="InfoIcon" width="100%" height="100%" />
                  </InfoIconWrapper>
                </Tooltip>
              </StyledFlexLabel>
            )}
            name={inputs.newPassword}
            rules={[
              {
                required: true,
                message: <FormItemErrorMessage errorMassage="New password cannot be empty!" />,
              },
              {
                pattern: AppConstants.regex.password,
                message: <FormItemErrorMessage errorMassage="Password can be 8-40 characters long, including at least a number and uppercase letter." />,
              },
            ]}
          >
            <Input.Password type="password" placeholder="New Password" />
          </Form.Item>
        </FormInputWrapper>
        <FormItemMarginTopWrapper>
          <FormInputWrapper>
            <Form.Item
              label="Confirm Password"
              name={inputs.confirmPassword}
              dependencies={[inputs.newPassword]}
              rules={[
                {
                  required: true,
                  message: <FormItemErrorMessage errorMassage="Confirm password cannot be empty!" />,
                },
                () => ({
                  validator(rule, value) {
                    if (!value || getFieldValue(inputs.newPassword) === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(<FormItemErrorMessage errorMassage="Passwords do not match!" />);
                  },
                }),
              ]}
            >
              <Input.Password type="password" onBlur={handleConfirmBlur} placeholder="Confirm Password" />
            </Form.Item>
          </FormInputWrapper>
        </FormItemMarginTopWrapper>
        <StyledButtonLogin isMarginTop type="primary" htmlType="submit" onClick={handleSubmit}>
          Save
        </StyledButtonLogin>
      </StyledFormMainWrapper>
    </StyledAuthMainWrapper>
  );
};

ModifyPassword.propTypes = {
  send: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
};

export default ModifyPassword;
