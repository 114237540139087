import { useTranslation } from 'react-i18next';
import { useCallback, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import {
  StyledPermissionWrapper,
  StyledPermissionText,
  StyledPermissionTitle,
  StyledPermissionIcon,
  StyledPermissionSettings1,
  StyledPermissionSettings2,
} from 'components/common/noAccess/NoAccess.styled';
import IconSvg from 'components/common/icon/IconSvg';
import { NavigationService } from 'services';
import { StyledButtonBlue } from 'components/styledComponents/button/Button.styled';
import Monitor from 'assets/img/monitor.png';

const NoAccess = (props) => {
  const { permissions = {}, isSuperAdmin } = props;
  const { t } = useTranslation();

  const hideLayout = useCallback(() => {
    const root = document.querySelector('#root');
    root.querySelector('aside').classList.add('hidden');
    root.querySelector('.ant-layout-content').classList.add('no-padding');
  }, []);

  const showLayout = () => {
    const root = document.querySelector('#root');
    const aside = root.querySelector('aside');
    const content = root.querySelector('.ant-layout-content');
    if (aside) {
      aside.classList.remove('hidden');
    }
    if (content) {
      content.classList.remove('no-padding');
    }
  };

  const hasPermissions = useMemo(() => Object.keys(permissions).length || isSuperAdmin, [permissions, isSuperAdmin]);

  useEffect(() => {
    if (!hasPermissions) {
      hideLayout();
    }
    return () => showLayout();
  }, [hideLayout, hasPermissions]);

  return (
    <StyledPermissionWrapper>
      <StyledPermissionIcon>
        <img alt="" src={Monitor} />
        <StyledPermissionSettings1>
          <IconSvg icon="PermissionSettingsIcon" width="3rem" height="3rem" />
        </StyledPermissionSettings1>
        <StyledPermissionSettings2>
          <IconSvg icon="PermissionSettingsIcon" width="5rem" height="5rem" />
        </StyledPermissionSettings2>
      </StyledPermissionIcon>
      <StyledPermissionTitle>{t('pageCantBeDisplayed')}</StyledPermissionTitle>
      <StyledPermissionText>{t('youDontHavePermission')}</StyledPermissionText>
      <div>
        <div onClick={() => NavigationService(!hasPermissions ? 'noAccessAccount' : '/account')}>
          <StyledButtonBlue size="medium">{t('home')}</StyledButtonBlue>
        </div>
      </div>
    </StyledPermissionWrapper>
  );
};

NoAccess.propTypes = {
  permissions: PropTypes.object,
  isSuperAdmin: PropTypes.bool,
};

NoAccess.defaultProps = {
  permissions: {},
  isSuperAdmin: false,
};

export default NoAccess;
