import { Tooltip } from 'antd';
import PropTypes from 'prop-types';
import { withTheme } from 'styled-components';
import { IconSvg } from 'components/common';
import { StyledAnimationModeSun, StyledListItemHeader } from 'components/layout/Layout.styled';
import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';
import { LocalStorageService } from 'services';

const ThemeColorsMode = (props) => {
  const {
    isNightMode,
    changeMode,
    theme: { colors },
  } = props;

  const { t } = useTranslation();

  useEffect(() => {
    LocalStorageService.set('isNightMode', isNightMode);
  }, [isNightMode]);

  return (
    <StyledListItemHeader onClick={() => changeMode(!isNightMode)}>
      {isNightMode ? (
        <Tooltip placement="bottom" title={t('lightMode')}>
          <StyledAnimationModeSun>
            <IconSvg icon="BrightnessIcon" color={colors.white} />
          </StyledAnimationModeSun>
        </Tooltip>
      ) : (
        <div>
          <Tooltip placement="bottom" title={t('nightMode')}>
            <div>
              <IconSvg icon="NightModeIcon" width="1.14rem" height="1.14rem" color={colors.white} />
            </div>
          </Tooltip>
        </div>
      )}
    </StyledListItemHeader>
  );
};

ThemeColorsMode.propTypes = {
  isNightMode: PropTypes.bool,
  changeMode: PropTypes.func.isRequired,
  theme: PropTypes.object.isRequired,
};

ThemeColorsMode.defaultProps = {
  isNightMode: false,
};

export default withTheme(ThemeColorsMode);
