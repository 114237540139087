import ExportCenterActionType from 'actions/exportCenter/exportCenterActionType';

const initialState = {
  exportHistory: {},
};

const exportCenterReducer = (state = initialState, { type, payload }) => {
  switch (type) {
  case ExportCenterActionType.SAVE_EXPORT_HISTORY:
    return {
      ...state,
      exportHistory: payload.data,
    };
  default:
    return state;
  }
};

export default exportCenterReducer;
