import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { ChangeName } from 'components/brandSetting';
import { editBrand } from 'actions/brand/brandActionCreator';
import { selectBrand } from 'selectors';

const mapStateToProps = ({ brands }) => ({
  brand: selectBrand(brands),
});

const mapDispatchToProps = (dispatch) => bindActionCreators(
  {
    editBrand,
  },
  dispatch,
);

export default connect(mapStateToProps, mapDispatchToProps)(ChangeName);
