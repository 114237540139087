import { Input, Form } from 'antd';
import PropTypes from 'prop-types';
import {
  StyledFlexCorrect, StyledFlexEnd, StyledForgetLink, StyledTitleForm,
} from 'components/auth/Login.styled';
import { StyledButtonLogin } from 'components/styledComponents/button/Button.styled';
import { FormInputWrapper } from 'components/styledComponents/input/Input.styled';
import { FormItemMarginWrapper } from 'Global.styled';
import { FormItemErrorMessage } from 'components/common';
import { AppConstants } from 'constants/index';

const LoginStep1 = (props) => {
  const { handleSubmit } = props;

  return (
    <>
      <StyledTitleForm>Log in</StyledTitleForm>
      <FormItemMarginWrapper>
        <FormInputWrapper>
          <Form.Item
            label="Email"
            name="userName"
            rules={[
              {
                required: true,
                message: <FormItemErrorMessage errorMassage="Email cannot be empty!" />,
              },
              {
                pattern: AppConstants.regex.email,
                message: <FormItemErrorMessage errorMassage="The input is not a valid Email!" />,
              },
            ]}
          >
            <Input placeholder="Email" allowClear autoComplete="off" />
          </Form.Item>
        </FormInputWrapper>
      </FormItemMarginWrapper>
      <FormInputWrapper>
        <Form.Item
          label="Password"
          name="password"
          rules={[
            {
              required: true,
              message: <FormItemErrorMessage errorMassage="Password cannot be empty!" />,
            },
          ]}
        >
          <Input.Password type="password" placeholder="Password" autoComplete="off" />
        </Form.Item>
      </FormInputWrapper>
      <StyledFlexEnd>
        <StyledForgetLink to="/forgotPassword">Recover Password?</StyledForgetLink>
      </StyledFlexEnd>
      <StyledFlexCorrect>
        <StyledButtonLogin size="isMaxWidth" type="primary" htmlType="submit" onClick={handleSubmit}>
          LOG IN
        </StyledButtonLogin>
      </StyledFlexCorrect>
    </>
  );
};

LoginStep1.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
};

export default LoginStep1;
