const PromotionActionType = {
  GET_PROMOTIONS: 'GET_PROMOTIONS',
  SAVE_PROMOTIONS: 'SAVE_PROMOTIONS',
  RESET_PROMOTIONS: 'RESET_PROMOTIONS',

  GET_PROMOTION: 'GET_PROMOTION',
  SAVE_PROMOTION: 'SAVE_PROMOTION',
  RESET_PROMOTION: 'RESET_PROMOTION',

  CREATE_PROMOTION: 'CREATE_PROMOTION',
  EDIT_PROMOTION: 'EDIT_PROMOTION',
  SAVE_CREATED_PROMOTION_ID: 'SAVE_CREATED_PROMOTION_ID',
  CHANGE_GENERAL_CONFIG_ENDED_STATUS: 'CHANGE_GENERAL_CONFIG_ENDED_STATUS',

  GET_SPIN_DISTRIBUTIONS: 'GET_SPIN_DISTRIBUTIONS',
  SAVE_SPIN_DISTRIBUTIONS: 'SAVE_SPIN_DISTRIBUTIONS',
  RESET_SPIN_DISTRIBUTIONS: 'RESET_SPIN_DISTRIBUTIONS',

  GET_SPIN_DISTRIBUTION: 'GET_SPIN_DISTRIBUTION',
  SAVE_SPIN_DISTRIBUTION: 'SAVE_SPIN_DISTRIBUTION',
  RESET_SPIN_DISTRIBUTION: 'RESET_SPIN_DISTRIBUTION',

  CREATE_SPIN_DISTRIBUTION: 'CREATE_SPIN_DISTRIBUTION',
  EDIT_SPIN_DISTRIBUTION: 'EDIT_SPIN_DISTRIBUTION',
  DELETE_SPIN_DISTRIBUTION: 'DELETE_SPIN_DISTRIBUTION',

  CHANGE_SPIN_DISTRIBUTION_ENDED_STATUS: 'CHANGE_SPIN_DISTRIBUTION_ENDED_STATUS',

  GET_MACHINES: 'GET_MACHINES',
  SAVE_MACHINES: 'SAVE_MACHINES',
  RESET_MACHINES: 'RESET_MACHINES',

  GET_MACHINE: 'GET_MACHINE',
  SAVE_MACHINE: 'SAVE_MACHINE',
  RESET_MACHINE: 'RESET_MACHINE',

  CREATE_MACHINE: 'CREATE_MACHINE',
  EDIT_MACHINE: 'EDIT_MACHINE',
  DELETE_MACHINE: 'DELETE_MACHINE',

  GET_MACHINE_DETAILS: 'GET_MACHINE_DETAILS',
  SAVE_MACHINE_DETAILS: 'SAVE_MACHINE_DETAILS',
  RESET_MACHINE_DETAILS: 'RESET_MACHINE_DETAILS',

  CHANGE_MACHINE_ENDED_STATUS: 'CHANGE_MACHINE_ENDED_STATUS',

  GET_PLAYING_PARTICIPANTS: 'GET_PLAYING_PARTICIPANTS',
  SAVE_PLAYING_PARTICIPANTS: 'SAVE_PLAYING_PARTICIPANTS',
  RESET_PLAYING_PARTICIPANTS: 'RESET_PLAYING_PARTICIPANTS',

  GET_PLAYING_PARTICIPANT: 'GET_PLAYING_PARTICIPANT',
  SAVE_PLAYING_PARTICIPANT: 'SAVE_PLAYING_PARTICIPANT',
  RESET_PLAYING_PARTICIPANT: 'RESET_PLAYING_PARTICIPANT',

  CREATE_PLAYING_PARTICIPANT: 'CREATE_PLAYING_PARTICIPANT',
  EDIT_PLAYING_PARTICIPANT: 'EDIT_PLAYING_PARTICIPANT',
  DELETE_PLAYING_PARTICIPANT: 'DELETE_PLAYING_PARTICIPANT',

  CHANGE_PLAYING_PARTICIPANT_ENDED_STATUS: 'CHANGE_PLAYING_PARTICIPANT_ENDED_STATUS',

  GET_PRIZES: 'GET_PRIZES',
  SAVE_PRIZES: 'SAVE_PRIZES',
  RESET_PRIZES: 'RESET_PRIZES',

  GET_PRIZE: 'GET_PRIZE',
  SAVE_PRIZE: 'SAVE_PRIZE',
  RESET_PRIZE: 'RESET_PRIZE',

  CREATE_PRIZE: 'CREATE_PRIZE',
  EDIT_PRIZE: 'EDIT_PRIZE',
  DELETE_PRIZE: 'DELETE_PRIZE',

  CHANGE_PRIZE_ENDED_STATUS: 'CHANGE_PRIZE_ENDED_STATUS',

  GET_MACHINE_CONFIGS: 'GET_MACHINE_CONFIGS',
  SAVE_MACHINE_CONFIGS: 'SAVE_MACHINE_CONFIGS',
  RESET_MACHINE_CONFIGS: 'RESET_MACHINE_CONFIGS',

  GET_MACHINE_CONFIG: 'GET_MACHINE_CONFIG',
  SAVE_MACHINE_CONFIG: 'SAVE_MACHINE_CONFIG',
  RESET_MACHINE_CONFIG: 'RESET_MACHINE_CONFIG',

  CREATE_MACHINE_CONFIG: 'CREATE_MACHINE_CONFIG',
  EDIT_MACHINE_CONFIG: 'EDIT_MACHINE_CONFIG',
  DELETE_MACHINE_CONFIG: 'DELETE_MACHINE_CONFIG',

  CHANGE_MACHINE_CONFIG_ENDED_STATUS: 'CHANGE_MACHINE_CONFIG_ENDED_STATUS',

  GET_GROUP_LIMIT: 'GET_GROUP_LIMIT',
  SAVE_GROUP_LIMIT: 'SAVE_GROUP_LIMIT',
  RESET_GROUP_LIMIT: 'RESET_GROUP_LIMIT',

  CREATE_GROUP_LIMIT: 'CREATE_GROUP_LIMIT',
  EDIT_GROUP_LIMIT: 'EDIT_GROUP_LIMIT',
  DELETE_GROUP_LIMIT: 'DELETE_GROUP_LIMIT',

  CHANGE_GROUP_LIMIT_ENDED_STATUS: 'CHANGE_GROUP_LIMIT_ENDED_STATUS',

  PUBLISH_PROMOTION: 'PUBLISH_PROMOTION',
  CHANGE_PUBLISH_ENDED_STATUS: 'CHANGE_PUBLISH_ENDED_STATUS',

  DELETE_PROMOTION: 'DELETE_PROMOTION',
  FINISH_PROMOTION: 'FINISH_PROMOTION',

  GET_MACHINE_CONTENTS: 'GET_MACHINE_CONTENTS',
  SAVE_MACHINE_CONTENTS: 'SAVE_MACHINE_CONTENTS',
  RESET_MACHINE_CONTENTS: 'RESET_MACHINE_CONTENTS',

  GET_MACHINE_CONTENT: 'GET_MACHINE_CONTENT',
  SAVE_MACHINE_CONTENT: 'SAVE_MACHINE_CONTENT',
  RESET_MACHINE_CONTENT: 'RESET_MACHINE_CONTENT',

  CREATE_MACHINE_CONTENT: 'CREATE_MACHINE_CONTENT',
  EDIT_MACHINE_CONTENT: 'EDIT_MACHINE_CONTENT',
  DELETE_MACHINE_CONTENT: 'DELETE_MACHINE_CONTENT',

  CHANGE_MACHINE_CONTENT_ENDED_STATUS: 'CHANGE_MACHINE_CONTENT_ENDED_STATUS',

  CHANGE_PROMOTION_STATUS: 'CHANGE_PROMOTION_STATUS',

  CLONE_PROMOTION: 'CLONE_PROMOTION',

  GET_PROMOTION_TRANSACTIONS: 'GET_PROMOTION_TRANSACTIONS',
  SAVE_PROMOTION_TRANSACTIONS: 'SAVE_PROMOTION_TRANSACTIONS',
  RESET_PROMOTION_TRANSACTIONS: 'RESET_PROMOTION_TRANSACTIONS',
  EXPORT_PROMOTION_TRANSACTIONS: 'EXPORT_PROMOTION_TRANSACTIONS',

  GET_PROMOTION_MACHINES: 'GET_PROMOTION_MACHINES',
  SAVE_PROMOTION_MACHINES: 'SAVE_PROMOTION_MACHINES',
  RESET_PROMOTION_MACHINES: 'RESET_PROMOTION_MACHINES',
  EXPORT_PROMOTION_MACHINES: 'EXPORT_PROMOTION_MACHINES',

  GET_PROMOTION_PRIZES: 'GET_PROMOTION_PRIZES',
  SAVE_PROMOTION_PRIZES: 'SAVE_PROMOTION_PRIZES',
  RESET_PROMOTION_PRIZES: 'RESET_PROMOTION_PRIZES',
  EXPORT_PROMOTION_PRIZES: 'EXPORT_PROMOTION_PRIZES',

  GET_PROMOTION_PLAYERS: 'GET_PROMOTION_PLAYERS',
  SAVE_PROMOTION_PLAYERS: 'SAVE_PROMOTION_PLAYERS',
  RESET_PROMOTION_PLAYERS: 'RESET_PROMOTION_PLAYERS',
  EXPORT_PROMOTION_PLAYERS: 'EXPORT_PROMOTION_PLAYERS',

  GET_PROMOTION_CSV_PLAYERS: 'GET_PROMOTION_CSV_PLAYERS',
  SAVE_PROMOTION_CSV_PLAYERS: 'SAVE_PROMOTION_CSV_PLAYERS',
  RESET_PROMOTION_CSV_PLAYERS: 'RESET_PROMOTION_CSV_PLAYERS',
  CHANGE_PLAYERS_ENDED_STATUS: 'CHANGE_PLAYERS_ENDED_STATUS',

  ADJUST_MANUAL_SPIN: 'ADJUST_MANUAL_SPIN',

  GET_PROMOTION_MANUAL_SPIN_REPORT: 'GET_PROMOTION_MANUAL_SPIN_REPORT',
  SAVE_PROMOTION_MANUAL_SPIN_REPORT: 'SAVE_PROMOTION_MANUAL_SPIN_REPORT',
  RESET_PROMOTION_MANUAL_SPIN_REPORT: 'RESET_PROMOTION_MANUAL_SPIN_REPORT',

  GET_PROMOTION_SPIN_ADJUSTMENT_PLAYERS: 'GET_PROMOTION_SPIN_ADJUSTMENT_PLAYERS',
  SAVE_PROMOTION_SPIN_ADJUSTMENT_PLAYERS: 'SAVE_PROMOTION_SPIN_ADJUSTMENT_PLAYERS',
  RESET_PROMOTION_SPIN_ADJUSTMENT_PLAYERS: 'RESET_PROMOTION_SPIN_ADJUSTMENT_PLAYERS',

  ADJUST_PLAYER_MANUAL_SPIN: 'ADJUST_PLAYER_MANUAL_SPIN',

  GET_PLAYER_PROMOTION_SPINS: 'GET_PLAYER_PROMOTION_SPINS',
  SAVE_PLAYER_PROMOTION_SPINS: 'SAVE_PLAYER_PROMOTION_SPINS',
  RESET_PLAYER_PROMOTION_SPINS: 'RESET_PLAYER_PROMOTION_SPINS',

  GET_PLAYER_PROMOTION_TRANSACTIONS: 'GET_PLAYER_PROMOTION_TRANSACTIONS',
  SAVE_PLAYER_PROMOTION_TRANSACTIONS: 'SAVE_PLAYER_PROMOTION_TRANSACTIONS',
  RESET_PLAYER_PROMOTION_TRANSACTIONS: 'RESET_PLAYER_PROMOTION_TRANSACTIONS',

  GET_PROMOTION_PREVIEW_VALIDATION_STATE: 'GET_PROMOTION_PREVIEW_VALIDATION_STATE',
  SAVE_PROMOTION_PREVIEW_VALIDATION_STATE: 'SAVE_PROMOTION_PREVIEW_VALIDATION_STATE',
  RESET_PROMOTION_PREVIEW_VALIDATION_STATE: 'RESET_PROMOTION_PREVIEW_VALIDATION_STATE',

  GET_PROMOTION_CONTENT: 'GET_PROMOTION_CONTENT',
  SAVE_PROMOTION_CONTENT: 'SAVE_PROMOTION_CONTENT',
  RESET_PROMOTION_CONTENT: 'RESET_PROMOTION_CONTENT',

  DELETE_PROMOTION_CONTENT: 'DELETE_PROMOTION_CONTENT',
  CREATE_PROMOTION_CONTENT: 'CREATE_PROMOTION_CONTENT',
  EDIT_PROMOTION_CONTENT: 'EDIT_PROMOTION_CONTENT',

  GET_PROMOTION_CONTENTS: 'GET_PROMOTION_CONTENTS',
  SAVE_PROMOTION_CONTENTS: 'SAVE_PROMOTION_CONTENTS',
  RESET_PROMOTION_CONTENTS: 'RESET_PROMOTION_CONTENTS',

  CHANGE_PROMOTION_CONTENT_ENDED_STATUS: 'CHANGE_PROMOTION_CONTENT_ENDED_STATUS',

  GET_PRIVATE_PROMOTION_PARTICIPANTS: 'GET_PRIVATE_PROMOTION_PARTICIPANTS',
  SAVE_PRIVATE_PROMOTION_PARTICIPANTS: 'SAVE_PRIVATE_PROMOTION_PARTICIPANTS',
  RESET_PRIVATE_PROMOTION_PARTICIPANTS: 'RESET_PRIVATE_PROMOTION_PARTICIPANTS',
  EXPORT_PRIVATE_PROMOTION_PARTICIPANTS: 'EXPORT_PRIVATE_PROMOTION_PARTICIPANTS',

  GET_PROMOTION_PRIZE_MATERIAL_STATISTICS: 'GET_PROMOTION_PRIZE_MATERIAL_STATISTICS',
  SAVE_PROMOTION_PRIZE_MATERIAL_STATISTICS: 'SAVE_PROMOTION_PRIZE_MATERIAL_STATISTICS',
  RESET_PROMOTION_PRIZE_MATERIAL_STATISTICS: 'RESET_PROMOTION_PRIZE_MATERIAL_STATISTICS',
  EXPORT_PROMOTION_PRIZE_MATERIAL_STATISTICS: 'EXPORT_PROMOTION_PRIZE_MATERIAL_STATISTICS',

  GET_PROMOTION_PRIZE_MACHINE_SPIN_STATISTICS: 'GET_PROMOTION_PRIZE_MACHINE_SPIN_STATISTICS',
  SAVE_PROMOTION_PRIZE_MACHINE_SPIN_STATISTICS: 'SAVE_PROMOTION_PRIZE_MACHINE_SPIN_STATISTICS',
  RESET_PROMOTION_PRIZE_MACHINE_SPIN_STATISTICS: 'RESET_PROMOTION_PRIZE_MACHINE_SPIN_STATISTICS',
  EXPORT_PROMOTION_PRIZE_MACHINE_SPIN_STATISTICS: 'EXPORT_PROMOTION_PRIZE_MACHINE_SPIN_STATISTICS',

  GET_PROMOTION_PRIZE_BALANCE_MULTIPLIER_STATISTICS: 'GET_PROMOTION_PRIZE_BALANCE_MULTIPLIER_STATISTICS',
  SAVE_PROMOTION_PRIZE_BALANCE_MULTIPLIER_STATISTICS: 'SAVE_PROMOTION_PRIZE_BALANCE_MULTIPLIER_STATISTICS',
  RESET_PROMOTION_PRIZE_BALANCE_MULTIPLIER_STATISTICS: 'RESET_PROMOTION_PRIZE_BALANCE_MULTIPLIER_STATISTICS',
  EXPORT_PROMOTION_PRIZE_BALANCE_MULTIPLIER_STATISTICS: 'EXPORT_PROMOTION_PRIZE_BALANCE_MULTIPLIER_STATISTICS',

  CHANGE_MATERIAL_PRIZE_STATUS: 'CHANGE_MATERIAL_PRIZE_STATUS',

  GET_SPIN_DISTRIBUTION_CONTENTS: 'GET_SPIN_DISTRIBUTION_CONTENTS',
  SAVE_SPIN_DISTRIBUTION_CONTENTS: 'SAVE_SPIN_DISTRIBUTION_CONTENTS',
  RESET_SPIN_DISTRIBUTION_CONTENTS: 'RESET_SPIN_DISTRIBUTION_CONTENTS',

  GET_SPIN_DISTRIBUTION_CONTENT: 'GET_SPIN_DISTRIBUTION_CONTENT',
  SAVE_SPIN_DISTRIBUTION_CONTENT: 'SAVE_SPIN_DISTRIBUTION_CONTENT',
  RESET_SPIN_DISTRIBUTION_CONTENT: 'RESET_SPIN_DISTRIBUTION_CONTENT',

  CREATE_SPIN_DISTRIBUTION_CONTENT: 'CREATE_SPIN_DISTRIBUTION_CONTENT',
  EDIT_SPIN_DISTRIBUTION_CONTENT: 'EDIT_SPIN_DISTRIBUTION_CONTENT',
  DELETE_SPIN_DISTRIBUTION_CONTENT: 'DELETE_SPIN_DISTRIBUTION_CONTENT',

  CHANGE_SPIN_DISTRIBUTION_CONTENT_ENDED_STATUS: 'CHANGE_SPIN_DISTRIBUTION_CONTENT_ENDED_STATUS',

  GET_PROMOTION_SPINS: 'GET_PROMOTION_SPINS',
  SAVE_PROMOTION_SPINS: 'SAVE_PROMOTION_SPINS',
  RESET_PROMOTION_SPINS: 'RESET_PROMOTION_SPINS',
  EXPORT_PROMOTION_SPINS: 'EXPORT_PROMOTION_SPINS',

  GET_PROMOTION_SPIN_STATISTICS: 'GET_PROMOTION_SPIN_STATISTICS',
  SAVE_PROMOTION_SPIN_STATISTICS: 'SAVE_PROMOTION_SPIN_STATISTICS',
  RESET_PROMOTION_SPIN_STATISTICS: 'RESET_PROMOTION_SPIN_STATISTICS',
  EXPORT_PROMOTION_SPIN_STATISTICS: 'EXPORT_PROMOTION_SPIN_STATISTICS',

  CHANGE_ORDER_MACHINE: 'CHANGE_ORDER_MACHINE',

  GET_PROMOTION_EXCHANGE_ITEMS: 'GET_PROMOTION_EXCHANGE_ITEMS',
  SAVE_PROMOTION_EXCHANGE_CENTER: 'SAVE_PROMOTION_EXCHANGE_CENTER',
  RESET_PROMOTION_EXCHANGE_CENTER: 'RESET_PROMOTION_EXCHANGE_CENTER',

  GET_PROMOTION_EXCHANGE_ITEM: 'GET_PROMOTION_EXCHANGE_ITEM',
  SAVE_PROMOTION_EXCHANGE_ITEM: 'SAVE_PROMOTION_EXCHANGE_ITEM',
  RESET_PROMOTION_EXCHANGE_ITEM: 'RESET_PROMOTION_EXCHANGE_ITEM',

  CREATE_PROMOTION_EXCHANGE_ITEM: 'CREATE_PROMOTION_EXCHANGE_ITEM',
  EDIT_PROMOTION_EXCHANGE_ITEM: 'EDIT_PROMOTION_EXCHANGE_ITEM',
  DELETE_PROMOTION_EXCHANGE_ITEM: 'DELETE_PROMOTION_EXCHANGE_ITEM',

  CHANGE_PROMOTION_EXCHANGE_CENTER_ENDED_STATUS: 'CHANGE_PROMOTION_EXCHANGE_CENTER_ENDED_STATUS',

  GET_PROMOTION_PRIZE_CONTENTS: 'GET_PROMOTION_PRIZE_CONTENTS',
  SAVE_PROMOTION_PRIZE_CONTENTS: 'SAVE_PROMOTION_PRIZE_CONTENTS',
  RESET_PROMOTION_PRIZE_CONTENTS: 'RESET_PROMOTION_PRIZE_CONTENTS',

  GET_PROMOTION_PRIZE_CONTENT: 'GET_PROMOTION_PRIZE_CONTENT',
  SAVE_PROMOTION_PRIZE_CONTENT: 'SAVE_PROMOTION_PRIZE_CONTENT',
  RESET_PROMOTION_PRIZE_CONTENT: 'RESET_PROMOTION_PRIZE_CONTENT',

  CREATE_PROMOTION_PRIZE_CONTENT: 'CREATE_PROMOTION_PRIZE_CONTENT',
  EDIT_PROMOTION_PRIZE_CONTENT: 'EDIT_PROMOTION_PRIZE_CONTENT',
  DELETE_PROMOTION_PRIZE_CONTENT: 'DELETE_PROMOTION_PRIZE_CONTENT',

  CHANGE_PROMOTION_PRIZE_CONTENT_ENDED_STATUS: 'CHANGE_PROMOTION_PRIZE_CONTENT_ENDED_STATUS',

  GET_PROMOTION_PRIZE_CONTENT_CONNECTED: 'GET_PROMOTION_PRIZE_CONTENT_CONNECTED',
  SAVE_PROMOTION_PRIZE_CONTENT_CONNECTED: 'SAVE_PROMOTION_PRIZE_CONTENT_CONNECTED',
  RESET_PROMOTION_PRIZE_CONTENT_CONNECTED: 'RESET_PROMOTION_PRIZE_CONTENT_CONNECTED',

  GET_PROMOTION_EXCHANGES: 'GET_PROMOTION_EXCHANGES',
  SAVE_PROMOTION_EXCHANGES: 'SAVE_PROMOTION_EXCHANGES',
  RESET_PROMOTION_EXCHANGES: 'RESET_PROMOTION_EXCHANGES',
  EXPORT_PROMOTION_EXCHANGES: 'EXPORT_PROMOTION_EXCHANGES',

  GET_PROMOTION_CHANGE_LOGS: 'GET_PROMOTION_CHANGE_LOGS',
  SAVE_PROMOTION_CHANGE_LOGS: 'SAVE_PROMOTION_CHANGE_LOGS',
  RESET_PROMOTION_CHANGE_LOGS: 'RESET_PROMOTION_CHANGE_LOGS',

  GET_PROMOTION_CHANGE_LOG: 'GET_PROMOTION_CHANGE_LOG',
  SAVE_PROMOTION_CHANGE_LOG: 'SAVE_PROMOTION_CHANGE_LOG',
  RESET_PROMOTION_CHANGE_LOG: 'RESET_PROMOTION_CHANGE_LOG',

  ADD_PRIVATE_PARTICIPANTS: 'ADD_PRIVATE_PARTICIPANTS',
};

export default PromotionActionType;
