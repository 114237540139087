import {
  Form, Input, Row, Col,
} from 'antd';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { withTheme } from 'styled-components';
import { useTranslation } from 'react-i18next';
import { NavigationService } from 'services';
import { FormItemErrorMessage } from 'components/common';
import { AppConstants } from 'constants/index';
import { StyledButtonBlue, StyledButtonBlueUnbordered } from 'components/styledComponents/button/Button.styled';
import { StyledCreateBrand } from 'components/brand/Brand.styled';
import { StyledClearIcon } from 'components/styledComponents/input/Input.styled';
import { AdminSelect } from 'components/common/form';

const { Item } = Form;
const BasicSettings = (props) => {
  const {
    next,
    setData,
    data,
    form,
    languages,
    countries,
    currencies,
    getLanguages,
    getCurrencies,
    getCountries,
    loading,
    changeLoadingState,
    getMarkets,
    markets,
    getIsBrandExist,
    partnerId,
    isBrandExist,
    resetIsBrandExist,
    timeZones,
    getTimeZones,
  } = props;

  const { t } = useTranslation();

  const {
    validateFields, getFieldsValue, setFieldsValue, setFields,
  } = form;

  useEffect(() => {
    if (markets.length && !data.market) {
      setFieldsValue({ market: markets[0].id });
    }
  }, [data.market, markets, markets.length, setFieldsValue]);

  const [isNextClicked, setIsNextClicked] = useState(false);
  const [isPending, setIsPending] = useState(false);

  const inputs = {
    name: 'name',
    countryId: 'countryId',
    languageId: 'languageId',
    currencyId: 'currencyId',
    market: 'market',
    timeZone: 'timeZone',
  };

  useEffect(() => {
    getLanguages(true);
    getCurrencies(true);
    getCountries(true);
    getMarkets();
    getTimeZones(true);
  }, [getLanguages, getCurrencies, getCountries, getMarkets, getTimeZones]);

  useEffect(() => {
    changeLoadingState(loading);
  }, [loading, changeLoadingState]);

  useEffect(() => {
    if (!_.isNull(isBrandExist)) {
      setIsPending(false);
    }
  }, [isBrandExist]);

  const validateForm = (e) => {
    e.preventDefault();
    setIsNextClicked(true);
    const { [inputs.name]: brandName } = getFieldsValue();
    if (brandName && brandName.match(AppConstants.regex.lN3to50)) {
      getIsBrandExist(partnerId, brandName);
      setIsPending(true);
      resetIsBrandExist();
    }
    validateFields();
  };

  useEffect(() => {
    if (isNextClicked && !isPending && !isBrandExist) {
      validateFields()
        .then((values) => {
          setIsNextClicked(false);
          setData({ ...data, ...values });
          next();
        })
        .catch(() => {
          setIsNextClicked(false);
        });
    }
  }, [data, getFieldsValue, isBrandExist, isNextClicked, isPending, next, setData, validateFields]);

  useEffect(
    () => () => {
      resetIsBrandExist();
    },
    [resetIsBrandExist],
  );

  useEffect(() => {
    if (!isPending) {
      setFields([
        {
          name: inputs.name,
          errors: isBrandExist ? [<FormItemErrorMessage errorMassage={(translate) => translate('notification:brandExist')} />] : [],
        },
      ]);
    }
  }, [t, inputs.name, isBrandExist, isPending, setFields]);

  return (
    <StyledCreateBrand>
      <Row type="flex" justify="center">
        <Col span={24} sm={17} lg={11}>
          <StyledClearIcon>
            <Item
              label={t('brand:brandName')}
              name={inputs.name}
              rules={[
                {
                  required: true,
                  message: <FormItemErrorMessage errorMassage={(translate) => translate('validate:requiredField', { fieldName: translate('brand:brandName') })} />,
                },
                {
                  pattern: AppConstants.regex.brandName,
                  message: <FormItemErrorMessage errorMassage={(translate) => translate('validate:brandName', { fieldName: translate('brand:brandName') })} />,
                },
              ]}
            >
              <Input placeholder={t('typeHere')} autoComplete="off" allowClear />
            </Item>
          </StyledClearIcon>
          <Item
            label={t('brand:defaultCountry')}
            rules={[
              {
                required: true,
                message: <FormItemErrorMessage errorMassage={(translate) => translate('validate:requiredField', { fieldName: translate('brand:defaultCountry') })} />,
              },
            ]}
            name={inputs.countryId}
          >
            <AdminSelect placeholder={t('select')} size="" allowClear={false} popupClassName="select-scrollable" data={countries} isToString={false} />
          </Item>
          <Item
            label={t('timeZone')}
            rules={[
              {
                required: true,
                message: <FormItemErrorMessage errorMassage={(translate) => translate('validate:requiredField', { fieldName: translate('timeZone') })} />,
              },
            ]}
            name={inputs.timeZone}
          >
            <AdminSelect
              placeholder={t('select')}
              size=""
              allowClear={false}
              popupClassName="select-scrollable"
              filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
              data={timeZones}
              isToString={false}
            />
          </Item>
          <Item
            label={t('brand:defaultLanguage')}
            name={inputs.languageId}
            rules={[
              {
                required: true,
                message: <FormItemErrorMessage errorMassage={(translate) => translate('validate:requiredField', { fieldName: translate('brand:defaultLanguage') })} />,
              },
            ]}
          >
            <AdminSelect placeholder={t('select')} size="" allowClear={false} popupClassName="select-scrollable" data={languages} isToString={false} />
          </Item>
          <Item
            label={t('brand:defaultCurrency')}
            name={inputs.currencyId}
            rules={[
              {
                required: true,
                message: <FormItemErrorMessage errorMassage={(translate) => translate('validate:requiredField', { fieldName: translate('brand:defaultCurrency') })} />,
              },
            ]}
          >
            <AdminSelect placeholder={t('select')} size="" allowClear={false} popupClassName="select-scrollable" data={currencies} isToString={false} />
          </Item>
          <Item label={t('market')} name={inputs.market}>
            <AdminSelect placeholder={t('select')} size="" allowClear={false} popupClassName="select-scrollable" data={markets} isToString={false} />
          </Item>
        </Col>
      </Row>
      <Row gutter={16} type="flex" justify="center">
        <Col>
          <StyledButtonBlueUnbordered type="default" onClick={() => NavigationService('/brands')} size="large">
            {t('cancel')}
          </StyledButtonBlueUnbordered>
        </Col>
        <Col>
          <Item>
            <StyledButtonBlue type="default" onClick={validateForm} size="large">
              {t('next')}
            </StyledButtonBlue>
          </Item>
        </Col>
      </Row>
    </StyledCreateBrand>
  );
};

BasicSettings.propTypes = {
  next: PropTypes.func.isRequired,
  setData: PropTypes.func.isRequired,
  form: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
  languages: PropTypes.array.isRequired,
  countries: PropTypes.array.isRequired,
  currencies: PropTypes.array.isRequired,
  timeZones: PropTypes.array.isRequired,
  getLanguages: PropTypes.func.isRequired,
  getCurrencies: PropTypes.func.isRequired,
  getCountries: PropTypes.func.isRequired,
  getTimeZones: PropTypes.func.isRequired,
  changeLoadingState: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  getMarkets: PropTypes.func.isRequired,
  markets: PropTypes.array,
  getIsBrandExist: PropTypes.func.isRequired,
  partnerId: PropTypes.number,
  isBrandExist: PropTypes.bool,
  resetIsBrandExist: PropTypes.func.isRequired,
};

BasicSettings.defaultProps = {
  loading: false,
  markets: [],
  partnerId: null,
  isBrandExist: null,
};

export default withTheme(BasicSettings);
