import {
  all, call, put, takeLatest,
} from 'redux-saga/effects';
import ReleaseNoteActionType from 'actions/releaseNote/releaseNoteActionType';
import {
  changeLoadingState, setErrorMessage, changeEndedStatus, setSuccessMessage,
} from 'actions/setting/settingActionCreator';
import { saveReleaseNotes, saveReleaseNote, saveLatestReleaseNote } from 'actions/releaseNote/releaseNoteActionCreator';
import NetworkService from 'services/networkService';
import { getError, getMessage } from 'utils/helpers';
import { AppConstants } from 'constants/index';
import { i18n } from 'services';

function* getReleaseNotes({ payload }) {
  try {
    const { DataCollector, ReleaseNotes } = AppConstants.api;
    yield put(changeLoadingState(true));
    const { data } = yield call(NetworkService.makeAPIGetRequest, [DataCollector, ReleaseNotes], payload);
    yield put(saveReleaseNotes(data));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getReleaseNote({ payload }) {
  try {
    const { DataCollector, ReleaseNotes } = AppConstants.api;
    const { id } = payload;
    yield put(changeLoadingState(true));
    const { data } = yield call(NetworkService.makeAPIGetRequest, [DataCollector, ReleaseNotes, id]);
    yield put(saveReleaseNote(data));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* createReleaseNote({ payload }) {
  try {
    const { name } = payload.data;
    const { DataCollector, ReleaseNotes } = AppConstants.api;
    yield put(changeLoadingState(true));
    yield call(NetworkService.makeAPIPostRequest, [DataCollector, ReleaseNotes], payload);
    yield put(setSuccessMessage(getMessage(i18n.t('tool:package'), i18n.t('notification:slSent'), name)));
    yield put(changeLoadingState(false));
    yield put(changeEndedStatus(true));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* editReleaseNote({ payload }) {
  try {
    const { id, data } = payload;
    const { name } = data;
    const { DataCollector, ReleaseNotes } = AppConstants.api;
    yield put(changeLoadingState(true));
    yield call(NetworkService.makeAPIPutRequest, [DataCollector, ReleaseNotes, id], payload);
    yield put(setSuccessMessage(getMessage(i18n.t('tool:package'), i18n.t('notification:slSent'), name)));
    yield put(changeLoadingState(false));
    yield put(changeEndedStatus(true));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* deleteReleaseNote({ payload }) {
  try {
    const { id, data } = payload;
    const { name } = data;
    const { DataCollector, ReleaseNotes } = AppConstants.api;
    yield put(changeLoadingState(true));
    yield call(NetworkService.makeAPIDeleteRequest, [DataCollector, ReleaseNotes, id]);
    yield put(setSuccessMessage(getMessage(i18n.t('tool:lRule'), i18n.t('notification:slDeleted'), name)));
    yield put(changeLoadingState(false));
    yield put(changeEndedStatus(true));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* changeReleaseNoteStatus({ payload }) {
  try {
    const { id, data } = payload;
    const { name, isEnabled } = data;
    const { DataCollector, ReleaseNotes, Status } = AppConstants.api;
    yield put(changeLoadingState(true));
    yield call(NetworkService.makeAPIPutRequest, [DataCollector, ReleaseNotes, id, Status], payload);
    yield put(setSuccessMessage(getMessage(i18n.t('tool:note'), i18n.t(!isEnabled ? 'notification:slDisabled' : 'notification:slEnabled'), name)));
    yield put(changeLoadingState(false));
    yield put(changeEndedStatus(true));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* markAsSeenReleaseNote({ payload }) {
  try {
    const { id } = payload;
    const { DataCollector, ReleaseNotes, Seen } = AppConstants.api;
    yield put(changeLoadingState(true));
    yield call(NetworkService.makeAPIPutRequest, [DataCollector, ReleaseNotes, id, Seen]);
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getLatestReleaseNote() {
  try {
    const { DataCollector, ReleaseNotes, Latest } = AppConstants.api;
    const { data } = yield call(NetworkService.makeAPIGetRequest, [DataCollector, ReleaseNotes, Latest]);
    yield put(saveLatestReleaseNote(data));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

export default function* releaseNoteSaga() {
  yield all([
    takeLatest(ReleaseNoteActionType.GET_RELEASE_NOTES, getReleaseNotes),
    takeLatest(ReleaseNoteActionType.GET_RELEASE_NOTE, getReleaseNote),
    takeLatest(ReleaseNoteActionType.CREATE_RELEASE_NOTE, createReleaseNote),
    takeLatest(ReleaseNoteActionType.EDIT_RELEASE_NOTE, editReleaseNote),
    takeLatest(ReleaseNoteActionType.DELETE_RELEASE_NOTE, deleteReleaseNote),
    takeLatest(ReleaseNoteActionType.CHANGE_RELEASE_NOTE_STATUS, changeReleaseNoteStatus),
    takeLatest(ReleaseNoteActionType.MARK_AS_SEEN_RELEASE_NOTE, markAsSeenReleaseNote),
    takeLatest(ReleaseNoteActionType.GET_LATEST_RELEASE_NOTE, getLatestReleaseNote),
  ]);
}
