import PropTypes from 'prop-types';
import { SelectTag, PopoverTag } from './tag';

const Tags = (props) => {
  const { title, data, type } = props;

  const renderTags = () => data.map((tag) => (type === 'select' ? (
    <SelectTag data={tag} key={tag.key} />
  ) : (
    <PopoverTag data={tag} key={tag.key} />
  )));

  return (
    <>
      <div>{title}</div>
      {renderTags()}
    </>
  );
};

Tags.propTypes = {
  title: PropTypes.string,
  data: PropTypes.array.isRequired,
  type: PropTypes.string,
};

Tags.defaultProps = {
  title: null,
  type: null,
};
export default Tags;
