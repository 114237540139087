const ReleaseNoteActionType = {
  GET_RELEASE_NOTES: 'GET_RELEASE_NOTES',
  SAVE_RELEASE_NOTES: 'SAVE_RELEASE_NOTES',
  RESET_RELEASE_NOTES: 'RESET_RELEASE_NOTES',

  GET_RELEASE_NOTE: 'GET_RELEASE_NOTE',
  SAVE_RELEASE_NOTE: 'SAVE_RELEASE_NOTE',
  RESET_RELEASE_NOTE: 'RESET_RELEASE_NOTE',

  CREATE_RELEASE_NOTE: 'CREATE_RELEASE_NOTE',

  DELETE_RELEASE_NOTE: 'DELETE_RELEASE_NOTE',
  EDIT_RELEASE_NOTE: 'EDIT_RELEASE_NOTE',
  CHANGE_RELEASE_NOTE_STATUS: 'CHANGE_RELEASE_NOTE_STATUS',
  MARK_AS_SEEN_RELEASE_NOTE: 'MARK_AS_SEEN_RELEASE_NOTE',

  GET_LATEST_RELEASE_NOTE: 'GET_LATEST_RELEASE_NOTE',
  SAVE_LATEST_RELEASE_NOTE: 'SAVE_LATEST_RELEASE_NOTE',
  RESET_LATEST_RELEASE_NOTE: 'RESET_LATEST_RELEASE_NOTE',
};
export default ReleaseNoteActionType;
