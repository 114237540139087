import LegalAgreementActionType from './legalAgreementActionType';

export const getBrandLegalAgreementsVersions = (params) => ({
  type: LegalAgreementActionType.GET_BRAND_LEGAL_AGREEMENTS_VERSIONS,
  payload: { params },
});

export const saveBrandLegalAgreementsVersions = (data) => ({
  type: LegalAgreementActionType.SAVE_BRAND_LEGAL_AGREEMENTS_VERSIONS,
  payload: { data },
});

export const resetBrandLegalAgreementsVersions = () => ({
  type: LegalAgreementActionType.RESET_BRAND_LEGAL_AGREEMENTS_VERSIONS,
});

export const getBrandLegalAgreementsPolicies = (params, isLoading) => ({
  type: LegalAgreementActionType.GET_BRAND_LEGAL_AGREEMENTS_POLICIES,
  payload: { params, isLoading },
});

export const saveBrandLegalAgreementsPolicies = (data) => ({
  type: LegalAgreementActionType.SAVE_BRAND_LEGAL_AGREEMENTS_POLICIES,
  payload: { data },
});

export const resetBrandLegalAgreementsPolicies = () => ({
  type: LegalAgreementActionType.RESET_BRAND_LEGAL_AGREEMENTS_POLICIES,
});

export const deleteBrandLegalAgreementsVersion = (legalAgreementVersionId, params) => ({
  type: LegalAgreementActionType.DELETE_BRAND_LEGAL_AGREEMENTS_VERSION,
  payload: { legalAgreementVersionId, params },
});

export const addBrandLegalAgreementsVersion = (data) => ({
  type: LegalAgreementActionType.ADD_BRAND_LEGAL_AGREEMENTS_VERSION,
  payload: { data },
});

export const editBrandLegalAgreementsVersion = (legalAgreementVersionId, data) => ({
  type: LegalAgreementActionType.EDIT_BRAND_LEGAL_AGREEMENTS_VERSION,
  payload: { legalAgreementVersionId, data },
});

export const saveBrandLegalAgreementsNotPendingPolicies = (data) => ({
  type: LegalAgreementActionType.SAVE_BRAND_LEGAL_AGREEMENTS_NOT_PENDING_POLICIES,
  payload: { data },
});

export const resetBrandLegalAgreementsNotPendingPolicies = () => ({
  type: LegalAgreementActionType.RESET_BRAND_LEGAL_AGREEMENTS_NOT_PENDING_POLICIES,
});

export const getPlayerLegalAgreementsVersions = (params) => ({
  type: LegalAgreementActionType.GET_PLAYER_LEGAL_AGREEMENTS_VERSIONS,
  payload: { params },
});

export const savePlayerLegalAgreementsVersions = (data) => ({
  type: LegalAgreementActionType.SAVE_PLAYER_LEGAL_AGREEMENTS_VERSIONS,
  payload: { data },
});

export const resetPlayerLegalAgreementsVersions = () => ({
  type: LegalAgreementActionType.RESET_PLAYER_LEGAL_AGREEMENTS_VERSIONS,
});
