import { useState, useEffect } from 'react';
import { Radio } from 'antd';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { StyledIconDefault } from 'components/styledComponents/icon/Icon.styled';
import { StyledRadioList, StyledRadioHover } from 'components/styledComponents/radio/Radio.styled';
import IconSvg from './icon/IconSvg';

const RadioGroup = (props) => {
  const {
    options, onChange, color, colorText, value, width, height, translationNamespace,
  } = props;

  const { t } = useTranslation();

  const [selected, setSelected] = useState(value);

  useEffect(() => {
    setSelected(value);
  }, [value]);

  const handleChange = (event) => {
    onChange(event.target.value);
  };

  const doRender = (rows) => {
    const content = [];
    rows.forEach((row) => {
      if (!row.hide) {
        content.push(
          <StyledRadioHover key={row.value}>
            <Radio.Button value={row.value}>
              <StyledIconDefault>
                <IconSvg icon={row.icon} width={row.iconWidth || width} height={row.iconHeight || height} />
              </StyledIconDefault>
            </Radio.Button>
            <p>{t(translationNamespace ? `${translationNamespace}:${row.title}` : row.title)}</p>
          </StyledRadioHover>,
        );
      }
    });
    return content;
  };

  return (
    <Radio.Group onChange={handleChange} value={selected}>
      <StyledRadioList color={color} colorText={colorText}>
        {doRender(options)}
      </StyledRadioList>
    </Radio.Group>
  );
};

RadioGroup.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      value: PropTypes.any.isRequired,
      icon: PropTypes.string.isRequired,
      onChange: PropTypes.func,
    }),
  ).isRequired,
  onChange: PropTypes.func,
  color: PropTypes.string.isRequired,
  colorText: PropTypes.string.isRequired,
  value: PropTypes.any,
  width: PropTypes.string,
  height: PropTypes.string,
  translationNamespace: PropTypes.string,
};

RadioGroup.defaultProps = {
  onChange: () => {},
  value: undefined,
  width: '1rem',
  height: '1rem',
  translationNamespace: 'title',
};

export default RadioGroup;
