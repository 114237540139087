import {
  all, call, put, takeLatest,
} from '@redux-saga/core/effects';
import qs from 'qs';
import mime from 'mimetypes';
import CampaignActionType from 'actions/campaign/campaignActionType';
import { AppConstants } from 'constants/index';
import { downloadFile } from 'utils/utils';
import {
  saveCampaigns,
  saveCampaign,
  saveCampaignTriggerConditions,
  saveCampaignPlayers,
  saveCampaignReceivedDepositStatistics,
  saveCampaignReceivedVerificationStatistics,
  saveCampaignReceivedSignUpStatistics,
  saveCampaignReceivedNoTriggerStatistics,
  saveCrossPlatformCampaigns,
  saveCrossPlatformCampaign,
  saveCrossPlatformCampaignNoTriggerStatistics,
  saveCampaignContentConfig,
  changeContentConfigLoadingState,
  changeCampaignLoadingState,
  saveCampaignParticipantPlayers,
  changeCampaignPlayersLoadingState,
  saveCrossPlatformCampaignContentConfig,
  saveCampaignFailedDepositStatistics,
  saveCampaignFailedVerificationStatistics,
  saveCampaignFailedSignUpStatistics,
  saveCampaignFailedNoTriggerStatistics,
  saveCampaignReceivedSignInStatistics,
  saveCampaignFailedSignInStatistics,
  saveCrossPlatformCampaignUsages,
} from 'actions/campaign/campaignActionCreator';
import {
  changeLoadingState, setErrorMessage, setSuccessMessage, changeEndedStatus,
} from 'actions/setting/settingActionCreator';
import NetworkService from 'services/networkService';
import { i18n } from 'services';
import { getError, getMessage } from 'utils/helpers';
import enumTypes from 'constants/enumTypes';

function* createCampaign({ payload }) {
  try {
    const { name } = payload.data;
    const { BonusAdmin, Campaigns } = AppConstants.api;
    yield put(changeLoadingState(true));
    const { data } = yield call(NetworkService.makeAPIPostRequest, [BonusAdmin, Campaigns], payload);
    const message = data?.status === enumTypes.bonusStatuses.InProcess ? i18n.t('bonus:campaignWillBeSavedInBgInfo') : getMessage(i18n.t('bonus:lCampaign'), i18n.t('notification:slCreated'), name);
    yield put(setSuccessMessage(message));
    yield put(changeLoadingState(false));
    yield put(changeEndedStatus(true));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getCampaigns({ payload }) {
  try {
    const { params } = payload;
    const options = {
      params,
      paramsSerializer: (param) => qs.stringify(param),
    };
    const { BonusAdmin, Campaigns } = AppConstants.api;
    yield put(changeLoadingState(true));
    const { data } = yield call(NetworkService.makeAPIGetRequest, [BonusAdmin, Campaigns], options);
    yield put(saveCampaigns(data));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
  }
}

function* getCampaign({ payload }) {
  const { id, isLoading } = payload;
  try {
    const { BonusAdmin, Campaigns } = AppConstants.api;

    yield put(changeCampaignLoadingState(true));
    if (isLoading) {
      yield put(changeLoadingState(true));
    }

    const { data } = yield call(NetworkService.makeAPIGetRequest, [BonusAdmin, Campaigns, id]);

    yield put(saveCampaign(data));

    yield put(changeCampaignLoadingState(false));
    if (isLoading) {
      yield put(changeLoadingState(false));
    }
  } catch (err) {
    if (err) {
      const error = getError(err, true);
      yield put(setErrorMessage(error));
    }
    yield put(changeCampaignLoadingState(false));
    if (isLoading) {
      yield put(changeLoadingState(false));
    }
  }
}

function* getCampaignTriggerConditions({ payload }) {
  try {
    const { type } = payload;
    const { BonusAdmin, Campaigns, TriggerConditions } = AppConstants.api;
    const { data } = yield call(NetworkService.makeAPIGetRequest, [BonusAdmin, Campaigns, TriggerConditions, type]);
    yield put(saveCampaignTriggerConditions(data));
  } catch (err) {
    if (err) {
      const error = getError(err, true);
      yield put(setErrorMessage(error));
    }
  }
}

function* editCampaign({ payload }) {
  try {
    const { id, data } = payload;
    const { name } = data;
    const { BonusAdmin, Campaigns } = AppConstants.api;
    yield put(changeLoadingState(true));
    yield call(NetworkService.makeAPIPutRequest, [BonusAdmin, Campaigns, id], { data });
    yield put(setSuccessMessage(getMessage(i18n.t('bonus:lCampaign'), i18n.t('notification:slUpdated'), name)));
    yield put(changeLoadingState(false));
    yield put(changeEndedStatus(true));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* deleteCampaign({ payload }) {
  try {
    const { id, data } = payload;
    const { name } = data;
    const { BonusAdmin, Campaigns } = AppConstants.api;
    yield put(changeLoadingState(true));
    yield call(NetworkService.makeAPIDeleteRequest, [BonusAdmin, Campaigns, id]);
    yield put(changeEndedStatus(true));
    yield put(setSuccessMessage(getMessage(i18n.t('bonus:lCampaign'), i18n.t('notification:slDeleted'), name)));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getCampaignPlayers({ payload }) {
  try {
    const { id, params } = payload;
    const options = {
      params,
      paramsSerializer: (param) => qs.stringify(param),
    };
    const { BonusAdmin, Campaigns, Players } = AppConstants.api;
    const { data } = yield call(NetworkService.makeAPIGetRequest, [BonusAdmin, Campaigns, id, Players], options);
    yield put(saveCampaignPlayers(data));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
  }
}

function* editCampaignPlayers({ payload }) {
  try {
    const { id, data } = payload;
    const { name } = data;
    const { BonusAdmin, Campaigns, Players } = AppConstants.api;
    yield put(changeLoadingState(true));
    yield call(NetworkService.makeAPIPutRequest, [BonusAdmin, Campaigns, id, Players], { data });
    yield put(setSuccessMessage(getMessage(i18n.t('bonus:campaignParticipants'), i18n.t('notification:plAdded'), name)));
    yield put(changeLoadingState(false));
    yield put(changeEndedStatus(true));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* finishCampaign({ payload }) {
  try {
    const { id, data } = payload;
    const { name } = data;
    const { BonusAdmin, Campaigns, Finish } = AppConstants.api;
    yield put(changeLoadingState(true));
    yield call(NetworkService.makeAPIPutRequest, [BonusAdmin, Campaigns, id, Finish]);
    yield put(changeEndedStatus(true));
    yield put(setSuccessMessage(getMessage(i18n.t('bonus:lCampaign'), i18n.t('notification:slFinished'), name)));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* cancelCampaign({ payload }) {
  try {
    const { id, data } = payload;
    const { name } = data;
    const { BonusAdmin, Campaigns, Cancel } = AppConstants.api;
    yield put(changeLoadingState(true));
    yield call(NetworkService.makeAPIPutRequest, [BonusAdmin, Campaigns, id, Cancel]);
    yield put(changeEndedStatus(true));
    yield put(setSuccessMessage(getMessage(i18n.t('bonus:lCampaign'), i18n.t('notification:slCancelled'), name)));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* cancelCampaignPromotion({ payload }) {
  try {
    const { id, data } = payload;
    const { name } = data;
    const { BonusAdmin, Campaigns, CancelPromotion } = AppConstants.api;
    yield put(changeLoadingState(true));
    yield call(NetworkService.makeAPIPutRequest, [BonusAdmin, Campaigns, id, CancelPromotion]);
    yield put(changeEndedStatus(true));
    yield put(setSuccessMessage(getMessage(i18n.t('bonus:lCampaign'), i18n.t('notification:slPromotionCancelled'), name)));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getCampaignReceivedDepositStatistics({ payload }) {
  try {
    const { id, params } = payload;
    const options = {
      params: { ...params, campaignId: id },
      paramsSerializer: (param) => qs.stringify(param),
    };
    const {
      BonusAdmin, Campaigns, Deposit, Statistics, Received,
    } = AppConstants.api;
    const { data } = yield call(NetworkService.makeAPIGetRequest, [BonusAdmin, Campaigns, id, Deposit, Statistics, Received], options);
    yield put(saveCampaignReceivedDepositStatistics(data));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
  }
}

function* getCampaignFailedDepositStatistics({ payload }) {
  try {
    const { id, params } = payload;
    const options = {
      params: { ...params, campaignId: id },
      paramsSerializer: (param) => qs.stringify(param),
    };
    const {
      BonusAdmin, Campaigns, Deposit, Statistics, Failed,
    } = AppConstants.api;
    const { data } = yield call(NetworkService.makeAPIGetRequest, [BonusAdmin, Campaigns, id, Deposit, Statistics, Failed], options);
    yield put(saveCampaignFailedDepositStatistics(data));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
  }
}

function* exportCampaignReceivedDepositStatistics({ payload }) {
  try {
    const { id, params } = payload;
    const options = {
      params,
      paramsSerializer: (param) => qs.stringify(param, { allowDots: true }),
      responseType: 'blob',
    };
    yield put(changeLoadingState(true));
    const {
      BonusAdmin, Campaigns, Deposit, Statistics, Received, Export,
    } = AppConstants.api;
    const { data, headers } = yield call(NetworkService.makeAPIGetRequest, [BonusAdmin, Campaigns, id, Deposit, Statistics, Received, Export], options);
    downloadFile(data, i18n.t('bonus:campaignStatistics'), mime.detectExtension(headers['content-type']));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getCampaignReceivedVerificationStatistics({ payload }) {
  try {
    const { id, params } = payload;
    const options = {
      params: { ...params, campaignId: id },
      paramsSerializer: (param) => qs.stringify(param),
    };
    const {
      BonusAdmin, Campaigns, Verify, Statistics, Received,
    } = AppConstants.api;
    const { data } = yield call(NetworkService.makeAPIGetRequest, [BonusAdmin, Campaigns, id, Verify, Statistics, Received], options);
    yield put(saveCampaignReceivedVerificationStatistics(data));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
  }
}

function* exportCampaignReceivedVerificationStatistics({ payload }) {
  try {
    const { id, params } = payload;
    const options = {
      params,
      paramsSerializer: (param) => qs.stringify(param, { allowDots: true }),
      responseType: 'blob',
    };
    yield put(changeLoadingState(true));
    const {
      BonusAdmin, Campaigns, Verify, Statistics, Received, Export,
    } = AppConstants.api;
    const { data, headers } = yield call(NetworkService.makeAPIGetRequest, [BonusAdmin, Campaigns, id, Verify, Statistics, Received, Export], options);
    downloadFile(data, i18n.t('bonus:campaignStatistics'), mime.detectExtension(headers['content-type']));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getCampaignReceivedSignUpStatistics({ payload }) {
  try {
    const { id, params } = payload;
    const options = {
      params: { ...params, campaignId: id },
      paramsSerializer: (param) => qs.stringify(param),
    };
    const {
      BonusAdmin, Campaigns, SignUp, Statistics, Received,
    } = AppConstants.api;
    const { data } = yield call(NetworkService.makeAPIGetRequest, [BonusAdmin, Campaigns, id, SignUp, Statistics, Received], options);
    yield put(saveCampaignReceivedSignUpStatistics(data));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
  }
}

function* exportCampaignReceivedSignUpStatistics({ payload }) {
  try {
    const { id, params } = payload;
    const options = {
      params,
      paramsSerializer: (param) => qs.stringify(param, { allowDots: true }),
      responseType: 'blob',
    };
    yield put(changeLoadingState(true));
    const {
      BonusAdmin, Campaigns, SignUp, Statistics, Received, Export,
    } = AppConstants.api;
    const { data, headers } = yield call(NetworkService.makeAPIGetRequest, [BonusAdmin, Campaigns, id, SignUp, Statistics, Received, Export], options);
    downloadFile(data, i18n.t('bonus:campaignStatistics'), mime.detectExtension(headers['content-type']));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getCampaignReceivedNoTriggerStatistics({ payload }) {
  try {
    const { id, params } = payload;
    const options = {
      params: { ...params, campaignId: id },
      paramsSerializer: (param) => qs.stringify(param),
    };
    const {
      BonusAdmin, Campaigns, NoTrigger, Statistics, Received,
    } = AppConstants.api;
    const { data } = yield call(NetworkService.makeAPIGetRequest, [BonusAdmin, Campaigns, id, NoTrigger, Statistics, Received], options);
    yield put(saveCampaignReceivedNoTriggerStatistics(data));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
  }
}

function* exportCampaignReceivedNoTriggerStatistics({ payload }) {
  try {
    const { id, params } = payload;
    const options = {
      params,
      paramsSerializer: (param) => qs.stringify(param, { allowDots: true }),
      responseType: 'blob',
    };
    yield put(changeLoadingState(true));
    const {
      BonusAdmin, Campaigns, NoTrigger, Statistics, Received, Export,
    } = AppConstants.api;
    const { data, headers } = yield call(NetworkService.makeAPIGetRequest, [BonusAdmin, Campaigns, id, NoTrigger, Statistics, Received, Export], options);
    downloadFile(data, i18n.t('bonus:campaignStatistics'), mime.detectExtension(headers['content-type']));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* createCrossPlatformCampaign({ payload }) {
  try {
    const { name } = payload.data;
    const { BonusAdmin, CampaignsCrossPlatform } = AppConstants.api;
    yield put(changeLoadingState(true));
    yield call(NetworkService.makeAPIPostRequest, [BonusAdmin, CampaignsCrossPlatform], payload);
    yield put(setSuccessMessage(getMessage(i18n.t('bonus:lCampaign'), i18n.t('notification:slCreated'), name)));
    yield put(changeLoadingState(false));
    yield put(changeEndedStatus(true));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getCrossPlatformCampaigns({ payload }) {
  try {
    const { params } = payload;
    const options = {
      params,
      paramsSerializer: (param) => qs.stringify(param),
    };
    const { BonusAdmin, CampaignsCrossPlatform } = AppConstants.api;
    yield put(changeLoadingState(true));
    const { data } = yield call(NetworkService.makeAPIGetRequest, [BonusAdmin, CampaignsCrossPlatform], options);
    yield put(saveCrossPlatformCampaigns(data));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
  }
}

function* getCrossPlatformCampaign({ payload }) {
  try {
    const { BonusAdmin, CampaignsCrossPlatform } = AppConstants.api;
    yield put(changeCampaignLoadingState(true));
    const { data } = yield call(NetworkService.makeAPIGetRequest, [BonusAdmin, CampaignsCrossPlatform, payload.id]);
    yield put(saveCrossPlatformCampaign(data));
    yield put(changeCampaignLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err, true);
      yield put(setErrorMessage(error));
    }
    yield put(changeCampaignLoadingState(false));
  }
}

function* finishCrossPlatformCampaign({ payload }) {
  try {
    const { id, data } = payload;
    const { name } = data;
    const { BonusAdmin, CampaignsCrossPlatform, Finish } = AppConstants.api;
    yield put(changeLoadingState(true));
    yield call(NetworkService.makeAPIPutRequest, [BonusAdmin, CampaignsCrossPlatform, id, Finish]);
    yield put(changeEndedStatus(true));
    yield put(setSuccessMessage(getMessage(i18n.t('bonus:lCampaign'), i18n.t('notification:slFinished'), name)));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* cancelCrossPlatformCampaign({ payload }) {
  try {
    const { id, data } = payload;
    const { name } = data;
    const { BonusAdmin, CampaignsCrossPlatform, Cancel } = AppConstants.api;
    yield put(changeLoadingState(true));
    yield call(NetworkService.makeAPIPutRequest, [BonusAdmin, CampaignsCrossPlatform, id, Cancel]);
    yield put(changeEndedStatus(true));
    yield put(setSuccessMessage(getMessage(i18n.t('bonus:lCampaign'), i18n.t('notification:slCancelled'), name)));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* cancelCrossPlatformCampaignPromotion({ payload }) {
  try {
    const { id, data } = payload;
    const { name } = data;
    const { BonusAdmin, CampaignsCrossPlatform, CancelPromotion } = AppConstants.api;
    yield put(changeLoadingState(true));
    yield call(NetworkService.makeAPIPutRequest, [BonusAdmin, CampaignsCrossPlatform, id, CancelPromotion]);
    yield put(changeEndedStatus(true));
    yield put(setSuccessMessage(getMessage(i18n.t('bonus:lCampaign'), i18n.t('notification:slPromotionCancelled'), name)));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getCrossPlatformCampaignNoTriggerStatistics({ payload }) {
  try {
    const { id, params } = payload;
    const options = {
      params: { ...params, campaignId: id },
      paramsSerializer: (param) => qs.stringify(param),
    };
    const {
      BonusAdmin, CampaignsCrossPlatform, NoTrigger, Statistics,
    } = AppConstants.api;
    const { data } = yield call(NetworkService.makeAPIGetRequest, [BonusAdmin, CampaignsCrossPlatform, id, NoTrigger, Statistics], options);
    yield put(saveCrossPlatformCampaignNoTriggerStatistics(data));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
  }
}

function* getCrossPlatformCampaignUsages({ payload }) {
  try {
    const { id, params } = payload;
    const options = {
      params: { ...params, campaignId: id },
    };
    yield put(changeLoadingState(true));
    const { BonusAdmin, CampaignsCrossPlatform, Usages } = AppConstants.api;
    const { data } = yield call(NetworkService.makeAPIGetRequest, [BonusAdmin, CampaignsCrossPlatform, id, Usages], options);
    yield put(saveCrossPlatformCampaignUsages(data));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* exportCrossPlatformCampaignNoTriggerStatistics({ payload }) {
  try {
    const { id, params } = payload;
    const options = {
      params,
      paramsSerializer: (param) => qs.stringify(param, { allowDots: true }),
      responseType: 'blob',
    };
    yield put(changeLoadingState(true));
    const {
      BonusAdmin, CampaignsCrossPlatform, NoTrigger, Statistics, Export,
    } = AppConstants.api;
    const { data, headers } = yield call(NetworkService.makeAPIGetRequest, [BonusAdmin, CampaignsCrossPlatform, id, NoTrigger, Statistics, Export], options);
    downloadFile(data, i18n.t('bonus:campaignStatistics'), mime.detectExtension(headers['content-type']));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getCampaignContentConfig({ payload }) {
  try {
    const { id } = payload;
    const { BonusAdmin, Campaigns, ContentConfig } = AppConstants.api;
    yield put(changeContentConfigLoadingState(true));
    const { data } = yield call(NetworkService.makeAPIGetRequest, [BonusAdmin, Campaigns, id, ContentConfig]);
    yield put(saveCampaignContentConfig(data));
    yield put(changeContentConfigLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeContentConfigLoadingState(false));
  }
}

function* editCampaignContentConfig({ payload }) {
  try {
    const { id, name, data } = payload;
    const { BonusAdmin, Campaigns, ContentConfig } = AppConstants.api;
    yield put(changeLoadingState(true));
    yield call(NetworkService.makeAPIPutRequest, [BonusAdmin, Campaigns, id, ContentConfig], { data });
    yield put(setSuccessMessage(getMessage(i18n.t('bonus:campaignParticipants'), i18n.t('notification:plUpdated'), name)));
    yield put(changeLoadingState(false));
    yield put(changeEndedStatus(true));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getCampaignParticipantPlayers({ payload }) {
  try {
    const { id } = payload;
    const { BonusAdmin, Campaigns, Participants } = AppConstants.api;
    yield put(changeCampaignPlayersLoadingState(true));

    const { data } = yield call(NetworkService.makeAPIGetRequest, [BonusAdmin, Campaigns, id, Participants]);
    yield put(saveCampaignParticipantPlayers(data));
    yield put(changeCampaignPlayersLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeCampaignPlayersLoadingState(false));
  }
}

function* getCrossPlatformCampaignContent({ payload }) {
  try {
    const { id } = payload;
    const { BonusAdmin, CampaignsCrossPlatform, ContentConfig } = AppConstants.api;
    yield put(changeContentConfigLoadingState(true));
    const { data } = yield call(NetworkService.makeAPIGetRequest, [BonusAdmin, CampaignsCrossPlatform, id, ContentConfig]);
    yield put(saveCrossPlatformCampaignContentConfig(data));
    yield put(changeContentConfigLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeContentConfigLoadingState(false));
  }
}

function* editCrossPlatformCampaignConfig({ payload }) {
  try {
    const { id, name, data } = payload;
    const { BonusAdmin, CampaignsCrossPlatform, ContentConfig } = AppConstants.api;
    yield put(changeLoadingState(true));
    yield call(NetworkService.makeAPIPutRequest, [BonusAdmin, CampaignsCrossPlatform, id, ContentConfig], { data });
    yield put(setSuccessMessage(getMessage(i18n.t('bonus:campaignParticipants'), i18n.t('notification:plUpdated'), name)));
    yield put(changeLoadingState(false));
    yield put(changeEndedStatus(true));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getCampaignFailedVerificationStatistics({ payload }) {
  try {
    const { id, params } = payload;
    const options = {
      params: { ...params, campaignId: id },
      paramsSerializer: (param) => qs.stringify(param),
    };
    const {
      BonusAdmin, Campaigns, Verify, Statistics, Failed,
    } = AppConstants.api;
    const { data } = yield call(NetworkService.makeAPIGetRequest, [BonusAdmin, Campaigns, id, Verify, Statistics, Failed], options);
    yield put(saveCampaignFailedVerificationStatistics(data));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
  }
}

function* getCampaignFailedSignUpStatistics({ payload }) {
  try {
    const { id, params } = payload;
    const options = {
      params: { ...params, campaignId: id },
      paramsSerializer: (param) => qs.stringify(param),
    };
    const {
      BonusAdmin, Campaigns, SignUp, Statistics, Failed,
    } = AppConstants.api;
    const { data } = yield call(NetworkService.makeAPIGetRequest, [BonusAdmin, Campaigns, id, SignUp, Statistics, Failed], options);
    yield put(saveCampaignFailedSignUpStatistics(data));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
  }
}

function* getCampaignFailedNoTriggerStatistics({ payload }) {
  try {
    const { id, params } = payload;
    const options = {
      params: { ...params, campaignId: id },
      paramsSerializer: (param) => qs.stringify(param),
    };
    const {
      BonusAdmin, Campaigns, NoTrigger, Statistics, Failed,
    } = AppConstants.api;
    const { data } = yield call(NetworkService.makeAPIGetRequest, [BonusAdmin, Campaigns, id, NoTrigger, Statistics, Failed], options);
    yield put(saveCampaignFailedNoTriggerStatistics(data));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
  }
}

function* getCampaignReceivedSignInStatistics({ payload }) {
  try {
    const { id, params } = payload;
    const options = {
      params: { ...params, campaignId: id },
      paramsSerializer: (param) => qs.stringify(param),
    };
    const {
      BonusAdmin, Campaigns, SignIn, Statistics, Received,
    } = AppConstants.api;
    const { data } = yield call(NetworkService.makeAPIGetRequest, [BonusAdmin, Campaigns, id, SignIn, Statistics, Received], options);
    yield put(saveCampaignReceivedSignInStatistics(data));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
  }
}

function* exportCampaignReceivedSignInStatistics({ payload }) {
  try {
    const { id, params } = payload;
    const options = {
      params,
      paramsSerializer: (param) => qs.stringify(param, { allowDots: true }),
      responseType: 'blob',
    };
    yield put(changeLoadingState(true));
    const {
      BonusAdmin, Campaigns, SignIn, Statistics, Received, Export,
    } = AppConstants.api;
    const { data, headers } = yield call(NetworkService.makeAPIGetRequest, [BonusAdmin, Campaigns, id, SignIn, Statistics, Received, Export], options);
    downloadFile(data, i18n.t('bonus:campaignStatistics'), mime.detectExtension(headers['content-type']));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getCampaignFailedSignInStatistics({ payload }) {
  try {
    const { id, params } = payload;
    const options = {
      params: { ...params, campaignId: id },
      paramsSerializer: (param) => qs.stringify(param),
    };
    const {
      BonusAdmin, Campaigns, SignIn, Statistics, Failed,
    } = AppConstants.api;
    const { data } = yield call(NetworkService.makeAPIGetRequest, [BonusAdmin, Campaigns, id, SignIn, Statistics, Failed], options);
    yield put(saveCampaignFailedSignInStatistics(data));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
  }
}

export default function* campaignSaga() {
  yield all([
    takeLatest(CampaignActionType.CREATE_CAMPAIGN, createCampaign),
    takeLatest(CampaignActionType.GET_CAMPAIGNS, getCampaigns),
    takeLatest(CampaignActionType.GET_CAMPAIGN, getCampaign),
    takeLatest(CampaignActionType.GET_CAMPAIGN_TRIGGER_CONDITIONS, getCampaignTriggerConditions),
    takeLatest(CampaignActionType.EDIT_CAMPAIGN, editCampaign),
    takeLatest(CampaignActionType.DELETE_CAMPAIGN, deleteCampaign),
    takeLatest(CampaignActionType.FINISH_CAMPAIGN, finishCampaign),
    takeLatest(CampaignActionType.CANCEL_CAMPAIGN, cancelCampaign),
    takeLatest(CampaignActionType.CANCEL_CAMPAIGN_PROMOTION, cancelCampaignPromotion),
    takeLatest(CampaignActionType.GET_CAMPAIGN_PLAYERS, getCampaignPlayers),
    takeLatest(CampaignActionType.EDIT_CAMPAIGN_PLAYERS, editCampaignPlayers),
    takeLatest(CampaignActionType.GET_CAMPAIGN_RECEIVED_DEPOSIT_STATISTICS, getCampaignReceivedDepositStatistics),
    takeLatest(CampaignActionType.EXPORT_CAMPAIGN_RECEIVED_DEPOSIT_STATISTICS, exportCampaignReceivedDepositStatistics),
    takeLatest(CampaignActionType.GET_CAMPAIGN_RECEIVED_VERIFICATION_STATISTICS, getCampaignReceivedVerificationStatistics),
    takeLatest(CampaignActionType.EXPORT_CAMPAIGN_RECEIVED_VERIFICATION_STATISTICS, exportCampaignReceivedVerificationStatistics),
    takeLatest(CampaignActionType.GET_CAMPAIGN_RECEIVED_SIGN_UP_STATISTICS, getCampaignReceivedSignUpStatistics),
    takeLatest(CampaignActionType.EXPORT_CAMPAIGN_RECEIVED_SIGN_UP_STATISTICS, exportCampaignReceivedSignUpStatistics),
    takeLatest(CampaignActionType.GET_CAMPAIGN_RECEIVED_NO_TRIGGER_STATISTICS, getCampaignReceivedNoTriggerStatistics),
    takeLatest(CampaignActionType.EXPORT_CAMPAIGN_RECEIVED_NO_TRIGGER_STATISTICS, exportCampaignReceivedNoTriggerStatistics),
    takeLatest(CampaignActionType.CREATE_CROSS_PLATFORM_CAMPAIGN, createCrossPlatformCampaign),
    takeLatest(CampaignActionType.GET_CROSS_PLATFORM_CAMPAIGNS, getCrossPlatformCampaigns),
    takeLatest(CampaignActionType.GET_CROSS_PLATFORM_CAMPAIGN, getCrossPlatformCampaign),
    takeLatest(CampaignActionType.FINISH_CROSS_PLATFORM_CAMPAIGN, finishCrossPlatformCampaign),
    takeLatest(CampaignActionType.CANCEL_CROSS_PLATFORM_CAMPAIGN, cancelCrossPlatformCampaign),
    takeLatest(CampaignActionType.CANCEL_CROSS_PLATFORM_CAMPAIGN_PROMOTION, cancelCrossPlatformCampaignPromotion),
    takeLatest(CampaignActionType.GET_CROSS_PLATFORM_CAMPAIGN_NO_TRIGGER_STATISTICS, getCrossPlatformCampaignNoTriggerStatistics),
    takeLatest(CampaignActionType.GET_CROSS_PLATFORM_CAMPAIGN_USAGES, getCrossPlatformCampaignUsages),
    takeLatest(CampaignActionType.EXPORT_CROSS_PLATFORM_CAMPAIGN_NO_TRIGGER_STATISTICS, exportCrossPlatformCampaignNoTriggerStatistics),
    takeLatest(CampaignActionType.GET_CAMPAIGN_CONTENT_CONFIG, getCampaignContentConfig),
    takeLatest(CampaignActionType.EDIT_CAMPAIGN_CONTENT_CONFIG, editCampaignContentConfig),
    takeLatest(CampaignActionType.GET_CAMPAIGN_PARTICIPANT_PLAYERS, getCampaignParticipantPlayers),
    takeLatest(CampaignActionType.GET_CROSS_PLATFORM_CONTENT_CONFIG, getCrossPlatformCampaignContent),
    takeLatest(CampaignActionType.EDIT_CROSS_PLATFORM_CONTENT_CONFIG, editCrossPlatformCampaignConfig),
    takeLatest(CampaignActionType.GET_CAMPAIGN_FAILED_DEPOSIT_STATISTICS, getCampaignFailedDepositStatistics),
    takeLatest(CampaignActionType.GET_CAMPAIGN_FAILED_VERIFICATION_STATISTICS, getCampaignFailedVerificationStatistics),
    takeLatest(CampaignActionType.GET_CAMPAIGN_FAILED_SIGN_UP_STATISTICS, getCampaignFailedSignUpStatistics),
    takeLatest(CampaignActionType.GET_CAMPAIGN_FAILED_NO_TRIGGER_STATISTICS, getCampaignFailedNoTriggerStatistics),
    takeLatest(CampaignActionType.GET_CAMPAIGN_RECEIVED_SIGN_IN_STATISTICS, getCampaignReceivedSignInStatistics),
    takeLatest(CampaignActionType.EXPORT_CAMPAIGN_RECEIVED_SIGN_IN_STATISTICS, exportCampaignReceivedSignInStatistics),
    takeLatest(CampaignActionType.GET_CAMPAIGN_FAILED_SIGN_IN_STATISTICS, getCampaignFailedSignInStatistics),
  ]);
}
