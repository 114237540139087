import BrandMarketActionType from 'actions/brandMarket/brandMarketActionType';

const initialState = {
  marketGeneralSettings: {},
  verificationConfigurations: {},
  verificationDocumentTypes: {},
  verificationPeriod: {},
  unverifiedPlayersDepositLimits: {},
};

const brandMarketReducer = (state = initialState, { type, payload }) => {
  switch (type) {
  case BrandMarketActionType.SAVE_BRAND_MARKET_GENERAL_SETTINGS:
    return {
      ...state,
      marketGeneralSettings: payload.data,
    };
  case BrandMarketActionType.RESET_BRAND_MARKET_GENERAL_SETTINGS:
    return {
      ...state,
      marketGeneralSettings: {},
    };
  case BrandMarketActionType.SAVE_BRAND_VERIFICATION_CONFIGURATIONS:
    return {
      ...state,
      verificationConfigurations: payload.data,
    };
  case BrandMarketActionType.RESET_BRAND_VERIFICATION_CONFIGURATIONS:
    return {
      ...state,
      verificationConfigurations: {},
    };
  case BrandMarketActionType.SAVE_BRAND_VERIFICATION_DOCUMENT_TYPES:
    return {
      ...state,
      verificationDocumentTypes: payload.data,
    };
  case BrandMarketActionType.RESET_BRAND_VERIFICATION_DOCUMENT_TYPES:
    return {
      ...state,
      verificationDocumentTypes: {},
    };
  case BrandMarketActionType.SAVE_BRAND_VERIFICATION_PERIOD:
    return {
      ...state,
      verificationPeriod: payload.data,
    };
  case BrandMarketActionType.RESET_BRAND_VERIFICATION_PERIOD:
    return {
      ...state,
      verificationPeriod: {},
    };
  case BrandMarketActionType.SAVE_BRAND_UNVERIFIED_PLAYERS_DEPOSIT_LIMITS:
    return {
      ...state,
      unverifiedPlayersDepositLimits: payload.data,
    };
  case BrandMarketActionType.RESET_BRAND_UNVERIFIED_PLAYERS_DEPOSIT_LIMITS:
    return {
      ...state,
      unverifiedPlayersDepositLimits: {},
    };
  default:
    return state;
  }
};

export default brandMarketReducer;
