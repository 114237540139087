import { Grid } from 'antd';
import { useEffect, useMemo, useState } from 'react';
import { withTheme } from 'styled-components';
import PropTypes from 'prop-types';
import { IconSvg } from 'components/common';
import { AdminSelect } from 'components/common/form';
import {
  StyledHeaderSelectClose, StyledHeaderSelectToggle, StyledHeaderSelectWrapper, StyledListItemViewMode,
} from 'components/layout/Layout.styled';
import { useTimeZone } from 'hooks';

const { useBreakpoint } = Grid;

const TimeZones = (props) => {
  const {
    showTimeZone,
    timeZones,
    timeZone,
    theme: { colors },
    createOrEditUserConfig,
    getTimeZones,
  } = props;

  const [open, setOpen] = useState(false);

  const screens = useBreakpoint();
  const isSmallDevice = !screens.sm;
  const { setDateTz } = useTimeZone();

  const isVisible = useMemo(() => !!timeZones.length, [timeZones.length]);

  useEffect(() => {
    if (timeZones?.length) {
      if (!timeZone) {
        const id = Intl.DateTimeFormat().resolvedOptions().timeZone;
        createOrEditUserConfig({ key: 'timeZone', configValue: id });
      }
    }
  }, [timeZones, createOrEditUserConfig, timeZone]);

  useEffect(() => {
    getTimeZones();
  }, [getTimeZones]);

  useEffect(() => {
    if (timeZone) {
      setDateTz(timeZone);
    }
  }, [timeZone, setDateTz]);

  if (!isVisible) {
    return;
  }

  if (showTimeZone?.viewMode) {
    const { name } = timeZones.find(({ id }) => id === timeZone);

    return <StyledListItemViewMode>{isSmallDevice ? name.slice(name.lastIndexOf(' ') + 1) : name}</StyledListItemViewMode>;
  }

  const openDropDown = () => {
    setOpen(true);
  };

  const closeDropDown = () => {
    setOpen(false);
  };

  const timeZoneChange = (id) => {
    createOrEditUserConfig({ key: 'timeZone', configValue: id });
    closeDropDown();
  };

  return (
    <div>
      <StyledHeaderSelectToggle onClick={openDropDown}>
        <IconSvg icon="TimeZoneIcon" width="1.41rem" height="1.41rem" color={colors.white} />
      </StyledHeaderSelectToggle>
      <StyledHeaderSelectWrapper isOpen={open ? 1 : 0}>
        <AdminSelect
          onChange={timeZoneChange}
          value={timeZone}
          allowClear={false}
          filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
          data={timeZones}
          isToString={false}
        />
        <StyledHeaderSelectClose onClick={closeDropDown}>
          <IconSvg icon="CloseModalIcon" width="1.14rem" height="1.14rem" color={colors.white} />
        </StyledHeaderSelectClose>
      </StyledHeaderSelectWrapper>
    </div>
  );
};

TimeZones.propTypes = {
  theme: PropTypes.object.isRequired,
  showTimeZone: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
  timeZones: PropTypes.array,
  timeZone: PropTypes.string,
  createOrEditUserConfig: PropTypes.func.isRequired,
  getTimeZones: PropTypes.func.isRequired,
};

TimeZones.defaultProps = {
  showTimeZone: false,
  timeZones: [],
  timeZone: null,
};

export default withTheme(TimeZones);
