import {
  all, call, put, takeLatest,
} from '@redux-saga/core/effects';
import {
  changeLoadingState, setErrorMessage, setSuccessMessage, changeEndedStatus,
} from 'actions/setting/settingActionCreator';
import { AppConstants } from 'constants';
import NetworkService from 'services/networkService';
import { saveBlockedPaymentMethodPlayers, saveBulkBlockedPaymentMethodsHistory, savePlayerBlockedPaymentMethodsHistory } from 'actions/paymentMethod/paymentMethodActionCreator';
import { getError, getMessage } from 'utils/helpers';
import PaymentMethodActionType from 'actions/paymentMethod/paymentMethodActionType';
import { i18n } from 'services';
import { changePlayerAccountInfoUpdatedStatus, changePlayerDataUpdatedStatus } from 'actions/player/playerActionCreator';
import qs from 'qs';
import { downloadFile } from 'utils/utils';
import mime from 'mimetypes';

function* getPlayerBlockedPaymentMethodsHistory({ payload }) {
  try {
    const { playerId } = payload;
    const options = {
      params: payload.data,
      paramsSerializer: (params) => qs.stringify(params),
    };
    yield put(changeLoadingState(true));
    const {
      PaymentAdmin, PaymentMethods, Player, Actions,
    } = AppConstants.api;
    const { data } = yield call(NetworkService.makeAPIGetRequest, [PaymentAdmin, PaymentMethods, Player, playerId, Actions], options);
    yield put(savePlayerBlockedPaymentMethodsHistory(data));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* blockPlayerPaymentMethods({ payload }) {
  try {
    const { playerId, data } = payload;
    const { paymentMethods } = data;

    yield put(changeLoadingState(true));
    const {
      PaymentAdmin, PaymentMethods, Player, Actions, Block,
    } = AppConstants.api;
    yield call(NetworkService.makeAPIPostRequest, [PaymentAdmin, PaymentMethods, Player, playerId, Actions, Block], { data });
    yield put(changeEndedStatus(true));
    yield put(changePlayerDataUpdatedStatus(true));
    yield put(
      setSuccessMessage(getMessage(i18n.t(paymentMethods.length > 1 ? 'paymentMethods' : 'paymentMethod'), i18n.t(paymentMethods.length > 1 ? 'notification:plBlocked' : 'notification:slBlocked'))),
    );
    yield put(changePlayerAccountInfoUpdatedStatus(true));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* unblockPlayerPaymentMethods({ payload }) {
  try {
    const { playerId, data } = payload;
    const { paymentMethods } = data;

    yield put(changeLoadingState(true));
    const {
      PaymentAdmin, PaymentMethods, Player, Actions, Unblock,
    } = AppConstants.api;
    yield call(NetworkService.makeAPIPostRequest, [PaymentAdmin, PaymentMethods, Player, playerId, Actions, Unblock], { data });
    yield put(changeEndedStatus(true));
    yield put(changePlayerDataUpdatedStatus(true));
    yield put(
      setSuccessMessage(
        getMessage(i18n.t(paymentMethods.length > 1 ? 'paymentMethods' : 'paymentMethod'), i18n.t(paymentMethods.length > 1 ? 'notification:plUnblocked' : 'notification:slUnblocked')),
      ),
    );
    yield put(changePlayerAccountInfoUpdatedStatus(true));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getBulkBlockedPaymentMethodsHistory({ payload }) {
  try {
    const options = {
      params: payload.params,
      paramsSerializer: (params) => qs.stringify(params),
    };
    yield put(changeLoadingState(true));
    const {
      PaymentAdmin, PaymentMethods, Actions, Bulk,
    } = AppConstants.api;
    const { data } = yield call(NetworkService.makeAPIGetRequest, [PaymentAdmin, PaymentMethods, Actions, Bulk], options);
    yield put(saveBulkBlockedPaymentMethodsHistory(data));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* blockPlayersPaymentMethodsByBulk({ payload }) {
  try {
    const { brandId, data } = payload;
    const { paymentMethods } = data;

    const options = {
      params: { brandId },
      data,
    };

    yield put(changeLoadingState(true));
    const {
      PaymentAdmin, PaymentMethods, Actions, Block, Bulk,
    } = AppConstants.api;
    yield call(NetworkService.makeAPIPostRequest, [PaymentAdmin, PaymentMethods, Actions, Block, Bulk], options);
    yield put(changeEndedStatus(true));
    yield put(
      setSuccessMessage(getMessage(i18n.t(paymentMethods.length > 1 ? 'paymentMethods' : 'paymentMethod'), i18n.t(paymentMethods.length > 1 ? 'notification:plBlocked' : 'notification:slBlocked'))),
    );
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* unblockPlayersPaymentMethodsByBulk({ payload }) {
  try {
    const { brandId, data } = payload;
    const { paymentMethods } = data;

    const options = {
      params: { brandId },
      data,
    };

    yield put(changeLoadingState(true));
    const {
      PaymentAdmin, PaymentMethods, Actions, Unblock, Bulk,
    } = AppConstants.api;
    yield call(NetworkService.makeAPIPostRequest, [PaymentAdmin, PaymentMethods, Actions, Unblock, Bulk], options);
    yield put(changeEndedStatus(true));
    yield put(
      setSuccessMessage(
        getMessage(i18n.t(paymentMethods.length > 1 ? 'paymentMethods' : 'paymentMethod'), i18n.t(paymentMethods.length > 1 ? 'notification:plUnblocked' : 'notification:slUnblocked')),
      ),
    );
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getBlockedPaymentMethodPlayers({ payload }) {
  try {
    const { id, params } = payload;
    const options = {
      params,
      paramsSerializer: (param) => qs.stringify(param),
    };
    const {
      PaymentAdmin, PaymentMethods, Actions, Bulk, Players,
    } = AppConstants.api;
    yield put(changeLoadingState(true));
    const { data } = yield call(NetworkService.makeAPIGetRequest, [PaymentAdmin, PaymentMethods, Actions, Bulk, id, Players], options);
    yield put(saveBlockedPaymentMethodPlayers(data));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* exportBlockedPaymentMethodPlayers({ payload }) {
  try {
    const { id, params } = payload;
    const options = {
      params,
      paramsSerializer: (param) => qs.stringify(param, { allowDots: true }),
      responseType: 'blob',
    };
    const {
      PaymentAdmin, PaymentMethods, Actions, Bulk, Players, Export,
    } = AppConstants.api;
    yield put(changeLoadingState(true));
    const { data, headers } = yield call(NetworkService.makeAPIGetRequest, [PaymentAdmin, PaymentMethods, Actions, Bulk, id, Players, Export], options);
    downloadFile(data, i18n.t('riskManagement:blockedPaymentMethodPlayers'), mime.detectExtension(headers['content-type']));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

export default function* paymentMethodSaga() {
  yield all([
    takeLatest(PaymentMethodActionType.GET_PLAYER_BLOCKED_PAYMENT_METHODS_HISTORY, getPlayerBlockedPaymentMethodsHistory),
    takeLatest(PaymentMethodActionType.BLOCK_PLAYER_PAYMENT_METHODS, blockPlayerPaymentMethods),
    takeLatest(PaymentMethodActionType.UNBLOCK_PLAYER_PAYMENT_METHODS, unblockPlayerPaymentMethods),
    takeLatest(PaymentMethodActionType.GET_BULK_BLOCKED_PAYMENT_METHODS_HISTORY, getBulkBlockedPaymentMethodsHistory),
    takeLatest(PaymentMethodActionType.BLOCK_PLAYERS_PAYMENT_METHODS_BY_BULK, blockPlayersPaymentMethodsByBulk),
    takeLatest(PaymentMethodActionType.UNBLOCK_PLAYERS_PAYMENT_METHODS_BY_BULK, unblockPlayersPaymentMethodsByBulk),
    takeLatest(PaymentMethodActionType.GET_BLOCKED_PAYMENT_METHOD_PLAYERS, getBlockedPaymentMethodPlayers),
    takeLatest(PaymentMethodActionType.EXPORT_BLOCKED_PAYMENT_METHOD_PLAYERS, exportBlockedPaymentMethodPlayers),
  ]);
}
