import PropTypes from 'prop-types';
import { useEffect, useMemo } from 'react';
import _ from 'lodash';
import brandSettingColumns from 'constants/columns/brandSetting';
import { AdminTable } from 'containers/common';
import { getInitialColumns } from 'utils/helpers';
import actionsPermissions from 'constants/actionsPermissions';
import { StyledTableMarginTop } from 'components/styledComponents/table/Table.styled';

const initialColumns = (params) => getInitialColumns(brandSettingColumns.brandLayouts(params), actionsPermissions.brandSportsBookLayout);

const Layouts = (props) => {
  const {
    templates, getSportsBookLayouts, localeLanguage, setViewId, viewId,
  } = props;

  useEffect(() => {
    if (!_.isEmpty(templates) && !viewId) {
      const defaultTemplate = templates.find((el) => el.isDefault);
      setViewId(defaultTemplate?.id || templates[0].id);
    }
  }, [setViewId, templates, viewId]);

  const data = useMemo(() => templates.map((el) => (el.id === viewId ? { ...el, isMain: true } : el)), [templates, viewId]);

  useEffect(() => {
    getSportsBookLayouts();
  }, [getSportsBookLayouts, localeLanguage]);

  return (
    <StyledTableMarginTop>
      <AdminTable scroll={{ x: 800 }} columns={initialColumns({ setViewId })} data={data} size="middle" pagination={false} rowClassName={(record, index) => `${index % 2 ? ' odd' : ' even'}`} />
    </StyledTableMarginTop>
  );
};

Layouts.propTypes = {
  templates: PropTypes.array,
  getSportsBookLayouts: PropTypes.func.isRequired,
  localeLanguage: PropTypes.string.isRequired,
  setViewId: PropTypes.func.isRequired,
  viewId: PropTypes.number,
};

Layouts.defaultProps = {
  templates: [],
  viewId: null,
};

export default Layouts;
