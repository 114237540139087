import { Link } from 'react-router-dom';
import { useMemo } from 'react';
import { Grid } from 'antd';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { IconSvg } from 'components/common';
import { StyledLogoWrapper } from 'components/layout/Layout.styled';
import { getSidebarSizes } from 'utils/styledHelpers';
import { withTheme } from 'styled-components';

const { useBreakpoint } = Grid;
const sidebarSizes = getSidebarSizes();

const Logo = (props) => {
  const {
    menuCollapse,
    collapsedWidth,
    user,
    theme: { colors },
    width,
  } = props;
  const screens = useBreakpoint();
  const isSmallDevice = !screens.sm;

  const collapsed = useMemo(() => (!isSmallDevice ? menuCollapse : true), [menuCollapse, isSmallDevice]);
  const menuWidth = useMemo(() => (collapsed ? collapsedWidth : width), [collapsed, collapsedWidth, width]);

  const renderLogo = () => (!collapsed ? <IconSvg icon="WordMarksIcon" color={colors.blue} width="100%" height="100%" /> : <IconSvg icon="MonogramIcon" color={colors.blue} width="100%" height="100%" />);

  return (
    <StyledLogoWrapper menuWidth={menuWidth} menuClose={collapsed}>
      {user && (!_.isEmpty(user?.permissions) || user.userClaims?.length) ? <Link to="/">{renderLogo()}</Link> : renderLogo()}
    </StyledLogoWrapper>
  );
};

Logo.propTypes = {
  user: PropTypes.object,
  menuCollapse: PropTypes.bool,
  collapsedWidth: PropTypes.string,
  theme: PropTypes.object.isRequired,
  width: PropTypes.string,
};

Logo.defaultProps = {
  user: null,
  menuCollapse: false,
  collapsedWidth: sidebarSizes.collapsedWidth,
  width: sidebarSizes.width,
};

export default withTheme(Logo);
