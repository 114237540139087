import _ from 'lodash';
import { createSelector } from 'reselect';

export const selectPermissionGroups = (state) => state.permissionGroups;
export const selectPermissionGroupsLoading = (state, key = 'first') => state.loading?.[key];
export const selectPGUpdatedDate = (state) => state.lastUpdateTime;
export const selectPermissionGroupData = (state, key = 'first') => state.permissionGroupData?.[key];
export const selectAllPermissionGroups = (state) => state.allPermissionGroups;
export const selectPermissionGroupPermissions = (state, key = 'first') => state.permissionGroupPermissions?.[key];
export const selectGroupedPermissionGroups = (state, selectData = selectAllPermissionGroups) => createSelector(
  () => selectData(state),
  (items) => {
    let data = {};
    const groups = ['title:myGroups', 'title:defaultGroups'];
    if (items) {
      data = _.groupBy(items, (el) => groups[+el.isDefault]);
    }
    return data;
  },
)();
