import {
  useState, useEffect, useCallback, useMemo,
} from 'react';
import { Checkbox, Popover } from 'antd';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { StyledButtonBlue, StyledButtonGrayIcon } from 'components/styledComponents/button/Button.styled';
import { StyledPopoverWithCheckboxes } from 'components/styledComponents/popover/Popover.styled';
import manageFilters from 'constants/manageFilters';
import { FilterService } from 'services';
import { getFilterConfigs } from 'utils/helpers';
import IconSvg from 'components/common/icon/IconSvg';
import { StyledManageFiltersWrapper } from 'components/styledComponents/filter/Filter.styled';

const ManageFilters = (props) => {
  const {
    userFilterConfigs, createOrEditUserConfig, filterKey, loading, changeLoadingState, setFilterConfigs, params,
  } = props;

  const { t } = useTranslation();
  const [visible, setVisible] = useState(false);
  const [pending, setPending] = useState(null);
  const [initialData, setInitialData] = useState(null);
  const [data, setData] = useState([]);
  const manageFiltersObj = useMemo(() => (_.isFunction(manageFilters[filterKey]) ? manageFilters[filterKey](...params) : manageFilters[filterKey]), [params, filterKey]);
  const { getQuery, setQuery } = FilterService;

  const location = useLocation();
  const query = getQuery(location.search);

  useEffect(() => {
    if (visible) {
      const newData = Object.keys(manageFiltersObj).map((dataIndex) => ({
        dataIndex,
        title: manageFiltersObj[dataIndex]?.title,
        checked: userFilterConfigs?.[dataIndex] ?? manageFiltersObj[dataIndex]?.checked,
        disabled: manageFiltersObj[dataIndex]?.disabled,
      }));

      setData(newData);

      if (_.isNull(initialData)) {
        setInitialData(newData);
      }
    }
  }, [manageFiltersObj, initialData, userFilterConfigs, visible]);

  useEffect(() => {
    if (!visible && _.isNull(pending)) {
      setInitialData(null);
    }
  }, [visible, pending]);

  useEffect(() => {
    changeLoadingState(loading);
  }, [loading, changeLoadingState]);

  const handleShowFilters = () => setVisible(!visible);

  const handleSelectAll = (checked) => {
    const newData = data.map((el) => (!el.disabled ? { ...el, checked } : el));
    setData(newData);
  };

  const handleSelect = (checked, index) => {
    const newData = [...data];
    newData[index].checked = checked;
    setData(newData);
  };

  useEffect(() => {
    if (!visible && pending && !_.isEmpty(data)) {
      setPending(null);
      const configValue = {};

      const uncheckedList = {};
      const baseFilterConfig = {};

      data.forEach(({ dataIndex, checked }) => {
        configValue[dataIndex] = checked;
        if (!checked) {
          uncheckedList[dataIndex] = null;
        }
        baseFilterConfig[dataIndex] = {
          checked,
          disabled: manageFiltersObj?.[dataIndex]?.disabled,
        };
      });

      // update query based on turned off filters
      if (_.intersection(Object.keys(query), Object.keys(uncheckedList)).length) {
        setQuery({
          ...query,
          ...uncheckedList,
        });
      }

      createOrEditUserConfig({ key: filterKey, configValue }, false);
      // update filter config by considering new user config
      setFilterConfigs?.(getFilterConfigs(baseFilterConfig));
    }
  }, [createOrEditUserConfig, data, filterKey, manageFiltersObj, pending, query, setFilterConfigs, setQuery, userFilterConfigs, visible]);

  const handleApply = () => {
    handleShowFilters();
    setTimeout(() => setPending(true), 300);
  };

  const isDisabled = useCallback(() => {
    const existing = initialData?.reduce((acc, el) => {
      acc[el.dataIndex] = el.checked;
      return acc;
    }, {});

    const updated = data?.reduce((acc, el) => {
      acc[el.dataIndex] = el.checked;
      return acc;
    }, {});

    return _.isEqual(existing, updated);
  }, [data, initialData]);

  const doRender = () => {
    const content = data.map((col, index) => (
      <Checkbox checked={col.checked} disabled={col.disabled} onChange={(e) => handleSelect(e.target.checked, index)} key={col.title} className={col.className}>
        {t(col.title)}
      </Checkbox>
    ));

    return (
      <div>
        <StyledPopoverWithCheckboxes>
          <Checkbox
            onChange={(e) => handleSelectAll(e.target.checked)}
            checked={data.length && !data.some((el) => !el.checked)}
            indeterminate={data.some((el) => el.checked && !el.disabled) && data.filter((el) => el.checked).length !== data.length}
            className="sticky-top"
          >
            <strong>{t('selectAll')}</strong>
          </Checkbox>
          {content}
        </StyledPopoverWithCheckboxes>
        <StyledButtonBlue onClick={handleApply} disabled={isDisabled()} size="small">
          {t('apply')}
        </StyledButtonBlue>
      </div>
    );
  };

  return (
    <StyledManageFiltersWrapper>
      <Popover placement="bottomLeft" content={doRender()} trigger="click" open={visible} onOpenChange={handleShowFilters} getPopupContainer={(trigger) => trigger.parentNode}>
        <StyledButtonGrayIcon size="medium" hidemobile="true">
          <IconSvg icon="ArrowLeftIcon" width="0.43rem" height="0.71rem" />
          <span>{t('more')}</span>
        </StyledButtonGrayIcon>
      </Popover>
    </StyledManageFiltersWrapper>
  );
};

ManageFilters.propTypes = {
  createOrEditUserConfig: PropTypes.func.isRequired,
  changeLoadingState: PropTypes.func.isRequired,
  filterKey: PropTypes.string.isRequired,
  loading: PropTypes.bool.isRequired,
  userFilterConfigs: PropTypes.object,
  setFilterConfigs: PropTypes.func,
  params: PropTypes.array,
};

ManageFilters.defaultProps = {
  userFilterConfigs: undefined,
  setFilterConfigs: null,
  params: [],
};

export default ManageFilters;
