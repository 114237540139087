import {
  put, call, takeLatest, all, select, takeEvery,
} from 'redux-saga/effects';
import NetworkService from 'services/networkService';
import {
  changeEndedStatus, changeLoadingState, setErrorMessage, setSuccessMessage,
} from 'actions/setting/settingActionCreator';
import SeoSettingActionType from 'actions/seoSetting/seoSettingActionType';
import { getError, getMessage } from 'utils/helpers';
import {
  saveSeoPagesSettings, saveSeoPageGroups, saveSeoSchemaMarkup, saveSeoSocialMediaConfig, saveSeoRobots,
} from 'actions/seoSetting/seoSettingActionCreator';
import { AppConstants } from 'constants/index';
import { i18n } from 'services';
import enumTypes from 'constants/enumTypes';
import { selectSeoPagesSettings } from 'selectors';

function* getSeoPageSettings({ payload }) {
  try {
    const { pageGroupId, id, params } = payload;

    const options = {
      params,
    };
    const {
      BuilderAdmin, Seo, PageGroups, Pages,
    } = AppConstants.api;
    yield put(changeLoadingState(true));
    const { data } = yield call(NetworkService.makeAPIGetRequest, [BuilderAdmin, Seo, PageGroups, pageGroupId, Pages, id], options);
    const { seoSetting } = yield select();
    const settings = selectSeoPagesSettings(seoSetting);
    settings[pageGroupId] = {
      ...settings[pageGroupId],
      [id]: data,
    };
    yield put(saveSeoPagesSettings({ ...settings }));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* editSeoPagesSettings({ payload }) {
  try {
    const { settings, data, params } = payload;
    const options = {
      data,
      params,
    };
    const { BuilderAdmin, Seo, PageGroups } = AppConstants.api;
    yield put(changeLoadingState(true));
    yield call(NetworkService.makeAPIPutRequest, [BuilderAdmin, Seo, PageGroups], options);
    yield put(saveSeoPagesSettings(settings));
    yield put(changeLoadingState(false));
    yield put(setSuccessMessage(getMessage(i18n.t('brand:seoSettings'), i18n.t('notification:plUpdated'))));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getSeoSocialMediaConfig({ payload }) {
  try {
    const { params } = payload;

    const options = {
      params,
    };
    const { BuilderAdmin, Seo, SocialMedia } = AppConstants.api;
    yield put(changeLoadingState(true));
    const { data } = yield call(NetworkService.makeAPIGetRequest, [BuilderAdmin, Seo, SocialMedia], options);
    yield put(saveSeoSocialMediaConfig(data));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* editSeoSocialMediaConfig({ payload }) {
  try {
    const { brandId } = payload;
    const options = {
      params: { brandId },
      data: payload.data,
      headers: { 'content-type': 'multipart/form-data' },
    };
    const { BuilderAdmin, Seo, SocialMedia } = AppConstants.api;
    yield put(changeLoadingState(true));
    yield call(NetworkService.makeAPIPostRequest, [BuilderAdmin, Seo, SocialMedia], options);
    yield put(changeEndedStatus(true));
    yield put(changeLoadingState(false));
    yield put(
      setSuccessMessage(
        getMessage(i18n.t(+payload.data.get('socialType') === enumTypes.seoSocialMediaType.OpenGraph ? 'brand:openGraphSettings' : 'brand:twitterSettings'), i18n.t('notification:plUpdated')),
      ),
    );
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getSeoSchemaMarkup({ payload }) {
  try {
    const { params } = payload;

    const options = {
      params,
    };
    const { BuilderAdmin, Seo, Schema } = AppConstants.api;
    yield put(changeLoadingState(true));
    const { data } = yield call(NetworkService.makeAPIGetRequest, [BuilderAdmin, Seo, Schema], options);
    yield put(saveSeoSchemaMarkup(data));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* editSeoSchemaMarkup({ payload }) {
  try {
    const { brandId } = payload;
    const options = {
      params: { brandId },
      data: payload.data,
      headers: { 'content-type': 'multipart/form-data' },
    };
    const { BuilderAdmin, Seo, Schema } = AppConstants.api;
    yield put(changeLoadingState(true));
    yield call(NetworkService.makeAPIPostRequest, [BuilderAdmin, Seo, Schema], options);
    yield put(changeEndedStatus(true));
    yield put(changeLoadingState(false));
    yield put(setSuccessMessage(getMessage(i18n.t('brand:schemaMarkupSettings'), i18n.t('notification:plUpdated'))));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getSeoPageGroups({ payload }) {
  try {
    const { params } = payload;
    const options = {
      params,
    };
    const { BuilderAdmin, Seo, PageGroups } = AppConstants.api;
    yield put(changeLoadingState(true));
    const { data } = yield call(NetworkService.makeAPIGetRequest, [BuilderAdmin, Seo, PageGroups], options);
    yield put(saveSeoPageGroups(data.data));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getSeoRobots({ payload }) {
  try {
    const { brandId } = payload;
    const options = {
      params: { brandId },
    };
    const { BuilderAdmin, Seo, Robots } = AppConstants.api;
    yield put(changeLoadingState(true));
    const { data } = yield call(NetworkService.makeAPIGetRequest, [BuilderAdmin, Seo, Robots], options);
    yield put(saveSeoRobots(data));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* editSeoRobots({ payload }) {
  try {
    const { brandId, data } = payload;
    const options = {
      params: { brandId },
      data,
    };

    const { BuilderAdmin, Seo, Robots } = AppConstants.api;
    yield put(changeLoadingState(true));
    yield call(NetworkService.makeAPIPostRequest, [BuilderAdmin, Seo, Robots], options);
    yield put(changeEndedStatus(true));
    yield put(changeLoadingState(false));
    yield put(setSuccessMessage(getMessage(i18n.t('brand:robotsFile'), i18n.t('notification:plUpdated'))));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

export default function* seoSettingSaga() {
  yield all([
    takeEvery(SeoSettingActionType.GET_SEO_PAGE_SETTINGS, getSeoPageSettings),
    takeLatest(SeoSettingActionType.EDIT_SEO_PAGES_SETTINGS, editSeoPagesSettings),
    takeLatest(SeoSettingActionType.GET_SEO_SOCIAL_MEDIA_CONFIG, getSeoSocialMediaConfig),
    takeLatest(SeoSettingActionType.EDIT_SEO_SOCIAL_MEDIA_CONFIG, editSeoSocialMediaConfig),
    takeLatest(SeoSettingActionType.GET_SEO_SCHEMA_MARKUP, getSeoSchemaMarkup),
    takeLatest(SeoSettingActionType.EDIT_SEO_SCHEMA_MARKUP, editSeoSchemaMarkup),
    takeLatest(SeoSettingActionType.GET_SEO_PAGE_GROUPS, getSeoPageGroups),
    takeLatest(SeoSettingActionType.GET_SEO_ROBOTS, getSeoRobots),
    takeLatest(SeoSettingActionType.EDIT_SEO_ROBOTS, editSeoRobots),
  ]);
}
