import { connect } from 'react-redux';
import { Loading } from 'components/layout';
import { selectPageLoading, selectUserConfig } from 'selectors';
import { settingKeys } from 'constants/configKeys';

const mapStateToProps = ({ settings, users }) => ({
  pageLoading: selectPageLoading(settings),
  menuCollapse: selectUserConfig(users, settingKeys.menuCollapse),
});

export default connect(mapStateToProps)(Loading);
