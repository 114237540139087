import { createSelector } from 'reselect';
import enumTypes from 'constants/enumTypes';

export const selectIsAllPlayers = (state) => state.isAllPlayers;
export const selectExistingPlayers = (state) => state.existingPlayers;
export const selectReferrals = (state) => state.referrals;
export const selectReferralsTotal = (state) => state.total;
export const selectReferral = (state) => state.referral;
export const selectReferralAccumulated = (state) => state.accumulated;
export const selectReferralAccumulatedTotal = (state) => state.accumulatedTotal;
export const selectReferralTransferred = (state) => state.transferred;
export const selectReferralTransferredTotal = (state) => state.transferredTotal;
export const selectReferralFailed = (state) => state.failed;
export const selectReferralFailedTotal = (state) => state.failedTotal;
export const selectReferralReferees = (state) => state.referees;
export const selectReferralRefereesTotal = (state) => state.refereesTotal;
export const selectReferralParticipants = (state) => state.participants;
export const selectReferralParticipantsTotal = (state) => state.participantsTotal;
export const selectPlayerReferralReferees = (state) => state.playerReferralReferees;
export const selectPlayerReferralRefereesTotal = (state) => state.playerReferralRefereesTotal;
export const selectPlayerReferrals = (state) => state.playerReferrals;
export const selectPlayerReferralsTotal = (state) => state.playerReferralsTotal;
export const selectReferralAllParticipants = (state) => state.allParticipants;
export const selectReferralName = (state) => selectReferral(state).name;

export const selectPlayerReferralPeriods = (state) => state.playerReferralPeriods;
export const selectPlayerReferralPeriod = (state, id) => createSelector(
  () => selectPlayerReferralPeriods(state),
  (data) => data[id] || [],
)();

export const selectReferralAccumulatedPeriods = (state) => state.referralAccumulatedPeriods;
export const selectReferralTransferredPeriods = (state) => state.referralTransferredPeriods;
export const selectReferralFailedPeriods = (state) => state.referralFailedPeriods;

export const selectReferralPeriod = (state, id, periodStatus) => createSelector(
  () => {
    let selectFunc = () => {};
    switch (periodStatus) {
    case enumTypes.cashBackReferralPeriodStatus.Transferred: {
      selectFunc = selectReferralTransferredPeriods;
      break;
    }
    case enumTypes.cashBackReferralPeriodStatus.Accumulated: {
      selectFunc = selectReferralAccumulatedPeriods;
      break;
    }
    default: {
      selectFunc = selectReferralFailedPeriods;
      break;
    }
    }
    return selectFunc(state);
  },
  (data) => data[id] || [],
)();
