import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Partners } from 'components/layout/header';
import { setCurrentPartner } from 'actions/partner/partnerActionCreator';
import { selectCurrentPartnerId, selectAuthUserPartnerAccesses, selectResourcePartner } from 'selectors';
import { getAuthUserPartnerAccesses } from 'actions/auth/authActionCreator';
import { getResourcePartner } from 'actions/resource/resourceActionCreator';

const mapDispatchToProps = (dispatch) => bindActionCreators(
  {
    setCurrentPartner,
    getAuthUserPartnerAccesses,
    getResourcePartner,
  },
  dispatch,
);

const mapStateToProps = ({ auth, partners, resource }) => ({
  partner: selectResourcePartner(resource),
  partners: selectAuthUserPartnerAccesses(auth),
  partnerId: selectCurrentPartnerId(partners),
});

export default connect(mapStateToProps, mapDispatchToProps)(Partners);
