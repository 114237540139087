import { DndContext, PointerSensor, useSensor } from '@dnd-kit/core';
import { horizontalListSortingStrategy, SortableContext } from '@dnd-kit/sortable';
import PropTypes from 'prop-types';
import DraggableTabNode from './DraggableTabNode';

const DraggableTabContext = (props) => {
  const {
    tabBarProps, DefaultTabBar, onDragEnd, items,
  } = props;

  const sensor = useSensor(PointerSensor, {
    activationConstraint: {
      distance: 10,
    },
  });

  return (
    <DndContext sensors={[sensor]} onDragEnd={onDragEnd}>
      <SortableContext items={items} strategy={horizontalListSortingStrategy}>
        <DefaultTabBar {...tabBarProps}>
          {(node) => (
            <DraggableTabNode {...node.props} key={node.key}>
              {node}
            </DraggableTabNode>
          )}
        </DefaultTabBar>
      </SortableContext>
    </DndContext>
  );
};

DraggableTabContext.propTypes = {
  items: PropTypes.array.isRequired,
  onDragEnd: PropTypes.func.isRequired,
  tabBarProps: PropTypes.object.isRequired,
  DefaultTabBar: PropTypes.elementType.isRequired,
};

export default DraggableTabContext;
