export default {
  en: {
    icon: 'Icon',
    icons: 'Icons',
    createDashboard: 'Create Dashboard',
    createWidget: 'Create Widget',
    dashboardName: 'Dashboard Name',
    editWidget: 'Edit Widget',
    dashboard: 'Dashboard',
    lDashboard: 'dashboard',
    lWidget: 'widget',
    dataType: 'Data Type',
    aggregationCriteria: 'Aggregation Criteria',
    selection: 'Selection',
    count: 'Count',
    applyToDashboard: 'Include in the current dashboard',
    darkTheme: 'Dark Theme',
    aggregationPeriod: 'Aggregation Period',
    aggregationPeriodBy: 'Aggregation Period By',
    valueType: 'Value Type',
    betType: 'Bet Type',
    logic: 'Logic',
    tableView: 'Table View',
    dropHere: 'Drag and drop one of the widgets to create your personal dashboard.',
    category: 'Category',
    needToCreateDashboard: 'Click on <0>Create a Dashboard</0> Button to create your Personal Dashboard',
    quarter: 'Quarter',
    viewDataTable: 'View Data Table',
    dataTable: 'Data Table',
    widgetNotAvailable: 'The Widget \n is not Available',
    availableCountryList: 'Available Country List',
    availableCountryListInfo: 'Select the countries whose cities you want to see in an aggregated form',
  },
  es: {
    icon: 'Icono',
    icons: 'Iconos',
    createDashboard: 'Crear panel de control',
    createWidget: 'Crear widget',
    dashboardName: 'Nombre de panel de control',
    editWidget: 'Editar widget',
    dashboard: 'Panel de control',
    lDashboard: 'panel de control',
    lWidget: 'widget',
    dataType: 'Tipo de datos',
    aggregationCriteria: 'Criterios de acumulación',
    selection: 'Selección',
    count: 'Contar',
    applyToDashboard: 'Incluir en el panel de control actual',
    darkTheme: 'Tema oscuro',
    aggregationPeriod: 'Periodo de acumulación',
    aggregationPeriodBy: 'Periodo de acumulación por',
    valueType: 'Tipo de valor',
    betType: 'Tipo de apuesta',
    logic: 'Lógica',
    tableView: 'Vista de la mesa',
    dropHere: 'Soltar aquí',
    category: 'Categoría',
    needToCreateDashboard: 'Crear panel de control',
    quarter: 'Cuarto',
    viewDataTable: 'Ver tabla de datos',
    dataTable: 'Tabla de datos',
    widgetNotAvailable: 'El widget \n no está disponible',
    availableCountryList: 'Lista de Países Disponibles',
    availableCountryListInfo: 'Selecciona los países cuyas ciudades deseas ver de forma agregada.',
  },
  tr: {
    icon: 'İkon',
    icons: 'İkonlar',
    createDashboard: 'Dashboard Oluştur',
    createWidget: 'Widget Oluştur',
    dashboardName: 'Dashboard Adı',
    editWidget: 'Widget’ı Düzenle',
    dashboard: 'Dashboard',
    lDashboard: 'dashboard',
    lWidget: 'widget',
    dataType: 'Veri Türü',
    aggregationCriteria: 'Toplama Kriterleri',
    selection: 'Seçim',
    count: 'Sayı',
    applyToDashboard: 'Mevcut dashboard’a dahil et',
    darkTheme: 'Koyu Tema',
    aggregationPeriod: 'Toplama Periyodu',
    aggregationPeriodBy: 'Toplama Dönemi Tarafından',
    valueType: 'Değer Türü',
    betType: 'Bahis Türü',
    logic: 'Mantık',
    tableView: 'Tablo Görünümü',
    dropHere: "Kişisel dashboard'unuzu oluşturmak için widget'lardan birini sürükleyip bırakın.",
    category: 'Kategori',
    needToCreateDashboard: "Kişisel Dashboard'unuzu oluşturmak için </0>'a tıklayın",
    quarter: 'Çeyrek',
    viewDataTable: 'Veri Tablosunu Görüntüle',
    dataTable: 'Veri Tablosu',
    widgetNotAvailable: 'Widget \n Mevcut Değil',
    availableCountryList: 'Mevcut Ülke Listesi',
    availableCountryListInfo: 'Şehirlerini toplu halde görmek istediğiniz ülkeleri seçin',
  },
  ru: {
    icon: 'Иконка',
    icons: 'Иконки',
    createDashboard: 'Создать панель управления',
    createWidget: 'Создать виджет',
    dashboardName: 'Название панели управления',
    editWidget: 'Изменить виджет',
    dashboard: 'Панель управления',
    lDashboard: 'панель управления',
    lWidget: 'Виджет',
    dataType: 'Тип данных',
    aggregationCriteria: 'Критерии агрегирования',
    selection: 'Выбор',
    count: 'Количество',
    applyToDashboard: 'Включить в текущую панель управления',
    darkTheme: 'Темная тема',
    aggregationPeriod: 'Период агрегирования',
    aggregationPeriodBy: 'Период агрегирования по',
    valueType: 'Тип значения',
    betType: 'Тип ставки',
    logic: 'Логика',
    tableView: 'Вид таблицы',
    dropHere: 'Перетащите один из виджетов, чтобы создать свою персональную панель управления.',
    category: 'Категория',
    needToCreateDashboard: 'Нажмите на кнопку Click on <0>Создать панель управления</0> чтобы создать персональную панель управления',
    quarter: 'Квартал',
    viewDataTable: 'Посмотреть таблицу данных',
    dataTable: 'Таблица данных',
    widgetNotAvailable: 'Виджет \n недоступен',
    availableCountryList: 'Список доступных стран',
    availableCountryListInfo: 'Выберите страны, города которых вы хотите видеть в агрегированном виде',
  },
  pt: {
    icon: 'Ícone',
    icons: 'Ícones',
    createDashboard: 'Criar Painel',
    createWidget: 'Criar Widget',
    dashboardName: 'Nome do Painel',
    editWidget: 'Editar Widget',
    dashboard: 'Painel',
    lDashboard: 'painel',
    lWidget: 'widget',
    dataType: 'Tipo de Dados',
    aggregationCriteria: 'Critérios de Agregação',
    selection: 'Seleção',
    count: 'Contagem',
    applyToDashboard: 'Incluir no painel atual',
    darkTheme: 'Tema Escuro',
    aggregationPeriod: 'Período de Agregação',
    aggregationPeriodBy: 'Período de Agregação por',
    valueType: 'Tipo de Valor',
    betType: 'Tipo de Aposta',
    logic: 'Lógica',
    tableView: 'Visão de Tabela',
    dropHere: 'Arraste e solte um dos widgets para criar o seu painel pessoal.',
    category: 'Categoria',
    needToCreateDashboard: 'Clique no Botão <0>Criar um Painel</0> para criar o seu Painel Pessoal',
    quarter: 'Trimestre',
    viewDataTable: 'Ver Tabela de Dados',
    dataTable: 'Tabela de Dados',
    widgetNotAvailable: 'O Widget \n não está Disponível',
    availableCountryList: 'Lista de países disponíveis',
    availableCountryListInfo: 'Seleccione os países cujas cidades pretende ver de forma agregada',
  },
  zh: {
    icon: '图标',
    icons: '图标',
    createDashboard: '创建信息中心',
    createWidget: '创建小工具',
    dashboardName: '信息中心名称',
    editWidget: '编辑小工具',
    dashboard: '信息中心',
    lDashboard: '信息中心',
    lWidget: '小工具',
    dataType: '数据类型',
    aggregationCriteria: '汇总条件',
    selection: '选项',
    count: '次数',
    applyToDashboard: '包括当前信息中心',
    darkTheme: '深色主题',
    aggregationPeriod: '汇总期',
    aggregationPeriodBy: '汇总期按',
    valueType: '值类型',
    betType: '投注类型',
    logic: '逻辑',
    tableView: '表格视图',
    dropHere: '拖拽其中一个组件来创建您的个人信息中心。',
    category: '类别',
    needToCreateDashboard: '点击<0>创建信息中心</0>按钮来创建您的个人信息中心',
    quarter: '季度',
    viewDataTable: '查看数据表格',
    dataTable: '数据表格',
    widgetNotAvailable: '小工具不可用',
    availableCountryList: '可用国家名单',
    availableCountryListInfo: '选择您希望以汇总形式查看其城市所在的国家/地区',
  },
  ro: {
    icon: 'Pictogramă',
    icons: 'Pictograme',
    createDashboard: 'Creează un tablou de bord',
    createWidget: 'Creează un Widget',
    dashboardName: 'Numele tabloului de bord',
    editWidget: 'Editează Widget-ul',
    dashboard: 'Tablou de bord',
    lDashboard: 'tablou de bord',
    lWidget: 'widget',
    dataType: 'Tip de date',
    aggregationCriteria: 'Criterii de agregare',
    selection: 'Selecție',
    count: 'Cantitate',
    applyToDashboard: 'Include în tabloul de bord actual',
    darkTheme: 'Tema întunecată ',
    aggregationPeriod: 'Perioadă de acumulare',
    aggregationPeriodBy: 'Perioada de acumulare până la',
    valueType: 'Tip de valoare',
    betType: 'Tip de pariu',
    logic: 'Logică',
    tableView: 'Vizualizare tabel',
    dropHere: 'Trage și plasează unul dintre widget-uri pentru a crea tabloul de bord personal.',
    category: 'Categorie',
    needToCreateDashboard: 'Fă clic pe butonul <0> Crează un tablou de bord</0> pentru a crea tabloul de bord personal',
    quarter: 'Sfert',
    viewDataTable: 'Vizualizare tabelul de date',
    dataTable: 'Tabel de date',
    widgetNotAvailable: 'Widgetul \n nu este disponibil',
  },
  bg: {},
  el: {
    icon: 'Εικονίδιο',
    icons: 'Εικονίδια',
    createDashboard: 'Δημιουργία πίνακα ελέγχου',
    createWidget: 'Δημιουργία γραφικού στοιχείου',
    dashboardName: 'Όνομα πίνακα ελέγχου',
    editWidget: 'Επεξεργασία γραφικού στοιχείου',
    dashboard: 'Πίνακας ελέγχου',
    lDashboard: 'πίνακας ελέγχου',
    lWidget: 'γραφικό στοιχείο',
    dataType: 'Τύπος δεδομένων',
    aggregationCriteria: 'Κριτήρια συγκέντρωσης',
    selection: 'Επιλογή',
    count: 'Αριθμός',
    applyToDashboard: 'Να συμπεριληφθεί στον τρέχοντα πίνακα ελέγχου',
    darkTheme: 'Σκοτεινό θέμα',
    aggregationPeriod: 'Περίοδος συγκέντρωσης',
    aggregationPeriodBy: 'Περίοδος συγκέντρωσης κατά',
    valueType: 'Τύπος τιμής',
    betType: 'Τύπος στοιχήματος',
    logic: 'Λογική',
    tableView: 'Προβολή πίνακα',
    dropHere: 'Σύρετε και αποθέστε ένα από τα γραφικά στοιχεία για να δημιουργήσετε τον προσωπικό σας πίνακα ελέγχου.',
    category: 'Κατηγορία',
    needToCreateDashboard: 'Κάντε κλικ στο πλήκτρο <0>Δημιουργία πίνακα ελέγχου</0> για να δημιουργήσετε τον προσωπικό σας Πίνακα ελέγχου',
    quarter: 'Τρίμηνο',
    viewDataTable: 'Προβολή Πίνακα δεδομένων',
    dataTable: 'Πίνακας δεδομένων',
    widgetNotAvailable: 'Το γραφικό στοιχείο \r\nδεν είναι διαθέσιμο',
    availableCountryList: 'Λίστα διαθέσιμων χωρών',
    availableCountryListInfo: 'Επιλέξτε τις χώρες των οποίων τις πόλεις θέλετε να δείτε σε συγκεντρωτική μορφή',
  },
};
