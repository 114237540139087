import SeoSettingActionType from './seoSettingActionType';

export const getSeoPageSettings = (pageGroupId, id, params) => ({
  type: SeoSettingActionType.GET_SEO_PAGE_SETTINGS,
  payload: { pageGroupId, id, params },
});

export const saveSeoPagesSettings = (data) => ({
  type: SeoSettingActionType.SAVE_SEO_PAGES_SETTINGS,
  payload: { data },
});

export const resetSeoPagesSettings = () => ({
  type: SeoSettingActionType.RESET_SEO_PAGES_SETTINGS,
});

export const editSeoPagesSettings = (params, data, settings) => ({
  type: SeoSettingActionType.EDIT_SEO_PAGES_SETTINGS,
  payload: { params, data, settings },
});

export const getSeoSocialMediaConfig = (params) => ({
  type: SeoSettingActionType.GET_SEO_SOCIAL_MEDIA_CONFIG,
  payload: { params },
});

export const saveSeoSocialMediaConfig = (data) => ({
  type: SeoSettingActionType.SAVE_SEO_SOCIAL_MEDIA_CONFIG,
  payload: { data },
});

export const resetSeoSocialMediaConfig = () => ({
  type: SeoSettingActionType.RESET_SEO_SOCIAL_MEDIA_CONFIG,
});

export const editSeoSocialMediaConfig = (brandId, data) => ({
  type: SeoSettingActionType.EDIT_SEO_SOCIAL_MEDIA_CONFIG,
  payload: { brandId, data },
});

export const getSeoSchemaMarkup = (params) => ({
  type: SeoSettingActionType.GET_SEO_SCHEMA_MARKUP,
  payload: { params },
});

export const saveSeoSchemaMarkup = (data) => ({
  type: SeoSettingActionType.SAVE_SEO_SCHEMA_MARKUP,
  payload: { data },
});

export const resetSeoSchemaMarkup = () => ({
  type: SeoSettingActionType.RESET_SEO_SCHEMA_MARKUP,
});

export const editSeoSchemaMarkup = (brandId, data) => ({
  type: SeoSettingActionType.EDIT_SEO_SCHEMA_MARKUP,
  payload: { brandId, data },
});

export const getSeoPageGroups = (params) => ({
  type: SeoSettingActionType.GET_SEO_PAGE_GROUPS,
  payload: { params },
});

export const saveSeoPageGroups = (data) => ({
  type: SeoSettingActionType.SAVE_SEO_PAGE_GROUPS,
  payload: { data },
});

export const resetSeoPageGroups = () => ({
  type: SeoSettingActionType.RESET_SEO_PAGE_GROUPS,
});

export const getSeoRobots = (brandId) => ({
  type: SeoSettingActionType.GET_SEO_ROBOTS,
  payload: { brandId },
});

export const saveSeoRobots = (data) => ({
  type: SeoSettingActionType.SAVE_SEO_ROBOTS,
  payload: { data },
});

export const resetSeoRobots = () => ({
  type: SeoSettingActionType.RESET_SEO_ROBOTS,
});

export const editSeoRobots = (brandId, data) => ({
  type: SeoSettingActionType.EDIT_SEO_ROBOTS,
  payload: { brandId, data },
});
