const BlacklistActionType = {
  GET_BLACKLIST: 'GET_BLACKLIST',
  SAVE_BLACKLIST: 'SAVE_BLACKLIST',
  RESET_BLACKLIST: 'RESET_BLACKLIST',

  EXPORT_BLACKLIST: 'EXPORT_BLACKLIST',

  DELETE_BLACKLIST: 'DELETE_BLACKLIST',

  ADD_BLACKLIST: 'ADD_BLACKLIST',

  IMPORT_BLACKLIST: 'IMPORT_BLACKLIST',

  GET_CSV_BLACKLIST: 'GET_CSV_BLACKLIST',
  SAVE_CSV_BLACKLIST: 'SAVE_CSV_BLACKLIST',
  RESET_CSV_BLACKLIST: 'RESET_CSV_BLACKLIST',

  CHANGE_BLACKLIST_ENDED_STATUS: 'CHANGE_BLACKLIST_ENDED_STATUS',

  CHANGE_PLAYERS_BLACKLISTED_STATUS: 'CHANGE_PLAYERS_BLACKLISTED_STATUS',
  CHANGE_PLAYER_BLACKLISTED_STATUS: 'CHANGE_PLAYER_BLACKLISTED_STATUS',
};

export default BlacklistActionType;
