const OperatorLimitActionType = {
  GET_LARGE_TRANSACTIONS_LIMITS_BY_BRAND: 'GET_LARGE_TRANSACTIONS_LIMITS_BY_BRAND',
  SAVE_LARGE_TRANSACTIONS_LIMITS_BY_BRAND: 'SAVE_LARGE_TRANSACTIONS_LIMITS_BY_BRAND',
  RESET_LARGE_TRANSACTIONS_LIMITS_BY_BRAND: 'RESET_LARGE_TRANSACTIONS_LIMITS_BY_BRAND',

  EDIT_LARGE_TRANSACTIONS_LIMITS_BY_BRAND: 'EDIT_LARGE_TRANSACTIONS_LIMITS_BY_BRAND',

  GET_LARGE_TRANSACTION_LIMITS_LOGS: 'GET_LARGE_TRANSACTION_LIMITS_LOGS',
  SAVE_LARGE_TRANSACTION_LIMITS_LOGS: 'SAVE_LARGE_TRANSACTION_LIMITS_LOGS',
  RESET_LARGE_TRANSACTION_LIMITS_LOGS: 'RESET_LARGE_TRANSACTION_LIMITS_LOGS',

  GET_BRAND_PLAYER_LIMITS: 'GET_BRAND_PLAYER_LIMITS',
  SAVE_BRAND_PLAYER_LIMITS: 'SAVE_BRAND_PLAYER_LIMITS',
  RESET_BRAND_PLAYER_LIMITS: 'RESET_BRAND_PLAYER_LIMITS',

  CHANGE_BRAND_PLAYER_LIMITS: 'CHANGE_BRAND_PLAYER_LIMITS',

  GET_PERIODS_LIMITS: 'GET_PERIODS_LIMITS',
  SAVE_PERIODS_LIMITS: 'SAVE_PERIODS_LIMITS',
  RESET_PERIODS_LIMITS: 'RESET_PERIODS_LIMITS',

  EDIT_PERIODS_LIMITS: 'EDIT_PERIODS_LIMITS',
};

export default OperatorLimitActionType;
