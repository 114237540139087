import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { ReleaseNoteModal } from 'components/common';
import { getReleaseNote, resetReleaseNote, markAsSeenReleaseNote } from 'actions/releaseNote/releaseNoteActionCreator';
import { selectLocaleResource, selectReleaseNote, selectResourceReleaseNotes } from 'selectors';
import { getResourceReleaseNotes, resetResourceReleaseNotes } from 'actions/resource/resourceActionCreator';
import enumTypes from 'constants/enumTypes';

const mapStateToProps = ({ releaseNote, resource, locale }) => ({
  releaseNote: selectReleaseNote(releaseNote),
  releaseNotes: selectResourceReleaseNotes(resource),
  locale: selectLocaleResource(locale, { releaseNoteLabels: enumTypes.releaseNoteLabels }),
});

const mapDispatchToProps = (dispatch) => bindActionCreators(
  {
    getReleaseNote,
    resetReleaseNote,
    getResourceReleaseNotes,
    resetResourceReleaseNotes,
    markAsSeenReleaseNote,
  },
  dispatch,
);

export default connect(mapStateToProps, mapDispatchToProps)(ReleaseNoteModal);
