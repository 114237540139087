const TemplateActionType = {
  CREATE_CUSTOM_TEMPLATE: 'CREATE_CUSTOM_TEMPLATE',
  CHANGE_ENDED_STATUS: 'CHANGE_ENDED_STATUS',

  GET_CUSTOM_TEMPLATES: 'GET_CUSTOM_TEMPLATES',
  GET_SYSTEM_TEMPLATES: 'GET_SYSTEM_TEMPLATES',
  GET_SYSTEM_TEMPLATE_TYPES: 'GET_SYSTEM_TEMPLATE_TYPES',
  GET_CUSTOM_TEMPLATE: 'GET_CUSTOM_TEMPLATE',
  GET_SYSTEM_TEMPLATE: 'GET_SYSTEM_TEMPLATE',

  SAVE_TEMPLATE: 'SAVE_TEMPLATE',
  SAVE_TEMPLATES: 'SAVE_TEMPLATES',
  SAVE_TEMPLATES_TOTAL: 'SAVE_TEMPLATES_TOTAL',
  SAVE_SYSTEM_TEMPLATE_TYPES: 'SAVE_SYSTEM_TEMPLATE_TYPES',

  RESET_TEMPLATES: 'RESET_TEMPLATES',
  RESET_TEMPLATE: 'RESET_TEMPLATE',

  DELETE_CUSTOM_TEMPLATE: 'DELETE_CUSTOM_TEMPLATE',
  EDIT_CUSTOM_TEMPLATE: 'EDIT_CUSTOM_TEMPLATE',
  EDIT_SYSTEM_TEMPLATE: 'EDIT_SYSTEM_TEMPLATE',

  ACTIVATE_SYSTEM_TEMPLATE: 'ACTIVATE_SYSTEM_TEMPLATE',

  GET_CUSTOM_TEMPLATES_RESOURCE: 'GET_CUSTOM_TEMPLATES_RESOURCE',
  SAVE_CUSTOM_TEMPLATES_RESOURCE: 'SAVE_CUSTOM_TEMPLATES_RESOURCE',
  RESET_CUSTOM_TEMPLATES_RESOURCE: 'RESET_CUSTOM_TEMPLATES_RESOURCE',
};

export default TemplateActionType;
