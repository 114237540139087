export default {
  en: {
    bonusBet: 'Bonus Bet',
    dateBet: 'Date Bet',
    dateCalculated: 'Date Calculated',
    couponNumber: 'Coupon Number',
    sportBetType: 'Sport Bet Type',
    possibleWin: 'Possible Win',
    txnDetails: 'TXN Details',
    eventStartDate: 'Event Start Date',
    region: 'Region',
    competition: 'Competition',
    eventName: 'Event Name',
    betPlacedPeriod: 'Bet Placed Period',
    finalScore: 'Final Score',
    couponDetails: 'Coupon Details',
    totalOdd: 'Total Odd',
    casinoBets: 'Casino Bets',
    playerCasinoBets: 'Player Casino Bets',
    sportBets: 'Sport Bets',
    playerSportBets: 'Player Sport Bets',
    sportWins: 'Sport Wins',
    casinoWins: 'Casino Wins',
    margin: 'Margin',
    sportGgr: 'Sport GGR',
    casinoGgr: 'Casino GGR',
    bonusBalanceAfter: 'Bonus Balance After',
    otherCostOrIncome: 'Other Cost/Income',
    bonusPlayerId: 'Bonus Player ID',
    systemType: 'System Type',
    autoBet: 'AutoBet',
    sportRealBets: 'Sport Real Bets',
    casinoRealBets: 'Casino Real Bets',
    sportRealWins: 'Sport Real Wins',
    casinoRealWins: 'Casino Real Wins',
    sportBonusBets: 'Sport Bonus Bets',
    casinoBonusBets: 'Casino Bonus Bets',
    sportBonusWins: 'Sport Bonus Wins',
    casinoBonusWins: 'Casino Bonus Wins',
    cashOut: 'Cash Out',
    calculatedGgrNgr: 'Calculated GGR/NGR',
    mixedGgrNgr: 'Mixed GGR/NGR',
    amountOfSettled: 'Amount of Settled',
    countOfSettled: 'Count of Settled',
    sportMixedBets: 'Sport Mixed Bets',
    amountOfReal: 'Amount of Real',
    countOfReal: 'Count of Real',
    amountOfBonus: 'Amount of Bonus',
    countOfBonus: 'Count of Bonus',
    autoRefreshInfo: 'Activating auto-refresh mode in the report will display the bets created within the last hour',
  },
  es: {
    bonusBet: 'Bono de apuesta',
    dateBet: 'Fecha apuesta',
    dateCalculated: 'Fecha calculada',
    couponNumber: 'Número de cupón',
    sportBetType: 'Tipo de apuesta deportiva',
    possibleWin: 'Ganancias potenciales',
    txnDetails: 'Detalles TXN',
    eventStartDate: 'Fecha de inicio del evento',
    region: 'Región',
    competition: 'Competición',
    eventName: 'Nombre del evento',
    betPlacedPeriod: 'Periodo de realización de apuestas',
    finalScore: 'Puntuación final',
    couponDetails: 'Detalles del cupón',
    totalOdd: 'Total Impares',
    casinoBets: 'Apuestas de casino',
    playerCasinoBets: 'Apuestas de casino del jugador',
    sportBets: 'Apuestas deportivas',
    playerSportBets: 'Apuestas deportivas del jugador',
    sportWins: 'Ganancias en deportes',
    casinoWins: 'Ganancias en casino',
    margin: 'Márgen',
    sportGgr: 'GGR deportivo',
    casinoGgr: 'GGR de casino',
    bonusBalanceAfter: 'Despúes del balance del bono ',
    otherCostOrIncome: 'Otros gastos/ingresos',
    bonusPlayerId: 'ID de jugador de la bonificación',
    systemType: 'Tipo de Sistema ',
    autoBet: 'Auto Apuesta',
    sportRealBets: 'Apuestas Deportivas Reales',
    casinoRealBets: 'Apuestas Reales de Casino',
    sportRealWins: 'Deporte Ganancias reales',
    casinoRealWins: 'Casino Ganancias reales',
    sportBonusBets: 'Apuestas Deportivas Bonificadas',
    casinoBonusBets: 'Apuestas de Bonos de Casino',
    sportBonusWins: 'Bonificaciones Deportivas',
    casinoBonusWins: 'Ganar Bonos de Casino',
    cashOut: 'Cash Out',
  },
  tr: {
    bonusBet: 'Bonus Bahis',
    dateBet: 'Bahis Tarihi',
    dateCalculated: 'Hesaplanan Tarih',
    couponNumber: 'Kupon Numarası',
    sportBetType: 'Spor Bahis Türü',
    possibleWin: 'Olası Kazanç',
    txnDetails: 'TXN Bilgileri',
    eventStartDate: 'Etkinlik Başlangıç Tarihi',
    region: 'Bölge',
    competition: 'Müsabaka',
    eventName: 'Etkinlik İsmi',
    betPlacedPeriod: 'Bahis Yapılan Dönem',
    finalScore: 'Son Skor',
    couponDetails: 'Kupon Bilgileri',
    totalOdd: 'Toplam Oran',
    casinoBets: 'Casino Bahisleri',
    playerCasinoBets: 'Oyuncu Casino Bahisleri',
    sportBets: 'Spor Bahisleri',
    playerSportBets: 'Oyuncu Spor Bahisleri',
    sportWins: 'Spor Kazançları',
    casinoWins: 'Casino Kazançları',
    margin: 'Kar Marjı',
    sportGgr: 'Spor GGR',
    casinoGgr: 'Casino GGR',
    bonusBalanceAfter: 'Sonraki Bonus Bakiyesi',
    otherCostOrIncome: 'Diğer Gider/Gelir',
    bonusPlayerId: "Bonus Oyuncu ID'si",
    systemType: 'Sistem Türü',
    autoBet: 'Otomatik Bahis',
    sportRealBets: 'Spor Gerçek Bahisler',
    casinoRealBets: 'Casino Gerçek Bahisler',
    sportRealWins: 'Spor Gerçek Kazançlar ',
    casinoRealWins: 'Casino Gerçek Kazançlar ',
    sportBonusBets: 'Spor Bonus Bahisleri ',
    casinoBonusBets: 'Casino Bonus Bahisleri ',
    sportBonusWins: 'Spor Bonus Kazançları ',
    casinoBonusWins: 'Casino Bonus Kazançları ',
    cashOut: 'Cash Out',
  },
  ru: {
    bonusBet: 'Бонусная ставка',
    dateBet: 'Дата ставки',
    dateCalculated: 'Дата расчета',
    couponNumber: 'Номер купона',
    sportBetType: 'Тип спортивной ставки',
    possibleWin: 'Возможный выигрыш',
    txnDetails: 'Данные транзакции',
    eventStartDate: 'Дата начала события',
    region: 'Регион',
    competition: 'Соревнование',
    eventName: 'Название события',
    betPlacedPeriod: 'Период размещения ставки',
    finalScore: 'Окончательный счет',
    couponDetails: 'Данные купона',
    totalOdd: 'Общий коэффициент',
    casinoBets: 'Ставки в казино',
    playerCasinoBets: 'Ставки игрока в казино',
    sportBets: 'Ставки на спорт',
    playerSportBets: 'Ставки игрока на спорт',
    sportWins: 'Выигрыши от ставок на спорт',
    casinoWins: 'Выигрыши от ставок в казино',
    margin: 'Доходность',
    sportGgr: 'Валовый доход от ставок на спорт',
    casinoGgr: 'Валовый доход от ставок в казино',
    bonusBalanceAfter: 'Баланс бонуса после',
    otherCostOrIncome: 'Другие затраты/Доход',
    bonusPlayerId: 'ID бонуса игрока',
    systemType: 'Тип системы',
    sportRealBets: 'Реальные ставки на спорт',
    casinoRealBets: 'Реальные ставки в казино',
    sportRealWins: 'Реальные выигрыши в спорте',
    casinoRealWins: 'Реальные выигрыши в казино',
    sportBonusBets: 'Бонусные ставки на спорт',
    casinoBonusBets: 'Бонусные ставки в казино',
    sportBonusWins: 'Бонусные выигрыши в спорте',
    casinoBonusWins: 'Бонусные выигрыши в казино',
    cashOut: 'Кэшаут',
  },
  pt: {
    bonusBet: 'Aposta Bónus',
    dateBet: 'Data da Aposta',
    dateCalculated: 'Data Calculada',
    couponNumber: 'Número do Cupão',
    sportBetType: 'Tipo de Aposta Desportiva',
    possibleWin: 'Ganho Possível',
    txnDetails: 'Detalhes de TXN',
    eventStartDate: 'Data de Início do Evento',
    region: 'Região',
    competition: 'Competição',
    eventName: 'Nome do Evento',
    betPlacedPeriod: 'Período de Apostas Realizadas',
    finalScore: 'Resultado Final',
    couponDetails: 'Detalhes do Cupão',
    totalOdd: 'Odds Totais',
    casinoBets: 'Apostas de Casino',
    playerCasinoBets: 'Apostas de Casino do Jogador',
    sportBets: 'Apostas Desportivas',
    playerSportBets: 'Apostas Desportivas do Jogador',
    sportWins: 'Ganhos Desportivos',
    casinoWins: 'Ganhos de Casino',
    margin: 'Margem',
    sportGgr: 'GGR do Desporto',
    casinoGgr: 'GGR do Casino',
    bonusBalanceAfter: 'Saldo de Bónus Após',
    otherCostOrIncome: 'Outro Custo/Rendimento',
    bonusPlayerId: 'ID do Bónus do Jogador',
    systemType: 'Tipo de Sistema ',
    autoBet: 'Auto Aposta',
    sportRealBets: 'Apostas desportivas reais',
    casinoRealBets: 'Apostas reais no casino',
    sportRealWins: 'Desporto Ganhos Reais',
    casinoRealWins: 'Casino Ganhos Reais',
    sportBonusBets: 'Apostas Bónus Desporto',
    casinoBonusBets: 'Apostas de Bónus do Casino',
    sportBonusWins: 'Ganhos de Bónus Desportivos',
    casinoBonusWins: 'Ganhos de Bónus de Casino',
    cashOut: 'Cash Out',
  },
  zh: {
    bonusBet: '投注奖金',
    dateBet: '投注日期',
    dateCalculated: '计算的日期',
    couponNumber: '优惠券编号',
    sportBetType: '体育投注类型',
    possibleWin: '可能赢利',
    txnDetails: 'TXN详情',
    eventStartDate: '赛事开始日期',
    region: '地区',
    competition: '比赛',
    eventName: '赛事名称',
    betPlacedPeriod: '投注期间',
    finalScore: '最终得分',
    couponDetails: '优惠券详情',
    totalOdd: '总赔率',
    casinoBets: '娱乐场投注',
    playerCasinoBets: '玩家的娱乐场投注',
    sportBets: '体育投注',
    playerSportBets: '玩家的体育投注',
    sportWins: '体育获胜',
    casinoWins: '娱乐场赌注获胜',
    margin: '保证金',
    sportGgr: '体育GGR (博彩总收益)',
    casinoGgr: '娱乐场GGR (博彩总收益)',
    bonusBalanceAfter: '后的奖金余额',
    otherCostOrIncome: '其他费用/收入',
    bonusPlayerId: '玩家的奖金ID',
    systemType: '系统类型',
    autoBet: '自动投注',
    sportRealBets: '体育真实投注',
    casinoRealBets: '赌场真实投注',
    sportRealWins: '体育真实赢',
    casinoRealWins: '赌场真赢钱',
    sportBonusBets: '体育红利投注',
    casinoBonusBets: '赌场红利投注',
    sportBonusWins: '体育红利赢',
    casinoBonusWins: '赌场红利赢',
    cashOut: '提现',
  },
  ro: {
    bonusBet: 'Pariu bonus',
    dateBet: 'Data de plasare a pariului',
    dateCalculated: 'Data calculată/validată',
    couponNumber: 'Numărul de cupon',
    sportBetType: 'Tip de pariu sportiv',
    possibleWin: 'Câștig posibil',
    txnDetails: 'Detalii de tranzacție',
    eventStartDate: 'Data începerii a evenimentului',
    region: 'Regiune',
    competition: 'Competiție',
    eventName: 'Numele evenimentului',
    betPlacedPeriod: 'Perioada plasării pariurilor',
    finalScore: 'Scor final',
    couponDetails: 'Detalii de cupon',
    totalOdd: 'Cota totală',
    casinoBets: 'Pariuri de casino',
    playerCasinoBets: 'Pariuri de casino pentru jucător',
    sportBets: 'Pariuri sportive',
    playerSportBets: 'Pariuri sportive pentru jucător',
    sportWins: 'Câștiguri din sport',
    casinoWins: 'Câștiguri la casino',
    margin: 'Marjă',
    sportGgr: 'Venit brut din sport ',
    casinoGgr: 'Venit brut din casino',
    bonusBalanceAfter: 'Balanța bonus după',
    otherCostOrIncome: 'Alt cost / Venit',
    bonusPlayerId: 'ID bonus de jucător',
    systemType: 'Tip sistem',
  },
  bg: {},
  el: {
    bonusBet: 'Μπόνους στοίχημα',
    dateBet: 'Ημερομηνία στοιχήματος',
    dateCalculated: 'Ημερομηνία υπολογισμού',
    couponNumber: 'Αριθμός κουπονιού',
    sportBetType: 'Τύπος αθλητικού στοιχήματος',
    possibleWin: 'Πιθανό κέρδος',
    txnDetails: 'Στοιχεία συναλ.',
    eventStartDate: 'Ημερομηνία έναρξης γεγονότος',
    region: 'Περιοχή',
    competition: 'Διαγωνισμός',
    eventName: 'Όνομα γεγονότος',
    betPlacedPeriod: 'Περίοδος τοποθέτησης στοιχήματος',
    finalScore: 'Τελικό σκορ',
    couponDetails: 'Στοιχεία κουπονιού',
    totalOdd: 'Σύνολο απόδοσης',
    casinoBets: 'Στοιχήματα καζίνο',
    playerCasinoBets: 'Στοιχήματα καζίνο παίκτη',
    sportBets: 'Αθλητικά στοιχήματα',
    playerSportBets: 'Αθλητικά στοιχήματα παίκτη',
    sportWins: 'Κέρδη αθλητικού στοιχήματος',
    casinoWins: 'Κέρδη στο καζίνο',
    margin: 'Περιθώριο',
    sportGgr: 'GGR αθλητικού στοιχήματος',
    casinoGgr: 'GGR καζίνο',
    bonusBalanceAfter: 'Υπόλοιπο μπόνους μετά',
    otherCostOrIncome: 'Άλλα έξοδα/έσοδα',
    bonusPlayerId: 'Αναγνωριστικό μπόνους παίκτη',
    systemType: 'Τύπος συστήματος',
    autoBet: 'Αυτόματο στοίχημα',
    sportRealBets: 'Αθλητικά στοιχήματα με πραγματικά χρήματα',
    casinoRealBets: 'Στοιχήματα καζίνο με πραγματικά χρήματα',
    sportRealWins: 'Κέρδη πραγματικών χρημάτων αθλητικού στοιχήματος',
    casinoRealWins: 'Κέρδη πραγματικών χρημάτων στο καζίνο',
    sportBonusBets: 'Στοιχήματα μπόνους αθλητικού στοιχήματος',
    casinoBonusBets: 'Στοιχήματα μπόνους καζίνο',
    sportBonusWins: 'Κέρδη μπόνους αθλητικού στοιχήματος',
    casinoBonusWins: 'Κέρδη μπόνους καζίνο',
  },
};
