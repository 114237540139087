import TranslationActionType from 'actions/translation/translationActionType';

export const getTranslationSections = (translationGroups) => ({
  type: TranslationActionType.GET_TRANSLATION_SECTIONS,
  payload: { translationGroups },
});

export const getTranslationSectionsSuccess = (data) => ({
  type: TranslationActionType.GET_TRANSLATION_SECTIONS_SUCCESS,
  payload: { data },
});

export const resetTranslationSections = () => ({
  type: TranslationActionType.RESET_TRANSLATION_SECTIONS,
});

export const changeTranslationLoadingState = (state) => ({
  type: TranslationActionType.CHANGE_TRANSLATION_LOADING_STATE,
  payload: { state },
});

export const changeTranslatedLanguagesLoadingState = (state) => ({
  type: TranslationActionType.CHANGE_TRANSLATED_LANGUAGES_LOADING_STATE,
  payload: { state },
});

export const getAllTranslationsByKey = (data) => ({
  type: TranslationActionType.GET_ALL_TRANSLATIONS_BY_KEY,
  payload: { data },
});

export const getAllTranslationsByKeySuccess = (data) => ({
  type: TranslationActionType.GET_ALL_TRANSLATIONS_BY_KEY_SUCCESS,
  payload: { data },
});

export const resetAllTranslationsByKey = () => ({
  type: TranslationActionType.RESET_ALL_TRANSLATIONS_BY_KEY,
});

export const getAllTranslations = (data) => ({
  type: TranslationActionType.GET_ALL_TRANSLATIONS,
  payload: { data },
});

export const resetAllTranslations = () => ({
  type: TranslationActionType.RESET_ALL_TRANSLATIONS,
});

export const saveAllTranslations = (data) => ({
  type: TranslationActionType.SAVE_ALL_TRANSLATIONS,
  payload: { data },
});

export const editTranslations = (data) => ({
  type: TranslationActionType.EDIT_TRANSLATIONS,
  payload: { data },
});

export const exportTranslation = (data) => ({
  type: TranslationActionType.EXPORT_TRANSLATIONS,
  payload: { data },
});

export const importTranslation = (data) => ({
  type: TranslationActionType.IMPORT_TRANSLATIONS,
  payload: { data },
});

export const setTranslationsChangesState = (state) => ({
  type: TranslationActionType.SET_TRANSLATIONS_CHANGES_STATE,
  payload: { state },
});

export const resetTranslationsToDefault = (data) => ({
  type: TranslationActionType.RESET_TRANSLATIONS_TO_DEFAULT,
  payload: { data },
});

export const getTranslatedLanguages = (data) => ({
  type: TranslationActionType.GET_TRANSLATED_LANGUAGES,
  payload: { data },
});

export const getTranslatedLanguagesSuccess = (data) => ({
  type: TranslationActionType.GET_TRANSLATED_LANGUAGES_SUCCESS,
  payload: { data },
});

export const resetTranslatedLanguages = () => ({
  type: TranslationActionType.RESET_TRANSLATED_LANGUAGES,
});
