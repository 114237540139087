import {
  all, call, put, takeLatest,
} from '@redux-saga/core/effects';
import qs from 'qs';
import ReasonsActionType from 'actions/reasons/reasonsActionType';
import { AppConstants } from 'constants/index';
import {
  changeEndedStatus, changeLoadingState, setErrorMessage, setSuccessMessage,
} from 'actions/setting/settingActionCreator';
import NetworkService from 'services/networkService';
import { getError, getMessage } from 'utils/helpers';
import {
  saveReasons, saveReason, saveReasonsResources, saveReasonsByResource,
} from 'actions/reasons/reasonsActionCreator';
import { i18n } from 'services';

function* getReasons({ payload }) {
  try {
    const { params } = payload;
    const options = {
      params,
      paramsSerializer: (param) => qs.stringify(param),
    };
    const { DataCollector, Reasons } = AppConstants.api;
    yield put(changeLoadingState(true));
    const { data } = yield call(NetworkService.makeAPIGetRequest, [DataCollector, Reasons], options);
    yield put(saveReasons(data));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getReason({ payload }) {
  try {
    const { params, reasonId } = payload;
    const options = {
      params,
    };
    const { DataCollector, Reasons } = AppConstants.api;
    yield put(changeLoadingState(true));
    const { data } = yield call(NetworkService.makeAPIGetRequest, [DataCollector, Reasons, reasonId], options);
    yield put(saveReason(data));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* createReason({ payload }) {
  try {
    const { data } = payload;
    const options = {
      data,
    };
    const { DataCollector, Reasons } = AppConstants.api;
    yield put(changeLoadingState(true));
    yield call(NetworkService.makeAPIPostRequest, [DataCollector, Reasons], options);
    yield put(changeLoadingState(false));
    yield put(changeEndedStatus(true));
    yield put(setSuccessMessage(getMessage('', i18n.t('notification:slCreated'), data.name)));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* editReason({ payload }) {
  try {
    const { reasonId, data } = payload;
    const options = {
      data,
    };
    const { DataCollector, Reasons } = AppConstants.api;
    yield put(changeLoadingState(true));
    yield call(NetworkService.makeAPIPutRequest, [DataCollector, Reasons, reasonId], options);
    yield put(changeLoadingState(false));
    yield put(changeEndedStatus(true));
    yield put(setSuccessMessage(getMessage('', i18n.t('notification:slUpdated'), data.name)));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* deleteReason({ payload }) {
  try {
    const { reasonId, name } = payload;
    const { DataCollector, Reasons } = AppConstants.api;
    yield put(changeLoadingState(true));
    yield call(NetworkService.makeAPIDeleteRequest, [DataCollector, Reasons, reasonId]);
    yield put(changeLoadingState(false));
    yield put(changeEndedStatus(true));
    yield put(setSuccessMessage(getMessage('', i18n.t('notification:slDeleted'), name)));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* enableReason({ payload }) {
  try {
    const { reasonId, data, name } = payload;
    const options = {
      data,
    };
    const { DataCollector, Reasons, Enable } = AppConstants.api;
    yield put(changeLoadingState(true));
    yield call(NetworkService.makeAPIPutRequest, [DataCollector, Reasons, reasonId, Enable], options);
    yield put(changeLoadingState(false));
    yield put(changeEndedStatus(true));
    yield put(setSuccessMessage(getMessage('', i18n.t(data.isActive ? 'notification:slEnabled' : 'notification:slDisabled'), name)));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getReasonsResources({ payload }) {
  try {
    const { params } = payload;
    const options = {
      params,
    };
    const { DataCollector, Resources } = AppConstants.api;
    yield put(changeLoadingState(true));
    const { data } = yield call(NetworkService.makeAPIGetRequest, [DataCollector, Resources], options);
    yield put(saveReasonsResources(data));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getReasonsByResource({ payload }) {
  try {
    const { resourceId, params } = payload;
    const options = {
      params,
      paramsSerializer: (param) => qs.stringify(param),
    };
    const { DataCollector, Resources, Reasons } = AppConstants.api;
    yield put(changeLoadingState(true));
    const { data } = yield call(NetworkService.makeAPIGetRequest, [DataCollector, Resources, resourceId, Reasons], options);
    yield put(saveReasonsByResource(data));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* deleteResourceReason({ payload }) {
  try {
    const {
      reasonId, resourceId, name, params,
    } = payload;
    const options = { params };
    const { DataCollector, Resources, Reasons } = AppConstants.api;
    yield put(changeLoadingState(true));
    yield call(NetworkService.makeAPIDeleteRequest, [DataCollector, Resources, resourceId, Reasons, reasonId], options);
    yield put(changeLoadingState(false));
    yield put(changeEndedStatus(true));
    yield put(setSuccessMessage(getMessage('', i18n.t('notification:slDeleted'), name)));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* enableResourceReason({ payload }) {
  try {
    const {
      reasonId, resourceId, name, data,
    } = payload;
    const options = {
      data,
    };
    const {
      DataCollector, Resources, Reasons, Enable,
    } = AppConstants.api;
    yield put(changeLoadingState(true));
    yield call(NetworkService.makeAPIPutRequest, [DataCollector, Resources, resourceId, Reasons, reasonId, Enable], options);
    yield put(changeLoadingState(false));
    yield put(changeEndedStatus(true));
    yield put(setSuccessMessage(getMessage('', i18n.t(data.isActive ? 'notification:slEnabled' : 'notification:slDisabled'), name)));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

export default function* reasonsSaga() {
  yield all([
    takeLatest(ReasonsActionType.GET_REASONS, getReasons),
    takeLatest(ReasonsActionType.GET_REASON, getReason),
    takeLatest(ReasonsActionType.CREATE_REASON, createReason),
    takeLatest(ReasonsActionType.EDIT_REASON, editReason),
    takeLatest(ReasonsActionType.DELETE_REASON, deleteReason),
    takeLatest(ReasonsActionType.ENABLE_REASON, enableReason),
    takeLatest(ReasonsActionType.GET_REASONS_RESOURCES, getReasonsResources),
    takeLatest(ReasonsActionType.GET_REASONS_BY_RESOURCE, getReasonsByResource),
    takeLatest(ReasonsActionType.DELETE_RESOURCE_REASON, deleteResourceReason),
    takeLatest(ReasonsActionType.ENABLE_RESOURCE_REASON, enableResourceReason),
  ]);
}
