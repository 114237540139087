const AuthActionType = {
  LOGIN: 'LOGIN',
  GET_AUTH_USER: 'GET_AUTH_USER',
  SAVE_AUTH_USER: 'SAVE_AUTH_USER',
  SET_AUTHENTICATED: 'SET_AUTHENTICATED',

  CHANGE_TOKEN_STATE: 'CHANGE_TOKEN_STATE',

  FORGOT_PASSWORD: 'FORGOT_PASSWORD',

  RESET_PASSWORD: 'RESET_PASSWORD',

  RESEND_TFA: 'RESEND_TFA',

  GET_USER_SUCCESS: 'GET_USER_SUCCESS',

  CHANGE_PASSWORD: 'CHANGE_PASSWORD',

  CHANGE_TFA_STATE: 'CHANGE_TFA_STATE',

  CHANGE_PASSWORD_EXPIRED_STATE: 'CHANGE_PASSWORD_EXPIRED_STATE',

  CHANGE_EXPIRED_PASSWORD: 'CHANGE_EXPIRED_PASSWORD',

  GET_AUTH_USER_PARTNER_ACCESSES: 'GET_AUTH_USER_PARTNER_ACCESSES',
  GET_AUTH_USER_PARTNER_ACCESSES_SUCCESS: 'GET_AUTH_USER_PARTNER_ACCESSES_SUCCESS',
};

export default AuthActionType;
